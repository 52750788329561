import { FunctionComponent, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { AlertTriangle, X } from "react-feather";
import { AcademyVideoModel } from "../../../../../service/models/academy";
import { GetAcademyVideoData } from "../../../../../service/repos/academy/academy_repo";
import HrmLoader from "../../../hrm/components/loading";
import { urls } from "../../../../../utils/urls";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import { academyVideoCategory } from "../../../../../utils/enums";

interface RecommendProps {}

const Recommend: FunctionComponent<RecommendProps> = () => {
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [searchData, setSearchData] = useState<AcademyVideoModel[]>([]);
  const [hideInput, setHideInput] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [protocolSearch, setProtocolSearch] = useState<any>([]);

  const { showToast } = useToast();
  const [videos, setVideos] = useState<any>([]);

  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(2);

  const getSalesData = async (p: number, c: number) => {
    const data: { page: number; count: number; category: string } = {
      page: p,
      count: c,
      category: academyVideoCategory.Sales,
    };
    const res = await GetAcademyVideoData(data);

    if (res.success) {
      setVideos((o: any) => [...o, ...res.data?.videos]);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
  };

  const getCmsData = async (p: number, c: number) => {
    const data: { page: number; count: number; category: string } = {
      page: p,
      count: c,
      category: academyVideoCategory.Cms,
    };
    const res = await GetAcademyVideoData(data);

    if (res.success) {
      setVideos((o: any) => [...o, ...res.data?.videos]);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
  };

  const protocolLinks = [
    {
      name: "Installation",
      link: urls.installation_protocol,
      logo: "/academy/installations.png",
    },
    {
      name: "Saas",
      link: urls.saas_protocol,
      logo: "/academy/cms.png",
    },
    {
      name: "Complaint",
      link: urls.complaint_segment,
      logo: "/academy/complaint.png",
    },
    {
      name: "Inside sales pitch",
      link: urls.inside_sales_protocol,
      logo: "/academy/insides.png",
    },
    {
      name: "Sales Training",
      link: urls.sales_training_protocol,
      logo: "/academy/sales.png",
    },
    {
      name: "Hub creation",
      link: urls.hub_creation_checklist,
      logo: "/academy/hub.png",
    },
  ];

  const searchSubmit = async (e: any) => {
    setLoading(true);
    setHideInput(true);
    e.preventDefault();
    // protocol searching
    const tempSearch = protocolLinks.filter((el) =>
      el.name.toLowerCase().includes(e.target.search.value.toLowerCase())
    );
    setProtocolSearch(tempSearch);

    // videos searching
    const data = {
      page: 1,
      count: 10,
      search: e.target.search.value,
    };
    const res = await GetAcademyVideoData(data);

    if (res.success) {
      setLoading(false);
      setShowSearch(true);
      setSearchData(res.data?.videos);
    }
  };

  useEffect(() => {
    getSalesData(page, count);
    getCmsData(page, count);
  }, []);

  return (
    <div className="relative">
      <div className="absolute -top-6 left-0 w-full flex flex-col items-center">
        <div className=" bg-white w-4/5 lg:w-1/2 h-12 lg:h-16 rounded-full shadow-lg">
          <form
            action=""
            id="searchForm"
            className="w-full h-full flex"
            onSubmit={searchSubmit}
          >
            <div className="w-3/4 lg:w-11/12 h-full">
              <input
                type="text"
                name="search"
                className="h-full w-full  rounded-full pl-4 lg:pl-12 focus:outline-none"
                placeholder="Search any topic"
                disabled={hideInput}
              />
            </div>

            <div className="w-1/4 lg:w-1/12 flex flex-col items-center justify-center">
              {loading ? (
                <HrmLoader className="w-8 h-8" />
              ) : (
                <input
                  type="reset"
                  className={` ${
                    hideInput ? "block" : "hidden"
                  } underline mr-2 font-bold cursor-pointer bg-transparent`}
                  onClick={(e) => {
                    setHideInput(false);
                    setShowSearch(false);
                  }}
                />
              )}
            </div>
          </form>
        </div>
        {showSearch && (
          <div className=" w-4/5 lg:w-1/2 z-40">
            {searchData?.length > 0 || protocolSearch.length > 0 ? (
              <div className="relative bg-white shadow-xl rounded-xl mt-2  py-16 px-12">
                <div className="absolute top-3 right-4">
                  <X
                    onClick={() => setShowSearch(false)}
                    className="text-black cursor-pointer"
                    size={32}
                  />
                </div>
                {searchData.length > 0 && (
                  <div className="">
                    <div className="text-xl font-bold border-b border-gray-300">
                      Videos
                    </div>
                    <div className="grid lg:grid-cols-3 gap-y-8 lg:gap-y-8 mt-8">
                      {searchData.map((el: AcademyVideoModel, i: number) => {
                        return (
                          <div className="w-48 ">
                            <div className="w-full h-32 rounded-xl">
                              <iframe
                                width="100%"
                                height="100%"
                                src={el?.url.replace("watch?v=", "embed/")}
                                className="rounded-xl"
                                title="Youtube"
                              ></iframe>
                            </div>
                            <div className="desc_wrapper font-semibold text-lg mt-4">
                              {el.title}
                            </div>
                            <div className="w-full h-px bg-gray-200 my-2"></div>
                            <div className="flex justify-between text-sm text-secondaryColor">
                              <div className="">Category</div>
                              <div className="">{el.category}</div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                {protocolSearch.length > 0 && (
                  <div className="mt-10">
                    <div className="text-xl font-bold border-b border-gray-300">
                      Protocols
                    </div>

                    <div className="grid lg:grid-cols-3 gap-y-8 lg:gap-y-0 mt-8">
                      {protocolSearch.map(
                        (
                          el: {
                            name: string;
                            link: string;
                            logo: string;
                          },
                          i: number
                        ) => {
                          return (
                            <NavLink to={el.link}>
                              <div className="h-32 flex flex-col justify-between py-3 ">
                                <div className="h-3/5">
                                  <img src={el.logo} alt="" className="w-14" />
                                </div>
                                <div className="h-2/5 text-lg font-semibold">
                                  {el.name}
                                </div>
                              </div>
                            </NavLink>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="mt-4 text-hrmRed">
                No data found{" "}
                <AlertTriangle size={20} className="text-hrmRed inline ml-1" />{" "}
              </div>
            )}
          </div>
        )}
      </div>
      <div className="flex flex-col items-center  pt-20 lg:pt-32">
        <div className="w-11/12 lg:w-4/5">
          <div className="text-xl lg:text-2xl font-semibold">
            Recommended videos
          </div>
          <div className="grid grid-cols-2 lg:grid-cols-4  gap-4 lg:gap-12 mt-8">
            {videos.length > 0 &&
              videos.map((el: AcademyVideoModel, i: number) => {
                return (
                  <div className="w-full h-60" key={i}>
                    <iframe
                      src={el.url.replace("watch?v=", "embed/")}
                      title="sales"
                      frameBorder="0"
                      width="100%"
                      height="100%"
                      allowFullScreen={true}
                    ></iframe>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recommend;
