import { Note, Tune } from "@material-ui/icons";
import { mdiDelete } from "@mdi/js";
import Icon from "@mdi/react";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { useAuth } from "../../../../../context_providers/auth/authProvider";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import { ComplaintCommentModel } from "../../../../../service/models/complaint_model";
import {
  CreateComplaintComment,
  DeleteComplaintComment,
  getComplaintComments,
} from "../../../../../service/repos/support/complaint_repo";

import { ACTION, MODULE } from "../../../../../utils/enums";
import LoadingWidget from "../../../../components/loading_spinner";
import MentionTextFieldWidget from "../../../orders/order_list/components/mention_text_field";

interface CommentWidgetProps {
  complaint_id: string;
}

const CommentWidget: FunctionComponent<CommentWidgetProps> = ({
  complaint_id,
}) => {
  const { showToast } = useToast();
  const { isAuthorised, user, logout } = useAuth();
  const [comments, setComments] = useState<ComplaintCommentModel[]>([]);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const getComments = async () => {
    setLoading(true);
    const res = await getComplaintComments({
      page: 1,
      count: 1,
      all: true,
      complaint_id,
    });
    if (res.success) {
      setComments(res.data);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  const createComment = async () => {
    setLoading(true);
    const res = await CreateComplaintComment({ comment: input, complaint_id });
    if (res.success) {
      setComments((pr) => [res.data, ...pr]);
      setInput("");
      showToast({ type: ToastType.success, text: res.message ?? "" });
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  const deleteComment = async (id: any) => {
    setLoading(true);
    const res = await DeleteComplaintComment({ complaint_id, id });
    if (res.success) {
      setComments((pr) => pr.filter((v) => v.id !== id));
      showToast({ type: ToastType.success, text: res.message ?? "" });
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  useEffect(() => {
    getComments();
  }, []);
  const interval = 1000 * 60 * 60 * 10;
  return (
    <>
      <div
        className="flex flex-col justify-between gap-2 p-3"
        style={{ height: "92%" }}
      >
        <div className="overflow-auto    " style={{ height: "87%" }}>
          {comments.length === 0 && (
            <p className="text-center my-10">No Comment!</p>
          )}
          {comments.map((comment, i) => (
            <div key={i}>
              <div className="flex justify-between py-2">
                <div className="flex justify-start items-center  gap-2">
                  <div className="bg-red-400 text-white rounded-full  p-2  flex justify-center items-center font-bold ">
                    {comment.user ? comment.user[0].toUpperCase() : "A"}
                  </div>
                  <div className="text-xs w-full">
                    <div className=" text-sm font-medium"> {comment.user}</div>
                    <div className="flex justify-between w-full text-xs">
                      {moment(comment.created_at).format("lll")}
                      {/* {category === "All" && (
                      <span className=" text-xs bg-yellow-700 text-white rounded-3xl px-2 py-1 ">
                        {comment.category}
                      </span>
                    )} */}
                    </div>
                  </div>
                </div>
                {user?.uid === comment.uid &&
                  Date.now() - (comment.created_at as any) < interval && (
                    <div className="" onClick={() => deleteComment(comment.id)}>
                      <Icon
                        path={mdiDelete}
                        className="h-4 w-4 hover:text-red-600 cursor-pointer"
                      ></Icon>
                    </div>
                  )}
              </div>
              <p className="text-xs   whitespace-pre-wrap overflow-hidden">
                {comment.comment}
              </p>
              <hr className="my-2" />
            </div>
          ))}
        </div>
        {!loading && (
          <div className=" grid grid-cols-1 md:grid-cols-2   z-50  fixed bottom-3 left-3 right-3 ">
            <MentionTextFieldWidget
              placeholder="Note"
              disabled={
                !isAuthorised({
                  module: MODULE.complaint,
                  action: [ACTION.UPDATE],
                })
              }
              value={input}
              onChange={(e) => {
                setInput(e);
              }}
            />

            <button
              onClick={() => {
                if (input) {
                  createComment();
                }
              }}
              type="button"
              className="  bg-gray-200 rounded py-1 px-2 text-sm hover:bg-red-400 hover:text-white col-span-2"
            >
              Submit
            </button>
          </div>
        )}
      </div>
      {/* <div className="flex-col justify-between h-full">
        <div className="overflow-auto" style={{ height: "85%" }}>
          {comments.length === 0 && (
            <p className="text-center my-10">No Comment!</p>
          )}
          {comments.map((comment, i) => (
            <div key={i}>
              <div className="flex justify-start items-center py-2">
                <div className="bg-red-400 text-white rounded-full w-10 h-10 flex justify-center items-center font-bold mr-3">
                  {comment.user ? comment.user[0].toUpperCase() : "A"}
                </div>
                <span className="text-xs">
                  <span className="font-semibold"> {comment.user}</span>{" "}
                  {moment(comment.created_at, "YYYY-MM-DD hh:mm:ss").format(
                    "lll"
                  )}
                </span>
              </div>
              <p className="text-sm"> {comment.comment}</p>
              <hr className="my-2" />
            </div>
          ))}
        </div>
        <div style={{ height: "12%" }} className="flex  items-end mx-2 z-50 ">
          <div className="">
            <label htmlFor="comment">Add Note</label>
            <textarea
              id="comment"
              className="border rounded w-full"
              name="comment"
              disabled={
                !isAuthorised(MODULE.COMPLAINT, [ACTION.UPDATE], authState.auth)
              }
              placeholder="Short note"
              value={input}
              onChange={(e) => setInput(e.target.value)}
            />
          </div>
          <div className="mx-2 flex">
            <button
              onClick={() => {
                if (input) {
                  createComment();
                }
              }}
              type="button"
              className=" bg-gray-200 rounded py-2 px-4 hover:bg-gray-400 hover:text-white"
            >
              Add
            </button>
          </div>
        </div>
      </div>  */}
      <LoadingWidget loading={loading} />
    </>
  );
};

export default CommentWidget;
