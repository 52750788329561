import { FunctionComponent, useState } from "react";
import { FileType } from "../../../../../../../../utils/enums";
import { GalleryModel } from "../../../../../infrastructure/models/car_model";
import { compressImage } from "../../../../../infrastructure/utils/image_compressor";
import FileDropZone from "../../../../new_components/common/file_drop_widget";

interface GalleryAddFileProps {
  onSubmit: (data: FormData) => void;
  data?: GalleryModel;
  varient_id: string;
}

const GalleryAddFile: FunctionComponent<GalleryAddFileProps> = (props) => {
  const [image, setImage] = useState<File>();
  const [url, setUrl] = useState(props.data?.url ?? "");
  const [type, setType] = useState(props.data?.type ?? "");
  return (
    <>
      <div className="h-5/6 m-5 ">
        <form
          onSubmit={async (e) => {
            e.preventDefault();

            const formData = new FormData();
            let data: {} = {};
            if (url) {
              data = { ...data, url, type };
            }
            if (props.data) {
              data = { ...data, id: props.data.id };
            }
            // let data = formToObject(new FormData(e.currentTarget));
            // delete data.files;
            if (!image && !url) {
              alert("Please select image");
              return;
            }
            if (image) {
              const compressedImage = await compressImage(image);
              formData.append("gallery", compressedImage);
            }

            formData.append(
              "data",
              JSON.stringify({ varient_id: props.varient_id, gallery: data })
            );
            props.onSubmit(formData);
            //  props.createVehicle(formData);
          }}
        >
          {image || url ? (
            <div className=" relative">
              <img
                alt="image"
                className="h-96 w-96 rounded"
                src={image ? URL.createObjectURL(image) : url}
              ></img>
              <div
                className="absolute top-0 right-0  flex justify-center items-center p-3 bg-gray-100 rounded-full  cursor-pointer "
                onClick={() => {
                  image ? setImage(undefined) : setUrl("");
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-red-400 transform transition-all duration-300 hover:text-red-700 hover:scale-125"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              </div>
            </div>
          ) : (
            <FileDropZone
              required={false}
              className="h-96 w-96"
              onChange={(files) => setImage(files[0])}
              multiSelect={false}
              type="image/*"
            />
          )}
          {!image && (
            <>
              --- OR ---
              <div className="flex flex-col">
                <label htmlFor="url" className=" text-lg">
                  URL
                </label>
                <input
                  disabled={image !== undefined}
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  className="focus:outline-none p-2 bg-gray-100 rounded"
                  type="text"
                  name="url"
                  id="url"
                  required
                  placeholder="Enter the url"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="type" className=" text-lg">
                  Type
                </label>
                <select
                  disabled={image !== undefined}
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  className="focus:outline-none p-2 bg-gray-100 rounded"
                  name="type"
                  id="type"
                  required
                >
                  <option value="">Select</option>
                  <option value={FileType.IMAGE}>Image</option>
                  <option value={FileType.VIDEO}>Video</option>
                </select>
              </div>
            </>
          )}
          <div className="flex justify-center my-5">
            <button
              className="px-5 py-2 mx-2 bg-red-400 text-white rounded-3xl hover:bg-red-800"
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default GalleryAddFile;
