import { FunctionComponent } from "react";
import { X } from "react-feather";
import { NavLink } from "react-router-dom";
import { urls } from "../../../../utils/urls";
import Card from "../components/Card";

interface VideosCategoryProps {}

const VideosCategory: FunctionComponent<VideosCategoryProps> = () => {
  const links = [
    {
      name: "General",
      link: urls.general_learning,
      logo: "/academy/general.png",
    },
    {
      name: "CMS",
      link: urls.cms_learning,
      logo: "/academy/cms.png",
    },

    {
      name: "Tally",
      link: urls.tally_learning,
      logo: "/academy/tally.png",
    },
    {
      name: "Sales",
      link: urls.sales_learning,
      logo: "/academy/sales.png",
    },
    {
      name: "Company updates",
      link: urls.company_updates,
      logo: "/academy/updates.png",
    },
    {
      name: "HR Policies",
      link: urls.hr_policies,
      logo: "/academy/tally.png",
    },

    {
      name: "Life at Kazam",
      link: urls.life_at_kazam,
      logo: "/academy/life.png",
    },
    {
      name: "Startup Gyaan",
      link: urls.startup_gyaan,
      logo: "/academy/cms.png",
    },
    {
      name: "Electric Vehicle News",
      link: urls.ev_news,
      logo: "/academy/gyaan.png",
    },
    {
      name: "Success story",
      link: urls.success_story,
      logo: "/academy/success.png",
    },
    {
      name: "Electric Vehicle Reviews",
      link: urls.ev_reviews,
      logo: "/academy/gyaan.png",
    },
  ];
  return (
    <div className="relative bg-hrmBgLight min-h-screen py-20">
      <div className="absolute top-4 right-4">
        <NavLink to={urls.kazam_academy}>
          <X className="text-black" size={48} />
        </NavLink>
      </div>
      <div className="flex flex-col items-center justify-center">
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-12 mt-8 w-11/12 lg:w-4/5">
          {links.map(
            (el: { name: string; link: string; logo: string }, i: number) => {
              return <Card data={el} />;
            }
          )}
        </div>
      </div>
    </div>
  );
};

export default VideosCategory;
