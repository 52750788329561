import { FunctionComponent } from "react";
import TaskSessionProvider from "../../../../context_providers/task_session_ctx";
import { Route, useRouteMatch } from "react-router-dom";
import EngineerTaskList from "../engineer_view/engineer_task_list";
import EngineerTaskDetail from "../engineer_view/engineer_detail/engineer_detail_view";
import EmpAttendancePage from "../attendance/emp_attedance";

interface EngineerLayoutProps {}

const EngineerLayout: FunctionComponent<EngineerLayoutProps> = () => {
  const { path } = useRouteMatch();
  return (
    <>
      {" "}
      <div className="grid lg:grid-cols-11 lg:hidden">
        <div
          className="lg:col-start-5 lg:col-span-3 md:rounded-lg md:border overflow-auto   "
          style={{ height: "92vh" }}
        >
          <TaskSessionProvider>
            <Route exact path={`${path}`}>
              <EngineerTaskList />
            </Route>
            <Route path={`${path}/detail/:id`}>
              <EngineerTaskDetail />
            </Route>
            <Route path={`${path}/checkin`}>
              <EmpAttendancePage />
            </Route>
          </TaskSessionProvider>
        </div>
      </div>
      <div className="hidden lg:flex flex-col h-full w-full justify-center items-center text-xl font-semibold text-gray-500">
        Please open in mobile
      </div>
    </>
  );
};

export default EngineerLayout;
