import { useState } from "react";
import { compressImage } from "../../infrastructure/utils/image_compressor";
import { uploadBlogPhoto } from "../../services/blogPostService";
import Loader from "../../ui/new_components/common/loader";
import "./index.css";

export default function Index(props) {
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState(null);

  const handleUploadFileData = async (e) => {
    let files = e.target.files;
    let data = new FormData();
    try {
      if (files && files.length) {
        const sure = window.confirm("Are you sure");
        if (sure) {
          setLoading(true);
          const compressedFile = await compressImage(files[0]);
          data.append("image", compressedFile);
          const response = await uploadBlogPhoto(data);
          if (response.success) {
            const s3url = response.file.url;
            setUrl(s3url);
          } else {
            console.log(response.error);
            alert(response.error);
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  if (loading) return <Loader />;
  return (
    <div className="mt-10 ">
      <div className="flex justify-center items-center">
        <div className="col-6">
          <form>
            <div className="form-group">
              <label for="exampleFormControlFile1">Upload Image</label>
              <input
                name="blogUpload"
                type="file"
                id="exampleFormControlFile1"
                className="form-control-file border upload"
                onChange={(e) => handleUploadFileData(e)}
              />
            </div>
            <p>Please upload image less than 3mb</p>
          </form>
        </div>
      </div>
      {url && (
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-8">
            <img className="text-align-center" src={url} alt="new" />
          </div>
          <div className="col-8">{url}</div>
        </div>
      )}
    </div>
  );
}
