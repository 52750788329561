import { Tooltip } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { Check, Info, X } from "react-feather";
import {
  ToastType,
  useToast,
} from "../../../../context_providers/toast/toast_ctx";
import { LeaveRequest } from "../../../../service/models/user_model";
import {
  GetLeaveRequests,
  UpdateLeaveRequests,
} from "../../../../service/repos/hrm/hrm_repo";
import { LeaveRequestStatus } from "../../../../utils/enums";
import HrmLoader from "../components/loading";
import Layout from "../layout";
import "../styles/index.css";

interface HrLeavePanelProps {}

const HrLeavePanel: FunctionComponent<HrLeavePanelProps> = () => {
  const headings = ["Employee", "Type", "Actions"];
  const { showToast } = useToast();

  const [activityData, setActivityData] = useState<LeaveRequest[]>([]);

  const count = 10;
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const getActivityAll = async (p: number) => {
    setLoading(true);
    const params: { page: number; count: number } = {
      page: p,
      count: count,
    };
    const res = await GetLeaveRequests(params);

    if (res.success) {
      setLoading(false);

      setTotal(res.data.metadata[0].total);
      setActivityData(res.data.leave_requests);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
  };

  const updateData = (id: number, status: string) => {
    setActivityData((old) =>
      old.map((o) => {
        if (o.id === id) o.status = status;
        return o;
      })
    );
  };

  const onPageChange = async (p: number) => {
    if (p !== page) {
      setPage(p);
      await getActivityAll(p);
    }
  };

  useEffect(() => {
    getActivityAll(page);
  }, []);

  return (
    <Layout>
      <div className="">
        <div className=" pb-12 pt-12">
          <div className="flex flex-col items-center overflow-hidden">
            <div className="w-full lg:w-11/12 relative  flex flex-col items-center">
              <div className="w-full flex items-center justify-between ml-2 lg:ml-0 lg:mr-2">
                <div className="">
                  <div className="text-black dark:text-primaryColor lg:text-2xl">
                    Leave requests to be approved by HR Admin
                  </div>
                  <div className="text-sm lg:text-xl text-secondaryColor">
                    Incase of pending leaves, hr can approve the leave of an
                    employee
                  </div>
                </div>
                <select className=" hidden focus:outline-none bg-transparent border border-primaryColor text-primaryColor py-1 px-2 rounded cursor-pointer">
                  <option className="text-black" value="All">
                    All
                  </option>
                  {Object.values(LeaveRequestStatus).map((el, i) => {
                    return (
                      <option className="text-black" value={el} key={i}>
                        {el}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="w-full p-4 mt-4 lg:mt-12 rounded-lg bg-white dark:bg-hrmCardDark">
                <div className="hidden lg:grid grid-cols-3 text-secondaryColor ">
                  {headings.map((el, i) => {
                    return (
                      <div className="" key={i}>
                        {el}
                      </div>
                    );
                  })}
                </div>
                {/* put posts here */}
                <div className="hr_leave_panel_wrapper overflow-y-auto ">
                  {loading ? (
                    <div className="flex flex-col items-center justify-center">
                      <HrmLoader className="w-12 h-12" />
                    </div>
                  ) : (
                    activityData.map((el: LeaveRequest, i: number) => {
                      return <Row data={el} key={i} updateData={updateData} />;
                    })
                  )}
                </div>
              </div>
              <div className="flex flex-col items-center">
                <Pagination
                  page={page}
                  count={Math.ceil(total / count)}
                  className={`my-5 hrm_pagination`}
                  color="secondary"
                  variant="outlined"
                  onChange={(event, page) => {
                    onPageChange(page);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default HrLeavePanel;

interface RowProps {
  data: LeaveRequest;
  updateData: (id: number, status: string) => void;
}

const Row: FunctionComponent<RowProps> = ({ data, updateData }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { showToast } = useToast();

  const updateLeaveRequest = async (id: number, status: string) => {
    setLoading(true);
    const res = await UpdateLeaveRequests({
      id,
      status,
    });

    if (res.success) {
      updateData(id, status);
      setLoading(false);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
  };

  return (
    <div className="grid grid-cols-2 gap-y-4 lg:gap-y-0 lg:grid-cols-3 items-center mt-5">
      <div className="">
        <div className="text-black dark:text-primaryColor text-sm lg:text-base">
          {data.emp_name}
        </div>
        <div className=" text-xs lg:text-sm text-secondaryColor">
          L.M - {data.reporting_name}
        </div>
      </div>
      <div className="">
        <div className="text-xs lg:text-base text-black dark:text-primaryColor">
          {data.leave_name}
          <Tooltip title={data.message}>
            <Info
              className="inline ml-3 text-gray-500 cursor-pointer"
              size={16}
            />
          </Tooltip>
        </div>
        <div className="text-xs lg:text-sm text-secondaryColor">
          {moment(data.from_date, "YYYY-MM-DD HH-mm-ss").format("DD MMM YYYY")}{" "}
          -{moment(data.to_date, "YYYY-MM-DD HH-mm-ss").format("DD MMM YYYY")}
        </div>
      </div>
      {data.status === LeaveRequestStatus.pending ? (
        loading ? (
          <div className="">
            <HrmLoader className="w-6 h-6" />
          </div>
        ) : (
          <div className="flex gap-3">
            <button
              className="p-2 bg-green-400 bg-opacity-30  rounded-lg"
              onClick={() => {
                updateLeaveRequest(data.id!, LeaveRequestStatus.approved);
              }}
            >
              <Check className="text-green-400" size={20} />
            </button>

            <button
              className="p-2 bg-red-400 bg-opacity-30  rounded-lg"
              onClick={() => {
                updateLeaveRequest(data.id!, LeaveRequestStatus.rejected);
              }}
            >
              <X className="text-red-400" size={20} />
            </button>
          </div>
        )
      ) : (
        <div className="">
          <div
            className={` ${
              data.status === LeaveRequestStatus.approved
                ? "  text-green-600 font-semibold "
                : data.status === LeaveRequestStatus.rejected
                ? "  text-red-600 font-semibold "
                : "  text-blue-600 font-semibold "
            }  rounded-full text-sm `}
          >
            {data.status}
          </div>
          <div className="text-xs text-secondaryColor">
            By {data.updated_by_name}
          </div>
        </div>
      )}
    </div>
  );
};
