import gsap from "gsap";
import { FunctionComponent, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { urls } from "../../../../../utils/urls";
import "../../academy.css";
interface HeaderProps {}

const Header: FunctionComponent<HeaderProps> = () => {
  const animateData = () => {
    const tl = gsap.timeline();

    tl.from(".academy_heading_text", 0.5, {
      opacity: 0,
      x: -100,
    }).fromTo(
      ".academy_image",
      0.5,
      {
        opacity: 0,
        x: 100,
      },
      {
        opacity: 1,
        x: 0,
      }
    );
  };

  useEffect(() => {
    animateData();
  }, []);

  return (
    <div className=" bg-black flex flex-col-reverse lg:flex-row py-12 lg:py-32">
      <div className="academy_heading_text lg:w-1/2 flex flex-col items-center justify-center">
        <div className="">
          <div className="heading_animate text-sm lg:text-base text-secondaryColor">
            Upgrade your skill
          </div>
          <div className="heading_animate mt-6 text-2xl lg:text-4xl text-white font-bold">
            Explore every field in Kazam
          </div>
          <div className="heading_animate text-primaryColor mt-3 lg:text-xl">
            Know how kazam works together
          </div>
          <div className="heading_animate flex gap-8 items-center mt-8">
            <div className="">
              <NavLink to={urls.videos_category}>
                <button className="py-2 lg:py-3 px-6 lg:px-8 rounded bg-hrmPurple text-white font-semibold">
                  Watch Videos
                </button>
              </NavLink>
            </div>{" "}
            <NavLink to={urls.protocol_category}>
              <div className="text-primaryColor cursor-pointer">
                Read protocols
              </div>
            </NavLink>
          </div>
        </div>
      </div>
      <div className="lg:w-1/2 flex flex-col items-center justify-center relative">
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-3/5">
          <svg
            viewBox="0 0 200 200"
            className="bg-opacity-20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="#8302E9"
              // style={"opacity: 0.2"}
              fillOpacity={0.3}
              d="M54,-64C66.3,-53.9,70.3,-33.5,73,-13.5C75.7,6.4,77.3,26,69.2,39.9C61.1,53.8,43.3,62.1,25.2,68.1C7.1,74.2,-11.3,78,-28.4,73.7C-45.4,69.3,-61.1,56.8,-69.7,40.8C-78.4,24.7,-80,5.2,-73.9,-10.2C-67.8,-25.5,-54,-36.7,-40.3,-46.5C-26.7,-56.3,-13.4,-64.7,3.7,-69.2C20.8,-73.6,41.6,-74,54,-64Z"
              transform="translate(100 100)"
            />
          </svg>
        </div>
        <div className="academy_image opacity-0">
          <img src="/academy/headerGif.gif" alt="" className="w-72 headerImg" />
        </div>
      </div>
    </div>
  );
};

export default Header;
