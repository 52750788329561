import { mdiChevronDown, mdiChevronUp } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useState } from "react";

interface SubmenuWidgetProps {
  collapsed: boolean;
  button: any;
  key?: any;
}

const SubmenuWidget: FunctionComponent<SubmenuWidgetProps> = ({
  collapsed,
  children,
  button,
  key,
}) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <li
        key={key}
        onClick={() => setShow((p) => !p)}
        className={` select-none cursor-pointer z-10  duration-300  ${
          children &&
          "rounded flex justify-between  items-center hover:bg-gray-900 text-white "
        } ${!collapsed && "gap-2 "} `}
      >
        {button}
        {children && (
          <span>
            <Icon
              path={mdiChevronUp}
              className={`transform duration-300 w-4 h-4 text-white ${
                show ? "rotate-0" : "rotate-180"
              }`}
            ></Icon>
          </span>
        )}
      </li>
      {children && (
        <ul
          className={`${collapsed ? "" : "ml-2 mt-1"} slide_down    ${
            show ? "block" : "hidden"
          }`}
        >
          {children}
        </ul>
      )}
    </>
  );
};

export default SubmenuWidget;
