import { RouteParam } from "../../../routes";
import { ACTION, MODULE } from "../../../utils/enums";
import { urls } from "../../../utils/urls";
import PaymentVoucherListScreen from "../account_booking/payment/payment_voucher_register";
import PaymentVoucher from "../vouchers/payment";

const payment_voucher_routes: RouteParam[] = [
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.payment_voucher,
    component: PaymentVoucherListScreen,
    module: MODULE.voucher,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.payment_voucher + "/create",
    component: PaymentVoucher,
    module: MODULE.voucher,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.payment_voucher + "/update/:id",
    component: PaymentVoucher,
    module: MODULE.voucher,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
];

export default payment_voucher_routes;
