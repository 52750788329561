const root = "/";
export interface URLS {
  [key: string]: string;
}
export const URLS = {
  homePage: `${root}`,
  login: `${root}login`,
  blogEditor: `${root}blog-editor`,
  blogListing: `${root}blog-listings`,
  uploadImage: `${root}upload-image`,

  installation: `${root}installation`,
  installationCreate: `${root}installation-create`,
  installationEdit: `${root}installation-editor`,
  magazine: `${root}magazine`,
  magazineCreate: `${root}magazine-create`,
  magazineEdit: `${root}magazine-editor`,

  vehicleListing: `${root}vehicle-listings`,
  vehicleCreate: `${root}vehicle-listings/create`,
  bikeSpec: `${root}vehicle-listings/bike/specifications/:id`,
  bikePricing: `${root}vehicle-listings/bike/pricing/:id`,
  bikeImages: `${root}vehicle-listings/bike/images/:id`,
  authors: `${root}authors`,
  authorCreate: `${root}author-create`,
  authorEdit: `${root}author-edit`,
  vehicles: `${root}vehicles`,
  varientListing: `${root}vehicles/varients`,
  priceListing: `${root}vehicles/varients/prices`,
  galleryListing: `${root}vehicles/varients/gallery`,
  createVehicle: `${root}create-vehicle`,
  updateVehicle: `${root}update-vehicle`,
  gallery: `${root}gallery`,
};

export const UrlLabels = () => {
  const labels: { [key: string]: string } = {};
  // Object.keys(URLS).forEach((url) => {
  //   labels[URLS[url].replace("/", "")] = url;
  // });
  labels[URLS.homePage.split("/")[URLS.homePage.split("/").length - 1]] =
    "Dashboard";
  labels[URLS.blogListing.split("/")[URLS.blogListing.split("/").length - 1]] =
    "Blogs";
  labels[URLS.login.split("/")[URLS.login.split("/").length - 1]] = "Login";
  labels[URLS.blogEditor.split("/")[URLS.blogEditor.split("/").length - 1]] =
    "Blog Editor";
  labels[URLS.uploadImage.split("/")[URLS.uploadImage.split("/").length - 1]] =
    "Upload Image";
  labels[
    URLS.installation.split("/")[URLS.installation.split("/").length - 1]
  ] = "Installations";
  labels[
    URLS.installationCreate.split("/")[
      URLS.installationCreate.split("/").length - 1
    ]
  ] = "Create Installation ";
  labels[
    URLS.installationEdit.split("/")[
      URLS.installationEdit.split("/").length - 1
    ]
  ] = "Edit Installation ";

  labels[URLS.magazine.split("/")[URLS.magazine.split("/").length - 1]] =
    "Magazines";
  labels[
    URLS.magazineCreate.split("/")[URLS.magazineCreate.split("/").length - 1]
  ] = "Create Magazine ";
  labels[
    URLS.magazineEdit.split("/")[URLS.magazineEdit.split("/").length - 1]
  ] = "Edit Magazine ";
  labels[URLS.authors.split("/")[URLS.authors.split("/").length - 1]] =
    "Authors ";
  labels[
    URLS.authorCreate.split("/")[URLS.authorCreate.split("/").length - 1]
  ] = " Create author";
  labels[URLS.authorEdit.split("/")[URLS.authorEdit.split("/").length - 1]] =
    " Edit author";

  labels[URLS.vehicles.split("/")[URLS.vehicles.split("/").length - 1]] =
    "Vehicles";
  labels[
    URLS.varientListing.split("/")[URLS.varientListing.split("/").length - 1]
  ] = "Varients";
  labels[
    URLS.priceListing.split("/")[URLS.priceListing.split("/").length - 1]
  ] = "Prices";
  labels[
    URLS.galleryListing.split("/")[URLS.galleryListing.split("/").length - 1]
  ] = "Gallery";

  labels[URLS.gallery.split("/")[URLS.gallery.split("/").length - 1]] =
    "Gallery";

  return labels;
};
