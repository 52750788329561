import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { Download, Trash } from "react-feather";
import { NavLink } from "react-router-dom";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import { SubDepartments } from "../../../../../utils/enums";
import HrmButton from "../../components/HrmButton";
import HrmLoader from "../../components/loading";
import { JobApplication } from "../../service/models/job_portal";
import {
  DeleteApplicantJobStatus,
  GetNonJobApplicants,
} from "../../service/repos/job_portal";

interface NonJobApplicantListProps {}

const NonJobApplicantList: FunctionComponent<NonJobApplicantListProps> = () => {
  const [jobs, setJobs] = useState<JobApplication[]>([]);
  const [filterJobs, setFilterJobs] = useState<JobApplication[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const tableHeadings = [
    "Name",
    "Email",
    "Mobile",
    "Department",
    "Applied on",
    "Actions",
  ];
  const { showToast } = useToast();

  const getJobData = async () => {
    setLoading(true);
    const res = await GetNonJobApplicants();

    if (res.success) {
      setJobs(res.data?.data);
      setFilterJobs(res.data?.data);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };

  const deleteApplicantData = async (id: string) => {
    const res = await DeleteApplicantJobStatus(id);

    if (res.success) {
      const tempAppl = filterJobs.filter((el) => el.id !== id);
      setFilterJobs(tempAppl);

      showToast({ type: ToastType.success, text: "Deleted successfully" });
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
  };

  const filterData = (dept: string) => {
    if (dept === "All") {
      setFilterJobs(jobs);
    } else {
      const tempJobs = jobs.filter((el) => el.department === dept);
      setFilterJobs(tempJobs);
    }
  };

  useEffect(() => {
    getJobData();
  }, []);

  return (
    <>
      <div className="">
        <div className="text-gray-500 font-semibold text-sm">Department</div>
        <select
          name=""
          id=""
          className="py-2 px-3 bg-white rounded-md border border-gray-300 focus:outline-none mt-1 text-sm"
          onChange={(e) => filterData(e.target.value)}
        >
          <option value="All">All</option>
          {Object.keys(SubDepartments).map((el, i) => {
            return (
              <option value={el} key={i}>
                {el}
              </option>
            );
          })}
        </select>
      </div>
      {loading ? (
        <HrmLoader className="w-8 h-8" />
      ) : (
        <div className=" bg-white mt-6">
          <div className="hidden lg:grid grid-cols-6 font-bold text-sm py-3 px-6  bg-gray-200 bg-opacity-90 rounded-t-xl">
            {tableHeadings.map((el, i) => {
              return (
                <div className="" key={i}>
                  {el}
                </div>
              );
            })}
          </div>
          {filterJobs.length === 0 ? (
            <div className="py-2 pl-3 font-semibold text-gray-400 text-sm">
              No data
            </div>
          ) : (
            <div className="job_applicant_wrapper overflow-y-auto">
              {filterJobs.map((el: JobApplication, i: number) => {
                return (
                  <div
                    className="grid grid-cols-1 lg:grid-cols-6 gap-x-4 mt-4 lg:mt-0 lg:gap-x-0 gap-y-2 lg:gap-y-0 text-black px-6 text-sm py-4 border-b hover:bg-hrmBlue hover:bg-opacity-5"
                    key={i}
                  >
                    <div className="">
                      <span className="lg:hidden">Name: </span> {el?.name}
                    </div>
                    <div className="">
                      <span className="lg:hidden">Email: </span>
                      {el?.email}
                    </div>
                    <div className="">
                      {" "}
                      <span className="lg:hidden">Mobile: </span> {el?.mobile}
                    </div>
                    <div className="">
                      <span className="lg:hidden">Department: </span>{" "}
                      {el?.department}
                    </div>
                    <div className="">
                      <span className="lg:hidden">Applied on: </span>
                      {el?.created_at
                        ? moment(el?.created_at).format("YYYY-MM-DD")
                        : ""}
                    </div>
                    <div className="flex items-center justify-center lg:justify-start gap-4">
                      <div className="">
                        <a href={el?.file} target="_blank" rel="noreferrer">
                          <HrmButton
                            type="primary"
                            text="Resume"
                            size="small"
                            className="py-1 px-1 rounded-full text-xs"
                          />
                        </a>
                      </div>
                      <div className="">
                        <Trash
                          size={20}
                          className="text-red-500 cursor-pointer"
                          onClick={() => {
                            const confirm = window.confirm(
                              "Are you sure you want to delete the job?"
                            );
                            if (confirm) {
                              deleteApplicantData(el.id);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default NonJobApplicantList;
