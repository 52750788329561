import { FunctionComponent, useEffect, useState } from "react";
import { AlertTriangle, Trash, X } from "react-feather";
import {
  DeleteWatchedVideo,
  GetCreateWatched,
} from "../../../../service/repos/academy/academy_repo";
import AcademyNavbar from "../components/Navbar";
import "../academy.css";
import { useAuth } from "../../../../context_providers/auth/authProvider";

interface WatchedVideosProps {}

const WatchedVideos: FunctionComponent<WatchedVideosProps> = () => {
  const [watchedVideos, setWatchedVideos] = useState<any>([]);
  const { user, isAuthorised } = useAuth();

  const getWatchedVideos = async () => {
    const res = await GetCreateWatched(user?.uid);
    console.log(res);

    if (res.success) {
      setWatchedVideos(res.data?.watched_videos);
    }
  };

  const deleteWatchedVideo = async (id: string) => {
    const res = await DeleteWatchedVideo(id, user?.uid!);

    if (res.success) {
      const temp = watchedVideos.filter((el: any) => el.id !== id);
      setWatchedVideos(temp);
    }
  };

  useEffect(() => {
    getWatchedVideos();
  }, []);

  return (
    <>
      <AcademyNavbar />
      <div className="bg-black h-96 flex flex-col items-center justify-center">
        <div className="text-white text-center">
          <div className="lg:text-4xl font-semibold">Watched videos</div>
          <div className="text-primaryColor mt-3 text-xl">
            Videos which you have marked as watch
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center">
        {watchedVideos.length > 0 ? (
          <div className="w-5/6 grid grid-cols-3 gap-x-8 gap-y-12 mt-12 mb-12">
            {watchedVideos.map((el: any, i: number) => {
              return (
                <div className="" key={i}>
                  <div className="h-64 bg-black relative">
                    <iframe
                      width="100%"
                      height="100%"
                      src={el?.video?.url.replace("watch?v=", "embed/")}
                      title="Youtube"
                    ></iframe>
                    {/* <div
                      className="absolute top-3 right-3 bg-hrmPurple p-2 rounded-full"
                      onClick={() => deleteSavedVideo(el.id)}
                    >
                      <X className="text-white" size={14} />
                    </div> */}
                  </div>
                  <div className="flex justify-between items-center">
                    <div className="">
                      <div className="mt-2 desc_wrapper">
                        {el?.video?.title}
                      </div>
                      <div className="text-sm text-secondaryColor desc_wrapper desc_wrapper">
                        {el?.video?.description}
                      </div>
                    </div>
                    <div className="">
                      <button
                        className="py-2 px-4 rounded text-white text-xs font-semibold bg-red-700"
                        onClick={() => {
                          const confirm = window.confirm(
                            "Are you sure you want to remove the saved video?"
                          );
                          if (confirm) {
                            deleteWatchedVideo(el.id);
                          }
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                  <div className="flex justify-between mt-3">
                    <div className="">Category</div>
                    <div className="text-gray-400">{el?.video?.category}</div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="pt-32 text-2xl">
            No saved videos{" "}
            <AlertTriangle size={32} className="ml-2 text-red-500 inline" />
          </div>
        )}
      </div>
    </>
  );
};

export default WatchedVideos;
