import { FunctionComponent, useEffect, useState } from "react";
import TabWidget from "../../../components/common/tab_widget";
import {
  OpsTaskSafetyChecklistA,
  OpsTaskSafetyChecklistB,
  OpsTaskSafetyChecklistC,
} from "../../../../utils/constant";
import Icon from "@mdi/react";
import { mdiCheckCircleOutline, mdiCloseCircleOutline } from "@mdi/js";
import { numIterator } from "../../../../utils/array_generator";

interface SafetyChecklistViewProps {
  editEnable: boolean;
  data?: {
    section_a: number[];
    section_b: number[];
    section_c: number[];
  };
  onSubmit: (data: {
    section_a: number[];
    section_b: number[];
    section_c: number[];
  }) => void;
}

const SafetyChecklistView: FunctionComponent<SafetyChecklistViewProps> = (
  props
) => {
  const [tab, setTab] = useState(0);
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState<{
    section_a: number[];
    section_b: number[];
    section_c: number[];
  }>({
    section_a: [],
    section_b: [],
    section_c: [],
    ...props.data,
  });

  const [seletedSection, SetSelectedSection] = useState<number[]>([]);
  const [curSection, SetCurSection] = useState<string[]>([]);
  const [allCheck, setAllCheck] = useState(false);
  useEffect(() => {
    SetCurSection(
      Object.values(
        tab == 0
          ? OpsTaskSafetyChecklistA
          : tab == 1
          ? OpsTaskSafetyChecklistB
          : OpsTaskSafetyChecklistC
      )
    );
    SetSelectedSection(
      Object.values(
        tab == 0 ? data.section_a : tab == 1 ? data.section_b : data.section_c
      )
    );
  }, [tab]);
  useEffect(() => {
    setData((o) => {
      if (tab == 0) {
        o.section_a = seletedSection;
      } else if (tab == 1) {
        o.section_b = seletedSection;
      } else {
        o.section_c = seletedSection;
      }
      return { ...o };
    });

    setAllCheck(seletedSection.length == curSection.length);
  }, [seletedSection]);
  console.log(props.editEnable);

  return (
    <div>
      <TabWidget
        tabs={["Section A", "Section B", "Section C"]}
        curIndex={tab}
        onClick={setTab}
      />
      <div className="grid grid-cols-8 gap-2  py-1 text-sm font-semibold bg-teal rounded text-white p-1">
        <div className="">Sl No</div>
        <div className="col-span-6">Description</div>
        <div className="flex justify-center">
          {edit && (
            <input
              className="h-4 w-4 rounded-full"
              type="checkbox"
              name=""
              id=""
              checked={allCheck}
              onChange={(e) => {
                if (e.target.checked) {
                  SetSelectedSection([...numIterator(curSection.length)]);
                } else {
                  SetSelectedSection([]);
                }
              }}
            />
          )}
        </div>
      </div>
      <div
        className="overflow-auto flex-col gap-3 divide-y"
        style={{ height: "63vh" }}
      >
        {curSection.map((f, i) => {
          const selected = seletedSection.includes(i);
          return (
            <div className="grid grid-cols-8 gap-2  py-1 text-sm">
              <div className="">{i + 1}</div>
              <div className="col-span-6 ">{f}</div>
              <div className=" flex justify-center items-start">
                {edit ? (
                  <input
                    className="h-4 w-4  rounded-full"
                    type="checkbox"
                    name=""
                    id=""
                    checked={selected}
                    onChange={(e) => {
                      SetSelectedSection((o) => {
                        if (selected) {
                          o.splice(i, 1);
                        } else {
                          o.push(i);
                        }
                        return [...o];
                      });
                    }}
                  />
                ) : (
                  <div>
                    <Icon
                      // path={mdiCheckCircleOutline}
                      path={
                        selected ? mdiCheckCircleOutline : mdiCloseCircleOutline
                      }
                      size={0.8}
                      className={`${
                        selected ? "text-green-500" : "text-red-500"
                      }`}
                    />
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>

      <div className="grid grid-cols-2 gap-5 p-2">
        {edit ? (
          <>
            <button
              onClick={() => setEdit(false)}
              className="border rounded-lg px-3 py-2"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                setEdit(false);
                props.onSubmit(data);
              }}
              className="border rounded-lg px-3 py-1 bg-teal text-white text-sm"
            >
              Submit
            </button>
          </>
        ) : (
          props.editEnable && (
            <>
              <div className=""></div>
              <button
                onClick={() => setEdit(true)}
                className="border rounded-lg px-3 py-1 bg-teal text-white text-sm"
              >
                Edit
              </button>
            </>
          )
        )}
      </div>
    </div>
  );
};

export default SafetyChecklistView;
