import "../styles/index.css";
import { useHistory } from "react-router-dom";
import { GetLeaveDataAll } from "../../../../service/repos/hrm/hrm_repo";
import { useEffect, useState } from "react";
import { ExportToCsv } from "export-to-csv";
import Layout from "../layout";
import {
  LeaveAvailableModel,
  LeaveTakenModel,
} from "../../../../service/models/user_model";
import HrmLoader from "../components/loading";
import { RefreshCw } from "react-feather";
import moment from "moment";
import LeaveTable from "./components/Table";
import "../styles/index.css";
import { GetEmpDetails } from "../../../../service/repos/users_repo";

const LeaveData = () => {
  const history = useHistory();
  const date = new Date();
  const [leaveData, setLeaveData] = useState([]);
  const [month, setMonth] = useState(date.getMonth() + 1);
  const [year, setYear] = useState(date.getFullYear());
  const [loading, setLoading] = useState(false);
  const [showRefresh, setShowRefresh] = useState<boolean>(false);

  const headings = [
    {
      subHeading: false,
      name: "Name",
    },
    // {
    //   subHeading: false,
    //   name: "Employee ID",
    // },

    {
      subHeading: true,
      name: "Earned leaves",
    },
    {
      subHeading: true,
      name: "Medical leaves",
    },
    {
      subHeading: true,
      name: "Work from home",
    },
    {
      subHeading: true,
      name: "Unpaid leaves",
    },
    {
      subHeading: false,
      name: "Actions",
    },
  ];

  const getEmployeeID = async (uid: string) => {
    const res = await GetEmpDetails(uid);
    if (res.success) {
      return res.data?.emp_profile?.emp_id;
    }
  };

  const getData = async (month: number, year: number) => {
    setLoading(true);
    const data = {
      month: month,
      year: year,
    };

    const res = await GetLeaveDataAll(data);
    console.log(res);

    if (res.success) {
      // for (let i = 0; i < res.data.length; i++) {
      //   const element = res.data[i];
      //   const empId = await getEmployeeID(element.uid);
      //   element.emp_id = empId;
      // }

      setLeaveData(res.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
    setShowRefresh(false);
  };

  const exportData = () => {
    const csvData = leaveData.map(
      (d: {
        uid: string;
        name: string;
        emp_id?: string;
        leave_available: LeaveAvailableModel;
        leave_taken: LeaveTakenModel;
        total_taken: {
          _id: string;
          carry_forward: number;
          paid_leaves: number;
          medical_leaves: number;
          restricted_leaves: number;
          work_from_home: number;
          unpaid_leaves: number;
        };
      }) => {
        return {
          emp_id: d.emp_id,
          name: d.name,
          totalEarnedEl:
            d.leave_available.paid_leaves + d.total_taken.paid_leaves,
          totalAvEl: d.leave_available.paid_leaves,
          totalTakenEl: d.total_taken.paid_leaves,
          elTakenThisMonth: d.leave_taken.paid_leaves,

          totalEarnedMl:
            d.leave_available.medical_leaves + d.total_taken.medical_leaves,
          totalAvMl: d.leave_available.medical_leaves,
          totalTakenMl: d.total_taken.medical_leaves,
          mlTakenThisMonth: d.leave_taken.medical_leaves,

          totalTakenUl: d.total_taken.unpaid_leaves,
          ulTakenThisMonth: d.leave_taken.unpaid_leaves,
        };
      }
    );
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: true,
      title: "Leave data csv",
      useTextFile: false,
      useBom: true,
      // useKeysAsHeaders: true,
      headers: [
        "Employee ID",
        "Name",
        "Total earned EL",
        "Total available EL",
        "Total taken EL",
        "EL taken this month",
        "Total earned ML",
        "Total available ML",
        "Total taken ML",
        "ML taken this month",
        "Total taken UL",
        "UL taken this month",
      ],
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(csvData);
  };

  useEffect(() => {
    getData(month, year);
  }, [month, year]);

  console.log(new Date(new Date(date).setMonth(date.getMonth() + 1)));

  return (
    <Layout pageTitle="Leaves data">
      <div className="pt-6 hidden lg:block">
        <div className="px-2 lg:px-6 ">
          <div className="flex flex-col items-end pr-6">
            <input
              type="month"
              min="2022-04"
              max={moment(
                new Date(new Date(date).setMonth(date.getMonth() + 1))
              ).format("YYYY-MM")}
              className="py-2 px-4 rounded-md focus:outline-none bg-white shadow-md"
              defaultValue={moment(date).format("YYYY-MM")}
              onChange={(e) => {
                const month = parseInt(e.target.value.slice(5, 7));
                const year = parseInt(e.target.value.slice(0, 4));
                setMonth(month);
                setYear(year);
              }}
            />
          </div>

          <div className="mt-8">
            {!loading && leaveData.length > 0 ? (
              <LeaveTable
                headings={headings}
                data={leaveData}
                showRefresh={() => setShowRefresh(true)}
              />
            ) : (
              <div className="flex flex-col items-center pt-32">
                <HrmLoader className="w-12 h-12 " />
              </div>
            )}
          </div>

          {/* <div className=" mt-6 lg:pr-4">
            <div className="">
              <TableHeader headingData={headings} />
            </div>

            {!loading ? (
              errorText === "" ? (
                <div className="text-primaryColor">
                  {data.map((item, i) => {
                    return (
                      <TableRow
                        data={item}
                        handleCsvLoading={() => setCsvLoading(true)}
                      />
                    );
                  })}
                </div>
              ) : (
                <div className="text-center mt-20 font-bold text-lg text-secondaryColor">
                  {errorText}
                </div>
              )
            ) : (
              <div className="flex flex-col items-center mt-20">
                <HrmLoader className="w-20 h-20" />
              </div>
            )}
          </div> */}
        </div>
        {!loading &&
          (showRefresh ? (
            <div
              className="bg-hrmRed py-2 px-12 cursor-pointer rounded text-white font-semibold fixed bottom-4 left-1/2"
              onClick={() => getData(month, year)}
            >
              Refresh
              <RefreshCw size={16} className="inline ml-3" />
            </div>
          ) : (
            <div
              className="bg-hrmBlue py-3 px-12 cursor-pointer rounded-md text-white font-semibold fixed bottom-4 left-1/2"
              onClick={() => exportData()}
            >
              Download CSV
            </div>
          ))}
      </div>
      <div className="lg:hidden pt-72 text-center">
        Please access this page from a desktop
      </div>
    </Layout>
  );
};

export default LeaveData;
