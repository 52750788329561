import gsap from "gsap/all";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import { SubDepartments } from "../../../../../utils/enums";
import { objectToFormdata } from "../../../../../utils/objectToFormData";
import { urls } from "../../../../../utils/urls";
import HrmLoader from "../../components/loading";
import Layout from "../../layout";
import {
  Experience,
  JobDetail,
  JobType,
} from "../../service/models/job_portal";
import { CreateJobPosting } from "../../service/repos/job_portal";
import { Editor, EditorState } from "draft-js";
import "draft-js/dist/Draft.css";
import { Trash } from "react-feather";

interface CreateJobProps {}

const defaultValue: JobDetail = {
  title: "",
  department: "",
  job_type: "",
  experience: "",
  position: 1,
  location: "",
  description: "",
};

const CreateJob: FunctionComponent<CreateJobProps> = () => {
  const [formData, setFormData] = useState(defaultValue);
  const [loading, setLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [otherLinks, setOtherLinks] = useState<string[]>([]);
  const editor = useRef(null);
  const [textInput, setTextInput] = useState<string>("");

  const addLink = (link: string) => {
    setOtherLinks((o) => [...o, link]);
  };

  const { showToast } = useToast();

  const deleteLink = (link: string) => {
    const tempOtherLink = otherLinks.filter((el) => el !== link);
    setOtherLinks(tempOtherLink);
  };

  const createJobPosting = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    setDisabled(true);
    const form: JobDetail = {
      title: formData.title,
      department: formData.department,
      job_type: formData.job_type,
      experience: formData.experience,
      position: formData.position,
      location: formData.location,
      otherLinks: otherLinks,
      description: formData.description,
    };

    const res = await CreateJobPosting(form);
    if (res.success) {
      setLoading(false);
      setShowSuccess(true);
    } else {
      setLoading(false);
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setDisabled(false);
  };

  const focusEditor = (editor: any) => {
    editor?.current?.focus();
  };

  useEffect(() => {
    focusEditor(editor);
  }, []);

  const animateData = () => {
    const tl = gsap.timeline();
    tl.fromTo(
      ".job_apply_left_card",
      0.5,
      {
        x: -500,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
      }
    ).fromTo(
      ".job_apply_right_card",
      0.5,
      {
        y: -300,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        delay: 0.3,
      }
    );
  };

  useEffect(() => {
    animateData();
  }, []);

  return (
    <Layout>
      <div className="py-12 lg:py-0">
        <div className="flex flex-col lg:flex-row gap-6 min-h-screen lg:p-4">
          <div className="job_apply_left_card  lg:w-1/3 hidden lg:flex flex-col items-center justify-center rounded-3xl relative bg-hrmBlue opacity-0">
            <div className="  font-bold absolute top-4 left-7 text-white ">
              Job Creation
            </div>
            <div className="w-4/5">
              <div className="mt-12 text-4xl font-bold text-white">
                Create a new Job <span className="mt-2 block"> Posting. </span>
              </div>
              <div className="mt-6 text-white text-opacity-90">
                Fill in the details to create a new job role for hiring new
                employees and making Kazam bigger and better.
              </div>
            </div>
          </div>

          {showSuccess ? (
            <div className="flex flex-col items-center justify-center w-full lg:w-2/3">
              <div className="">
                <div className="text-3xl font-semibold">
                  Job created successfully!
                </div>
                <div className="mt-2 flex flex-col items-center">
                  <NavLink to={urls.hrm_job_portal}>
                    <button className="text-white rounded py-2 px-6 bg-hrmBlue">
                      Go to job portal
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-full lg:w-2/3 pl-4 pr-4 lg:pr-12">
              {/* <div className="text-2xl font-bold">Job details</div> */}
              <div className="job_apply_right_card pt-3 opacity-0">
                <form
                  action=""
                  className="grid grid-cols-2 gap-x-12 gap-y-8"
                  onSubmit={createJobPosting}
                >
                  <div className="w-full col-span-2">
                    <label
                      htmlFor=""
                      className="text-gray-500 text-sm font-semibold "
                    >
                      Job title
                      <br />
                      <input
                        type="text"
                        className="w-full py-3 px-4 bg-white shadow-md focus:outline-none rounded-lg text-black font-semibold mt-1 focus:ring-2 focus:ring-blue-500"
                        required
                        disabled={disabled}
                        defaultValue={defaultValue.title}
                        onChange={(e) =>
                          setFormData((o) => ({
                            ...o,
                            title: e.target.value,
                          }))
                        }
                      />
                    </label>
                  </div>
                  <div className="w-full">
                    <label
                      htmlFor=""
                      className="text-gray-500 text-sm font-semibold "
                    >
                      Department
                      <br />
                      <select
                        className="w-full py-3 px-4 bg-white shadow-md focus:outline-none rounded-lg text-black font-semibold mt-1 cursor-pointer"
                        required
                        disabled={disabled}
                        defaultValue={defaultValue.department}
                        onChange={(e) =>
                          setFormData((o) => ({
                            ...o,
                            department: e.target.value,
                          }))
                        }
                      >
                        <option value="" hidden>
                          Select
                        </option>
                        {Object.keys(SubDepartments).map((el) => {
                          return <option value={el}>{el}</option>;
                        })}
                      </select>
                    </label>
                  </div>
                  <div className="w-full">
                    <label
                      htmlFor=""
                      className="text-gray-500 text-sm font-semibold "
                    >
                      Job type
                      <br />
                      <select
                        className="w-full py-3 px-4 bg-white shadow-md focus:outline-none rounded-lg text-black font-semibold mt-1 cursor-pointer"
                        required
                        disabled={disabled}
                        defaultValue={defaultValue.job_type}
                        onChange={(e) =>
                          setFormData((o) => ({
                            ...o,
                            job_type: e.target.value,
                          }))
                        }
                      >
                        <option value="" hidden>
                          Select
                        </option>
                        {Object.keys(JobType).map((el) => {
                          return (
                            <option value={JobType[el]} className="">
                              {JobType[el]}
                            </option>
                          );
                        })}
                      </select>
                    </label>
                  </div>

                  <div className="w-full">
                    <label
                      htmlFor=""
                      className="text-gray-500 text-sm font-semibold "
                    >
                      Experience
                      <br />
                      <select
                        className="w-full py-3 px-4 bg-white shadow-md focus:outline-none rounded-lg text-black font-semibold mt-1 cursor-pointer"
                        required
                        disabled={disabled}
                        defaultValue={defaultValue.experience}
                        onChange={(e) =>
                          setFormData((o) => ({
                            ...o,
                            experience: e.target.value,
                          }))
                        }
                      >
                        <option value="" hidden>
                          Select
                        </option>
                        {Object.keys(Experience).map((el) => {
                          return (
                            <option value={Experience[el]} className="">
                              {Experience[el]}
                            </option>
                          );
                        })}
                      </select>
                    </label>
                  </div>
                  <div className="w-full">
                    <label
                      htmlFor=""
                      className="text-gray-500 text-sm font-semibold "
                    >
                      Position
                      <br />
                      <input
                        type="number"
                        className="w-full py-3 px-4 bg-white shadow-md focus:outline-none rounded-lg text-black font-semibold mt-1 focus:ring-2 focus:ring-blue-500"
                        required
                        disabled={disabled}
                        defaultValue={defaultValue.position}
                        onChange={(e) =>
                          setFormData((o) => ({
                            ...o,
                            position: parseInt(e.target.value),
                          }))
                        }
                      />
                    </label>
                  </div>
                  <div className="w-full col-span-2">
                    <label
                      htmlFor=""
                      className="text-gray-500 text-sm font-semibold "
                    >
                      Location
                      <br />
                      <select
                        className="w-full py-3 px-4 bg-white shadow-md focus:outline-none rounded-lg text-black font-semibold mt-1 cursor-pointer"
                        required
                        disabled={disabled}
                        defaultValue={defaultValue.location}
                        onChange={(e) =>
                          setFormData((o) => ({
                            ...o,
                            location: e.target.value,
                          }))
                        }
                      >
                        <option value="" hidden>
                          Select
                        </option>
                        {[
                          "Bangalore",
                          "Chandigarh",
                          "Gurugram",
                          "Kolkata",
                          "Lucknow",
                          "Pune",
                        ].map((el) => {
                          return (
                            <option value={el} className="">
                              {el}
                            </option>
                          );
                        })}
                      </select>
                    </label>
                  </div>

                  <div className="w-full col-span-2">
                    <label
                      htmlFor=""
                      className="text-gray-500 text-sm font-semibold "
                    >
                      Description
                      <br />
                      <textarea
                        rows={6}
                        className="w-full py-3 px-4 bg-white shadow-md focus:outline-none rounded-lg text-black font-semibold mt-1 focus:ring-2 focus:ring-blue-500"
                        required
                        disabled={disabled}
                        defaultValue={defaultValue.description}
                        onChange={(e) => {
                          setFormData((o) => ({
                            ...o,
                            description: e.target.value,
                          }));
                        }}
                      />
                    </label>
                  </div>

                  <div className="col-span-2">
                    <div className="text-gray-500 text-sm font-semibold">
                      Job portal links
                    </div>
                    <div className="bg-white ">
                      {otherLinks.length > 0 &&
                        otherLinks.map((el: string) => {
                          return (
                            <div className="my-2 flex items-center justify-between  border-b border-gray-300 py-1 px-3 text-sm ">
                              <div className="text-gray-500 font-semibold">
                                {el}
                              </div>
                              <div className="">
                                <Trash
                                  className="text-red-700 cursor-pointer"
                                  size={16}
                                  onClick={() => deleteLink(el)}
                                />
                              </div>
                              {/* <div className="py-1 px-3 rounded-full bg-red-600 text-sm text-white font-semibold">
                                Delete
                              </div> */}
                            </div>
                          );
                        })}
                    </div>
                    <LinkInput addLink={addLink} />
                  </div>

                  {!loading ? (
                    <div className="w-full flex flex-col items-end col-span-2">
                      <button className="py-3 px-8 rounded-lg bg-hrmBlue text-white font-bold">
                        Create job posting
                      </button>
                    </div>
                  ) : (
                    <div className="flex flex-col items-end col-span-2">
                      <HrmLoader className="w-12 h-12" />
                    </div>
                  )}
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default CreateJob;

interface LinkInputProps {
  addLink: (link: string) => void;
}

const LinkInput: FunctionComponent<LinkInputProps> = ({ addLink }) => {
  const [link, setLink] = useState<string>("");
  return (
    <div className="flex items-center gap-2">
      <div className="w-11/12">
        <input
          type="text"
          className="w-full py-2 px-4 bg-white shadow-md focus:outline-none rounded-lg text-black font-semibold mt-1 focus:ring-2 focus:ring-blue-500"
          placeholder="Linkedin job url, Hirist Job url etc"
          value={link}
          onChange={(e) => {
            setLink(e.target.value);
          }}
        />
      </div>
      {link !== "" && (
        <div
          className="py-2 rounded-full bg-hrmBlue w-1/12 flex flex-col items-center justify-center text-white font-semibold text-sm cursor-pointer"
          onClick={() => {
            setLink("");
            addLink(link);
          }}
        >
          Add
        </div>
      )}
    </div>
  );
};
