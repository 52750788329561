import { FunctionComponent } from "react";
import { InputFields } from "./form";
export interface ZipInputPropsProps {
  alignment?: string;
  name: string;
  label: string;
  value: any;
  defaultValue?: any;

  placeholder?: string;
  inputChange?: (data: any) => void;
  disabled?: boolean;
  required?: boolean;
}

const ZipInput: FunctionComponent<ZipInputPropsProps> = (props) => {
  const {
    alignment,
    defaultValue,
    name,
    label,
    value,
    placeholder,
    inputChange,
    disabled = false,
    required = false,
  } = props;
  return (
    <div
      className={`grid ${alignment === "row" ? "grid-cols-3" : "grid-col-1"}`}
    >
      <label htmlFor={name} className="w">
        {label}
      </label>
      <input
        className="focus:outline-none p-2 bg-gray-100 rounded col-span-2"
        id={name}
        name={name}
        value={value}
        defaultValue={defaultValue}
        onChange={(e) => {
          if (inputChange) inputChange(e.target.value);
        }}
        type="number"
        placeholder={placeholder ?? name}
        disabled={disabled}
        required={required}
        pattern="[0-9]{6}"
        title="Please enter a 6 digit pincode"
      />
    </div>
  );
};
export default ZipInput;
