import { debounce } from "lodash";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import {
  useToast,
  ToastType,
} from "../../../../../context_providers/toast/toast_ctx";
import { CustomerDetailModel } from "../../../../../service/models/orders/customer_detail_model";
import { GetCustomersOfOrderApi } from "../../../../../service/repos/order_repo";
import AutoCompleteField from "../../../../new_components/common/autocomplete";
import { getComplaints } from "../../../../../service/repos/support/complaint_repo";

interface ComplaintDropDownProps {
  onSelect: (val: { id: string }) => void;
  placeholder?: string;
  id?: string;
  disabled?: boolean;
}

const ComplaintDropDown: FunctionComponent<ComplaintDropDownProps> = ({
  id,
  onSelect,
  placeholder,

  disabled = false,
}) => {
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState("");
  const [edit, setEdit] = useState(false);
  useEffect(() => {
    setSearch(id || "");
  }, [id]);
  useEffect(() => {
    if (search) {
      if (id && id === search) return;
      debouncedHandler(search);
      // debounce(() => {
      //   searchEmp();
      // });
    }
  }, [search]);
  const [data, setData] = useState<
    {
      id: string;
    }[]
  >([]);

  const searchHost = async (str: string) => {
    setLoading(true);
    const res = await getComplaints({
      search: str,
      page: 1,
      count: 100,
      all: true,
    });

    if (res.success) {
      setData(res.data.complaints);
    } else {
      setData([]);
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };

  const debouncedHandler = useCallback(
    debounce((query) => {
      if (query) searchHost(query);
    }, 500),
    []
  );
  // useEffect(() => {
  //   if (search) {
  //     debouncedHandler(search);
  //   } else setData([]);
  // }, [search]);
  return (
    <div className="flex gap-1 items-center    w-full">
      {edit ? (
        <AutoCompleteField
          loading={loading}
          value={search}
          placeholder={placeholder ?? "search order id"}
          onSelect={(val) => {
            setEdit(false);
            setSearch(val.value.id);
            onSelect(val.value);
          }}
          suggestions={data.map((e) => ({
            label: (
              <div
                style={{ fontSize: "10px" }}
                className=" flex flex-col  flex-wrap border-b"
              >
                <b> {e.id} </b>
              </div>
            ),

            value: e,
          }))}
          onChange={(str) => {
            setSearch(str);
          }}
        />
      ) : (
        <div
          className="text-sm px-3 py-2  h-8 rounded focus:outline-none w-full border"
          onClick={() => {
            if (!disabled) {
              setEdit(true);
              // setSearch(emp?.name ?? "");
            }
          }}
        >
          {search || placeholder}
        </div>
      )}
    </div>
  );
};

export default ComplaintDropDown;
