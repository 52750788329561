import {
  mdiCheck,
  mdiChevronDown,
  mdiChevronUp,
  mdiClose,
  mdiDelete,
  mdiPencil,
  mdiPlus,
} from "@mdi/js";
import Icon from "@mdi/react";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import OrderItemModel from "../../../../../service/models/orders/order_item_model";
import ProductModel, {
  OrderItemDataModel,
} from "../../../../../service/models/product_model";
import { currencyFormat } from "../../../../../utils/orders_utils";
const defaultItem: OrderItemModel = {
  product_id: 0,
  name: "",
  price: 0,
  qty: 0,
  delivered_qty: 0,
  installed_qty: 0,
  returned_qty: 0,
};
interface ProductRowWidgetProps {
  item?: OrderItemModel;
  products: ProductModel[] | undefined;
  onSubmit: (data: OrderItemModel) => void;
  edit: boolean;
  disabled: boolean;
  onDelete?: (i: OrderItemModel) => void;
  // form_id?: string;
}

const ProductRowWidget: FunctionComponent<ProductRowWidgetProps> = (props) => {
  const { showToast } = useToast();
  const [showMore, setShowMore] = useState(false);
  const { products, edit, onSubmit } = props;
  const [item, setItem] = useState<OrderItemModel>({
    ...(props.item ?? defaultItem),
  });
  const [editable, setEditable] = useState(false);
  useEffect(() => {
    setEditable(edit);
  }, []);
  const onDone = () => {
    if (!item.product_id || item.qty < 1) {
      showToast({
        type: ToastType.warning,
        text: "Please select product and Qty",
      });
      return;
    }
    onSubmit(item);
  };
  return (
    <>
      <form
        // id={props.form_id}
        onSubmit={(e) => {
          e.preventDefault();
          onDone();
        }}
        className="bg-white rounded-md shadow   "
      >
        <div className="grid grid-cols-11 gap-3 p-5">
          <div className="">{item.id}</div>
          <select
            disabled={!editable || props.disabled}
            value={JSON.stringify({
              product_id: item?.product_id,
              name: item?.name,
            })}
            onChange={(e) => {
              const p = JSON.parse(e.target.value);

              setItem((ol) => ({ ...ol, ...p }));
            }}
            id="product"
            name="product_id"
            className=" p-1 rounded bg-gray-100 focus:outline-none    col-span-2"
          >
            <option value="" hidden>
              Select...
            </option>
            {products?.map((item: ProductModel, i: number) => (
              <option
                key={i}
                value={JSON.stringify({ product_id: item.id, name: item.name })}
              >
                {item.name}
              </option>
            ))}
          </select>

          <input
            value={item?.qty}
            onChange={(e) => {
              let v = 0;
              if (e.target.value && !isNaN(e.target.value as any)) {
                v = parseInt(e.target.value);
              }

              setItem((ol) => ({ ...ol, qty: v }));
            }}
            disabled={!editable || props.disabled}
            placeholder=""
            type="number"
            className="text-right  p-1 rounded bg-gray-100 focus:outline-none"
          />
          <input
            value={item?.price}
            onChange={(e) => {
              let v = 0;
              if (e.target.value && !isNaN(e.target.value as any)) {
                v = Number(e.target.value);
              }
              setItem((ol) => ({ ...ol, price: v }));
            }}
            disabled={!editable || props.disabled}
            // disabled={item.id ? true : !editable || props.disabled}
            // disabled={true}
            placeholder=""
            type="number"
            className="text-right  p-1 rounded bg-gray-100 focus:outline-none"
          />
          <input
            value={item?.delivered_qty}
            onChange={(e) => {
              let v = 0;
              if (e.target.value && !isNaN(e.target.value as any)) {
                v = Number(e.target.value);
              }
              setItem((ol) => ({ ...ol, delivered_qty: v }));
            }}
            // disabled={!editable || props.disabled}
            disabled={true}
            placeholder=""
            type="text"
            className="text-right  p-1 rounded bg-gray-100 focus:outline-none"
          />
          <input
            value={item?.installed_qty}
            onChange={(e) => {
              let v = 0;
              if (e.target.value && !isNaN(e.target.value as any)) {
                v = Number(e.target.value);
              }
              setItem((ol) => ({ ...ol, installed_qty: v }));
            }}
            // disabled={!editable || props.disabled}
            disabled={true}
            placeholder=""
            type="text"
            className="text-right  p-1 rounded bg-gray-100 focus:outline-none"
          />
          <input
            value={item?.returned_qty}
            onChange={(e) => {
              let v = 0;
              if (e.target.value && !isNaN(e.target.value as any)) {
                v = Number(e.target.value);
              }
              setItem((ol) => ({ ...ol, returned_qty: v }));
            }}
            // disabled={!editable || props.disabled}
            disabled={true}
            placeholder=""
            type="text"
            className="text-right  p-1 rounded bg-gray-100 focus:outline-none"
          />
          <input
            value={(item?.delivered_qty ?? 0) - (item?.returned_qty ?? 0)}
            disabled={true}
            placeholder=""
            type="text"
            className="text-right  p-1 rounded bg-gray-100 focus:outline-none"
          />
          <input
            value={(item?.qty ?? 0) * (item?.price ?? 0)}
            disabled={true}
            placeholder=""
            type="text"
            className="text-right  p-1 rounded bg-gray-100 focus:outline-none"
          />
          <div className="flex gap-2 items-center justify-end">
            {props.item ? (
              !props.disabled && (
                <>
                  <div className="flex justify-center gap-2">
                    {editable ? (
                      <>
                        <div
                          onClick={() => {
                            if (!window.confirm("Are you sure !")) return;
                            onDone();
                            setEditable(false);
                          }}
                          className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                        >
                          <Icon path={mdiCheck} className="h-4 w-4"></Icon>
                        </div>
                        <div
                          onClick={() => {
                            setItem(props.item!);
                            setEditable(false);
                          }}
                          className="bg-red-100 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                        >
                          <Icon path={mdiClose} className="h-4 w-4"></Icon>
                        </div>
                      </>
                    ) : (
                      <div
                        onClick={() => setEditable(true)}
                        className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                      >
                        <Icon path={mdiPencil} className="h-4 w-4"></Icon>
                      </div>
                    )}
                  </div>

                  <button
                    title="Delivered qty should be 0"
                    type="button"
                    disabled={(item?.delivered_qty ?? 0) > 0}
                    onClick={() => {
                      if (!window.confirm("Are you sure !")) return;
                      if (props.onDelete) props.onDelete(item);
                    }}
                    className={` p-1 rounded  hover:scale-110 transform duration-200 ${
                      (item?.delivered_qty ?? 0) > 0
                        ? "text-gray-500 bg-gray-100"
                        : "bg-red-200 text-red-800"
                    }`}
                  >
                    <Icon path={mdiDelete} className="h-4 w-4"></Icon>
                  </button>
                </>
              )
            ) : (
              <button
                // form="item_form"
                className="flex gap-1 text-white text-sm bg-myPrimaryColor rounded-full px-2 py-1 hover:scale-110 transform duration-300"
              >
                <Icon path={mdiPlus} className="h-5 w-5 "></Icon> Add
              </button>
            )}
            {/* {props.item && (
              <div
                onClick={() => setShowMore((o) => !o)}
                className="p-1 bg-gray-100 rounded cursor-pointer hover:bg-gray-200"
              >
                <Icon
                  path={showMore ? mdiChevronUp : mdiChevronDown}
                  className="h-4 w-4"
                ></Icon>
              </div>
            )} */}
          </div>
        </div>
        {showMore && props.item && (
          <div className="px-2 py-1">
            <hr className=" " />
            <div className="text-xs text-gray-400">
              <p>Id : {props.item.id}</p>
              <p>Extra : {currencyFormat(props.item.addition_amt ?? 0)}</p>
              <p>Discount : {currencyFormat(props.item.discount_amt ?? 0)}</p>
              <p>Paid : {currencyFormat(props.item.paid ?? 0)}</p>
            </div>
            <div className="flex   justify-between gap-2 my-1">
              <p className="text-xs ">
                Created by :<br />
                <div className="flex gap-1">
                  <div className="">
                    <b>{props.item.created_by_name ?? "N/A"}</b>
                  </div>
                  <div className="">
                    {props.item.created_at
                      ? moment(props.item.created_at).format(
                          "DD MMM YYYY h:mm a"
                        )
                      : "--"}
                  </div>
                </div>
              </p>
              <p className="text-xs ">
                Updated by :<br />
                <div className="flex gap-1">
                  <div className="">
                    <b>{props.item.updated_by_name ?? "N/A"}</b>
                  </div>
                  <div className="">
                    {props.item.updated_at
                      ? moment(props.item.updated_at).format(
                          "DD MMM YYYY h:mm a"
                        )
                      : "--"}
                  </div>
                </div>
              </p>
            </div>
          </div>
        )}
      </form>
    </>
  );
};

export default ProductRowWidget;
