import { FunctionComponent } from "react";

interface ModalDialogProps {
  show: boolean;
  title: string;
  onClose: () => void;
}

const ModalDialog: FunctionComponent<ModalDialogProps> = (props) => {
  return (
    <div className="relative">
      <div
        className={`fixed top-0 left-0  z-50  w-screen h-screen  bg-gray-400 bg-opacity-50 ${
          props.show ? "block" : "hidden"
        }`}
      >
        <div className="flex h-full w-full justify-center items-center">
          <div
            className={` mx-auto   border  shadow-lg rounded-xl bg-white zoom_wrapper`}
          >
            {" "}
            <div className="flex justify-center mt-2">
              <div className="w-20 h-1 bg-gray-400 rounded-lg"></div>
            </div>
            <div
              className="flex justify-between items-center    px-2 rounded-t"
              style={{ maxHeight: "90%" }}
            >
              <div className="mx-3 text-lg  font-semibold">{props.title}</div>
              <div
                className="p-1   rounded-full cursor-pointer   hover:text-gray-400"
                onClick={props.onClose}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6  "
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
            </div>
            {/* <hr /> */}
            <div style={{ maxHeight: "90%" }} className="overflow-y-auto p-5">
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalDialog;
