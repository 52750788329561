import { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import LoadingWidget from "../../../context_providers/modal/loader";
import {
  ToastType,
  useToast,
} from "../../../context_providers/toast/toast_ctx";
import { fetchGet, fetchPost, fetchPut } from "../../../service/api_client";

import {
  WAREHOUSE_TYPE,
  warehouseDefaultValue,
  WarehouseModel,
} from "../models/warehouse_model";
import { urls } from "../../../utils/urls";
import EmployeeSearchWidget from "../../../ui/new_components/emp_search_widget";
import { TaxDetail } from "../../accounts/models/party_model";
import DoneWidget from "../../../ui/new_components/common/done";
import PartySearchField from "../../accounts/parties/party_search_field";

interface InventoryBasketCreateScreenProps {
  edit: boolean;
  onClose: (id?: WarehouseModel) => void;
  data?: WarehouseModel;
}

const InventoryBasketCreateScreen: FunctionComponent<
  InventoryBasketCreateScreenProps
> = (props) => {
  const isUpdatePage = useRouteMatch(
    `${urls.procurement_masters_inventory_bucket}/update/:id`
  );
  const { id } = useParams<{ id: string }>();
  const urlParty = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/accounts/party`;
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/warehouse_type`;
  const [showSuccess, setShowSucess] = useState(false);
  const { showToast } = useToast();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { edit } = props;
  const [editable, setEditable] = useState(false);
  const [data, setData] = useState<WarehouseModel>(
    props.data || ({} as WarehouseModel)
  );
  const [addNew, setAddNew] = useState(false);

  const defaultWarehouseModel: WarehouseModel = warehouseDefaultValue;

  const handleOptionChange = (value: WarehouseModel["type"]) => {
    setData((pv) => ({ ...pv, type: value }));
  };

  const handleTaxChange = (value: TaxDetail["reg_type"]) => {
    setData((pv) => ({
      ...pv,
      tax_detail: { ...pv.tax_detail!, reg_type: value },
    }));
  };

  const submit = async () => {
    try {
      console.log(data);
      if (!data.name || !data.type) {
        showToast({
          type: ToastType.error,
          text: "Please fill all the required details",
        });
        return;
      }

      setLoading(true);

      const res = data.id
        ? await fetchPut(`${url}/${data.id}`, data)
        : await fetchPost(url, data);
      console.log({ res });
      if (res.success) {
        showToast({ type: ToastType.success, text: res.message });
        if (!data.id) {
          setData(defaultWarehouseModel);
          setShowSucess(true);
        }
        setData((prevData) => ({
          ...prevData,
          ...res.data, // Update with the response data
        }));
        if (props.onClose) props.onClose(res.data);
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      showToast({ type: ToastType.error, text: "An error occurred" });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setEditable(edit);
    if (id && isUpdatePage) {
      getData(id);
    }
  }, []);

  useEffect(() => {
    // Initialize form with existing data if editing
    if (edit && props.data) {
      setData(props.data);
    }
  }, [edit, props.data]);

  const getData = async (id: string) => {
    try {
      setLoading(true);
      const res = await fetchGet(`${url}/${id}`);
      if (res.success) {
        console.log(res.data);
        const updatedData = { ...res.data, type: res.data.type }; // Update type property
        setData(updatedData); // Update state with merged data
      } else {
        console.log("Invalid");
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error) {
      showToast({ type: ToastType.error, text: "An error occurred" });
    } finally {
      setLoading(false);
    }
  };

  if (showSuccess)
    return (
      <div className="w-full h-full flex flex-col items-center justify-center">
        <div className="w-80 h-80 p-0">
          <DoneWidget />
        </div>
        <div className="flex flex-col gap-2 justify-start items-center">
          <h1 className="text-3xl font-semibold text-gray-400 ">
            Created successfully
          </h1>
          <span className="text-black italic font-semibold">{data.id}</span>
          <div className="flex gap-5">
            <button
              onClick={() => {
                history.push(
                  urls.procurement_masters_inventory_bucket +
                    "/update/" +
                    data.id
                );
                setShowSucess(false);
              }}
              className="border rounded-3xl px-5 py-2 text-green-800 border-green-400"
            >
              Continue
            </button>

            <button
              onClick={() => {
                setData({
                  ...defaultWarehouseModel,
                  type: data.type,
                });

                setShowSucess(false);
              }}
              className="border rounded-3xl px-4 py-2 bg-green-400 text-white  "
            >
              Create new
            </button>
          </div>
        </div>
      </div>
    );

  return (
    <>
      <div className="grid grid-cols-1 m-5 gap-2 select-none text-sm">
        <h1 className="text-xl font-bold border-l-4 border-myPrimaryColor pl-2">
          warehouse/office {id ? "update" : "create"}
        </h1>
        <div className="grid grid-cols-2 m-5 gap-2 select-none">
          <div className="p-5 md:p-10 flex flex-col gap-5 shadow bg-white rounded-lg">
            <div className="flex flex-col items-start text-sm w-full">
              <label className="font-semibold w-full" htmlFor="name">
                Warehouse Name*
              </label>
              <input
                placeholder="Enter Name"
                className="p-1 rounded focus:outline-none w-full border"
                id="name"
                name="name"
                value={data.name || ""}
                required
                type="text"
                onChange={(e) => setData({ ...data, name: e.target.value })}
              />
            </div>

            <div className="flex flex-col items-start text-sm w-full">
              <label className="font-semibold w-full" htmlFor="desc">
                Description
              </label>
              <textarea
                placeholder="Short description"
                className="p-1 rounded focus:outline-none w-full border"
                id="description"
                name="description"
                value={data.description || ""}
                onChange={(e) =>
                  setData((d) => ({ ...d, description: e.target.value }))
                }
              ></textarea>
            </div>

            <div className="grid grid-cols-2 gap-5 items-end">
              <div className="flex flex-col items-start text-sm w-full">
                <label className="font-semibold w-full" htmlFor="type">
                  Type*
                </label>
                <select
                  value={data.type}
                  onChange={(e) => {
                    handleOptionChange(
                      e.target.value as WarehouseModel["type"]
                    );
                    setData({
                      ...data,
                      type: e.target.value as WarehouseModel["type"],
                    });
                  }}
                  className="p-1 rounded focus:outline-none w-full border"
                >
                  <option value="" hidden>
                    Select
                  </option>
                  <option value={WAREHOUSE_TYPE.warehouse}>Warehouse</option>
                  <option value={WAREHOUSE_TYPE.transit}>Transit</option>
                </select>
              </div>
            </div>
          </div>
          {data.type === "transit" && (
            <div className="p-5 md:p-10 flex flex-col gap-5 shadow bg-white  rounded-lg">
              <div className="">
                <h4 className="font-bold text-center mb-5">
                  SPOC Details to Track{" "}
                </h4>
                <div className="grid grid-cols-2 gap-5 items-end">
                  <div className="flex flex-col items-start text-sm w-full">
                    <label className="font-semibold w-full" htmlFor="line1">
                      User Name
                    </label>
                    <PartySearchField
                      show_add={true}
                      placeholder={data.spoc?.contact_name}
                      onSelect={(e) => {
                        setData((prevData) => ({
                          ...prevData,
                          spoc: {
                            ...prevData.spoc!,
                            contact_name: e.name,
                            contact_email: e.mail_detail.contact_email,
                            contact_mobile:e.mail_detail.contact_mobile
                          },
                        }));
                      }}
                    />

                  </div>
                  <div className="flex flex-col items-start text-sm w-full">
                    <label className="font-semibold w-full" htmlFor="line1">
                      Phone Number
                    </label>
                    <input
                      placeholder="phone number"
                      className="p-1 rounded focus:outline-none w-full border"
                      id="transitPhoneNumber"
                      name="transitPhoneNumber"
                      value={data.spoc?.contact_mobile}
                      onChange={(e) =>
                        setData((prevData) => ({
                          ...prevData,
                          spoc: {
                            ...prevData.spoc!,
                            contact_mobile: e.target.value,
                          },
                        }))
                      }
                    />
                  </div>
                  <div className="flex flex-col items-start text-sm w-full">
                    <label className="font-semibold w-full" htmlFor="line1">
                      Email{" "}
                    </label>
                    <input
                      placeholder="email"
                      className="p-1 rounded focus:outline-none w-full border"
                      id="email"
                      name="email"
                      value={data.spoc?.contact_email}
                      onChange={(e) =>
                        setData((prevData) => ({
                          ...prevData,
                          spoc: {
                            ...prevData.spoc!,
                            contact_email: e.target.value,
                          },
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {data.type === "warehouse" && (
            <div className="p-5 md:p-10 flex flex-col gap-5 shadow bg-white  rounded-lg">
              <div>
                <div className="">
                  <h4 className="font-bold text-center">Tax Details </h4>
                </div>
                <div>
                  <label className="font-semibold w-full" htmlFor="pan">
                    PAN Number
                  </label>
                  <input
                    className="p-1 rounded focus:outline-none w-full border"
                    name="pan"
                    id="pan"
                    placeholder="Enter PAN Number"
                    value={data.tax_detail?.pan || ""}
                    onChange={(e) =>
                      setData((prevData) => ({
                        ...prevData,
                        tax_detail: {
                          ...prevData.tax_detail!,
                          pan: e.target.value,
                        },
                      }))
                    }
                  />
                </div>
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="type">
                    Type*
                  </label>
                  <select
                    value={data.tax_detail?.reg_type || "Unknown"}
                    onChange={(e) => {
                      handleTaxChange(e.target.value as TaxDetail["reg_type"]);
                      setData((prevData) => ({
                        ...prevData,
                        tax_detail: {
                          ...prevData.tax_detail!,
                          reg_type: e.target.value as TaxDetail["reg_type"],
                        },
                      }));
                    }}
                    className="p-1 rounded focus:outline-none w-full border"
                  >
                    <option value="Unknown">Unknown</option>
                    <option value="Composition">Composition</option>
                    <option value="Consumer">Consumer</option>
                    <option value="Regular">Regular</option>
                    <option value="Unregistered">Unregistered</option>
                  </select>
                </div>
                <div>
                  <label className="font-semibold w-full" htmlFor="gst_no">
                    GST Number
                  </label>
                  <input
                    className="p-1 rounded focus:outline-none w-full border"
                    name="gst_no"
                    id="gst_no"
                    placeholder="Enter GST No"
                    value={data.tax_detail?.gst_no || ""}
                    onChange={(e) =>
                      setData((prevData) => ({
                        ...prevData,
                        tax_detail: {
                          ...prevData.tax_detail!,
                          gst_no: e.target.value,
                        },
                      }))
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        {/* Address Fields */}
        {data.type === "warehouse" && (
          <div className="grid grid-cols-2 m-5 gap-2 select-none">
            <div className="p-5 md:p-10 flex flex-col gap-5 shadow bg-white  rounded-lg">
              <div className="">
                <h4 className="font-bold text-center mb-5">Details </h4>
                <div className="">
                  <div className="flex flex-col items-start text-sm w-full">
                    <label className="font-semibold w-full" htmlFor="line1">
                      User Name/SPOC
                    </label>
                    <EmployeeSearchWidget
                      withBtn={false}
                      onSelect={(emp) => {
                        setData((pv) => {
                          return {
                            ...pv!,
                            spoc: {
                              ...pv.spoc,
                              uid: emp.uid,
                              contact_name:
                                emp.first_name + " " + (emp.last_name ?? ""),
                              contact_mobile: emp.mobile,
                              contact_email: emp.email,
                              department: emp.emp_profile?.department,
                            },
                          };
                        });
                      }}
                      emp={
                        data.spoc?.uid
                          ? {
                              uid: data.spoc.uid!,
                              name: data.spoc.contact_name,
                            }
                          : undefined
                      }
                    />
                  </div>
                  <div className="flex flex-col items-start text-sm w-full">
                    <label className="font-semibold w-full" htmlFor="line1">
                      Contact Number
                    </label>
                    <input
                      placeholder="Phone"
                      className="p-1 rounded focus:outline-none w-full border"
                      id="phone"
                      name="phone"
                      value={data.spoc?.contact_mobile}
                      onChange={(e) =>
                        setData((prevData) => ({
                          ...prevData,
                          spoc: {
                            ...prevData.spoc!,
                            contact_mobile: e.target.value,
                          },
                        }))
                      }
                    />
                  </div>
                  <div className="flex flex-col items-start text-sm w-full">
                    <label className="font-semibold w-full" htmlFor="line1">
                      Contact Email
                    </label>
                    <input
                      placeholder="Email"
                      className="p-1 rounded focus:outline-none w-full border"
                      id="gmail"
                      name="gmail"
                      value={data.spoc?.contact_email}
                      onChange={(e) =>
                        setData((prevData) => ({
                          ...prevData,
                          spoc: {
                            ...prevData.spoc!,
                            contact_email: e.target.value,
                          },
                        }))
                      }
                    />
                  </div>
                  <div className="flex flex-col items-start text-sm w-full">
                    <label className="font-semibold w-full" htmlFor="line1">
                      Department
                    </label>
                    <input
                      placeholder="department"
                      className="p-1 rounded focus:outline-none w-full border"
                      id="dept"
                      name="dept"
                      value={data.spoc?.department}
                      onChange={(e) =>
                        setData((prevData) => ({
                          ...prevData,
                          spoc: {
                            ...prevData.spoc!,
                            department: e.target.value,
                          },
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="p-5 md:p-10 flex flex-col gap-5 shadow bg-white  rounded-lg">
              <div className="">
                <h4 className="font-bold text-center mb-5">Address </h4>
                <div className="grid grid-cols-2 gap-5 items-end">
                  <div className="flex flex-col items-start text-sm w-full">
                    <label className="font-semibold w-full" htmlFor="line1">
                      Address Line 1
                    </label>
                    <input
                      placeholder="Enter address line 1"
                      className="p-1 rounded focus:outline-none w-full border"
                      id="line1"
                      name="line1"
                      value={data.address?.line1 || ""}
                      onChange={(e) =>
                        setData((prevData) => ({
                          ...prevData,
                          address: {
                            ...prevData.address!,
                            line1: e.target.value,
                          },
                        }))
                      }
                    />
                  </div>

                  <div className="flex flex-col items-start text-sm w-full">
                    <label className="font-semibold w-full" htmlFor="line2">
                      Address Line 2
                    </label>
                    <input
                      placeholder="Enter address line 1"
                      className="p-1 rounded focus:outline-none w-full border"
                      id="line2"
                      name="line2"
                      value={data.address?.line2 || ""}
                      onChange={(e) =>
                        setData((prevData) => ({
                          ...prevData,
                          address: {
                            ...prevData.address!,
                            line2: e.target.value,
                          },
                        }))
                      }
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-5 items-end">
                  <div className="flex flex-col items-start text-sm w-full">
                    <label className="font-semibold w-full" htmlFor="city">
                      City*
                    </label>
                    <input
                      placeholder="Enter city"
                      className="p-1 rounded focus:outline-none w-full border"
                      id="city"
                      name="city"
                      required
                      value={data.address?.city || ""}
                      onChange={(e) =>
                        setData((prevData) => ({
                          ...prevData,
                          address: {
                            ...prevData.address!,
                            city: e.target.value,
                          },
                        }))
                      }
                    />
                  </div>
                  <div className="flex flex-col items-start text-sm w-full">
                    <label className="font-semibold w-full" htmlFor="state">
                      State*
                    </label>
                    <input
                      placeholder="Enter state"
                      className="p-1 rounded focus:outline-none w-full border"
                      id="state"
                      name="state"
                      required
                      value={data.address?.state || ""}
                      onChange={(e) =>
                        setData((prevData) => ({
                          ...prevData,
                          address: {
                            ...prevData.address!,
                            state: e.target.value,
                          },
                        }))
                      }
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-5 items-end">
                  <div className="flex flex-col items-start text-sm w-full">
                    <label className="font-semibold w-full" htmlFor="country">
                      Country*
                    </label>
                    <input
                      placeholder="Enter country"
                      className="p-1 rounded focus:outline-none w-full border"
                      id="country"
                      name="country"
                      required
                      value={data.address?.country || ""}
                      onChange={(e) =>
                        setData((prevData) => ({
                          ...prevData,
                          address: {
                            ...prevData.address!,
                            country: e.target.value,
                          },
                        }))
                      }
                    />
                  </div>
                  <div className="flex flex-col items-start text-sm w-full">
                    <label className="font-semibold w-full" htmlFor="pincode">
                      Pincode*
                    </label>
                    <input
                      placeholder="Enter pincode"
                      className="p-1 rounded focus:outline-none w-full border"
                      id="pincode"
                      name="pincode"
                      required
                      value={data.address?.pincode || ""}
                      onChange={(e) =>
                        setData((prevData) => ({
                          ...prevData,
                          address: {
                            ...prevData.address!,
                            pincode: e.target.value,
                          },
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="flex justify-center gap-5 mt-5 mb-2">
          <button
            onClick={submit}
            type="button"
            className="rounded-md text-sm font-semibold bg-myPrimaryColor text-white px-3 py-1"
          >
            Submit
          </button>
        </div>
      </div>

      {loading && <LoadingWidget />}
    </>
  );
};

export default InventoryBasketCreateScreen;
