import { mdiCheckboxMarkedOutline, mdiCheckboxBlankOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { filter } from "lodash";
import { FunctionComponent, useEffect, useState } from "react";
import { Ops_task_status } from "../../../../../utils/enums";

interface EngineerTaskSortWidgetProps {
  data: { sort_by?: string; sort_order?: string };
  onClose: () => void;
  onSubmit: (data: { sort_by?: string; sort_order?: string }) => void;
}

const EngineerTaskSortWidget: FunctionComponent<EngineerTaskSortWidgetProps> = (
  props
) => {
  const [selectedSort, setselectedSort] = useState<{
    sort_by?: string;
    sort_order?: string;
  }>({});
  useEffect(() => {
    setselectedSort({ ...props.data });
  }, [props.data]);
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 z-10 h-full w-full  backdrop-filter backdrop-blur-xs flex items-end justify-center ">
      <div className="p-5 bg-white rounded-t-xl w-full border-t shadow-lg">
        <div className="flex justify-between border-b mb-2">
          <h1>
            <b> Sort </b>
          </h1>
          <button onClick={props.onClose}>
            {/* <Icon path={mdiClose} className="text-red-500" size={0.8} /> */}
            <p className="font-semibold text-blue-500">Close</p>
          </button>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-col">
            <label htmlFor="sort-by">Sort By</label>
            <select
              key={selectedSort.sort_by ?? "by"}
              value={selectedSort.sort_by}
              onChange={(e) => {
                setselectedSort((f) => ({ ...f, sort_by: e.target.value }));
              }}
              name=""
              id="sort-by"
              className="focus:outline-none p-2 border rounded"
            >
              <option value="" hidden>
                Select
              </option>
              {[
                { key: "created_at", label: "Creation Date" },
                { key: "last_date", label: "Due Date" },
                { key: "end_date", label: "Completion Date" },
              ].map((f) => (
                <option value={f.key}>{f.label}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-col">
            <label htmlFor="sort-order">Sort Order</label>
            <select
              key={selectedSort.sort_order ?? "order"}
              value={selectedSort.sort_order}
              onChange={(e) => {
                setselectedSort((f) => ({ ...f, sort_order: e.target.value }));
              }}
              name=""
              id="sort-order"
              className="focus:outline-none p-2 border rounded"
            >
              {" "}
              <option value="" hidden>
                Select
              </option>
              {[
                { key: "asc", label: "Ascending" },
                { key: "desc", label: "Descending" },
              ].map((f) => (
                <option value={f.key}>{f.label}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-5 p-2">
          <button
            onClick={() =>
              setselectedSort({ sort_by: undefined, sort_order: undefined })
            }
            className="bg-gray-50 border border-gray-400 text-gray-500 rounded-lg  px-3 py-1"
          >
            Clear
          </button>
          <button
            onClick={() => props.onSubmit(selectedSort)}
            className="bg-green-50 border border-green-400 text-green-500 rounded-lg  px-3 py-1"
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default EngineerTaskSortWidget;
