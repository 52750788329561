import OrderItemModel from "./order_item_model";
import { CustomerDetailModel } from "./customer_detail_model";

import { DiscountDataModel } from "./order_discount_model";
import { ExcessDataModel } from "./order_excess_model";
import { TransactionDataModel } from "../trannsaction_model";

import {
  PO_STATUS,
  PaymentStatus,
  AddressType,
  OrderType,
  OrderStatus,
} from "../../../utils/enums";
import { ItemMovementModel } from "../../../erp/inventory/models/inventory_voucher";
export interface OrderModel {
  [key: string]: any;
}
export interface OrderModel {
  id: number;
  order_id: string;
  user_id: number;
  type: string;
  customer: CustomerDetailModel;
  delivery_profile: CustomerDetailModel;
  delivery_profile_id: number;
  customer_id: number;
  items: OrderItemModel[];
  discountData: DiscountDataModel[];
  excessData: ItemMovementModel[];
  transactionData: TransactionDataModel[];

  subtotal: number;
  discount: number;
  excess: number;
  paid: number;
  total: number;
  created_by?: number;
  updated_by?: number;
  created_by_name?: string;
  updated_by_name?: string;
  created_at?: string;
  updated_at?: string;
  payment_status: string;
  status: string;
  po_status: string;
  completed_at?: Date;
  order_date: Date;
  comment?: string[];
  sales_person_id?: string;
  sales_person_name?: string;
  source?: string;
  demo?: boolean;
  expected_pay_date?: Date;
  next_delivery_date?: Date;
  remark?: string;
  category?: string;
  rm_uid?: string;
  rm_name?: string;
  rm2_uid?: string;
  rm2_name?: string;
  site_survey?: string;
  site_survey_remark?: string;
  wiring_status?: string;
  wiring_remark?: string;

  installation_remark?: string;
  load_sanction?: number;
  subsidy_status?: string;
  qc_status?: string;
  monthly_recurring_fee?: string;
  monthly_recurring_payment_term?: string;
  collection_start_date?: string;
  per_month?: number;
  per_transaction?: number;
  per_unit?: number;
  payment_voc?: string;
  registration_doc_issued?: string;
  no_device_registered?: number;
  registration_remark?: string;
  meter_type?: string;
  hold_remark?: string;
  registration_number?: string;
  subsidy_claimed_qty?: number;
  discom?: string;
  segment?: string;
  sale_type?: string;
  survey_date?: Date;
  sld_and_boq_date?: Date;
  sld_and_boq_approval_date?: Date;
  installation_date?: Date;
  commission_date?: Date;
  dealer_code?: string;
}
export interface OrderByItemModel {
  id: number;
  order_id: string;
  user_id: number;
  type: string;
  customer: CustomerDetailModel;
  customer_id: number;
  items: OrderItemModel;
  discountData: DiscountDataModel[];
  excessData: ItemMovementModel[];
  transactionData: TransactionDataModel[];

  subtotal: number;
  discount: number;
  excess: number;
  paid: number;
  total: number;
  created_by?: number;
  updated_by?: number;
  created_by_name?: string;
  updated_by_name?: string;
  created_at?: string;
  updated_at?: string;
  payment_status: string;
  status: string;
  po_status: string;
  completed_at?: Date;
  order_date: string;
  comment?: string[];
  sales_person_id?: number;
  sales_person_name?: string;
  source?: string;
  demo?: boolean;
  expected_pay_date?: string;
  next_delivery_date?: string;
  remark?: string;
  category?: string;
  rm_uid?: number;
  rm_name?: string;
  site_survey?: string;
  site_survey_remark?: string;
  wiring_status?: string;
  wiring_remark?: string;

  installation_remark?: string;
  load_sanction?: number;
  subsidy_status?: string;
  qc_status?: string;
  monthly_recurring_fee?: string;
  monthly_recurring_payment_term?: string;
  collection_start_date?: string;
  per_month?: number;
  per_transaction?: number;
  per_unit?: number;
  payment_voc?: string;
  registration_doc_issued?: string;
  no_device_registered?: number;
  registration_remark?: string;
  meter_type?: string;
  hold_remark?: string;
  registration_number?: string;
  subsidy_claimed_qty?: string;
  discom?: string;
  segment?: string;
}

export const defaultOrderVal: OrderModel = {
  id: 0,

  po_status: PO_STATUS.pending,

  customer_id: 0,
  order_date: new Date(),
  order_id: "",
  payment_status: PaymentStatus.PENDING_PAYMENT,
  user_id: 0,
  customer: {
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    alt_mobile: "",
    address: {
      line1: "",
      state: "",
      city: "",
      country: "India",
      pincode: 0,
      type: AddressType.HOME,
    },
  },
  monthly_recurring_fee: "No",
  type: OrderType.B2C,
  total: 0,
  paid: 0,
  status: OrderStatus.CONFIRMED,
  subtotal: 0,
  discount: 0,
  excess: 0,
  items: [],
  transactionData: [],
  excessData: [],
  discountData: [],
  delivery_profile_id: 0,
  delivery_profile: {
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    alt_mobile: "",
    address: {
      line1: "",
      state: "",
      city: "",
      country: "India",
      pincode: 0,
      type: AddressType.HOME,
    },
  },
};
