import { FunctionComponent, useEffect, useState } from "react";
import {
  useToast,
  ToastType,
} from "../../../../../context_providers/toast/toast_ctx";

import { AttachmentModel } from "../../../../../service/models/attachment_modal";
import {
  GetTrasactionAttachmentRepo,
  UploadTrasactionAttachmentRepo,
} from "../../../../../service/repos/transaction_repo";

import { ACTION, FileType } from "../../../../../utils/enums";
import { compressImage } from "../../../../../utils/image_compressor";

import FileUploadWidget from "../../../../components/common/file_upload_widget";
import ImageTile from "../../../../components/common/image_tile";
import PdfTile from "../../../../components/common/pdf_tile";
import LoadingWidget from "../../../../components/loading_spinner";
import ModalDialog from "../../../../new_components/common/modal";
import StadiumButton from "../../../../new_components/common/stadium_button";

interface TransactionTableProps {
  txn_id: string;
}

const TransactionAttachmentView: FunctionComponent<TransactionTableProps> = (
  props
) => {
  const { showToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [attachments, setAttachments] = useState<AttachmentModel[]>([]);
  const [showAddAttachment, setShoAddAttachment] = useState<boolean>(false);
  const getAttachments = async () => {
    setLoading(true);

    const res = await GetTrasactionAttachmentRepo(props.txn_id);
    if (res.success) {
      setAttachments(res.data ?? []);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  const uploadAttachment = async (data: FormData) => {
    setLoading(true);

    const res = await UploadTrasactionAttachmentRepo(data);
    if (res.success) {
      setShoAddAttachment(false);
      getAttachments();
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  useEffect(() => {
    getAttachments();
  }, []);

  return (
    <>
      <ModalDialog
        title="Upload attachment"
        show={showAddAttachment}
        onClose={() => setShoAddAttachment(false)}
      >
        <div className="p-5">
          <FileUploadWidget
            onSubmit={async (files) => {
              if (files.length === 0) {
                showToast({
                  type: ToastType.warning,
                  text: "Please upload an attachment",
                });
                return;
              }

              if (files.length > 0) {
                const form = new FormData();
                setLoading(true);
                for (let index = 0; index < files.length; index++) {
                  const file = files[index];

                  if (file.type.startsWith("image")) {
                    const comFile = await compressImage(file);

                    if (comFile.size > 3000000) {
                      showToast({
                        type: ToastType.warning,
                        text: "Please upload file size less than 3 md",
                      });
                      return;
                    }
                    form.append("attachment", comFile);
                  } else {
                    if (file.size > 3000000) {
                      showToast({
                        type: ToastType.warning,
                        text: "Please upload file size less than 3 md",
                      });
                      return;
                    }
                    form.append("attachment", file);
                  }
                }
                setLoading(false);
                form.append("txn_id", props.txn_id.toString());
                uploadAttachment(form);
              }
            }}
          />
        </div>
      </ModalDialog>
      <div
        className=" w-full  p-5"
        style={{ height: "80vh", minWidth: "50vw" }}
      >
        <div className="">
          <div className="flex justify-end">
            <StadiumButton onTap={() => setShoAddAttachment(true)}>
              {" "}
              Upload Attachment
            </StadiumButton>
          </div>{" "}
          {/* <FileUploadWidget onSubmit={(files) => {}} /> */}
          {attachments.length === 0 ? (
            <div className="flex h-full justify-center items-center">
              <h3 className="text-2xl text-gray-500 mt-5">
                No attachment found !
              </h3>
            </div>
          ) : (
            <div className="  flex  flex-wrap">
              {attachments?.map((it, i) => (
                <div className="max-h-36" key={i}>
                  {it.type === FileType.IMAGE ? (
                    <ImageTile url={it.url} />
                  ) : (
                    <PdfTile url={it.url} />
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <LoadingWidget loading={loading} />
    </>
  );
};

export default TransactionAttachmentView;
