import { ExportToCsv } from "export-to-csv";
import moment from "moment";
import { FunctionComponent, useState, useEffect } from "react";
import {
  useToast,
  ToastType,
} from "../../../../context_providers/toast/toast_ctx";
import {
  InventoryImportData,
  InventoryModel,
} from "../../../../service/models/inventory/inventory_model";
import { GetInventoryListApi } from "../../../../service/repos/inventory_repo";
import { getComplaints } from "../../../../service/repos/support/complaint_repo";
import { UTCToLocalDate } from "../../../../utils/date_util";
import LoadingWidget from "../../../components/loading_spinner";
import StadiumButton from "../../../new_components/common/stadium_button";
import { ComplaintFilterModle } from "../../complaint/listing";

interface ExportInventoryProps {
  filter: {
    product_id?: string[];
    status?: string[];
    search?: string;
    page?: number;
    count?: number;
    all?: any;
    warehouse_id?: string[];
    fromDate?: Date;
    toDate?: Date;
  };
}

const ExportInventory: FunctionComponent<ExportInventoryProps> = (props) => {
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);

  const [fromDate, setFromdate] = useState<Date>();
  const [toDate, setTodate] = useState<Date>();

  const getOrders = async () => {
    if (!fromDate || !toDate) {
      showToast({
        type: ToastType.warning,
        text: "Please select date",
      });

      return;
    } else if (
      moment(fromDate, "YYYY-MM-DD").isAfter(moment(toDate, "YYYY-MM-DD"))
    ) {
      showToast({
        type: ToastType.warning,
        text: "From Date should not be greater than To Date ",
      });
      return;
    }
    setLoading(true);
    const res = await GetInventoryListApi({
      all: true,
      page: 0,
      count: 0,
      ...props.filter,
      fromDate,
      toDate,
    });

    if (res.success) {
      if (res.data.inventories.length) {
        const options = {
          fieldSeparator: ",",
          quoteStrings: '"',
          decimalSeparator: ".",
          showLabels: true,
          showTitle: true,
          title: "Inventory",
          useTextFile: false,
          useBom: true,
          useKeysAsHeaders: true,
          // headers: [
          //   "Name",
          //   "Dept",
          //   "Earned leave av.",
          //   "Earned leave taken",
          //   "Current month taken",
          //   "Medical leave av.",
          //   "Medical leave taken",
          //   "Current month taken",
          //   "Unpaid leave",
          // ],
        };

        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(
          res.data.inventories.map((inv: InventoryModel, i: number) => {
            const allotment = inv.allocations.find((od) => od.active === true);

            const t: InventoryImportData = {
              device_id: inv.device_id,
              product_id: inv.device.product_id,
              product_name: inv.device.product_name,
              warehouse_id: inv.warehouse_id,
              warehouse_name: inv.warehouse?.name,
              mobile_number: inv.device.mobile_number,
              module_imei: inv.device.module_imei,
              sim_imsi: inv.device.sim_imsi || "",
              plan_name: inv.device.plan_name || "",
              sim_no: inv.device.sim_no || "",
              sim_status: inv.device.sim_status || "",
              status: inv.status || "",
              order_id: allotment?.order_id || "",
              org_namespace: allotment?.org_namespace || "",
              org_name: allotment?.org_name || "",
              delivery_date: (allotment?.delivery_date
                ? new Date(allotment.delivery_date).toLocaleString()
                : "") as any,

              installation_date: (allotment?.installation_date
                ? new Date(allotment.installation_date).toLocaleString()
                : "") as any,

              return_back_date: (allotment?.return_back_date
                ? new Date(allotment.return_back_date).toLocaleString()
                : "") as any,
              responsible_id: inv.responsible_id,
              responsible_name: inv.responsible_name,
            };

            return t;
          })
        );
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    const date = new Date();

    if (props.filter?.fromDate && props.filter.toDate) {
      setFromdate(props.filter.fromDate);
      setTodate(props.filter.toDate);
    } else {
      setFromdate(new Date(date.setHours(0, 0, 0, 0)));
      setTodate(new Date(date.setHours(23, 59, 59, 999)));
    }
  }, []);

  return (
    <div className="p-5">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <div className="">
          <label htmlFor="from-date">From</label>
          <input
            id="from-date"
            className="border rounded p-2 w-full"
            type="date"
            name="fromDate"
            placeholder="From"
            required
            value={moment(fromDate).format("YYYY-MM-DD")}
            onChange={(e) => {
              let date = UTCToLocalDate(e.target.value)!;
              date = new Date(date.setHours(0, 0, 0, 0));
              if (toDate && moment(date).isAfter(moment(toDate))) {
                showToast({
                  type: ToastType.warning,
                  text: "From Date should not be greater than To Date ",
                });
                return;
              }
              setFromdate(date);
            }}
          />
        </div>
        <div className="">
          <label htmlFor="to-date">To</label>
          <input
            id="to-date"
            className="border rounded p-2 w-full"
            type="date"
            name="toDate"
            placeholder="To"
            required
            value={moment(toDate).format("YYYY-MM-DD")}
            min={fromDate ? moment(fromDate).format("YYYY-MM-DD") : ""}
            onChange={(e) => {
              let date = UTCToLocalDate(e.target.value)!;
              date = new Date(date.setHours(23, 59, 59, 999));
              if (fromDate && moment(fromDate).isAfter(moment(date))) {
                showToast({
                  type: ToastType.warning,
                  text: "To Date should  be greater than From Date ",
                });
                return;
              }
              setTodate(date);
            }}
          />
        </div>
      </div>
      <div className="flex justify-end my-2">
        <StadiumButton type="submit" onTap={getOrders}>
          Export
        </StadiumButton>
      </div>
      <LoadingWidget loading={loading} />
    </div>
  );
};

export default ExportInventory;
