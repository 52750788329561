import { FunctionComponent, useState } from "react";
import ItemSearchField from "../../../../erp/inventory/items/item_search_field";
import { ItemModel } from "../../../../erp/inventory/models/item_model";
import DeviceComponentRow from "./device_component_row";
import DeviceItemRow from "./device_item_row";

interface DeviceComponentWidgetProps {
  data?: { item: ItemModel; qty: number; id: any }[];
  onSubmit: (data: { item: ItemModel; qty: number; id: any }[]) => void;
  editEnable: boolean;
  onDelete?: (i: { item: ItemModel; qty: number; id: any }[]) => void;
  onCancel: () => void;
}

const DeviceComponentWidget: FunctionComponent<DeviceComponentWidgetProps> = (
  props
) => {
  const [data, setData] = useState<{ item: ItemModel; qty: number; id: any }[]>(
    props.data ?? []
  );
  // const [disabled, setDisabled] = useState<boolean>(props.disabled ?? false);
  const [edit, setEdit] = useState<boolean>(false);
  const [addNewId, setAddNewId] = useState("");

  return (
    <div className="flex flex-col justify-between h-full p-2">
      <div className="  rounded flex flex-col gap-1   ">
        <div className="grid grid-cols-4 gap-2 p-1 bg-myPrimaryColor text-white text-sm font-semibold rounded-t  uppercase ">
          <div className="text-center col-span-2">Item</div>
          <div className="text-center ">Qty</div>

          {/* <div className="text-center col-span-2">Remark</div> */}
          <div className="text-center  "> Actions</div>
        </div>
        <div className="flex flex-col gap-1    text-sm overflow-auto h-72">
          {data.map((item, i) => {
            return (
              <DeviceComponentRow
                disabled={!edit || false}
                key={item.id}
                data={item}
                onDelete={(d) => {
                  setData((o) => [...(o || [])].filter((i) => i.id !== d.id));
                }}
                onSubmit={function (d): void {
                  setData((o) => [
                    ...(o || []).map((it) => {
                      if (it.id === d.id) return d;
                      return it;
                    }),
                  ]);
                }}
                edit={false}
              />
            );
          })}
          {edit && (
            <DeviceComponentRow
              key={addNewId}
              onSubmit={function (data): void {
                data.id = (Math.random() * 1000).toString();

                setData((o) => {
                  let t = [...o, data];
                  return t;
                });
                setAddNewId((Math.random() * 1000).toString());
              }}
              edit={true}
              disabled={false}
            />
          )}
        </div>
      </div>
      {/* <div className="flex gap-5 mb-5 justify-center">
        <button
          onClick={props.onCancel}
          className="border rounded-lg px-3 py-1"
        >
          Cancel
        </button>
        <button
          onClick={() => props.onSubmit(data)}
          className="border rounded-lg px-3 py-1 bg-teal text-white"
        >
          Submit
        </button>
      </div> */}
      <div className="grid grid-cols-2 gap-5 p-2">
        {edit ? (
          <>
            <button
              onClick={() => setEdit(false)}
              className="border rounded-lg px-3 py-2 bg-red-50 text-red-500 border-red-400"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                setEdit(false);
                props.onSubmit(data);
              }}
              className="border rounded-lg px-3 py-2 bg-green-50 text-green-500 border-green-400"
            >
              Submit
            </button>
          </>
        ) : (
          props.editEnable && (
            <>
              <div className=""></div>
              <button
                onClick={() => setEdit(true)}
                className="border rounded-lg px-3 py-2 bg-green-50 text-green-500 border-green-400"
              >
                Edit
              </button>
            </>
          )
        )}
      </div>
    </div>
  );
};

export default DeviceComponentWidget;
