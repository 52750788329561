export function isSet(obj) {
  if (obj && obj !== "null" && obj !== undefined && obj !== "" && obj !== "[]" && obj !== [] && obj !== {} && obj !== "") {
      if (typeof obj !== "undefined") {
          return true;
      }
  }
  return false;
}

export function getCurrentDateTime() {
  let date_ob = new Date();

  // adjust 0 before single digit date
  let date = ("0" + date_ob.getDate()).slice(-2);

  // current month
  let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

  // current year
  let year = date_ob.getFullYear();

  // current hours
  let hours = date_ob.getHours();

  // current minutes
  let minutes = date_ob.getMinutes();

  // current seconds
  let seconds = date_ob.getSeconds();

  // prints date & time in YYYY-MM-DD HH:MM:SS format
  return year + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds;
}
export function getCurrentTimestamp() {
  return Math.floor(Date.now() / 1000);
}
export function getCurrentDate() {
  let date_ob = new Date();

  // adjust 0 before single digit date
  let date = ("0" + date_ob.getDate()).slice(-2);

  // current month
  let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

  // current year
  let year = date_ob.getFullYear();

  // prints date & time in YYYY-MM-DD HH:MM:SS format
  return year + "-" + month + "-" + date;
}
export function getDateTime(timestamp)
{
  return new Date(timestamp*1000).toLocaleString();    
}

export function getLocalDateTime(dateAndTime){
  if(isSet(dateAndTime))
  {
      return new Date(dateAndTime).toLocaleString();
  }
}

export function isValidJsonData(data) {
  var json_data;
  try {
      json_data=JSON.parse(data);
  } catch (e) {
      return false;
  }
  return json_data;
}

export function isSetObject(obj) {
  if(Object.keys(obj).length > 0 )
  {
      return true;
  }
  else{
      return false;
  }    
}

export function isContainSlash(data)
{
  if(isSet(data))
  {
      if(data.split(/(\\|\/)/)[1])
      {
          return true;
      }
  }
  return false;    
}
