import { FunctionComponent, useState } from "react";
import { SideBarBtn } from "../../../utils/urls";
import Header from "../header";
import Sidebar from "../sidebar";
import "./index.css";

interface LayoutProps {
  urls: SideBarBtn[];
}

const Layout: FunctionComponent<LayoutProps> = (props) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="wrapper flex h-screen  overflow-hidden ">
      {/* Sidebar */}
      <Sidebar
        url_btns={props.urls}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />

      {/* Content area */}
      <div
        className="relative flex flex-col flex-1 overflow-x-hidden overflow-y-auto h-full "
        // style={{ height: "max-content" }}
      >
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main className="h-full"> {props.children}</main>
      </div>
    </div>
  );
};

export default Layout;
