import React from "react";
import DashboardCards from "./components/DashboardCards";

const Dashboard = () => {
  return (
    <div className="px-3 py-5">
      <div className="mt-2 mb-12">
        <div className="text-3xl font-bold">All Dashboards</div>
        <div className="font-semibold">
          Here’s the overall analysis of our company
        </div>
      </div>
      <DashboardCards />
    </div>
  );
};

export default Dashboard;