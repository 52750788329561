import { FunctionComponent } from "react";

interface ToggleBtnProps {
  value: boolean;
  onChange: (v: boolean) => void;
  disabled?: boolean;
  name?: string;
}

const ToggleBtn: FunctionComponent<ToggleBtnProps> = ({
  value,
  onChange,
  disabled,
  name,
}) => {
  return (
    <div
      title={value ? "true" : "false"}
      className="relative inline-block w-9 h-5 align-middle select-none transition duration-200 ease-in"
    >
      <input
        disabled={disabled}
        checked={value}
        type="checkbox"
        name={name || "toggle"}
        id={"tbtn"}
        className={`toggle-checkbox absolute block w-5 h-5 rounded-full bg-white border-4 appearance-none cursor-pointer ${
          value ? "right-0 border-green-400" : "border-red-400"
        }`}
        onChange={(e) => {
          onChange(e.target.checked);
        }}
      />
      <label
        htmlFor={"tbtn"}
        className={`toggle-label block overflow-hidden h-5 rounded-full bg-gray-300 cursor-pointer ${
          value ? "bg-green-300" : "bg-red-300"
        }`}
      ></label>{" "}
    </div>
  );
};

export default ToggleBtn;
