let timer: any;
export function debounce(func: () => void, timeout = 500) {
  if (timer) {
    clearTimeout(timer);
    timer = 0;
  }
  timer = setTimeout(() => {
    func();
  }, timeout);
}
