import { FunctionComponent } from "react";

interface FileDropZoneProps {
  type: string;
  required?: boolean;
  onChange: (files: File[]) => void;
  multiSelect: boolean;
  className?: string;
}

const FileDropZone: FunctionComponent<FileDropZoneProps> = (props) => {
  return (
    <div className={props.className ?? "h-full w-full"}>
      <div
        className="flex flex-col justify-center items-center h-full w-full bg-gray-100 border-2 border-dashed border-black hover:border-green-400 "
        onDragOver={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onDrag={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onDragEnter={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onDragLeave={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onDrop={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            if (props.multiSelect && e.dataTransfer.files.length) {
              const files = [...e.dataTransfer.files];
              for (let index = 0; index < files.length; index++) {
                const file = files[index];
                if (!file.type.startsWith(props.type)) {
                  alert("Please select " + props.type + "Files ");
                  return;
                }
              }
              return props.onChange(files);
            } else if (!props.multiSelect && e.dataTransfer.files.length) {
              const file = e.dataTransfer.files[0];

              if (!file.type.match(props.type)) {
                alert("Please select " + props.type + "Files ");
                return;
              }
              return props.onChange([file]);
            } else alert("Please select  file");
            e.dataTransfer.clearData();
          }
        }}
      >
        <p>Drag and drop image</p>
        <p>----- Or -----</p>
        <input
          className="focus:outline-none"
          type="file"
          name="files"
          id="files"
          accept={props.type}
          multiple={props.multiSelect}
          required={props.required}
          onChange={async (e) => {
            if (e.target.files?.length) {
              props.onChange([...e.target.files]);
            }
          }}
        />
      </div>
    </div>
  );
};

export default FileDropZone;
