import { FunctionComponent } from "react";

interface ToggleButtonProps {
  value: boolean;
  onToggle: (v: boolean) => void;
  trueLabel: string;
  falseLabel: string;
}

const ToggleButton: FunctionComponent<ToggleButtonProps> = (props) => {
  return (
    <div className="">
      <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
        <input
          type="checkbox"
          onClick={() => props.onToggle(!props.value)}
          name="toggle"
          id="toggle"
          className={`toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer ${
            props.value ? "right-0 border-green-400" : ""
          }`}
        />
        <label
          htmlFor="toggle"
          className={`toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer ${
            props.value ? "bg-green-300" : ""
          }`}
        ></label>{" "}
      </div>
      {props.value ? props.trueLabel : props.falseLabel}
    </div>
  );
};

export default ToggleButton;
