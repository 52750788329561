const relativePath = "";
export const URLS = {
  homePage: `${relativePath}/`,
  orders: `${relativePath}/order-listings`,
  login: `${relativePath}/login`,
  blogEditor: `${relativePath}/blog-editor`,
  blogListing: `${relativePath}/blog-listings`,
  uploadImage: `${relativePath}/upload-image`,
  vehicleListing: `${relativePath}/vehicle-listings`,
  vehicleCreate: `${relativePath}/vehicle-listings/create`,
  bikeSpec: `${relativePath}/vehicle-listings/bike/specifications/:id`,
  bikePricing: `${relativePath}/vehicle-listings/bike/pricing/:id`,
  bikeImages: `${relativePath}/vehicle-listings/bike/images/:id`,
  installation: `${relativePath}/installation`,
  installationCreate: `${relativePath}/installation-create`,
  installationEdit: `${relativePath}/installation-editor`,
  magazine: `${relativePath}/magazine`,
  magazineCreate: `${relativePath}/magazine-create`,
  magazineEdit: `${relativePath}/magazine-editor`,
  authors: `${relativePath}/authors`,
  authorCreate: `${relativePath}/authors-create`,
  authorEdit: `${relativePath}/authors-edit`,
};
