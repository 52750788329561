import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import { FunctionComponent, useEffect } from "react";
import { NavLink } from "react-router-dom";

interface CardProps {
  data: { name: string; link: string; logo: string };
}

const Card: FunctionComponent<CardProps> = ({ data }) => {
  const animateData = () => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".academy_card",
      },
    });

    gsap.registerPlugin(ScrollTrigger);

    tl.fromTo(
      ".academy_card",
      0.5,
      {
        opacity: 0,
        stagger: 0.3,
      },
      {
        opacity: 1,
      }
    );
  };

  useEffect(() => {
    animateData();
  }, []);

  return (
    <NavLink to={data.link}>
      <div className="academy_card flex flex-col items-center rounded-xl bg-white py-8 h-full opacity-0">
        <div className="h-1/2 w-20">
          <img src={data.logo} alt="" className="w-full h-full" />
        </div>
        <div className="text-xl h-1/2 mt-4 font-semibold">{data.name}</div>
      </div>
    </NavLink>
  );
};

export default Card;
