import { FunctionComponent, useCallback, useEffect, useState } from "react";

import {
  useToast,
  ToastType,
} from "../../../../../../context_providers/toast/toast_ctx";
import { toQueryString } from "../../../../../../service/api_client";
import AutoCompleteField from "../../../../../new_components/common/autocomplete";
import { fetchGet } from "../../../../content_panel/services/serviceClient";
import debounce from "lodash.debounce";
import ModalDialog from "../../../../../new_components/common/modal";
import { CustomerDetailModel } from "../../../../../../service/models/orders/customer_detail_model";
import AddCustomerWidget from "../customer/customer_add_widget";
import { GetBillingProfilesOfUserApi } from "../../../../../../service/repos/customer/billing_profile_repo";

interface BillingSearchFieldProps {
  user_id: string;
  onSelect: (data: CustomerDetailModel) => void;
  disabled?: boolean;
  placeholder?: string;
  value?: CustomerDetailModel;
  defaultShow?: boolean;
}

const BillingSearchField: FunctionComponent<BillingSearchFieldProps> = (
  props
) => {
  const { showToast } = useToast();
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [addNew, setAddNew] = useState(false);
  const [suggestions, setSuggestions] = useState<
    {
      label: string;
      value: CustomerDetailModel;
    }[]
  >();
  const getData = async () => {
    setLoading(true);
    try {
      const res = await GetBillingProfilesOfUserApi(props.user_id);
      if (res.success) {
        setSuggestions(
          res.data.billing_profiles.map((data: CustomerDetailModel) => ({
            label: `${data.mobile} | ${data.address.line1}, | ${data.address.city}| ${data.address.state}  `,
            value: data,
          }))
        );
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
    }
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, [props.user_id]);
  // const debouncedHandler = useCallback(
  //   debounce((query) => {
  //     if (query) getData({ search: query, page: 1, count: 1, all: true });
  //     else setSuggestions(undefined);
  //   }, 500),
  //   []
  // );

  // useEffect(() => {
  //   if (search && edit) {
  //     if (props.value && props.value.first_name === search) return;
  //     debouncedHandler(search);
  //   }
  // }, [search]);
  // useEffect(() => {
  //   setSearch(props.value?.first_name ?? "");
  // }, [props.value]);
  return (
    <>
      {edit ? (
        <AutoCompleteField
          defaultShow={
            props.defaultShow === undefined ? true : props.defaultShow
          }
          loading={loading}
          disabled={props.disabled}
          value={search}
          onChange={setSearch}
          onSelect={(d) => {
            setSearch(d.value.firstName);
            setEdit(false);
            props.onSelect(d.value);
          }}
          placeholder={props.placeholder || "Search"}
          suggestions={suggestions}
        />
      ) : (
        <input
          type="text"
          placeholder={props.placeholder || "Search"}
          className="text-sm p-1   rounded focus:outline-none w-full border cursor-text"
          onClick={() => {
            if (!props.disabled) {
              setEdit(true);
            }
          }}
          value={props.value?.firstName}
        ></input>
      )}
      {/* {addNew && (
        <ModalDialog
          onClose={() => {
            setAddNew(false);
          }}
          show={addNew}
          title={"Add customer/user"}
        >
          <div className="overflow-auto" style={{ maxHeight: "80vh" }}>
            {" "}
            <AddCustomerWidget
              onAdded={(user) => {
                if (user) {
                  setEdit(false);
                  setSearch(user.first_name);
                  props.onSelect(user as any);
                  setAddNew(false);
                }
              }}
            />
          </div>
        </ModalDialog>
      )} */}
    </>
  );
};

export default BillingSearchField;
