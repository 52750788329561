import moment from "moment";

export const UTCToLocalDate = (
  d: string | Date | number,
  offsetSec: number = 0
) => {
  if (!d) return;
  let date = new Date(d);

  var currentOffset = date.getTimezoneOffset();
  var localDate = new Date(
    date.getTime() + (offsetSec + currentOffset) * 60000
  );

  return localDate;
  // return new Date(new Date(date).getTime() + HOUR * 5.5);
};

export const dateString = (date: Date) => {
  const t = new Date(date.getTime() + 5.5 * 60 * 60000)
    .toISOString()
    .split(".")[0];

  return t;
  return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
};

export const getFY = (date: string | Date | number) => {
  date = new Date(date);
  let ADate: Date = new Date(date.getFullYear(), 3, 1);
  let JDate: Date = new Date(date.getFullYear(), 0, 1);

  if (date.getTime() >= JDate.getTime() && date.getTime() < ADate.getTime()) {
    return date.getFullYear() - 1;
  } else return date.getFullYear();
};

export const dayOfYear = () => {
  var now: any = new Date();
  var start: any = new Date(now.getFullYear(), 0, 0);
  var diff =
    now -
    start +
    (start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000;
  var oneDay = 1000 * 60 * 60 * 24;
  var today = Math.floor(diff / oneDay);
  // setDayOfTheYear(day);
  return today;
};

export const birthdayOfYear = (birthDate: any) => {
  var now: any = new Date();
  var start: any = new Date(now.getFullYear(), 0, 0);
  var diff =
    birthDate -
    start +
    (start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000;
  var oneDay = 1000 * 60 * 60 * 24;
  var day = Math.floor(diff / oneDay);
  return day;
};

export const getDayAgo = (date: Date) => {
  return dayOfYear() - birthdayOfYear(date);
};

export const datetimeFormat = (date: Date | string | number) => {
  return moment(new Date(date)).format("DD MMM YYYY hh:mm a");
};
export const timeFormat = (date: Date | string | number) => {
  return moment(new Date(date)).format("hh:mm a");
};
export const dateFormat = (date: Date | string | number) => {
  return moment(new Date(date)).format("DD MMM YYYY ");
};

export const timeDiff = (
  date1: string | Date | number,
  date2?: string | Date | number
) => {
  let distance = Math.abs(
    (date2 ? new Date(date2) : new Date()).getTime() - new Date(date1).getTime()
  );
  const years = Math.floor(distance / 3.154e10);
  distance -= years * 3.154e10;
  const days = Math.floor(distance / 8.64e7);
  distance -= days * 8.64e7;
  const hours = Math.floor(distance / 3600000);
  distance -= hours * 3600000;
  const minutes = Math.floor(distance / 60000);
  distance -= minutes * 60000;
  const seconds = Math.floor(distance / 1000);
  return ` ${years > 0 ? `${years} years` : ""}   ${
    days > 0 ? `${days} days` : ""
  }  ${hours}:${("0" + minutes).slice(-2)}:${("0" + seconds).slice(-2)}`;
};

export const timeSince = (
  date1: string | Date | number,
  date2?: string | Date | number
) => {
  var sec = Math.floor(
    ((date2 ? new Date(date2) : new Date()).getTime() -
      new Date(date1).getTime()) /
      1000
  );

  var interval = sec / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = sec / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = sec / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = sec / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = sec / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(sec) + " seconds";
};
export const durationLabel = (ms: number) => {
  let distance = Math.abs(ms);
  const years = Math.floor(distance / 3.154e10);
  distance -= years * 3.154e10;
  const months = Math.floor(distance / 2592000000);
  distance -= months * 2592000000;
  const days = Math.floor(distance / 8.64e7);
  distance -= days * 8.64e7;
  const hours = Math.floor(distance / 3600000);
  distance -= hours * 3600000;
  const minutes = Math.floor(distance / 60000);
  distance -= minutes * 60000;
  const seconds = Math.floor(distance / 1000);
  return ` ${years > 0 ? `${years} ${years > 1 ? " Years" : " Year"}` : ""} ${
    months > 0 ? `${months} ${months > 1 ? " Months" : " Month"}` : ""
  }   ${days > 0 ? `${days} ${days > 1 ? " Days" : " Day"}` : ""}
          ${hours}:${("0" + minutes).slice(-2)}:${("0" + seconds).slice(-2)} 
        `;
};
export function addDurationToDate(date: Date, duration: Duration): Date {
  date = new Date(date);
  const milliseconds = duration.milliseconds || 0;
  const seconds = duration.seconds || 0;
  const minutes = duration.minutes || 0;
  const hours = duration.hours || 0;
  const days = duration.days || 0;
  const weeks = duration.weeks || 0;
  const months = duration.months || 0;
  const years = duration.years || 0;

  let newDate = new Date(date.getTime() + milliseconds);
  newDate.setSeconds(newDate.getSeconds() + seconds);
  newDate.setMinutes(newDate.getMinutes() + minutes);
  newDate.setHours(newDate.getHours() + hours);
  newDate.setDate(newDate.getDate() + days);
  newDate.setDate(newDate.getDate() + weeks * 7);
  newDate.setMonth(newDate.getMonth() + months);
  newDate.setFullYear(newDate.getFullYear() + years);

  return newDate;
}

export interface Duration {
  milliseconds?: number;
  seconds?: number;
  minutes?: number;
  hours?: number;
  days?: number;
  weeks?: number;
  months?: number;
  years?: number;
}
export const GetStartAndEndDateOfMonth = (date: Date | string | number) => {
  date = new Date(date);
  let start_date = new Date(date.getTime());
  start_date.setDate(1);
  start_date.setHours(0, 0, 0, 0);
  let end_date = new Date(date.getTime());
  end_date.setMonth(end_date.getMonth() + 1);
  end_date.setDate(0);
  end_date.setHours(23, 59, 59, 999);
  return { start_date, end_date };
};
export const isExpired = ({
  start,
  duration,
  between,
}: {
  start: Date;
  duration: Duration;
  between?: { start_date: Date; end_date: Date };
}) => {
  if (!between) {
    between = GetStartAndEndDateOfMonth(Date.now());
  }

  const expired_at = addDurationToDate(start, duration);

  return (
    expired_at.getTime() >= between.start_date.getTime() &&
    expired_at.getTime() <= between.end_date.getTime()
  );
};

export const getMonthsDiff = (d1: string | Date, d2: string | Date) => {
  d1 = new Date(d1);
  d2 = new Date(d2);
  var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth() + 1;
  months += d2.getMonth() + 1;
  return months <= 0 ? 0 : months;
};
export const getDaysDiff = (d1: string | Date, d2: string | Date) => {
  d1 = new Date(d1);
  d2 = new Date(d2);
  return moment(d2).diff(d1, "days");
};
