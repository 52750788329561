import {
  mdiCheck,
  mdiClose,
  mdiDotsVertical,
  mdiPlus,
  mdiRefresh,
} from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../../../context_providers/auth/authProvider";
import { useOrderDetailCtx } from "../../../../../../context_providers/order_detail_context";
import {
  ToastType,
  useToast,
} from "../../../../../../context_providers/toast/toast_ctx";
import { SubscriptionBillModel } from "../../../../../../service/models/orders/subscription_plan_model";
import { NewTransactionModel } from "../../../../../../service/models/orders/txn_model";
import { TransactionDataModel } from "../../../../../../service/models/trannsaction_model";
import { GetOrderPaymentApi } from "../../../../../../service/repos/order_repo";

import { MODULE, ACTION, PaymentRefType } from "../../../../../../utils/enums";
import { currencyFormat } from "../../../../../../utils/orders_utils";

import PaymentRowWidget from "./payment_row";

interface SubsPaymentSectionWidgetProps {
  // bill: SubscriptionBillModel;
  // disabled: boolean;
  location?: any;
}

const SubPaymentSectionWidget: FunctionComponent<
  SubsPaymentSectionWidgetProps
> = (props) => {
  const { isAuthorised, user, logout } = useAuth();
  const { bill_id, order_id } = useParams<{
    order_id: string;
    bill_id: string;
  }>();

  const { showToast } = useToast();

  const [bill, setBill] = useState<SubscriptionBillModel>();

  const [payments, setPayments] = useState<TransactionDataModel[]>([]);

  const {
    subBills,
    onPaymentAdded,
    onPaymentDeleted,
    onPaymentUpdated,
    balTxnAmt,
    getBalTxn,
  } = useOrderDetailCtx();
  const [useBal, setUseBal] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [formId, setFormId] = useState(0);

  const getTxns = async () => {
    const res = await GetOrderPaymentApi({
      order_id: order_id,
      ref_id: bill_id,
      ref_type: PaymentRefType.subscription,
    });
    if (res.success) {
      setPayments(res.data);
    } else showToast({ type: ToastType.error, text: res.error });
  };
  useEffect(() => {
    if (subBills.length) {
      const b = subBills.find((b) => b.bill_id === bill_id);
      console.log({ b, subBills, bill_id });

      setdisableAdd((b?.total ?? 0) - (b?.paid ?? 0) <= 0);
      setBill(b);
      getTxns();
      setDisabled(
        !isAuthorised({ module: MODULE.order, action: [ACTION.UPDATE] })
      );
    }
  }, [subBills]);
  useEffect(() => {
    if (bill) getTxns();
  }, [bill]);
  // useEffect(() => {
  //   if (addNew) getBalTxn();
  // }, [addNew]);

  const [disableAdd, setdisableAdd] = useState(false);

  // const disableAdd = true;
  return (
    <>
      <div className="flex justify-between items-end mt-5 ">
        <h3 className="font-semibold text-gray-500 flex gap-2">
          Payments (Bill # {bill_id}){" "}
          <span onClick={() => getTxns()} className="cursor-pointer">
            <Icon path={mdiRefresh} className="h-4 w-4"></Icon>
          </span>
        </h3>
        <div className="flex justify-end   text-sm items-center ">
          {addNew ? (
            <div className="flex gap-2">
              <div
                onClick={() => {
                  setAddNew(false);
                }}
                className="flex gap-1 items-center cursor-pointer rounded p-1 bg-red-100 hover:scale-105 duration-200 hover:text-red-600 hover:bg-red-200 text-red-400  transform"
              >
                <Icon path={mdiClose} className="h-4 w-4"></Icon>Cancel
              </div>
            </div>
          ) : (
            !disabled && (
              <div
                onClick={() => {
                  setAddNew(true);
                }}
                className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
              >
                <Icon path={mdiPlus} className="h-4 w-4"></Icon>Add
              </div>
            )
          )}
        </div>
      </div>

      <div className="bg-white rounded-md shadow p-3 my-2 text-sm">
        <div className="grid grid-cols-11 gap-3 ">
          <h4 className="font-semibold text-center ">
            {" "}
            <span className="text-base text-red-500">*</span>Date
          </h4>
          <h4 className="font-semibold text-center ">
            {" "}
            <span className="text-base text-red-500">*</span>Type
          </h4>
          <h4 className="font-semibold text-center ">
            {" "}
            <span className="text-base text-red-500">*</span>Mode
          </h4>
          <h4 className="font-semibold text-center col-span-2">Txn ID</h4>
          <h4 className="font-semibold text-center">
            {" "}
            <span className="text-base text-red-500">*</span>Amount
          </h4>
          <h4 className="font-semibold text-center col-span-2">Note</h4>
          <h4 className="font-semibold text-center">Status</h4>
          <h4 className="font-semibold text-center">File</h4>
          <h4 className="font-semibold text-center"> </h4>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        {payments.length === 0 ? (
          <div className="my-10 flex flex-col  items-center justify-center text-xl font-semibold text-gray-400">
            <p> No Payment Found for Bill # {bill_id}</p>
          </div>
        ) : (
          payments.map((payment, i) => (
            <PaymentRowWidget
              disabled={disabled}
              edit={false}
              key={payment.id}
              payment={payment}
              onDelete={onPaymentDeleted}
              onSubmit={onPaymentUpdated}
            />
          ))
        )}
        {addNew && !disabled && (
          <div className="   border-dashed  border-2 rounded-md border-green-300 p-2">
            {/* <div className="text-sm flex flex-col items-start ">
              <div className="flex flex-col gap-2 cursor-pointer    items-center ">
                <div className="flex gap-1">
                  <p>B/D Balance : </p>
                  <span className="font-semibold">
                    {rupeeFormat(balTxnAmt)}
                  </span>
                </div>
              
                <div className="flex gap-1">
                  <input
                    disabled={balTxnAmt === 0}
                    type="checkbox"
                    name=""
                    id="use_bal"
                    checked={useBal}
                    onChange={(e) => {
                      setUseBal(e.target.checked);
                    }}
                  />
                  <label htmlFor="use_bal" className="text-xs">
                    Use B/D Balance
                  </label>
                </div>
              </div>
              <p className="italic text-xs text-gray-400">
                Check the above checkbox to use the D/B Balance
              </p>
            </div> */}

            <PaymentRowWidget
              disabled={disabled}
              key={formId}
              edit={true}
              form_id={formId.toString()}
              onSubmit={(data) => {
                data.ref_id = bill_id;
                data.ref_type = PaymentRefType.subscription;
                onPaymentAdded({ data, use_bal: useBal });

                setFormId(Math.random());
              }}
            />
            <div className="flex justify-between">
              <p className="text-xs italic text-gray-400">
                Please fill required fields then hit + to add product.
              </p>
              <p className="text-xs italic text-gray-400">
                Note :Payment will reflect in order only after verified by
                Accounts department.
              </p>
            </div>
            <div className="flex justify-center my-3 gap-2">
              <button
                disabled={disableAdd}
                form={formId.toString()}
                className={`${
                  disableAdd
                    ? " cursor-not-allowed "
                    : "hover:scale-110 transform duration-300  "
                } rounded-full p-2 bg-myPrimaryColor`}
              >
                <Icon path={mdiPlus} className="h-5 w-5 text-white"></Icon>
              </button>
              {/* <button
                disabled={disableAdd}
                type="button"
                className={`${
                  disableAdd
                    ? " cursor-not-allowed "
                    : "hover:scale-110 transform duration-300  "
                } rounded-full p-2 bg-myPrimaryColor`}
              >
                <Icon path={mdiCheck} className="h-5 w-5 text-white"></Icon>
              </button> */}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SubPaymentSectionWidget;
