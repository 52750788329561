import { fetchPost } from "../../api_client";

export const PostQuery = async (data: { message: string }) => {
  try {
    let url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/erp-support/query`;
    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const PostModuleRequest = async (data: { module: string[] }) => {
  try {
    let url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/erp-support/access-request`;
    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
