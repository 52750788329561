import { FunctionComponent } from "react";
import { InputFields } from "./form";
export interface EmailInputPropsProps {
  alignment?: string;
  name: string;
  label: string;
  value: any;
  placeholder?: string;
  inputChange?: (data: any) => void;
  disabled?: boolean;
  required?: boolean;
  defaultValue?: any;
}

const EmailInput: FunctionComponent<EmailInputPropsProps> = (props) => {
  const {
    alignment,

    name,
    label,
    value,
    placeholder,
    inputChange,
    disabled = false,
    required = false,
    defaultValue,
  } = props;
  return (
    <div
      className={`grid ${alignment === "row" ? "grid-cols-3" : "grid-col-1"}`}
    >
      <label htmlFor={name} className="w">
        {label}
      </label>
      <input
        className="focus:outline-none p-2 bg-gray-100 rounded col-span-2"
        id={name}
        name={name}
        value={value}
        defaultValue={defaultValue}
        onChange={(e) => {
          if (inputChange) inputChange(e.target.value);
        }}
        type={InputFields.EMAIL}
        placeholder={placeholder ?? name}
        disabled={disabled}
        required={required}
        title={label + " is required"}
      />
    </div>
  );
};
export default EmailInput;
