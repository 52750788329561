import { Route, Redirect, useLocation } from "react-router-dom";
import { menuUrls, urls } from "../../../utils/urls";

import Layout from "../../new_components/layout";
import TaskSessionProvider from "../../../context_providers/task_session_ctx";
import { useAuth } from "../../../context_providers/auth/authProvider";
import { Component } from "react";
import { RouteParam } from "../../../routes";

const ProtectedRoute = ({
  component: Component,
  fullScreen,
  main_module,
  module,
  action,
  operator,
  isPrivate,
  path,
  ...rest
}: any) => {
  const location = useLocation();
  const { user, isAuthorised } = useAuth();
  const { pathname } = location;

  const links = menuUrls(main_module);
  const hasPermission = isAuthorised({ module, action, operator });

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isPrivate && !user) {
          return <Redirect to={urls.login} />;
        } else if (isPrivate && !hasPermission) {
          return <Redirect to={urls.notAllowed} />;
        } else {
          // if (
          //   [
          //     urls.my_operation_task_detail,
          //     urls.my_operation_task,
          //     urls.my_recent_operation_task,
          //     urls.emp_attendance,
          //     urls.emp_attendace_history,
          //     urls.operation_engineer_task,
          //   ].includes(pathname)
          // )
          //   return (
          //     <TaskSessionProvider>
          //       <>
          //         {fullScreen ? (
          //           <Component {...rest} {...props} />
          //         ) : (
          //           <Layout urls={links}>
          //             <Component {...rest} {...props} />
          //           </Layout>
          //         )}
          //       </>
          //     </TaskSessionProvider>
          //   );
          // else
          return (
            <>
              {fullScreen ? (
                <Component {...rest} {...props} />
              ) : (
                <Layout urls={links}>
                  <Component {...rest} {...props} />
                </Layout>
              )}
            </>
          );
        }
      }}
    />
  );
};

export default ProtectedRoute;
