import { mdiChevronRight, mdiMagnify, mdiMapMarker } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useCallback, useState } from "react";
import {
  ToastType,
  useToast,
} from "../../../../context_providers/toast/toast_ctx";
import { add, debounce } from "lodash";
import { fetchGet, toQueryString } from "../../../../service/api_client";
import {
  AddressModel,
  getDefaultAddressValue,
  getFullAddress,
} from "../../../../service/models/address_model";
import LoadingWidget from "../../../components/loading_spinner";
import { getCityStateApi } from "../../../../service/repos/address/address_repo";
import LocationPicker from "./LocationPicker";
export interface PlaceDetailResponse {
  address_components: {
    long_name: string;
    short_name: string;
    types: string[];
  }[];
  formatted_address: string;
  geometry: {
    location: {
      lat: number;
      lng: number;
    };
    viewport: {
      northeast: {
        lat: number;
        lng: number;
      };
      southwest: {
        lat: number;
        lng: number;
      };
    };
  };
}
export let parseAddressFromPlaceApi = (data: PlaceDetailResponse) => {
  let values: {
    line1: string;
    line2: string;
    city: string;
    state: string;
    pincode: number;
    country: string;
    coordinates: [long: number, lat: number];
  } = {
    line1: "",
    line2: "",
    city: "",
    state: "",
    pincode: 0,
    country: "",
    coordinates: [0, 0],
  };

  const subpremise = data.address_components.filter((d: any) =>
    d.types.includes("subpremise")
  );
  if (subpremise && subpremise.length)
    values.line1 = subpremise.reduce(
      (pv, v) => pv + (pv ? ", " : "") + v.long_name,
      values.line1
    );
  const point_of_interest = data.address_components.filter((d: any) =>
    d.types.includes("point_of_interest")
  );
  if (point_of_interest && point_of_interest.length)
    values.line1 = point_of_interest.reduce(
      (pv, v) => pv + (pv ? ", " : "") + v.long_name,
      values.line1
    );
  const premise = data.address_components.filter((d: any) =>
    d.types.includes("premise")
  );
  if (premise && premise.length)
    values.line1 = premise.reduce(
      (pv, v) => pv + (pv ? ", " : "") + v.long_name,
      values.line1
    );
  const route = data.address_components.filter((d: any) =>
    d.types.includes("route")
  );
  if (route && route.length)
    values.line2 = route.reduce(
      (pv, v) => pv + (pv ? ", " : "") + v.long_name,
      values.line2
    );
  const neighborhood = data.address_components.filter((d: any) =>
    d.types.includes("neighborhood")
  );
  if (neighborhood && neighborhood)
    values.line2 = neighborhood.reduce(
      (pv, v) => pv + (pv ? ", " : "") + v.long_name,
      values.line2
    );

  const sublocality = data.address_components.filter((d: any) =>
    d.types.includes("sublocality")
  );
  if (sublocality)
    values.line2 = sublocality.reduce(
      (pv, v) => pv + ", " + v.long_name,
      values.line2
    );
  const locality = data.address_components.find((d: any) =>
    d.types.includes("locality")
  );
  if (locality) values.city = locality.long_name;

  const pincode = data.address_components.find((d: any) =>
    d.types.includes("postal_code")
  );
  if (pincode) values.pincode = Number(pincode.long_name);

  const administrative_area_level_1 = data.address_components.find((d: any) =>
    d.types.includes("administrative_area_level_1")
  );
  if (administrative_area_level_1)
    values.state = administrative_area_level_1.long_name;

  const country = data.address_components.find((d: any) =>
    d.types.includes("country")
  );
  if (country) values.country = country.long_name;

  if (data.geometry.location) {
    values.coordinates = [
      data.geometry.location.lng,
      data.geometry.location.lat,
    ];
  }

  let address: AddressModel = {
    location: {
      type: "Point",
      coordinates: values.coordinates,
    },
    line1: values.line1,
    line2: values.line2,
    landmark: "",
    city: String(values.city).toUpperCase(),
    state: String(values.state).toUpperCase(),
    country: String(values.country).toUpperCase(),
    pincode: Number(values.pincode),
    full_address: "",
  };

  address.full_address = getFullAddress(address);
  return address;
};

interface AddressSugesstion {
  description: string;
  matched_substrings: [
    {
      length: number;
      offset: number;
    }
  ];
  place_id: string;
  reference: string;
  // structured_formatting: {
  //   main_text: string;
  //   main_text_matched_substrings: [
  //     {
  //       length: number;
  //       offset: number;
  //     }
  //   ];
  //   secondary_text: string;
  // };
}
interface AddressPickerWidgetProps {
  location_required?: boolean;
  data?: AddressModel;
  onSubmit: (data: AddressModel) => void;
  onCancel: () => void;
}

const AddressPickerWidget: FunctionComponent<AddressPickerWidgetProps> = (
  props
) => {
  const [edit, setEdit] = useState(props.data ? false : true);
  const [suggestions, setSuggestions] = useState<AddressSugesstion[]>([]);
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [address, setAddress] = useState<AddressModel>(
    props.data ?? getDefaultAddressValue()
  );
  const getData = async (prop: {
    search?: string;
    page: number;
    count: number;
    all?: boolean;
  }) => {
    setLoading(true);
    try {
      const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/address/autocomplete`;

      const res = await fetchGet(url + toQueryString(prop));

      if (res.success) {
        setSuggestions(res.data);
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
      //   return { success: false, error: error.message };
    }
    setLoading(false);
  };
  const getPlaceData = async (prop: { place_id: string }) => {
    setLoading(true);
    try {
      const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/address/${prop.place_id}`;

      const res = await fetchGet(url);
      if (res.success) {
        const data: PlaceDetailResponse = res.data;
        if (data) {
          let address = parseAddressFromPlaceApi(data);
          props.onSubmit(address);
        }
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
      //   return { success: false, error: error.message };
    }
    setLoading(false);
  };

  const debouncedHandler = useCallback(
    debounce((query) => {
      if (query) getData({ search: query, page: 1, count: 1, all: true });
      else setSuggestions([]);
    }, 1000),
    []
  );

  const getCityState = async (pincode: number) => {
    setLoading(true);
    const res = await getCityStateApi(pincode);

    if (res.success) {
      if (!res.data) {
        setLoading(false);
        return showToast({
          text: "invalid zip code",
          type: ToastType.error,
        });
      }
      const { city, state, pincode } = res.data;
      if (city && state)
        setAddress((o) => ({ ...o, city, state, pincode, country: "INDIA" }));
    } else showToast({ type: ToastType.error, text: res.error ?? "" });
    setLoading(false);
  };
  const [showMap, setShowMap] = useState(false);
  if (showMap) {
    return (
      <div className="relative w-full h-full">
        <LocationPicker
          address={address}
          onSubmit={function (_address: AddressModel): void {
            setAddress((o) => ({ ...o, location: _address.location }));
            setShowMap(false);
          }}
        />
        <div className="absolute top-3 left-3">
          <button
            onClick={() => {
              setShowMap(false);
            }}
            className="rounded-3xl border px-5 py-1 bg-blue-50 text-blue-500 border-blue-400 "
          >
            Back
          </button>
        </div>
      </div>
    );
  }
  return (
    <div className="flex flex-col gap-2  ">
      {/* <div className="flex items-center rounded-lg bg-white w-full px-1 py-2 gap-2 relative">
        <Icon path={mdiMapMarker} size={1} className="text-gray-500"></Icon>
        <input
          onChange={(e) => {
            debouncedHandler(e.target.value);
          }}
          type="text"
          className="focus:outline-none border-none outline-none p-2 w-full "
          placeholder="Search"
        />
      </div>
      <div className="flex flex-col bg-white rounded">
        {suggestions.map((suggestion, i) => {
          const offset = suggestion.matched_substrings[0].offset;
          const length = suggestion.matched_substrings[0].length;
          const match = suggestion.description.substring(offset, length);
          const prefix = suggestion.description.substring(0, offset);
          const postfix = suggestion.description.substring(offset + length);
          return (
            <button
              onClick={() => getPlaceData({ place_id: suggestion.place_id })}
              key={i}
              className="hover:bg-gray-50  border-b p-3 text-gray-500 flex justify-between items-center w-full"
            >
              <p className="text-left h-12 overflow-hidden overflow-ellipsis">
                <span>{prefix}</span>
                <span className="font-semibold text-gray-800">{match}</span>
                <span>{postfix}</span>
              </p>
              <Icon path={mdiChevronRight} size={1} />
            </button>
          );
        })}
      </div> */}
      <div className="bg-white p-2 flex flex-col gap-2">
        <div className="grid grid-cols-1 md:grid-cols-3 items-center">
          <label htmlFor="line_1">Line 1*</label>
          <textarea
            rows={5}
            disabled={!edit}
            required
            className={`col-span-2 p-2 rounded focus:outline-none border ${
              !edit && "bg-gray-100 "
            }`}
            id="line_1"
            value={address?.line1}
            onChange={(e) =>
              setAddress((o) => ({
                ...o!,
                line1: e.target.value,
              }))
            }
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 items-center">
          <label htmlFor="line_2">Line 2</label>
          <textarea
            disabled={!edit}
            className={`col-span-2 p-2 rounded focus:outline-none border ${
              !edit && "bg-gray-100 "
            }`}
            id="line_2"
            value={address.line2}
            onChange={(e) =>
              setAddress((o) => ({
                ...o!,
                line2: e.target.value,
              }))
            }
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 items-center">
          <label htmlFor="mobile">City *</label>
          <input
            disabled={!edit}
            required
            type="text"
            className={`col-span-2 p-2 rounded focus:outline-none border ${
              !edit && "bg-gray-100 "
            }`}
            id="mobile"
            value={address.city}
            onChange={(e) =>
              setAddress((o) => ({
                ...o!,
                city: e.target.value,
              }))
            }
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 items-center">
          <label htmlFor="state">State *</label>
          <input
            disabled={!edit}
            required
            type="text"
            className={`col-span-2 p-2 rounded focus:outline-none border ${
              !edit && "bg-gray-100 "
            }`}
            id="state"
            value={address.state}
            onChange={(e) =>
              setAddress((o) => ({
                ...o!,
                state: e.target.value,
              }))
            }
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 items-center">
          <label htmlFor="country">Country *</label>
          <input
            disabled={!edit}
            required
            type="text"
            className={`col-span-2 p-2 rounded focus:outline-none border ${
              !edit && "bg-gray-100 "
            }`}
            id="country"
            value={address.country}
            onChange={(e) =>
              setAddress((o) => ({
                ...o!,
                country: e.target.value,
              }))
            }
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 items-center">
          <label htmlFor="pincode">Pincode *</label>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (address.pincode?.toString().length === 6) {
                getCityState(address.pincode);
              } else {
                return showToast({
                  text: "Please enter 6 digits postal code",
                  type: ToastType.error,
                });
              }
            }}
            className="flex gap-2 col-span-2  items-center"
          >
            <input
              disabled={!edit}
              type="number"
              className={`col-span-2 p-2 rounded focus:outline-none border ${
                !edit && "bg-gray-100 "
              }`}
              id="pincode"
              placeholder="Postal code"
              pattern="[0-9]{6}"
              title="Please enter a 6 digit pincode"
              value={address.pincode ? address.pincode : ""}
              onChange={(e) => {
                setAddress((o) => ({
                  ...o!,
                  pincode: Number(e.target.value),
                }));

                // if (e.target.value.length <= 6)
                //   setCustomer((o) => ({
                //     ...o!,
                //     address: {   ...o!, pincode: v },
                //   }));
                // if (e.target.value.length === 6) {
                //   getCityState(v);
                // }
              }}
            />
            <button
              className="p-2 cursor-pointer bg-gray-200 rounded-full"
              onClick={() => {
                if (address.pincode?.toString().length === 6) {
                  getCityState(address.pincode);
                } else {
                  return showToast({
                    text: "Please enter 6 digits postal code",
                    type: ToastType.error,
                  });
                }
              }}
            >
              <Icon path={mdiMagnify} className="w-5 h-5"></Icon>
            </button>
          </form>
        </div>

        <hr />
        <div className="grid grid-cols-1 md:grid-cols-3 items-center">
          <label htmlFor="mobile">
            Latitude {props.location_required ? "*" : ""}{" "}
          </label>
          <input
            disabled={!edit}
            required
            type="number"
            className={`col-span-2 p-2 rounded focus:outline-none border ${
              !edit && "bg-gray-100 "
            }`}
            id="mobile"
            value={
              address.location?.coordinates[1]
                ? address.location?.coordinates[1]
                : ""
            }
            onChange={(e) => {
              setAddress((o) => {
                const location = o.location ?? {
                  type: "Point",
                  coordinates: [0, 0],
                };
                location.coordinates[1] = Number(e.target.value);
                return {
                  ...o!,
                  location,
                };
              });
            }}
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 items-center">
          <label htmlFor="state">
            Longitude {props.location_required ? "*" : ""}{" "}
          </label>
          <input
            disabled={!edit}
            required
            type="text"
            className={`col-span-2 p-2 rounded focus:outline-none border ${
              !edit && "bg-gray-100 "
            }`}
            id="state"
            value={
              address.location?.coordinates[0]
                ? address.location?.coordinates[0]
                : ""
            }
            onChange={(e) => {
              setAddress((o) => {
                const location = o.location ?? {
                  type: "Point",
                  coordinates: [0, 0],
                };
                location.coordinates[0] = Number(e.target.value);
                return {
                  ...o!,
                  location,
                };
              });
            }}
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 items-center">
          <label htmlFor="state"> </label>

          <button
            disabled={!edit}
            onClick={() => {
              setShowMap(true);
            }}
            className={`col-span-2 p-2 rounded focus:outline-none border ${
              edit
                ? "bg-purple-50 border border-purple-300 text-purple-500"
                : "bg-gray-100 "
            }`}
          >
            Mark on map
          </button>
        </div>
      </div>
      {edit ? (
        <div className="grid grid-cols-2 gap-5 my-2">
          <button
            onClick={() => {
              setEdit(false);
              if (props.data) {
                props.onCancel();
                return;
              }
              setAddress((o) => ({
                ...(props.data ?? getDefaultAddressValue()),
              }));
            }}
            className="bg-blue-50 text-blue-500 border-blue-300 border rounded-lg px-3 py-1 text-center"
          >
            Cancel
          </button>

          <button
            onClick={() => {
              console.log({ address, req: props.location_required });

              if (
                !address.line1 ||
                !address.city ||
                !address.state ||
                !address.country ||
                (props.location_required &&
                  (!address.location?.coordinates[0] ||
                    !address.location?.coordinates[1]))
              ) {
                return showToast({
                  text: "Please fill required fields !",
                  type: ToastType.error,
                });
              }

              address.full_address = getFullAddress(address);
              props.onSubmit(address);
            }}
            className="bg-green-50 text-green-500 border-green-300 border rounded-lg px-3 py-1 text-center"
          >
            Submit
          </button>
        </div>
      ) : (
        <button
          onClick={() => setEdit(true)}
          className="bg-blue-50 text-blue-500 border-blue-300 border rounded-lg px-3 py-1 text-center w-full"
        >
          Edit
        </button>
      )}
      {loading && <LoadingWidget loading={true} />}
    </div>
  );
};

export default AddressPickerWidget;
