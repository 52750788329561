import {
  mdiFile,
  mdiCheck,
  mdiClose,
  mdiPencil,
  mdiDelete,
  mdiChevronUp,
  mdiChevronDown,
  mdiContentCopy,
} from "@mdi/js";
import Icon from "@mdi/react";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import {
  useModal,
  ModalType,
} from "../../../../../context_providers/modal/modal_context";
import {
  useToast,
  ToastType,
} from "../../../../../context_providers/toast/toast_ctx";
import { TransactionDataModel } from "../../../../../service/models/trannsaction_model";
import { UTCToLocalDate } from "../../../../../utils/date_util";
import {
  PaymentMode,
  TransactionType,
  TransactionStatus,
  PaymentRefType,
} from "../../../../../utils/enums";
import { currencyFormat } from "../../../../../utils/orders_utils";
import TransactionAttachmentView from "../../../orders/order_detail/payments/txn_attachment";
const defaultItem: TransactionDataModel = {
  type: "",
  payment_date: new Date(),
  payment_mode: "",

  amount: 0,
  status: "",
};
interface PaymentRowWidgetProps {
  payment?: TransactionDataModel;
  onStatusChanged: (id: string, status: string) => void;
  //   onSubmit: (data: TransactionDataModel) => void;
  edit: boolean;
  disabled: boolean;
  //   onDelete?: (i: TransactionDataModel) => void;
  //   form_id?: string;
  //   use_bal?: boolean;
  sl_no?: number;
}

const PaymentRowWidget: FunctionComponent<PaymentRowWidgetProps> = (props) => {
  const { showModal } = useModal();
  const { showToast } = useToast();
  const [showMore, setShowMore] = useState(false);
  const { edit } = props;
  const [payment, setPayment] = useState<TransactionDataModel>({
    ...(props.payment ?? defaultItem),
  });
  const [editable, setEditable] = useState(false);
  useEffect(() => {
    setEditable(edit);
  }, []);

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          //   onDone();
        }}
        className="bg-white rounded-md shadow  text-sm overflow-hidden hover:bg-gray-200   p-2 "
      >
        <div className="flex items-center">
          <p className="font-semibold text-xs ">{props.sl_no}</p>
          <div className="w-px h-4 bg-gray-400 mx-1"></div>
          <div className="grid grid-cols-12 gap-3 items-center  text-xs hover:">
            <div className="flex items-center  ">
              {/* <div className="w-px h-4 bg-gray-400 "></div> */}
              <div className=" whitespace-nowrap ">
                {moment(payment.payment_date).format("DD/MM/YY hh:mm a")}
              </div>
            </div>
            <div className="text-center  ">{payment.type}</div>
            <div className="text-center  ">{payment.payment_mode}</div>
            <div className="text-center  ">{payment.ref_type ?? "--"}</div>
            {payment.ref_id ? (
              <div className="flex col-span-2 items-center  gap-1 text-xs p-1  ">
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(payment.ref_id ?? "");
                    showToast({
                      type: ToastType.info,
                      text: "Copied to clipboard !",
                    });
                  }}
                  className=""
                >
                  <Icon path={mdiContentCopy} className="w-3 h-3"></Icon>
                </button>

                <div
                  title={payment.ref_id}
                  className="text-left overflow-ellipsis overflow-hidden w-full "
                >
                  {payment.ref_id ?? "--"}
                </div>
              </div>
            ) : (
              <div className="col-span-2 text-center ">--</div>
            )}
            {payment.txn_id ? (
              <div className="flex col-span-2 items-center  gap-1 text-xs p-1 rounded ">
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(payment.txn_id ?? "");
                    showToast({
                      type: ToastType.info,
                      text: "Copied to clipboard !",
                    });
                  }}
                  className=" "
                >
                  <Icon path={mdiContentCopy} className="w-3 h-3"></Icon>
                </button>
                <div
                  title={payment.txn_id}
                  className="text-left overflow-ellipsis overflow-hidden w-full "
                >
                  {payment.txn_id ?? "--"}
                </div>
              </div>
            ) : (
              <div className="col-span-2 text-center ">--</div>
            )}
            <div className="text-right  ">{currencyFormat(payment.amount)}</div>

            {!editable || props.disabled ? (
              <div
                className={`text-center font-semibold ${
                  payment.status === TransactionStatus.SUCCESS
                    ? "text-green-400"
                    : payment.status === TransactionStatus.PENDING
                    ? "text-yellow-400"
                    : "text-red-400"
                }`}
              >
                {payment.status}
              </div>
            ) : (
              <select
                disabled={!editable || props.disabled}
                // disabled={
                //   !isAuthorised(
                //     MODULE.PAYMENT,
                //     [ACTION.UPDATE],
                //     authState.auth
                //   )
                // }
                id="status"
                name="status"
                className="  p-1 rounded bg-gray-100 focus:outline-none"
                required
                value={payment.status ?? ""}
                onChange={(e) => {
                  setPayment((o) => ({ ...o, status: e.target.value }));
                }}
              >
                <option value="" hidden>
                  Select...
                </option>
                {Object.values(TransactionStatus).map((item, i: number) => (
                  <option key={i} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            )}
            {props.payment?.id ? (
              <div
                className=" text-blue-400 cursor-pointer text-center"
                onClick={() => {
                  showModal({
                    type: ModalType.modal,
                    title: "Attachments",
                    container: (id) => (
                      <TransactionAttachmentView txn_id={payment.id!} />
                    ),
                  });
                }}
              >
                View
              </div>
            ) : (
              <div className="flex justify-center ">
                {payment.files && payment.files.length > 0 ? (
                  <div className="h-14 w-14 flex flex-col items-center">
                    {payment.files[0].type.startsWith("image") ? (
                      <img src={URL.createObjectURL(payment.files[0])} alt="" />
                    ) : (
                      <embed
                        src={URL.createObjectURL(payment.files[0])}
                        type="application/pdf"
                        className="h-full w-full  object-cover"
                      ></embed>
                    )}
                    <div
                      onClick={() => {
                        if (editable)
                          setPayment((o) => ({
                            ...o,
                            files: [],
                          }));
                      }}
                      className="flex gap-1 cursor-pointer items-center  text-xs "
                    >
                      Delete
                    </div>
                  </div>
                ) : (
                  <div>
                    <label
                      htmlFor={
                        props.payment ? `${props.payment.id}-file` : "file"
                      }
                      className={`border rounded px-2 py-1 flex w-min text-green-400 gap-2 cursor-pointer`}
                    >
                      Select <Icon path={mdiFile} className="h-4 w-4"></Icon>
                    </label>
                    <input
                      id={props.payment ? `${props.payment.id}-file` : "file"}
                      hidden
                      disabled={!editable || props.disabled}
                      onChange={(e) => {
                        setPayment((o) => ({
                          ...o,
                          files: [...(e.target.files ?? [])],
                        }));
                      }}
                      type="file"
                      accept="image/*,application/pdf"
                    />
                  </div>
                )}
              </div>
            )}
            <div className="flex gap-2 items-center justify-end">
              {props.payment && !props.disabled && (
                <>
                  <div className="flex justify-center gap-2">
                    {editable ? (
                      <>
                        <div
                          onClick={() => {
                            if (
                              !window.confirm(
                                "Are you sure to change payment status to :" +
                                  payment.status
                              )
                            )
                              return;
                            props.onStatusChanged(payment.id!, payment.status);
                            setEditable(false);
                          }}
                          className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                        >
                          <Icon path={mdiCheck} className="h-4 w-4"></Icon>
                        </div>
                        <div
                          onClick={() => {
                            setPayment(props.payment!);
                            setEditable(false);
                          }}
                          className="bg-red-100 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                        >
                          <Icon path={mdiClose} className="h-4 w-4"></Icon>
                        </div>
                      </>
                    ) : (
                      <div
                        onClick={() => setEditable(true)}
                        className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                      >
                        <Icon path={mdiPencil} className="h-4 w-4"></Icon>
                      </div>
                    )}
                  </div>
                  {/* <div
                    onClick={() => {
                      if (!window.confirm("Are you sure !")) return;
                      if (props.onDelete) props.onDelete(payment);
                    }}
                    className="bg-red-200 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                  >
                    <Icon path={mdiDelete} className="h-4 w-4"></Icon>
                  </div> */}
                </>
              )}
              {props.payment && (
                <div
                  onClick={() => setShowMore((o) => !o)}
                  className="p-1 bg-gray-100 rounded cursor-pointer hover:bg-gray-200"
                >
                  <Icon
                    path={showMore ? mdiChevronUp : mdiChevronDown}
                    className="h-4 w-4"
                  ></Icon>
                </div>
              )}
            </div>
          </div>
        </div>
        {showMore && props.payment && (
          <div className="px-2 py-1">
            <hr className=" " />
            <div className="text-xs text-gray-400">
              <p>Id : {props.payment.id}</p>
              <p>Parent Txn id :{props.payment.parent_txn_id}</p>
              <p>Note :{props.payment.comment}</p>
            </div>
            <div className="flex   justify-between gap-2 my-1">
              <p className="text-xs ">
                Created by :<br />
                <div className="flex gap-1">
                  <div className="">
                    <b>{props.payment.created_by_name ?? "N/A"}</b>
                  </div>
                  <div className="">
                    {props.payment.created_at
                      ? moment(props.payment.created_at).format(
                          "DD MMM YYYY h:mm a"
                        )
                      : "--"}
                  </div>
                </div>
              </p>
              <p className="text-xs ">
                Updated by :<br />
                <div className="flex gap-1">
                  <div className="">
                    <b>{props.payment.updated_by_name ?? "N/A"}</b>
                  </div>
                  <div className="">
                    {props.payment.updated_at
                      ? moment(props.payment.updated_at).format(
                          "DD MMM YYYY h:mm a"
                        )
                      : "--"}
                  </div>
                </div>
              </p>
              {props.payment.verified_by && (
                <p
                  className={`text-xs  text-${
                    props.payment.status === TransactionStatus.SUCCESS
                      ? "green"
                      : "red"
                  }-400`}
                >
                  Verified by :<br />
                  <div className="flex gap-1">
                    <div className="">
                      <b>{props.payment.verified_by_name ?? "N/A"}</b>
                    </div>
                    <div className="">
                      {props.payment.verified_at
                        ? moment(props.payment.verified_at).format(
                            "DD MMM YYYY h:mm a"
                          )
                        : "--"}
                    </div>
                  </div>
                </p>
              )}
            </div>
          </div>
        )}
      </form>
    </>
  );
};

export default PaymentRowWidget;
