import { fetchGet, toQueryString } from "../../../../../service/api_client";

export const GetEmpStrength = async (data: { fromDate: any; toDate: any }) => {
  try {
    const url = `${
      process.env.REACT_APP_BACKEND_BASE_URL
    }/api/v3/hrm/analytic/emp-strength${toQueryString(data)}`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const GetEmpMood = async (data: { fromDate: any; toDate: any }) => {
  try {
    const url = `${
      process.env.REACT_APP_BACKEND_BASE_URL
    }/api/v3/hrm/analytic/emp-mood${toQueryString(data)}`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const GetAttrition = async (data: { fromDate: any; toDate: any }) => {
  try {
    const url = `${
      process.env.REACT_APP_BACKEND_BASE_URL
    }/api/v3/hrm/analytic/emp-attrition${toQueryString(data)}`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const GetLeaveData = async (data: {
  fromDate: any;
  toDate: any;
  department?: any;
  uid?: string;
}) => {
  try {
    const url = `${
      process.env.REACT_APP_BACKEND_BASE_URL
    }/api/v3/hrm/analytic/emp-leave${toQueryString(data)}`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
