import { FunctionComponent } from "react";
import { VehiclePrice } from "../../../../../infrastructure/models/car_model";
import GenerateForm, {
  FormFieldModel,
  InputFields,
} from "../../../../new_components/common/form/form";

interface AddPriceWidgetProps {
  price?: VehiclePrice;
  onSubmit: (data: any) => void;
}
const AddPriceWidget: FunctionComponent<AddPriceWidgetProps> = (props) => {
  return (
    <div className="md:p-32">
      <GenerateForm
        formFields={priceFields}
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-2 gap-y-5 p-2"
        values={{}}
        onSubmit={function (data: { [key: string]: any }): void {
          props.onSubmit(data);
        }}
        onFormValueChanged={function (e: any): void {}}
        defaultValue={props.price ?? {}}
      />
    </div>
  );
};

export default AddPriceWidget;

export const priceFields: FormFieldModel[] = [
  {
    inputType: InputFields.TEXT,
    name: "state_display",
    label: "state",
    placeholder: "Enter state",
    alignment: "col",
    required: true,
  },
  {
    inputType: InputFields.TEXT,
    name: "city_display",
    label: "City",
    placeholder: "Enter city",
    alignment: "col",
    required: true,
  },
  {
    inputType: InputFields.NUMBER,
    name: "on_road_price",
    label: "On road price",
    placeholder: "Enter on road price",
    alignment: "col",
    required: true,
  },
  {
    inputType: InputFields.NUMBER,
    name: "ex_showroom_price",
    label: "Ex-showroom price",
    placeholder: "Enter ex-sjowroom price",
    alignment: "col",
    required: true,
  },
  {
    inputType: InputFields.NUMBER,
    name: "rto_cost",
    label: "RTO cost",
    placeholder: "Enter RTO cost",
    alignment: "col",
    required: true,
  },
  {
    inputType: InputFields.NUMBER,
    name: "insurance_cost",
    label: "Insurance cost",
    placeholder: "Enter insurance cost",
    alignment: "col",
    required: true,
  },
  {
    inputType: InputFields.NUMBER,
    name: "other_costs",
    label: "Others",
    placeholder: "Enter other",
    alignment: "col",
    required: true,
  },
];
