import React from "react";
import ReactDOM from "react-dom";
import dotEnv from "dotenv";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import ToastProvider from "./context_providers/toast/toast_ctx";
import ModalProvider from "./context_providers/modal/modal_context";
import Maintainence from "./ui/pages/maintainence";

dotEnv.config();
// store.dispatch(AuthInitializeThunk());
export const isProduction = process.env.NODE_ENV === "production";
ReactDOM.render(
  <React.Fragment>
    {/* <Provider store={store}> */}
    <ToastProvider>
      <App />
    </ToastProvider>
    {/* <Maintainence /> */}
    {/* </Provider> */}
  </React.Fragment>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
