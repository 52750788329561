import { mdiTrayArrowDown } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useCallback } from "react";
import { useDropzone } from "react-dropzone";

interface FileDropZoneProps {
  accept?: string;
  onDrop: (files: File[]) => void;
  multiple?: boolean;
}

const FileDropZone: FunctionComponent<FileDropZoneProps> = ({
  accept,
  onDrop,
  multiple,
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    multiple: multiple ?? false,
  });
  return (
    <div
      {...getRootProps()}
      className="h-full w-full flex justify-center items-center border rounded-lg border-green-500 text-gray-400 border-dashed p-10"
    >
      <input {...getInputProps()} />
      <div className=" text-sm flex flex-col justify-center items-center text-center">
        <Icon path={mdiTrayArrowDown} className="h-20 w-20 "></Icon>
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>Drag & drop some files here, or click to select files</p>
        )}
      </div>
    </div>
  );
};

export default FileDropZone;
