import {
  fetchDelete,
  fetchGet,
  fetchPost,
  toQueryString,
} from "../../api_client";
import { AcademyVideoModel } from "../../models/academy";

export const GetAcademyVideoData = async (data: {
  page: number;
  count: number;
  category?: string;
  search?: string;
  all?: boolean;
}) => {
  try {
    let url = `${
      process.env.REACT_APP_BACKEND_BASE_URL
    }/api/v3/academy${toQueryString(data)}`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const UploadVideo = async (data: AcademyVideoModel) => {
  try {
    let url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/academy`;
    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const GetSaveVideo = async (user_id?: number) => {
  try {
    let url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/academy/user/${user_id}/saved`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const SaveVideo = async (
  data: { video_id: string },
  user_id?: number
) => {
  try {
    let url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/academy/user/${user_id}/saved`;
    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const CreateWatched = async (
  data: { video_id: string },
  user_id?: number
) => {
  try {
    let url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/academy/user/${user_id}/watched`;
    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const GetCreateWatched = async (user_id?: number) => {
  try {
    let url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/academy/user/${user_id}/watched`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const DeleteSavedVideo = async (id: string, user_id: number) => {
  try {
    let url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/academy/user/${user_id}/saved/${id}`;
    const res = await fetchDelete(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const DeleteWatchedVideo = async (id: string, user_id: number) => {
  try {
    let url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/academy/user/${user_id}/watched/${id}`;
    const res = await fetchDelete(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const DeleteUploadVideo = async (video_id: string) => {
  try {
    let url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/academy/${video_id}`;
    const res = await fetchDelete(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
