import { ApiResponse } from "../../models/response";
import axios from "axios";
import { fetchGet, fetchPost, getHeaders } from "../../api_client";
import { stateData } from "../../../utils/states_data";
import ProductModel from "../../models/product_model";
import { UserModel } from "../../../context_providers/auth/user_model";
// ${process.env.REACT_APP_BACKEND_BASE_URL}
// export const products: ProductModel[] = [
//   {
//     id: 1,
//     name: "Kazam 3.3",
//     price: 18000,
//     sale_price: 12600,
//     hsn: "85044030",
//   },
//   {
//     id: 2,
//     name: "Kazam Mini with Wifi",
//     price: 7500,
//     sale_price: 4999,
//     hsn: "85044030",
//   },
//   {
//     id: 3,
//     name: "Kazam Mini with Sim",
//     price: 9000,
//     sale_price: 5999,
//     hsn: "85044030",
//   },
//   {
//     id: 4,
//     name: "Kazam LEV AC",
//     price: 18000,
//     sale_price: 12600,
//     hsn: "85044030",
//   },
//   {
//     id: 5,
//     name: "CMS-Basic",
//     price: 18000,
//     sale_price: 12600,
//     hsn: "85044030",
//   },
//   {
//     id: 6,
//     name: "CMS-Premium",
//     price: 18000,
//     sale_price: 12600,
//     hsn: "85044030",
//   },
//   {
//     id: 7,
//     name: "CMS-Enterprise",
//     price: 18000,
//     sale_price: 12600,
//     hsn: "85044030",
//   },
// ];

export const itemType = { goods: "Goods", service: "Service" };
export const GetProductsApi = async (): Promise<ApiResponse> => {
  const peroducts: ProductModel[] = [
    {
      type: itemType.goods,
      id: 1,
      name: "Kazam 3.3",
      price: 18000,
      sale_price: 12600,
      hsn: "85044030",
    },
    {
      id: 2,
      type: itemType.goods,
      name: "Kazam Mini with Wifi",
      price: 7500,
      sale_price: 4999,
      hsn: "85044030",
    },
    {
      id: 3,
      type: itemType.goods,
      name: "Kazam Mini with Sim",
      price: 9000,
      sale_price: 5999,
      hsn: "85044030",
    },
    {
      id: 4,
      type: itemType.goods,
      name: "Kazam LEV AC",
      price: 18000,
      sale_price: 12600,
      hsn: "85044030",
    },
    {
      id: 5,
      type: itemType.service,
      name: "CMS-Basic",
      price: 18000,
      sale_price: 12600,
      hsn: "85044030",
    },
    {
      id: 6,
      type: itemType.service,
      name: "CMS-Premium",
      price: 18000,
      sale_price: 12600,
      hsn: "85044030",
    },
    {
      id: 7,
      type: itemType.service,
      name: "CMS-Enterprise",
      price: 18000,
      sale_price: 12600,
      hsn: "85044030",
    },
    {
      id: 8,
      type: itemType.service,
      name: "Smart HUB Conversion",
      price: 18000,
      sale_price: 12600,
      hsn: "85044030",
    },
    {
      id: 9,
      type: itemType.service,
      name: "Park Plus Charge",
      price: 18000,
      sale_price: 12600,
      hsn: "85044030",
    },
    {
      id: 10,
      type: itemType.goods,
      name: "P2 Power 7.4kw",
      price: 18000,
      sale_price: 12600,
      hsn: "85044030",
    },
    {
      id: 11,
      type: itemType.goods,
      name: "Kazam LEV AC Pro",
      price: 18000,
      sale_price: 12600,
      hsn: "85044030",
    },
    {
      id: 12,
      type: itemType.goods,
      name: "Kazam LEV AC Pro Plus",
      price: 18000,
      sale_price: 12600,
      hsn: "85044030",
    },
    {
      id: 13,
      type: itemType.goods,
      name: "Kazam LEV AC (OCPP)",
      price: 18000,
      sale_price: 12600,
      hsn: "85044030",
    },
    {
      id: 14,
      type: itemType.goods,
      name: "Plugzmart 7.4 KW",
      price: 18000,
      sale_price: 12600,
      hsn: "85044030",
    },
    {
      id: 15,
      type: itemType.goods,
      name: "Ather Grid",
      price: 18000,
      sale_price: 12600,
      hsn: "85044030",
    },
    {
      id: 16,
      type: itemType.goods,
      name: "Electrical Infra-I&C",
      price: 18000,
      sale_price: 12600,
      hsn: "85044030",
    },
    {
      id: 17,
      type: itemType.goods,
      name: "LEV DC",
      price: 18000,
      sale_price: 12600,
      hsn: "85044030",
    },
    {
      id: 18,
      type: itemType.service,
      name: "Site Survey",
      price: 18000,
      sale_price: 12600,
      hsn: "85044030",
    },
    {
      id: 19,
      type: itemType.goods,
      name: "Kazam Mini(Bajaj)",
      price: 18000,
      sale_price: 12600,
      hsn: "85044030",
    },
    {
      id: 20,
      type: itemType.goods,
      name: "Mini without PCB",
      price: 18000,
      sale_price: 12600,
      hsn: "85044030",
    },
    {
      id: 21,
      type: itemType.goods,
      name: "Kazam 7.4",
      price: 18000,
      sale_price: 12600,
      hsn: "85044030",
    },
    {
      id: 22,
      type: itemType.goods,
      name: "Mini with RCCB",
      price: 18000,
      sale_price: 12600,
      hsn: "85044030",
    },
  ];
  return { success: true, data: peroducts };
  // return new Promise<ApiResponse>((resolve) =>
  //   setTimeout(() => resolve({ success: true, data: peroducts }), 300)
  // );
};

export const GetExecutivesApi = async (dep: string) => {
  try {
    let executives: {
      id: string;
      name: string;
      dep: string;
      sub_dep: string;
    }[] = [];
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/employees/active/department/${dep}?all=true`;
    const res = await fetchGet(url);
    if (res.success) {
      executives = res.data.map((emp: UserModel) => {
        return {
          id: emp.uid,
          name: `${emp.first_name} ${emp.last_name}`,
          dep: emp.emp_profile?.department,
          sub_dep: emp.emp_profile?.sub_dep,
        };
      });
    }
    return { success: true, data: executives };
  } catch (error: any) {
    return { success: false, error: error.message };
  }
  // return new Promise<ApiResponse>((resolve) =>
  //   setTimeout(() => resolve({ success: true, data: executives }), 300)
  // );
};

export const GetCitiesApi = async (
  state: string,
  country: string = "India"
) => {
  try {
    const endpoint =
      "https://countriesnow.space/api/v0.1/countries/state/cities";
    const res = await fetchPost(endpoint, {
      country,
      state,
    });
    const cities = res.data.map((city: string, i: number) => {
      return { id: i, name: city };
    });
    return { success: true, data: cities };
  } catch (error: any) {
    return { success: false, error: error.message };
  }
  // return new Promise<ApiResponse>((resolve) =>
  //   setTimeout(() => resolve({ success: true, data: cities }), 300)
  // );
};

export const GetStatesApi = async (country: string = "India") => {
  return { success: true, data: stateData };
  // try {
  // const endpoint = "https://countriesnow.space/api/v0.1/countries/states";
  // const res = await fetchPost(endpoint, {
  //   country,
  // });

  //   // return { success: true, data: res.data.data.states };
  // } catch (error: any) {
  //   return { success: false, error: error.message };
  // }
  // const states = [
  //   { id: 1, name: "State 1" },
  //   { id: 2, name: "State 2" },
  //   { id: 3, name: "State 3" },
  //   { id: 4, name: "State 4" },
  // ];
  // return new Promise<ApiResponse>((resolve) =>
  //   setTimeout(
  //     () => resolve({ success: true, data: res.data.data.states }),
  //     300
  //   )
  // );
};
export const GetCountryApi = async () => {
  const countries = [
    { id: 1, name: "country 1" },
    { id: 2, name: "country 2" },
    { id: 3, name: "country 3" },
    { id: 4, name: "country 4" },
  ];
  return new Promise<ApiResponse>((resolve) =>
    setTimeout(() => resolve({ success: true, data: countries }), 300)
  );
};
