import { FunctionComponent, useEffect, useState } from "react";
import Icon from "@mdi/react";
import {
  mdiChevronDoubleLeft,
  mdiChevronDoubleRight,
  mdiChevronLeft,
  mdiChevronRight,
} from "@mdi/js";
interface PaginationProps {
  page: number;
  total: number;
  count: number;
  onChange: (page: number) => void;

  itemCount: number;

  range?: number;
  showFirstLast?: boolean;
  showPrevNext?: boolean;
  showEllipsis?: boolean;
  showMidpoint?: boolean;
}

const Pagination: FunctionComponent<PaginationProps> = ({
  total = 0,
  count = 10,
  page = 1,
  range = 2,
  showEllipsis = true,
  showFirstLast = true,
  showMidpoint = true,
  showPrevNext = true,
  onChange,
  itemCount,
}) => {
  const [start, setStart] = useState(total === 0 ? 0 : (page - 1) * count + 1);
  const [end, setEnd] = useState(total);
  const [totalPages, setTotalpages] = useState(Math.ceil(total / count));
  const [page_btns, setPageBtns] = useState<number[]>([]);
  useEffect(() => {
    setStart(total === 0 ? 0 : (page - 1) * count + 1);
    if (count < total) {
      let t_end = count * page;
      if (t_end > total) {
        t_end = total;
      }
      setEnd(t_end);
    } else {
      setEnd(total);
    }

    setTotalpages(Math.ceil(total / count));
  }, [page, total]);
  useEffect(() => {
    const t = getPages();
    setPageBtns(t);
  }, [total, totalPages, page]);
  function goToPage(p: number) {
    if (p >= 1 && p <= totalPages) {
      // page = p;
      onChange(p);
      // page_btns = getPages();
    }
  }

  function getPages() {
    const pages = [];

    for (let i = page - range; i <= page + range; i++) {
      if (i >= 1 && i <= totalPages) {
        pages.push(i);
      }
    }

    if (showMidpoint) {
      const remainingPages = totalPages - page;
      const midpoint = page + Math.ceil(remainingPages / 2);

      if (midpoint - page > range + 1) {
        pages.push(midpoint);
      }
    }

    return pages;
  }

  // const totalPage = Math.ceil(total / count);
  // const onNext = () => {
  //   if (page < totalPage) {
  //     onChange(page++);
  //   }
  // };
  // const onPrev = () => {
  //   if (page > 1) {
  //     onChange(page--);
  //   }
  // };

  return (
    <div className="flex flex-col sm:flex-row justify-between py-2 items-center flex-wrap">
      <div>
        <p className="text-xs text-gray-700">
          Showing <span className="font-medium">{start}</span> to{" "}
          <span className="font-medium">{end}</span> |{" "}
          <span className="font-medium">{itemCount}</span> of{" "}
          <span className="font-medium">{total}</span> Records
        </p>
      </div>

      <div className="pagination flex text-sm rounded-md bg-white">
        {showFirstLast && page > range + 1 && (
          <button
            title="First"
            className="pagination-item border rounded-l-md p-2"
            onClick={() => goToPage(1)}
          >
            <Icon path={mdiChevronDoubleLeft} size={0.8}></Icon>
          </button>
        )}

        {showPrevNext && page > 1 && (
          <button
            title="Previous"
            className={` pagination-item border p-2 ${
              !(showFirstLast && page > range + 1) && "rounded-l-md"
            }`}
            onClick={() => goToPage(page - 1)}
          >
            <Icon path={mdiChevronLeft} size={0.8}></Icon>
          </button>
        )}

        {page_btns.map((page_btn, i) => (
          <>
            {showEllipsis &&
            page_btn === page - range - 1 &&
            page - range > 2 ? (
              <div
                className={`pagination-item ${
                  page_btn === page
                    ? "active bg-black border border-black text-white"
                    : "border"
                } px-2 py-1`}
              >
                ...
              </div>
            ) : showEllipsis &&
              page_btn === page + range + 1 &&
              page + range < totalPages - 1 ? (
              <div
                className={`pagination-item ${
                  page_btn === page
                    ? "active bg-black border border-black text-white"
                    : "border"
                } px-2 py-1`}
              >
                ...
              </div>
            ) : (
              <button
                className={`pagination-item ${
                  page_btn == 1 && !(showPrevNext && page > 1) && "rounded-l-md"
                }  ${
                  page_btn === totalPages &&
                  !(showPrevNext && page < totalPages) &&
                  "rounded-r-md"
                } ${
                  page_btn === page
                    ? "active bg-black border border-black text-white"
                    : "border"
                } px-2 py-1`}
                onClick={() => goToPage(page_btn)}
              >
                {page_btn}
              </button>
            )}
          </>
        ))}

        {showMidpoint && page + range < totalPages && (
          <>
            <div className="pagination-item border px-2 py-1">...</div>
            <button
              className={`pagination-item border px-2 py-1 ${
                page === totalPages && "active"
              }`}
              onClick={() => goToPage(totalPages)}
            >
              {totalPages}
            </button>
          </>
        )}

        {showPrevNext && page < totalPages && (
          <button
            title="Next"
            className={`pagination-item border p-2 ${
              !(showFirstLast && page < totalPages - range) && "rounded-r-md"
            }`}
            onClick={() => goToPage(page + 1)}
          >
            <Icon path={mdiChevronRight} size={0.8}></Icon>
          </button>
        )}
        {showFirstLast && page < totalPages - range && (
          <button
            title="Last"
            className="pagination-item border p-2 rounded-r-md"
            onClick={() => goToPage(totalPages)}
          >
            <Icon path={mdiChevronDoubleRight} size={0.8}></Icon>
          </button>
        )}
      </div>
    </div>
  );
};
export default Pagination;
