import { FunctionComponent } from "react";
import { academyVideoCategory } from "../../../../../utils/enums";
import VideoLayout from "../components/VideosLayout";

interface SalesProps {}

const Sales: FunctionComponent<SalesProps> = () => {
  const segment = academyVideoCategory.Sales;

  return <VideoLayout segment={segment} />;
};

export default Sales;
