import {
  fetchDelete,
  fetchGet,
  fetchPost,
  fetchPut,
  toQueryString,
} from "../../../../../service/api_client";
import { JobApplication, JobDetail } from "../models/job_portal";

// Get all jobs
export const GetJobs = async (data: {
  page: number;
  count: number;
  with_count: boolean;
  active?: boolean;
}) => {
  try {
    const url = `${
      process.env.REACT_APP_BACKEND_BASE_URL
    }/api/v3/job/job-detail${toQueryString(data)}`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

// Create a new job
export const CreateJobPosting = async (data: JobDetail) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/job/job-detail`;
    const res = await fetchPost(url, data);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

// update applicant job status
export const UpdateJobDetail = async (id: string, data: JobDetail) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/job/job-detail/${id}`;
    const res = await fetchPut(url, data);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

// Delete a job
export const DeleteJob = async (id: string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/job/job-detail/${id}`;
    const res = await fetchDelete(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

// Get all job applications
export const GetApplicantDetails = async (id: string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/job/job-application/${id}`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

// Apply for a job
export const PostJobApplication = async (data: FormData) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/job/job-application`;
    const res = await fetchPost(url, data);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

// update applicant job status
export const UpdateApplicantJobStatus = async (
  id: string,
  data: JobApplication
) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/job/job-application/${id}`;
    const res = await fetchPut(url, data);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

// update applicant job status
export const DeleteApplicantJobStatus = async (id: string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/job/job-application/${id}`;
    const res = await fetchDelete(url);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

// :/api/v3/job/job-detail/:jd_id/applications

// Get specific job applicants
export const GetJobApplicants = async (jd_id: string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/job/job-detail/${jd_id}/applications`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

// Get employee ref
export const GetRef = async (uid: any) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/job/job-ref/${uid}`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

// Get employee ref
export const GetReferals = async (id: number) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/job/job-ref/${id}/applications`;
    // /api/v3/job/job-ref/:ref_code/
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

// GET: /api/v3/job/job-application
export const GetNonJobApplicants = async () => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/job/job-application`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
