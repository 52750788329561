import axios from "axios";
import { ComplaintFilterModle } from "../../../ui/pages/complaint/listing";
import {
  fetchDelete,
  fetchGet,
  fetchPost,
  fetchPut,
  getHeaders,
  toQueryString,
} from "../../api_client";
import { ComplaintCommentModel } from "../../models/complaint_model";

export const CreateComplaint = async (data: FormData) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/complaint`;
    const res = await fetchPost(url, data);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const UpdateComplaint = async (data: {
  [key: string]: any;
  id: string;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/complaint/${data.id}`;
    const res = await fetchPut(url, data);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GetComplaint = async (id: string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/complaint/${id}`;
    const res = await fetchGet(url);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};


export const GetOrgByDeviceId = async (id: string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/complaint/device/${id}`;
    console.log("id is:", id)
    const res = await fetchGet(url);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const GetAddressByDeviceId = async (id: string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/complaint/device_address/${id}`;
    const res = await fetchGet(url);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const OrgSearchInComplaints = async (option: {
  count?: number;
  page?: number;
  search?: string;
  all?: boolean;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/complaint/org_search${toQueryString(option)}`;
    const res = await fetchGet(url);
    console.log("log", res.data)
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
}

export const CreateComplaintComment = async (data: ComplaintCommentModel) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/complaint/${data.complaint_id}/comment`;
    const res = await fetchPost(url, data);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const DeleteComplaintComment = async ({
  complaint_id,
  id,
}: {
  complaint_id: any;
  id: any;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/complaint/${complaint_id}/comment/${id}`;
    const res = await fetchDelete(url);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const getComplaints = async (data: ComplaintFilterModle) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/complaint/get-complaints`;
    const res = await fetchPost(url, data);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const exportComplaints = async (data: {
  search?: string;
  filterData?: {
    [key: string]: any;
    fromDate?: Date;
    toDate?: Date;
    complaintBy?: string[];
    complainRelatedTo?: string[];
    responsiblePerson?: string[];
    emp_name?: string;
    status?: string[];
    state?: string[];
    pre_post?: string[];
    priority?: string[];
    active?: boolean;
    sort_by?: string;
    sort_order?: string;
  };
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL
      }/api/v3/complaint/export${toQueryString(data)}`;
    const res = await fetchGet(url, data);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const getComplaintComments = async ({
  page,
  count,
  all,
  complaint_id,
}: {
  complaint_id: string;
  page: number;
  count: number;
  all: any;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/complaint/${complaint_id}/comment?page=${page}&count=${count}&all=${all}`;
    const res = await fetchGet(url);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const getComplaintLogs = async ({
  page,
  count,
  all,
  complaint_id,
}: {
  complaint_id: string;
  page: number;
  count: number;
  all: any;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/complaint/${complaint_id}/log?page=${page}&count=${count}&all=${all}`;
    const res = await fetchGet(url);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GetComplaintWordCount = async () => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/complaint/cloud-word`;
    const res = await fetchGet(url);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const UploadComplaintAttachmentRepo = async (
  complaint_id: string,
  data: FormData
) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/complaint/${complaint_id}/attachments`;
    const res = await fetchPost(url, data);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GetComplaintAttachmentRepo = async (complaint_id: string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/complaint/${complaint_id}/attachments`;
    const res = await fetchGet(url);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
