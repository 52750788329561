import {
  mdiAlert,
  mdiAlertCircleOutline,
  mdiCheck,
  mdiCheckCircleOutline,
  mdiClose,
  mdiInformationOutline,
  mdiMessageAlert,
} from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useState } from "react";
import { ToastData, ToastType, useToast } from "../toast_ctx";
import "./index.css";
interface ToastModalProps {
  toast: ToastData;
  index: number;
  // duration: number;
}

const ToastModal: FunctionComponent<ToastModalProps> = (props) => {
  const { onClose } = useToast();
  const [toast, setToast] = useState<ToastData>(props.toast);
  const [color, setColor] = useState("");
  const [icon, setIcon] = useState("");
  // const [pause, setPause] = useState(false);
  const duration = 3000;
  useEffect(() => {
    setColor(getColor(props.toast.type));
    setIcon(getIcon(props.toast.type));
    // const t = setTimeout(() => {
    //   // onClose(toast.id);
    //   clearTimeout(t);
    // }, duration);
  }, []);

  // useEffect(() => {
  //   const t = setInterval(() => {
  //     console.log(pause, toast);

  //     if (!pause)
  //       setToast((t) => {
  //         t.progress += interval;
  //         if (t.progress >= duration) onClose(t.id);
  //         return { ...t };
  //       });
  //   }, interval);
  //   return () => {
  //     clearInterval(t);
  //   };
  // }, [pause]);

  return (
    <div
      className={`  rounded-md     text-white  transform duration-500 ease-in-out  slide_drawer_rtl bg-darkCard hover:bg-darkCardHover`}
    >
      <div className="p-2">
        <div className="flex justify-end">
          <div
            className=""
            onClick={() => {
              onClose(toast.id);
            }}
          >
            <Icon
              path={mdiClose}
              className={` h-5 w-5  text${color} cursor-pointer`}
            ></Icon>
          </div>
        </div>
        <div className="flex items-center gap-2 my-2">
          <div className={`p-2 rounded-full bg${color}  bg-opacity-5`}>
            <Icon path={icon} className={` h-5 w-5  text${color}`}></Icon>
          </div>
          <h3 className="text-sm">{toast?.text}</h3>
        </div>
      </div>
      <div className="w-full bg-gray-200 rounded-b-full h-1 dark:bg-gray-700">
        <div className={`bg${color} h-1 rounded-b-full   w-full`}></div>
      </div>
      {/* <progress
        className="rounded h-2"
        id="file"
        value={100 - (toast.progress / duration) * 100}
        max={100}
      ></progress> */}
    </div>
  );
};

export default ToastModal;
const getColor = (type: ToastType) => {
  switch (type) {
    case ToastType.success:
      return "-green-500 ";
    case ToastType.error:
      return "-red-500 ";
    case ToastType.info:
      return "-blue-500 ";
    case ToastType.warning:
      return "-yellow-500 ";

    default:
      return "-white ";
  }
};
const getIcon = (type: ToastType) => {
  switch (type) {
    case ToastType.success:
      return mdiCheckCircleOutline;
    case ToastType.error:
      return mdiAlertCircleOutline;
    case ToastType.info:
      return mdiInformationOutline;
    case ToastType.warning:
      return mdiAlert;

    default:
      return mdiMessageAlert;
  }
};
