import { FunctionComponent } from "react";
import TaskAttachmentWidget from "../../../components/attachment_widget";
import { useOpsTask } from "../../../../../../context_providers/ops_task_engineer_detail/ops_task_engineer_detail";

interface AttachmentScreenProps {}

const AttachmentScreen: FunctionComponent<AttachmentScreenProps> = () => {
  const { data } = useOpsTask();
  return (
    <TaskAttachmentWidget
      task_id={data?.id}
      attachments={data!.attachments || []}
      onRefresh={function (): void {
        // getTask(data!.id);
      }}
    />
  );
};

export default AttachmentScreen;
