import { FunctionComponent, useState } from "react";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import FileDropZone1 from "../../../../new_components/common/file_dropdown";
export const AttachmentCategory = [
  "PO",
  "E-Bill",
  "Aadhaar Card",
  "PAN Card",
  "Installation Photos",
  "Email",
  "Subsidy Registration Acknowledgement Copy",
  "Facilitation fees Invoice",
  "Customer Review video",
  "Other",
];
interface UploadAttachmentWidgetProps {
  onSubmit: (data: { category: string; file: File }) => void;
  onClose: () => void;
}

const UploadAttachmentWidget: FunctionComponent<UploadAttachmentWidgetProps> = (
  props
) => {
  const { showToast } = useToast();
  const [category, setCategory] = useState("");
  const [file, SetFile] = useState<File>();
  return (
    <>
      <div className="p-5 flex flex-col">
        <div className="my-2">
          <div className=" mb-1 font-semibold text-sm">Attachment category</div>
          <select
            value={category}
            onChange={(e) => {
              setCategory(e.target.value);
            }}
            name=""
            id=""
            className=" w-full text-xs  px-3 py-1 cursor-pointer bg-gray-100 rounded focus:outline-none"
          >
            <option hidden>Select</option>
            {AttachmentCategory.map((at, i) => (
              <option value={at}>{at}</option>
            ))}
          </select>
        </div>
        <FileDropZone1
          type="image/*,application/pdf"
          onChange={(f) => {
            SetFile(f[0]);
          }}
          multiSelect={false}
        ></FileDropZone1>

        {file && (
          <>
            <div className="h-60 overflow-hidden rounded my-5 bg-gray-100">
              {file.type.startsWith("image") ? (
                <img
                  src={URL.createObjectURL(file)}
                  alt=""
                  className="object-contsain w-full h-full"
                />
              ) : (
                <embed
                  src={URL.createObjectURL(file)}
                  type="application/pdf"
                  className="w-auto h-full"
                ></embed>
              )}
            </div>

            <div className="grid grid-cols-2 gap-5">
              <button
                onClick={props.onClose}
                className="rounded-3xl py-2 px-5 border border-myPrimaryColor text-myPrimaryColor text-sm"
              >
                Cancel
              </button>{" "}
              <button
                onClick={() => {
                  if (!category || !file) {
                    showToast({
                      type: ToastType.error,
                      text: "Category and file required",
                    });
                    return;
                  }

                  props.onSubmit({ category, file });
                }}
                className="rounded-3xl py-2 px-5 bg-myPrimaryColor text-white text-sm"
              >
                Submit
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default UploadAttachmentWidget;
