import { mdiDelete, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useState } from "react";
import {
  ToastType,
  useToast,
} from "../../../../context_providers/toast/toast_ctx";
import { BomItem, ItemBom } from "../../models/item_model";
import ItemSearchField from "../item_search_field";

interface BomItemProps {
  item: BomItem;
  onChange?: (d: { [key: string]: any }) => void;
  onSubmit?: (item: BomItem) => void;
  onDelete?: (item: BomItem) => void;
}

const BomItemWidget: FunctionComponent<BomItemProps> = (props) => {
  const { showToast } = useToast();
  const [data, setData] = useState(props.item);
  return (
    <div className="grid grid-cols-8 gap-2  items-end">
      <div className="col-span-3 flex flex-col items-start text-sm w-full">
        <label className="font-semibold w-full" htmlFor="unit">
          Item *
        </label>

        <ItemSearchField
          value={data.item}
          onSelect={(d) => {
            setData((o) => ({ ...o, item: d }));
            if (props.onChange) props.onChange({ item: d });
          }}
        />
      </div>
      <div className="col-span-2 flex flex-col items-start text-sm w-full">
        <label className="font-semibold w-full" htmlFor="bom_unit">
          Qty*
        </label>
        <input
          placeholder="0"
          className="p-1 rounded  focus:outline-none w-full border"
          id="bom_unit"
          name="bom_unit"
          value={data.unit_no || ""}
          required={true}
          type="number"
          onChange={(e) => {
            const unit_no = Number(e.target.value);

            setData((o) => ({
              ...o,
              unit_no,
            }));
            if (props.onChange) props.onChange({ unit_no });
          }}
        ></input>
      </div>
      {/* <div className="col-span-2 flex flex-col items-start text-sm w-full">
        <label className="font-semibold w-full" htmlFor="bom_price">
          Price*
        </label>
        <input
          placeholder="0"
          className="p-1 rounded  focus:outline-none w-full border"
          id="bom_price"
          name="bom_price"
          value={data.price || ""}
          required={true}
          type="number"
          onChange={(e) => {
            const price = Number(e.target.value);

            setData((o) => ({
              ...o,
              price,
            }));
            if (props.onChange) props.onChange({ price });
          }}
        ></input>
      </div> */}
      {props.onSubmit && (
        <div className="">
          <button
            onClick={() => {
              if (!data.item || !data.unit_no) {
                showToast({
                  type: ToastType.error,
                  text: "Please fill the required fields",
                });
                return;
              }
              if (props.onSubmit) props.onSubmit(data);
            }}
            className="rounded bg-white p-1 shadow"
          >
            <Icon path={mdiPlus} className="h-5 w-5"></Icon>
          </button>
        </div>
      )}
      {props.onDelete && (
        <div className="">
          <button
            onClick={() => {
              props.onDelete!(data);
            }}
            className="rounded bg-white p-1 shadow"
          >
            <Icon path={mdiDelete} className="h-5 w-5 text-red-400"></Icon>
          </button>
        </div>
      )}
    </div>
  );
};

export default BomItemWidget;
