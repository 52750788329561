import { RouteParam } from ".";
import ErrorPage from "../ui/pages/errors/error_screen";
import LoginPageWidget from "../ui/pages/login";
import { urls } from "../utils/urls";

const auth_routes: RouteParam[] = [
  {
    isPrivate: false,
    fullScreen: true,
    exact: true,
    path: urls.login,
    component: LoginPageWidget,
  },
  {
    isPrivate: false,
    fullScreen: true,
    exact: true,
    path: urls.notAllowed,
    component: () => <ErrorPage />,
  },
];

export default auth_routes;
