import { RouteParam } from ".";
import HrmAnalytics from "../ui/pages/hrm/analytics";
import EmployeeMap from "../ui/pages/hrm/analytics/pages/Map";
import Anniversary from "../ui/pages/hrm/anniversary";
import Birthday from "../ui/pages/hrm/birthday";
import CreateLeaveRecord from "../ui/pages/hrm/create-leave-record";
import DeptEmployees from "../ui/pages/hrm/dept-employees";
import Employees from "../ui/pages/hrm/employees";
import CreateEmployee from "../ui/pages/hrm/employees/pages/CreateEmployee";
import FilesUpload from "../ui/pages/hrm/files-upload";
import Home from "../ui/pages/hrm/home";
import HrLeavePanel from "../ui/pages/hrm/hr_leave_panel";
import JobPortal from "../ui/pages/hrm/job_portal";
import Applicants from "../ui/pages/hrm/job_portal/pages/applicants";
import ApplicantsView from "../ui/pages/hrm/job_portal/pages/applicants-view";
import CreateJob from "../ui/pages/hrm/job_portal/pages/create-job";
import JobApply from "../ui/pages/hrm/job_portal/pages/job-apply";
import LeaveApplication from "../ui/pages/hrm/leave-application";
import LeaveData from "../ui/pages/hrm/leave-data";
import Memories from "../ui/pages/hrm/memories";
import MemoryView from "../ui/pages/hrm/memories/pages/MemoryView";
import OrganizationTree from "../ui/pages/hrm/organization";
import OrgTreeTab from "../ui/pages/hrm/organization/components/OrgTreeTab";
import Overview from "../ui/pages/hrm/overview";
import Policies from "../ui/pages/hrm/policies";
import Gmc from "../ui/pages/hrm/policies/components/gmc";
import Profile from "../ui/pages/hrm/profile";
import { ACTION, MODULE } from "../utils/enums";
import { urls } from "../utils/urls";

const hrm_routes: RouteParam[] = [
  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.hrm_home,
    component: Home,
    main_module: MODULE.hrm_panel,
    module: MODULE.hrm_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.hrm_leave_application,
    component: LeaveApplication,
    main_module: MODULE.hrm_panel,
    module: MODULE.hrm_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.hrm_organization_tree,
    component: OrganizationTree,
    main_module: MODULE.hrm_panel,
    module: MODULE.hrm_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.hrm_memories,
    component: Memories,
    main_module: MODULE.hrm_panel,
    module: MODULE.hrm_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.hrm_memories_view,
    component: MemoryView,
    main_module: MODULE.hrm_panel,
    module: MODULE.hrm_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },

  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.hrm_overview,
    component: Overview,
    main_module: MODULE.hrm_panel,
    module: MODULE.hrm_leave,
    action: [ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.hrm_analytics,
    component: HrmAnalytics,
    main_module: MODULE.hrm_panel,
    module: MODULE.hrm_leave,
    action: [ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.hrm_employee_map,
    component: EmployeeMap,
    main_module: MODULE.hrm_panel,
    module: MODULE.hrm_leave,
    action: [ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.hrm_job_portal,
    component: JobPortal,
    main_module: MODULE.hrm_panel,
    module: MODULE.hrm_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.hrm_apply_job,
    component: JobApply,
    main_module: MODULE.hrm_panel,
    module: MODULE.hrm_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.hrm_create_job,
    component: CreateJob,
    main_module: MODULE.hrm_panel,
    module: MODULE.hrm_leave,
    action: [ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.hrm_add_employee,
    component: CreateEmployee,
    main_module: MODULE.hrm_panel,
    module: MODULE.hrm_leave,
    action: [ACTION.UPDATE],
    operator: "or",
  },

  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.hrm_job_applicants,
    component: Applicants,
    main_module: MODULE.hrm_panel,
    module: MODULE.hrm_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.hrm_job_applicants_view,
    component: ApplicantsView,
    main_module: MODULE.hrm_panel,
    module: MODULE.hrm_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.hrm_leave_data,
    component: LeaveData,
    main_module: MODULE.hrm_panel,
    module: MODULE.hrm_leave,
    action: [ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.hrm_hr_leave_record,
    component: CreateLeaveRecord,
    main_module: MODULE.hrm_panel,
    module: MODULE.hrm_leave,
    action: [ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.hrm_hr_leave_panel,
    component: HrLeavePanel,
    main_module: MODULE.hrm_panel,
    module: MODULE.hrm_leave,
    action: [ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.hrm_dept,
    component: Employees,
    main_module: MODULE.hrm_panel,
    module: MODULE.hrm_panel,
    action: [ACTION.READ],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.hrm_dept_emp,
    component: DeptEmployees,
    main_module: MODULE.hrm_panel,
    module: MODULE.hrm_panel,
    action: [ACTION.READ],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.hrm_birthday,
    component: Birthday,
    main_module: MODULE.hrm_panel,
    module: MODULE.hrm_panel,
    action: [ACTION.READ],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.hrm_work_anniversary,
    component: Anniversary,
    main_module: MODULE.hrm_panel,
    module: MODULE.hrm_panel,
    action: [ACTION.READ],
    operator: "or",
  },

  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.hrm_org_tree_tab,
    component: OrgTreeTab,
    main_module: MODULE.hrm_panel,
    module: MODULE.hrm_panel,
    action: [ACTION.READ],
    operator: "or",
  },

  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.hrm_policies,
    component: Policies,
    main_module: MODULE.hrm_panel,
    module: MODULE.hrm_panel,
    action: [ACTION.READ],
    operator: "or",
  },

  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.hrm_gmc_policy,
    component: Gmc,
    main_module: MODULE.hrm_panel,
    module: MODULE.hrm_panel,
    action: [ACTION.READ],
    operator: "or",
  },

  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.hrm_files_upload,
    component: FilesUpload,
    main_module: MODULE.hrm_panel,
    module: MODULE.hrm_employee,
    action: [ACTION.UPDATE],
    operator: "or",
  },

  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.hrm_profile,
    component: Profile,
    main_module: MODULE.hrm_panel,
    module: MODULE.hrm_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
];

export default hrm_routes;
