import { FunctionComponent } from "react";
import { academyVideoCategory } from "../../../../../utils/enums";
import VideoLayout from "../components/VideosLayout";

interface UpdatesProps {}

const Updates: FunctionComponent<UpdatesProps> = () => {
  const segment = academyVideoCategory.Company_Updates;

  return <VideoLayout segment={segment} />;
};

export default Updates;
