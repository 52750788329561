import {
  mdiAttachment,
  mdiBank,
  mdiBankOutline,
  mdiCheckboxBlankCircle,
  mdiCheckboxBlankCircleOutline,
  mdiCurrencyRupee,
  mdiEmailFast,
  mdiMagnify,
  mdiMail,
  mdiMailbox,
} from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import LoadingWidget from "../../../context_providers/modal/loader";
import {
  ToastType,
  useToast,
} from "../../../context_providers/toast/toast_ctx";
import {
  fetchDelete,
  fetchGet,
  fetchPost,
  fetchPut,
  toQueryString,
} from "../../../service/api_client";
import { getCityStateApi } from "../../../service/repos/address/address_repo";
import { AddressType, file_dir } from "../../../utils/enums";
import { updateObject } from "../../../utils/formDataToJson";
import { urls } from "../../../utils/urls";

import {
  PartyModel,
  currencies,
  defaulPartyValue,
  default_mail_detail,
  partyTypes,
  taxRegType,
} from "../models/party_model";
import DrawerWidget from "../../../context_providers/modal/drawer_widget";
import {
  ModalAlignment,
  ModalType,
} from "../../../context_providers/modal/modal_context";
import AttachmentSection from "./components/attachment";
import {
  AttachmentModel,
  getFileType,
} from "../../purchase_order/model/purchase_order_model";
import axios from "axios";
import { dataTypes } from "../../../utils/import_export_parser";
import DoneWidget from "../../../ui/new_components/common/done";

interface PartyCreateScreenProps {
  onClose: (data?: PartyModel) => void;
  data?: PartyModel;
}

const PartyCreateScreen: FunctionComponent<PartyCreateScreenProps> = (
  props
) => {
  const { id } = useParams<{ id: string }>();
  const update_page = useRouteMatch(
    urls.procurement_masters_party + "/update/:id"
  );
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/accounts/party`;
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [party, setParty] = useState({ ...(props.data || defaulPartyValue) });
  const [showSuccess, setShowSucess] = useState(false);
  const [creating, setCreating] = useState(false);
  const [showAttachment, setShowAttachment] = useState(false);
  const [attachmentKey, setAttachmentKey] = useState(Math.random() * 10000);
  const handleChange = (e: any) => {
    const { value, name } = e.target;
    const type = e.target.getAttribute("data-type");

    const keys = name.split(".");
    const updatedValue = updateObject(party, keys, value, type);
    setParty(updatedValue);
  };

  // const handleChange = (e: any) => {
  //   const { value, name } = e.target;
  //   if (name.includes(".")) {
  //     const fields = name.split(".");

  //     if (fields.length === 3) {
  //       setParty((prev: any) => {
  //         const newParty = prev;

  //         newParty[fields[0]][fields[1]][fields[2]] = value;
  //         return { ...newParty };
  //       });
  //     } else if (fields.length === 2) {
  //       setParty((prev: any) => {
  //         const newParty = prev;
  //         newParty[fields[0]][fields[1]] = value;
  //         return { ...newParty };
  //       });
  //     }
  //   } else setParty((prev) => ({ ...prev, [name]: value }));
  // };

  const createParty = async (e: any) => {
    e.preventDefault();
    // console.log({ party });

    // if (
    //   !party.name ||
    //   !party.type ||
    //   !party.category ||
    //   !party.sub_category ||
    //   !party.mail_detail.contact_email ||
    //   !party.mail_detail.contact_mobile ||
    //   !party.mail_detail.contact_name ||
    //   !party.mail_detail.address.city ||
    //   !party.mail_detail.address.country ||
    //   !party.mail_detail.address.line1 ||
    //   !party.mail_detail.address.state ||
    //   !party.mail_detail.address.type ||
    //   !party.mail_detail.address.pincode ||
    //   !party.bank_detail.ac_no ||
    //   !party.bank_detail.bank_name ||
    //   !party.bank_detail.branch ||
    //   !party.bank_detail.ifsc ||
    //   !party.tax_detail.gst_no ||
    //   !party.tax_detail.pan ||
    //   !party.tax_detail.reg_type
    // ) {
    //   return showToast({
    //     type: ToastType.error,
    //     text: "Please fill all the required details",
    //   });
    // }

    // if (party.mail_detail.contact_mobile.length !== 10)
    //   return showToast({
    //     type: ToastType.error,
    //     text: "Wrong phone",
    //   });

    setCreating(true);
    try {
      const res = party.id
        ? await fetchPut(url + "/" + party.id, party)
        : await fetchPost(url, party);
      if (res.success) {
        showToast({ type: ToastType.success, text: res.message });
        if (!party.id) {
          setParty(defaulPartyValue);
          setShowSucess(true);
        }
        // setParty(res.data);
        setParty((prevData) => ({
          ...prevData,
          ...res.data, // Update with the response data
        }));
        if (props.onClose) props.onClose(res.data);
      } else showToast({ type: ToastType.error, text: res.error });
    } catch (error) {
      console.log(error);
    }
    setCreating(false);
  };
  useEffect(() => {
    if (id && update_page) getData(id);
  }, []);
  const getData = async (id: string) => {
    setLoading(true);
    const res = await fetchGet(url + "/" + id);
    if (res.success) {
      setParty(res.data);
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  // const getCityState = async (pincode: number) => {
  //   setLoading(true);
  //   const res = await getCityStateApi(pincode);

  //   if (res.success) {
  //     if (!res.data) {
  //       setLoading(false);
  //       alert("invalid zip code !");
  //       return;
  //     }
  //     const { city, state, pincode } = res.data;
  //     setParty((o) => ({
  //       ...o!,
  //       mail_detail: {
  //         ...o.mail_detail,
  //         address: { ...o?.mail_detail.address, city, state, pincode },
  //       },
  //     }));
  //   } else showToast({ type: ToastType.error, text: res.error ?? "" });
  //   setLoading(false);
  // };
  const uploadFile = async ({
    file,
    category,
    removeSelectedFile,
    setShowUpload,
  }: {
    file: File;
    category: string;
    removeSelectedFile: () => void;
    setShowUpload: (v: boolean) => void;
  }) => {
    try {
      if (!file || !category) {
        showToast({
          type: ToastType.warning,
          text: "Please select file and category",
        });
        return;
      }
      setLoading(true);
      const { name, type } = file;
      const create_url = `${url}/${party.id}/attachment`;
      const get_signed_urls = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/signed-url`;
      const query = {
        mimetype: type,
        extension: name.split(".").pop(),
        dir: file_dir.party_attachments,
      };
      const res = await fetchGet(get_signed_urls + toQueryString(query));
      if (res.success) {
        const { signed_url, url } = res.data;

        //   const options = {
        //     method: "PUT",
        //     body: file,
        //     headers: {
        //       "Content-Type": type,
        //     },
        //   };
        const aws_res = await axios.put(signed_url, file, {
          headers: {
            "Content-Type": type,
          },
        });
        console.log({ aws_res });
        const attachment: AttachmentModel = {
          id: "",
          category,
          type: getFileType(type),
          url,
        };
        const create_res = await fetchPost(create_url, attachment);

        if (create_res.success) {
          showToast({
            type: ToastType.success,
            text: create_res.message,
          });
          setParty((o) => ({
            ...o,
            attachments: [...(o.attachments || []), create_res.data],
          }));

          removeSelectedFile();
          setShowUpload(false);
          setAttachmentKey(Math.random() * 1000);
        }
      }
      setLoading(false);
    } catch (error: any) {
      showToast({
        type: ToastType.error,
        text: error.message,
      });
      setLoading(false);
    }
  };
  const deleteFile = async (att: AttachmentModel) => {
    try {
      if (!window.confirm("Are your sure to delete ?")) return;
      if (!att) {
        showToast({
          type: ToastType.warning,
          text: "Please select file",
        });
        return;
      }
      setLoading(true);
      const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order/${party.id}/attachment/${att.id}`;

      const res = await fetchDelete(url);

      if (res.success) {
        showToast({
          type: ToastType.success,
          text: res.message,
        });
        setParty((o) => ({
          ...o,
          attachments: [
            ...(o.attachments || []).filter((f) => f.id !== att.id),
          ],
        }));
        setAttachmentKey(Math.random() * 1000);
      }
      setLoading(false);
    } catch (error: any) {
      showToast({
        type: ToastType.error,
        text: error.message,
      });
      setLoading(false);
    }
  };

  const onSelectAttchment = (d: AttachmentModel) => {
    window.open(d.url, "_blank");
  };

  if (showSuccess)
    return (
      <div className="w-full h-full flex flex-col items-center justify-center">
        <div className="w-80 h-80 p-0">
          <DoneWidget />
        </div>
        <div className="flex flex-col gap-2 justify-start items-center">
          <h1 className="text-3xl font-semibold text-gray-400 ">
            Created successfully
          </h1>
          <span className="text-black italic font-semibold"># {party.id}</span>
          <div className="flex gap-5">
            <button
              onClick={() => {
                history.push(
                  urls.procurement_masters_party + "/update/" + party.id
                );
                setShowSucess(false);
              }}
              className="border rounded-3xl px-5 py-2 text-green-800 border-green-400"
            >
              Edit
            </button>
            <button
              onClick={() => {
                setParty({
                  ...defaulPartyValue,
                  // mail_detail: {
                  //   ...default_mail_detail,
                  //   contact_name: party.mail_detail.contact_name,
                  //   contact_email: party.mail_detail.contact_email,
                  //   contact_mobile: party.mail_detail.contact_mobile,
                  // },
                });

                setShowSucess(false);
              }}
              className="border rounded-3xl px-4 py-2 bg-green-400 text-white  "
            >
              Create new
            </button>
          </div>
        </div>
      </div>
    );

  return (
    <>
      {party.id && (
        <div className="fixed flex flex-col gap-2 bottom-3 right-3">
          <button
            title="Attchments"
            // to={`${window.document.location.pathname}/comment`}
            onClick={() => setShowAttachment(true)}
            className="  rounded-full p-2  hover:scale-105 transform duration-150 bg-green-400 text-white   shadow cursor-pointer flex items-center justify-center"
          >
            <Icon
              path={mdiAttachment}
              size={1}
              className="hover:scale-105 transform -rotate-45"
            ></Icon>
          </button>
        </div>
      )}
      {showAttachment && party.id && (
        <DrawerWidget
          data={{
            id: 1,
            title: "Attchments",
            type: ModalType.drawer,
            alignment: ModalAlignment.right,
            container: (
              <AttachmentSection
                key={attachmentKey}
                attachments={party.attachments}
                onSubmit={uploadFile}
                onDelete={deleteFile}
                onSelect={onSelectAttchment}
              />
            ),
          }}
          onClose={function (): void {
            setShowAttachment(false);
          }}
        ></DrawerWidget>
      )}
      <form
        id="party_create_form"
        onSubmit={createParty}
        className="grid gap-2 p-5 "
      >
        {update_page && (
          <h1 className="  text-xl font-bold border-l-4 border-myPrimaryColor pl-2">
            Party {id ? "update" : "create"}
          </h1>
        )}
        <section className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-3 bg-white rounded-md p-2">
          <div className="grid gap-1">
            <label htmlFor="name" className="text-sm">
              Party Name <RequiredMark />
            </label>
            <input
              required
              className="border rounded-md px-3 py-1 "
              type="text"
              data-type={dataTypes.string}
              id="name"
              name="name"
              value={party.name}
              onChange={handleChange}
            />
          </div>
          <div className="grid gap-1">
            <label htmlFor="type" className="text-sm">
              Party Type <RequiredMark />
            </label>
            <select
              required
              className="border rounded-md px-3 py-1 capitalize"
              id="type"
              name="type"
              data-type={dataTypes.string}
              value={party.type}
              onChange={handleChange}
            >
              {" "}
              <option value="" hidden>
                {" "}
                select
              </option>
              {Object.keys(partyTypes)?.map((type: any) => (
                <option value={type}>{type}</option>
              ))}
            </select>
          </div>

          <div className="grid gap-1">
            <label htmlFor="loc_type" className="text-sm">
              Party location <RequiredMark />
            </label>
            <select
              required
              className="border rounded-md px-3 py-1 capitalize"
              id="loc_type"
              name="loc_type"
              data-type={dataTypes.string}
              value={party.loc_type}
              onChange={handleChange}
            >
              {" "}
              <option value="" hidden>
                {" "}
                select
              </option>
              {["Domestic", "Foreign"].map((type: any) => (
                <option value={type}>{type}</option>
              ))}
            </select>
          </div>

          <div className="grid gap-1">
            <label htmlFor="category" className="text-sm">
              Category <RequiredMark />
            </label>
            <select
              required
              className="border rounded-md px-3 py-1 capitalize"
              id="category"
              name="category"
              data-type={dataTypes.string}
              value={party.category}
              onChange={handleChange}
            >
              {" "}
              <option value="" hidden>
                {" "}
                select
              </option>
              {["Goods", "Services"].map((type: any) => (
                <option value={type}>{type}</option>
              ))}
            </select>
          </div>
          <div className="grid gap-1">
            <label htmlFor="sub_category" className="text-sm">
              Sub category <RequiredMark />
            </label>
            <select
              required
              className="border rounded-md px-3 py-1 capitalize"
              id="sub_category"
              name="sub_category"
              data-type={dataTypes.string}
              value={party.sub_category}
              onChange={handleChange}
            >
              {" "}
              <option value="" hidden>
                {" "}
                select
              </option>
              {[
                "Electrical",
                "Electroninc",
                "Mechanical",
                "Packaging",
                "Printing",
                "Turnkey",
                "Assembly",
                "Office Supply",
                "Charger",
                "Marketing",
                "Others",
              ].map((type: any) => (
                <option value={type}>{type}</option>
              ))}
            </select>
          </div>
          {party.sub_category == "Others" && (
            <div className="grid gap-1">
              <label htmlFor="other_category" className="text-sm">
                Other category <RequiredMark />
              </label>
              <input
                required
                className="border rounded-md px-3 py-1 "
                type="text"
                data-type={dataTypes.string}
                id="other_category"
                name="other_category"
                value={party.other_category}
                onChange={handleChange}
              />
            </div>
          )}
          <div className="grid gap-1">
            <label htmlFor="currency" className="text-sm">
              Currency
              <RequiredMark />
            </label>
            <select
              required
              className="border rounded-md px-3 py-1 capitalize"
              id="currency"
              name="currency"
              value={party.currency && JSON.stringify(party.currency)}
              onChange={handleChange}
            >
              <option value="" hidden>
                select
              </option>
              {Object.values(currencies).map((currency: any) => (
                <option value={JSON.stringify(currency)}>
                  {currency.formal_name}({currency.symbol})
                </option>
              ))}
            </select>
          </div>
        </section>

        <section className="py-4">
          <h1 className="mb-2 font-semibold flex gap-2 items-center">
            <span className="text-blue-500">
              <Icon path={mdiEmailFast} size={0.8} />
            </span>
            Mail Details
          </h1>
          <div className="grid grid-cols-1  sm:grid-cols-2 md:grid-cols-4 gap-3 bg-white rounded-md p-2">
            <div className="grid gap-0.5">
              <label htmlFor="mail_detail.contact_name" className="text-sm">
                Contact Name <RequiredMark />
              </label>
              <input
                required
                type="text"
                data-type={dataTypes.string}
                className="border rounded-md px-3 py-1"
                id="mail_detail.contact_name"
                name="mail_detail.contact_name"
                value={party.mail_detail.contact_name}
                onChange={handleChange}
              />
            </div>
            <div className="grid gap-0.5">
              <label htmlFor="mail_detail.contact_mobile" className="text-sm">
                Contact Mobile <RequiredMark />
              </label>
              <input
                required
                type="text"
                data-type={dataTypes.string}
                className="border rounded-md px-3 py-1"
                id="mail_detail.contact_mobile"
                name="mail_detail.contact_mobile"
                value={party.mail_detail.contact_mobile}
                onChange={handleChange}
              />
            </div>
            <div className="grid gap-0.5">
              <label htmlFor="mail_detail.contact_email" className="text-sm">
                Contact Email
              </label>
              <input
                data-type={dataTypes.string}
                type="email"
                className="border rounded-md px-3 py-1"
                id="mail_detail.contact_email"
                name="mail_detail.contact_email"
                value={party.mail_detail.contact_email}
                onChange={handleChange}
              />
            </div>
            <div className="grid gap-0.5">
              <label
                htmlFor="mail_detail.alternative_mobile"
                className="text-sm"
              >
                Alternative Mobile
              </label>
              <input
                type="text"
                data-type={dataTypes.string}
                className="border rounded-md px-3 py-1"
                id="mail_detail.alternative_mobile"
                name="mail_detail.alternative_mobile"
                value={party.mail_detail.alternative_mobile}
                onChange={handleChange}
              />
            </div>
            <div className="grid gap-0.5">
              <label
                htmlFor="mail_detail.alternative_email"
                className="text-sm"
              >
                Alternative Email
              </label>
              <input
                data-type={dataTypes.string}
                type="email"
                className="border rounded-md px-3 py-1"
                id="mail_detail.alternative_email"
                name="mail_detail.alternative_email"
                value={party.mail_detail.alternative_email}
                onChange={handleChange}
              />
            </div>
            <div className="grid gap-0.5">
              <label htmlFor="mail_detail.address.type" className="text-sm">
                Address type <RequiredMark />
              </label>
              <select
                required
                className="border rounded-md px-3 py-1"
                id="mail_detail.address.type"
                name="mail_detail.address.type"
                data-type={dataTypes.string}
                value={party.mail_detail.address.type}
                onChange={handleChange}
              >
                <option value="" hidden>
                  Select{" "}
                </option>
                {Object.values(AddressType)?.map((type: any) => (
                  <option value={type}>{type}</option>
                ))}
              </select>
            </div>

            <div className="grid gap-0.5">
              <label htmlFor="mail_detail.address.line1" className="text-sm">
                Line1 <RequiredMark />
              </label>
              <input
                required
                type="text"
                data-type={dataTypes.string}
                className="border rounded-md px-3 py-1"
                id="mail_detail.address.line1"
                name="mail_detail.address.line1"
                value={party.mail_detail.address.line1}
                onChange={handleChange}
              />
            </div>
            <div className="grid gap-0.5">
              <label htmlFor="mail_detail.address.line2" className="text-sm">
                Line2
              </label>
              <input
                type="text"
                data-type={dataTypes.string}
                className="border rounded-md px-3 py-1"
                id="mail_detail.address.line2"
                name="mail_detail.address.line2"
                value={party.mail_detail.address.line2}
                onChange={handleChange}
              />
            </div>
            <div className="grid gap-0.5">
              <label htmlFor="mail_detail.address.city" className="text-sm">
                City <RequiredMark />
              </label>
              <input
                required
                type="text"
                data-type={dataTypes.string}
                className="border rounded-md px-3 py-1"
                id="mail_detail.address.city"
                name="mail_detail.address.city"
                value={party.mail_detail.address.city}
                onChange={handleChange}
              />
            </div>
            <div className="grid gap-0.5">
              <label htmlFor="mail_detail.address.state" className="text-sm">
                State <RequiredMark />
              </label>
              <input
                type="text"
                data-type={dataTypes.string}
                className="border rounded-md px-3 py-1"
                id="mail_detail.address.state"
                name="mail_detail.address.state"
                value={party.mail_detail.address.state}
                onChange={handleChange}
              />
            </div>
            <div className="grid gap-0.5">
              <label htmlFor="mail_detail.address.country" className="text-sm">
                Country <RequiredMark />
              </label>
              <input
                type="text"
                data-type={dataTypes.string}
                className="border rounded-md px-3 py-1"
                id="mail_detail.address.country"
                name="mail_detail.address.country"
                value={party.mail_detail.address.country}
                onChange={handleChange}
              />
            </div>

            {/* <div className="grid grid-cols-1  items-center">
              <label htmlFor="pincode">
                Pincode <RequiredMark />
              </label>
              <div className="flex gap-2 col-span-2  items-center">
                <input
                  type="text"   data-type={dataTypes.string}
                  className="border rounded-md px-3 py-1"
                  id="pincode"
                  placeholder="Postal code"
                  pattern="[0-9]{6}"
                  title="Please enter a 6 digit pincode"
                  value={party.mail_detail.address.pincode}
                  onChange={(e) => {
                    let v = 0;

                    if (e.target.value && !isNaN(e.target.value as any)) {
                      v = Number(e.target.value);
                    }

                    if (e.target.value.length <= 6)
                      setParty((o) => ({
                        ...o!,
                        mail_detail: {
                          ...o.mail_detail!,
                          address: { ...o!.mail_detail!.address!, pincode: v },
                        },
                      }));
                    if (e.target.value.length === 6) {
                      getCityState(v);
                    }
                  }}
                />
                <div
                  className="p-2 cursor-pointer bg-gray-200 rounded-full"
                  onClick={() => {
                    if (
                      party.mail_detail.address.pincode?.toString().length === 6
                    ) {
                      getCityState(party.mail_detail.address.pincode);
                    } else {
                      alert("Please enter 6 digits postal code");
                    }
                  }}
                >
                  <Icon path={mdiMagnify} className="w-5 h-5"></Icon>
                </div>
              </div>
            </div> */}

            <div className="grid gap-0.5">
              <label htmlFor="mail_detail.address.pincode" className="text-sm">
                Postal code
              </label>
              <input
                type="text"
                data-type={dataTypes.string}
                className="border rounded-md px-3 py-1"
                id="mail_detail.address.pincode"
                name="mail_detail.address.pincode"
                value={party.mail_detail.address.pincode}
                onChange={handleChange}
              />
            </div>
          </div>
        </section>

        <div className="grid grid-cols-1  md:grid-cols-2 gap-3  ">
          <section className="py-4">
            <h1 className="mb-2 font-semibold flex gap-2 items-center">
              <span className="text-green-500">
                <Icon path={mdiBank} size={0.7} />
              </span>
              Bank Details
            </h1>
            <div className="grid  grid-cols-1  md:grid-cols-2 gap-3 bg-white rounded-md p-2">
              <div className="grid gap-0.5">
                <label htmlFor="bank_detail.ac_no" className="text-sm">
                  Account no.
                </label>
                <input
                  type="text"
                  data-type={dataTypes.string}
                  className="border rounded-md px-3 py-1"
                  id="bank_detail.ac_no"
                  name="bank_detail.ac_no"
                  value={party.bank_detail.ac_no}
                  onChange={handleChange}
                />
              </div>
              {party.loc_type === "Domestic" ? (
                <div className="grid gap-0.5">
                  <label htmlFor="bank_detail.ifsc" className="text-sm">
                    IFSC
                  </label>
                  <input
                    type="text"
                    data-type={dataTypes.string}
                    className="border rounded-md px-3 py-1"
                    id="bank_detail.ifsc"
                    name="bank_detail.ifsc"
                    value={party.bank_detail.ifsc}
                    onChange={handleChange}
                  />
                </div>
              ) : (
                <div className="grid gap-0.5">
                  <label htmlFor="bank_detail.swift_code" className="text-sm">
                    Swift code
                  </label>
                  <input
                    type="text"
                    data-type={dataTypes.string}
                    className="border rounded-md px-3 py-1"
                    id="bank_detail.swift_code"
                    name="bank_detail.swift_code"
                    value={party.bank_detail.swift_code}
                    onChange={handleChange}
                  />
                </div>
              )}
              <div className="grid gap-0.5">
                <label htmlFor="bank_detail.bank_name" className="text-sm">
                  Bank Name
                </label>
                <input
                  type="text"
                  data-type={dataTypes.string}
                  className="border rounded-md px-3 py-1"
                  id="bank_detail.bank_name"
                  name="bank_detail.bank_name"
                  value={party.bank_detail.bank_name}
                  onChange={handleChange}
                />
              </div>
              <div className="grid gap-0.5">
                <label htmlFor="bank_detail.branch" className="text-sm">
                  Branch
                </label>
                <input
                  type="text"
                  data-type={dataTypes.string}
                  className="border rounded-md px-3 py-1"
                  id="bank_detail.branch"
                  name="bank_detail.branch"
                  value={party.bank_detail.branch}
                  onChange={handleChange}
                />
              </div>
            </div>
          </section>

          <section className="py-4">
            <h1 className="mb-2 font-semibold flex gap-2 items-center">
              <span className="text-green-500">
                <Icon path={mdiCurrencyRupee} size={0.7} />
              </span>
              Tax Details
            </h1>
            <div className="grid grid-cols-1  md:grid-cols-2 gap-3 bg-white rounded-md p-2">
              <div className="grid gap-0.5">
                <label htmlFor="tax_detail.pan" className="text-sm">
                  PAN
                </label>
                <input
                  type="text"
                  data-type={dataTypes.string}
                  className="border rounded-md px-3 py-1"
                  id="tax_detail.pan"
                  name="tax_detail.pan"
                  value={party.tax_detail.pan}
                  onChange={handleChange}
                />
              </div>
              <div className="grid gap-0.5">
                <label htmlFor="tax_detail.reg_type" className="text-sm">
                  Registration Type
                </label>
                <select
                  id="tax_detail.reg_type"
                  name="tax_detail.reg_type"
                  className="border rounded-md px-3 py-1"
                  data-type={dataTypes.string}
                  value={party.tax_detail.reg_type}
                  onChange={handleChange}
                >
                  {Object.values(taxRegType).map((type) => (
                    <option value={type}>{type}</option>
                  ))}
                </select>
              </div>
              <div className="grid gap-0.5">
                <label htmlFor="tax_detail.gst_no" className="text-sm">
                  GST no.
                </label>
                <input
                  type="text"
                  data-type={dataTypes.string}
                  id="tax_detail.gst_no"
                  name="tax_detail.gst_no"
                  className="border rounded-md px-3 py-1"
                  value={party.tax_detail.gst_no}
                  onChange={handleChange}
                />
              </div>
            </div>
          </section>
        </div>

        <section className="grid grid-cols-1  sm:grid-cols-2 md:grid-cols-4 gap-3 items-start bg-white rounded-md p-2">
          <div className="grid gap-1">
            <label htmlFor="name" className="text-sm">
              Credit period (in days)
            </label>
            <input
              data-type={dataTypes.number}
              className="border rounded-md px-3 py-1"
              min={0}
              type="number"
              id="credit_period_day"
              name="credit_period_day"
              value={party.credit_period_day}
              onChange={handleChange}
            />
          </div>
          <div className="grid gap-1">
            <label htmlFor="remarks" className="text-sm">
              Remarks
            </label>
            <textarea
              className="border rounded-md px-3 py-1"
              id="remarks"
              name="remarks"
              data-type={dataTypes.string}
              value={party.remarks}
              onChange={handleChange}
            />
          </div>
        </section>
        <div className="flex justify-center">
          <button
            form="party_create_form"
            type="submit"
            className="bg-myPrimaryColor text-white rounded-lg px-6 py-1.5 text-sm cursor-pointer duration-300  "
          >
            Submit
          </button>
        </div>
      </form>
      {creating && <LoadingWidget />}
    </>
  );
};

const RequiredMark = () => (
  <>
    <span className="text-red-500">*</span>
  </>
);

export default PartyCreateScreen;
