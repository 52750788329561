import { FunctionComponent, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import HrmLoader from "../../components/loading";
import { GetAttrition } from "../../service/repos/analytics";

interface AttritionGraphProps {
  fromDate: number;
  toDate: number;
}

const AttritionGraph: FunctionComponent<AttritionGraphProps> = ({
  fromDate,
  toDate,
}) => {
  const [labels, setLabels] = useState<any[]>([]);
  const [chartSeries, setChartSeries] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [attrition, setAttrition] = useState<number>();

  const getAttritionData = async (fromDate: number, toDate: number) => {
    setLoading(true);
    const data = {
      fromDate: fromDate,
      toDate: toDate,
    };
    const res = await GetAttrition(data);
    if (res.success) {
      setAttrition(res.data?.attrition_rate);
      const tempSeries = [res.data?.left?.count, res.data?.total?.count];

      setChartSeries(tempSeries);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAttritionData(fromDate, toDate);
  }, [fromDate, toDate]);

  const options = {
    chart: {
      id: "basic-bar",
      stacked: true,
    },

    labels: ["Employees left", "Employees present"],

    colors: ["#4B69FA", "#AEEBEE"],
  };

  return (
    <div className="border border-gray-200 p-8 lg:p-3 bg-white rounded-2xl">
      <div className="px-2 border-b border-gray-200  pb-2 font-bold">
        Attrition graph
      </div>
      {loading ? (
        <div className="flex flex-col items-center pt-6">
          <HrmLoader className="w-12 h-12" />
        </div>
      ) : (
        <div className=" h-full">
          {/* <Chart
            options={options}
            series={chartSeries}
            type="donut"
            width="100%"
          /> */}
          <div className=" px-4 flex flex-col items-center justify-between h-full">
            <div className="pt-2 font-semibold text-gray-500">
              The attrition rate between the respective range is
            </div>
            <div className="flex flex-col justify-center items-center h-full">
              <div
                className="text-xl lg:text-4xl text-gray-500 w-32 lg:w-48 h-32 lg:h-48 rounded-full flex flex-col items-center justify-center font-bold  "
                style={{
                  boxShadow:
                    "rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px",
                }}
              >
                {attrition?.toFixed(2)}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AttritionGraph;
// box-shadow: blue 0px 0px 0px 2px inset, rgb(255, 255, 255) 10px -10px 0px -3px, rgb(31, 193, 27) 10px -10px, rgb(255, 255, 255) 20px -20px 0px -3px, rgb(255, 217, 19) 20px -20px, rgb(255, 255, 255) 30px -30px 0px -3px, rgb(255, 156, 85) 30px -30px, rgb(255, 255, 255) 40px -40px 0px -3px, rgb(255, 85, 85) 40px -40px;
// box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
