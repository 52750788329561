import { isSet } from "../../../utils/helpers";
export default function Index(props) {
  const { errors, keywords, keywordChange } = props;
  return (
    <>
      <div className="  col-span-6 col-start-4 p-5 mt-10">
        <div className="form-group">
          <label htmlFor="exampleFormControlTextarea1">
            Enter comma(,) separated keywords (without any whitespaces)
          </label>
          <textarea
            rows="3"
            value={keywords ?? ""}
            className="w-full"
            id="exampleFormControlTextarea1"
            onChange={(e) =>
              keywordChange(e.target.value.replace(/[^A-Za-z0-9 ,]/g, ""))
            }
          />
        </div>{" "}
        {isSet(errors) && isSet(errors.keywords) && (
          <div
            className="m-0 p-0 text-danger"
            style={{ fontSize: 12, fontWeight: "bold" }}
          >
            {errors && errors.keywords}
          </div>
        )}
      </div>
    </>
  );
}
