import { FunctionComponent, useEffect, useState } from "react";
import { GetAnniversary } from "../../../../service/repos/hrm/hrm_repo";
import "../styles/index.css";
import Icon from "@mdi/react";
import { mdiAccountCircle } from "@mdi/js";
import { birthdayOfYear, dayOfYear } from "../../../../utils/date_util";
import Layout from "../layout";
import HrmLoader from "../components/loading";
import NoData from "../components/NoData";

interface AnniversaryProps {}

const Anniversary: FunctionComponent<AnniversaryProps> = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const daysList = ["S", "M", "T", "W", "T", "F", "S"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [upcomingAnni, setUpcomingAnni] = useState<{}[]>([]);
  // const [pastAnni, setPastAnni] = useState<{}[]>([]);
  const [showAnimation, setShowAnimation] = useState<boolean>(true);

  const getBirthdayList = async () => {
    const res = await GetAnniversary();

    if (res.success) {
      setLoading(false);
      sortDate(res.data);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setShowAnimation(false);
    }, 6000);
  }, []);

  useEffect(() => {
    dayOfYear();
  }, []);

  const sortDate = (data: any[]) => {
    let now = new Date();
    now = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    let temp: any[] = [];
    temp = data.map((d) => {
      const date = new Date(d.doj);

      return {
        ...d,
        dojToday: new Date(now.getFullYear(), date.getMonth(), date.getDate()),
      };
    });

    temp.sort((d1, d2) => {
      if (d1.dojToday.getTime() > d2.dojToday.getTime()) return 1;
      else if (d1.dojToday.getTime() < d2.dojToday.getTime()) return -1;
      else return 0;
    });
    const tempPast = [];
    const tempUpcoming = [];
    for (let i = 0; i < temp.length; i++) {
      const d = temp[i];
      if (d.dojToday.getTime() < now.getTime()) {
        // setPastAnni((o) => [...o, d]);
        tempPast.push(d);
      } else {
        // setUpcomingAnni((o) => [...o, d]);
        tempUpcoming.push(d);
      }
    }

    // setPastAnni(tempPast);

    setUpcomingAnni(tempUpcoming);
  };

  useEffect(() => {
    getBirthdayList();
  }, []);

  return (
    <Layout pageTitle="Upcoming anniversaries">
      <div className="pt-6">
        {showAnimation && (
          <div className="fixed bottom-0 -right-12 z-50 transform scale-150 -rotate-45">
            <iframe
              src="https://embed.lottiefiles.com/animation/105525"
              title="confetti"
            ></iframe>
          </div>
        )}

        <div className="lg:pl-12 pb-12">
          {loading ? (
            <div className="flex flex-col items-center mt-12">
              <HrmLoader className="w-20 h-20" />
            </div>
          ) : (
            <div className="">
              {upcomingAnni.length > 0 ? (
                <div className="grid lg:grid-cols-4 justify-items-center lg:justify-items-start gap-6 lg:gap-10 mt-10">
                  {upcomingAnni.map((item: any, i) => {
                    return (
                      <div
                        className={`${
                          birthdayOfYear(item.dojToday) - dayOfYear() === 0
                            ? "purple_bg"
                            : "bg-white dark:bg-hrmCardDark"
                        } flex flex-col items-center justify-center py-12 rounded-2xl  w-5/6 lg:w-full `}
                        key={i}
                      >
                        <div className="">
                          {item.profile_image ? (
                            <img
                              src={item?.profile_image}
                              alt=""
                              className="w-20 h-20 mt-2 rounded-full"
                            />
                          ) : (
                            <img
                              src={"/images/cat.jpeg"}
                              alt=""
                              className="w-20 h-20 mt-2 rounded-full"
                            />
                          )}
                        </div>
                        <div
                          className={` ${
                            birthdayOfYear(item.dojToday) - dayOfYear() === 0
                              ? "text-white  font-bold text-lg lg:text-xl"
                              : "text-black dark:text-purple-200 text-base lg:text-lg  "
                          } mt-8 `}
                        >
                          {item.first_name + " " + item.last_name}
                        </div>
                        <div
                          className={` ${
                            birthdayOfYear(item.dojToday) - dayOfYear() === 0
                              ? "text-white"
                              : "text-black dark:text-secondaryColor"
                          } text-sm  mt-1`}
                        >
                          {item.designation}
                        </div>

                        <div className="w-full flex justify-between mt-10 mb-10 px-4 items-center">
                          <div className="text-sm text-secondaryColor">
                            Date of joining
                          </div>
                          <div
                            className={`  ${
                              birthdayOfYear(item.dojToday) - dayOfYear() === 0
                                ? "text-white"
                                : "text-black dark:text-primaryColor"
                            } font-semibold text-sm `}
                          >
                            {item.dojToday.getDate()}{" "}
                            {months[item.dojToday.getMonth()]}{" "}
                            {item.doj.substring(0, 4)}
                          </div>
                        </div>
                        <div className=" flex items-center gap-3 px-4 ">
                          <div className="w-12 h-px bg-secondaryColor"></div>
                          {birthdayOfYear(item.dojToday) - dayOfYear() === 0 ? (
                            <div className="text-white font-bold">Today</div>
                          ) : (
                            <div className=" text-secondaryColor whitespace-nowrap lg:whitespace-normal">
                              {birthdayOfYear(item.dojToday) -
                                dayOfYear() +
                                " days to go"}
                            </div>
                          )}
                          <div className="w-12 h-px bg-secondaryColor"></div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <NoData
                  align="center"
                  text="No upcoming anniversaries"
                  imageSize="large"
                />
              )}
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Anniversary;
