import { addDurationToDate } from "../../../utils/date_util";
import { BaseModel } from "../base";
export const DurationType = {
  month: "Month",
  year: "Year",
  lifetime: "Lifetime",
};
export interface SubscriptionPlanModel extends BaseModel {
  order_id: string;
  org_name?: string;
  org_namespace?: string;
  note?: string;
  end_date: Date;
  start_date: Date;
  // recurring_start: Date;
  // last_renewal_date: Date;
  // duration_type: "Month" | "Year" | "Lifetime";
  // duration_value: number;
  renewal_fee_collection_type: "Full Amount" | "Monthly";
  renewal_fee: number;
  renewal_fee_emi?: number;
  renewal_fee_emi_months?: number;
  amc_collection_type: "Full Amount" | "Monthly";
  amc: number;
  amc_emi?: number;
  amc_emi_months?: number;
  sim_charges_collection_type: "Full Amount" | "Monthly";
  sim_charges: number;
  sim_charges_emi?: number;
  sim_charges_emi_months?: number;
  per_connector_ac: number;
  per_connector_dc: number;
  free_connector_ac: number;
  free_connector_dc: number;
  per_txn_val_type: "Amount" | "Percent";
  per_txn_value: number;
  per_unit: number;
  active?: boolean;

  deactivated_at?: Date;
  deactivated_by?: any;
  deactivated_by_name?: string;
}
export const subscriptionPlanDefault: SubscriptionPlanModel = {
  order_id: "",
  start_date: new Date(),
  end_date: addDurationToDate(new Date(), { years: 1 }),
  renewal_fee_collection_type: "Full Amount",
  renewal_fee: 0,
  per_connector_ac: 0,
  per_connector_dc: 0,

  per_txn_val_type: "Amount",
  per_txn_value: 0,
  per_unit: 0,
  amc_collection_type: "Full Amount",
  amc: 0,
  sim_charges_collection_type: "Full Amount",
  sim_charges: 0,
  // last_renewal_date: new Date(),
  // duration_type: "Month",
  // duration_value: 0,
  free_connector_ac: 0,
  free_connector_dc: 0,
};

export interface SubscriptionBillModel extends BaseModel {
  from_date: Date;
  to_date: Date;
  plan_id: string;
  bill_id: string;
  user_id: string;
  org_name: string;
  org_namespace: string;
  type: string;
  items: {
    name: string;
    qty: number;
    qty_type: string;
    tax: number;
    tax_items: { name: string; rate: number; amount: string }[];
    price: number;
    amount: number;
  }[];
  subtotal: number;
  discount: number;
  tax_items: { name: string; amount: number; rate: number }[];
  tax: number;
  total: number;
  date: Date;
  paid: number;
}
