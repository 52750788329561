import { FunctionComponent } from "react";
import { X } from "react-feather";
import { NavLink } from "react-router-dom";
import { urls } from "../../../../utils/urls";
import Card from "../components/Card";

interface ProtocolCategoryProps {}

const ProtocolCategory: FunctionComponent<ProtocolCategoryProps> = () => {
  const links = [
    {
      name: "Installations",
      link: urls.installation_protocol,
      logo: "/academy/installations.png",
    },
    {
      name: "Saas",
      link: urls.saas_protocol,
      logo: "/academy/cms.png",
    },
    {
      name: "Complaint",
      link: urls.complaint_segment,
      logo: "/academy/complaint.png",
    },
    {
      name: "Inside sales pitch",
      link: urls.inside_sales_protocol,
      logo: "/academy/insides.png",
    },
    {
      name: "Sales Training",
      link: urls.sales_training_protocol,
      logo: "/academy/sales.png",
    },
    {
      name: "Hub creation",
      link: urls.hub_creation_checklist,
      logo: "/academy/hub.png",
    },
    {
      name: "Kazam warranty",
      link: urls.kazam_warranty,
      logo: "/academy/cms.png",
    },
  ];
  return (
    <div className="relative bg-hrmBgLight h-screen">
      <div className="absolute top-4 right-4">
        <NavLink to={urls.kazam_academy}>
          <X className="text-black" size={48} />
        </NavLink>
      </div>
      <div className="flex flex-col items-center justify-center pt-20 lg:pt-32">
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-12 mt-8 w-11/12 lg:w-4/5">
          {links.map(
            (el: { name: string; link: string; logo: string }, i: number) => {
              return <Card data={el} />;
            }
          )}
        </div>
      </div>
    </div>
  );
};

export default ProtocolCategory;
