import { XIcon } from "@heroicons/react/solid";
import { FunctionComponent } from "react";

interface FilterDropDownProps {
  onSelect: (val: any) => void;
  onClearAll: () => void;
  onDelete: (val: any) => void;
  options?: { label: string; value: any }[];
  selectedOptions?: any[];
  placeholder?: string;
}

const FilterDropDown: FunctionComponent<FilterDropDownProps> = ({
  onSelect,
  onClearAll,
  onDelete,
  options = [],
  selectedOptions = [],
  placeholder = "Select",
}) => {
  return (
    <div className=" ">
      <div className="">
        <select
          className="border rounded text-sm w-full h-8 focus:outline-none cursor-pointer"
          aria-label="Default select example"
          name="drop-down"
          // defaultValue={filter?.type}
          onChange={(e) => {
            if (!selectedOptions.includes(e.target.value))
              onSelect(e.target.value);
            e.target.value = "";
            // document.getElementById("order-type")!.value="";
          }}
        >
          <option value="" selected disabled hidden>
            {placeholder}
          </option>
          {options.map((option, i) => (
            <option key={i} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      {selectedOptions && selectedOptions.length > 0 && (
        <div className="flex mt-2 items-center gap-1 flex-wrap  ">
          {selectedOptions.map((val, i) => (
            <div
              key={i}
              style={{ fontSize: "10px" }}
              className="  font-semibold bg-gray-200 rounded-3xl pl-2   flex items-center"
            >
              <p className="w-14 truncate">
                {options.find((op) => op.value == val)?.label}
              </p>
              <XIcon
                className="h-2 w-2 hover:bg-gray-400 hover:text-white  rounded-full mx-1"
                onClick={() => onDelete(val)}
              />
            </div>
          ))}
          <div
            onClick={onClearAll}
            style={{ fontSize: "10px" }}
            className="  font-semibold bg-gray-100 text-red-800 rounded-3xl px-2   cursor-pointer"
          >
            clear all
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterDropDown;
