import { FunctionComponent, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import { objectToFormdata } from "../../../../../utils/objectToFormData";
import { urls } from "../../../../../utils/urls";
import HrmLoader from "../../components/loading";
import Layout from "../../layout";
import {
  JobApplication,
  JobApplicationStatus,
} from "../../service/models/job_portal";
import { PostJobApplication } from "../../service/repos/job_portal";

const defaultValue: JobApplication = {
  jd_id: "",
  ref: {
    ref_code: "",
  },
  name: "",
  email: "",
  mobile: "",
  experience: "",
  linkedin: "",
  file: "",
  status: JobApplicationStatus.submitted,
};

interface JobApplyProps {}

const JobApply: FunctionComponent<JobApplyProps> = (props) => {
  const [formData, setFormData] = useState<JobApplication>(defaultValue);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const { showToast } = useToast();
  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  const { id } = useParams<{ id: string }>();
  const { ref_id } = useParams<{ ref_id: string }>();
  console.log(ref_id);

  const submitForm = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    setDisabled(true);

    const form = {
      jd_id: id,
      ref: {
        ref_code: ref_id,
      },
      name: formData.name,
      email: formData.email,
      mobile: formData.mobile,
      experience: formData.experience,
      linkedin: formData.linkedin,
      status: JobApplicationStatus.submitted,
    };

    // const data = objectToFormdata(form);
    const tempForm = new FormData();
    tempForm.append("file", file!);
    tempForm.append("data", JSON.stringify(form));

    const res = await PostJobApplication(tempForm);

    if (res.success) {
      setLoading(false);
      setShowSuccess(true);
    } else {
      setLoading(false);
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
  };

  return (
    <Layout>
      <div className="py-4 lg:pr-4 h-screen">
        <div className="flex h-full lg:pl-3">
          <div className=" w-1/3 hidden lg:flex flex-col items-center justify-center rounded-3xl relative bg-hrmBlue">
            <div className="  font-bold absolute top-4 left-7 text-white ">
              Apply for a friend
            </div>
            <div className="w-4/5">
              <div className="mt-12 text-4xl font-bold text-white">
                Fill in the details{" "}
                <span className="mt-2 block"> of the applicant. </span>
              </div>
              <div className="mt-6 text-white text-opacity-90">
                Fill the details or share the referral code to your friend and
                he will do the same from the Kazam website.
              </div>
            </div>
          </div>
          {showSuccess ? (
            <div className="flex flex-col items-center justify-center w-full lg:w-2/3">
              <div className="">
                <div className="text-3xl font-semibold">
                  Applied for job successfully
                </div>
                <div className="mt-2 flex flex-col items-center">
                  <NavLink to={urls.hrm_job_portal}>
                    <button className="text-white rounded py-2 px-6 bg-hrmBlue">
                      Go to job portal
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-full lg:w-2/3 flex flex-col items-center pt-12">
              <form
                action=""
                className="grid gap-x-12 gap-y-8 lg:w-2/3"
                onSubmit={submitForm}
              >
                <div className="w-full">
                  <label
                    htmlFor=""
                    className="text-gray-500 text-sm font-semibold "
                  >
                    Name
                    <br />
                    <input
                      type="text"
                      className="w-full py-3 px-4 bg-white shadow-md focus:outline-none rounded-lg text-black font-semibold mt-1 focus:ring-2 focus:ring-blue-500"
                      required
                      disabled={disabled}
                      defaultValue={defaultValue.name}
                      onChange={(e) =>
                        setFormData((o) => ({
                          ...o,
                          name: e.target.value,
                        }))
                      }
                    />
                  </label>
                </div>
                <div className="w-full">
                  <label
                    htmlFor=""
                    className="text-gray-500 text-sm font-semibold "
                  >
                    Email
                    <br />
                    <input
                      type="email"
                      className="w-full py-3 px-4 bg-white shadow-md focus:outline-none rounded-lg text-black font-semibold mt-1 focus:ring-2 focus:ring-blue-500"
                      required
                      disabled={disabled}
                      defaultValue={defaultValue.email}
                      onChange={(e) =>
                        setFormData((o) => ({
                          ...o,
                          email: e.target.value,
                        }))
                      }
                    />
                  </label>
                </div>

                <div className="w-full">
                  <label
                    htmlFor=""
                    className="text-gray-500 text-sm font-semibold "
                  >
                    Mobile
                    <br />
                    <input
                      type="text"
                      className="w-full py-3 px-4 bg-white shadow-md focus:outline-none rounded-lg text-black font-semibold mt-1 focus:ring-2 focus:ring-blue-500"
                      required
                      disabled={disabled}
                      defaultValue={defaultValue.mobile}
                      onChange={(e) =>
                        setFormData((o) => ({
                          ...o,
                          mobile: e.target.value,
                        }))
                      }
                    />
                  </label>
                </div>

                <div className="w-full">
                  <label
                    htmlFor=""
                    className="text-gray-500 text-sm font-semibold "
                  >
                    Experience(optional)
                    <br />
                    <input
                      type="text"
                      className="w-full py-3 px-4 bg-white shadow-md focus:outline-none rounded-lg text-black font-semibold mt-1 focus:ring-2 focus:ring-blue-500"
                      disabled={disabled}
                      defaultValue={defaultValue.experience}
                      onChange={(e) =>
                        setFormData((o) => ({
                          ...o,
                          experience: e.target.value,
                        }))
                      }
                    />
                  </label>
                </div>
                <div className="w-full">
                  <label
                    htmlFor=""
                    className="text-gray-500 text-sm font-semibold "
                  >
                    Linkedin(optional)
                    <br />
                    <input
                      type="text"
                      className="w-full py-3 px-4 bg-white shadow-md focus:outline-none rounded-lg text-black font-semibold mt-1 focus:ring-2 focus:ring-blue-500"
                      disabled={disabled}
                      defaultValue={defaultValue.linkedin}
                      onChange={(e) =>
                        setFormData((o) => ({
                          ...o,
                          linkedin: e.target.value,
                        }))
                      }
                    />
                  </label>
                </div>
                <div className="w-full">
                  <label
                    htmlFor=""
                    className="text-gray-500 text-sm font-semibold "
                  >
                    Resume(pdf or doc)
                    <br />
                    <input
                      type="file"
                      accept="application/pdf,application/msword"
                      className="w-full py-3 px-4 bg-white shadow-md focus:outline-none rounded-lg text-black font-semibold mt-1 focus:ring-2 focus:ring-blue-500"
                      required
                      disabled={disabled}
                      onChange={(e) => {
                        const file = (e.target.files || [])[0];
                        if (
                          file &&
                          (file.type === "application/pdf" ||
                            file.type === "application/msword" ||
                            file.type.startsWith("image"))
                        ) {
                          setFile((e.target.files || [])[0]);
                        } else {
                          showToast({
                            type: ToastType.error,
                            text: "Please enter suggested type",
                          });
                        }
                      }}
                    />
                  </label>
                </div>
                {!loading ? (
                  <div className="w-full flex flex-col items-end ">
                    <button className="py-3 px-8 rounded-lg bg-hrmBlue text-white font-bold">
                      Apply for job
                    </button>
                  </div>
                ) : (
                  <div className="flex flex-col items-end ">
                    <HrmLoader className="w-12 h-12" />
                  </div>
                )}
              </form>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default JobApply;
