import { FunctionComponent, useEffect, useState } from "react";
import { Camera, Download, Trash, Upload, X } from "react-feather";
import { useAuth } from "../../../../../context_providers/auth/authProvider";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import { EmpDoc } from "../../../../../service/models/user_model";
import {
  DeleteMyDoc,
  GetDocs,
  GetMyDocs,
  PostMyDocs,
  UpdateMyDoc,
} from "../../../../../service/repos/hrm/hrm_repo";
import { EmpDocType } from "../../../../../utils/enums";
import HrmButton from "../../components/HrmButton";
import HrmPopup from "../../components/HrmPopup";
import HrmLoader from "../../components/loading";

interface DocumentsProps {
  uid: any;
}

const Documents: FunctionComponent<DocumentsProps> = ({ uid }) => {
  console.log(uid);

  // const [loading, setLoading] = useState<boolean>(false);
  const [docs, setDocs] = useState<EmpDoc[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [documentTypes, setDocumentTypes] = useState<{ [key: string]: string }>(
    EmpDocType
  );
  const { user } = useAuth();
  const { showToast } = useToast();

  // get documents
  const getDocs = async (uid: string) => {
    const res = await GetDocs(uid);

    if (res.success) {
      setDocs(res.data);
      if (res.data.length) {
        const docs = Object.keys(EmpDocType);
        setDocumentTypes((o) => {
          for (let i = 0; i < res.data.length; i++) {
            for (let j = 0; j < docs.length; j++) {
              if (res.data[i].category === o[docs[j]]) {
                delete o[docs[j]];
              }
            }
          }
          return { ...o };
        });
      }
    }
  };

  const getMyDocs = async (uid: string) => {
    const res = await GetMyDocs(uid);

    if (res.success) {
      setDocs(res.data);
      if (res.data.length) {
        const docs = Object.keys(EmpDocType);
        setDocumentTypes((o) => {
          for (let i = 0; i < res.data.length; i++) {
            for (let j = 0; j < docs.length; j++) {
              if (res.data[i].category === o[docs[j]]) {
                delete o[docs[j]];
              }
            }
          }
          return { ...o };
        });
      }
    }
  };

  const postDocData = async (uid: any, doc: any, category: string) => {
    const formData = new FormData();
    formData.append("attachment", doc);
    formData.append("uid", uid);
    formData.append("category", category);

    console.log(uid, doc, category);

    const res = await PostMyDocs(uid, formData);
    if (res.success) {
      showToast({
        type: ToastType.success,
        text: "Doc submitted successfully",
      });

      getDocs(uid);
    } else {
      showToast({
        type: ToastType.error,
        text: res.error ?? "",
      });
    }
    setShowModal(false);
  };

  // delete a document
  const deleteDocData = async (uid: string, id: number) => {
    const res = await DeleteMyDoc(uid, id);
    if (res.success) {
      showToast({
        type: ToastType.success,
        text: "Doc deleted successfully",
      });
      getDocs(uid);
    } else {
      showToast({
        type: ToastType.error,
        text: res.error ?? "",
      });
    }
  };

  useEffect(() => {
    if (user?.uid) {
      if (user.uid === uid) {
        getMyDocs(uid);
      } else {
        getDocs(uid);
      }
    }
  }, []);

  return (
    <div className="relative">
      {user?.uid === uid && (
        <HrmButton
          className="absolute top-2 right-3"
          type="primary"
          text="Add doc"
          onClick={() => setShowModal(true)}
        />
      )}
      <div className="flex flex-col items-center pt-20 w-full">
        {docs.length > 0 ? (
          <div className="grid lg:grid-cols-4 gap-4 lg:gap-12 w-full">
            {docs.map((el, i) => {
              return (
                <div
                  className="shadow-lg rounded-xl py-10 px-3 relative"
                  key={i}
                >
                  <div className="absolute top-2 right-2">
                    {user?.uid === uid && (
                      <Trash
                        size={16}
                        className="text-hrmRed cursor-pointer"
                        onClick={() => {
                          const confirmCheck = window.confirm(
                            "Are you sure you want to delete the document?"
                          );

                          if (confirmCheck) {
                            deleteDocData(el.uid, el.id!);
                          }
                        }}
                      />
                    )}
                  </div>
                  <a href={el?.url} target="_blank" rel="noreferrer">
                    <div className="flex flex-col items-center">
                      <div className="">
                        <Download
                          size={32}
                          className="text-hrmBlue inline ml-2"
                        />
                      </div>
                      <div className="mt-6 font-semibold text-lg">
                        {el.category}
                      </div>
                    </div>
                  </a>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="font-semibold text-xl text-gray-400">
            No docs present
          </div>
        )}
      </div>
      {user?.uid === uid && showModal && (
        <HrmPopup>
          <AddDocModal
            documentTypes={documentTypes}
            close={() => setShowModal(false)}
            postDocData={postDocData}
          />
        </HrmPopup>
      )}
    </div>
  );
};

export default Documents;

interface AddDocProps {
  documentTypes: any;
  close: () => void;
  postDocData: (uid: any, doc: any, category: string) => void;
}

const AddDocModal: FunctionComponent<AddDocProps> = ({
  documentTypes,
  close,
  postDocData,
}) => {
  const { showToast } = useToast();
  const [category, setCategory] = useState<string>("");
  const { user } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  return (
    <div className="w-11/12 lg:w-1/3 bg-white pt-20 pb-12 px-8 rounded-lg shadow-lg relative">
      <div className="absolute top-3 right-3 cursor-pointer">
        <X size={28} onClick={() => close()} />
      </div>
      <select
        name=""
        id=""
        className="w-full bg-white border border-gray-300 py-3 px-3 rounded-md focus:outline-none font-semibold"
        onChange={(e) => setCategory(e.target.value)}
      >
        <option value="Select" hidden>
          Select
        </option>
        {Object.keys(documentTypes).map((el, i) => {
          return (
            <option value={documentTypes[el]} className="">
              {documentTypes[el]}
            </option>
          );
        })}
      </select>
      <div className="relative mt-4">
        <label htmlFor="doc-input" className="cursor-pointer">
          <div className="py-4 px-12 rounded-md border border-dashed border-hrmBlue inline-block">
            <Upload size={20} className="text-hrmBlue" />
          </div>
        </label>

        <input
          type="file"
          name="file"
          id="doc-input"
          accept="application/pdf/*"
          onChange={(e: any) => {
            if (e.target.files[0]) {
              if (e.target.files[0].type != "application/pdf") {
                showToast({
                  type: ToastType.error,
                  text: "Please upload pdf",
                });
                e.target.value = "";
              } else {
                const confirmCheck = window.confirm(
                  "Are you sure you want to update the document?"
                );

                if (confirmCheck) {
                  if (user?.uid) {
                    setLoading(true);
                    postDocData(user.uid!, e.target.files[0], category);
                  }
                }
              }
            }
          }}
          className="hidden invisible"
        />
      </div>
      <div className="flex flex-col items-center mt-12">
        {loading ? (
          <HrmLoader className="w-12 h-12" />
        ) : (
          <HrmButton type="primary" text="Submit" />
        )}
      </div>
    </div>
  );
};
