import { FunctionComponent, useState } from "react";
import Sidebar from "../../components/Sidebar";
import ProtocolLayout from "../components/ProtocolLayout";
import Faq from "../components/Faq";

interface InsideSalesProps {}

const InsideSales: FunctionComponent<InsideSalesProps> = () => {
  const [active, setActive] = useState<string>("english");

  return (
    <ProtocolLayout>
      <div className="flex flex-col items-center">
        <div className="mt-20 w-11/12 lg:w-4/5">
          <div className="flex gap-3 items-center justify-center">
            <div
              className={` ${
                active === "english"
                  ? "bg-black text-white"
                  : "bg-transparent text-black"
              } text-xl rounded-lg border border-black border-opacity-60 cursor-pointer py-2 px-6`}
              onClick={() => setActive("english")}
            >
              English
            </div>
            <div
              className={` ${
                active === "hindi"
                  ? "bg-black text-white"
                  : " bg-transparent text-black"
              } text-xl rounded-lg border border-black border-opacity-60 cursor-pointer py-2 px-6`}
              onClick={() => setActive("hindi")}
            >
              Hindi
            </div>
            <div
              className={` ${
                active === "faq"
                  ? "bg-black text-white"
                  : " bg-transparent text-black"
              } text-xl rounded-lg border border-black border-opacity-60 cursor-pointer py-2 px-6`}
              onClick={() => setActive("faq")}
            >
              Faq
            </div>
          </div>
          {active === "faq" ? (
            <Faq />
          ) : (
            <div className="pt-12 lg:pt-0 pb-32">
              <div className=" lg:mt-20">
                <div className="text-xl lg:text-3xl font-semibold">
                  Introduction
                </div>
                {active === "english" ? (
                  <div className="mt-2 lg:mt-4 leading-8">
                    Hi Sir, this Vaibhav from Kazam. You had placed an inquiry
                    about electric vehicle charging stations (complete sentence
                    without pause)
                  </div>
                ) : (
                  <div className="mt-2 lg:mt-4 leading-8">
                    Hi Sir, this Vaibhav from Kazam EV. Tech. Pvt. Ltd. . आपने
                    इलेक्ट्रिक वाहन चार्जिंग स्टेशनों के बारे में एक जांच रखी थी
                  </div>
                )}
              </div>
              <div className="mt-8 lg:mt-20">
                <div className="text-xl lg:text-3xl font-semibold">
                  Pre-leminary questions
                </div>
                {active === "english" ? (
                  <ul className="mt-2 lg:mt-4 leading-8 list-decimal pl-6">
                    <li>
                      {" "}
                      Sir, may I know if you are looking for a charging station
                      for commercial purpose or personal purpose
                    </li>
                    <li>
                      Do u have a parking spot available where a bike or scooter
                      or can be parked?
                    </li>
                  </ul>
                ) : (
                  <ul className="mt-2 lg:mt-4 leading-8 list-decimal pl-6">
                    <li>
                      {" "}
                      Sir, क्या मैं जान सकता हूं कि आप वाणिज्यिक उद्देश्य या
                      व्यक्तिगत उद्देश्य के लिए चार्जिंग स्टेशन की तलाश कर रहे
                      हैं
                    </li>
                    <li>क्या आपके पास पार्किंग स्थल उपलब्ध है?</li>
                  </ul>
                )}
              </div>{" "}
              <div className="mt-8 lg:mt-20">
                <div className="text-xl lg:text-3xl font-semibold">
                  Explain types of EV charger
                </div>
                {active === "english" ? (
                  <ul className="mt-2 lg:mt-4 leading-8 pl-6 list-decimal">
                    <li>
                      {" "}
                      Sir, please let me explain the system to you first:
                    </li>
                    <li>We are offering AC chargers</li>
                    <li>
                      AC chargers charge a bike (80%) in two to three hours
                      (depending on the battery) and charge a car (80%) anywhere
                      between 6-8 hrs depending on the car.
                    </li>
                  </ul>
                ) : (
                  <ul className="mt-2 lg:mt-4 leading-8 pl-6 list-decimal">
                    <li> कृपया मुझे पहले आपको सिस्टम explain karne दें:</li>
                    <li>" हम एसी चार्जर दे रहे हैं"</li>{" "}
                    <li>
                      1 एसी चार्जर जो आमतौर पर धीमे चार्जर्स होते हैं और कार या
                      बाइक के आधार पर 3-4 ghante के बीच वाहन चार्ज करते हैं।
                    </li>
                  </ul>
                )}
              </div>
              <div className="mt-8 lg:mt-20">
                <div className="text-xl lg:text-3xl font-semibold">
                  Explain our product
                </div>
                {active === "english" ? (
                  <ul className="mt-2 lg:mt-4 leading-8 pl-6 list-decimal">
                    <li>
                      Kazam offers a 3.3 KW IOT based SIM based smart charger
                      for your parking spot
                    </li>
                    <li>
                      You can charge bikes, scooters and rickshaws from this
                      charger. (and car also)
                    </li>
                    <li>
                      It is a water proof, smart charging station thorugh which
                      EV owners can charge their EV and you can earn money
                      through it
                    </li>
                    <li>
                      When the user/driver charges, money from his balance will
                      be deducted and money in your balance will be added
                    </li>
                    <li>
                      EV drivers will be able to search your charging station
                      via google maps
                    </li>
                    <li>
                      "If personal use, then add 3 more points:
                      <br />
                      -You can remotely operate charging station via app <br />{" "}
                      -You can protect your battery against any current surge as
                      charger has MCB and Fuse <br /> -You can monitor current
                      concumption"
                    </li>
                  </ul>
                ) : (
                  <ul className="mt-2 lg:mt-4 leading-8 pl-6 list-decimal">
                    <li>
                      Kazam आपके पार्किंग स्थल के लिए 3.3 KW IOT आधारित सिम
                      आधारित स्मार्ट चार्जर प्रदान करता है
                    </li>
                    <li>
                      आप इस चार्जर से बाइक, स्कूटर और रिक्शा चार्ज कर सकते हैं।
                    </li>{" "}
                    <li>
                      यह एक वॉटर प्रूफ, एंटी थेफ्ट स्मार्ट चार्जिंग स्टेशन थोरुघ
                      है जिसे ईवी मालिक अपने ईवी को चार्ज कर सकते हैं और आप इसके
                      माध्यम से पैसा कमा सकते हैं
                    </li>
                    <li>
                      When the user charges, moeny from his balance will be
                      deducted and money in his balance will be added
                    </li>
                    <li>
                      ईवी ड्राइवर आपके चार्जिंग स्टेशन को गूगल मैप्स के माध्यम
                      से खोज सकेंगे
                    </li>
                    <li>
                      "यदि व्यक्तिगत उपयोग है, तो 3 और अंक जोड़ें:
                      <br />
                      -आप ऐप के जरिए चार्जिंग स्टेशन को रिमोटली ऑपरेट कर सकते
                      हैं
                      <br />
                      -आप अपनी बैटरी को किसी भी मौजूदा उछाल से बचा सकते हैं
                      क्योंकि चार्जर में एमसीबी और फ्यूज होता है
                      <br />
                      -आप वर्तमान खपत की निगरानी कर सकते हैं"
                    </li>
                  </ul>
                )}
              </div>{" "}
              <div className="mt-8 lg:mt-20">
                <div className="text-xl lg:text-3xl font-semibold">
                  Pricing, payment terms
                </div>
                {active === "english" ? (
                  <ul className="mt-2 lg:mt-4 leading-8 pl-6 list-decimal">
                    <li>
                      pricing is Rs 12,600/- including delivery, GST and
                      Installation (price subject to change)
                    </li>
                    <li>
                      you can book the order for an ammount of Rs 900 by paying
                      us online and we will deliver your charging station within
                      20-30 days of booking
                    </li>
                    <li>rest of the payment before delivery</li>
                    <li>
                      please note delivery, GST and installation included{" "}
                    </li>
                  </ul>
                ) : (
                  <ul className="mt-2 lg:mt-4 leading-8 pl-6 list-decimal">
                    <li> pricing is Rs 12,600/-</li>
                    <li>
                      आप हमें ऑनलाइन payment करके 900 रुपये के अमाउंट के लिए
                      ऑर्डर बुक कर सकते हैं और बुकिंग के 30-45 के भीतर हम आपके
                      chrging स्टेशन को डिलीवर कर देंगे।
                    </li>{" "}
                    <li>rest of the payment before delivery</li>
                    <li>please note delivery and installation is free</li>
                  </ul>
                )}
              </div>{" "}
              <div className="mt-8 lg:mt-20">
                <div className="text-xl lg:text-3xl font-semibold">
                  Additional Benefits
                </div>
                {active === "english" ? (
                  <ul className="mt-2 lg:mt-4 leading-8 pl-6 list-decimal">
                    <li> 1 year replacement warranty and +5 year free AMC</li>
                    <li>
                      Internet connectivity via SIM is inclusive and no extra
                      charges
                    </li>
                    <li>GST is included</li>
                    <li>Free delivery and free installation</li>
                  </ul>
                ) : (
                  <ul className="mt-2 lg:mt-4 leading-8 pl-6 list-decimal">
                    <li> 1 साल की रिप्लेसमेंट वारंटी</li>
                    <li>सिम के माध्यम से इंटरनेट कनेक्टिविटी</li>{" "}
                    <li>जीएसटी शामिल है</li>
                    <li>मुफ्त delivery और मुफ्त installation</li>
                  </ul>
                )}
              </div>
              <div className="mt-8 lg:mt-20">
                <div className="text-xl lg:text-3xl font-semibold">
                  How to Buy?
                </div>
                {active === "english" ? (
                  <ul className="mt-2 lg:mt-4 leading-8 pl-6 list-decimal">
                    <li>
                      Sir, you can book the product at 900 Rs after which we
                      will ship the product within 20 days (since this build to
                      order model)
                    </li>
                    <li>
                      I will send you the link right away (SMS or whatsapp) and
                      you can pay for the same
                    </li>
                    <li>
                      We are running a discount scheme, if you book the order
                      before xxx date, there is one year extra service warranty
                    </li>
                  </ul>
                ) : (
                  <ul className="mt-2 lg:mt-4 leading-8 pl-6 list-decimal">
                    <li>
                      "महोदय, आप उत्पाद को 900 रुपये में बुक कर सकते हैं जिसके
                      बाद हम उत्पाद को 20 दिनों के भीतर भेज देंगे (चूंकि यह मॉडल
                      ऑर्डर करने के लिए निर्मित है) मैं आपको तुरंत लिंक भेजूंगा
                      (एसएमएस या व्हाट्सएप) और आप इसके लिए भुगतान कर सकते हैं हम
                      छूट योजना चला रहे हैं, यदि आप xxx तिथि से पहले ऑर्डर बुक
                      करते हैं, तो एक वर्ष की अतिरिक्त सेवा वारंटी है"
                    </li>
                  </ul>
                )}
              </div>
              <div className="text-xl lg:text-2xl font-semibold mt-8">
                After the call is done, it is mandatory to send details of
                company and product to the customer on whatsapp/SMS + Product
                Catalogue + Demo video
              </div>
            </div>
          )}
        </div>
      </div>
    </ProtocolLayout>
  );
};

export default InsideSales;
