import moment from "moment";
import ToggleBtn from "../../../ui/new_components/common/toggle_btn";
import { UTCToLocalDate } from "../../../utils/date_util";

export enum FieldType {
  string,
  long_string,
  number,
  currency,
  file,
  boolean,
  date,
  datetime,
}
export enum InputType {
  email,
  phone,
  password,
  text,
  longText,
  number,
  checkbox,
  radio,
  switch,
  dropDown,
  date,
  dateTime,
  file,
}

export interface FormFieldProps {
  key?: any;
  form?: string;
  id?: string;
  name: string;
  placeholder?: string;
  label: string;
  type: FieldType;
  inputType: InputType;
  options?: {
    title: string;
    value: any;
  }[];
  required?: boolean;
  title?: string;
  onChange?: (t: { [key: string]: any }) => void;
  value: any;
}

export const getInputField = ({
  key,
  form,
  inputType,
  id,
  name,
  label,
  value,
  options,
  required,
  title,
  type,
  placeholder,
  onChange,
}: FormFieldProps) => {
  switch (inputType) {
    case InputType.text:
      return (
        <div key={key} className="flex flex-col items-start text-sm w-full">
          <label className="font-semibold w-full" htmlFor={id}>
            {label}
          </label>
          <input
            placeholder={placeholder}
            className="p-1 rounded  focus:outline-none w-full"
            id={id}
            name={name}
            value={value || ""}
            title={title}
            required={required || false}
            type="text"
            onChange={(e) => {
              const t: { [key: string]: any } = {};
              t[e.target.name] = e.target.value;
              if (onChange) onChange(t);
            }}
            form={form}
          ></input>
        </div>
      );
    case InputType.number:
      return (
        <div key={key} className="flex flex-col items-start text-sm w-full">
          <label className="font-semibold w-full" htmlFor={id}>
            {label}
          </label>
          <input
            placeholder={placeholder}
            className="p-1 rounded  focus:outline-none w-full"
            id={id}
            name={name}
            value={value || ""}
            title={title}
            required={required || false}
            type="number"
            onChange={(e) => {
              const t: { [key: string]: any } = {};
              t[e.target.name] = Number(e.target.value);
              if (onChange) onChange(t);
            }}
            form={form}
          ></input>
        </div>
      );
    case InputType.email:
      return (
        <div key={key} className="flex flex-col items-start text-sm w-full">
          <label className="font-semibold" htmlFor={id}>
            {label}
          </label>
          <input
            placeholder={placeholder}
            className="p-1 rounded  focus:outline-none w-full"
            id={id}
            name={name}
            value={value || ""}
            title={title}
            required={required || false}
            type="email"
            onChange={(e) => {
              const t: { [key: string]: any } = {};
              t[e.target.name] = e.target.value;
              if (onChange) onChange(t);
            }}
            form={form}
          ></input>
        </div>
      );
    case InputType.phone:
      return (
        <div key={key} className="flex flex-col items-start text-sm w-full">
          <label className="font-semibold" htmlFor={id}>
            {label}
          </label>
          <input
            placeholder={placeholder}
            className="p-1 rounded  focus:outline-none w-full"
            id={id}
            name={name}
            value={value || ""}
            title={title}
            required={required || false}
            type="tel"
            onChange={(e) => {
              const t: { [key: string]: any } = {};
              t[e.target.name] = e.target.value;
              if (onChange) onChange(t);
            }}
            form={form}
            pattern="[1-9]{1}[0-9]{9}"
          ></input>
        </div>
      );
    case InputType.longText:
      return (
        <div key={key} className="flex flex-col items-start text-sm w-full">
          <label className="font-semibold" htmlFor={id}>
            {label}
          </label>
          <textarea
            placeholder={placeholder}
            className="p-1 rounded  focus:outline-none w-full"
            id={id}
            name={name}
            value={value || ""}
            title={title}
            required={required || false}
            onChange={(e) => {
              const t: { [key: string]: any } = {};
              t[e.target.name] = e.target.value;
              if (onChange) onChange(t);
            }}
            form={form}
            // pattern="[1-9]{1}[0-9]{9}"
          ></textarea>
        </div>
      );

    case InputType.dropDown:
      return (
        <div key={key} className="flex flex-col items-start text-sm w-full">
          <label className="font-semibold w-full" htmlFor={id}>
            {label}
          </label>
          <select
            placeholder={placeholder}
            className="p-1 rounded  focus:outline-none w-full"
            id={id}
            name={name}
            value={value || ""}
            title={title}
            required={required || false}
            onChange={(e) => {
              const t: { [key: string]: any } = {};
              t[name] = JSON.parse(e.target.value);
              if (onChange) onChange(t);
            }}
            form={form}
          >
            {options?.map((op, i) => (
              <option key={i} value={JSON.stringify(op.value)}>
                {op.title}
              </option>
            ))}
          </select>
        </div>
      );
    case InputType.radio:
      return (
        <div key={key} className=" flex gap-5  items-center w-full">
          {" "}
          <div className="font-semibold">{label}</div>
          <div className="flex items-center gap-5 flex-wrap  w-full">
            {options?.map((option, i) => (
              <div
                key={i}
                title={option.title}
                className="flex  justify-start items-center text-sm gap-2"
              >
                <input
                  className="p-1 rounded  focus:outline-none"
                  id={option.title + key}
                  name={name}
                  checked={value === option.value}
                  required={required || false}
                  type="radio"
                  onChange={(e) => {
                    if (e.target.checked) {
                      const t: { [key: string]: any } = {};
                      t[name] = option.value;
                      if (onChange) onChange(t);
                    }
                  }}
                  form={form}
                ></input>
                <label className="font-medium" htmlFor={option.title + key}>
                  {option.title}
                </label>
              </div>
            ))}
          </div>
        </div>
      );
    case InputType.checkbox:
      return (
        <div key={key} className=" flex gap-5  items-center w-full">
          <div className="font-semibold ">{label}</div>
          <div className="flex items-center gap-5  flex-wrap  w-full">
            {options?.map((option, i) => (
              <div
                key={i}
                className="flex  justify-start items-center text-sm gap-2"
                title={option.title}
              >
                <input
                  className="p-1 rounded  focus:outline-none"
                  id={option.title + i}
                  name={name}
                  checked={(value || []).includes(option.value)}
                  required={required || false}
                  type="checkbox"
                  onChange={(e) => {
                    console.log(e.target.checked, option.value);

                    if (e.target.checked) {
                      const t: { [key: string]: any } = {};
                      t[name] = [...(value || []), option.value];
                      if (onChange) onChange(t);
                    }
                  }}
                  form={form}
                ></input>
                <label className="font-medium" htmlFor={option.title + i}>
                  {option.title}
                </label>
              </div>
            ))}
          </div>
        </div>
      );

    case InputType.switch:
      return (
        <div key={key} className="flex   items-center gap-5 text-sm  w-full">
          <label className="font-semibold" htmlFor={id}>
            {label}
          </label>
          <ToggleBtn
            name={name}
            value={value}
            onChange={function (v: boolean): void {
              const t: { [key: string]: any } = {};
              t[name] = v;
              if (onChange) onChange(t);
            }}
          />
        </div>
      );
    case InputType.date:
      return (
        <div key={key} className="flex flex-col items-start text-sm  w-full">
          <label className="font-semibold" htmlFor={id}>
            {label}
          </label>
          <input
            className="p-1 rounded  focus:outline-none "
            id={id}
            name={name}
            value={value ? moment(value).format("YYYY-MM-DD") : ""}
            title={title}
            required={required || false}
            type="date"
            onChange={(e) => {
              const t: { [key: string]: any } = {};
              t[name] = UTCToLocalDate(e.target.value)!;
              if (onChange) onChange(t);
            }}
            form={form}
          ></input>
        </div>
      );
    case InputType.dateTime:
      return (
        <div key={key} className="flex flex-col items-start text-sm">
          <label className="font-semibold" htmlFor={id}>
            {label}
          </label>
          <input
            className="p-1 rounded  focus:outline-none"
            id={id}
            name={name}
            value={value ? moment(value).format("YYYY-MM-DDTHH:mm:ss") : ""}
            title={title}
            required={required || false}
            type="datetime-local"
            onChange={(e) => {
              const t: { [key: string]: any } = {};
              t[name] = new Date(e.target.value);
              if (onChange) onChange(t);
            }}
            form={form}
          ></input>
        </div>
      );

    default:
      return <></>;
  }
};
