import dayjs from "dayjs";
import { useHistory } from "react-router-dom";

import TextTruncate from "react-text-truncate";
import relativeTime from "dayjs/plugin/relativeTime";

import "./index.css";
import { URLS } from "../../../infrastructure/utils/urls";

import { useAppSelector } from "../../../store/hooks";
import { useEffect, useState } from "react";
import Icon from "@mdi/react";
import {
  mdiArrowDownDropCircleOutline,
  mdiArrowUpDropCircleOutline,
  mdiOpenInNew,
} from "@mdi/js";
import ToggleBtn from "../../../containers/BlogListings/components/toggle_btn";
import { BlogModel } from "../../../containers/BlogEditor";
import { useAuth } from "../../../../../../context_providers/auth/authProvider";
import { urls } from "../../../../../../utils/urls";
import { blogCountry } from "../blogDetails";

dayjs.extend(relativeTime);

const getBlogUrl = (blog: BlogModel) => {
  switch (blog.language.toLowerCase().trim()) {
    case "English".toLowerCase():
      if (blog.country === blogCountry.usa) {
        return "https://kazamcharge.com/blogs/" + blog.slug;
      }
      return (
        "https://kazam.in/category/" +
        blog.categories[0].category_slug +
        "/" +
        blog.slug
      );
    case "Kannada".toLowerCase():
      return (
        "https://kannada.kazam.in/category/" +
        blog.categories[0].category_slug +
        "/" +
        blog.slug
      );
    case "Hindi".toLowerCase():
      return (
        "https://hindi.kazam.in/category/" +
        blog.categories[0].category_slug +
        "/" +
        blog.slug
      );
    case "Kannada".toLowerCase():
      return (
        "https://marathi.kazam.in/category/" +
        blog.categories[0].category_slug +
        "/" +
        blog.slug
      );

    default:
      return (
        "https://kazam.in/category/" +
        blog.categories[0].category_slug +
        "/" +
        blog.slug
      );
  }
};

export default function Index(props: any) {
  const { user, isAuthorised } = useAuth();
  const { post, onDelete, toggleVisibility } = props;
  // const [post, setPost] = useState(props.post);
  const history = useHistory();

  const handleClick = () => {
    history.push({
      pathname: urls.blogUpdate + "/" + post.slug,
    });
  };
  // useEffect(() => {
  //   setPost(props.post);
  // }, [props.post]);
  return (
    <div className="w-full h-auto shadow rounded">
      <img
        style={{ cursor: "pointer" }}
        className="w-full h-48"
        src={post.feature_image}
        alt="feature"
        onClick={handleClick}
      />
      <div className="p-2">
        <h5
          className="cursor-pointer text-xs font-semibold h-12 w-full  overflow-hidden"
          onClick={handleClick}
        >
          {post.title}
        </h5>
        {/* <p className="text-sm font-semibold h-14 w-full truncate">
          {post.description}
        </p> */}
        {/* <TextTruncate
          line={2}
          element="span"
          truncateText="…"
          text={post.description}
          textTruncateChild={<p>Read on</p>}
        /> */}
        {/* <hr className="my-2" />{" "} */}
        <div className="flex justify-between my-2 items-center">
          <div className="flex gap-2">
            {/* <ToggleBtn
              value={post.active}
              onChange={(v) => {
                console.log({ v, id: post.id });

                toggleVisibility({ id: post.id, active: v });
              }}
            /> */}
            <div className="flex rounded-3xl bg-gray-200 p-1 gap-1 text-xs items-center">
              <div
                className={`transform duration-100 rounded-3xl px-2 py-1 cursor-pointer ${
                  post.active ? "bg-teal text-white" : ""
                } `}
                onClick={() => {
                  if (!post.active)
                    toggleVisibility({ id: post.id, active: true });
                }}
              >
                Online
              </div>
              <div
                className={`transform duration-100 rounded-3xl px-3 py-1  cursor-pointer ${
                  !post.active ? "bg-teal text-white" : ""
                } `}
                onClick={() => {
                  if (post.active)
                    toggleVisibility({ id: post.id, active: false });
                }}
              >
                Offline
              </div>
            </div>
          </div>
          {post.active && (
            <a
              className="flex gap-1 items-center cursor-pointer"
              href={getBlogUrl(post)}
              target="_blank"
            >
              view <Icon path={mdiOpenInNew} className="h-4 w-4"></Icon>
            </a>
          )}
          {/* <button
            className={`rounded  mx-1 px-3 py-1 text-white ${
              post.active
                ? "bg-red-400 hover:bg-red-800"
                : "bg-green-400 hover:bg-green-800"
            } `}
            onClick={() => toggleVisibility(post)}
          >
            {post.active ? "Hide" : "Show"}
          </button> */}
        </div>
        <div className="flex justify-end">
          {props.showDelete && (
            <button
              className="rounded border  px-3 py-1 border-red-50 text-red-500 hover:bg-red-700  hover:text-white"
              onClick={() => onDelete(post)}
            >
              Delete
            </button>
          )}
        </div>
        <hr className="my-1" />
        {/* <div className="flex justify-end">
          <div
            className="p-2 cursor-pointer"
            onClick={() => setShowDetail(!showDetail)}
          >
            <Icon
              className="h-5 w-5"
              path={
                showDetail
                  ? mdiArrowUpDropCircleOutline
                  : mdiArrowDownDropCircleOutline
              }
            ></Icon>
          </div>
        </div> */}

        <div className="flex flex-col  items-start">
          <p className=" text-sm font-bold">
            Author:
            {post.authorProfile?.name ?? "old author ( " + post.author + " )"}
          </p>
          <p className="flex items-end  justify-between w-full text-xs">
            <div className="">Created by </div>
            <div className="flex flex-col items-end">
              {post.created_by_name}
              <small className="text-muted">
                {" "}
                {dayjs(post.created_at).format("DD MMM YYYY hh:mm a")}
              </small>
            </div>
          </p>
          <p className="flex items-end justify-between w-full text-xs mt-1">
            Updated by{" "}
            <div className="flex flex-col items-end">
              {post.updated_by_name}
              <small className="text-muted">
                {" "}
                {dayjs(post.updated_at).format("DD MMM YYYY hh:mm a")}
              </small>
            </div>
          </p>
        </div>
      </div>
    </div>
  );
}
