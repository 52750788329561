import { FunctionComponent } from "react";
import { Globe, Slash } from "react-feather";
import { NavLink } from "react-router-dom";
import { urls } from "../../../../../utils/urls";
import Card from "../Card";

interface ProtocolsCategoryProps {}

const ProtocolsCategory: FunctionComponent<ProtocolsCategoryProps> = () => {
  const links = [
    {
      name: "Installations",
      link: urls.installation_protocol,
      logo: "/academy/installations.png",
    },
    {
      name: "Saas",
      link: urls.saas_protocol,
      logo: "/academy/cms.png",
    },
    {
      name: "Complaint",
      link: urls.complaint_protocol,
      logo: "/academy/complaint.png",
    },
    {
      name: "Hub creation",
      link: urls.hub_creation_checklist,
      logo: "/academy/hub.png",
    },
  ];
  return (
    <div className="flex flex-col items-center  pt-20">
      <div className="w-11/12 lg:w-4/5">
        <div className="text-xl lg:text-2xl font-semibold">
          Important protocols to follow
        </div>
        <div className="grid grid-cols-2 lg:grid-cols-4  gap-4 lg:gap-12 mt-8">
          {links.map(
            (el: { name: string; link: string; logo: string }, i: number) => {
              return <Card data={el} />;
            }
          )}
        </div>
        <NavLink to={urls.protocol_category}>
          <div className="flex flex-col items-end underline mt-3 lg:text-lg">
            View more &gt; &gt;
          </div>
        </NavLink>
      </div>
      <div className=" flex flex-col lg:flex-row justify-between items-center w-4/5 pt-20">
        <div className=" text-2xl lg:text-3xl font-semibold">
          Video Sessions <br /> by the managers{" "}
        </div>
        <div className="h-12 lg:h-32 w-px bg-primaryColor"></div>
        <div className="">
          <div className="">
            <Slash className="text-red-700" size={32} />
          </div>
          <div className=" lg:text-xl mt-4">
            Stuck in any topic? <br /> Come and check out here
          </div>
        </div>
        <div className="h-12 lg:h-32 w-px bg-primaryColor"></div>
        <div className="">
          <div className="">
            <Globe className="text-teal" size={32} />
          </div>
          <div className=" lg:text-xl mt-4">
            Help kazam grow globally <br />
            Your inputs are worthy
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProtocolsCategory;
