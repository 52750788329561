import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useTaskSession } from "../../../../context_providers/task_session_ctx";
import {
  ToastType,
  useToast,
} from "../../../../context_providers/toast/toast_ctx";
import { OpsTaskModel } from "../../../../service/models/ops/ops_ticket";
import { GetMyOpsTicketsApi } from "../../../../service/repos/ops/ops_tickets_repo";
import moment from "moment";
import Icon from "@mdi/react";
import {
  mdiBriefcase,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarkedCircleOutline,
  mdiCheckboxMarkedOutline,
  mdiClose,
  mdiFilter,
  mdiHome,
  mdiNavigation,
  mdiNavigationVariantOutline,
} from "@mdi/js";
import { Link, useRouteMatch } from "react-router-dom";
import { urls } from "../../../../utils/urls";
import { Ops_task_status } from "../../../../utils/enums";
import StatusWidget from "./engineer_detail/components/status_widget";
import BottomNavBar from "./components/bottom_nav_bar";
import { fetchGet, toQueryString } from "../../../../service/api_client";
import EngineerTaskFilterWidget from "./components/filter_widget";
import { debounce } from "lodash";
import EngineerTaskSortWidget from "./components/sort_widget";
import { getDistance } from "../../../../utils/distance_util";
import LoadingWidget from "../../../components/loading_spinner";

interface EngineerTaskListProps {}

const EngineerTaskList: FunctionComponent<EngineerTaskListProps> = () => {
  const { showToast } = useToast();
  const { onDuty, position } = useTaskSession();
  const [data, setData] = useState<OpsTaskModel[]>([]);
  const [sortedTickets, setSortedTickets] = useState<OpsTaskModel[]>([]);
  const [sortType, setSortType] = useState("Today");
  const [loading, setLoading] = useState(false);
  const [showLogs, setShowLogs] = useState<OpsTaskModel>();
  const [showComments, setShowComments] = useState<OpsTaskModel>();
  const { path, url } = useRouteMatch();
  const [metadata, setMetadata] = useState<{
    total: number;
    count: number;
    page: number;
  }>({ total: 0, page: 1, count: 10 });
  const [filter, setFilter] = useState<{
    fromDate?: string;
    toDate?: string;
    status?: string[];
    type?: string[];
    active: boolean;
    search?: string;

    sort_by?: string | undefined;
    sort_order?: string | undefined;
  }>({
    active: true,
    status: [Ops_task_status.pending, Ops_task_status.under_progress],
  });
  const getTickets = async (options: {
    fromDate?: string;
    toDate?: string;
    status?: string[];
    type?: string[];
    active: boolean;
    search?: string;
    page: number;
    count: number;
    sort_by?: string | undefined;
    sort_order?: string | undefined;
  }) => {
    console.log({ options });

    setLoading(true);
    const url = `${
      process.env.REACT_APP_BACKEND_BASE_URL
    }/api/v3/new-ops/task/my-tasks${toQueryString({
      filter: JSON.stringify({ ...options }),
    })}`;
    const res = await fetchGet(url);
    if (res.success) {
      setData((o) =>
        options.page == 1 ? res.data.data : [...o, ...res.data.data]
      );
      setMetadata((o) => ({ ...o, total: res.data.metadata.total }));
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  const debouncedHandler = useCallback(
    debounce(
      (prop: {
        fromDate?: string;
        toDate?: string;
        status?: string[];
        type?: string[];
        active: boolean;
        search?: string;
        page: number;
        count: number;
        sort_by?: string | undefined;
        sort_order?: string | undefined;
      }) => {
        if (prop) getTickets(prop);
        else setData([]);
      },
      100
    ),
    []
  );
  useEffect(() => {
    debouncedHandler({ ...filter, ...metadata });
  }, [metadata.page]);
  useEffect(() => {
    debouncedHandler({ ...filter, ...metadata, page: 1 });
  }, [filter]);
  //   useEffect(() => {
  //     sortTickets();
  //   }, [tickets, sortType]);
  //   const sortTickets = () => {
  //     const now = new Date();
  //     setSortedTickets(
  //       tickets.filter((d) => {
  //         const t_date = new Date(d.last_date || new Date()).getTime();
  //         const tom_date = new Date(
  //           now.getFullYear(),
  //           now.getMonth(),
  //           now.getDate() + 1
  //         ).getTime();
  //         const lat_date = new Date(
  //           now.getFullYear(),
  //           now.getMonth(),
  //           now.getDate() + 2
  //         ).getTime();
  //         switch (sortType) {
  //           case "Today":
  //             if (t_date < tom_date) {
  //               return true;
  //             }
  //             break;
  //           case "Tomorrow":
  //             if (t_date >= tom_date && t_date < lat_date) {
  //               return true;
  //             }
  //             break;
  //           case "Later":
  //             if (t_date >= lat_date) {
  //               return true;
  //             }
  //             break;

  //           default:
  //             return false;
  //         }
  //       })
  //     );
  //   };
  const onScroll = (e: any) => {
    if (e?.target) {
      const { scrollTop, scrollHeight, clientHeight } = e.target;

      if (scrollTop + clientHeight >= scrollHeight - 10 && !loading) {
        console.log(metadata.total / metadata.count, metadata.page);

        if (metadata.total / metadata.count > metadata.page) {
          // onPageChange(metaData.page + 1, searchValue);
          setMetadata((o) => ({ ...o, page: o.page + 1 }));
        }
      }
    }
  };
  const [showFilter, setShowFilter] = useState(false);
  const [showSort, setShowSort] = useState(false);
  return (
    <>
      {" "}
      <div className="  ">
        <div className="grid grid-cols-2 gap-5 p-2 bg-white">
          <button
            onClick={() => setShowFilter(true)}
            className="bg-blue-50 border border-blue-400 text-blue-500 rounded-lg px-3 py-1"
          >
            Filter
          </button>
          <button
            onClick={() => setShowSort(true)}
            className="bg-yellow-50 border border-yellow-400 text-yellow-500 rounded-lg px-3 py-1"
          >
            Sort
          </button>
        </div>
        {showFilter && (
          <EngineerTaskFilterWidget
            status={filter.status || []}
            onClose={function (): void {
              setShowFilter(false);
            }}
            onSubmit={function (status: string[]): void {
              setFilter((f) => ({ ...f, status }));
              setShowFilter(false);
            }}
          />
        )}
        {showSort && (
          <EngineerTaskSortWidget
            data={{
              sort_by: filter.sort_by,
              sort_order: filter.sort_order,
            }}
            onClose={function (): void {
              setShowSort(false);
            }}
            onSubmit={function (sort_data: {
              sort_by?: string | undefined;
              sort_order?: string | undefined;
            }): void {
              setFilter((f) => ({ ...f, ...sort_data }));
              setShowSort(false);
            }}
          />
        )}
        <div
          className="  overflow-auto "
          style={{ height: "85vh" }}
          onScroll={onScroll}
        >
          {data.map((task, i) => (
            <Link
              to={`${url}/detail/${task.id}`}
              className="border-b  p-3 bg-white text-sm flex flex-col gap-1"
            >
              <div className="flex justify-between">
                <p className="font-semibold">#{task.id}</p>

                <div className="">
                  <StatusWidget status={task.status} />
                </div>
              </div>
              <p
                className="text-sm uppercase  font-semibold "
                style={{ color: "#a88132" }}
              >
                {task.type}
              </p>
              <div className="flex gap-5 items-end justify-between">
                <div className="flex flex-col justify-between">
                  <p className="text-sm text-myDdarkBgcolor font-semibold ">
                    {task.cust_name}
                  </p>
                  <p className="text-xs text-myDdarkBgcolor font-semibold ">
                    {task.cust_mobile}
                  </p>
                  <div className="text-xs text-gray-500">
                    {task.address?.full_address}
                  </div>
                  <span className="whitespace-nowrap text-xs mb-1">
                    Distance from start point{" "}
                    {task?.distance?.toFixed(2) ?? "--"} KM
                  </span>
                </div>
                <div className="">
                  <span className="whitespace-nowrap text-xs">
                    {task?.address?.location?.coordinates[1] &&
                    task?.address?.location?.coordinates[0]
                      ? getDistance({
                          lat1: position?.coords.latitude ?? 0,
                          lon1: position?.coords.longitude ?? 0,
                          lat2: task?.address?.location?.coordinates[1] ?? 0,
                          lon2: task?.address?.location?.coordinates[0] ?? 0,
                          unit: "K",
                        }).toFixed(2)
                      : "--"}{" "}
                    KM
                  </span>
                  {/* <a
                  href={`https://maps.google.com/?q=${task.address.location?.coordinates[1]},${task.address.location?.coordinates[0]}&z=18`}
                  className="rounded shadow p-1"
                >
                  <Icon path={mdiNavigationVariantOutline} size={0.8} />
                </a> */}
                </div>
              </div>
              {task?.status == Ops_task_status.completed ? (
                <p className="text-xs text-green-500 ">
                  Completed On{" "}
                  {moment(task?.end_date).utcOffset(330).format("DD MMM YYYY")}
                </p>
              ) : (
                <div className="">
                  {new Date(task.last_date!).getTime() > Date.now() ? (
                    <p className="text-xs text-yellow-500 ">
                      Due date{" "}
                      {moment(task.last_date)
                        .utcOffset(330)
                        .format("DD MMM YYYY")}
                    </p>
                  ) : (
                    <p className="text-xs text-red-500 ">
                      Over due on{" "}
                      {moment(task.last_date)
                        .utcOffset(330)
                        .format("DD MMM YYYY")}
                    </p>
                  )}
                </div>
              )}
            </Link>
          ))}
          <div className="h-20"></div>
        </div>

        <div className="fixed bottom-0 right-0 left-0 bg-white  text-sm">
          <div className="grid grid-cols-2 ">
            <div className="flex flex-col  items-center justify-center p-2 text-teal">
              <Icon path={mdiHome} size={1}></Icon>
              Home
            </div>
            <Link
              to={`${url}/checkin`}
              className="flex flex-col  items-center justify-center p-2"
            >
              <Icon path={mdiBriefcase} size={1}></Icon>
              {onDuty ? "Stop Duty" : "  Start Duty"}
            </Link>
          </div>
        </div>
      </div>
      {loading && <LoadingWidget loading={true} />}
    </>
  );
};

export default EngineerTaskList;
