import React, { FunctionComponent, useEffect, useRef, useState } from "react";

import { NavLink, Redirect, useLocation } from "react-router-dom";

import { mdiArrowCollapseLeft, mdiMenu } from "@mdi/js";
import Icon from "@mdi/react";
import Tooltip from "@material-ui/core/Tooltip";
import { SideBarBtn, urls } from "../../../utils/urls";
import { LogOut } from "react-feather";
import { useGoogleLogout } from "react-google-login";
import { useAuth } from "../../../context_providers/auth/authProvider";
import SubmenuWidget from "./sub_menu";

interface SideBarProps {
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  url_btns?: SideBarBtn[];
}
const Sidebar: FunctionComponent<SideBarProps> = ({
  sidebarOpen,
  setSidebarOpen,
  url_btns,
}) => {
  const { isAuthorised, user, logout } = useAuth();
  const location = useLocation();
  const { pathname } = location;
  // const page = pathname.split("/")[1];

  const trigger = useRef<any>();
  const sidebar = useRef<any>();
  const [collapsed, setCollapsed] = useState(false);

  // const { signOut, loaded } = useGoogleLogout({
  //   clientId:
  //     "225306128297-9b0pj2cq68voiqk87qpod3fpuevghoje.apps.googleusercontent.com",
  // });

  // close on click outside
  useEffect(() => {
    const clickHandler = (e: any) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current?.contains(e.target) ||
        trigger.current?.contains(e.target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = (e: any) => {
      if (!sidebarOpen || e.keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });
  const [menus, setMenus] = useState<SideBarBtn[]>([]);
  useEffect(() => {
    const m: SideBarBtn[] = [];
    if (url_btns && url_btns.length > 0) {
      url_btns.forEach((url, i) => {
        if (
          isAuthorised({
            module: url.module!,
            action: url.action,
            operator: url.operator,
          })
        ) {
          m.push(url);
        }
      });
      setMenus(m);
    }
  }, []);

  const getMenus = (menus: SideBarBtn[]) => {
    return menus.map((url, i) =>
      url.menu ? (
        <SubmenuWidget
          collapsed={collapsed}
          button={
            <SideBarLink
              key={Math.random() * 100000}
              url={url.url}
              collapsed={collapsed}
              label={url.lable}
              icon={url.Icon}
              tag={url.tag}
            />
          }
        >
          {getMenus(url.menu)}
        </SubmenuWidget>
      ) : (
        <SideBarLink
          key={Math.random() * 100000}
          url={url.url}
          collapsed={collapsed}
          label={url.lable}
          icon={url.Icon}
          tag={url.tag}
        />
      )
    );
  };

  return (
    <div className="flex flex-col items-center  ">
      {/* Sidebar backdrop (mobile only) */}
      <div
        // style={{ zIndex: 999 }}
        className={`fixed inset-0 bg-gray-900 bg-opacity-30 z-50 lg:hidden lg:z-auto transition-opacity duration-200 ${
          sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        aria-hidden="true"
      ></div>

      {/* Sidebar */}
      <div
        // style={{ zIndex: 999 }}
        id="sidebar"
        ref={sidebar}
        className={`  ${
          collapsed ? "lg:p-1" : "lg:p-4"
        }  absolute z-50 lg:z-auto left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen   lg:overflow-y-auto no-scrollbar transition-all duration-300 w-auto flex-shrink-0 bg-gray-800 ${
          collapsed ? "p-1" : "p-4"
        } transition-transform duration-200 ease-in-out ${
          sidebarOpen ? "translate-x-0" : "-translate-x-64"
        }`}
      >
        {" "}
        {/* Logo */}
        {/* Sidebar header */}
        <div
          className={`flex ${
            collapsed ? "justify-center " : "justify-between"
          } item-center   `}
        >
          {/* Close button */}
          <NavLink
            exact
            to={urls.root}
            className={`text-sm font-medium ${collapsed ? "hidden" : "block"}`}
          >
            <div className=" w-auto  ">
              <img src="/assets/logo.png" alt="kazam" className="h-10" />
            </div>
          </NavLink>{" "}
          <button
            ref={trigger}
            className="lg:hidden text-white hover:text-red-400  mx-2"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <Tooltip title={`close side menu`}>
              <Icon path={mdiArrowCollapseLeft} className="w-4 h-4" />
            </Tooltip>
          </button>{" "}
          <button
            className="hidden lg:block text-white hover:text-red-400   mx-2"
            onClick={() => setCollapsed(!collapsed)}
            // aria-controls="sidebar"
            // aria-expanded={collapsed}
          >
            {collapsed ? (
              <Icon path={mdiMenu} className="w-6 h-6" />
            ) : (
              <Tooltip title={`close side menu`}>
                <Icon path={mdiArrowCollapseLeft} className="w-4 h-4" />
              </Tooltip>
            )}
          </button>
        </div>
        {/* Links */}
        <div
          className="flex flex-col justify-between"
          style={{ height: "92%" }}
        >
          <div className="flex flex-col justify-center  border-white pt-6 ">
            {getMenus(menus)}
            {/* {menus.map((url) =>
              url.url ? (
                <SideBarLink
                  key={url.url}
                  url={url.url}
                  collapsed={collapsed}
                  label={url.lable}
                  icon={url.Icon}
                  tag={url.tag}
                />
              ) : (
                <SubmenuWidget
                  collapsed={collapsed}
                  button={
                    <SideBarLink
                      key={url.lable}
                      url={url.url}
                      collapsed={collapsed}
                      label={url.lable}
                      icon={url.Icon}
                      tag={url.tag}
                    />
                  }
                >
                  {url.menu?.map(sub_url)}
                </SubmenuWidget>
              )
            )} */}
          </div>

          {/* <UserMenu collapsed={collapsed} /> */}
          <div className="">
            <div className={`${collapsed ? "hidden" : "block"} pt-8 `}>
              {user?.profile_image ? (
                <div className="rounded-lg h-16 w-16">
                  <img
                    src={user?.profile_image}
                    alt=""
                    className="w-full h-full rounded-lg"
                  />
                </div>
              ) : (
                <div className="rounded-lg h-16 w-16 flex flex-col items-center justify-center">
                  <div className=""></div>
                </div>
              )}
              <div className="text-white mt-3 text-sm">
                {user?.first_name + " " + user?.last_name}
              </div>
              <div className="text-gray-400 text-xs overflow-ellipsis overflow-hidden w-36 whitespace-nowrap">
                {user?.emp_profile?.designation}
              </div>
            </div>

            <div
              className="flex items-center gap-3 pt-8 text-white cursor-pointer"
              onClick={logout}
            >
              <div className={` ${collapsed ? "hidden" : "block"} text-sm`}>
                Logout
              </div>
              <LogOut className="" size={16} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
interface SideBarLinkProps {
  collapsed: boolean;
  url?: string;
  label: string;
  icon: any;
  tag?: any;
  key?: any;
}

const SideBarLink: FunctionComponent<SideBarLinkProps> = ({
  collapsed,
  url,
  label,
  icon,
  tag,
  key,
}) => {
  return url ? (
    <NavLink
      title={label}
      key={key}
      exact
      to={url}
      className={`relative  py-2 ${
        collapsed ? "pr-2" : "pr-12"
      } pl-2  rounded mb-0.5 transition-all duration-100 last:mb-0 block  border-l-2 border-transparent text-white   hover:bg-gray-900  `}
      activeClassName="border-red-900 bg-gray-900"
    >
      <div
        className={`flex items-end ${
          collapsed ? "justify-start" : ""
        }  flex-grow items-center`}
      >
        {icon ? icon : collapsed && label[0]?.toUpperCase()}

        <span className={`text-xs  ${collapsed ? "hidden" : "block"}`}>
          {label} {tag && <span className="absolute -top-1"> {tag}</span>}
        </span>
      </div>
    </NavLink>
  ) : (
    <li
      title={label}
      key={key}
      className={`block text-gray-200 hover:text-white  p-2 rounded mb-0.5 transition-all duration-200 last:mb-0 cursor-pointer   hover:bg-gray-900  `}
    >
      <div
        className={`flex ${
          collapsed ? "justify-center" : ""
        }  flex-grow items-center`}
      >
        {icon ? icon : collapsed && label[0]?.toUpperCase()}

        <span className={`text-xs  ${collapsed ? "hidden" : "block"}`}>
          {label}
          {tag && <span className="absolute -top-1"> {tag}</span>}
        </span>
      </div>
    </li>
  );
};
