import { FunctionComponent, useState } from "react";
import { Check, Edit, X } from "react-feather";
import {
  LeaveAvailableModel,
  LeaveTakenModel,
} from "../../../../../service/models/user_model";
import { UpdateLeaveData } from "../../../../../service/repos/hrm/hrm_repo";
import { GetEmpDetails } from "../../../../../service/repos/users_repo";
import HrmLoader from "../../components/loading";
import "../../styles/index.css";

interface LeaveTableProps {
  headings: { subHeading: boolean; name: string }[];
  data: {
    uid: string;
    name: string;
    leave_available: LeaveAvailableModel;
    leave_taken: LeaveTakenModel;
    total_taken: {
      _id: string;
      carry_forward: number;
      paid_leaves: number;
      medical_leaves: number;
      restricted_leaves: number;
      work_from_home: number;
      unpaid_leaves: number;
    };
  }[];
  showRefresh: () => void;
}

const LeaveTable: FunctionComponent<LeaveTableProps> = ({
  headings,
  data,
  showRefresh,
}) => {
  return (
    <div className="leave-table-data overflow-scroll relative pb-4">
      <table className="">
        <thead className="sticky top-0 z-20 border ">
          <tr>
            {headings.map((el, i) => {
              if (el.subHeading) {
                if (
                  el.name !== "Work from home" &&
                  el.name !== "Unpaid leaves"
                ) {
                  return (
                    <th className=" border border-gray-300">
                      <div className="text-gray-600 bg-gray-100 py-2   text-sm  font-semibold z-10 border-b border-gray-400">
                        {el.name}
                      </div>
                      <div className="grid grid-cols-4 text-xs bg-gray-100 py-1">
                        <div className="">Total earned</div>
                        <div className="">Available now</div>
                        <div className="">Total taken</div>
                        <div className="">Taken this month</div>
                      </div>
                    </th>
                  );
                } else {
                  return (
                    <th className=" border border-gray-300">
                      <div className="text-gray-600 bg-gray-100 py-2   text-sm  font-semibold z-10 border-b border-gray-400">
                        {el.name}
                      </div>
                      <div className="grid grid-cols-2 text-xs bg-gray-100 py-1">
                        <div className="">Total taken</div>
                        <div className="">Taken this month</div>
                      </div>
                    </th>
                  );
                }
              } else {
                return (
                  <th
                    className={` border border-gray-300 text-gray-600 bg-gray-100  text-sm  font-semibold z-10`}
                  >
                    {el.name}
                  </th>
                );
              }
            })}
          </tr>
        </thead>
        <tbody className="sticky top-0">
          {data.map((el, i) => {
            return <TableRow showRefresh={showRefresh} data={el} key={i} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default LeaveTable;

interface TableRowProps {
  data: {
    uid: string;
    name: string;
    leave_available: LeaveAvailableModel;
    leave_taken: LeaveTakenModel;
    total_taken: {
      _id: string;
      carry_forward: number;
      paid_leaves: number;
      medical_leaves: number;
      restricted_leaves: number;
      work_from_home: number;
      unpaid_leaves: number;
    };
  };
  showRefresh: () => void;
}

const TableRow: FunctionComponent<TableRowProps> = ({ data, showRefresh }) => {
  const [defaultData, setDefaultData] = useState({ ...data });
  const [disabled, setDisabled] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const updateData = async (tempUpdate: {
    uid: string;
    name: string;
    leave_taken: LeaveTakenModel;
  }) => {
    console.log(tempUpdate);

    setLoading(true);
    delete tempUpdate.leave_taken._id;
    const res = await UpdateLeaveData(tempUpdate);
    console.log(res);
    if (res.success) {
      setDisabled(true);
      setLoading(false);
    }
    showRefresh();
  };

  // GetEmpDetails

  return (
    <tr className="">
      <TableData data={data.name} />
      {/* <TableData data={getEmployeeID(data.uid)} /> */}

      <TableData
        data={
          <div className="grid grid-cols-4">
            <div className="">
              {data?.leave_available?.paid_leaves +
                data?.total_taken?.paid_leaves}
            </div>
            <div className="">{data?.leave_available.paid_leaves}</div>
            <div className="">{data?.total_taken?.paid_leaves}</div>
            <div className="">
              <input
                disabled={disabled}
                type="number"
                step=".25"
                className={`${
                  disabled ? "" : "border border-gray-600"
                } bg-transparent w-12 pl-1`}
                defaultValue={defaultData.leave_taken.paid_leaves}
                onChange={(e) =>
                  setDefaultData({
                    ...defaultData,
                    leave_taken: {
                      ...defaultData.leave_taken,
                      paid_leaves: Number(e.target.value),
                    },
                  })
                }
              />
            </div>
          </div>
        }
      />
      <TableData
        data={
          <div className="grid grid-cols-4">
            <div className="">
              {data?.leave_available?.medical_leaves +
                data?.total_taken?.medical_leaves}
            </div>
            <div className="">{data?.leave_available.medical_leaves}</div>
            <div className="">{data?.total_taken?.medical_leaves}</div>
            <div className="">
              <input
                disabled={disabled}
                type="number"
                step=".25"
                className={`${
                  disabled ? "" : "border border-gray-600"
                } bg-transparent w-12 pl-1`}
                defaultValue={defaultData.leave_taken.medical_leaves}
                onChange={(e) =>
                  setDefaultData({
                    ...defaultData,
                    leave_taken: {
                      ...defaultData.leave_taken,
                      medical_leaves: Number(e.target.value),
                    },
                  })
                }
              />
            </div>
          </div>
        }
      />
      <TableData
        data={
          <div className="grid grid-cols-2">
            <div className="">{data?.total_taken?.work_from_home}</div>
            <div className="">
              <input
                disabled={disabled}
                type="number"
                step=".25"
                className={`${
                  disabled ? "" : "border border-gray-600"
                } bg-transparent w-12 pl-1`}
                defaultValue={defaultData.leave_taken.work_from_home}
                onChange={(e) =>
                  setDefaultData({
                    ...defaultData,
                    leave_taken: {
                      ...defaultData.leave_taken,
                      work_from_home: Number(e.target.value),
                    },
                  })
                }
              />
            </div>
          </div>
        }
      />
      <TableData
        data={
          <div className="grid grid-cols-2">
            <div className="">{data?.total_taken?.unpaid_leaves}</div>
            <div className="">
              <input
                disabled={disabled}
                type="number"
                step=".25"
                className={`${
                  disabled ? "" : "border border-gray-600"
                } bg-transparent w-12 pl-1`}
                defaultValue={defaultData.leave_taken.unpaid_leaves}
                onChange={(e) =>
                  setDefaultData({
                    ...defaultData,
                    leave_taken: {
                      ...defaultData.leave_taken,
                      unpaid_leaves: Number(e.target.value),
                    },
                  })
                }
              />
            </div>
          </div>
        }
      />
      <td className="bg-white">
        <div className="flex flex-col items-center ">
          {disabled ? (
            <div>
              <div className="">
                <Edit
                  size={16}
                  className="text-gray-500 cursor-pointer"
                  onClick={() => setDisabled(false)}
                />
              </div>
            </div>
          ) : loading ? (
            <HrmLoader className="w-4 h-4" />
          ) : (
            <div className="flex gap-3 px-2">
              <div className="flex w-6 h-6 rounded-full bg-green-100 flex-col items-center justify-center">
                <Check
                  className="text-green-500 cursor-pointer"
                  size={12}
                  onClick={() => updateData(defaultData)}
                />
              </div>
              <div className="flex w-6 h-6 rounded-full bg-red-100 flex-col items-center justify-center">
                <X
                  className="text-red-500 cursor-pointer"
                  size={12}
                  onClick={() => {
                    setDefaultData({ ...data });
                    setDisabled(true);
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </td>
    </tr>
  );
};

interface TableDataProps {
  data: any;
}

const TableData: FunctionComponent<TableDataProps> = ({ data }) => {
  return (
    <td
      className={`text-gray-600 py-4 pl-2 border text-sm  font-semibold bg-white`}
    >
      {data}
    </td>
  );
};
