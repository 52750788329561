import { FunctionComponent } from "react";
import { academyVideoCategory } from "../../../../../utils/enums";
import VideoLayout from "../components/VideosLayout";

interface TallyProps {}

const Tally: FunctionComponent<TallyProps> = () => {
  const segment = academyVideoCategory.Tally;

  return <VideoLayout segment={segment} />;
};

export default Tally;
