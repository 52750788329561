import { RouteParam } from ".";
import BlogEditor from "../ui/pages/content_panel/containers/BlogEditor";
import BlogListings from "../ui/pages/content_panel/containers/BlogListings";
import ContentPanelHome from "../ui/pages/content_panel/containers/Home";
import Installation from "../ui/pages/content_panel/containers/Installation";
import InstallationCreate from "../ui/pages/content_panel/containers/Installation/create";
import MagazineListingPage from "../ui/pages/content_panel/containers/magazine/magazinListing";
import MagazineCreatePage from "../ui/pages/content_panel/containers/magazine/magazin_create";
import UploadImage from "../ui/pages/content_panel/containers/UploadImage";
import VehicleListings from "../ui/pages/content_panel/containers/VehicleListings";
import AuthorCreate from "../ui/pages/content_panel/ui/pages/authors/create";
import AuthorListingPage from "../ui/pages/content_panel/ui/pages/authors/listing";
import VehicleListing from "../ui/pages/content_panel/ui/pages/vehicles/listing";
import GalleryList from "../ui/pages/content_panel/ui/pages/vehicles/listing/components/gallery_list";
import PriceListing from "../ui/pages/content_panel/ui/pages/vehicles/listing/components/price_list";
import VarientListing from "../ui/pages/content_panel/ui/pages/vehicles/listing/components/varient_list";

import { ACTION, MODULE } from "../utils/enums";
import { urls } from "../utils/urls";

const content_panel_routes: RouteParam[] = [
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.content_panel,
    component: ContentPanelHome,
    main_module: MODULE.content_panel,
    module: MODULE.content,
    action: [ACTION.CREATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.blogCreate,
    component: BlogEditor,
    main_module: MODULE.content_panel,
    module: MODULE.content,
    action: [ACTION.CREATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.blogUpdate + "/:slug",
    component: BlogEditor,
    main_module: MODULE.content_panel,
    module: MODULE.content,
    action: [ACTION.CREATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.blogListing,
    component: BlogListings,
    main_module: MODULE.content_panel,
    module: MODULE.content,
    action: [ACTION.CREATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.uploadImage,
    component: UploadImage,
    main_module: MODULE.content_panel,
    module: MODULE.content,
    action: [ACTION.CREATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.installation,
    component: Installation,
    main_module: MODULE.content_panel,
    module: MODULE.content,
    action: [ACTION.CREATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.installationCreate,
    component: InstallationCreate,
    main_module: MODULE.content_panel,
    module: MODULE.content,
    action: [ACTION.CREATE],
    operator: "or",
  },

  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.installationEdit,
    component: InstallationCreate,
    main_module: MODULE.content_panel,
    module: MODULE.content,
    action: [ACTION.CREATE],
    operator: "or",
  },

  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.magazine,
    component: MagazineListingPage,
    main_module: MODULE.content_panel,
    module: MODULE.content,
    action: [ACTION.CREATE],
    operator: "or",
  },

  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.magazineCreate,
    component: MagazineCreatePage,
    main_module: MODULE.content_panel,
    module: MODULE.content,
    action: [ACTION.CREATE],
    operator: "or",
  },

  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.magazineEdit,
    component: MagazineCreatePage,
    main_module: MODULE.content_panel,
    module: MODULE.content,
    action: [ACTION.CREATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.authors,
    component: AuthorListingPage,
    main_module: MODULE.content_panel,
    module: MODULE.content,
    action: [ACTION.CREATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.authorCreate,
    component: AuthorCreate,
    main_module: MODULE.content_panel,
    module: MODULE.content,
    action: [ACTION.CREATE],
    operator: "or",
  },

  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.authorEdit,
    component: AuthorCreate,
    main_module: MODULE.content_panel,
    module: MODULE.content,
    action: [ACTION.CREATE],
    operator: "or",
  },

  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.vehicles,
    component: VehicleListing,
    main_module: MODULE.content_panel,
    module: MODULE.content,
    action: [ACTION.READ, ACTION.UPDATE],
    operator: "and",
  },

  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.varientListing,
    component: VarientListing,
    main_module: MODULE.content_panel,
    module: MODULE.content,
    action: [ACTION.READ, ACTION.UPDATE],
    operator: "and",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.priceListing,
    component: PriceListing,
    main_module: MODULE.content_panel,
    module: MODULE.content,
    action: [ACTION.READ, ACTION.UPDATE],
    operator: "and",
  },

  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.galleryListing,
    component: GalleryList,
    main_module: MODULE.content_panel,
    module: MODULE.content,
    action: [ACTION.READ, ACTION.UPDATE],
    operator: "and",
  },
];

export default content_panel_routes;
