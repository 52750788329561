import { FunctionComponent, useEffect, useState } from "react";
import { Tree, TreeNode } from "react-organizational-chart";

interface OrgTreeTabProps {}

const OrgTreeTab: FunctionComponent<OrgTreeTabProps> = (props: any) => {
  const [orgData, setOrgData] = useState({ ...props.location.state });

  useEffect(() => {
    const orgTree = window.document.getElementById("orgTree");
    orgTree?.scrollTo(7000, 0);
  }, []);
  return (
    <div className="bg-black">
      <div
        className=" overflow-x-scroll flex items-center"
        id="orgTree"
        style={{ transform: "scale(70%)" }}
      >
        <Tree
          lineWidth={"2px"}
          nodePadding={"20px"}
          lineColor={"gray"}
          lineBorderRadius={"20px"}
          lineHeight={"60px"}
          label={
            <div className=" bg-red-700 inline-block py-2 px-6 rounded ">
              <div className="text-xl text-white whitespace-nowrap">
                {orgData.first_name}
              </div>
            </div>
          }
        >
          {orgData?.children?.length ? (
            <>
              {orgData.children.map((child: any) => (
                <OrgNode data={child} />
              ))}
            </>
          ) : (
            ""
          )}
        </Tree>
      </div>
    </div>
  );
};

export default OrgTreeTab;

interface OrgNodeProps {
  data: {
    uid: string;
    reporting_to_id: number;
    first_name: string;
    designation: string;
    children?: {
      uid: string;
      reporting_to_id: number;
      first_name: string;
      designation: string;
    }[];
  };
}

const OrgNode: FunctionComponent<OrgNodeProps> = ({ data }) => {
  return (
    <>
      {data.children?.length ? (
        <TreeNode
          label={
            <div className="py-2 px-5 rounded inline-block border border-primaryColor">
              <div className="text-primaryColor whitespace-nowrap">
                {data.first_name}
              </div>
              <div className="text-secondaryColor whitespace-nowrap">
                {data.designation}
              </div>
            </div>
          }
        >
          <>
            {data.children.map((child) => (
              <OrgNode data={child} />
            ))}
          </>
        </TreeNode>
      ) : (
        <TreeNode
          label={
            <div className="py-2 px-5 rounded inline-block border border-primaryColor">
              <div className="text-primaryColor whitespace-nowrap">
                {data.first_name}
              </div>
              <div className="text-secondaryColor whitespace-nowrap">
                {data.designation}
              </div>
            </div>
          }
        />
      )}
    </>
  );
};
