import { FunctionComponent, useEffect, useState } from "react";
import { WarehouseModel } from "../../../../service/models/inventory/warehouse_model";
import { GetProductsApi } from "../../../../service/repos/products_repo";
import { formToObject } from "../../../../utils/formDataToJson";
import { InputFields } from "../../../components/common/common/constants";
import GenerateForm, {
  FormFieldModel,
} from "../../../components/common/common/form";

interface WarehouseCreateProps {
  onSubmit: (data: WarehouseModel) => void;
  data?: WarehouseModel;
}

const WarehouseCreate: FunctionComponent<WarehouseCreateProps> = (props) => {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        const data = formToObject(
          new FormData(e.currentTarget)
        ) as WarehouseModel;
        if (props.data) {
          data.id = props.data.id;
        }
        props.onSubmit(data);
      }}
      className="p-5"
    >
      <div className="grid grid-cols-1 md:grid-cols-3 text-sm gap-5">
        <div className="flex flex-col">
          <label htmlFor="name">Name</label>
          <input
            defaultValue={props.data?.name}
            className="rounded-md px-2 py-1 border focus:outline-none"
            required
            type="text"
            id="name "
            name="name"
            placeholder="Warehouse name"
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="address">Address</label>
          <input
            defaultValue={props.data?.address}
            type="text"
            name="address"
            id="address"
            className="rounded-md px-2 py-1 border focus:outline-none"
          ></input>
        </div>
        <div className="flex flex-col">
          <label htmlFor="city">City</label>
          <input
            defaultValue={props.data?.city}
            type="text"
            name="city"
            id="city"
            className="rounded-md px-2 py-1 border focus:outline-none"
          ></input>
        </div>
        <div className="flex flex-col">
          <label htmlFor="state">State</label>
          <input
            defaultValue={props.data?.state}
            type="text"
            name="state"
            id="state"
            className="rounded-md px-2 py-1 border focus:outline-none"
          ></input>
        </div>
        <div className="flex flex-col">
          <label htmlFor="pincode">Pincode</label>
          <input
            defaultValue={props.data?.pincode}
            type="text"
            name="pincode"
            id="pincode"
            className="rounded-md px-2 py-1 border focus:outline-none"
          ></input>
        </div>
      </div>
      <div className="flex justify-center gap-2 my-2">
        <button
          className="border rounded-lg px-2 py-1 hover:bg-gray-800 hover:text-white"
          type="submit"
        >
          {props.data ? "Update" : "Create"}
        </button>
      </div>
    </form>
  );
};

export default WarehouseCreate;
