import { FunctionComponent, useEffect, useState } from "react";
import {
  ApprovalModel,
  approval_threshold,
} from "../../model/purchase_order_model";
import { mdiCheck } from "@mdi/js";
import Icon from "@mdi/react";

interface ApprovalProgressBarProps {
  approved_count: number;
  approval_count_required: number;
  approval_level: string;
}

const ApprovalProgressBar: FunctionComponent<ApprovalProgressBarProps> = (
  props
) => {
  const [progress, setprogress] = useState(0);
  useEffect(() => {
    setprogress((props.approved_count / props.approval_count_required) * 100);
  }, []);
  return (
    <div className=" border rounded-full bg-white text-center relative w-full">
      <div
        className={`flex justify-center items-center  text-xs font-medium text-blue-100 text-center h-full p-0.5  transform duration-300  ${
          progress < 99 ? "rounded-l-full" : "rounded-full  "
        } ${
          progress > 0 && progress < 100
            ? "bg-gradient-to-r from-blue-400 via-purple-500 to-teal"
            : progress >= 100
            ? "bg-teal"
            : " "
        }    `}
        style={{
          width: (progress < 5 ? 5 : progress >= 100 ? 100 : progress) + "%",
        }}
      >
        {progress >= 100 ? (
          <div className="flex items-center gap-1">
            <Icon path={mdiCheck} size={0.8} /> <p>Completed</p>
          </div>
        ) : (
          `${progress}%`
        )}
      </div>
      {props.approved_count == 0 && (
        <div className="absolute top-0 bottom-0 right-0 left-0 flex justify-center items-center">
          <p className="  text-ceneter text-xs  ">pending</p>
        </div>
      )}
    </div>
  );
};

export default ApprovalProgressBar;
