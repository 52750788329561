import { FunctionComponent } from "react";

interface StadiumButtonProps {
  onTap: () => void;
  type?: "submit" | "reset" | "button";
}

const StadiumButton: FunctionComponent<StadiumButtonProps> = (props) => {
  return (
    <button
      type={props.type}
      onClick={props.onTap}
      className="flex items-center justify-center cursor-pointer hover:bg-black hover:text-white transition-all duration-300 px-6 py-2 rounded border hover:shadow text-sm font-semibold"
    >
      {props.children}
    </button>
  );
};

export default StadiumButton;
