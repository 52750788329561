import { FunctionComponent } from "react";
import TaskAttachmentWidget from "../../../components/attachment_widget";
import { useOpsTask } from "../../../../../../context_providers/ops_task_engineer_detail/ops_task_engineer_detail";
import CommentWidget from "../../../comment_widget";

interface CommentScreenProps {}

const CommentScreen: FunctionComponent<CommentScreenProps> = () => {
  const { data } = useOpsTask();
  return <CommentWidget task_id={data!.id} active={data!.active} />;
};

export default CommentScreen;
