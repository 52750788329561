import { mdiCheck, mdiClose, mdiDelete, mdiPencil, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import moment from "moment";
import React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import { DiscountDataModel } from "../../../../../service/models/orders/order_discount_model";
import OrderItemModel from "../../../../../service/models/orders/order_item_model";
import ProductModel, {
  OrderItemDataModel,
} from "../../../../../service/models/product_model";
import { TransactionDataModel } from "../../../../../service/models/trannsaction_model";
import { PaymentMode } from "../../../../../utils/enums";
const defaultItem: TransactionDataModel = {
  type: "",
  payment_date: new Date().toISOString() as any,
  payment_mode: "",
  amount: 0,
  status: "",
};
interface PaymentRowWidgetProps {
  payment?: TransactionDataModel;
  onSubmit: (data: TransactionDataModel) => void;
  edit: boolean;
  onDelete?: (i: TransactionDataModel) => void;
  form_id?: string;
}

const PaymentRowWidget: FunctionComponent<PaymentRowWidgetProps> = (props) => {
  const { showToast } = useToast();

  const { edit, onSubmit } = props;
  const [payment, setPayment] = useState<TransactionDataModel>({
    ...(props.payment ?? defaultItem),
  });
  const [editable, setEditable] = useState(false);
  useEffect(() => {
    setEditable(edit);
  }, []);
  const onDone = () => {
    if (
      !payment.payment_mode ||
      payment.amount < 1 ||
      (payment.payment_mode !== PaymentMode.Cash &&
        (!payment.txn_id || !payment.files || payment.files.length == 0))
    ) {
      showToast({
        type: ToastType.warning,
        text: "Please fill  required data",
      });
      return;
    }
    onSubmit(payment);
  };

  return (
    <>
      <form
        id={props.form_id}
        onSubmit={(e) => {
          e.preventDefault();
          onDone();
        }}
        className="bg-white rounded-md shadow p-5 text-sm"
      >
        <div className="grid grid-cols-9 gap-3 items-center">
          <input
            disabled={!editable}
            className="p-1 rounded bg-gray-100 focus:outline-none"
            type="date"
            name=""
            id=""
            value={moment(payment.payment_date).format("YYYY-MM-DD")}
            onChange={(e) => {
              if (e.target.value) {
                const d = new Date(e.target.value);
                setPayment({
                  ...payment,
                  payment_date: d.toISOString() as any,
                });
              }
            }}
          />
          <select
            disabled={!editable}
            name=""
            id=""
            className="p-1 rounded bg-gray-100 focus:outline-none"
            value={payment.payment_mode}
            onChange={(e) => {
              setPayment((o) => ({ ...o, payment_mode: e.target.value }));
            }}
          >
            <option value="" hidden>
              select
            </option>
            {Object.values(PaymentMode).map((mode, i) => (
              <option key={i} value={mode}>
                {mode}
              </option>
            ))}
          </select>

          <input
            value={payment?.txn_id}
            onChange={(e) => {
              setPayment((o) => ({ ...o, txn_id: e.target.value }));
            }}
            disabled={
              !(
                payment.payment_mode &&
                payment.payment_mode !== PaymentMode.Cash &&
                editable
              )
            }
            placeholder=""
            type="text"
            className=" col-span-2  p-1 rounded bg-gray-100 focus:outline-none"
          />
          <input
            value={payment?.amount}
            onChange={(e) => {
              let v = 0;
              if (e.target.value && !isNaN(e.target.value as any)) {
                v = Number(e.target.value);
              }
              setPayment((o) => ({ ...o, amount: v }));
            }}
            disabled={!editable}
            placeholder=""
            type="number"
            className="text-right  p-1 rounded bg-gray-100 focus:outline-none"
          />
          <input
            value={payment?.comment}
            onChange={(e) => {
              setPayment((o) => ({ ...o, comment: e.target.value }));
            }}
            disabled={!editable}
            placeholder=""
            type="text"
            className=" col-span-2  p-1 rounded bg-gray-100 focus:outline-none"
          />
          <div className=" ">
            {payment.files && payment.files.length > 0 ? (
              <div className="h-14 w-14 flex flex-col items-center">
                {payment.files[0].type.startsWith("image") ? (
                  <img src={URL.createObjectURL(payment.files[0])} alt="" />
                ) : (
                  <embed
                    src={URL.createObjectURL(payment.files[0])}
                    type="application/pdf"
                    className="h-full w-full  object-cover"
                  ></embed>
                )}
                <div
                  onClick={() => {
                    if (editable)
                      setPayment((o) => ({
                        ...o,
                        files: [],
                      }));
                  }}
                  className="flex gap-1 cursor-pointer items-center  text-xs "
                >
                  Delete
                </div>
              </div>
            ) : (
              <div>
                <input
                  disabled={!editable}
                  onChange={(e) => {
                    setPayment((o) => ({
                      ...o,
                      files: [...(e.target.files ?? [])],
                    }));
                  }}
                  type="file"
                  accept="image/*,application/pdf"
                />
              </div>
            )}
          </div>
          <div className="flex gap-2 items-center justify-end">
            {props.payment && (
              <>
                <div className="flex justify-center gap-2">
                  {editable ? (
                    <>
                      <div
                        onClick={() => {
                          if (!window.confirm("Are you sure !")) return;
                          onDone();
                          setEditable(false);
                        }}
                        className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                      >
                        <Icon path={mdiCheck} className="h-4 w-4"></Icon>
                      </div>
                      <div
                        onClick={() => {
                          // setExtra(props.payment!);
                          setEditable(false);
                        }}
                        className="bg-red-100 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                      >
                        <Icon path={mdiClose} className="h-4 w-4"></Icon>
                      </div>
                    </>
                  ) : (
                    <div
                      onClick={() => setEditable(true)}
                      className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                    >
                      <Icon path={mdiPencil} className="h-4 w-4"></Icon>
                    </div>
                  )}
                </div>
                <div
                  onClick={() => {
                    if (!window.confirm("Are you sure !")) return;
                    if (props.onDelete) props.onDelete(payment);
                  }}
                  className="bg-red-200 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                >
                  <Icon path={mdiDelete} className="h-4 w-4"></Icon>
                </div>
              </>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default PaymentRowWidget;
