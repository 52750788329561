import { RouteParam } from "../../../routes";
import { ACTION, MODULE } from "../../../utils/enums";
import { urls } from "../../../utils/urls";
import StockTransferJournalListScreen from "../journals/stock_journal/stock_transfer_journal_list";
import StockJournal from "../journals/stock_journal/stock_transfer_journal_create/stock_journal";
import DestinationStockJournal from "../journals/destination_stock_voucher/destination_stock_journal";
import DestinationStockTransferJournalListScreen from "../journals/destination_stock_voucher/destination_stock_list";

const item_transfer_routes: RouteParam[] = [
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.stock_transfer_voucher,
    component: StockTransferJournalListScreen,
    module: MODULE.inventory_journals,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.stock_transfer_voucher + "/create",
    component: StockJournal,
    module: MODULE.inventory_journals,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.stock_transfer_voucher + "/update/:id",
    component: StockJournal,
    module: MODULE.inventory_journals,
    main_module: MODULE.procurement_panel,
    action: [ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.stock_transfer_destination,
    component:DestinationStockTransferJournalListScreen ,
    module: MODULE.inventory_journals,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.stock_transfer_destination + "/create",
    component: DestinationStockJournal,
    module: MODULE.inventory_journals,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.stock_transfer_destination + "/update/:id",
    component: DestinationStockJournal,
    module: MODULE.inventory_journals,
    main_module: MODULE.procurement_panel,
    action: [ACTION.UPDATE],
    operator: "or",
  },
];

export default item_transfer_routes;
