import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import {
  useToast,
  ToastType,
} from "../../../context_providers/toast/toast_ctx";
import {
  OpsTaskCommentModel,
  OpsTaskLogModel,
} from "../../../service/models/ops/ops_ticket";
import { GetOpsTaskCommentsApi } from "../../../service/repos/ops/ops_task_comment_repo";
import { GetOpsTaskLogsApi } from "../../../service/repos/ops/ops_task_logs";

interface LogsWidgetProps {
  task_id: string;
}

const LogsWidget: FunctionComponent<LogsWidgetProps> = ({ task_id }) => {
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState<OpsTaskLogModel[]>([]);
  const getLogs = async () => {
    setLoading(true);
    const res = await GetOpsTaskLogsApi(task_id);

    if (res.success) {
      setLogs(res.data);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };

  useEffect(() => {
    getLogs();
  }, []);
  return (
    <>
      {loading && <div className="m-5">Loading ...</div>}
      {logs.map((c, i) => (
        <div key={i} className="p-2 border-b">
          {/* <div className="flex items-center gap-2 m-1">
            <div className="bg-blue-500 text-white p-2 flex justify-center items-center rounded-full w-10 h-10">
              {c.created_by_name?.charAt(0)}
            </div>
            <div className="text-sm font-normal">{c.created_by_name}</div>{" "}
            <div className="text-xs font-thin">
              {moment(c.created_at, "YYYY-MM-DD HH:mm:ss").format(
                "DD MMM YYYY hh:mm a"
              )}
            </div>
          </div> */}

          <div className=" text-sm">{c.log}</div>
          <div className="text-xs font-thin text-right">
            {moment(c.created_at, "YYYY-MM-DD HH:mm:ss").format(
              "DD MMM YYYY hh:mm a"
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export default LogsWidget;
