import { FunctionComponent } from "react";
import Sidebar from "../../components/Sidebar";
import ProtocolLayout from "../components/ProtocolLayout";

interface InstallationProps {}

const Installation: FunctionComponent<InstallationProps> = () => {
  return (
    <ProtocolLayout>
      <div className="flex flex-col items-center">
        <div className="mt-6 lg:mt-20 w-11/12 lg:w-4/5">
          <div className=" flex flex-col items-center mb-12">
            <div className="text-xl lg:text-3xl font-semibold">
              SOP for Installation of EV charging station
            </div>
            <div className="lg:text-xl mt-4 lg:mt-12 mb-3 w-full">
              Safety shall be followed By Installation Engineer/ Technician ::
            </div>
            <div className="w-full">
              <div className="mt-4">
                <div className="text-lg font-semibold">
                  1. Ensure to have all Tools, Tackles &amp; PPE before leaving
                  for Installation
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-4 lg:flex-row justify-between gap-7 mt-6">
                  <div className="">
                    <img
                      src="/images/kazam-academy/install1.jpg"
                      alt=""
                      className="w-56 h-52"
                    />
                  </div>
                  <div className="">
                    <div className="font-semibold">
                      Use approved Tools &amp; Tackles
                    </div>
                    <div className="mt-2">
                      1. Insulated Pliers <br /> 2. Screw-drivers and
                      Nut-drivers <br /> 3. Wire strippers, Cable Ties <br /> 4.
                      Multi-meter devices <br /> 5. Measuring Tape <br />
                      6. Power drills <br /> 7. Drivers, hammer/drills
                    </div>
                  </div>
                  <div className="">
                    <img
                      src="/images/kazam-academy/install2.jpg"
                      alt=""
                      className="w-56 h-52"
                    />
                  </div>
                  <div className="">
                    <div className="font-semibold">Use appropriate PPE</div>
                    <div className="mt-2">
                      1. Helmet-For head protection <br /> 2. Safety Goggles-For
                      eye protection <br /> 3. Insulated Gloves <br /> 4. Ear
                      Plugs during drilling <br />
                      5. Safety footwear
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-10">
                <div className="text-lg font-semibold">
                  2. Refer “ANNEX-I” for Graphics &amp; Drawing Reference.
                </div>
              </div>
              <div className="mt-10">
                <div className="text-lg font-semibold">
                  3. Site Survey &amp; Installation of Charger
                </div>
                <div className="mt-2">
                  <div className="font-semibold">
                    a&#41; For Single Installation:
                  </div>
                  <div className="ml-6 mt-2">
                    1. Check for Power source; Power connection for EVSE shall
                    be from the main distribution panel/metering panel. Looping
                    from the existing power socket is not recommended.
                    <span className="font-semibold">
                      (Ensure to switch OFF the power before making a
                      connection)
                    </span>
                    <br /> <br />
                    2. Cable Route from the charger to the power source shall be
                    fixed with conduit or saddle without any loose hangings,
                    size of the cable
                    <span className="font-semibold">
                      shall not be less than 2.5 Sq mm.
                    </span>
                    <br /> <br /> 3. EVSE shall be fixed on concrete wall/pillar
                    as per site requirement with all
                    <span className="font-semibold">
                      {" "}
                      four corner fasteners.{" "}
                    </span>{" "}
                    4. Drill hole concrete wall/pillar for fasteners
                    <span className="font-semibold">
                      shall be of the same size as PVC Sleeve Gitti
                    </span>
                    / Wall Column Plug / Wood plugs supplied with the equipment.
                    <br /> <br />
                    5. EVSE (Charger) shall be installed
                    <span className="font-semibold">
                      minimum at a height of 800mm from ground level; 5 ft from
                      the ground is recommended
                    </span>
                    height. <br /> <br />
                    6. Earth connection for EVSE shall be from the
                    <span className="font-semibold">
                      main earth grid/earth electrode,
                    </span>
                    looping from other device connections
                    <span className="font-semibold">
                      {" "}
                      shall be avoided.{" "}
                    </span>{" "}
                    <br /> <br />
                    7.{" "}
                    <span className="font-semibold">
                      Switch ON the power supply
                    </span>
                    only after installation &amp; termination of cable, cable
                    wires Jointing using inline
                    <span className="font-semibold">
                      {" "}
                      tube lugs shall be used{" "}
                    </span>{" "}
                    , if any joint is required. <br /> <br />
                    8. Power connection for charger shall be from
                    <span className="font-semibold"> DB panel </span>{" "}
                    immediately after EB-meter of the host premises, direct
                    connection from
                    <span className="font-semibold">
                      EB-meter shall not be made.
                    </span>
                  </div>
                </div>
                <div className="mt-6">
                  <div className="font-semibold">
                    b&#41; For Park &amp; Charge Hub- In addition to all the
                    above points:
                  </div>
                  <div className="ml-6 mt-2">
                    1. Before laying power connection,{" "}
                    <span className="font-semibold">
                      {" "}
                      check parking slot allocation{" "}
                    </span>
                    and confirm EVSE(Charger) location to install. <br />
                    <br /> 2. A dedicated{" "}
                    <span className="font-semibold"> Earthing </span> for
                    Protection Earth (PE) &amp; Surge Protection{" "}
                    <span className="font-semibold"> Earthing </span> shall be
                    provided. <br />
                    <br /> 3. Check for earthing resistance and which{" "}
                    <span className="font-semibold">
                      {" "}
                      shall be less than 5Ω.{" "}
                    </span>{" "}
                    <br /> <br /> 4. All the above points shall be added to the{" "}
                    <span className="font-semibold">
                      {" "}
                      vendor scope of work{" "}
                    </span>{" "}
                    If works are contracted.
                  </div>
                </div>
                <div className="mt-6">
                  <div className="font-semibold">
                    c&#41; In case the installation of the device is on standee
                  </div>
                  <div className="ml-6 mt-2">
                    The standee base plate shall be anchored with bolts to the
                    floor. A brick or concrete foundation with coping for a
                    thickness of 6-8 inches above ground level is required to be
                    provided.
                  </div>
                </div>
                <div className="mt-6">
                  <div className="font-semibold">
                    d&#41; Instruction manual, Signage to be fixed alongside the
                    charging station for user reference.
                  </div>
                </div>
                <div className="mt-6">
                  <div className="font-semibold">
                    e&#41; After ensuring a healthy Power supply &amp; Earthing,
                    switch ON the power supply from the source (Meter Board /
                    Electrical Point), check for the device&apos;s initial
                    power-up process and configure it in the mobile app of the
                    host.
                  </div>
                </div>
                <div className="mt-6">
                  <div className="font-semibold">
                    f&#41; Post-Installation: Training to the user for Charger
                    &amp; mobile app usage shall be given by the Engineer/
                    Technician as below.
                  </div>
                  <div className="ml-6 mt-2">
                    1. Configure the device with the Host&apos;s mobile phone
                    and explain app features, including rate setting,
                    enable-disable, and phone number of support/help. <br />
                    <br />
                    2. Record customer details- name, phone number, Accurate GPS
                    location. <br /> <br />
                    3. Three photos of the charging station (one 5 meters
                    distance and one from close-up) shall be taken and posted on
                    Operations “whats app” group. <br /> <br />
                    4. Single Video feedback with the customer, asking below two
                    questions: <br /> <br />
                    <span className="font-semibold">
                      {" "}
                      Q1 - Why did you go for an electric vehicle charging
                      station?{" "}
                    </span>
                    <br />
                    <span className="font-semibold">
                      {" "}
                      Q2 - How was your experience with Kazam?{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-20 w-full">
              <div className="text-2xl font-bold text-center">Annex 1</div>
              <div className="grid lg:grid-cols-2 gap-y-12 mt-12">
                <div className="">
                  <div className="w-full">
                    <img
                      src="/images/kazam-academy/annex1.png"
                      alt=""
                      className="w-full h-96"
                    />
                  </div>
                  <div className="w-full text-xl text-center mt-8 font-bold">
                    For single installation
                  </div>
                </div>
                <div className="">
                  <div className="w-full">
                    <img
                      src="/images/kazam-academy/annex2.png"
                      alt=""
                      className="w-full h-96"
                    />
                  </div>
                  <div className="w-full text-xl text-center mt-8 font-bold">
                    For Park &amp; Charge Hub
                  </div>
                </div>
                <div className="">
                  <div className="w-full">
                    <img
                      src="/images/kazam-academy/annex4.png"
                      alt=""
                      className="w-full h-96"
                    />
                  </div>
                  <div className="w-full text-xl text-center mt-8 font-bold">
                    Recommended device installation
                  </div>
                </div>
                <div className="">
                  <div className="w-full">
                    <img
                      src="/images/kazam-academy/annex4.png"
                      alt=""
                      className="w-full h-96"
                    />
                  </div>
                  <div className="w-full text-xl text-center mt-8 font-bold">
                    Use only Wire Jointing with Crimp connectors
                  </div>
                </div>
                <div className="">
                  <div className="w-full">
                    <img
                      src="/images/kazam-academy/annex5.png"
                      alt=""
                      className="w-full h-96"
                    />
                  </div>
                  <div className="w-full text-xl text-center mt-4 font-bold">
                    Sample Layout Diagram Of Hub (EVCS)
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ProtocolLayout>
  );
};

export default Installation;
