import { mdiCheckboxMarkedOutline, mdiCheckboxBlankOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { filter } from "lodash";
import { FunctionComponent, useEffect, useState } from "react";
import { Ops_task_status } from "../../../../../utils/enums";

interface EngineerTaskFilterWidgetProps {
  status: string[];
  onClose: () => void;
  onSubmit: (status: string[]) => void;
}

const EngineerTaskFilterWidget: FunctionComponent<
  EngineerTaskFilterWidgetProps
> = (props) => {
  const [selectedStatus, setselectedStatus] = useState<string[]>([]);
  useEffect(() => {
    setselectedStatus([...props.status]);
  }, [props.status]);
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 z-10 h-full w-full  backdrop-filter backdrop-blur-xs flex items-end justify-center ">
      <div className="p-5 bg-white rounded-t-xl w-full border-t shadow-lg">
        <div className="flex justify-between border-b mb-2">
          <h1>
            <b> Filters </b>
          </h1>
          <button onClick={props.onClose}>
            {/* <Icon path={mdiClose} className="text-red-500" size={0.8} /> */}
            <p className="font-semibold text-blue-500">Close</p>
          </button>
        </div>
        <div className="flex flex-col">
          {Object.values(Ops_task_status)?.map((status) => {
            const exist = selectedStatus?.includes(status);
            return (
              <button
                onClick={() => {
                  if (exist) {
                    setselectedStatus((f) => [
                      ...(f || []).filter((x) => x !== status),
                    ]);
                  } else {
                    setselectedStatus((f) => [...(f || []), status]);
                  }
                }}
                className="flex gap-2 items-center justify-between border-b py-2"
              >
                <p className="font-semibold capitalize">{status}</p>
                {/* <input
                    className="rounded-full"
                    type="checkbox"
                    name=""
                    id=""
                    checked={filter.status?.includes(status)}
                  /> */}
                <Icon
                  path={
                    exist ? mdiCheckboxMarkedOutline : mdiCheckboxBlankOutline
                  }
                  size={0.8}
                />
              </button>
            );
          })}
        </div>

        <div className="grid grid-cols-2 gap-5 p-2">
          <button
            onClick={() => setselectedStatus([])}
            className="bg-gray-50 border border-gray-400 text-gray-500 rounded-lg  px-3 py-1"
          >
            Clear
          </button>
          <button
            onClick={() => props.onSubmit(selectedStatus)}
            className="bg-green-50 border border-green-400 text-green-500 rounded-lg  px-3 py-1"
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default EngineerTaskFilterWidget;
