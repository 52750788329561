import axios from "axios";

import React, { useContext, useState, useEffect, createContext } from "react";
import { useGoogleLogin, useGoogleLogout } from "react-google-login";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getHeaders } from "../../service/api_client";
import {
  GetAuthDataApi,
  GoogleLoginApi,
  LogoutApi,
} from "../../service/repos/auth_repo";
import { ACTION } from "../../utils/enums";
import { urls } from "../../utils/urls";
import LoadingWidget from "../modal/loader";
import { ToastType, useToast } from "../toast/toast_ctx";
import { Role, UserModel } from "./user_model";

interface authCtx {
  isAuthorised: ({
    action,
    module,
    operator,
  }: {
    module?: string;
    action?: string | string[];
    operator?: "or" | "and" | undefined;
  }) => boolean;
  user?: UserModel;
  logout: () => Promise<void>;
  // google_login?: () => Promise<UserCredential>;
  google_login: (idToken: string) => Promise<void>;
  setUser: React.Dispatch<React.SetStateAction<UserModel | undefined>>;
}

const defaultValue: authCtx = {
  isAuthorised: function ({
    action,
    module,
    operator,
  }: {
    module?: string;
    action?: string | string[];
    operator?: "or" | "and" | undefined;
  }): boolean {
    throw new Error("Function not implemented.");
  },
  logout: function (): Promise<void> {
    throw new Error("Function not implemented.");
  },
  google_login: function (idToken: string): Promise<void> {
    throw new Error("Function not implemented.");
  },
  setUser: function (value: React.SetStateAction<UserModel | undefined>): void {
    throw new Error("Function not implemented.");
  },
};

const AuthContext = createContext<authCtx>(defaultValue);

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }: { children: any }) {
  const google_props = {
    clientId:
      "225306128297-9b0pj2cq68voiqk87qpod3fpuevghoje.apps.googleusercontent.com",
  };
  const history = useHistory();
  let location = useLocation();

  const { signOut, loaded } = useGoogleLogout(google_props);

  const { showToast } = useToast();
  const [user, setUser] = useState<UserModel>();
  // const { setLoading } = useModal();
  const [loading, setLoading] = useState(true);

  async function google_login(idToken: string) {
    setLoading(true);
    const res = await GoogleLoginApi({ idToken });

    if (res.success) {
      setUser(res.data);
      window.localStorage.setItem("token", res.data.token);
    } else {
      showToast({ type: ToastType.error, text: res.error });
      signOut();
    }

    setLoading(false);
  }

  async function logout() {
    setLoading(true);
    const res = await LogoutApi();

    if (res.success) {
      signOut();
      setUser(undefined);
      window.localStorage.removeItem("token");

      showToast({ type: ToastType.success, text: res.message });
    } else {
      showToast({ type: ToastType.error, text: res.error });
    }
    setLoading(false);
  }
  async function getUserData() {
    setLoading(true);
    try {
      // const res = await GetAuthDataApi();

      const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/auth/data`;
      const res = (
        await axios.get(url, {
          withCredentials: true,
          headers: { ...getHeaders() },
        })
      ).data;

      if (res.success) {
        setUser(res.data);
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      const { message, response, request } = error;

      if (response && response.status === 401) {
        signOut();
        window.localStorage.removeItem("token");
      }
      // else if (response && response.status === 400) return response.data;
      // else if (response && response.status === 403) return response.data;
    }
    setLoading(false);
  }

  useEffect(() => {
    const query = new URLSearchParams(location?.search);
    const token = query.get("token");
    if (token) {
      localStorage.setItem("token", token);
      query.delete("token");
      history.replace(location.pathname + "?" + query.toString());
    }

    if (!user) getUserData();
    else setLoading(false);
  }, []);

  const isAuthorised = ({
    action,
    module,
    operator,
  }: {
    module?: string;
    action?: string | string[];
    operator?: "or" | "and" | undefined;
  }) => {
    if (!action || action.length === 0 || !module) return true;
    const roles = user?.emp_profile?.permissions ?? [];
    if (roles.length > 0) {
      let flag = false;
      for (let i = 0; i < roles.length; i++) {
        const role = roles[i];
        for (let j = 0; j < role.permissions.length; j++) {
          const per = role.permissions[j];
          if (module === per.module) {
            // matched mod

            // check for actions

            if (typeof action === "string") {
              if (
                per.action.indexOf(action) !== -1 ||
                per.action.indexOf(ACTION.ALL as any) !== -1
              )
                return true;
            } else {
              for (let i = 0; i < action.length; i++) {
                const act = action[i];

                if (!operator || operator === "or") {
                  if (
                    per.action.indexOf(act) !== -1 ||
                    per.action.indexOf(ACTION.ALL as any) !== -1
                  ) {
                    flag = true;
                    break;
                  }
                } else {
                  if (
                    per.action.indexOf(act) !== -1 ||
                    per.action.indexOf(ACTION.ALL as any) !== -1
                  ) {
                    flag = true;
                  } else {
                    flag = false;
                    break;
                  }
                }
              }
            }
          }
        }
        if (flag) break;
      }
      return flag;
    }
    return false;
  };

  const value = {
    user,
    google_login,
    logout,
    setUser,
    isAuthorised,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading ? children : <LoadingWidget />}
    </AuthContext.Provider>
  );
}
