import {
  createContext,
  FunctionComponent,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { debounce } from "../../utils/debounce";
import DrawerWidget from "./drawer_widget";
import LoadingWidget from "./loader";
import ModalWidget from "./modal_widget";
export enum ModalType {
  modal,
  drawer,
}
export enum ModalAlignment {
  left,
  right,
}
export interface ModalProps {
  id: number;
  container?: any;
  type: ModalType;
  alignment?: ModalAlignment;
  title: any;
  onClose?: () => void;
}

export interface ModalCtxModel {
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  showModal: (data: {
    onClose?: () => void;
    container?: (id: any) => any;
    type: ModalType;
    alignment?: ModalAlignment;
    title: any;
  }) => void;
  onClose: (id: number) => void;
  clearAll: () => void;
}
const defaultVal: ModalCtxModel = {
  showModal: function (data: {
    container?: any;
    type: ModalType;
    alignment?: ModalAlignment;
    title: any;
    onClose?: () => void;
  }): void {
    throw new Error("Function not implemented.");
  },
  onClose: function (id: number): void {
    throw new Error("Function not implemented.");
  },
  clearAll: function (): void {
    throw new Error("Function not implemented.");
  },
  loading: false,
  setLoading: function (value: SetStateAction<boolean>): void {
    throw new Error("Function not implemented.");
  },
};
const context = createContext<ModalCtxModel>(defaultVal);
export const useModal = () => useContext(context);
interface ModalProviderProps {}

const ModalProvider: FunctionComponent<ModalProviderProps> = ({ children }) => {
  const [modals, setModals] = useState<ModalProps[]>([]);
  const [loading, setLoading] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const showModal = ({
    container,
    type,
    alignment,
    title,
    onClose,
  }: {
    onClose?: () => void;
    container?: (id: any) => any;
    type: ModalType;
    alignment?: ModalAlignment;
    title: any;
  }) => {
    const id = Math.random() * 100;
    setModals((o) => [
      ...o,
      {
        id,
        container: container ? container(id) : null,
        type,
        alignment,
        title,
        onClose,
      },
    ]);
    return id;
  };
  const clearAll = () => {
    setModals([]);
  };
  const onClose = (id: number) => {
    setModals((o) => [...o.filter((f) => f.id !== id)]);
  };
  const value: ModalCtxModel = {
    clearAll,
    showModal,
    onClose,
    loading,
    setLoading,
  };
  useEffect(() => {
    if (!loading)
      debounce(() => {
        setShowLoader(loading);
      }, 500);
    else setShowLoader(loading);
  }, [loading]);
  useEffect(() => {
    const cb = (ev: KeyboardEvent) => {
      if (modals.length)
        if (ev.key === "Escape") {
          setModals((o) => {
            o.pop();
            return [...o];
          });
        }
    };
    document.addEventListener("keyup", cb);
    return () => document.removeEventListener("keyup", cb);
  }, []);

  return (
    <context.Provider value={value}>
      {children}
      {modals.map((modal, i) =>
        modal.type === ModalType.modal ? (
          <ModalWidget
            key={modal.id}
            data={modal}
            onClose={() => {
              onClose(modal.id);
              if (modal.onClose) modal.onClose();
            }}
          />
        ) : modal.type === ModalType.drawer ? (
          <DrawerWidget
            key={modal.id}
            data={modal}
            onClose={() => {
              onClose(modal.id);
              if (modal.onClose) modal.onClose();
            }}
          />
        ) : (
          <></>
        )
      )}
      {showLoader && <LoadingWidget />}
    </context.Provider>
  );
};

export default ModalProvider;
