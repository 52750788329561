import { useState } from "react";
import { useHistory } from "react-router-dom";
import { ACTION, MODULE } from "../../../../../utils/enums";

import {
  CreateRoleApi,
  GetRoleApi,
  UpdateRoleApi,
} from "../../../../../service/repos/roles_repo";
import { ApiResponse } from "../../../../../service/models/response";

import LoadingWidget from "../../../../components/loading_spinner";
import { useEffect } from "react";
import {
  useToast,
  ToastType,
} from "../../../../../context_providers/toast/toast_ctx";
import { Role } from "../../../../../context_providers/auth/user_model";
import { OrgModel } from "../../../../new_components/org_multi_search_widget";
import OrgSearchFieldForRoles from "../../../complaint/listing/components/organizationSearchForRoles";
const defaultRoleValue: Role = {
  old_id: undefined,
  title: "",
  desc: "",
  active: false,
  permissions: [],
};
export interface RoleCreatePageProps {
  location: any;
}

const RoleCreatePage: React.FC<RoleCreatePageProps> = (props) => {
  const { showToast } = useToast();
  const state = props.location.state;
  const history = useHistory();
  const [edit, setEdit] = useState(state ? false : true);
  const [preRole, setPreRole] = useState<Role>(defaultRoleValue);
  const [role, setRole] = useState<Role>(defaultRoleValue);
  const [org, setOrg] = useState<OrgModel>({ name: "", namespace: "" });

  const [loading, setLoading] = useState(false);

  const permissionChangeHandler = (mod: string, action: string) => {
    const actions = action === ACTION.ALL ? Object.values(ACTION) : [action];
    const modExists =
      role.permissions.filter((per) => per.module === mod).length > 0;
    if (modExists) {
      role.permissions.forEach((per, i) => {
        if (per.module === mod) {
          const actionAt = per.action.indexOf(action);

          if (actionAt !== -1) {
            // "action exist";
            const allActionAt = role.permissions[i].action.indexOf(ACTION.ALL);
            if (allActionAt !== -1 && action !== ACTION.ALL) {
              // all action is exist and action is not all action
              role.permissions[i].action.splice(actionAt, 1);
              //  removed existing action1 : "

              role.permissions[i].action.splice(
                role.permissions[i].action.indexOf(ACTION.ALL),
                1
              );
            } else {
              //  "all action not exist ");
              if (action === ACTION.ALL) {
                //  "all action not exist and action is all action");
                role.permissions[i].action = [];
              } else role.permissions[i].action.splice(actionAt, 1);
            }
          } else {
            //  "action not exist");
            if (action === ACTION.ALL) {
              //  "action not exist and action is all action");
              role.permissions[i].action = actions;
            } else {
              // "action not exist and action is not all action");
              role.permissions[i].action.push(action);
              if (
                role.permissions[i].action.indexOf(ACTION.ALL) === -1 &&
                role.permissions[i].action.length === 4
              )
                role.permissions[i].action.push(ACTION.ALL);
            }
          }
        }
      });
    } else {
      //  "mod not exist");
      role.permissions.push({ module: mod, action: actions });
    }

    // "post op role", role);
    setRole({ ...role });
  };
  useEffect(() => {
    if (state) getRole();
  }, []);
  const getRole = async () => {
    setLoading(true);
    const res: ApiResponse = await GetRoleApi(state.id);
    if (res.success) {
      console.log(res.data);
      setPreRole(res.data);
      setRole(res.data);
      const roleOrg = {
        name: res.data.org,
        namespace: res.data.org,
      } as OrgModel;
      setOrg(roleOrg);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };

  const handleCreate = async () => {
    role.org = org?.namespace;
    if (!role.title) {
      showToast({
        type: ToastType.warning,
        text: "Please enter role title",
      });
      return;
    }
    if (role.permissions.length === 0) {
      showToast({
        type: ToastType.warning,
        text: "Please select atleast 1 permission",
      });
      return;
    }
    setLoading(true);
    const res: ApiResponse = role.id
      ? await UpdateRoleApi(role)
      : await CreateRoleApi(role);
    if (res.success) {
      setRole(res.data);
      setPreRole(res.data);
      setEdit(false);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };

  return (
    <div className="p-2 md:p-5 h-screen">
      <div className="flex">
        <h1></h1>
      </div>
      <div className="flex  border rounded items-center justify-between bg-white p-5">
        <div className="grid grid-cols-1 gap-5 w-1/2">
          <div className="flex flex-col">
            <label htmlFor="name" className="font-semibold text-sm">
              Role title
            </label>
            <input
              disabled={!edit}
              value={role.title}
              onChange={(e) =>
                setRole((o) => {
                  o.title = e.target.value;
                  return { ...o };
                })
              }
              className="px-2 py-1 border focus:outline-none  rounded text-sm"
              type="text"
              id="name"
              name="name"
              placeholder="Enter role title"
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="name" className="font-semibold text-sm">
              Description
            </label>
            <textarea
              disabled={!edit}
              value={role.desc}
              onChange={(e) =>
                setRole((o) => {
                  o.desc = e.target.value;
                  return { ...o };
                })
              }
              className="px-2 py-1 border focus:outline-none  rounded text-sm"
              id="name"
              name="name"
              placeholder="Short description"
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="name" className="font-semibold text-sm">
              Organization
            </label>
            <OrgSearchFieldForRoles
              disabled={!edit}
              value={org.name.length ? `${org?.name}(${org?.namespace})` : ""}
              onSelect={(o) => {
                setOrg(o);
              }}
              placeholder="Select Org for Role"
            />
          </div>
        </div>
        <div className="flex gap-2">
          <button
            onClick={
              role.id
                ? () => {
                    setEdit((o) => !o);

                    if (edit) {
                      setRole({ ...preRole });
                    }
                  }
                : () => {
                    history.goBack();
                  }
            }
            className="border rounded px-2 py-1 text-sm hover:bg-gray-800 hover:text-white"
          >
            {!role.id || edit ? "Cancel" : "Edit"}
          </button>

          {edit && (
            <button
              onClick={() => handleCreate()}
              className="border rounded px-2 py-1 text-sm hover:bg-gray-800 bg-gray-600 text-gray-100 hover:text-white"
            >
              Save
            </button>
          )}
        </div>
      </div>
      <div className="mt-5">
        <h5 className="font-semibold text-lg">Permissions</h5>
      </div>
      <div className="border rounded h-3/5 w-full overflow-auto ">
        <table className="w-full h-full overflow-auto divide-y divide-gray-200 relative">
          <thead className="bg-gray-800 text-white font-normal sticky  top-0 z-10 uppercase text-sm py-1">
            <tr>
              <td align="center" className="py-1">
                MODULE
              </td>
              {Object.values(ACTION).map((action, i) => (
                <td key={i} align="center">
                  {action}
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.values(MODULE).map((mod: string, index: number) => (
              //loop through all the modules
              <tr key={index} className={` border-b hover:bg-gray-200`}>
                <td align="left" className="px-5 text-sm uppercase">
                  {mod}
                </td>
                {/* {ACTIONLABEL.map((action,i)=>(<TableCell key={i} align="center">{perm.action.indexOf(i)!==-1?(<i className="fa fa-check text-success" aria-hidden="true"></i>):(<i className="fa fa-times text-danger" aria-hidden="true"></i>)} */}
                {Object.values(ACTION).map((action, i) => {
                  //loop through all the actions
                  //get the permission of a module

                  // const perm = role.permissions[index] ? role.permissions[index] : { module: mod, action: [] };
                  const perm = role.permissions.filter(
                    (per) => per.module === mod
                  )[0];

                  //check if the action is present in selected permission

                  let exist = perm ? perm.action.indexOf(action) !== -1 : false;
                  if (!exist)
                    exist = perm
                      ? perm.action.indexOf(ACTION.ALL) !== -1
                      : false;
                  return (
                    <td key={i} align="center" className={`py-1`}>
                      {edit ? (
                        <input
                          type="checkbox"
                          name="check"
                          id=""
                          checked={exist}
                          onChange={() => {
                            permissionChangeHandler(mod, action);
                          }}
                        />
                      ) : // <Checkbox
                      //   checked={exist}
                      //   onChange={() => {
                      //     permissionChangeHandler(mod, action);
                      //   }}
                      //   color="primary"
                      // />
                      exist ? (
                        <span className="rounded-3xl bg-green-200 text-green-800 px-2  text-xs ">
                          {" "}
                          YES
                        </span>
                      ) : (
                        <span className="rounded-3xl bg-red-200 text-red-800 px-2  text-xs">
                          NO
                        </span>
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <LoadingWidget loading={loading} />
    </div>
  );
};

export default RoleCreatePage;
