import { mdiDelete } from "@mdi/js";
import Icon from "@mdi/react";
import moment from "moment";
import { FunctionComponent, useState } from "react";
import { TransactionDataModel } from "../../../../../service/models/trannsaction_model";
import { CheckTxnIdRepo } from "../../../../../service/repos/transaction_repo";
import {
  PaymentMode,
  PaymentRefType,
  TransactionStatus,
  TransactionType,
} from "../../../../../utils/enums";
import { formToObject } from "../../../../../utils/formDataToJson";
import { compressImage } from "../../../../../utils/image_compressor";
import ImageTile from "../../../../components/common/image_tile";
import PdfTile from "../../../../components/common/pdf_tile";
import LoadingWidget from "../../../../components/loading_spinner";
import FileDropZone from "../../../../new_components/common/file_dropdown";
import StadiumButton from "../../../../new_components/common/stadium_button";
const defaultValue: TransactionDataModel = {
  type: TransactionType.PAYMENT,
  payment_mode: "",
  amount: 0,
  status: "",
  payment_date: new Date(),
};

interface TransactionEditWidgetProps {
  onSubmit: (data: FormData) => void;
}

const TransactionEditWidget: FunctionComponent<TransactionEditWidgetProps> = (
  props
) => {
  const [data, setData] = useState<TransactionDataModel>({ ...defaultValue });
  // const [type, setType] = useState(TransactionType.PAYMENT);
  // const [mode, setMode] = useState(PaymentMode.Cash);
  // const [file, setFile] = useState<File>();
  // const [txn_id, setTxn_id] = useState("");
  // const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);

  return (
    <div className="       p-2">
      <form
        onSubmit={async (e) => {
          // e.preventDefault();
          // const item: any = formToObject(new FormData(e.currentTarget));
          // props.onSubmit(item);
          // setTransactions((items) => [...items, item]);
          e.preventDefault();

          if (
            data.payment_mode &&
            data.payment_mode !== PaymentMode.Cash &&
            data.txn_id
          ) {
            if (!data.files || data.files.length === 0) {
              alert("Please select a supporting document/file");
              return;
            }
            setLoading(true);
            const existRes = await CheckTxnIdRepo(data.txn_id);

            if (existRes.data) {
              alert("Txn id already exist !");
              setLoading(false);
              return;
            }
            setLoading(false);
          }
          data.status = TransactionStatus.SUCCESS;

          const form = new FormData();
          const files = data.files;
          delete data.files;
          form.append("data", JSON.stringify(data));
          if (files && files[0]) {
            console.log(files[0]);

            form.append("attachment", files[0]);
          }
          props.onSubmit(form);
        }}
      >
        <div className="flex">
          {Object.values(TransactionType).map((t, i) => {
            return (
              <div className="mx-3" key={i}>
                <input
                  onChange={(e) =>
                    setData((old) => ({ ...old, type: e.target.value }))
                  }
                  className="mx-2"
                  type="radio"
                  value={t}
                  checked={t === data.type}
                  name="type"
                  required
                  id={`${t}`}
                />

                <label className=" font-semibold" htmlFor={`${t}`}>
                  {t}
                </label>
              </div>
            );
          })}
        </div>
        <div className=" grid grid-cols-1 md:grid-cols-2  gap-2 items-start    mt-2">
          <div className="grid grid-cols-3 gap-3 items-center">
            <label className="col-span-1" htmlFor="orderid">
              Payment Date
            </label>
            <input
              id="orderid"
              value={moment(data.payment_date).format("YYYY-MM-DD HH:mm:ss")}
              className="border rounded  p-2 w-full col-span-2"
              type="datetime-local"
              name="payment_date"
              required
              onChange={(e) =>
                setData((old) => ({
                  ...old,
                  payment_date: new Date(e.target.value),
                }))
              }
            />
          </div>{" "}
          <div className="grid grid-cols-3 gap-3 items-center">
            <label className="col-span-1" htmlFor="mode">
              Mode
            </label>
            <select
              id="mode"
              name="payment_mode"
              className="border rounded  p-2   w-full col-span-2"
              required
              onChange={(e) =>
                setData((old) => ({ ...old, payment_mode: e.target.value }))
              }
            >
              <option value="" hidden>
                Select...
              </option>
              {Object.values(PaymentMode).map((item, i: number) => (
                <option key={i} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
          <div className="grid grid-cols-3 gap-3 items-center">
            <label className="col-span-1" htmlFor="ref_type">
              Ref Type
            </label>
            <select
              id="ref_type"
              name="payment_mode"
              className="border rounded  p-2   w-full col-span-2"
              required
              onChange={(e) =>
                setData((old) => ({ ...old, ref_type: e.target.value }))
              }
            >
              <option value="" hidden>
                Select...
              </option>
              {Object.values(PaymentRefType).map((item, i: number) => (
                <option key={i} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
          <div className="grid grid-cols-3 gap-3 items-center">
            <label className="col-span-1" htmlFor="ref_id">
              Ref ID
            </label>
            <input
              id="ref_id"
              className="border rounded  p-2 w-full col-span-2"
              type="text"
              name="ref_id"
              required
              placeholder="Reference id"
              onChange={(e) =>
                setData((old) => ({ ...old, ref_id: e.target.value }))
              }
            />
          </div>
          <div className="grid grid-cols-3 gap-3 items-center">
            <label className="col-span-1" htmlFor="amount">
              Amount
            </label>
            <input
              id="amount"
              className="border rounded p-2 w-full col-span-2"
              type="number"
              name="amount"
              required
              placeholder="Amount"
              onChange={(e) => {
                setData((old) => ({
                  ...old,
                  amount: parseFloat(e.target.value),
                }));
              }}
            />
          </div>
          {data.payment_mode && data.payment_mode !== PaymentMode.Cash && (
            <div className="grid grid-cols-3 gap-3 items-center">
              <label className="col-span-1" htmlFor="txn_id">
                Transaction Id
              </label>
              <input
                id="txn_id"
                className="border rounded p-2 w-full col-span-2"
                type="text"
                name="txn_id"
                required
                placeholder="Transaction ID/cheque no"
                onChange={(e) =>
                  setData((old) => ({ ...old, txn_id: e.target.value }))
                }
              />
            </div>
          )}
        </div>{" "}
        <div className="">
          <label htmlFor="comment">Comment</label>
          <textarea
            id="comment"
            className="border rounded p-2 w-full"
            name="comment"
            // disabled
            placeholder="Note/comment"
            onChange={(e) =>
              setData((old) => ({ ...old, comment: e.target.value }))
            }
          />
        </div>
        <div className=" ">
          <p>Attachments</p>

          {data.files && data.files.length > 0 ? (
            <div className="relative  w-56 h-52">
              {data.files[0].type.startsWith("image") ? (
                <img
                  src={URL.createObjectURL(data.files[0])}
                  alt=""
                  className="object-cover w-full h-full"
                />
              ) : (
                <embed
                  src={URL.createObjectURL(data.files[0])}
                  type="application/pdf"
                  className="object-cover w-full h-full"
                ></embed>
              )}
              <button
                className="cursor-pointer text-red-400 p-1 bg-white rounded-full absolute top-0 right-0"
                onClick={() => setData((old) => ({ ...old, files: undefined }))}
              >
                <Icon path={mdiDelete} className="w-5 h-5"></Icon>
              </button>
            </div>
          ) : (
            <FileDropZone
              onChange={async (files) => {
                if (files.length > 0) {
                  // const encoded = await fileToBase64(files[0]);
                  if (files[0].type.startsWith("image")) {
                    const comFile = await compressImage(files[0]);
                    if (comFile.size > 3000000) {
                      alert("Please upload file size less than 3 md");
                      return;
                    }
                    setData((old) => ({ ...old, files: [comFile] }));
                  } else {
                    if (files[0].size > 3000000) {
                      alert("Please upload file size less than 3 md");
                      return;
                    }
                    setData((old) => ({ ...old, files: [files[0]] }));
                  }
                }
              }}
              type="image/*,application/pdf"
              multiSelect={false}
            />
          )}
        </div>
        <div className="flex justify-center my-5">
          <button className="px-3 py-1  rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform">
            Submit
          </button>
        </div>
      </form>{" "}
      <LoadingWidget loading={loading} />
    </div>
  );
};

export default TransactionEditWidget;
