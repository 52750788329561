import { RouteParam } from "../../../routes";
import { ACTION, MODULE } from "../../../utils/enums";
import { urls } from "../../../utils/urls";
import SalesVoucherListScreen from "../account_booking/sales/sales_voucher_register";
import SalesVoucher from "../vouchers/sales";

const sales_voucher_routes: RouteParam[] = [
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.sales_voucher,
    component: SalesVoucherListScreen,
    module: MODULE.voucher,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.sales_voucher + "/create",
    component: SalesVoucher,
    module: MODULE.voucher,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.sales_voucher + "/update/:id",
    component: SalesVoucher,
    module: MODULE.voucher,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
];

export default sales_voucher_routes;
