import { FunctionComponent } from "react";

interface RemarkWidgetProps {
  disabled?: boolean;
  value: string;
  setValue: (val: string) => void;
}

const RemarkWidget: FunctionComponent<RemarkWidgetProps> = (props) => {
  return (
    <div className="flex flex-col my-2">
      <label htmlFor="remark">Remarks</label>
      <textarea
        disabled={props.disabled}
        id="remark"
        placeholder="notes .."
        className="w-full focus:outline-none border rounded-lg p-2 bg-white"
        rows={6}
        value={props.value}
        onChange={(e) => {
          props.setValue(e.target.value);
        }}
      ></textarea>
    </div>
  );
};

export default RemarkWidget;
