import { useState } from "react";
import ModalDialog from "./modal";

export interface ImageTileProps {
  url: string;
}
const ImageTile: React.FC<ImageTileProps> = (props) => {
  return (
    <a target="_blank" href={props.url}>
      <div className="card m-2">
        <img
          src={props.url}
          alt=""
          style={{ width: "200px", height: "200px" }}
        />
      </div>
    </a>
  );
};
export default ImageTile;
