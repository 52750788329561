import { BaseModel } from "../../../service/models/base";
import { LedgerEntryModel, VoucherModel } from "./common_model";
import { PurchaseVoucherModel } from "./purchase_model";

export interface PaymentVoucherModel extends VoucherModel {
  voucher_type: "Payment";
  entry_type: string;
  date: Date;
  payment_ledger_entry?: LedgerEntryModel;
  payment_ledger_entry_id: string;
  party_ledger_entry?: LedgerEntryModel;
  party_ledger_entry_id: string;

  sub_total: number;
  total: number;
  payment_split: {
    id?: string;
    voucher_id: string;
    amount: number;
    voucher: PurchaseVoucherModel;
  }[];
  txn_detail?: {
    txn_id: string;
    mode: string;
    date: Date;
  };
}

export const paymentDefaultValue: PaymentVoucherModel = {
  entry_type: "Bill",
  voucher_type: "Payment",
  date: new Date(),
  payment_ledger_entry_id: "",
  party_ledger_entry_id: "",
  sub_total: 0,
  total: 0,
  payment_split: [],
  attachments: [],
};
