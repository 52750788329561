import { FunctionComponent, useEffect, useState } from "react";
import { Tooltip } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "react-feather";
import Sidebar from "../components/sidebar";
import { GetBirthdayToday } from "../../../../service/repos/hrm/hrm_repo";
import "../styles/index.css";
import Icon from "@mdi/react";
import { mdiAccountCircle } from "@mdi/js";
import { birthdayOfYear, dayOfYear } from "../../../../utils/date_util";
import Layout from "../layout";
import HrmLoader from "../components/loading";
import NoData from "../components/NoData";

interface BirthdayProps {}

const Birthday: FunctionComponent<BirthdayProps> = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const daysList = ["S", "M", "T", "W", "T", "F", "S"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [upcomingBirthdays, setUpcomingBirthdays] = useState<{}[]>([]);
  const [pastBirthdays, setPastBirthdays] = useState<{}[]>([]);
  const [showUpcoming, setShowUpcoming] = useState<boolean>(true);
  const [showAnimation, setShowAnimation] = useState<boolean>(true);

  const getBirthdayList = async () => {
    const res = await GetBirthdayToday();

    if (res.success) {
      setLoading(false);
      sortDate(res.data);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setShowAnimation(false);
    }, 6000);
  }, []);

  useEffect(() => {
    dayOfYear();
  }, []);

  const sortDate = (data: any[]) => {
    let now = new Date();
    now = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    let temp: any[] = [];
    temp = data.map((d) => {
      const date = new Date(d.dob);
      return {
        ...d,
        dob: new Date(now.getFullYear(), date.getMonth(), date.getDate()),
      };
    });

    temp.sort((d1, d2) => {
      if (d1.dob.getTime() > d2.dob.getTime()) return 1;
      else if (d1.dob.getTime() < d2.dob.getTime()) return -1;
      else return 0;
    });
    const tempPast = [];
    const tempUpcoming = [];
    for (let i = 0; i < temp.length; i++) {
      const d = temp[i];
      if (d.dob.getTime() < now.getTime()) {
        tempPast.push(d);
      } else {
        tempUpcoming.push(d);
      }
    }

    setPastBirthdays(tempPast);
    setUpcomingBirthdays(tempUpcoming);
  };

  useEffect(() => {
    getBirthdayList();
  }, []);

  return (
    <Layout pageTitle="Birthdays">
      <div className="">
        {showAnimation && (
          <div className="fixed bottom-0 -right-12 z-50 transform scale-150 -rotate-45">
            <iframe
              src="https://embed.lottiefiles.com/animation/105525"
              title="confetti"
            ></iframe>
          </div>
        )}

        <div className="py-2 px-2 pb-12">
          <div className="flex flex-col items-center">
            <div className="flex items-center gap-4 mt-6">
              <div
                className={`${
                  showUpcoming
                    ? "bg-hrmBlue text-white font-bold"
                    : "border border-gray-500"
                } rounded-full py-1 px-4 dark:text-white cursor-pointer `}
                onClick={() => setShowUpcoming(true)}
              >
                Upcoming
              </div>
              <div
                className={`${
                  showUpcoming
                    ? "border border-gray-500"
                    : "bg-hrmBlue border border-white text-white font-bold"
                } rounded-full py-1 px-7 dark:text-white cursor-pointer`}
                onClick={() => setShowUpcoming(false)}
              >
                Past
              </div>
            </div>
          </div>
          {loading ? (
            <div className="flex flex-col items-center mt-12">
              <HrmLoader className="w-20 h-20" />
            </div>
          ) : showUpcoming ? (
            <div className="mt-6">
              {upcomingBirthdays.length > 0 ? (
                <div className="grid lg:grid-cols-4 gap-x-4 lg:gap-x-8 gap-y-4 lg:gap-y-4">
                  {upcomingBirthdays.map((item: any, i) => {
                    return (
                      <div
                        className={` ${
                          i % 2 === 0 ? "" : ""
                        } flex flex-col items-center bg-white py-8 px-4 rounded-xl`}
                      >
                        <div className="">
                          {item.profile_image ? (
                            <img
                              src={item?.profile_image}
                              alt=""
                              className="w-32 h-32 mt-2 rounded-full"
                            />
                          ) : (
                            <>
                              <Icon
                                path={mdiAccountCircle}
                                className="w-32 h-32 mt-2"
                              />
                            </>
                          )}
                        </div>

                        <div className="text-base lg:text-lg dark:text-primaryColor font-semibold mt-2">
                          {item.first_name + " " + item.last_name}
                        </div>
                        <div className="text-sm text-secondaryColor my-1">
                          {item.designation}
                        </div>
                        <div className="text-lg lg:text-2xl font-semibold mt-8">
                          {item.dob.getDate()} {months[item.dob.getMonth()]}
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <NoData
                  text="No birthdays left"
                  align="center"
                  imageSize="large"
                />
              )}
            </div>
          ) : (
            <div className="mt-8">
              <div className="grid lg:grid-cols-4 gap-x-4 lg:gap-x-8 gap-y-4 lg:gap-y-4">
                {pastBirthdays.map((item: any, i) => {
                  return (
                    <div
                      className={` ${
                        i % 2 === 0 ? "" : ""
                      } flex flex-col items-center bg-white py-8 px-4 rounded-xl`}
                    >
                      <div className="">
                        {item.profile_image ? (
                          <img
                            src={item?.profile_image}
                            alt=""
                            className="w-32 h-32 mt-2 rounded-full"
                          />
                        ) : (
                          <>
                            <Icon
                              path={mdiAccountCircle}
                              className="w-32 h-32 mt-2"
                            />
                          </>
                        )}
                      </div>

                      <div className="text-base lg:text-lg dark:text-primaryColor font-semibold mt-2">
                        {item.first_name + " " + item.last_name}
                      </div>
                      <div className="text-sm text-secondaryColor my-1">
                        {item.designation}
                      </div>
                      <div className="text-lg lg:text-2xl font-semibold mt-8">
                        {item.dob.getDate()} {months[item.dob.getMonth()]}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Birthday;
