import { Search } from "@material-ui/icons";
import axios from "axios";
import {
  fetchDelete,
  fetchGet,
  fetchPost,
  fetchPut,
  getHeaders,
} from "../api_client";
import {
  InventoryCommentModel,
  InventoryModel,
} from "../models/inventory/inventory_model";
import { WarehouseModel } from "../models/inventory/warehouse_model";

export const CreateInventoryApi = async (data: InventoryModel[]) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/inventory`;
    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const UpdateInventoryApi = async (data: InventoryModel[]) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/inventory`;
    const res = await fetchPut(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const DeleteInventoryApi = async (data: string[]) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/inventory/delete`;
    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GetInventoryApi = async (id: number) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/inventory/${id}`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GetInventoryOverViewByStatusApi = async (data: {
  status: string[];
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/inventory/overview-data`;
    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GetInventoryOverViewByWarehousApi = async (data: {
  status: string[];
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/inventory/overview-data/warehouse`;
    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const GetInventoryListApi = async (data: {
  product_id?: string[];
  status?: string[];
  search?: string;
  page: number;
  count: number;
  all?: any;
  warehouse_id?: string[];
  fromDate?: Date;
  toDate?: Date;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/inventory/get`;

    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GetInventoryDeviceForOrderApi = async (data: {
  order_id: string;
  warehouse_id: number;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/inventory/get-inventory-for-order`;
    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const RevokeInventoryDeviceForOrderApi = async (data: {
  device_id: string;
  warehouse_id: number;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/inventory/revoke-device-for-order`;
    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const CheckInventoryAvailableApi = async (data: {
  device_id: string;
  warehouse_id: number;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/inventory/check-inventory-available`;
    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const GetOrderDevicesApi = async (order_id: string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/inventory/assign-device-to-order/${order_id}`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const DeleteOrderDevicesApi = async (data: {
  id: number;
  date: string;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/inventory/device_returned_back`;
    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const CreateDeliveredDevicesApi = async (data: {
  device_ids: string[];
  order_id: string;
  status: string;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/inventory/assign-device-to-order`;
    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const ChangeStatusOfDeliveredDevicesApi = async (data: {
  id: number;
  status: string;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/inventory/assign-device-to-order/change-status`;
    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const CreateInventoryCommentApi = async (
  data: InventoryCommentModel
) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/inventory/comments/${data.inventory_id}`;
    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GetInventoryCommentsApi = async (id: number) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/inventory/comments/${id}`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GetInventoryLogsApi = async (id: number) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/inventory/logs/${id}`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
