import { FunctionComponent, useState } from "react";
import { useOpsTask } from "../../../../../../context_providers/ops_task_engineer_detail/ops_task_engineer_detail";
import { useTaskSession } from "../../../../../../context_providers/task_session_ctx";
import {
  useToast,
  ToastType,
} from "../../../../../../context_providers/toast/toast_ctx";
import { fetchPut } from "../../../../content_panel/services/serviceClient";
import { Ops_task_status, TaskType } from "../../../../../../utils/enums";
import RemarkWidget from "./remark_widget";
import RequiredMark from "../../../../../new_components/common/required_mark";
import LoadingWidget from "../../../../../components/loading_spinner";

interface CommonDetailSectionProps {}

const CommonDetailSection: FunctionComponent<CommonDetailSectionProps> = () => {
  const { onDuty, getLocation, position } = useTaskSession();
  const { data, setData } = useOpsTask();

  const [task, setTask] = useState({ ...data });
  const { showToast } = useToast();

  const [loading, setLoading] = useState(false);

  const [edit, setEdit] = useState(false);
  const update_task = async () => {
    if (!window.confirm("Are you sure")) return;
    setLoading(true);
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/new-ops/task/my-tasks/${data?.id}/`;
    const res = await fetchPut(url, {
      distance_travelled: task?.distance_travelled,
      remarks: task?.remarks,
    });
    if (res.success) {
      setData((pv) => ({ ...pv, ...res.data }));
      setTask((pv) => ({ ...pv, ...res.data }));
      setEdit(false);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  return (
    <>
      <div className="flex flex-col my-2">
        <label htmlFor="tranvelled">
          Distance Travelled (KM) <RequiredMark />
        </label>
        <input
          disabled={!edit}
          id="tranvelled"
          placeholder="Click here"
          className="w-full focus:outline-none border rounded-lg p-2 bg-white"
          type="number"
          value={task?.distance_travelled}
          onChange={(e) => {
            const distance_travelled = Number(e.target.value);
            setTask((o) => ({ ...o!, distance_travelled }));
          }}
        ></input>
      </div>
      <RemarkWidget
        disabled={!edit}
        value={task?.remarks ?? ""}
        setValue={function (val: string): void {
          setTask((o) => ({ ...o!, remarks: val }));
        }}
      />
      {edit ? (
        <div className="grid grid-cols-2 gap-5 my-2">
          <button
            onClick={() => {
              setEdit(false);
              setTask((o) => ({ ...data }));
            }}
            className="bg-blue-50 text-blue-500 border-blue-300 border rounded-lg px-3 py-1 text-center"
          >
            Cancel
          </button>

          <button
            onClick={update_task}
            className="bg-green-50 text-green-500 border-green-300 border rounded-lg px-3 py-1 text-center"
          >
            Update
          </button>
        </div>
      ) : (
        onDuty &&
        data?.status == Ops_task_status.under_progress && (
          <button
            onClick={() => setEdit(true)}
            className="bg-blue-50 text-blue-500 border-blue-300 border rounded-lg px-3 py-1 text-center w-full"
          >
            Edit
          </button>
        )
      )}
      {loading && <LoadingWidget loading={true} />}
    </>
  );
};

export default CommonDetailSection;
