import {
  createContext,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import ToastModal from "./toast_modal";
// export const ToastType = {
//   sucess: 1,
//   error: 2,
//   info: 3,
//   warning: 4,
//   default: 5,
// };
export enum ToastType {
  success,
  error,
  info,
  warning,
  default,
}
export interface ToastData {
  id: number;
  type: ToastType;
  progress: number;
  text: string;
}
export interface ToastCtxModel {
  toasts: ToastData[];
  showToast: (data: { type?: ToastType; text?: string }) => void;
  onClose: (id: number) => void;
  clearAll: () => void;
  // onPause: () => void;
  // onResume: () => void;
  // duration: number;
}
const defaultVal: ToastCtxModel = {
  toasts: [],
  showToast: function (data: { type?: ToastType; text?: string }): void {},
  onClose: function (id: number): void {},
  clearAll: function (): void {},
  // onPause: () => {},
  // onResume: () => {},
  // duration: 0,
};
const context = createContext<ToastCtxModel>(defaultVal);
export const useToast = () => useContext(context);
interface ToastProviderProps {}

const ToastProvider: FunctionComponent<ToastProviderProps> = ({ children }) => {
  const [toasts, setToasts] = useState<ToastData[]>([]);

  const duration = 4500;

  const showToast = ({ type, text }: { type?: ToastType; text?: string }) => {
    const id = Math.random();
    setToasts((o) => [
      ...o,
      {
        id,
        type: type ?? ToastType.default,
        progress: 0,
        text: text ?? "",
      },
    ]);
    const t = setTimeout(() => {
      onClose(id);
      clearTimeout(t);
    }, duration);
  };
  const onClose = (id: number) => {
    setToasts((o) => {
      const t = o.filter((t, i) => {
        return id !== t.id;
      });

      return t;
    });
  };
  const clearAll = () => {
    setToasts([]);
  };

  const value: ToastCtxModel = {
    toasts,
    showToast,
    onClose,
    clearAll,
  };

  return (
    <context.Provider value={value}>
      {children}
      <div
        className={`  top-0 right-0 bottom-0 max-h-screen   w-80 z-50 p-5 overflow-auto  ${
          toasts.length ? " fixed " : " hidden "
        }`}
        style={{ height: "max-content" }}
      >
        <div className="flex flex-col gap-3">
          {/* <div
          className="  flex justify-end bg-darkCard hover:bg-darkCardHover p-1 rounded  gap-3 text-white text-sm cursor-pointer hover:text-red-500"
          onClick={() => {
            clearAll();
          }}
        >
          <p>Clear all</p>
          <Icon path={mdiClose} className={` h-5 w-5   `}></Icon>
        </div> */}

          {toasts.map((t, i) => (
            <ToastModal toast={t} index={t.id} key={Math.random() * 100000} />
          ))}
        </div>
      </div>
    </context.Provider>
  );
};

export default ToastProvider;
