import { RouteParam } from ".";
import LandingScreen from "../ui/pages/landing_screen";
import WarehouseListing from "../ui/pages/warehouse";
import { MODULE } from "../utils/enums";
import { urls } from "../utils/urls";

const master_data_page_routes: RouteParam[] = [
  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.master_data_warehouse,
    component: WarehouseListing,
    main_module: MODULE.master_data_panel,
  },
];

export default master_data_page_routes;
