import { FunctionComponent } from "react";
import { academyVideoCategory } from "../../../../../utils/enums";
import VideoLayout from "../components/VideosLayout";

interface SuccessProps {}

const Success: FunctionComponent<SuccessProps> = () => {
  const segment = academyVideoCategory.Success_story;

  return <VideoLayout segment={segment} />;
};

export default Success;
