import { FunctionComponent, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useAuth } from "../../../../../context_providers/auth/authProvider";
import HrmButton from "../../components/HrmButton";
import { GetRef, GetReferals } from "../../service/repos/job_portal";

interface ReferralsProps {
  uid: any;
}

const Referrals: FunctionComponent<ReferralsProps> = ({ uid }) => {
  const [referrals, setReferrals] = useState<any>([]);

  const getRef = async (uid: any) => {
    const res = await GetRef(uid);

    if (res.success) {
      getReferrals(res.data?.ref_code);
    }
  };

  const getReferrals = async (ref_id: number) => {
    const res = await GetReferals(ref_id);
    console.log(res);
    if (res.success) {
      setReferrals(res.data?.data);
    }
  };

  useEffect(() => {
    getRef(uid);
  }, []);

  return (
    <div className="">
      {referrals.length > 0 ? (
        <div className="pt-12">
          <div className="hidden lg:grid grid-cols-4  bg-opacity-60  border-b border-gray-300 pb-3">
            {["Name", "Email", "Mobile", "Resume"].map((el, i) => {
              return (
                <div className="" key={i}>
                  {el}
                </div>
              );
            })}
          </div>
          <div className="">
            {referrals.map((el: any, i: number) => {
              return (
                <div
                  key={i}
                  className="grid grid-cols-2 lg:grid-cols-4 gap-y-4 py-4 items-center lg:gap-y-0 text-black text-sm   border-b border-gray-200"
                >
                  <div className="">{el?.name}</div>
                  <div className="">{el?.email}</div>
                  <div className="">{el?.mobile}</div>
                  <div className="">
                    <a href={el?.file} target="_blank" rel="noreferrer">
                      <HrmButton text="View" size="small" type="primary" />
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="pt-8 pl-2 font-semibold text-gray-400">
          No referrals
        </div>
      )}
    </div>
  );
};

export default Referrals;
