import { mdiDownload, mdiOpenInNew } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useState } from "react";
import {
  useToast,
  ToastType,
} from "../../../../../context_providers/toast/toast_ctx";
import { InvoiceModel } from "../../../../../service/models/orders/invoice_model";
import { GetInvoiceOfRefApi } from "../../../../../service/repos/invoice_service";
import { PaymentRefType } from "../../../../../utils/enums";
import LoadingWidget from "../../../../components/loading_spinner";

interface InvoiceWidgetProps {
  order_id: string;
}

const InvoiceWidget: FunctionComponent<InvoiceWidgetProps> = (props) => {
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState<InvoiceModel[]>([]);

  const heads = ["Date", "Invoice #", "Ref #", "Ref type", "Action"];
  // const downloadInvoice = async (url: string) => {
  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.download = `invoice.pdf`;
  //   link.click();
  //   link.remove();
  // };
  const getInvoices = async () => {
    setLoading(true);
    const res = await GetInvoiceOfRefApi({
      ref_id: props.order_id,
      ref_type: PaymentRefType.order,
    });
    if (res.success) {
      setInvoices(res.data);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  useEffect(() => {
    getInvoices();
  }, []);

  return (
    <div className=" overflow-auto h-full my-3">
      <table className="border divide-y divide-gray-200 relative w-full">
        <thead className="bg-gray-200 sticky  top-0">
          <tr>
            {heads.map((h) => (
              <th
                key={h}
                scope="col"
                className="px-3 py-2 text-xs font-medium text-center text-black uppercase tracking-wider"
              >
                {h}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200 max-h-96 mx-2 text-xs">
          {invoices.map((invoice) => {
            //
            return (
              <tr
                key={invoice.id}
                // onDoubleClick={() => props.onTxnClick(txn.id!)}
              >
                <td className="px-3 py-2 whitespace-nowrap text-xs font-medium text-gray-900">
                  {new Date(invoice.created_at).toLocaleString()}
                </td>
                <td className="px-3 py-2 whitespace-nowrap  ">
                  {/* <NavLink to={URLS.transactionDetail + "?txn_id=" + txn.id}> */}
                  {invoice.invoice_no}
                  {/* </NavLink> */}
                </td>
                <td className="px-3 py-2 whitespace-nowrap  ">
                  {/* <NavLink to={URLS.transactionDetail + "?txn_id=" + txn.id}> */}
                  {invoice.ref_id}
                  {/* </NavLink> */}
                </td>
                <td className="px-3 py-2 whitespace-nowrap  ">
                  {/* <NavLink to={URLS.transactionDetail + "?txn_id=" + txn.id}> */}
                  {invoice.ref_type}
                  {/* </NavLink> */}
                </td>

                <td align="center" className="">
                  <div className="flex gap-2">
                    <a className="d-block" target="_blank" href={invoice.url}>
                      <Icon path={mdiOpenInNew} className="w-5 h-5"></Icon>
                    </a>
                    <a href={invoice.url} download={"invoice.pdf"}>
                      <Icon path={mdiDownload} className="w-5 h-5"></Icon>
                    </a>{" "}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>{" "}
      {invoices.length === 0 && (
        <div className="text-lg text-gray-400 font-semibold my-10 flex justify-center ">
          <div className=""> No Invoice found !</div>
        </div>
      )}{" "}
      <LoadingWidget loading={loading} />
    </div>
  );
};

export default InvoiceWidget;
