import { fetchGet, fetchPost } from "../api_client";

export const GetUploadUrlsApi = async ({
  mimetype,
  parts,
  belong,
}: {
  belong: string;
  mimetype: string;
  parts: number;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/get-upload-url?mimetype=${mimetype}&parts=${parts}&belong=${belong}`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const CompleteUploadApi = async (data: {
  mimetype: string;
  meta: {
    belong: string;
    ref_id: string;
    category?: string;
  };
  key: string;
  upload_id: string;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/complete-upload`;
    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const CancelUploadApi = async (data: {
  key: string;
  upload_id: string;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/cancel-upload`;
    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
