import Layout from "../layout";
import OrgTree from "./components/OrgTree";
import { orgTreeData } from "../../../../utils/orgTreeData";
import { FunctionComponent, useEffect, useState } from "react";
import { fetchGet } from "../../../../service/api_client";
import HrmLoader from "../components/loading";
import { useHistory } from "react-router-dom";
import { urls } from "../../../../utils/urls";
import { UserModel } from "../../../../context_providers/auth/user_model";
import moment from "moment";
import { Mail, Phone, X } from "react-feather";

const OrganizationTree = () => {
  const [index, setIndex] = useState(0);
  const [orgTree, setOrgTree] = useState({});
  const [loading, setLoading] = useState(true);
  const [scale, setScale] = useState(0.8);
  const [inDisabled, setInDisabled] = useState(false);
  const [outDisabled, setOutDisabled] = useState(false);
  const [nodeData, setNodeData] = useState(null);

  const zoomOut = () => {
    if (scale > 0.6) {
      setScale((e) => e - 0.2);
      setInDisabled(false);
    } else {
      setOutDisabled(true);
    }
  };

  const zoomIn = () => {
    if (scale < 1.2) {
      setScale((e) => e + 0.2);
      setOutDisabled(false);
    } else {
      setInDisabled(true);
    }
  };

  const scrollToRight = () => {
    const orgTree = window.document.getElementById("orgTree");
    orgTree?.scrollTo(5500, 0);
  };

  const getFilterData = async () => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/employees?all=true`;
      const res = await fetchGet(url);

      const tempData = res.data?.data.filter(
        (el: UserModel) => el.emp_profile?.active
      );

      const kazamHead = {
        uid: 1000,
        emp_profile: {
          reporting_to_id: null,
        },
        first_name: "Kazam",
      };

      const tryData = tempData.reduce(
        (acc: any[], curr: any) => {
          if (
            curr.uid === "QhpT5hyFIIsL" ||
            curr.uid === "vlrAjwXGbrTG" ||
            curr.uid === "K2eVe1hrNgo7"
          ) {
            return [
              ...acc,
              {
                ...curr,
                emp_profile: {
                  reporting_to_id: 1000,
                },
              },
            ];
          } else {
            return [...acc, curr];
          }
        },
        [kazamHead]
      );

      const orgIdObj = tryData.reduce((acc: any, el: any, i: number) => {
        acc[el.uid] = i;
        return acc;
      }, {});

      makeTree(tryData, orgIdObj);
    } catch (error) {
      console.log(error);
    }
  };

  const makeTree = (tempData: any, orgIdObj: any) => {
    let tree = {};
    tempData.forEach((el: any, i: number) => {
      if (el.emp_profile.reporting_to_id === null) {
        tree = el;
        return;
      }

      // Use our mapping to locate the parent element in our data array

      const parentEl = tempData[orgIdObj[el.emp_profile.reporting_to_id]];

      if (parentEl) {
        parentEl.children = [...(parentEl.children || []), el];
      }
    });

    setOrgTree(tree);
    setLoading(false);

    scrollToRight();
  };

  useEffect(() => {
    getFilterData();
  }, []);

  const history = useHistory();

  const handleClick = () => {
    history.push(urls.hrm_org_tree_tab, orgTree);
  };

  return (
    <Layout pageTitle="Hierarchy of organization">
      <div className="relative hidden lg:block">
        <div className="">
          {loading ? (
            <div className="flex flex-col items-center justify-center h-screen">
              <HrmLoader className="w-12 h-12" />
            </div>
          ) : (
            <div className="">
              <div className="fixed bottom-8 left-1/2 -translate-x-1/2 flex items-center mr-5 mt-6 z-30">
                <button
                  className={` ${
                    outDisabled
                      ? "border border-gray-500 text-gray-300"
                      : "bg-gray-300 text-black "
                  } cursor-pointer py-1 px-6 text-4xl font-bold    rounded-l-lg`}
                  onClick={() => zoomOut()}
                >
                  -
                </button>
                <button
                  className={`  ${
                    inDisabled
                      ? "border border-gray-500 text-gray-300"
                      : "bg-gray-300 text-black "
                  } cursor-pointer py-1 px-6 text-4xl font-bold    rounded-r-lg`}
                  onClick={() => zoomIn()}
                >
                  +
                </button>
              </div>

              <div
                className=" overflow-x-scroll py-10  relative   "
                id="orgTree"
                // style={{ transform: "scale(20%)" }}
              >
                <div
                  className=""
                  style={{
                    transform: `scale(${scale})`,
                    // transformOrigin: "left",
                    transition: "0.4s all ease",
                  }}
                >
                  <OrgTree
                    handleNodeData={(d: any) => setNodeData(d)}
                    orgData={orgTree}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="lg:hidden text-white flex flex-col items-center mt-32">
        Org tree can be viewed only on desktop
      </div>

      {nodeData && (
        <div className="fixed top-0 left-0 w-screen h-screen flex flex-col items-center justify-center z-40 bg-black bg-opacity-30">
          <EmpCard data={nodeData} close={() => setNodeData(null)} />
        </div>
      )}
    </Layout>
  );
};

export default OrganizationTree;

interface EmpCardProps {
  data: any;
  close: any;
}

const EmpCard: FunctionComponent<EmpCardProps> = ({ data, close }) => {
  return (
    <div className=" bg-white cursor-pointer rounded-2xl pt-8 pb-6 px-6 relative  z-50">
      <X className="absolute top-3 right-3" onClick={() => close()} />
      <div className="flex lg:flex-col gap-4 lg:gap-0">
        <div className=" flex flex-col items-center justify-center">
          {data.profile_image ? (
            <img
              src={data.profile_image}
              alt=""
              className=" w-12 h-12 lg:w-24 lg:h-24 rounded-full"
            />
          ) : (
            <img
              src={"/images/cat.jpeg"}
              alt=""
              className=" w-12 h-12 lg:w-24 lg:h-24 rounded-full object-cover"
            />
          )}
        </div>
        <div className=" flex  flex-col lg:items-center ">
          <div
            className={`text-lg font-semibold lg:pt-4 ${
              data.active ? "dark:text-primaryColor" : "text-gray-500"
            }`}
          >
            {data?.first_name + " "} {data?.last_name}
          </div>
          <div className="text-secondaryColor text-sm">
            {data?.emp_profile?.designation}
          </div>
        </div>
      </div>

      <div className="bg-purple-100  bg-opacity-60 dark:bg-secondaryColor dark:bg-opacity-30 rounded-lg px-6  mt-4 lg:mt-2 py-4 lg:py-8">
        <div className="grid grid-cols-2 gap-16">
          <div className="">
            <div className="text-xs font-semibold text-secondaryColor dark:text-white">
              Department
            </div>
            <div className="font-semibold dark:text-white dark:text-opacity-40">
              {data?.emp_profile?.department}
            </div>
          </div>
          <div className="">
            <div className="text-xs font-semibold text-secondaryColor dark:text-white">
              Joining date
            </div>
            <div className="font-semibold dark:text-white dark:text-opacity-40">
              {moment(data?.emp_profile?.doj).format("ll")}
            </div>
          </div>
        </div>
        <div className="mt-1 lg:mt-3">
          <div className="flex items-center gap-2 text-secondaryColor pt-4">
            <Mail className="" size={16} />
            <div className="text-sm dark:text-white dark:text-opacity-40">
              {data?.email}
            </div>
          </div>

          <div className="flex items-center gap-2 text-secondaryColor pt-2 lg:pt-4">
            <Phone className="" size={16} />
            <div className="text-sm dark:text-white dark:text-opacity-40">
              {data?.mobile}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
