import { FunctionComponent, useEffect, useState } from "react";
import Layout from "../../layout";
// src/service/repos/users_repo.ts
import { GetEmps } from "../../../../../service/repos/users_repo";
import { SubDepartments, zones } from "../../../../../utils/enums";
import { UserModel } from "../../../../../context_providers/auth/user_model";
import HrmPopup from "../../components/HrmPopup";
import { Tooltip } from "@material-ui/core";
import gsap from "gsap/all";

interface EmployeeMapProps {}

const EmployeeMap: FunctionComponent<EmployeeMapProps> = () => {
  const [northEmps, setNorthEmps] = useState<UserModel[]>([]);
  const [southEmps, setSouthEmps] = useState<UserModel[]>([]);
  const [westEmps, setWestEmps] = useState<UserModel[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<UserModel[]>([]);
  const [showSideHover, setShowSideHover] = useState<boolean>(false);

  const getAllEmps = async () => {
    const res = await GetEmps({ all: true, active: true });
    console.log(res);
    if (res.success) {
      const tempNorth = res.data.data.filter(
        (el: UserModel) => el.emp_profile?.zone === zones.north
      );
      const tempSouth = res.data.data.filter(
        (el: UserModel) => el.emp_profile?.zone === zones.south
      );
      const tempWest = res.data.data.filter(
        (el: UserModel) => el.emp_profile?.zone === zones.west
      );
      setNorthEmps(tempNorth);
      setSouthEmps(tempSouth);
      setWestEmps(tempWest);
    } else {
      console.log("nahi aa rha data");
    }
  };

  useEffect(() => {
    getAllEmps();
  }, []);

  return (
    <Layout>
      <div className="bg-white h-screen flex flex-col items-center justify-center relative">
        <div className="bg-black text-white  absolute top-2 left-2 py-6 px-6 rounded-lg">
          <div className="">
            <img src="/kazam-text-logo.png" alt="" className="w-20" />
          </div>
          {showSideHover ? (
            <div className="mt-6 grid grid-cols-4 gap-3">
              {/* <div className="text-sm text-gray-500">Total employees</div>
              <div className="text-xl font-bold mt-2">{modalData.length}</div> */}
              {Object.keys(SubDepartments).map((el) => {
                return (
                  <div className="text-center">
                    <div className="text-sm text-gray-500">{el}</div>

                    <div className="text-xl font-bold mt-1">
                      {modalData.filter((o) => o.emp_profile?.department === el)
                        .length === 0
                        ? "-"
                        : modalData.filter(
                            (o) => o.emp_profile?.department === el
                          ).length}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="grid grid-cols-3 gap-6 mt-6">
              <div className="text-center">
                <div className="text-sm text-gray-500">North zone</div>
                <div className="text-xl font-bold mt-2">{northEmps.length}</div>
              </div>
              <div className="text-center">
                <div className="text-sm text-gray-500">South zone</div>
                <div className="text-xl font-bold mt-2">{southEmps.length}</div>
              </div>
              <div className="text-center">
                <div className="text-sm text-gray-500">West zone</div>
                <div className="text-xl font-bold mt-2">{westEmps.length}</div>
              </div>
            </div>
          )}
        </div>
        <div className="relative" id="map">
          <Tooltip title="Click to view details">
            <div
              className="absolute top-40 left-32 bg-hrmBlue cursor-pointer bg-opacity-30 hover:bg-opacity-50 duration-300 border border-hrmBlue h-16 w-16 rounded-full"
              onMouseEnter={() => {
                setShowSideHover(true);
                setModalData(northEmps);
              }}
              onMouseLeave={() => setShowSideHover(false)}
              onClick={() => {
                setShowModal(true);
                setModalData(northEmps);
              }}
            ></div>
          </Tooltip>
          <Tooltip title="Click to view details">
            <div
              className="absolute top-80 left-20 bg-hrmBlue cursor-pointer bg-opacity-30 hover:bg-opacity-50 duration-300 border border-hrmBlue h-16 w-16 rounded-full"
              onMouseEnter={() => {
                setShowSideHover(true);
                setModalData(westEmps);
              }}
              onMouseLeave={() => setShowSideHover(false)}
              onClick={() => {
                setShowModal(true);
                setModalData(westEmps);
              }}
            ></div>
          </Tooltip>
          <Tooltip title="Click to view details">
            <div
              className="absolute bottom-20 left-32 bg-hrmBlue  cursor-pointer bg-opacity-30 hover:bg-opacity-50 duration-300 border border-hrmBlue h-16 w-16 rounded-full"
              onMouseEnter={() => {
                setShowSideHover(true);
                setModalData(southEmps);
              }}
              onMouseLeave={() => setShowSideHover(false)}
              onClick={() => {
                setShowModal(true);
                setModalData(southEmps);
              }}
            ></div>
          </Tooltip>
          <img src="/images/hrm/analytics/indiaMap.png" alt="" id="image" />
          {/* <div className="text-xl font-bold text-center mt-4">
            Kazam employee distribution
          </div> */}
        </div>
        {showModal && modalData.length > 0 && (
          <HrmPopup>
            <Modal data={modalData} close={() => setShowModal(false)} />
          </HrmPopup>
        )}
      </div>
    </Layout>
  );
};

export default EmployeeMap;

interface ModalProps {
  data: UserModel[];
  close: () => void;
}

const Modal: FunctionComponent<ModalProps> = ({ data, close }) => {
  const [activeDept, setActiveDept] = useState<string>("Sales");
  return (
    <div className="bg-white py-3 px-6 rounded-lg">
      <div className="flex flex-col items-end">
        <div
          className="py-2 px-4 text-sm  cursor-pointer text-gray-600 rounded-md bg-gray-100"
          onClick={() => close()}
        >
          Close X
        </div>
      </div>
      <div className="grid grid-cols-3 lg:flex gap-8 items-center border-b mt-4">
        {Object.keys(SubDepartments).map((el, i) => {
          return (
            <div
              className={` ${
                activeDept === el
                  ? "font-bold border-b-4 border-hrmBlue"
                  : "text-gray-500"
              } cursor-pointer flex items-center gap-1`}
              onClick={() => setActiveDept(el)}
              key={i}
            >
              {el}
              {activeDept === el && (
                <span
                  className={` bg-black w-5 h-5 text-xs text-white mb-3 rounded-full hidden lg:flex flex-col items-center justify-center`}
                >
                  {data.filter((o) => o.emp_profile?.department === el).length}
                </span>
              )}
            </div>
          );
        })}
      </div>
      <div className="overflow-auto  h-96">
        {data.filter((o) => o.emp_profile?.department === activeDept).length >
        0 ? (
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 pt-12 pb-6">
            {data
              .filter((o) => o.emp_profile?.department === activeDept)
              .map((el) => {
                return (
                  <div className="flex items-center gap-4">
                    <div className="">
                      <img
                        src={el.profile_image}
                        alt=""
                        className="w-12 h-12 rounded-full"
                      />
                    </div>
                    <div className="font-semibold">
                      {el.first_name + " " + el.last_name}
                    </div>
                  </div>
                );
              })}
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center h-full">
            <div className="">
              <img src="/images/noData.png" alt="" className="w-48" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
