import { Tooltip } from "@material-ui/core";
// @ts-ignore
import gsap from "gsap/all";
import moment from "moment";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { Mail, Phone, X } from "react-feather";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import { OverviewModel } from "../../../../../service/models/user_model";
import { UserModel } from "../../../../../context_providers/auth/user_model";
import {
  GetOverviewData,
  GetPublicHolidayList,
} from "../../../../../service/repos/hrm/hrm_repo";
import { GetEmpDetails } from "../../../../../service/repos/users_repo";
import HrmPopup from "../../components/HrmPopup";
import HrmLoader from "../../components/loading";
import EmpCard from "../../dept-employees/components/EmpCard";
import { LeaveType } from "../../../../../utils/enums";

interface WeekOffProps {}

const defaultValue: OverviewModel = {
  next_day_off_emp: [],
  offDuty: 0,
  onDuty: 0,
  today_off_emp: [],
  wfh_emp: [],
  onWFH: 0,
  next_day_wfh_emp: [],
  next_day_onWFH: 0,
};
const WeekOff: FunctionComponent<WeekOffProps> = () => {
  const [data, setData] = useState<OverviewModel>(defaultValue);
  const [showCard, setShowCard] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isHolidayToday, setIsHolidayToday] = useState<boolean>(false);
  const [emp, setEmp] = useState<UserModel>();
  const [onLeaveCount, setOnLeaveCount] = useState<number>(0);
  const [onWfh, setOnWfh] = useState<number>(0);
  const [showLeaveModal, setShowLeaveModal] = useState<boolean>(false);
  const [showLeave, setShowLeave] = useState<boolean>(true);
  const leaves = [
    LeaveType.paid_leave,
    LeaveType.medicle_leave,
    LeaveType.unpaid_leave,
    LeaveType.work_from_home,
    LeaveType.restricted_holiday,
  ];

  const { showToast } = useToast();

  const getData = async () => {
    const res = await GetOverviewData();
    if (res.success) {
      setData(res.data);
      if (res.data.offDuty > 4) {
        setOnLeaveCount(res.data.offDuty - 4);
      }
      if (res.data.onWFH > 4) {
        setOnWfh(res.data.onWFH - 4);
      }
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
  };

  const date = new Date();

  const getHolidayList = async () => {
    const res = await GetPublicHolidayList({
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    });

    if (res.success) {
      for (let i = 0; i < res.data.length; i++) {
        const el = res.data[i];

        if (
          moment(date.getTime()).format("DD MM YY") ===
            moment(el.date).format("DD MM YY") &&
          !leaves.includes(el.holiday_name)
        ) {
          // setIsHolidayToday(true);
        }
      }
    }
  };

  useEffect(() => {
    getHolidayList();
  }, []);

  const getEmpData = async (uid: string) => {
    setShowCard(true);
    setLoading(true);
    const res = await GetEmpDetails(uid);
    if (res.success) {
      setEmp(res.data);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };

  // useEffect(() => {
  //   getEmpData();
  // }, []);

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {isHolidayToday ? (
        <div className="  bg-white w-full h-full rounded-2xl flex flex-col items-center justify-center">
          <div className="flex flex-col items-center text-gray-400">
            Holiday, everyone's on leave!
            <div className="mt-1">
              {/* @ts-ignore */}
              <lord-icon
                src="https://cdn.lordicon.com/fcoczpqi.json"
                trigger="loop"
                delay="1000"
                style={{ width: "80px", height: "80px" }}
              >
                {/* @ts-ignore */}
              </lord-icon>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h-full bg-white dark:bg-hrmCardDark py-4 px-3 rounded-2xl mt-3 lg:mt-0">
          <div className="">
            <div className="font-semibold dark:text-white">On leave today</div>

            <div className="">
              {data?.today_off_emp.length > 0 ? (
                <div className="grid grid-cols-5 gap-y-2 mt-2 w-4/5">
                  {data?.today_off_emp?.slice(0, 4).map((emp, i) => {
                    return (
                      <Tooltip title={emp?.first_name + " " + emp?.last_name}>
                        <div
                          className="cursor-pointer"
                          key={i}
                          onClick={() => getEmpData(emp.uid)}
                        >
                          <img
                            src={emp.profile_image}
                            alt=""
                            className="w-12 h-12 rounded-full"
                          />{" "}
                        </div>
                      </Tooltip>
                    );
                  })}
                  {onLeaveCount > 0 && (
                    <div
                      className="w-12 h-12 rounded-full border border-gray-500 font-bold flex flex-col items-center justify-center hover:bg-black hover:text-white duration-300 transform cursor-pointer"
                      onClick={() => {
                        setShowLeave(true);
                        setShowLeaveModal(true);
                      }}
                    >
                      + {onLeaveCount}
                    </div>
                  )}
                </div>
              ) : (
                <div className=" mt-3 text-sm dark:text-primaryColor">
                  No one is on leave
                </div>
              )}
            </div>
          </div>
          {data?.wfh_emp.length > 0 && (
            <div className="pt-20">
              <div className="dark:text-white">Work from home</div>

              <div className="">
                <div className="grid grid-cols-5 gap-y-2 mt-2 w-4/5">
                  {data?.wfh_emp?.slice(0, 4).map((emp, i) => {
                    return (
                      <Tooltip title={emp?.first_name + " " + emp?.last_name}>
                        <div
                          className="relative cursor-pointer"
                          key={i}
                          onClick={() => getEmpData(emp.uid)}
                        >
                          <img
                            src={emp.profile_image}
                            alt=""
                            className="w-12 h-12 rounded-full"
                          />{" "}
                        </div>
                      </Tooltip>
                    );
                  })}
                  {onWfh > 0 && (
                    <div
                      className="w-12 h-12 rounded-full border border-gray-500 font-bold flex flex-col items-center justify-center hover:bg-black hover:text-white duration-300 transform cursor-pointer"
                      onClick={() => {
                        setShowLeave(false);
                        setShowLeaveModal(true);
                      }}
                    >
                      + {onWfh}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {showCard && (
        <HrmPopup>
          {loading ? (
            <HrmLoader className="w-20 h-20" />
          ) : (
            emp && (
              <div className="w-4/5 lg:w-1/4">
                <div className="flex flex-col items-end">
                  <X
                    onClick={() => setShowCard(false)}
                    className="text-white cursor-pointer"
                    size={32}
                  />
                </div>
                <EmpCard emp={emp} />
              </div>
            )
          )}
        </HrmPopup>
      )}
      {showLeaveModal && (
        <HrmPopup>
          <LeaveModal
            data={data}
            showOnLeave={showLeave}
            handleShowLeave={(o: boolean) => setShowLeave(o)}
            close={() => setShowLeaveModal(false)}
            showModal={showLeaveModal}
          />
        </HrmPopup>
      )}
    </>
  );
};

export default WeekOff;

interface LeaveModalProps {
  data: OverviewModel;
  showOnLeave: boolean;
  handleShowLeave: (o: boolean) => void;
  close: () => void;
  showModal: boolean;
}

const LeaveModal: FunctionComponent<LeaveModalProps> = ({
  data,
  showOnLeave,
  handleShowLeave,
  close,
  showModal,
}) => {
  return (
    <>
      <div className="bg-white relative w-11/12 lg:w-auto rounded-lg">
        <div className="flex flex-col items-end ">
          <div className="py-2 px-2 cursor-pointer" onClick={() => close()}>
            <X className="" />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-x-2 lg:gap-x-12 mt-2 lg:mt-6 px-6">
          <div
            className={`${
              showOnLeave
                ? "font-bold text-hrmBlue border-b-4 border-hrmBlue"
                : ""
            } pb-1 cursor-pointer `}
            onClick={() => handleShowLeave(true)}
          >
            On leave today
          </div>
          <div
            className={`${
              !showOnLeave
                ? "font-bold text-hrmBlue border-b-4 border-hrmBlue"
                : ""
            } pb-1 cursor-pointer `}
            onClick={() => handleShowLeave(false)}
          >
            Work from home
          </div>
        </div>
        {showOnLeave ? (
          <div className="overflow-y-auto h-72 px-6 pb-2">
            {data.today_off_emp.length > 0 ? (
              data.today_off_emp.map((el, i) => {
                return (
                  <div className="flex gap-8 items-center mt-4" key={i}>
                    <div className="">
                      <img
                        src={el.profile_image}
                        alt=""
                        className="w-12 h-12 rounded-full"
                      />
                    </div>
                    <div className="">{el.first_name + " " + el.last_name}</div>
                  </div>
                );
              })
            ) : (
              <div className="pt-12 text-center text-sm text-black text-opacity-50">
                No one is on leave
              </div>
            )}
          </div>
        ) : (
          <div className="overflow-y-auto h-72 px-6 pb-2">
            {data.wfh_emp.length > 0 ? (
              data.wfh_emp.map((el, i) => {
                return (
                  <div className="flex gap-8 items-center mt-4" key={i}>
                    <div className="">
                      <img
                        src={el.profile_image}
                        alt=""
                        className="w-12 h-12 rounded-full"
                      />
                    </div>
                    <div className="">{el.first_name + " " + el.last_name}</div>
                  </div>
                );
              })
            ) : (
              <div className="pt-12 text-center text-sm text-black text-opacity-50">
                No one is working from home
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
