import { FunctionComponent, useState } from "react";
import { VehicleModel } from "../../../../../infrastructure/models/car_model";
import { formToObject } from "../../../../../infrastructure/utils/formDataToJson";
import { compressImage } from "../../../../../infrastructure/utils/image_compressor";
import { useAppSelector, useAppDispatch } from "../../../../../store/hooks";
import { vehicleDetailChanged } from "../../../../../store/vehicle_process_reducer";
import { VPVehicleDetailSubmitThunk } from "../../../../../store/vehicle_process_reducer/actions";
import FileDropZone from "../../../../new_components/common/file_drop_widget";
import ToggleButton from "../../../../new_components/form_components/toggle_button";

interface VehicleDetailSectionProps {
  createVehicle: (data: FormData) => void;
  data?: VehicleModel;
  // vehicleModel?: VehicleModel;
}

const VehicleDetailSection: FunctionComponent<VehicleDetailSectionProps> = (
  props
) => {
  // const VPState = useAppSelector((state) => state.vehicleProcessReducer);
  // const dispatch = useAppDispatch();
  const [image, setImage] = useState<File>();
  const [active, setActive] = useState(props.data ? props.data.active : true);
  const [data, setData] = useState<VehicleModel | undefined>(
    props.data ? { ...props.data } : undefined
  );
  return (
    <>
      <div className="rounded shadow grid grid-cols-1 md:grid-cols-2 gap-3 p-5 m-5">
        <div className="flex flex-col">
          <label htmlFor="image" className=" text-lg">
            Display image
          </label>
          {data?.feature_image || image ? (
            <div className=" relative">
              <img
                alt="installation"
                className="h-auto w-full rounded"
                src={
                  image ? URL.createObjectURL(image) : data?.feature_image!.url
                }
              ></img>
              <div
                onClick={() => {
                  setImage(undefined);
                  if (data && data.feature_image) {
                    delete data.feature_image;
                    setData({ ...data });
                  }
                }}
                className="absolute top-0 right-0  flex justify-center items-center p-3 bg-gray-100 rounded-full  cursor-pointer "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-red-400 transform transition-all duration-300 hover:text-red-700 hover:scale-125"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              </div>
            </div>
          ) : (
            <FileDropZone
              required={true}
              className="h-full w-full"
              onChange={(files) => setImage(files[0])}
              multiSelect={false}
              type="image/*"
            />
          )}
        </div>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            if (!props.data?.feature_image && !image) {
              alert("Please select image");
              return;
            }
            const formData = new FormData();
            const d = formToObject(new FormData(e.currentTarget));

            d["active"] = active;
            if (image) {
              const compressedImage = await compressImage(image);
              formData.append("display_image", compressedImage);
            }
            if (props.data) {
              d["id"] = props.data.id;
              d["feature_image"] = props.data.feature_image;
            }

            d.category = d.category.toLowerCase();
            d.segment_type = d.segment_type.toLowerCase();
            formData.append("data", JSON.stringify(d));

            props.createVehicle(formData);

            // dispatch(VPVehicleDetailSubmitThunk(image!));
          }}
          className=""
        >
          <div className="mb-2 flex justify-end">
            <div className="text-lg mr-2">Status : </div>
            <ToggleButton
              onToggle={
                (v) => setActive(v)
                // dispatch(vehicleDetailChanged({ key: "active", value: v }))
              }
              value={active}
              trueLabel="Live"
              falseLabel="Offline"
            />
          </div>
          <div className=" grid grid-cols-1 md:grid-cols-2 gap-3">
            <div className="flex flex-col">
              <label htmlFor="brand" className=" text-lg">
                Brand
              </label>
              <input
                defaultValue={props.data?.brand}
                className="focus:outline-none p-2 bg-gray-100 rounded"
                type="text"
                name="brand"
                id="brand"
                required
                placeholder="Enter the Brand name"
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="model" className=" text-lg">
                Model
              </label>
              <input
                defaultValue={props.data?.model}
                className="focus:outline-none p-2 bg-gray-100 rounded"
                type="text"
                name="model"
                id="model"
                placeholder="Enter the model name"
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="category" className=" text-lg">
                Category
              </label>
              <select
                defaultValue={props.data?.category}
                className="focus:outline-none p-2 bg-gray-100 rounded"
                name="category"
                id="category"
              >
                {[
                  { label: "Bike", value: "bike" },
                  { label: "Car", value: "car" },
                  { label: "3-wheeler", value: "3-wheeler" },
                ].map((op, i) => (
                  <option value={op.value}>{op.label}</option>
                ))}
              </select>
              {/* <input
                defaultValue={props.data?.category}
                className="focus:outline-none p-2 bg-gray-100 rounded"
                type="text"
                name="category"
                id="category"
                required
                placeholder="Bike/car "
              /> */}
            </div>
            <div className="flex flex-col">
              <label htmlFor="segment" className=" text-lg">
                Segment
              </label>
              <input
                defaultValue={props.data?.segment_type}
                className="focus:outline-none p-2 bg-gray-100 rounded"
                type="text"
                name="segment_type"
                id="segment"
                required
                placeholder="Enter segment type"
              />
            </div>
            {/* <div className="flex flex-col">
              <label htmlFor="category" className=" text-lg">
                Category
              </label>
              <select
                defaultValue={props.data?.category}
                className="focus:outline-none p-2 bg-gray-100 rounded"
                name="category"
                id="category"
                required
              >
                <option value="">Select</option>
                <option value="bike">Bike</option>
                <option value="car">Car</option>
              </select>
            </div>
            <div className="flex flex-col">
              <label htmlFor="segment" className=" text-lg">
                Segment
              </label>
              <select
                defaultValue={props.data?.segment_type}
                className="focus:outline-none p-2 bg-gray-100 rounded"
                name="segment_type"
                id="segment"
                required
              >
                <option value="">Select</option>
                <option value="bike">Bike</option>
                <option value="scooter">Scooter</option>
              </select>
            </div> */}
            <div className="flex flex-col">
              <label htmlFor="price" className=" text-lg">
                Display Price
              </label>
              <input
                defaultValue={props.data?.display_price}
                className="focus:outline-none p-2 bg-gray-100 rounded"
                type="number"
                name="display_price"
                id="price"
                required
                placeholder="Enter the display price"
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="description" className=" text-lg">
                Description
              </label>
              <textarea
                defaultValue={props.data?.description}
                rows={5}
                className="focus:outline-none p-2 bg-gray-100 rounded"
                name="description"
                id="description"
                placeholder="Enter description"
              />
            </div>
          </div>
          <div className="flex justify-center my-5">
            <button
              className="px-5 py-2 mx-2 bg-red-400 text-white rounded-3xl hover:bg-red-800"
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default VehicleDetailSection;
