import { FunctionComponent, memo, useEffect, useState } from "react";
import { ChevronLeft, Heart, Mail, Trash2, X } from "react-feather";
import { MemoryGetModel } from "../../../../../service/models/user_model";
import { UserModel } from "../../../../../context_providers/auth/user_model";
import Layout from "../../layout";
// @ts-ignore
import gsap from "gsap/all";
// @ts-ignore
import { ScrollTrigger } from "gsap/all";
import MentionTextFieldWidget from "../../../orders/order_list/components/mention_text_field";
import HrmPopup from "../../components/HrmPopup";
import { useHistory } from "react-router-dom";
import {
  DeleteMemory,
  DeleteMemoryComment,
  GetMemoryComment,
  PostMemoryComment,
  PostMemoryLike,
  PostMemoryUnlike,
} from "../../../../../service/repos/hrm/hrm_repo";
import HrmLoader from "../../components/loading";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import { GetEmpDetails } from "../../../../../service/repos/users_repo";
import { mdiEmail, mdiPhoneInTalk } from "@mdi/js";
import Icon from "@mdi/react";
import { useAuth } from "../../../../../context_providers/auth/authProvider";
import { urls } from "../../../../../utils/urls";

interface MemoryViewProps {
  location: any;
}

const MemoryView: FunctionComponent<MemoryViewProps> = (props) => {
  const [memory, setMemory] = useState<MemoryGetModel>(props.location.state);
  const { user, isAuthorised } = useAuth();

  const [comment, setComment] = useState<string>("");
  const [showLikes, setShowLikes] = useState<boolean>(false);
  const [key, setKey] = useState(1);
  const { showToast } = useToast();
  const [profileData, setProfileData] = useState<UserModel>();
  const [profile, setProfile] = useState<boolean>(false);

  const [comments, setComments] = useState<
    {
      id: string;
      comment: string;
      uid: string;
      username: string;
      profile_image: string;
      created_at: number;
    }[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [liked, setLiked] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (comment === "") {
      setKey(Math.random());
    }
  }, [comment]);

  const array = [1, 1, 1, 1, 1, 1, 1, 1];

  const findDate = (d: number) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    const date = new Date(d);
    return date.getDate() + " " + months[date.getMonth()];
  };

  const checkLiked = () => {
    const temp = memory?.likes?.find((el: any) => el.uid === user?.uid);

    if (temp) {
      setLiked(true);
    }
  };

  useEffect(() => {
    checkLiked();
  }, [memory?.likes]);

  const getComment = async (id: string) => {
    const res = await GetMemoryComment(id);

    if (res.success) {
      setComments(res.data.comments);
    }
  };

  const postComment = async (id: string, c: string) => {
    if (c === "") {
      showToast({ type: ToastType.error, text: "Write a comment to post" });
    } else {
      setLoading(true);
      const tempComment = {
        comment: c,
      };

      const res = await PostMemoryComment(id, tempComment);

      if (res.success) {
        setComment("");
        setComments((o) => [res.data, ...o]);
        setLoading(false);
      } else {
        showToast({ type: ToastType.error, text: res.error ?? "" });
      }
    }
  };

  const deleteComment = async (id: string, c_id: string) => {
    const res = await DeleteMemoryComment(id, c_id);

    if (res.success) {
      const tempComments = comments.filter((el) => el.id !== c_id);

      setComments(tempComments);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
  };

  const deletePost = async (id: string) => {
    const res = await DeleteMemory(id);

    if (res.success) {
      history.goBack();
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
  };

  const addLike = async (id: string) => {
    setLiked(true);
    const res = await PostMemoryLike(id, { post_id: id });
    console.log(res);

    if (res.success) {
      const tempObj = {
        uid: user?.uid,
        name: user?.first_name + " " + user?.last_name,
        img: user?.profile_image,
      };

      setMemory({ ...memory, likes: [...memory.likes, tempObj] });
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
  };

  const unLike = async (id: string) => {
    setLiked(false);
    const res = await PostMemoryUnlike(id, { post_id: id });
    console.log(res);

    if (res.success) {
      const tempLikes = memory.likes.filter((el: any) => el.uid !== user?.uid);
      setMemory({ ...memory, likes: tempLikes });
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
  };

  const getProfileData = async (uid: string) => {
    const res = await GetEmpDetails(uid);
    console.log(res.data.employee);

    setProfileData(res.data.employee);
  };

  const animateData = () => {
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.create({
      trigger: ".image_animate",
      pin: true,
    });
  };

  useEffect(() => {
    animateData();
    getComment(memory.id);
    getProfileData(memory.uid);
  }, []);

  return (
    <Layout>
      <div className="flex flex-col lg:flex-row">
        <div className="lg:image_animate relative lg:w-2/3 bg-white dark:bg-black flex flex-col items-center justify-center py-12  lg:h-screen">
          <div
            className="absolute top-5 left-5 w-10 h-10 lg:w-16  lg:h-16 flex flex-col items-center justify-center bg-hrmRed bg-opacity-20 rounded-full cursor-pointer"
            onClick={() => history.push(urls.hrm_memories)}
          >
            <ChevronLeft size={28} className="text-hrmRed" />
          </div>
          {memory.uid === user?.uid && (
            <button
              className="absolute top-5 right-5  bg-hrmRed bg-opacity-80 text-white py-2 px-6 rounded-md font-semibold"
              onClick={() => {
                var prompt = window.confirm(
                  "Are you sure you want to delete your post?"
                );
                if (prompt) {
                  deletePost(memory.id);
                }
              }}
            >
              Delete post
            </button>
          )}
          <div className="w-3/5 lg:w-4/5 h-full">
            <img
              src={memory.files[0].url}
              alt=""
              className="w-full h-full object-contain"
            />
          </div>
        </div>
        {/* memory right view */}
        <div className="lg:w-1/3 relative pt-4 lg:pt-0">
          <div className="  pt-4 px-3">
            <div className="flex gap-4">
              <div className="flex-shrink-0 w-12 h-12 relative">
                <img
                  src={memory.img}
                  alt=""
                  className="w-full h-full rounded-full cursor-pointer"
                  onClick={() => setProfile(true)}
                />
              </div>
              <div className="">
                <div className="flex gap-4 justify-between items-center">
                  <div
                    className="font-semibold dark:text-primaryColor text-sm cursor-pointer"
                    onClick={() => setProfile(true)}
                  >
                    {memory.name}
                  </div>
                  <div className="text-sm font-semibold text-secondaryColor dark:text-primaryColor ">
                    {findDate(memory.created_at)}
                  </div>
                </div>
                <div className="dark:text-white">{memory.description}</div>
                <div className="flex gap-2 items-center mt-2">
                  {liked ? (
                    <Heart
                      size={24}
                      fill="#FD5440"
                      className=" text-hrmRed cursor-pointer"
                      onClick={() => unLike(memory.id)}
                    />
                  ) : (
                    <Heart
                      size={24}
                      className=" text-hrmRed cursor-pointer"
                      onClick={() => addLike(memory.id)}
                    />
                  )}
                  <div
                    className="dark:text-white cursor-pointer font-semibold"
                    onClick={() => setShowLikes(true)}
                  >
                    {memory.likes.length} likes
                  </div>
                  {/* {findDate(view.created_at)} */}
                </div>
              </div>
            </div>
          </div>
          <div className="my-6 lg:my-0  overflow-y-auto relative">
            <div className="pl-3 sticky top-0 bg-hrmBgLight">
              <div className="text-sm text-secondaryColor">Comments</div>
              <div className="w-2/3 h-px bg-primaryColor dark:bg-secondaryColor"></div>
            </div>
            {comments.length > 0 ? (
              <div className="pl-3 pt-3">
                {/* Comment */}
                {comments.map(
                  (
                    el: {
                      id: string;
                      comment: string;
                      uid: string;
                      username: string;
                      profile_image: string;
                      created_at: number;
                    },
                    i: number
                  ) => {
                    return (
                      <div className="flex gap-3 mb-8">
                        <div className="w-8 h-8 rounded-full flex-shrink-0">
                          <img
                            src={el.profile_image}
                            alt=""
                            className="w-full h-full rounded-full"
                          />
                        </div>
                        <div className="">
                          <div className="text-sm dark:text-white font-semibold">
                            {el.username}
                          </div>
                          <div className="text-sm dark:text-primaryColor">
                            {el.comment}
                          </div>
                        </div>
                        {el.uid === user?.uid && (
                          <div className="pt-1">
                            <Trash2
                              size={16}
                              className="text-hrmRed cursor-pointer"
                              onClick={() => {
                                var prompt = window.confirm(
                                  "Are you sure you want to delete your comment?"
                                );
                                if (prompt) {
                                  deleteComment(memory.id, el.id);
                                }
                              }}
                            />
                          </div>
                        )}
                      </div>
                    );
                  }
                )}
              </div>
            ) : (
              <div className="pl-3 pt-3 text-primaryColor">
                <div className="text-sm">No comments</div>
              </div>
            )}
          </div>

          <div className="lg:fixed  bottom-0 right-6 lg:w-1/4  ">
            <MentionTextFieldWidget
              placeholder="Comment and tag user with @"
              disabled={false}
              key={key}
              value={comment}
              onChange={(e) => {
                setComment(e);
              }}
              className="h-full bg-white"
            />

            <div className="flex flex-col items-center">
              {!loading ? (
                <button
                  className="py-3 px-6 rounded bg-hrmRed text-white font-semibold w-full"
                  onClick={() => postComment(memory.id, comment)}
                >
                  Post comment
                </button>
              ) : (
                <div className="py-3 px-6 rounded bg-hrmRed text-white font-semibold w-full flex flex-col items-center justify-center">
                  <HrmLoader className="w-6 h-6" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {showLikes && (
        <HrmPopup>
          <div className="relative bg-white pt-8 pb-4 px-8 rounded-xl">
            <div className="">
              <X
                className="absolute top-3 right-3 cursor-pointer"
                size={24}
                onClick={() => setShowLikes(false)}
              />
            </div>
            <div className="text-lg font-semibold">Likes</div>
            <div className="h-64 overflow-y-auto">
              {memory.likes?.map((el, i) => {
                return (
                  <div className="flex gap-12 items-center mt-4 px-4">
                    <div className="w-10 h-10">
                      <img
                        src={el.img}
                        alt=""
                        className="w-full h-full rounded-full flex-shrink-0"
                      />
                    </div>
                    <div className="">{el.name}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </HrmPopup>
      )}
      {profile && (
        <HrmPopup>
          <div className="bg-hrmCardDark py-6 px-6 rounded-2xl w-4/5 lg:w-1/4 relative">
            <div className="">
              <X
                className="absolute top-4 right-4 text-white cursor-pointer"
                size={28}
                onClick={() => setProfile(false)}
              />
            </div>
            <div className="">
              <img
                src={profileData?.profile_image}
                alt=""
                className="w-28 h-28 rounded-full border-4 border-white border-opacity-20"
              />
            </div>

            <div className="text-xl text-white mt-8">
              {profileData?.first_name + " " + profileData?.last_name}
            </div>
            <div className="mt-1 text-primaryColor">
              {profileData?.emp_profile?.designation}
            </div>
            <div className="text-sm flex gap-4 items-center mt-4">
              <div className="text-secondaryColor">Line manager</div>
              <div className="text-primaryColor">
                {profileData?.emp_profile?.reporting_to_name}
              </div>
            </div>

            <div className="mt-6 flex items-center gap-4">
              <a href={`mailto:${profileData?.email}`}>
                <Icon path={mdiEmail} className="w-8 h-8 text-red-700" />
              </a>
              <a href={`tel:+91${profileData?.mobile}`}>
                <Icon path={mdiPhoneInTalk} className="w-8 h-8 text-blue-700" />
              </a>
            </div>
          </div>
        </HrmPopup>
      )}
    </Layout>
  );
};

export default MemoryView;
