import { RouteParam } from "../../../routes";
import { ACTION, MODULE } from "../../../utils/enums";
import { urls } from "../../../utils/urls";
import ItemListScreen from "../items/item_list_screen";
import ItemGroupListScreen from "../item_group/item_group_list_screen";
import ItemUnitCreateScreen from "../item_unit/item_unit_create_screen";
import ItemUnitListScreen from "../item_unit/item_unit_list_screen";

const item_unit_routes: RouteParam[] = [
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.procurement_masters_item_unit,
    component: ItemUnitListScreen,
    module: MODULE.procurement_master,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.procurement_masters_item_unit + "/create",
    component: ItemUnitCreateScreen,
    module: MODULE.procurement_master,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.procurement_masters_item_unit + "/update/:id",
    component: ItemUnitCreateScreen,
    module: MODULE.procurement_master,
    main_module: MODULE.procurement_panel,
    action: [ACTION.UPDATE],
    operator: "or",
  },
];

export default item_unit_routes;
