import { Category } from "@material-ui/icons";
import { FunctionComponent } from "react";
import { VehicleVarient } from "../../../../../infrastructure/models/car_model";
import GenerateForm, {
  FormFieldModel,
  InputFields,
} from "../../../../new_components/common/form/form";

interface CreateVarientProps {
  varient?: VehicleVarient;
  onSubmit: (data: any) => void;
  category: string;
}

const CreateVarient: FunctionComponent<CreateVarientProps> = (props) => {
  const getFields = (category: string) => {
    switch (category) {
      case "bike":
        return bikeVarientFields;
      case "2-wheeler":
        return bikeVarientFields;
      case "car":
        return carVarientsFields;
      case "4-wheeler":
        return carVarientsFields;

      default:
        return [];
    }
  };
  return (
    <>
      <div className="flex">{props.category}</div>
      <GenerateForm
        formFields={getFields(props.category.toLocaleLowerCase())}
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-col-3 xl:grid-cols-4 gap-x-2 gap-y-5 p-2"
        values={{}}
        onSubmit={function (data: { [key: string]: any }): void {
          props.onSubmit(data);
        }}
        onFormValueChanged={function (e: any): void {}}
        defaultValue={
          props.varient
            ? {
                ...props.varient?.specification,
                title: props.varient?.title,
                active: props.varient?.active,
                release_date: props.varient?.release_date
                  ? new Date(props.varient?.release_date * 1000)
                      .toISOString()
                      .split("T")[0]
                  : "",
              }
            : {}
        }
      />
    </>
  );
};

export default CreateVarient;

export const bikeVarientFields: FormFieldModel[] = [
  {
    inputType: InputFields.TEXT,
    name: "title",
    label: "Varient title",
    placeholder: "Enter varient title",
    alignment: "col",
  },
  {
    inputType: InputFields.DATE,
    name: "release_date",
    label: "Release Date",

    alignment: "col",
  },
  {
    inputType: InputFields.NUMBER,
    name: "range",
    label: "Range",
    placeholder: "Enter range in km/full charge",
    alignment: "col",
  },
  {
    inputType: InputFields.NUMBER,
    name: "max_speed",
    label: "Max speed",
    placeholder: "Enter Max speed",
    alignment: "col",
  },
  {
    inputType: InputFields.NUMBER,
    name: "length",
    label: "Length",
    placeholder: "Enter length",
    alignment: "col",
  },
  {
    inputType: InputFields.NUMBER,
    name: "width",
    label: "Width",
    placeholder: "Enter width",
    alignment: "col",
  },
  {
    inputType: InputFields.NUMBER,
    name: "height",
    label: "Height",
    placeholder: "Enter height",
    alignment: "col",
  },
  {
    inputType: InputFields.NUMBER,
    name: "saddle_height",
    label: "Saddle height",
    placeholder: "Enter saddle height",
    alignment: "col",
  },
  {
    inputType: InputFields.NUMBER,
    name: "ground_clearance",
    label: "Ground clearance",
    placeholder: "Enter Ground clearance",
    alignment: "col",
  },
  {
    inputType: InputFields.NUMBER,
    name: "wheeles",
    label: "Wheeles",
    placeholder: "Enter number of wheeles",
    alignment: "col",
  },
  {
    inputType: InputFields.NUMBER,
    name: "wheels_type",
    label: "Wheeles type",
    placeholder: "Enter wheeles type",
    alignment: "col",
  },
  {
    inputType: InputFields.TEXT,
    name: "fuel_type",
    label: "Fuel type",
    placeholder: "Enter fuel type",
    alignment: "col",
  },
  {
    inputType: InputFields.NUMBER,
    name: "kerb_weight",
    label: "kerb weight",
    placeholder: "Enter kerb weight",
    alignment: "col",
  },
  {
    inputType: InputFields.NUMBER,
    name: "gradeability",
    label: "Gradeability",
    placeholder: "Enter gradeability",
    alignment: "col",
  },
  {
    inputType: InputFields.NUMBER,
    name: "motor_ip_rating",
    label: "Motor ip rating",
    placeholder: "Enter Motor ip rating",
    alignment: "col",
  },
  {
    inputType: InputFields.NUMBER,
    name: "battery_ip_rating",
    label: "Battery ip rating",
    placeholder: "Enter battery ip rating",
    alignment: "col",
  },
  {
    inputType: InputFields.NUMBER,
    name: "motor_power",
    label: "Motor power",
    placeholder: "Enter motor power",
    alignment: "col",
  },
  {
    inputType: InputFields.TEXT,
    name: "motor_type",
    label: "Motor type",
    placeholder: "Enter motor type",
    alignment: "col",
  },
  {
    inputType: InputFields.NUMBER,
    name: "battery_type",
    label: "Battery type",
    placeholder: "Enter Battery type",
    alignment: "col",
  },
  {
    inputType: InputFields.NUMBER,
    name: "battery_capacity",
    label: "Battery capacity",
    placeholder: "Enter Battery capacity in amph",
    alignment: "col",
  },
  {
    inputType: InputFields.TEXT,
    name: "charging_time",
    label: "Charging time",
    placeholder: "Enter Charging time",
    alignment: "col",
  },
  {
    inputType: InputFields.RADIO,
    name: "fast_charging",
    label: "Fast charging",
    options: [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
    alignment: "col",
  },
  {
    inputType: InputFields.TEXT,
    name: "max_power",
    label: "Max power",
    placeholder: "Enter max power",
    alignment: "col",
  },
  {
    inputType: InputFields.TEXT,
    name: "max_torque",
    label: "Max torque",
    placeholder: "Enter max torque",
    alignment: "col",
  },
  {
    inputType: InputFields.TEXT,
    name: "transmission_type",
    label: "Transmission type",
    placeholder: "Enter Transmission type",
    alignment: "col",
  },

  {
    inputType: InputFields.NUMBER,
    name: "quarter_mile",
    label: "Quarter mile",
    placeholder: "Enter quarter mile",
    alignment: "col",
  },
  {
    inputType: InputFields.TEXT,
    name: "front_brake_type",
    label: "Front brake type",
    placeholder: "Enter Front brake type",
    alignment: "col",
  },
  {
    inputType: InputFields.TEXT,
    name: "rear_brake_type",
    label: "Rear brake type",
    placeholder: "Enter rear brake type",
    alignment: "col",
  },
  {
    inputType: InputFields.TEXT,
    name: "chassis",
    label: "Chassis",
    placeholder: "Enter chassis type",
    alignment: "col",
  },
  {
    inputType: InputFields.TEXT,
    name: "front_suspension",
    label: "Front suspension",
    placeholder: "Enter front suspension",
    alignment: "col",
  },
  {
    inputType: InputFields.TEXT,
    name: "rear_suspension",
    label: "Rear suspension",
    placeholder: "Enter rear suspension",
    alignment: "col",
  },
  {
    inputType: InputFields.TEXT,
    name: "starting_type",
    label: "Starting type",
    placeholder: "Enter starting type",
    alignment: "col",
  },
  {
    inputType: InputFields.TEXT,
    name: "console",
    label: "Console",
    placeholder: "Enter console",
    alignment: "col",
  },
  {
    inputType: InputFields.TEXT,
    name: "odometer",
    label: "Odometer",
    placeholder: "Enter odometer",
    alignment: "col",
  },
  {
    inputType: InputFields.TEXT,
    name: "speedometer",
    label: "Speedometer",
    placeholder: "Enter speedometer",
    alignment: "col",
  },
  {
    inputType: InputFields.RADIO,
    name: "clock",
    label: "Clock",
    options: [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
    alignment: "col",
  },
  {
    inputType: InputFields.RADIO,
    name: "display",
    label: "Display",
    options: [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
    alignment: "col",
  },
  {
    inputType: InputFields.RADIO,
    name: "navigation",
    label: "Navigation",
    options: [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
    alignment: "col",
  },
  {
    inputType: InputFields.RADIO,
    name: "passenger_footrest",
    label: "Passenger footrest",
    options: [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
    alignment: "col",
  },
  {
    inputType: InputFields.NUMBER,
    name: "riding_modes",
    label: "Riding modes",
    placeholder: "Enter riding modes",
    alignment: "col",
  },
];

export const carVarientsFields: FormFieldModel[] = [
  {
    inputType: InputFields.TEXT,
    name: "varient",
    label: "Varient title",
    placeholder: "Enter Varient name",
    alignment: "col",
  },
  {
    inputType: InputFields.DATE,
    name: "release_date",
    label: "Release date",

    alignment: "col",
  },

  // {
  //   inputType: InputFields.NUMBER,
  //   name: "wheels",
  //   label: "Wheels",
  //   placeholder: "Enter number of wheels",
  //   alignment: "col",
  //
  // },
  // {
  //   inputType: InputFields.TEXT,
  //   name: "wheel_type",
  //   label: "Wheel type",
  //   placeholder: "Enter wheel type",
  //   alignment: "col",
  //
  // },
  {
    inputType: InputFields.NUMBER,
    name: "battery_type",
    label: "Battery type",
    placeholder: "Enter Battery type",
    alignment: "col",
  },
  {
    inputType: InputFields.NUMBER,
    name: "battery_capacity",
    label: "Battery capacity",
    placeholder: "Enter Battery capacity in amph",
    alignment: "col",
  },
  {
    inputType: InputFields.NUMBER,
    name: "motor_power",
    label: "Motor power",
    placeholder: "Enter motor power",
    alignment: "col",
  },
  {
    inputType: InputFields.TEXT,
    name: "motor_type",
    label: "Motor type",
    placeholder: "Enter motor type",
    alignment: "col",
  },
  {
    inputType: InputFields.TEXT,
    name: "charging_time",
    label: "Charging time",
    placeholder: "Enter Charging time",
    alignment: "col",
  },
  {
    inputType: InputFields.RADIO,
    name: "fast_charging",
    label: "Fast charging",
    options: [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
    alignment: "col",
  },
  {
    inputType: InputFields.TEXT,
    name: "body_Type",
    label: "Body type",
    placeholder: "Enter body type",
    alignment: "col",
  },
  {
    inputType: InputFields.TEXT,
    name: "engine_type",
    label: "Engine type",
    placeholder: "Enter engine type",
    alignment: "col",
  },
  {
    inputType: InputFields.TEXT,
    name: "displacement",
    label: "Displacement",
    placeholder: "Enter displacement",
    alignment: "col",
  },
  {
    inputType: InputFields.TEXT,
    name: "max_power",
    label: "Max power",
    placeholder: "Enter max power",
    alignment: "col",
  },
  {
    inputType: InputFields.TEXT,
    name: "max_torque",
    label: "Max torque",
    placeholder: "Enter max torque",
    alignment: "col",
  },
  {
    inputType: InputFields.TEXT,
    name: "transmission_type",
    label: "Transmission type",
    placeholder: "Enter Transmission type",
    alignment: "col",
  },
  {
    inputType: InputFields.TEXT,
    name: "fuel_type",
    label: "Fuel type",
    placeholder: "Enter fuel type",
    alignment: "col",
  },
  {
    inputType: InputFields.NUMBER,
    name: "range",
    label: "Range",
    placeholder: "Enter range in km/full charge",
    alignment: "col",
  },
  {
    inputType: InputFields.NUMBER,
    name: "boot_space",
    label: "Boot space",
    placeholder: "Enter boot space",
    alignment: "col",
  },
  {
    inputType: InputFields.NUMBER,
    name: "seating_capacity",
    label: "Seating capacity",
    placeholder: "Enter seating capacity",
    alignment: "col",
  },
  {
    inputType: InputFields.NUMBER,
    name: "wheel_base",
    label: "Wheel base",
    placeholder: "Enter wheel base",
    alignment: "col",
  },
  {
    inputType: InputFields.RADIO,
    name: "power_steering",
    label: "Power steering",
    options: [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
    alignment: "col",
  },
  {
    inputType: InputFields.RADIO,
    name: "power_windows_front",
    label: "Power windows front",
    options: [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
    alignment: "col",
  },
  {
    inputType: InputFields.RADIO,
    name: "power_windows_rear",
    label: "Power windows rear",
    options: [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
    alignment: "col",
  },
  {
    inputType: InputFields.RADIO,
    name: "power_boot",
    label: "Power boot",
    options: [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
    alignment: "col",
  },
  {
    inputType: InputFields.RADIO,
    name: "air_conditioner",
    label: "Air conditioner",
    options: [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
    alignment: "col",
  },
  {
    inputType: InputFields.RADIO,
    name: "automatic_climate_control",
    label: "Automatic climate control",
    options: [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
    alignment: "col",
  },
  {
    inputType: InputFields.RADIO,
    name: "fog_lights_front",
    label: "Fog lights front",
    options: [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
    alignment: "col",
  },
  {
    inputType: InputFields.RADIO,
    name: "fog_lights_rear",
    label: "Fog lights rear",
    options: [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
    alignment: "col",
  },
  {
    inputType: InputFields.RADIO,
    name: "wheel_covers",
    label: "Wheel covers",
    options: [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
    alignment: "col",
  },
  {
    inputType: InputFields.RADIO,
    name: "antiLock_braking_system",
    label: "Anti-lock braking system",
    options: [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
    alignment: "col",
  },
  {
    inputType: InputFields.RADIO,
    name: "driver_airbag",
    label: "Driver airbag",
    options: [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
    alignment: "col",
  },
  {
    inputType: InputFields.RADIO,
    name: "passenger_airbag",
    label: "Passenger airbag",
    options: [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
    alignment: "col",
  },
  {
    inputType: InputFields.TEXT,
    name: "front_brake_type",
    label: "Front brake type",
    placeholder: "Enter Front brake type",
    alignment: "col",
  },
  {
    inputType: InputFields.TEXT,
    name: "rear_brake_type",
    label: "Rear brake type",
    placeholder: "Enter rear brake type",
    alignment: "col",
  },
];
