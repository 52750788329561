import { Tooltip } from "@material-ui/core";
import { FunctionComponent, useEffect, useState } from "react";
import { AlertTriangle, CheckCircle, ChevronLeft, Trash } from "react-feather";
import { NavLink, useHistory } from "react-router-dom";
import { useAuth } from "../../../../../context_providers/auth/authProvider";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import { AcademyVideoModel } from "../../../../../service/models/academy";
import {
  CreateWatched,
  DeleteUploadVideo,
  GetAcademyVideoData,
  GetCreateWatched,
  GetSaveVideo,
  SaveVideo,
} from "../../../../../service/repos/academy/academy_repo";
import { urls } from "../../../../../utils/urls";
import HrmPopup from "../../../hrm/components/HrmPopup";
import HrmLoader from "../../../hrm/components/loading";
import Sidebar from "../../components/Sidebar";

interface VideoLayoutProps {
  segment: string;
}

const VideoLayout: FunctionComponent<VideoLayoutProps> = ({ segment }) => {
  const [showVideo, setShowVideo] = useState<boolean>(false);
  const [metadata, setMetadata] = useState<any>();
  const [videos, setVideos] = useState<any>();
  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(10);
  const [savedVideos, setSavedVideos] = useState<any>([]);
  const [watchedVideos, setWatchedVideos] = useState<AcademyVideoModel[]>([]);
  const [mainData, setMainData] = useState<AcademyVideoModel>();
  const { user, isAuthorised } = useAuth();
  const [showViewBtn, setShowViewBtn] = useState<boolean>(true);
  const [loadView, setLoadView] = useState<boolean>(false);

  const { showToast } = useToast();
  const history = useHistory();
  // var date = new Date(mainData?.created_at);

  const getUploadData = async (p: number, c: number) => {
    setLoadView(true);
    const data: { page: number; count: number; category: string } = {
      page: p,
      count: c,
      category: segment,
    };
    const res = await GetAcademyVideoData(data);
    console.log(res?.data);

    if (res.success) {
      setMetadata(res.data?.metadata);
      if (videos) {
        if (res.data.videos.length > 0) {
          setVideos((o: any) => [...o, ...res.data?.videos]);
          setLoadView(false);
        } else {
          setShowViewBtn(false);
        }
      } else {
        setMainData(res.data?.videos[0]);
        setVideos(res.data?.videos);
        setLoadView(false);
      }
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
      setLoadView(false);
    }
  };

  const getSavedVideos = async () => {
    const res = await GetSaveVideo(user?.uid);

    if (res.success) {
      setSavedVideos(res.data?.saved_videos);
    }
  };

  const getWatchedVidoes = async () => {
    const res = await GetCreateWatched(user?.uid);

    if (res.success) {
      setWatchedVideos(res.data?.watched_videos);
    }
  };

  const saveVideo = async (id: string) => {
    const d = {
      video_id: id,
    };
    const res = await SaveVideo(d, user?.uid);
    console.log(res);

    if (res.success) {
      setSavedVideos((o: any) => [
        ...o,
        {
          video: {
            id: id,
          },
        },
      ]);
    }
  };

  const createWatched = async (id: string) => {
    const d = {
      video_id: id,
    };
    const res = await CreateWatched(d, user?.uid);
    console.log(res);
    if (res.success) {
      setWatchedVideos((o: any) => [
        ...o,
        {
          video: {
            id: id,
          },
        },
      ]);
    }
  };

  const deleteVideo = async (video_id: string) => {
    const res = await DeleteUploadVideo(video_id);
    console.log(res);
    if (res.success) {
      const temp = videos.filter((el: AcademyVideoModel) => el.id !== video_id);
      setVideos(temp);
      if (temp.length > 0) {
        setMainData(temp[0]);
      }
    }
  };

  const handleMainVideo = (data: AcademyVideoModel) => {
    setMainData(data);
    setShowVideo(true);
  };

  useEffect(() => {
    getUploadData(page, count);
  }, [page]);

  useEffect(() => {
    getSavedVideos();
    getWatchedVidoes();
  }, []);

  return (
    <div className="">
      <Sidebar />
      {videos ? (
        videos.length > 0 ? (
          <div className="lg:ml-20 flex">
            <div className="lg:w-2/5 flex flex-col items-center bg-hrmBgLight lg:h-screen overflow-y-auto">
              <div className="w-11/12 ">
                <div className="flex justify-between items-center pt-8">
                  <div className="text-2xl lg:text-xl font-semibold ">
                    {segment}
                  </div>
                  <div className="lg:hidden">
                    <NavLink to={urls.videos_category}>
                      <ChevronLeft size={32} />
                    </NavLink>
                  </div>
                </div>

                <div className="mb-12 lg:mb-0">
                  {videos?.map((el: AcademyVideoModel, i: number) => {
                    return (
                      <Card
                        data={el}
                        key={i}
                        handleMainVideo={handleMainVideo}
                      />
                    );
                  })}
                  {showViewBtn &&
                    (loadView ? (
                      <div className="my-8 flex flex-col items-center">
                        <HrmLoader className="w-5 h-5" />
                      </div>
                    ) : (
                      <div className="my-8 flex flex-col items-center">
                        <button
                          className="py-2 px-5 rounded text-white bg-purple-500 text-sm"
                          onClick={() => setPage((o) => o + 1)}
                        >
                          View more
                        </button>
                      </div>
                    ))}
                </div>
              </div>
            </div>

            {mainData && (
              <div
                className={` ${
                  showVideo ? "flex" : "hidden lg:flex"
                } fixed top-0 left-0 w-screen lg:w-3/5  lg:relative flex-col items-center min-h-screen overflow-y-auto bg-white`}
              >
                <div className="w-5/6 pt-6 lg:pt-12 ">
                  <div className="mb-6 lg:hidden">
                    <ChevronLeft
                      onClick={() => setShowVideo(false)}
                      size={32}
                    />
                  </div>
                  <div className="w-full h-96 bg-black rounded z-20">
                    <iframe
                      width="100%"
                      height="100%"
                      src={mainData?.url.replace("watch?v=", "embed/")}
                      title="Youtube"
                      className="z-40"
                      allowFullScreen={true}
                    ></iframe>
                  </div>
                  <div className="flex items-center justify-end gap-8 mt-4">
                    <div className="">
                      {savedVideos.length > 0 &&
                      savedVideos.some(
                        (el: any) => el?.video?.id === mainData.id
                      ) ? (
                        <div className="">
                          <button className="py-1 px-5 border border-green-500 text-green-500  rounded-full">
                            Saved
                          </button>
                        </div>
                      ) : (
                        <div className="">
                          <button
                            className="py-1 px-4 lg:py-2 lg:px-12 text-white bg-hrmRed font-semibold rounded"
                            onClick={() => saveVideo(mainData?.id)}
                          >
                            Save
                          </button>
                        </div>
                      )}
                    </div>

                    <div className="">
                      {watchedVideos.length > 0 &&
                      watchedVideos.some(
                        (el: any) => el?.video?.id === mainData.id
                      ) ? (
                        <div className="">
                          <Tooltip title="Watched already">
                            <CheckCircle className="text-green-500" size={28} />
                          </Tooltip>
                        </div>
                      ) : (
                        <div className="">
                          <button
                            className="py-1 px-4 lg:py-2 lg:px-12 text-white bg-hrmPurple font-semibold rounded"
                            onClick={() => createWatched(mainData?.id)}
                          >
                            Mark as watched
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="">
                    <div className="">
                      <div className="text-xl font-bold mt-6">
                        {mainData?.title}
                      </div>
                      <div className="">{mainData?.description}</div>
                    </div>
                    {user?.uid === mainData.created_by && (
                      <div className="mt-12">
                        <div className="text-gray-500">
                          Want to remove the video?
                        </div>
                        <div className="mt-2">
                          <button
                            className="py-2 px-5 rounded text-white bg-red-500"
                            onClick={() => {
                              const confirm = window.confirm(
                                "Want to delete the video?"
                              );
                              if (confirm) {
                                deleteVideo(mainData?.id);
                              }
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center h-screen">
            <div className="lg:text-xl">
              No vidoes have been uploaded in this category{" "}
              <AlertTriangle className="text-hrmRed inline ml-1" size={24} />{" "}
            </div>
            <div className="mt-4">
              <button
                className="py-2 px-6 rounded-full bg-hrmPurple text-white font-semibold"
                onClick={() => history.goBack()}
              >
                Go back
              </button>
            </div>
          </div>
        )
      ) : (
        <div className="flex flex-col items-center justify-center h-screen">
          <HrmLoader className="w-20 h-20" />
        </div>
      )}
    </div>
  );
};

export default VideoLayout;

interface CardProps {
  handleMainVideo: (d: AcademyVideoModel) => void;
  data: AcademyVideoModel;
}

const Card: FunctionComponent<CardProps> = ({ handleMainVideo, data }) => {
  const getThumbnail = (url: string) => {
    const temp = url.substring(url.indexOf("=") + 1);
    const imgUrl = `https://img.youtube.com/vi/${temp}/hqdefault.jpg`;
    return imgUrl;
  };

  const imgUrl = getThumbnail(data?.url);
  return (
    <div
      className="flex gap-4 mt-4 bg-white py-2 px-4 cursor-pointer"
      onClick={() => handleMainVideo(data)}
    >
      <div className="rounded-lg relative w-1/3 h-32">
        {imgUrl ? (
          <img src={imgUrl} alt="" className="w-full h-full object-cover" />
        ) : (
          <div className="">No image</div>
        )}
      </div>

      <div className="py-2 w-2/3 flex flex-col justify-between px-4">
        <div className="">
          <div className="">{data.title}</div>
          {/* <div className="text-sm desc_wrapper">{data.description}</div> */}
        </div>
      </div>
    </div>
  );
};
