export const getCurrentLocation = () => {
  return new Promise<GeolocationPosition>((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve(position);
        },
        (err) => {
          reject(err);
        }
      );
    } else {
      reject("Geolocation is not supported by this browser.");
    }
  });
};
