import {
  mdiCheck,
  mdiClose,
  mdiPencil,
  mdiDelete,
  mdiChevronDown,
  mdiChevronUp,
  mdiContentCopy,
} from "@mdi/js";
import Icon from "@mdi/react";
import moment from "moment";
import { FunctionComponent, useState } from "react";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import { UTCToLocalDate } from "../../../../../utils/date_util";
import { InventoryStatus } from "../../../../../utils/enums";
export interface OrderDevice {
  id: string;
  order_id: string;
  device_id: string;
  status: string;
  product_id: number;
  product_name: string;
  created_by: any;
  created_at?: Date | null;
  created_by_name: string;
  updated_at?: Date | null;
  updated_by: any;
  updated_by_name: string;
  installation_date?: Date | null;
  return_back_date?: Date | null;
  delivery_date?: Date | null;
  item_id: string;
  active: boolean;
  org_namespace?: string;
  org_name?: string;
  replaced_to?: string;
  replaced_by?: string;
  replaced_to_id?: string;
  replaced_by_id?: string;
  issue_category?: string;
}
export const OrderDeviceDefaultValue: OrderDevice = {
  id: "",
  order_id: "",
  device_id: "",
  status: "",
  org_namespace: "",
  org_name: "",
  product_id: 0,
  product_name: "",
  created_at: new Date(),
  created_by: "",
  created_by_name: "",
  updated_at: new Date(),
  updated_by: "",
  updated_by_name: "",
  installation_date: new Date(),
  return_back_date: new Date(),
  delivery_date: new Date(),
  item_id: "",
  active: false,
};
interface OrderDeviceRowProps {
  device: OrderDevice;
  sl_no: number;
  onUpdate: (data: OrderDevice) => void;
  disabled?: boolean;
}

const OrderDeviceRow: FunctionComponent<OrderDeviceRowProps> = (props) => {
  const [edit, setEdit] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [device, setDevice] = useState({ ...props.device });
  const { showToast } = useToast();
  return (
    <div className={`bg-white rounded-md px-3 py-2 text-xs`}>
      <div className="  grid grid-cols-12 items-center justify-center">
        <div>{props.sl_no}</div>
        <div className="">
          {device.id ?? "---"} | {device.item_id ?? "---"}
        </div>
        <div className="flex">
          <button
            onClick={() => {
              navigator.clipboard.writeText(device.device_id ?? "");
              showToast({
                type: ToastType.info,
                text: "Copied to clipboard !",
              });
            }}
            className=""
          >
            <Icon path={mdiContentCopy} className="w-3 h-3"></Icon>
          </button>
          <div
            className={`${
              device.status === InventoryStatus.returnedNStocked
                ? "text-red-400"
                : "text-green-400"
            }`}
          >
            {device.device_id}
          </div>
        </div>
        <div className="text-center"> {device.replaced_by ?? "---"}</div>
        <div className="text-center"> {device.replaced_to ?? "---"}</div>
        <div className="flex flex-col gap-1">
          <p>{device.org_namespace ?? "---"}</p>
        </div>
        <div> {device.product_name}</div>
        <div>
          <input
            disabled={!edit}
            name="date"
            type="date"
            id="date"
            className="p-1 rounded bg-gray-100 focus:outline-none"
            value={
              device.delivery_date
                ? moment(device.delivery_date).format("YYYY-MM-DD")
                : ""
            }
            onChange={(e) => {
              setDevice((o) => ({
                ...o,
                delivery_date: UTCToLocalDate(e.target.value)!,
              }));
            }}
          />
        </div>
        <div>
          <input
            disabled={!edit}
            name="date"
            type="date"
            id="date"
            className="p-1 rounded bg-gray-100 focus:outline-none"
            value={
              device.installation_date
                ? moment(device.installation_date).format("YYYY-MM-DD")
                : ""
            }
            onChange={(e) => {
              setDevice((o) => ({
                ...o,
                installation_date: UTCToLocalDate(e.target.value)!,
              }));
            }}
          />
        </div>
        <div>
          <input
            disabled={!edit}
            name="date"
            type="date"
            id="date"
            className="p-1 rounded bg-gray-100 focus:outline-none"
            value={
              device.return_back_date
                ? moment(device.return_back_date).format("YYYY-MM-DD")
                : ""
            }
            onChange={(e) => {
              setDevice((o) => ({
                ...o,
                return_back_date: UTCToLocalDate(e.target.value)!,
              }));
            }}
          />
        </div>

        <div>
          <select
            disabled={!edit}
            name=""
            id=""
            className="p-1 rounded bg-gray-100 focus:outline-none"
            value={device.status}
            onChange={(e) =>
              setDevice((o) => ({ ...o, status: e.target.value }))
            }
          >
            <option value="" hidden>
              select
            </option>
            {[
              InventoryStatus.delivered,
              InventoryStatus.installed,
              InventoryStatus.returnedNStocked,
            ].map((s, i) => (
              <option value={s} key={i}>
                {s}
              </option>
            ))}
          </select>
        </div>
        {!props.disabled && (
          <div className="">
            <>
              <div className="flex justify-center gap-2">
                {edit ? (
                  <>
                    <div
                      onClick={() => {
                        if (!window.confirm("Are you sure !")) return;
                        props.onUpdate(device);
                        setEdit(false);
                      }}
                      className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                    >
                      <Icon path={mdiCheck} className="h-4 w-4"></Icon>
                    </div>
                    <div
                      onClick={() => {
                        setEdit(false);
                        setDevice({ ...props.device });
                      }}
                      className="bg-red-100 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                    >
                      <Icon path={mdiClose} className="h-4 w-4"></Icon>
                    </div>
                  </>
                ) : (
                  <div
                    onClick={() => props.onUpdate(device)}
                    className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                  >
                    <Icon path={mdiPencil} className="h-4 w-4"></Icon>
                  </div>
                )}{" "}
                {props.device && (
                  <div
                    onClick={() => setShowMore((o) => !o)}
                    className="p-1 bg-gray-100 rounded cursor-pointer hover:bg-gray-200"
                  >
                    <Icon
                      path={showMore ? mdiChevronUp : mdiChevronDown}
                      className="h-4 w-4"
                    ></Icon>
                  </div>
                )}
              </div>
            </>
          </div>
        )}
      </div>
      {showMore && props.device && (
        <div className=" mt-2">
          <hr className=" " />

          <div className="flex   justify-between gap-2 my-1">
            <p className="text-xs ">
              Created by :<br />
              <div className="flex gap-1">
                <div className="">
                  <b>{props.device.created_by_name ?? "N/A"}</b>
                </div>
                <div className="">
                  {props.device.created_at
                    ? moment(props.device.created_at).format(
                        "DD MMM YYYY h:mm a"
                      )
                    : "--"}
                </div>
              </div>
            </p>
            <p className="text-xs ">
              Updated by :<br />
              <div className="flex gap-1">
                <div className="">
                  <b>{props.device.updated_by_name ?? "N/A"}</b>
                </div>
                <div className="">
                  {props.device.updated_at
                    ? moment(props.device.updated_at).format(
                        "DD MMM YYYY h:mm a"
                      )
                    : "--"}
                </div>
              </div>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderDeviceRow;
