import { FunctionComponent } from "react";
import { academyVideoCategory } from "../../../../../utils/enums";
import VideoLayout from "../components/VideosLayout";

interface StartupGyaanProps {}

const StartupGyaan: FunctionComponent<StartupGyaanProps> = () => {
  const segment = academyVideoCategory.Startup_gyaan;

  return <VideoLayout segment={segment} />;
};

export default StartupGyaan;
