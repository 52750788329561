import { mdiCheck, mdiCodeJson, mdiFilePlus, mdiMicrosoftExcel } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import ReactJson from "react-json-view";

import { useDropzone } from "react-dropzone";
import Papa from "papaparse";
import ProgressBar from "./progress_bar";
import {
  getDataType,
  parseDataType,
  dataTypes,
} from "../../../../utils/import_export_parser";
export function objectsHaveSameKeys(...objects: any[]) {
  const allKeys = objects.reduce(
    (keys, object) => keys.concat(Object.keys(object)),
    []
  );
  const union = new Set(allKeys);
  return objects.every((object) => union.size === Object.keys(object).length);
}
interface ImportWizardProps<T> {
  onCancel: () => void;
  onClose: () => void;
  onSubmit: (d: {
    data: T[];
    setUpLoading: (loading: boolean) => void;
    setProgress: (loading: number) => void;
    setCompleted: (loading: boolean) => void;
    // setCancelled: (v: boolean) => void;
  }) => void;

  onError: (error: string) => void;
}

const ImportWizard = <T extends unknown>(props: ImportWizardProps<T>) => {
  const [file, setFile] = useState<File | null>(null);

  const onDrop = useCallback((acceptedFiles) => {
    // Assuming only one file is allowed to be selected

    if (
      acceptedFiles[0] &&
      (acceptedFiles[0].type === "application/json" ||
        acceptedFiles[0].type === "text/csv")
    )
      setFile(acceptedFiles[0]);
    else props.onError("Please select JSON or CSV file.");
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: ".json,.csv",
    maxSize: 5000000,
  });
  const [data, setData] = useState<any[]>([]);
  const parseCSVFile = (file: File) => {
    if (!file) return;
    setLoading(true);
    Papa.parse(file, {
      header: true,
      complete: function (results) {
        setData(results.data);
        setLoading(false);
      },
    });
  };
  const parseJSONFile = (file: File) => {
    if (!file) return;
    try {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) {
          // console.log({ res: reader.result });

          const jsonData = JSON.parse(reader.result as any);
          // console.log({ jsonData });
          setLoading(false);
          if (Array.isArray(jsonData)) setData(jsonData);
          else setData([jsonData]);
        }
      };
      setLoading(true);
      reader.readAsText(file);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (file?.type === "text/csv") parseCSVFile(file);

    if (file?.type === "application/json")
      // setData([{ name: "hassain" }]);
      parseJSONFile(file);
  }, [file]);
  useEffect(() => {
    if (data.length > 0) {
      const temp: { key: string; type: string }[] = Object.keys(data[0]).map(
        (d) => {
          const type = getDataType(data[0][d]);
          return { key: d, type };
        }
      );
      setHeaders(temp);
    }
  }, [data]);

  const [headers, setHeaders] = useState<{ key: string; type: string }[]>([]);
  const [uploading, setUpLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const onSubmit = () => {
    try {
      const parseData = data.map((d) => {
        // const t: { [key: string]: any } = {};
        // for (let i = 0; i < headers.length; i++) {
        //   const head = headers[i];
        //   t[head.key] = parseDataType(d[head.key], head.type);
        // }
        // return t;
        const t: any = {};
        //  const keys = Object.keys(d);
        for (let i = 0; i < headers.length; i++) {
          const header = headers[i];
          const split = header.key.split(".");
          split.reduce((obj, prop, index) => {
            if (index === split.length - 1) {
              obj[prop] = parseDataType(d[header.key], header.type);
            } else {
              obj[prop] = obj[prop] || {};
            }
            return obj[prop];
          }, t);
        }
        return t;
      });
      props.onSubmit({
        data: parseData,
        setUpLoading,
        setProgress,
        setCompleted: (v) => {
          setProgress(0);
          setCompleted(true);
        },
        // setCancelled,
      });
    } catch (error: any) {
      props.onError(error.message);
    }
  };
  return (
    <div className="grid grid-cols-1 gap-2 " style={{ width: "630px" }}>
      {file && (
        <div
          className={`border border-green-400 rounded-md ${
            file ? " bg-green-50" : "border-dashed"
          } p-3 hover:bg-green-50`}
          {...getRootProps()}
        >
          <input
            {...getInputProps()}
            multiple={false}
            accept=".json,.csv"
            disabled={loading}
          />
          <div
            className={`flex gap-1 text-sm items-center justify-center ${
              file ? "text-gray-800" : "text-gray-400"
            }`}
          >
            {file ? (
              <p>{file.name}</p>
            ) : isDragActive ? (
              <p>Drop the file here ...</p>
            ) : (
              <>
                <Icon path={mdiFilePlus} className="w-4 h-4"></Icon>
                <p>Select a file..</p>
              </>
            )}
          </div>
        </div>
      )}
      {file && (
        <div className="flex gap-5 text-lg justify-start">
          <div
            className={`px-8 py-3 rounded border flex justify-center gap-1 items-center ${
              file?.type === "application/json"
                ? "bg-green-50 border border-green-400"
                : ""
            }`}
          >
            <Icon path={mdiCodeJson} size={0.8} />
            JSON
          </div>
          <div
            className={`px-10 py-3 rounded border flex justify-center gap-1 items-center ${
              file?.type === "text/csv"
                ? "bg-green-50 border border-green-400"
                : ""
            }`}
          >
            <Icon path={mdiMicrosoftExcel} size={0.8} />
            CSV
          </div>
        </div>
      )}

      {loading ? (
        <div className="flex flex-col  space-y-2 w-full  h-80  text-xs animate-pulse  rounded-xl border p-5">
          <div className="h-4 bg-gray-100 rounded animate-pulse"></div>
          <div className="h-4 bg-gray-100 rounded animate-pulse"></div>
          <div className="h-4 bg-gray-100 rounded animate-pulse"></div>
          <div className="h-4 bg-gray-100 rounded animate-pulse"></div>
          <div className="h-4 bg-gray-100 rounded animate-pulse"></div>
          <div className="h-4 bg-gray-100 rounded animate-pulse"></div>
          <div className="h-4 bg-gray-100 rounded animate-pulse"></div>
          <div className="h-4 bg-gray-100 rounded animate-pulse"></div>
          <div className="h-4 bg-gray-100 rounded animate-pulse"></div>
          <div className="h-4 bg-gray-100 rounded animate-pulse"></div>
          <div className="h-4 bg-gray-100 rounded animate-pulse"></div>
          <div className="h-4 bg-gray-100 rounded animate-pulse"></div>
        </div>
      ) : data && data.length > 0 ? (
        file?.type === "text/csv" ? (
          <section
            id="table-container"
            className="flex flex-col justify-between items-center w-full  h-80  text-xs rounded"
          >
            <div
              id="scroll_listener"
              className=" overflow-auto h-full border rounded-lg w-full"
            >
              <table className=" divide-y divide-gray-200 relative w-full">
                <thead className="  sticky  -top-1 z-10 ">
                  <tr className=" sticky  -top-0.5  ">
                    <th className={`  pb-2 font-semibold    w-8 `}>
                      <div className=" bg-white shadow py-1  ">
                        <div className="flex flex-col gap-1">
                          <h3>Sl</h3>
                          <div className="">-</div>
                        </div>
                      </div>
                    </th>
                    {headers.map((header, i) => (
                      <th
                        className={`   pb-2 font-semibold  overflow-hidden  `}
                        key={i}
                      >
                        <div className=" bg-white shadow py-1 flex justify-center  items-center gap-1 ">
                          <div className="flex flex-col gap-1">
                            <h3>{header.key ?? "---"}</h3>
                            <select
                              name=""
                              id=""
                              value={header.type}
                              onChange={(e) => {
                                setHeaders((o) => [
                                  ...o.map((h, j) => {
                                    if (i === j)
                                      return { ...h, type: e.target.value };
                                    return h;
                                  }),
                                ]);
                              }}
                            >
                              {Object.values(dataTypes).map((type) => (
                                <option value={type}>{type}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200 max-h-96 mx-2  ">
                  {data.map((item, i) => (
                    <tr
                      key={i}
                      className={`sticky-row    bg-white shadow p-1  hover:bg-gray-100 `}
                    >
                      <td
                        className={`p-1    text-right w-min whitespace-nowrap border sticky-field py-1 `}
                      >
                        <div className={``}>{i + 1}</div>
                      </td>
                      {headers.map((header, j) => {
                        return (
                          <td
                            title={item[header.key]}
                            key={j}
                            className={`p-1 text-center whitespace-nowrap border sticky-field  `}
                          >
                            <input
                              type="text"
                              className={`bg-transparent outline-none`}
                              value={item[header.key]}
                              onChange={(e) => {
                                setData((o) =>
                                  o.map((d, j) => {
                                    if (i == j) {
                                      d[header.key] = e.target.value;
                                    }
                                    return d;
                                  })
                                );
                              }}
                              // onInput={(e: any) => {
                              //   console.log(e);
                              // setData((o) =>
                              //   o.map((d, j) => {
                              //     if (i == j) {
                              //       d[header.key] = e.target.textContent;
                              //     }
                              //     return d;
                              //   })
                              // );
                              // }}
                              // contentEditable
                            ></input>
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section>
        ) : (
          file?.type === "application/json" && (
            <div className=" w-full  h-80 overflow-auto">
              <ReactJson
                src={data}
                onEdit={(edit) => {
                  setData([...((edit.updated_src as any) || [])]);
                }}
                onAdd={(add) => {
                  setData([...((add.updated_src as any) || [])]);
                }}
                onDelete={(del) => {
                  setData([...((del.updated_src as any) || [])]);
                }}
                onSelect={(sel) => {}}
              />
            </div>
          )
        )
      ) : (
        <div
          className={`border border-green-400 rounded-md ${
            file ? " bg-green-50" : "border-dashed"
          }     flex flex-col items-center justify-center    w-full  h-96      rounded-xl border p-5`}
          {...getRootProps()}
        >
          <input
            {...getInputProps()}
            multiple={false}
            accept=".json,.csv"
            disabled={loading}
          />

          <div className="flex flex-col items-center justify-center ">
            <img src="/assets/add_file.png" alt="" className="h-52 " />
            <h3 className="text-lg font-semibold text-gray-400">
              Drag and drop a file or select .{" "}
            </h3>
            <h3 className="text-xs font-semibold text-gray-400">
              JSON or CSV file is allowed.
            </h3>
          </div>
        </div>
      )}
      {uploading && (
        <div className="flex">
          <ProgressBar progress={progress} />
        </div>
      )}
      <div className="flex gap-3 text-sm justify-end">
        {completed ? (
          <div className="px-2 py-1 rounded border border-green-400 bg-green-50 text-green-600 flex items-center justify-center gap-1">
            <Icon path={mdiCheck} size={0.8} /> Done
          </div>
        ) : (
          <>
            <button
              className="px-2 py-1 rounded border"
              onClick={() => {
                if (uploading) {
                  props.onCancel();
                  setProgress(0);
                  setUpLoading(false);
                } else props.onClose();
              }}
            >
              Cancel
            </button>
            {!uploading && (
              <button
                disabled={loading || !data.length}
                className="px-2 py-1 rounded border"
                onClick={onSubmit}
              >
                Submit
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ImportWizard;
