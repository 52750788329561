import { FunctionComponent, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import { AcademyVideoModel } from "../../../../../service/models/academy";
import { GetAcademyVideoData } from "../../../../../service/repos/academy/academy_repo";
import { academyVideoCategory } from "../../../../../utils/enums";
import { urls } from "../../../../../utils/urls";

interface CmsProps {}

const Cms: FunctionComponent<CmsProps> = () => {
  const links = [1, 1, 1];
  const { showToast } = useToast();
  const [videos, setVideos] = useState<any>();

  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(3);

  const getUploadData = async (p: number, c: number) => {
    const data: { page: number; count: number; category: string } = {
      page: p,
      count: c,
      category: academyVideoCategory.Cms,
    };
    const res = await GetAcademyVideoData(data);

    if (res.success) {
      setVideos(res.data?.videos);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
  };

  useEffect(() => {
    getUploadData(page, count);
  }, []);
  return (
    <>
      {videos && videos.length > 0 && (
        <div className="flex flex-col items-center bg-black mt-12 py-12 lg:py-20">
          <div className="text-white w-4/5 text-center">
            <div className="text-2xl lg:text-3xl font-semibold">
              Know about our software
            </div>
            <div className="lg:text-lg text-primaryColor mt-3">
              Learn about our kazam cms andt its feature
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 justify-items-center  gap-8 lg:gap-12 mt-12 lg:mt-20 w-4/5">
            {videos.slice(0, 4).map((el: AcademyVideoModel, i: number) => {
              return (
                <div className="w-full h-60">
                  <iframe
                    src={el.url.replace("watch?v=", "embed/")}
                    title="sales"
                    frameBorder="0"
                    width="100%"
                    height="100%"
                    allowFullScreen={true}
                  ></iframe>
                </div>
              );
            })}
          </div>
          <NavLink to={urls.cms_learning}>
            <div className="flex flex-col text-white items-end underline mt-3 text-lg">
              View all category &gt; &gt;
            </div>
          </NavLink>
        </div>
      )}
      <div className="flex flex-col items-center py-20">
        <div className="w-4/5 grid grid-cols-2 lg:grid-cols-3 gap-8 lg:gap-0 justify-items-center">
          <div className="flex flex-col items-center">
            <div className="">
              <img src="/academy/create.png" alt="" className="w-12" />
            </div>
            <div className=" text-lg lg:text-2xl mt-5">Create</div>
          </div>
          <div className="flex flex-col items-center">
            <div className="">
              <img src="/academy/innovate.png" alt="" className="w-12" />
            </div>
            <div className=" text-lg lg:text-2xl mt-5">Innovative</div>
          </div>
          <div className="flex flex-col items-center">
            <div className="">
              <img src="/academy/grow.png" alt="" className="w-12" />
            </div>
            <div className=" text-lg lg:text-2xl mt-5">Grow</div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-end">
        <div
          className="underline mr-4 mb-4 text-xl cursor-pointer"
          onClick={() => window.scrollTo({ top: 0 })}
        >
          Scroll to top
        </div>
      </div>
    </>
  );
};

export default Cms;
