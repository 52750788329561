import { useState } from "react";
import ModalDialog from "./modal";

export interface PdfTileProps {
  url: string;
}
const PdfTile: React.FC<PdfTileProps> = (props) => {
  return (
    <a target="_blank" href={props.url}>
      <div className="card m-2">
        <embed src={props.url} type="application/pdf"></embed>
        <a className="d-block" target="_blank" href={props.url}>
          View
        </a>
      </div>
    </a>
  );
};
export default PdfTile;
