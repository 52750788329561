import axios from "axios";
import { fetchGet, fetchPost, getHeaders, toQueryString } from "../api_client";

export const CreateInvoiceApi = async (data: FormData) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/invoice`;
    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GetInvoiceApi = async (data: {
  page: number;
  count: number;
  search?: string;
}) => {
  try {
    const url = `${
      process.env.REACT_APP_BACKEND_BASE_URL
    }/api/v3/invoice${toQueryString(data)}`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GetInvoiceOfRefApi = async ({
  ref_id,
  ref_type,
}: {
  ref_id: string;
  ref_type: string;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/invoice/order/${ref_id}/${ref_type}`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
