import { FunctionComponent } from "react";
import { X } from "react-feather";
import { JobDetail } from "../../service/models/job_portal";
import { UpdateJobDetail } from "../../service/repos/job_portal";

interface DescriptionProps {
  close: () => void;
  data: JobDetail;
}

const Description: FunctionComponent<DescriptionProps> = ({ close, data }) => {
  const tempDesc = data.description
    ? data.description.replace(/\n/g, "<br /> ")
    : "";

  return (
    <div className="bg-white relative w-11/12 lg:w-3/5 pt-6 pb-12 px-2 lg:px-6 overflow-y-scroll my-12">
      <X
        size={20}
        className=" absolute top-2 right-2"
        onClick={() => close()}
      />

      <div className="mt-4 grid grid-cols-2 lg:grid-cols-4 gap-5 lg:gap-4">
        <div className="col-span-2">
          <div className="text-sm font-semibold text-gray-500">Job title</div>
          <div className="font-semibold mt-1">{data.title}</div>
        </div>
        <div className="">
          <div className="text-sm font-semibold text-gray-500">Department</div>
          <div className="font-semibold mt-1">{data.department}</div>
        </div>
        <div className="">
          <div className="text-sm font-semibold text-gray-500">Job type</div>
          <div className="font-semibold mt-1">{data.job_type}</div>
        </div>
        <div className="">
          <div className="text-sm font-semibold text-gray-500">Location</div>
          <div className="font-semibold mt-1">{data.location}</div>
        </div>
      </div>
      <div className="h-px w-full bg-gray-300 mt-2 mb-6"></div>
      <div className="">
        <div className="text-sm font-semibold text-gray-500">
          Job description
        </div>
        <div dangerouslySetInnerHTML={{ __html: tempDesc }}></div>
      </div>
      {data?.otherLinks && data?.otherLinks?.length > 0 && (
        <div className="mt-12">
          <div className="border-b text-sm font-semibold text-gray-500">
            Other links to apply
          </div>
          <div className="">
            {data?.otherLinks?.map((el) => {
              return (
                <a href={el} target="_blank" rel="noreferrer">
                  <div className="text-sm text-blue-500 underline mt-1">
                    {el}
                  </div>
                </a>
              );
            })}
          </div>
        </div>
      )}
      {/* <div className="mt-4 flex flex-col items-end">
        <button
          className="py-2 px-4 rounded-md text-white bg-hrmBlue font-semibold"
          onClick={() => updateJobStatus(data?.id, data)}
        >
          Mark as complete
        </button>
      </div> */}
    </div>
  );
};

export default Description;
