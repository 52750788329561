import { FunctionComponent, useRef } from "react";
import { UserModel } from "../../../../../context_providers/auth/user_model";
import IdCard from "./IdCard";
import * as htmlToImage from "html-to-image";
import VisitingCard from "./VisitingCard";

interface EmployeeCardsProps {
  userData: UserModel;
}

const EmployeeCards: FunctionComponent<EmployeeCardsProps> = ({ userData }) => {
  const ref = useRef(null);
  return (
    <div className="">
      <div className="pt-8 font-semibold text-xl">Employee ID card</div>
      <div className=" mt-12 flex flex-col items-center" id="idcard">
        <IdCard user={userData} />
      </div>
      {/* <div className="">Employee visiting card</div> */}
      <div className="pt-32 font-semibold text-xl">Employee visiting card</div>
      <div className=" mt-12 flex flex-col items-center" id="idcard">
        <VisitingCard user={userData} />
      </div>
    </div>
  );
};

export default EmployeeCards;
