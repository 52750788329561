import { fetchGet, fetchPost, fetchPut, toQueryString } from "../../api_client";
import { CustomerDetailModel } from "../../models/orders/customer_detail_model";

export const GetBillingProfilesOfUserApi = async (uid: any) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/billing-profiles/user/${uid} `;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GetBillingProfilesApi = async (data: {
  page: number;
  count: number;
  search?: string;
  all?: boolean;
}) => {
  try {
    const url = `${
      process.env.REACT_APP_BACKEND_BASE_URL
    }/api/v3/billing-profiles${toQueryString(data)}`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const CreateBillingProfileApi = async (data: CustomerDetailModel) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/billing-profiles`;
    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const UpdateBillingProfileApi = async (data: CustomerDetailModel) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/billing-profiles/${data.id}`;
    const res = await fetchPut(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
