import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import GoogleMapReact from "google-map-react";
import Icon from "@mdi/react";
import { mdiMapMarker, mdiNavigation, mdiRefresh } from "@mdi/js";
import { fetchGet, toQueryString } from "../../../../service/api_client";
import { debounce } from "lodash";
import { AddressModel } from "../../../../service/models/address_model";
import { PlaceDetailResponse, parseAddressFromPlaceApi } from "./AddressPicker";
import LoadingWidget from "../../../components/loading_spinner";

const Marker = ({ text }: { text: string; lat: number; lng: number }) => (
  <Icon path={mdiMapMarker} size={1.5} className="text-teal" title={"Center"} />
);

interface LocationPickerProps {
  address?: AddressModel;
  onSubmit: (address: AddressModel) => void;
}

const LocationPicker: FunctionComponent<LocationPickerProps> = (props) => {
  const [center, setCenter] = useState<{ lat: number; lng: number }>();
  const [address, setAddress] = useState<AddressModel | undefined>(
    props.address ? { ...props.address } : undefined
  );
  const [loading, setLoading] = useState(false);
  const [showRefetch, setshowRefetch] = useState(false);
  const getPlaceData = async (c: { lat: number; lng: number }) => {
    setLoading(true);
    try {
      const url = `${
        process.env.REACT_APP_BACKEND_BASE_URL
      }/api/v3/address/coordinates${toQueryString({
        coordinates: JSON.stringify(c),
      })}`;
      const res = await fetchGet(url);

      if (res.success) {
        const data: PlaceDetailResponse = res.data.results[0];
        if (data) {
          let _address = parseAddressFromPlaceApi(data);
          setAddress(_address);
        }
      }
    } catch (error) {
      console.log({ error });
    }
    setLoading(false);
  };

  const debouncedHandler = useCallback(
    debounce((query: { lat: number; lng: number } | undefined) => {
      if (query) {
        if (address) {
          if (
            query.lat !== address?.location?.coordinates[1] ||
            query.lng !== address?.location?.coordinates[0]
          ) {
            setAddress((o) => ({
              ...o!,
              location: {
                ...o!.location!,
                coordinates: [query.lng, query.lat],
              },
            }));
            setshowRefetch(true);
          }
        } else {
          getPlaceData(query);
        }
      } else setAddress(undefined);
      // if (query) getPlaceData(query);
      // else setAddress(undefined);
    }, 2000),
    []
  );

  useEffect(() => {
    // if (center) {
    debouncedHandler(center);
    // }
  }, [center]);
  return (
    // Important! Always set the container height explicitly
    <div className="relative" style={{ height: "100%", width: "100%" }}>
      <div className="relative" style={{ height: "90%", width: "100%" }}>
        <GoogleMapReact
          options={{ zoomControlOptions: { position: 3 } }}
          onClick={(e) => {
            setCenter({ lat: e.lat, lng: e.lng });
          }}
          defaultCenter={
            props.address?.location
              ? {
                  lat: props.address.location?.coordinates[1] ?? 12.9339162,
                  lng: props.address.location?.coordinates[0] ?? 77.6159146,
                }
              : {
                  lat: 12.9339162,
                  lng: 77.6159146,
                }
          }
          bootstrapURLKeys={{ key: "AIzaSyB2mseYiLUF1hQZdO-odTm9FdK6cTUFFXY" }}
          center={center}
          defaultZoom={props.address ? 12 : 8}
          onChange={(e) => {
            setCenter(e.center);
          }}
        >
          {center && (
            <Marker lat={center.lat} lng={center.lng} text="Selected" />
          )}
        </GoogleMapReact>
      </div>
      {loading && <LoadingWidget loading={true} />}
      {address && !loading && (
        <div className=" bottom-0 right-0 left-0 p-2 z-10">
          <div className="bg-white rounded-lg p-2 text-xs">
            {/* <div className="flex gap-2 items-start justify-between">
              <div className="flex gap-2 items-start">
                <div className="">
                  <Icon path={mdiMapMarker} size={1} className="" />
                </div>
                <h1>{address.full_address}</h1>
              </div>
              {center && showRefetch && (
                <button
                  title="Refresh Address"
                  className="shadow rounded-full p-1"
                  onClick={() => {
                    getPlaceData(center);
                  }}
                >
                  <Icon path={mdiRefresh} size={1}></Icon>
                </button>
              )}
            </div> */}

            <button
              onClick={() => {
                props.onSubmit(address);
              }}
              className="bg-green-50 text-green-500 border-green-400 border rounded-lg px-3 py-2 w-full  uppercase font-bold"
            >
              Confirm
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LocationPicker;
