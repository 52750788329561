import {
  mdiBriefcase,
  mdiHistory,
  mdiHome,
  mdiMapMarkerOffOutline,
  mdiRefresh,
} from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTaskSession } from "../../../../context_providers/task_session_ctx";
import {
  GetTaskSessionLiveRepo,
  StartTaskSessionRepo,
  StopTaskSessionRepo,
  TaskSessionModel,
} from "../../../../service/repos/ops/task_session_repo";
// import { timeDiff, timeSince } from "../../../../utils/date_util";
import { getCurrentLocation } from "../../../../utils/location_util";
import { urls } from "../../../../utils/urls";
import LoadingWidget from "../../../components/loading_spinner";
import TimerWidget from "./timer_widget";

interface EmpAttendancePageProps {}

const EmpAttendancePage: FunctionComponent<EmpAttendancePageProps> = () => {
  const { session, getLocation, StartSession, StopSession, onDuty, position } =
    useTaskSession();

  // const [session, setSession] = useState<TaskSessionModel>();
  // const [loading, setLoading] = useState(false);
  // const [offDuty, setOffDuty] = useState(true);
  // const [position, setPosition] = useState<GeolocationPosition>();

  // useEffect(() => {
  //   if (session && !session.off_duty) {
  //     setOffDuty(false);
  //     // setInterval(() => {}, 1000);
  //   } else setOffDuty(true);
  // }, [session]);
  // useEffect(() => {
  //   getSession();
  //   getLocation();
  // }, []);
  // const getSession = async () => {
  //   setLoading(true);
  //   const res = await GetTaskSessionLiveRepo();
  //   if (res.success) {
  //     if (res.data) setSession(res.data);
  //   } else {
  //     showToast({ type: ToastType.error, text: res.error ?? "" })
  //   }
  //   setLoading(false);
  // };
  // const StartSession = async () => {
  //   setLoading(true);
  //   if (position) {
  //     const res = await StartTaskSessionRepo({
  //       lat: position!.coords.latitude,
  //       long: position!.coords.longitude,
  //     });
  //     if (res.success) {
  //       setSession(res.data);
  //     } else {
  //       showToast({ type: ToastType.error, text: res.error ?? "" })
  //     }
  //   }
  //   setLoading(false);
  // };
  // const StopSession = async () => {
  //   setLoading(true);
  //   if (position) {
  //     const res = await StopTaskSessionRepo({
  //       lat: position!.coords.latitude,
  //       long: position!.coords.longitude,
  //     });
  //     if (res.success) {
  //       setSession(res.data);
  //     } else {
  //       showToast({ type: ToastType.error, text: res.error ?? "" })
  //     }
  //   }
  //   setLoading(false);
  // };

  // const getLocation = async () => {
  //   setLoading(true);
  //   try {
  //     const pos = await getCurrentLocation();
  //     if (pos) {
  //       setPosition(pos);
  //       return pos;
  //     }
  //     alert("Please allow location permission");
  //   } catch (error: any) {
  //     alert(error.message);
  //   }
  //   setLoading(false);
  // };

  return (
    <>
      <div className="hidden md:block select-none">
        <div className="flex flex-col justify-center items-center h-screen w-full   ">
          {/* <div className="flex justify-end w-full ">
            <button className="border rounded p-1 m-5">History</button>
          </div> */}
          <div className="">
            {" "}
            <div className="flex justify-end w-full mb-1 ">
              <Link
                to={`${urls.emp_attendace_history}`}
                className="border rounded p-1 flex gap-2 items-center mb-1"
              >
                <Icon path={mdiHistory} className="w-5 h-5"></Icon> History
              </Link>
            </div>
            <div className="flex flex-col items-center px-20  py-20 border rounded-md shadow">
              <img src="/logo512.png" alt="" className="h-16 w-16 mb-10" />
              <h1 className="text-3xl font-semibold">
                {!onDuty ? "You are Off Duty !" : "You are On Duty !"}
              </h1>
              <h1 className="text-md font-semibold">
                {!onDuty
                  ? " Click start to go On Duty"
                  : " Click stop to go Off Duty"}
              </h1>
              {!position && (
                <div className="flex flex-col items-center">
                  <div className="flex gap-2 items-end mt-3">
                    <Icon
                      path={mdiMapMarkerOffOutline}
                      className="w-5 h-5"
                    ></Icon>
                    <p className="text-xs">Location Unavailable</p>
                  </div>
                  <button
                    onClick={() => getLocation()}
                    className="rounded-3xl px-2 py-1 border mt-3 "
                  >
                    <Icon path={mdiRefresh} className="w-10 h-10 mt-2"></Icon>
                    Retry
                  </button>
                </div>
              )}

              {onDuty && session && (
                <div className="flex justify-between items-end w-full">
                  <div>
                    <h1 className="text-md font-semibold mt-5">Started on</h1>
                    <h1 className="text-sm font-semibold ">
                      {new Date(session!.on_duty!).toDateString()}
                    </h1>
                    <h1 className="text-md font-semibold">
                      {new Date(session!.on_duty!).toLocaleTimeString()}
                    </h1>
                  </div>
                  <div className="">
                    {/* {timeDiff(session.on_duty!, new Date())} */}
                    <TimerWidget start={session.on_duty!} />
                  </div>
                </div>
              )}
              <button
                disabled={!position}
                onClick={async () => {
                  if (!onDuty) {
                    StartSession();
                  } else {
                    StopSession();
                  }
                }}
                className="rounded-3xl bg-teal text-white border w-full   px-2  py-1 mt-5"
              >
                {!onDuty ? "Start" : " Stop"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="md:hidden select-none">
        <div
          className="flex flex-col justify-between"
          style={{ height: "88vh" }}
        >
          <div className="flex flex-col items-center      ">
            <div className="flex justify-end w-full  ">
              <Link
                to={`${urls.emp_attendace_history}`}
                className="border rounded p-1 flex gap-2 items-center m-2"
              >
                <Icon path={mdiHistory} className="w-5 h-5"></Icon> History
              </Link>
            </div>
            <div className="flex flex-col items-center my-3">
              <img src="/logo512.png" alt="" className="h-16 w-16 mb-10" />
              <h1 className="text-3xl font-semibold">
                {!onDuty ? "You are Off Duty !" : "You are On Duty !"}
              </h1>
              <h1 className="text-md font-semibold">
                {!onDuty
                  ? " Click start to go On Duty"
                  : " Click stop to go Off Duty"}
              </h1>
              {!position && (
                <div className="flex flex-col items-center">
                  <div className="flex gap-2 items-end mt-3">
                    <Icon
                      path={mdiMapMarkerOffOutline}
                      className="w-5 h-5"
                    ></Icon>
                    <p className="text-xs">Location Unavailable</p>
                  </div>
                  <button
                    onClick={() => getLocation()}
                    className="rounded-3xl px-2 py-1 border mt-3 "
                  >
                    <Icon path={mdiRefresh} className="w-10 h-10 mt-2"></Icon>
                    Retry
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 right-0 left-0   ">
        <div className="p-3">
          {onDuty && session && (
            <div className="flex justify-between items-end w-full">
              <div>
                <h1 className="text-md font-semibold">Started</h1>
                <h1 className="text-md font-semibold ">
                  {new Date(session!.on_duty!).toDateString()}
                </h1>
                <h1 className="text-lg font-semibold">
                  {new Date(session!.on_duty!).toLocaleTimeString()}
                </h1>
              </div>
              <div className="">
                {/* {timeDiff(session.on_duty!, new Date())} */}
                <TimerWidget start={session.on_duty!} />
              </div>
            </div>
          )}
          <button
            disabled={!position}
            onClick={async () => {
              if (!onDuty) {
                StartSession();
              } else {
                StopSession();
              }
            }}
            className="rounded-3xl bg-teal text-white border  w-full   px-2  py-1 mt-3 "
          >
            {!onDuty ? "Start" : "Stop"}
          </button>
        </div>
        <div className="grid grid-cols-2 bg-white  text-sm">
          <Link
            to={urls.operation_engineer_task}
            className="flex flex-col items-center justify-center p-2  "
          >
            <Icon path={mdiHome} size={1}></Icon>
            Home
          </Link>
          <div className="flex flex-col items-center justify-center p-2 text-teal">
            <Icon path={mdiBriefcase} size={1}></Icon>
            {onDuty ? "Stop Duty" : "  Start Duty"}
          </div>
        </div>
      </div>
      {/* <LoadingWidget loading={loading} /> */}
    </>
  );
};

export default EmpAttendancePage;
