import { Pagination } from "@material-ui/lab";
// @ts-ignore
import gsap from "gsap/all";
import { FunctionComponent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  ToastType,
  useToast,
} from "../../../../context_providers/toast/toast_ctx";
import { GetMemory } from "../../../../service/repos/hrm/hrm_repo";
import { urls } from "../../../../utils/urls";
import HrmPopup from "../components/HrmPopup";
import HrmLoader from "../components/loading";
import Layout from "../layout";
import "../styles/index.css";
import AddMemory from "./components/AddMemory";

interface MemoriesProps {}

const Memories: FunctionComponent<MemoriesProps> = () => {
  const [memories, setMemories] = useState<any[]>([]);
  const [showAdd, setShowAdd] = useState(false);
  const [showMemories, setShowMemories] = useState<boolean>(false);
  const history = useHistory();
  const count = 15;
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const { showToast } = useToast();
  const [loading, setLoading] = useState<boolean>(false);

  const getMemories = async (p: number) => {
    const params: { page: number; count: number } = {
      page: p,
      count: count,
    };
    const res = await GetMemory(params);
    console.log(res);

    if (res.success) {
      setMemories(res.data.posts);
      setTotal(res.data?.metadata[0]?.total);
    }
  };

  const loadMemories = async (p: number) => {
    setLoading(true);
    setPage((p) => p + 1);
    const params: { page: number; count: number } = {
      page: p,
      count: count,
    };

    const res = await GetMemory(params);

    if (res.success) {
      setMemories((o) => [...o, ...res.data.posts]);
      setLoading(false);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
      setLoading(false);
    }
  };

  const onPageChange = async (p: number) => {
    if (p !== page) {
      setPage(p);
      await getMemories(p);
    }
  };

  const findDate = (d: number) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    const date = new Date(d);
    return date.getDate() + " " + months[date.getMonth()];
  };

  useEffect(() => {
    console.log(memories);
  }, [memories]);

  useEffect(() => {
    getMemories(page);
  }, []);

  return (
    <Layout pageTitle="Memories">
      <>
        <div className="relative pt-6 pl-6 pb-32">
          {memories.length > 0 && (
            <div className="flex flex-col items-end">
              <button
                className="hrm_btn bg-hrmBlue   text-white font-semibold rounded py-3 px-10 z-40"
                onClick={() => setShowAdd(true)}
              >
                Add memory
              </button>
            </div>
          )}
          {/* <NavLink
            target="_blank"
            to={`orders/${order.order_id}`}
                        > */}
          {memories.length > 0 ? (
            <>
              <div className="grid lg:grid-cols-3 justify-items-center gap-y-20 lg:gap-y-48 mt-32">
                {memories.map((el, i) => {
                  return (
                    <div
                      className={` w-72 h-auto bg-white dark:bg-black dark:bg-opacity-50 rounded-2xl cursor-pointer z-30 ${
                        i % 2 === 0
                          ? "memory_card_odd "
                          : "lg:top-20 memory_card_even "
                      } relative`}
                      onClick={() => {
                        history.push(urls.hrm_memories_view, el);
                      }}
                    >
                      <div className="flex items-center gap-3 py-2 px-4">
                        <div className="w-8 h-8 rounded-full">
                          <img
                            src={el?.img}
                            alt=""
                            className="w-full h-full rounded-full "
                          />
                        </div>
                        <div className="dark:text-white text-sm">
                          {el?.name}
                        </div>
                      </div>
                      <div className="w-full h-72 relative">
                        <div className="w-full h-full bg-white dark:bg-black bg-opacity-10 dark:bg-opacity-10 absolute top-0 left-0"></div>
                        <img
                          src={el?.files[0].url}
                          alt=""
                          className=" w-full h-full object-contain"
                        />
                      </div>
                      <div className="flex justify-between items-end px-2">
                        <div className="py-2 px-3 ">
                          <div className="text-sm dark:text-primaryColor mt-2">
                            {el.description}
                          </div>
                          <div className="text-hrmRed dark:text-secondaryColor text-sm mt-1">
                            {findDate(el.created_at)}
                          </div>
                        </div>
                        <div className="text-sm text-hrmRed pb-2">
                          {el?.likes.length} likes
                        </div>
                      </div>
                      <div className="absolute -top-5 -right-4 w-10 h-10">
                        <img
                          src="/images/icons/clip.png"
                          alt=""
                          className="w-full h-full"
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="flex flex-col items-center mt-32 lg:mt-48">
                {loading ? (
                  <HrmLoader className="w-12 h-12"></HrmLoader>
                ) : (
                  <div
                    className="py-3 px-12 rounded bg-hrmPurple duration-200 text-white font-bold cursor-pointer"
                    onClick={() => loadMemories(page + 1)}
                  >
                    Load more
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="mt-32 flex flex-col items-center">
              <HrmLoader className="w-20 h-20" />
            </div>
          )}

          {showAdd && (
            <HrmPopup>
              <AddMemory showAdd={showAdd} close={() => setShowAdd(false)} />
            </HrmPopup>
          )}
        </div>
      </>
    </Layout>
  );
};

export default Memories;
