import { FunctionComponent, useEffect, useRef, useState } from "react";
import { fetchGet, fetchPost } from "../../../../../../service/api_client";
import {
  ToastType,
  useToast,
} from "../../../../../../context_providers/toast/toast_ctx";
import { useOpsTask } from "../../../../../../context_providers/ops_task_engineer_detail/ops_task_engineer_detail";
import OtpField from "../../components/otp_field";
import CountDownWidget from "../../components/count_down_widget";
import { Redirect, useHistory, useRouteMatch } from "react-router-dom";
import DoneWidget from "../../../../../new_components/common/done";
import { urls } from "../../../../../../utils/urls";

interface OtpVerificationSectionProps {}

const OtpVerificationSection: FunctionComponent<
  OtpVerificationSectionProps
> = () => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const { showToast } = useToast();
  const { data, setData } = useOpsTask();
  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(true);
  const [otp, setotp] = useState("");
  const [showOtpResend, setShowOtpResent] = useState(false);
  //   const audioRef = useRef<any>();
  const getOtp = async () => {
    setLoading(true);
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/new-ops/task/my-tasks/${data?.id}/verification/otp`;
    const res = await fetchGet(url);
    if (res.success) {
      setOtpSent(true);
      showToast({
        type: ToastType.success,
        text: res.messsage ?? "Otp sent successfully",
      });
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  const verifyOtp = async (_otp: string) => {
    if (_otp.length < 4) {
      return showToast({ type: ToastType.error, text: "Enter 4 digit otp" });
    }
    setLoading(true);
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/new-ops/task/my-tasks/${data?.id}/verification/otp`;
    const res = await fetchPost(url, { otp: _otp });
    if (res.success) {
      setData({ ...res.data });
      showToast({
        type: ToastType.success,
        text: res.messsage ?? "Otp veified",
      });
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  useEffect(() => {
    //   if (!otp && data?.verified) {
    //     history.goBack();
    //   }
    let t: NodeJS.Timeout;
    if (!otp && data?.verified) {
      t = setTimeout(() => {
        history.replace(urls.operation_engineer_task);
      }, 6000);
    }
    return () => {
      if (t) {
        clearTimeout(t);
      }
    };
  }, [data?.verified]);
  useEffect(() => {
    if (!data?.verified) getOtp();
  }, []);
  if (data?.verified) {
    return (
      <>
        <audio
          autoPlay
          id="sucess_audio"
          src="https://kazam-media.s3.ap-south-1.amazonaws.com/audio/mission-completed.mp3"
        ></audio>
        <div className="">
          <DoneWidget />
          <h1 className="text-center text-xl font-semibold">
            {" "}
            Task Completed !
          </h1>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="flex flex-col justify-between h-full bg-white">
        <div className="">
          <div className="pl-5 pt-5">
            <button
              onClick={() => {
                history.goBack();
              }}
              className="rounded-3xl border px-5 py-1 bg-blue-50 text-blue-500 border-blue-400 "
            >
              Back
            </button>
          </div>
          <div className="p-5">
            <h2 className=" my-1  ">
              Enter OTP sent to
              <span>{data?.cust_mobile}</span>
            </h2>
            {/* <p className="text-sm text-gray-500">{data?.cust_name}</p> */}
            <div className="my-10">
              <OtpField onChange={setotp} onSubmit={setotp} />
            </div>
            <div className="flex justify-center my-2">
              {otpSent && !showOtpResend ? (
                <div className="flex gap-2 text-sm">
                  Resend in{" "}
                  <CountDownWidget
                    onTimeout={() => {
                      setShowOtpResent(true);
                    }}
                    date={Date.now() + 1000 * 10}
                  />
                </div>
              ) : (
                <button
                  className="border rounded-3xl px-3 py-2 bg-purple-50 text-purple-500 border-purple-300"
                  onClick={() => {
                    getOtp();
                  }}
                >
                  Resend
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="fixed bottom-0 left-0 right-0 p-3 bg-white">
          <button
            // disabled={otp.length != 4}
            onClick={() => {
              verifyOtp(otp);
            }}
            className="bg-green-50 text-green-500 border rounded-lg border-green-300 text-center px-3 py-2 w-full font-bold"
          >
            Verify
          </button>
        </div>
      </div>
    </>
  );
};

export default OtpVerificationSection;
