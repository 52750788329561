import { FunctionComponent } from "react";

interface HrmButtonProps {
  className?: string;
  type?: string;
  size?: string;
  text: string;
  onClick?: () => void;
}

const HrmButton: FunctionComponent<HrmButtonProps> = ({
  className,
  type,
  size,
  text,
  onClick,
}) => {
  return (
    <button
      className={`${
        type === "primary"
          ? "bg-hrmBlue"
          : type === "success"
          ? "bg-green-400"
          : type === "danger"
          ? "bg-hrmRed"
          : "bg-hrmPurple"
      } text-white ${
        size === "large"
          ? "py-3 lg:py-4 px-10 lg:px-12"
          : size === "medium"
          ? "lg:py-3 lg:px-8"
          : size === "very-small"
          ? "py-1 px-2"
          : "py-2 px-6"
      } rounded-full font-semibold  ${className} `}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default HrmButton;
