import { FunctionComponent } from "react";
export interface RadioProps {
  alignment?: string;
  name: string;
  label: string;
  value: any;
  defaultValue?: any;

  placeholder: string;
  inputChange?: (data: any) => void;
  disabled?: boolean;
  required?: boolean;
  options: { label: string; value: any }[];
}

const Radio: FunctionComponent<RadioProps> = (props) => {
  const {
    alignment,
    disabled = false,
    value,
    name,
    label,
    inputChange,
    options,
    required = false,
    defaultValue,
  } = props;

  return (
    <div
      className={`grid ${alignment === "row" ? "grid-cols-3" : "grid-col-1"}`}
    >
      <label htmlFor={name} className="">
        {label}
      </label>
      <div className="col-span-2">
        {options.map((option: any) => (
          <label className="inline-flex items-center mx-3">
            <input
              disabled={disabled}
              name={name}
              value={option.value}
              type="radio"
              defaultChecked={defaultValue === option.value}
              onChange={(e) => {
                if (inputChange) inputChange(e.target.value);
              }}
              required={required}
              title={label + " is required"}
            />
            <span className="ml-2 text-md mx-2">{option.label}</span>
          </label>
        ))}
      </div>
    </div>
  );
};
export default Radio;
