import { FunctionComponent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../../../../../../context_providers/auth/authProvider";
import { ACTION, MODULE } from "../../../../../../../../utils/enums";
import { urls } from "../../../../../../../../utils/urls";
import category from "../../../../../components/blogEditor/category";
import { VehicleVarient } from "../../../../../infrastructure/models/car_model";
import { URLS } from "../../../../../infrastructure/utils/urls";
import {
  deletetVehicleVarient,
  getVarientsByVehicleIdRepo,
  postVarient,
  putVarient,
  putVarientVisibility,
} from "../../../../../services/vehicle_service";
import { useAppSelector } from "../../../../../store/hooks";
import DropDownMenu from "../../../../new_components/common/drop_down_menu";
import ModalDialog from "../../../../new_components/common/modal";
import Pagination from "../../../../new_components/common/pagination";
import ToggleButton from "../../../../new_components/form_components/toggle_button";
import CreateVarient from "./varient_detail";

interface VarientListingProps {
  location: any;
}

const VarientListing: FunctionComponent<VarientListingProps> = (props) => {
  const { state } = props.location;
  const { vehicle } = state;
  const history = useHistory();
  const { user, isAuthorised } = useAuth();
  const [showAddVarient, setShowAddVarient] = useState(false);
  const [count, setCount] = useState(20);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [varients, setVarients] = useState<VehicleVarient[]>([]);
  const [selectedVarient, setselectedVarient] = useState<VehicleVarient>();
  const getVarients = async () => {
    const res = await getVarientsByVehicleIdRepo({
      vehicle_id: vehicle.id,
      page,
      count,
    });
    if (res.success) {
      setVarients(res.data);
      setTotal(res.total);
    } else alert(res.error);
  };
  const onDelete = async (varient_id: string) => {
    const str = "DELETE";
    const confirm = prompt(`Enter ${str} to confirm (note : Cannot be undo)`);
    if (confirm !== str) return;
    const res = await deletetVehicleVarient(varient_id);
    if (res.success) {
      alert(res.message);
      getVarients();
    } else {
      alert(res.error);
    }
  };
  const createVarient = async (data: any) => {
    const varientData = {
      active: true,
      title: data.title,
      release_date: new Date(data.release_date).getTime() / 1000,
    };

    delete data.active;
    delete data.release_date;
    delete data.title;

    const res = await postVarient({
      vehicle_id: vehicle.id,
      varients: [{ ...varientData, specification: data }],
    });

    if (res.success) {
      setShowAddVarient(false);
      getVarients();
      // setVarients(res.data);
      // setTotal(res.total);
    } else alert(res.error);
  };
  const updateVarient = async (data: any) => {
    const varientData = {
      id: selectedVarient?.id,
      active: true,
      title: data.title,
      release_date: new Date(data.release_date).getTime() / 1000,
    };

    delete data.active;
    delete data.release_date;
    delete data.title;

    const res = await putVarient({ ...varientData, specification: data });
    if (res.success) {
      alert(res.message);
      setselectedVarient(undefined);
      getVarients();
      // setVarients(res.data);
      // setTotal(res.total);
    } else alert(res.error);
  };
  useEffect(() => {
    getVarients();
  }, [page, count]);
  const onChangeVisibility = async (id: string, active: boolean) => {
    const res = await putVarientVisibility({ id, active });
    if (res.success) {
      alert(res.message);
      getVarients();
    } else {
      alert(res.error);
    }
  };
  return (
    <>
      <ModalDialog
        onClose={() => {
          setShowAddVarient(false);
        }}
        show={showAddVarient}
        title="Add varient"
      >
        <div
          style={{ height: "85vh", width: "90vw" }}
          className=" w-screen  overflow-y-scroll p-5 "
        >
          <CreateVarient
            varient={selectedVarient}
            onSubmit={createVarient}
            category={vehicle.category}
          />
        </div>
      </ModalDialog>
      {selectedVarient && (
        <ModalDialog
          onClose={() => {
            setselectedVarient(undefined);
          }}
          show={selectedVarient !== undefined}
          title="Edit varient"
        >
          <div
            style={{ height: "85vh", width: "90vw" }}
            className=" w-screen  overflow-y-scroll p-5 "
          >
            <CreateVarient
              varient={selectedVarient}
              onSubmit={updateVarient}
              category={vehicle.category}
            />
          </div>
        </ModalDialog>
      )}
      <div className="flex justify-center h-screen">
        <div className="w-max">
          <div className="flex justify-end my-3">
            <div
              onClick={() => setShowAddVarient(true)}
              className="flex items-center bg-gray-100 px-3 py-2 rounded font-bold hover:bg-gray-400 hover:text-white cursor-pointer"
            >
              Add Varient
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div className="block w-full h-3/5  overflow-x-auto border-t border-blue-50">
            <table className="items-center w-full bg-transparent border-collapse ">
              <thead className="thead-light sticky top-0 select-none z-10">
                <tr className="bg-gray-200">
                  <th className="px-2 py-2"> Title</th>
                  <th className="px-5 py-2"> Release date</th>
                  <th className="px-5 py-2">Status</th>
                  <th className="px-3 py-2">Options</th>
                </tr>
              </thead>
              <tbody>
                {varients.map((varient, i) => (
                  <tr key={i} onClick={() => {}} className="border-b z-0">
                    <td className="px-2 py-2"> {varient.title}</td>
                    <td className="px-5 py-2"> {varient.release_date}</td>
                    <td className="px-5 py-2">
                      <ToggleButton
                        onToggle={(v) => onChangeVisibility(varient.id, v)}
                        value={varient.active}
                        trueLabel="Live"
                        falseLabel="Offline"
                      />
                    </td>
                    <td className="px-2 py-2">
                      <DropDownMenu
                        title={
                          <div className="p-2 m-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                              />
                            </svg>
                          </div>
                        }
                        options={[
                          <div
                            onClick={() =>
                              history.push(urls.priceListing, {
                                varient_id: varient.id,
                                price: varient.price,
                              })
                            }
                            className="rounded border-b px-2 py-1 hover:bg-gray-100 cursor-pointer"
                          >
                            Prices
                          </div>,
                          <div
                            onClick={() =>
                              history.push(urls.galleryListing, {
                                varient_id: varient.id,
                                gallery: varient.gallery,
                              })
                            }
                            className="rounded border-b px-2 py-1 hover:bg-gray-100  cursor-pointer"
                          >
                            Gallery
                          </div>,
                          // <div className="rounded border-b px-2 py-1 hover:bg-gray-100  cursor-pointer">
                          //   View
                          // </div>,
                          <div
                            onClick={() => {
                              setselectedVarient(varient);
                            }}
                            className=" flex items-center rounded border-b px-2 py-1 hover:bg-gray-100  cursor-pointer"
                          >
                            Edit
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-4 w-4 ml-2"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                              />
                            </svg>
                          </div>,
                          <>
                            {isAuthorised({
                              module: MODULE.content,
                              action: [ACTION.DELETE],
                            }) && (
                              <div
                                onClick={() => onDelete(varient.id)}
                                className="rounded border-b px-2 py-1 flex items-center text-red-500 hover:bg-red-800 hover:text-white  cursor-pointer"
                              >
                                Delete
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-4 w-4 ml-2"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                  />
                                </svg>
                              </div>
                            )}
                          </>,
                        ]}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex justify-center items-center my-5">
            <p className="mx-5">Total Records : {total}</p>
            <div className="">
              <label htmlFor="count">Records per page</label>
              <select
                className=" mx-2 px-3 py-2 bg-gray-100  focus:outline-none rounded"
                name="count"
                id="count"
                onChange={(e) => {
                  setCount(parseInt(e.target.value));
                }}
                value={count}
              >
                <option selected value="10">
                  10
                </option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>
          <Pagination
            page={page}
            itemCount={varients.length}
            total={total}
            onChange={(p) => {
              setPage(p);
            }}
            count={count}
          />
        </div>
      </div>
    </>
  );
};

export default VarientListing;
