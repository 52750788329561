import { AttachmentModel } from "../../../service/models/attachment_modal";
import { BaseModel } from "../../../service/models/base";
import { LedgerEntryModel, OrderDetail, VoucherModel } from "./common_model";
import { PartyModel } from "./party_model";
import { SalesVoucherModel } from "./sale_model ";

export interface RecieptVoucherModel extends VoucherModel {
  voucher_type: "Reciept";
  entry_type: string;
  date: Date;
  reciept_ledger_entry?: LedgerEntryModel;
  reciept_ledger_entry_id: string;
  party_ledger_entry?: LedgerEntryModel;
  party_ledger_entry_id: string;

  sub_total: number;
  total: number;
  reciept_split: {
    id?: string;
    voucher_id: string;
    amount: number;
    voucher: SalesVoucherModel;
  }[];
  txn_detail?: {
    txn_id: string;
    mode: string;
    date: Date;
  };

  // party_detail?: PartyModel;
  // party_id?: string;
  // order_detail?: OrderDetail;
}

export const recieptDefaultValue: RecieptVoucherModel = {
  entry_type: "Bill",
  voucher_type: "Reciept",
  date: new Date(),
  reciept_ledger_entry_id: "",
  party_ledger_entry_id: "",
  sub_total: 0,
  total: 0,
  reciept_split: [],
  attachments: [],
};
