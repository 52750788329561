import { mdiDownload, mdiOpenInNew } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useState, useEffect } from "react";
import { useAuth } from "../../../../../context_providers/auth/authProvider";
import {
  ModalAlignment,
  ModalType,
  useModal,
} from "../../../../../context_providers/modal/modal_context";
import {
  useToast,
  ToastType,
} from "../../../../../context_providers/toast/toast_ctx";

import { InvoiceModel } from "../../../../../service/models/orders/invoice_model";

import { GetInvoiceOfRefApi } from "../../../../../service/repos/invoice_service";
import { SendOrderMailApi } from "../../../../../service/repos/order_repo";

import { mailType, getMailTemplates } from "../../../../../utils/constant";
import {
  MODULE,
  ACTION,
  OrderLogType,
  PaymentRefType,
} from "../../../../../utils/enums";

import LoadingWidget from "../../../../components/loading_spinner";
import DrawerModal, {
  DrawerModalLoaction,
} from "../../../../new_components/common/drawer_modal";
import ActionHistory from "./history_logs";

interface CommunicationWidgetProps {
  order_id: string;
}

const CommunicationWidget: FunctionComponent<CommunicationWidgetProps> = ({
  order_id,
}) => {
  const { showModal } = useModal();
  // const { order_id } = useParams<{ order_id: string }>();

  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);

  const { user, isAuthorised } = useAuth();

  const [disabled, setDisabled] = useState(true);

  const [showHistory, setShowHistory] = useState(false);
  const [selectedMailTemplate, setSelectedMailTemplate] = useState("");
  const [invoiceId, setInvoiceId] = useState(0);
  const [invoices, setInvoices] = useState<InvoiceModel[]>([]);

  const getInvoices = async () => {
    setLoading(true);
    const res = await GetInvoiceOfRefApi({
      ref_id: order_id,
      ref_type: PaymentRefType.order,
    });
    if (res.success) {
      setInvoices(res.data);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };

  const sendMail = async () => {
    if (
      selectedMailTemplate &&
      parseInt(selectedMailTemplate) === mailType.invoice &&
      invoiceId === 0
    ) {
      alert("Please select invoice");
      return;
    }
    setLoading(true);
    if (selectedMailTemplate) {
      const res = await SendOrderMailApi({
        order_id: order_id!,
        template_id: parseInt(selectedMailTemplate),
        invoice_id: invoiceId,
      });
      if (res.success) {
        showToast({ type: ToastType.success, text: res.error });
      } else showToast({ type: ToastType.error, text: res.error ?? "" });
      setLoading(false);
    }
  };
  useEffect(() => {
    if (selectedMailTemplate === mailType.invoice.toString()) getInvoices();
  }, [selectedMailTemplate]);

  useEffect(() => {
    setDisabled(
      !isAuthorised({
        module: MODULE.order_customer_mail,
        action: ACTION.CREATE,
      })
    );
  }, []);
  return (
    <div className="mx-2 my-5 p-3 ">
      {showHistory && (
        <DrawerModal
          location={DrawerModalLoaction.right}
          onClose={() => setShowHistory(false)}
          show={showHistory}
          title="Mail sent"
        ></DrawerModal>
      )}
      <div className="grid grid-cols-1">
        <div className="">
          {/* <h5 className="text-xl mb-3 font-semibold">Send mail and sms</h5> */}
          <div className="flex flex-col">
            <label htmlFor="mail" className="text-sm font-semibold mb-2">
              Mail tamplate
            </label>
            <select
              disabled={disabled}
              className="text-sm font-semibold border rounded p-2 focus:outline-none"
              name="template_id"
              id="mail"
              // value={selectedMailTemplate}
              onChange={(e) => setSelectedMailTemplate(e.target.value)}
            >
              <option value="" hidden>
                select
              </option>
              {Object.values(mailType).map((t) => (
                <option value={t}>{getMailTemplates(t)?.name}</option>
              ))}
            </select>
          </div>
          {selectedMailTemplate === mailType.invoice.toString() && (
            <div className="my-2">
              <InvoiceWidget
                selected_invoice={invoiceId}
                invoices={invoices}
                onSelect={(invoice) => setInvoiceId(invoice.id!)}
              />
            </div>
          )}
          {selectedMailTemplate && (
            <div className="bg-gray-50 rounded p-2 text-sm text-gray-500 my-3">
              {" "}
              <p>
                Subject :{" "}
                {
                  getMailTemplates(parseInt(selectedMailTemplate), order_id)
                    ?.sub
                }
              </p>
              <br />
              <p>
                Body :
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      getMailTemplates(parseInt(selectedMailTemplate), order_id)
                        ?.body ?? "",
                  }}
                />
              </p>
            </div>
          )}
          <p className="text-xs text-gray-400 italic">
            Note : Mail and SMS will be send to registered <b>mobile</b> and
            <b> Email</b>
          </p>
          <div className="flex gap-2 justify-end mt-2">
            <button
              onClick={() => {
                showModal({
                  title: "Mail Sent",
                  type: ModalType.drawer,
                  alignment: ModalAlignment.right,
                  container: (id) => (
                    <ActionHistory
                      order_id={order_id}
                      log_type={OrderLogType.cust_mail}
                    />
                    // <ChatHistory
                    //   order_id={order_id as any}
                    //   onClose={(v) => setShowHistory(false)}
                    //   show={showHistory}
                    // />
                  ),
                });
              }}
              className=" flex items-center rounded bg-white px-2 py-1 text-gray-500 hover:text-white hover:bg-myPrimaryColor transition-all duration-300 "
            >
              History
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 ml-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                />
              </svg>
            </button>
            {selectedMailTemplate &&
              ((selectedMailTemplate === mailType.invoice.toString() &&
                invoices.length > 0) ||
                selectedMailTemplate !== mailType.invoice.toString()) && (
                <button
                  disabled={disabled}
                  onClick={() => sendMail()}
                  className=" flex items-center rounded bg-white px-2 py-1 text-gray-500 hover:text-white hover:bg-myPrimaryColor transition-all duration-300"
                >
                  Send
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 ml-2 transform rotate-90"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                    />
                  </svg>
                </button>
              )}
          </div>
        </div>
      </div>{" "}
      <LoadingWidget loading={loading} />
    </div>
  );
};

export default CommunicationWidget;

interface InvoiceWidgetProps {
  selected_invoice: number;
  onSelect: (data: InvoiceModel) => void;
  invoices: InvoiceModel[];
}

const InvoiceWidget: FunctionComponent<InvoiceWidgetProps> = (props) => {
  const heads = [" ", "Date", "Invoice #", "Ref #", "Ref type", "Action"];
  return (
    <div className=" overflow-auto max-h-96 my-3 border-red-400">
      <table className="border divide-y divide-gray-200 relative">
        <thead className="bg-gray-200 sticky  top-0">
          <tr>
            {heads.map((h) => (
              <th
                key={h}
                scope="col"
                className="px-3 py-2 text-xs font-medium text-center text-black uppercase tracking-wider"
              >
                {h}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200  mx-2 text-xs">
          {props.invoices.map((invoice) => {
            //
            return (
              <tr
                key={invoice.id}
                // onDoubleClick={() => props.onTxnClick(txn.id!)}
              >
                <td>
                  <input
                    type="radio"
                    name="invoice"
                    defaultChecked={props.selected_invoice === invoice.id}
                    id={invoice.id.toString()}
                    onClick={() => props.onSelect(invoice)}
                  />
                </td>
                <td className="px-3 py-2 whitespace-nowrap text-xs font-medium text-gray-900">
                  {new Date(invoice.created_at).toLocaleString()}
                </td>
                <td className="px-3 py-2 whitespace-nowrap  ">
                  {/* <NavLink to={URLS.transactionDetail + "?txn_id=" + txn.id}> */}
                  {invoice.invoice_no}
                  {/* </NavLink> */}
                </td>
                <td className="px-3 py-2 whitespace-nowrap  ">
                  {/* <NavLink to={URLS.transactionDetail + "?txn_id=" + txn.id}> */}
                  {invoice.ref_id}
                  {/* </NavLink> */}
                </td>
                <td className="px-3 py-2 whitespace-nowrap  ">
                  {/* <NavLink to={URLS.transactionDetail + "?txn_id=" + txn.id}> */}
                  {invoice.ref_type}
                  {/* </NavLink> */}
                </td>

                <td align="center" className="">
                  <div className="flex gap-2">
                    <a className="d-block" target="_blank" href={invoice.url}>
                      <Icon path={mdiOpenInNew} className="w-5 h-5"></Icon>
                    </a>
                    <a href={invoice.url} download={"invoice.pdf"}>
                      <Icon path={mdiDownload} className="w-5 h-5"></Icon>
                    </a>{" "}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>{" "}
      {props.invoices.length === 0 && (
        <div className="text-lg text-gray-400 font-semibold my-10 flex justify-center ">
          <div className=""> No Invoice found !</div>
        </div>
      )}
    </div>
  );
};
