import moment from "moment";
import {
  FunctionComponent,
  MouseEventHandler,
  useEffect,
  useState,
} from "react";
import { OrderModel } from "../../../../../service/models/orders/order_model";
import { GetOrdersApi } from "../../../../../service/repos/order_repo";
import { exportCSV } from "../../../../../utils/csv_generator";
import {
  OrderTypeLabel,
  OrderType,
  OrderStatusLabel,
  OrderStatus,
} from "../../../../../utils/enums";
import LoadingWidget from "../../../../components/loading_spinner";
import StadiumButton from "../../../../new_components/common/stadium_button";
import jsonexport from "jsonexport";
import { ExportToCsv } from "export-to-csv";
import {
  exportComplaints,
  getComplaints,
} from "../../../../../service/repos/support/complaint_repo";
import { ComplaintFilterModle } from "..";
import { Complaintmodel } from "../../../../../service/models/complaint_model";
import {
  useToast,
  ToastType,
} from "../../../../../context_providers/toast/toast_ctx";
import { UTCToLocalDate } from "../../../../../utils/date_util";
import { mdiDownload } from "@mdi/js";
import Icon from "@mdi/react";
import { toQueryString } from "../../../../../service/api_client";
import { offsetM, offsetMs } from "../../../../../utils/constant";
interface ExportComplaintsProps {
  filter: ComplaintFilterModle;
}

const ExportComplaints: FunctionComponent<ExportComplaintsProps> = (props) => {
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);

  const [fromDate, setFromdate] = useState<Date>();
  const [toDate, setTodate] = useState<Date>();

  const getOrders = async () => {
    if (!fromDate || !toDate) {
      showToast({
        type: ToastType.error,
        text: "Please select date",
      });

      return;
    } else if (
      moment(fromDate, "YYYY-MM-DD").isAfter(moment(toDate, "YYYY-MM-DD"))
    ) {
      showToast({
        type: ToastType.error,
        text: "From date should not be greater than to date",
      });
      return;
    }
    setLoading(true);
    // const res = await exportComplaints({
    //   filterData: {
    //     ...props.filter.filterData,
    //     fromDate: fromDate as any,
    //     toDate: toDate as any,
    //   },
    // });

    const a = document.createElement("a");
    a.target = "_blank";
    a.href = `${
      process.env.REACT_APP_BACKEND_BASE_URL
    }/api/v3/complaint/export${toQueryString({
      query: JSON.stringify({
        filterData: {
          ...props.filter.filterData,
          fromDate: fromDate as any,
          toDate: toDate as any,
        },
      }),
    })}`;

    setTimeout(() => {
      setLoading(false);
      showToast({ type: ToastType.success, text: "Download started !" });
      a.click();
    }, 1000);
  };
  useEffect(() => {
    if (props.filter.filterData?.fromDate && props.filter.filterData.toDate) {
      setFromdate(props.filter.filterData.fromDate);
      setTodate(props.filter.filterData.toDate);
    }
  });
  return (
    <div className="p-5">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <div className="">
          <label htmlFor="from-date">From</label>
          <input
            id="from-date"
            className="border rounded p-2 w-full focus:outline-none outline-none"
            type="date"
            name="fromDate"
            placeholder="From"
            required
            value={
              fromDate &&
              moment(fromDate).utcOffset(offsetM).format("YYYY-MM-DD")
            }
            onChange={(e) => {
              let d = e.target.valueAsDate;
              d?.setUTCHours(0, 0, 0, 0);
              d = new Date(d!.getTime() - offsetMs);

              if (toDate && moment(d).isAfter(moment(toDate))) {
                showToast({
                  type: ToastType.error,
                  text: "From date should not be greater than todate",
                });
                return;
              }
              setFromdate(d);
            }}
          />
        </div>
        <div className="">
          <label htmlFor="to-date">To</label>
          <input
            id="to-date"
            className="border rounded p-2 w-full focus:outline-none outline-none"
            type="date"
            name="toDate"
            placeholder="To"
            required
            value={
              toDate && moment(toDate).utcOffset(offsetM).format("YYYY-MM-DD")
            }
            min={
              fromDate
                ? moment(fromDate).utcOffset(offsetM).format("YYYY-MM-DD")
                : ""
            }
            onChange={(e) => {
              let d = e.target.valueAsDate;
              d?.setUTCHours(23, 59, 59, 999);
              d = new Date(d!.getTime() - offsetMs);
              if (fromDate && moment(fromDate).isAfter(moment(d))) {
                showToast({
                  type: ToastType.error,
                  text: "To date should  be greater than From date",
                });
                return;
              }
              setTodate(d);
            }}
          />
        </div>
      </div>
      <div className="flex justify-end my-2">
        <button
          onClick={getOrders}
          className="flex items-center gap-1 border rounded px-3 py-2"
        >
          <Icon path={mdiDownload} className="h-4 w-5"></Icon>
          <p>Download</p>
        </button>
      </div>
      <LoadingWidget loading={loading} />
    </div>
  );
};

export default ExportComplaints;
