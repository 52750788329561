import Icon from "@mdi/react";
import { FunctionComponent, useState } from "react";
import { useOrderCreateCtx } from "../../../../../context_providers/order_create_context";
import DrawerModal, {
  DrawerModalLoaction,
} from "../../../../new_components/common/drawer_modal";

import UploadAttachmentWidget from "./upload_attachment_widget";

interface AttachmentSectionProps {}

const AttachmentSection: FunctionComponent<AttachmentSectionProps> = () => {
  const { attachments, setAttachments } = useOrderCreateCtx();
  const [showAdd, setShowAdd] = useState(false);

  return (
    <>
      {showAdd && (
        <DrawerModal
          location={DrawerModalLoaction.right}
          onClose={() => setShowAdd(false)}
          show={showAdd}
          title="Add Attachment"
        >
          <UploadAttachmentWidget
            onClose={() => setShowAdd(false)}
            onSubmit={(data) => {
              setAttachments((o) => [...o, data]);
              setShowAdd(false);
            }}
          />
        </DrawerModal>
      )}
      <div className="flex justify-between">
        <h3> Attachments</h3>
        <button
          onClick={() => setShowAdd(true)}
          className="px-3 py-1 rounded bg-myPrimaryColor text-white text-sm"
        >
          Add
        </button>
      </div>
      {attachments.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-2">
          {attachments.map((data, i) => (
            <div className=" overflow-hidden rounded my-5 bg-gray-100">
              <div className="h-60">
                {data.file.type.startsWith("image") ? (
                  <img
                    src={URL.createObjectURL(data.file)}
                    alt=""
                    className="object-contsain w-full h-full"
                  />
                ) : (
                  <embed
                    src={URL.createObjectURL(data.file)}
                    type="application/pdf"
                    className="w-auto h-full"
                  ></embed>
                )}
              </div>
              <div className="flex justify-between p-2">
                <div className=" text-sm">{data.category}</div>
                <button
                  onClick={() => {
                    if (window.confirm("Are you sure to delete ?"))
                      setAttachments((o) => o.filter((f, j) => i !== j));
                  }}
                  className="text-red-400 text-sm"
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <>
          <div className="flex flex-col justify-center items-center h-96 ">
            <h3 className="text-xl font-semibold text-gray-400">
              No attachment selected
            </h3>
          </div>
        </>
      )}
    </>
  );
};

export default AttachmentSection;
