import {
  mdiAlert,
  mdiAlertDecagram,
  mdiArrowBottomLeft,
  mdiCashPlus,
  mdiCheck,
  mdiCheckDecagram,
  mdiChevronRight,
  mdiClose,
  mdiCloseOctagon,
  mdiCreditCardOutline,
  mdiDotsVertical,
  mdiInformationOutline,
} from "@mdi/js";
import Icon from "@mdi/react";
import moment from "moment";
import { FunctionComponent, useState } from "react";
import { NavLink, Route, useHistory, useRouteMatch } from "react-router-dom";
import { useOrderDetailCtx } from "../../../../../context_providers/order_detail_context";
import SubPaymentSectionWidget from "./payments/payment_section_widget";
import { currencyFormat } from "../../../../../utils/orders_utils";
import { SubscriptionBillModel } from "../../../../../service/models/orders/subscription_plan_model";
import ModalWidget from "../../../../../context_providers/modal/modal_widget";
import {
  ModalAlignment,
  ModalType,
  useModal,
} from "../../../../../context_providers/modal/modal_context";
import Dropdown from "../../../../new_components/common/dropdown";

interface PaymentSectionProps {}

const PaymentSection: FunctionComponent<PaymentSectionProps> = () => {
  const { url, path } = useRouteMatch();
  const { subBills } = useOrderDetailCtx();
  const { showModal } = useModal();
  const history = useHistory();

  function row(bill: SubscriptionBillModel, i: number): JSX.Element {
    return (
      <tr
        title="Double click to view details"
        onDoubleClick={() => {
          showBillDetail(bill);
        }}
        key={i}
        className=" cursor-pointer  shadow my-2 bg-white dark:bg-darkCardColor rounded"
      >
        <td align="center" className="py-1 px-3">
          {i + 1}
        </td>
        <td align="center" className="py-2 px-3">
          {new Date(bill.date!).toLocaleString("en-IN")}
        </td>
        <td align="center" className="py-1 px-3">
          {bill.plan_id}
        </td>
        <td align="center" className="py-2 px-3">
          {moment(bill.from_date).format("MMM-YYYY")}
        </td>
        <td align="center" className="py-2 px-3">
          {bill.bill_id}
        </td>
        <td align="center" className="py-2 px-3">
          {bill.type}
        </td>

        <td align="right" className="px-1">
          {currencyFormat(bill.subtotal)}
        </td>
        <td align="right" className="px-1">
          {currencyFormat(bill.tax)}
        </td>
        <td align="right" className="px-1">
          {currencyFormat(bill.total)}
        </td>
        <td align="right" className="px-1">
          {currencyFormat(bill.paid)}
        </td>
        <td align="right" className="px-1">
          {currencyFormat(bill.total - bill.paid)}
        </td>
        <td align="center">
          <b>
            {bill.total === bill.paid
              ? "Full Paid"
              : bill.paid > 0
              ? "Partial Paid"
              : "Pending"}
          </b>
          {/* <div className="">
        <select
          onChange={(e) =>
            changeStatus({
              id: bill.id,
              status: e.target.value,
            })
          }
          className="dark:bg-darkBgcolor rounded-md p-1"
          name=""
          id=""
          value={bill.status}
        >
          <option value="" hidden>
            Select
          </option>
          {Object.values(venueBookingStatus).map((s, i) => (
            <option key={i} value={s}>
              {s}
            </option>
          ))}
        </select>
      </div> */}
        </td>
        <td>
          {" "}
          <div className="flex justify-center items-center">
            <Dropdown
              button={
                <div title="More" className="    ">
                  <Icon path={mdiDotsVertical} size={1}></Icon>
                </div>
              }
              items={[
                {
                  onClick: () => {
                    // setselectedBill(bill.bill_id);
                    history.push(`${url}/bills/${bill.bill_id}`);
                  },
                  leading: <Icon path={mdiCreditCardOutline} size={0.8}></Icon>,
                  title: <div className="text-sm ">Payments</div>,
                  trailling: <Icon path={mdiChevronRight} size={0.8}></Icon>,
                },
                {
                  onClick: () => {
                    showBillDetail(bill);
                  },
                  leading: (
                    <Icon path={mdiInformationOutline} size={0.8}></Icon>
                  ),
                  title: <div className="text-sm ">Details</div>,
                  trailling: <Icon path={mdiChevronRight} size={0.8}></Icon>,
                },
                // {
                //   onClick: () => {},
                //   leading: <Icon path={mdiArrowTopRight} size={0.8}></Icon>,
                //   title: <div className="text-sm ">Export</div>,
                //   trailling: <Icon path={mdiChevronRight} size={0.8}></Icon>,
                // },
              ]}
            />
          </div>
        </td>
      </tr>
    );
  }
  const showBillDetail = (data: SubscriptionBillModel) => {
    showModal({
      title: `Bill #${data.bill_id}`,
      type: ModalType.drawer,
      alignment: ModalAlignment.right,
      container: (id) => (
        <div className=" text-sm  p-3">
          <div className="bg-white dark:bg-darkCardColor  p-3 md:p-5 relative text-xs border border-dashed rounded-lg ">
            <div className="flex justify-end ">
              <div className="flex  ">
                Date: {moment(data.date).format("lll")}
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex justify-between items-center">
                <h5 className="text-gray-400">Plan id</h5>
                <h5 className="text-right">{data.plan_id}</h5>
              </div>
              <div className="flex justify-between items-center">
                <h5 className="text-gray-400">Bill Period</h5>
                <h5 className="text-right">
                  {" "}
                  {moment(data.from_date).format("MMM-YYYY")}
                </h5>
              </div>
              <div className="flex justify-between items-center">
                <h5 className="text-gray-400">Bill type</h5>
                <h5 className="text-right">{data.type}</h5>
              </div>
              <div className="flex justify-between items-center">
                <h5 className="text-gray-400">Status</h5>
                <h5 className="text-right">
                  {data.total === data.paid
                    ? "Full Paid"
                    : data.paid > 0
                    ? "Partial Paid"
                    : "Pending"}
                </h5>
              </div>
              {/* <div className="flex justify-between items-center">
                <h5 className="text-gray-400">Refund status</h5>
                <h5 className="text-right">{data.refund_status}</h5>
              </div> */}
            </div>
            <hr className="border-dashed my-5 w-full" />{" "}
            <div className="grid grid-cols-6 my-2 ">
              <h4 className="   font-semibold  col-span-3">PARTICULAR</h4>
              <h4 className="   font-semibold text-center ">UNITS</h4>
              <h4 className="   font-semibold  text-right ">PRICE</h4>
              <h4 className="   font-semibold text-right">AMOUNT</h4>
            </div>{" "}
            <hr className="border-dashed     w-full" />
            {data.items.map((item, i) => {
              return (
                <>
                  <div
                    key={i}
                    className="bg-white dark:bg-darkCardColor rounded-xl  grid grid-cols-6 text-xs my-2"
                  >
                    <div className="col-span-3">{item.name}</div>
                    <div className="text-center ">
                      {item.qty} {item.qty_type}
                    </div>
                    <div className="text-right">
                      {currencyFormat(item.price)}
                    </div>
                    <div className="text-right">
                      {currencyFormat(item.amount)}
                    </div>
                  </div>{" "}
                </>
              );
            })}{" "}
            <hr className="border-dashed  w-full" />
            <div className="flex flex-col  my-1">
              <div className="flex justify-between items-center">
                <h5 className="text-gray-400">SUBTOTAL</h5>
                <h5 className="text-right">{currencyFormat(data.subtotal)}</h5>
              </div>
              <div className="flex justify-between items-center  my-1">
                <div className="">
                  <h5 className="text-gray-400">TAX</h5>
                  {data.tax_items.map((tax) => (
                    <div>
                      {tax.name} {tax.amount}
                    </div>
                  ))}
                </div>
                <h5 className="text-right">{currencyFormat(data.tax)}</h5>
              </div>{" "}
              <hr className="border-dashed  w-full" />
              <div className="flex justify-between items-center  my-1">
                <h5 className="text-gray-400">TOTAL</h5>
                <h5 className="text-right">{currencyFormat(data.total)}</h5>
              </div>
              <div className="flex justify-between items-center  my-1">
                <h5 className="text-gray-400">PAID</h5>
                <h5 className="text-right">{currencyFormat(data.paid)}</h5>
              </div>{" "}
              <hr className="border-dashed  w-full" />
              <div className="flex justify-between items-center font-semibold  my-1">
                <h5 className="text-gray-400 ">BALANCE</h5>
                <h5 className="text-right">
                  {currencyFormat(data.total - data.paid)}
                </h5>
              </div>
            </div>
          </div>
        </div>
      ),
    });
  };
  return (
    <>
      <div className="   ">
        {/* {subBills.length === 0 ? (
          <div className="my-10 flex flex-col gap-5 items-center justify-center text-2xl font-semibold text-gray-400">
            <p> No Bills Found !</p>
          </div>
        ) : (
          // <div className="grid grid-cols-1  gap-5">
          //   <div className="flex items-end    gap-5 overflow-auto p-2">
          //     <div
          //       style={{ left: "-10px" }}
          //       className="sticky px-1 whitespace-nowrap bg-white flex flex-col  gap-2 justify-between items-start"
          //     >
          //       <div className="flex flex-col items-center text-sm border rounded p-2 invisible  bg-teal text-white">
          //         <p className="text-lg">Nov</p>
          //         <p className="text-xs">2022</p>
          //       </div>
          //       <p className="my-1 text-gray-600 font-semibold">Payment Due</p>
          //       <p className="my-1 font-semibold">Payment Done</p>
          //       <div className="invisible">
          //         <Icon className={`h-4 w-4  `} path={mdiClose}></Icon>
          //       </div>
          //     </div>
          //     <div className="h-8 w-1 rounded bg-gray-200 mb-10"></div>
          //     {subBills.map((payment, i) => {
          //       return BillTile(payment, url, i);
          //     })}
          //     {subBills.map((payment, i) => {
          //       return BillTile(payment, url, i);
          //     })}
          //     {subBills.map((payment, i) => {
          //       return BillTile(payment, url, i);
          //     })}
          //     {subBills.map((payment, i) => {
          //       return BillTile(payment, url, i);
          //     })}
          //     {subBills.map((payment, i) => {
          //       return BillTile(payment, url, i);
          //     })}
          //     {subBills.map((payment, i) => {
          //       return BillTile(payment, url, i);
          //     })}
          //     {subBills.map((payment, i) => {
          //       return BillTile(payment, url, i);
          //     })}
          //     {subBills.map((payment, i) => {
          //       return BillTile(payment, url, i);
          //     })}
          //     {subBills.map((payment, i) => {
          //       return BillTile(payment, url, i);
          //     })}
          //     {subBills.map((payment, i) => {
          //       return BillTile(payment, url, i);
          //     })}
          //     {subBills.map((payment, i) => {
          //       return BillTile(payment, url, i);
          //     })}
          //     {subBills.map((payment, i) => {
          //       return BillTile(payment, url, i);
          //     })}
          //     {subBills.map((payment, i) => {
          //       return BillTile(payment, url, i);
          //     })}
          //   </div>
          // </div>

         
        )} */}

        <div className="    overflow-auto  rounded-md h-96 border bg-white">
          <table
            className="table-auto w-full border-separate  text-xs   "
            style={{ borderSpacing: "0px 0.3rem" }}
          >
            <thead className="sticky  top-0 z-10 text-sm font-medium tracking-wider shadow px-2 bg-white dark:bg-darkCardColor">
              <tr>
                <th align="center" className="py-2 px-3">
                  SL.No
                </th>
                <th align="center" className="py-2 px-3">
                  Date
                </th>
                <th align="center" className="py-2 px-3">
                  Plan ID
                </th>
                <th align="center" className="py-2 px-3">
                  Billing Period
                </th>
                <th align="center">Bill ID</th>
                <th align="center" className="py-2 px-3">
                  Type
                </th>
                <th align="center">Subtotal</th>
                <th align="center">Tax</th>
                <th align="right">Total</th>
                <th align="right">Paid</th>
                <th align="right">Balance</th>
                <th align="center">Status</th>
                <th align="center"></th>
              </tr>
            </thead>
            <tbody className="">
              {subBills.length === 0 ? (
                <tr>
                  <td colSpan={11}>
                    {" "}
                    <div className="my-10 flex flex-col gap-5 items-center justify-center text-2xl font-semibold text-gray-400">
                      <p> No Bills Found !</p>
                    </div>
                  </td>
                </tr>
              ) : (
                subBills.map((bill, i) => row(bill, i))
              )}
            </tbody>
          </table>
        </div>
        <p className="  flex items-center text-gray-400 text-sm ">
          {" "}
          Click{" "}
          <span>
            {" "}
            <Icon path={mdiDotsVertical} size={0.7}></Icon>
          </span>{" "}
          on bill and select payments to view payment{" "}
        </p>
      </div>
      {/* <Route exact path={`${path}`}>
        <div className="bg-white shadow p-5 my-2 ">
          <div className="my-10 flex flex-col gap-5 items-center justify-center text-2xl font-semibold text-gray-400">
            <p> Please select a bill !</p>
          </div>
        </div>
      </Route> */}

      <Route path={`${path}/bills/:bill_id`}>
        <SubPaymentSectionWidget />
      </Route>

      {/* {!selectedBill && <SubPaymentSectionWidget />} */}
    </>
  );
};

export default PaymentSection;
function BillTile(
  payment: SubscriptionBillModel,
  url: string,
  i: number
): JSX.Element {
  return (
    <>
      <div className="flex flex-col  gap-2 justify-between items-center">
        <p className="text-gray-400 ">#{payment.bill_id}</p>
        <NavLink
          to={`${url}/bills/${payment.bill_id}`}
          activeClassName=" text-white bg-green-300   "
          className={`flex flex-col items-center text-sm  rounded-md p-3  cursor-pointer   
                         bg-teal text-white  hover:scale-105 transform duration-100    
                      `}
        >
          <p className="text-lg">
            {moment.monthsShort()[payment.from_date.getMonth()]}
          </p>
          <p className="text-xs">{payment.from_date.getFullYear()}</p>
        </NavLink>
        <p
          className={`my-1  font-semibold       text-gray-600  
                      `}
        >
          {currencyFormat(payment.total - payment.paid)}
        </p>
        <p className={`my-1   font-semibold    text-teal `}>{payment.paid}</p>
        <div
          className={`font-semibold text-${
            payment.total === payment.paid
              ? "green-400"
              : payment.paid > 0
              ? "yellow-400"
              : "red-400"
          }`}
        >
          {payment.total === payment.paid
            ? "Full Paid"
            : payment.paid > 0
            ? "Partial Paid"
            : "Pending"}
          {/* <Icon
          className={`h-4 w-4 text-${
            payment.total === payment.paid
              ? "green-400"
              : payment.paid > 0
              ? "yellow-400"
              : "red-400"
          }`}
          path={
            payment.total === payment.paid
              ? mdiCheckDecagram
              : payment.paid > 0
              ? mdiAlertDecagram
              : mdiCloseOctagon
          }
        ></Icon> */}
        </div>
      </div>
      {/* {i < subBills.length && (
        <div className="h-8 w-1 rounded bg-gray-200 mb-10"></div>
      )} */}
    </>
  );
}
