const Loader = () => {
  return (
    <div
      className="spinner-border"
      role="status"
      style={{ position: "absolute", top: "50%", left: "50%" }}
    >
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export default Loader;
