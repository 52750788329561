import { RouteParam } from "../../../routes";
import { ACTION, MODULE } from "../../../utils/enums";
import { urls } from "../../../utils/urls";
import ManufacturingJournalListScreen from "../journals/manufacturing_journal/manufactuing_journal_list";
import ManufacturingJournal from "../journals/manufacturing_journal/manufacturing_journal_create/manufacturing_journal";

const manufacturing_routes: RouteParam[] = [
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.stock_manufacture_voucher,
    component: ManufacturingJournalListScreen,
    module: MODULE.inventory_journals,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.stock_manufacture_voucher + "/create",
    component: ManufacturingJournal,
    module: MODULE.inventory_journals,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.stock_manufacture_voucher + "/update/:id",
    component: ManufacturingJournal,
    module: MODULE.inventory_journals,
    main_module: MODULE.procurement_panel,
    action: [ACTION.UPDATE],
    operator: "or",
  },
];

export default manufacturing_routes;
