import { RouteParam } from ".";
import CustomersListingPage from "../ui/pages/customers/listings";
import InvoiceListingScreen from "../ui/pages/invoice/listing";

import TransactionPage from "../ui/pages/transactions/txn_listing";
import { ACTION, MODULE } from "../utils/enums";
import { urls } from "../utils/urls";

const payment_routes: RouteParam[] = [
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.transaction,
    component: TransactionPage,
    main_module: MODULE.payment_panel,
    module: MODULE.payment,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.invoice,
    component: InvoiceListingScreen,
    main_module: MODULE.payment_panel,
    module: MODULE.invoice,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.invoice,
    component: InvoiceListingScreen,
    main_module: MODULE.payment_panel,
    module: MODULE.invoice,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.customers,
    component: CustomersListingPage,
    main_module: MODULE.payment_panel,
    module: MODULE.customer_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
];

export default payment_routes;
