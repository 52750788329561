import { FunctionComponent } from "react";
import "../styles/index.css";

interface HrmLoaderProps {
  className: any;
}

const HrmLoader: FunctionComponent<HrmLoaderProps> = ({ className }) => {
  return (
    <div className={`hrm_loading ${className}`}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default HrmLoader;
