import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import {
  useToast,
  ToastType,
} from "../../../../../context_providers/toast/toast_ctx";
import { ComplaintCommentModel } from "../../../../../service/models/complaint_model";
import {
  CreateComplaintComment,
  getComplaintComments,
} from "../../../../../service/repos/support/complaint_repo";
import LoadingWidget from "../../../../components/loading_spinner";

interface CommentWidgetProps {
  complaint_id: string;
}

const CommentWidget: FunctionComponent<CommentWidgetProps> = ({
  complaint_id,
}) => {
  const { showToast } = useToast();
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState<ComplaintCommentModel[]>([]);
  const getComments = async () => {
    setLoading(true);
    const res = await getComplaintComments({
      complaint_id,
      page: 1,
      count: 50,
      all: true,
    });
    if (res.success) {
      setComments(res.data);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  const createComment = async (comment: string) => {
    setLoading(true);
    const res = await CreateComplaintComment({
      complaint_id,
      comment,
    });

    if (!res.success)
      showToast({ type: ToastType.error, text: res.error ?? "" });
    else getComments();
    setLoading(false);
  };
  useEffect(() => {
    getComments();
  }, []);
  return (
    <>
      {" "}
      <div className="flex-col justify-between h-full">
        <div className="overflow-auto" style={{ height: "85%" }}>
          {comments.length === 0 && (
            <p className="text-center my-10">No Comment!</p>
          )}
          {comments.map((c, i) => (
            <div key={i} className="p-2 border-b">
              <div className="flex items-center gap-2 m-1">
                <div className="bg-blue-500 text-white p-2 flex justify-center items-center rounded-full w-10 h-10">
                  {c.user?.charAt(0)}
                </div>
                <div className="text-sm font-normal">{c.user}</div>{" "}
                <div className="text-xs font-thin">
                  {moment(c.created_at, "YYYY-MM-DD HH:mm:ss").format(
                    "DD MMM YYYY hh:mm a"
                  )}
                </div>
              </div>

              <div className=" text-sm">{c.comment}</div>
            </div>
          ))}
        </div>
        <div style={{ height: "10%" }} className="flex  items-end mx-2 z-50">
          <div className="">
            <label htmlFor="comment">Add comment</label>
            <textarea
              id="comment"
              className="border rounded p-2 w-full"
              name="comment"
              placeholder="comment"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </div>
          <div className="mx-2 flex">
            <button
              onClick={() => {
                if (comment) {
                  createComment(comment);
                  setComment("");
                }
              }}
              type="button"
              className="m-1 bg-gray-200 rounded py-2 px-4 hover:bg-gray-400 hover:text-white"
            >
              Submit
            </button>
          </div>
        </div>
        <LoadingWidget loading={loading} />
      </div>
    </>
  );
};

export default CommentWidget;
