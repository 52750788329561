import { FunctionComponent } from "react";
import Layout from "../layout";

interface FilesUploadProps {}

const FilesUpload: FunctionComponent<FilesUploadProps> = () => {
  return (
    <Layout>
      <div className="">
        <div className="lg:pl-12 pb-12 pt-12">
          <div className="">Upload your gmc card here</div>
          <div className=""></div>
        </div>
      </div>
    </Layout>
  );
};

export default FilesUpload;
