import { RouteParam } from ".";
import InventoryListing from "../ui/pages/inventory";
import DeliveryChallanWidget from "../ui/pages/inventory/delivery_challan";
import InventoryOverViewPage from "../ui/pages/inventory/overview";
import Dashboard from "../ui/pages/dashboard";
import OrderCreatePage from "../ui/pages/orders/order_create";
import OrderDetailPage from "../ui/pages/orders/order_detail/order_detail_page";
import OrderListPage from "../ui/pages/orders/order_list";
import { ACTION, MODULE } from "../utils/enums";
import { urls } from "../utils/urls";

const inventory_routes: RouteParam[] = [
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.inventory_overview,
    component: InventoryOverViewPage,
    main_module: MODULE.device_inventory_panel,
    module: MODULE.device_inventory_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "and",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.inventory_listing,
    component: InventoryListing,
    main_module: MODULE.device_inventory_panel,
    module: MODULE.device_inventory_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.delivery_note,
    component: DeliveryChallanWidget,
    main_module: MODULE.device_inventory_panel,
    module: MODULE.device_inventory_panel,
    action: [ACTION.CREATE],
    operator: "or",
  },
];

export default inventory_routes;
