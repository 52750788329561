import {
  mdiAccount,
  mdiCheck,
  mdiCheckCircle,
  mdiEmail,
  mdiPhone,
} from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useModal } from "../../../../../../context_providers/modal/modal_context";
import { useOrderCreateCtx } from "../../../../../../context_providers/order_create_context";
import {
  useToast,
  ToastType,
} from "../../../../../../context_providers/toast/toast_ctx";
import { GetUsersRepo } from "../../../../../../service/repos/customer/customer_repo";
import ModalDialog from "../../../../../new_components/common/modal";
import SearchableField from "../../../../../new_components/common/search_with_suggestion/search_with_suggestion_field";
import AddCustomerWidget from "./customer_add_widget";
import debounce from "lodash.debounce";
import UserSearchField from "./user_search_widget";
import { UserModel } from "../../../../../../context_providers/auth/user_model";
interface CustomerWidgetProps {}

const CustomerWidget: FunctionComponent<CustomerWidgetProps> = () => {
  const { showToast } = useToast();
  const { setLoading } = useModal();
  const {
    order,
    setOrder,
    user,
    setUser,
    lastOrder,
    selectLastOrder,
    prefilled,
    setPrefilled,
  } = useOrderCreateCtx();

  const [search, setSearch] = useState("");

  const [addNew, setAddNew] = useState(false);
  const [suggestions, setSuggestions] = useState<
    {
      label: string;
      value: any;
    }[]
  >();
  const getUsers = async (query: string) => {
    setLoading(true);
    const res = await GetUsersRepo({ search: query, all: true });
    if (res.success) {
      setSuggestions(
        res.data.data.map(
          (cust: {
            uid: number;
            first_name: string;
            last_name: string;
            mobile?: string;
            email?: string;
          }) => ({
            label: `${cust.first_name} ${cust.last_name} (${cust.mobile})`,
            value: cust,
          })
        )
      );
      // setUsers(res.data);
    } else {
      showToast({ type: ToastType.error, text: res.error });
    }
    setLoading(false);
  };
  // useEffect(() => {
  //   if (search) {
  //     getUsers();
  //   } else {
  //     setSuggestions(undefined);
  //   }
  // }, [search]);
  const debouncedHandler = useCallback(
    debounce((query) => {
      if (query) getUsers(query);
      else setSuggestions(undefined);
    }, 500),
    []
  );
  // const debounceHandler = debounce((query) => {
  //   console.log(query);
  // }, 500);
  useEffect(() => {
    debouncedHandler(search);
  }, [search]);
  return (
    <>
      {/* {" "}
      {addNew && (
        <ModalDialog
          title="Add New Customer"
          show={addNew}
          onClose={() => setAddNew(false)}
        >
          <AddCustomerWidget
            onAdded={(user) => {
              setUser(user);
              setAddNew(false);
            }}
          />
        </ModalDialog>
      )} */}
      <div className="   text-sm">
        <div className="flex flex-col justify-between">
          <div className={`w-96  `}>
            {" "}
            <UserSearchField
              value={user as any}
              placeholder="Search customer"
              onSelect={function (data: UserModel): void {
                setUser(data as any);
              }}
            />
            {/* <SearchableField
              value={search}
              onChange={setSearch}
              onAddNew={() => setAddNew(true)}
              onSelect={(d) => {
                setSearch("");
                setUser(d.value);

                // setEdit(false);
              }}
              placeholder="Search customer"
              onSubmit={function (search: string): void {}}
              suggestions={suggestions}
            /> */}
          </div>
          {/* {!edit && (
            <button
              onClick={() => setEdit(true)}
              className="cursor-pointer hover:scale-125 transform duration-300 text-myPrimaryColor font-semibold"
            >
              Edit
            </button>
          )} */}
          <p className="italic text-xs text-gray-400">
            Please search customer and select from suggestions.
          </p>
        </div>
        {user && (
          <>
            <hr className="my-3" />
            <div className="py-2">
              <div className="flex gap-1">
                <Icon
                  path={mdiAccount}
                  className="h-4 w-4 text-gray-500"
                ></Icon>
                <p>
                  <b>
                    {user.first_name} {user.last_name}
                  </b>
                </p>
              </div>
              <div className="flex gap-1">
                <Icon path={mdiPhone} className="h-4 w-4 text-gray-500"></Icon>

                <p>{user.mobile}</p>
              </div>
              <div className="flex gap-1">
                <Icon path={mdiEmail} className="h-4 w-4 text-gray-500"></Icon>
                <p>{user.email}</p>
              </div>
            </div>
            <hr />
            <div className="text-sm">
              Last Order : {"  "}
              <Link
                target="_blank"
                to={`orders/${lastOrder?.order_id}`}
                className="text-blue-400"
              >
                {lastOrder?.order_id}
              </Link>
              <div className=" flex gap-2 items-center">
                <p> Do you want to pre fill order details from last order ?</p>
                {!prefilled ? (
                  <button
                    className="bg-teal text-white shadow rounded px-5 py-1"
                    onClick={() => {
                      selectLastOrder();
                      setPrefilled(true);
                    }}
                  >
                    Yes
                  </button>
                ) : (
                  <div className="flex gap-1 text-green-400">
                    Done{" "}
                    <Icon path={mdiCheckCircle} className="w-4 h-4 "></Icon>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CustomerWidget;
