import { FunctionComponent, useEffect, useState } from "react";
import { durationLabel, timeSince } from "../../../../../utils/date_util";

interface CountDownWidgetProps {
  date: Date | number;
  onTimeout: () => void;
}

const CountDownWidget: FunctionComponent<CountDownWidgetProps> = (props) => {
  const [duration, setduration] = useState(0);
  useEffect(() => {
    let timer = setInterval(() => {
      const t = new Date(props.date).getTime() - Date.now();
      setduration(t);
      if (t <= 0) {
        clearInterval(timer);
        props.onTimeout();
      }
    }, 1000);
    return () => {
      if (timer) clearInterval(timer);
    };
  }, []);
  return <>{durationLabel(duration)}</>;
};

export default CountDownWidget;
