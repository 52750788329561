import { mdiCheck, mdiClose, mdiPencil } from "@mdi/js";
import Icon from "@mdi/react";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { useAuth } from "../../../../context_providers/auth/authProvider";
import { useOrderDetailCtx } from "../../../../context_providers/order_detail_context";
import {
  ToastType,
  useToast,
} from "../../../../context_providers/toast/toast_ctx";

import {
  OrderType,
  orderCategories,
  orderSegment,
  LeadSource,
  OrderStatus,
  remarks,
  ACTION,
  MODULE,
  PO_STATUS,
  QcStatus,
  TransactionStatus,
  PaymentStatus,
  sale_type,
} from "../../../../utils/enums";
import EmployeeSearchWidget from "../../../new_components/emp_search_widget";
import { UTCToLocalDate } from "../../../../utils/date_util";
export interface OrderDetailModel {
  [key: string]: any;
}
export interface OrderDetailModel {
  order_id: string;
  type: string;

  payment_status: string;
  status: string;
  po_status: string;
  completed_at?: Date;
  order_date: Date;
  sales_person_id?: string;
  sales_person_name?: string;
  source?: string;
  demo?: boolean;
  expected_pay_date?: Date;
  next_delivery_date?: Date;
  category?: string;
  rm_uid?: string;
  rm_name?: string;
  rm2_uid?: string;
  rm2_name?: string;
  load_sanction?: number;
  subsidy_status?: string;
  qc_status?: string;
  registration_doc_issued?: string;
  no_device_registered?: number;
  meter_type?: string;
  registration_number?: string;
  subsidy_claimed_qty?: number;
  discom?: string;
  segment?: string;
  remark?: string;
  wiring_status?: string;
  site_survey?: string;
  survey_date?: Date;
  sld_and_boq_date?: Date;
  sld_and_boq_approval_date?: Date;
  installation_date?: Date;
  commission_date?: Date;
  dealer_code?: string;
}
const orderDetailDefaultValue: OrderDetailModel = {
  order_id: "",
  type: "",
  payment_status: "",
  status: "",
  po_status: "",
  order_date: new Date(),
};
interface OrderDetailSectionProps {}

const OrderDetailSection: FunctionComponent<OrderDetailSectionProps> = () => {
  const { showToast } = useToast();
  const { user, isAuthorised } = useAuth();
  const [disabled, setDisabled] = useState(false);
  const context = useOrderDetailCtx();
  const { getDiscoms, discoms } = context;
  const [edit, setEdit] = useState(false);
  const [order, setOrder] = useState<OrderDetailModel>({
    ...orderDetailDefaultValue,
  });
  useEffect(() => {
    setDisabled(
      !isAuthorised({ module: MODULE.order, action: [ACTION.UPDATE] })
    );
  }, []);
  useEffect(() => {
    setOrder({ ...context.order });
  }, [context.order]);
  const [saftyDisabled, setSaftyDisabled] = useState(true);
  useEffect(() => {
    setSaftyDisabled(
      !isAuthorised({
        module: MODULE.safety_check,
        action: [ACTION.UPDATE],
      })
    );
  }, []);
  return (
    <>
      <div className="flex justify-between  text-sm items-center mb-2">
        <h3 className="font-semibold text-gray-500">Order Details</h3>
        {edit ? (
          <div className="flex gap-2">
            <div
              onClick={() => {
                if (window.confirm("Are your sure ?")) {
                  context.onOrderDetailUpdated(order);
                  setEdit(false);
                }
              }}
              className="cursor-pointer rounded p-1 bg-green-100 hover:scale-105 duration-200 hover:text-green-600 hover:bg-green-200 text-green-400  transform"
            >
              <Icon path={mdiCheck} className="h-4 w-4"></Icon>
            </div>
            <div
              onClick={() => {
                setOrder({ ...context.order });
                setEdit(false);
              }}
              className="cursor-pointer rounded p-1 bg-red-100 hover:scale-105 duration-200 hover:text-red-600 hover:bg-red-200 text-red-400  transform"
            >
              <Icon path={mdiClose} className="h-4 w-4"></Icon>
            </div>
          </div>
        ) : (
          !disabled && (
            <div
              onClick={() => {
                setEdit(true);
              }}
              className="flex gap-1 items-center cursor-pointer rounded p-1  bg-myPrimaryColor text-white hover:scale-105  transform"
            >
              <Icon path={mdiPencil} className="h-4 w-4"></Icon> Edit
            </div>
          )
        )}
      </div>

      <div className="shadow rounded-xl px-3 md:px-6 py-2 md:py-4 bg-white  ">
        <div className=" flex items-center gap-3 my-2 ">
          <label htmlFor="demo">Demo Order</label>
          <input
            disabled={!edit || disabled}
            name=""
            id="demo"
            type="checkbox"
            className=" p-2 rounded bg-gray-100 focus:outline-none"
            checked={order.demo}
            onChange={(e) =>
              setOrder((o) => ({ ...o!, demo: e.target.checked }))
            }
          ></input>
        </div>
        {/* <div className="grid grid-cols-1  gap-5 items-start"> */}
        <div
          onClick={
            !edit
              ? () => {
                  showToast({
                    type: ToastType.info,
                    text: "Click edit button to make changes !",
                  });
                }
              : undefined
          }
          className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4   gap-x-5 gap-y-3  items-start justify-start"
        >
          <div className="">
            <div className=" mb-1 font-semibold text-xs">Date </div>
            <input
              disabled={!edit || disabled}
              className={`w-full text-xs  px-3 py-2 ${
                disabled ? "cursor-not-allowed" : "cursor-pointer"
              } bg-gray-100 rounded focus:outline-none`}
              type="datetime-local"
              name=""
              id=""
              value={moment(order.order_date).format("YYYY-MM-DD HH:mm:ss")}
              onChange={(e) => {
                const date = new Date(e.target.value);

                setOrder((o) => ({ ...o!, order_date: date }));
              }}
            />
          </div>
          <div className="">
            <div className=" mb-1 font-semibold text-xs">Type </div>
            <select
              disabled={!edit || disabled}
              name=""
              id=""
              className={`w-full text-xs  px-3 py-2 ${
                disabled ? "cursor-not-allowed" : "cursor-pointer"
              } bg-gray-100 rounded focus:outline-none`}
              value={order.type}
              required
              onChange={(e) => {
                setOrder((o) => ({ ...o, type: e.target.value }));
              }}
            >
              <option value="" hidden>
                Select ...
              </option>
              {Object.values(OrderType).map((type, i) => (
                <option value={type} key={i}>
                  {type}
                </option>
              ))}
            </select>
          </div>{" "}
          <div className="flex flex-col ">
            <label htmlFor="status" className="font-semibold text-xs mb-1">
              Order status
            </label>
            <select
              id="status"
              name="status"
              className={`w-full text-xs  px-3 py-2 ${
                disabled ? "cursor-not-allowed" : "cursor-pointer"
              } bg-gray-100 rounded focus:outline-none`}
              required
              value={order.status}
              disabled={!edit || disabled}
              onChange={(e) => {
                setOrder((o) => ({ ...o, status: e.target.value }));
              }}
            >
              <option value="" hidden>
                Select...
              </option>
              {Object.values(OrderStatus).map((item, i: number) => (
                <option key={i} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>{" "}
          <div className="flex flex-col ">
            <label htmlFor="status" className="font-semibold text-xs mb-1">
              Payment status
            </label>
            <select
              id="status"
              name="status"
              className={`w-full text-xs  px-3 py-2 ${
                disabled ? "cursor-not-allowed" : "cursor-pointer"
              } bg-gray-100 rounded focus:outline-none`}
              required
              value={order.payment_status}
              disabled={!edit || disabled}
              onChange={(e) => {
                setOrder((o) => ({ ...o, payment_status: e.target.value }));
              }}
            >
              <option value="" hidden>
                Select...
              </option>
              {Object.values(PaymentStatus).map((item, i: number) => (
                <option key={i} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
          <div className="">
            <div className=" mb-1 font-semibold text-xs">Category </div>
            <select
              name=""
              id=""
              disabled={!edit || disabled}
              className={`w-full text-xs  px-3 py-2 ${
                disabled ? "cursor-not-allowed" : "cursor-pointer"
              } bg-gray-100 rounded focus:outline-none`}
              value={order.category}
              required
              onChange={(e) => {
                setOrder((o) => ({ ...o, category: e.target.value }));
              }}
            >
              <option value="" hidden>
                Select ...
              </option>
              {orderCategories.map((src, i) => (
                <option value={src} key={i}>
                  {src}
                </option>
              ))}
            </select>
          </div>
          <div className="">
            <div className=" mb-1 font-semibold text-xs">Sales Person</div>
            <div
              className={`w-full text-xs ${
                disabled ? "cursor-not-allowed" : "cursor-pointer"
              } bg-gray-100 rounded focus:outline-none`}
            >
              <EmployeeSearchWidget
                withBtn={false}
                disabled={!edit || disabled}
                emp={{
                  uid: order.sales_person_id!,
                  name: order.sales_person_name!,
                }}
                placeholder="Select"
                onSelect={(emp) => {
                  const name = (emp.first_name + " " + emp.last_name).trim();

                  setOrder((o) => ({
                    ...o,
                    sales_person_name: name,
                    sales_person_id: emp.uid,
                  }));
                }}
              />
            </div>
          </div>{" "}
          <div className="">
            <div className=" mb-1 font-semibold text-xs">
              Relationship Manager 1
            </div>
            <div
              className={`w-full text-xs ${
                disabled ? "cursor-not-allowed" : "cursor-pointer"
              } bg-gray-100 rounded focus:outline-none`}
            >
              <EmployeeSearchWidget
                key={Math.random()}
                withBtn={false}
                disabled={!edit || disabled}
                emp={{ uid: order.rm_uid!, name: order.rm_name! }}
                placeholder="Relationship manager"
                onSelect={(emp) => {
                  const name = (emp.first_name + " " + emp.last_name).trim();
                  setOrder((o) => ({ ...o, rm_name: name, rm_uid: emp.uid }));
                }}
              />
            </div>
          </div>
          <div className="">
            <div className=" mb-1 font-semibold text-xs">
              Relationship Manager 2
            </div>{" "}
            <div
              className={`w-full text-xs ${
                disabled ? "cursor-not-allowed" : "cursor-pointer"
              } bg-gray-100 rounded focus:outline-none`}
            >
              <EmployeeSearchWidget
                withBtn={false}
                disabled={!edit || disabled}
                emp={{ uid: order.rm2_uid!, name: order.rm2_name! }}
                placeholder="Relationship manager 2"
                onSelect={(emp) => {
                  const name = (emp.first_name + " " + emp.last_name).trim();

                  setOrder((o) => ({
                    ...o,
                    rm2_name: name,
                    rm2_uid: emp.uid,
                  }));
                }}
              />
            </div>
          </div>{" "}
          <div className="">
            <div className="mb-1 font-semibold text-xs">Lead Segment </div>
            <select
              required
              name=""
              id=""
              disabled={!edit || disabled}
              className={`w-full text-xs  px-3 py-2 ${
                disabled ? "cursor-not-allowed" : "cursor-pointer"
              } bg-gray-100 rounded focus:outline-none`}
              value={order.segment}
              onChange={(e) => {
                setOrder((o) => ({ ...o, segment: e.target.value }));
              }}
            >
              <option value="" hidden>
                Select ...
              </option>
              {orderSegment.map((src, i) => (
                <option value={src} key={i}>
                  {src}
                </option>
              ))}
            </select>
          </div>
          <div className="">
            <div className="mb-1 font-semibold text-xs">Lead Source </div>
            <select
              required
              name=""
              id=""
              disabled={!edit || disabled}
              className={`w-full text-xs  px-3 py-2 ${
                disabled ? "cursor-not-allowed" : "cursor-pointer"
              } bg-gray-100 rounded focus:outline-none`}
              value={order.source}
              onChange={(e) => {
                setOrder((o) => ({ ...o, source: e.target.value }));
              }}
            >
              <option value="" hidden>
                Select ...
              </option>
              {LeadSource.map((src, i) => (
                <option value={src} key={i}>
                  {src}
                </option>
              ))}
            </select>
          </div>{" "}
          <div className="flex flex-col ">
            <label htmlFor="status" className="font-semibold text-xs">
              Purchase order status
            </label>
            <select
              id="status"
              name="status"
              className={`w-full text-xs  px-3 py-2 ${
                disabled ? "cursor-not-allowed" : "cursor-pointer"
              } bg-gray-100 rounded focus:outline-none`}
              required
              value={order.po_status}
              disabled={!edit || disabled}
              onChange={(e) => {
                setOrder((o) => ({
                  ...o,
                  po_status: e.target.value,
                }));
              }}
            >
              <option value="" hidden>
                Select...
              </option>
              {Object.values(PO_STATUS).map((item, i: number) => (
                <option key={i} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
          <div className="">
            <div className=" mb-1 font-semibold text-xs">
              Installation/Quality check status
            </div>
            <select
              disabled={saftyDisabled}
              className={`w-full text-xs  px-3 py-2 ${
                saftyDisabled ? "cursor-pointer" : "cursor-not-allowed"
              } bg-gray-100 rounded focus:outline-none`}
              value={order.qc_status}
              onChange={(e) => {
                setOrder((o) => ({
                  ...o,
                  qc_status: e.target.value,
                }));
              }}
            >
              <option value="" hidden>
                Select
              </option>

              {Object.values(QcStatus).map((item, i) => (
                <option key={i} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col">
            <label htmlFor="status" className="font-semibold text-xs">
              Subscription applicable
            </label>
            <select
              disabled={!edit || disabled}
              name=""
              id="status"
              className={`w-full text-xs  px-3 py-2 ${
                disabled ? "cursor-not-allowed" : "cursor-pointer"
              } bg-gray-100 rounded focus:outline-none`}
              value={order.monthly_recurring_fee}
              onChange={(e) => {
                setOrder((o) => ({
                  ...o!,
                  monthly_recurring_fee: e.target.value,
                }));
              }}
            >
              <option value="" hidden>
                Select ...
              </option>
              {["Yes", "No"].map((item, i) => (
                <option key={i} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>{" "}
          <div className="grid grid-cols-1 items-center text-sm">
            <label htmlFor="sale_type"> Sales type</label>
            <select
              disabled={!edit || disabled}
              name=""
              id="sale_type"
              className="col-span-2 p-2 rounded bg-gray-100 focus:outline-none"
              value={order.sale_type}
              onChange={(e) => {
                setOrder((o) => ({ ...o!, sale_type: e.target.value }));
              }}
            >
              <option value="" hidden>
                Select ...
              </option>
              {sale_type.sort().map((src, i) => (
                <option value={src} key={i}>
                  {src}
                </option>
              ))}
            </select>
          </div>
        </div>
        <br />
        <hr />
        <h3 className="font-semibold mt-5">Remarks</h3>
        <div
          onClick={
            !edit
              ? () => {
                  showToast({
                    type: ToastType.info,
                    text: "Click edit button to make changes !",
                  });
                }
              : undefined
          }
          className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 gap-x-5 gap-y-3"
        >
          <div className="">
            <div className="mb-1 font-semibold text-xs">Discom</div>
            <select
              name=""
              id=""
              disabled={!edit || disabled}
              className={`w-full text-xs  px-3 py-2 ${
                disabled ? "cursor-not-allowed" : "cursor-pointer"
              } bg-gray-100 rounded focus:outline-none`}
              value={order.discom}
              onChange={(e) => {
                setOrder((o) => ({ ...o, discom: e.target.value }));
              }}
            >
              <option value="" hidden>
                Select ...
              </option>
              {discoms.map((d, i) => (
                <option value={d.short_name} key={i}>
                  {`${d.short_name}(${d.state})`}
                </option>
              ))}
            </select>
          </div>
          <div className="">
            <div className=" mb-1 font-semibold text-xs">Meter type</div>
            <select
              disabled={!edit || disabled}
              name=""
              id=""
              className={`w-full text-xs  px-3 py-2 ${
                disabled ? "cursor-not-allowed" : "cursor-pointer"
              } bg-gray-100 rounded focus:outline-none`}
              value={order.meter_type}
              onChange={(e) => {
                setOrder((o) => ({
                  ...o,
                  meter_type: e.target.value,
                }));
              }}
            >
              <option value="" hidden>
                Select ...
              </option>
              {[
                "Domestic Residential",
                "Domestic staircase",
                "Domestic lighting",
                "NDLS",
                "CGHS",
                "EV meter",
              ].map((src, i) => (
                <option value={src} key={i}>
                  {src}
                </option>
              ))}
            </select>
          </div>
          <div className="">
            <div className=" mb-1 font-semibold text-xs">Site survey</div>
            <select
              disabled={!edit || disabled}
              className={`w-full text-xs  px-3 py-2 ${
                disabled ? "cursor-not-allowed" : "cursor-pointer"
              } bg-gray-100 rounded focus:outline-none`}
              value={order.site_survey}
              onChange={(e) => {
                setOrder((o) => ({ ...o, site_survey: e.target.value }));
              }}
            >
              <option value="" hidden>
                Select
              </option>
              <option value="0">N/A</option>
              {["Pending", "Done", "WIP"].map((item, i) => (
                <option key={i} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
          <div className="">
            <div className=" mb-1 font-semibold text-xs">Wiring status</div>
            <select
              disabled={!edit || disabled}
              className={`w-full text-xs  px-3 py-2 ${
                disabled ? "cursor-not-allowed" : "cursor-pointer"
              } bg-gray-100 rounded focus:outline-none`}
              value={order.wiring_status}
              onChange={(e) => {
                setOrder((o) => ({ ...o, wiring_status: e.target.value }));
              }}
            >
              <option value="" hidden>
                Select
              </option>
              <option value="0">N/A</option>
              {["Pending", "Done", "WIP"].map((item, i) => (
                <option key={i} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
          <div className="">
            <div className=" mb-1 font-semibold text-xs">
              Expected payment date
            </div>
            <input
              className={`w-full text-xs  px-3 py-2 ${
                disabled ? "cursor-not-allowed" : "cursor-pointer"
              } bg-gray-100 rounded focus:outline-none`}
              id="payment-date"
              type="date"
              name="payment_date"
              required
              disabled={!edit || disabled}
              value={
                order.expected_pay_date &&
                moment(order.expected_pay_date).format("YYYY-MM-DD")
              }
              onChange={(e) => {
                const date = new Date(e.target.value);
                setOrder((o) => ({
                  ...o!,
                  expected_pay_date: date,
                }));
              }}
            />
          </div>
          <div className="">
            <div className=" mb-1 font-semibold text-xs">
              Next Delivery/Installation date
            </div>
            <input
              className={`w-full text-xs  px-3 py-2 ${
                disabled ? "cursor-not-allowed" : "cursor-pointer"
              } bg-gray-100 rounded focus:outline-none`}
              id="installation_date"
              type="date"
              name="installation_date"
              required
              disabled={!edit || disabled}
              value={
                order.next_delivery_date &&
                moment(order.next_delivery_date).format("YYYY-MM-DD")
              }
              onChange={(e) => {
                const date = new Date(e.target.value);
                setOrder((o) => ({
                  ...o!,
                  next_delivery_date: date,
                }));
              }}
            />
          </div>
          <div className="">
            <div className=" mb-1 font-semibold text-xs">
              Registration document received
            </div>
            <select
              disabled={!edit || disabled}
              className={`w-full text-xs  px-3 py-2 ${
                disabled ? "cursor-not-allowed" : "cursor-pointer"
              } bg-gray-100 rounded focus:outline-none`}
              value={order.registration_doc_issued}
              onChange={(e) => {
                setOrder((o) => ({
                  ...o,
                  registration_doc_issued: e.target.value,
                }));
              }}
            >
              <option value="" hidden>
                Select
              </option>

              {["Yes", "No"].map((item, i) => (
                <option key={i} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
          <div className="">
            <div className=" mb-1 font-semibold text-xs">
              Registration Number
            </div>
            <input
              disabled={!edit || disabled}
              name=""
              id=""
              className={`w-full text-xs  px-3 py-2 ${
                disabled ? "cursor-not-allowed" : "cursor-pointer"
              } bg-gray-100 rounded focus:outline-none`}
              value={order.registration_number}
              onChange={(e) => {
                setOrder((o) => ({
                  ...o,
                  registration_number: e.target.value,
                }));
              }}
            ></input>
          </div>
          <div className="">
            <div className=" mb-1 font-semibold text-xs">Device Registered</div>
            <input
              disabled={!edit || disabled}
              type="number"
              className={`w-full text-xs  px-3 py-2 ${
                disabled ? "cursor-not-allowed" : "cursor-pointer"
              } bg-gray-100 rounded focus:outline-none`}
              defaultValue={order.no_device_registered}
              onChange={(e) => {
                setOrder((o) => ({
                  ...o,
                  no_device_registered:
                    e.target.value === "" ? 0 : parseFloat(e.target.value),
                }));
              }}
            ></input>
          </div>
          <div className="">
            <div className=" mb-1 font-semibold text-xs">
              Subsidy claimed qty
            </div>
            <input
              disabled={!edit || disabled}
              name=""
              id=""
              className={`w-full text-xs  px-3 py-2 ${
                disabled ? "cursor-not-allowed" : "cursor-pointer"
              } bg-gray-100 rounded focus:outline-none`}
              value={order.subsidy_claimed_qty}
              onChange={(e) => {
                setOrder((o) => ({
                  ...o,
                  subsidy_claimed_qty:
                    e.target.value === "" ? 0 : parseFloat(e.target.value),
                }));
              }}
            ></input>
          </div>{" "}
          <div className="">
            <div className=" mb-1 font-semibold text-xs">Load sanction</div>
            <input
              disabled={!edit || disabled}
              type="text"
              className={`w-full text-xs  px-3 py-2 ${
                disabled ? "cursor-not-allowed" : "cursor-pointer"
              } bg-gray-100 rounded focus:outline-none`}
              defaultValue={order.load_sanction}
              onChange={(e) => {
                setOrder((o) => ({
                  ...o,
                  load_sanction:
                    e.target.value === "" ? 0 : parseFloat(e.target.value),
                }));
              }}
            ></input>
          </div>
          <div className="">
            <div className=" mb-1 font-semibold text-xs">Survey date</div>
            <input
              className={`w-full text-xs  px-3 py-2 ${
                disabled ? "cursor-not-allowed" : "cursor-pointer"
              } bg-gray-100 rounded focus:outline-none`}
              id="survey_date"
              type="date"
              name="survey_date"
              required
              disabled={!edit || disabled}
              value={
                order.survey_date &&
                moment(order.survey_date).format("YYYY-MM-DD")
              }
              onChange={(e) => {
                const date = e.target.value
                  ? UTCToLocalDate(e.target.value)
                  : undefined;

                setOrder((o) => ({
                  ...o!,
                  survey_date: date,
                }));
              }}
            />
          </div>
          <div className="">
            <div className=" mb-1 font-semibold text-xs">SLD & BOQ date</div>
            <input
              className={`w-full text-xs  px-3 py-2 ${
                disabled ? "cursor-not-allowed" : "cursor-pointer"
              } bg-gray-100 rounded focus:outline-none`}
              id="survey_date"
              type="date"
              name="survey_date"
              required
              disabled={!edit || disabled}
              value={
                order.sld_and_boq_date &&
                moment(order.sld_and_boq_date).format("YYYY-MM-DD")
              }
              onChange={(e) => {
                const date = e.target.value
                  ? UTCToLocalDate(e.target.value)
                  : undefined;

                setOrder((o) => ({
                  ...o!,
                  sld_and_boq_date: date,
                }));
              }}
            />
          </div>
          <div className="">
            <div className=" mb-1 font-semibold text-xs">
              SLD & BOQ approval date
            </div>
            <input
              className={`w-full text-xs  px-3 py-2 ${
                disabled ? "cursor-not-allowed" : "cursor-pointer"
              } bg-gray-100 rounded focus:outline-none`}
              id="survey_date"
              type="date"
              name="survey_date"
              required
              disabled={!edit || disabled}
              value={
                order.sld_and_boq_approval_date &&
                moment(order.sld_and_boq_approval_date).format("YYYY-MM-DD")
              }
              onChange={(e) => {
                const date = e.target.value
                  ? UTCToLocalDate(e.target.value)
                  : undefined;

                setOrder((o) => ({
                  ...o!,
                  sld_and_boq_approval_date: date,
                }));
              }}
            />
          </div>
          <div className="">
            <div className=" mb-1 font-semibold text-xs">Installation date</div>
            <input
              className={`w-full text-xs  px-3 py-2 ${
                disabled ? "cursor-not-allowed" : "cursor-pointer"
              } bg-gray-100 rounded focus:outline-none`}
              id="survey_date"
              type="date"
              name="survey_date"
              required
              disabled={!edit || disabled}
              value={
                order.installation_date &&
                moment(order.installation_date).format("YYYY-MM-DD")
              }
              onChange={(e) => {
                const date = e.target.value
                  ? UTCToLocalDate(e.target.value)
                  : undefined;

                setOrder((o) => ({
                  ...o!,
                  installation_date: date,
                }));
              }}
            />
          </div>
          <div className="">
            <div className=" mb-1 font-semibold text-xs">Commission date</div>
            <input
              className={`w-full text-xs  px-3 py-2 ${
                disabled ? "cursor-not-allowed" : "cursor-pointer"
              } bg-gray-100 rounded focus:outline-none`}
              id="survey_date"
              type="date"
              name="survey_date"
              required
              disabled={!edit || disabled}
              value={
                order.commission_date &&
                moment(order.commission_date).format("YYYY-MM-DD")
              }
              onChange={(e) => {
                const date = e.target.value
                  ? UTCToLocalDate(e.target.value)
                  : undefined;

                setOrder((o) => ({
                  ...o!,
                  commission_date: date,
                }));
              }}
            />
          </div>
          <div className="">
            <div className=" mb-1 font-semibold text-xs">Dealer code</div>
            <input
              className={`w-full text-xs  px-3 py-2 ${
                disabled ? "cursor-not-allowed" : "cursor-pointer"
              } bg-gray-100 rounded focus:outline-none`}
              id="dealer_code"
              type="string"
              name="dealer_code"
              required
              disabled={!edit || disabled}
              value={order.dealer_code}
              onChange={(e) => {
                setOrder((o) => ({
                  ...o!,
                  dealer_code: e.target.value,
                }));
              }}
            />
          </div>
        </div>
        {/* </div> */}

        <hr className="mt-2" />
        <div className="flex justify-between gap-2 my-1">
          <p className="text-xs ">
            Created by :<br />
            <div className="flex gap-1">
              <div className="">
                <b>{context.order.created_by_name ?? "N/A"}</b>
              </div>
              <div className="">
                {context.order.created_at
                  ? moment(context.order.created_at).format(
                      "DD MMM YYYY h:mm a"
                    )
                  : "--"}
              </div>
            </div>
          </p>
          <p className="text-xs ">
            Updated by :<br />
            <div className="flex gap-1">
              <div className="">
                <b>{context.order.updated_by_name ?? "N/A"}</b>
              </div>
              <div className="">
                {context.order.updated_at
                  ? moment(context.order.updated_at).format(
                      "DD MMM YYYY h:mm a"
                    )
                  : "--"}
              </div>
            </div>
          </p>
        </div>
      </div>
    </>
  );
};

export default OrderDetailSection;
