import {
  fetchGet,
  fetchPost,
  fetchPut,
  getHeaders,
  toQueryString,
} from "../../api_client";
import { CustomerDetailModel } from "../../models/orders/customer_detail_model";

// export const GetCustomersApi = async (data: {
//   page?: number;
//   count?: number;
//   all?: boolean;
//   unique?: boolean;
//   fromDate?: string;
//   toDate?: string;
//   type?: string[];
//   search?: string;
// }) => {
//   try {
//     // const query = new URLSearchParams();
//     // Object.keys(data).forEach((k, i) => {
//     //   query.append(k, data[k]);
//     // });

//     const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/customers`;
//     const res = await fetchPost(url, data);
//     // let url = `${
//     //   process.env.REACT_APP_BACKEND_BASE_URL
//     // }/api/v3/users${toQueryString(data)}`;

//     // const res = await fetchGet(url);

//     return res;
//   } catch (error: any) {
//     return { success: false, error: error.message };
//   }
// };
export const GetCustomerOfOrderApi = async (order_id: string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/${order_id}/billing-profile`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const UpdateCustomerApi = async ({
  data,
  order_id,
  customer_id,
}: {
  data: CustomerDetailModel;
  order_id: string;
  customer_id: string;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/${order_id}/billing-profile/${customer_id}`;
    const res = await fetchPut(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GetUsersRepo = async (option: {
  count?: number;
  page?: number;
  search?: string;
  all?: boolean;
}) => {
  try {
    let url = `${
      process.env.REACT_APP_BACKEND_BASE_URL
    }/api/v3/users${toQueryString(option)}`;

    const res = await fetchGet(url);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const CreateUserRepo = async (data: {
  first_name: string;
  last_name: string;
  mobile?: string;
  email?: string;
}) => {
  try {
    let url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/users`;

    const res = await fetchPost(url, data);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
