import { FunctionComponent, useEffect, useState } from "react";
import { FileText, RefreshCw, X } from "react-feather";
// @ts-ignore
import gsap from "gsap/all";
import {
  CancelLeaveRequest,
  GetLeaveRequests,
  UpdateLeaveRequests,
} from "../../../../../service/repos/hrm/hrm_repo";
import { LeaveRequest } from "../../../../../service/models/user_model";
import moment from "moment";
import { LeaveRequestStatus } from "../../../../../utils/enums";
import HrmPopup from "../../components/HrmPopup";
import HrmLoader from "../../components/loading";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import { Pagination } from "@material-ui/lab";
import { useAuth } from "../../../../../context_providers/auth/authProvider";
import "../../styles/index.css";

interface ActivityBlockProps {}

const ActivityBlock: FunctionComponent<ActivityBlockProps> = () => {
  const headingsSelf = ["Type", "From", "To", "Status"];
  const headings = ["Name", "Type", "From", "To", "Status"];
  const [selfActivity, setSelfActivity] = useState<LeaveRequest[]>([]);
  const [otherActivity, setOtherActivity] = useState<LeaveRequest[]>([]);
  const [showSelf, setShowSelf] = useState<boolean>(true);
  const { user } = useAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const { showToast } = useToast();
  const count = 20;
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [page2, setPage2] = useState<number>(1);
  const [total2, setTotal2] = useState<number>(0);
  const [showPendingModal, setShowPendingModal] = useState<boolean>(false);
  const date = new Date();

  const getSelfActivity = async (p: number) => {
    setLoading(true);
    const params = {
      page: p,
      count: count,
      uid: user?.uid,
    };
    const res = await GetLeaveRequests(params);
    if (res.success) {
      setSelfActivity(res.data.leave_requests);
      setTotal(res.data.metadata[0]?.total ?? 0);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };

  const getOtherActivity = async (p: number) => {
    setLoading(true);

    const params2: any = {
      page: p,
      count: count,
      reporting_uid: user?.uid,
    };
    const reporting_res = await GetLeaveRequests(params2);
    if (reporting_res.success) {
      setOtherActivity(reporting_res.data.leave_requests);
      setTotal2(reporting_res.data.metadata[0]?.total ?? 0);
      // handling pending modal functions
      const tempData = reporting_res.data.leave_requests;
      for (let i = 0; i < tempData.length; i++) {
        if (tempData[i].status === LeaveRequestStatus.pending) {
          handlePendingModal();
          break;
        }
      }
    } else {
      showToast({ type: ToastType.error, text: reporting_res.error ?? "" });
    }
    setLoading(false);
  };

  const onPageChange = async (p: number) => {
    if (p !== page) {
      setPage(p);
      await getSelfActivity(p);
    }
  };

  const onSecPageChange = async (p: number) => {
    if (p !== page) {
      setPage2(p);
      await getOtherActivity(p);
    }
  };

  const handlePendingModal = () => {
    const pendingModalDate = localStorage.getItem("pendingModalDate");
    const today = JSON.stringify(date.getDate());

    if (pendingModalDate) {
      if (pendingModalDate !== today) {
        setTimeout(() => {
          setShowPendingModal(true);
        }, 1000);
        localStorage.setItem("pendingModalDate", today);
      }
    } else {
      setTimeout(() => {
        setShowPendingModal(true);
      }, 1000);
      localStorage.setItem("pendingModalDate", today);
    }
  };

  useEffect(() => {
    if (user?.emp_profile?.isReportingManager) {
      setShowSelf(false);
    }
  }, []);

  useEffect(() => {
    getSelfActivity(page);
    getOtherActivity(page);
  }, []);

  return (
    <>
      <div className="mt-3 lg:mt-4">
        <div className="flex justify-between items-center">
          <div className="font-bold hidden lg:block">Leaves activities</div>
          <div className="flex items-center justify-end text-sm gap-3 mt-2">
            {user?.emp_profile?.isReportingManager ? (
              <div className="flex rounded-3xl bg-white dark:bg-darkBgcolor p-1    ">
                <div
                  title={"Your leave requests"}
                  onClick={() => setShowSelf(true)}
                  className={`px-4 py-1 duration-100 cursor-pointer ${
                    showSelf ? "bg-hrmBlue text-white rounded-3xl" : ""
                  }`}
                >
                  Your Requests
                </div>
                <div
                  title={"Your team's leave requests"}
                  onClick={() => setShowSelf(false)}
                  className={`px-2 py-1 duration-100 cursor-pointer ${
                    !showSelf ? "bg-hrmBlue text-white rounded-3xl" : ""
                  }`}
                >
                  Team's Requests
                </div>
              </div>
            ) : (
              <div
                className={`px-4 py-1 duration-100 cursor-pointer bg-hrmBlue text-white rounded-3xl `}
              >
                Your Requests
              </div>
            )}
          </div>
          <div className="py-1 px-3 rounded flex flex-col items-center justify-center">
            <RefreshCw
              size={18}
              className="cursor-pointer dark:text-white"
              onClick={() => {
                getSelfActivity(page);
                getOtherActivity(page2);
              }}
            />
          </div>
        </div>

        <div className="activity_block rounded-xl bg-white mt-2 ">
          <div
            className={`hidden lg:grid ${
              showSelf ? "grid-cols-4" : "grid-cols-5"
            }  border-b`}
          >
            {showSelf
              ? headingsSelf.map((el) => {
                  return (
                    <div className="font-bold text-sm py-3 pl-3">{el}</div>
                  );
                })
              : headings.map((el) => {
                  return (
                    <div className="font-bold text-sm py-3 pl-3">{el}</div>
                  );
                })}
          </div>
          {loading ? (
            <div className="h-72 flex flex-col items-center justify-center">
              <HrmLoader className="w-12 h-12" />
            </div>
          ) : showSelf ? (
            <div className="">
              <div className="overflow-y-auto mt-3" style={{ height: "50vh" }}>
                {selfActivity.length > 0 ? (
                  selfActivity.map((el: LeaveRequest, i: number) => {
                    return <ActivityCard selfRequest={showSelf} data={el} />;
                  })
                ) : (
                  <div className="pt-3 text-primaryColor">No requests</div>
                )}
              </div>

              <div className="flex flex-col items-center">
                <Pagination
                  page={page}
                  count={Math.ceil(total / count)}
                  className={`my-5 hrm_pagination`}
                  color="secondary"
                  variant="outlined"
                  onChange={(event, page) => {
                    onPageChange(page);
                  }}
                />
              </div>
            </div>
          ) : (
            <div className="">
              <div
                className="h-64 overflow-y-auto mt-3"
                style={{ height: "50vh" }}
              >
                {otherActivity.length > 0 ? (
                  otherActivity.map((el: LeaveRequest, i: number) => {
                    return <ActivityCard selfRequest={showSelf} data={el} />;
                  })
                ) : (
                  <div className="pt-3 text-primaryColor">No requests</div>
                )}
              </div>
              <div className="flex flex-col items-center">
                <Pagination
                  page={page2}
                  count={Math.ceil(total2 / count)}
                  className={`my-5 hrm_pagination`}
                  color="secondary"
                  variant="outlined"
                  onChange={(event, page) => {
                    onSecPageChange(page);
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      {showPendingModal && (
        <HrmPopup>
          <div className="bg-white px-4 py-8 lg:py-12 lg:px-12 rounded-md relative mx-6 lg:mx-0">
            <X
              className="absolute top-2 right-3 cursor-pointer"
              onClick={() => setShowPendingModal(false)}
            />
            <div className="font-bold text-xl lg:text-2xl">
              You have pending leave requests!
            </div>
            <div className="mt-1 text-gray-500">
              Please approve or reject, as it helps us keep the data in sync
            </div>
            <div
              className="mt-4 lg:mt-12 w-full h-0 relative"
              style={{
                paddingBottom: "56%",
              }}
            >
              <iframe
                src="https://giphy.com/embed/gdSmAqQqMo4IpBLqaB"
                width="100%"
                height="100%"
                style={{ position: "absolute" }}
                className="giphy-embed"
                allowFullScreen
              ></iframe>
              <div className="absolute top-0 left-0 w-full h-full z-30"></div>
            </div>
          </div>
        </HrmPopup>
      )}
    </>
  );
};

export default ActivityBlock;

interface ActivityCardProps {
  selfRequest: boolean;
  data: LeaveRequest;
}

const ActivityCard: FunctionComponent<ActivityCardProps> = ({
  selfRequest,
  data,
}) => {
  const [view, setView] = useState<boolean>(false);
  const { showToast } = useToast();
  const pendingTrue = data.status === LeaveRequestStatus.pending;

  const cancelRequest = async (id: number) => {
    const res = await CancelLeaveRequest(id);

    if (res.success) {
      showToast({
        type: ToastType.error,
        text: "Leave request has been cancelled",
      });
      window.location.reload();
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
  };

  return (
    <div
      className={` ${pendingTrue ? "activityPending text-white" : ""} ${
        selfRequest
          ? "grid grid-cols-2 gap-y-2 lg:gap-y-0 lg:grid-cols-4"
          : " grid grid-cols-2 gap-y-2 lg:gap-y-0 lg:grid-cols-5"
      } text-sm py-3 pl-3 border-b`}
    >
      <div
        className={` ${selfRequest ? "hidden" : "block"} ${
          pendingTrue ? "text-white font-bold" : "text-hrmPurple"
        }`}
      >
        {data.created_by_name}
      </div>
      <div
        className={`${selfRequest ? "col-span-1" : "col-span-1"} ${
          pendingTrue ? "text-white font-bold" : "text-hrmPurple"
        } `}
      >
        {data.leave_name}
      </div>
      <div className="">
        {" "}
        <span className="lg:hidden">From:</span>{" "}
        {moment(data.from_date).format("DD-MM-YY")}
      </div>
      <div className="">
        {" "}
        <span className="lg:hidden">To:</span>{" "}
        {moment(data.to_date).format("DD-MM-YY")}
      </div>

      {selfRequest ? (
        <div className="grid grid-cols-3 gap-3 justify-items-start items-center">
          <div className="">
            <div
              className={` ${
                data.status === LeaveRequestStatus.approved
                  ? "text-teal"
                  : data.status === LeaveRequestStatus.rejected
                  ? "text-hrmRed"
                  : data.status === LeaveRequestStatus.cancelled
                  ? "text-yellow-400"
                  : "text-white font-bold"
              } `}
            >
              {data.status}
            </div>
            <div
              className={`${
                pendingTrue ? "hidden" : "  text-secondaryColor"
              } text-xs `}
            >
              By {data?.updated_by_name.split(" ")[0]}
            </div>
          </div>

          <div className="hidden lg:block ">
            <button
              className="py-1 px-2 rounded bg-hrmBlue text-white text-xs "
              onClick={() => setView(true)}
            >
              View
            </button>
          </div>
          {data.status === LeaveRequestStatus.pending && (
            <div className="inline">
              <button
                className="py-1 px-2 rounded bg-hrmRed text-white text-xs"
                onClick={() => {
                  var prompt = window.confirm(
                    "Are you sure you want to cancel your leave?"
                  );
                  if (prompt) {
                    cancelRequest(data.id!);
                  }
                }}
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      ) : (
        <div className="grid grid-cols-2 gap-3">
          <div className="">
            <div
              className={` ${
                data.status === LeaveRequestStatus.approved
                  ? "text-teal"
                  : data.status === LeaveRequestStatus.rejected
                  ? "text-hrmRed"
                  : data.status === LeaveRequestStatus.cancelled
                  ? "text-yellow-400"
                  : "text-white font-bold"
              } `}
            >
              {data.status}
            </div>
            <div
              className={` ${
                pendingTrue ? "hidden " : "text-xs text-secondaryColor"
              } `}
            >
              By {data?.updated_by_name.split(" ")[0]}
            </div>
          </div>

          <div className="hidden lg:block">
            <button
              className="py-1 px-2 rounded bg-hrmBlue text-white text-xs "
              onClick={() => setView(true)}
            >
              View
            </button>
          </div>
        </div>
      )}
      <div
        className={` ${
          selfRequest ? "col-span-2 flex flex-col items-end mr-3" : "col-span-1"
        } lg:hidden`}
      >
        <button
          className="py-1 px-2 rounded bg-hrmBlue text-white text-xs"
          onClick={() => setView(true)}
        >
          View
        </button>
      </div>
      {view && (
        <HrmPopup>
          <UpdateCard data={data} close={() => setView(false)} />
        </HrmPopup>
      )}
    </div>
  );
};

interface UpdateCardProps {
  data: LeaveRequest;
  close: () => void;
}

const UpdateCard: FunctionComponent<UpdateCardProps> = ({ data, close }) => {
  const { user, isAuthorised } = useAuth();

  const [loading, setLoading] = useState<boolean>(false);
  const { showToast } = useToast();

  const updateLeaveRequest = async (status: string) => {
    setLoading(true);
    if (data) {
      const res = await UpdateLeaveRequests({
        id: data.id!,
        status,
      });

      if (res.success) {
        setLoading(false);
        close();
        showToast({ type: ToastType.success, text: res.message ?? "" });
      } else {
        close();
        showToast({ type: ToastType.error, text: res.error ?? "" });
      }
    }
  };

  return (
    <>
      {data && (
        <div className="w-full h-screen flex fixed lg:absolute top-0 left-0 z-30 rounded-xl ">
          <div className="m-auto  formModal py-8 px-10 relative overflow-hidden bg-white shadow-lg dark:bg-hrmCardDark">
            <div className="absolute top-2 right-3">
              <X className="cursor-pointer" size={24} onClick={() => close()} />
            </div>
            <div className="text-black grid grid-cols-2 lg:grid-cols-3 gap-y-12 gap-x-6">
              <div className="">
                <div className="font-bold text-sm text-black text-opacity-40">
                  Name
                </div>
                <div className="text-base font-semibold">{data.emp_name}</div>
              </div>
              <div className="">
                <div className="font-bold text-sm text-black text-opacity-40">
                  Leave name
                </div>
                <div className="text-base font-semibold">{data.leave_name}</div>
              </div>
              {data.period && (
                <div className="">
                  <div className="font-bold text-sm text-black text-opacity-40">
                    Period
                  </div>
                  <div className="text-base font-semibold">{data.period}</div>
                </div>
              )}
              <div className="">
                <div className="font-bold text-sm text-black text-opacity-40">
                  From
                </div>
                <div className="text-base font-semibold">{data.from_date}</div>
              </div>
              <div className="">
                <div className="font-bold text-sm text-black text-opacity-40">
                  To
                </div>
                <div className="text-base font-semibold">{data.to_date}</div>
              </div>
              <div className="">
                <div className="font-bold text-sm text-black text-opacity-40">
                  Applied on
                </div>
                <div className="text-base font-semibold">
                  {moment(data.created_at).format("lll")}
                </div>
              </div>
              <div className="col-span-2 lg:col-span-3">
                <div className="font-bold text-sm text-black text-opacity-40">
                  Message
                </div>
                <div className="text-base font-semibold">{data.message}</div>
              </div>
            </div>{" "}
            {data.reporting_uid === user?.uid &&
              data.status === LeaveRequestStatus.pending && (
                <div className="flex flex-col items-center mt-10">
                  {loading ? (
                    <div className="">
                      <HrmLoader className="w-8 h-8" />
                    </div>
                  ) : (
                    <div className="flex gap-2">
                      <div
                        className="cursor-pointer text-white font-bold bg-green-400 py-3 px-6 rounded-md"
                        onClick={() => {
                          updateLeaveRequest(LeaveRequestStatus.approved);
                        }}
                      >
                        Approve
                      </div>

                      <div
                        className="cursor-pointer text-white font-bold bg-red-400 py-3 px-6 rounded-md"
                        onClick={() => {
                          updateLeaveRequest(LeaveRequestStatus.rejected);
                        }}
                      >
                        Reject
                      </div>
                    </div>
                  )}
                </div>
              )}
          </div>
        </div>
      )}
    </>
  );
};
