import { FunctionComponent, useEffect, useState } from "react";
import {
  CreateApprovedLeaveRequest,
  CreateLeaveRequestRepo,
} from "../../../../service/repos/hrm/hrm_repo";
import { LeavePeriod, LeaveType } from "../../../../utils/enums";
import "../styles/index.css";
import moment from "moment";
import Layout from "../layout";
import HrmLoader from "../components/loading";
import {
  useToast,
  ToastType,
} from "../../../../context_providers/toast/toast_ctx";
// @ts-ignore
import gsap from "gsap/all";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";

// @ts-ignore
import { Calendar } from "react-date-range";
// @ts-ignore
import { DateRange } from "react-date-range";
import { addDays } from "date-fns";
import { CheckCircle } from "react-feather";
import { Link } from "react-router-dom";
import { urls } from "../../../../utils/urls";
import EmployeeSearchWidget from "../../../new_components/emp_search_widget";
import { UserModel } from "../../../../context_providers/auth/user_model";

interface CreateLeaveRecordProps {}

const defaultValue: {
  leave_name: string;
  from_date: string;
  to_date: string;
  period: string;
  message: string;
} = {
  leave_name: "",
  from_date: "",
  period: LeavePeriod.full_day,
  to_date: "",
  message: "",
};

const CreateLeaveRecord: FunctionComponent<CreateLeaveRecordProps> = () => {
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [emp, setEmp] = useState<UserModel>();
  const [dateRange, setDateRange] = useState<any>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [leaveRequest, setLeaveRequest] = useState<{
    leave_name: string;
    from_date: string;
    to_date: string;
    period: string;
    message: string;
  }>({ ...defaultValue });

  const date = new Date();
  const animateData = async () => {
    const tl = gsap.timeline();
    tl.fromTo(
      ".success_wrapper",
      0.4,
      {
        opacity: 0,
      },
      {
        opacity: 1,
      }
    );
  };

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!emp) {
      showToast({ type: ToastType.error, text: "Select Employee" });
      return;
    }

    const tempStart = moment(dateRange[0].startDate).format("YYYY-MM-DD");
    const tempEnd = moment(dateRange[0].endDate).format("YYYY-MM-DD");
    const formData = {
      uid: emp?.uid,
      leave_name: leaveRequest.leave_name,
      from_date: tempStart,
      to_date: tempEnd,
      period: leaveRequest.period,
      message: leaveRequest.message,
    };

    setLoading(true);
    console.log(formData);

    const res: any = await CreateApprovedLeaveRequest(formData);
    console.log(res);

    if (res.success) {
      setLoading(false);
      setShowSuccess(true);
      animateData();
    } else {
      setLoading(false);
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
  };

  return (
    <Layout pageTitle="Create leave record">
      <div className="h-screen relative">
        {showSuccess && (
          <div className="success_wrapper opacity-0 fixed top-0 left-0 h-screen w-screen bg-white flex flex-col items-center justify-center z-20">
            <div className="flex items-center gap-4">
              <div className="text-2xl italic">Submitted Successfully</div>
              <CheckCircle size={48} className="text-green-400" />
            </div>
            <div className="">
              <Link to={urls.hrm_home}>
                <button className="py-2 px-6 rounded bg-hrmPurple text-white font-semibold">
                  Go back home
                </button>
              </Link>
            </div>
          </div>
        )}
        <div className="flex flex-col lg:flex-row items-center h-full">
          <div className="w-1/2  flex flex-col items-center">
            <div className="hidden lg:block w-3/4">
              <ul className="pl-4 list-disc text-secondaryColor dark:text-primaryColor mt-4 text-lg">
                <li>
                  Create a leave record of the employee in case they haven't
                  applied for the leave
                </li>
                <li className="mt-2">
                  Your leave request will be approved by your{" "}
                  <span className="font-bold underline"> Line Manager</span>
                </li>
              </ul>
              <div className="">
                <img src="/images/create_record.gif" alt="" className="w-96" />
              </div>
            </div>
          </div>
          <div className="w-full overflow-y-auto  h-full lg:w-2/3 relative  flex flex-col  items-center py-12 rounded-l-xl bg-white ">
            <form className="w-11/12  2xl:text-lg" onSubmit={submit}>
              <div className="">Select date range</div>
              <div className="mt-2 lg:w-1/2">
                <DateRange
                  // date={new Date()}
                  ranges={dateRange}
                  // minDate={new Date()}
                  onChange={(item: any) => {
                    console.log(item);
                    setDateRange([item.selection]);
                  }}
                  rangeColors={["#FD5440", "#8302E9"]}
                  className="w-full"
                />
              </div>

              <div className="grid grid-cols-2 gap-x-2 gap-y-6">
                <div className="">
                  <div className="">Select Employee</div>

                  <div className="">
                    <EmployeeSearchWidget
                      withBtn={false}
                      emp={
                        emp
                          ? {
                              uid: emp.uid!,
                              name: emp.first_name + " " + emp.last_name,
                            }
                          : undefined
                      }
                      onSelect={setEmp}
                    />
                  </div>
                </div>
                <div className="">
                  <label htmlFor="date">Type</label>

                  <select
                    value={leaveRequest.leave_name}
                    name="leave_type"
                    id="leave_type"
                    className="w-full bg-transparent border border-primaryColor text-secondaryColor focus:outline-none rounded py-1 px-2 cursor-pointer"
                    onChange={(e) =>
                      setLeaveRequest((o) => ({
                        ...o,
                        leave_name: e.target.value,
                      }))
                    }
                    required
                  >
                    <option value="" hidden>
                      Select ...
                    </option>
                    {Object.values(LeaveType).map((item, i) => (
                      <option
                        key={i}
                        value={item}
                        className="text-secondaryColor"
                      >
                        {item}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="">
                  {moment(dateRange[0].startDate).format("YYYY-MM-DD") ===
                    moment(dateRange[0].endDate).format("YYYY-MM-DD") &&
                    (leaveRequest.leave_name === LeaveType.paid_leave ||
                      leaveRequest.leave_name === LeaveType.medicle_leave ||
                      leaveRequest.leave_name === LeaveType.unpaid_leave) && (
                      <>
                        <label htmlFor="date">Leave period</label>
                        <br />
                        <select
                          name="leave_period"
                          id=""
                          defaultValue={LeavePeriod.full_day}
                          className="w-full bg-transparent border border-primaryColor text-secondaryColor focus:outline-none rounded py-2 px-2 cursor-pointer"
                        >
                          {Object.values(LeavePeriod).map((el, i) => {
                            return <option value={el}>{el}</option>;
                          })}
                        </select>
                      </>
                    )}
                </div>
              </div>
              <div className="mt-6">
                <label htmlFor="date">Message</label>
                <br />
                <textarea
                  name="message"
                  id="message"
                  cols={30}
                  rows={3}
                  className="resize-none w-full mt-2 bg-transparent border border-primaryColor text-secondaryColor rounded py-1 px-2 focus:outline-none"
                  value={leaveRequest.message}
                  onChange={(e) =>
                    setLeaveRequest((o) => ({
                      ...o,
                      message: e.target.value,
                    }))
                  }
                  required
                ></textarea>
              </div>
              <br />

              <div className="flex flex-col items-end">
                {!loading ? (
                  <button
                    className={`hrm_btn bg-hrmBlue w-1/3 py-3 text-white font-semibold rounded-full hover:scale-105 duration-150 ${
                      loading ? "animate-pulse" : ""
                    }`}
                    disabled={loading}
                  >
                    Submit
                  </button>
                ) : (
                  <div
                    className={`flex flex-col items-center bg-hrmPurple py-3 px-12 text-xl text-white font-semibold rounded hover:scale-105 duration-150 ${
                      loading ? "animate-pulse" : ""
                    }`}
                  >
                    <HrmLoader className="w-8 h-8" />
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CreateLeaveRecord;
