import { mdiMagnify } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useState } from "react";
import {
  ModalAlignment,
  ModalType,
  useModal,
} from "../../../../../context_providers/modal/modal_context";
import { useOrderCreateCtx } from "../../../../../context_providers/order_create_context";
import { useOrderDetailCtx } from "../../../../../context_providers/order_detail_context";
import {
  useToast,
  ToastType,
} from "../../../../../context_providers/toast/toast_ctx";
import { AddressModel } from "../../../../../service/models/address_model";
import { CustomerDetailModel } from "../../../../../service/models/orders/customer_detail_model";
import { getCityStateApi } from "../../../../../service/repos/address/address_repo";
import {
  CreateBillingProfileApi,
  UpdateBillingProfileApi,
} from "../../../../../service/repos/customer/billing_profile_repo";
import {
  UpdateOrderCustomerApi,
  UpdateOrderDeliveryProfileApi,
} from "../../../../../service/repos/order_repo";
import { AddressType, OrderType } from "../../../../../utils/enums";
import LoadingWidget from "../../../../components/loading_spinner";
import AddressPickerWidget from "../../../../new_components/common/location/AddressPicker";

interface AddBillingProfileWidgetProps {
  address_type: "bill" | "delivery";
  type: string;
  user_id: any;
  order_id?: any;
  billing?: CustomerDetailModel;
  onAdded: (data: CustomerDetailModel) => void;
}
const defaultAddress: AddressModel = {
  line1: "",
  city: "",
  state: "",
  country: "India",
  pincode: 0,
};
const defaultValue: CustomerDetailModel = {
  firstName: "",
  mobile: "",
  alt_mobile: "",
  email: "",
  address: defaultAddress,
};
const AddBillingProfileWidget: FunctionComponent<
  AddBillingProfileWidgetProps
> = ({ onAdded, user_id, billing, type, order_id, address_type }) => {
  const { setLoading } = useModal();
  const { showToast } = useToast();
  const { showModal, onClose } = useModal();
  const [customer, setCustomer] = useState<CustomerDetailModel>({
    ...(billing ?? defaultValue),
    user_id,
  });
  // const [loading, setLoading] = useState(false);
  const createBillingProfile = async () => {
    setLoading(true);
    const res = await (address_type == "delivery"
      ? UpdateOrderDeliveryProfileApi({ data: customer, order_id })
      : UpdateOrderCustomerApi({ data: customer, order_id }));
    // : await CreateBillingProfileApi(customer!);
    if (res.success) {
      showToast({
        type: ToastType.success,
        text: customer.id ? "Updated Successfully" : "Created Successfull !",
      });
      setLoading(false);
      return onAdded(res.data);
    } else {
      showToast({ type: ToastType.error, text: res.error });
    }
    setLoading(false);
  };
  const getCityState = async (pincode: number) => {
    setLoading(true);
    const res = await getCityStateApi(pincode);

    if (res.success) {
      if (!res.data) {
        setLoading(false);
        alert("invalid zip code !");
        return;
      }
      const { city, state, pincode } = res.data;
      if (city && state)
        setCustomer((o) => ({
          ...o!,
          address: { ...o?.address, city, state, pincode },
        }));
    } else showToast({ type: ToastType.error, text: res.error ?? "" });
    setLoading(false);
  };

  return (
    <>
      {" "}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          createBillingProfile();
        }}
        className="grid grid-cols-1 md:grid-cols-2 gap-8 text-sm"
      >
        <div className="flex flex-col gap-2">
          <div className="grid grid-cols-1 md:grid-cols-3 items-center">
            <label htmlFor="first-name">
              {type === OrderType.B2C || type === OrderType.DELHI_TENDOR
                ? "First Name*"
                : "Business Name*"}
            </label>
            <input
              id="first-name"
              className="col-span-2 p-2 rounded bg-gray-100 focus:outline-none"
              type="text"
              name="firstName"
              placeholder={
                type === OrderType.B2C || type === OrderType.DELHI_TENDOR
                  ? "First Name"
                  : "Business name"
              }
              value={customer.firstName}
              onChange={(e) =>
                setCustomer((o) => ({
                  ...o!,
                  firstName: e.target.value,
                }))
              }
              required
            />
          </div>
          {(type === OrderType.B2C || type === OrderType.DELHI_TENDOR) && (
            <div className="grid grid-cols-1 md:grid-cols-3 items-center">
              <label htmlFor="last-name">Last Name *</label>
              <input
                id="last-name"
                className="col-span-2 p-2 rounded bg-gray-100 focus:outline-none"
                type="text"
                name="lastName"
                // required
                value={customer?.lastName}
                onChange={(e) =>
                  setCustomer((o) => ({
                    ...o!,
                    lastName: e.target.value,
                  }))
                }
                placeholder={"Last Name"}
              />
            </div>
          )}

          <div className="grid grid-cols-1 md:grid-cols-3 items-center">
            <label htmlFor="mobile">Mobile *</label>
            <input
              required
              type="tel"
              pattern="[1-9]{1}[0-9]{9}"
              className="col-span-2 p-2 rounded bg-gray-100 focus:outline-none "
              id="mobile"
              value={customer?.mobile}
              onChange={(e) =>
                setCustomer((o) => ({ ...o!, mobile: e.target.value.trim() }))
              }
              title="Please enter 10 digit mobile number"
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 items-center">
            <label htmlFor="alt_mobile">Alternate Mobile </label>
            <input
              type="tel"
              pattern="[1-9]{1}[0-9]{9}"
              className="col-span-2 p-2 rounded bg-gray-100 focus:outline-none "
              id="alt_mobile"
              value={customer?.alt_mobile}
              onChange={(e) =>
                setCustomer((o) => ({
                  ...o!,
                  alt_mobile: e.target.value.trim(),
                }))
              }
              title="Please enter 10 digit mobile number"
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 items-center">
            <label htmlFor="email">Email</label>
            <input
              type="text"
              className="col-span-2 p-2 rounded bg-gray-100 focus:outline-none"
              id="email"
              value={customer?.email}
              onChange={(e) =>
                setCustomer((o) => ({ ...o!, email: e.target.value.trim() }))
              }
            />
          </div>
          {(type === OrderType.B2B || type === OrderType.PARTNER) && (
            <div className="grid grid-cols-1 md:grid-cols-3 items-center">
              <label htmlFor="poc">Contact person*</label>
              <input
                id="poc"
                className="col-span-2 p-2 rounded bg-gray-100 focus:outline-none"
                type="text"
                name="poc"
                required
                placeholder="Contact person name"
                value={customer?.poc}
                onChange={(e) =>
                  setCustomer((o) => ({ ...o!, poc: e.target.value }))
                }
              />
            </div>
          )}

          <div className="grid grid-cols-1 md:grid-cols-3 items-center">
            <label htmlFor="gst">GST number</label>
            <input
              id="gst"
              className="col-span-2 p-2 rounded bg-gray-100 focus:outline-none"
              type="text"
              name="gst"
              // required={type === OrderType.B2B || type === OrderType.PARTNER}
              value={customer.gst}
              onChange={(e) =>
                setCustomer((o) => ({ ...o!, gst: e.target.value.trim() }))
              }
              placeholder="GST number"
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 items-center">
            <label htmlFor="ca">CA number</label>
            <input
              id="ca"
              className="col-span-2 p-2 rounded bg-gray-100 focus:outline-none"
              type="text"
              name="ca"
              // required
              value={customer.ca}
              onChange={(e) =>
                setCustomer((o) => ({ ...o!, ca: e.target.value.trim() }))
              }
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <h3>Addess type</h3>
          <div className="flex">
            {Object.values(AddressType).map((type, i) => {
              return (
                <div className="mx-3" key={i}>
                  <input
                    onChange={(e) => {
                      setCustomer((o) => ({
                        ...o!,
                        address: { ...o?.address, type: type },
                      }));
                    }}
                    className="mx-2"
                    type="radio"
                    checked={customer.address.type === type}
                    name="type"
                    required
                    id={`${type}`}
                  />
                  <label className=" font-semibold" htmlFor={`${type}`}>
                    {type}
                  </label>
                </div>
              );
            })}
          </div>
          <div className="flex    flex-col col-span-2">
            <label htmlFor="address-picker">Address</label>
            <div
              onClick={() => {
                showModal({
                  type: ModalType.drawer,
                  title: "Address Picker",
                  alignment: ModalAlignment.right,
                  container(id) {
                    return (
                      <div className="  bg-gray-100 p-1 h-full w-full">
                        <AddressPickerWidget
                          data={customer.address}
                          location_required={false}
                          onSubmit={function (_address: AddressModel): void {
                            setCustomer((o) => ({
                              ...o!,
                              address: { ...o!.address, ..._address },
                            }));
                            onClose(id);
                          }}
                          onCancel={function (): void {
                            onClose(id);
                          }}
                        />
                      </div>
                    );
                  },
                });
              }}
              className="border rounded px-2 p-1 focus:outline-none h-32 w-96 "
            >
              {customer.address.full_address}
            </div>
          </div>
          {/* <div className="grid grid-cols-1 md:grid-cols-3 items-center">
            <label htmlFor="line_1">Line 1*</label>
            <input
              required
              type="text"
              className="col-span-2 p-2 rounded bg-gray-100 focus:outline-none"
              id="line_1"
              value={customer?.address.line1}
              onChange={(e) =>
                setCustomer((o) => ({
                  ...o!,
                  address: { ...o?.address, line1: e.target.value },
                }))
              }
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 items-center">
            <label htmlFor="line_2">Line 2</label>
            <input
              type="text"
              className="col-span-2 p-2 rounded  bg-gray-100 focus:outline-none"
              id="line_2"
              value={customer?.address.line2}
              onChange={(e) =>
                setCustomer((o) => ({
                  ...o!,
                  address: { ...o?.address, line2: e.target.value },
                }))
              }
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 items-center">
            <label htmlFor="mobile">City *</label>
            <input
              required
              type="text"
              className="col-span-2 p-2 rounded bg-gray-100 focus:outline-none "
              id="mobile"
              value={customer?.address.city}
              onChange={(e) =>
                setCustomer((o) => ({
                  ...o!,
                  address: { ...o?.address, city: e.target.value },
                }))
              }
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 items-center">
            <label htmlFor="state">State *</label>
            <input
              required
              type="text"
              className="col-span-2 p-2 rounded bg-gray-100 focus:outline-none"
              id="state"
              value={customer?.address.state}
              onChange={(e) =>
                setCustomer((o) => ({
                  ...o!,
                  address: { ...o?.address, state: e.target.value },
                }))
              }
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 items-center">
            <label htmlFor="country">Country *</label>
            <input
              required
              type="text"
              className="col-span-2 p-2 rounded bg-gray-100 focus:outline-none"
              id="country"
              value={customer?.address.country}
              onChange={(e) =>
                setCustomer((o) => ({
                  ...o!,
                  address: { ...o?.address, country: e.target.value },
                }))
              }
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 items-center">
            <label htmlFor="pincode">Pincode *</label>
            <div className="flex gap-2 col-span-2  items-center">
              <input
                type="text"
                className="p-2 rounded bg-gray-100 focus:outline-none"
                id="pincode"
                placeholder="Postal code"
                pattern="[0-9]{6}"
                title="Please enter a 6 digit pincode"
                value={customer.address.pincode}
                onChange={(e) => {
                  let v = 0;

                  if (e.target.value && !isNaN(e.target.value as any)) {
                    v = Number(e.target.value);
                  }

                  if (e.target.value.length <= 6)
                    setCustomer((o) => ({
                      ...o!,
                      address: { ...o?.address, pincode: v },
                    }));
                  if (e.target.value.length === 6) {
                    getCityState(v);
                  }
                }}
              />
              <div
                className="p-2 cursor-pointer bg-gray-200 rounded-full"
                onClick={() => {
                  if (customer.address.pincode?.toString().length === 6) {
                    getCityState(customer.address.pincode);
                  } else {
                    alert("Please enter 6 digits postal code");
                  }
                }}
              >
                <Icon path={mdiMagnify} className="w-5 h-5"></Icon>
              </div>
            </div>
          </div> */}
        </div>
        <div className="col-span-2 flex justify-center ">
          <button className="bg-myPrimaryColor font-semibold text-sm text-white rounded-3xl px-3 py-1 hover:scale-110 transform duration-300">
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

export default AddBillingProfileWidget;
