import React, { Component } from "react";

import ImageCard from "../../components/installationComponents/imageCard";
import {
  getInstallations,
  updateInsllation,
  deleteInsllation,
} from "../../services/installationService";
import { Alert } from "@material-ui/lab";
import Pagination from "../../ui/new_components/common/pagination";
import SearchFieldWidget from "../../../../new_components/search_field";
import { urls } from "../../../../../utils/urls";
import moment from "moment";
import Icon from "@mdi/react";
import { mdiReload } from "@mdi/js";
import { Link } from "react-router-dom";
import NoRecordsFound from "../../components/common/noRecordsFound";
class InstallationContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 20,
      page: 1,
      total: 0,
      loading: true,
      data: [],
      msg: "",
    };
  }
  componentDidMount() {
    this.getData(this.state.page, this.state.count);
  }
  handleLoading(val) {
    this.setState({ loading: val });
  }
  getData = async (page, count) => {
    this.setState({ loading: true });
    try {
      const res = await getInstallations({
        count: count,
        page: page,
      });
      if (res.success) {
        const total = res.data.metadata?.total || 0;
        this.setState({
          data: res.data.installations,
          page,
          total,
          loading: false,
          timeStamp: res.timeStamp,
        });
        // this.state = { ...this.state, data: res.data, page, total: res.total };
      } else {
        this.setState({
          msg: res.error,

          loading: false,
        });
      }

      // this.state.data =  res;
    } catch (error) {
      console.log(error);
      this.state.msg = "Something went wrong !";
      this.setState({ loading: false });
    }
  };

  async onToggleActive(index) {
    this.setState({ loading: true });
    try {
      this.state.data[index].active = !this.state.data[index].active;

      const formData = new FormData();
      const keys = Object.keys(this.state.data[index]);

      keys.forEach((key, i) => {
        formData.append(key, this.state.data[index][key]);
      });
      await updateInsllation(formData);
      this.setState({ loading: false });
    } catch (error) {
      console.log(error);
      this.state.msg = "Something went wrong !";
      this.setState({ loading: false });
    }
  }

  async onDelete(index) {
    const del = "Delete";
    const ans = prompt(`Enter ${del} to Confirm `);

    if (ans !== del) return;
    this.setState({ loading: true });
    try {
      await deleteInsllation(this.state.data[index]);
      this.state.data.splice(index, 1);
      this.setState({ loading: false });
    } catch (error) {
      console.log(error);
      this.state.msg = "Something went wrong !";
      this.setState({ loading: false });
    }
  }
  render() {
    if (this.state.loading) {
      return <h1>Loading...</h1>;
    }

    return (
      <div className="p-5">
        <div className="flex flex-wrap justify-between  items-end mb-2">
          <div className="">
            <h1 className="font-bold text-2xl text-gray-500 ">
              Installation collages
            </h1>
            <div className="flex gap-2">
              <p className="text-xs text-gray-400">
                Fetched at :
                {this.state.timeStamp
                  ? moment(this.state.timeStamp).format("hh:mm:ss A")
                  : ""}
              </p>
              <span
                className="transform hover:scale-125 duration-300  cursor-pointer text-gray-400 hover:text-gray-800 "
                onClick={() => this.getData(this.state.page, this.state.count)}
              >
                <div title="Refresh" className="flex  items-center ">
                  <Icon path={mdiReload} className="w-4 h-4"></Icon>
                </div>
              </span>
            </div>
          </div>
          <div className="flex gap-2 items-center flex-wrap">
            {/* <SearchFieldWidget
              placeholder="Search"
              defaulValue={filter.search ?? ""}
              onClear={() => {
                setFilter({ ...filter, page: 1, search: "" });
              }}
              onSubmit={(search) => {
                if (search) {
                  setFilter({ ...filter, page: 1, search });
                }
              }}
            /> */}

            {/* <div className="flex gap-1 text-sm flex-col">
            
              <select
                title="Language"
                className=" bg-myBgcolor  border rounded px-2 py-1"
                name="language"
                id="language"
                value={lang}
                onChange={(e) => {
                  setLang(e.target.value);
                }}
              >
                <option value="English">English</option>
                <option value="Hindi">Hindi</option>
                <option value="Kannada">Kannada</option>
                <option value="Marathi">Marathi</option>
              </select>
            </div> */}
            <Link
              title="Create new Blog post"
              to={urls.installationCreate}
              className="bg-myPrimaryColor text-white px-2 py-1 rounded text-sm"
            >
              Add new
            </Link>
          </div>
        </div>

        <div className=" flex justify-center" style={{ height: "83vh" }}>
          <div
            id="scroll_listener"
            className=" overflow-auto h-full border rounded-lg p-1"
          >
            {this.state.data.length > 0 ? (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-3">
                {this.state.data.map((item, i) => (
                  <ImageCard
                    item={item}
                    index={i}
                    onToggleActive={this.onToggleActive.bind(this)}
                    onDelete={this.onDelete.bind(this)}
                  />
                ))}
              </div>
            ) : (
              <NoRecordsFound displayText="No Record found. Please create!" />
            )}
          </div>
        </div>

        <div className="mt-1">
          <Pagination
            page={parseInt(this.state.page)}
            itemCount={parseInt(this.state.data.length)}
            total={parseInt(this.state.total)}
            onChange={(p) => {
              this.setState({ page: p });
              this.getData(p, this.state.count);
            }}
            count={parseInt(this.state.count)}
          />
        </div>
      </div>
    );
  }
}
export default InstallationContainer;
