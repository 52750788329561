import { mdiClose, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useState } from "react";
import { InventoryModel } from "../../../service/models/inventory/inventory_model";
import ProductModel from "../../../service/models/product_model";
import { currencyFormat } from "../../../utils/orders_utils";
// import pdfmake from "pdfmake";
import { TDocumentDefinitions } from "pdfmake/interfaces";
import { inWords } from "../../../utils/number_to_word";
import moment from "moment";
import {
  useToast,
  ToastType,
} from "../../../context_providers/toast/toast_ctx";
import { GetProductsApi } from "../../../service/repos/products_repo";
import UserSearchField from "../orders/order_create/customer_section/customer/user_search_widget";
import { UserModel } from "../../../context_providers/auth/user_model";
import BillingSearchField from "../orders/order_create/customer_section/billing_profile/billing_search_widget";
import { CustomerDetailModel } from "../../../service/models/orders/customer_detail_model";
import { fetchGet } from "../../../service/api_client";
import { fetchPost } from "../content_panel/services/serviceClient";
import DrawerWidget from "../../../context_providers/modal/drawer_widget";
import {
  ModalAlignment,
  ModalType,
} from "../../../context_providers/modal/modal_context";
import { BaseModel } from "../../../service/models/base";
import DcSnapSection from "./components/dc_snaps";
interface ItemModel {
  name: string;
  hsn: string;
  rate: number;
  qty: number;
  amount: number;
  qty_type: string;
}
interface Warehouse {
  id: string;
  name: string;
  address: string;
  gst: string;
  state: string;
  code: string;
  email: string;
  cin: string;
  city: string;
}
interface DeliveryChallanWidgetProps {
  items: InventoryModel[];
}

const DeliveryChallanWidget: FunctionComponent<
  DeliveryChallanWidgetProps
> = () => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/inventory/dc`;
  const { showToast } = useToast();
  const [warehouses, setWarehouses] = useState<Warehouse[]>([
    {
      id: "1",
      name: "KAZAM EV TECH PRIVATE LIMITED",
      address: "#657, 17th D Main, 6th Block, Koramangala Bengaluru - 560095",
      gst: "29AAICK4818R1ZN",
      city: "Bangalore",
      state: "Karnataka",
      code: "29",
      cin: "U40106MH2020PTC351326",
      email: "support@kazam.in",
    },
    {
      id: "1",
      name: "Kazam EV Tech Pvt Ltd (Delhi Office)",
      address: "N-14/27 DLF Phase 2 Sector 25, Gurgaon - 122022",
      gst: "29AAICK4818R1ZN",
      state: "Haryana",
      city: "Gurgaon",
      code: "06",
      cin: "U40106MH2020PTC351326",
      email: "support@kazam.in",
    },
  ]);
  const [fromWarehouse, setFromWarehouse] = useState<Warehouse>();
  const [toWarehouse, setToWarehouse] = useState<Warehouse>();
  const [billTo, setBillTo] = useState<Warehouse>();
  const [date, setDate] = useState<string>(moment().format("YYYY-MM-DD"));
  const [dc_no, setDcno] = useState<string>(Date.now().toString().substring(8));
  const [billToType, setBillToType] = useState("warehouse");
  const [user, setUser] = useState<UserModel>();
  const [deliveryProfile, setDeliveryProfile] = useState<CustomerDetailModel>();
  const [billingProfile, setBillingProfile] = useState<CustomerDetailModel>();
  const [items, setItems] = useState<ItemModel[]>([]);
  const [products, setProducts] = useState<ProductModel[]>([]);
  const [same, setSame] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  useEffect(() => {
    getProducts();
  }, []);
  const getProducts = async () => {
    const res = await GetProductsApi();
    if (res.success) {
      setProducts(res.data);
    }
  };
  let subtotal = 0;
  //  = items.reduce((p, v) => p + v.qty * v.rate, 0);
  let qty = 0;
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    subtotal += item.qty * item.rate;
    qty += item.qty;
  }
  const tax = parseFloat((subtotal * 0.05).toFixed(2));
  const total = Math.ceil(subtotal + tax);
  const round_off = parseFloat((total - (subtotal + tax)).toFixed(2));
  const onClear = () => {
    setFromWarehouse(undefined);
    setToWarehouse(undefined);
    setBillTo(undefined);
    setItems([]);
  };
  const onGenerate = async () => {
    var fonts = {
      Roboto: {
        normal:
          "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.3.0-beta.1/fonts/Roboto/Roboto-Regular.ttf",
        bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.3.0-beta.1/fonts/Roboto/Roboto-Medium.ttf",
        italics:
          "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.3.0-beta.1/fonts/Roboto/Roboto-Italic.ttf",
        bolditalics:
          "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.3.0-beta.1/fonts/Roboto/Roboto-MediumItalic.ttf",
      },
    };
    var pdfmake = require("pdfmake");
    const data: DcData = {
      fromWarehouse: fromWarehouse!,
      toWarehouse: toWarehouse!,
      billTo: same ? toWarehouse : billTo!,
      deliveryProfile,
      billingProfile: same ? deliveryProfile : billingProfile,
      items,
      subtotal,
      total,
      tax,
      round_off,
      qty,
      date: date!,
      dc_no,
    };
    if (!data.fromWarehouse) {
      showToast({
        type: ToastType.warning,
        text: "From warehouse required !",
      });
      return;
    } else if (!data.toWarehouse && !data.deliveryProfile) {
      showToast({
        type: ToastType.warning,
        text: "Shipping profile required !",
      });
      return;
    } else if (!data.billTo && !data.billingProfile) {
      showToast({
        type: ToastType.warning,
        text: "Billing profile required !",
      });
      return;
    } else if (data.items.length === 0) {
      showToast({
        type: ToastType.warning,
        text: "items required !",
      });
      return;
    } else if (!data.date) {
      showToast({
        type: ToastType.warning,
        text: "Datarequired !",
      });
      return;
    }

    // post api
    const res = await fetchPost(url, data);
    if (res.success) {
      showToast({ type: ToastType.success, text: res.message });
      pdfmake.createPdf(get_dc_format(res.data), undefined, fonts).open();
    } else {
      showToast({ type: ToastType.error, text: res.error });
    }
  };
  return (
    <>
      {showHistory && (
        <DrawerWidget
          data={{
            id: 1,
            title: "Delivery challans",
            type: ModalType.drawer,
            alignment: ModalAlignment.right,
            container: <DcSnapSection />,
          }}
          onClose={function (): void {
            setShowHistory(false);
          }}
        ></DrawerWidget>
      )}
      <div className="flex h-screen w-full justify-center items-center">
        <div className="  text-xs ">
          <div className="flex justify-start text-lg font-medium mb-2">
            Delivery Note
          </div>
          <div className="border rounded  bg-white">
            <div className="grid grid-cols-2 ">
              <div className="flex flex-col border-r ">
                <div className="p-2">
                  <select
                    name=""
                    id=""
                    onChange={(e) => {
                      setFromWarehouse(JSON.parse(e.target.value));
                    }}
                  >
                    <option value="" hidden>
                      From warehouse
                    </option>
                    {warehouses.map((w, i) => (
                      <option value={JSON.stringify(w)}>{w.name}</option>
                    ))}
                  </select>
                  {fromWarehouse && (
                    <>
                      {/* <b>{fromWarehouse.name.toUpperCase()}</b> */}
                      {/* <br /> */}
                      <p className="text-xs w-4/5">
                        {fromWarehouse.address}
                        <br />
                        GSTIN/UIN: {fromWarehouse.gst}
                        <br />
                        State Name : {fromWarehouse.state}, Code :{" "}
                        {fromWarehouse.code}
                        <br />
                        CIN: {fromWarehouse.cin} <br />
                        E-Mail : {fromWarehouse.email}
                      </p>
                    </>
                  )}
                </div>

                <div className="text-xs border-t p-2 ">
                  Consignee (Ship to)
                  <div className="flex  gap-5">
                    <div className="flex gap-1">
                      <input
                        type="radio"
                        name="bill_to_type"
                        id="warehouse"
                        checked={billToType === "warehouse"}
                        onChange={(e) => {
                          if (e.target.checked) setBillToType("warehouse");
                        }}
                      />{" "}
                      <label htmlFor="warehouse">Warehouse</label>
                    </div>
                    <div className="flex gap-1">
                      <input
                        type="radio"
                        name="bill_to_type"
                        id="customer"
                        checked={billToType === "customer"}
                        onChange={(e) => {
                          if (e.target.checked) setBillToType("customer");
                        }}
                      />{" "}
                      <label htmlFor="customer">Customer</label>
                    </div>
                  </div>
                  <br />
                  {billToType === "warehouse" && (
                    <>
                      <select
                        name=""
                        id=""
                        onChange={(e) => {
                          setToWarehouse(JSON.parse(e.target.value));
                        }}
                      >
                        <option value="" hidden>
                          Bill to warehouse
                        </option>
                        {warehouses.map((w, i) => (
                          <option value={JSON.stringify(w)}>{w.name}</option>
                        ))}
                      </select>
                      {billTo && (
                        <>
                          {/* <b>{toWarehouse.name.toUpperCase()}</b> */}
                          {/* <br /> */}
                          <p className="text-xs w-4/5">
                            {toWarehouse?.address}
                            <br />
                            State Name : {toWarehouse?.state}, Code :{" "}
                            {toWarehouse?.code}
                          </p>
                        </>
                      )}
                    </>
                  )}
                  {billToType === "customer" && (
                    <>
                      {" "}
                      <div className="w-60">
                        <UserSearchField
                          value={user as any}
                          placeholder="Search customer"
                          onSelect={function (data: UserModel): void {
                            setUser(data);
                          }}
                        />
                      </div>
                      {user && (
                        <>
                          <div className="w-60">
                            <BillingSearchField
                              key={user._id}
                              defaultShow={false}
                              placeholder="Delivery Address"
                              user_id={user.uid}
                              onSelect={function (
                                data: CustomerDetailModel
                              ): void {
                                setDeliveryProfile(data);
                              }}
                            />{" "}
                            {deliveryProfile && (
                              <div className="my-2">
                                <p className="text-xs w-4/5">
                                  {/* {deliveryProfile?.firstName}
                                {deliveryProfile?.lastName}
                                <br /> */}
                                  {deliveryProfile?.address.line1}
                                  {deliveryProfile?.address.line2}
                                  {deliveryProfile?.address.city}
                                  <br /> State Name :{" "}
                                  {deliveryProfile?.address.state}
                                </p>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className="text-xs border-t p-2 ">
                  Buyer (Bill to){" "}
                  <div className="flex  gap-5">
                    <div className="flex gap-1">
                      <input
                        type="checkbox"
                        name="same_as_delivery"
                        id="same_as_delivery"
                        checked={same}
                        onChange={(e) => {
                          setSame(e.target.checked);
                        }}
                      />{" "}
                      <label htmlFor="same_as_delivery">Same as Ship to</label>
                    </div>
                  </div>
                  <br />
                  {!same && billToType === "warehouse" && (
                    <>
                      <select
                        name=""
                        id=""
                        onChange={(e) => {
                          setBillTo(JSON.parse(e.target.value));
                        }}
                      >
                        <option value="" hidden>
                          Bill to warehouse
                        </option>
                        {warehouses.map((w, i) => (
                          <option value={JSON.stringify(w)}>{w.name}</option>
                        ))}
                      </select>
                      {billTo && (
                        <>
                          {/* <b>{toWarehouse.name.toUpperCase()}</b> */}
                          {/* <br /> */}
                          <p className="text-xs w-4/5">
                            {billTo.address}
                            <br />
                            State Name : {billTo.state}, Code : {billTo.code}
                          </p>
                        </>
                      )}
                    </>
                  )}
                  {!same && billToType === "customer" && (
                    <>
                      {user && (
                        <>
                          <div className="w-60">
                            <BillingSearchField
                              key={user._id}
                              defaultShow={false}
                              placeholder="Delivery Address"
                              user_id={user.uid}
                              onSelect={function (
                                data: CustomerDetailModel
                              ): void {
                                setBillingProfile(data);
                              }}
                            />
                          </div>

                          {/* <b>{toWarehouse.name.toUpperCase()}</b> */}
                          {/* <br /> */}
                          {billingProfile && (
                            <p className="text-xs w-4/5">
                              {/* {billingProfile?.firstName}
                            {billingProfile?.lastName}
                            <br /> */}
                              {billingProfile?.address.line1}
                              {billingProfile?.address.line2}
                              {billingProfile?.address.city}
                              <br /> State Name :{" "}
                              {billingProfile?.address.state}
                            </p>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-2 ">
                <div className="flex flex-col gap-1 p-1 border">
                  <p>Delivery note no</p>
                  <p>-</p>
                </div>
                <div className="flex flex-col gap-1 p-1 border">
                  <p>Dated</p>
                  <p>
                    <input
                      value={date}
                      type="date"
                      onChange={(e) => {
                        setDate(moment(e.target.value).format("DD MMM YYYY"));
                      }}
                    />
                  </p>
                </div>
                <div className="flex flex-col gap-1 p-1 border"></div>
                <div className="flex flex-col gap-1 p-1 border">
                  <p>Mode/Term of Payment</p>
                  <p></p>
                </div>
                <div className="flex flex-col gap-1 p-1 border">
                  <p>Reference no & Date</p>
                  <p>dt. {moment(date).format("DD-MM-YYYY")}</p>
                </div>
                <div className="flex flex-col gap-1 p-1 border">
                  <p>Other References</p>
                  <p> </p>
                </div>
                <div className="flex flex-col gap-1 p-1 border">
                  <p>Buyers order no</p>
                  <p> </p>
                </div>
                <div className="flex flex-col gap-1 p-1 border">
                  <p>Date</p>
                  <p> </p>
                </div>
                <div className="flex flex-col gap-1 p-1 border">
                  <p>Dispatch doc no</p>
                </div>
                <div className="flex flex-col gap-1 p-1 border"></div>
                <div className="flex flex-col gap-1 p-1 border">
                  <p>Dispatch through</p>
                  <p> courier</p>
                </div>
                <div className="flex flex-col gap-1 p-1 border">
                  <p>Destination</p>
                  <p> {toWarehouse?.city}</p>
                </div>
                <div className="col-span-2 h-20">Terms of delivery</div>
              </div>
            </div>

            <table
              className="w-full"
              style={{ borderCollapse: "collapse", border: "1px solid" }}
            >
              <thead>
                <th className="border"></th>
                <th className="border" align="center">
                  SL NO.
                </th>
                <th className="border" align="left">
                  Description
                </th>
                <th className="border">HSN/SAC</th>
                <th className="border">QTY</th>
                <th className="border" align="right">
                  Rate
                </th>
                <th className="border">Per</th>
                <th className="border" align="right">
                  Amount
                </th>
              </thead>
              <tbody>
                {items.map((it, i) => (
                  <tr key={i}>
                    <td className="border">
                      <div
                        className=""
                        onClick={() =>
                          setItems((o) => o.filter((x, j) => j !== i))
                        }
                      >
                        <Icon path={mdiClose} className="h-5 w-5"></Icon>
                      </div>
                    </td>
                    <td className="border" align="center">
                      {i + 1}
                    </td>
                    <td className="border w-72">{it.name}</td>
                    <td className="border" align="center">
                      {it.hsn}
                    </td>
                    <td className="border" align="center">
                      <div className="flex gap-1">
                        <input
                          className="w-10 text-right "
                          type="text"
                          value={it.qty}
                          onChange={(e) => {
                            if (!e.target.value) {
                              setItems((old) =>
                                old.map((o, j) => {
                                  if (j === i) {
                                    o.qty = 0;
                                  }
                                  return o;
                                })
                              );
                            }
                            if (
                              e.target.value &&
                              !isNaN(e.target.value as any)
                            ) {
                              setItems((old) =>
                                old.map((o, j) => {
                                  if (j === i) {
                                    o.qty = parseInt(e.target.value);
                                  }
                                  return o;
                                })
                              );
                            }
                          }}
                        />{" "}
                        {it.qty_type}
                      </div>
                    </td>
                    <td className="border" align="right">
                      <input
                        className="w-16 text-right"
                        type="number"
                        step="any"
                        name="price"
                        id="price"
                        min={1}
                        value={it.rate}
                        onChange={(e) => {
                          if (!e.target.value) {
                            setItems((old) =>
                              old.map((o, j) => {
                                if (j === i) {
                                  o.rate = 0;
                                }
                                return o;
                              })
                            );
                          } else
                            setItems((old) =>
                              old.map((o, j) => {
                                if (j === i) {
                                  o.rate = parseFloat(e.target.value);
                                }
                                return o;
                              })
                            );
                        }}
                      />{" "}
                    </td>
                    <td className="border" align="center">
                      {it.qty_type}
                    </td>
                    <td className="border" align="right">
                      {currencyFormat(it.rate * it.qty)}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td className="border"></td>
                  <td className="border"></td>
                  <td className="border ">
                    <div className="w-full">
                      <select
                        name=""
                        id=""
                        onChange={(e) => {
                          const item: ProductModel = JSON.parse(e.target.value);
                          const it: ItemModel = {
                            name: item.name,
                            hsn: item.hsn,
                            rate: item.price,
                            qty: 1,
                            amount: item.price,
                            qty_type: "nos",
                          };
                          e.target.value = "";
                          setItems((o) => [...o, it]);
                          // setBillTo(JSON.parse(e.target.value));
                        }}
                      >
                        <option value="" hidden>
                          Product
                        </option>
                        {products.map((p, i) => (
                          <option value={JSON.stringify(p)}>{p.name}</option>
                        ))}
                      </select>
                    </div>
                  </td>
                  <td className="border" align="center"></td>
                  <td className="border" align="center"></td>
                  <td className="border" align="right"></td>
                  <td className="border" align="center"></td>
                  <td className="border" align="right"></td>
                </tr>
                {/* <tr>
              <td className="border">
                <div className="">
                  <Icon path={mdiPlus} className="h-5 w-5"></Icon>
                </div>
              </td>
              <td className="border"></td>
              <td className="border w-72"> </td>
              <td className="border" align="center"></td>
              <td className="border" align="center"></td>
              <td className="border" align="right"></td>
              <td className="border" align="center"></td>
              <td className="border" align="right"></td>
            </tr> */}

                <tr>
                  <td className="border"></td>
                  <td className="border"></td>
                  <td className="border text-right">Output IGST @5%(KN)</td>
                  <td className="border" align="center">
                    {" "}
                  </td>
                  <td className="border" align="center">
                    {" "}
                  </td>
                  <td className="border" align="right">
                    5
                  </td>
                  <td className="border" align="center">
                    %
                  </td>
                  <td className="border" align="right">
                    {currencyFormat(tax)}
                  </td>
                </tr>
                <tr>
                  <td className="border"></td>
                  <td className="border"></td>
                  <td className="border text-right">Round off</td>
                  <td className="border" align="center">
                    {" "}
                  </td>
                  <td className="border" align="center">
                    {" "}
                  </td>
                  <td className="border" align="right"></td>
                  <td className="border" align="center"></td>
                  <td className="border" align="right">
                    {currencyFormat(round_off)}
                  </td>
                </tr>
                <tr className="font-semibold">
                  <td className="border"></td>
                  <td className="border"></td>
                  <td className="border text-right">Total</td>
                  <td className="border" align="center">
                    {" "}
                  </td>
                  <td className="border" align="center">
                    {qty}
                  </td>
                  <td className="border" align="right"></td>
                  <td className="border" align="center"></td>
                  <td className="border" align="right">
                    {currencyFormat(total)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="flex my-2 gap-2">
            <button className="border rounded p-1" onClick={onClear}>
              Clear
            </button>
            <button className="border rounded p-1" onClick={onGenerate}>
              Generate
            </button>
            <button
              className="border rounded p-1"
              onClick={() => setShowHistory(true)}
            >
              History
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeliveryChallanWidget;
export interface DcData extends BaseModel {
  fromWarehouse: Warehouse;
  toWarehouse?: Warehouse;
  billTo?: Warehouse;
  billingProfile?: CustomerDetailModel;
  deliveryProfile?: CustomerDetailModel;
  items: ItemModel[];
  subtotal: number;
  tax: number;
  total: number;
  round_off: number;
  qty: number;
  date: string;
  dc_no: string;
}
const get_dc_format = (data: DcData) => {
  // playground requires you to assign document definition to a variable called dd
  const items = data.items.map((it, i) => [
    i + 1,
    it.name,
    it.hsn,
    it.qty + " " + it.qty_type,
    { text: currencyFormat(it.rate), alignment: "right" },
    it.qty_type,
    {
      text: currencyFormat(it.qty * it.rate),
      alignment: "right",
    },
  ]);

  const tax_items = data.items.map((t, i) => [
    t.hsn,
    { text: currencyFormat(t.qty * t.rate), alignment: "right" },
  ]);
  var dd: TDocumentDefinitions = {
    content: [
      {
        text: "DELIVERY NOTE\n\n",
        alignment: "center",
        bold: true,
        fontSize: 18,
      },

      {
        style: "tableExample",
        table: {
          widths: ["*", "*"],
          body: [
            [
              {
                style: "tableExample",
                table: {
                  widths: ["*"],
                  body: [
                    [
                      {
                        text: [
                          {
                            text: [
                              {
                                text: `${data.fromWarehouse.name}\n`,
                                style: "header",
                              },
                              {
                                text: `${data.fromWarehouse.address}\nGSTIN/UIN:${data.fromWarehouse.gst}\nState Name : ${data.fromWarehouse.state}, Code : ${data.fromWarehouse.code}\nCIN: ${data.fromWarehouse.cin}\nE-Mail : ${data.fromWarehouse.email}\n`,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                    [
                      {
                        text: [
                          {
                            text: [
                              {
                                text: "Consignee (Ship to)\n",
                              },
                              {
                                text: `${
                                  data.toWarehouse
                                    ? data.toWarehouse.name
                                    : data.deliveryProfile?.firstName +
                                      " " +
                                      data.deliveryProfile?.lastName
                                }\n`,
                                style: "header",
                              },
                              {
                                text: `${
                                  data.toWarehouse
                                    ? `${data.toWarehouse.address}\nState Name : ${data.toWarehouse.state}, Code : ${data.toWarehouse.code}`
                                    : `${data.deliveryProfile?.address.line1}\nState Name : ${data.deliveryProfile?.address.state}`
                                }   \n `,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                    [
                      {
                        text: [
                          {
                            text: [
                              {
                                text: "Buyer (Bill to)\n",
                              },
                              {
                                text: `${
                                  data.toWarehouse
                                    ? data.toWarehouse.name
                                    : data.billingProfile?.firstName +
                                      " " +
                                      data.billingProfile?.lastName
                                }\n`,
                                style: "header",
                              },
                              {
                                text: `${
                                  data.toWarehouse
                                    ? `${data.toWarehouse.address}\nState Name : ${data.toWarehouse.state}, Code : ${data.toWarehouse.code}`
                                    : `${data.billingProfile?.address.line1}\nState Name : ${data.billingProfile?.address.state}`
                                }   \n `,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  ],
                },
              },
              {
                style: "tableExample",
                table: {
                  widths: ["*", "*"],
                  body: [
                    [
                      {
                        text: [
                          { text: "Delivery Note no\n" },
                          { text: data.dc_no },
                        ],
                      },
                      { text: [{ text: "Dated\n" }, { text: data.date }] },
                    ],
                    [
                      { text: [{ text: "" }, { text: " " }] },
                      {
                        text: [
                          { text: "Mode/Terms of Payment\n" },
                          { text: " " },
                        ],
                      },
                    ],
                    [
                      {
                        text: [
                          { text: "Reference No. & Date\n" },
                          { text: data.dc_no + " dt. " + data.date },
                        ],
                      },
                      { text: [{ text: "Other References\n" }, { text: " " }] },
                    ],
                    [
                      {
                        text: [{ text: "Buyer's Order No.\n" }, { text: " " }],
                      },
                      { text: [{ text: "Dated\n" }, { text: " " }] },
                    ],
                    [
                      { text: [{ text: "Dispatch Doc No \n" }, { text: "1" }] },
                      { text: [{ text: " \n" }, { text: " " }] },
                    ],
                    [
                      {
                        text: [
                          { text: "Dispatched through\n" },
                          { text: "Courier" },
                        ],
                      },
                      {
                        text: [
                          { text: "Destination\n" },
                          {
                            text: data.toWarehouse
                              ? data.toWarehouse.city
                              : data.deliveryProfile?.address.city,
                          },
                        ],
                      },
                    ],
                    [
                      {
                        colSpan: 2,
                        // rowSpan: 3,

                        text: [
                          { text: "Terms of Delivery\n\n\n" },
                          { text: " " },
                        ],
                      },
                    ],
                  ],
                },
              },
            ],
            [
              {
                colSpan: 2,

                table: {
                  headerRows: 1,
                  widths: [20, "*", "auto", "auto", "auto", "auto", "auto"],
                  body: [
                    [
                      { text: "Sl No", style: "tableHeader" },
                      {
                        text: "Description of\nGoods and services",
                        style: "tableHeader",
                        alignment: "center",
                      },
                      { text: "HSN/SAC", style: "tableHeader" },
                      { text: "QTY", style: "tableHeader" },
                      { text: "Rate", style: "tableHeader" },
                      { text: "Per", style: "tableHeader" },
                      { text: "Amount", style: "tableHeader" },
                    ],
                    ...items,
                    // [
                    //   "1",
                    //   "Kazam LEV AC",
                    //   "85044030",
                    //   "14 Nos",
                    //   { text: "2,857.14", alignment: "right" },
                    //   "Nos",
                    //   { text: "39,999.96", alignment: "right" },
                    // ],
                    [
                      "",
                      { text: "Output IGST @ 5% (KN)", alignment: "right" },
                      "",
                      "",
                      { text: "5", alignment: "right" },
                      "%",
                      { text: currencyFormat(data.tax), alignment: "right" },
                    ],
                    [
                      "",
                      { text: " Round Off", alignment: "right" },
                      "",
                      "",
                      "",
                      "",
                      {
                        text: currencyFormat(data.round_off),
                        alignment: "right",
                      },
                    ],
                    [
                      "",
                      {
                        text: " Total",
                        alignment: "right",
                        style: "tableHeader",
                      },
                      "",
                      { text: data.qty, style: "tableHeader" },
                      "",
                      "",
                      {
                        text: currencyFormat(data.total),
                        alignment: "right",
                        style: "tableHeader",
                      },
                    ],
                  ],
                },
              },
            ],
            [
              {
                colSpan: 2,
                text: [
                  { text: "Amount  chargeable(in words)\n" },
                  { text: `${inWords(data.total)}`, bold: true, fontSize: 12 },
                ],
              },
            ],

            [
              {
                colSpan: 2,

                table: {
                  widths: ["*", "auto"],
                  body: [
                    [
                      {
                        text: "HSN/SAC",
                        style: "tableHeader",
                        alignment: "center",
                      },
                      { text: "Taxable AMT", style: "tableHeader" },
                    ],
                    ...tax_items,
                    [
                      {
                        text: " Total",
                        alignment: "right",
                        style: "tableHeader",
                      },
                      {
                        text: `${currencyFormat(data.subtotal)}`,
                        alignment: "right",
                        style: "tableHeader",
                      },
                    ],
                  ],
                },
              },
            ],

            [
              {
                colSpan: 2,
                text: [
                  { text: "Tax Amount : " },
                  {
                    text: `${currencyFormat(data.subtotal)}\n\n\n`,
                    style: "header",
                  },
                  { text: "Company's PAN	:" },
                  { text: "AAICK4818R", style: "header" },
                ],
              },
            ],

            [
              {
                text: "Recd. In Good condition",
              },
              {
                text: [
                  { text: "for \t" },
                  {
                    text: `${
                      data.toWarehouse ? data.toWarehouse.name : "Customer "
                    }\n\n\n`,
                    style: "header",
                  },
                  { text: "Authorised Signator", alignment: "right" },
                ],
              },
            ],
          ],
        },
      },
      {
        text: "This is a Computer Generated Document",
        alignment: "center",
      },
    ],

    pageSize: "A4",
    pageMargins: 30,
    styles: {
      tableHeader: {
        bold: true,
        fontSize: 10,
        color: "black",
      },
      header: {
        fontSize: 12,
        bold: true,
      },
      logo: {
        fontSize: 30,
        bold: true,
      },
      bigger: {
        fontSize: 15,
        italics: true,
      },
    },
    defaultStyle: {
      fontSize: 10,
      columnGap: 20,
    },
  };
  return dd;
};
