import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  deleteMagazineRepo,
  getMagazinesRepo,
  updateMagazineStatusRepo,
} from "../../../services/magazine";
import { URLS } from "../../../infrastructure/utils/urls";
import Pagination from "../../../ui/new_components/common/pagination";
import { useAuth } from "../../../../../../context_providers/auth/authProvider";
import { ACTION, MODULE } from "../../../../../../utils/enums";

const MagazineListingPage = () => {
  const { isAuthorised } = useAuth();

  const history = useHistory();
  const [count, setCount] = useState(10);

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const [mag, setMag] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const getMagazines = async () => {
    const res = await getMagazinesRepo({ count, page });

    if (res.success) {
      setMag([...res.data]);
      setTotal(res.total);
    } else alert(res.error);
  };
  const updateStatus = async ({ id, active }) => {
    const res = await updateMagazineStatusRepo({ id, active });

    if (res.success) {
      alert(res.message);
      // getMagazines();
    } else alert(res.error);
  };
  const deleteMag = async (id) => {
    const del = "Delete";
    const ans = prompt(`Enter ${del} to Confirm `);

    if (ans !== del) return;
    const res = await deleteMagazineRepo(id);

    if (res.success) {
      alert(res.message);
      getMagazines();
    } else alert(res.error);
  };
  useEffect(() => getMagazines(), [page, count]);
  useEffect(() => {
    if (isAuthorised({ module: MODULE.CONTENT, action: [ACTION.DELETE] }))
      setShowDelete(true);
  }, []);
  return (
    <div className="h-100 w-100 overflow-y-auto overflow-x-hidden">
      {mag.length === 0 ? (
        <div className="text-gray-400 text-lg text-center my-10">
          {" "}
          No Magazine found !
        </div>
      ) : (
        <>
          <div className="grid grid-cols-5 gap-5">
            {mag.map((item, i) => (
              <div key={i} className="w-100 h-auto  rounded shadow px-3 py-2">
                <img
                  src={item.img_url}
                  alt={item.title}
                  className="h-auto w-100"
                />
                <div className="text-sm font-bold my-2">{item.title}</div>{" "}
                <div className="">
                  <div class="relative inline-block w-9 mr-2 align-middle select-none transition duration-200 ease-in">
                    <input
                      type="checkbox"
                      name="toggle"
                      id={i}
                      className={`toggle-checkbox absolute block w-5 h-5 rounded-full bg-white border-4 appearance-none cursor-pointer ${
                        item.active
                          ? "right-0 border-green-400"
                          : "border-red-400"
                      }`}
                      onChange={(e) => {
                        if (e.target.checked !== mag[i].active) {
                          mag[i].active = e.target.checked;
                          updateStatus({
                            id: mag[i].id,
                            active: e.target.checked,
                          });
                          setMag([...mag]);
                        }
                      }}
                    />
                    <label
                      htmlFor={i}
                      className={`toggle-label block overflow-hidden h-5 rounded-full bg-gray-300 cursor-pointer ${
                        item.active ? "bg-green-300" : "bg-red-400"
                      }`}
                    ></label>{" "}
                  </div>
                  {item.active ? "Live" : "offline"}
                </div>
                <div className="flex justify-between">
                  <button
                    onClick={() => {
                      history.push(URLS.magazineEdit, item);
                    }}
                    className=" bg-red-400 rounded-full px-3 py-1 hover:bg-red-700
                  text-white text-center no-underline"
                  >
                    edit
                  </button>
                  <a
                    href={item.pdf_url}
                    target="_blank"
                    rel="noreferrer"
                    className=" bg-red-400 rounded-full px-3 py-1 hover:bg-red-700
                  text-white text-center no-underline"
                  >
                    view
                  </a>
                  {showDelete && (
                    <button
                      onClick={() => deleteMag(item.id)}
                      className=" border-2 border-red-400 rounded-full px-3 py-1 hover:bg-red-400 hover:text-white "
                    >
                      Delete
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-center items-center my-5">
            <p className="mx-5">Total Records : {total}</p>

            <div className="">
              <label htmlFor="count">Records per page</label>
              <select
                className=" mx-2 px-3 py-2 bg-gray-100  focus:outline-none rounded"
                name="count"
                id="count"
                onChange={(e) => {
                  const t = parseInt(e.target.value);
                  setCount(t);
                }}
                value={count}
              >
                <option selected value="10">
                  10
                </option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>
          <Pagination
            page={page}
            itemCount={mag.length}
            total={total}
            onChange={(p) => {
              setPage(p);
            }}
            count={count}
          />
        </>
      )}
    </div>
  );
};

export default MagazineListingPage;
