import { mdiRefresh } from "@mdi/js";
import Icon from "@mdi/react";
import { da } from "date-fns/locale";
import debounce from "lodash.debounce";
import moment from "moment";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import LoadingWidget from "../../../../context_providers/modal/loader";
import { useModal } from "../../../../context_providers/modal/modal_context";
import {
  useToast,
  ToastType,
} from "../../../../context_providers/toast/toast_ctx";
import { FieldType } from "../../../../procurement/components/create_view/form_field";
import TableView from "../../../../procurement/components/table_comp";
import { fetchGet, toQueryString } from "../../../../service/api_client";
import SearchFieldWidget from "../../../../ui/new_components/search_field";
import { UTCToLocalDate } from "../../../../utils/date_util";
import { currencyFormat } from "../../../../utils/orders_utils";
import { urls } from "../../../../utils/urls";
import {
  accountTxnType,
  LedgerEntryModel,
  voucherType,
} from "../../models/common_model";
import { LedgerModel } from "../../models/ledger_model";
import { SalesVoucherModel } from "../../models/sale_model ";

interface LedgerEntriesListScreenProps {}

const LedgerEntriesListScreen: FunctionComponent<
  LedgerEntriesListScreenProps
> = () => {
  const { id } = useParams<{ id: string }>();
  const navaigation = useHistory();
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/accounts/ledgers/${id}`;
  const { showModal, onClose } = useModal();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(50);
  const [total, setTotal] = useState(0);

  const [currentDebitTotal, setCurrentDebitTotal] = useState(0);
  const [currentCreditTotal, setCurrenCreditTotal] = useState(0);
  const [ledger, setLedger] = useState<LedgerModel>();
  const [data, setData] = useState<LedgerEntryModel[]>([]);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  useEffect(() => {
    const now = new Date();
    let from = new Date(new Date(now).setHours(0, 0, 0, 0));
    from = new Date(from.setDate(1));
    let to = new Date(new Date(now).setHours(23, 59, 59, 999));
    to = new Date(to.setMonth(now.getMonth() + 1));
    to = new Date(to.setDate(0));
    setFromDate(from);
    setToDate(to);
  }, []);
  const [summery, setSummery] = useState<{
    debit: number;
    credit: number;
    ledger: LedgerModel;
  }>();
  const [search, setSearch] = useState("");
  const getLedger = async () => {
    setLoading(true);
    try {
      const res = await fetchGet(url);
      if (res.success) {
        setLedger(res.data);
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
      //   return { success: false, error: error.message };
    }
    setLoading(false);
  };

  const fetchData = async (prop: {
    search?: string;
    page: number;
    count: number;
    all?: boolean;
    fromDate: Date;
    toDate: Date;
  }) => {
    setLoading(true);
    try {
      const res = await fetchGet(url + "/entries" + toQueryString(prop));
      if (res.success) {
        setData(res.data.data.data);
        setSummery(res.data.summery);
        if (res.data.data.metadata) {
          setTotal(res.data.data.metadata.total);
        }
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
      //   return { success: false, error: error.message };
    }
    setLoading(false);
  };
  const debouncedHandler = useCallback(
    debounce(
      (prop: {
        search?: string;
        page: number;
        count: number;
        all?: boolean;
        fromDate: Date;
        toDate: Date;
      }) => {
        if (prop) fetchData(prop);
        else setData([]);
      },
      100
    ),
    []
  );
  useEffect(() => {
    getLedger();
  }, []);
  useEffect(() => {
    if (ledger) debouncedHandler({ page, count, search, fromDate, toDate });
  }, [page, fromDate, toDate, ledger]);
  // useEffect(() => {
  //   fetchData({ page, count, search, fromDate, toDate });
  // }, [page]);
  // useEffect(() => {
  //   if (search) fetchData({ page, count, search, fromDate, toDate });
  // }, [search]);
  // return <LedgerCreateScreen />;

  useEffect(() => {
    let debit = 0;
    let credit = 0;
    for (let i = 0; i < data.length; i++) {
      const entry = data[i];
      if (entry.type === accountTxnType.debitor) debit += entry.amount;
      if (entry.type === accountTxnType.creditor) credit += entry.amount;
    }
    setCurrentDebitTotal(debit);
    setCurrenCreditTotal(credit);
  }, [data]);

  const onSelect = (d: LedgerEntryModel) => {
    let url = ``;
    if (d.voucher?.voucher_type === voucherType.purchase)
      url = urls.purchase_voucher + "/update/" + d.voucher_id;
    else if (d.voucher?.voucher_type === voucherType.payment)
      url = urls.payment_voucher + "/update/" + d.voucher_id;
    else if (d.voucher?.voucher_type === voucherType.sales)
      url = urls.sales_voucher + "/update/" + d.voucher_id;
    else if (d.voucher?.voucher_type === voucherType.receipt)
      url = urls.receipt_voucher + "/update/" + d.voucher_id;
    if (!url) return;
    navaigation.push(url);
  };
  const closing_bal = (summery?.credit || 0) - (summery?.debit || 0);
  return (
    <>
      <div
        id="page_container"
        className="flex flex-col justify-between h-full p-1"
      >
        <section
          id="top_section"
          className="flex justify-between items-end my-1 "
        >
          <h1 className="font-bold text-2xl text-gray-500 flex items-center gap-3">
            <p className="border-l-4 border-myPrimaryColor pl-2">
              {" "}
              {ledger?.name}
            </p>
            <button
              title="Refersh"
              onClick={() =>
                debouncedHandler({ page, count, search, fromDate, toDate })
              }
            >
              <Icon path={mdiRefresh} className="h-6 w-6"></Icon>
            </button>
          </h1>
          <div className="flex justify-end   text-sm items-center gap-1 ">
            {/* <div className="">
              Opening balance :
              {ledger && (
                <b>
                  {rupeeFormat(ledger?.opening_balance?.amount)}{" "}
                  {ledger?.opening_balance?.txn_type === accountTxnType.debitor
                    ? "Dr"
                    : "Cr"}
                </b>
              )}
            </div>{" "} */}
            <div className="text-sm flex items-center gap-2">
              <div className="flex items-center  gap-1">
                <label htmlFor="fromDate" className="">
                  From
                </label>
                <input
                  type="date"
                  name=""
                  id="fromDate"
                  className="p-1 rounded "
                  value={moment(fromDate).format("YYYY-MM-DD")}
                  onChange={(e) => {
                    const now = UTCToLocalDate(e.target.value)!;
                    const from = new Date(new Date(now).setHours(0, 0, 0, 0));

                    setFromDate(from);
                  }}
                />
              </div>
              <div className="flex items-center  gap-1">
                <label htmlFor="toDate" className="">
                  To
                </label>
                <input
                  type="date"
                  name=""
                  id="toDate"
                  className="p-1 rounded "
                  value={moment(toDate).format("YYYY-MM-DD")}
                  onChange={(e) => {
                    const now = UTCToLocalDate(e.target.value)!;
                    const to = new Date(
                      new Date(now).setHours(23, 59, 59, 999)
                    );
                    setToDate(to);
                  }}
                />
              </div>
            </div>
            {/* <SearchFieldWidget
              defaulValue={search}
              onClear={() => {
                setSearch("");
                fetchData({ page: 1, count });
              }}
              onSubmit={(val) => {
                fetchData({ page: 1, count, search: val });
                setPage(1);
                setSearch("");

                // setTimeout(() => {
                //   setSearch(val);
                // }, 10);
              }}
            /> */}
            {/* <div
              onClick={() => {
                showModal({
                  title: `Add Ledger`,
                  type: ModalType.modal,
                  container(id) {
                    return (
                      <LedgerCreateScreen
                        onClose={(d) => {
                          if (d)
                            setData((o) => {
                              o.pop();
                              return [d, ...o];
                            });
                          setTotal((o) => o + 1);
                          onClose(id);
                        }}
                      />
                    );
                  },
                });
              }}
              className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
            >
              <Icon path={mdiPlus} className="h-4 w-4"></Icon>Add
            </div> */}
          </div>
        </section>{" "}
        <TableView
          headers={[
            {
              key: "date",
              title: "Date",
              type: FieldType.date,
            },
            {
              colspan: 4,
              key: "party",
              title: "Particulars",
              type: FieldType.long_string,
            },
            {
              colspan: 1,
              key: "voucher",
              title: "voucher",
              type: FieldType.long_string,
            },

            {
              key: "debit",
              title: "Debit",
              type: FieldType.currency,
            },
            {
              key: "credit",
              title: "Credit",
              type: FieldType.currency,
            },
          ]}
          rows={data.map((data, i) => {
            return {
              data: data,
              values: [
                data.voucher?.date,

                data.voucher?.party_ledger_entry?.ledger?.name,
                data.voucher?.voucher_type,
                currencyFormat(
                  data.type === accountTxnType.debitor ? data.amount : 0
                ),
                currencyFormat(
                  data.type === accountTxnType.creditor ? data.amount : 0
                ),
              ],
            };
          })}
          page={page}
          total={total}
          count={count}
          itemCount={data.length}
          onPageChange={setPage}
          onClick={onSelect}
        />
        <div className="flex justify-end text-xs ">
          <div className="grid grid-cols-3  divide-y p-1 ">
            <div className="p-1"></div>
            <div className="p-1">Debit</div>
            <div className="p-1">Credit</div>
            <div className="p-1 font-semibold">Opening balance :</div>
            <div className="p-1  font-semibold">
              {ledger?.opening_balance?.txn_type === accountTxnType.debitor
                ? currencyFormat(ledger?.opening_balance?.amount)
                : 0}
            </div>
            <div className="p-1  font-semibold">
              {ledger?.opening_balance?.txn_type === accountTxnType.creditor
                ? currencyFormat(ledger?.opening_balance?.amount)
                : 0}
            </div>
            <div className="p-1">Current total :</div>
            <div className="p-1">{currencyFormat(currentDebitTotal)}</div>
            <div className="p-1">{currencyFormat(currentCreditTotal)}</div>
            <div className="p-1  font-semibold">Closing balance :</div>
            <div className="p-1  font-semibold">
              {closing_bal < 0 ? currencyFormat(closing_bal * -1) : 0}
            </div>
            <div className="p-1  font-semibold">
              {closing_bal >= 0 ? currencyFormat(closing_bal) : 0}
            </div>
          </div>
        </div>
      </div>

      {loading && <LoadingWidget></LoadingWidget>}
    </>
  );
};

export default LedgerEntriesListScreen;
