import moment from "moment";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { UserModel } from "../../../../../context_providers/auth/user_model";
import html2canvas from "html2canvas";
import HrmLoader from "../../components/loading";

interface IdCardProps {
  user: UserModel;
}

const IdCard: FunctionComponent<IdCardProps> = ({ user }) => {
  const [loading, setLoading] = useState<boolean>(false);

  // const downloadId = async (classname: any, filename: any) => {
  //   const element = document.querySelector(classname);
  //   if (element) {
  //     html2canvas(element).then((canvas) => {
  //       const imgData = canvas.toDataURL("image/png");
  //       const link = document.createElement("a");
  //       link.href = imgData;
  //       link.download = filename;
  //       link.click();
  //     });
  //   }
  //   setLoading(false);
  // };

  const downloadImage = async () => {
    const element = document.querySelector(".sample");
    console.log("element", element);

    if (element) {
      // @ts-ignore
      const canvas = await html2canvas(element, { useCORS: true });
      const dataUrl = canvas.toDataURL("image/png");
      console.log(dataUrl);

      const link = document.createElement("a");
      console.log(link);

      link.href = dataUrl;

      link.download = "id_card.png";
      link.click();
    }
  };

  const fieldData = [
    {
      name: "Name",
      value: user ? user?.first_name + " " + user?.last_name : "",
    },
    {
      name: "Contact",
      value: user?.mobile ?? "-",
    },
    {
      name: "Designation",
      value: user?.emp_profile?.designation ?? "-",
    },
    {
      name: "Blood Group",
      value: user?.emp_profile?.blood_group ?? "-",
    },
    {
      name: "Emg contact",
      value: user?.emp_profile?.emergency_contact ?? "-",
    },
    {
      name: "Date of joining",
      value: moment(user?.emp_profile?.doj).format("DD-MM-YYYY") ?? "-",
    },
  ];

  return (
    <div className="lg:flex gap-20">
      <div className=" rounded-3xl w-80 idcard1">
        <div className="bg-black py-4 flex flex-col items-center rounded-t-3xl">
          <img src="/kazam-text-logo.png" alt="" className="w-28" />
        </div>
        <div className="flex flex-col items-center bg-white py-10 ">
          <div className="w-36 h-36">
            <img
              src={user?.profile_image}
              alt=""
              className="rounded-full w-full h-full"
            />
          </div>
          <div className="text-red-500 text-2xl font-bold pt-6 z-30">
            {user ? user?.first_name + " " + user?.last_name : ""}
          </div>
          <div className="pt-2 font-thin z-30">
            {user?.emp_profile?.designation}
          </div>
          <div className="font-thin z-30">{user?.emp_profile?.department}</div>
        </div>

        <div className="bg-black text-white text-opacity-80 px-6 text-sm py-6 rounded-b-3xl relative">
          <div className="w-12 h-12 rounded-full bg-white absolute -top-8 left-1/2 transform -translate-x-1/2 z-20"></div>
          <div className="flex justify-between">
            <div className="flex justify-between gap-4">
              <div className="">
                <img
                  src="/images/id-card/dots-white.svg"
                  alt=""
                  className="h-16"
                />
              </div>
              <div className="flex flex-col gap-1">
                <div className="">ID</div>
                <div className="">Phone</div>
                <div className="">Email</div>
              </div>
            </div>
            <div className="text-right flex flex-col gap-1">
              <div className="">{user?.emp_profile?.emp_id}</div>
              <div className="">{user?.mobile}</div>
              <div className="">{user?.email}</div>
            </div>
          </div>
          <div className="text-center mt-4">wwww.kazam.in</div>
        </div>
      </div>
      {/* Back card */}
      <div className="w-80 idcard2 mt-12 lg:mt-0">
        <div className="bg-black flex items-center justify-between py-6 px-4 rounded-t-3xl">
          <div className="text-xl text-white font-bold">
            {user?.emp_profile?.emp_id}
          </div>
          <div className="">
            <img src="/kazam-text-logo.png" alt="" className="w-24" />
          </div>
        </div>
        <div className="bg-white relative flex flex-col items-center px-5 py-2">
          <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 w-16 h-16">
            <img
              src={user?.profile_image}
              alt=""
              className="rounded-full w-full h-full"
            />
          </div>

          <div className="pt-12 text-red-500 font-bold">
            KAZAM EV TECH PVT. LTD
          </div>
          <div className="text-sm flex flex-col gap-2 mt-2">
            {fieldData.map((el, i) => {
              return (
                <div className="grid grid-cols-2 font-bold">
                  <div className="">{el.name}</div>
                  <div className="whitespace-normal text-right" key={i}>
                    {el.value}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="text-center mt-6 text-sm pb-4">
            If found please return to below address or Contact: +91 99589 43092
          </div>
        </div>
        <div className="bg-black text-white text-opacity-90 text-sm px-5 text-center py-6 rounded-b-3xl">
          1st floor, Mp Krishna Mansion, 1st Cross Rd, KHB Colony, 5th Block,
          Koramangala, Bengaluru, Karnataka 560095
        </div>
      </div>
      <div className="mt-12 lg:mt-0">
        {loading ? (
          <HrmLoader className="w-6 h-6" />
        ) : (
          <button
            className="py-3 px-12 rounded-md text-white bg-hrmBlue"
            onClick={async () => {
              setLoading(true);
              // await downloadId(".idcard1", "front.png");
              await downloadImage();
              // await downloadId("idcard2", "back.png");
            }}
          >
            Download
          </button>
        )}
      </div>
    </div>
  );
};

export default IdCard;
