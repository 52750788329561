import { FunctionComponent } from "react";
import { academyVideoCategory } from "../../../../../utils/enums";
import VideoLayout from "../components/VideosLayout";

interface EvReviewsProps {}

const EvReviews: FunctionComponent<EvReviewsProps> = () => {
  const segment = academyVideoCategory.Ev_reviews;

  return <VideoLayout segment={segment} />;
};

export default EvReviews;
