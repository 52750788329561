import notFound from "../../assets/not-found.png";

const NoRecordsFound = (props) => {
  const { displayText } = props;
  return (
    <div className="   my-5  flex flex-col justify-center items-center p-5">
      <img src={notFound} alt="not found" />
      <h3 className="text-2xl font-semibold text-gray-400 my-5">
        {displayText || "No records found."}
      </h3>
    </div>
  );
};

export default NoRecordsFound;
