import { BaseModel } from "../../../service/models/base";
import { ItemMovementModel } from "../../inventory/models/inventory_voucher";
import {
  LedgerEntryModel,
  OrderDetail,
  DispatchDetail,
  VoucherModel,
} from "./common_model";
import { PartyModel } from "./party_model";
import { ledgerEntryDefaultValue, PurchaseVoucherItem } from "./purchase_model";

export interface SalesVoucherModel extends VoucherModel {
  voucher_type: "Sales";
  entry_type?: "General" | "Invoice";
  date: Date;
  sales_ledger_entry: LedgerEntryModel;
  sales_ledger_entry_id?: string;
  party_ledger_entry: LedgerEntryModel;
  party_ledger_entry_id?: string;

  invoice_date?: Date;
  invoice_no?: string;
  items?: PurchaseVoucherItem[];
  taxes: LedgerEntryModel[];
  tax_ids?: string[];
  extras: LedgerEntryModel[];
  extra_ids?: string[];
  extra?: number;
  discounts: LedgerEntryModel[];
  discount_ids?: string[];
  due_date?: Date;
  sub_total: number;
  discount?: number;
  tax?: number;
  total: number;
  paid: number;
  credit_period_day?: number;

  party_detail?: PartyModel;
  party_id?: string;
  order_detail?: OrderDetail;
  dispatch_detail?: DispatchDetail;
}
export const defaultSalesValue: SalesVoucherModel = {
  voucher_type: "Sales",
  entry_type: "Invoice",
  date: new Date(),
  taxes: [],
  discounts: [],
  sub_total: 0,
  total: 0,
  paid: 0,
  credit_period_day: 0,
  sales_ledger_entry: ledgerEntryDefaultValue,
  party_ledger_entry: ledgerEntryDefaultValue,
  sales_ledger_entry_id: "",
  party_ledger_entry_id: "",

  discount_ids: [],
  attachments: [],
  extras: [],
};
