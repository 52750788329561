import { mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { useOrderCreateCtx } from "../../../../../context_providers/order_create_context";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import OrderItemModel from "../../../../../service/models/orders/order_item_model";
import ProductModel from "../../../../../service/models/product_model";
import { GetProductsApi } from "../../../../../service/repos/products_repo";
import { GetSubTotalAmt } from "../../../../../utils/order_util";
import ProductRowWidget from "./product_row";

interface ProductSectionProps {}
const defaultItem: OrderItemModel = {
  product_id: 0,
  name: "",
  price: 0,
  qty: 0,
  delivered_qty: 0,
  installed_qty: 0,
  returned_qty: 0,
};
const ProductSection: FunctionComponent<ProductSectionProps> = () => {
  const { order, setOrder, products } = useOrderCreateCtx();

  const [formId, setFormId] = useState(0);

  return (
    <>
      <h3 className="font-semibold mt-5">Products/Services</h3>
      <div className="bg-white rounded-md shadow p-3 my-2 text-sm">
        <div className="grid grid-cols-9 gap-3 ">
          <h4 className="font-semibold col-span-2 text-center">
            <span className="text-base text-red-500">*</span> Product/Service
          </h4>
          <h4 className="font-semibold text-center">
            {" "}
            <span className="text-base text-red-500">*</span>Quantity
          </h4>
          <h4 className="font-semibold text-center">Price per unit</h4>
          <h4 className="font-semibold text-center">Delivered</h4>
          <h4 className="font-semibold text-center">Installed</h4>
          <h4 className="font-semibold text-center">Returned</h4>
          <h4 className="font-semibold text-center">Amount</h4>
          <h4 className="font-semibold text-center">Action</h4>
        </div>
      </div>
      <div className="flex flex-col gap-2 text-sm">
        {order.items.map((item, i) => (
          <ProductRowWidget
            edit={false}
            products={products}
            key={item.id}
            item={item}
            onDelete={(it: OrderItemModel) => {
              const items = [...order.items].filter((v, j) => v.id !== it.id);
              const subtotal = GetSubTotalAmt(items);
              setOrder((old) => ({
                ...old,
                items,
                subtotal,
              }));
            }}
            onSubmit={(data) => {
              const items = order.items.map((it, j) => {
                if (it.id === item.id) {
                  return data;
                }
                return it;
              });
              const subtotal = GetSubTotalAmt(items);
              setOrder((old) => ({
                ...old,
                subtotal,
                items,
              }));
            }}
          />
        ))}
        <ProductRowWidget
          key={formId}
          edit={true}
          // form_id="item_form"
          onSubmit={(data) => {
            data.id = Date.now();
            const items = [...order.items, data];
            const subtotal = GetSubTotalAmt(items);

            setOrder((old) => ({ ...old, items, subtotal }));
            setFormId(Math.random());
          }}
          products={products}
        />
        <div className="flex justify-between">
          <p className="text-sm italic text-gray-400">
            Please select product and qty then hit + to add product.
          </p>
          <p className="text-sm italic text-gray-400">
            Note: Enter amount including GST.
          </p>
        </div>
        {/* <div className="flex justify-center my-3">
          <button
            form="item_form"
            className="bg-myPrimaryColor rounded-full p-2 hover:scale-110 transform duration-300"
          >
            <Icon path={mdiPlus} className="h-5 w-5 text-white"></Icon>
          </button>
        </div> */}
      </div>
    </>
  );
};

export default ProductSection;
