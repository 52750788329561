import { FunctionComponent, useEffect, useState } from "react";

import LoadingWidget from "../../../components/loading_spinner";
import {
  ToastType,
  useToast,
} from "../../../../context_providers/toast/toast_ctx";
import { getComplaints } from "../../../../service/repos/support/complaint_repo";
import { ComplaintFilterModle, defaultFilter } from "../listing";
import Icon from "@mdi/react";
import { mdiTextBoxMultiple } from "@mdi/js";
// import moment from "moment";

interface ComplaintInsightsProps {}

type OverdueCount = {
  department: string;
  totalComplaints: number;
  todayComplaints: number;
};

const ComplaintInsights: FunctionComponent<ComplaintInsightsProps> = () => {
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const tempFilter = localStorage.getItem("com_filter");
  const [filter, setFilter] = useState<ComplaintFilterModle>(
    tempFilter ? JSON.parse(tempFilter) : defaultFilter
  );
  const [averageTime, setAverageTime] = useState<{
    cur_month: {
      count: number;
      days: number;
    };
    last_month: {
      count: number;
      days: number;
    };
    all_days: {
      count: number;
      days: number;
    };
    dep_cur_month: {
      count: number;
      days: number;
    };
    dep_last_month: {
      count: number;
      days: number;
    };
    dep_all_days: {
      count: number;
      days: number;
    };
  }>();
  const [overdueCounts, setOverdueCounts] = useState<OverdueCount[]>([]);

  useEffect(() => {
    localStorage.setItem("com_filter", JSON.stringify(filter));

    // const cf = localStorage.getItem("cf");
    // const now = new Date();

    // if (
    //   !cf ||
    //   moment(parseInt(cf)).isBefore(
    //     new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1)
    //   )
    // )
    //   setShowPopUp(true);
    fetchComplaints();
  }, [filter]);

  const fetchComplaints = async () => {
    setLoading(true);

    const res = await getComplaints(filter);
    console.log(res);

    if (res.success) {
      //   setComplaints(res.data.complaints);
      if (res.departmentWiseComplaints) {
        setOverdueCounts(res.departmentWiseComplaints);
      }
      //   if (res.data.metadata[0]) setTotal(res.data.metadata[0].total);
      //   setAnalytic(res.analytic);
      if (res.average_time) setAverageTime(res.average_time);
      //   if (res.over_due) setOverDue(res.over_due);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };

  return (
    <div className="h-screen m-3">
      <h1 className="text-2xl font-semibold text-center my-5">
        Complaint Insights
      </h1>
      <div className="mb-3">
        <div>
          <h4 className="text-sm text-gray-500 border-gray-300 mt-2 pb-2">
            Department-wise OverDue Complaints
          </h4>

          {/* Cards on the right */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {overdueCounts.map(
              ({ department, todayComplaints, totalComplaints }) => (
                <div
                  key={department}
                  className="flex items-center gap-2 border p-4 rounded-md justify-between bg-white"
                >
                  <div>
                    <div className="text-sm text-gray-500">{department}</div>
                    <div className="font-semibold text-2xl">
                      {totalComplaints}
                    </div>
                    {todayComplaints > 0 && (
                      <div className="text-sm text-red-500">
                        {todayComplaints} due today
                      </div>
                    )}
                  </div>
                  <div className="h-8 w-8 rounded-full flex justify-center items-center bg-blue-200">
                    <Icon
                      path={mdiTextBoxMultiple}
                      className="h-4 w-4 text-white"
                    />
                  </div>
                </div>
              )
            )}
          </div>
          <h4 className="text-sm text-gray-500 border-b border-gray-300 mt-2 pb-2">
            Department-wise Average Turnaround Time
          </h4>
          {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 py-2 mt-2 border p-4 rounded-md bg-white">
            <>
              {averageTime?.dep_all_days && (
                <table className="min-w-full border border-gray-300 mt-2">
                  <thead>
                    <tr>
                      <th className="border border-gray-300 text-left p-2">
                        Department
                      </th>

                      <th className="border border-gray-300 text-left p-2">
                        All Time (days)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(averageTime.dep_all_days) &&
                      averageTime.dep_all_days.map((dept) => {
                        const allTimeDept = Array.isArray(
                          averageTime.dep_all_days
                        )
                          ? averageTime.dep_all_days.find(
                              (d) => d._id?.department === dept._id?.department
                            ) || { count: 0, days: 0 }
                          : { count: 0, days: 0 };

                        const allTimeDays =
                          allTimeDept.count > 0
                            ? Math.ceil(allTimeDept.days / allTimeDept.count)
                            : 0;

                        // Skip rendering if department is null
                        if (!allTimeDept._id?.department) {
                          return null;
                        }

                        return (
                          <tr
                            key={dept._id?.department}
                            className="border-b border-gray-200"
                          >
                            <td className="p-2 border">
                              {dept._id?.department}
                            </td>
                            <td className="p-2 border">{allTimeDays}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              )}
              {averageTime?.dep_last_month && (
                <table className="min-w-full border border-gray-300 mt-2">
                  <thead>
                    <tr>
                      <th className="border border-gray-300 text-left p-2">
                        Department
                      </th>

                      <th className="border border-gray-300 text-left p-2">
                        Last Month (days)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(averageTime.dep_last_month) &&
                      averageTime.dep_last_month.map((dept) => {
                        const lastTimeDept = Array.isArray(
                          averageTime.dep_last_month
                        )
                          ? averageTime.dep_last_month.find(
                              (d) => d._id?.department === dept._id?.department
                            ) || { count: 0, days: 0 }
                          : { count: 0, days: 0 };
                        const lastTimeDays =
                          lastTimeDept.count > 0
                            ? Math.ceil(lastTimeDept.days / lastTimeDept.count)
                            : 0;
                        if (!lastTimeDept._id?.department) {
                          return null;
                        }

                        return (
                          <tr
                            key={dept._id?.department}
                            className="border-b border-gray-200"
                          >
                            <td className=" border">{dept._id?.department}</td>
                            <td className="p-2 border">{lastTimeDays}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              )}
              {averageTime?.dep_cur_month && (
                <table className="min-w-full border border-gray-300 mt-2">
                  <thead>
                    <tr>
                      <th className="border border-gray-300 text-left p-2">
                        Department
                      </th>
                      <th className="border border-gray-300 text-left p-2">
                        Current Month (days)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(averageTime.dep_cur_month) &&
                      averageTime.dep_cur_month.map((dept) => {
                        const currTimeDept = Array.isArray(
                          averageTime.dep_cur_month
                        )
                          ? averageTime.dep_cur_month.find(
                              (d) => d._id?.department === dept._id?.department
                            ) || { count: 0, days: 0 }
                          : { count: 0, days: 0 };
                        const currTimeDays =
                          currTimeDept.count > 0
                            ? Math.ceil(currTimeDept.days / currTimeDept.count)
                            : 0;

                        if (!currTimeDept._id?.department) {
                          return null;
                        }

                        return (
                          <tr
                            key={dept._id?.department}
                            className="border-b border-gray-200"
                          >
                            <td className="p-2 border">
                              {dept._id?.department}
                            </td>
                            <td className="p-2 border">{currTimeDays}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              )}
            </>
          </div> */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 py-2 mt-2 border p-4 rounded-md bg-white">
            <>
              {averageTime?.dep_all_days && (
                <div className="flex flex-col">
                  <table className="min-w-full border border-gray-300 mt-2">
                    <thead>
                      <tr>
                        <th className="border border-gray-300 text-left p-2">
                          Department
                        </th>
                        <th className="border border-gray-300 text-left p-2">
                          All Time (days)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(averageTime.dep_all_days) &&
                        averageTime.dep_all_days.map((dept) => {
                          const allTimeDept = Array.isArray(
                            averageTime.dep_all_days
                          )
                            ? averageTime.dep_all_days.find(
                                (d) =>
                                  d._id?.department === dept._id?.department
                              ) || { count: 0, days: 0 }
                            : { count: 0, days: 0 };

                          const allTimeDays =
                            allTimeDept.count > 0
                              ? Math.ceil(allTimeDept.days / allTimeDept.count)
                              : 0;

                          if (!allTimeDept._id?.department) {
                            return null;
                          }

                          return (
                            <tr
                              key={dept._id?.department}
                              className="border-b border-gray-200 h-12"
                            >
                              <td className="p-2 border">
                                {dept._id?.department}
                              </td>
                              <td className="p-2 border">{allTimeDays}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              )}
              {averageTime?.dep_last_month && (
                <div className="flex flex-col">
                  <table className="min-w-full border border-gray-300 mt-2">
                    <thead>
                      <tr>
                        <th className="border border-gray-300 text-left p-2">
                          Department
                        </th>
                        <th className="border border-gray-300 text-left p-2">
                          Last Month (days)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(averageTime.dep_last_month) &&
                        averageTime.dep_last_month.map((dept) => {
                          const lastTimeDept = Array.isArray(
                            averageTime.dep_last_month
                          )
                            ? averageTime.dep_last_month.find(
                                (d) =>
                                  d._id?.department === dept._id?.department
                              ) || { count: 0, days: 0 }
                            : { count: 0, days: 0 };

                          const lastTimeDays =
                            lastTimeDept.count > 0
                              ? Math.ceil(
                                  lastTimeDept.days / lastTimeDept.count
                                )
                              : 0;

                          if (!lastTimeDept._id?.department) {
                            return null;
                          }

                          return (
                            <tr
                              key={dept._id?.department}
                              className="border-b border-gray-200 h-12"
                            >
                              <td className="p-2 border">
                                {dept._id?.department}
                              </td>
                              <td className="p-2 border">{lastTimeDays}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              )}
              {averageTime?.dep_cur_month && (
                <div className="flex flex-col">
                  <table className="min-w-full border border-gray-300 mt-2">
                    <thead>
                      <tr>
                        <th className="border border-gray-300 text-left p-2">
                          Department
                        </th>
                        <th className="border border-gray-300 text-left p-2">
                          Current Month (days)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(averageTime.dep_cur_month) &&
                        averageTime.dep_cur_month.map((dept) => {
                          const currTimeDept = Array.isArray(
                            averageTime.dep_cur_month
                          )
                            ? averageTime.dep_cur_month.find(
                                (d) =>
                                  d._id?.department === dept._id?.department
                              ) || { count: 0, days: 0 }
                            : { count: 0, days: 0 };

                          const currTimeDays =
                            currTimeDept.count > 0
                              ? Math.ceil(
                                  currTimeDept.days / currTimeDept.count
                                )
                              : 0;

                          if (!currTimeDept._id?.department) {
                            return null;
                          }

                          return (
                            <tr
                              key={dept._id?.department}
                              className="border-b border-gray-200 h-12"
                            >
                              <td className="p-2 border">
                                {dept._id?.department}
                              </td>
                              <td className="p-2 border">{currTimeDays}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          </div>
        </div>
      </div>

      <LoadingWidget loading={loading} />
    </div>
  );
};

export default ComplaintInsights;
