import { Tooltip } from "@material-ui/core";
import { mdiCheck } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent } from "react";
export interface Step {
  label: string;
  required: boolean;
  validated: boolean;
}
interface StepperWidgetProps {
  steps: Step[];
  activeStep: number;
  onChange: (step: number) => void;
}

const StepperWidget: FunctionComponent<StepperWidgetProps> = ({
  steps,
  activeStep,
  onChange,
}) => {
  return (
    <>
      <div className="flex w-full ">
        {steps.map((s, i) => (
          <div
            key={i}
            onClick={() => onChange(i)}
            className={`flex w-full items-center cursor-pointer`}
          >
            {
              <div
                style={{ height: "1px" }}
                className={` w-full duration-300 ${
                  i !== 0
                    ? i <= activeStep
                      ? "bg-myPrimaryColor"
                      : "bg-gray-300"
                    : ""
                } `}
              ></div>
            }
            <div className="flex flex-col items-center relative">
              <Tooltip title={s.label}>
                <div
                  className={`w-8 h-8 text-sm flex justify-center items-center duration-300  ${
                    i === activeStep
                      ? "bg-myPrimaryColor text-white"
                      : i < activeStep
                      ? "bg-myPrimaryColor text-white font-semibold"
                      : "bg-gray-200 text-gray-400"
                  } rounded-full`}
                >
                  {s.validated ? (
                    <Icon path={mdiCheck} className="h-5 w-5"></Icon>
                  ) : (
                    i + 1
                  )}
                </div>
              </Tooltip>
              <div
                className={`hidden md:block absolute -bottom-5 whitespace-nowrap text-xs font-semibold duration-300 ${
                  i == activeStep
                    ? "text-myPrimaryColor font-semibold"
                    : " text-gray-400"
                }`}
              >
                {s.label}
                {s.required && (
                  <span className="text-base text-red-500">*</span>
                )}
              </div>
            </div>

            {
              <div
                style={{ height: "1px" }}
                className={`  w-full duration-300 ${
                  i != steps.length - 1
                    ? i < activeStep
                      ? "bg-myPrimaryColor"
                      : "bg-gray-300"
                    : ""
                }`}
              ></div>
            }
          </div>
        ))}
      </div>
    </>
  );
};

export default StepperWidget;
