import { object, string } from "yup";
import { useHistory, useParams } from "react-router-dom";
import { useRef, useState, useEffect } from "react";

// import Step from "@material-ui/core/Step";
import Button from "@material-ui/core/Button";
// import Stepper from "@material-ui/core/Stepper";
// import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";

import Tags from "../../components/blogEditor/tags";
import Publish from "../../components/blogEditor/publish";
import Content from "../../components/blogEditor/content";
import Keywords from "../../components/blogEditor/keywords";
import Category from "../../components/blogEditor/category";
import BlogDetails, {
  blogCountry,
} from "../../components/blogEditor/blogDetails";
import Preview from "../../components/blogEditor/preview";
import {
  getAllTags,
  GetAuthorsList,
  GetBlogPost,
} from "../../services/blogPostService";
import {
  getAllCategories,
  createBlogPost,
  updateBlogPost,
} from "../../services/blogPostService";
import { AuthorModel } from "../../ui/pages/authors/create";
import StepperWidget from "../../../../new_components/common/stepper_widget";
import { mdiArrowLeft, mdiArrowRight } from "@mdi/js";
import Icon from "@mdi/react";
import { updateObject } from "../../../../../utils/formDataToJson";
import LoadingWidget from "../../../../../context_providers/modal/loader";
export interface BlogModel {
  id: any;
  slug: string;
  title: string;
  description: string;
  content?: string;
  author: string;
  feature_image: string;
  keywords: string;
  created_at: number;
  updated_at: number;
  views: number;
  likes: number;
  created_by: any;
  updated_by: any;
  dislikes: number;
  read_time: number;
  language: string;
  country: string;
  categories: {
    id: any;
    category_slug: string;
    category_display: string;
  }[];
  tags: {
    id: any;
    tag_slug: string;
    tag_display: string;
  }[];
  author_id?: any;
  authorProfile?: {
    id: any;
    name: string;
    img: string;
    about: string;
    email: string;
    linked_in: string;
    twitter: string;
  };
}

const blogDetails = object({
  title: string().required("Title is Required"),
  description: string().required("Description is Required"),
  author_id: string().required("Author is Required"),
  feature_image: string().required("Feature image url is Required"),
  language: string().required("Language is Required"),
  country: string().required("Country is Required"),
});

const validateBlogDetails = (values: any) =>
  blogDetails.validate(values, {
    abortEarly: false,
  });

const defaultVal: BlogModel = {
  id: undefined,
  slug: "",
  title: "",
  description: "",
  author: "",
  feature_image: "",
  keywords: "",
  created_at: 0,
  updated_at: 0,
  views: 0,
  likes: 0,
  created_by: undefined,
  updated_by: undefined,
  dislikes: 0,
  read_time: 0,
  language: "",
  categories: [],
  tags: [],
  country: blogCountry.india,
};
export default function HorizontalLabelPositionBelowStepper() {
  // const history: any = useHistory();
  // const { state } = history.location;
  const { slug } = useParams<{ slug: string }>();
  // const defaultValues = {
  //   blogId: state ? state.id : null,
  //   blogDetails: {
  //     title: state ? state.title : "",
  //     description: state ? state.description : "",
  //     author_id: state ? state.authorProfile.id : "",
  //     feature: state ? state.feature_image : "",
  //     language: state ? state.language : "English",
  //   },
  //   keywords: state ? state.keywords : null,
  //   tags: state
  //     ? state.tags
  //       ? state.tags.map((cat: any) => cat.id.toString())
  //       : []
  //     : [],
  //   categories: state
  //     ? state.categories
  //       ? state.categories.map((cat: any) => cat.id.toString())
  //       : []
  //     : [],
  // };
  const [blog, setBlog] = useState<BlogModel>(defaultVal);
  const [errors, setErrors] = useState<any>([]);
  const [tags, setTags] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [categories, setCategories] = useState([]);
  const [content, setContent] = useState<any>();
  // const [keywords, setKeywords] = useState(defaultValues.keywords);
  // const [selectedTags, setSelectedTags] = useState([...defaultValues.tags]);
  // const [blogDetails, setBlogDetails] = useState<{ [key: string]: string }>(
  //   defaultValues.blogDetails
  // );
  // const [selectedCategories, setSelectedCategories] = useState([
  //   ...defaultValues.categories,
  // ]);

  const childRef = useRef<any>(null);
  const [steps, setSteps] = useState([
    {
      label: "Blog Details",
      required: false,
      validated: false,
    },
    {
      label: "Content",
      required: false,
      validated: false,
    },
    {
      label: "Categories",
      required: false,
      validated: false,
    },
    {
      label: "Tags",
      required: false,
      validated: false,
    },
    {
      label: "Keywords",
      required: false,
      validated: false,
    },
    {
      label: "Preview",
      required: false,
      validated: false,
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [authors, setAuthors] = useState<AuthorModel[]>([]);
  const getAuthors = async () => {
    setLoading(true);
    const res = await GetAuthorsList({ page: 1, count: 1, all: true });
    if (res.success) {
      setAuthors(res.data.authors);
    } else {
      alert(res.error);
    }
    setLoading(false);
  };

  const getBlogPost = async (slug: any) => {
    try {
      setLoading(true);
      const res = await GetBlogPost(slug);

      if (res.success) {
        setBlog(res.data);
        const tcontent = JSON.parse(res.data.content);
        setContent(tcontent);
        // setSelectedCategories([...res.data.categories]);
        // setSelectedTags([...res.data.tags]);
        // setKeywords(res.data.keywords);
      } else {
        console.log(res.error);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const handleNext = async (step: number) => {
    console.log(step);

    if (activeStep === 0) {
      try {
        await validateBlogDetails(blog);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setErrors([]);
      } catch (error: any) {
        // console.log(error)
        setErrors(
          error.inner.reduce((acc: any, currentValue: any) => {
            acc[currentValue.path] = currentValue.message;
            return acc;
          }, {})
        );
      }
    }
    if (activeStep === 1) {
      childRef!.current!.handleSave();
      // setActiveStep((prevActiveStep) => 5)
    }
    if (activeStep === 2) {
      if (blog.categories.length > 0) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setErrors([]);
      } else {
        setErrors({ categories: "Please select a category." });
      }
    }
    if (activeStep === 3) {
      if (blog.tags.length > 0) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setErrors([]);
      } else {
        setErrors({ tags: "Please select a tag." });
      }
    }
    if (activeStep === 4) {
      if (blog.keywords) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        setErrors({ keywords: "Please enter comma separated keywords." });
      }
    }

    // setActiveStep(step);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const [showSuccess, setShowSuccess] = useState(false);
  const handleFinish = async () => {
    setErrors([]);
    setLoading(true);
    try {
      // setActiveStep((prevActiveStep) => prevActiveStep + 1);
      // const body = {
      //   blogId: defaultValues.blogId,
      //   blogDetails,
      //   content: JSON.stringify(content),
      //   tags: selectedTags,
      //   categories: selectedCategories,
      //   keywords: keywords,
      // };

      const formData = new FormData();
      formData.append(
        "data",
        JSON.stringify({
          ...blog,
          content: JSON.stringify(content),
        })
      );
      const response = blog.id
        ? await updateBlogPost(formData)
        : await createBlogPost(formData);
      if (response && response.success) {
        setShowSuccess(true);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleInputChange = (e: any) => {
    // e.preventDefault();
    const { value, name } = e.target;
    const keys = name.split(".");
    // setBlogDetails((old) => {
    const _blogDetails = updateObject(blog, keys, value);
    setBlog((old) => ({ ...old, ..._blogDetails }));
    // const _blogDetails: { [key: string]: string } = {};
    // _blogDetails[name] = isNaN(value) ? value : parseInt(value);
    // setBlog((old) => ({ ...old, ..._blogDetails }));
    // setBlogDetails(_blogDetails);
  };

  const handleContentSave = (content: any) => {
    setContent(content);

    // console.log(!content.time, content.blocks, !content.blocks[0].text.trim());

    if (
      !content ||
      (!content.time && content.blocks && !content.blocks[0].text.trim())
    ) {
      alert("blog must not be empty");
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleCategorySelection = (category: any) => {
    let _selectedCategories = [...blog.categories];
    if (_selectedCategories.find((v) => v.id === category.id) !== undefined) {
      _selectedCategories = _selectedCategories.filter(
        (v) => v.id !== category.id
      );
      // const index = _selectedCategories.indexOf(categoryId);
      // _selectedCategories.splice(index, 1);
    } else {
      _selectedCategories.push(category);
    }
    setBlog((old) => ({ ...old, categories: _selectedCategories }));
  };

  const handleTagSelection = (tag: any) => {
    let _selectedTags = [...blog.tags];
    if (_selectedTags.find((v) => v.id === tag.id) !== undefined) {
      // const index = _selectedTags.indexOf(tagId);
      // _selectedTags.splice(index, 1);
      _selectedTags = _selectedTags.filter((v) => v.id !== tag.id);
    } else {
      _selectedTags.push(tag);
    }
    setBlog((old) => ({ ...old, tags: _selectedTags }));
  };

  const handleKeywordChange = (keywords: any) => {
    setBlog((old) => ({ ...old, keywords }));
  };

  const getStepContent = (stepIndex: any) => {
    switch (stepIndex) {
      case 0:
        return (
          <BlogDetails
            errors={errors}
            blog={blog}
            inputChange={handleInputChange}
            authors={authors}
          />
        );
      case 1:
        return (
          <Content
            ref={childRef}
            errors={errors}
            content={content}
            contentSave={handleContentSave}
          />
        );
      case 2:
        return (
          <Category
            errors={errors}
            categories={categories}
            selectedCategories={blog.categories}
            categorySelection={handleCategorySelection}
          />
        );
      case 3:
        return (
          <Tags
            tags={tags}
            errors={errors}
            selectedTags={blog.tags}
            tagSelection={handleTagSelection}
          />
        );
      case 4:
        return (
          <Keywords
            errors={errors}
            keywords={blog.keywords}
            keywordChange={handleKeywordChange}
          />
        );
      case 5:
        return <Preview content={content} />;
      default:
        return "Unknown stepIndex";
    }
  };

  const fetchAllCategories = async () => {
    try {
      const response = await getAllCategories();

      if (response && response.success) {
        const categories = response.data;
        setCategories(categories);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAllTags = async () => {
    try {
      const response = await getAllTags();
      if (response && response.success) {
        const tags = response.data;
        setTags(tags);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    Promise.all([
      fetchAllTags(),
      fetchAllCategories(),
      getAuthors(),
      ...(slug ? [getBlogPost(slug)] : []),
    ])
      .then((d) => {
        console.log("Fetch done !");
      })
      .catch((e) => console.log("Error in fetch ", e));
  }, []);
  if (showSuccess)
    return (
      <div className="grid grid-cols-12">
        <div className="col-span-6 col-start-4 mt-10">
          <Publish />
        </div>
      </div>
    );
  return (
    <>
      {" "}
      {loading && <LoadingWidget />}
      <div className="relative bg-myBgcolor h-screen px-2 md:px-5  overflow-auto">
        <div className="sticky z-10 top-0 p-2  md:px-5 md:pt-3 md:pb-6 rounded-md shadow bg-white mb-3">
          <StepperWidget
            activeStep={activeStep}
            onChange={(s) => {
              // handleNext(s);
            }}
            steps={steps}
          />
        </div>
        <div className="relative" style={{ minHeight: "78vh" }}>
          <div className="grid grid-cols-12">
            {getStepContent(activeStep)}

            {/* <div className="col-span-6 col-start-3 "> */}

            {/*           
          {activeStep === steps.length ? (
            <div className="col-9">
              <Publish publishing={publishing} />
            </div>
          ) : (
            <>
              <div className="  "> {getStepContent(activeStep)}</div>
              <div className=" ">
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className="bg-secondary text-white px-4 py-2"
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  className="bg-primary text-white px-4 py-2"
                  onClick={
                    activeStep === steps.length - 1 ? handleFinish : handleNext
                  }
                >
                  {activeStep === steps.length - 1 ? "Publish" : "Next"}
                </Button>
              </div>
            </>
          )} */}
            {/* </div> */}
          </div>
          {activeStep !== steps.length && (
            <>
              {/* <div className="sticky  bottom-16 right-0 left-0 flex items-center justify-between p-5 "> */}
              <div
                onClick={handleBack}
                className={`${
                  activeStep === 0 && "invisible"
                } absolute bottom-52  p-2   left-0 bg-myPrimaryColor text-white rounded-full cursor-pointer hover:scale-110 transform duration-100 `}
              >
                <Icon path={mdiArrowLeft} className="h-6 w-6"></Icon>
              </div>
              <div
                onClick={() => handleNext(activeStep + 1)}
                className={`${
                  activeStep === steps.length - 1 && "invisible"
                } absolute bottom-52 right-0 p-2 bg-myPrimaryColor text-white rounded-full cursor-pointer hover:scale-110 transform duration-100`}
              >
                <Icon path={mdiArrowRight} className="h-6 w-6"></Icon>
              </div>

              {/* </div> */}
            </>
          )}
          {activeStep < steps.length && (
            <div className="flex justify-center my-10 gap-5">
              <div className=" ">
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className="bg-secondary text-white px-4 py-2"
                >
                  Back
                </Button>

                <Button
                  variant="contained"
                  className="bg-primary text-white px-4 py-2"
                  onClick={
                    activeStep === steps.length - 1
                      ? () => {
                          console.log("handle finsh");
                          handleFinish();
                        }
                      : () => {
                          console.log("handle next");
                          handleNext(activeStep + 1);
                        }
                  }
                >
                  {activeStep === steps.length - 1 ? "Publish" : "Next"}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );

  // return (
  //   <div className="">
  //     <Stepper
  //       onSelect={(e) => {
  //         console.log(e);
  //       }}
  //       className=""
  //       activeStep={activeStep}
  //       alternativeLabel
  //     >
  //       {steps.map((label) => (
  //         <Step key={label}>
  //           <StepLabel>{label}</StepLabel>
  //         </Step>
  //       ))}
  //     </Stepper>

  //   </div>
  // );
}
