import { mdiClose, mdiCheck } from "@mdi/js";
import Icon from "@mdi/react";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { useModal } from "../../../../../context_providers/modal/modal_context";
import { useOrderCreateCtx } from "../../../../../context_providers/order_create_context";
import { useOrderDetailCtx } from "../../../../../context_providers/order_detail_context";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import {
  DurationType,
  subscriptionPlanDefault,
  SubscriptionPlanModel,
} from "../../../../../service/models/orders/subscription_plan_model";
import {
  getActiveOrgPlanApi,
  getOrgNamespaceApi,
} from "../../../../../service/repos/cms/org_repo";
import { UTCToLocalDate } from "../../../../../utils/date_util";
import RequiredMark from "../../../../new_components/common/required_mark";
import OrgSearchField, { OrgModel } from "./org/org_search";

interface SubscriptionConfigWidgetProps {
  onCancel: () => void;
}

const SubscriptionConfigWidget: FunctionComponent<
  SubscriptionConfigWidgetProps
> = (props) => {
  const { setLoading } = useModal();
  const { showToast } = useToast();
  const ctx = useOrderDetailCtx();
  const [subscription, setSubscription] = useState<SubscriptionPlanModel>({
    ...(ctx.subscription ?? subscriptionPlanDefault),
  });
  // const [hasActivePlan, setHasActivePlan] = useState(false);
  // const [activePlan, setActivePlan] = useState<SubscriptionPlanModel>();
  // useEffect(() => {
  //   if (namespaces.length === 0)
  //     getOrgNamespaceApi()
  //       .then((val) => {
  //         if (val.success) {
  //           setNamespaces(val.data);
  //         } else alert(val.error);
  //       })
  //       .catch((e) => alert(e.message));
  // }, []);
  const getActivePlan = async ({
    org_name,
    org_namespace,
  }: {
    org_name: string;
    org_namespace: string;
  }) => {
    setLoading(true);
    const res = await getActiveOrgPlanApi(org_namespace);
    if (res.success) {
      if (res.data) {
        const f = window.confirm(
          `${org_namespace} has an existing active plan would you like to override ? \n since org can have only one sctive plan.`
        );
        console.log({ f, data: res.data });

        if (f) {
          setSubscription({ ...res.data });
        }
      } else {
        setSubscription((o) => ({ ...o!!, org_name, org_namespace }));
      }
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  const onSubmit = async () => {
    if (!subscription?.org_namespace) {
      showToast({
        type: ToastType.warning,
        text: "Select Organizaion !",
      });
      return;
    }
    if (!subscription!.start_date) {
      showToast({
        type: ToastType.warning,
        text: "Start date required !",
      });
      return;
    }
    if (!subscription!.end_date) {
      showToast({
        type: ToastType.warning,
        text: "end date required !",
      });
      return;
    }
    // if (!subscription!.duration_type) {
    //   showToast({
    //     type: ToastType.warning,
    //     text: "Select Duration type !",
    //   });
    //   return;
    // }
    // if (
    //   subscription!.duration_type != DurationType.lifetime &&
    //   !subscription!.duration_value
    // ) {
    //   showToast({
    //     type: ToastType.warning,
    //     text: "Duration required !",
    //   });
    //   return;
    // }
    if (
      subscription!.renewal_fee > 0 &&
      !subscription!.renewal_fee_collection_type
    ) {
      showToast({
        type: ToastType.warning,
        text: " Renewal Collection Type required!",
      });
      return;
    }
    if (
      subscription!.renewal_fee_collection_type === "Monthly" &&
      !subscription!.renewal_fee_emi_months
    ) {
      showToast({
        type: ToastType.warning,
        text: "Renewal collection EMI duartion required !",
      });
      return;
    }

    ctx.setSubscription(subscription);
    await ctx.onSubmitSubscription(subscription);
    props.onCancel();
  };

  useEffect(() => {
    if (subscription!.start_date) {
      subscription!.start_date = new Date(subscription!.start_date);
      subscription!.start_date.setHours(0, 0, 0, 0);
    }
    if (subscription!.end_date) {
      subscription!.end_date = new Date(subscription!.end_date);
      subscription!.end_date.setHours(23, 59, 59, 999);
    }
    setSubscription(subscription);
  }, []);
  console.log({ subscription });
  return (
    <>
      <div className="bg-white shadow rounded-md p-5">
        <div className="grid grid-cols-1 sm:grid-cols-4 gap-5 ">
          <div className="my-5">
            <h5 className="font-semibold ">Organization</h5>
            <div className="grid grid-cols-1  gap-5">
              {" "}
              <div className="grid grid-cols-1 items-center">
                <label htmlFor="org" className="text-gray-600">
                  Org/namespace
                  <RequiredMark />
                </label>
                <OrgSearchField
                  key={Math.random()}
                  placeholder="Select Organization | Create"
                  value={
                    subscription!.org_namespace
                      ? {
                          id: "",
                          name: subscription!.org_name!,
                          namespace: subscription!.org_namespace!,
                        }
                      : undefined
                  }
                  onSelect={function (data: OrgModel): void {
                    if (data.namespace !== subscription!.org_namespace) {
                      getActivePlan({
                        org_name: data.name,
                        org_namespace: data.namespace,
                      });
                    }
                  }}
                />
              </div>
            </div>
          </div>

          <div className="my-5 col-span-3">
            <h5 className="font-semibold ">Validity</h5>
            <div className="grid grid-cols-1 sm:grid-cols-3  gap-5">
              {" "}
              <div className="grid grid-cols-1 items-center">
                <label htmlFor="start_date" className="text-gray-600">
                  Start Date <RequiredMark />
                </label>
                <input
                  value={moment(subscription?.start_date).format("YYYY-MM-DD")}
                  onChange={(e) => {
                    let date = new Date(e.target.value);
                    date = UTCToLocalDate(date)!;
                    date.setHours(0, 0, 0, 0);
                    setSubscription((o) => ({
                      ...o!!,
                      start_date: date,
                    }));
                  }}
                  type="date"
                  name=""
                  id="start_date"
                  className="col-span-2 px-2 py-1 bg-gray-100 rounded focus:outline-none  "
                />
              </div>
              {/* <div className="grid grid-cols-1 items-center">
                <label htmlFor="duration_type" className="text-gray-600">
                  Duration Type <RequiredMark />
                </label>
                <select
                  value={subscription?.duration_type}
                  onChange={(e) => {
                    setSubscription((o) => ({
                      ...o!,
                      duration_type: e.target.value as any,
                    }));
                  }}
                  name=""
                  id="duration_type"
                  className="col-span-2 px-2 py-1 bg-gray-100 rounded focus:outline-none "
                >
                  <option value="" hidden>
                    Select
                  </option>
                  {Object.values(DurationType).map((type, i) => (
                    <option key={i}>{type}</option>
                  ))}
                </select>
              </div>
              <div className="grid grid-cols-1 items-center">
                <label htmlFor="duration" className="text-gray-600">
                  Duration <RequiredMark />
                </label>
                <input
                  value={subscription?.duration_value}
                  onChange={(e) => {
                    let v = 0;
                    if (e.target.value) {
                      v = parseFloat(e.target.value);
                    }
                    setSubscription((o) => ({ ...o!, duration_value: v }));
                  }}
                  placeholder="0"
                  type="number"
                  name=""
                  id="duration"
                  className="col-span-2 px-2 py-1 bg-gray-100 rounded focus:outline-none  text-right "
                />
              </div> */}
              <div className="grid grid-cols-1 items-center">
                <label htmlFor="end_date" className="text-gray-600">
                  End Date <RequiredMark />
                </label>
                <input
                  value={moment(subscription?.end_date).format("YYYY-MM-DD")}
                  onChange={(e) => {
                    let date = new Date(e.target.value);
                    console.log({ date });

                    date = UTCToLocalDate(date)!;
                    console.log({ date });
                    date.setHours(23, 59, 59, 999);
                    console.log({ date });
                    setSubscription((o) => ({
                      ...o!,
                      end_date: date,
                    }));
                  }}
                  type="date"
                  name=""
                  id="end_date"
                  className="col-span-2 px-2 py-1 bg-gray-100 rounded focus:outline-none  "
                />
              </div>
            </div>
          </div>
        </div>

        <hr />
        <div className="my-5">
          <h5 className="font-semibold ">Renewal</h5>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-5 ">
            <div className="grid grid-cols-1 items-center">
              <label htmlFor="renew_fee" className="text-gray-600">
                Renewal Fee({"\u20b9"}) <RequiredMark />
              </label>
              <input
                value={subscription?.renewal_fee}
                onChange={(e) => {
                  let v = 0;
                  if (e.target.value) {
                    v = parseFloat(e.target.value);
                  }
                  setSubscription((o) => ({ ...o!, renewal_fee: v }));
                }}
                placeholder="0"
                type="number"
                name=""
                id="renew_fee"
                className="col-span-2 px-2 py-1 bg-gray-100 rounded focus:outline-none  text-right"
              />
            </div>
            <div className="grid grid-cols-1 items-center">
              <label htmlFor="renew_col_type" className="text-gray-600">
                Renewal Collection Type <RequiredMark />
              </label>
              <select
                value={subscription?.renewal_fee_collection_type}
                onChange={(e) => {
                  setSubscription((o) => ({
                    ...o!,
                    renewal_fee_collection_type: e.target.value as any,
                  }));
                }}
                name=""
                id="renew_col_type"
                className="col-span-2 px-2 py-1 bg-gray-100 rounded focus:outline-none "
              >
                <option value="" hidden>
                  Select
                </option>
                {["Full Amount", "Monthly"].map((type, i) => (
                  <option key={i}>{type}</option>
                ))}
              </select>
            </div>
            {subscription!.renewal_fee_collection_type === "Monthly" && (
              <div className="grid grid-cols-1 items-center">
                <label htmlFor="renew_fee" className="text-gray-600">
                  Renewal Fee Emi Duration(Months) <RequiredMark />
                </label>
                <input
                  value={subscription?.renewal_fee_emi_months}
                  onChange={(e) => {
                    let v = 0;
                    if (e.target.value) {
                      v = parseFloat(e.target.value);
                    }
                    setSubscription((o) => ({
                      ...o!,
                      renewal_fee_emi_months: v,
                    }));
                  }}
                  placeholder="0"
                  type="number"
                  name=""
                  id="renew_fee"
                  className="col-span-2 px-2 py-1 bg-gray-100 rounded focus:outline-none text-right"
                />
              </div>
            )}
            {subscription!.renewal_fee_collection_type === "Monthly" && (
              <div className="grid grid-cols-1 items-center">
                <label htmlFor="renew_fee" className="text-gray-600">
                  Renewal EMI({"\u20b9"})
                </label>
                <input
                  disabled
                  value={
                    subscription!.renewal_fee /
                    (subscription?.renewal_fee_emi_months || 1)
                  }
                  placeholder="0"
                  type="number"
                  name=""
                  id="renew_fee"
                  className="col-span-2 px-2 py-1 bg-gray-100 rounded focus:outline-none text-right"
                />
              </div>
            )}
          </div>
        </div>
        <hr />
        <div className="my-5">
          <h5 className="font-semibold ">Annual Maintenance Charges</h5>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-5 ">
            <div className="grid grid-cols-1 items-center">
              <label htmlFor="amc" className="text-gray-600">
                AMC({"\u20b9"}) <RequiredMark />
              </label>
              <input
                value={subscription?.amc}
                onChange={(e) => {
                  let v = 0;
                  if (e.target.value) {
                    v = parseFloat(e.target.value);
                  }
                  setSubscription((o) => ({ ...o!, amc: v }));
                }}
                placeholder="0"
                type="number"
                name=""
                id="amc"
                className="col-span-2 px-2 py-1 bg-gray-100 rounded focus:outline-none  text-right"
              />
            </div>
            <div className="grid grid-cols-1 items-center">
              <label htmlFor="amc_col_type" className="text-gray-600">
                AMC Collection Type <RequiredMark />
              </label>
              <select
                value={subscription?.amc_collection_type}
                onChange={(e) => {
                  setSubscription((o) => ({
                    ...o!,
                    amc_collection_type: e.target.value as any,
                  }));
                }}
                name=""
                id="amc_col_type"
                className="col-span-2 px-2 py-1 bg-gray-100 rounded focus:outline-none "
              >
                <option value="" hidden>
                  Select
                </option>
                {["Full Amount", "Monthly"].map((type, i) => (
                  <option key={i}>{type}</option>
                ))}
              </select>
            </div>
            {subscription!.amc_collection_type === "Monthly" && (
              <div className="grid grid-cols-1 items-center">
                <label htmlFor="amc_emi_month" className="text-gray-600">
                  AMC Emi Duration(Months) <RequiredMark />
                </label>
                <input
                  value={subscription?.amc_emi_months}
                  onChange={(e) => {
                    let v = 0;
                    if (e.target.value) {
                      v = parseFloat(e.target.value);
                    }
                    setSubscription((o) => ({
                      ...o!,
                      amc_emi_months: v,
                    }));
                  }}
                  placeholder="0"
                  type="number"
                  name=""
                  id="amc_emi_month"
                  className="col-span-2 px-2 py-1 bg-gray-100 rounded focus:outline-none text-right"
                />
              </div>
            )}
            {subscription!.amc_collection_type === "Monthly" && (
              <div className="grid grid-cols-1 items-center">
                <label htmlFor="amc_emi" className="text-gray-600">
                  AMC EMI({"\u20b9"})
                </label>
                <input
                  disabled
                  value={
                    subscription!.amc / (subscription?.amc_emi_months || 1)
                  }
                  placeholder="0"
                  type="number"
                  name=""
                  id="amc_emi"
                  className="col-span-2 px-2 py-1 bg-gray-100 rounded focus:outline-none text-right"
                />
              </div>
            )}
          </div>
        </div>
        <hr />
        <div className="my-5">
          <h5 className="font-semibold ">Sim Charges</h5>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-5 ">
            <div className="grid grid-cols-1 items-center">
              <label htmlFor="sim_charges" className="text-gray-600">
                Sim charges({"\u20b9"}) <RequiredMark />
              </label>
              <input
                value={subscription?.sim_charges}
                onChange={(e) => {
                  let v = 0;
                  if (e.target.value) {
                    v = parseFloat(e.target.value);
                  }
                  setSubscription((o) => ({ ...o!, sim_charges: v }));
                }}
                placeholder="0"
                type="number"
                name=""
                id="sim_charges"
                className="col-span-2 px-2 py-1 bg-gray-100 rounded focus:outline-none  text-right"
              />
            </div>
            <div className="grid grid-cols-1 items-center">
              <label htmlFor="sim_col_type" className="text-gray-600">
                Sim Charges Collection Type <RequiredMark />
              </label>
              <select
                value={subscription?.sim_charges_collection_type}
                onChange={(e) => {
                  setSubscription((o) => ({
                    ...o!,
                    sim_charges_collection_type: e.target.value as any,
                  }));
                }}
                name=""
                id="sim_col_type"
                className="col-span-2 px-2 py-1 bg-gray-100 rounded focus:outline-none "
              >
                <option value="" hidden>
                  Select
                </option>
                {["Full Amount", "Monthly"].map((type, i) => (
                  <option key={i}>{type}</option>
                ))}
              </select>
            </div>
            {subscription!.sim_charges_collection_type === "Monthly" && (
              <div className="grid grid-cols-1 items-center">
                <label htmlFor="amc_emi_month" className="text-gray-600">
                  Sim Charges Emi Duration(Months) <RequiredMark />
                </label>
                <input
                  value={subscription?.sim_charges_emi_months}
                  onChange={(e) => {
                    let v = 0;
                    if (e.target.value) {
                      v = parseFloat(e.target.value);
                    }
                    setSubscription((o) => ({
                      ...o!,
                      sim_charges_emi_months: v,
                    }));
                  }}
                  placeholder="0"
                  type="number"
                  name=""
                  id="amc_emi_month"
                  className="col-span-2 px-2 py-1 bg-gray-100 rounded focus:outline-none text-right"
                />
              </div>
            )}
            {subscription!.sim_charges_collection_type === "Monthly" && (
              <div className="grid grid-cols-1 items-center">
                <label htmlFor="amc_emi" className="text-gray-600">
                  Sim Charges EMI({"\u20b9"})
                </label>
                <input
                  disabled
                  value={
                    subscription!.sim_charges /
                    (subscription?.sim_charges_emi_months || 1)
                  }
                  placeholder="0"
                  type="number"
                  name=""
                  id="amc_emi"
                  className="col-span-2 px-2 py-1 bg-gray-100 rounded focus:outline-none text-right"
                />
              </div>
            )}
          </div>
        </div>
        <hr />
        <div className="my-5">
          <h5 className="font-semibold ">Connectors</h5>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-5">
            <div className="grid grid-cols-1 items-center">
              <label htmlFor="ac_connector" className="text-gray-600">
                AC connector/ per connector / month({"\u20b9"})
              </label>
              <input
                value={subscription?.per_connector_ac}
                onChange={(e) => {
                  let v = 0;
                  if (e.target.value) {
                    v = parseFloat(e.target.value);
                  }
                  setSubscription((o) => ({ ...o!, per_connector_ac: v }));
                }}
                placeholder="0"
                type="number"
                name=""
                id="ac_connector"
                className="col-span-2 px-2 py-1 bg-gray-100 rounded focus:outline-none text-right"
              />
            </div>{" "}
            <div className="grid grid-cols-1 items-center">
              <label htmlFor="free_connector_ac" className="text-gray-600">
                Waive off connector AC
              </label>
              <input
                value={subscription?.free_connector_ac}
                onChange={(e) => {
                  let v = 0;
                  if (e.target.value) {
                    v = parseFloat(e.target.value);
                  }
                  setSubscription((o) => ({ ...o!, free_connector_ac: v }));
                }}
                placeholder="0"
                type="number"
                name=""
                id="free_connector_ac"
                className="col-span-2 px-2 py-1 bg-gray-100 rounded focus:outline-none text-right"
              />
            </div>
            <div className="grid grid-cols-1 items-center">
              <label htmlFor="dc_connector" className="text-gray-600">
                DC connector / per connector / month({"\u20b9"})
              </label>
              <input
                value={subscription?.per_connector_dc}
                onChange={(e) => {
                  let v = 0;
                  if (e.target.value) {
                    v = parseFloat(e.target.value);
                  }
                  setSubscription((o) => ({ ...o!, per_connector_dc: v }));
                }}
                placeholder="0"
                type="number"
                name=""
                id="dc_connector"
                className="col-span-2 px-2 py-1 bg-gray-100 rounded focus:outline-none text-right"
              />
            </div>
            <div className="grid grid-cols-1 items-center">
              <label htmlFor="free_connector_dc" className="text-gray-600">
                Waive off connector DC
              </label>
              <input
                value={subscription?.free_connector_dc}
                onChange={(e) => {
                  let v = 0;
                  if (e.target.value) {
                    v = parseFloat(e.target.value);
                  }
                  setSubscription((o) => ({ ...o!, free_connector_dc: v }));
                }}
                placeholder="0"
                type="number"
                name=""
                id="free_connector_dc"
                className="col-span-2 px-2 py-1 bg-gray-100 rounded focus:outline-none text-right"
              />
            </div>
          </div>
        </div>
        <hr />
        <div className="my-5">
          <h5 className="font-semibold ">Transaction</h5>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-5">
            <div className="grid grid-cols-1 items-center">
              <label htmlFor="per_txn_type" className="text-gray-600">
                Transaction value Type <RequiredMark />
              </label>
              <select
                value={subscription?.per_txn_val_type}
                onChange={(e) => {
                  setSubscription((o) => ({
                    ...o!,
                    per_txn_val_type: e.target.value as any,
                  }));
                }}
                name=""
                id="per_txn_type"
                className="col-span-2 px-2 py-1 bg-gray-100 rounded focus:outline-none "
              >
                <option value="" hidden>
                  Select
                </option>
                {["Amount", "Percent"].map((type, i) => (
                  <option key={i}>{type}</option>
                ))}
              </select>
            </div>
            <div className="grid grid-cols-1 items-center">
              <label htmlFor="per_txn" className="text-gray-600">
                Per Trasaction (
                {subscription!.per_txn_val_type == "Amount"
                  ? "\u20b9"
                  : subscription!.per_txn_val_type == "Percent"
                  ? "%"
                  : "--"}
                )
              </label>
              <input
                value={subscription?.per_txn_value}
                onChange={(e) => {
                  let v = 0;
                  if (e.target.value) {
                    v = parseFloat(e.target.value);
                  }
                  setSubscription((o) => ({ ...o!, per_txn_value: v }));
                }}
                placeholder="0"
                type="number"
                name=""
                id="per_txn"
                className="col-span-2 px-2 py-1 bg-gray-100 rounded focus:outline-none text-right"
              />
            </div>
          </div>
        </div>
        <hr />
        <div className="my-5">
          <h5 className="font-semibold ">Unit({"\u20b9"})</h5>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-5">
            <div className="grid grid-cols-1 items-center">
              <label htmlFor="per_unit" className="text-gray-600">
                Per unit
              </label>
              <input
                value={subscription?.per_unit}
                onChange={(e) => {
                  let v = 0;
                  if (e.target.value) {
                    v = parseFloat(e.target.value);
                  }
                  setSubscription((o) => ({ ...o!, per_unit: v }));
                }}
                placeholder="0"
                type="number"
                name=""
                id="per_unit"
                className="col-span-2 px-2 py-1 bg-gray-100 rounded focus:outline-none text-right"
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1    my-5 items-start">
          <label htmlFor="note" className="text-gray-600">
            Remarks
          </label>
          <textarea
            value={subscription?.note}
            onChange={(e) => {
              setSubscription((o) => ({ ...o!, note: e.target.value }));
            }}
            placeholder="note"
            rows={5}
            name=""
            id="note"
            className="col-span-6 px-2 py-1 bg-gray-100 rounded focus:outline-none "
          />
        </div>
      </div>
      <div className="flex justify-end my-2 gap-2">
        <button
          onClick={() => {
            if (window.confirm("Are you sure to cancel")) props.onCancel();
          }}
          className=" border border-myPrimaryColor  rounded-3xl px-2  py-1 flex gap-1  text-sm text-myPrimaryColor items-center hover:scale-110 transform duration-300"
        >
          <Icon path={mdiClose} className="h-5 w-5  "></Icon> Cancel
        </button>
        <button
          onClick={onSubmit}
          className="border border-myPrimaryColor bg-myPrimaryColor rounded-3xl px-2  py-1 flex gap-1  text-sm text-white items-center hover:scale-110 transform duration-300"
        >
          <Icon path={mdiCheck} className="h-5 w-5  "></Icon> Submit
        </button>
      </div>
    </>
  );
};

export default SubscriptionConfigWidget;
