export const MINUTE = 60 * 1000;
export const HOUR = MINUTE * 60;
export const offsetM = 5.5 * 60;
export const offsetMs = offsetM * MINUTE;

export const DeviceIdRegex = new RegExp("^[a-z0-9]{6}$");
export const numberRegEx =
  /^-?([0]{1}.{1}[0-9]+|[1-9]{1}[0-9]*.{1}[0-9]+|[0-9]+|0)$/;
export const OrderCreateActions = {
  addItem: "add item",
  removeItem: "remove item",
  loading: "loading",
  addInitAction: "initaction",
  next: "next",
  back: "back",
  cancel: "cancel",
  submit: "submit",
};
export const CONTROLTYPE = {
  text: "TEXT",
  textArea: "TEXTAREA",
  password: "PASSWORD",
  number: "NUMBER",
  radio: "RADIO",
  select: "SELECT",
  multiselect: "MULTISELECT",
  checkbox: "CHECKBOX",
  file: "FILE",
  date: "DATE",
  time: "TIME",
  dateTime: "DATETIME",
  switch: "SWITCH",
};

export const ALERTTYPE = {
  success: "SUCCESS",
  info: "INFO",
  error: "ERROR",
  warining: "WARNING",
};
export const DEPARTMENTS = ["SALES,ACCOUNTS", "PRODUCT"];

export const mailType = {
  welcome: 1,
  receipt: 2,
  invoice: 3,
  visit: 4,
  complaint: 5,
  payment_reminder: 6,
  po_confirmation: 7,
};
export const getMailTemplates = (type: number, arg: any = "") => {
  switch (type) {
    case mailType.welcome:
      return {
        name: "Welcome mail",
        sub: `Welcome to Kazam Network (order ID : ${arg})`,
        body: `Hello Dear,\nWelcome to Kazam Network.\nThanks for placing an order with us. We will be delivering your order within stipulated timelines.\nMeanwhile, if you have any concerns, please feel free to whatsapp or call us on +91 9958943092 or mail us at support@kazam.in\nLet us build the world's largest EV charging network.\n\nRegards,\nKazam Team`,
      };

    case mailType.po_confirmation:
      return {
        name: "PO Confirmation",
        sub: ` Welcome to Kazam Charging Network (order ID : ${arg})`,
        body: `Hello Dear,\n Welcome to Kazam Charging Network.\nThanks for placing an order with us.\nPlease confirm your order.\n Click here`,
      };
    case mailType.invoice:
      return {
        name: "Invoice mail",
        sub: `Invoice from Kazam  (Order Id : ${arg})`,
        body: `Hello Dear,\nPlease find the invoice attached in the mail.\nIf you have any concerns, please feel free to whatsapp or call us on +91 9958943092 or mail us at support@kazam.in\n\nRegards,\nKazam Team`,
      };
    case mailType.visit:
      return {
        name: "Engineer visit",
        sub: "Regarding Kazam Engineer Visit",
        body: `Hello Dear,\nAs discussed our engineer will be visiting your site tomorrow.\nIf you have any concerns,please feel free to whatsapp or call us on +91 9958943092 or mail us at support@kazam.in\n\nRegards,\nKazam Team`,
      };
    case mailType.complaint:
      return {
        name: "Complaint Resolution",
        sub: "Regarding Kazam Electric Vehicle Charging Station",
        body: `Hello Dear,\nWe have noted down your issue. Our team will reach out to you for the resolution of the same.\nIf you have any concerns,  please feel free to whatsapp or call us on +91 9958943092 or mail us at support@kazam.in\n\nRegards,\nKazam Team`,
      };
    case mailType.receipt:
      return {
        name: "Payment Receipt ",
        sub: `Welcome to Kazam Network (Order Id: ${arg})`,
        body: `Hello Dear,\nWelcome to Kazam Network. We received your payment successfully. Please find the payment receipt attached.\nMeanwhile, if you have any concerns,please feel free to whatsapp or call us on +91 9958943092 or mail us at support@kazam.in\n\nRegards,\nKazam Team`,
      };
    case mailType.payment_reminder:
      return {
        name: "Payment Reminder ",
        sub: `Welcome to Kazam Network (Order Id: ${arg})`,
        body: `Hello Dear,<br>
              
        Welcome to Kazam Charging Network. We hope you are doing great. This is a gentle reminder for the pending payment for Kazam EV charging solution.  <a href="${process.env.REACT_APP_BACKEND_BASE_URL}/payment/initiate?order_id=${arg}">Click here</a> to complete your payment and process further. Meanwhile, if you have any concerns, please feel free to whatsapp or call us on +91 9958943092 or mail us at support@kazam.in
        <br> Regards,\n
         Kazam Team`,
      };
    default:
      return {};
      break;
  }
};
export const MINI_EXTRA_AMT = 1000;
// export const products = [
//   {
//     name: "Kazam 3.3",
//     id: 1,
//     instock: true,
//     image: "/3.3-product.webp",
//     old_price: 18000,
//     price: 14000,
//     description:
//       "3.3 KW AC Smart Charging Station, SIM Based IOT Enabled, Supports: Electric Car, Electric Bike, Electric Scooter, 3 Pinpoint,16A Socket Charger",
//   },
//   {
//     name: "Kazam Mini",
//     id: 2,
//     instock: true,
//     image: "/mini-product.webp",
//     extra_amt: MINI_EXTRA_AMT,
//     extra_des: "with GSM connectivity",
//     old_price: 7500,
//     price: 5500,
//     description:
//       "3.3 KW AC Smart Charging Station, Affordable, Wi-fi based IOT Enabled, Supports: Electric Car, Electric Bike, Electric Scooter, 3 Pinpoint,16A Socket Charger, GSM (Optional)",
//   },
//   // { id: 2, name: "Kazam MINI with SIM", price: 9000, sale_price: 6500 },
//   { id: 4, name: "Kazam LEV AC", price: 18000, sale_price: 12600 },
// ];

export const OpsTaskDeviceIssueType = {
  sim_issue: "SIM ISSUE",
  led_strip: "LED STRIP",
  white_led_issue: "WHITE LED ISSUE",
  f1_fuse: "F1 FUSE",
  f2_fuse: "F2 FUSE",
  ntc: "NTC",
  mcb: "MCB",
  emergency_stop: "EMERGENCY STOP",
  three_pin_socket_burnt: "3PIN SOCKET BURNT",
  three_pin_socket_damaged: "3PIN SOCKET DAMAGED",
  industrial_socket_damaged: "INDUSTRIAL SOCKET DAMAGED",
  industrial_plug_damaged: "INDUSTRIAL PLUG DAMAGED",
  front_enclosure_damaged: "FRONT ENCLOSURE DAMAGED",
  back_enclosure_damaged: "BACK ENCLOSURE DAMAGED",
  mcb_reset: "MCB RESET",
  smps_failure: "SMPS FAILURE",
  fuse_holder_burnt: "FUSE HOLDER BURNT",
  earthing_issue: "EARTHING ISSUE",
  r_58_burnt: "R-58 BURNT",
  surge_arrester_burnt: "SURGE ARRESTER BURNT",
  led_blinking: "LED BLINKING",
  deivce_led_not_working: "DEVICE LED NOT WORKING",
  terminal_block_connector_burnt: "TERMINAL BLOCK CONNECTOR BURNT",
  amps_16_socket_back_enclosure_conver_damaged:
    "16 AMPS SOCKET BACK ENCLOSURE/ COVER DAMAGE",
  amps_16_socket_fron_enclosure: "16 AMPS SOCKET FRONT ENCLOSURE",
};

export let OpsTaskSafetyChecklistA = {
  "1": `EV charging stations shall need to be provided with protection against the overload of
input & output supply fittings`,
  "2": `EV charging points outlet socket shall be min. of 800 mm above the finished ground
level`,
  "3": `A cord extension set or second supply lead or adaptor or portable socket- outlets shall
not be used in the charging point`,
  "4": `No adaptor shall be used to connect a vehicle connector to a vehicle inlet`,
  "5": `EV charging point & vehicle parking shall be within five meters`,
  "6": `Suitable lightning protection system shall be provided for the electric vehicle charging
stations (IS/ IEC 62305)`,
  "7": `Safety clearance between the oil or gas dispenser and electric vehicle charging point
shall be as per the PESO`,
  "8": `Only four core cable shall be used for charging points which require three-phase power
supply`,
  "9": `Underground cables shall not cross the underground oil tank or oil pipeline`,
  "10": `Underground cables through the charging area or vehicle passage shall be avoided andif provided shall be at a minimum depth of one metre from the finished ground surface`,
};
export let OpsTaskSafetyChecklistB = {
  "1": `All residual current device for the protection of supplies for electric vehicle shall, -
(a) have a residual operating current of not greater than 30 mA;
(b) interrupt all live conductors, including the neutral; and
(c) have a performance at least equal to Type A and be in conformity with IS 732-2018`,
  "2": `Each electric vehicle charging points shall be supplied individually by a dedicated final
sub-circuit protected by an over-current protective device`,
  "3": `Co-ordination of various protective devices shall be required`,
  "4": `All electric vehicle charging stations shall be supplied from a sub-circuit protected by a
voltage independent residual current device and also provide personal protection that
is compatible with a charging supply for an electric vehicle`,
  "5": `All electric vehicle charging stations shall be provided with an earth continuity
monitoring system`,
  "6": `Earthing of all electric vehicle charging stations shall be as per IS 732`,
  "7": ` A protective earth conductor shall be provided to establish an equipotential
connection between the earth terminal of the supply and the conductive parts of the
vehicle which shall be of sufficient rating to satisfy the requirements of IEC 60364-5-54`,
};
export const OpsTaskSafetyChecklistC = {
  "1": `Firefighting system for charging stations shall be provided `,
  "2": `Enclosure of charging stations shall be made of fire retardant material with self- extinguishing property and free from Halogen`,
  "3": `Fire detection, alarm and control system shall be provided as per relevant Indian
standards`,
  "4": `Power supply cables used in charging station or charging points shall conformto IEC
62893-1`,
};
