import axios from "axios";
import {
  VehicleModel,
  VehiclePrice,
  VehicleVarient,
} from "../infrastructure/models/car_model";
import {
  fetchDelete,
  fetchGet,
  fetchPost,
  fetchPut,
  getHeaders,
} from "./serviceClient";

export const getVehiclesRepo = async ({
  category,
  page,
  count,
}: {
  category: string;
  page: number;
  count: number;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/vehicle?category=${category}&page=${page}&count=${count}`;

    const res = await fetchGet(url, {
      headers: getHeaders(),
    });

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const getVarientsByVehicleIdRepo = async ({
  vehicle_id,
  page,
  count,
}: {
  vehicle_id: string;
  page: number;
  count: number;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/vehicle/${vehicle_id}/varient?page=${page}&count=${count}`;

    const res = await fetchGet(url, {
      headers: getHeaders(),
    });

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const getPricesByVerientIdRepo = async ({
  varient_id,
}: {
  varient_id: string;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/vehicle/varient/${varient_id}/price`;

    const res = await fetchGet(url, {
      headers: getHeaders(),
    });

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const getGalleryByVerientIdRepo = async ({
  varient_id,
}: {
  varient_id: string;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/vehicle/varient/${varient_id}/gallery`;

    const res = await fetchGet(url, {
      headers: getHeaders(),
    });

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const postVehicleDetail = async (data: FormData) => {
  const token = localStorage.getItem("token");
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/vehicle`;

  const res = await fetchPost(url, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return res;
};
export const putVehicleDetail = async (data: FormData) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/vehicle`;
  const res = await fetchPut(url, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return res;
};
export const postVarient = async (data: {
  vehicle_id: string;
  varients: any[];
}) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/vehicle/varient`;
  const res = await fetchPost(url, data);

  return res;
};
export const putVarient = async (data: any) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/vehicle/varient`;
  const res = await fetchPut(url, data);

  return res;
};
export const postVehiclePrice = async (data: {
  varient_id: string;
  prices: any[];
}) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/vehicle/price`;
  const res = await fetchPost(url, data);

  return res;
};
export const postVehicleGallery = async (data: FormData) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/vehicle/gallery`;
  const res = await fetchPost(url, data);

  return res;
};
export const putVehiclePrice = async (data: {
  varient_id: string;
  price: VehiclePrice;
}) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/vehicle/price`;
  const res = await fetchPut(url, data);

  return res;
};
export const putVehicleGallery = async (data: FormData) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/vehicle/gallery`;
  const res = await fetchPut(url, data);

  return res;
};
export const deletetVehicle = async (vehicle_id: string) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/vehicle/${vehicle_id}`;
  const res = await fetchDelete(url);

  return res;
};
export const deletetVehicleVarient = async (varient_id: string) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/vehicle/varient/${varient_id}`;
  const res = await fetchDelete(url);

  return res;
};
export const deletetVehiclePrice = async ({
  varient_id,
  price_id,
}: {
  varient_id: string;
  price_id: string;
}) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/vehicle/varient/${varient_id}/price/${price_id}`;
  const res = await fetchDelete(url);

  return res;
};
export const deletetVehicleGallery = async ({
  varient_id,
  gallery_id,
}: {
  varient_id: string;
  gallery_id: string;
}) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/vehicle/varient/${varient_id}/gallery/${gallery_id}`;
  const res = await fetchDelete(url);

  return res;
};

export const putVehicleVisibility = async (data: {
  id: string;
  active: boolean;
}) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/vehicle/visibility`;
  const res = await fetchPut(url, data);

  return res;
};

export const putVarientVisibility = async (data: {
  id: string;
  active: boolean;
}) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/vehicle/varient/visibility`;
  const res = await fetchPut(url, data);

  return res;
};
export const putPriceVisibility = async (data: {
  id: string;
  price_id: string;
  active: boolean;
}) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/vehicle/price/visibility`;
  const res = await fetchPut(url, data);

  return res;
};
