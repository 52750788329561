import React, { FunctionComponent, useEffect, useState } from "react";
import Icon from "@mdi/react";
import {
  mdiDelete,
  mdiFilterMenu,
  mdiPencil,
  mdiSortAscending,
  mdiSortDescending,
} from "@mdi/js";
import Pagination from "../../ui/new_components/common/pagination";
import { FieldType } from "./create_view/form_field";
import { currencyFormat } from "../../utils/orders_utils";

interface TableViewProps<T extends { id?: string }> {
  page?: number;
  total?: number;
  count?: number;
  itemCount?: number;
  onPageChange?: (p: number) => void;
  headers: {
    colspan?: number;
    type: FieldType;
    key: string;
    sort_order?: number;
    onClick?: (data: { sort: string; sort_order: number }) => void;
    title: string;
    sub_title?: string;
    icon?: string;
    options?: string[];
    onChange?: (selectedOptions: string[] | any) => void;
  }[];
  rows: {
    data: T;
    values: any[];
  }[];
  onClick?: (data: T) => void;
  onEdit?: (data: T) => void;
  onDelete?: (data: T) => void;
}

const TableView = <T extends { id?: string }>({
  headers,
  rows,
  onClick,
  onEdit,
  onDelete,
  page,
  count,
  itemCount,
  total,
  onPageChange,
}: TableViewProps<T>): JSX.Element => {
  const [offSet, setOffSet] = useState(0);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]); // State for selected filter options

  useEffect(() => {
    const container = document.getElementById("page_container");
    const top_section = document.getElementById("top_section");
    const table = document.getElementById("table-container");
    if (container && top_section) {
      table!.style.height = `${
        container!.clientHeight - 16 - top_section!.clientHeight
      }px`;
    }
    if (page && count) {
      setOffSet(page > 0 ? (page - 1) * count : 0);
    }
    // Add event listener for clicks outside the filter icon
    const handleClickOutside = (event: MouseEvent) => {
      const filterIcon = document.getElementById("filter_menu_icon");

      if (filterIcon && !filterIcon.contains(event.target as Node)) {
        setShowFilterOptions(false); // Close filter options menu
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [page, count]);

  // Method to handle filter option changes
  const handleFilterChange = (option: string) => {
    const isSelected = selectedOptions.includes(option);
    if (isSelected) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  return (
    <section
      id="table-container"
      className="flex flex-col justify-between items-center w-full"
    >
      <div
        id="scroll_listener"
        className="overflow-auto h-full border rounded-lg w-full"
        style={{ height: "95%" }}
      >
        <table className="divide-y divide-gray-200 relative w-full">
          <thead className="sticky -top-1 z-10">
            <tr className="sticky -top-0.5 text-sm bg-white shadow whitespace-nowrap">
              <th className="pb-2 font-semibold w-8">
                <div className="py-1">Sl</div>
              </th>
              {headers.map((header, i) => (
                <th
                  key={i}
                  colSpan={header.colspan || 1}
                  className="pb-2 font-semibold"
                >
                  <div className="py-1 flex justify-center items-center gap-1">
                    <div className="flex flex-col gap-1">
                      <h3>{header.title ?? "---"}</h3>
                      {header.sub_title && <p>{header.sub_title}</p>}
                    </div>
                    {header.icon === mdiFilterMenu && header.options && (
                      <div className="relative" id="filter_menu_icon">
                        <div
                          onClick={() =>
                            setShowFilterOptions(!showFilterOptions)
                          }
                          className="h-5 w-5 cursor-pointer text-gray-500"
                        >
                          <Icon path={header.icon} className="h-full w-full" />
                        </div>
                        {showFilterOptions && (
                          <div className="absolute z-10 right-0 mt-2 w-48 bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 divide-y divide-gray-200">
                            {header.options.map((option, index) => (
                              <label
                                key={index}
                                className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100"
                              >
                                <input
                                  type="checkbox"
                                  className="form-checkbox h-4 w-4 text-blue-500"
                                  checked={selectedOptions.includes(option)}
                                  onChange={() => handleFilterChange(option)}
                                />
                                <span className="ml-2 text-sm text-gray-700">
                                  {option}
                                </span>
                              </label>
                            ))}
                          </div>
                        )}
                      </div>
                    )}
                    {header.onClick && (
                      <div
                        onClick={() =>
                          header.onClick!({
                            sort: header.key,
                            sort_order: header.sort_order === 1 ? -1 : 1,
                          })
                        }
                        title={
                          header.sort_order === 1 ? "Ascending" : "Descending"
                        }
                      >
                        <Icon
                          path={
                            header.sort_order === 1
                              ? mdiSortAscending
                              : mdiSortDescending
                          }
                          className="h-4 w-5"
                        />
                      </div>
                    )}
                  </div>
                </th>
              ))}
              {(onEdit || onDelete) && (
                <th className="pb-2 font-semibold">
                  <div className="py-1 flex justify-center items-center gap-1">
                    Action
                  </div>
                </th>
              )}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200 max-h-96 mx-2 text-xs">
            {rows
              .filter(
                (row) =>
                  selectedOptions.length === 0 ||
                  selectedOptions.includes(row.values[2])
              ) // Filter rows based on selected options
              .map((item, i) => (
                <tr
                  key={i}
                  className={`sticky-row ${
                    onClick || onEdit ? "cursor-pointer" : ""
                  } bg-white shadow p-1 hover:bg-gray-100`}
                  onDoubleClick={() =>
                    onClick
                      ? onClick(item.data)
                      : onEdit
                      ? onEdit(item.data)
                      : undefined
                  }
                  title="Double click to open"
                >
                  <td className="p-1 text-right w-min whitespace-nowrap border sticky-field py-1">
                    <div>{offSet + (i + 1)}</div>
                  </td>
                  {item.values.map((val, j) => (
                    <td
                      key={j}
                      colSpan={headers[j].colspan || 1}
                      className={`p-1 border sticky-field ${
                        onClick ? "cursor-pointer" : ""
                      } ${
                        headers[j].type === FieldType.currency
                          ? "text-right"
                          : "text-center"
                      } overflow-hidden overflow-ellipsis`}
                    >
                      <div className="line-clamp-2 whitespace-pre">
                        {val ?? "--"}
                      </div>
                    </td>
                  ))}
                  {(onEdit || onDelete) && (
                    <td>
                      <div className="flex justify-center gap-5">
                        <button
                          onClick={() => onEdit && onEdit(item.data)}
                          className="text-sm font-semibold text-blue-300 flex items-center gap-1 hover:text-blue-500"
                        >
                          <Icon path={mdiPencil} className="w-4 h-4" />
                          Edit
                        </button>
                        <button
                          onClick={() => onDelete && onDelete(item.data)}
                          className="text-sm font-semibold text-red-300 flex items-center gap-1 hover:text-red-500"
                        >
                          <Icon path={mdiDelete} className="w-4 h-4" />
                          Delete
                        </button>
                      </div>
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {page && count && (
        <Pagination
          page={page}
          total={total || 1}
          count={count}
          itemCount={itemCount || 1}
          onChange={onPageChange ? onPageChange : () => {}}
        />
      )}
    </section>
  );
};

export default TableView;
