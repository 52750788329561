import { FunctionComponent, useState } from "react";
import ItemSearchField from "../../../../erp/inventory/items/item_search_field";
import { ItemModel } from "../../../../erp/inventory/models/item_model";
import DeviceComponentRow from "./device_component_row";
import DeviceItemRow from "./device_item_row";

interface DeviceRemarkWidgetProps {
  data?: string;
  onSubmit: (data: string) => void;
  editEnable: boolean;
  onCancel: () => void;
}

const DeviceRemarkWidget: FunctionComponent<DeviceRemarkWidgetProps> = (
  props
) => {
  const [data, setData] = useState<string>(props.data ?? "");
  // const [disabled, setDisabled] = useState<boolean>(props.disabled ?? false);
  const [edit, setEdit] = useState<boolean>(false);
  const [addNewId, setAddNewId] = useState("");

  return (
    <div className="flex flex-col justify-between h-full p-2">
      <div className="p-5">
        <textarea
          disabled={!edit || false}
          rows={10}
          className="border rounded w-full h-full focus:outline-none outline-none p-2 text-sm"
          placeholder="Remarks"
          value={data}
          onChange={(e) => {
            setData(e.target.value);
          }}
        ></textarea>
      </div>
      <div className="grid grid-cols-2 gap-5 p-2">
        {edit ? (
          <>
            <button
              onClick={() => setEdit(false)}
              className="border rounded-lg px-3 py-2 bg-red-50 text-red-500 border-red-400"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                setEdit(false);
                props.onSubmit(data);
              }}
              className="border rounded-lg px-3 py-2 bg-green-50 text-green-500 border-green-400"
            >
              Submit
            </button>
          </>
        ) : (
          props.editEnable && (
            <>
              <div className=""></div>
              <button
                onClick={() => setEdit(true)}
                className="border rounded-lg px-3 py-2 bg-green-50 text-green-500 border-green-400"
              >
                Edit
              </button>
            </>
          )
        )}
      </div>
    </div>
  );
};

export default DeviceRemarkWidget;
