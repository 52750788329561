import { FunctionComponent, useState } from "react";
import { ChevronDown, ChevronUp } from "react-feather";
import { learningFaq } from "../../../../../utils/faq";

interface FaqProps {}

const Faq: FunctionComponent<FaqProps> = () => {
  return (
    <div className=" relative min-h-screen">
      <div className="flex flex-col items-center pt-20 lg:pt-0">
        <div className="flex flex-col items-center py-12 lg:py-20 w-5/6 lg:w-3/5">
          {learningFaq.map((data: any, i) => {
            return (
              <div className="w-full mb-12" key={i}>
                <div className="text-xl lg:text-3xl text-red-700 font-bold mb-6 lg:mb-12">
                  {data.name}
                </div>
                <div className="">
                  {data.dataArray.map((item: any, i: number) => {
                    return <FaqAccordion data={item} key={i} />;
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Faq;

interface FaqAccordion {
  data: any;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FaqAccordion: FunctionComponent<FaqAccordion> = ({ data }) => {
  const [show, setShow] = useState(data.show);
  return (
    <div className="mb-6 lg:mb-12">
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={() => setShow(!show)}
      >
        <div className="lg:text-xl font-medium lg:font-semibold ">
          {data.ques}
        </div>
        <div className="">{show ? <ChevronUp /> : <ChevronDown />}</div>
      </div>
      {show && (
        <div className="">
          <div
            className={`faqAns text-sm lg:text-base mt-2 lg:w-4/5 text-black text-opacity-60 duration-300`}
          >
            {data.ans}
          </div>
          <div
            className={`faqAns w-full duration-200 bg-black bg-opacity-30 h-px mt-6`}
          ></div>
        </div>
      )}
      {!show && (
        <div
          className={` w-full duration-200 bg-black bg-opacity-30 h-px mt-3 lg:mt-6`}
        ></div>
      )}
    </div>
  );
};
