import {
  Avatar,
  Divider,
  ListItemIcon,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import { PersonAdd, Settings } from "@material-ui/icons";
import { mdiCheckDecagram, mdiCloseOctagon } from "@mdi/js";
import Icon from "@mdi/react";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  useToast,
  ToastType,
} from "../../../../../context_providers/toast/toast_ctx";

import {
  OrderByItemModel,
  OrderModel,
} from "../../../../../service/models/orders/order_model";
import { GetOrdersApi } from "../../../../../service/repos/order_repo";
import { debounce } from "../../../../../utils/debounce";
import {
  OrderStatus,
  PaymentStatus,
  PO_STATUS,
} from "../../../../../utils/enums";
import { currencyFormat } from "../../../../../utils/orders_utils";
import { urls } from "../../../../../utils/urls";

interface OrderTableWidgetProps {
  analytics?: {
    total_orders: number;
    total: number;
    subtotal: number;
    discount: number;
    addition: number;
    paid: number;
    qty: number;
    delivered_qty: number;
    installed_qty: number;
    returned_qty: number;
    net_qty: number;
  };
  orders: OrderByItemModel[];
  // page: number;
  // total: number;

  order_by: string;
  sort_order: string;
  onSort: (order_by: string) => void;
  onShowComment: (id: OrderByItemModel) => void;
  onShowHistory: (id: OrderByItemModel) => void;

  onOrderClick: (row: OrderByItemModel) => void;
}

const OrderTableWidget: FunctionComponent<OrderTableWidgetProps> = (props) => {
  // const [orders, setOrders] = useState([...props.orders]);
  const { orders } = props;
  const { showToast } = useToast();
  const columns: { [key: string]: string } = {
    order_id: "Order ID",
    order_date: "Date",
    ago: "Age",
    type: "Order type",
    "customer.firstName": "Customer",
    "customer.mobile": "Mobile",
    "customer.address.city": "City",
    "customer.address.state": "State",
    "customer.address.country": "Country",
    status: "Status",
    payment_status: "Payment Status",
    items: "Product/Service",
    "items.price": "Price",
    "items.qty": "Qty",

    "items.delivered_qty": "Delivered",
    "items.installed_qty": "Installed",
    "items.returned_qty": "Returned",
    net_qty: "Net Qty ",
    "Order_items.subtotal": "Subtotal",
    "items.discount_amt": "Discount",
    "items.addition_amt": "Extra",
    "Order_items.total": "Total",
    "Order_items.paid": "Paid",
    balance: "Balance",
    expected_pay_date: "Expected Pay Date",
    next_delivery_date: "Next Delivery Date",
    sale_by: "Sales person",
    // rm: "RM",
    source: "Source",
    wiring_status: "Wire status",
    site_survey: "Site survey",
    load_sancion: "Load sanction(KW)",
    subsidy_status: "Subsidy status",
    qc_status: "QC status",
    remark: "Pending collection remark",
  };

  return (
    <>
      <div className=" flex justify-center mt-3" style={{ height: "82vh" }}>
        <div
          id="scroll_listener"
          className=" overflow-auto h-full border rounded-lg"
        >
          <table className=" divide-y divide-gray-200 relative">
            <thead className="  sticky  -top-1 z-10 ">
              <tr className="bg-gray-200">
                <th
                  scope="col"
                  className={`px-2 py-2  text-xs font-normal   text-center   uppercase lg:sticky lg:left-0  bg-gray-200    border`}
                ></th>

                {Object.keys(columns).map((column, i) => {
                  const filter_av =
                    [
                      "order_date",
                      "type",
                      "customer.firstName",
                      "customer.address.city",
                      "customer.address.state",
                      "customer.address.status",
                      "payment_status",
                      "items.price",
                      "items.qty",
                      "items.delivered_qty",
                      "items.installed_qty",
                      "items.subtotal",
                      "items.addition_amt",
                      "items.discount_amt",
                      "items.paid",
                      "items.total",

                      "expected_pay_date",
                      "next_delivery_date",
                    ].indexOf(column) !== -1;
                  return (
                    <th
                      key={i}
                      scope="col"
                      style={{ fontSize: "12px" }}
                      className={`px-3 py-1     text-center   whitespace-nowrap ${
                        column === "order_date"
                          ? " lg:sticky lg:left-14"
                          : column === "customer.firstName"
                          ? " lg:sticky lg:left-32"
                          : column === "customer.address.state"
                          ? " lg:sticky lg:left-56"
                          : column === "status"
                          ? " lg:sticky lg:left-72"
                          : column === "payment_status"
                          ? " lg:sticky lg:left-96"
                          : ""
                      }  bg-gray-200    border`}
                    >
                      <div
                        className="flex items-center gap-2 justify-between  cursor-pointer "
                        onClick={
                          filter_av ? () => props.onSort(column) : undefined
                        }
                      >
                        <div className="">
                          <p> {columns[column]}</p>
                          {props.analytics && (
                            <p
                              className="font-normal"
                              style={{ fontSize: "10px" }}
                            >
                              {column === "order_id"
                                ? props.analytics?.total_orders ?? 0
                                : column === "qty"
                                ? props.analytics?.qty ?? 0
                                : column === "delivered_qty"
                                ? props.analytics?.delivered_qty ?? 0
                                : column === "installed_qty"
                                ? props.analytics?.installed_qty ?? 0
                                : column === "returned_qty"
                                ? props.analytics?.returned_qty ?? 0
                                : column === "net_qty"
                                ? props.analytics?.net_qty ?? 0
                                : column === "Order_items.subtotal"
                                ? currencyFormat(props.analytics?.subtotal ?? 0)
                                : column === "addition_amt"
                                ? currencyFormat(props.analytics?.addition ?? 0)
                                : column === "discount_amt"
                                ? currencyFormat(props.analytics?.discount ?? 0)
                                : column === "Order_items.total"
                                ? currencyFormat(props.analytics?.total ?? 0)
                                : column === "Order_items.paid"
                                ? currencyFormat(props.analytics?.paid ?? 0)
                                : column === "balance"
                                ? currencyFormat(
                                    (props.analytics.total ?? 0) -
                                      (props.analytics.paid ?? 0)
                                  )
                                : ""}
                            </p>
                          )}
                        </div>
                        {filter_av ? (
                          <>
                            {props.order_by === column ? (
                              <Tooltip
                                title={
                                  props.sort_order === "asc"
                                    ? "Ascending"
                                    : "Descending"
                                }
                              >
                                <div className="">
                                  {props.sort_order === "asc" ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-4 w-4"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M17 13l-5 5m0 0l-5-5m5 5V6"
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-4 w-4"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M7 11l5-5m0 0l5 5m-5-5v12"
                                      />
                                    </svg>
                                  )}
                                </div>
                              </Tooltip>
                            ) : (
                              <Tooltip title="sort">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-3 w-3"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4"
                                  />
                                </svg>
                              </Tooltip>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 max-h-96 mx-2">
              {orders.map((order, i) => {
                // return order.items.map((item, j) => (
                return (
                  <tr
                    style={{ fontSize: "12px" }}
                    className={`sticky-row  hover:bg-gray-200  `}
                    key={i}
                  >
                    <td className=" whitespace-nowrap border sticky-field lg:sticky lg:left-0">
                      <div className="flex gap-1">
                        <Tooltip title="copy order ID">
                          <svg
                            onClick={() => {
                              navigator.clipboard.writeText(order.order_id);

                              showToast({
                                type: ToastType.info,
                                text: "Copied order ID to clip board!",
                              });
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4 cursor-pointer hover:text-red-600"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
                            />
                          </svg>
                        </Tooltip>
                        <Tooltip title="comments">
                          <svg
                            onClick={() => props.onShowComment(order)}
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4 hover:text-red-600 cursor-pointer"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                            />
                          </svg>
                        </Tooltip>
                        <Tooltip title="history">
                          <svg
                            onClick={() => props.onShowHistory(order)}
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4  cursor-pointer hover:text-red-600"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        </Tooltip>
                      </div>
                    </td>
                    <td
                      // onClick={() => props.onOrderClick(order)}
                      className={` px-2 py-2 whitespace-nowrap     border  `}
                    >
                      <div className="flex">
                        <NavLink
                          target="_blank"
                          // to={urls.orderDetail + "?order_id=" + order.order_id}
                          to={`orders/${order.order_id}`}
                        >
                          <div className="mx-1 cursor-pointer flex   ">
                            {order.po_status === PO_STATUS.confirmed && (
                              <span>
                                <Tooltip title={"PO " + PO_STATUS.confirmed}>
                                  <Icon
                                    className="text-green-400 h-4 w-4"
                                    path={mdiCheckDecagram}
                                  ></Icon>
                                </Tooltip>
                              </span>
                            )}
                            {order.po_status === PO_STATUS.rejected && (
                              <span>
                                <Tooltip title={"PO " + PO_STATUS.rejected}>
                                  <Icon
                                    className="text-red-600 h-4 w-4"
                                    path={mdiCloseOctagon}
                                  ></Icon>
                                </Tooltip>
                              </span>
                            )}
                            <span className="text-blue-400 ">
                              {order.order_id}
                            </span>
                            {order.demo === (1 as any) ||
                              (order.demo === true && (
                                <Tooltip title={`Demo Order`}>
                                  <span
                                    style={{
                                      fontSize: "8px",
                                      marginLeft: "3px",
                                      fontWeight: "bolder",
                                    }}
                                  >
                                    DEMO
                                  </span>
                                </Tooltip>
                              ))}
                          </div>
                        </NavLink>
                      </div>
                    </td>
                    <td
                      align="center"
                      className="  whitespace-nowrap border sticky-field lg:sticky lg:left-14 "
                    >
                      {/* <Tooltip
                        title={moment(
                          order.order_date,
                          "DD/MM/YYYY hh:mm:ss"
                        ).fromNow()}
                      > */}
                      <p>{moment(order.order_date).format("lll")}</p>
                      {/* </Tooltip> */}
                    </td>
                    <td align="center" className="  whitespace-nowrap border">
                      {/* <Tooltip
                        title={moment(
                          order.order_date,
                          "DD/MM/YYYY hh:mm:ss"
                        ).fromNow()}
                      > */}
                      <Tooltip title="Days">
                        <p>{moment().diff(moment(order.order_date), "day")}</p>
                      </Tooltip>
                      {/* </Tooltip> */}
                    </td>
                    <td
                      align="center"
                      // onClick={() => props.onOrderClick(order)}
                      className=" px-2 whitespace-nowrap  font-medium   border"
                    >
                      {order.type}
                    </td>
                    <td
                      align="left"
                      // onClick={() => props.onOrderClick(order)}
                      className=" sticky-field lg:sticky lg:left-32 px-2   font-medium    truncate  h-7 border   "
                      style={{ maxWidth: "150px" }}
                    >
                      <NavLink target="_blank" to={`orders/${order.order_id}`}>
                        <div
                          title={
                            (order.customer?.firstName ?? "") +
                            " " +
                            (order.customer?.lastName ?? "")
                          }
                          // onClick={() => props.onOrderClick(order)}
                          className="mx-1 cursor-pointer flex   "
                        >
                          <span className="text-blue-400 ">
                            {(order.customer?.firstName ?? "") +
                              " " +
                              (order.customer?.lastName ?? "")}
                          </span>
                          {/* {order.complaint_status && (
                            <Tooltip
                              title={`complaint status : ${order.complaint_status}`}
                            >
                              <div className=" ml-2 text-sm filter grayscale">
                                {order.complaint_status.toLowerCase() ===
                                "Solved".toLowerCase() ? (
                                  <p> &#128522; </p>
                                ) : order.complaint_status.toLowerCase() ===
                                  "Under Progress".toLowerCase() ? (
                                  <p>&#128542;</p>
                                ) : order.complaint_status.toLowerCase() ===
                                  "Pending".toLowerCase() ? (
                                  <p>&#128544;</p>
                                ) : (
                                  ""
                                )}
                              </div>
                            </Tooltip>
                          )} */}
                        </div>
                      </NavLink>
                    </td>
                    <td className="p-2 whitespace-nowrap  border">
                      {order.customer?.mobile}
                    </td>
                    <td className="p-2 font-medium   whitespace-nowrap  border">
                      {order.customer?.address?.city}
                    </td>
                    <td className="p-2 whitespace-nowrap font-medium    border sticky-field lg:sticky lg:left-56">
                      {order.customer?.address?.state}
                    </td>
                    <td className="p-2 whitespace-nowrap font-medium    border  ">
                      {order.customer?.address?.country}
                    </td>
                    <td
                      align="center"
                      className={` px-3 sticky-field whitespace-nowrap lg:sticky lg:left-72 border  `}
                    >
                      <span
                        className={`px-2 py-1 whitespace-nowrap font-medium     rounded-full ${
                          order.status === OrderStatus.CANCELLED
                            ? "bg-red-50 text-red-800"
                            : order.status === OrderStatus.INIT ||
                              order.status === OrderStatus.WANT_TO_CANCEL
                            ? "bg-yellow-50 text-yellow-400"
                            : "bg-green-50 text-green-800"
                        }`}
                      >
                        {order.status}
                      </span>
                    </td>
                    <td
                      align="center"
                      className={` sticky-field lg:sticky lg:left-96   whitespace-nowrap border  `}
                    >
                      <span
                        className={` px-2 py-1 whitespace-nowrap font-medium     rounded-full ${
                          order.payment_status !== PaymentStatus.PENDING_PAYMENT
                            ? "bg-green-50 text-green-800"
                            : "bg-red-50 text-red-800"
                        }`}
                      >
                        {order.payment_status!}
                      </span>
                    </td>
                    <td
                      align="left"
                      className="px-2 whitespace-nowrap   truncate  h-7 border"
                      style={{ maxWidth: "150px" }}
                    >
                      <div className="ml-4">{order.items.name}</div>
                    </td>
                    <td align="center" className=" whitespace-nowrap   border">
                      {currencyFormat(order.items.price)}
                    </td>
                    <td align="center" className=" whitespace-nowrap   border">
                      {order.items.qty}
                    </td>
                    <td align="center" className=" whitespace-nowrap   border">
                      {order.items.delivered_qty ?? 0}
                    </td>
                    <td align="center" className=" whitespace-nowrap   border">
                      {order.items.installed_qty ?? 0}
                    </td>
                    <td align="center" className=" whitespace-nowrap   border">
                      {order.items.returned_qty ?? 0}
                    </td>
                    <td align="center" className=" whitespace-nowrap   border">
                      {(order.items.delivered_qty ?? 0) -
                        (order.items.returned_qty ?? 0)}
                    </td>
                    <td
                      align="right"
                      className="whitespace-nowrap   border px-2"
                    >
                      {currencyFormat(
                        (order.items.price + (order.items.extra_amt ?? 0)) *
                          order.items.qty
                      )}
                    </td>
                    <td
                      align="right"
                      className="whitespace-nowrap   border px-2"
                    >
                      {currencyFormat(order.items.discount_amt ?? 0)}
                    </td>
                    <td
                      align="right"
                      className="whitespace-nowrap   border px-2"
                    >
                      {currencyFormat(order.items.addition_amt ?? 0)}
                    </td>
                    <td
                      className=" whitespace-nowrap px-1 border"
                      align="right"
                    >
                      {currencyFormat(order.items.total ?? 0)}
                    </td>
                    <td
                      className=" whitespace-nowrap px-1 border"
                      align="right"
                    >
                      {currencyFormat(order.items.paid ?? 0)}
                    </td>
                    <td
                      className=" font-bold whitespace-nowrap px-1 border"
                      align="right"
                    >
                      {currencyFormat(
                        (order.items.total ?? 0) - (order.items.paid ?? 0)
                      )}
                    </td>
                    <td
                      className="px-3 py-2     truncate  h-7 border"
                      style={{ maxWidth: "120px" }}
                    >
                      {order.expected_pay_date
                        ? moment(order.expected_pay_date, "YYYY-MM-DD").format(
                            "DD MMM YYYY"
                          )
                        : "--"}
                    </td>
                    <td
                      className="px-3 py-2     truncate  h-7 border"
                      style={{ maxWidth: "120px" }}
                    >
                      {order.next_delivery_date
                        ? moment(order.next_delivery_date, "YYYY-MM-DD").format(
                            "DD MMM YYYY"
                          )
                        : "--"}
                    </td>
                    <td
                      className="px-3 py-2     truncate  h-7 border"
                      style={{ maxWidth: "120px" }}
                    >
                      {order.sales_person_name ?? "--"}
                    </td>
                    {/* <td
                      className="px-3 py-2     truncate  h-7 border"
                      style={{ maxWidth: "120px" }}
                    >
                      {order.rm_name ?? "--"}
                    </td> */}
                    <td
                      className="px-3 py-2 truncate  border"
                      style={{ maxWidth: "120px" }}
                    >
                      {order.source ?? "--"}
                    </td>
                    <td
                      className="px-3 py-2     truncate  h-7 border"
                      style={{ maxWidth: "120px" }}
                    >
                      {order.wiring_status ?? "--"}
                    </td>
                    <td
                      className="px-3 py-2     truncate  h-7 border"
                      style={{ maxWidth: "120px" }}
                    >
                      {order.site_survey ?? "--"}
                    </td>
                    <td
                      className="px-3 py-2     truncate  h-7 border"
                      style={{ maxWidth: "120px" }}
                    >
                      {order.load_sanction ? `${order.load_sanction} KW` : "--"}
                    </td>
                    <td
                      className="px-3 py-2     truncate  h-7 border"
                      style={{ maxWidth: "120px" }}
                    >
                      {order.subsidy_status ?? "--"}
                    </td>
                    <td
                      className="px-3 py-2     truncate  h-7 border"
                      style={{ maxWidth: "120px" }}
                    >
                      {order.qc_status ?? "--"}
                    </td>
                    <td
                      className="px-3 py-2     truncate  h-7 border"
                      style={{ maxWidth: "120px" }}
                    >
                      {order.remark ?? "--"}
                    </td>
                  </tr>
                );
                // ));
              })}
            </tbody>
          </table>
        </div>
      </div>
      {/* {loading && (
        <div className=" flex justify-center items-center">
          <div className="font-semibold  text-green-500 m-1">
            <Loader />
          </div>
          <p className="font-semibold  text-green-500">Please wait ....</p>
        </div>
      )}
      {orders.length === total && (
        <div className=" flex justify-center">
          <p className="font-semibold ">No more items </p>
        </div>
      )}

      <div className=" flex justify-center">
        <p className="font-semibold text-sm">
          Showing {orders.length} of {total}
        </p>
      </div> */}
    </>
  );
};

export default OrderTableWidget;
