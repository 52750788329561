import {
  mdiRefresh,
  mdiClose,
  mdiPlus,
  mdiFilter,
  mdiFileDocument,
  mdiExport,
  mdiFileExcel,
  mdiMicrosoftExcel,
  mdiDownloadBox,
  mdiDownload,
  mdiTrayArrowDown,
} from "@mdi/js";
import Icon from "@mdi/react";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useAuth } from "../../../../context_providers/auth/authProvider";
import {
  ModalType,
  useModal,
} from "../../../../context_providers/modal/modal_context";
import {
  useToast,
  ToastType,
} from "../../../../context_providers/toast/toast_ctx";

import { AttachmentModel } from "../../../../service/models/attachment_modal";
import { TransactionDataModel } from "../../../../service/models/trannsaction_model";
import {
  CreateTrasactionRepo,
  GetTxnsApi,
  UpdateTrasactionStatusRepo,
} from "../../../../service/repos/transaction_repo";
import { MODULE, ACTION } from "../../../../utils/enums";
import ModalDialog from "../../../new_components/common/modal";
import Pagination from "../../../new_components/common/pagination";
import SearchFieldWidget from "../../../new_components/search_field";
import ExportWidget from "./components/export_widget";
import TxnFilterWidget from "./components/filter_widget";
import PaymentRowWidget from "./components/payment_row";
import TransactionEditWidget from "./components/trasaction_edit_widget";

interface TransactionPageProps {}

const TransactionPage: FunctionComponent<TransactionPageProps> = () => {
  const { showToast } = useToast();
  const { setLoading, showModal, onClose } = useModal();
  const history = useHistory();

  const { user, isAuthorised } = useAuth();

  const [addNew, setAddNew] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [formId, setFormId] = useState(0);
  useEffect(() => {
    setDisabled(
      !isAuthorised({ module: MODULE.order, action: [ACTION.UPDATE] })
    );
  }, []);
  const [showFilter, setShowFilter] = useState(false);
  // const state = useAppSelector((state) => state.TxnReducer);
  // const dispatch = useAppDispatch();

  const [showTransactionCreate, setShowTransactionCreate] = useState(false);
  const [filter, setFilter] = useState<{
    fromDate?: Date;
    toDate?: Date;
    status?: string;
    type?: string;
    search?: string;
  }>({});
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(100);
  const [total, setTotal] = useState(0);
  const [timeStamp, setTimeStamp] = useState("");
  const [search, setSearch] = useState("");
  const [payments, setPayments] = useState<TransactionDataModel[]>([]);

  const changeTxnStatus = async (id: string, status: string) => {
    setLoading(true);
    const res = await UpdateTrasactionStatusRepo({ id, status });

    if (res.success) {
      setPayments((o) =>
        o.map((t) => {
          if (t.id === id || t.parent_txn_id === id) {
            t.status = status;
            t.verified_by_name = res.data.verified_by_name;
            t.verified_by = res.data.uid;
          }

          return t;
        })
      );
      // setShowTransactionCreate(false);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  const getTransactions = async () => {
    setLoading(true);
    const option = { page, count, ...filter };
    const result: {
      success: boolean;
      data: {
        metadata: { total: number; page: number; count: number }[];
        transactions: TransactionDataModel[];
      };

      error?: string;
      timeStamp: string;
    } = await GetTxnsApi(option);

    if (result.success) {
      if (result.data.metadata[0]) {
        setTotal(result.data.metadata[0].total);
      }
      setPayments(result.data.transactions);

      setTimeStamp(result.timeStamp);
    } else {
      showToast({ type: ToastType.error, text: result.error ?? "" });
    }
    setLoading(false);
  };
  useEffect(() => {
    getTransactions();
  }, [page, filter]);

  const createTxn = async (data: FormData, modal_id?: any) => {
    setLoading(true);

    const res = await CreateTrasactionRepo(data);

    if (res.success) {
      setPayments((o) => {
        if (o.length === count) o.pop();
        return [res.data, ...o];
      });
      onClose(modal_id);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };

  return (
    <div className="px-2 pt-3 bg-myBgcolor">
      <>
        <div className="flex justify-between items-end my-1 ">
          <div className="flex flex-col ">
            <div className="flex items-center gap-2 ">
              <h3 className="font-semibold text-gray-500">Payments</h3>{" "}
              <button className="" onClick={getTransactions}>
                <Icon
                  path={mdiRefresh}
                  className="h-4 w-4 text-gray-500 hover:scale-105 transform hover:text-black"
                ></Icon>
              </button>
            </div>
            <p className="text-xs">
              Fetched at : {moment(timeStamp).format("h:mm:ss a")}
            </p>
          </div>

          <div className="flex justify-end gap-2   text-sm items-center ">
            <div className="bg-white rounded">
              <SearchFieldWidget
                placeholder="Txn id, order id, name, mobile, email"
                defaulValue={search}
                onClear={() => {
                  setFilter({ ...filter, search: "" });
                  setSearch("");
                }}
                onSubmit={(search) => {
                  if (search) {
                    setPage(1);
                    setFilter({ search });
                  }
                }}
              />
            </div>
            <div
              onClick={() => {
                showModal({
                  type: ModalType.modal,

                  container: (id) => (
                    <TxnFilterWidget
                      onReset={() => {
                        if (filter) setFilter({});
                        onClose(id);
                      }}
                      filter={filter}
                      onSubmit={setFilter}
                      onClose={() => onClose(id)}
                    />
                  ),
                  title: "Filter",
                });
              }}
              className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
            >
              <Icon path={mdiFilter} className="h-4 w-4"></Icon>Filter
            </div>
            <div
              onClick={() => {
                showModal({
                  type: ModalType.modal,
                  container: (id) => (
                    <TransactionEditWidget
                      onSubmit={(data) => createTxn(data, id)}
                    />
                  ),
                  title: "Add Payment",
                });
              }}
              className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
            >
              <Icon path={mdiPlus} className="h-4 w-4"></Icon>Add
            </div>
            <div
              onClick={() => {
                showModal({
                  type: ModalType.modal,
                  container: (id) => <ExportWidget />,
                  title: "Export",
                });
              }}
              className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
            >
              <Icon path={mdiTrayArrowDown} className="h-4 w-4"></Icon>Export
            </div>
          </div>
        </div>

        <div className="bg-myPrimaryColor text-white rounded-md shadow p-3 my-2 text-sm">
          <div className="grid grid-cols-12 gap-3 ">
            <h4 className="font-semibold text-start ">Sl.no | Date</h4>
            <h4 className="font-semibold text-center ">Type</h4>
            <h4 className="font-semibold text-center ">Mode</h4>
            <h4 className="font-semibold text-center ">Ref type</h4>
            <h4 className="font-semibold text-center col-span-2 ">Ref</h4>
            <h4 className="font-semibold text-center col-span-2">Txn ID</h4>
            <h4 className="font-semibold text-center">Amount ({`\u20b9`})</h4>
            {/* <h4 className="font-semibold text-center col-span-2">Note</h4> */}
            <h4 className="font-semibold text-center">Status</h4>
            <h4 className="font-semibold text-center">File</h4>
            <h4 className="font-semibold text-center"> </h4>
          </div>
        </div>
        <div className="  overflow-auto" style={{ height: "76vh" }}>
          <div className="flex flex-col gap-2">
            {payments.length > 0 ? (
              payments.map((payment, i) => (
                <PaymentRowWidget
                  sl_no={(page - 1) * count + (i + 1)}
                  disabled={disabled}
                  edit={false}
                  key={payment.id}
                  payment={payment}
                  onStatusChanged={changeTxnStatus}
                />
              ))
            ) : (
              <div className="my-10 flex flex-col gap-5 items-center justify-center text-2xl font-semibold text-gray-400">
                <p> No Record Found !</p>
              </div>
            )}
          </div>
        </div>
        <div className="bg-white rounded-md shadow px-3 py-1 my-1   text-sm">
          <Pagination
            itemCount={payments.length}
            page={page}
            total={total}
            count={count}
            onChange={setPage}
          />
        </div>
      </>
    </div>
  );
  return (
    <div className="px-3 py-5 bg-myBgcolor">
      {showFilter && (
        <ModalDialog
          title="Filter"
          show={showFilter}
          onClose={() => setShowFilter(false)}
        >
          <TxnFilterWidget
            onReset={() => {
              if (filter) setFilter({});
            }}
            filter={filter}
            onSubmit={setFilter}
            onClose={() => setShowFilter(false)}
          />
        </ModalDialog>
      )}
      {showTransactionCreate && (
        <ModalDialog
          show={showTransactionCreate}
          title="Add Transaction"
          onClose={() => setShowTransactionCreate(false)}
        >
          <TransactionEditWidget onSubmit={createTxn} />
        </ModalDialog>
      )}

      <div className="flex justify-between flex-wrap items-center ">
        <form
          id="searchForm"
          name="searchForm"
          className=" flex items-center rounded border focus:border-black my-1 justify-between "
          onSubmit={(e) => {
            e.preventDefault();
            setPage(1);
            setFilter({ search });
          }}
        >
          {/* <label htmlFor="search">Search</label> */}
          <input
            id="search"
            name="search"
            value={search}
            className=" px-3 py-2 w-full  rounded focus:outline-none"
            type="text"
            placeholder="Search by order Id,Txn Id"
            onChange={(e) => setSearch(e.target.value)}
          />{" "}
          {search && (
            <div className=" flex items-center  ">
              <button
                onClick={() => {
                  setFilter({ ...filter, search: "" });
                  setSearch("");
                }}
                form="searchForm"
                type="reset"
                className=" rounded-full hover:bg-gray-200  mx-1 p-1"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 "
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          )}
          <div className="border-l-2 border-gray-700 flex items-center  ">
            <button
              form="searchForm"
              type="submit"
              className=" rounded-full hover:bg-gray-200  mx-1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 p-1  "
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </button>
          </div>
        </form>{" "}
        <div className="flex">
          <div className="flex items-center justify-end">
            <p className="text-xs">Last upated at : {timeStamp}</p>
            <button
              className=" rounded px-2 py-1 mx-2 bg-transparent border border-gray-500 "
              type="reset"
              onClick={() => {
                getTransactions();
              }}
            >
              <div className="flex items-center text-sm ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 mx-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                  />
                </svg>
                Refresh
              </div>
            </button>
          </div>
          <button
            className="  rounded px-3 py-1 my-2 bg-transparent border border-gray-500 "
            onClick={() => {
              setShowTransactionCreate(true);
            }}
          >
            <div className="flex items-center text-sm gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 mr-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                />
              </svg>
              Add
            </div>
          </button>{" "}
          {/* <button
            className="bg-gray-200  rounded px-3 py-1 my-2  hover:bg-gray-500 hover:text-white"
            // onClick={onExport}
          >
            <div className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 mr-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                />
              </svg>
              Export
            </div>
          </button> */}
          <button
            className="  rounded px-3 py-1 my-2 mx-2 bg-transparent border border-gray-500"
            onClick={() => setShowFilter(true)}
          >
            <div className="flex text-sm items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 mr-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                />
              </svg>
              Filter
            </div>
          </button>{" "}
        </div>
      </div>
      {/* <div className="flex gap-3 flex-wrap my-2">
        <div className="rounded-md shadow py-1 px-2">
          <div className="flex gap-3 items-end">
            <div className="">
              <h6 className="text-sm font-semibold">Total Transactions</h6>
              <h3 className="text-3xl font-semibold">
                {analytics?.total_txn.total}
              </h3>
            </div>
            <h3 className="text-sm font-semibold">
              {analytics?.total_txn.amount.toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                style: "currency",
                currency: "INR",
              })}
            </h3>
          </div>
        </div>
        <div className="rounded-md shadow py-1 px-2">
          <div className="flex gap-3 items-end">
            <div className="">
              <h6 className="text-sm font-semibold">Total Payment</h6>
              <h3 className="text-3xl font-semibold">
                {analytics?.payment_txn.total}
              </h3>
            </div>
            <h3 className="text-sm font-semibold">
              {(analytics?.payment_txn.amount ?? 0).toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                style: "currency",
                currency: "INR",
              })}
            </h3>
          </div>
        </div>
        <div className="rounded-md shadow py-1 px-2">
          <div className="flex gap-3 items-end">
            <div className="">
              <h6 className="text-sm font-semibold">Total Refund</h6>
              <h3 className="text-3xl font-semibold">
                {analytics?.refund_txn.total}
              </h3>
            </div>
            <h3 className="text-sm font-semibold">
              {(analytics?.refund_txn.amount ?? 0).toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                style: "currency",
                currency: "INR",
              })}
            </h3>
          </div>
        </div>
        <div className="rounded-md shadow py-1 px-2">
          <div className="flex gap-3 items-end">
            <div className="">
              <h6 className="text-sm font-semibold">Pending transactions</h6>
              <h3 className="text-3xl font-semibold">
                {analytics?.pending_txn.total}
              </h3>
            </div>
            <h3 className="text-sm font-semibold">
              {(analytics?.pending_txn.amount ?? 0).toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                style: "currency",
                currency: "INR",
              })}
            </h3>
          </div>
        </div>
      </div> */}
      {/* <TxnTableWidget
        onStatusChanged={changeTxnStatus}
        txns={transactions}
        onPageChange={setPage}
        page={page}
        count={count}
        total={total}
      /> */}

      {/* <LoadingWidget loading={loading} /> */}
    </div>
  );
};

export default TransactionPage;
