import { FunctionComponent } from "react";

interface HrmInputProps {
  type: string;
  name: string;
  label: string;
  defaultValue?: string;
  value?: string;
  labelClass?: string;
  inputClass?: string;
  disabled?: boolean;
  required: boolean;
  onChange: (e: any) => void;
}

const HrmInput: FunctionComponent<HrmInputProps> = ({
  type,
  name,
  label,
  defaultValue,
  value,
  labelClass,
  inputClass,
  disabled,
  required,
  onChange,
}) => {
  return (
    <div className="">
      <div className={` text-sm text-gray-400 font-semibold ${labelClass}`}>
        {label}
      </div>
      {disabled ? (
        <div className="">{defaultValue}</div>
      ) : (
        <input
          className={`shadow-md py-3 px-4 bg-white w-full  focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg text-black font-semibold mt-1 ${inputClass}  `}
          name={name}
          type={type}
          placeholder={label}
          defaultValue={defaultValue}
          onChange={(e) => onChange(e)}
          disabled={disabled}
          required={required}
          value={value}
        />
      )}
    </div>
  );
};

export default HrmInput;
