import { mdiCheck, mdiClose, mdiLink, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useState } from "react";
import { useAuth } from "../../../../../context_providers/auth/authProvider";
import { useOrderDetailCtx } from "../../../../../context_providers/order_detail_context";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";

import { MODULE, ACTION, PaymentRefType } from "../../../../../utils/enums";
import { currencyFormat } from "../../../../../utils/orders_utils";
import PaymentRowWidget from "./payment_row";
import ModalDialog from "../../../../new_components/common/modal";
import {
  ModalType,
  useModal,
} from "../../../../../context_providers/modal/modal_context";
import PaymentLinkWidget from "./paymant_link_widget";

interface PaymentSectionWidgetProps {}

const PaymentSectionWidget: FunctionComponent<
  PaymentSectionWidgetProps
> = () => {
  const { showToast } = useToast();
  const { showModal } = useModal();
  const { user, isAuthorised, logout } = useAuth();

  const [useBal, setUseBal] = useState(false);
  const {
    order,
    setOrder,
    onPaymentAdded,
    onPaymentDeleted,
    onPaymentUpdated,
    balTxnAmt,

    getBalTxn,
  } = useOrderDetailCtx();

  // const [showPaymantLink, setShowPaymantLink] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [formId, setFormId] = useState(0);
  useEffect(() => {
    setDisabled(
      !isAuthorised({ module: MODULE.order, action: [ACTION.UPDATE] })
    );
  }, []);
  useEffect(() => {
    if (addNew) getBalTxn();
  }, [addNew]);

  const disableAdd =
    order.subtotal + order.excess - order.discount - order.paid <= 0;

  const showPayment = () => {
    showModal({
      type: ModalType.modal,
      title: "Payment Link",
      container(id) {
        return (
          <PaymentLinkWidget
            order_id={order.order_id}
            max_amount={order.total - order.paid}
          />
        );
        return (
          <div className="w-96">
            <div className="grid grid-cols-2">
              <div className="">
                <label htmlFor="full">Full Amount</label>
                <input type="radio" name="amt_type" id="full" />
              </div>
              <div className="">
                <label htmlFor="partial">Partial Amount</label>
                <input type="radio" name="amt_type" id="partial" />
              </div>
            </div>
          </div>
        );
      },
    });
  };
  return (
    <>
      <div className="flex justify-between items-end my-1 ">
        <h3 className="font-semibold text-gray-500">Payments</h3>
        <div className="flex justify-end   text-sm items-center ">
          {addNew ? (
            <div className="flex gap-2">
              <div
                onClick={() => {
                  setAddNew(false);
                }}
                className="flex gap-1 items-center cursor-pointer rounded p-1 bg-red-100 hover:scale-105 duration-200 hover:text-red-600 hover:bg-red-200 text-red-400  transform"
              >
                <Icon path={mdiClose} className="h-4 w-4"></Icon>Cancel
              </div>
            </div>
          ) : (
            !disabled && (
              <div className="flex gap-2">
                <div
                  onClick={showPayment}
                  className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
                >
                  <Icon path={mdiLink} className="h-4 w-4"></Icon> payment link
                </div>
                <div
                  onClick={() => {
                    if (disableAdd) {
                      showToast({
                        type: ToastType.info,
                        text: "Full amount paid !",
                      });
                      return;
                    }

                    setAddNew(true);
                  }}
                  className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
                >
                  <Icon path={mdiPlus} className="h-4 w-4"></Icon>Add
                </div>
              </div>
            )
          )}
        </div>
      </div>

      <div className="bg-white rounded-md shadow p-3 my-2 text-sm">
        <div className="grid grid-cols-11 gap-3 ">
          <h4 className="font-semibold text-center ">
            {" "}
            <span className="text-base text-red-500">*</span>Date
          </h4>
          <h4 className="font-semibold text-center ">
            {" "}
            <span className="text-base text-red-500">*</span>Type
          </h4>
          <h4 className="font-semibold text-center ">
            {" "}
            <span className="text-base text-red-500">*</span>Mode
          </h4>
          <h4 className="font-semibold text-center col-span-2">Txn ID</h4>
          <h4 className="font-semibold text-center">
            {" "}
            <span className="text-base text-red-500">*</span>Amount
          </h4>
          <h4 className="font-semibold text-center col-span-2">Note</h4>
          <h4 className="font-semibold text-center">Status</h4>
          <h4 className="font-semibold text-center">File</h4>
          <h4 className="font-semibold text-center"> </h4>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        {order.transactionData?.length > 0 ? (
          order.transactionData?.map((payment, i) => (
            <PaymentRowWidget
              disabled={disabled}
              edit={false}
              key={payment.id}
              payment={payment}
              onDelete={onPaymentDeleted}
              onSubmit={onPaymentUpdated}
            />
          ))
        ) : (
          <div className="my-10 flex flex-col gap-5 items-center justify-center text-2xl font-semibold text-gray-400">
            <p> No Record Found !</p>
          </div>
        )}
        {addNew && !disabled && (
          <div className="   border-dashed  border-2 rounded-md border-green-300 p-2">
            <div className="text-sm flex flex-col items-start ">
              <div className="flex flex-col  gap-2     items-start ">
                <div className="flex gap-1">
                  <p> B/D Balance : </p>
                  <span className="font-semibold text-green-500">
                    {currencyFormat(balTxnAmt)}
                  </span>
                </div>
                {/* <div className=" flex gap-1  items-center rounded bg-green-100 text-green-500  px-1 border border-green-400">
                  <Icon path={mdiPlus} className="h-4 w-4"></Icon> use
                </div> */}
                <div className="flex gap-1 cursor-pointer">
                  <input
                    disabled={balTxnAmt === 0}
                    type="checkbox"
                    name=""
                    id="use_bal"
                    checked={useBal}
                    onChange={(e) => {
                      setUseBal(e.target.checked);
                    }}
                  />
                  <label htmlFor="use_bal" className="text-xs">
                    Use B/D Balance
                  </label>
                </div>
              </div>
              <p className="italic text-xs text-gray-400">
                Check the above checkbox to use the D/B Balance
              </p>
            </div>

            <PaymentRowWidget
              use_bal={useBal}
              disabled={disabled}
              key={formId}
              edit={true}
              // form_id={formId.toString()}
              onSubmit={async (data) => {
                data.id = Date.now() as any;
                data.ref_id = order.order_id;
                data.ref_type = PaymentRefType.order;
                await onPaymentAdded({ data, use_bal: useBal });
                setAddNew(false);
                setFormId(Math.random());
              }}
            />
            <div className="flex justify-between">
              <p className="text-sm italic text-gray-400">
                Please fill required fields then hit + to add product.
              </p>
              <p className="text-sm italic text-gray-400">
                Note :Payment will reflect in order only after verified by
                Accounts department.
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PaymentSectionWidget;
