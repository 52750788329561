import { Tooltip } from "@material-ui/core";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import "../../styles/index.css";
import { ChevronLeft, ChevronRight, ExternalLink, X } from "react-feather";
import { GetPublicHolidayList } from "../../../../../service/repos/hrm/hrm_repo";
import HrmLoader from "../../components/loading";

interface CalendarProps {
  showClose?: boolean;
  close: () => void;
}

const Calendar: FunctionComponent<CalendarProps> = ({ close, showClose }) => {
  const [calendarData, setCalendarData] = useState<any>([]);
  const daysList = ["S", "M", "T", "W", "T", "F", "S"];
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const leaveTypes = [
    "Earned Leave",
    "Medical Leave",
    "Work From Home",
    "Unpaid Leave",
  ];
  const weekOffTypes = ["Week off", "Alt week off"];

  const [date, setDate] = useState<Date>(new Date());

  const getHolidayList = async () => {
    const res = await GetPublicHolidayList({
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    });
    if (res.success) {
      getCalendarData(date, res.data);
    }
  };

  const getCalendarData = (
    date: Date,
    holidays: { date: number; holiday_name: string; isRestricted: boolean }[]
  ) => {
    const data: {
      day: number;
      isHoliday: boolean;
      isRestricted: boolean;
      holiday_name?: string;
    }[] = [];
    const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
    const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    let curDay = startDate.getDate();
    for (let i = 0; i < startDate.getDay() + endDate.getDate(); i++) {
      if (i < startDate.getDay()) {
        data.push({ day: 0, isHoliday: false, isRestricted: false });
        continue;
      }
      const holiday = holidays.find((h) => {
        return new Date(h.date).getDate() === curDay;
      });
      if (holiday) {
        data.push({
          day: curDay,
          isHoliday: !holiday.isRestricted,
          isRestricted: holiday.isRestricted,
          holiday_name: holiday.holiday_name,
        });
      } else {
        data.push({
          day: curDay,
          isHoliday: false,
          isRestricted: false,
        });
      }
      //logic

      curDay++;
    }

    setCalendarData(data);
  };

  useEffect(() => {
    console.log("here", calendarData);
  }, [calendarData]);

  useEffect(() => {
    getHolidayList();
  }, [date]);

  return (
    <div className="w-full  bg-white shadow-lg dark:bg-black py-4 px-6 rounded-2xl">
      {showClose && (
        <div className="flex flex-col items-end">
          <X
            size={32}
            className="dark:text-white cursor-pointer"
            onClick={() => close()}
          />
        </div>
      )}

      <div className="flex justify-between items-center mb-2">
        <div className=" font-bold dark:text-primaryColor">Work Calendar</div>
        <div className="text-sm font-bold text-hrmBlue rounded-full flex gap-1 justify-center items-center">
          <a href="/docs/publicHoliday.pdf" target="_blank">
            Check full calendar
          </a>
          <ExternalLink size={16} />
        </div>
      </div>
      <div className="flex justify-between my-4">
        <div className="mb-4 text-sm text-gray-400 dark:text-primaryColor">
          {monthNames[date.getMonth()]} {date.getFullYear()}
        </div>
        <div className="flex">
          <div
            className="w-6 h-6 rounded-full border border-primaryColor flex justify-center items-center cursor-pointer"
            onClick={() =>
              setDate((d) => {
                d.setMonth(d.getMonth() - 1);
                return new Date(d);
              })
            }
          >
            <ChevronLeft className="dark:text-primaryColor" size={12} />
          </div>
          <div
            className="ml-2 w-6 h-6 rounded-full border border-primaryColor flex justify-center items-center cursor-pointer"
            onClick={() =>
              setDate((d) => {
                d.setMonth(d.getMonth() + 1);
                return new Date(d);
              })
            }
          >
            <ChevronRight className="dark:text-primaryColor" size={12} />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-7 gap-y-7 mb-2 border-b pb-2">
        {daysList.map((d) => (
          <div className=" dark:text-primaryColor text-xs pl-3 font-bold">
            {d}
          </div>
        ))}
      </div>
      <div className="grid grid-cols-7 gap-y-4 ">
        {calendarData.length > 0 ? (
          calendarData.map((d: any, i: number) => {
            if (d.day === 0) {
              return <div className=""></div>;
            } else if (d.isHoliday) {
              return (
                <Tooltip title={d.holiday_name}>
                  <div
                    className={`${
                      weekOffTypes.includes(d?.holiday_name)
                        ? "bg-gray-300 text-white"
                        : d.isRestricted
                        ? "bg-yellow-300 text-white"
                        : leaveTypes.includes(d?.holiday_name)
                        ? "bg-hrmBlue text-white"
                        : "bg-green-500 text-white"
                    }  dark:bg-white dark:bg-opacity-25 calendar_tool relative w-8 h-8 rounded-full text-xs flex flex-col items-center justify-center`}
                  >
                    {d.day}
                  </div>
                </Tooltip>
              );
            } else {
              return (
                <Tooltip title={d?.holiday_name}>
                  <div
                    className={`${
                      d?.isRestricted ? "bg-blue-300 text-white" : ""
                    } calendar_tool relative w-8 h-8 rounded-full text-xs flex flex-col items-center justify-center    dark:text-white  `}
                  >
                    {d.day}
                  </div>
                </Tooltip>
              );
            }
          })
        ) : (
          <HrmLoader className="w-6 h-6" />
        )}
      </div>
      {calendarData.length > 0 && (
        <div className="grid grid-cols-4 gap-1 mt-5">
          <div className="flex items-center gap-1">
            <div className="w-3 h-3 rounded-full bg-gray-300"></div>
            <div className="text-xs dark:text-primaryColor">Week off</div>
          </div>
          <div className="flex items-center gap-1">
            <div className="w-3 h-3 rounded-full bg-hrmBlue"></div>
            <div className="text-xs dark:text-primaryColor">Leaves taken</div>
          </div>
          <div className="flex items-center gap-1">
            <div className="w-3 h-3 rounded-full bg-green-500"></div>
            <div className="text-xs dark:text-primaryColor">Holidays</div>
          </div>
          <div className="flex items-center gap-1">
            <div className="w-3 h-3 rounded-full bg-blue-300"></div>
            <div className="text-xs dark:text-primaryColor">Restricted</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Calendar;

// const getData = () => {
//   var now = new Date();
//   const tempDays = new Date(
//     now.getFullYear(),
//     now.getMonth() + 1,
//     0
//   ).getDate();

//   setTotalData(tempDays);
//   const monthArray: any = [];
//   for (let i = 1; i <= tempDays; i++) {
//     monthArray.push(i);
//   }

//   const monthData = [...monthArray];
//   data.forEach((el: any) => {
//     const itemIndex = monthData.indexOf(getDate(el.date));
//     monthData.splice(itemIndex, 1, el);
//   });
//   setCalendarData(monthData);
// };
