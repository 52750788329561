import { mdiDownload, mdiDownloadBoxOutline, mdiRefresh } from "@mdi/js";
import Icon from "@mdi/react";
import axios from "axios";
import { count } from "console";
import { toDate } from "date-fns";
import { debounce } from "lodash";
import moment from "moment";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import LoadingWidget from "../../../../context_providers/modal/loader";
import {
  ToastType,
  useToast,
} from "../../../../context_providers/toast/toast_ctx";
import {
  fetchDelete,
  fetchGet,
  fetchPost,
  toQueryString,
} from "../../../../service/api_client";

import { useAuth } from "../../../../context_providers/auth/authProvider";
import { AddressModel } from "../../../../service/models/address_model";
import { BaseModel } from "../../../../service/models/base";
import Pagination from "../../../new_components/common/pagination";
import { ItemModel } from "../../../../erp/inventory/models/item_model";
export interface BoqReportModel extends BaseModel {
  task_id: string;
  order_id: string;
  ref_no: string;
  issue_no: string;
  ref_date: Date;
  issue_date: Date;
  report_no: string;
  report_date: Date;
  project_name: string;
  client_name: string;
  client_address: AddressModel;
  po_no?: string;
  po_date?: Date;
  components?: { item: ItemModel; qty: number; id: string }[];
  doc_no: string;
  emp_id: string;
  emp_name: string;
  emp_mobile: string;
  emp_designation: string;
}
interface BoqReportSectionProps {
  order_id: string;
}

const BoqReportSection: FunctionComponent<BoqReportSectionProps> = (props) => {
  const { user, isAuthorised } = useAuth();

  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();
  const [file, setFile] = useState<File>();
  const [fileBloUrl, setFileBlobUrl] = useState<string>();
  const [fileType, setFileType] = useState<string>();
  const [category, setCategory] = useState("");
  const [showUpload, setShowUpload] = useState(false);
  const [snaps, setData] = useState<BoqReportModel[]>([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(50);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  useEffect(() => {
    if (!snaps || snaps.length === 0) {
      setShowUpload(true);
    }
  }, []);
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/${props.order_id}/boq-report`;
  const fetchData = async (prop: {
    search?: string;
    page: number;
    count: number;
    all?: boolean;
  }) => {
    setLoading(true);
    try {
      const res = await fetchGet(url + toQueryString(prop));
      if (res.success) {
        setData(res.data.data);
        if (res.data.metadata) {
          setTotal(res.data.metadata.total);
        }
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
    }
    setLoading(false);
  };
  // const generateNew = async () => {
  //   setLoading(true);
  //   try {
  //     const res = await fetchPost(url, {});
  //     console.log({ type: typeof res, res });

  //     if (!res.success) showToast({ type: ToastType.error, text: res.error });

  //     const file = dataURLtoFile(res.data);
  //     // const blob = new Blob(res, {
  //     //   type: "application/pdf",
  //     // });
  //     // create a URL for the blob object
  //     const blob_url = URL.createObjectURL(file);
  //     // create a link element to download the PDF
  //     const link = document.createElement("a");
  //     link.href = blob_url;
  //     link.download = (props.po_no || "po") + ".pdf";
  //     // click the link to start the download
  //     link.click();
  //     link.remove();
  //   } catch (error: any) {
  //     showToast({ type: ToastType.error, text: error.message });
  //   }

  //   setLoading(false);
  // };
  const debouncedHandler = useCallback(
    debounce(
      (prop: {
        search?: string;
        page: number;
        count: number;
        all?: boolean;
      }) => {
        if (prop) fetchData(prop);
        else setData([]);
      },
      100
    ),
    []
  );

  useEffect(() => {
    debouncedHandler({ page, count, search });
  }, [page, search]);

  return (
    <>
      <div className="p-5 select-none">
        <div className="flex justify-end gap-1 mb-3">
          <button
            className="flex gap-1"
            title="Refresh"
            onClick={() => debouncedHandler({ page, count, search })}
          >
            Refres
            <Icon path={mdiRefresh} size={0.8}></Icon>
          </button>
        </div>

        <div className="grid grid-cols-7 text-sm font-semibold rounded-lg border  p-2">
          <div className="col-span-2">Date</div>
          <div className="col-span-2 ">Task ID</div>
          <div className="col-span-2 ">Emp name</div>
          <div className=" ">Action</div>
        </div>
        <div
          className="flex flex-col gap-1  overflow-auto w-full mt-1"
          style={{ height: "75vh" }}
        >
          {snaps &&
            snaps.length > 0 &&
            snaps.map((att, i) => (
              <div
                key={i}
                className="grid grid-cols-7 rounded-lg border   justify-center items-center text-xs p-2"
              >
                <div className=" col-span-2  ">
                  {" "}
                  {moment(att.created_at).utcOffset(330).format("lll")}
                </div>
                <div
                  // target="_blank"
                  // href={att.url}
                  className="rounded-lg   col-span-2 flex justify-center items-center    "
                >
                  {att.task_id}
                </div>

                <div className=" col-span-2  ">{att.emp_name}</div>

                <div className=" flex justify-center  ">
                  <a
                    target="_blank"
                    href={`${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/new-ops/task/${att.task_id}/work-completion-report/download?auth=${user?.token}`}
                    className=" rounded-full p-1 flex justify-center items-center "
                  >
                    <Icon
                      path={mdiDownload}
                      size={0.8}
                      className="hover:scale-105 transform"
                    ></Icon>
                  </a>
                </div>
              </div>
              // <div className="rounded-lg border overflow-hidden relative">
              //   {att.type === FileType.IMAGE && (
              //     <a target="_blank" href={att.url}>
              //       <img src={att.url} alt="" />
              //     </a>
              //   )}
              //   {att.type === FileType.PDF && (
              //     <a target="_blank" href={att.url}>
              //       <embed src={att.url} type="application/pdf"></embed>
              //     </a>
              //   )}
              //   <button
              //     onClick={() => deleteFile(att)}
              //     className="absolute top-0 right-0 rounded-full p-1 flex justify-center items-center "
              //   >
              //     <Icon
              //       path={mdiDelete}
              //       size={0.8}
              //       className="hover:scale-105 transform"
              //     ></Icon>
              //   </button>
              // </div>
            ))}
        </div>
        <Pagination
          page={page}
          total={total}
          count={count}
          itemCount={snaps.length}
          onChange={setPage}
        />
      </div>
      {loading && <LoadingWidget />}
    </>
  );
};

export default BoqReportSection;
