import { FunctionComponent } from "react";
import { Ops_task_status } from "../../../../../../utils/enums";

interface StatusWidgetProps {
  status: string;
}

const StatusWidget: FunctionComponent<StatusWidgetProps> = (props) => {
  return (
    <>
      {props.status == Ops_task_status.pending ? (
        <div className="border rounded-3xl px-3 py-1 text-xs bg-yellow-50 text-yellow-500 border-yellow-400">
          Pending
        </div>
      ) : props.status == Ops_task_status.under_progress ? (
        <div className="border rounded-3xl px-3 py-1 text-xs bg-blue-50 text-blue-500 border-blue-400">
          On going
        </div>
      ) : props.status == Ops_task_status.completed ? (
        <div className="border rounded-3xl px-3 py-1 text-xs bg-green-50 text-green-500 border-green-400">
          Completed
        </div>
      ) : props.status == Ops_task_status.cancelled ? (
        <div className="border rounded-3xl px-3 py-1 text-xs bg-red-50 text-red-500 border-red-400">
          Cancelled
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default StatusWidget;
