import { mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useState } from "react";
import { useOrderCreateCtx } from "../../../../../context_providers/order_create_context";
import { DiscountDataModel } from "../../../../../service/models/orders/order_discount_model";
import { TransactionDataModel } from "../../../../../service/models/trannsaction_model";
import { currencyFormat } from "../../../../../utils/orders_utils";
import { GetPaidAmt, GetTxnAmt } from "../../../../../utils/order_util";
import PaymentRowWidget from "./payment_row";

interface PaymentSectionWidgetProps {}

const PaymentSectionWidget: FunctionComponent<
  PaymentSectionWidgetProps
> = () => {
  const { order, setOrder, balTxnAmt, useBal, setUseBal } = useOrderCreateCtx();

  const [formId, setFormId] = useState(0);
  const disableAdd =
    order.subtotal +
      order.excess -
      order.discount -
      (order.paid + (useBal ? balTxnAmt : 0)) <=
    0;
  return (
    <>
      <div className="flex justify-between  items-end mt-5">
        <h3 className="font-semibold">Payments</h3>
        {/* <div className="text-sm flex flex-col items-end">
          <div className="">
            B/D Balance <span>{rupeeFormat(balTxnAmt)}</span>
          </div>
          <div className="flex gap-2 cursor-pointer text-green-600">
            <input
              disabled={balTxnAmt === 0}
              type="checkbox"
              name=""
              id="use_bal"
              checked={useBal}
              onChange={(e) => {
                setUseBal(e.target.checked);
              }}
            />
            <label htmlFor="use_bal" className="text-xs">
              Use B/D Balance
            </label>
          </div>
        </div> */}
      </div>
      <div className="bg-white rounded-md shadow p-3 my-2 text-sm">
        <div className="grid grid-cols-9 gap-3 ">
          <h4 className="font-semibold text-center ">
            {" "}
            <span className="text-base text-red-500">*</span>Date
          </h4>
          <h4 className="font-semibold text-center ">
            {" "}
            <span className="text-base text-red-500">*</span>Mode
          </h4>
          <h4 className="font-semibold text-center col-span-2">Txn ID</h4>
          <h4 className="font-semibold text-center">
            {" "}
            <span className="text-base text-red-500">*</span>Amount
          </h4>
          <h4 className="font-semibold text-center col-span-2">Note</h4>
          <h4 className="font-semibold text-center">File</h4>
          <h4 className="font-semibold text-center"> </h4>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        {order.transactionData.map((payment, i) => (
          <PaymentRowWidget
            edit={false}
            key={payment.id}
            payment={payment}
            onDelete={(it: TransactionDataModel) => {
              const transactionData = [...order.transactionData].filter(
                (v, j) => v.id !== it.id
              );
              const paid = GetTxnAmt(transactionData);
              setOrder((old) => ({
                ...old,
                paid,
                transactionData,
              }));
            }}
            onSubmit={(data) => {
              const transactionData = order.transactionData.map((it, j) => {
                if (it.id === payment.id) {
                  return data;
                }
                return it;
              });
              const paid = GetTxnAmt(transactionData);

              setOrder((old) => ({
                ...old,
                paid,
                transactionData,
              }));
            }}
          />
        ))}

        <PaymentRowWidget
          key={formId}
          edit={true}
          form_id={formId.toString()}
          onSubmit={(data) => {
            data.id = Date.now() as any;
            const paid = GetTxnAmt([...order.transactionData, data]);
            setOrder((old) => ({
              ...old,
              transactionData: [...old.transactionData, data],
              paid,
            }));
            setFormId(Math.random());
          }}
        />
        <div className="flex justify-between">
          <p className="text-sm italic text-gray-400">
            Please fill required fields then hit + to add product.
          </p>
          <p className="text-sm italic text-gray-400">
            Note :Payment will reflect in order only after verified by Accounts
            department.
          </p>
        </div>
        <div className="flex justify-center my-3">
          <button
            disabled={disableAdd}
            form={formId.toString()}
            className={`${
              disableAdd
                ? " cursor-not-allowed "
                : "hover:scale-110 transform duration-300  "
            } rounded-full p-2 bg-myPrimaryColor`}
          >
            <Icon path={mdiPlus} className="h-5 w-5 text-white"></Icon>
          </button>
        </div>
      </div>
    </>
  );
};

export default PaymentSectionWidget;
