import { FunctionComponent, useEffect, useState } from "react";
import { useOpsTask } from "../../../../../../context_providers/ops_task_engineer_detail/ops_task_engineer_detail";
import axios from "axios";
import { string } from "yup";
import { useHistory, useParams } from "react-router-dom";
import { fetchGet } from "../../../../content_panel/services/serviceClient";
import Icon from "@mdi/react";
import {
  mdiInformationOutline,
  mdiLightningBolt,
  mdiLightningBoltOutline,
} from "@mdi/js";
import DoneWidget from "../../../../../new_components/common/done";
import {
  OpsTaskDeviceItem,
  OpsTaskDeviceItemStatus,
} from "../../../../../../service/models/ops/ops_ticket";
import {
  ToastType,
  useToast,
} from "../../../../../../context_providers/toast/toast_ctx";
import { fetchPut } from "../../../../../../service/api_client";
import LoadingWidget from "../../../../../components/loading_spinner";

interface DeviceTxnTestProps {}

const DeviceTxnTest: FunctionComponent<DeviceTxnTestProps> = () => {
  const history = useHistory();
  const { showToast } = useToast();
  const { device_id } = useParams<{ device_id: string }>();
  const { data, setData } = useOpsTask();
  const [loading, setLoading] = useState(false);
  const [chargerState, setChargerState] = useState<
    "Idle" | "Offline" | "Charging" | "Error"
  >();
  const [deviceItem, setDeviceItem] = useState<OpsTaskDeviceItem>();
  const updateDeviceItem = async (item: OpsTaskDeviceItem) => {
    setLoading(true);
    try {
      item.status = OpsTaskDeviceItemStatus.completed;
      const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/new-ops/task/my-tasks/${data?.id}/device_item/${item.device_id}`;
      const res = await fetchPut(url, item);
      if (res.success) {
        setDeviceItem(res.data);
        showToast({
          type: ToastType.success,
          text: res.messsage ?? "Completed",
        });
      } else {
        showToast({ type: ToastType.error, text: res.error ?? "" });
      }
    } catch (error) {}
    setLoading(false);
  };
  const getDeviceState = async (device_id: string) => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/device/${device_id}/status`;
      const res: {
        error: string;
        success: boolean;
        data: {
          device_id: string;
          connectors: {
            connector_id: number;
            status: string;
          }[];
        };
      } = await fetchGet(url);
      if (res.success) {
        setChargerState(res.data.connectors[0].status as any);
        if (res.data.connectors[0].status == "Charging") {
          await updateDeviceItem({
            ...deviceItem!,
            status: OpsTaskDeviceItemStatus.completed,
            charger_state: res.data.connectors[0].status,
            txn_test_done: true,
          });
          let t: OpsTaskDeviceItem;
          setDeviceItem((o) => {
            o!.charger_state = res.data.connectors[0].status;
            //   let status = o!.status;
            o!.status = OpsTaskDeviceItemStatus.completed;
            o!.txn_test_done = true;

            return {
              ...o!,
            };
          });
          setData((prv) => {
            if (t && prv && prv!.device_items) {
              prv.device_items = prv.device_items.map((f) =>
                f.device_id == t.device_id ? t! : f
              );
            }
            return { ...prv! };
          });
        }
      } else {
        showToast({
          text: res.error,
          type: ToastType.error,
        });
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const reconfirm = async (
    state: "Idle" | "Offline" | "Charging" | "Error"
  ) => {
    if (state == chargerState) {
      await updateDeviceItem({
        ...deviceItem!,
        txn_test_done: true,
        charger_state: chargerState,
        status: OpsTaskDeviceItemStatus.completed,
      });
      let t: OpsTaskDeviceItem;
      setDeviceItem((pv) => {
        pv = {
          ...pv!,
          txn_test_done: true,
          charger_state: chargerState,
          status: OpsTaskDeviceItemStatus.completed,
        };
        t = pv;
        return pv;
      });
      setData((o) => {
        if (o?.device_items) {
          o!.device_items = o.device_items.map((f) =>
            f.device_id == deviceItem?.device_id ? t! : f
          );
        }
        return { ...o! };
      });
    } else {
      showToast({
        text: "Charger state does not match !",
        type: ToastType.error,
      });
    }
  };

  useEffect(() => {
    data?.device_items?.forEach((e) => {
      if (e.device_id == device_id) {
        setDeviceItem(e);
      }
    });
  }, []);
  // useEffect(() => {
  //   console.log({ deviceItem });

  //   if (deviceItem) {
  //     const x = data?.device_items?.find(
  //       (f) => f.device_id == deviceItem?.device_id
  //     );
  //     console.log({ x });

  //     if (x?.txn_test_done !== deviceItem?.txn_test_done) {
  //       updateDeviceItem(deviceItem);
  //     }
  //   }
  // }, [deviceItem?.txn_test_done]);
  // useEffect(() => {
  //   if (deviceItem && deviceItem.status == OpsTaskDeviceItemStatus.completed)
  //     udpateDeviceItem(deviceItem);
  // }, [deviceItem]);
  return (
    <>
      <div className="flex flex-col gap-2 items-start p-3 h-full w-full pb-10">
        <div className="">
          <button
            onClick={() => {
              history.goBack();
            }}
            className="rounded-3xl border px-5 py-1 bg-blue-50 text-blue-500 border-blue-400 "
          >
            Back
          </button>
        </div>

        <div className="flex justify-center w-full my-5 ">
          <div className="p-5 rounded-full bg-blue-100  border-blue-300 text-blue-500 ">
            {/* <div className="p-3 rounded-full bg-blue-200  border-blue-300 text-blue-500  "> */}
            <Icon path={mdiInformationOutline} size={2} />
            {/* </div> */}
          </div>
          {/* <div className="p-5 rounded-full bg-green-100  border-green-300 text-green-500 animate-pulse">
            <div className="p-3 rounded-full bg-green-200  border-green-300 text-green-500  ">
              <Icon path={mdiLightningBolt} size={5} />
            </div>
          </div> */}
        </div>
        <p className="text-center w-full">
          {" "}
          Device #{" "}
          <span className="text-purple-600  uppercase font-semibold my-5">
            {device_id}
          </span>
        </p>
        <p className="text-gray-500 mb-2 mt-5 text-center ">
          Please start charging through kazam app and hit test.
        </p>
        {deviceItem?.txn_test_done && (
          <>
            {" "}
            <DoneWidget />
            <h1 className="text-lg font-semibold text-center w-full ">
              Completed !
            </h1>
          </>
        )}

        {deviceItem && !deviceItem?.txn_test_done && (
          <div className="fixed bottom-0 right-0 left-0 p-3 bg-white shadow-sm">
            {!chargerState ? (
              <button
                onClick={() => {
                  getDeviceState(device_id);
                }}
                className="bg-green-50 text-green-500 border rounded-lg border-green-300 text-center px-3 py-2 w-full font-bold"
              >
                Test
              </button>
            ) : (
              <>
                <div className="">What's the charger state ?</div>
                <div className="grid grid-cols-2 gap-1">
                  <button
                    onClick={() => reconfirm("Idle")}
                    className="border rounded-lg text-center px-3 py-2 bg-green-50 text-green-500 border-green-300"
                  >
                    Online
                  </button>
                  <button
                    onClick={() => reconfirm("Offline")}
                    className="border rounded-lg text-center px-3 py-2 bg-yellow-50 text-yellow-500 border-yellow-300"
                  >
                    Offline
                  </button>
                  {/* <button
                    onClick={() => reconfirm("Charging")}
                    className="border rounded-lg text-center px-3 py-2 bg-blue-50 text-blue-500 border-blue-300"
                  >
                    Charging
                  </button> */}
                  {/* <button
                    onClick={() => reconfirm("Error")}
                    className="border rounded-lg text-center px-3 py-2 bg-red-50 text-red-500 border-red-300"
                  >
                    Error
                  </button> */}
                </div>
              </>
            )}
          </div>
        )}
      </div>
      {loading && <LoadingWidget loading={true} />}
    </>
  );
};

export default DeviceTxnTest;
