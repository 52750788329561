import { FunctionComponent } from "react";
import {
  useToast,
  ToastType,
} from "../../../context_providers/toast/toast_ctx";

interface FileDropZoneProps {
  type: string;
  required?: boolean;
  onChange: (files: File[]) => void;
  multiSelect: boolean;
  className?: string;
}

const FileDropZone1: FunctionComponent<FileDropZoneProps> = (props) => {
  const { showToast } = useToast();
  return (
    <div className={"h-full w-full"}>
      <label htmlFor="files" className={props.className ?? "h-full w-full"}>
        <div>
          <div
            className="flex flex-col justify-center items-center h-full w-full bg-gray-100 border border-gray-400 cursor-pointer hover:border-green-400  p-5 rounded"
            onDragOver={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onDrag={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onDragEnter={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onDragLeave={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onDrop={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
                if (props.multiSelect && e.dataTransfer.files.length) {
                  const files = [...e.dataTransfer.files];
                  for (let index = 0; index < files.length; index++) {
                    const file = files[index];

                    if (!file.type.match(props.type.replace(",", "|"))) {
                      // if (!file.type.startsWith(props.type)) {
                      showToast({
                        type: ToastType.error,
                        text: "Please select " + props.type + "Files ",
                      });
                      return;
                    }
                  }
                  return props.onChange(files);
                } else if (!props.multiSelect && e.dataTransfer.files.length) {
                  const file = e.dataTransfer.files[0];
                  if (!file.type.match(props.type.replace(",", "|"))) {
                    // if (!file.type.match(props.type)) {
                    showToast({
                      type: ToastType.error,
                      text: "Please select " + props.type + "Files ",
                    });
                    return;
                  }
                  return props.onChange([file]);
                } else
                  showToast({ type: ToastType.error, text: "select a file" });

                e.dataTransfer.clearData();
              }
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-10 w-10  text-gray-300"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
              />
            </svg>
            <p className="text-gray-400">Drag and drop files</p>
          </div>
          <p className="text-xs text-gray-400 mt-2 font-semibold">
            Suported format : {props.type}
          </p>
        </div>
      </label>
      <input
        className="focus:outline-none"
        type="file"
        name="files"
        id="files"
        accept={props.type}
        multiple={props.multiSelect}
        required={props.required}
        hidden
        onChange={async (e) => {
          if (e.target.files?.length) {
            props.onChange([...e.target.files]);
          }
        }}
      />
    </div>
  );
};

export default FileDropZone1;
