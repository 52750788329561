import {
  mdiCheck,
  mdiClose,
  mdiPencil,
  mdiDelete,
  mdiPlus,
  mdiChevronUp,
  mdiChevronDown,
  mdiTools,
  mdiDotsVertical,
  mdiChevronRight,
  mdiComment,
} from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useState } from "react";
import {
  useToast,
  ToastType,
} from "../../../../context_providers/toast/toast_ctx";
import {
  OpsTaskDeviceItem,
  OpsTaskDeviceItemResolution,
  OpsTaskDeviceItemStatus,
  getDefaultTaskDeviceItem,
} from "../../../../service/models/ops/ops_ticket";
import InventorySearchField from "./device_search_field";
import { InventoryModel } from "../../../../service/models/inventory/inventory_model";
import InventoryDropDown from "./inventory_dropdown";
import DropDownMenu from "../../content_panel/ui/new_components/common/drop_down_menu";
import ModalWidget from "../../../../context_providers/modal/modal_widget";
import {
  ModalAlignment,
  ModalType,
  useModal,
} from "../../../../context_providers/modal/modal_context";
import DeviceComponentWidget from "./device_item_component";
import { ItemModel } from "../../../../erp/inventory/models/item_model";
import { OpsTaskDeviceIssueType } from "../../../../utils/constant";
import DeviceRemarkWidget from "./device_item_remark_widget";

interface DeviceItemRowProps {
  data?: OpsTaskDeviceItem;
  onSubmit: (data: OpsTaskDeviceItem) => void;
  edit: boolean;
  EditEnable: boolean;
  disabled?: boolean;
  onDelete?: (i: OpsTaskDeviceItem) => void;
}

const DeviceItemRow: FunctionComponent<DeviceItemRowProps> = (props) => {
  const [data, setData] = useState<OpsTaskDeviceItem>({
    ...getDefaultTaskDeviceItem(),
  });
  const { showToast } = useToast();
  const { onClose, showModal } = useModal();
  const [showMore, setShowMore] = useState(false);
  const { edit, onSubmit } = props;

  const [editable, setEditable] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setEditable(edit);
  }, []);
  const onDone = () => {
    if (!data.device_id || !data.status) {
      showToast({
        type: ToastType.warning,
        text: "Please select device",
      });
      return;
    }
    onSubmit(data);
  };

  useEffect(() => {
    if (props.data) setData({ ...props.data });
  }, [props.data]);

  // const [showComponent, setshowComponent] = useState(false);

  return (
    <>
      {/* {showComponent && (
        <ModalWidget
          data={{
            title: "Components",
            id: 1,
            type: ModalType.drawer,
            alignment: ModalAlignment.right,
          }}
          onClose={function (): void {
            setshowComponent(false);
            onClose(1);
          }}
        ></ModalWidget>
      )} */}
      <form
        onSubmit={(e) => {
          e.preventDefault();

          onDone();
        }}
        className="bg-white   text-sm  "
      >
        <div className="grid grid-cols-9 gap-1">
          <div className="">
            <InventorySearchField
              disabled={!editable || props.disabled}
              value={{ device_id: data.device_id, name: data.name }}
              onSelect={function (val: InventoryModel): void {
                setData((o) => ({
                  ...o,
                  device_id: val.device_id,
                  name: val.device.product_name,
                }));
              }}
            />
          </div>

          <input
            type="text"
            value={data.name}
            disabled
            className="border rounded p-1 focus:outline-none outline-none col-span-2"
          />

          <select
            value={data.issue_type}
            disabled={!editable || props.disabled}
            className="border rounded p-1 focus:outline-none outline-none"
            onChange={(e) => {
              setData((o) => ({ ...o, issue_type: e.target.value }));
            }}
          >
            <option value="" hidden>
              Select
            </option>
            {Object.values(OpsTaskDeviceIssueType).map((o) => (
              <option value={o}>{o}</option>
            ))}
          </select>
          {/* <select
            value={data.issue_sub_type}
            disabled={!editable || props.disabled}
            className="border rounded p-1 focus:outline-none outline-none"
            onChange={(e) => {
              setData((o) => ({ ...o, issue_sub_type: e.target.value }));
            }}
          >
            <option value="" hidden>
              Select
            </option>
            {Object.values(OpsTaskDeviceItemResolution).map((o) => (
              <option value={o}>{o}</option>
            ))}
          </select> */}

          <input
            type="text"
            value={data.issue_sub_type}
            disabled={!editable || props.disabled}
            className="border rounded p-1 focus:outline-none outline-none"
            onChange={(e) => {
              setData((o) => ({ ...o, issue_sub_type: e.target.value }));
            }}
          />
          <input
            type="text"
            value={data.charger_state}
            disabled={true}
            className="border rounded p-1 focus:outline-none outline-none"
            onChange={(e) => {
              setData((o) => ({ ...o, test_txn_id: e.target.value }));
            }}
          />

          <select
            value={data.status}
            disabled={!editable || props.disabled}
            className="border rounded p-1 focus:outline-none outline-none"
            onChange={(e) => {
              setData((o) => ({ ...o, status: e.target.value }));
            }}
          >
            <option value="" hidden>
              Select
            </option>
            {Object.values(OpsTaskDeviceItemStatus).map((o) => (
              <option value={o}>{o}</option>
            ))}
          </select>
          <select
            value={data.resolution}
            disabled={!editable || props.disabled}
            className="border rounded p-1 focus:outline-none outline-none"
            onChange={(e) => {
              setData((o) => ({ ...o, resolution: e.target.value }));
            }}
          >
            <option value="" hidden>
              Select
            </option>
            {Object.values(OpsTaskDeviceItemResolution).map((o) => (
              <option value={o}>{o}</option>
            ))}
          </select>

          {/* <input
            type="text"
            value={data.remark}
            disabled={!editable || props.disabled}
            className="border rounded p-1 focus:outline-none outline-none col-span-2"
            onChange={(e) => {
              setData((o) => ({ ...o, remark: e.target.value }));
            }}
          /> */}

          <div className="flex gap-2 items-center justify-center ">
            {props.data ? (
              <>
                <div className="flex justify-center gap-2">
                  {editable ? (
                    <>
                      <button
                        type="button"
                        onClick={() => {
                          if (!window.confirm("Are you sure !")) return;
                          onDone();
                          setEditable(false);
                        }}
                        className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                      >
                        <Icon path={mdiCheck} className="h-4 w-4"></Icon>
                      </button>
                      <div
                        onClick={() => {
                          setData(props.data!);
                          setEditable(false);
                        }}
                        className="bg-red-100 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                      >
                        <Icon path={mdiClose} className="h-4 w-4"></Icon>
                      </div>
                    </>
                  ) : (
                    <DropDownMenu
                      open={showMore}
                      title={
                        <div className=" flex  px-1 text-blue-400">
                          More
                          <Icon path={mdiChevronRight} size={0.8} />
                        </div>
                      }
                      options={
                        <>
                          <button
                            disabled={props.disabled}
                            type="button"
                            onClick={() => {
                              setEditable(true);
                            }}
                            className="flex gap-1 p-1 rounded     hover:text-teal"
                          >
                            <Icon path={mdiPencil} className="h-4 w-4"></Icon>
                            Edit
                          </button>
                          <button
                            title="Components used"
                            type="button"
                            onClick={() => {
                              showModal({
                                title: "Components",

                                type: ModalType.drawer,
                                alignment: ModalAlignment.right,
                                container(i) {
                                  return (
                                    <DeviceComponentWidget
                                      editEnable={props.EditEnable}
                                      onCancel={() => {
                                        onClose(i);
                                      }}
                                      data={data.components}
                                      onSubmit={function (
                                        com: {
                                          item: ItemModel;
                                          qty: number;
                                          id: any;
                                        }[]
                                      ): void {
                                        props.onSubmit({
                                          ...data,
                                          components: com,
                                        });
                                        onClose(i);
                                      }}
                                    />
                                  );
                                },
                              });
                            }}
                            className=" flex gap-1 p-1 rounded     hover:text-teal"
                          >
                            <Icon path={mdiTools} className="h-4 w-4"></Icon>{" "}
                            Components
                          </button>
                          <button
                            title="Remarks"
                            type="button"
                            onClick={() => {
                              showModal({
                                title: "Remarks",

                                type: ModalType.drawer,
                                alignment: ModalAlignment.right,
                                container(i) {
                                  return (
                                    <DeviceRemarkWidget
                                      data={data.remark}
                                      onSubmit={function (
                                        remark: string
                                      ): void {
                                        setData((o) => ({ ...o, remark }));
                                      }}
                                      editEnable={props.EditEnable}
                                      onCancel={function (): void {
                                        onClose(i);
                                      }}
                                    />
                                  );
                                },
                              });
                            }}
                            className=" flex gap-1 p-1 rounded     hover:text-teal"
                          >
                            <Icon path={mdiComment} className="h-4 w-4"></Icon>{" "}
                            Remarks
                          </button>
                          <button
                            disabled={props.disabled}
                            type="button"
                            onClick={() => {
                              if (!window.confirm("Are you sure !")) return;
                              if (props.onDelete) props.onDelete(data);
                            }}
                            className="flex gap-1 p-1 rounded     hover:text-teal"
                          >
                            <Icon path={mdiDelete} className="h-4 w-4"></Icon>{" "}
                            Delete
                          </button>
                        </>
                      }
                    />
                  )}
                </div>
                {/* <button
                    title="Components used"
                    type="button"
                    onClick={() => {}}
                    className="bg-blue-200 p-1 rounded text-blue-800 hover:scale-110 transform duration-200"
                  >
                    <Icon path={mdiTools} className="h-4 w-4"></Icon>
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      if (!window.confirm("Are you sure !")) return;
                      if (props.onDelete) props.onDelete(data);
                    }}
                    className="bg-red-200 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                  >
                    <Icon path={mdiDelete} className="h-4 w-4"></Icon>
                  </button> */}
              </>
            ) : (
              <button
                type="submit"
                // form="item_form"
                className="flex gap-1 text-white text-sm bg-myPrimaryColor rounded-full px-2 py-1  "
              >
                <Icon path={mdiPlus} className="h-5 w-5 "></Icon> Add
              </button>
            )}
            {/* {props.data && (
              <div
                onClick={() => setShowMore((o) => !o)}
                className="p-1 bg-gray-100 rounded cursor-pointer hover:bg-gray-200"
              >
                <Icon
                  path={showMore ? mdiChevronUp : mdiChevronDown}
                  className="h-4 w-4"
                ></Icon>
              </div>
            )} */}
          </div>
        </div>
        {/* {showMore && props.item && (
          <div className="px-2 py-1">
            <hr className=" " />
            <div className="text-xs text-gray-400">
              <p>Id : {props.item.id}</p>
              <p>Extra : {rupeeFormat(props.item.addition_amt ?? 0)}</p>
              <p>Discount : {rupeeFormat(props.item.discount_amt ?? 0)}</p>
              <p>Paid : {rupeeFormat(props.item.paid ?? 0)}</p>
            </div>
            <div className="flex   justify-between gap-2 my-1">
              <p className="text-xs ">
                Created by :<br />
                <div className="flex gap-1">
                  <div className="">
                    <b>{props.item.created_by_name ?? "N/A"}</b>
                  </div>
                  <div className="">
                    {props.item.created_at
                      ? moment(props.item.created_at).format(
                          "DD MMM YYYY h:mm a"
                        )
                      : "--"}
                  </div>
                </div>
              </p>
              <p className="text-xs ">
                Updated by :<br />
                <div className="flex gap-1">
                  <div className="">
                    <b>{props.item.updated_by_name ?? "N/A"}</b>
                  </div>
                  <div className="">
                    {props.item.updated_at
                      ? moment(props.item.updated_at).format(
                          "DD MMM YYYY h:mm a"
                        )
                      : "--"}
                  </div>
                </div>
              </p>
            </div>
          </div>
        )} */}
      </form>
    </>
  );
};

export default DeviceItemRow;
