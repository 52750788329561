import { Tooltip } from "@material-ui/core";
import { mdiSwapVertical } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useState } from "react";
import { OrderModel } from "../../../../../service/models/orders/order_model";
import { OpsOperation } from "../../../../../utils/enums";
import EmployeeSearchWidget from "../../../../new_components/emp_search_widget";

interface AllotAddressEditWidgetProps {
  onClose: () => void;
  emps: {
    id: string;
    name: string;
    dep: string;
    sub_dep: string;
  }[];
  preData?: {
    contact_person: string;
    contact_person_mobile: string;
    address: string;
    state: string;
    city: string;
    pincode: string;
    emp_id: string;
    emp_name: string;
    operation: string;
  };
  onSubmit: (data: {
    contact_person: string;
    contact_person_mobile: string;
    address: string;
    state: string;
    city: string;
    pincode: string;
    emp_id: string;
    emp_name: string;
    operation: string;
  }) => void;
}
const defaultValue: {
  contact_person: string;
  contact_person_mobile: string;
  address: string;
  state: string;
  city: string;
  pincode: string;
  emp_id: string;
  emp_name: string;
  operation: string;
} = {
  contact_person: "",
  contact_person_mobile: "",
  address: "",
  state: "",
  city: "",
  pincode: "",
  emp_id: "",
  emp_name: "",
  operation: "",
};
const AllotAddressEditWidget: FunctionComponent<AllotAddressEditWidgetProps> = (
  props
) => {
  const [data, setData] = useState<{
    contact_person: string;
    contact_person_mobile: string;
    address: string;
    state: string;
    city: string;
    pincode: string;
    emp_id: string;
    emp_name: string;
    operation: string;
  }>(props.preData ?? defaultValue);
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        props.onSubmit(data);
      }}
    >
      {/* <Tooltip title="Copy detail from Billing profile">
        <h6 className="select-none flex gap-2 items-center text-sm my-2 hover:text-gray-800 font-semibold text-gray-500 cursor-pointer">
          Same as Billing profile ?{" "}
          <Icon path={mdiSwapVertical} className="w-4 h-4" />
        </h6>
      </Tooltip> */}
      <div className="text-sm grid grid-cols-1 md:grid-cols-3 gap-2 my-5">
        <div className="flex flex-col">
          <label htmlFor="cn">Contact Person Name</label>
          <input
            value={data.contact_person}
            onChange={(e) =>
              setData((pre) => ({ ...pre, contact_person: e.target.value }))
            }
            required
            id="cn"
            type="text"
            placeholder="Contact person name"
            className="rounded border focus:outline-none px-3 py-1"
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="cn">Contact Person Mobile</label>
          <input
            value={data.contact_person_mobile}
            onChange={(e) =>
              setData((pre) => ({
                ...pre,
                contact_person_mobile: e.target.value,
              }))
            }
            required
            id="cn"
            type="text"
            placeholder="Contact person mobile"
            className="rounded border focus:outline-none px-3 py-1"
          />
        </div>
        <div className="flex flex-col col-span-3">
          <label htmlFor="cn">Address</label>
          <textarea
            value={data.address}
            onChange={(e) =>
              setData((pre) => ({ ...pre, address: e.target.value }))
            }
            required
            rows={3}
            id="cn"
            placeholder="Installation/Delivery Address"
            className="rounded border focus:outline-none px-3 py-1"
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="city">City</label>
          <input
            value={data.city}
            onChange={(e) =>
              setData((pre) => ({ ...pre, city: e.target.value }))
            }
            required
            id="city"
            type="text"
            placeholder="City"
            className="rounded border focus:outline-none px-3 py-1"
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="state">State</label>
          <input
            value={data.state}
            onChange={(e) =>
              setData((pre) => ({ ...pre, state: e.target.value }))
            }
            required
            id="state"
            type="text"
            placeholder="State"
            className="rounded border focus:outline-none px-3 py-1"
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="pincode">Pincode</label>
          <input
            value={data.pincode}
            onChange={(e) =>
              setData((pre) => ({ ...pre, pincode: e.target.value }))
            }
            required
            id="pincode"
            type="text"
            placeholder="Pincode"
            className="rounded border focus:outline-none px-3 py-1"
          />
        </div>
      </div>
      <hr />

      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        {" "}
        <div className="flex flex-col">
          <label htmlFor="emp">Responsible Person</label>
          <EmployeeSearchWidget
            onSelect={(em) => {
              setData((pre) => {
                return {
                  ...pre,
                  emp_id: em.uid!,
                  emp_name: em.first_name + " " + em.last_name,
                };
              });
            }}
            emp={{ uid: data.emp_id, name: data.emp_name }}
            placeholder="Responsible presonal"
          />
          {/* <select
            required
            value={data.emp_id}
            onChange={(e) => {
              const emp = props.emps.find(
                (em) => em.id === parseInt(e.target.value)
              );
              setData((pre) => {
                return { ...pre, emp_id: emp!.id!, emp_name: emp!.name! };
              });
            }}
            name="emp"
            id="emp"
            className="rounded border focus:outline-none px-3 py-1"
          >
            <option value="" hidden>
              {" "}
              select ...
            </option>
            {props.emps.map((e) => (
              <option value={e.id}>{e.name}</option>
            ))}
          </select> */}
        </div>
        <div className="flex flex-col">
          <label htmlFor="task">Task</label>
          <select
            required
            value={data.operation}
            onChange={(e) => {
              setData((pre) => {
                return { ...pre, operation: e.target.value };
              });
            }}
            name="task"
            id="task"
            className="rounded border focus:outline-none px-3 py-1"
          >
            <option value="" hidden>
              {" "}
              select ...
            </option>
            {Object.values(OpsOperation).map((op) => (
              <option value={op}>{op}</option>
            ))}
          </select>
        </div>
      </div>
      <div className="flex my-5 justify-end gap-5">
        <button
          type="button"
          onClick={props.onClose}
          className="border rounded px-2 py-1"
        >
          Cancel
        </button>
        <button type="submit" className="border rounded px-2 py-1">
          Confirm
        </button>
      </div>
    </form>
  );
};

export default AllotAddressEditWidget;
