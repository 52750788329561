import { Tooltip } from "@material-ui/core";
import { mdiRefresh } from "@mdi/js";
import Icon from "@mdi/react";
import { parse } from "papaparse";
import { FunctionComponent, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { isProduction } from "../../../../..";
import { useAuth } from "../../../../../context_providers/auth/authProvider";
import {
  Role,
  UserModel,
} from "../../../../../context_providers/auth/user_model";
import {
  useToast,
  ToastType,
} from "../../../../../context_providers/toast/toast_ctx";
import { fetchGet } from "../../../../../service/api_client";
import { ApiResponse } from "../../../../../service/models/response";
import { GetRolesApi } from "../../../../../service/repos/roles_repo";

import {
  GetEmps,
  ImportEmpRepo,
  UpdateEmpStatusRepo,
} from "../../../../../service/repos/users_repo";
import { urls } from "../../../../../utils/urls";

import LoadingWidget from "../../../../components/loading_spinner";
import FileDropZone from "../../../../new_components/common/file_drop_zone";
import ModalDialog from "../../../../new_components/common/modal";
import Pagination from "../../../../new_components/common/pagination";
import ToggleBtn from "../../../../new_components/common/toggle_btn";
import SearchFieldWidget from "../../../../new_components/search_field";

interface UserTableWidgetProps {
  // onTxnClick: (id: number) => void;
  // onViewAttachment: (txn_id: number) => void;
  // onStatusChanged: (txn_id: number, status: number, order_id: number) => void;
}

const UserTableWidget: FunctionComponent<UserTableWidgetProps> = (props) => {
  const { showToast } = useToast();
  const { user, isAuthorised } = useAuth();
  const [users, setUsers] = useState<UserModel[]>([]);
  const [active, setActive] = useState(true);
  const [selectedUser, setSelectUser] = useState<UserModel>();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(50);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [roles, setRoles] = useState<Role[]>([]);
  // const [loading, setLoading] = useState(false);
  const history = useHistory();
  useEffect(() => {
    getRoles();
  }, []);
  const getRoles = async () => {
    try {
      setLoading(true);

      const res = await GetRolesApi({ all: true });
      if (res.success) {
        setRoles(res.data.data);
      } else {
        showToast({ type: ToastType.error, text: res.error ?? "" });
      }
      setLoading(false);
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error ?? "" });
    }
  };
  const handleClick = (user: UserModel) => {
    history.push(urls.userEdit, { user, roles });
  };

  const handleEmpStatus = async (uid: number, active: boolean) => {
    const res = await UpdateEmpStatusRepo({
      uid: uid,
      active,
    });
    if (res.success) {
      setUsers((old) =>
        old.map((u) => {
          if (u.uid === uid) u.emp_profile!.active = active;
          return u;
        })
      );
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
  };
  const getUsers = async () => {
    setLoading(true);
    const res: ApiResponse = await GetEmps({ page, count, search, active });
    console.log({ res });

    if (res.success) {
      setUsers(res.data.data);
      if (res.data.metadata) setTotal(res.data.metadata.total);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  useEffect(() => {
    getUsers();
  }, [page, search, active]);

  const onImport = async (data: any) => {
    setLoading(true);

    const form = new FormData();
    form.append("data", JSON.stringify(data));
    const res = await ImportEmpRepo(form);

    if (res.success) {
      const data = res.data;
      // if (data.created.length > 0) {
      getUsers();
      // setUsers((o) => [...data.createdEmp, ...data.updatedEmp, ...o]);
      setShowImport(false);
      // }
      if (data.updatedEmp.length > 0) {
        showToast({
          type: ToastType.success,
          text: "Employees updated " + `[${data.updatedEmp}]`,
        });
      }
    } else {
    }
    setLoading(false);
  };
  const heads = [
    "",
    "Name",
    "Email",
    "Mobile",
    "Designation",
    "Department",
    "Sub Department",
    "HOD",
    "Reporting Manager",
    "Permission",
    "status",
    "Action",
  ];
  const [showImport, setShowImport] = useState(false);

  const login = async (uid: string) => {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/employees/${uid}/token`;
    const res = await fetchGet(url);

    if (res.success) {
      window.localStorage.setItem("token", res.data);
      window.location.reload();
      // const windowFeatures = "location=yes,scrollbars=yes,status=yes";

      // let win = window.open("https://erp.kazam.in", "_blank", windowFeatures);
      // if (win) {
      //   console.log(win.localStorage);
      //   win.localStorage.clear();
      //   console.log(win.localStorage);
      //   win.localStorage.setItem("token", res.data);
      //   win.location.reload();
      // }
    } else {
      showToast({
        type: ToastType.error,
        text: res.error,
      });
    }
  };
  return (
    <div className="h-full">
      {selectedUser !== undefined && (
        <ModalDialog
          show={selectedUser !== undefined}
          onClose={() => setSelectUser(undefined)}
          title=" "
        >
          <div className="w-96 h-auto">
            <img src={selectedUser.profile_image} alt="" />
          </div>
        </ModalDialog>
      )}
      {showImport && (
        <ModalDialog
          show={showImport}
          onClose={() => setShowImport(false)}
          title="Import Employees "
        >
          <div className="text-xs w-96">
            <h2 className="font-semibold">Please note :</h2>
            <p>sheet should have header as</p>
            <p className="font-semibold text-purple-500">
              [emp_id ,first_name, last_name, mobile, email, department,
              sub_dep, designation, dob, doj, dol, parmanent_address, address,
              pp_month, zone, hod, reporting_manager, isHod, isReportingManager,
              active ]
            </p>
            <p>In the first row of csv.</p>
          </div>
          <div className="h-96 w-96 mt-5">
            <FileDropZone
              accept=".csv"
              onDrop={(files) => {
                parse<any>(files[0], {
                  header: true,
                  skipEmptyLines: true,
                  complete: (data) => {
                    const res = data.data;
                    if (res.length === 0) {
                      showToast({
                        type: ToastType.info,
                        text: "No record found !",
                      });
                      return;
                    }
                    const keys = Object.keys(res[0]);
                    if (keys.indexOf("email") === -1) {
                      showToast({
                        type: ToastType.error,
                        text: "Email required !",
                      });
                    }
                    const filteredData: any[] = [];
                    for (let index = 0; index < res.length; index++) {
                      const {
                        emp_id,
                        first_name,
                        last_name,
                        mobile,
                        email,
                        department,
                        sub_dep,
                        designation,
                        dob,
                        doj,
                        pp_month,
                        zone,
                        active,
                        isHod,
                        isReportingManager,
                        hod,
                        reporting_manager,
                      } = res[index];
                      if (!email) {
                        showToast({
                          type: ToastType.error,
                          text: "Missing required data !",
                        });

                        return;
                      }

                      filteredData.push({
                        emp_id,
                        first_name,
                        last_name,
                        mobile,
                        email,
                        department,
                        sub_dep,
                        designation,
                        dob,
                        doj,
                        pp_month,
                        zone,
                        active,
                        isHod,
                        isReportingManager,
                        hod,
                        reporting_manager,
                      });
                    }

                    // Bulk upload
                    setShowImport(false);
                    onImport(filteredData);
                  },
                });
              }}
            />
          </div>
        </ModalDialog>
      )}
      <div className="flex justify-between items-end flex-wrap my-3">
        <h1 className="font-bold text-2xl text-gray-500 flex items-center gap-3">
          Users
          <button onClick={() => getUsers()}>
            <Icon path={mdiRefresh} className="h-6 w-6"></Icon>
          </button>
        </h1>
        <div className="flex gap-2">
          <SearchFieldWidget
            onClear={() => setSearch("")}
            onSubmit={(val) => {
              setPage(1);
              setSearch(val);
            }}
          />
          <div
            title={active ? "Active employees" : "Inactive employees"}
            className="flex rounded-3xl bg-white dark:bg-darkBgcolor p-1    "
          >
            <div
              onClick={() => setActive(true)}
              className={`px-4 py-1 duration-100 cursor-pointer ${
                active ? "bg-myPrimaryColor text-white rounded-3xl" : ""
              }`}
            >
              Active
            </div>
            <div
              onClick={() => setActive(false)}
              className={`px-2 py-1 duration-100 cursor-pointer ${
                !active ? "bg-myPrimaryColor text-white rounded-3xl" : ""
              }`}
            >
              Inactive
            </div>
          </div>
          {/* <button
            onClick={() => setShowImport(true)}
            className="rounded-lg border px-2 py-1 hover:bg-gray-800 hover:text-white"
          >
            Import
          </button> */}
        </div>
      </div>
      <div className=" overflow-auto " style={{ height: "85%" }}>
        <table className="border divide-y divide-gray-200 relative w-full">
          <thead className="bg-gray-200 sticky  top-0 z-10">
            <tr>
              {heads.map((h) => (
                <th
                  key={h}
                  scope="col"
                  className="px-3 py-2 text-xs font-medium text-center text-black uppercase tracking-wider"
                >
                  {h}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200 max-h-96 mx-2 text-xs">
            {users.map((user) => {
              const myRoles =
                user.emp_profile?.roles && user.emp_profile.roles.length > 0
                  ? roles
                      .filter((role) =>
                        user.emp_profile?.roles?.includes(role.id)
                      )
                      .map((p) => p.title)
                      ?.join(" | ")
                  : "--";

              return (
                <tr
                  className="hover:bg-gray-200"
                  key={user.uid}
                  // onDoubleClick={() => props.onTxnClick(txn.id!)}
                >
                  <td className="px-3 py-2 whitespace-nowrap text-xs font-medium text-gray-900">
                    <div>
                      <div
                        className={`${
                          user.emp_profile?.active
                            ? "bg-green-400"
                            : "bg-red-400"
                        } p-1 rounded-full w-11 h-11 flex justify-center items-center`}
                      >
                        {user.profile_image && (
                          <img
                            onClick={() => setSelectUser(user)}
                            src={user.profile_image}
                            alt=""
                            className="h-10 w-10 rounded-full cursor-pointer"
                          />
                        )}
                      </div>
                    </div>
                  </td>
                  <td className="px-3 py-2 whitespace-nowrap text-xs font-medium text-gray-900">
                    {user.first_name + " "} {user.last_name}
                  </td>
                  <td className="px-3 py-2 whitespace-nowrap  ">
                    {user.email && user.email !== "NULL" ? user.email : "--"}
                  </td>
                  <td className="px-3 py-2 whitespace-nowrap  ">
                    {user.mobile && user.mobile !== "NULL" ? user.mobile : "--"}
                  </td>
                  <td className="px-3 py-2 whitespace-nowrap  ">
                    {user.emp_profile?.designation &&
                    user.emp_profile?.designation !== "NULL"
                      ? user.emp_profile?.designation
                      : "--"}
                  </td>
                  <td className="px-3 py-2 whitespace-nowrap  ">
                    {user.emp_profile?.department &&
                    user.emp_profile?.department !== "NULL"
                      ? user.emp_profile?.department
                      : "--"}
                  </td>
                  <td className="px-3 py-2 whitespace-nowrap  ">
                    {user.emp_profile?.sub_dep &&
                    user.emp_profile?.sub_dep !== "NULL"
                      ? user.emp_profile?.sub_dep
                      : "--"}
                  </td>
                  <td className="px-3 py-2 whitespace-nowrap  ">
                    {user.emp_profile?.hod_name &&
                    user.emp_profile?.hod_name !== "NULL"
                      ? user.emp_profile?.hod_name
                      : "--"}
                  </td>
                  <td className="px-3 py-2 whitespace-nowrap  ">
                    {user.emp_profile?.reporting_to_name &&
                    user.emp_profile?.reporting_to_name !== "NULL"
                      ? user.emp_profile?.reporting_to_name
                      : "--"}
                  </td>
                  <td className="px-3 py-2 whitespace-nowrap  font-medium text-gray-900 ">
                    {/* <Tooltip title={roles}> */}
                    <div
                      title={myRoles}
                      className="max-w-xs  overflow-hidden overflow-ellipsis"
                    >
                      {myRoles}
                    </div>
                    {/* </Tooltip> */}
                  </td>{" "}
                  <td align="right">
                    <ToggleBtn
                      value={user.emp_profile?.active || false}
                      onChange={function (v: boolean): void {
                        const sure = window.confirm(
                          `Are you sure to change ${user.first_name} ${user.last_name} to ` +
                            (v ? "active" : "deactive")
                        );
                        if (sure) {
                          handleEmpStatus(user.uid!, v);
                        }
                      }}
                    />
                    {/* <select
                      id="status"
                      name="status"
                      className="  p-2 m-2   w-ful"
                      required
                      value={user.active ? "true" : "false" ?? ""}
                      onChange={(e) => {
                        const sure = window.confirm(
                          "Are you sure to change user status to :" +
                            (e.target.value === "true" ? "active" : "deactive")
                        );
                        if (sure) {
                          handleEmpStatus(user.uid!, e.target.value === "true");
                        }
                      }}
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      <option value={"true"}>Active</option>
                      <option value={"false"}>Deactive</option>
                    </select> */}
                  </td>
                  <td align="center" className="p-2">
                    <div className="flex gap-2">
                      <div
                        onClick={() => handleClick(user)}
                        className="text-blue-300 cursor-pointer hover:text-red-600"
                      >
                        Edit
                      </div>
                      {user.email == "hassain@kazam.in" && (
                        <div
                          onClick={() => login(user.uid as any)}
                          className="text-blue-300 cursor-pointer hover:text-red-600"
                        >
                          Login
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="mt-3">
        <Pagination
          itemCount={users.length}
          page={page}
          total={total}
          count={count}
          onChange={(p) => {
            setPage(p);
            // dispatch(GoToPageTxnThunk({ page: p, count }));
          }}
        />
      </div>{" "}
      <LoadingWidget loading={loading} />
    </div>
  );
};

export default UserTableWidget;
