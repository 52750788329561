import { FunctionComponent, useEffect, useState } from "react";
// @ts-ignore
import gsap from "gsap/all";
import { GetMood, PostMood } from "../../../../../service/repos/hrm/hrm_repo";
import { Tooltip } from "@material-ui/core";
import { useAuth } from "../../../../../context_providers/auth/authProvider";

interface MoodBlockProps {}

const MoodBlock: FunctionComponent<MoodBlockProps> = () => {
  const { user, isAuthorised } = useAuth();

  const [mood, setMood] = useState<boolean>(true);
  const [moodData, setMoodData] = useState<
    {
      response?: string;
      uid?: string;
      name?: string;
      img?: string;
      week?: number;
      year?: number;
    }[]
  >([]);

  const animateData = () => {
    const tl = gsap.timeline({ delay: 2 });
    tl.from(".bar", 1, {
      width: 0,
      stagger: 0.5,
    });
  };

  const getMood = async () => {
    const res = await GetMood();

    if (res.success) {
      const findTemp = res.data.find((el: any) => el.uid === user?.uid);
      if (findTemp) {
        setMood(false);
      }
      setMoodData(res.data);
    }
  };

  const postMood = async (response: any) => {
    setMood(false);
    const data = {
      response: response,
    };
    const res = await PostMood(data);

    if (res.success) {
      setMood(false);
      setMoodData((o) => [...o, res.data]);
    } else {
      setMood(true);
    }
  };

  useEffect(() => {
    getMood();
  }, []);

  useEffect(() => {
    animateData();
  }, []);

  return (
    <div className=" bg-white dark:bg-hrmCardDark rounded-t-2xl py-4 px-3">
      <div className="2xl:text-lg dark:text-white font-semibold">
        {mood ? "Select your mood" : "Mood of the organization this week"}
      </div>
      {mood ? (
        <div className="flex gap-2 mt-4">
          <Tooltip title="Great">
            <div
              className="w-12 h-12 cursor-pointer"
              onClick={() => postMood("great")}
            >
              <img src="/hrm-icons/great.png" alt="" className="" />
            </div>
          </Tooltip>
          <Tooltip title="Okay">
            <div
              className="w-16 h-16 cursor-pointer"
              onClick={() => postMood("okay")}
            >
              <img src="/hrm-icons/okay.png" alt="" className="" />
            </div>
          </Tooltip>

          <Tooltip title="Bad">
            <div
              className="w-16 h-16 cursor-pointer"
              onClick={() => postMood("bad")}
            >
              <img src="/hrm-icons/bad.png" alt="" className="" />
            </div>
          </Tooltip>
        </div>
      ) : (
        <div className="flex gap-4 mt-4">
          <Tooltip title="Having a great mood">
            <div className="flex flex-col items-center">
              <div className="w-12 h-12 cursor-pointer">
                <img src="/hrm-icons/great.png" alt="" className="" />
              </div>
              <div className="text-sm text-secondaryColor dark:text-primaryColor mt-1">
                {
                  moodData?.filter(
                    (el: {
                      response?: string;
                      uid?: string;
                      name?: string;
                      img?: string;
                      week?: number;
                      year?: number;
                    }) => el.response === "great"
                  ).length
                }
              </div>
            </div>
          </Tooltip>
          <Tooltip title="Mood is okay">
            <div className="flex flex-col items-center">
              <div className="w-12 h-12 cursor-pointer">
                <img src="/hrm-icons/okay.png" alt="" className="" />
              </div>
              <div className="text-sm text-secondaryColor dark:text-primaryColor mt-1">
                {
                  moodData?.filter(
                    (el: {
                      response?: string;
                      uid?: string;
                      name?: string;
                      img?: string;
                      week?: number;
                      year?: number;
                    }) => el.response === "okay"
                  ).length
                }
              </div>
            </div>
          </Tooltip>
          <Tooltip title="Having a bad mood">
            <div className="flex flex-col items-center">
              <div className="w-12 h-12 cursor-pointer">
                <img src="/hrm-icons/bad.png" alt="" className="" />
              </div>
              <div className="text-sm text-secondaryColor dark:text-primaryColor mt-1">
                {
                  moodData?.filter(
                    (el: {
                      response?: string;
                      uid?: string;
                      name?: string;
                      img?: string;
                      week?: number;
                      year?: number;
                    }) => el.response === "bad"
                  ).length
                }
              </div>
            </div>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default MoodBlock;

interface ProgressProps {
  percent: number;
  className: string;
}

const Progress: FunctionComponent<ProgressProps> = ({ percent, className }) => {
  return (
    <div className="w-full flex items-center gap-2">
      <div
        className={`bar h-1 ${className} rounded-2xl `}
        style={{ width: `${percent}%` }}
      ></div>

      <div className="w-1/5 text-secondaryColor text-xs">20</div>
    </div>
  );
};
