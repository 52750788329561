import { debounce } from "lodash";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import {
  ToastType,
  useToast,
} from "../../../../context_providers/toast/toast_ctx";
import { CustomerDetailModel } from "../../../../service/models/orders/customer_detail_model";
import { GetCustomersOfOrderApi } from "../../../../service/repos/order_repo";

import AutoCompleteField from "../../../new_components/common/autocomplete";

interface OrderDropDownProps {
  onSelect: (val: {
    order_id: string;
    order_date: string;
    total: number;
    customer: CustomerDetailModel;
  }) => void;

  placeholder?: string;
  order_id?: string;
  disabled?: boolean;
  withBtn?: boolean;
}

const OrderDropDown: FunctionComponent<OrderDropDownProps> = ({
  order_id,
  onSelect,
  placeholder,

  withBtn = true,
  disabled = false,
}) => {
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState("");
  const [edit, setEdit] = useState(false);
  useEffect(() => {
    setSearch(order_id || "");
  }, [order_id]);
  useEffect(() => {
    if (search) {
      if (order_id && order_id === search) return;
      debouncedHandler(search);
      // debounce(() => {
      //   searchEmp();
      // });
    }
  }, [search]);
  const [data, setData] = useState<
    {
      id: "";
      order_id: string;
      order_date: string;
      total: number;
      customer: CustomerDetailModel;
    }[]
  >([]);

  const searchHost = async (str: string) => {
    setLoading(true);
    const res = await GetCustomersOfOrderApi({
      search: str,
      page: 1,
      count: 100,
      all: true,
    });

    if (res.success) {
      setData(res.data.data);
    } else {
      setData([]);
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };

  const debouncedHandler = useCallback(
    debounce((query) => {
      if (query) searchHost(query);
    }, 500),
    []
  );
  // useEffect(() => {
  //   if (search) {
  //     debouncedHandler(search);
  //   } else setData([]);
  // }, [search]);
  return (
    <div className="flex gap-1 items-center    w-full">
      {edit ? (
        <AutoCompleteField
          loading={loading}
          value={search}
          placeholder={placeholder ?? "search order id"}
          onSelect={(val) => {
            setEdit(false);
            setSearch(val.value.order_id);
            onSelect(val.value);
          }}
          suggestions={data.map((e) => ({
            label: (
              <div
                style={{ fontSize: "10px" }}
                className=" flex flex-col  flex-wrap border-b"
              >
                <b> {e.order_id} </b>
                <p>
                  {e.customer.firstName} {e.customer.lastName}
                </p>

                <div className="">{e.customer.mobile} </div>
                <p>
                  <span style={{ fontSize: "10px" }}>
                    <b>{e.customer.address.city}</b>
                  </span>
                </p>
              </div>
            ),

            value: e,
          }))}
          onChange={(str) => {
            setSearch(str);
          }}
        />
      ) : (
        <div
          className="text-sm px-3 py-2  h-8 rounded focus:outline-none w-full border"
          onClick={() => {
            if (!disabled) {
              setEdit(true);
              // setSearch(emp?.name ?? "");
            }
          }}
        >
          {search || placeholder}
        </div>
      )}
      {/* {withBtn && !disabled && (
        <div
          className="text-xs cursor-pointer "
          onClick={() => {
            if (edit) {
              setSearch(order_id ?? "");
            }

            setEdit((o) => !o);
          }}
        >
          {edit ? <p>Cancel</p> : <p>Edit</p>}{" "}
        </div>
      )} */}
    </div>
  );
};

export default OrderDropDown;
