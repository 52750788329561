// file = Html5QrcodePlugin.jsx
import { Html5Qrcode, Html5QrcodeScanner } from "html5-qrcode";

import { FunctionComponent, useEffect, useRef, useState } from "react";
import QrScanLottieIcon from "../../../../../new_components/common/lottie/qr_scanner_icon";

interface Html5QrcodePluginProps {
  qrCodeSuccessCallback: (decodedText: string, decodedResult: any) => void;
  qrCodeErrorCallback?: (e: any) => void;
}

const Html5QrcodePlugin: FunctionComponent<Html5QrcodePluginProps> = (
  props
) => {
  const qrCodeId = "qr-code-scanner";
  // const [flashOn, setFlashOn] = useState(false);
  const ref = useRef<Html5QrcodeScanner>();

  useEffect(() => {
    const qrScanner = new Html5Qrcode(qrCodeId);

    qrScanner.start(
      { facingMode: "environment" },
      {
        fps: 10,
        aspectRatio: 1,
      },
      props.qrCodeSuccessCallback,
      props.qrCodeErrorCallback
    );
    return () => {
      if (qrScanner.isScanning) {
        qrScanner
          .stop()
          .then(() => {
            qrScanner.clear();
          })
          .catch((e) => console.log(e));
      } else qrScanner.clear();
    };
  }, []);

  // useEffect(() => {
  //   const html5QrcodeScanner = new Html5QrcodeScanner(
  //     qrCodeId,
  //     {
  //       fps: 10,
  //       aspectRatio: 1,
  //       showTorchButtonIfSupported: true,
  //       formatsToSupport: [0],
  //       rememberLastUsedCamera: true,
  //     },
  //     false
  //   );

  //   html5QrcodeScanner.render(
  //     props.qrCodeSuccessCallback,
  //     props.qrCodeErrorCallback
  //   );
  //   return () => {
  //     html5QrcodeScanner.clear().catch((error) => {
  //       console.error("Failed to clear html5QrcodeScanner. ", error);
  //     });
  //   };
  // }, []);

  return (
    <div className="  relative  flex flex-col items-center justify-center">
      <div id={qrCodeId} ref={ref as any} className="h-full w-full" />
      <div className="absolute">
        <QrScanLottieIcon />
      </div>
      {/* <button className="absolute bottom-1 right-1 rounded-full flex items-center mr-2 mt-auto   bg-gray-100   p-2">
        <Icon path={mdiFlashlight} size={1.2} />
      </button> */}
    </div>
  );
};

export default Html5QrcodePlugin;
