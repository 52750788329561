// import { EmployeeDataModel } from "../service/models/user_model";
// import { isAuthorised } from "../store/authReducer/actions";
// import { ACTION, MODULE } from "./enums";

import {
  mdiAccount,
  mdiAccountGroup,
  mdiAccountMultiplePlus,
  mdiAccountPlus,
  mdiAlphaSCircleOutline,
  mdiApi,
  mdiArrowLeft,
  mdiBike,
  mdiBookCheckOutline,
  mdiBookClock,
  mdiBookMultipleOutline,
  mdiBox,
  mdiBriefcase,
  mdiCalendarCheck,
  mdiCalendarClock,
  mdiCar,
  mdiCartPlus,
  mdiCash,
  mdiChat,
  mdiChatPlus,
  mdiCheck,
  mdiCloudPrint,
  mdiCollage,
  mdiContentSavePlusOutline,
  mdiDoctor,
  mdiEvStation,
  mdiFileDocument,
  mdiFileDocumentPlus,
  mdiFileMultiple,
  mdiFormatListCheckbox,
  mdiHome,
  mdiHumanDolly,
  mdiImagePlusOutline,
  mdiInboxFull,
  mdiOfficeBuildingMarker,
  mdiPostOutline,
  mdiReceipt,
  mdiShieldKey,
  mdiShieldPlus,
  mdiShoppingOutline,
  mdiTextBoxMultiple,
  mdiTextBoxMultipleOutline,
  mdiTextBoxPlusOutline,
  mdiTicketConfirmation,
  mdiToolboxOutline,
  mdiTrayArrowUp,
  mdiViewDashboard,
  mdiWarehouse,
} from "@mdi/js";
import Icon from "@mdi/react";
import { ACTION, MODULE } from "./enums";
import { Dashboard } from "@material-ui/icons";

const root = "/";
// export interface URLS {
//   [key: string]: string;
// }
export let urls = {
  root: `${root}`,

  mediaPage: `${root}media`,
  orderList: `${root}orders`,
  customers: `${root}customers`,
  create_customer: `${root}customers/create`,
  customer_detail: `${root}customers/detail`,
  dashboard: `${root}dashboard`,
  login: `${root}login`,
  notAllowed: `${root}not-allowed`,
  users: `${root}users`,
  roles: `${root}access-panel/roles`,
  createRole: `${root}access-panel/create-role`,
  editRole: `${root}access-panel/edit-role`,
  userEdit: `${root}access-panel/edit-detail`,
  userCreate: `${root}access-panel/user-create`,
  createOrder: `${root}create-order`,
  orderDetail: `${root}order-detail`,
  transaction: `${root}transaction`,
  invoice: `${root}invoice`,
  transactionDetail: `${root}transaction-detail`,
  test: `${root}test`,
  kpi_dash: `${root}kpi-dash`,
  complaints: `${root}complaints`,
  create_complaint: `${root}complaints/create`,
  complaint_detail: `${root}complaints/detail`,
  complaint_word_cloud: `${root}complaints/word_cloud`,
  compliant_insights:`${root}complaints/complaint-insights`,
  visit_listing: `${root}sales-force/visit`,
  visit_create: `${root}sales-force/visit-create`,
  lead_listing: `${root}sales-force/lead`,
  lead_create: `${root}sales-force/lead-create`,
  proposal_listing: `${root}sales-force/proposal`,
  props_create: `${root}sales-force/proposal-create`,
  salesTarget: `${root}sales-target`,
  inventory_overview: `${root}inventory/overview`,
  inventory_listing: `${root}inventory`,
  delivery_note: `${root}delivery_note`,
  warehouse_listing: `${root}warehouse`,
  host_listing: `${root}host`,
  installed_device: `${root}installed_device`,
  device_alloction: `${root}device_allocation`,
  ops_tasks: `${root}ops_tasks`,
  ops_tickets: `${root}ops_tickets`,
  emp_basket: `${root}emp_basket`,
  operation_task: `${root}operation-tasks`,
  operation_engineer_task: `${root}operation/engineer/tasks`,
  // create_operation_task: `${root}operation_task/create`,
  // operation_task_detail: `${root}operation_task/detail`,
  my_operation_task: `${root}my_operation_task`,
  my_recent_operation_task: `${root}my_recent_operation_task`,
  my_operation_task_detail: `${root}my_operation_task/detail`,
  emp_attendance: `${root}my_operation_task/attendance`,
  emp_attendace_history: `${root}my_operation_task/attendace_history`,
  ops_task_session: `${root}operation_task/sessions`,
  ops_admin_view: `${root}operation/admin`,

  hrm_home: `${root}hrm/home`,
  hrm_leave_application: `${root}hrm/leave-application`,
  hrm_overview: `${root}hrm/overview`,
  hrm_dept: `${root}hrm/departments`,
  hrm_dept_emp: `${root}hrm/departments/:dept`,
  hrm_my_profile: `${root}hrm/my-profile`,
  hrm_profile: `${root}hrm/profile/detail`,
  hrm_gmc: `${root}hrm/profile/gmc/:uid`,
  hrm_docs: `${root}hrm/profile/docs/:uid`,
  hrm_my_docs: `${root}hrm/profile/my-docs/:uid`,
  hrm_pf: `${root}hrm/profile/pf/:uid`,
  hrm_personalInfo: `${root}hrm/profile/personal-info/:uid`,
  hrm_leaves: `${root}hrm/profile/leaves/:uid`,
  hrm_leaves_edit: `${root}hrm/profile/edit-leaves`,
  hrm_leave_data: `${root}hrm/leave-data`,
  hrm_birthday: `${root}hrm/birthday`,
  hrm_work_anniversary: `${root}hrm/work-anniversary`,
  hrm_policies: `${root}hrm/policies`,
  hrm_gmc_policy: `${root}hrm/gmc-policy`,
  hrm_files_upload: `${root}hrm/files-upload`,
  hrm_organization_tree: `${root}hrm/organization-tree`,
  hrm_memories: `${root}hrm/memories`,
  hrm_memories_view: `${root}hrm/memories-view`,
  hrm_org_tree_tab: `${root}hrm/org-tree-tab`,
  hrm_hr_leave_record: `${root}hrm/create-leave_record`,
  hrm_hr_leave_panel: `${root}hrm/hr-leave-panel`,
  hrm_job_portal: `${root}hrm/job-portal`,
  hrm_create_job: `${root}hrm/create-job`,
  hrm_add_employee: `${root}hrm/add_employee`,
  hrm_apply_job: `${root}hrm/apply-job/:id/:ref_id`,
  hrm_job_applicants: `${root}hrm/job-applicants`,
  hrm_job_applicants_view: `${root}hrm/job-applicants-view/:id`,
  hrm_analytics: `${root}hrm/analytics`,
  hrm_employee_map: `${root}hrm/analytics/employee-map`,

  master_data: `${root}master-data`,
  master_data_warehouse: `${root}master-data/warehouse`,
  master_data_warehouse_create: `${root}master-data/warehouse/create`,
  master_data_warehouse_update: `${root}master-data/warehouse/update`,

  kazam_academy: `${root}kazam-academy`,
  protocol_category: `${root}kazam-academy/protocol-category`,
  videos_category: `${root}kazam-academy/videos-category`,
  upload_academy: `${root}kazam-academy/upload-video`,
  saved_videos: `${root}kazam-academy/saved-videos`,
  watched_videos: `${root}kazam-academy/watched-videos`,
  installation_protocol: `${root}kazam-academy/installation-protocol`,
  saas_protocol: `${root}kazam-academy/saas-protocol`,
  complaint_segment: `${root}kazam-academy/complaint-segment`,
  complaint_protocol: `${root}kazam-academy/complaint-segment/complaint-protocol`,
  inside_sales_protocol: `${root}kazam-academy/inside-sales-protocol`,
  sales_training_protocol: `${root}kazam-academy/sales-training-protocol`,
  hub_creation_checklist: `${root}kazam-academy/hub-creation-checklist`,
  kazam_warranty: `${root}kazam-academy/kazam-warranty`,

  kazam_learning: `${root}kazam-academy/kazam-learning`,
  general_learning: `${root}kazam-academy/videos/general`,
  cms_learning: `${root}kazam-academy/kazam-learning/cms`,
  podcast_learning: `${root}kazam-academy/kazam-learning/podcasts`,
  ev_news: `${root}kazam-academy/kazam-learning/ev-news`,
  ev_reviews: `${root}kazam-academy/kazam-learning/ev-reviews`,
  tally_learning: `${root}kazam-academy/kazam-learning/tally`,
  sales_learning: `${root}kazam-academy/kazam-learning/sales`,
  company_updates: `${root}kazam-academy/kazam-learning/company-updates`,
  hr_policies: `${root}kazam-academy/kazam-learning/hr-policies`,
  life_at_kazam: `${root}kazam-academy/kazam-learning/life-at-kazam`,
  startup_gyaan: `${root}kazam-academy/kazam-learning/startup-gyaan`,
  success_story: `${root}kazam-academy/kazam-learning/success-stories`,
  api_client_list: `${root}api-clients/all`,
  api_client_my_list: `${root}api-clients`,
  content_panel: `${root}content-panel`,
  // uploadImage: `${root}content-panel/upload-image`,
  // content_blogs: `${root}content-panel/blogs`,
  // content_blogs_create: `${root}content-panel/blogs/create`,
  // content_blogs_edit: `${root}content-panel/blogs/:blog_id`,
  // content_blogs_author: `${root}content-panel/authors`,
  // content_blogs_author_create: `${root}content-panel/authors/create`,
  // content_blogs_author_edit: `${root}content-panel/authors/:id`,

  // vehicles: `${root}content-panel/vehicles`,
  // varientListing: `${root}content-panel/vehicles/varients`,
  // priceListing: `${root}content-panel/vehicles/varients/prices`,
  // galleryListing: `${root}content-panel/vehicles/varients/gallery`,
  // createVehicle: `${root}content-panel/create-vehicle`,
  // updateVehicle: `${root}content-panel/update-vehicle`,

  // installation: `${root}content-panel/installation`,
  // installationCreate: `${root}content-panel/installation-create`,
  // installationEdit: `${root}content-panel/installation-editor`,

  Dashboard:`${root}Dashboard`,

  blogCreate: `${root}content-panel/blogs/create`,
  blogUpdate: `${root}content-panel/blogs/update`,
  blogListing: `${root}content-panel/blogs`,
  uploadImage: `${root}content-panel/upload-image`,

  installation: `${root}content-panel/installation`,
  installationCreate: `${root}content-panel/installation-create`,
  installationEdit: `${root}content-panel/installation-editor`,
  magazine: `${root}content-panel/magazine`,
  magazineCreate: `${root}content-panel/magazine-create`,
  magazineEdit: `${root}content-panel/magazine-editor`,

  vehicleListing: `${root}content-panel/vehicle-listings`,
  vehicleCreate: `${root}content-panel/vehicle-listings/create`,
  bikeSpec: `${root}content-panel/vehicle-listings/bike/specifications/:id`,
  bikePricing: `${root}content-panel/vehicle-listings/bike/pricing/:id`,
  bikeImages: `${root}content-panel/vehicle-listings/bike/images/:id`,
  authors: `${root}content-panel/authors`,
  authorCreate: `${root}content-panel/author-create`,
  authorEdit: `${root}content-panel/author-edit`,
  vehicles: `${root}content-panel/vehicles`,
  varientListing: `${root}content-panel/vehicles/varients`,
  priceListing: `${root}content-panel/vehicles/varients/prices`,
  galleryListing: `${root}content-panel/vehicles/varients/gallery`,
  createVehicle: `${root}content-panel/create-vehicle`,
  updateVehicle: `${root}content-panel/update-vehicle`,
  gallery: `${root}content-panel/gallery`,

  procurement: `${root}procurement`,
  procurement_masters: `${root}procurement/masters`,
  procurement_masters_ledger_groups: `${root}procurement/masters/ledger-groups`,
  procurement_masters_ledgers: `${root}procurement/masters/ledgers`,
  procurement_masters_inventory_bucket:`${root}procurement/masters/inventory-bucket`,
  procurement_masters_party: `${root}procurement/masters/party`,
  procurement_masters_item_groups: `${root}procurement/masters/item-groups`,
  procurement_masters_item: `${root}procurement/masters/item`,
  procurement_masters_item_unit: `${root}procurement/masters/item_unit`,
  procurement_vouchers: `${root}procurement/vouchers`,

  purchase_voucher: `${root}procurement/vouchers/purchase-voucher`,
  sales_voucher: `${root}procurement/vouchers/sales-voucher`,
  payment_voucher: `${root}procurement/vouchers/payment-voucher`,
  receipt_voucher: `${root}procurement/vouchers/reciept-voucher`,
  debit_note: `${root}procurement/vouchers/debit-note`,
  credit_note: `${root}procurement/vouchers/credit-note`,

  account_books: `${root}procurement/account-books`,
  account_books_purchase: `${root}procurement/account-books/purchase-vouchers`,
  account_books_sales: `${root}procurement/account-books/sales-vouchers`,
  account_books_payment: `${root}procurement/account-books/payment-vouchers`,
  account_books_reciept: `${root}procurement/account-books/reciept-vouchers`,
  account_ledger_summery: `${root}procurement/account/ledger-summery`,
  account_ledger_entries: `${root}procurement/account/ledger-entries`,
  outstanding_payable: `${root}procurement/account/outstanding/payable`,
  outstanding_receivable: `${root}procurement/account/outstanding/receivable`,

  stock_transfer_voucher: `${root}procurement/stock-transfer-journal`,
  stock_transfer_destination:`${root}procurement/stock_transfer_destination`,
  stock_manufacture_voucher: `${root}procurement/stock-manufacture-journal`,

  stock_items: `${root}procurement/stocks`,
  inventory_manufacturing_projection: `${root}procurement/inventory-projection`,
  purchase_order: `${root}procurement/purchase-order`,
  procurement_project: `${root}procurement/project`,
  po_payment_request: `${root}procurement/payment-request`,
  po_payment_request_approval: `${root}procurement/payment-request/approval`,
};

export interface SideBarBtn {
  url?: string;
  lable: string;
  tag?: string;
  Icon?: any;
  module?: string;
  action: string[];
  operator?: "or" | "and";
  menu?: SideBarBtn[];
}

export const menuUrls = (mod?: string): SideBarBtn[] => {
  if (!mod) return [];
  switch (mod) {
    case MODULE.order_panel:
      return [
        {
          url: urls.root,
          lable: "Main menu",
          Icon: <Icon path={mdiHome} className="h-5 w-5 mx-2" />,
          action: [ACTION.READ],
        },
        {
          url: urls.orderList,
          lable: "Orders",
          Icon: <Icon path={mdiShoppingOutline} className="h-5 w-5 mx-2" />,
          module: MODULE.order,
          action: [ACTION.READ],
        },
        {
          url: urls.createOrder,
          lable: "Create Orders",
          Icon: <Icon path={mdiCartPlus} className="h-5 w-5 mx-2" />,
          module: MODULE.order,
          action: [ACTION.CREATE],
        },

        // {
        //   url: urls.dashboard,
        //   lable: "Kpi Dashboard",
        //   Icon: <Icon path={mdiViewDashboard} className="h-5 w-5 mx-2" />,
        //   module: MODULE.analytic,
        //   action: [ACTION.READ],
        // },
      ];
    case MODULE.payment_panel:
      return [
        {
          url: urls.root,
          lable: "Main menu",
          Icon: <Icon path={mdiHome} className="h-5 w-5 mx-2" />,

          action: [ACTION.READ],
        },
        {
          url: urls.transaction,
          lable: "Transactions",
          Icon: <Icon path={mdiCash} className="h-5 w-5 mx-2" />,
          module: MODULE.payment,
          action: [ACTION.READ],
        },
        {
          url: urls.invoice,
          lable: "Invoice",
          Icon: <Icon path={mdiReceipt} className="h-5 w-5 mx-2" />,
          module: MODULE.invoice,
          action: [ACTION.READ],
        },

        {
          url: urls.customers,
          lable: "Customers",
          Icon: <Icon path={mdiAccountGroup} className="h-5 w-5 mx-2" />,
          module: MODULE.customer_panel,
          action: [ACTION.READ],
        },
        // {
        //   url: urls.create_customer,
        //   lable: "Create Customer",
        //   Icon: <Icon path={mdiAccountMultiplePlus} className="h-5 w-5 mx-2" />,
        //   module: MODULE.CUSTOMER,
        //   action: [ACTION.CREATE],
        // },
      ];
    case MODULE.device_inventory_panel:
      return [
        {
          url: urls.inventory_listing,
          lable: "Inventory",
          Icon: <Icon path={mdiHumanDolly} className="h-5 w-5 mx-2" />,
          module: MODULE.device_inventory_panel,
          action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
          operator: "or",
        },
        {
          url: urls.inventory_overview,
          lable: "Overview",
          Icon: <Icon path={mdiViewDashboard} className="h-5 w-5 mx-2" />,
          module: MODULE.device_inventory_panel,
          action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
          operator: "and",
        },
        {
          url: urls.delivery_note,
          lable: "Delivery Note",
          Icon: <Icon path={mdiFileDocument} className="h-5 w-5 mx-2" />,
          module: MODULE.device_inventory_panel,
          action: [ACTION.CREATE],
          operator: "or",
        },
        // {
        //   url: urls.warehouse_listing,
        //   lable: "Warehouses",
        //   Icon: <Icon path={mdiWarehouse} className="h-5 w-5 mx-2" />,
        //   module: MODULE.INVENTORY,
        //   action: [ACTION.READ],
        // },
      ];
    case MODULE.access_panel:
      return [
        {
          url: urls.root,
          lable: "Main menu",
          Icon: <Icon path={mdiHome} className="h-5 w-5 mx-2" />,

          action: [ACTION.READ],
        },
        {
          url: urls.users,
          lable: "Users",
          Icon: <Icon path={mdiAccountGroup} className="h-5 w-5 mx-2" />,
          module: MODULE.emp,
          action: [ACTION.CREATE],
        },
        {
          url: urls.userCreate,
          lable: "Create User",
          Icon: <Icon path={mdiAccountPlus} className="h-5 w-5 mx-2" />,
          module: MODULE.emp,
          action: [ACTION.CREATE],
        },
        {
          url: urls.roles,
          lable: "Roles / Permissions",
          Icon: <Icon path={mdiShieldKey} className="h-5 w-5 mx-2" />,
          module: MODULE.role,
          action: [ACTION.CREATE],
        },
        {
          url: urls.createRole,
          lable: "Create Role",
          Icon: <Icon path={mdiShieldPlus} className="h-5 w-5 mx-2" />,
          module: MODULE.role,
          action: [ACTION.CREATE],
        },
        {
          url: urls.api_client_list,
          lable: "Api Clients",
          Icon: <Icon path={mdiApi} className="h-5 w-5 mx-2" />,
          module: MODULE.api_master,
          action: [ACTION.DELETE],
          tag: "New",
        },
      ];
    case MODULE.complaint_panel:
      return [
        {
          url: urls.root,
          lable: "Main menu",
          Icon: <Icon path={mdiHome} className="h-5 w-5 mx-2" />,

          action: [ACTION.READ],
        },
        {
          url: urls.complaints,
          lable: "Complaints",
          Icon: (
            <Icon path={mdiTextBoxMultipleOutline} className="h-5 w-5 mx-2" />
          ),
          module: MODULE.complaint,
          action: [ACTION.READ],
        },
        {
          url: urls.create_complaint,
          lable: "Create Complaint",
          Icon: <Icon path={mdiTextBoxPlusOutline} className="h-5 w-5 mx-2" />,
          module: MODULE.complaint,
          action: [ACTION.CREATE],
        },
        {
          url: urls.complaint_word_cloud,
          lable: "Word cloud",
          Icon: <Icon path={mdiCloudPrint} className="h-5 w-5 mx-2" />,
          module: MODULE.complaint,
          action: [ACTION.CREATE],
        },
        {
          url: urls.complaint_segment,
          lable: "Protocol",
          Icon: <Icon path={mdiFileMultiple} className="h-5 w-5 mx-2" />,
          module: MODULE.complaint,
          action: [ACTION.READ],
        },
        {
          url: urls.compliant_insights,
          lable: "Complaint Insights",
          Icon: <Icon path={mdiInboxFull} className="h-5 w-5 mx-2" />,
          module: MODULE.complaint,
          action: [ACTION.READ],
        },
      ];
    case MODULE.Sales_force_panel:
      return [
        {
          url: urls.root,
          lable: "Main menu",
          Icon: <Icon path={mdiHome} className="h-5 w-5 mx-2" />,

          action: [ACTION.READ],
        },
        {
          url: urls.salesTarget,
          lable: "Sales Target",
          Icon: <Icon path={mdiAlphaSCircleOutline} className="h-5 w-5 mx-2" />,
          module: MODULE.Sales_force_panel,
          action: [ACTION.READ],
        },
        // {
        //   url: urls.visit_listing,
        //   lable: "Visits",
        //   Icon: (
        //     <Icon path={mdiOfficeBuildingMarker} className="h-5 w-5 mx-2" />
        //   ),
        //   module: MODULE.SALES_FORCE,
        //   action: [ACTION.READ],
        // },
      ];
    case MODULE.hrm_panel:
      return [
        {
          url: urls.hrm_home,
          lable: "Home",
          Icon: <Icon path={mdiAlphaSCircleOutline} className="h-5 w-5 mx-2" />,
          module: MODULE.hrm_panel,
          action: [ACTION.READ],
        },
        {
          url: urls.hrm_overview,
          lable: "Overview",
          Icon: <Icon path={mdiAlphaSCircleOutline} className="h-5 w-5 mx-2" />,
          module: MODULE.hrm_leave,
          action: [ACTION.READ],
        },
        {
          url: urls.hrm_dept,
          lable: "Employees",
          Icon: <Icon path={mdiAlphaSCircleOutline} className="h-5 w-5 mx-2" />,
          module: MODULE.hrm_panel,
          action: [ACTION.READ],
        },
        {
          url: urls.hrm_profile,
          lable: "Profile",
          Icon: <Icon path={mdiAlphaSCircleOutline} className="h-5 w-5 mx-2" />,
          module: MODULE.hrm_panel,
          action: [ACTION.READ],
        },
      ];
    case MODULE.master_data_panel:
      return [
        {
          url: urls.master_data_warehouse,
          lable: "Warehouses",
          Icon: <Icon path={mdiWarehouse} className="h-5 w-5 mx-2" />,
          module: MODULE.master_data_panel,
          action: [ACTION.READ],
        },
      ];

    case MODULE.ops_task_panel:
      return [
        // {
        //   url: urls.installed_device,
        //   lable: "Installed Devices",
        //   Icon: <Icon path={mdiEvStation} className="h-5 w-5 mx-2" />,
        //   module: MODULE.INSTALLED_DEVICES,
        //   action: [ACTION.READ],
        // },
        // {
        //   url: urls.host_listing,
        //   lable: "Hosts",
        //   Icon: <Icon path={mdiAccount} className="h-5 w-5 mx-2" />,
        //   module: MODULE.INSTALLED_DEVICES,
        //   action: [ACTION.READ],
        // },
        // {
        //   url: urls.ops_tickets,
        //   lable: "Operation Tickets",
        //   Icon: <Icon path={mdiTicketConfirmation} className="h-5 w-5 mx-2" />,
        //   module: MODULE.OPS_TICKET,
        //   action: [ACTION.READ],
        // },
        // {
        //   url: urls.ops_tasks,
        //   lable: "My Tickets",
        //   Icon: <Icon path={mdiTicketConfirmation} className="h-5 w-5 mx-2" />,
        //   module: MODULE.OPS_TICKET,
        //   action: [ACTION.READ],
        // },
        // {
        //   url: urls.device_alloction,
        //   lable: "Allocation",
        //   Icon: <Icon path={mdiTicketConfirmation} className="h-5 w-5 mx-2" />,
        //   module: MODULE.OPS_TICKET,
        //   action: [ACTION.READ],
        // },
        // {
        //   url: urls.emp_basket,
        //   lable: "Basket",
        //   Icon: <Icon path={mdiTicketConfirmation} className="h-5 w-5 mx-2" />,
        //   module: MODULE.OPS_TICKET,
        //   action: [ACTION.READ],
        // },
        // {
        //   url: urls.create_operation_task,
        //   lable: "Create Task",
        //   Icon: <Icon path={mdiTicketConfirmation} className="h-5 w-5 mx-2" />,
        //   module: MODULE.OPS_TICKET,
        //   action: [ACTION.CREATE],
        // },
        {
          url: urls.ops_admin_view,
          lable: "Dashboard",
          Icon: <Icon path={mdiViewDashboard} className="h-5 w-5 mx-2" />,
          module: MODULE.ops_task,
          action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
          operator: "and",
        },
        {
          url: urls.operation_task,
          lable: "Manage Tasks",
          Icon: <Icon path={mdiFormatListCheckbox} className="h-5 w-5 mx-2" />,
          module: MODULE.ops_ticket,
          action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
          operator: "and",
        },
        {
          url: urls.operation_engineer_task,
          lable: "My Tasks",
          Icon: <Icon path={mdiToolboxOutline} className="h-5 w-5 mx-2" />,
          module: MODULE.ops_task,
          action: [ACTION.READ],
        },
        // {
        //   url: urls.my_operation_task,
        //   lable: "Tasks",
        //   Icon: <Icon path={mdiBookClock} className="h-5 w-5 mx-2" />,
        //   module: MODULE.ops_task,
        //   action: [ACTION.READ],
        // },
        // {
        //   url: urls.my_recent_operation_task,
        //   lable: "Completed",
        //   Icon: <Icon path={mdiBookCheckOutline} className="h-5 w-5 mx-2" />,
        //   module: MODULE.ops_task,
        //   action: [ACTION.READ],
        // },
        // {
        //   url: urls.emp_attendance,
        //   lable: "Start | Stop Duty",
        //   Icon: <Icon path={mdiCalendarCheck} className="h-5 w-5 mx-2" />,
        //   module: MODULE.ops_task,
        //   action: [ACTION.READ],
        // },
        {
          url: urls.ops_task_session,
          lable: "Engineer Sessions",
          Icon: <Icon path={mdiCalendarClock} className="h-5 w-5 mx-2" />,
          module: MODULE.ops_ticket,
          action: [ACTION.READ],
        },
      ];
    case MODULE.api_inegration_panel:
      return [
        {
          url: urls.api_client_my_list,
          lable: "Api keys",
          Icon: <Icon path={mdiApi} className="h-5 w-5 mx-2" />,
          module: MODULE.api_master,
          action: [ACTION.READ],
        },
      ];
    case MODULE.content_panel:
      return [
        {
          url: urls.content_panel,
          lable: "Dashboard",
          Icon: <></>,
          module: MODULE.api_master,
          action: [ACTION.READ],
        },

        {
          url: urls.blogListing,
          lable: "Blogs",
          Icon: <Icon path={mdiPostOutline} className="h-5 w-5 mx-2" />,
          module: MODULE.content,
          action: [ACTION.CREATE],
          operator: "or",
        },

        {
          lable: "Upload image",
          Icon: <Icon path={mdiTrayArrowUp} className="h-5 w-5 mx-2" />,
          url: urls.uploadImage,

          module: MODULE.content,
          action: [ACTION.CREATE],
          operator: "or",
        },

        {
          lable: "Installation collage",
          Icon: <Icon path={mdiCollage} className="h-5 w-5 mx-2" />,
          url: urls.installation,
          module: MODULE.content,
          action: [ACTION.CREATE],
          operator: "or",
        },

        // {
        //   lable: "Installation create",
        //   Icon: <Icon path={mdiImagePlusOutline} className="h-5 w-5 mx-2" />,
        //   url: urls.installationCreate,
        //   module: MODULE.content,
        //   action: [ACTION.CREATE],
        //   operator: "or",
        // },

        // {
        //   lable: "Magazines",
        //   Icon: <Icon path={mdiTextBoxMultiple} className="h-5 w-5 mx-2" />,
        //   url: urls.magazine,
        //   module: MODULE.content,
        //   action: [ACTION.CREATE],
        //   operator: "or",
        // },

        // {
        //   lable: "Magazines create",
        //   Icon: <Icon path={mdiFileDocumentPlus} className="h-5 w-5 mx-2" />,
        //   url: urls.magazineCreate,
        //   module: MODULE.content,
        //   action: [ACTION.CREATE],
        //   operator: "or",
        // },

        {
          lable: "Blog Authors",
          Icon: <Icon path={mdiAccountGroup} className="h-5 w-5 mx-2" />,
          url: urls.authors,
          module: MODULE.content,
          action: [ACTION.CREATE],
          operator: "or",
        },
        // {
        //   lable: "Blog Authors",
        //   Icon: <Icon path={mdiAccountMultiplePlus} className="h-5 w-5 mx-2" />,
        //   url: urls.authorCreate,
        //   module: MODULE.content,
        //   action: [ACTION.CREATE],
        //   operator: "or",
        // },

        {
          lable: "Vehicles",
          Icon: <Icon path={mdiCar} className="h-5 w-5 mx-2" />,
          url: urls.vehicles,
          module: MODULE.content,
          action: [ACTION.READ, ACTION.UPDATE],
          operator: "and",
        },
      ];

    case MODULE.procurement_panel:
      return [
        {
          url: urls.procurement,
          lable: "Home",
          // Icon: <Icon path={mdiBox} className="h-5 w-5 mx-2" />,
          module: MODULE.procurement_panel,
          action: [ACTION.READ],
        },
        {
          lable: "Purchase order",
          // Icon: <Icon path={mdiBox} className="h-5 w-5 mx-2" />,
          module: MODULE.purchase_order_self,
          action: [ACTION.READ],
          menu: [
            {
              url: urls.purchase_order,
              lable: "Purchase orders",
              // Icon: <Icon path={mdiBox} className="h-5 w-5 mx-2" />,
              module: MODULE.purchase_order_self,
              action: [ACTION.READ],
            },

            {
              url: urls.po_payment_request,
              lable: "Payment Request",
              // Icon: <Icon path={mdiBox} className="h-5 w-5 mx-2" />,
              module: MODULE.purchase_order,
              action: [ACTION.READ],
            },
          ],
        },
        {
          lable: "Approvals",
          // Icon: <Icon path={mdiBox} className="h-5 w-5 mx-2" />,
          module: MODULE.purchase_order_approval,
          action: [ACTION.READ],
          menu: [
            {
              url: urls.purchase_order + "/approval",
              lable: "PO Approvals",
              // Icon: <Icon path={mdiBox} className="h-5 w-5 mx-2" />,
              module: MODULE.purchase_order_approval,
              action: [ACTION.READ],
            },
            {
              url: urls.po_payment_request_approval,
              lable: "Payment Request approval",
              // Icon: <Icon path={mdiBox} className="h-5 w-5 mx-2" />,
              module: MODULE.purchase_order,
              action: [ACTION.READ],
            },
            {
              url: urls.procurement_project,
              lable: "Project Budgets",
              // Icon: <Icon path={mdiBox} className="h-5 w-5 mx-2" />,
              module: MODULE.purchase_order_approval,
              action: [ACTION.READ],
            },
          ],
        },
        {
          lable: "Account vouchers",
          // Icon: <Icon path={mdiBox} className="h-5 w-5 mx-2" />,
          module: MODULE.voucher_self,
          action: [ACTION.READ],
          menu: [
            {
              url: urls.purchase_voucher,
              lable: "Purchase",
              // Icon: <Icon path={mdiBox} className="h-5 w-5 mx-2" />,
              module: MODULE.voucher_self,
              action: [ACTION.READ],
            },
            // {
            //   url: urls.sales_voucher,
            //   lable: "Sales",
            //   // Icon: <Icon path={mdiBox} className="h-5 w-5 mx-2" />,
            //   module: MODULE.voucher_self,
            //   action: [ACTION.READ],
            // },
            // {
            //   url: urls.payment_voucher,
            //   lable: "Payment",
            //   // Icon: <Icon path={mdiBox} className="h-5 w-5 mx-2" />,
            //   module: MODULE.voucher_self,
            //   action: [ACTION.READ],
            // },
            // {
            //   url: urls.receipt_voucher,
            //   lable: "Reciept",
            //   // Icon: <Icon path={mdiBox} className="h-5 w-5 mx-2" />,
            //   module: MODULE.voucher_self,
            //   action: [ACTION.READ],
            // },
            // {
            //   url: urls.debit_note,
            //   lable: "Debit Note",
            //   // Icon: <Icon path={mdiBox} className="h-5 w-5 mx-2" />,
            //   module: MODULE.voucher_self,
            //   action: [ACTION.READ],
            // },
            // {
            //   url: urls.credit_note,
            //   lable: "Credit Note",
            //   // Icon: <Icon path={mdiBox} className="h-5 w-5 mx-2" />,
            //   module: MODULE.voucher_self,
            //   action: [ACTION.READ],
            // },
          ],
        },

        {
          lable: "Inventory journals",
          // Icon: <Icon path={mdiBox} className="h-5 w-5 mx-2" />,
          module: MODULE.inventory_journal_self,
          action: [ACTION.READ],
          menu: [
            {
              url: urls.stock_transfer_voucher,
              lable: "Sender",
              // Icon: <Icon path={mdiBox} className="h-5 w-5 mx-2" />,
              module: MODULE.inventory_journal_self,
              action: [ACTION.READ],
            },
            {
              url: urls.stock_transfer_destination,
              lable: "Receiver",
              // Icon: <Icon path={mdiBox} className="h-5 w-5 mx-2" />,
              module: MODULE.inventory_journal_self,
              action: [ACTION.READ],
            },
            {
              url: urls.stock_manufacture_voucher,
              lable: "Manufacturing",
              // Icon: <Icon path={mdiBox} className="h-5 w-5 mx-2" />,
              module: MODULE.inventory_journal_self,
              action: [ACTION.READ],
            },
            {
              url: urls.inventory_manufacturing_projection,
              lable: "Projection",
              // Icon: <Icon path={mdiBox} className="h-5 w-5 mx-2" />,
              module: MODULE.inventory_journal_self,
              action: [ACTION.READ],
            },
          ],
        },

        {
          lable: "Masters",
          // Icon: <Icon path={mdiBox} className="h-5 w-5 mx-2" />,
          module: MODULE.procurement_master,
          action: [ACTION.READ],
          menu: [
            // {
            //   url: urls.procurement_masters_ledger_groups,
            //   lable: "Ledger Groups",
            //   // Icon: <Icon path={mdiBox} className="h-5 w-5 mx-2" />,
            //   module: MODULE.procurement_master,
            //   action: [ACTION.READ],
            // },
            {
              url: urls.procurement_masters_ledgers,
              lable: "Ledgers",
              // Icon: <Icon path={mdiBox} className="h-5 w-5 mx-2" />,
              module: MODULE.procurement_master,
              action: [ACTION.READ],
            },
            {
              url: urls.procurement_masters_inventory_bucket,
              lable: "Inventory Basket",
              // Icon: <Icon path={mdiBox} className="h-5 w-5 mx-2" />,
              module: MODULE.procurement_master,
              action: [ACTION.READ],
            },
            {
              url: urls.procurement_masters_party,
              lable: "Vendor & Client",
              // Icon: <Icon path={mdiAccountGroup} className="h-5 w-5 mx-2" />,
              module: MODULE.procurement_master,
              action: [ACTION.READ],
            },
            {
              url: urls.procurement_masters_item_groups,
              lable: "Item groups",
              // Icon: <Icon path={mdiAccountGroup} className="h-5 w-5 mx-2" />,
              module: MODULE.procurement_master,
              action: [ACTION.READ],
            },
            {
              url: urls.procurement_masters_item,
              lable: "Items",
              // Icon: <Icon path={mdiAccountGroup} className="h-5 w-5 mx-2" />,
              module: MODULE.procurement_master,
              action: [ACTION.READ],
            },
            {
              url: urls.procurement_masters_item_unit,
              lable: "Item Units",
              // Icon: <Icon path={mdiAccountGroup} className="h-5 w-5 mx-2" />,
              module: MODULE.procurement_master,
              action: [ACTION.READ],
            },
          ],
        },
        {
          lable: "Summary",
          // Icon: <Icon path={mdiBox} className="h-5 w-5 mx-2" />,
          module: MODULE.procurement_master,
          action: [ACTION.READ],
          menu: [
            {
              url: urls.account_ledger_summery,
              lable: "Ledger",
              // Icon: <Icon path={mdiBox} className="h-5 w-5 mx-2" />,
              module: MODULE.procurement_master,
              action: [ACTION.READ],
            },
            {
              url: urls.stock_items,
              lable: "Inventory",
              // Icon: <Icon path={mdiBox} className="h-5 w-5 mx-2" />,
              module: MODULE.procurement_master,
              action: [ACTION.READ],
            },
          ],
        },
        {
          lable: "Outstandings",
          // Icon: <Icon path={mdiBox} className="h-5 w-5 mx-2" />,
          module: MODULE.procurement_panel,
          action: [ACTION.READ],
          menu: [
            {
              url: urls.outstanding_payable,
              lable: "Payables",
              // Icon: <Icon path={mdiBox} className="h-5 w-5 mx-2" />,
              module: MODULE.procurement_panel,
              action: [ACTION.READ],
            },
            {
              url: urls.outstanding_receivable,
              lable: "Receivables",
              // Icon: <Icon path={mdiBox} className="h-5 w-5 mx-2" />,
              module: MODULE.procurement_panel,
              action: [ACTION.READ],
            },
          ],
        },
      ];
      case MODULE.dashboard:
      return [
        {
          url: urls.Dashboard,
          lable: "All Dashboards",
          Icon: <Icon path={mdiHome} className="h-5 w-5 mx-2" />,
          action: [ACTION.READ],
        }
      ];
    default:
      return [];
  }
};
