import { mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent } from "react";

interface LoadingWidgetProps {}

const LoadingWidget: FunctionComponent<LoadingWidgetProps> = (props) => {
  // <div className="flex h-full w-full justify-center items-center ">
  //     {loading}
  //   </div>
  return (
    <>
      <div
        className={`h-screen w-screen fixed z-50 top-0 left-0 bg-black bg-opacity-30 select-none cursor-wait  `}
      >
        <div className="flex h-full w-full justify-center items-center text-white ">
          <div className="flex items-center gap-2">
            <Icon path={mdiLoading} size={1} spin className="mr-2 ml-1 " />
            {/* <HrmLoader className="w-12 h-12" /> */}
            {/* <div className="flex justify-center">
              <div
                className={`h-2.5 w-2.5 bg-current   rounded-full mr-1 animate-bounce`}
              ></div>
              <div
                className={`h-2.5 w-2.5 bg-current   rounded-full mr-1 animate-bounce200`}
              ></div>
              <div
                className={`h-2.5 w-2.5 bg-current   rounded-full animate-bounce400`}
              ></div>
            </div> */}
            <p>Loading . . . .</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoadingWidget;
