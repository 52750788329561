import { FunctionComponent, useEffect, useState } from "react";
import { useAuth } from "../../../../../../../../context_providers/auth/authProvider";
import { ACTION, MODULE } from "../../../../../../../../utils/enums";
import {
  GalleryModel,
  VehiclePrice,
} from "../../../../../infrastructure/models/car_model";
import {
  deletetVehicleGallery,
  getGalleryByVerientIdRepo,
  postVehicleGallery,
  putVehicleGallery,
} from "../../../../../services/vehicle_service";
import FileDropZone from "../../../../new_components/common/file_drop_widget";
import ModalDialog from "../../../../new_components/common/modal";
import GalleryAddFile from "./add_gallery";

interface GalleryListProps {
  location: any;
}

const GalleryList: FunctionComponent<GalleryListProps> = (props) => {
  const { varient_id, gallery } = props.location.state;
  const { user, isAuthorised } = useAuth();

  const [galleries, setGalleries] = useState<GalleryModel[]>(gallery ?? []);
  const [showAddFile, setShowAddFile] = useState(false);
  const [selectedGallery, setSelectedGalllery] = useState<GalleryModel>();
  const getGallery = async () => {
    const res = await getGalleryByVerientIdRepo({ varient_id });

    if (res.success) {
      if (res.data) setGalleries(res.data);
    } else {
      alert(res.error);
    }
  };
  const onSubmit = async (data: FormData) => {
    const res = selectedGallery
      ? await putVehicleGallery(data)
      : await postVehicleGallery(data);
    if (res.success) {
      alert(res.message);
      setShowAddFile(false);
      setSelectedGalllery(undefined);
      getGallery();
    } else {
      alert(res.error);
    }
  };
  const onDelete = async (gallery_id: string) => {
    const str = "DELETE";
    const confirm = prompt(`Enter ${str} to confirm (note : Cannot be undo)`);
    if (confirm !== str) return;
    const res = await deletetVehicleGallery({ varient_id, gallery_id });
    if (res.success) {
      alert(res.message);
      getGallery();
    } else {
      alert(res.error);
    }
  };
  useEffect(() => {
    getGallery();
  }, []);
  return (
    <>
      {showAddFile && (
        <ModalDialog
          onClose={() => setShowAddFile(false)}
          show={showAddFile}
          title="Add Gallery"
        >
          <GalleryAddFile varient_id={varient_id} onSubmit={onSubmit} />
        </ModalDialog>
      )}
      {selectedGallery && (
        <ModalDialog
          onClose={() => setSelectedGalllery(undefined)}
          show={selectedGallery !== undefined}
          title="Edit Gallery"
        >
          {" "}
          <div
            style={{ height: "85vh", width: "90vw" }}
            className=" w-screen  overflow-y-scroll p-5 "
          >
            <GalleryAddFile
              data={selectedGallery!}
              varient_id={varient_id}
              onSubmit={onSubmit}
            />
          </div>
        </ModalDialog>
      )}
      <div className="rounded shadow p-5">
        <div className="flex justify-between mb-2">
          <h1>Gallery</h1>
          <div
            onClick={() => setShowAddFile(true)}
            className="rounded-full shadow h-14 w-14 bg-gray-50 flex justify-center items-center transform transition-all duration-300 hover:text-red-700 hover:scale-125 "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-12 w-12 "
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
              />
            </svg>
          </div>
        </div>
        <div className=" grid grid-cols-2 md:grid-cols-5 ">
          {galleries.map((g, i) => (
            <div key={i} className=" relative">
              <div className="border rounded m-4 h-64 w-64 bg-gray-100">
                <img src={g.url} alt="" className="h-full w-full rounded" />
              </div>
              <div className="ml-4 absolute bottom-0 flex justify-around w-64">
                <div
                  onClick={() => {
                    setSelectedGalllery(g);
                    //   document.getElementById("image")?.click();
                  }}
                  className="border mx-2  flex justify-center items-center p-3 bg-gray-100 rounded-full  cursor-pointer "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-red-400 transform transition-all duration-300 hover:text-red-700 hover:scale-125"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
                    />

                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                </div>
                {isAuthorised({
                  module: MODULE.content,
                  action: [ACTION.DELETE],
                }) && (
                  <div
                    onClick={() => onDelete(g.id)}
                    className="border mx-2  flex justify-center items-center p-3 bg-gray-100 rounded-full  cursor-pointer "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 text-red-400 transform transition-all duration-300 hover:text-red-700 hover:scale-125"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </svg>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default GalleryList;
