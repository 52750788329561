import { FunctionComponent, useEffect, useState } from "react";
import { useOpsTask } from "../../../../../../context_providers/ops_task_engineer_detail/ops_task_engineer_detail";
import axios from "axios";
import { string } from "yup";
import { Link, useHistory, useParams } from "react-router-dom";
import { fetchGet } from "../../../../content_panel/services/serviceClient";
import Icon from "@mdi/react";
import {
  mdiCheck,
  mdiInformationOutline,
  mdiLightningBolt,
  mdiLightningBoltOutline,
} from "@mdi/js";
import DoneWidget from "../../../../../new_components/common/done";
import {
  OpsTaskDeviceItem,
  OpsTaskDeviceItemResolution,
  OpsTaskDeviceItemStatus,
} from "../../../../../../service/models/ops/ops_ticket";
import {
  ToastType,
  useToast,
} from "../../../../../../context_providers/toast/toast_ctx";
import { fetchPut } from "../../../../../../service/api_client";
import LoadingWidget from "../../../../../components/loading_spinner";
import { OpsTaskDeviceIssueType } from "../../../../../../utils/constant";
import DeviceComponentWidget from "../../../components/device_item_component";
import { ItemModel } from "../../../../../../erp/inventory/models/item_model";
import DeviceComponentRow from "../../../components/device_component_row";
import { urls } from "../../../../../../utils/urls";
import { useTaskSession } from "../../../../../../context_providers/task_session_ctx";

interface DeviceServiceScreenProps {}

const DeviceServiceScreen: FunctionComponent<DeviceServiceScreenProps> = () => {
  const history = useHistory();
  const { onDuty } = useTaskSession();
  const { showToast } = useToast();
  const { device_id } = useParams<{ device_id: string }>();
  const { data, setData } = useOpsTask();
  const [loading, setLoading] = useState(false);
  const [showTestTxn, setShowTextTxn] = useState(true);
  const [deviceItem, setDeviceItem] = useState<OpsTaskDeviceItem>();
  const updateDeviceItem = async (item: OpsTaskDeviceItem) => {
    if (!item.issue_type)
      return showToast({
        type: ToastType.error,
        text: "Select issue category ",
      });
    if (!item.issue_sub_type)
      return showToast({
        type: ToastType.error,
        text: "Select issue sub category ",
      });
    if (!item.resolution)
      return showToast({
        type: ToastType.error,
        text: "Select resolution ",
      });
    if (!window.confirm("Are you sure ?")) return;
    if (deviceItem?.txn_test_done)
      item.status = OpsTaskDeviceItemStatus.completed;

    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/new-ops/task/my-tasks/${data?.id}/device_item/${item.device_id}`;
    const res = await fetchPut(url, item);
    if (res.success) {
      setShowTextTxn(true);
      setDeviceItem(res.data);
      setData((o) => ({
        ...o!,
        device_items: [...(o?.device_items || [])].map((f) => {
          if (f.device_id == res.data.device_id) return res.data;
          return f;
        }),
      }));
      showToast({
        type: ToastType.success,
        text: res.messsage ?? "Completed",
      });
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    // setLoading(false);
  };
  useEffect(() => {
    data?.device_items?.forEach((e) => {
      if (e.device_id == device_id) {
        setDeviceItem(e);
      }
    });
  }, []);
  const [addNewId, setAddNewId] = useState("");

  return (
    <>
      <div className="flex flex-col gap-2 items-start h-full w-full pb-10">
        <div className="flex justify-between w-full">
          <button
            onClick={() => {
              history.goBack();
            }}
            className="rounded-3xl border px-5 py-1 bg-blue-50 text-blue-500 border-blue-400 "
          >
            Back
          </button>
          <div className="">
            <p
              className={`font-semibold uppercase ${
                deviceItem?.status == OpsTaskDeviceItemStatus.pending
                  ? "text-yellow-600"
                  : deviceItem?.status == OpsTaskDeviceItemStatus.completed
                  ? "text-green-600"
                  : "text-red-500"
              }`}
            >
              {deviceItem?.status}
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-2 w-full">
          <div className="flex flex-col">
            <div className=" ">
              <p>Device ID </p>
            </div>
            <div className="p-3 bg-white rounded-lg border w-full flex justify-between ">
              {deviceItem?.device_id}
              <div
                className=""
                title={`Txn Test ${
                  deviceItem?.txn_test_done ? "Done" : "Pending"
                }`}
              >
                {deviceItem?.txn_test_done ? (
                  <div className="flex gap-1 text-green-500 text-sm">
                    <Icon
                      path={mdiCheck}
                      className="text-green-500"
                      size={0.8}
                    />{" "}
                    Test Txn Done
                  </div>
                ) : (
                  deviceItem?.status == OpsTaskDeviceItemStatus.pending &&
                  data?.start_date &&
                  onDuty && (
                    <Link
                      to={`${urls.operation_engineer_task}/detail/${data?.id}/device/${device_id}/transaction-test`}
                      className="text-blue-500 text-sm"
                    >
                      Test Txn Pending
                    </Link>
                  )
                )}
              </div>
            </div>
          </div>

          <div className="flex flex-col">
            <div className=" ">
              <p>Issue Category </p>
            </div>
            <div className="p-3 bg-white rounded-lg border w-full ">
              <select
                disabled={
                  deviceItem?.status !== OpsTaskDeviceItemStatus.pending ||
                  !onDuty ||
                  !data?.start_date
                }
                value={deviceItem?.issue_type}
                onChange={(e) => {
                  setDeviceItem((o) => ({ ...o!, issue_type: e.target.value }));
                }}
                name=""
                id=""
                className="w-full focus:outline-none bg-transparent"
              >
                <option value="" hidden>
                  Select
                </option>
                {Object.values(OpsTaskDeviceIssueType).map((f) => (
                  <option value={f} key={f}>
                    {f}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex flex-col">
            <div className=" ">
              <p>Issue Sub Category </p>
            </div>
            <div className="p-3 bg-white rounded-lg border w-full  ">
              <select
                disabled={
                  deviceItem?.status !== OpsTaskDeviceItemStatus.pending ||
                  !onDuty ||
                  !data?.start_date
                }
                value={deviceItem?.issue_sub_type}
                onChange={(e) => {
                  setDeviceItem((o) => ({
                    ...o!,
                    issue_sub_type: e.target.value,
                  }));
                }}
                name=""
                id=""
                className="w-full focus:outline-none bg-transparent"
              >
                {" "}
                <option value="" hidden>
                  Select
                </option>
                {Object.values(OpsTaskDeviceIssueType).map((f) => (
                  <option value={f} key={f}>
                    {f}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex flex-col">
            <div className=" ">
              <p>Resolution </p>
            </div>
            <div className="p-3 bg-white rounded-lg border w-full  ">
              <select
                disabled={
                  deviceItem?.status !== OpsTaskDeviceItemStatus.pending ||
                  !onDuty ||
                  !data?.start_date
                }
                value={deviceItem?.resolution}
                onChange={(e) => {
                  setDeviceItem((o) => ({
                    ...o!,
                    resolution: e.target.value,
                  }));
                }}
                name=""
                id=""
                className="w-full focus:outline-none bg-transparent"
              >
                {" "}
                <option value="" hidden>
                  Select
                </option>
                {Object.values(OpsTaskDeviceItemResolution).map((f) => (
                  <option value={f} key={f}>
                    {f}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex flex-col my-2">
            <label htmlFor="time-taken">Time Taken (Minutes)</label>
            <input
              disabled={
                deviceItem?.status !== OpsTaskDeviceItemStatus.pending ||
                !onDuty ||
                !data?.start_date
              }
              id="time-taken"
              placeholder="Click here"
              className="w-full focus:outline-none border rounded-lg p-2 bg-white"
              type="number"
              value={deviceItem?.time_taken}
              onChange={(e) => {
                const time_taken = Number(e.target.value);
                setDeviceItem((o) => ({
                  ...o!,
                  time_taken,
                }));
              }}
            ></input>
          </div>
          <div className="flex flex-col">
            <div className=" ">
              <p>Remarks </p>
            </div>
            <div className="p-3 bg-white rounded-lg border w-full  ">
              <textarea
                disabled={
                  deviceItem?.status !== OpsTaskDeviceItemStatus.pending ||
                  !onDuty ||
                  !data?.start_date
                }
                value={deviceItem?.remark}
                onChange={(e) => {
                  setDeviceItem((o) => ({
                    ...o!,
                    remark: e.target.value,
                  }));
                }}
                placeholder="Short note"
                name=""
                id=""
                className="w-full focus:outline-none bg-transparent"
              >
                {" "}
              </textarea>
            </div>
          </div>

          <div className="flex flex-col  ">
            <div className=" ">
              <p>Components Consumed </p>
            </div>
            <div className="  rounded flex flex-col gap-1  bg-white ">
              <div className="grid grid-cols-4 gap-2 p-1 bg-myPrimaryColor text-white text-sm font-semibold rounded-t  uppercase ">
                <div className="text-center col-span-2">Item</div>
                <div className="text-center ">Qty</div>

                {/* <div className="text-center col-span-2">Remark</div> */}
                <div className="text-center  "> Actions</div>
              </div>
              <div
                className="flex flex-col gap-1   text-sm overflow-auto  "
                style={{ maxHeight: "90vh", minHeight: "50vh" }}
              >
                {deviceItem?.components?.map((item, i) => {
                  return (
                    <DeviceComponentRow
                      disabled={
                        deviceItem?.status !==
                          OpsTaskDeviceItemStatus.pending ||
                        !onDuty ||
                        !data?.start_date
                      }
                      key={item.id}
                      data={item}
                      onDelete={(d) => {
                        setDeviceItem((o) => ({
                          ...o!,
                          components: [...(o?.components || [])].filter(
                            (x) => x.id !== d.id
                          ),
                        }));
                      }}
                      onSubmit={function (d): void {
                        setDeviceItem((o) => ({
                          ...o!,
                          components: [...(o?.components || [])].map((x) => {
                            if (x.id == d.id) {
                              return d;
                            }
                            return x;
                          }),
                        }));
                      }}
                      edit={false}
                    />
                  );
                })}

                {onDuty &&
                  data?.start_date &&
                  deviceItem?.status == OpsTaskDeviceItemStatus.pending && (
                    <DeviceComponentRow
                      key={addNewId}
                      onSubmit={function (new_item): void {
                        new_item.id = Math.ceil(
                          Math.random() * 10000
                        ).toString();
                        setDeviceItem((o) => ({
                          ...o!,
                          components: [...(o?.components || []), new_item],
                        }));
                        setAddNewId((Math.random() * 1000).toString());
                      }}
                      edit={true}
                      disabled={false}
                    />
                  )}
              </div>
            </div>
          </div>
          <div className="h-20"></div>
        </div>

        {/* {deviceItem &&
          deviceItem.status == OpsTaskDeviceItemStatus.pending &&
          (showTestTxn ? (
            <div className="fixed bottom-3 right-3 left-3">
              <Link
                to={`${urls.operation_engineer_task}/update/${data?.id}/device/transaction-test/${deviceItem.device_id}`}
                className="w-full"
              >
                <div className="uppercase bg-purple-50 text-purple-500 border-purple-300 border rounded-lg   text-center px-3 py-2 w-full font-bold">
                  Test Transaction
                </div>
              </Link>
            </div>
          ) : (
            <div className="fixed bottom-3 right-3 left-3">
              <button
                onClick={() => updateDeviceItem(deviceItem!)}
                className="uppercase bg-purple-50 text-purple-500 border-purple-300 border rounded-lg   text-center px-3 py-2 w-full font-bold"
              >
                Confirm
              </button>
            </div>
          ))} */}
        {deviceItem &&
          onDuty &&
          data?.start_date &&
          deviceItem.status == OpsTaskDeviceItemStatus.pending && (
            <div className="fixed bottom-0 right-0 left-0 p-3 bg-white shadow-sm">
              <button
                onClick={() => updateDeviceItem(deviceItem!)}
                className="uppercase bg-purple-50 text-purple-500 border-purple-300 border rounded-lg   text-center px-3 py-2 w-full font-bold"
              >
                Submit
              </button>
            </div>
          )}
      </div>
      {loading && <LoadingWidget loading={true} />}
    </>
  );
};

export default DeviceServiceScreen;
