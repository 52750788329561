import { mdiCheck, mdiClose, mdiPencil, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { useModal } from "../../../../../context_providers/modal/modal_context";
import { useOrderCreateCtx } from "../../../../../context_providers/order_create_context";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import {
  DurationType,
  subscriptionPlanDefault,
  SubscriptionPlanModel,
} from "../../../../../service/models/orders/subscription_plan_model";
import {
  getActiveOrgPlanApi,
  getOrgNamespaceApi,
} from "../../../../../service/repos/cms/org_repo";
import PaymentSection from "./payment_section";
import SubscriptionConfigWidget from "./subscription_config_widget";
import { currencyFormat } from "../../../../../utils/orders_utils";
import { sub } from "date-fns";
import { addDurationToDate } from "../../../../../utils/date_util";

interface SubscriptionSectionProps {}

const SubscriptionSection: FunctionComponent<SubscriptionSectionProps> = () => {
  const { loading, setLoading } = useModal();
  const { showToast } = useToast();
  const {
    namespaces,
    setNamespaces,
    subscription,
    setSubscription,
    order,
    setOrder,
    subscriptionReq,
    setSubscriptionReq,
  } = useOrderCreateCtx();
  // const [subscription, setActivePlan] = useState<SubscriptionPlanModel>();
  const [edit, setEdit] = useState(false);
  // useEffect(() => {
  //   if (namespaces.length === 0)
  //     getOrgNamespaceApi()
  //       .then((val) => {
  //         if (val.success) {
  //           setNamespaces(val.data);
  //         } else alert(val.error);
  //       })
  //       .catch((e) => alert(e.message));
  // }, []);
  // const getActivePlan = async (namespace: string) => {
  //   setLoading(true);
  //   const res = await getActiveOrgPlanApi(namespace);
  //   if (res.success) {
  //     if (res.data) {
  //       setEdit(false);
  //       setSubscription(res.data);
  //       showToast({
  //         type: ToastType.warning,
  //         text: `${namespace} has an active subscription plan`,
  //       });
  //     } else {
  //       setEdit(true);
  //       setSubscription((o) => ({
  //         ...subscriptionPlanDefault,
  //         org_name: o.org_name,
  //         org_namespace: o.org_namespace,
  //       }));
  //     }
  //   } else showToast({ type: ToastType.error, text: res.error });
  //   setLoading(false);
  // };
  // lg:col-start-2 lg:col-span-10  xl:col-start-3 xl:col-span-8
  return (
    <>
      <div className="  text-sm   mt-10">
        {subscriptionReq ? (
          <div className={` ${!subscriptionReq ? "cursor-not-allowed" : ""}`}>
            <>
              <h3 className="font-semibold my-1"> Subscription Plan</h3>

              {subscription && !edit ? (
                <>
                  <div
                    className={`${
                      subscription.active ? " shadow " : ""
                    } rounded-md bg-white  p-5 text-sm text-gray-600`}
                  >
                    <div className="flex justify-end mb-2">
                      <button
                        disabled={!subscriptionReq}
                        onClick={() => {
                          setEdit(true);
                        }}
                        className={` flex gap-1 cursor-pointer  text-blue-400 px-2 py-1 text-xs hover:text-blue-600 ${
                          subscriptionReq ? "" : "cursor-not-allowed"
                        }`}
                      >
                        <Icon path={mdiPencil} className="h-4 w-4"></Icon> Edit
                      </button>
                    </div>

                    <div className="my-2">
                      <p>
                        <b>Org name</b> :{subscription.org_name}
                      </p>
                      <p>
                        <b>Org namespace</b> : {subscription.org_namespace}
                      </p>
                    </div>
                    <hr className="" />
                    <div className="my-2">
                      {" "}
                      <h5 className="font-semibold ">Validity</h5>
                      <div className="flex gap-5">
                        <p>
                          <b>Start Date</b> :{" "}
                          {moment(subscription.start_date).format("lll")}
                        </p>
                        <p>
                          <b>End Date</b> :{" "}
                          {subscription.end_date &&
                            moment(subscription.end_date).format("lll")}
                        </p>
                      </div>
                    </div>
                    <hr />
                    <div className="my-2">
                      <h5 className="font-semibold ">Renewal</h5>
                      <div className="flex gap-5">
                        <p>
                          <b>Renewal Fee</b> :{" "}
                          {currencyFormat(subscription.renewal_fee)}
                        </p>
                        <p>
                          <b>Renewal collection type</b> :{" "}
                          {subscription.renewal_fee_collection_type}
                        </p>
                        {subscription.renewal_fee_collection_type ==
                          "Monthly" && (
                          <p>
                            <b>Renewal collection duration (EMI)</b> :{" "}
                            {subscription.renewal_fee_emi_months}
                          </p>
                        )}
                        {subscription.renewal_fee_collection_type ==
                          "Monthly" && (
                          <p>
                            <b>Renewal collection EMI</b> :{" "}
                            {currencyFormat(
                              subscription.renewal_fee /
                                (subscription.renewal_fee_emi_months || 1)
                            )}
                          </p>
                        )}
                      </div>
                    </div>
                    <hr />
                    <div className="my-2">
                      <h5 className="font-semibold ">Connectors</h5>
                      <div className="flex gap-5">
                        <p>
                          <b>AC connector/ per connector / month</b> :{" "}
                          {currencyFormat(subscription.per_connector_ac)}
                        </p>
                        <p>
                          <b>DC connector/ per connector / month</b> :{" "}
                          {currencyFormat(subscription.per_connector_dc)}
                        </p>
                        <p>
                          <b> Waive off connector AC</b> :{" "}
                          {subscription.free_connector_ac}
                        </p>
                        <p>
                          <b> Waive off connector DC</b> :{" "}
                          {subscription.free_connector_dc}
                        </p>
                      </div>
                    </div>
                    <hr />
                    <div className="my-2">
                      <h5 className="font-semibold ">Transaction</h5>
                      <div className="flex gap-5">
                        <p>
                          <b> Transaction value Type</b> :{" "}
                          {subscription.per_txn_val_type}
                        </p>
                        <p>
                          <b> Per Trasaction :</b>{" "}
                          {subscription.per_txn_val_type == "Amount"
                            ? currencyFormat(subscription.per_connector_dc)
                            : subscription.per_txn_val_type == "Percent"
                            ? `${subscription.per_connector_dc}%`
                            : "--"}
                        </p>
                      </div>
                    </div>
                    <hr />
                    <div className="my-2">
                      <h5 className="font-semibold ">Unit</h5>
                      <div className="flex gap-5">
                        <p>
                          <b>Per unit</b> :{" "}
                          {currencyFormat(subscription.per_unit)}
                        </p>
                      </div>
                    </div>
                    <hr />

                    <p className="italic text-gray-400 ">
                      <b>Remarks </b> : {subscription.note}
                    </p>
                    <hr className="my-2" />
                    <p className="text-xs text-gray-400">
                      Id:{subscription.id}
                    </p>
                    <div className="flex justify-between gap-2 my-1">
                      <p className="text-xs ">
                        Created by :<br />
                        <div className="flex gap-1">
                          <div className="">
                            <b>{subscription.created_by_name ?? "N/A"}</b>
                          </div>
                          <div className="">
                            {subscription.created_at
                              ? moment(subscription.created_at).format(
                                  "DD MMM YYYY h:mm a"
                                )
                              : "--"}
                          </div>
                        </div>
                      </p>
                      <p className="text-xs ">
                        Updated by :<br />
                        <div className="flex gap-1">
                          <div className="">
                            <b>{subscription.updated_by_name ?? "N/A"}</b>
                          </div>
                          <div className="">
                            {subscription.updated_at
                              ? moment(subscription.updated_at).format(
                                  "DD MMM YYYY h:mm a"
                                )
                              : "--"}
                          </div>
                        </div>
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <SubscriptionConfigWidget
                    disabled={!subscriptionReq}
                    subscription={subscription}
                    onSubmit={(data) => {
                      setSubscription(data);
                      setEdit(false);
                    }}
                    onCancel={() => setEdit(false)}
                  />
                </>
              )}
            </>
            <p className="text-gray-400">
              Note : If subscription plan is not applicable then selected
              subscription will be discarded.
            </p>
          </div>
        ) : (
          <>
            <div className=" flex justify-between">
              <h3 className="font-semibold my-1"> Subscription Plan</h3>
              <button
                onClick={() => {
                  setOrder((o) => ({ ...o, monthly_recurring_fee: "Yes" }));
                  setSubscriptionReq(true);
                  setEdit(true);
                }}
                className="border rounded px-3 py-1"
              >
                Add Subscription
              </button>
            </div>
            <div className="h-96 flex flex-col justify-center items-center">
              <h2 className="text-3xl text-gray-400">
                Click Add subscription to set the subscription plan
              </h2>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SubscriptionSection;
