import { FunctionComponent, useState } from "react";
import { compressImage } from "../../infrastructure/utils/image_compressor";
import {
  updateInsllation,
  uploadInstallation,
} from "../../services/installationService";
import FileDropZone from "../../ui/new_components/common/file_drop_widget";
import Loader from "../../ui/new_components/common/loader";
import ToggleButton from "../../ui/new_components/form_components/toggle_button";
interface InstallationModel {
  [key: string]: any;
}
interface InstallationModel {
  id?: number;
  title: string;
  address: string;
  latitude: string;
  longitude: string;
  image_link?: string;
  city: string;
  state: string;
  active: boolean;
}

interface InstallationCreateProps {
  location: { state?: InstallationModel };
}
const initState = {
  title: "",
  address: "",
  latitude: "",
  longitude: "",
  city: "",
  state: "",
  active: true,
};
const InstallationCreate: FunctionComponent<InstallationCreateProps> = (
  props
) => {
  const { state } = props.location;
  const [preData, setPreData] = useState<InstallationModel>(
    state ? { ...state } : initState
  );

  const [image, setImage] = useState<File>();
  const [loading, setLoading] = useState(false);

  const onImageSelected = async (file: File) => {
    try {
      const compressedImage = await compressImage(file);

      setImage(compressedImage);
    } catch (error) {}
  };

  const onSubmit = async (e: any) => {
    try {
      e.preventDefault();
      const sure = window.confirm("Are you sure?");
      if (!sure) return;
      setLoading(true);
      const formdata = new FormData();
      if (image) {
        formdata.append("image", image);
        if (state) {
          formdata.append("image_link", state.image_link!);
        }
      }

      Object.keys(preData).forEach((k) => {
        formdata.append(k, preData[k]);
      });

      if (preData.id) {
        const res = await updateInsllation(formdata);
        if (res.success) alert(res.message);
        else alert(res.error);
      } else {
        const res = await uploadInstallation(formdata);

        if (res.success) {
          alert(res.message);
          setImage(undefined);
          setPreData(initState);
        } else alert(res.error);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  if (loading) return <Loader />;

  return (
    <>
      <div className="flex justify-center my-5 lg:my-10">
        <form
          onSubmit={onSubmit}
          className="hover:shadow-2xl border rounded px-5 py-8 w-full "
        >
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 lg:gap-5">
            <div className="flex flex-col">
              <label htmlFor="image" className=" text-lg">
                Display image
              </label>
              {(preData && preData.image_link) || image ? (
                <div className=" relative">
                  <img
                    alt="installation"
                    className="h-auto w-full rounded"
                    src={
                      image ? URL.createObjectURL(image) : preData!.image_link
                    }
                  ></img>
                  <div
                    onClick={() =>
                      image
                        ? setImage(undefined)
                        : setPreData((pre) => {
                            if (pre.image_link) delete pre.image_link;
                            return { ...pre };
                          })
                    }
                    className="absolute top-0 right-0  flex justify-center items-center p-3 bg-gray-100 rounded-full  cursor-pointer "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 text-red-400 transform transition-all duration-300 hover:text-red-700 hover:scale-125"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </svg>
                  </div>
                </div>
              ) : (
                <FileDropZone
                  onChange={(files) => onImageSelected(files[0])}
                  multiSelect={false}
                  type="image/*"
                />
              )}
            </div>
            <div className="">
              <div className="grid grid-cols-1 gap-3">
                <div className="flex flex-col">
                  <label htmlFor="title" className=" text-lg">
                    Title
                  </label>
                  <input
                    onChange={(e) => {
                      setPreData((pre) => ({ ...pre, title: e.target.value }));
                    }}
                    value={preData.title}
                    className="focus:outline-none p-2 bg-gray-100 rounded"
                    type="text"
                    name="title"
                    id="title"
                    required
                    placeholder="Enter the title of charging station"
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="address" className=" text-lg">
                    Address
                  </label>
                  <textarea
                    rows={5}
                    onChange={(e) => {
                      setPreData((pre) => ({
                        ...pre,
                        address: e.target.value,
                      }));
                    }}
                    value={preData.address}
                    className="focus:outline-none p-2 bg-gray-100 rounded"
                    name="address"
                    id="address"
                    required
                    placeholder="Enter the address of charging station"
                  />
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                  <div className="flex flex-col">
                    <label htmlFor="city" className=" text-lg">
                      city
                    </label>
                    <input
                      onChange={(e) => {
                        setPreData((pre) => ({ ...pre, city: e.target.value }));
                      }}
                      value={preData.city}
                      className="focus:outline-none p-2 bg-gray-100 rounded"
                      type="text"
                      name="city"
                      id="city"
                      required
                      placeholder="Enter the cty of charging station"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label htmlFor="state" className=" text-lg">
                      state
                    </label>
                    <input
                      onChange={(e) => {
                        setPreData((pre) => ({
                          ...pre,
                          state: e.target.value,
                        }));
                      }}
                      value={preData.state}
                      className="focus:outline-none p-2 bg-gray-100 rounded"
                      type="text"
                      name="state"
                      id="state"
                      required
                      placeholder="Enter the state of charging station"
                    />
                  </div>

                  <div className="flex flex-col ">
                    <label htmlFor="lat" className=" text-lg">
                      Latitude
                    </label>
                    <input
                      onChange={(e) => {
                        setPreData((pre) => ({
                          ...pre,
                          latitude: e.target.value,
                        }));
                      }}
                      value={preData.latitude}
                      className="focus:outline-none p-2 bg-gray-100 rounded"
                      type="text"
                      name="latitude"
                      id="latitude"
                      required
                      placeholder="Enter the Latitude of charging station"
                    />
                  </div>
                  <div className="flex flex-col ">
                    <label htmlFor="long" className=" text-lg">
                      longitude
                    </label>
                    <input
                      onChange={(e) => {
                        setPreData((pre) => ({
                          ...pre,
                          longitude: e.target.value,
                        }));
                      }}
                      value={preData.longitude}
                      className="focus:outline-none p-2 bg-gray-100 rounded"
                      type="text"
                      name="longitude"
                      id="long"
                      required
                      placeholder="Enter the longitude  of charging station"
                    />
                  </div>
                </div>
                <div className="mt-2 flex">
                  <div className="text-lg mr-2">Status : </div>
                  <ToggleButton
                    onToggle={(v) =>
                      setPreData((pre) => ({ ...pre, active: v }))
                    }
                    value={preData.active}
                    trueLabel="Live"
                    falseLabel="Offline"
                  />
                </div>
              </div>
              <div className="flex justify-center my-3">
                <button
                  className="px-5 py-2 mx-2 border border-red-400 hover:text-white rounded-3xl hover:bg-red-800"
                  type="reset"
                  onClick={() => {
                    setImage(undefined);

                    setPreData({ ...(state ? state : initState) });
                  }}
                >
                  Reset
                </button>
                <button
                  className="px-5 py-2 mx-2 bg-red-400 text-white rounded-3xl hover:bg-red-800"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default InstallationCreate;
