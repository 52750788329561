import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
export enum DrawerModalLoaction {
  left,
  right,
}
interface DrawerModalProps {
  show: boolean;
  title: string;
  location: DrawerModalLoaction;
  onClose: () => void;
}

const DrawerModal: FunctionComponent<DrawerModalProps> = (props) => {
  useEffect(() => {
    const cb = (ev: KeyboardEvent) => {
      if (ev.key === "Escape") {
        props.onClose();
      }
    };
    document.addEventListener("keyup", cb);
    return () => document.removeEventListener("keyup", cb);
  }, []);
  return (
    <div className=" relative  ">
      <div
        onClick={props.onClose}
        className="fixed top-0 left-0 right-0 w-screen h-screen  backdrop-filter backdrop-blur-xs z-10"
      ></div>

      <div
        style={{ height: "100vh" }}
        className={`fixed z-30  top-0 bottom-0 ${
          props.location === DrawerModalLoaction.left ? "left-0" : " "
        }  ${
          props.location === DrawerModalLoaction.right ? "right-0" : " "
        }   bg-white shadow rounded w-full md:w-1/3   transform duration-500 ease-in-out ${
          props.show ? "" : "translate-x-full"
        } ${
          props.location === DrawerModalLoaction.right
            ? "slide_drawer_rtl"
            : "slide_drawer_ltr"
        }`}
      >
        <div className=" shadow" style={{ height: "5%" }}>
          <div className="flex m-3">
            <div className="cursor-pointer" onClick={props.onClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
            <h5 className="mx-3">{props.title}</h5>
          </div>
        </div>
        <div className="overflow-y-auto  " style={{ height: "95%" }}>
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default DrawerModal;
