import parse from "html-react-parser";
import BlogStyles from "./tag_process.css";
// import Image from 'next/image';

// const renderText = (text) =>{
//   const regex=/([^\S]|^)?(((https?\:\/\/)|(www\.))(\S+))/gi;

//  return text
//     .split(" ")
//     .map((part,i) =>{
//       if(regex.test(part)){
//         const mt=part.match(regex)
//         const href=mt[0].trim();

//         const extraWords=part.split(part.match(regex));

//         return <a key={i} href={href}>Kazam ev </a>
//       }else return part + " ";
//     }
//       // regex.test(part) ? <a key={i} href={part}>{part} </a> : part + " "
//     );}
export const tagsProcessor = {
  paragraph: function paragraphProcessor(data) {
    return <p className={`${BlogStyles.Paragraph}`}>{parse(data.text)}</p>;
  },
  image: function imageProcessor(data) {
    return (
      //   <Image
      //   className={`my-5 ${BlogStyles.Image}`}
      //   src={data.file.url}
      //   alt={data.caption}
      //   width={600}
      //   height={315}
      // />
      <img
        className={`my-5 ${BlogStyles.Image}`}
        src={data.file.url}
        alt={data.caption}
      ></img>
    );
  },
  simpleImage: function imageProcessor(data) {
    return (
      // <Image
      // cla[[ssName={`my-5 ${BlogStyles.Image}`}
      // src={data.url}
      // alt={data.caption}
      // width={600}
      // height={315}
      // />
      <img
        className={`my-5 w-100 ${BlogStyles.Image}`}
        src={data.url}
        alt={data.caption}
      ></img>
    );
  },
  list: function listProcessor(data) {
    if (data.style === "unordered") {
      return (
        <ul>
          {data.items.map((item) => (
            <li key={item}>{parse(item)}</li>
          ))}
        </ul>
      );
    }
  },
  header: function headerProcessor(data) {
    if (data.level === 1) {
      return (
        <h1 className={`mt-5 mb-3 ${BlogStyles.Title}`}>{parse(data.text)}</h1>
      );
    }
    if (data.level === 2) {
      return <h2 className="mt-5 mb-3">{parse(data.text)}</h2>;
    }
    if (data.level === 3) {
      return <h3 className="mt-4 mb-2">{parse(data.text)}</h3>;
    }
    if (data.level === 4) {
      return <h4 className="mt-3 mb-2">{parse(data.text)}</h4>;
    }
    if (data.level === 5) {
      return <h5 className="mt-2 mb-1">{parse(data.text)}</h5>;
    }
    if (data.level === 6) {
      return (
        <h6 className="mt-2 mb-1" style={{ fontWeight: 700 }}>
          {parse(data.text)}
        </h6>
      );
    }
  },
  table: function tableProcessor(data) {
    return (
      <table className="table table-bordered">
        <thead>
          {data.content.map((row) => (
            <tr>
              <th style={{ width: "50%", textAlign: "center" }}>{row[0]}</th>
              <th style={{ width: "50%", textAlign: "center" }}>{row[1]}</th>
            </tr>
          ))}
        </thead>
      </table>
    );
  },
};
