import { fetchGet } from "../../api_client";
import { ApiResponse } from "../../models/response";

export const GetDiscomApi = async (): Promise<ApiResponse> => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/discom`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
