import { mdiReload } from "@mdi/js";
import Icon from "@mdi/react";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { urls } from "../../../../../../../utils/urls";
import NoRecordsFound from "../../../../components/common/noRecordsFound";
import { URLS } from "../../../../infrastructure/utils/urls";
import { GetAuthorsList } from "../../../../services/blogPostService";
import Loader from "../../../new_components/common/loader";
import Pagination from "../../../new_components/common/pagination";
import { AuthorModel } from "../create";

interface AuthorListingPageProps {}

const AuthorListingPage: FunctionComponent<AuthorListingPageProps> = () => {
  const [authors, setAuthors] = useState<AuthorModel[]>([]);
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [timeStamp, settimeStamp] = useState<string>();

  const history = useHistory();
  useEffect(() => {
    getAuthors();
  }, [page]);
  const getAuthors = async () => {
    setLoading(true);
    const res = await GetAuthorsList({ page, count });
    if (res.success) {
      setTotal(res.data.metadata[0].total);
      setAuthors(res.data.authors);
      settimeStamp(res.timeStamp);
    } else {
      alert(res.error);
    }
    setLoading(false);
  };

  const handleClick = (item: any) => {
    history.push({
      pathname: urls.authorEdit,
      state: item,
    });
  };
  if (loading) return <Loader />;

  return (
    <div className="p-5">
      <div className="flex flex-wrap justify-between  items-end mb-2">
        <div className="">
          <h1 className="font-bold text-2xl text-gray-500 ">Authors</h1>
          <div className="flex gap-2">
            <p className="text-xs text-gray-400">
              Fetched at :{timeStamp && moment(timeStamp).format("hh:mm:ss A")}
            </p>
            <span
              className="transform hover:scale-125 duration-300  cursor-pointer text-gray-400 hover:text-gray-800 "
              onClick={getAuthors}
            >
              <div title="Refresh" className="flex  items-center ">
                <Icon path={mdiReload} className="w-4 h-4"></Icon>
              </div>
            </span>
          </div>
        </div>
        <div className="flex gap-2 items-center flex-wrap">
          {/* <SearchFieldWidget
          placeholder="Search"
          defaulValue={filter.search ?? ""}
          onClear={() => {
            setFilter({ ...filter, page: 1, search: "" });
          }}
          onSubmit={(search) => {
            if (search) {
              setFilter({ ...filter, page: 1, search });
            }
          }}
        /> */}

          <Link
            title="Create new Blog post"
            to={urls.authorCreate}
            className="bg-myPrimaryColor text-white px-2 py-1 rounded text-sm"
          >
            Add new
          </Link>
        </div>
      </div>

      <div className=" flex justify-center" style={{ height: "83vh" }}>
        <div
          id="scroll_listener"
          className=" overflow-auto h-full border rounded-lg p-1"
        >
          {authors.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-3">
              {authors.map((a, i) => (
                <div
                  key={i}
                  className=" border rounded p-3 flex flex-col items-center"
                  onClick={() => handleClick(a)}
                >
                  <img
                    alt="profile_image"
                    className="h-36 w-36  rounded-full object-cover shadow"
                    src={a.img}
                  ></img>
                  <div className="mt-3 mb-2 text-center">
                    <h4 className="text-base font-semibold">{a.name}</h4>
                    <p
                      title={a.about}
                      className="text-sm font-normal max-h-16 overflow-ellipsis overflow-hidden"
                    >
                      {a.about}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <NoRecordsFound displayText="No Blogs found. Please create!" />
          )}
        </div>
      </div>

      <div className="mt-1">
        <Pagination
          page={page}
          itemCount={authors.length}
          total={total}
          onChange={setPage}
          count={count}
        />
      </div>
    </div>
  );
};

export default AuthorListingPage;
