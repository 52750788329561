import { mdiCheck, mdiClose, mdiDelete, mdiPencil, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import { DiscountDataModel } from "../../../../../service/models/orders/order_discount_model";
import OrderItemModel from "../../../../../service/models/orders/order_item_model";
import ProductModel, {
  OrderItemDataModel,
} from "../../../../../service/models/product_model";
import { DiscountType } from "../../../../../utils/enums";
const defaultItem: DiscountDataModel = {
  order_id: "",
  name: "",
  amount: 0,
  type: DiscountType.DIRECT,
};
interface DiscountRowWidgetProps {
  discount?: DiscountDataModel;
  onSubmit: (data: DiscountDataModel) => void;
  edit: boolean;
  onDelete?: (i: DiscountDataModel) => void;
  // form_id?: string;
}

const DicountRowWidget: FunctionComponent<DiscountRowWidgetProps> = (props) => {
  const { showToast } = useToast();

  const { edit, onSubmit } = props;
  const [discount, setExtra] = useState<DiscountDataModel>({
    ...(props.discount ?? defaultItem),
  });
  const [editable, setEditable] = useState(false);
  useEffect(() => {
    setEditable(edit);
  }, []);
  const onDone = () => {
    if (!discount.name || discount.amount < 1) {
      showToast({
        type: ToastType.warning,
        text: "Please Add description and amount",
      });
      return;
    }
    onSubmit(discount);
  };
  return (
    <>
      <form
        // id={props.form_id}
        onSubmit={(e) => {
          e.preventDefault();
          onDone();
        }}
        className="bg-white rounded-md shadow p-5 "
      >
        <div className="grid grid-cols-4 gap-3">
          <input
            value={discount?.name}
            onChange={(e) => {
              setExtra((ol) => ({ ...ol, name: e.target.value }));
            }}
            disabled={!editable}
            placeholder=""
            type="text"
            className="   p-1 rounded bg-gray-100 focus:outline-none col-span-2"
          />
          <input
            value={discount?.amount}
            onChange={(e) => {
              let v = 0;
              if (e.target.value && !isNaN(e.target.value as any)) {
                v = Number(e.target.value);
              }
              setExtra((ol) => ({ ...ol, amount: v }));
            }}
            disabled={!editable}
            placeholder=""
            type="text"
            className="text-right  p-1 rounded bg-gray-100 focus:outline-none"
          />

          <div className="flex gap-2 items-center justify-end">
            {props.discount ? (
              <>
                <div className="flex justify-center gap-2">
                  {editable ? (
                    <>
                      <div
                        onClick={() => {
                          if (!window.confirm("Are you sure !")) return;
                          onDone();
                          setEditable(false);
                        }}
                        className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                      >
                        <Icon path={mdiCheck} className="h-4 w-4"></Icon>
                      </div>
                      <div
                        onClick={() => {
                          setExtra(props.discount!);
                          setEditable(false);
                        }}
                        className="bg-red-100 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                      >
                        <Icon path={mdiClose} className="h-4 w-4"></Icon>
                      </div>
                    </>
                  ) : (
                    <div
                      onClick={() => setEditable(true)}
                      className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                    >
                      <Icon path={mdiPencil} className="h-4 w-4"></Icon>
                    </div>
                  )}
                </div>
                <div
                  onClick={() => {
                    if (!window.confirm("Are you sure !")) return;
                    if (props.onDelete) props.onDelete(discount);
                  }}
                  className="bg-red-200 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                >
                  <Icon path={mdiDelete} className="h-4 w-4"></Icon>
                </div>
              </>
            ) : (
              <button
                // form="item_form"
                className="flex gap-1 text-white text-sm bg-myPrimaryColor rounded-full px-2 py-1 hover:scale-110 transform duration-300"
              >
                <Icon path={mdiPlus} className="h-5 w-5 "></Icon> Add
              </button>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default DicountRowWidget;
