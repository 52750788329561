import { FunctionComponent } from "react";
import Sidebar from "../../components/Sidebar";
import ProtocolLayout from "../components/ProtocolLayout";

interface SalesTrainingProps {}

const SalesTraining: FunctionComponent<SalesTrainingProps> = () => {
  return (
    <ProtocolLayout>
      <div className="flex flex-col items-center">
        <div className="mt-20 w-11/12 lg:w-4/5">
          <div className="pb-32">
            <div className="text-xl lg:text-3xl font-semibold text-center lg:mt-12">
              Sales Training Schedule
            </div>
            {/* Day 1 */}
            <div className="flex flex-col lg:flex-row justify-around mt-12 lg:mt-24">
              <div className="lg:mt-6">
                <div className="text-xl lg:text-2xl font-semibold">Day 1</div>
                <ul className="list-disc mt-4 pl-6">
                  <li className="mt-3">
                    Company overview and business model- Team Leader (give sales
                    pitch and docs)
                  </li>
                  <li className="mt-3">
                    Explanation of the product and the website and the tech-
                    Sales Manager
                  </li>
                  <li className="mt-3">
                    Sales Manager to take a session on unit costing, payback
                    period
                  </li>
                  <li className="mt-3">
                    HR to Forward the sales (PITCH) doc for revision + All
                    learning videos
                  </li>
                </ul>
              </div>
              <div className="">
                <img
                  src="/kazam-academy/sales/salesTraining1.png"
                  alt=""
                  className="w-96"
                />
              </div>
            </div>
            {/* Day 2 */}
            <div className="flex flex-col lg:flex-row-reverse justify-around mt-12 lg:mt-24">
              <div className="lg:mt-6">
                <div className="text-xl lg:text-2xl font-semibold">Day 2</div>
                <ul className="list-disc mt-4 pl-6">
                  <li className="mt-3">Live Product Demo (ISM)</li>
                  <li className="mt-3">
                    Mock Calls One by One, Inside sales Manager
                  </li>
                  <li className="mt-3">
                    CRM/Salesforce training + set up CRM for them- ISM
                  </li>
                  <li className="mt-3">
                    5-10 Customer demo calls with recordings
                  </li>
                  <li className="mt-3">
                    Also set-up CRM on mobile with display fields- name, phone
                    number, responsible person, NAD
                  </li>
                </ul>
              </div>
              <div className="">
                <img
                  src="/kazam-academy/sales/salesTraining2.png"
                  alt=""
                  className="w-96"
                />
              </div>
            </div>{" "}
            {/* Day 3 */}
            <div className="flex flex-col lg:flex-row justify-around mt-12 lg:mt-24">
              <div className="lg:mt-6">
                <div className="text-xl lg:text-2xl font-semibold">Day 3</div>
                <ul className="list-disc mt-4 pl-6">
                  <li className="mt-3">
                    Full day Customer Demo calls, recording and feedback
                  </li>
                  <li className="mt-3">
                    Also, (Written test in the format given by Vaibhav- min
                    score required to pass 5/10)
                  </li>
                </ul>
              </div>
              <div className="">
                <img
                  src="/kazam-academy/sales/salesTraining3.png"
                  alt=""
                  className="w-96"
                />
              </div>
            </div>{" "}
            {/* Day 4 */}
            <div className="flex flex-col lg:flex-row-reverse justify-around mt-12 lg:mt-24">
              <div className="lg:mt-6">
                <div className="text-xl lg:text-2xl font-semibold">Day 4</div>
                <ul className="list-disc mt-4 pl-6">
                  <li className="mt-3">Actual calls or field sales</li>
                </ul>
              </div>
              <div className="">
                <img
                  src="/kazam-academy/sales/salesTraining4.png"
                  alt=""
                  className="w-96"
                />
              </div>
            </div>
            <div className="mt-20 flex flex-col items-center">
              <div className="">
                <div className="">Please Note*</div>
                <div className="text-xl font-bold italic ">
                  CMS training to be given, if required, by Key Account Manager/
                  CMS team
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ProtocolLayout>
  );
};

export default SalesTraining;
