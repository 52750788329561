// @ts-ignore
import gsap from "gsap/all";
import { FunctionComponent, useEffect, useState } from "react";
import { Film, Menu, Save, X } from "react-feather";
import { NavLink } from "react-router-dom";
import { useAuth } from "../../../../context_providers/auth/authProvider";
import { ACTION, MODULE } from "../../../../utils/enums";
import { urls } from "../../../../utils/urls";

interface AcademyNavbarProps {}

const AcademyNavbar: FunctionComponent<AcademyNavbarProps> = () => {
  const { user, isAuthorised } = useAuth();
  const [showMenu, setShowMenu] = useState<boolean>(false);

  return (
    <>
      <div
        className={`fixed top-0 left-0 w-screen h-screen bg-black text-white z-30 ${
          showMenu ? "flex lg:hidden " : "hidden"
        } flex-col items-center justify-center`}
      >
        <div className="absolute top-4 right-4">
          <X
            className="text-white"
            size={32}
            onClick={() => setShowMenu(false)}
          />
        </div>
        <div className="text-xl">
          <NavLink to={urls.protocol_category}>
            <div className="">Protocols</div>
          </NavLink>
          <NavLink to={urls.videos_category}>
            <div className="mt-6">Videos</div>
          </NavLink>
          <NavLink to={urls.upload_academy}>
            <div
              className={`   ${
                isAuthorised({
                  module: MODULE.academy,
                  action: [ACTION.UPDATE],
                })
                  ? "block"
                  : "hidden"
              }  mt-6`}
            >
              Upload video
            </div>
          </NavLink>
          <div className="mt-6">Saved protocols</div>
          <div className="mt-6">Saved videos</div>
        </div>
      </div>

      <div className="bg-black py-3 lg:py-4 text-white flex justify-between">
        <div className="flex items-center gap-2 ml-3 lg:ml-6">
          <div className="">
            <NavLink to={urls.root}>
              <img src="/kazam-text-logo.png" alt="" className="" />
            </NavLink>
          </div>
        </div>
        <div className="mr-6 hidden lg:flex gap-12 justify-between items-center">
          <NavLink to={urls.protocol_category}>
            <div className="">Protocols</div>
          </NavLink>
          <NavLink to={urls.videos_category}>
            <div className="">Learnings</div>
          </NavLink>
          <div className="">
            <NavLink to={urls.upload_academy}>
              <button
                className={`   ${
                  isAuthorised({
                    module: MODULE.academy,
                    action: [ACTION.UPDATE],
                  })
                    ? "block"
                    : "hidden"
                }  py-2 px-4 rounded text-white text-sm font-semibold bg-hrmPurple`}
              >
                Upload
              </button>
            </NavLink>
          </div>
          <div className="">
            <div className="relative nav_dropdown">
              <img
                src={user?.profile_image}
                alt=""
                className="w-8 h-8 rounded-full"
              />
              <div className="absolute right-0 z-20 bg-white rounded-lg text-black py-4 px-6 nav_sub">
                <NavLink to={urls.saved_videos}>
                  <div className="flex gap-2 items-center">
                    <Save size={18} />
                    <div className="whitespace-nowrap">Saved vidoes</div>
                  </div>
                </NavLink>
                <NavLink to={urls.watched_videos}>
                  <div className="flex gap-2 items-center mt-3">
                    <Film size={18} />
                    <div className="whitespace-nowrap">Watched videos</div>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:hidden mr-3">
          <Menu
            className="text-white"
            size={28}
            onClick={() => setShowMenu(true)}
          />
        </div>
      </div>
    </>
  );
};

export default AcademyNavbar;
