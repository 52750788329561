import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import ProtectedRoute from "./ui/components/protected-route";

import NotFound from "./ui/pages/NotFound";
import { AuthProvider } from "./context_providers/auth/authProvider";
import main_routes, { RouteParam } from "./routes";
import ModalProvider from "./context_providers/modal/modal_context";
import { useEffect, useState } from "react";
import ModalDialog from "./ui/new_components/common/modal";
import {
  mdiMonitorArrowDownVariant,
  mdiCellphoneArrowDownVariant,
} from "@mdi/js";
import Icon from "@mdi/react";

const App = () => {
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const [showInstallPrompt, setShowInstallPrompt] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState<any>();
  useEffect(() => {
    const handleBeforeInstallPrompt = (event: any) => {
      event.preventDefault();

      // Check if the PWA is already installed

      if (isPwaInstalled()) {
        // console.log("PWA is already installed");
        return;
      }

      // Show the custom install prompt

      if (!deferredPrompt) {
        setShowInstallPrompt(true);
        setDeferredPrompt(event);
      }
    };
    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    window.addEventListener("appinstalled", handleAppInstalled);
    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
      window.removeEventListener("appinstalled", handleAppInstalled);
    };
  }, [deferredPrompt]);

  const handleAppInstalled = () => {
    // console.log("PWA was installed");
    setShowInstallPrompt(false);
  };

  const installApp = () => {
    // console.log({ deferredPrompt });

    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === "accepted") {
          // console.log("User accepted the install prompt");
        } else {
          // console.log("User dismissed the install prompt");
        }
        setShowInstallPrompt(false);
        // setDeferredPrompt(null);
      });
    }
  };

  const skipInstall = () => {
    // Handle the case when the user chooses to skip the installation
    setShowInstallPrompt(false);
  };
  const neverInstall = () => {
    // Handle the case when the user chooses to skip the installation
    localStorage.setItem("NEVER_INSTALL", "true");
    setShowInstallPrompt(false);
  };
  const isPwaInstalled = () => {
    // check if user op for never install

    const never = localStorage.getItem("NEVER_INSTALL");
    if (never == "true") return true;
    // Check if the PWA is already installed on different platforms

    if (window.matchMedia("(display-mode: standalone)").matches) {
      // console.log("already installed in other browser");

      // Other browsers supporting display-mode media query
      return true;
    } else if (window.navigator && "standalone" in window.navigator) {
      // console.log("already installed in IOS");
      // Safari on iOS
      return true;
    }
    return false;
  };

  useEffect(() => {
    const online = (event: Event) => {
      setShowAlert(false);
    };
    const offline = (event: Event) => {
      setShowAlert(true);
    };
    window.addEventListener("online", online);
    window.addEventListener("offline", offline);

    return () => {
      window.removeEventListener("online", online);
      window.removeEventListener("offline", offline);
    };
  }, []);

  const generateRoutes = (routes: RouteParam[]) => {
    return routes.map((props, i) =>
      props.isPrivate ? (
        <ProtectedRoute
          fullScreen={props.fullScreen}
          main_module={props.main_module}
          module={props.module}
          action={props.action}
          operator={props.operator}
          isPrivate={props.isPrivate}
          exact={props.exact}
          key={props.path}
          path={props.path}
          routeParam={props}
          component={props.component}
        >
          {props.routes && <>{generateRoutes(props.routes)} </>}
        </ProtectedRoute>
      ) : (
        <Route exact path={props.path} component={props.component} />
      )
    );
  };
  return (
    <div className="bg-myBgcolor">
      {showInstallPrompt && (
        <ModalDialog
          show={showInstallPrompt}
          title={"Installation"}
          onClose={skipInstall}
        >
          <div className="w-full sm:w-96 bg-cardColor dark:bg-darkCardColor p-5 flex flex-col items-center">
            <Icon
              path={mdiMonitorArrowDownVariant}
              size={3}
              className="text-gray-400 hidden md:block"
            />
            <Icon
              path={mdiCellphoneArrowDownVariant}
              size={3}
              className="text-gray-400  md:hidden"
            />
            <div className="text-sm my-5">
              <p className="">
                Install the app for a better and enhanced experience.
              </p>
              <p className="italic font-semibold">
                Recomended for mobile users
              </p>
              {/* <div className="flex items-center gap-1">
                <input type="checkbox" name="rem" id="rem" />{" "}
                <label htmlFor="rem">Don't show me again</label>
              </div> */}
            </div>

            <div className="flex gap-5 text-sm">
              <button
                onClick={installApp}
                className="border border-myPrimaryColor bg-myPrimaryColor text-white rounded px-3 py-1 "
              >
                Install
              </button>
              <button
                onClick={skipInstall}
                className="border border-myPrimaryColor text-myPrimaryColor rounded px-3 py-1"
              >
                Skip
              </button>
              <button
                onClick={neverInstall}
                className="border border-red-500 text-red-500 rounded px-3 py-1"
              >
                Never
              </button>
            </div>
          </div>
        </ModalDialog>
      )}
      {showAlert && (
        <div className="fixed top-3 left-1/2 transform -translate-x-1/2 py-2 px-6 rounded-md bg-red-600 text-white bg-opacity-60 z-40">
          <div className="font-bold">Your internet is experiencing issues</div>
          <div className="text-sm ">Please check your internet connection</div>
        </div>
      )}
      <Router>
        <AuthProvider>
          <ModalProvider>
            <Switch key={Math.random()}>
              {generateRoutes(main_routes)}
              {/* {main_routes.map((props) =>
                props.isPrivate ? (
                  <ProtectedRoute
                    fullScreen={props.fullScreen}
                    main_module={props.main_module}
                    module={props.module}
                    action={props.action}
                    operator={props.operator}
                    isPrivate={props.isPrivate}
                    exact={props.exact}
                    key={props.path}
                    path={props.path}
                    routeParam={props}
                    component={props.component}
                  >
                    {" "}
                  </ProtectedRoute>
                ) : (
                  <Route exact path={props.path} component={props.component} />
                )
              )} */}

              <Route path="*" component={NotFound} />
            </Switch>{" "}
          </ModalProvider>
        </AuthProvider>
      </Router>
    </div>
  );
};
export default App;
//

// hello world 1
