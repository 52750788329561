import { BaseModel } from "../../../service/models/base";
export const account_group = {
  branch: {
    main_group: "primary",
    name: "Branch/Division",
  },
  current_asset: {
    main_group: "primary",
    name: "Current Assets",
  },
  indirect_income: {
    main_group: "primary",
    name: "Indirect Income",
  },
  capital_ac: {
    main_group: "primary",
    name: "Capital Accounts",
  },
  fixed_assets: {
    main_group: "primary",
    name: "Fixed Assets",
  },
  misc_expense: {
    main_group: "primary",
    name: "Misc. Expenses",
  },
  loan: {
    main_group: "primary",
    name: "Loans",
  },
  invenstment: {
    main_group: "primary",
    name: "Investments",
  },
  purchase_ac: {
    main_group: "primary",
    name: "Purchase Accounts",
  },
  suspense_ac: {
    main_group: "primary",
    name: "Suspense Account",
  },
  sales_ac: {
    main_group: "primary",
    name: "Sales Accounts",
  },
  direct_income: {
    main_group: "primary",
    name: "Direct Income",
  },
  current_liabilities: {
    main_group: "primary",
    name: "Current Liabilities",
  },
  indirect_expense: {
    main_group: "primary",
    name: "Indirect Expenses",
  },
  direct_expense: { main_group: "primary", name: "Direct Expenses" },
};

export const account_sub_group = {
  sundry_creditors: {
    main_group: account_group.current_liabilities.name,
    name: "Sundry Creditors",
  },
  secured_loans: {
    main_group: account_group.loan.name,
    name: "Secured Loans",
  },
  banks_OD_ccounts: {
    main_group: account_group.loan.name,
    name: "Banks OD Accounts",
  },
  unsecured_loans: {
    main_group: account_group.loan.name,
    name: "Unsecured Loans",
  },
  bank_accounts: {
    main_group: account_group.current_asset.name,
    name: "Bank Accounts",
  },
  stock_in_hand: {
    main_group: account_group.current_asset.name,
    name: "Stock in Hand",
  },
  deposit: {
    main_group: account_group.current_asset.name,
    name: "Deposits",
  },
  cash_in_hand: {
    main_group: account_group.current_asset.name,
    name: "Cash in Hand",
  },
  duties_and_taxes: {
    main_group: account_group.current_liabilities.name,
    name: "Duties & Taxes",
  },
  loan_advances: {
    main_group: account_group.current_asset.name,
    name: "Loan & Advances (Assets)",
  },
  provisions: {
    main_group: account_group.current_liabilities.name,
    name: "Provisions",
  },
  reserves_surplus: {
    main_group: account_group.capital_ac.name,
    name: "Reserves & Surplus",
  },
  sundry_debtors: {
    main_group: account_group.current_asset.name,
    name: "Sundry Debtors",
  },
};
export const getLedgerGroups = () => {
  return [...Object.values(account_group), ...Object.values(account_sub_group)];
};
export interface LedgerGroupModel extends BaseModel {
  main_group: string;
  name: string;
}

export const ledgerGroupDefaultValue: LedgerGroupModel = {
  main_group: "",
  name: "",
};
