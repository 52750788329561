import { useState, useRef, useEffect, FunctionComponent } from "react";

import Transition from "../common/transition";

interface DropDownMenuProps {
  title: any;
  options: any;
  open?: boolean;
}

const DropDownMenu: FunctionComponent<DropDownMenuProps> = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef<any>(null);
  const dropdown = useRef<any>(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = (e: any) => {
      if (
        !dropdownOpen ||
        dropdown.current?.contains(e.target) ||
        trigger.current?.contains(e.target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = (e: any) => {
      if (!dropdownOpen || e.keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <div className="relative inline-flex">
      <button
        type="button"
        ref={trigger}
        className="inline-flex justify-center items-center group focus:outline-none"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        {props.title}
      </button>

      <Transition
        className="origin-top-right z-10 absolute top-full right-0 min-w-min bg-white border border-gray-200 py-1.5 rounded shadow-lg overflow-hidden mt-1"
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          style={{
            width: "8rem ",
          }}
          className=""
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          {props.options}
        </div>
      </Transition>
    </div>
  );
};

export default DropDownMenu;
