import { FunctionComponent } from "react";
import Sidebar from "../../components/Sidebar";
import ProtocolLayout from "../components/ProtocolLayout";

interface ComplaintProps {}

const Complaint: FunctionComponent<ComplaintProps> = () => {
  return (
    <ProtocolLayout>
      <div className="flex flex-col items-center">
        <div className="mt-20 w-11/12 lg:w-4/5">
          <div className=" flex flex-col items-center mb-12">
            <div className="text-xl lg:text-3xl font-semibold">
              Complaint Management Protocol
            </div>
            <div className=" mt-4 lg:mt-12 ">
              <img
                src="/images/kazam-academy/complaint.jpeg"
                alt=""
                className="transform"
              />
            </div>
            <div className="italic mt-2">
              *Collection and appointment is carried out via ops team as of now
            </div>
            <div className="mt-6 lg:mt-20">
              <div className="text-2xl font-semibold">
                Complaint Management Flow
              </div>
              <ul className="mt-4  pl-6">
                <li>
                  <b> Step 1:</b> Please communicate all your issues to support
                  phone 9958943092 -call or whatsapp or to support mail
                  support@kazam.in. Service desk will create a ticket. Make sure
                  you get your 'ticket id' for tracking. (only support team can
                  create ticket)
                </li>{" "}
                <br />
                <li>
                  <b> Step 2:</b> Service desk will try to solve the issue by
                  themselves first else they will raise or assign it. Raising is
                  for urgent issues and assigning is for non-urgent issues which
                  can be solved in 48 hours. (for example, an EV driver not
                  being able to charge is an urgent issue whereas a host
                  complaining about app wallet can be solved within 48 hours)
                  <br />
                  Support team can contact the customer for in-depth
                  understanding of the issue. In case the complaint can be
                  resolved at support level itself, like general guidance to
                  drivers and hosts, APP training, CMS training, then it will be
                  closed at support level itself (based on the tech support
                  handbook) In case complaint is urgent, related to EV driver,
                  immediate escalation has to happen to concerned department
                  (tech or ops), Else the concerned department can take 48 hours
                  to respond
                </li>
                <br />
                <li>
                  <b> Step 3:</b> Level 2 includes tech team and operations
                  team. They will try to solve issues and if not solved, issues
                  will reach the tech leads
                </li>
                <br />
                <li>
                  <b> Step 4:</b> Weekly catch-up to happen between various
                  departments to implement the learning’s from complaints. (to
                  be conducted by Vaibhav). In case the issue is unsolved for
                  more than 48 hours, escalation to happen to department heads
                  via the portal. No complaints to be left in “Pending” state.
                </li>
                <br />
              </ul>
            </div>

            <div className=" mt-4 lg:mt-12 flex gap-2 ">
              <img
                src="/images/kazam-academy/complaint_flow.jpeg"
                alt=""
                className="transform"
              />
            </div>
          </div>
        </div>
      </div>
    </ProtocolLayout>
  );
};
export default Complaint;
