// The download function takes a CSV string, the filename and mimeType as parameters
// Scroll/look down at the bottom of this snippet to see how download is called
export const exportCSV = function (data: string, fileName?: string) {
  // Building the CSV from the Data two-dimensional array
  // Each column is separated by ";" and new line "\n" for next row

  let csvContent = "data:text/csv;charset=utf-8," + data;

  var a = document.createElement("a");
  var encodedUri = encodeURI(csvContent);
  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", (fileName ?? "data") + ".csv");
  document.body.appendChild(link); // Required for FF

  link.click(); // This will download the data file named "my_data.csv".
  document.body.removeChild(link);
};
