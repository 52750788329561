import { mdiPlus, mdiRefresh } from "@mdi/js";
import Icon from "@mdi/react";
import debounce from "lodash.debounce";
import moment from "moment";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import LoadingWidget from "../../../../context_providers/modal/loader";
import { useModal } from "../../../../context_providers/modal/modal_context";
import {
  useToast,
  ToastType,
} from "../../../../context_providers/toast/toast_ctx";
import { FieldType } from "../../../../procurement/components/create_view/form_field";
import TableView from "../../../../procurement/components/table_comp";
import {
  fetchDelete,
  fetchGet,
  toQueryString,
} from "../../../../service/api_client";
import SearchFieldWidget from "../../../../ui/new_components/search_field";
import { UTCToLocalDate } from "../../../../utils/date_util";
import { urls } from "../../../../utils/urls";
import { RecieptVoucherModel } from "../../models/receipt_model";
import { SalesVoucherModel } from "../../models/sale_model ";

interface RecieptVoucherListScreenProps {}

const RecieptVoucherListScreen: FunctionComponent<
  RecieptVoucherListScreenProps
> = () => {
  const navaigation = useHistory();
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/accounts/voucher/reciept`;
  const { showModal, onClose } = useModal();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(50);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<RecieptVoucherModel[]>([]);
  const [search, setSearch] = useState("");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  useEffect(() => {
    const now = new Date();
    let from = new Date(new Date(now).setHours(0, 0, 0, 0));
    from = new Date(from.setDate(1));
    let to = new Date(new Date(now).setHours(23, 59, 59, 999));
    to = new Date(to.setMonth(now.getMonth() + 1));
    to = new Date(to.setDate(0));
    setFromDate(from);
    setToDate(to);
  }, []);
  const fetchData = async (prop: {
    search?: string;
    page: number;
    count: number;
    all?: boolean;
    fromDate: Date;
    toDate: Date;
  }) => {
    setLoading(true);
    try {
      const res = await fetchGet(url + toQueryString(prop));
      if (res.success) {
        setData(res.data.data);
        if (res.data.metadata) {
          setTotal(res.data.metadata.total);
        }
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
      //   return { success: false, error: error.message };
    }
    setLoading(false);
  };
  const debouncedHandler = useCallback(
    debounce(
      (prop: {
        search?: string;
        page: number;
        count: number;
        all?: boolean;
        fromDate: Date;
        toDate: Date;
      }) => {
        if (prop) fetchData(prop);
        else setData([]);
      },
      100
    ),
    []
  );
  useEffect(() => {
    debouncedHandler({ page, count, search, fromDate, toDate });
  }, [page, fromDate, toDate]);

  // useEffect(() => {
  //   fetchData({ page, count, search });
  // }, [page]);
  // useEffect(() => {
  //   if (search) fetchData({ page, count, search });
  // }, [search]);
  // // return <LedgerCreateScreen />;

  const onSelect = (d: RecieptVoucherModel) => {
    navaigation.push(urls.receipt_voucher + "/update/" + d.id);
  };

  const onDelete = async (d: RecieptVoucherModel) => {
    const propmt = window.prompt("Type 'delete' to confirm.");
    if (!propmt || propmt?.trim() !== "delete") {
      return;
    }
    setLoading(true);
    const res = await fetchDelete(url + "/" + d.id);
    if (res.success) {
      await fetchData({ page, count, search, fromDate, toDate });
      showToast({ type: ToastType.success, text: res.message });
    } else {
      showToast({ type: ToastType.error, text: res.error });
    }
    setLoading(false);
  };
  return (
    <>
      <div
        id="page_container"
        className="flex flex-col justify-between h-full p-1"
      >
        <section
          id="top_section"
          className="flex justify-between items-end my-1 "
        >
          <h1 className="font-bold text-2xl text-gray-500 flex items-center gap-3">
            <p className="border-l-4 border-myPrimaryColor pl-2">
              Receipt vouchers
            </p>
            <button
              title="Refersh"
              onClick={() =>
                debouncedHandler({ page, count, search, fromDate, toDate })
              }
            >
              <Icon path={mdiRefresh} className="h-6 w-6"></Icon>
            </button>
          </h1>
          <div className="flex justify-end   text-sm items-center gap-1 ">
            <div className="text-sm flex items-center gap-2">
              <div className="flex items-center  gap-1">
                <label htmlFor="fromDate" className="">
                  From
                </label>
                <input
                  type="date"
                  name=""
                  id="fromDate"
                  className="p-1 rounded "
                  value={moment(fromDate).format("YYYY-MM-DD")}
                  onChange={(e) => {
                    const now = UTCToLocalDate(e.target.value)!;
                    const from = new Date(new Date(now).setHours(0, 0, 0, 0));

                    setFromDate(from);
                  }}
                />
              </div>
              <div className="flex items-center  gap-1">
                <label htmlFor="toDate" className="">
                  To
                </label>
                <input
                  type="date"
                  name=""
                  id="toDate"
                  className="p-1 rounded "
                  value={moment(toDate).format("YYYY-MM-DD")}
                  onChange={(e) => {
                    const now = UTCToLocalDate(e.target.value)!;
                    const to = new Date(
                      new Date(now).setHours(23, 59, 59, 999)
                    );
                    setToDate(to);
                  }}
                />
              </div>
            </div>
            {/* <SearchFieldWidget
              defaulValue={search}
              onClear={() => {
                setSearch("");
                fetchData({ page: 1, count });
              }}
              onSubmit={(val) => {
                fetchData({ page: 1, count, search: val });
                setPage(1);
                setSearch("");

                // setTimeout(() => {
                //   setSearch(val);
                // }, 10);
              }}
            /> */}
            <div
              onClick={() => {
                navaigation.push(urls.receipt_voucher + "/create");
              }}
              className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
            >
              <Icon path={mdiPlus} className="h-4 w-4"></Icon>Add
            </div>
          </div>
        </section>{" "}
        <TableView
          headers={[
            {
              key: "date",
              title: "Date",
              type: FieldType.date,
              onClick: (d) => {},
            },
            {
              colspan: 4,
              key: "party",
              title: "Particulars",
              type: FieldType.long_string,
            },
            {
              key: "voucher",
              title: "Voucher type",
              type: FieldType.string,
            },
            {
              key: "debit",
              title: "Debit",
              type: FieldType.string,
            },
            {
              key: "credit",
              title: "Credit",
              type: FieldType.string,
            },
          ]}
          rows={data.map((data, i) => ({
            data: data,
            values: [
              data.date,
              data.party_ledger_entry?.ledger?.name,
              data.voucher_type,
              "--",
              data.total,
            ],
            onClick: onSelect,
          }))}
          page={page}
          total={total}
          count={count}
          itemCount={data.length}
          onPageChange={setPage}
          onClick={onSelect}
          onEdit={onSelect}
          onDelete={onDelete}
        />
      </div>

      {loading && <LoadingWidget></LoadingWidget>}
    </>
  );
};

export default RecieptVoucherListScreen;
