import {
  mdiCheck,
  mdiClose,
  mdiPencil,
  mdiDelete,
  mdiPlus,
  mdiChevronUp,
  mdiChevronDown,
  mdiTools,
  mdiDotsVertical,
  mdiChevronRight,
} from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useState } from "react";
import {
  useToast,
  ToastType,
} from "../../../../context_providers/toast/toast_ctx";
import {
  OpsTaskDeviceItem,
  OpsTaskDeviceItemResolution,
  OpsTaskDeviceItemStatus,
  getDefaultTaskDeviceItem,
} from "../../../../service/models/ops/ops_ticket";
import InventorySearchField from "./device_search_field";
import { InventoryModel } from "../../../../service/models/inventory/inventory_model";
import InventoryDropDown from "./inventory_dropdown";
import DropDownMenu from "../../content_panel/ui/new_components/common/drop_down_menu";
import ModalWidget from "../../../../context_providers/modal/modal_widget";
import {
  ModalAlignment,
  ModalType,
  useModal,
} from "../../../../context_providers/modal/modal_context";
import {
  ItemModel,
  itemDefaultValue,
} from "../../../../erp/inventory/models/item_model";
import ItemSearchField from "../../../../erp/inventory/items/item_search_field";
import { numberRegEx } from "../../../../utils/constant";

interface DeviceComponentRowProps {
  data?: { item: ItemModel; qty: number; id: any };
  onSubmit: (data: { item: ItemModel; qty: number; id: any }) => void;
  edit: boolean;
  disabled?: boolean;
  onDelete?: (i: { item: ItemModel; qty: number; id: any }) => void;
}

const DeviceComponentRow: FunctionComponent<DeviceComponentRowProps> = (
  props
) => {
  const [data, setData] = useState<{ item: ItemModel; qty: number; id: any }>({
    id: "",
    item: { ...itemDefaultValue },
    qty: 0,
  });
  const { showToast } = useToast();
  const { onClose, showModal } = useModal();
  const [showMore, setShowMore] = useState(false);
  const { edit, onSubmit } = props;

  const [editable, setEditable] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setEditable(edit);
  }, []);
  const onDone = () => {
    if (!data.qty || !data.item) {
      showToast({
        type: ToastType.warning,
        text: "Please select item and qty",
      });
      return;
    }
    onSubmit(data);
  };

  useEffect(() => {
    if (props.data) setData({ ...props.data });
  }, [props.data]);

  // const [showComponent, setshowComponent] = useState(false);

  return (
    <>
      {/* {showComponent && (
        <ModalWidget
          data={{
            title: "Components",
            id: 1,
            type: ModalType.drawer,
            alignment: ModalAlignment.right,
          }}
          onClose={function (): void {
            setshowComponent(false);
            onClose(1);
          }}
        ></ModalWidget>
      )} */}
      <form
        onSubmit={(e) => {
          e.preventDefault();

          onDone();
        }}
        className="bg-white   text-sm  "
      >
        <div className="grid grid-cols-4 gap-2">
          <div className="col-span-2">
            <ItemSearchField
              disabled={!editable || props.disabled}
              value={data.item}
              onSelect={function (item: ItemModel): void {
                setData((o) => ({
                  ...o,
                  item,
                }));
              }}
            />
          </div>

          <div className="grid grid-cols-4 gap-1 items-center">
            <input
              value={data?.qty}
              onChange={(e) => {
                const val = e.target.value.trim();
                if (!val || numberRegEx.test(val)) {
                  const value = Number(val || 0);
                  setData((ol) => ({
                    ...ol,
                    qty: value,
                  }));
                }
              }}
              disabled={!editable || props.disabled}
              placeholder=""
              type="number"
              className="text-right  p-1 focus:outline-none border rounded col-span-3"
            />
            <div className="">{data.item?.unit.symbol}</div>
          </div>

          {/* <input
            type="text"
            value={data.remark}
            disabled={!editable || props.disabled}
            className="border rounded p-1 focus:outline-none outline-none col-span-2"
            onChange={(e) => {
              setData((o) => ({ ...o, remark: e.target.value }));
            }}
          /> */}

          <div className="flex gap-2 items-center justify-center ">
            {props.data ? (
              !props.disabled && (
                <>
                  <div className="flex justify-center gap-2">
                    {editable ? (
                      <>
                        <div
                          onClick={() => {
                            // if (!window.confirm("Are you sure !")) return;
                            onDone();
                            setEditable(false);
                          }}
                          className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                        >
                          <Icon path={mdiCheck} className="h-4 w-4"></Icon>
                        </div>
                        <div
                          onClick={() => {
                            setData(props.data!);
                            setEditable(false);
                          }}
                          className="bg-red-100 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                        >
                          <Icon path={mdiClose} className="h-4 w-4"></Icon>
                        </div>
                      </>
                    ) : (
                      <div
                        onClick={() => setEditable(true)}
                        className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                      >
                        <Icon path={mdiPencil} className="h-4 w-4"></Icon>
                      </div>
                    )}
                  </div>
                  <div
                    onClick={() => {
                      if (!window.confirm("Are you sure !")) return;
                      if (props.onDelete) props.onDelete(data);
                    }}
                    className="bg-red-200 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                  >
                    <Icon path={mdiDelete} className="h-4 w-4"></Icon>
                  </div>
                </>
              )
            ) : (
              <button
                // form="item_form"
                className="flex gap-1 text-white text-sm bg-myPrimaryColor rounded-full px-2 py-1  "
              >
                <Icon path={mdiPlus} className="h-5 w-5 "></Icon> Add
              </button>
            )}
            {/* {props.data && (
              <div
                onClick={() => setShowMore((o) => !o)}
                className="p-1 bg-gray-100 rounded cursor-pointer hover:bg-gray-200"
              >
                <Icon
                  path={showMore ? mdiChevronUp : mdiChevronDown}
                  className="h-4 w-4"
                ></Icon>
              </div>
            )} */}
          </div>
        </div>
        {/* {showMore && props.item && (
          <div className="px-2 py-1">
            <hr className=" " />
            <div className="text-xs text-gray-400">
              <p>Id : {props.item.id}</p>
              <p>Extra : {rupeeFormat(props.item.addition_amt ?? 0)}</p>
              <p>Discount : {rupeeFormat(props.item.discount_amt ?? 0)}</p>
              <p>Paid : {rupeeFormat(props.item.paid ?? 0)}</p>
            </div>
            <div className="flex   justify-between gap-2 my-1">
              <p className="text-xs ">
                Created by :<br />
                <div className="flex gap-1">
                  <div className="">
                    <b>{props.item.created_by_name ?? "N/A"}</b>
                  </div>
                  <div className="">
                    {props.item.created_at
                      ? moment(props.item.created_at).format(
                          "DD MMM YYYY h:mm a"
                        )
                      : "--"}
                  </div>
                </div>
              </p>
              <p className="text-xs ">
                Updated by :<br />
                <div className="flex gap-1">
                  <div className="">
                    <b>{props.item.updated_by_name ?? "N/A"}</b>
                  </div>
                  <div className="">
                    {props.item.updated_at
                      ? moment(props.item.updated_at).format(
                          "DD MMM YYYY h:mm a"
                        )
                      : "--"}
                  </div>
                </div>
              </p>
            </div>
          </div>
        )} */}
      </form>
    </>
  );
};

export default DeviceComponentRow;
