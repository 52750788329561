import { FunctionComponent, useState } from "react";
import { UserModel } from "../../../../../context_providers/auth/user_model";
import "../../styles/visiting-card.css";
import html2canvas from "html2canvas";
import HrmLoader from "../../components/loading";

interface VisitingCardProps {
  user: UserModel;
}

const VisitingCard: FunctionComponent<VisitingCardProps> = ({ user }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const downloadId = (classname: any, filename: string) => {
    const divElement = document.querySelector(classname);
    // @ts-ignore
    html2canvas(divElement).then((canvas) => {
      const imgDataUrl = canvas.toDataURL("image/png");
      const linkElement = document.createElement("a");
      console.log(linkElement);

      linkElement.download = filename;
      linkElement.href = imgDataUrl;
      document.body.appendChild(linkElement);
      linkElement.click();
      document.body.removeChild(linkElement);
    });
    setLoading(false);
  };

  return (
    <div className="">
      <div className="lg:flex gap-6">
        {/* cards front */}
        <div className="id-card-front-wrapper">
          <div className="id-front-wrapper  bg-white relative">
            <div className="">
              <img
                src="/images/id-card/kazamLogo.png"
                alt=""
                className="kazam-logo-id"
              />
            </div>
            <div className="id-text-heading text-xl">
              Single platform for EV charging
            </div>

            <div className="id-code-web">
              <img src="/images/id-card/web_url.png" alt="" />
            </div>
            <div className="kazam-url text-xs">www.kazam.in</div>

            <div className="absolute bottom-0 right-0">
              <img src="/images/id-card/bottom_logo.png" alt="" className="" />
            </div>
          </div>
        </div>
        {/* Cards back */}
        <div className="mt-12 lg:mt-0 id-card-back-wrapper">
          <div className="id-back-wrapper bg-white relative" id="back_card">
            <div className="absolute top-0 left-0">
              <img src="/images/id-card/top_left.png" alt="" />
            </div>
            <div className="name-id text-xl font-bold focus:outline-none">
              {user.first_name + " " + user.last_name}
            </div>
            <div
              className="des-id text-lg "
              style={{
                color: "#5B5C5C",
              }}
            >
              {user.emp_profile?.designation}
            </div>
            <div className="kazam-full">
              <img
                src="/images/id-card/kazam_full.png"
                alt=""
                className="kazam-full-img"
              />
            </div>
            <div className="id-address text-xs">
              1st floor, Mp Krishna Mansion
              <br />, 1st Cross Rd, KHB Colony, 5th Block,
              <br /> Koramangala, Bengaluru,
              <br /> Karnataka 560095
            </div>
            <div className="id-qr2">
              <img
                src="/images/id-card/url2.png"
                alt=""
                className="id-qr2-img"
              />
            </div>
            <div className="text-xs kazam-url2">www.kazam.in</div>
            <div className="phone-id">{user.mobile}</div>
            <div className="email-id">{user.email}</div>
            <div className="text-xs kazam-app-text">Kazam EV app</div>
            <div className="google-id">
              <img
                src="/images/id-card/google.png"
                alt=""
                className="google-id-img"
              />
            </div>
            <div className="apple-id">
              <img
                src="/images/id-card/apple.png"
                alt=""
                className="apple-id-img"
              />
            </div>
          </div>
        </div>
        <div className="mt-12 lg:mt-0">
          {loading ? (
            <HrmLoader className="w-6 h-6" />
          ) : (
            <button
              className="py-3 px-12 rounded-md text-white bg-hrmBlue"
              onClick={() => {
                setLoading(true);
                downloadId(".id-card-front-wrapper", "front-id.png");
                downloadId(".id-card-back-wrapper", "back-id.png");
              }}
            >
              Download
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default VisitingCard;
