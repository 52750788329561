import { FunctionComponent, useEffect, useState } from "react";
import "../styles/index.css";
import Layout from "../layout";
import LeavesBlock from "./components/LeavesBlock";
import MoodBlock from "./components/MoodBlock";
import BirthdayBlock from "./components/BirthdayBlock";
import ActivityBlock from "./components/ActivityBlock";
import PollBlock from "./components/PollBlock";
import MemoriesBlock from "./components/MemoriesBlock";
// @ts-ignore
import gsap from "gsap/all";
import WeekOff from "./components/WeekOffBlock";
import { useLocation } from "react-router-dom";

import QuickLeave from "./components/QuickLeave";
import { useAuth } from "../../../../context_providers/auth/authProvider";

interface HomeProps {}

const Home: FunctionComponent<HomeProps> = () => {
  const [showQuickLeave, setShowQuickLeave] = useState<boolean>(false);
  const { user, isAuthorised } = useAuth();

  const { search } = useLocation();

  const animateData = () => {
    const tl = gsap.timeline({ delay: 1.5 });
    tl.fromTo(
      ".poll_block",
      0.5,
      {
        opacity: 0,
        y: -50,
      },
      {
        opacity: 1,
        y: 0,
      }
    );
  };

  useEffect(() => {
    // animateData();
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(search);
    if (query.get("showQuick")) {
      setShowQuickLeave(true);
    }
  }, []);

  return (
    <Layout>
      <div className="">
        <div className="relative">
          <div className="lg:flex gap-4">
            <div className=" lg:w-9/12">
              <div className=" mt-4">
                <LeavesBlock />
              </div>
              <div className="mt-3">
                <ActivityBlock />
              </div>
            </div>
            <div className="poll_block lg:w-3/12 mt-4 flex flex-col justify-between bg-white dark:bg-hrmCardDark rounded-2xl">
              <MemoriesBlock hrm={true} />
              <WeekOff />
              <MoodBlock />
            </div>
          </div>
        </div>
        {showQuickLeave && (
          <QuickLeave close={() => setShowQuickLeave(false)} />
        )}
        <button
          className=" fixed bottom-6 left-1/2 transform -translate-x-1/2 shadow-xl px-12 py-3 whitespace-nowrap rounded-full text-white font-bold bg-hrmBlue "
          onClick={() => setShowQuickLeave(true)}
        >
          Apply for quick leave
        </button>
      </div>
    </Layout>
  );
};

export default Home;
