import moment from "moment";
import {
  FunctionComponent,
  MouseEventHandler,
  useEffect,
  useState,
} from "react";
import { OrderModel } from "../../../../../service/models/orders/order_model";
import { GetOrdersApi } from "../../../../../service/repos/order_repo";
import { exportCSV } from "../../../../../utils/csv_generator";
import {
  OrderTypeLabel,
  OrderType,
  OrderStatusLabel,
  OrderStatus,
} from "../../../../../utils/enums";
import LoadingWidget from "../../../../components/loading_spinner";
import StadiumButton from "../../../../new_components/common/stadium_button";
import jsonexport from "jsonexport";

import { UTCToLocalDate } from "../../../../../utils/date_util";
import { useModal } from "../../../../../context_providers/modal/modal_context";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import Icon from "@mdi/react";
import { mdiDownload, mdiDownloadBoxOutline } from "@mdi/js";
interface ExportWidgetProps {
  filter: {
    page?: number;
    search?: string;
    fromDate?: string;
    toDate?: string;
    state?: string[];
    orderStatus?: string[];
    paymentStatus?: string[];
    saleExecutive?: string[];
    type?: string[];
    items?: string[];
    site_survey?: string[];
    wiring_status?: string[];
    subsidy_status?: string[];
  };
}

const ExportWidget: FunctionComponent<ExportWidgetProps> = (props) => {
  const { setLoading } = useModal();
  // const [loading, setLoading] = useState(false);
  const { showToast } = useToast();
  // const headers = [
  //   "Order date",
  //   "Order ID",
  //   "Order type",
  //   "Customer",
  //   "Poc",
  //   "Mobile",
  //   "Email",
  //   "Address",
  //   "City",
  //   "State",
  //   "Pincode",
  //   "Product",
  //   "price",
  //   "Qty",
  //   "Del/Installed qty",
  //   "Sub total",
  //   "Discount",
  //   "Extra amount",
  //   "Total amount",
  //   "Paid amount",
  //   "Balance amount",
  //   "Order status",
  //   "Payment status",
  //   "Sales person",
  //   "Source",

  // { label: "Order date", key: "order_date" },
  // { label: "Order ID", key: "order_id" },
  // { label: "Order Type", key: "type" },
  // { label: "Customer", key: "customer" },
  // { label: "POC", key: "poc" },
  // { label: "Mobile", key: "mobile" },
  // { label: "Email", key: "email" },
  // { label: "Address", key: "address" },
  // { label: "City", key: "city" },
  // { label: "State", key: "state" },
  // { label: "Pincode", key: "pincode" },
  // { label: "Product", key: "product" },
  // { label: "Price", key: "price" },
  // { label: "Qty", key: "qty" },
  // { label: "Delivered/Installed qty", key: "delivered_qty" },
  // { label: "Sub Total", key: "stubtotal" },
  // { label: "Discount", key: "discount" },
  // { label: "Extra amount", key: "extra_amt" },
  // { label: "Total amount", key: "total_amt" },
  // { label: "Paid amount", key: "paid_amt" },
  // { label: "Balance amount", key: "balance_amt" },
  // { label: "Order status", key: "status" },
  // { label: "Payment status", key: "payment_status" },
  // { label: "Sales person", key: "sales_emp" },
  // { label: "source", key: "source" },
  // ];
  const getExpObj = (orders: any[]) => {
    return orders.map((order, i) => {
      return {
        order_date: moment(order.order_date).format("DD-MM-YYYY hh:mm:ss a"),
        order_id: order.order_id,
        type: order.type,
        category: order.category,
        customer:
          order.customer.firstName + " " + (order.customer.lastName ?? ""),
        poc:
          order.type === OrderType.B2B || order.type === OrderType.PARTNER
            ? order.poc
            : "N/A",
        mobile: order.customer.mobile,
        email: order.customer.email,
        city: order.customer.address.city,
        state: order.customer.address.state,
        pincode: order.customer.address.pincode,
        product: order.items.name,
        price: order.items.price,
        qty: order.items.qty,
        delivered_qty: order.items.delivered_qty,
        installed_qty: order.items.installed_qty,
        returned_qty: order.items.returned_qty,
        net_qty: order.items.net_qty,
        subtotal: order.items.subtotal,
        discount: order.items.discount_amt,
        extra_amt: order.items.addition_amt,
        total_amt: order.items.total,
        paid_amt: order.items.paid,
        balance_amt: order.items.total - order.items.paid,
        status: order.status,
        payment_status: order.payment_status,
        relationship_manager: order.rm_name,
        sales_emp: order.sales_person_name,
        source: order.source,
        demo: order.demo ? "Yes" : "No",
        expected_payment_date: order.expected_pay_date
          ? moment(order.expected_pay_date).format("DD-MM-YYYY")
          : "",
        next_delivery_date: order.next_delivery_date
          ? moment(order.next_delivery_date).format("DD-MM-YYYY")
          : "",
        remark: order.remark,
        site_survey: order.site_survey,
        wiring_status: order.wiring_status,
        load_sanction: order.load_sanction,
        lead_segment: order.segment,
      };
    });
  };
  const [fromDate, setFromdate] = useState("");
  const [toDate, setTodate] = useState("");

  const getOrders = async () => {
    if (!fromDate || !toDate) {
      showToast({
        type: ToastType.warning,
        text: "Please select date !",
      });
      return;
    } else if (
      moment(fromDate, "YYYY-MM-DD").isAfter(moment(toDate, "YYYY-MM-DD"))
    ) {
      showToast({
        type: ToastType.warning,
        text: "From Date should not be greater than To Date ",
      });
      return;
    }
    setLoading(true);
    delete props.filter.search;
    const res = await GetOrdersApi({
      ...props.filter,
      fromDate: UTCToLocalDate(fromDate) as any,
      toDate: UTCToLocalDate(toDate) as any,
      all: "true",
    });

    if (res.success) {
      const t = getExpObj(res.data.orders);

      jsonexport(t, function (err, csv) {
        if (err) return console.error(err);

        exportCSV(csv, `${fromDate}-${toDate}-orders`);
      });
    } else {
      showToast({ type: ToastType.error, text: res.error });
    }
    setLoading(false);
  };
  useEffect(() => {
    if (props.filter.fromDate && props.filter.toDate) {
      setFromdate(props.filter.fromDate);
      setTodate(props.filter.toDate);
    }
  });
  return (
    <div className="p-5 text-sm">
      <div className="flex flex-col gap-1 my-2">
        <div className="font-semibold"> Anokhi Reports</div>
        <a
          target="_blank"
          href={`${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/report/anokhi-report/order`}
          className="flex items-center gap-1"
        >
          <Icon path={mdiDownload} className="h-4 w-5"></Icon>
          <p> Order data</p>
        </a>
        <a
          target="_blank"
          href={`${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/report/anokhi-report/device-allocation`}
          className="flex items-center gap-1"
        >
          <Icon path={mdiDownload} className="h-4 w-5"></Icon>
          <p> Device allocated data</p>
        </a>
      </div>
      <hr />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <div className="">
          <label htmlFor="from-date">From</label>
          <input
            id="from-date"
            className="border rounded p-2 w-full"
            type="date"
            name="fromDate"
            placeholder="From"
            required
            value={fromDate}
            onChange={(e) => {
              if (
                toDate &&
                moment(e.target.value, "YYYY-MM-DD").isAfter(
                  moment(toDate, "YYYY-MM-DD")
                )
              ) {
                showToast({
                  type: ToastType.warning,
                  text: "From Date should not be greater than To Date ",
                });
                return;
              }
              setFromdate(e.target.value);
            }}
          />
        </div>
        <div className="">
          <label htmlFor="to-date">To</label>
          <input
            id="to-date"
            className="border rounded p-2 w-full"
            type="date"
            name="toDate"
            placeholder="To"
            required
            value={toDate}
            min={fromDate ? fromDate : ""}
            onChange={(e) => {
              if (
                fromDate &&
                moment(fromDate, "YYYY-MM-DD").isAfter(
                  moment(e.target.value, "YYYY-MM-DD")
                )
              ) {
                showToast({
                  type: ToastType.warning,
                  text: "To Date should  be greater than From Date ",
                });
                return;
              }
              setTodate(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="flex justify-end my-2">
        <StadiumButton type="submit" onTap={getOrders}>
          Export
        </StadiumButton>
      </div>
      {/* <LoadingWidget loading={loading} /> */}
    </div>
  );
};

export default ExportWidget;
