import { mdiCheck, mdiClose, mdiPencil, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { useOrderCreateCtx } from "../../../../../context_providers/order_create_context";
import { useOrderDetailCtx } from "../../../../../context_providers/order_detail_context";
import {
  DurationType,
  SubscriptionPlanModel,
  subscriptionPlanDefault,
} from "../../../../../service/models/orders/subscription_plan_model";
import { getOrgNamespaceApi } from "../../../../../service/repos/cms/org_repo";
import PaymentSection from "./payment_section";
import SubscriptionConfigWidget from "./subscription_config_widget";
import ModalWidget from "../../../../../context_providers/modal/modal_widget";
import {
  ModalAlignment,
  ModalType,
  useModal,
} from "../../../../../context_providers/modal/modal_context";
import { currencyFormat } from "../../../../../utils/orders_utils";
import OrgCreateWidget from "./org_create";
import { OrgModel } from "./org/org_search";
import { addDurationToDate } from "../../../../../utils/date_util";

interface SubscriptionSectionProps {}

const SubscriptionSection: FunctionComponent<SubscriptionSectionProps> = () => {
  const {
    subscriptions,
    getSubscription,
    subscription,
    setSubscription,
    order,
  } = useOrderDetailCtx();
  const { showModal } = useModal();
  const [addNew, setAddNew] = useState(false);
  const [edit, setEdit] = useState(false);
  useEffect(() => {
    getSubscription();
  }, []);
  // const showCreateOrg = () => {
  // showModal({
  //   title: "Create Organization",
  //   type: ModalType.modal,
  //   alignment: ModalAlignment.right,
  //   container: (id) => (
  //     <OrgCreateWidget
  //       onClose={function (data?: OrgModel | undefined): void {

  //       }}
  //     />
  //   ),
  // });
  // };
  const showPlans = () => {
    showModal({
      title: "Subscription plans",
      type: ModalType.drawer,
      alignment: ModalAlignment.right,
      container: (id) => (
        <div className="flex flex-col divide-y-2">
          {subscriptions
            .filter((f) => !f.active)
            .map((sub, i) => (
              <>
                <div
                  className={`${
                    sub.active ? " shadow " : ""
                  } rounded-md bg-white  p-5 text-sm text-gray-600`}
                >
                  <div className="flex justify-between mb-2">
                    {sub.active ? (
                      <div className="rounded-3xl px-2 py-1 text-sm border border-green-400 text-green-400">
                        Active
                      </div>
                    ) : (
                      <div className="rounded-3xl px-2 py-1 text-sm border border-red-400 text-red-400">
                        Inactive
                      </div>
                    )}
                  </div>

                  <p>
                    <b>Org name</b> :{sub.org_name}
                  </p>
                  <p>
                    <b>Org namespace</b> : {sub.org_namespace}
                  </p>
                  <hr className="my-2" />
                  <div className="flex gap-5">
                    <p>
                      <b>Start Date</b> : {moment(sub.start_date).format("lll")}
                    </p>
                    <p>
                      <b>End Date</b> :{" "}
                      {sub.end_date && moment(sub.end_date).format("lll")}
                      {/* {sub.duration_type == DurationType.lifetime
                        ? "Lifetime"
                        : moment(
                            addDurationToDate(
                              sub.last_renewal_date,
                              sub.duration_type == "Year"
                                ? { years: sub.duration_value }
                                : { months: sub.duration_value }
                            )
                          ).format("lll")} */}
                    </p>
                  </div>
                  <p>
                    <b>Free Connectors AC</b> : {sub.free_connector_ac}
                  </p>
                  <p>
                    <b>Free Connectors DC</b> : {sub.free_connector_dc}
                  </p>
                  <p>
                    <b>Per Unit {"\u20b9"}</b> : {sub.per_unit}
                  </p>
                  <p>
                    <b>Per Connector AC {"\u20b9"}</b> : {sub.per_connector_ac}
                  </p>
                  <p>
                    <b>Per Connector DC {"\u20b9"}</b> : {sub.per_connector_dc}
                  </p>

                  <p>
                    <b>
                      Per Transaction{" "}
                      {sub.per_txn_val_type == "Percent" ? "%" : "\u20b9"}
                    </b>{" "}
                    : {sub.per_txn_value}
                  </p>
                  <p className="italic text-gray-400 ">
                    <b>Note </b> : {sub.note}
                  </p>
                  <hr className="my-2" />
                  <p className="text-xs text-gray-400">Id:{sub.id}</p>
                  <div className="flex justify-between gap-2 my-1">
                    <p className="text-xs ">
                      Created by :<br />
                      <div className="flex gap-1">
                        <div className="">
                          <b>{sub.created_by_name ?? "N/A"}</b>
                        </div>
                        <div className="">
                          {sub.created_at
                            ? moment(sub.created_at).format(
                                "DD MMM YYYY h:mm a"
                              )
                            : "--"}
                        </div>
                      </div>
                    </p>
                    <p className="text-xs ">
                      Updated by :<br />
                      <div className="flex gap-1">
                        <div className="">
                          <b>{sub.updated_by_name ?? "N/A"}</b>
                        </div>
                        <div className="">
                          {sub.updated_at
                            ? moment(sub.updated_at).format(
                                "DD MMM YYYY h:mm a"
                              )
                            : "--"}
                        </div>
                      </div>
                    </p>
                  </div>
                </div>
              </>
            ))}
        </div>
      ),
    });
  };
  return (
    <>
      <div
        className={`grid grid-cols-1 lg:grid-cols-12 text-sm gap-2   right-0
             transform duration-500 ease-in-out   slide_drawer_rtl `}
      >
        <div className="lg:col-start-1 lg:col-span-12  ">
          {order.monthly_recurring_fee === "Yes" ? (
            <>
              {" "}
              {!subscription && !addNew && (
                <div className="mt-20 flex flex-col gap-5 items-center justify-center text-2xl font-semibold text-gray-400">
                  {/* <p>I'm under development!</p> */}
                  <p> No Subscription Found !</p>
                  <div className="">
                    <button
                      onClick={() => {
                        setAddNew(true);
                      }}
                      className="bg-myPrimaryColor rounded-3xl px-2  py-1 flex gap-1  text-sm text-white items-center hover:scale-110 transform duration-300"
                    >
                      <Icon path={mdiPlus} className="h-5 w-5  "></Icon> Add
                    </button>
                  </div>
                </div>
              )}
              {subscription && (
                <>
                  <div className="flex justify-between">
                    <h3 className="font-semibold text-gray-500 mb-1 ">
                      Subscription Plan
                    </h3>
                    <button className="text-blue-400" onClick={showPlans}>
                      More
                    </button>
                  </div>

                  <div className="flex flex-col gap-2">
                    {edit ? (
                      <SubscriptionConfigWidget
                        onCancel={() => {
                          setEdit(false);
                        }}
                      />
                    ) : (
                      <>
                        <div
                          className={`${
                            subscription.active ? " shadow " : ""
                          } rounded-md bg-white  p-5 text-sm text-gray-600`}
                        >
                          <div className="flex justify-between mb-2">
                            {subscription.active ? (
                              <div className="rounded-3xl px-2 py-1 text-sm border border-green-400 text-green-400">
                                Active
                              </div>
                            ) : (
                              <div className="rounded-3xl px-2 py-1 text-sm border border-red-400 text-red-400">
                                Inactive
                              </div>
                            )}
                            {subscription.active && (
                              <div
                                onClick={() => {
                                  setEdit(true);
                                }}
                                className=" flex gap-1 cursor-pointer  text-blue-400 px-2 py-1 text-xs hover:text-blue-600"
                              >
                                <Icon
                                  path={mdiPencil}
                                  className="h-4 w-4"
                                ></Icon>{" "}
                                Edit
                              </div>
                            )}
                          </div>

                          <div className="my-2">
                            <p>
                              <b>Org name</b> :{subscription.org_name}
                            </p>
                            <p>
                              <b>Org namespace</b> :{" "}
                              {subscription.org_namespace}
                            </p>
                          </div>
                          <hr className="" />
                          <div className="my-2">
                            {" "}
                            <h5 className="font-semibold ">Validity</h5>
                            <div className="flex gap-5">
                              <p>
                                <b>Start Date</b> :{" "}
                                {moment(subscription.start_date).format("lll")}
                              </p>
                              <p>
                                <b>End Date</b> :{" "}
                                {subscription.end_date &&
                                  moment(subscription.end_date).format("lll")}
                                {/* {subscription.duration_type == DurationType.lifetime
                                  ? "Lifetime"
                                  : moment(
                                      addDurationToDate(
                                        subscription.last_renewal_date,
                                        subscription.duration_type == "Year"
                                          ? { years: subscription.duration_value }
                                          : { months: subscription.duration_value }
                                      )
                                    ).format("lll")} */}
                              </p>
                            </div>
                          </div>
                          <hr />
                          <div className="my-2">
                            <h5 className="font-semibold ">Renewal</h5>
                            <div className="flex gap-5">
                              <p>
                                <b>Renewal Fee</b> :{" "}
                                {currencyFormat(subscription.renewal_fee)}
                              </p>
                              <p>
                                <b>Renewal collection type</b> :{" "}
                                {subscription.renewal_fee_collection_type}
                              </p>
                              {subscription.renewal_fee_collection_type ==
                                "Monthly" && (
                                <p>
                                  <b>Renewal collection duration (EMI)</b> :{" "}
                                  {subscription.renewal_fee_emi_months}
                                </p>
                              )}
                              {subscription.renewal_fee_collection_type ==
                                "Monthly" && (
                                <p>
                                  <b>Renewal collection EMI</b> :{" "}
                                  {currencyFormat(
                                    subscription.renewal_fee /
                                      (subscription.renewal_fee_emi_months || 1)
                                  )}
                                </p>
                              )}
                            </div>
                          </div>
                          <hr />
                          <div className="my-2">
                            <h5 className="font-semibold ">Connectors</h5>
                            <div className="flex gap-5">
                              <p>
                                <b>AC connector/ per connector / month</b> :{" "}
                                {currencyFormat(subscription.per_connector_ac)}
                              </p>
                              <p>
                                <b>DC connector/ per connector / month</b> :{" "}
                                {currencyFormat(subscription.per_connector_dc)}
                              </p>
                              <p>
                                <b> Waive off connector AC</b> :{" "}
                                {subscription.free_connector_ac}
                              </p>
                              <p>
                                <b> Waive off connector DC</b> :{" "}
                                {subscription.free_connector_dc}
                              </p>
                            </div>
                          </div>
                          <hr />
                          <div className="my-2">
                            <h5 className="font-semibold ">Transaction</h5>
                            <div className="flex gap-5">
                              <p>
                                <b> Transaction value Type</b> :{" "}
                                {subscription.per_txn_val_type}
                              </p>
                              <p>
                                <b> Per Trasaction :</b>{" "}
                                {subscription.per_txn_val_type == "Amount"
                                  ? currencyFormat(subscription.per_txn_value)
                                  : subscription.per_txn_val_type == "Percent"
                                  ? `${subscription.per_txn_value}%`
                                  : "--"}
                              </p>
                            </div>
                          </div>
                          <hr />
                          <div className="my-2">
                            <h5 className="font-semibold ">Unit</h5>
                            <div className="flex gap-5">
                              <p>
                                <b>Per unit</b> :{" "}
                                {currencyFormat(subscription.per_unit)}
                              </p>
                            </div>
                          </div>
                          <hr />

                          <p className="italic text-gray-400 ">
                            <b>Remarks </b> : {subscription.note}
                          </p>
                          <hr className="my-2" />
                          <p className="text-xs text-gray-400">
                            Id:{subscription.id}
                          </p>
                          <div className="flex justify-between gap-2 my-1">
                            <p className="text-xs ">
                              Created by :<br />
                              <div className="flex gap-1">
                                <div className="">
                                  <b>{subscription.created_by_name ?? "N/A"}</b>
                                </div>
                                <div className="">
                                  {subscription.created_at
                                    ? moment(subscription.created_at).format(
                                        "DD MMM YYYY h:mm a"
                                      )
                                    : "--"}
                                </div>
                              </div>
                            </p>
                            <p className="text-xs ">
                              Updated by :<br />
                              <div className="flex gap-1">
                                <div className="">
                                  <b>{subscription.updated_by_name ?? "N/A"}</b>
                                </div>
                                <div className="">
                                  {subscription.updated_at
                                    ? moment(subscription.updated_at).format(
                                        "DD MMM YYYY h:mm a"
                                      )
                                    : "--"}
                                </div>
                              </div>
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
              {addNew && (
                <>
                  <SubscriptionConfigWidget
                    onCancel={() => {
                      setAddNew(false);
                    }}
                  />
                </>
              )}
              {subscriptions.length > 0 && (
                <>
                  {" "}
                  <h3 className="font-semibold text-gray-500 mb-1 mt-5">
                    {" "}
                    Generated Bills
                  </h3>
                  <PaymentSection />
                </>
              )}
            </>
          ) : (
            <>
              <div className="mt-20 flex flex-col gap-5 items-center justify-center text-2xl font-semibold text-gray-400">
                {/* <p>I'm under development!</p> */}
                <p>Subscription is not applicable </p>
                <p className="text-sm">
                  Go to order details section and update the Subscription
                  applicable to 'Yes'
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SubscriptionSection;
