import { FunctionComponent, useEffect, useState } from "react";
import { X } from "react-feather";
import { useAuth } from "../../../../context_providers/auth/authProvider";
import {
  useToast,
  ToastType,
} from "../../../../context_providers/toast/toast_ctx";
import { PostModuleRequest } from "../../../../service/repos/landing_screen/landing_repo";

import { ACTION, MODULE, MODULELABEL } from "../../../../utils/enums";

interface RequestModalProps {
  handleRequestModal: () => void;
}

const RequestModal: FunctionComponent<RequestModalProps> = ({
  handleRequestModal,
}) => {
  const { showToast } = useToast();
  const { user, isAuthorised } = useAuth();
  const [authorizedModules, setAuthorizedModules] = useState<string[]>([]);

  const [modules, setModules] = useState<string[]>([]);
  const [queryLoading, setQueryLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const moduleNumbersArray = [
    MODULE.ops_task_panel,
    MODULE.content_panel,
    MODULE.order_panel,
    MODULE.payment_panel,
    MODULE.device_inventory_panel,
    MODULE.hrm_panel,
    MODULE.complaint_panel,
  ];

  useEffect(() => {
    const tempData = moduleNumbersArray.filter(
      (v) => !isAuthorised({ module: v, action: [ACTION.READ] })
    );

    setAuthorizedModules(tempData);
  }, []);

  const postModuleRequest = async (modules: string[]) => {
    if (modules.length > 0) {
      setQueryLoading(true);
      const data = {
        module: modules,
      };
      const res = await PostModuleRequest(data);
      if (res.success) {
        setShowSuccess(true);
        setQueryLoading(false);

        setTimeout(() => {
          setShowSuccess(false);
          handleRequestModal();
        }, 1200);
      } else {
        setQueryLoading(false);
        showToast({ type: ToastType.error, text: res.error ?? "" });
      }
    } else {
      showToast({
        type: ToastType.warning,
        text: "Please select any module",
      });
    }
  };

  return (
    <div className="visibility_toggle w-5/6 lg:mx-0 lg:w-1/3 fixed top-1/2 left-1/2 transform  -translate-x-1/2 -translate-y-1/2 z-30  rounded-xl overflow-hidden ">
      <X
        size={32}
        className="text-white absolute top-2 right-2 cursor-pointer"
        onClick={() => handleRequestModal()}
      />
      {authorizedModules.length > 0 ? (
        <div className="">
          <div className="bg-black pt-12 pb-8 px-8 rounded-t-xl">
            <div className="text-white text-2xl font-bold">
              Need access for the modules?
            </div>
            <div className="text-white text-opacity-70 mt-4">
              Select the modules. We will be reviewing and granting you the
              permissions.
            </div>
          </div>
          <div className="grid grid-cols-2 gap-y-4 pl-12 py-4 text-lg  bg-white">
            {authorizedModules.map((module, i) => {
              return (
                <div className="">
                  <input
                    type="checkbox"
                    className="mr-2"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setModules((o) => [...o, module]);
                      } else {
                        setModules((o) => o.filter((m) => m !== module));
                      }
                    }}
                  />
                  {module}
                </div>
              );
            })}
          </div>
          <div className="bg-white py-4 flex justify-center rounded-b-xl">
            {!queryLoading ? (
              <button
                className="py-2 px-12 bg-red-800 text-white font-semibold rounded-lg"
                onClick={() => postModuleRequest(modules)}
              >
                Submit Request
              </button>
            ) : (
              <button className="py-2 px-12 bg-red-800 text-white font-semibold rounded-lg">
                Loading...
              </button>
            )}
          </div>
        </div>
      ) : (
        <div className="w-full bg-black rounded-xl">
          <div className="text-2xl font-bold text-white pt-12 pb-2 text-center">
            You have access to <br /> all the modules
          </div>
          <div className="flex justify-center">
            <img src="/images/goku.gif" alt="" className="transform scale-75" />
          </div>
        </div>
      )}

      <div
        className={`absolute top-0 left-0 w-full h-full bg-black flex flex-col items-center justify-center rounded-b-lg success_msg ${
          showSuccess ? "block" : "hidden"
        } `}
      >
        <div className="text-primaryColor text-2xl font-semibold">
          Successfully Submitted
        </div>
        <div className="mt-2">
          <img src="/images/icons/success.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default RequestModal;
