import { FunctionComponent, useEffect } from "react";
import Lottie from "lottie-web";
import done from "../../lottie_files/done.json";
interface DoneWidgetProps {}

const DoneWidget: FunctionComponent<DoneWidgetProps> = () => {
  useEffect(() => {
    Lottie.loadAnimation({
      container: document.querySelector("#done")!,
      animationData: done,
      renderer: "canvas", // "canvas", "html"
      loop: false, // boolean
      autoplay: true, // boolean
    });
  }, []);
  return (
    <>
      {/* <div className="flex h-screen w-screen justify-center items-center  select-none">
      <div className="flex flex-col justify-center items-center"> */}
      <div id="done" className="h-full w-full"></div>
      {/* </div>
    </div> */}
    </>
  );
};

export default DoneWidget;
