import { FunctionComponent } from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import { useOrderDetailCtx } from "../../../../context_providers/order_detail_context";
import { currencyFormat } from "../../../../utils/orders_utils";

interface OrderSummeryProps {}

const OrderSummery: FunctionComponent<OrderSummeryProps> = () => {
  const { order } = useOrderDetailCtx();
  const { url, path } = useRouteMatch();
  return (
    <div className="bg-white rounded-lg  h-full">
      <div className=" text-sm flex flex-col gap-1   px-3 py-4   mb-3">
        {/* <h3 className="font-bold mb-2">Summary</h3> */}
        <div className="flex justify-between">
          <span>Subtotal :</span> <span>{currencyFormat(order.subtotal)}</span>
        </div>
        <div className="flex justify-between">
          <span>
            Discount : {order.discountData.reduce((pv, d) => pv + d.name, "")}
          </span>
          <span>
            <b>-</b>
            {currencyFormat(order.discount)}
          </span>
        </div>
        <div className="flex justify-between">
          <div>Addtional amt :</div> <span>{currencyFormat(order.excess)}</span>
        </div>
        <div className="flex justify-between font-semibold">
          <span>Total :</span> <span>{currencyFormat(order.total)}</span>
        </div>
        <hr />
        <div className="flex justify-between text-green-400">
          <NavLink to={`${url}/payments`}>Paid :</NavLink>
          <span>
            <b>-</b>
            {currencyFormat(order.paid)}
          </span>
        </div>
        <div className="flex justify-between font-bold text-green-900    ">
          <span>Balance :</span>{" "}
          <span>{currencyFormat(order.total - order.paid)}</span>
        </div>
      </div>
    </div>
  );
};

export default OrderSummery;
