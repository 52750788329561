import { ExportToCsv } from "export-to-csv";
import gsap from "gsap/all";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { Download, Trash, X } from "react-feather";
import { NavLink, useParams } from "react-router-dom";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import HrmPopup from "../../components/HrmPopup";
import HrmLoader from "../../components/loading";
import Layout from "../../layout";
import { JobApplication, JobDetail } from "../../service/models/job_portal";
import {
  DeleteApplicantJobStatus,
  GetApplicantDetails,
  GetJobApplicants,
  UpdateApplicantJobStatus,
} from "../../service/repos/job_portal";
import { JobApplicationStatus } from "../../service/models/job_portal";
import { useAuth } from "../../../../../context_providers/auth/authProvider";

interface ApplicantsViewProps {}

const ApplicantsView: FunctionComponent<ApplicantsViewProps> = () => {
  const [jobDetail, setJobDetail] = useState<JobDetail>();
  const [applicants, setApplicants] = useState<JobApplication[]>([]);
  const [filteredApplicants, setFilteredApplicants] = useState<
    JobApplication[]
  >([]);
  const [status, setStatus] = useState<string>("All");

  const [showApplicantModal, setShowApplicantModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [applicantId, setApplicantId] = useState<string>("");
  const [showReferral, setShowReferral] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const { user } = useAuth();

  const { showToast } = useToast();

  const getJobApplicants = async (id: string) => {
    setLoading(true);
    const res = await GetJobApplicants(id);
    console.log(res);

    if (res.success) {
      setJobDetail(res.job_detail);
      setApplicants(res.data?.data);
      setFilteredApplicants(res.data?.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const updateStatus = async (
    id: string,
    tempApp: JobApplication,
    show: boolean,
    status: string
  ) => {
    const res = await UpdateApplicantJobStatus(id, tempApp);
    if (res.success) {
      const tempApplicants = filteredApplicants.map((el) => {
        if (el.id === tempApp.id) {
          return {
            ...el,
            status: tempApp.status,
          };
        } else {
          return el;
        }
      });
      setFilteredApplicants(tempApplicants);
      filterData(show, status);
    } else {
      showToast({ type: ToastType.error, text: res.error });
    }
  };

  const deleteApplicantData = async (id: string) => {
    const res = await DeleteApplicantJobStatus(id);

    if (res.success) {
      const tempAppl = filteredApplicants.filter((el) => el.id !== id);
      setFilteredApplicants(tempAppl);

      showToast({ type: ToastType.success, text: "Deleted successfully" });
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
  };

  const filterData = (show: boolean, status: string) => {
    if (show) {
      // get only referred candidates
      const tempApplicants = applicants.filter(
        (el) => el.ref?.ref_code && el.ref?.ref_code !== ""
      );

      // filter according to status
      const tempFilter = tempApplicants.filter((o) => {
        if (status === "All") {
          return o;
        } else {
          return o.status === status;
        }
      });
      setFilteredApplicants(tempFilter);
    } else {
      // filter according to status
      const tempFilter = applicants.filter((o) => {
        if (status === "All") {
          return o;
        } else {
          return o.status === status;
        }
      });
      setFilteredApplicants(tempFilter);
    }
  };

  const animateData = () => {
    const tl = gsap.timeline();
    tl.fromTo(
      ".applicant_view_header",
      0.5,
      {
        y: -200,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
      }
    ).fromTo(
      ".applicant_table",
      0.5,
      {
        opacity: 0,
      },
      {
        opacity: 1,
      }
    );
  };

  const exportData = () => {
    const csvData = filteredApplicants.map((d: JobApplication) => {
      return {
        name: d.name,
        email: d.email,
        mobile: d.mobile,
        experience: d.experience,
        linkedin: d?.linkedin,
        resume: d?.file,
        referredBy: d?.ref?.email,
      };
    });
    const options = {
      filename: jobDetail?.title,
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: true,
      title: "Applicant list",
      useTextFile: false,
      useBom: true,

      // useKeysAsHeaders: true,
      headers: [
        "Name",
        "Email",
        "Mobile",
        "Experience",
        "Linkedin",
        "Resume",
        "Referred By",
      ],
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(csvData);
  };

  useEffect(() => {
    filterData(showReferral, status);
  }, [showReferral, status]);

  useEffect(() => {
    getJobApplicants(id);
    animateData();
  }, []);

  return (
    <Layout>
      {user?.emp_profile?.isReportingManager ? (
        <div className="px-3 lg:px-6 py-5 lg:py-6 relative">
          <div className="applicant_view_header opacity-0 bg-hrmBlue lg:rounded-xl text-white pl-4 lg:pl-10 py-12">
            <div className="">
              <div className="text-xl lg:text-2xl font-bold">
                {jobDetail?.title}
              </div>
              <div className="flex gap-2 items-center font-semibold mt-1 text-gray-300">
                <div className="">{jobDetail?.department}</div>
                <div className="h-5 w-px bg-gray-200"></div>
                <div className="">{jobDetail?.job_type}</div>
              </div>
            </div>
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-y-6 lg:gap-y-0  lg:gap-x-12 lg:w-1/2 mt-6 lg:mt-12">
              <div className="">
                <div className="text-sm text-gray-300 font-semibold">
                  Location
                </div>
                <div className="lg:text-lg mt-1">{jobDetail?.location}</div>
              </div>

              <div className="">
                <div className="text-sm text-gray-300 font-semibold">
                  Positions
                </div>
                <div className="lg:text-lg mt-1">{jobDetail?.position}</div>
              </div>
              <div className="">
                <div className="text-sm text-gray-300 font-semibold">
                  Posted on
                </div>
                <div className="lg:text-lg mt-1">
                  {moment(jobDetail?.created_at).format("DD-MM-YYYY")}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12">
            <div className="font-semibold text-gray-500 pl-3">
              Total {filteredApplicants.length} applicants
            </div>
            <div className=" flex flex-col lg:flex-row items-center justify-between mt-3">
              <div className="flex whitespace-nowrap lg:whitespace-normal gap-2 items-center text-sm overflow-x-auto w-full lg:w-auto pb-4 lg:pb-0">
                <div
                  className={`rounded-full py-1 px-3   ${
                    status === "All"
                      ? "bg-black text-white font-semibold"
                      : "border border-gray-400 text-gray-500"
                  } cursor-pointer `}
                  onClick={() => setStatus("All")}
                >
                  All
                </div>
                {Object.keys(JobApplicationStatus).map((el: any) => {
                  return (
                    <div
                      className={`rounded-full py-1 px-3 ${
                        status === JobApplicationStatus[el]
                          ? "bg-black text-white font-semibold"
                          : "border border-gray-400 text-gray-500"
                      } cursor-pointer `}
                      onClick={() => setStatus(JobApplicationStatus[el])}
                    >
                      {JobApplicationStatus[el]}
                    </div>
                  );
                })}
              </div>
              <div className="flex items-center gap-12 mt-4 lg:mt-0">
                <div className="flex gap-3 items-center">
                  <div className="text-sm font-semibold text-gray-500">
                    Show only referred
                  </div>
                  <div
                    className={`w-9 h-4 rounded-full bg-gray-300 cursor-pointer flex  ${
                      showReferral ? "justify-end" : "justify-start"
                    }`}
                    onClick={() => {
                      setShowReferral(!showReferral);
                    }}
                  >
                    <div
                      className={`h-4 w-4 rounded-full ${
                        showReferral ? "bg-green-500" : "bg-hrmBlue"
                      }  `}
                    ></div>
                  </div>
                </div>

                <div className="">
                  <button
                    className="py-2 px-4 bg-hrmBlue text-white font-semibold rounded-md text-sm"
                    onClick={() => exportData()}
                  >
                    Download
                    <Download className="inline ml-2" size={14} />
                  </button>
                </div>
              </div>
            </div>

            <div className="mt-4 lg:bg-white">
              <div className="hidden lg:grid grid-cols-8 font-bold text-sm py-3 px-6  bg-gray-200 bg-opacity-90 ">
                {[
                  "Name",
                  "Email",
                  "Phone",
                  "Experience",
                  "Applied on",
                  "Referred by",
                  "Status",
                  "Action",
                ].map((el, i) => {
                  return (
                    <div className="" key={i}>
                      {el}
                    </div>
                  );
                })}
              </div>
              {filteredApplicants.length === 0 ? (
                <div className="py-2  text-sm text-gray-500 font-semibold text-center">
                  No applicants
                </div>
              ) : (
                <div className="">
                  {filteredApplicants.map((el: JobApplication, i: number) => {
                    return (
                      <div
                        key={i}
                        className="grid grid-cols-1 bg-white lg:bg-transparent mt-4 lg:mt-0 lg:grid-cols-8 gap-y-4 items-center lg:gap-y-0 text-black px-6 text-sm py-4 lg:py-3  border-b"
                      >
                        <div className="">
                          <span className="lg:hidden">Name: </span> {el?.name}:
                        </div>
                        <div className="w-11/12 overflow-x-hidden bg-white">
                          <span className="lg:hidden">Email: </span>
                          {el?.email}
                        </div>
                        <div className="bg-white">
                          {" "}
                          <span className="lg:hidden">Mobile: </span>{" "}
                          {el?.mobile}
                        </div>
                        <div className="text-gray-400">
                          <span className="lg:hidden">Experience: </span>
                          {el.experience ? (
                            el.experience
                          ) : (
                            <span className="text-gray-400">Nil</span>
                          )}
                        </div>
                        <div className="">
                          <span className="lg:hidden">Applied on: </span>
                          {el?.created_at
                            ? moment(el?.created_at).format("YYYY-MM-DD")
                            : ""}
                        </div>
                        <div className="">
                          <span className="lg:hidden">Referred by: </span>
                          {el?.ref?.email ? (
                            el.ref.email
                          ) : (
                            <span className="text-gray-400">Nil</span>
                          )}
                        </div>
                        <div className="">
                          <select
                            name=""
                            id=""
                            className="py-1 px-2 border border-gray-300 focus:outline-none text-sm bg-white rounded-md cursor-pointer font-semibold"
                            value={el.status}
                            onChange={(e) => {
                              const tempApp = el;
                              el.status = e.target.value;
                              updateStatus(
                                el.id,
                                tempApp,
                                showReferral,
                                status
                              );
                            }}
                          >
                            {Object.keys(JobApplicationStatus).map(
                              (el: any) => {
                                return (
                                  <option value={JobApplicationStatus[el]}>
                                    {JobApplicationStatus[el]}
                                  </option>
                                );
                              }
                            )}
                          </select>
                        </div>
                        <div className="flex justify-center lg:justify-start items-center gap-3">
                          <div
                            className="text-blue-700 underline cursor-pointer"
                            onClick={() => {
                              setApplicantId(el.id);
                              setShowApplicantModal(true);
                            }}
                          >
                            View
                          </div>
                          <div className="">
                            <Trash
                              size={20}
                              className="text-red-500 cursor-pointer"
                              onClick={() => {
                                const confirm = window.confirm(
                                  "Are you sure you want to delete the job?"
                                );
                                if (confirm) {
                                  deleteApplicantData(el.id);
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center py-32">
          <div className="">
            <img src="/images/noData.png" alt="" className="w-64" />
          </div>
          <div className="text-xl mt-12">
            Only reporting managers can access this page.
          </div>
        </div>
      )}

      {showApplicantModal && (
        <HrmPopup>
          <ApplicantModal
            id={applicantId}
            close={() => setShowApplicantModal(false)}
          />
        </HrmPopup>
      )}
    </Layout>
  );
};

export default ApplicantsView;

interface ApplicantModalProps {
  id: string;
  close: () => void;
}

const ApplicantModal: FunctionComponent<ApplicantModalProps> = ({
  id,
  close,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [applicantData, setApplicantData] = useState<JobApplication>();
  const { showToast } = useToast();
  const getApplicantData = async (apId: string) => {
    setLoading(true);
    const res = await GetApplicantDetails(apId);

    if (res.success) {
      setApplicantData(res.data);
      setLoading(false);
    } else {
      setLoading(false);
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
  };

  useEffect(() => {
    getApplicantData(id);
  }, []);

  return loading ? (
    <div className="pt-12">
      <HrmLoader className="w-12 h-12" />
    </div>
  ) : (
    <div className="">
      {applicantData ? (
        <div className="bg-white py-8 px-6 relative">
          <div className="my-2 absolute top-2 right-3">
            <X size={32} className="cursor-pointer" onClick={() => close()} />
          </div>
          <div className="text-xl border-b font-semibold ">
            {applicantData?.name}
          </div>
          <div className="grid lg:grid-cols-3 gap-x-12 gap-y-8  pt-6 w-full">
            <div className="">
              <div className="text-sm text-gray-500 font-semibold">Email</div>
              <div className="font-semibold text-sm">
                {applicantData?.email}
              </div>
            </div>
            <div className="">
              <div className="text-sm text-gray-500 font-semibold">Mobile</div>
              <div className="font-semibold text-sm">
                {applicantData?.mobile}
              </div>
            </div>
            <div className="">
              <div className="text-sm text-gray-500 font-semibold">
                Experience
              </div>
              <div className="font-semibold text-sm">
                {applicantData.experience ? applicantData.experience : "null"}
              </div>
            </div>
            <div className="lg:col-span-2">
              <div className="text-sm text-gray-500 font-semibold">
                Linkedin
              </div>
              <div className=" text-sm text-blue-600 underline">
                <a
                  href={applicantData.linkedin ? applicantData.linkedin : "#"}
                  target="_blank"
                  rel="noreferrer"
                >
                  {applicantData.linkedin ? applicantData.linkedin : "null"}
                </a>
              </div>
            </div>
            <div className="">
              <div className="text-sm text-gray-500 font-semibold">
                Applied on
              </div>
              <div className="font-semibold text-sm">
                {applicantData?.created_at
                  ? moment(applicantData?.created_at).format("YYYY-MM-DD")
                  : ""}
              </div>
            </div>
            <div className="lg:col-span-4">
              <div className="text-sm text-gray-500 font-semibold">Resume</div>
              <div className="">
                <a href={applicantData?.file} target="_blank" rel="noreferrer">
                  <button className="py-2 px-4 rounded-md text-white bg-hrmBlue cursor-pointer">
                    Open resume
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="">No data</div>
      )}
    </div>
  );
};
