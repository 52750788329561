import { RouteParam } from ".";

import OrderCreatePage from "../ui/pages/orders/order_create";
import OrderDetailPage from "../ui/pages/orders/order_detail/order_detail_page";
import OrderListPage from "../ui/pages/orders/order_list";
import { ACTION, MODULE } from "../utils/enums";
import { urls } from "../utils/urls";

const order_routes: RouteParam[] = [
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.orderList,
    component: OrderListPage,
    module: MODULE.order,
    main_module: MODULE.order_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.createOrder,
    component: OrderCreatePage,
    module: MODULE.order,
    main_module: MODULE.order_panel,
    action: [ACTION.CREATE],
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: false,
    path: "/orders/:order_id",
    component: OrderDetailPage,
    module: MODULE.order,
    main_module: MODULE.order_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
   
];

export default order_routes;
