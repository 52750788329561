import axios from "axios";
import { getHeaders, fetchPut, fetchGet } from "../../api_client";

import { AddressModel } from "../../models/address_model";

export const UpdateAddressApi = async ({
  data,
  order_id,
  customer_id,
}: {
  data: AddressModel;
  order_id: string;
  customer_id: string;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/orders/${order_id}/billing-profile/${customer_id}/address`;
    const res = await fetchPut(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const getStatesApi = async () => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/place/states`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const getCitiesApi = async (state: string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/place/cities?state=${state}`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const getCityStateApi = async (pincode: number) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/place?pincode=${pincode}`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
