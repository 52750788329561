import { mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useState } from "react";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import { ledgerDefaultValue } from "../../../../../erp/accounts/models/ledger_model";
import { fetchPost } from "../../../content_panel/services/serviceClient";
import { OrgModel } from "./org/org_search";
export interface CreateOrgData {
  uid: string;
  email: string;
  org_name: string;
  namespace: string;
  session_time: string;
  supportMail: string;
  plan: string;
  templateApp: boolean;
}
const defaultData: CreateOrgData = {
  uid: "",
  email: "",
  org_name: "",
  namespace: "",
  session_time: "",
  supportMail: "",
  plan: "",
  templateApp: false,
};
interface OrgCreateWidgetProps {
  onClose: (data?: OrgModel) => void;
  data?: OrgModel;
}

const OrgCreateWidget: FunctionComponent<OrgCreateWidgetProps> = (props) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/cms/org`;
  const { showToast } = useToast();
  const [data, setdata] = useState<CreateOrgData>(defaultData);
  const [loading, setLoading] = useState(false);
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const res = await fetchPost(url, data);
    if (res.success) {
      showToast({ type: ToastType.success, text: res.message });
      if (props.onClose) props.onClose(res.data);
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };
  return (
    <>
      <form
        onSubmit={onSubmit}
        style={{ width: "40vw" }}
        className="relative flex flex-col gap-4  h-max bg-white p-4 rounded-md sm:w-[90vw] sm:h-max sm:p-4"
      >
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <label htmlFor="" className="text-gray-500 text-sm flex gap-1 ">
              Email<span className="text-red-500">*</span>
            </label>
            <input
              required
              onChange={(e) =>
                setdata((o) => ({ ...o, email: e.target.value }))
              }
              type="eamil"
              id="searchBar"
              className="border rounded-md drop-shadow-sm px-2 py-1 text-sm"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label
              htmlFor=""
              className="text-gray-500 text-sm flex gap-1 items-center"
            >
              Organization Name<span className="text-red-500">*</span>
            </label>
            <input
              required
              onChange={(e) =>
                setdata((o) => ({ ...o, org_name: e.target.value }))
              }
              type="text"
              id="searchBar"
              className="border rounded-md drop-shadow-sm px-2 py-1 text-sm"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label
              htmlFor=""
              className="text-gray-500 text-sm flex gap-1 items-center"
            >
              Organization Namespace<span className="text-red-500">*</span>
            </label>
            <input
              required
              onChange={(e) =>
                setdata((o) => ({ ...o, namespace: e.target.value }))
              }
              type="text"
              id="searchBar"
              className="border rounded-md drop-shadow-sm px-2 py-1 text-sm"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label
              htmlFor=""
              className="text-gray-500 text-sm flex gap-1 items-center"
            >
              Organization Plan<span className="text-red-500">*</span>
            </label>
            <select
              required
              onChange={(e) => setdata((o) => ({ ...o, plan: e.target.value }))}
              name=""
              className="border rounded-md drop-shadow-sm px-2 py-1 text-sm bg-white"
            >
              <option value="" hidden>
                Select{" "}
              </option>
              <option value="basic">Basic (Plan)</option>
              <option value="premium">Premium (Plan)</option>
              <option value="enterprise">Enterprise (Plan)</option>
            </select>
          </div>
          <div className="flex flex-col gap-1">
            <label
              htmlFor=""
              className="text-gray-500 text-sm flex gap-1 items-center"
            >
              API Token Validity (30-360 days){" "}
              <span className="text-red-500">*</span>
            </label>
            <input
              required
              onChange={(e) =>
                setdata((o) => ({ ...o, session_time: e.target.value }))
              }
              type="text"
              id="searchBar"
              className="border rounded-md drop-shadow-sm px-2 py-1 text-sm"
            />
          </div>
          <div className="flex gap-2">
            <input
              type="checkbox"
              name=""
              id=""
              onChange={(e) =>
                setdata((o) => ({
                  ...o,
                  templateApp: e.target.checked,
                }))
              }
            />
            <p className="text-gray-500 text-sm flex gap-1 items-center sm:flex-col sm:items-start sm:mb-1">
              Template App Present
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <label
              htmlFor=""
              className="text-gray-500 text-sm flex gap-1 items-center sm:flex-col sm:items-start sm:mb-1"
            >
              Support Manager Email{" "}
              <span className="text-xs">(only kazam mail)</span>
            </label>
            <input
              onChange={(e) =>
                setdata((o) => ({
                  ...o,
                  supportMail: e.target.value,
                }))
              }
              type="eamil"
              id="searchBar"
              className="border rounded-md drop-shadow-sm px-2 py-1 text-sm"
            />
          </div>
        </div>

        <div className="w-max ml-auto">
          <button
            disabled={loading}
            className="  flex items-center gap-2 border rounded-lg px-2 py-1  bg-blue-500 text-white"
          >
            Submit
            {loading && (
              <span className="animate-spin">
                <Icon path={mdiLoading} size={0.8} />
              </span>
            )}
          </button>
        </div>
      </form>
    </>
  );
};

export default OrgCreateWidget;
