import { FunctionComponent, useEffect, useState } from "react";
import { useOrderCreateCtx } from "../../../../../context_providers/order_create_context";
import { OrderType } from "../../../../../utils/enums";
import SearchFieldWidget from "../../../../new_components/search_field";
import BillingProfileWidget from "./billing_profile/billing_profile_widget";
import DeliveryProfileWidget from "./billing_profile/delivery_profile_widget";
import CustomerWidget from "./customer/customer_widget";
import NewDeliveryProfileWidget from "./billing_profile/new_delivery_profile_widget";
import NewBillingProfileWidget from "./billing_profile/new_billing_profile_widget";

interface CustomerSectionWidgetProps {}

const CustomerSectionWidget: FunctionComponent<
  CustomerSectionWidgetProps
> = () => {
  const { order, setOrder, user, setUser, deliveryProfile, setBilling } =
    useOrderCreateCtx();
  const [same, setSame] = useState(true);
  useEffect(() => {
    if (same && deliveryProfile) {
      setBilling(deliveryProfile);
    } else {
      setBilling(undefined);
    }
  }, []);
  return (
    <>
      {" "}
      <div className="grid grid-cols-4 text-sm bg-white p-5 justify-center rounded-lg">
        <div className=""></div>
        <div className="flex  whitespace-nowrap col-span-3">
          <h3 className="  font-semibold ">Order Type</h3>
          {Object.values(OrderType).map((type, i) => {
            return (
              <div className="mr-3 flex gap-1" key={i}>
                <input
                  onChange={(e) => {
                    setOrder((ol) => ({ ...ol, type }));
                  }}
                  className="mx-2"
                  type="radio"
                  checked={order.type === type}
                  name="order-type"
                  required
                  id={`${type}-${i}`}
                />
                <label className="" htmlFor={`${type}-${i}`}>
                  {type}
                </label>
              </div>
            );
          })}
        </div>
      </div>
      <div className="w-full my-5 flex flex-col gap-5">
        <div className="grid grid-cols-4 bg-white rounded-lg ">
          <div className="text-lg font-semibold p-5 flex justify-center items-center">
            Customer/User<span className="text-base text-red-500">*</span>
          </div>
          <div className="col-span-3 bg-gray-50   p-5 py-10 rounded-md">
            <CustomerWidget />
          </div>
        </div>

        {user && (
          <>
            <div className="grid grid-cols-4 bg-white rounded-lg ">
              <div className="text-lg font-semibold p-5 flex justify-center items-center">
                Delivery Address
                <span className="text-base text-red-500">*</span>
              </div>
              <div className="col-span-3 bg-gray-50   p-5  rounded-md">
                <NewDeliveryProfileWidget />
              </div>
            </div>
            <div className="grid grid-cols-4 bg-white rounded-lg ">
              <div className="text-lg font-semibold p-5 flex justify-center items-center">
                Billing Address
                <span className="text-base text-red-500">*</span>
              </div>
              <div className="col-span-3 bg-gray-50   p-5 rounded-md">
                <NewBillingProfileWidget />
              </div>
            </div>
          </>
        )}
      </div>
      {/* <div className="flex justify-between mt-5">
        <h3 className="font-semibold">
          {" "}
          <span className="text-base text-red-500">*</span>Customer
        </h3>
      </div>
      <BillingProfileWidget />
      <DeliveryProfileWidget /> */}
    </>
  );
};

export default CustomerSectionWidget;
