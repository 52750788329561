import { FunctionComponent, useEffect, useState } from "react";
import {
  OpsTaskDeviceItem,
  OpsTaskDeviceItemStatus,
  OpsTaskModel,
} from "../../../../../../service/models/ops/ops_ticket";
import { Link, useRouteMatch } from "react-router-dom";
import { useOpsTask } from "../../../../../../context_providers/ops_task_engineer_detail/ops_task_engineer_detail";
import Icon from "@mdi/react";
import {
  mdiChevronRight,
  mdiClose,
  mdiShield,
  mdiShieldCheckOutline,
} from "@mdi/js";
import {
  ModalType,
  useModal,
} from "../../../../../../context_providers/modal/modal_context";
import {
  ToastType,
  useToast,
} from "../../../../../../context_providers/toast/toast_ctx";
import { fetchPost, fetchPut } from "../../../../../../service/api_client";
import Html5QrcodePlugin from "./html5QrcodePlugin";
import { Ops_task_status } from "../../../../../../utils/enums";

interface DeviceListSectionProps {}

const DeviceListSection: FunctionComponent<DeviceListSectionProps> = (
  props
) => {
  const { data, setData } = useOpsTask();
  const { url, path } = useRouteMatch();
  const { showToast } = useToast();
  const { showModal, onClose } = useModal();
  const [showScan, setshowScan] = useState(false);
  const [scanedDevice, setScanedDevice] = useState("");
  const addDevice = async (item: OpsTaskDeviceItem) => {
    // if (!window.confirm("Are you sure ?")) return;

    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/new-ops/task/my-tasks/${data?.id}/device_item`;
    const res = await fetchPost(url, item);
    if (res.success) {
      setData((o) => ({
        ...o!,
        device_items: [...(o!.device_items || []), item],
      }));
      showToast({
        type: ToastType.success,
        text: res.messsage ?? "Added successfully !",
      });
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    // setLoading(false);
  };

  useEffect(() => {
    if (scanedDevice) {
      if (scanedDevice.length == 6) {
        const item: OpsTaskDeviceItem = {
          id: Math.ceil(Math.random() * 1000).toString(),
          device_id: scanedDevice.toLowerCase(),
          name: "",
          status: OpsTaskDeviceItemStatus.pending,
          remark: "",
          components: [],
        };
        const exist = data?.device_items?.reduce(
          (pv, v) => pv || v.device_id.toLowerCase() === item.device_id,
          false
        );
        if (exist) {
          return showToast({
            type: ToastType.error,
            text: "Device id exist !",
          });
        }
        // showModal({
        //   type: ModalType.modal,
        //   title: "Confirmation",

        // });
        if (
          window.confirm(
            `Scanned Device Id "${item.device_id}" \nAre your sure to submit ?`
          )
        ) {
          addDevice(item);
          setshowScan(false);
        }
        setScanedDevice("");
        // setData((f) => ({
        //   ...f!,
        //   device_items: [...(f?.device_items || []), item],
        // }));
      } else {
        showToast({ type: ToastType.error, text: "Invalid device id" });
      }
    }
  }, [scanedDevice]);

  return (
    <>
      <div className="">
        <div className=" my-2">
          <div className="flex justify-between my-1">
            <h2 className="font-semibold">Devices</h2>
            {data?.start_date &&
              !data.end_date &&
              data.status == Ops_task_status.under_progress && (
                <button
                  onClick={() => setshowScan(true)}
                  className="rounded-3xl bg-blue-50 text-blue-500 border-blue-300 border px-3"
                >
                  Add
                </button>
              )}
          </div>
          <div className="flex flex-col gap-2">
            {data?.device_items?.length ? (
              data?.device_items?.map((device, i) => (
                <Link
                  key={i}
                  to={`${url}/device/${device.device_id}/detail`}
                  className="border rounded-lg p-3 py-5 bg-white flex justify-between "
                >
                  <div className="flex flex-col">
                    <div className="">
                      <p className="uppercase">{device.device_id}</p>
                    </div>
                    <div className="">
                      <p
                        className={`font-semibold ${
                          device.status == OpsTaskDeviceItemStatus.pending
                            ? "text-yellow-600"
                            : device.status == OpsTaskDeviceItemStatus.completed
                            ? "text-green-600"
                            : "text-red-500"
                        }`}
                      >
                        {device.status}
                      </p>
                    </div>
                  </div>
                  <Icon path={mdiChevronRight} size={0.8} />
                </Link>
              ))
            ) : (
              <div className="border rounded-lg p-3 py-5 bg-white flex justify-center  ">
                {" "}
                No Device selected!
              </div>
            )}
          </div>
        </div>
        {/* <div className="fixed bottom-3 right-3 left-3 "> */}
        {/* <Link to={`${url}/checklist`} className=" my-2 ">
          <div className="flex justify-center bg-purple-50 text-purple-500 border rounded-lg border-purple-300 text-center px-3 py-2 w-full font-bold uppercase ">
            <Icon path={mdiShieldCheckOutline} size={0.8} /> Safety check
          </div>
        </Link> */}
        {/* </div> */}
      </div>
      {showScan && (
        <div className="fixed bottom-0 right-0 left-0 top-0 z-10   backdrop-filter backdrop-blur-xs flex flex-col justify-end ">
          <div className="bg-white rounded-t-3xl p-2  ">
            <div className="flex justify-center">
              <div className="h-1 w-10 bg-gray-500 rounded-full"></div>
            </div>
            <div className="flex  py-2 font-semibold justify-between ">
              <h1>Scan device</h1>
              <button
                className=""
                onClick={() => {
                  setScanedDevice("");
                  setshowScan(false);
                }}
              >
                {" "}
                <Icon path={mdiClose} size={1} />
              </button>
            </div>

            <Html5QrcodePlugin
              qrCodeErrorCallback={(e) => {
                // console.log(e);
              }}
              qrCodeSuccessCallback={function (
                decodedText: string,
                decodedResult: any
              ): void {
                if (decodedText) {
                  setScanedDevice(decodedText);
                }
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};
export default DeviceListSection;
