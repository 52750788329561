import { Card } from "@material-ui/core";
import { FunctionComponent, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import { AcademyVideoModel } from "../../../../../service/models/academy";
import { GetAcademyVideoData } from "../../../../../service/repos/academy/academy_repo";
import { academyVideoCategory } from "../../../../../utils/enums";
import { urls } from "../../../../../utils/urls";

interface SalesProps {}

const Sales: FunctionComponent<SalesProps> = () => {
  const { showToast } = useToast();
  const [videos, setVideos] = useState<any>();

  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(3);

  const getUploadData = async (p: number, c: number) => {
    const data: { page: number; count: number; category: string } = {
      page: p,
      count: c,
      category: academyVideoCategory.Sales,
    };
    const res = await GetAcademyVideoData(data);
    console.log(res);

    if (res.success) {
      setVideos(res.data?.videos);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
  };

  useEffect(() => {
    getUploadData(page, count);
  }, []);

  return (
    <>
      {videos && videos.length > 0 && (
        <div className="flex flex-col items-center  pt-20">
          <div className=" w-11/12 lg:w-4/5">
            <div className="text-2xl font-semibold">Top videos from sales</div>

            <div className=" grid grid-cols-1 lg:grid-cols-4  gap-8 lg:gap-12 mt-8">
              {videos.slice(0, 4).map((el: AcademyVideoModel, i: number) => {
                return (
                  <div className="w-full h-60">
                    <iframe
                      src={el.url.replace("watch?v=", "embed/")}
                      title="sales"
                      frameBorder="0"
                      width="100%"
                      height="100%"
                      allowFullScreen={true}
                    ></iframe>
                  </div>
                );
              })}
            </div>

            <NavLink to={urls.general_learning}>
              <div className="flex flex-col items-end underline mt-3 text-lg">
                View all category &gt; &gt;
              </div>
            </NavLink>
          </div>
        </div>
      )}
    </>
  );
};

export default Sales;

// <div className="">
//   {tempUrl ? (
//     <img
//       src={tempUrl}
//       alt=""
//       className="w-full lg:h-96 object-cover"
//     />
//   ) : (
//     <div className="">No image</div>
//   )}
// </div>
