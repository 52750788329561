import { FunctionComponent } from "react";
import { InputFields } from "./form";

export interface DateProps {
  alignment?: string;
  name: string;
  label: string;
  value: any;
  inputChange?: (data: any) => void;
  disabled?: boolean;
  required?: boolean;
  defaultValue?: any;
}
const DatePicker: FunctionComponent<DateProps> = (props) => {
  const {
    alignment,
    name,
    label,
    value,
    defaultValue,
    inputChange,
    disabled = false,
    required = false,
  } = props;

  return (
    <div
      className={`grid ${alignment === "row" ? "grid-cols-3" : "grid-col-1"}`}
    >
      <label htmlFor={name} className="">
        {label}
      </label>
      <input
        className="focus:outline-none p-2 bg-gray-100 rounded col-span-2"
        disabled={disabled}
        id={name}
        name={name}
        type={InputFields.DATE}
        onChange={(e) => {
          if (inputChange) inputChange(e.target.value);
        }}
        value={value}
        defaultValue={defaultValue}
        required={required}
        title={label + " is required"}
      />
    </div>
  );
};
export default DatePicker;
