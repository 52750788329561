import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../context_providers/auth/authProvider";
import {
  ToastType,
  useToast,
} from "../../../context_providers/toast/toast_ctx";
import { fetchPut, fetchGet } from "../../../service/api_client";
import { numberRegEx } from "../../../utils/constant";
import { UTCToLocalDate } from "../../../utils/date_util";
import { currencyFormat } from "../../../utils/orders_utils";
import LedgerSearchField from "../../accounts/ledgers/ledger_search_field";
import {
  accountTxnType,
  LedgerEntryModel,
} from "../../accounts/models/common_model";
import { account_group } from "../../accounts/models/ledger_group_model";
import { LedgerModel } from "../../accounts/models/ledger_model";
import {
  currencies,
  default_mail_detail,
} from "../../accounts/models/party_model";
import { ledgerEntryDefaultValue } from "../../accounts/models/purchase_model";
import PartySearchField from "../../accounts/parties/party_search_field";
import WarehouseSearchField from "../../inventory/warehouse/warehouse_search_field";
import {
  PoItem,
  po_approval_status,
  po_default_value,
  po_status,
  PurchaseOrderModel,
} from "../model/purchase_order_model";
import PoItemRow from "../purchase_order_create/components/item_row";
import TaxEntryRow, {
  getItemTaxAmount,
} from "../purchase_order_create/components/tax_list";
import { PaymentMode } from "../../../utils/enums";
import { mdiInformationOutline } from "@mdi/js";
import Icon from "@mdi/react";
import ApprovalBottomSheetWidget from "./components/bottom_sheet";
import ProjectSearchField from "../projects/project_master/project_search_field";
import RequiredMark from "../../../ui/new_components/common/required_mark";
import {
  payment_request_default,
  PaymentRequestModel,
} from "../model/payment_request_model";
import { ProcurementProjectModel } from "../projects/model/project_model";
import { WAREHOUSE_TYPE } from "../../inventory/models/warehouse_model";

interface PurchaseOrderApprovalScreenProps {}

const PurchaseOrderApprovalScreen: FunctionComponent<
  PurchaseOrderApprovalScreenProps
> = (props) => {
  const { user } = useAuth();
  const { id } = useParams<{ id: string }>();
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order`;
  const [, setLoading] = useState(false);
  const { showToast } = useToast();
  const [, setOldTotal] = useState(0);
  const [, setShowCreatePaymentRequestModal] = useState(false);
  const [project, setProject] = useState<ProcurementProjectModel>();
  const [disabled] = useState();
  const [data, setData] = useState<PurchaseOrderModel>({
    ...po_default_value,
    shipping_profile: {
      ...default_mail_detail,
      contact_name: user?.first_name + " " + (user?.last_name || ""),
      contact_mobile: user?.mobile || "",
      contact_email: user?.email || "",
    },
  });
  const submit = async ({
    approval_status,
    level,
  }: {
    approval_status: string;
    level: string;
  }) => {
    let reason = "";
    if (
      approval_status === po_approval_status.rejected ||
      approval_status === po_approval_status.pending
    ) {
      reason =
        window.prompt(
          approval_status === po_approval_status.rejected
            ? "What's the reason for Rejection ?"
            : "What's the reason for Pending ?"
        ) || "";
      reason = reason.trim();
      if (!reason) return;
    }
    if (
      !window.confirm(
        `Are you sure to ${approval_status} the ${level} approval ?`
      )
    )
      return;

    setLoading(true);
    const res = await fetchPut(url + "/" + data.id + "/approval", {
      approval_status,
      level,
      id: data.id,
      reason,
    });

    if (res.success) {
      setData((o) => ({ ...o, ...res.data }));
      showToast({ type: ToastType.success, text: res.message });
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  useEffect(() => {
    if (id) getData(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (data.id && !project && data.project_id) {
      getProjectData(data.project_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const getData = async (id: string) => {
    setLoading(true);
    const res = await fetchGet(url + "/" + id);
    if (res.success) {
      if (!res.data.vendor_currency) {
        res.data.vendor_currency = currencies.rupee;
        res.data.vendor_currency_to_inr = 1;
      }
      setData(res.data);
      setOldTotal(res.data.total);
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };
  const [, setPaymentRequest] = useState<PaymentRequestModel>({
    ...payment_request_default,
  });
  const getProjectData = async (id: string) => {
    setLoading(true);

    const res = await fetchGet(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/project/${id}`
    );
    if (res.success) {
      setProject({ ...res.data });
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  return (
    <>
      <div className="relative bg-myBgcolor h-screen px-2 md:px-5  overflow-auto">
        <div className="flex items-center justify-between py-2 sticky top-0 bg-myBgcolor">
          <div className="text-xl font-bold border-l-4 border-myPrimaryColor pl-2">
            Purchase Order <span className="text-sm">#{data.po_no}</span>
          </div>
          <div className="flex gap-2 items-center">
            <label htmlFor="" className="text-sm font-semibold text-gray-500">
              Date
            </label>
            <input
              disabled={true}
              type="date"
              className=" focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full text-sm border"
              value={data.date ? moment(data.date).format("YYYY-MM-DD") : ""}
              onChange={(e) => {
                const date = UTCToLocalDate(e.target.value)!;
                setData((o) => ({
                  ...o,
                  date: date,
                }));
              }}
            />
          </div>
        </div>
        {data.approval_status === po_approval_status.rejected &&
          data.commets.length > 0 && (
            <p>
              <b>Reason of rejection :</b>
              {data.commets[data.commets.length - 1].comment}
            </p>
          )}
        <div className="bg-white rounded-lg px-3 py-5 my-2 grid grid-cols-1 md:grid-cols-4 ">
          <div className="col-span-2 flex flex-col gap-2">
            <div className="">
              <label htmlFor="" className="text-sm font-semibold  ">
                Project <RequiredMark />
              </label>{" "}
              <ProjectSearchField
                disabled={disabled}
                placeholder="Select Project"
                value={project}
                onSelect={(d) => {
                  setProject(d);
                }}
              />
            </div>

            <div className=" text-sm mt-2">
              <div className="grid grid-cols-4">
                <h5>Project name</h5>
                <p className="col-span-3">:{project?.name}</p>
              </div>
              <div className="grid grid-cols-4">
                <h5>Description</h5>
                <p className="col-span-3">:{project?.description}</p>
              </div>
              <hr />
              <div className="grid grid-cols-4">
                <h5>Amount :</h5>
                <p className="col-span-3  text-right">
                  {currencyFormat(project?.amount || 0)}
                </p>
              </div>
              <div className="grid grid-cols-4">
                <h5>Spent :</h5>
                <p className="col-span-3  text-right">
                  {currencyFormat(project?.spent || 0)}
                </p>
              </div>
              <hr />
              <div className="grid grid-cols-4">
                <h5>Balance : </h5>
                <p className="col-span-3 text-right">
                  {currencyFormat(
                    (project?.amount || 0) - (project?.spent || 0)
                  )}
                </p>
              </div>

              <div className="flex gap-1 text-gray-500 text-xs mt-2 items-center">
                <Icon path={mdiInformationOutline} size={0.8}></Icon>Note : this
                balance is real-time ,Eg: if you create a po on x date and
                balance is y then if you open the same po on z date balance may
                be v.
              </div>
            </div>
          </div>
          {console.log(
            data.po_no,
            " ",
            data.approval_level,
            " ",
            data.current_approval_level,
            " ",
            data.current_approval_count,
            " ",
            data.current_approval_required_count
          )}

          {data.po_no &&
            data.approval_level === data.current_approval_level &&
            (data.current_approval_count ?? 0) >=
              (data.current_approval_required_count ?? 0) && (
              <div className="flex items-start col-span-2">
                <button
                  className="ml-auto bg-myPrimaryColor px-3 py-1 text-sm rounded-md text-white"
                  onClick={() => {
                    setPaymentRequest((o) => ({
                      ...o,
                      amount: data.total - (data?.paid || 0),
                    }));
                    setShowCreatePaymentRequestModal(true);
                  }}
                >
                  Create Payment Request
                </button>
              </div>
            )}
        </div>
        <div className="bg-white rounded-lg px-3 py-5 ">
          <div className="grid grid-cols-2 gap-10 ">
            <div className="">
              <div className=" grid grid-cols-2 text-sm mt-2 gap-2">
                {" "}
                <label htmlFor="" className="text-sm font-semibold  ">
                  Vendor
                </label>{" "}
                <PartySearchField
                  show_add={true}
                  disabled={true}
                  placeholder="Vendor"
                  value={data.party ? data.party : undefined}
                  onSelect={(d) => {
                    setData((o) => ({
                      ...o,
                      party: d,
                      party_mail_detail: d.mail_detail,
                    }));
                  }}
                />
                <div className="">Address</div>
                <div className="">
                  {data.party_mail_detail.address.city},
                  {data.party_mail_detail.address.country}-
                  {data.party_mail_detail.address.pincode},
                </div>
                <div className="">Contact person</div>
                <div className="">
                  {" "}
                  <input
                    disabled={true}
                    type="text"
                    placeholder="Enter name"
                    value={data.party_mail_detail?.contact_name}
                    onChange={(e) => {
                      setData((o) => ({
                        ...o,
                        party_mail_detail: {
                          ...o.party_mail_detail,
                          contact_name: e.target.name,
                        },
                      }));
                    }}
                    className="p-1 focus:outline-none border rounded w-full "
                  />
                </div>
                <div className="">Contact phone</div>
                <div className="">
                  {" "}
                  <input
                    disabled={true}
                    type="tel"
                    placeholder="Enter contact number"
                    value={data.party_mail_detail?.contact_mobile}
                    onChange={(e) => {
                      setData((o) => ({
                        ...o,
                        party_mail_detail: {
                          ...o.party_mail_detail,
                          contact_mobile: e.target.name,
                        },
                      }));
                    }}
                    className="p-1 focus:outline-none border rounded w-full "
                  />
                </div>
                <div className="">Contact email</div>
                <div className="">
                  {" "}
                  <input
                    disabled={true}
                    type="email"
                    placeholder="Enter email"
                    value={data.party_mail_detail?.contact_email}
                    onChange={(e) => {
                      setData((o) => ({
                        ...o,
                        party_mail_detail: {
                          ...o.party_mail_detail,
                          contact_email: e.target.name,
                        },
                      }));
                    }}
                    className="p-1 focus:outline-none border rounded  w-full"
                  />
                </div>
                <div className="">Currency</div>
                <div className="">
                  <select
                    disabled
                    required
                    className="p-1 focus:outline-none border rounded  w-full"
                    id="currency"
                    name="currency"
                    value={
                      data.vendor_currency &&
                      JSON.stringify(data.vendor_currency)
                    }
                    onChange={(e) => {
                      setData((o) => ({
                        ...o,
                        vendor_currency: JSON.parse(e.target.value),
                      }));
                    }}
                  >
                    <option value="" hidden>
                      select
                    </option>
                    {Object.values(currencies).map((currency: any) => (
                      <option value={JSON.stringify(currency)}>
                        {currency.formal_name}({currency.symbol})
                      </option>
                    ))}
                  </select>
                </div>
                <div className="">Currency Exchange rate({"\u20b9"})</div>
                <div className="">
                  <input
                    disabled={true}
                    type="number"
                    placeholder="Exchange rate of inr"
                    value={data.vendor_currency_to_inr}
                    onChange={(e) => {
                      setData((o) => ({
                        ...o,
                        vendor_currency_to_inr: Number(e.target.value),
                      }));
                    }}
                    className="p-1 focus:outline-none border rounded  w-full"
                  />
                  <div className="flex gap-1 text-gray-500 text-xs mt-2 items-center">
                    <Icon path={mdiInformationOutline} size={0.8}></Icon>
                    INR Per {data.vendor_currency.short_name} Eg: INR 70 = USD 1
                    then enter 70
                  </div>{" "}
                </div>
              </div>
            </div>
            <div className="">
              <div className=" grid grid-cols-2 text-sm mt-2 gap-1">
                {" "}
                <label htmlFor="" className="text-sm font-semibold  ">
                  Shipping profile
                </label>
                <WarehouseSearchField
                  type={WAREHOUSE_TYPE.warehouse}
                  disabled={true}
                  placeholder="Warehouse"
                  value={data.warehouse.id ? data.warehouse : undefined}
                  onSelect={(d) => {
                    setData((o) => ({
                      ...o,
                      warehouse: d,
                      warehouse_id: d.id,
                      shipping_profile: {
                        ...o.shipping_profile,
                        address: d.address!,
                      },
                    }));
                  }}
                />
                <div className="">Address</div>
                <div className="">
                  {data.shipping_profile?.address.city},
                  {data.shipping_profile?.address.country}-
                  {data.shipping_profile?.address.pincode},
                </div>
                <div className="">Contact person</div>
                <div className="">
                  {" "}
                  <input
                    disabled={true}
                    type="text"
                    placeholder="Enter name"
                    value={data.shipping_profile?.contact_name}
                    onChange={(e) => {
                      setData((o) => ({
                        ...o,
                        shipping_profile: {
                          ...o.shipping_profile,
                          contact_name: e.target.name,
                        },
                      }));
                    }}
                    className="p-1 focus:outline-none border rounded w-full "
                  />
                </div>
                <div className="">Contact phone</div>
                <div className="">
                  {" "}
                  <input
                    disabled={true}
                    type="tel"
                    placeholder="Enter contact number"
                    value={data.shipping_profile?.contact_mobile}
                    onChange={(e) => {
                      setData((o) => ({
                        ...o,
                        shipping_profile: {
                          ...o.shipping_profile,
                          contact_mobile: e.target.name,
                        },
                      }));
                    }}
                    className="p-1 focus:outline-none border rounded w-full "
                  />
                </div>
                <div className="">Contact email</div>
                <div className="">
                  {" "}
                  <input
                    disabled={true}
                    type="email"
                    placeholder="Enter email"
                    value={data.shipping_profile?.contact_email}
                    onChange={(e) => {
                      setData((o) => ({
                        ...o,
                        shipping_profile: {
                          ...o.shipping_profile,
                          contact_email: e.target.name,
                        },
                      }));
                    }}
                    className="p-1 focus:outline-none border rounded  w-full"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="my-5">
          <h3 className="text-sm font-bold">Items</h3>
          <div
            className="bg-white rounded flex flex-col gap-1 border  overflow-auto h-80"
            // style={{ height: "75vh" }}
          >
            <div className="grid grid-cols-9 gap-5 p-1 bg-myPrimaryColor text-white text-sm font-semibold rounded-t">
              <div className="col-span-2">Particular</div>
              <div className="text-center">HSN/SAC</div>
              <div className="text-center">qty</div>
              <div className="text-center">Rate</div>
              <div className="text-right">Amount</div>
              <div className="text-center" title="Received qty">
                Received qty
              </div>
              <div className="text-center" title="Returned qty">
                Returned qty
              </div>
              <div className="text-right"> </div>
            </div>
            <div className="flex flex-col gap-1 px-1 ">
              {data.items.map((item, i) => {
                return (
                  <PoItemRow
                    currency={data.vendor_currency}
                    disabled={true}
                    key={item.id}
                    data={item}
                    onSubmit={function (data: PoItem): void {
                      setData((o) => ({
                        ...o,
                        items: [
                          ...(o.items || []).map((it) => {
                            if (it.id === data.id) return data;
                            return it;
                          }),
                        ],
                      }));
                    }}
                    edit={false}
                  />
                );
              })}
              {/* <PoItemRow
                key={(Math.random() * 1000).toString()}
                onSubmit={function (data: PoItem): void {
                  data.id = (Math.random() * 1000).toString();
                  setData((o) => ({
                    ...o,
                    items: [...(o.items || []), data],
                  }));
                }}
                edit={true}
                disabled={false}
              /> */}
            </div>
          </div>
        </div>{" "}
        <div className="grid grid-cols-2 gap-5 my-2">
          <div className="">
            <h3 className="text-sm font-bold">Taxes</h3>
            <div className="bg-white rounded flex flex-col gap-1">
              <div className="grid grid-cols-3 gap-5 p-1 bg-myPrimaryColor text-white text-sm font-semibold rounded-t">
                <div className="col-span-2">Particular</div>
                <div className="text-center">Amount</div>
              </div>
              {data.taxes.map((tax, i) => (
                <TaxEntryRow
                  currency={data.vendor_currency}
                  disabled={true}
                  key={i}
                  entry={tax}
                  items={[...(data.items || [])]}
                  onDelete={(d) => {
                    d.type = accountTxnType.debitor;
                    setData((o) => {
                      const taxes = [...(o.taxes || [])].filter(
                        (i) => i.id !== d.id
                      );
                      let tax = taxes.reduce((pv, val) => pv + val.amount, 0);
                      const total = o.subtotal + (tax ?? 0) - (o.discount ?? 0);
                      return {
                        ...o,
                        taxes,
                        tax,
                        total,
                      };
                    });
                  }}
                  onSubmit={function (d: LedgerEntryModel): void {
                    setData((o) => {
                      const taxes = [
                        ...(o.taxes || [[]]).map((t) => {
                          if (t.id === d.id) {
                            return { ...d };
                          }
                          return { ...t };
                        }),
                      ].map((t, i) => {
                        // if (data.entry_type === voucherEntryType.Invoice)
                        let tax = getItemTaxAmount({
                          entry_type: "Invoice",
                          items: data.items || [],
                          ledgerTaxDetail: t.ledger!.tax_detail!,
                        });
                        if (typeof tax == "number") {
                          t.amount = tax;
                        }
                        return { ...t };
                      });
                      let tax = taxes.reduce((pv, val) => pv + val.amount, 0);
                      const total = o.subtotal + (tax ?? 0) - (o.discount ?? 0);
                      return {
                        ...o,
                        taxes,
                        tax,
                        total,
                      };
                    });
                    // setAddNewTaxId(Math.random() * 1000);
                  }}
                  edit={false}
                />
              ))}
              {/* <TaxEntryRow
                // key={addNewTaxId}
                items={[...(data.items || [])]}
                onSubmit={function (d: LedgerEntryModel): void {
                  d.id = (Math.random() * 1000).toString();
                  setData((o) => {
                    d.type = accountTxnType.debitor;
                    const taxes = [...(o.taxes || [[]]), d].map((t, i) => {
                      // if (data.entry_type === voucherEntryType.Invoice)
                      let tax = getItemTaxAmount({
                        entry_type: "Invoice",

                        items: data.items || [],
                        ledgerTaxDetail: t.ledger!.tax_detail!,
                      });
                      if (typeof tax == "number") {
                        t.amount = tax;
                      }
                      return { ...t };
                    });
                    let tax = taxes.reduce((pv, val) => pv + val.amount, 0);
                    const total = o.subtotal + (tax ?? 0) - (o.discount ?? 0);
                    return {
                      ...o,
                      taxes,
                      tax,
                      total,
                    };
                  });
                  // setAddNewTaxId(Math.random() * 100);
                }}
                edit={true}
                disabled={true}
              /> */}
            </div>
          </div>
          <div className="">
            <h3 className="text-sm font-bold">Discounts</h3>
            <div className="bg-white rounded flex flex-col gap-1">
              <div className="grid grid-cols-3 gap-5 p-1 bg-myPrimaryColor text-white text-sm font-semibold rounded-t">
                <div className="col-span-2">Particular</div>
                <div className="text-center">Amount</div>
              </div>
              <div className="grid grid-cols-3 gap-5 p-1  ">
                <div className="col-span-2">
                  <LedgerSearchField
                    disabled={true}
                    group={[account_group.indirect_income.name]}
                    value={
                      data.discounts[0] ? data.discounts[0].ledger : undefined
                    }
                    onSelect={function (data: LedgerModel): void {
                      setData((ol) => {
                        return {
                          ...ol,

                          discounts: [
                            {
                              ...(ol.discounts[0] || ledgerEntryDefaultValue),
                              ledger: data,
                              ledger_id: data.id,
                              type: accountTxnType.creditor,
                            },
                          ],
                        };
                      });
                    }}
                  />
                </div>
                <div className="grid grid-cols-4  items-center">
                  <div className="text-right">
                    {data.vendor_currency.symbol}
                  </div>{" "}
                  <input
                    disabled={true}
                    value={data.discounts[0]?.amount}
                    onChange={(e) => {
                      const val = e.target.value.trim();
                      if (!val || numberRegEx.test(val)) {
                        const value = parseFloat(val || "0");
                        setData((ol) => ({
                          ...ol,
                          discounts: [
                            {
                              ...(ol.discounts[0] || ledgerEntryDefaultValue),
                              amount: value,
                              type: accountTxnType.creditor,
                            },
                          ],
                          discount: value,
                          total: ol.subtotal + (ol.tax || 0) - value,
                        }));
                      }
                    }}
                    placeholder=""
                    type="text"
                    className="text-right  p-1 focus:outline-none border rounded  w-full col-span-3"
                  />
                </div>
              </div>
            </div>
            {/* <div className="text-sm flex justify-end">
              <div className="flex flex-col gap-2">
                <div className=" flex gap-5 justify-between">
                  <div className="">Sub total:</div>
                  <div className="">{data.subtotal || 0}</div>
                </div>
                <div className=" flex gap-5 justify-between">
                  <div className="">Tax:</div>
                  <div className="">{data.tax || 0}</div>
                </div>
                <div className=" flex gap-5 justify-between">
                  <div className="">Discount:</div>
                  <div className="">{data.discount || 0}</div>
                </div>
                <div className=" flex gap-5 justify-between font-bold">
                  <div className="">Total:</div>
                  <div className="">{data.total || 0}</div>
                </div>
              </div>
            </div> */}
          </div>
          <div className="">
            <h3 className="text-sm font-bold">Extra charges</h3>
            <div className="bg-white rounded flex flex-col gap-1">
              <div className="grid grid-cols-3 gap-5 p-1 bg-myPrimaryColor text-white text-sm font-semibold rounded-t">
                <div className="col-span-2">Particular</div>
                <div className="text-center">Amount</div>
              </div>
              <div className="grid grid-cols-3 gap-5 p-1  ">
                <div className="col-span-2">
                  <LedgerSearchField
                    disabled={true}
                    group={[
                      account_group.indirect_expense.name,
                      account_group.misc_expense.name,
                    ]}
                    value={data.extras[0] ? data.extras[0].ledger : undefined}
                    onSelect={function (data: LedgerModel): void {
                      setData((ol) => {
                        return {
                          ...ol,

                          extras: [
                            {
                              ...(ol.extras[0] || ledgerEntryDefaultValue),
                              ledger: data,
                              ledger_id: data.id,
                              type: accountTxnType.debitor,
                            },
                          ],
                        };
                      });
                    }}
                  />
                </div>

                <div className="grid grid-cols-4  items-center">
                  <div className="text-right">
                    {data.vendor_currency.symbol}
                  </div>{" "}
                  <input
                    disabled={true}
                    value={data.extras[0]?.amount}
                    onChange={(e) => {
                      const val = e.target.value.trim();
                      if (!val || numberRegEx.test(val)) {
                        const value = parseFloat(val || "0");
                        setData((ol) => ({
                          ...ol,
                          extras: [
                            {
                              ...(ol.extras[0] || ledgerEntryDefaultValue),
                              amount: value,
                              type: accountTxnType.creditor,
                            },
                          ],
                          extra: value,
                          total:
                            ol.subtotal +
                            (ol.tax || 0) -
                            (ol.discount || 0) +
                            value,
                        }));
                      }
                    }}
                    placeholder=""
                    type="number"
                    className="text-right  p-1 focus:outline-none border rounded  w-full col-span-3"
                  />
                </div>
              </div>
            </div>
            {/* <div className="text-sm flex justify-end">
              <div className="flex flex-col gap-2">
                <div className=" flex gap-5 justify-between">
                  <div className="">Sub total:</div>
                  <div className="">{data.subtotal || 0}</div>
                </div>
                <div className=" flex gap-5 justify-between">
                  <div className="">Tax:</div>
                  <div className="">{data.tax || 0}</div>
                </div>
                <div className=" flex gap-5 justify-between">
                  <div className="">Discount:</div>
                  <div className="">{data.discount || 0}</div>
                </div>
                <div className=" flex gap-5 justify-between font-bold">
                  <div className="">Total:</div>
                  <div className="">{data.total || 0}</div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="grid grid-cols-3 divide-x-2  text-sm  bg-white rounded-lg p-3  ">
          <div className=" flex flex-col gap-1 pr-5 ">
            {" "}
            <div className="grid grid-cols-1  md:grid-cols-4   dark: items-center">
              <label htmlFor="valid_till_date">Valid till Date</label>
              <input
                disabled={true}
                value={
                  data.valid_till_date
                    ? moment(data.valid_till_date).format("YYYY-MM-DD")
                    : ""
                }
                onChange={(e) => {
                  setData((o) => ({
                    ...o,
                    valid_till_date: UTCToLocalDate(e.target.value)!,
                  }));
                }}
                type="date"
                name=""
                id="valid_till_date"
                className="p-1 focus:outline-none border rounded w-full  col-span-3"
              ></input>
            </div>
            <div className="grid grid-cols-4   dark: items-center">
              <label htmlFor="delivery_date">Delivery Date</label>
              <input
                disabled={true}
                value={
                  data.delivery_date
                    ? moment(data.delivery_date).format("YYYY-MM-DD")
                    : ""
                }
                onChange={(e) => {
                  setData((o) => ({
                    ...o,
                    delivery_date: UTCToLocalDate(e.target.value)!,
                  }));
                }}
                type="date"
                name=""
                id="delivery_date"
                className="p-1 focus:outline-none border rounded w-full  col-span-3"
              ></input>
            </div>
            <div className="grid grid-cols-4   items-center">
              <label htmlFor="delivery_method">Delivery Method</label>
              <select
                disabled={true}
                value={data.shipping_method}
                onChange={(e) => {
                  setData((o) => ({ ...o, shipping_method: e.target.value }));
                }}
                name=""
                id="delivery_method"
                className="p-1 focus:outline-none border rounded w-full  col-span-3"
              >
                <option value="" hidden>
                  select{" "}
                </option>
                {["Road", "Ship", "Airway"].map((m) => (
                  <option value={m} key={m}>
                    {m}
                  </option>
                ))}
              </select>
            </div>
            <div className="grid grid-cols-1  md:grid-cols-4  items-start">
              <label htmlFor="ship_term">Delivery terms</label>
              <textarea
                disabled={true}
                rows={3}
                value={data.ship_term}
                onChange={(e) => {
                  const sanitizedValue = e.target.value.replace(/[\n\r]/g, ""); // Remove newlines
                  setData((o) => ({
                    ...o,
                    ship_term: sanitizedValue,
                  }));
                }}
                // onChange={(e) => {
                //   setData((o) => ({
                //     ...o,
                //     ship_term: e.target.value,
                //   }));
                // }}
                name=""
                id="ship_term"
                className="p-1 focus:outline-none border rounded w-full  col-span-3"
              ></textarea>
            </div>
            <div className="grid grid-cols-1  md:grid-cols-4  items-start">
              <label htmlFor="payment_term">Payment terms</label>
              <textarea
                disabled={true}
                rows={3}
                value={data.payment_term}
                onChange={(e) => {
                  const sanitizedValue = e.target.value.replace(/[\n\r]/g, ""); // Remove newlines
                  setData((o) => ({
                    ...o,
                    payment_term: sanitizedValue,
                  }));
                }}
                // onChange={(e) => {
                //   setData((o) => ({
                //     ...o,
                //     payment_term: e.target.value,
                //   }));
                // }}
                name=""
                id="payment_term"
                className="p-1 focus:outline-none border rounded w-full  col-span-3"
              ></textarea>
            </div>
          </div>
          <div className="flex flex-col gap-1 px-5">
            <div className="grid grid-cols-1  md:grid-cols-4  items-center">
              <label htmlFor="payment_mode">Payment Mode</label>
              <select
                disabled={true}
                value={data.payment_mode}
                onChange={(e) => {
                  setData((o) => ({ ...o, payment_mode: e.target.value }));
                }}
                name=""
                id="payment_mode"
                className="p-1 focus:outline-none border rounded w-full  col-span-3"
              >
                <option value="" hidden>
                  select{" "}
                </option>
                {Object.values(PaymentMode).map((m) => (
                  <option value={m} key={m}>
                    {m}
                  </option>
                ))}
              </select>
            </div>
            <div className="grid grid-cols-4  items-center">
              <label htmlFor="status">Status</label>
              <select
                disabled={true}
                value={data.status}
                onChange={(e) => {
                  setData((o) => ({ ...o, status: e.target.value }));
                }}
                name=""
                id="status"
                className="p-1 focus:outline-none border rounded w-full  col-span-3"
              >
                <option value="" hidden>
                  select{" "}
                </option>
                {Object.values(po_status).map((m) => (
                  <option value={m} key={m}>
                    {m}
                  </option>
                ))}
              </select>
            </div>
            <div className="grid grid-cols-4 items-center">
              <label htmlFor="approval_status">Approval status</label>
              <select
                disabled={true}
                value={data.approval_status}
                onChange={(e) => {
                  setData((o) => ({ ...o, approval_status: e.target.value }));
                }}
                name=""
                id="approval_status"
                className="p-1 focus:outline-none border rounded w-full  col-span-3"
              >
                <option value="" hidden>
                  select{" "}
                </option>
                {Object.values(po_approval_status).map((m) => (
                  <option value={m} key={m}>
                    {m}
                  </option>
                ))}
              </select>
            </div>
            <div className="grid grid-cols-4">
              <label htmlFor="approved_by">Approved by</label>
              <input
                disabled
                value={
                  data.approval_required
                    ? data.approved_by_name || "--"
                    : "Not required "
                }
                name=""
                id="approved_by"
                type="text"
                className="p-1 focus:outline-none border rounded w-full  col-span-3"
              ></input>
            </div>
          </div>
          <div className=" flex flex-col gap-1 pl-5 ">
            <div className="flex justify-between">
              <div className="">Subtotal</div>
              <div className="">
                {currencyFormat(data.subtotal, data.vendor_currency.short_name)}
              </div>
            </div>{" "}
            <div className="flex justify-between ">
              <div className="flex  gap-2 items-end ">
                <label htmlFor="extra_desc">Extra </label>{" "}
              </div>

              <div className="">
                + {currencyFormat(data.extra, data.vendor_currency.short_name)}
              </div>
            </div>
            <div className="flex justify-between ">
              <div className="flex  gap-2 items-end ">
                <label htmlFor="tax_desc">Tax </label>{" "}
                {/* <input
                  placeholder="description"
                  type="text"
                  value={data.tax.desc}
                  onChange={(e) => {
                    setData((o) => ({
                      ...o,
                      tax: { ...o.tax, desc: e.target.value },
                    }));
                  }}
                  name=""
                  id="tax_desc"
                  className=" focus:outline-none  bg-transparent   "
                ></input> */}
              </div>
              {/* <input
                disabled
                placeholder={"\u20b9 0"}
                type="text"
                value={data.tax}
                onChange={(e) => {
                  let v = 0;

                  if (e.target.value && !isNaN(e.target.value as any)) {
                    v = parseFloat(e.target.value);
                  }
                  setData((o) => ({
                    ...o,
                    tax: v,
                  }));
                }}
                name=""
                id=" "
                className="p-1 focus:outline-none border rounded  w-32 text-right"
              ></input> */}
              <div className="">
                + {currencyFormat(data.tax, data.vendor_currency.short_name)}
              </div>
            </div>
            <div className="flex justify-between ">
              <div className="flex  gap-2 items-end ">
                <label htmlFor="discount_desc">Discount </label>{" "}
                {/* <input
                  placeholder=" description"
                  type="text"
                  value={data.discount.desc}
                  onChange={(e) => {
                    setData((o) => ({
                      ...o,
                      discount: { ...o.discount, desc: e.target.value },
                    }));
                  }}
                  name=""
                  id="discount_desc"
                  className=" focus:outline-none  bg-transparent   "
                ></input> */}
              </div>
              {/* <input
                disabled
                placeholder={"\u20b9 0"}
                type="text"
                value={data.discount}
                onChange={(e) => {
                  let v = 0;
                  if (e.target.value && !isNaN(e.target.value as any)) {
                    v = parseFloat(e.target.value);
                  }
                  setData((o) => ({
                    ...o,
                    discount: v,
                  }));
                }}
                name=""
                id=" "
                className="p-1 focus:outline-none border rounded  w-32 text-right"
              ></input> */}
              <div className="">
                -{" "}
                {currencyFormat(data.discount, data.vendor_currency.short_name)}
              </div>
            </div>
            <hr />
            <div className="flex justify-between font-bold ">
              <p>Total</p>{" "}
              <div className="">
                {currencyFormat(data.total, data.vendor_currency.short_name)}
              </div>
            </div>{" "}
            {data.vendor_currency.short_name !==
              currencies.rupee.short_name && (
              <div className="flex justify-between font-bold ">
                <p>Total in INR</p>{" "}
                <div className="">
                  {currencyFormat(data.total * data.vendor_currency_to_inr)}
                </div>
              </div>
            )}
            <hr />
            {/* {data.approval_status === po_approval_status.pending && (
              <div className=" flex justify-end my-3 ">
                <div className="flex gap-2">
                  <button
                    onClick={() => submit(po_approval_status.approved)}
                    className="px-5 py-1 rounded-md bg-myPrimaryColor text-white text-sm "
                  >
                    Approve
                  </button>
                  <button
                    onClick={() => submit(po_approval_status.rejected)}
                    className="px-7 py-1 rounded-md bg-red-400 text-white text-sm "
                  >
                    Reject
                  </button>
                </div>
              </div>
            )} */}
          </div>
        </div>
        <div className="sticky bottom-0 right-0 mt-3 w-full rounded-t-md bg-white shadow-sm py-3 px-5 border">
          {" "}
          <h3 className="text-sm font-semibold">Approval status</h3>
          <ApprovalBottomSheetWidget data={data} onSubmit={submit} />
        </div>
      </div>
    </>
  );
};

export default PurchaseOrderApprovalScreen;
