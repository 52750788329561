import {
  mdiChevronLeft,
  mdiChevronRight,
  mdiClose,
  mdiCloud,
  mdiCloudUpload,
  mdiCross,
  mdiFileDocument,
  mdiMagnify,
  mdiPencil,
  mdiPencilOutline,
  mdiShieldCheckOutline,
} from "@mdi/js";
import Icon from "@mdi/react";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { useAuth } from "../../../context_providers/auth/authProvider";
import {
  useToast,
  ToastType,
} from "../../../context_providers/toast/toast_ctx";
import { AddressModel } from "../../../service/models/address_model";
import { Complaintmodel } from "../../../service/models/complaint_model";
import { InventoryModel } from "../../../service/models/inventory/inventory_model";
import { WarehouseModel } from "../../../service/models/inventory/warehouse_model";
import {
  OpsTaskDeviceItem,
  OpsTaskModel,
} from "../../../service/models/ops/ops_ticket";
import { CustomerDetailModel } from "../../../service/models/orders/customer_detail_model";
import { GetCustomerOfOrderApi } from "../../../service/repos/customer/customer_repo";
import { GetInventoryListApi } from "../../../service/repos/inventory_repo";
import {
  CreateOpsTicketApi,
  GetOpsTaskApi,
  UpdateOpsTaskApi,
} from "../../../service/repos/ops/ops_tickets_repo";
import { getComplaints } from "../../../service/repos/support/complaint_repo";
import { GetWareHouseListApi } from "../../../service/repos/warehouse_repo";

import { getDayAgo, UTCToLocalDate } from "../../../utils/date_util";
import { debounce } from "../../../utils/debounce";
import {
  ACTION,
  MODULE,
  Ops_task_status,
  PriorityType,
  TaskType,
} from "../../../utils/enums";
import { isValidJson } from "../../../utils/formDataToJson";
import { urls } from "../../../utils/urls";
import LoadingWidget from "../../components/loading_spinner";
import AutoCompleteField from "../../new_components/common/autocomplete";
import DrawerModal, {
  DrawerModalLoaction,
} from "../../new_components/common/drawer_modal";
import ModalDialog from "../../new_components/common/modal";
import EmployeeSearchWidget from "../../new_components/emp_search_widget";
import OrderDropDown from "../orders/components/order_dropdown";
import CommentWidget from "./comment_widget";
import TaskAttachmentWidget from "./components/attachment_widget";
import InventoryDropDown from "./components/inventory_dropdown";
import LogsWidget from "./logs_widget";
import { getCityStateApi } from "../../../service/repos/address/address_repo";
import ComplaintDropDown from "../complaint/listing/components/complaint_dropdown";
import DeviceItemRow from "./components/device_item_row";
import {
  ModalType,
  ModalAlignment,
  useModal,
} from "../../../context_providers/modal/modal_context";
import SafetyChecklistView from "./components/safety_check_list_widget";
import AddressPickerWidget from "../../new_components/common/location/AddressPicker";

interface CreateOpsTicketPageProps {
  location: any;
}

const defaultValue: OpsTaskModel = {
  id: "",
  type: "",
  remarks: "",
  last_date: new Date(),
  date: new Date(),
  status: Ops_task_status.pending,
  emp_id: "",
  emp_name: "",
  warehouse_id: 0,
  warehouse_name: "",
  active: true,
  emp_id2: "",
  emp_name2: "",
  priority: PriorityType.low,
  device_items: [],
  verified: false,
  address: {
    line1: "",
    city: "",
    state: "",
    country: "",
    pincode: 0,
  },
};
const CreateOpsTicketPage: FunctionComponent<CreateOpsTicketPageProps> = (
  props
) => {
  const is_update_page = useRouteMatch(urls.operation_task + "/update/:id");
  const { id } = useParams<{ id: string }>();
  const { showToast } = useToast();
  const { user, isAuthorised } = useAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  // const [inventoryLoading, setInventoryLoading] = useState(false);
  const [complaintLoading, setComplaintLoading] = useState(false);
  const [showLogs, setShowLogs] = useState<boolean>(false);
  const [showComments, setShowComments] = useState<boolean>(false);
  const [showAttachment, setShowAttachment] = useState(false);
  const [edit, setEdit] = useState<boolean>(true);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [warehouses, setWareHouse] = useState<WarehouseModel[]>([]);

  const [data, setData] = useState<OpsTaskModel>({ ...defaultValue });
  const [comment, setComment] = useState("");
  const [addNewId, setAddNewId] = useState("");

  const { showModal, onClose } = useModal();

  const getWarehouse = async () => {
    setLoading(true);
    const res = await GetWareHouseListApi({ page: 0, count: 0, all: true });

    if (res.success) {
      setWareHouse(res.data.data);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };

  const onSubmit = async () => {
    if (
      !data.type ||
      !data.status ||
      !data.warehouse_id ||
      !data.order_id ||
      (data.type == TaskType.complaint && !data.complaint_id)
    ) {
      showToast({
        type: ToastType.warning,
        text: "Please fill the required * fields !",
      });
      return;
    }

    setLoading(true);
    const res = data.id
      ? await UpdateOpsTaskApi(data)
      : await CreateOpsTicketApi({ data, comment });
    if (res.success) {
      showToast({ type: ToastType.success, text: res.message });
      setEdit(false);
      // if (!data.id) {
      //   history.replace(urls.operation_task);
      //   return;
      // }
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  const getTask = async (id: string) => {
    setLoading(true);
    const res = await GetOpsTaskApi(id);

    if (res.success) {
      // if (
      //   !isAuthorised({ module: MODULE.ops_ticket, action: [ACTION.UPDATE] })
      // ) {
      //   setEdit(false);
      // }
      setEdit(false);
      setData(res.data);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      getTask(id);
    }
    getWarehouse();
  }, []);

  // const getCityState = async () => {
  //   setLoading(true);
  //   const res = await getCityStateApi(data.pincode!);

  //   if (res.success) {
  //     if (!res.data) {
  //       setLoading(false);

  //       showToast({ type: ToastType.error, text: "Invalid zip code !" });
  //       return;
  //     }
  //     const { city, state } = res.data;
  //     setData((c) => ({
  //       ...c,
  //       city,
  //       state,
  //     }));
  //   } else showToast({ type: ToastType.error, text: res.error ?? "" });
  //   setLoading(false);
  // };
  // const ago = data.date ? getDayAgo(new Date(data.date)) : 0;

  return (
    <div className="p-2 sm:p-5 text-sm sm:text-base">
      {showAttachment && (
        <ModalDialog
          show={showAttachment}
          title="Attachment"
          onClose={() => setShowAttachment(false)}
        >
          <TaskAttachmentWidget
            task_id={data?.id}
            attachments={data.attachments || []}
            onRefresh={function (): void {
              getTask(data.id);
            }}
          />
        </ModalDialog>
      )}
      {showComments && (
        <DrawerModal
          show={showComments}
          title={"Comments"}
          location={DrawerModalLoaction.right}
          onClose={() => {
            setShowComments(false);
          }}
        >
          <CommentWidget task_id={data.id} active={data.active} />
        </DrawerModal>
      )}
      {showLogs && (
        <DrawerModal
          show={showLogs}
          title={"Log history"}
          location={DrawerModalLoaction.right}
          onClose={() => {
            setShowLogs(false);
          }}
        >
          <LogsWidget task_id={data.id} />
        </DrawerModal>
      )}

      <h1 className="  text-xl font-bold border-l-4 border-myPrimaryColor pl-2 flex items-center">
        Operation Task {data.id ? ` # ${data.id}` : "Create"}
      </h1>
      {data.id && (
        <div className="flex my-2 items-end justify-between bg-white p-2 rounded sticky top-0 z-10">
          <button
            onClick={() => {
              history.replace(urls.operation_task);
            }}
            className="bg-blue-50 border border-blue-400 text-blue-500 rounded-3xl px-2 py-1 flex  items-center "
          >
            <Icon path={mdiChevronLeft} size={1}></Icon>{" "}
            <span className="text-base font-semibold">Back</span>
          </button>

          <div className="flex gap-1 text-sm ">
            <a
              target="_blank"
              href={`${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/new-ops/task/${data.id}/work-completion-report/download?auth=${user?.token}`}
              className="border rounded px-2 py-1 flex gap-1 items-center "
            >
              <Icon
                path={mdiFileDocument}
                size={0.8}
                className="text-green-500"
              />
              Work completion report
              {/* <Icon path={mdiChevronRight} size={0.8} /> */}
            </a>
            <a
              target="_blank"
              href={`${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/new-ops/task/${data.id}/boq-report/download?auth=${user?.token}`}
              className="border rounded px-2 py-1 flex gap-1 items-center "
            >
              <Icon
                path={mdiFileDocument}
                size={0.8}
                className="text-green-500"
              />
              BOQ report
              {/* <Icon path={mdiChevronRight} size={0.8} /> */}
            </a>
            <button
              className="border rounded px-2 py-1 flex gap-1 items-center "
              onClick={() => {
                showModal({
                  title: "Safety checklist",

                  type: ModalType.drawer,
                  alignment: ModalAlignment.right,
                  container(i) {
                    return (
                      <div className="p-2">
                        <SafetyChecklistView
                          editEnable={edit}
                          data={data.checklist}
                          onSubmit={function (checklist: {
                            section_a: number[];
                            section_b: number[];
                            section_c: number[];
                          }): void {
                            setData((o) => ({ ...o, checklist }));
                            // onClose(i);
                          }}
                        />
                      </div>
                    );
                  },
                });
              }}
            >
              <Icon
                path={mdiShieldCheckOutline}
                size={0.8}
                className="text-green-500"
              />
              Safety Checklist
              {/* <Icon path={mdiChevronRight} size={0.8} /> */}
            </button>

            <button
              onClick={() => setShowComments(true)}
              className="border rounded px-2 py-1"
            >
              Comment
            </button>
            <button
              onClick={() => setShowLogs(true)}
              className="border rounded px-2 py-1"
            >
              History
            </button>
            <button
              onClick={() => setShowAttachment(true)}
              className="border rounded px-2 py-1"
            >
              Attachment
            </button>
          </div>
        </div>
      )}
      {/* <div className="flex justify-between my-3 flex-wrap">
        <div className="">
          {!data.id ? (
            <div className="text-xl font-bold border-l-4 border-myPrimaryColor pl-2">
              Create Task
            </div>
          ) : (
            <>
              {ago === 0 ? (
                <div className="text-4xl">Today</div>
              ) : (
                <div className="flex items-end gap-2">
                  <div className="text-6xl">{ago}</div>
                  <div className="text-xl">Days Ago</div>
                </div>
              )}
            </>
          )}
        </div>

        <div className="flex flex-col gap-5 items-end flex-wrap justify-between sm:justify-start">
          <h1 className="text-lg font-semibold">
            {data.id && (
              <>
                {" "}
                <span className="text-base font-normal">
                  Task ID
                </span> &middot; {data.id}
              </>
            )}
          </h1>

          {data.id && (
            <div className="flex gap-1 items-center justify-center">
              <select
                className="border rounded   w-full   focus:outline-none cursor-pointer text-2xl p-1"
                name="active"
                id="active"
                disabled={!edit}
                value={data.active ? "true" : "false"}
                onChange={(e) => {
                  setData((o) => ({
                    ...o,
                    active: e.target.value === "true",
                  }));
                }}
              >
                <option value="" hidden className="text-sm">
                  Select..
                </option>
                <option value="true" className="text-sm">
                  Active
                </option>
                <option value="false" className="text-sm">
                  Closed
                </option>
              </select>
            </div>
          )}
        </div>
      </div> */}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 text-sm">
        <div className="p-7 h-full  border bg-white rounded-lg">
          <div className="grid grid-cols-1 md:grid-cols-2  gap-3    ">
            <div className="  ">
              <div className="flex  flex-col   ">
                <label htmlFor="warehouse">Office*</label>
                <select
                  disabled={!edit}
                  value={JSON.stringify({
                    warehouse_id: data.warehouse_id,
                    warehouse_name: data.warehouse_name,
                  })}
                  onChange={(e) => {
                    setData((p) => ({ ...p, ...JSON.parse(e.target.value) }));
                  }}
                  name="warehouser"
                  id="warehouse"
                  className="border rounded px-2 py-1"
                >
                  <option value="" hidden>
                    Select Office
                  </option>
                  {warehouses.map((warehouse) => (
                    <option
                      value={JSON.stringify({
                        warehouse_id: warehouse.id,
                        warehouse_name: warehouse.name,
                      })}
                    >
                      {warehouse.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className=" ">
              <div className="flex flex-col">
                <label htmlFor="type">Task Type*</label>
                <select
                  disabled={!edit}
                  value={data.type}
                  onChange={(e) => {
                    setData((p) => ({ ...p, type: e.target.value }));
                  }}
                  className="border rounded px-2 p-1 focus:outline-none w-full"
                  name="type"
                  id="type"
                >
                  <option value="" hidden>
                    Select ....
                  </option>
                  {Object.values(TaskType).map((type, i) => (
                    <option key={i} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="">
              {" "}
              <div className="flex      flex-col">
                <label htmlFor="device">Responsible Person</label>
                {!loading && (
                  <EmployeeSearchWidget
                    disabled={!edit}
                    withBtn={false}
                    emp={{ uid: data.emp_id, name: data.emp_name }}
                    onSelect={(emp) => {
                      setData({
                        ...data,
                        emp_id: emp.uid!,
                        emp_name: (emp.first_name + " " + emp.last_name).trim(),
                      });
                    }}
                  />
                )}
              </div>
            </div>
            {/* <div className="">
              {" "}
              <div className="flex      flex-col">
                <label htmlFor="device">Responsible person 2</label>
                {!loading && (
                  <EmployeeSearchWidget
                    withBtn={false}
                    disabled={!edit}
                    emp={{ uid: data.emp_id2, name: data.emp_name2 }}
                    onSelect={(emp) => {
                      setData({
                        ...data,
                        emp_id2: emp.uid!,
                        emp_name2: (
                          emp.first_name +
                          " " +
                          emp.last_name
                        ).trim(),
                      });
                    }}
                  />
                )}
              </div>{" "}
            </div> */}
            <div className="flex flex-col    justify-between   w-full">
              <label htmlFor="priority" className="  ">
                Priority
              </label>
              <select
                disabled={!edit}
                required
                value={data.priority}
                onChange={(e) => {
                  setData((c) => ({
                    ...c,
                    priority: e.target.value,
                  }));
                }}
                name="priority"
                id="priority"
                className={` px-2 py-1 w-full  rounded  border focus:outline-none  ${
                  data.priority === PriorityType.low
                    ? "text-yellow-500 border-yellow-200 bg-yellow-50"
                    : data.priority === PriorityType.medium
                    ? "text-blue-500 border-blue-200 bg-blue-50"
                    : "text-red-500 border-red-200 bg-red-50"
                } `}
              >
                <option value="" disabled hidden>
                  Select
                </option>
                {Object.values(PriorityType).map((d) => (
                  <option value={d} className="bg-white">
                    {d}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex   flex-col">
              <label htmlFor="device">Status*</label>
              <select
                disabled={!edit}
                value={data.status}
                onChange={(e) => {
                  setData((p) => ({ ...p, status: e.target.value }));
                }}
                className={`border rounded px-2 p-1 focus:outline-none w-full ${
                  data.status == Ops_task_status.pending
                    ? "text-yellow-500 border-yellow-200 bg-yellow-50"
                    : data.status == Ops_task_status.under_progress
                    ? "text-blue-500 border-blue-200 bg-blue-50"
                    : data.status == Ops_task_status.completed
                    ? "text-green-500 border-green-200 bg-green-50"
                    : "text-gray-500 border-gray-200 bg-gray-50"
                }`}
                name="status"
                id="status"
              >
                <option value="" hidden>
                  Select ....
                </option>
                {Object.values(Ops_task_status).map((type, i) => (
                  <option key={i} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>
            {/* <div className="flex     flex-col">
              <label htmlFor="date">Visibility</label>
              <select
                className={`border rounded px-2 p-1 focus:outline-none w-full ${
                  data.active
                    ? "text-red-500 border-red-200 bg-red-50"
                    : "text-green-500 border-green-200 bg-green-50"
                }`}
                name="active"
                id="active"
                disabled={!edit}
                value={data.active ? "true" : "false"}
                onChange={(e) => {
                  setData((o) => ({
                    ...o,
                    active: e.target.value === "true",
                  }));
                }}
              >
                <option value="" hidden className="text-sm">
                  Select..
                </option>
                <option value="true" className="text-sm">
                  Active
                </option>
                <option value="false" className="text-sm">
                  Closed
                </option>
              </select>
            </div>{" "} */}
            <div className="flex     flex-col">
              <label htmlFor="date">Due Date</label>
              <input
                disabled={!edit}
                value={
                  data.last_date
                    ? moment(data.last_date).format("YYYY-MM-DD")
                    : ""
                }
                onChange={(e) => {
                  setData((p) => ({
                    ...p,
                    last_date: UTCToLocalDate(e.target.value),
                  }));
                }}
                // min={new Date().toISOString().split("T")[0]}
                id="date"
                type="date"
                name="order_id"
                className="border rounded px-2 p-1 focus:outline-none w-full"
              />
            </div>
            <div className="flex     flex-col">
              <label htmlFor="start_date">Start Date</label>
              <input
                disabled={!edit}
                value={
                  data.start_date
                    ? moment(data.start_date).format("YYYY-MM-DD")
                    : ""
                }
                onChange={(e) => {
                  setData((p) => ({
                    ...p,
                    start_date: UTCToLocalDate(e.target.value),
                  }));
                }}
                // min={new Date().toISOString().split("T")[0]}
                id="start_date"
                type="date"
                name="start_date"
                className="border rounded px-2 p-1 focus:outline-none w-full"
              />
            </div>
            <div className="flex     flex-col">
              <label htmlFor="end_date">End Date</label>
              <input
                disabled={!edit}
                value={
                  data.end_date
                    ? moment(data.end_date).format("YYYY-MM-DD")
                    : ""
                }
                onChange={(e) => {
                  setData((p) => ({
                    ...p,
                    end_date: UTCToLocalDate(e.target.value),
                  }));
                }}
                // min={new Date().toISOString().split("T")[0]}
                id="end_date"
                type="date"
                name="end_date"
                className="border rounded px-2 p-1 focus:outline-none w-full"
              />
            </div>

            <div className="flex gap-1 flex-col items-start   col-span-2">
              <label htmlFor="note">Note :</label>
              <textarea
                disabled={!edit}
                value={data.description}
                onChange={(e) => {
                  setData((p) => ({ ...p, description: e.target.value }));
                }}
                cols={35}
                rows={5}
                name="note"
                placeholder="Eg : Instructions"
                className="border rounded px-2 p-1 focus:outline-none w-full "
              />
            </div>
          </div>
        </div>
        <div className="p-7 h-full  border bg-white rounded-lg">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3   items-start justify-start">
            <div className="">
              <p>Order # *</p>
              <OrderDropDown
                disabled={!edit}
                withBtn={false}
                order_id={data.order_id}
                onSelect={(v) => {
                  setData({
                    ...data,
                    order_id: v.order_id,
                    cust_name: `${v.customer.firstName} ${
                      v.customer.lastName ?? ""
                    }`.trim(),
                    cust_mobile: v.customer.mobile,
                    cust_alt_mobile: v.customer.alt_mobile,
                    address: v.customer.address,
                    // cust_address: `${v.customer.address.line1} ${
                    //   v.customer.address.line2 ?? ""
                    // } ${v.customer.address.city} ${v.customer.address.state} ${
                    //   v.customer.address.country
                    // } ${v.customer.address.pincode}`,
                  });
                }}
              />
            </div>

            <div className="flex gap-1 flex-col items-start flex-wrap">
              <label htmlFor="device">
                Complaint # {data.type == TaskType.complaint && "*"}{" "}
              </label>
              <ComplaintDropDown
                disabled={!edit}
                placeholder="Complaint id"
                id={data.complaint_id}
                onSelect={(v) => {
                  setData({
                    ...data,
                    complaint_id: v.id,
                  });
                }}
              />
            </div>

            <div className="flex   flex-col">
              <label htmlFor="customer_name">Client Name</label>
              <input
                disabled={!edit}
                value={data.cust_name}
                onChange={(e) => {
                  setData((p) => ({ ...p, cust_name: e.target.value }));
                }}
                type="text"
                name="customer_name"
                className="border rounded px-2 p-1 focus:outline-none "
              />
            </div>
            <div className="flex   flex-col">
              <label htmlFor="customer_email">Client Email</label>
              <input
                disabled={!edit}
                value={data.cust_email}
                onChange={(e) => {
                  setData((p) => ({ ...p, cust_email: e.target.value }));
                }}
                type="email"
                name="customer_email"
                className="border rounded px-2 p-1 focus:outline-none "
              />
            </div>

            <div className=" ">
              <div className="flex   flex-col">
                <label htmlFor="device">Contact No.</label>
                <input
                  disabled={!edit}
                  value={data.cust_mobile}
                  onChange={(e) => {
                    setData((p) => ({ ...p, cust_mobile: e.target.value }));
                  }}
                  type="text"
                  name="customer_mobile"
                  className="border rounded px-2 p-1 focus:outline-none"
                />
              </div>
            </div>

            <div className="flex    flex-col">
              <label htmlFor="alt_mob">Alternative No.</label>
              <input
                disabled={!edit}
                value={data.cust_alt_mobile}
                onChange={(e) => {
                  setData((p) => ({ ...p, cust_alt_mobile: e.target.value }));
                }}
                type="text"
                id="alt_mob"
                className="border rounded px-2 p-1 focus:outline-none "
              />
            </div>
            <div className="flex    flex-col col-span-2">
              <label htmlFor="address-picker">Address</label>
              <div
                onClick={() => {
                  if (!edit) return;
                  showModal({
                    type: ModalType.drawer,
                    title: "Address Picker",
                    alignment: ModalAlignment.right,
                    container(id) {
                      return (
                        <div className="  bg-gray-100 p-1 h-full w-full">
                          <AddressPickerWidget
                            data={data.address}
                            location_required={true}
                            onSubmit={function (_address: AddressModel): void {
                              setData((o) => ({ ...o, address: _address }));
                              onClose(id);
                            }}
                            onCancel={function (): void {
                              onClose(id);
                            }}
                          />
                        </div>
                      );
                    },
                  });
                }}
                className={`border rounded px-2 p-1 focus:outline-none h-32 ${
                  !data.address.full_address && "text-gray-400"
                }  ${!edit ? "bg-gray-100" : "cursor-text"}`}
              >
                {data.address.full_address ?? "Full address"}
              </div>
            </div>

            {/* <div className="col-span-2 ">
            <div className="flex flex-col     ">
              <label htmlFor="device">Address</label>
              <textarea
                disabled={!edit}
                value={data.line1}
                onChange={(e) => {
                  setData((p) => ({ ...p, line1: e.target.value }));
                }}
                cols={35}
                rows={4}
                placeholder="Line 1"
                name="customer_address"
                className="border rounded px-2 p-1 focus:outline-none "
              />
            </div>
          </div>
          <div className="flex flex-col">
            <label htmlFor="city" className="mr-2 text-sm">
              City/District
            </label>
            <input
              disabled
              value={data.city}
              onClick={(e) => {
                showToast({
                  type: ToastType.error,
                  text: "Please enter 6 digit postal code",
                });
              }}
              onChange={(e) => {
                setData((c) => ({
                  ...c,
                  city: e.target.value,
                }));
              }}
              className="border px-2 py-1 rounded"
              type="text"
              name="city"
              id="city"
              placeholder="City"
              required
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="state" className="mr-2 text-sm">
              States
            </label>
            <input
              disabled
              value={data.state}
              onChange={(e) => {
                setData((c) => ({
                  ...c,
                  state: e.target.value,
                }));
              }}
              onClick={(e) => {
                showToast({
                  type: ToastType.error,
                  text: "Please enter 6 digit postal code",
                });
              }}
              className="border px-2 py-1 rounded"
              type="text"
              name="state"
              id="state"
              placeholder="State"
              required
            />
          </div>
          <div className="">
            <div className="flex items-end w-full gap-1">
              <div className="flex flex-col w-full">
                <label htmlFor="pincode" className="mr-2 text-sm">
                  Postal code
                </label>
                <input
                  disabled={!edit}
                  type="number"
                  name="pincode"
                  placeholder="Postal code"
                  pattern="[0-9]{6}"
                  title="Please enter a 6 digit pincode"
                  value={data.pincode}
                  onChange={(e) => {
                    const t = parseInt(e.target.value);
                    if (e.target.value.length <= 6)
                      setData((o) => ({ ...o!, pincode: t }));

                    // setComplaint((c) => ({
                    //   ...c,
                    //   pincode: parseInt(e.target.value),
                    // }));
                  }}
                  className="border px-2 py-1 rounded"
                  id="pincode"
                  required
                />
              </div>

              <button
                disabled={!edit}
                className="border rounded-full p-1"
                onClick={() => {
                  if (data.pincode?.toString().length === 6) {
                    getCityState();
                  } else {
                    showToast({
                      type: ToastType.error,
                      text: "Please enter 6 digit postal code",
                    });
                  }
                }}
              >
                <Icon path={mdiMagnify} size={0.8} />
              </button>
            </div>
            <p className="text-xs text-gray-400">
              Please enter pincode to get city/district and state.
            </p>
          </div> */}
          </div>
        </div>
      </div>

      <div className="my-5 ">
        <h3 className="text-sm font-bold">Devices</h3>
        <div
          className="bg-white rounded flex flex-col gap-1 border   "
          // style={{ height: "75vh" }}
        >
          <div className="grid grid-cols-9 gap-1 p-1 bg-myPrimaryColor text-white text-sm font-semibold rounded-t  uppercase ">
            <div className="text-center">Device ID</div>
            <div className="text-center col-span-2">Product name</div>
            <div className="text-center">Issue Type</div>
            <div className="text-center">Issue sub Type</div>
            <div className="text-center">Test Txn Id</div>
            <div className="text-center">Status</div>
            <div className="text-center">Resolution</div>
            {/* <div className="text-center col-span-2">Remark</div> */}
            <div className="text-center  "> Actions</div>
          </div>
          <div className="flex flex-col gap-1 px-1  text-sm overflow-auto h-72">
            {(data.device_items ?? []).map((item, i) => {
              return (
                <DeviceItemRow
                  EditEnable={edit}
                  disabled={!edit || false}
                  key={item.id}
                  data={item}
                  onDelete={(d) => {
                    setData((o) => ({
                      ...o,
                      device_items: [...(o.device_items || [])].filter(
                        (i) => i.id !== d.id
                      ),
                    }));
                  }}
                  onSubmit={function (row_item: OpsTaskDeviceItem): void {
                    setData((o) => ({
                      ...o,
                      device_items: [
                        ...(o.device_items || []).map((it) => {
                          if (it.id === row_item.id) return row_item;
                          return it;
                        }),
                      ],
                    }));
                  }}
                  edit={false}
                />
              );
            })}
            {edit && (
              <DeviceItemRow
                EditEnable={edit}
                key={addNewId}
                onSubmit={function (data: OpsTaskDeviceItem): void {
                  data.id = (Math.random() * 1000).toString();

                  setData((o) => ({
                    ...o,
                    device_items: [...(o.device_items || []), data],
                  }));
                  setAddNewId((Math.random() * 1000).toString());
                }}
                edit={true}
                disabled={false}
              />
            )}
          </div>
        </div>
      </div>
      {data.device_ids ? (
        <>
          <div className="flex     flex-col">
            <label htmlFor="date">Old version selected Device</label>
            <div className="flex flex-wrap gap-2 col-span-2 text-xs  ">
              {typeof data.device_ids == "object"
                ? data.device_ids.map((d, i) => (
                    <div className="border rounded-3xl p-1 flex gap-1" key={i}>
                      <p>
                        {d.device_id}{" "}
                        <span style={{ fontSize: 8 }}>({d.name})</span>
                      </p>
                    </div>
                  ))
                : JSON.stringify(data.device_ids)}
            </div>
            <p className="text-lg animate-pulse">
              Please reselect the same device and update in the above device
              section
            </p>
          </div>
          <div className="h-20"></div>
        </>
      ) : (
        <></>
      )}

      <div className="sticky bottom-0 right-0 left-0 flex justify-end gap-5  bg-white rounded-lg  ">
        <div className="grid grid-cols-2 gap-5 p-2 w-96">
          {edit ? (
            <>
              <button
                onClick={() => {
                  if (data.id) {
                    setEdit(false);
                  } else {
                    history.replace(urls.operation_task);
                  }
                }}
                className="border rounded-lg px-3 py-2 flex items-center justify-center gap-1 bg-red-50 text-red-500 border-red-300"
              >
                <Icon path={mdiClose} size={0.8} className=" " />{" "}
                <p className="text-center"> Cancel</p>
              </button>
              <button
                onClick={onSubmit}
                className="border rounded-lg px-3 py-2 flex items-center justify-center gap-1 bg-green-50 text-green-500 border-green-300"
              >
                <Icon path={mdiCloudUpload} size={0.8} className=" " /> Submit
              </button>
            </>
          ) : (
            isAuthorised({
              module: MODULE.ops_ticket,
              action: [ACTION.UPDATE],
            }) && (
              <>
                <div className=""></div>
                <button
                  onClick={() => setEdit(true)}
                  className="border rounded-lg px-3 py-2 flex items-center justify-center gap-1 bg-green-50 text-green-500 border-green-300"
                >
                  <Icon path={mdiPencil} size={0.8} className=" " /> Edit
                </button>
              </>
            )
          )}
        </div>
      </div>
      <LoadingWidget loading={loading} />
    </div>
  );
};

export default CreateOpsTicketPage;
