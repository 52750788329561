import {
  fetchDelete,
  fetchGet,
  fetchPost,
  fetchPut,
  toQueryString,
} from "../../api_client";
import { OpsTaskModel } from "../../models/ops/ops_ticket";
export interface TaskFilterModel {
  page: number;
  count: number;
  all?: boolean;
  fromDate?: Date | null;
  toDate?: Date | null;
  status?: string[];
  active?: boolean;
  type?: string[];
  search?: string;
  emp_id?: string;
  emp_name?: string;
}
export const CreateOpsTicketApi = async (data: {
  data: OpsTaskModel;
  comment?: string;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/new-ops/task`;
    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const UpdateOpsTaskStatusApi = async (data: {
  id: string;
  status: string;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/new-ops/task`;
    const res = await fetchPut(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const UpdateOpsTaskApi = async (data: OpsTaskModel) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/new-ops/task`;
    const res = await fetchPut(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const GetOpsTicketsApi = async (data: TaskFilterModel) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/new-ops/task/get-tasks`;
    const res = await fetchPost(url, data);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GetOpsTicketsByOrderIdApi = async (order_id: string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/new-ops/task/get-tasks-of_order/${order_id}`;
    const res = await fetchGet(url);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const GetMyOpsTicketsApi = async (data: {
  page: number;
  count: number;
  all?: boolean;
  fromDate?: string;
  toDate?: string;
  status?: string[];
  active: boolean;
  search?: string;
}) => {
  try {
    const url = `${
      process.env.REACT_APP_BACKEND_BASE_URL
    }/api/v3/new-ops/task/my-tasks${toQueryString({
      filter: JSON.stringify(data),
    })}`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GetOpsTaskApi = async (id: string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/new-ops/task/${id}`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GetOpsTaskAttachmentsApi = async (id: string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/new-ops/task/${id}/attachments`;
    const res = await fetchGet(url);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const DeleteOpsTaskAttachmentsApi = async ({
  id,
  task_id,
}: {
  id: string;
  task_id: string;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/new-ops/task/${task_id}/attachments/${id}`;
    const res = await fetchDelete(url);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
