import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import Icon from "@mdi/react";
import { debounce } from "lodash";
import {
  mdiArrowBottomLeft,
  mdiArrowTopRight,
  mdiClose,
  mdiPlus,
  mdiRefresh,
} from "@mdi/js";
import moment from "moment";
import TableView from "./stock_transfer_journal_create/components/table_comp";
import {
  fetchDelete,
  fetchGet,
  fetchPost,
  toQueryString,
} from "../../../../service/api_client";
import {
  useToast,
  ToastType,
} from "../../../../context_providers/toast/toast_ctx";
import { FieldType } from "../../../../procurement/components/create_view/form_field";
import LoadingWidget from "../../../../context_providers/modal/loader";
import SearchFieldWidget from "../../../../ui/new_components/search_field";
import { UTCToLocalDate } from "../../../../utils/date_util";
import { urls } from "../../../../utils/urls";
import {
  StockJournalModel,
  voucherStatus,
} from "../../models/inventory_voucher";
import ModalDialog from "../../../../ui/new_components/common/modal";
import FileDropZone from "../../../../ui/new_components/common/file_drop_zone";
import { parse } from "papaparse";
import DrawerModal, {
  DrawerModalLoaction,
} from "../../../../ui/new_components/common/drawer_modal";
import ActionHistory from "./stock_transfer_journal_create/components/transfer_logs";
import ExportTransferJournal from "./stock_transfer_journal_create/components/export_widget";

interface StockTransferJournalListScreenProps {
  records: StockJournalModel[];
  onClick: (id: string) => void;
  sort_by: string;
  sort_order: number;
  pages: number;
  options: []; // Replace with actual type
  handleSort: (data: { sort: string; sort_order: number }) => void;
  handlePageChange: (page: number) => void;
  // handleStatusFilterChange: (selectedOptions: string[]) => void;
}

interface StockListFilter {
  voucherStatus?: string;
  voucher_id?: string[];
  status?: string[];
  search?: string;
  page: number;
  count: number;
  all?: any;
  warehouse_id?: string[];
  fromDate?: Date;
  toDate?: Date;
}

const defaultFilter = (): StockListFilter => {
  const now = new Date();
  const start_date = new Date(new Date(now).setDate(now.getDate() - 30));
  const end_date = now;
  return {
    page: 1,
    count: 100,
    fromDate: start_date,
    toDate: end_date,
  };
};

const StockTransferJournalListScreen: FunctionComponent<
  StockTransferJournalListScreenProps
> = ({
  records,
  onClick,
  sort_by,
  sort_order,
  pages,
  handleSort,
  handlePageChange,
}) => {
  const navigation = useHistory();
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/journal/source_transfer`;
  const { showToast } = useToast();
  const [page, setPage] = useState(1);
  const [count] = useState(50);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<StockJournalModel[]>([]);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [filter, setFilter] = useState<StockListFilter>(defaultFilter());
  const [showImport, setShowImport] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [ShowHistory, setShowHistory] = useState<any>();

  useEffect(() => {
    const now = new Date();
    let from = new Date(new Date(now).setHours(0, 0, 0, 0));
    from = new Date(from.setDate(1));
    let to = new Date(new Date(now).setHours(23, 59, 59, 999));
    to = new Date(to.setMonth(now.getMonth() + 1));
    to = new Date(to.setDate(0));
    setFromDate(from);
    setToDate(to);
    fetchData({ ...filter });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Format ISO date to YYYY-MM-DD
  const formatISODate = (isoDateString: any): any => {
    const dateObject = new Date(isoDateString);
    return dateObject.toISOString().slice(0, 10);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandler = useCallback(
    debounce((prop: StockListFilter) => {
      if (prop) fetchData(prop);
      else setData([]);
    }, 300),
    []
  );
  useEffect(() => {
    debouncedHandler(filter);
  }, [filter, debouncedHandler]);

  const fetchData = async (prop: StockListFilter) => {
    setLoading(true);
    try {
      const res = await fetchGet(url + toQueryString(prop));
      if (res.success) {
        setData(res.data.data);
        if (res.data.metadata) {
          setTotal(res.data.metadata.total);
        }
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
    } finally {
      setLoading(false);
    }
  };

  // const onSubmit = async (data: StockJournalModel[]) => {
  //   setLoading(true);

  //   try {
  //     const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/journal/source_transfer`;
  //     const res = await fetchPost(url, data);

  //     return res;
  //   } catch (error: any) {
  //     return { success: false, error: error.message };
  //   }
  // };

  const onSubmit_Bulk = async (data: StockJournalModel[]) => {
    setLoading(true);

    try {
      const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/journal/source_transfer/bulk_upload`;
      const res = await fetchPost(url, data);

      return res;
    } catch (error: any) {
      return { success: false, error: error.message };
    }
  };

  const onSelect = (d: StockJournalModel) => {
    // if (d.status !== "Returned") {
    //   navigation.push(urls.stock_transfer_voucher + "/update/" + d.id);
    // } else {
    //   showToast({
    //     type: ToastType.error,
    //     text: "Return Voucher can't be editable.",
    //   });
    // }
    navigation.push(urls.stock_transfer_voucher + "/update/" + d.id);
  };

  const onDelete = async (d: StockJournalModel) => {
    const promptText = window.prompt("Type 'delete' to confirm.");
    if (!promptText || promptText.trim() !== "delete") {
      return;
    }
    setLoading(true);
    const res = await fetchDelete(url + "/" + d.id);
    if (res.success) {
      await fetchData({ ...filter });
      showToast({ type: ToastType.success, text: res.message });
    } else {
      showToast({ type: ToastType.error, text: res.error });
    }
    setLoading(false);
  };
  return (
    <>
      {/* {console.log("id of order", ShowHistory)} */}
      {ShowHistory && (
        <DrawerModal
          location={DrawerModalLoaction.right}
          show={ShowHistory !== undefined}
          onClose={() => setShowHistory(undefined)}
          title="History"
        >
          {/* {console.log("id of order", ShowHistory)} */}
          <ActionHistory
            voucher_id={ShowHistory}
            // show={ShowHistory !== undefined}
            // onClose={(v) => setShowHistory(undefined)}
          />
        </DrawerModal>
      )}
      {showExport && (
        <ModalDialog
          show={showExport}
          title="Export"
          onClose={() => setShowExport(false)}
        >
          <ExportTransferJournal filter={filter} />
        </ModalDialog>
      )}
      {/* {console.log(showImport)} */}
      {showImport && (
        <ModalDialog
          show={showImport}
          onClose={() => setShowImport(false)}
          title="Import Inventory"
        >
          <div className="text-xs w-96">
            <h2 className="font-semibold">Please note :</h2>
            <p>sheet should have header as</p>
            <p className="font-semibold text-purple-500">
              device_id,product_id,product_name,warehouse_id,mobile_number,
              module_imei,sim_imsi,plan_name,sim_no,sim_status,
              status,responsible_email
            </p>
            <p>In the first row of csv.</p>
          </div>
          <div className="h-96 w-96 mt-5">
            <FileDropZone
              accept=".csv"
              onDrop={(files) => {
                parse<StockJournalModel>(files[0], {
                  transformHeader(header, index) {
                    return header.trim();
                  },
                  header: true,
                  skipEmptyLines: true,
                  complete: (data) => {
                    const res = data.data;
                    // console.log({ res });

                    if (res.length === 0) {
                      showToast({
                        type: ToastType.warning,
                        text: "No record found !",
                      });
                      return;
                    }
                    const keys = Object.keys(res[0]);
                    if (
                      keys.indexOf("device_id") === -1 ||
                      keys.indexOf("product_id") === -1 ||
                      keys.indexOf("warehouse_id") === -1
                    ) {
                      showToast({
                        type: ToastType.warning,
                        text: "Required fields are [device_id,product_id,product_name,warehouse_id]",
                      });
                      return;
                    }
                    const filteredData: StockJournalModel[] = [];
                    for (let index = 0; index < res.length; index++) {
                      const {
                        receiver,
                        delivery_challan_number,
                        user,
                        id,
                        source,
                      } = res[index];
                      if (
                        !id &&
                        !source &&
                        !user &&
                        !delivery_challan_number &&
                        !receiver
                      )
                        continue;
                      if (
                        !id &&
                        !source &&
                        !user &&
                        !delivery_challan_number &&
                        !receiver
                      ) {
                        console.log({
                          id,
                          source,
                          user,
                          delivery_challan_number,
                          receiver,
                        });

                        showToast({
                          type: ToastType.warning,
                          text:
                            `Either Device id, product_id, warehouse_id, responsible_email missing at row ` +
                            (index + 2),
                        });
                        return;
                      }

                      filteredData.push({
                        id,
                        voucher_type: "",
                        voucher_no: "",
                        source_taxes: [],
                        source_tax_ids: [],
                        source_tax: 0,
                        destination_taxes: [],
                        destination_tax_ids: [],
                        destination_tax: 0,
                        source_sub_total: 0,
                        destination_sub_total: 0,
                        source_total: 0,
                        destination_total: 0,
                        delivery_challan_number: "",
                        status: "",
                      });
                    }

                    // Bulk upload
                    setShowImport(false);
                    onSubmit_Bulk(filteredData);
                  },
                });
              }}
            />
          </div>
        </ModalDialog>
      )}
      <div
        id="page_container"
        className="flex flex-col justify-between h-full p-1"
      >
        <section
          id="top_section"
          className="flex justify-between items-end my-1 "
        >
          <h1 className="font-bold text-2xl text-gray-500 flex items-center gap-3">
            Stock transfer journal
            <button
              title="Refersh"
              onClick={() => debouncedHandler({ fromDate, toDate, ...filter })}
            >
              <Icon path={mdiRefresh} className="h-6 w-6"></Icon>
            </button>
          </h1>
          <div className="flex justify-end   text-sm items-center gap-1 ">
            <SearchFieldWidget
              defaulValue={filter.search}
              onClear={() => {
                setFilter((o) => ({ ...o, search: "", page: 1 }));
              }}
              onSubmit={(val) => {
                setFilter((o) => ({
                  ...o,
                  search: val,
                  page: 1,
                }));
              }}
            />

            <div
              className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
              onClick={() =>
                navigation.push(urls.stock_transfer_voucher + "/create")
              }
            >
              <Icon path={mdiPlus} className="h-4 w-4"></Icon>Add
            </div>
            <button
              onClick={() => setShowImport(true)}
              className="rounded-lg border px-2 py-1 hover:bg-gray-800 hover:text-white flex gap-2 items-center "
            >
              <Icon path={mdiArrowBottomLeft} size="15"></Icon>
              Import
            </button>
            <button
              onClick={() => setShowExport(true)}
              className="rounded-lg border px-2 py-1 hover:bg-gray-800 hover:text-white flex gap-2 items-center"
            >
              <Icon path={mdiArrowTopRight} size="15"></Icon> Export
            </button>
          </div>
        </section>{" "}
        <div className="text-sm flex items-center gap-2 m-3">
          <div className="flex items-center  gap-1">
            <label htmlFor="fromDate" className="">
              From
            </label>
            <input
              type="date"
              id="fromDate"
              className="p-1 rounded focus:outline-none"
              value={
                filter.fromDate
                  ? moment(filter.fromDate).format("YYYY-MM-DD")
                  : ""
              }
              onChange={(e) => {
                const selectedDate = UTCToLocalDate(e.target.value);
                setFilter((prevFilter) => ({
                  ...prevFilter,
                  fromDate: selectedDate,
                }));
              }}
            />
          </div>
          <div className="flex items-center  gap-1">
            <label htmlFor="toDate" className="">
              To
            </label>
            <input
              type="date"
              id="toDate"
              className="p-1 rounded focus:outline-none "
              value={
                filter.toDate ? moment(filter.toDate).format("YYYY-MM-DD") : ""
              }
              onChange={(e) => {
                const selectedDate = UTCToLocalDate(e.target.value);
                setFilter((prevFilter) => ({
                  ...prevFilter,
                  toDate: selectedDate,
                }));
              }}
            />
          </div>
          {(filter.fromDate || filter.toDate) && (
            <button
              title="Clear dates"
              onClick={() =>
                setFilter((prevFilter) => ({
                  ...prevFilter,
                  fromDate: undefined,
                  toDate: undefined,
                }))
              }
              className="flex gap-1 items-center cursor-pointer rounded p-1 border r hover:scale-105 duration-200     transform"
            >
              <Icon path={mdiClose} size={0.7}></Icon>
            </button>
          )}
          <div className="flex items-center  gap-1">
            <label htmlFor="app_status" className="">
              Voucher status
            </label>
            <select
              className="p-1 rounded focus:outline-none"
              name=""
              id="voucher_status"
              value={filter.voucherStatus}
              onChange={(e) => {
                setFilter((o) => ({
                  ...o,
                  voucherStatus: e.target.value,
                }));
              }}
            >
              <option value="" hidden>
                select
              </option>
              {Object.values(voucherStatus).map((f) => (
                <option key={f} value={f}>
                  {f}
                </option>
              ))}
            </select>
            {filter.voucherStatus && (
              <button
                title="clear voucher status"
                onClick={() =>
                  setFilter((o) => ({
                    ...o,
                    voucherStatus: "",
                  }))
                }
                className="flex gap-1 items-center cursor-pointer rounded p-1 border  r hover:scale-105 duration-200     transform"
              >
                <Icon path={mdiClose} size={0.7}></Icon>
              </button>
            )}
          </div>
        </div>
        <TableView
          headers={[
            {
              key: "date",
              title: "Date",
              type: FieldType.date,
              onClick: (d) => {},
            },
            {
              key: "track",
              title: "Tracking Ref",
              type: FieldType.long_string,
            },
            {
              colspan: 4,
              key: "party",
              title: "Items",
              type: FieldType.long_string,
            },
            {
              key: "warehouse",
              title: "Warehouse",
              type: FieldType.string,
            },
            {
              key: "projectFrom",
              title: "Project From",
              type: FieldType.string,
            },
            {
              key: "projectTo",
              title: "Project To",
              type: FieldType.string,
            },
            {
              key: "inward",
              title: "Inward value",
              type: FieldType.string,
            },
            {
              key: "outward",
              title: "Outward value",
              type: FieldType.string,
            },
            {
              key: "sender",
              title: "Sender",
              type: FieldType.string,
            },
            {
              key: "receiver",
              title: "Receiver",
              type: FieldType.string,
            },
            {
              key: "voucherStatus",
              title: "Status",
              type: FieldType.string,
              // icon: mdiFilterMenu,
              // options: options, // Ensure options is correctly passed here
              // onChange: handleStatusFilterChange,
            },
          ]}
          rows={data.map((data, i) => ({
            data: data,
            values: [
              formatISODate(data.date),
              data.id,
              data.source?.[0]?.item?.name,
              data.receiver?.name,
              data.source?.[0]?.projectFrom,
              data.destination?.[0]?.projectTo?.name,
              // data.voucher_type,
              data.destination_total,
              data.source_total,
              `${data.user?.first_name ?? ""} ${
                data.user?.last_name ?? ""
              }`.trim(),
              data.receiver?.spoc?.contact_name,
              data.status,
            ],
            disabled: data.status === "Returned",
          }))}
          page={page}
          total={total}
          count={count}
          itemCount={data.length}
          onPageChange={setPage}
          onClick={onSelect}
          onShowHistory={(data) => {
            setShowHistory(data);
            // console.log(data);
          }}
          // onDelete={onDelete}
        />
      </div>

      {loading && <LoadingWidget />}
    </>
  );
};

export default StockTransferJournalListScreen;
