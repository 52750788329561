import { RouteParam } from ".";
import KazamAcademy from "../ui/pages/kazam_academy";
import Cms from "../ui/pages/kazam_academy/videos/pages/cms";
import Sales from "../ui/pages/kazam_academy/videos/pages/sales";

import ProtocolCategory from "../ui/pages/kazam_academy/protocol-category";
import Complaint from "../ui/pages/kazam_academy/protocols/pages/complaint";
import ComplaintSegment from "../ui/pages/kazam_academy/protocols/pages/complaint_segment";
import HubCreation from "../ui/pages/kazam_academy/protocols/pages/hub_creation";
import InsideSales from "../ui/pages/kazam_academy/protocols/pages/inside_sales";
import Installation from "../ui/pages/kazam_academy/protocols/pages/installation";
import Saas from "../ui/pages/kazam_academy/protocols/pages/saas";
import SalesTraining from "../ui/pages/kazam_academy/protocols/pages/sales_training";
import SavedVideos from "../ui/pages/kazam_academy/saved_videos";
import UploadAcademyVideo from "../ui/pages/kazam_academy/upload-video";
import VideosCategory from "../ui/pages/kazam_academy/videos-category";
import EvNews from "../ui/pages/kazam_academy/videos/pages/ev_news";
import EvReviews from "../ui/pages/kazam_academy/videos/pages/ev_reviews";
import General from "../ui/pages/kazam_academy/videos/pages/general";
import Life from "../ui/pages/kazam_academy/videos/pages/life";
import StartupGyaan from "../ui/pages/kazam_academy/videos/pages/startup_gyaan";
import Success from "../ui/pages/kazam_academy/videos/pages/success";
import Tally from "../ui/pages/kazam_academy/videos/pages/tally";
import Updates from "../ui/pages/kazam_academy/videos/pages/updates";
import WatchedVideos from "../ui/pages/kazam_academy/watched_videos";

import { ACTION, MODULE } from "../utils/enums";
import { urls } from "../utils/urls";
import KazamWarranty from "../ui/pages/kazam_academy/protocols/pages/kazam_warranty";
import HrPolicies from "../ui/pages/kazam_academy/videos/pages/hr_policies";

const academy_routes: RouteParam[] = [
  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.kazam_academy,
    component: KazamAcademy,
    main_module: MODULE.academy,
    module: MODULE.academy,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.videos_category,
    component: VideosCategory,
    main_module: MODULE.academy,
    module: MODULE.academy,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.protocol_category,
    component: ProtocolCategory,
    main_module: MODULE.academy,
    module: MODULE.academy,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.upload_academy,
    component: UploadAcademyVideo,
    main_module: MODULE.academy,
    module: MODULE.academy,
    action: [ACTION.CREATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.saved_videos,
    component: SavedVideos,
    main_module: MODULE.academy,
    module: MODULE.academy,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.watched_videos,
    component: WatchedVideos,
    main_module: MODULE.academy,
    module: MODULE.academy,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.installation_protocol,
    component: Installation,
    main_module: MODULE.academy,
    module: MODULE.academy,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.saas_protocol,
    component: Saas,
    main_module: MODULE.academy,
    module: MODULE.academy,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },

  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.complaint_segment,
    component: ComplaintSegment,
    main_module: MODULE.academy,
    module: MODULE.academy,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.complaint_protocol,
    component: Complaint,
    main_module: MODULE.academy,
    module: MODULE.academy,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.inside_sales_protocol,
    component: InsideSales,
    main_module: MODULE.academy,
    module: MODULE.academy,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },

  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.sales_training_protocol,
    component: SalesTraining,
    main_module: MODULE.academy,
    module: MODULE.academy,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.hub_creation_checklist,
    component: HubCreation,
    main_module: MODULE.academy,
    module: MODULE.academy,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.kazam_warranty,
    component: KazamWarranty,
    main_module: MODULE.academy,
    module: MODULE.academy,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  // {
  //   isPrivate: true,
  //   fullScreen: true,
  //   exact: true,
  //   path: urls.kazam_learning,
  //   component: KazamLearning,
  //   main_module: MODULE.academy,
  //   module: MODULE.academy,
  //   action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
  //   operator: "or",
  // },

  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.general_learning,
    component: General,
    main_module: MODULE.academy,
    module: MODULE.academy,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.cms_learning,
    component: Cms,
    main_module: MODULE.academy,
    module: MODULE.academy,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },

  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.ev_news,
    component: EvNews,
    main_module: MODULE.academy,
    module: MODULE.academy,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.ev_reviews,
    component: EvReviews,
    main_module: MODULE.academy,
    module: MODULE.academy,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.tally_learning,
    component: Tally,
    main_module: MODULE.academy,
    module: MODULE.academy,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },

  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.sales_learning,
    component: Sales,
    main_module: MODULE.academy,
    module: MODULE.academy,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.company_updates,
    component: Updates,
    main_module: MODULE.academy,
    module: MODULE.academy,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.hr_policies,
    component: HrPolicies,
    main_module: MODULE.academy,
    module: MODULE.academy,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.life_at_kazam,
    component: Life,
    main_module: MODULE.academy,
    module: MODULE.academy,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.startup_gyaan,
    component: StartupGyaan,
    main_module: MODULE.academy,
    module: MODULE.academy,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: urls.success_story,
    component: Success,
    main_module: MODULE.academy,
    module: MODULE.academy,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
];

export default academy_routes;
