import debounce from "lodash.debounce";
import { FunctionComponent, useCallback, useEffect, useState } from "react";

import {
  useToast,
  ToastType,
} from "../../../../../../context_providers/toast/toast_ctx";
import { toQueryString } from "../../../../../../service/api_client";
import AutoCompleteField from "../../../../../new_components/common/autocomplete";
import { fetchGet } from "../../../../content_panel/services/serviceClient";
import ModalDialog from "../../../../../new_components/common/modal";
import OrgCreateWidget from "../org_create";
import {
  ModalAlignment,
  ModalType,
  useModal,
} from "../../../../../../context_providers/modal/modal_context";
export interface OrgModel {
  id?: string;
  name: string;
  namespace: string;
}
interface OrgSearchFieldProps {
  onSelect: (data: OrgModel) => void;
  disabled?: boolean;
  placeholder?: string;
  value?: OrgModel;
  group?: string | string[];
}

const OrgSearchField: FunctionComponent<OrgSearchFieldProps> = (props) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/cms/org`;
  const { showToast } = useToast();
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [addNew, setAddNew] = useState(false);
  const [data, setData] = useState<OrgModel[]>();
  const getData = async (prop: {
    search?: string;
    page: number;
    count: number;
    all?: boolean;
  }) => {
    setLoading(true);
    try {
      const res = await fetchGet(url + toQueryString({ ...prop }));
      if (res.success) {
        setData(res.data.data);
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
      //   return { success: false, error: error.message };
    }
    setLoading(false);
  };

  const debouncedHandler = useCallback(
    debounce((query) => {
      if (query)
        getData({
          search: query,
          page: 1,
          count: 1,
          all: true,
        });
      else setData(undefined);
    }, 500),
    []
  );

  useEffect(() => {
    if (search && edit) {
      if (props.value && props.value.name === search) return;
      debouncedHandler(search);
    }
  }, [search]);
  useEffect(() => {
    setSearch(props.value?.name ?? "");
  }, [props.value]);
  const { showModal, onClose } = useModal();
  const [createId, setcreateId] = useState(0);
  const showCreateOrg = () => {
    showModal({
      title: "Create Organization",
      type: ModalType.modal,
      alignment: ModalAlignment.right,
      onClose() {
        onClose(createId);
      },
      container: (id) => {
        setcreateId(id);
        return (
          <OrgCreateWidget
            onClose={(d) => {
              if (d) {
                setEdit(false);
                setAddNew(false);
                setSearch(d.name);
                props.onSelect(d);
              }
            }}
          />
        );
      },
    });
  };

  return (
    <div className="flex gap-1 items-center    w-full">
      {edit ? (
        <AutoCompleteField
          onAddNew={showCreateOrg}
          loading={loading}
          value={search}
          placeholder={props.placeholder ?? "Select "}
          onSelect={(val) => {
            setEdit(false);
            setSearch(val.value.name);
            props.onSelect(val.value);
          }}
          suggestions={data?.map((d) => ({
            label: (
              <div className="text-xs">
                <b>
                  {d.name}({d.namespace}){" "}
                </b>
              </div>
            ),

            value: d,
          }))}
          onChange={setSearch}
        />
      ) : (
        <div
          className={`text-sm px-3 py-2  h-8 rounded focus:outline-none w-full border ${
            search ? "" : " text-gray-400 "
          }`}
          onClick={() => {
            if (!props.disabled) {
              setEdit(true);
              // setSearch(emp?.name ?? "");
            }
          }}
        >
          {search || props.placeholder || "Select a org"}
        </div>
      )}
      {/* {addNew && (
        <ModalDialog
          onClose={() => {
            setAddNew(false);
          }}
          show={addNew}
          title={"Add ledger"}
        >
          <OrgCreateWidget
            onClose={(d) => {
              if (d) {
                setEdit(false);
                setAddNew(false);
                setSearch(d.name);
                props.onSelect(d);
              }
            }}
          />
        </ModalDialog>
      )} */}
    </div>
  );
};

export default OrgSearchField;
