import { useState } from "react";
import { compressImage } from "../../../infrastructure/utils/image_compressor";
import {
  updateMagazineRepo,
  uploadMagazineRepo,
} from "../../../services/magazine";

const MagazineCreatePage = (props) => {
  const { state } = props.location;
  const [checked, setChecked] = useState(state ? state.active : true);
  const submit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    formData.set("active", checked);
    const files = formData.getAll("files");

    formData.delete("files");

    const comImage = await compressImage(files[0]);
    const name = files[0].name;

    comImage.name = name;
    formData.append("files", comImage);
    formData.append("files", files[1]);

    const res = state
      ? await updateMagazineRepo(formData)
      : await uploadMagazineRepo(formData);
    if (res.success) alert(res.message);
    else alert(res.error);
  };
  return (
    <form autoComplete="off" onSubmit={submit}>
      {state && (
        <>
          {" "}
          <input type="hidden" name="id" value={state.id} />
          <input type="hidden" name="created_by" value={state.created_by} />
          <input type="hidden" name="updated_by" value={state.updated_by} />
          <input type="hidden" name="img_url" value={state.img_url} />
          <input type="hidden" name="pdf_url" value={state.pdf_url} />
        </>
      )}
      <div className="flex justify-center items-center">
        <div className="rounded shadow p-2 md:p-5">
          <h6 className="text-lg font-bold text-center my-3">
            {state ? "Update magazine" : "Create magazine"}
          </h6>
          <div className="">
            <div className=" my-5 mx-3">
              <label htmlFor="title" className="mr-5">
                Title
              </label>
              <input
                type="text"
                name="title"
                id="title"
                required
                defaultValue={state ? state.title : ""}
                placeholder="eg : May 2021"
                className=" border-2 rounded border-gray-700 px-2 py-1 ml-4"
              />
            </div>
            <div className="my-5 mx-3">
              <label htmlFor="image" className="mr-2">
                Cover image
              </label>
              <input
                type="file"
                accept="image/*"
                name="files"
                id="image"
                required={state === undefined}
                className=" border-2 rounded border-gray-700 px-2 py-1"
              />
              <p className="text-center">Please select image format</p>
            </div>

            <div className="my-5 mx-3">
              <label htmlFor="pdf" className="mr-4">
                Magazine
              </label>
              <input
                className=" border-2 rounded border-gray-700 px-2 py-1"
                type="file"
                accept="application/pdf"
                name="files"
                id="pdf"
                required={state === undefined}
              />
              <p className="text-center">Please select pdf format</p>
            </div>

            <div className="my-5 mx-3 flex">
              <div className="mr-5">Status</div>
              <div class="relative inline-block w-9 mr-2 align-middle select-none transition duration-200 ease-in">
                <input
                  type="checkbox"
                  name="active"
                  id="active"
                  required
                  defaultChecked={checked}
                  className={`toggle-checkbox absolute block w-5 h-5 rounded-full bg-white border-4 appearance-none cursor-pointer ${
                    checked ? "right-0 border-green-400" : "border-red-400"
                  }`}
                  onChange={(e) => {
                    setChecked(e.target.checked);
                  }}
                />
                <label
                  htmlFor="active"
                  className={`toggle-label block overflow-hidden h-5 rounded-full bg-gray-300 cursor-pointer ${
                    checked ? "bg-green-300" : "bg-red-400"
                  }`}
                ></label>{" "}
              </div>
              {checked ? "Live" : "offline"}
            </div>
          </div>
          <div className=" flex justify-center my-5">
            <button
              type="submit"
              className="w-min rounded-full px-3 py-1 bg-red-400 hover:bg-red-700 text-white cursor-pointer"
            >
              {state ? "Update" : "Create"}
            </button>
          </div>

          {state && (
            <>
              <div className="flex">
                <div className="w-48 mx-2">
                  <img
                    className="w-full h-auto"
                    src={state.img_url}
                    alt=""
                    // style={{ width: "20rem", height: "20rem" }}
                  />
                  <p>Cover image</p>
                </div>
                <div className="w-48 mx-2">
                  <embed
                    src={state.pdf_url}
                    type="application/pdf"
                    height="300px"
                    width="200px"
                  ></embed>
                  <p>Magazine file</p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </form>
  );
};

export default MagazineCreatePage;
