import { RouteParam } from ".";
import LandingScreen from "../ui/pages/landing_screen";

const landing_page_routes: RouteParam[] = [
  {
    isPrivate: true,
    fullScreen: true,
    exact: true,
    path: "/",
    component: LandingScreen,
  },
];

export default landing_page_routes;
