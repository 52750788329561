import { FunctionComponent, useEffect, useState } from "react";
import { Copy, Triangle, X } from "react-feather";
import { NavLink } from "react-router-dom";
import { useAuth } from "../../../../../context_providers/auth/authProvider";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import { urls } from "../../../../../utils/urls";
import HrmButton from "../../components/HrmButton";
import { GetRef } from "../../service/repos/job_portal";

interface ReferralProps {
  close: () => void;
  id: string;
}

const Referral: FunctionComponent<ReferralProps> = ({ close, id }) => {
  const [showText, setShowText] = useState<boolean>(false);
  const { showToast } = useToast();
  const [empRef, setEmpRef] = useState<string>("");
  const [refUrl, setUrlRef] = useState<string>("");
  const { user } = useAuth();

  const getRef = async (uid: any) => {
    const res = await GetRef(uid);

    if (res.success) {
      setEmpRef(res.data?.ref_code);
      const tempUrl = `https://kazam.in/careers/job-overview/${id}?referral=${res.data?.ref_code}`;
      setUrlRef(tempUrl);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
  };

  useEffect(() => {
    if (user?.uid) {
      getRef(user?.uid);
    }
  }, []);

  return (
    <div className="w-4/5 lg:w-auto flex">
      <div className="bg-white rounded-l-xl py-20 hidden lg:flex flex-col items-center justify-center w-1/2">
        <div className="w-4/5">
          <div className="text-3xl font-bold">Kazam is hiring!!</div>
          <div className="mt-2">
            Incase you have any referral, please share the url/referral code to
            the applicant or fill in the applicant details.
          </div>
          <div className="mt-12">
            <img src="/images/referral.png" alt="" className="w-64" />
          </div>
        </div>
      </div>
      <div className="bg-black lg:rounded-r-xl py-20 text-white w-full lg:w-1/2 flex flex-col items-center relative">
        <div className="absolute top-3 right-3">
          <X
            size={32}
            className="text-white cursor-pointer"
            onClick={() => close()}
          />
        </div>
        <div className="w-4/5 min-h-2/3">
          <div className="text-xl font-semibold">Job referral</div>
          <div className="flex items-center gap-1 text-sm text-gray-400 font-semibold mt-1">
            <div className="">Your referral code</div>
            <div className="text-teal">{empRef ? empRef : ""}</div>
          </div>
          <div className="mt-6">
            <div className="text-sm text-gray-500 font-semibold">
              Share URL for referral
            </div>
            {refUrl !== "" && (
              <div className="relative flex items-center bg-white bg-opacity-10 text-gray-400 rounded-lg mt-2 py-2 pl-2">
                <div className="w-11/12">
                  <input
                    type="text"
                    className="bg-transparent rounded-lg px-2 py-2 w-full"
                    placeholder={refUrl}
                    disabled={true}
                  />
                </div>
                <div className="w-1/12">
                  <Copy
                    size={16}
                    className="cursor-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText(refUrl);
                      setShowText(true);
                      setTimeout(() => {
                        setShowText(false);
                      }, 2000);
                    }}
                  />
                </div>
              </div>
            )}

            <div
              className={`${
                showText ? "opacity-50" : "opacity-0"
              } text-green-400`}
            >
              URL has been copied to clipboard
            </div>
          </div>
          <div className="flex items-center justify-center gap-3 my-12">
            <div className="w-20 h-px bg-gray-500"></div>
            <div className="text-gray-400">or</div>
            <div className="w-20 h-px bg-gray-500"></div>
          </div>
          <div className="flex flex-col items-center relative">
            <NavLink to={`/hrm/apply-job/${id}/${empRef}`}>
              <button className="py-3 px-12 rounded-full bg-green-700 text-white text-sm ">
                Apply for a friend
              </button>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Referral;
