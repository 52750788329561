import { FunctionComponent } from "react";
import { academyVideoCategory } from "../../../../../utils/enums";
import VideoLayout from "../components/VideosLayout";

interface CmsProps {}

const Cms: FunctionComponent<CmsProps> = () => {
  const segment = academyVideoCategory.Cms;

  return <VideoLayout segment={segment} />;
};

export default Cms;
