export const stateData = [
  { id: 1, name: "Andaman and Nicobar Islands", state_code: "AN" },
  { id: 2, name: "Andhra Pradesh", state_code: "AP" },
  { id: 3, name: "Arunachal Pradesh", state_code: "AR" },
  { id: 4, name: "Assam", state_code: "AS" },
  { id: 5, name: "Bihar", state_code: "BR" },
  { id: 6, name: "Chandigarh", state_code: "CH" },
  { id: 7, name: "Chhattisgarh", state_code: "CT" },
  { id: 8, name: "Dadra and Nagar Haveli", state_code: "DN" },
  { id: 9, name: "Daman and Diu", state_code: "DD" },
  { id: 10, name: "Delhi", state_code: "DL" },
  { id: 11, name: "Goa", state_code: "GA" },
  { id: 12, name: "Gujarat", state_code: "GJ" },
  { id: 13, name: "Haryana", state_code: "HR" },
  { id: 14, name: "Himachal Pradesh", state_code: "HP" },
  { id: 15, name: "Jammu and Kashmir", state_code: "JK" },
  { id: 16, name: "Jharkhand", state_code: "JH" },
  { id: 17, name: "Karnataka", state_code: "KA" },
  { id: 18, name: "Kerala", state_code: "KL" },
  { id: 19, name: "Ladakh", state_code: "LA" },
  { id: 20, name: "Lakshadweep", state_code: "LD" },
  { id: 21, name: "Madhya Pradesh", state_code: "MP" },
  { id: 22, name: "Maharashtra", state_code: "MH" },
  { id: 23, name: "Manipur", state_code: "MN" },
  { id: 24, name: "Meghalaya", state_code: "ML" },
  { id: 25, name: "Mizoram", state_code: "MZ" },
  { id: 26, name: "Nagaland", state_code: "NL" },
  { id: 28, name: "Odisha", state_code: "OR" },
  { id: 29, name: "Puducherry", state_code: "PY" },
  { id: 30, name: "Punjab", state_code: "PB" },
  { id: 31, name: "Rajasthan", state_code: "RJ" },
  { id: 32, name: "Sikkim", state_code: "SK" },
  { id: 33, name: "Tamil Nadu", state_code: "TN" },
  { id: 34, name: "Telangana", state_code: "TG" },
  { id: 35, name: "Tripura", state_code: "TR" },
  { id: 36, name: "Uttar Pradesh", state_code: "UP" },
  { id: 37, name: "Uttarakhand", state_code: "UT" },
  { id: 38, name: "West Bengal", state_code: "WB" },
];
