import {
  mdiAttachment,
  mdiCheck,
  mdiChevronDown,
  mdiChevronUp,
  mdiClose,
  mdiDelete,
  mdiFile,
  mdiPencil,
  mdiPlus,
} from "@mdi/js";
import Icon from "@mdi/react";
import moment from "moment";
import React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import {
  ModalType,
  useModal,
} from "../../../../../context_providers/modal/modal_context";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import { DiscountDataModel } from "../../../../../service/models/orders/order_discount_model";
import OrderItemModel from "../../../../../service/models/orders/order_item_model";
import ProductModel, {
  OrderItemDataModel,
} from "../../../../../service/models/product_model";
import { TransactionDataModel } from "../../../../../service/models/trannsaction_model";
import { UTCToLocalDate } from "../../../../../utils/date_util";

import {
  PaymentMode,
  TransactionStatus,
  TransactionType,
} from "../../../../../utils/enums";
import TransactionAttachmentView from "./txn_attachment";
const defaultItem: TransactionDataModel = {
  type: "",
  payment_date: new Date(),
  payment_mode: "",

  amount: 0,
  status: "",
};
interface PaymentRowWidgetProps {
  payment?: TransactionDataModel;
  onSubmit: (data: TransactionDataModel) => void;
  edit: boolean;
  disabled: boolean;
  onDelete?: (i: TransactionDataModel) => void;
  // form_id?: string;
  use_bal?: boolean;
}

const PaymentRowWidget: FunctionComponent<PaymentRowWidgetProps> = (props) => {
  const { showModal } = useModal();
  const { showToast } = useToast();
  const [showMore, setShowMore] = useState(false);
  const { edit, onSubmit, use_bal } = props;
  const [payment, setPayment] = useState<TransactionDataModel>({
    ...(props.payment ?? defaultItem),
  });
  const [editable, setEditable] = useState(false);
  useEffect(() => {
    setEditable(edit);
  }, []);
  const onDone = () => {
    if (use_bal && (!payment.amount || payment.amount <= 0)) {
      onSubmit(payment);
    }
    if (
      !payment.type ||
      !payment.payment_mode ||
      payment.amount < 1 ||
      (payment.payment_mode !== PaymentMode.Cash &&
        (!payment.txn_id || !payment.files || payment.files.length == 0))
    ) {
      showToast({
        type: ToastType.warning,
        text: "Please fill  required data",
      });
      return;
    }
    onSubmit(payment);
  };

  return (
    <>
      <form
        // id={props.form_id}
        onSubmit={(e) => {
          e.preventDefault();
          onDone();
        }}
        className="bg-white rounded-md shadow  text-sm overflow-hidden"
      >
        {!props.payment && (
          <div className="grid grid-cols-11 gap-3 mt-2">
            <h4 className="font-semibold text-center ">
              <span className="text-base text-red-500">*</span>Date
            </h4>
            <h4 className="font-semibold text-center ">
              <span className="text-base text-red-500">*</span>Type
            </h4>
            <h4 className="font-semibold text-center ">
              <span className="text-base text-red-500">*</span>Mode
            </h4>
            <h4 className="font-semibold text-center col-span-2">
              {" "}
              {payment.payment_mode !== PaymentMode.Cash && (
                <span className="text-base text-red-500">*</span>
              )}{" "}
              Txn ID
            </h4>
            <h4 className="font-semibold text-center">
              <span className="text-base text-red-500">*</span>Amount
            </h4>
            <h4 className="font-semibold text-center col-span-2">Note</h4>
            <h4 className="font-semibold text-center">Status</h4>
            <h4 className="font-semibold text-center">File</h4>
            <h4 className="font-semibold text-center"> </h4>
          </div>
        )}
        <div className="grid grid-cols-11 gap-3 items-center p-5">
          <input
            disabled={!editable || props.disabled}
            className="p-1 rounded bg-gray-100 focus:outline-none"
            type="date"
            name=""
            id=""
            value={moment(payment.payment_date).format("YYYY-MM-DD")}
            onChange={(e) => {
              if (e.target.value) {
                const d = UTCToLocalDate(e.target.value)!;
                setPayment({
                  ...payment,
                  payment_date: d,
                });
              }
            }}
          />
          <select
            disabled={!editable || props.disabled}
            name=""
            id=""
            className="p-1 rounded bg-gray-100 focus:outline-none"
            value={payment.type}
            onChange={(e) => {
              setPayment((o) => ({ ...o, type: e.target.value }));
            }}
          >
            <option value="" hidden>
              select
            </option>
            {Object.values(TransactionType).map((mode, i) => (
              <option key={i} value={mode}>
                {mode}
              </option>
            ))}
          </select>
          <select
            disabled={!editable || props.disabled}
            name=""
            id=""
            className="p-1 rounded bg-gray-100 focus:outline-none"
            value={payment.payment_mode}
            onChange={(e) => {
              setPayment((o) => ({ ...o, payment_mode: e.target.value }));
            }}
          >
            <option value="" hidden>
              select
            </option>
            {Object.values(PaymentMode).map((mode, i) => (
              <option key={i} value={mode}>
                {mode}
              </option>
            ))}
          </select>

          <input
            value={payment?.txn_id}
            onChange={(e) => {
              setPayment((o) => ({ ...o, txn_id: e.target.value }));
            }}
            disabled={
              !(
                payment.payment_mode &&
                payment.payment_mode !== PaymentMode.Cash &&
                editable
              )
            }
            placeholder=""
            type="text"
            className=" col-span-2  p-1 rounded bg-gray-100 focus:outline-none"
          />
          <input
            value={payment?.amount}
            onChange={(e) => {
              let v = 0;
              if (e.target.value && !isNaN(e.target.value as any)) {
                v = Number(e.target.value);
              }
              setPayment((o) => ({ ...o, amount: v }));
            }}
            disabled={!editable || props.disabled}
            placeholder=""
            type="number"
            className="text-right  p-1 rounded bg-gray-100 focus:outline-none"
          />

          <input
            value={payment?.comment}
            onChange={(e) => {
              setPayment((o) => ({ ...o, comment: e.target.value }));
            }}
            disabled={!editable || props.disabled}
            placeholder=""
            type="text"
            className=" col-span-2  p-1 rounded bg-gray-100 focus:outline-none"
          />
          <div className="text-center">{payment.status}</div>
          {props.payment?.id ? (
            <div
              className=" text-blue-400 cursor-pointer text-center"
              onClick={() => {
                showModal({
                  type: ModalType.modal,
                  title: "Attachments",
                  container: (id) => (
                    <TransactionAttachmentView txn_id={payment.id!} />
                  ),
                });
              }}
            >
              View
            </div>
          ) : (
            <div className="flex justify-center ">
              {payment.files && payment.files.length > 0 ? (
                <div className="h-14 w-14 flex flex-col items-center">
                  {payment.files[0].type.startsWith("image") ? (
                    <img src={URL.createObjectURL(payment.files[0])} alt="" />
                  ) : (
                    <embed
                      src={URL.createObjectURL(payment.files[0])}
                      type="application/pdf"
                      className="h-full w-full  object-cover"
                    ></embed>
                  )}
                  <div
                    onClick={() => {
                      if (editable)
                        setPayment((o) => ({
                          ...o,
                          files: [],
                        }));
                    }}
                    className="flex gap-1 cursor-pointer items-center  text-xs "
                  >
                    Delete
                  </div>
                </div>
              ) : (
                <div>
                  <label
                    htmlFor={
                      props.payment ? `${props.payment.id}-file` : "file"
                    }
                    className={`border rounded px-2 py-1 flex w-min text-green-400 gap-2 cursor-pointer`}
                  >
                    Select <Icon path={mdiFile} className="h-4 w-4"></Icon>
                  </label>
                  <input
                    id={props.payment ? `${props.payment.id}-file` : "file"}
                    hidden
                    disabled={!editable || props.disabled}
                    onChange={(e) => {
                      setPayment((o) => ({
                        ...o,
                        files: [...(e.target.files ?? [])],
                      }));
                    }}
                    type="file"
                    accept="image/*,application/pdf"
                  />
                </div>
              )}
            </div>
          )}
          <div className="flex gap-2 items-center justify-end">
            {props.payment ? (
              !props.disabled &&
              payment.status === TransactionStatus.PENDING && (
                <>
                  <div className="flex justify-center gap-2">
                    {editable ? (
                      <>
                        <div
                          onClick={() => {
                            if (!window.confirm("Are you sure !")) return;
                            onDone();
                            setEditable(false);
                          }}
                          className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                        >
                          <Icon path={mdiCheck} className="h-4 w-4"></Icon>
                        </div>
                        <div
                          onClick={() => {
                            // setExtra(props.payment!);
                            setEditable(false);
                          }}
                          className="bg-red-100 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                        >
                          <Icon path={mdiClose} className="h-4 w-4"></Icon>
                        </div>
                      </>
                    ) : (
                      <div
                        onClick={() => setEditable(true)}
                        className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                      >
                        <Icon path={mdiPencil} className="h-4 w-4"></Icon>
                      </div>
                    )}
                  </div>
                  <div
                    onClick={() => {
                      if (!window.confirm("Are you sure !")) return;
                      if (props.onDelete) props.onDelete(payment);
                    }}
                    className="bg-red-200 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                  >
                    <Icon path={mdiDelete} className="h-4 w-4"></Icon>
                  </div>
                </>
              )
            ) : (
              <button
                // form="item_form"
                className="flex gap-1 text-white text-sm bg-myPrimaryColor rounded-full px-2 py-1 hover:scale-110 transform duration-300"
              >
                <Icon path={mdiPlus} className="h-5 w-5 "></Icon> Add
              </button>
            )}
            {/* {props.payment && (
              <div
                onClick={() => setShowMore((o) => !o)}
                className="p-1 bg-gray-100 rounded cursor-pointer hover:bg-gray-200"
              >
                <Icon
                  path={showMore ? mdiChevronUp : mdiChevronDown}
                  className="h-4 w-4"
                ></Icon>
              </div>
            )} */}
          </div>
        </div>
        {showMore && props.payment && (
          <div className="px-2 py-1">
            <hr className=" " />
            <div className="text-xs text-gray-400">
              <p>Id : {props.payment.id}</p>
              <p>Parent Txn id :{props.payment.parent_txn_id}</p>
            </div>
            <div className="flex   justify-between gap-2 my-1">
              <p className="text-xs ">
                Created by :<br />
                <div className="flex gap-1">
                  <div className="">
                    <b>{props.payment.created_by_name ?? "N/A"}</b>
                  </div>
                  <div className="">
                    {props.payment.created_at
                      ? moment(props.payment.created_at).format(
                          "DD MMM YYYY h:mm a"
                        )
                      : "--"}
                  </div>
                </div>
              </p>
              <p className="text-xs ">
                Updated by :<br />
                <div className="flex gap-1">
                  <div className="">
                    <b>{props.payment.updated_by_name ?? "N/A"}</b>
                  </div>
                  <div className="">
                    {props.payment.updated_at
                      ? moment(props.payment.updated_at).format(
                          "DD MMM YYYY h:mm a"
                        )
                      : "--"}
                  </div>
                </div>
              </p>
              {props.payment.verified_by && (
                <p
                  className={`text-xs  text-${
                    props.payment.status === TransactionStatus.SUCCESS
                      ? "green"
                      : "red"
                  }-400`}
                >
                  Verified by :<br />
                  <div className="flex gap-1">
                    <div className="">
                      <b>{props.payment.verified_by_name ?? "N/A"}</b>
                    </div>
                    <div className="">
                      {props.payment.verified_at
                        ? moment(props.payment.verified_at).format(
                            "DD MMM YYYY h:mm a"
                          )
                        : "--"}
                    </div>
                  </div>
                </p>
              )}
            </div>
          </div>
        )}
      </form>
    </>
  );
};

export default PaymentRowWidget;
