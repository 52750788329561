import jsonexport from "jsonexport";
import moment from "moment";
import { FunctionComponent, useState, useEffect } from "react";
import { useModal } from "../../../../../context_providers/modal/modal_context";
import {
  useToast,
  ToastType,
} from "../../../../../context_providers/toast/toast_ctx";
import { TransactionDataModel } from "../../../../../service/models/trannsaction_model";
import { GetOrdersApi } from "../../../../../service/repos/order_repo";
import { GetTxnsApi } from "../../../../../service/repos/transaction_repo";
import { exportCSV } from "../../../../../utils/csv_generator";
import { UTCToLocalDate } from "../../../../../utils/date_util";
import { OrderType } from "../../../../../utils/enums";
import StadiumButton from "../../../../new_components/common/stadium_button";

interface ExportWidgetProps {}

const ExportWidget: FunctionComponent<ExportWidgetProps> = (props) => {
  const { setLoading } = useModal();
  const { showToast } = useToast();

  const getExpObj = (datas: TransactionDataModel[]) => {
    return datas.map((data, i) => {
      return {
        payment_date: moment(data.payment_date).format("DD-MM-YYYY hh:mm:ss a"),
        type: data.type,
        ref_id: data.ref_id,
        ref_type: data.ref_type,
        payment_mode: data.payment_mode,
        txn_id: data.txn_id,
        amount: data.amount,
        status: data.status,
        updated_at: moment(data.updated_at).format("DD-MM-YYYY hh:mm:ss a"),
        created_at: moment(data.created_at).format("DD-MM-YYYY hh:mm:ss a"),
        comment: "Extra wiring cash",
        created_by_name: data.created_by_name,
        updated_by_name: data.updated_by_name,
        verified_by_name: data.verified_by_name,
      };
    });
  };
  const [fromDate, setFromdate] = useState("");
  const [toDate, setTodate] = useState("");
  const getTransactions = async () => {
    if (!fromDate || !toDate) {
      showToast({
        type: ToastType.warning,
        text: "Please select date !",
      });

      return;
    } else if (
      moment(fromDate, "YYYY-MM-DD").isAfter(moment(toDate, "YYYY-MM-DD"))
    ) {
      showToast({
        type: ToastType.warning,
        text: "From Date should not be greater than To Date ",
      });
      return;
    }
    setLoading(true);
    const option = {
      fromDate: UTCToLocalDate(fromDate) as any,
      toDate: UTCToLocalDate(toDate) as any,
      all: true,
    };
    const result: {
      success: boolean;
      data: {
        metadata: { total: number; page: number; count: number }[];
        transactions: TransactionDataModel[];
      };

      error?: string;
      timeStamp: string;
    } = await GetTxnsApi(option);

    if (result.success) {
      const t = getExpObj(result.data.transactions);

      jsonexport(t, function (err, csv) {
        if (err) return console.error(err);

        exportCSV(csv, `${fromDate}-${toDate}-orders`);
      });
    } else {
      showToast({ type: ToastType.error, text: result.error ?? "" });
    }
    setLoading(false);
  };

  return (
    <div className="p-5">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <div className="">
          <label htmlFor="from-date">From</label>
          <input
            id="from-date"
            className="border rounded p-2 w-full"
            type="date"
            name="fromDate"
            placeholder="From"
            required
            value={fromDate}
            onChange={(e) => {
              if (
                toDate &&
                moment(e.target.value, "YYYY-MM-DD").isAfter(
                  moment(toDate, "YYYY-MM-DD")
                )
              ) {
                showToast({
                  type: ToastType.warning,
                  text: "From Date should not be greater than To Date ",
                });
                return;
              }
              setFromdate(e.target.value);
            }}
          />
        </div>
        <div className="">
          <label htmlFor="to-date">To</label>
          <input
            id="to-date"
            className="border rounded p-2 w-full"
            type="date"
            name="toDate"
            placeholder="To"
            required
            value={toDate}
            min={fromDate ? fromDate : ""}
            onChange={(e) => {
              if (
                fromDate &&
                moment(fromDate, "YYYY-MM-DD").isAfter(
                  moment(e.target.value, "YYYY-MM-DD")
                )
              ) {
                showToast({
                  type: ToastType.warning,
                  text: "To Date should  be greater than From Date ",
                });
                return;
              }
              setTodate(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="flex justify-end my-2">
        <StadiumButton type="submit" onTap={getTransactions}>
          Export
        </StadiumButton>
      </div>
      {/* <LoadingWidget loading={loading} /> */}
    </div>
  );
};

export default ExportWidget;
