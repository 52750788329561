import { isSet } from "../../../utils/helpers";
export default function Index(props) {
  const { errors, categories, categorySelection, selectedCategories } = props;

  return (
    <>
      <div
        className=" col-span-6 col-start-4 p-5 mt-10"
        style={{ backgroundColor: "#E9ECEF" }}
      >
        <div className=" flex items-center justify-center gap-5 ">
          <>
            {categories.map((category) => (
              <div key={category.category_slug} className=" flex gap-1">
                <label style={{ fontSize: 14 }}>
                  <input
                    type="checkbox"
                    className="mr-2"
                    id={category.id}
                    onChange={(e) => categorySelection(category)}
                    checked={selectedCategories.find(
                      (v) => v.id === category.id
                    )}
                  />
                  {category.category_display}
                </label>
              </div>
            ))}
          </>
        </div>
        {isSet(errors) && isSet(errors.categories) && (
          <div
            className="m-0 p-0 text-red-400 text-center"
            style={{ fontSize: 12, fontWeight: "bold" }}
          >
            {errors && errors.categories}
          </div>
        )}
      </div>
    </>
  );
}
