import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import HrmLoader from "../../components/loading";
import { GetEmpMood } from "../../service/repos/analytics";

interface MoodGraphProps {
  fromDate: number;
  toDate: number;
}

const MoodGraph: FunctionComponent<MoodGraphProps> = ({ fromDate, toDate }) => {
  const [labels, setLabels] = useState<any[]>([]);
  const [chartSeries, setChartSeries] = useState<any[]>([{}]);
  const [loading, setLoading] = useState<boolean>(true);

  const options = {
    colors: ["#4B69FA", "#AEEBEE", "#FD9E33"],
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: labels,
    },
  };

  const getEmpMoodData = async (fromDate: number, toDate: number) => {
    setLoading(true);

    const data = {
      fromDate: fromDate,
      toDate: toDate,
    };
    const res = await GetEmpMood(data);

    if (res.success) {
      const tempLabels = res.data.map((el: any) => {
        return moment(el.date).format("DD/MM");
      });

      let uniqueLabels = [...new Set(tempLabels)];
      setLabels(uniqueLabels);

      const greatTemp = res.data
        .filter((el: any) => el.response === "great")
        .map((o: any) => {
          return Number(o.count);
        });

      const okayTemp = res.data
        .filter((el: any) => el.response === "okay")
        .map((o: any) => {
          return o.count;
        });
      const badTemp = res.data
        .filter((el: any) => el.response === "bad")
        .map((o: any) => {
          return o.count;
        });

      const tempSeries = [
        {
          name: "Great",
          data: greatTemp,
        },
        {
          name: "Okay",
          data: okayTemp,
        },
        {
          name: "Bad",
          data: badTemp,
        },
      ];
      setLoading(false);

      setChartSeries(tempSeries);
    }
  };

  useEffect(() => {
    getEmpMoodData(fromDate, toDate);
  }, [fromDate, toDate]);

  return (
    <div className="border border-gray-200 p-3 bg-white rounded-t-2xl ">
      <div className="px-2 border-b border-gray-200  pb-2 font-bold">
        Mood graph
      </div>
      {loading ? (
        <div className="flex flex-col items-center pt-6">
          <HrmLoader className="w-12 h-12" />
        </div>
      ) : (
        <div className="pt-3">
          <Chart
            // @ts-ignore
            options={options}
            series={chartSeries}
            type="line"
            // width="100%"
          />
        </div>
      )}
    </div>
  );
};

export default MoodGraph;
