import { FunctionComponent, useEffect, useState } from "react";
import { Download } from "react-feather";
import { NavLink } from "react-router-dom";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import { SubDepartments } from "../../../../../utils/enums";
import HrmLoader from "../../components/loading";
import { JobDetail } from "../../service/models/job_portal";
import { GetJobs } from "../../service/repos/job_portal";

interface ApplicantListProps {}

const ApplicantList: FunctionComponent<ApplicantListProps> = () => {
  const [jobs, setJobs] = useState<JobDetail[]>([]);
  const [filterJobs, setFilterJobs] = useState<JobDetail[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const tableHeadings = ["Role", "Dept", "Applicants", "Location"];
  const { showToast } = useToast();

  const getJobData = async () => {
    setLoading(true);
    const res = await GetJobs({ page: 1, count: 10, with_count: true });
    console.log(res);

    if (res.success) {
      setJobs(res.data?.data);
      setFilterJobs(res.data?.data);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };

  const filterData = (dept: string) => {
    if (dept === "All") {
      setFilterJobs(jobs);
    } else {
      const tempJobs = jobs.filter((el) => el.department === dept);
      setFilterJobs(tempJobs);
    }
  };

  useEffect(() => {
    getJobData();
  }, []);

  return (
    <>
      <div className="">
        <div className="text-gray-500 font-semibold text-sm">Department</div>
        <select
          name=""
          id=""
          className="py-2 px-3 bg-white rounded-md border border-gray-300 focus:outline-none mt-1 text-sm"
          onChange={(e) => filterData(e.target.value)}
        >
          <option value="All">All</option>
          {Object.keys(SubDepartments).map((el, i) => {
            return (
              <option value={el} key={i}>
                {el}
              </option>
            );
          })}
        </select>
      </div>
      {loading ? (
        <HrmLoader className="w-8 h-8" />
      ) : (
        <div className=" bg-white mt-6">
          <div className="hidden lg:grid grid-cols-4 font-bold text-sm py-3 px-6  bg-gray-200 bg-opacity-90 rounded-t-xl">
            {tableHeadings.map((el, i) => {
              return (
                <div className="" key={i}>
                  {el}
                </div>
              );
            })}
          </div>
          {filterJobs.length === 0 ? (
            <div className="py-2 pl-3 font-semibold text-gray-400 text-sm">
              No data
            </div>
          ) : (
            <div className="job_applicant_wrapper overflow-y-auto">
              {filterJobs.map((el: JobDetail, i: number) => {
                return (
                  <NavLink to={`/hrm/job-applicants-view/${el.id}`}>
                    <div
                      className="grid grid-cols-2 lg:grid-cols-4 gap-x-4 lg:gap-x-0 text-black px-6 text-sm py-4  cursor-pointer border-b hover:bg-hrmBlue hover:bg-opacity-5"
                      key={i}
                    >
                      <div className="">{el?.title}</div>
                      <div className="">{el?.department}</div>
                      <div className="">
                        {" "}
                        <span className="lg:hidden font-semibold text-xs">
                          Applicants-
                        </span>{" "}
                        {el?.applications?.count! > 0
                          ? el?.applications?.count
                          : "0"}
                      </div>
                      <div className="">{el?.location}</div>
                    </div>
                  </NavLink>
                );
              })}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ApplicantList;
