import { RouteParam } from ".";
import InventoryListing from "../ui/pages/inventory";
import DeliveryChallanWidget from "../ui/pages/inventory/delivery_challan";
import InventoryOverViewPage from "../ui/pages/inventory/overview";
import Dashboard from "../ui/pages/dashboard";
import EngineerLayout from "../ui/pages/new_ops/Engineer/layout";
import EmpAttendancePage from "../ui/pages/new_ops/attendance/emp_attedance";
import EmpAttendanceHistoryPage from "../ui/pages/new_ops/attendance/emp_attendance_history";
import EmpTaskSessionsWidget from "../ui/pages/new_ops/attendance/task_session_admin";
import CreateOpsTicketPage from "../ui/pages/new_ops/create_ticket";
import EngineerTaskDetail from "../ui/pages/new_ops/engineer_view/engineer_detail/engineer_detail_view";
import EngineerTaskList from "../ui/pages/new_ops/engineer_view/engineer_task_list";
import MyRecentOpsTasksScreen from "../ui/pages/new_ops/my_ops_task_recent";
import MyOpsTaskDetailPage from "../ui/pages/new_ops/my_task_detail_widget";
import MyOpsTasksScreen from "../ui/pages/new_ops/my_task_widget";
import OpsAdminView from "../ui/pages/new_ops/ops_admin_view";
import OpsTicketListScreen from "../ui/pages/new_ops/ops_ticket_list";
import OrderCreatePage from "../ui/pages/orders/order_create";
import OrderDetailPage from "../ui/pages/orders/order_detail/order_detail_page";
import OrderListPage from "../ui/pages/orders/order_list";
import { ACTION, MODULE } from "../utils/enums";
import { urls } from "../utils/urls";

const operation_routes: RouteParam[] = [
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.ops_admin_view,
    component: OpsAdminView,
    main_module: MODULE.ops_task_panel,
    module: MODULE.ops_ticket,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "and",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.operation_task + "/create",
    component: CreateOpsTicketPage,
    main_module: MODULE.ops_task_panel,
    module: MODULE.ops_ticket,
    action: [ACTION.CREATE],
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.operation_task + "/update/:id",
    component: CreateOpsTicketPage,
    main_module: MODULE.ops_task_panel,
    module: MODULE.ops_ticket,
    action: [ACTION.CREATE],
  },

  // {
  //   isPrivate: true,
  //   fullScreen: false,
  //   exact: false,
  //   path: urls.operation_engineer_task + "/update/:id",
  //   component: EngineerTaskDetail,
  //   main_module: MODULE.ops_task_panel,
  //   module: MODULE.ops_ticket,
  //   action: [ACTION.CREATE],
  // },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.operation_task,
    component: OpsTicketListScreen,
    main_module: MODULE.ops_task_panel,
    module: MODULE.ops_ticket,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "and",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: false,
    path: urls.operation_engineer_task,
    component: EngineerLayout,
    main_module: MODULE.ops_task_panel,
    module: MODULE.ops_task,
    action: [ACTION.READ],
  },
  // {
  //   isPrivate: true,
  //   fullScreen: false,
  //   exact: true,
  //   path: urls.my_operation_task,
  //   component: MyOpsTasksScreen,
  //   main_module: MODULE.ops_task_panel,
  //   module: MODULE.ops_task,
  //   action: [ACTION.READ],
  // },
  // {
  //   isPrivate: true,
  //   fullScreen: false,
  //   exact: true,
  //   path: urls.my_recent_operation_task,
  //   component: MyRecentOpsTasksScreen,
  //   main_module: MODULE.ops_task_panel,
  //   module: MODULE.ops_task,
  //   action: [ACTION.READ],
  // },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.my_operation_task_detail,
    component: MyOpsTaskDetailPage,
    main_module: MODULE.ops_task_panel,
    module: MODULE.ops_task,
    action: [ACTION.READ],
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.emp_attendance,
    component: EmpAttendancePage,
    main_module: MODULE.ops_task_panel,
    module: MODULE.ops_task,
    action: [ACTION.READ],
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.emp_attendace_history,
    component: EmpAttendanceHistoryPage,
    main_module: MODULE.ops_task_panel,
    module: MODULE.ops_task,
    action: [ACTION.READ],
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.ops_task_session,
    component: EmpTaskSessionsWidget,
    main_module: MODULE.ops_task_panel,
    module: MODULE.ops_ticket,
    action: [ACTION.READ],
  },
];

export default operation_routes;
