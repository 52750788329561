import { FunctionComponent, useEffect, useState } from "react";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import {
  ItemMovementModel,
} from "../../../../inventory/models/inventory_voucher";
import {
  PurchaseVoucherItem,
} from "../../../models/purchase_model";
import ItemSplitRow from "./item_split_row";

interface ItemSplitWidgetProps {
  max_qty?: number;
  price?: number;
  item: PurchaseVoucherItem;
  onSubmit: (data: PurchaseVoucherItem) => void;
}

const ItemSplitWidget: FunctionComponent<ItemSplitWidgetProps> = (props) => {
  const { showToast } = useToast();
  const [items, setItems] = useState<ItemMovementModel[]>([]);

  const [itemData, setItemData] = useState<PurchaseVoucherItem>({
    ...props.item,
  });
  useEffect(() => {
    const bill_unit_no = items.reduce((pv, val) => pv + val.bill_unit_no, 0);
    const unit_no = items.reduce((pv, val) => pv + val.unit_no, 0);

    setItemData((o) => ({ ...o, bill_unit_no, unit_no }));
  }, [items]);
  useEffect(() => {
    setItems(props.item.item_split || []);
  }, [props.item.item_split]);

  return (
    <div className="p-2">
      <div className="flex justify-between mb-1 items-end">
        <div className="">
          Item : <b>{itemData.item?.name}</b>
        </div>
        <div className="flex gap-2 items-center">
          <label
            htmlFor="price"
            title={`Price per ${itemData.item?.unit.symbol}`}
          >
            Price *
          </label>
          <input
            disabled={props.price !== undefined}
            value={itemData?.price}
            onChange={(e) => {
              const val = e.target.value.trim();
              const value = Number(val || 0);
              setItemData((ol) => ({ ...ol, price: value }));
            }}
            placeholder=""
            type="number"
            className="text-right  p-1 focus:outline-none border rounded no-arrows"
          />
        </div>
      </div>
      <hr />
      <div className="flex flex-col gap-2 text-xs bg-white rounded ">
        <div className="grid gap-1 grid-cols-7 bg-myPrimaryColor text-white font-bold p-1  rounded-t  ">
          <div className="py-1  col-span-4 ">Warehouse</div>
          {/* <div className="py-1  col-span-1">Bill Qty</div> */}
          <div className="py-1  col-span-1 text-center">Qty</div>
          {/* <div className="py-1  col-span-1">Price</div> */}
          <div className="py-1  col-span-1 text-center">Amount</div>
          <div className="py-1  col-span-1 text-center">Actions</div>
        </div>
        <div className="flex flex-col gap-2 h-96 overflow-auto">
          {items.map((item, i) => (
            <ItemSplitRow
              item={itemData.item!}
              price={itemData.price}
              key={Math.random() * 1000}
              onSubmit={function (data: ItemMovementModel): void {
                setItems((o) => {
                  const t = [
                    ...o.map((it) => {
                      if (it.id === data.id) return data;

                      return it;
                    }),
                  ];

                  if (props.max_qty !== undefined) {
                    const qty = t.reduce((p, v) => p + v.unit_no, 0);
                    if (qty > props.max_qty) {
                      showToast({
                        type: ToastType.error,
                        text: `Max quantity is ${props.max_qty}`,
                      });

                      return [...o];
                    }
                  }
                  return t;
                });
              }}
              edit={false}
              disabled={false}
              item_split={item}
            />
          ))}
          <ItemSplitRow
            item={itemData.item!}
            price={itemData.price}
            key={(Math.random() * 1000).toString()}
            onSubmit={function (data: ItemMovementModel): void {
              data.id = (Math.random() * 1000).toString();

              setItems((o) => {
                const t = [...o, data];
                if (props.max_qty !== undefined) {
                  const qty = t.reduce((p, v) => p + v.unit_no, 0);
                  if (qty > props.max_qty) {
                    showToast({
                      type: ToastType.error,
                      text: `Max quantity is ${props.max_qty}`,
                    });
                    return o;
                  }
                }
                return t;
              });
            }}
            edit={true}
            disabled={false}
          />
        </div>
        <hr />
        <div className=" text-sm items-center  grid grid-cols-8 gap-1    py-1">
          <div className={`  col-span-4 `}>
            <div className="text-right font-semibold">Total</div>
          </div>
          <div className=""></div>
          {/* <div className=""></div> */}
          {/* <div
            title="Bill Qty"
            className="   border rounded py-1 px-2 col-span-1 w-full text-right"
          >
            {itemData.bill_unit_no}
          </div> */}
          <div className="grid grid-cols-4 gap-1 items-center">
            <div
              title="Qty"
              className="    py-1 px-2   w-full text-right col-span-3"
            >
              {itemData.unit_no}
            </div>
            <div className="">{itemData.item?.unit.symbol}</div>
          </div>

          {/* <div
            title="Price"
            className="   border rounded py-1 px-2 col-span-1 w-full text-right"
          >
            {itemData.price}
          </div> */}

          <div
            title="Total Amount"
            className="    py-1 px-2 col-span-1 w-full text-right"
          >
            {itemData.price * itemData.bill_unit_no}
          </div>
          <button
            type="button"
            onClick={() => {
              if (
                !itemData.item ||
                !itemData.bill_unit_no ||
                !itemData.unit_no ||
                !itemData.price
              ) {
                showToast({
                  type: ToastType.warning,
                  text: "Please select Item, Qty and price",
                });
                return;
              }
              itemData.item_split = items;
              props.onSubmit(itemData);
            }}
            className="px-2 py-1 rounded bg-myPrimaryColor text-white"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ItemSplitWidget;
