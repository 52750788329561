import { fetchDelete, fetchGet, fetchPost } from "../api_client";
export interface ApiClientModel {
  id: string;
  user_id: any;
  name: string;
  user_name: string;
  api_id: string;
  api_key: string;
  created_at: number;
}
export const GetAdminApiClientsApi = async ({
  page,
  count,
}: {
  page: number;
  count: number;
}) => {
  try {
    const params = new URLSearchParams();
    params.append("page", page.toString());
    params.append("count", count.toString());
    const url = `${
      process.env.REACT_APP_BACKEND_BASE_URL
    }/api/v3/api-client/all?${params.toString()}`;
    console.log();

    const res = await fetchGet(url);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GetApiClientsApi = async ({
  page,
  count,
}: {
  page: number;
  count: number;
}) => {
  try {
    const params = new URLSearchParams();
    params.append("page", page.toString());
    params.append("count", count.toString());
    const url = `${
      process.env.REACT_APP_BACKEND_BASE_URL
    }/api/v3/api-client?${params.toString()}`;

    const res = await fetchGet(url);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GenerateApiClientsApi = async (data: { name: string }) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/api-client `;

    const res = await fetchPost(url, data);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const DeleteApiClientApi = async (id: string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/api-client/${id}`;
    const res = await fetchDelete(url);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
