import { BaseModel } from "../../../../service/models/base";

export interface ProcurementProjectModel extends BaseModel {
  name: string;
  description: string;
  amount: number;
  spent: number;
  batch_name: string;
  batch_size: number;
  category: string;
}

export const procurementProjectDefaultValue: ProcurementProjectModel = {
  name: "",
  description: "",
  amount: 0,
  spent: 0,
  batch_name: "",
  batch_size: 0,
  category: "",
};
