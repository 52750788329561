import { FunctionComponent } from "react";
import { academyVideoCategory } from "../../../../../utils/enums";
import VideoLayout from "../components/VideosLayout";

interface EvNewsProps {}

const EvNews: FunctionComponent<EvNewsProps> = () => {
  const segment = academyVideoCategory.Ev_news;

  return <VideoLayout segment={segment} />;
};

export default EvNews;
