import Tooltip from "@material-ui/core/Tooltip";
import {
  mdiComment,
  mdiMathLog,
  mdiMicrosoftExcel,
  mdiPlus,
  mdiTrayArrowDown,
} from "@mdi/js";
import Icon from "@mdi/react";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { Download, Search } from "react-feather";
import { Link } from "react-router-dom";
import {
  useToast,
  ToastType,
} from "../../../context_providers/toast/toast_ctx";
import { OpsTaskModel } from "../../../service/models/ops/ops_ticket";
import {
  TaskFilterModel,
  GetOpsTicketsApi,
  UpdateOpsTaskStatusApi,
} from "../../../service/repos/ops/ops_tickets_repo";
import { UTCToLocalDate } from "../../../utils/date_util";
import { isValidJson } from "../../../utils/formDataToJson";
import { urls } from "../../../utils/urls";
import LoadingWidget from "../../components/loading_spinner";
import DrawerModal, {
  DrawerModalLoaction,
} from "../../new_components/common/drawer_modal";
import FilterDropDown from "../../new_components/common/filter_drop_down";
import ModalDialog from "../../new_components/common/modal";
import Pagination from "../../new_components/common/pagination";
import EmployeeSearchWidget from "../../new_components/emp_search_widget";
import SearchFieldWidget from "../../new_components/search_field";
import CommentWidget from "./comment_widget";
import ExportTask from "./components/export_widget";
import LogsWidget from "./logs_widget";
import { Ops_task_status } from "../../../utils/enums";

interface OpsTicketListScreenProps {}
const defaultFilter: TaskFilterModel = {
  page: 1,
  count: 100,
  active: true,
};
const OpsTicketListScreen: FunctionComponent<OpsTicketListScreenProps> = () => {
  const { showToast } = useToast();
  const tempFilter = localStorage.getItem("task_filter");
  const [filter, setFilter] = useState<TaskFilterModel>(
    tempFilter ? JSON.parse(tempFilter) : defaultFilter
  );
  const [tickets, setTickets] = useState<OpsTaskModel[]>([]);
  const [loading, setLoading] = useState(false);
  const [showLogs, setShowLogs] = useState<OpsTaskModel>();
  const [showComments, setShowComments] = useState<OpsTaskModel>();

  const [total, setTotal] = useState(0);
  const [showExport, setShowExport] = useState(false);
  const getTickets = async () => {
    setLoading(true);
    const res = await GetOpsTicketsApi(filter);

    if (res.success) {
      setTickets(res.data.data);
      console.log(res.data.metadata);

      if (res.data.metadata[0]) setTotal(res.data.metadata[0].total);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };

  const updateTaskStatus = async (data: { id: string; status: string }) => {
    setLoading(true);

    const res = await UpdateOpsTaskStatusApi(data);

    if (res.success) {
      setTickets((o) => {
        o.forEach((t) => {
          if (t.id === data.id) {
            t.status = data.status;
          }
        });
        return o;
      });
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  useEffect(() => {
    localStorage.setItem("task_filter", JSON.stringify(filter));
    getTickets();
  }, [filter]);

  return (
    <div className="bg-gray-200 p-3">
      {" "}
      {showExport && (
        <ModalDialog
          show={showExport}
          title="Export"
          onClose={() => setShowExport(false)}
        >
          <ExportTask filter={filter} />
        </ModalDialog>
      )}
      {showComments && (
        <DrawerModal
          show={showComments !== undefined}
          title={"Comments"}
          location={DrawerModalLoaction.right}
          onClose={() => {
            setShowComments(undefined);
          }}
        >
          <CommentWidget
            task_id={showComments.id}
            active={showComments!.active}
          />
        </DrawerModal>
      )}
      {showLogs && (
        <DrawerModal
          show={showLogs !== undefined}
          title={"Log history"}
          location={DrawerModalLoaction.right}
          onClose={() => {
            setShowLogs(undefined);
          }}
        >
          <LogsWidget task_id={showLogs.id} />
        </DrawerModal>
      )}
      <div className="flex justify-between  flex-wrap">
        <div className="">
          <h1 className="text-2xl font-bold">Task Management</h1>
          <div className="text-gray-500 font-semibold text-sm">
            Manage tasks of engineers
          </div>
        </div>
        <div className="flex gap-2 items-center  ">
          <Tooltip title="Search by ticket id ,order id,resonsible person">
            <div className=" bg-white rounded-md">
              <SearchFieldWidget
                defaulValue={filter.search}
                onClear={() => {
                  setFilter((o) => ({ ...o, search: "" }));
                }}
                placeholder="Search"
                onSubmit={(search) => {
                  setFilter((o) => ({ ...o, search }));
                }}
              />
            </div>
          </Tooltip>
          <button
            className=" rounded-md py-2 px-4  text-sm  bg-gray-600 text-white font-semibold "
            onClick={() => setShowExport(true)}
          >
            {/* <Icon path={mdiMicrosoftExcel} className="h-4 w-4  " /> */}
            Export <Download className="inline ml-1" size={12} />
          </button>
          <Link
            to={urls.operation_task + "/create"}
            className=" rounded-md py-2 px-4  text-sm  bg-purple-500 text-white font-semibold "
          >
            Create Task
          </Link>
        </div>
      </div>
      <div className="p-2 rounded-md  my-2 bg-white">
        <div className="flex justify-between items-end text-sm">
          <h3 className="mt-2   font-bold text-base">Filter</h3>
          <button
            onClick={() => {
              setFilter(defaultFilter);
            }}
          >
            Clear
          </button>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-6 gap-3 my-2   ">
          <div className="">
            <label htmlFor="fromDate"></label>
            <input
              value={
                filter.fromDate
                  ? moment(filter.fromDate).format("YYYY-MM-DD")
                  : ""
              }
              onChange={(e) => {
                setFilter((o) => ({
                  ...o,
                  fromDate: UTCToLocalDate(e.target.value),
                }));
              }}
              className="border rounded text-sm w-full h-8 focus:outline-none cursor-pointer"
              type="date"
              name="fromDate"
              id="fromDate"
            />
          </div>
          <div className="">
            <label htmlFor="toDate"></label>
            <input
              value={
                filter.toDate ? moment(filter.toDate).format("YYYY-MM-DD") : ""
              }
              onChange={(e) => {
                setFilter((o) => ({
                  ...o,
                  toDate: UTCToLocalDate(e.target.value),
                }));
              }}
              className="border rounded text-sm w-full h-8 focus:outline-none cursor-pointer"
              type="date"
              name="toDate"
              id="toDate"
            />
          </div>
          <FilterDropDown
            placeholder="Task type"
            onClearAll={() =>
              setFilter({
                ...filter,
                type: undefined,
              })
            }
            onDelete={(type) => {
              setFilter({
                ...filter,
                type: filter.type?.filter((val) => val !== type),
              });
            }}
            onSelect={(type) => {
              setFilter({
                ...filter,
                type: [...(filter.type ?? []), type],
              });
            }}
            selectedOptions={filter.type}
            options={[
              "Installation",
              "Delivery",
              "Service",
              "Config",
              "Complaint",
              "Survey",
              "Other",
            ].map((t) => ({
              label: t,
              value: t,
            }))}
          />
          <FilterDropDown
            placeholder="Status"
            onClearAll={() =>
              setFilter({
                ...filter,
                status: undefined,
              })
            }
            onDelete={(status) => {
              setFilter({
                ...filter,
                status: filter.status?.filter((val) => val !== status),
              });
            }}
            onSelect={(status) => {
              setFilter({
                ...filter,
                status: [...(filter.status ?? []), status],
              });
            }}
            selectedOptions={filter.status}
            options={["Pending", "Under Progress", "Completed"].map((t) => ({
              label: t,
              value: t,
            }))}
          />
          <select
            value={
              filter.active !== undefined
                ? filter.active
                  ? "true"
                  : "false"
                : ""
            }
            name=""
            id=""
            onChange={(e) => {
              setFilter({
                ...filter,
                active:
                  e.target.value === "true"
                    ? true
                    : e.target.value === "false"
                    ? false
                    : undefined,
              });
            }}
          >
            {[
              { label: "Active", value: "true" },
              { label: "Closed", value: "false" },
              { label: "All", value: "" },
            ].map((v) => (
              <option value={v.value}>{v.label}</option>
            ))}
          </select>
          {/* <FilterDropDown
            placeholder="Visibility"
            onClearAll={() =>
              setFilter({
                ...filter,
                active: undefined,
              })
            }
            onDelete={(active) => {
              setFilter({
                ...filter,
                active: filter.active?.filter((val) => val !== active),
              });
            }}
            onSelect={(active) => {
              setFilter({
                ...filter,
                active: [...(filter.active ?? []), active],
              });
            }}
            selectedOptions={filter.active}
            options={[
              { label: "Active", value: "true" },
              { label: "Closed", value: "false" },
            ]}
          /> */}
          <div className=" z-20 ">
            {/* <label htmlFor="" className="text-sm">
            Responsible person
          </label> */}
            <div className="flex gap-1 items-center w-full">
              {!loading && (
                <EmployeeSearchWidget
                  placeholder="Responsible person"
                  emp={
                    filter.emp_id
                      ? {
                          uid: filter.emp_id,
                          name: filter.emp_name ?? "",
                        }
                      : undefined
                  }
                  withBtn={false}
                  onSelect={(emp) => {
                    setFilter((old) => ({
                      ...old,
                      emp_id: emp.uid,
                      emp_name: emp.first_name + " " + emp.last_name,
                    }));
                  }}
                />
              )}
              {filter.emp_id && (
                <button
                  className="text-xs"
                  onClick={() => {
                    setLoading(true);
                    setFilter((old) => ({
                      ...old,
                      emp_id: undefined,
                      emp_name: undefined,
                    }));
                    setLoading(false);
                  }}
                >
                  {/* <Icon path={mdiClose} className="h-4 w-4"></Icon> */}
                  Clear
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="sm:hidden  flex-col justify-between overflow-hidden   "
        style={{ height: "90vh" }}
      >
        <div className="overflow-auto " style={{ height: "92%" }}>
          {tickets.map((ticket, i) => (
            <Link to={`${urls.operation_task}/update/${ticket.id}`} key={i}>
              <div className="my-5 shadow p-2 text-sm">
                <div className="flex justify-between">
                  <div className="">{ticket.id}</div>
                  <div className="">{ticket.type}</div>
                </div>
                <div className="text-xs">{ticket.order_id ?? ""}</div>
                <div className="text-xs">{ticket.warehouse_name}</div>
                <div className="flex justify-between mt-2">
                  <div className="text-xs">{ticket.emp_name ?? ""}</div>
                  <div className="text-xs">
                    Due Date {new Date(ticket.last_date!).toLocaleDateString()}
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
        <div className="">
          <Pagination
            itemCount={tickets.length}
            page={filter.page}
            total={total}
            count={filter.count}
            onChange={(page) => setFilter((o) => ({ ...o, page }))}
          />
        </div>
      </div>
      <div
        className="hidden sm:flex  flex-col justify-between overflow-hidden  rounded-md bg-white  p-1  "
        style={{ height: "75vh" }}
      >
        <div style={{ height: "95%" }} className="  rounded overflow-auto  ">
          <table className="w-full  text-sm">
            <thead className="rounded shadow ">
              <tr className="text-xs sticky top-0 bg-white z-10 whitespace-nowrap">
                <th className="px-2 py-2"> </th>
                <th className="px-2 py-2" align="left">
                  Date
                </th>
                <th className="px-2 py-2" align="left">
                  Due Date
                </th>
                <th className="px-2 py-2" align="left">
                  Task ID
                </th>
                <th className="px-3 py-2" align="left">
                  Order ID
                </th>
                <th className="px-3 py-2" align="left">
                  Warehouse
                </th>
                <th className="px-3 py-2" align="left">
                  Task Type
                </th>
                <th className="px-3 py-2" align="left">
                  Device ID
                </th>
                <th className="px-3 py-2" align="left">
                  Responsible
                </th>

                <th className="px-3 py-2" align="left">
                  Status
                </th>
                {/* <th className="px-3 py-2">Visibility</th> */}
                <th className="px-3 py-2" align="left">
                  Contact Name
                </th>
                <th className="px-3 py-2" align="left">
                  Contact number
                </th>

                <th className="px-3 py-2" align="left">
                  Address
                </th>
                {/* <th className="px-3 py-2">Description</th> */}
              </tr>
            </thead>
            <tbody>
              {tickets.map((d, i) => (
                <tr className="border-b text-xs whitespace-nowrap" key={i}>
                  <td>
                    <div className="flex gap-1">
                      <Tooltip title="copy task ID">
                        <svg
                          onClick={() => {
                            navigator.clipboard.writeText(d.id);
                            showToast({
                              type: ToastType.info,
                              text: "Copied task ID to clip board!",
                            });
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4 cursor-pointer hover:text-red-600"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
                          />
                        </svg>
                      </Tooltip>
                      <Tooltip title="Notes">
                        <svg
                          onClick={() => setShowComments(d)}
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4 hover:text-red-600 cursor-pointer"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                          />
                        </svg>
                      </Tooltip>
                      <Tooltip title="history">
                        <svg
                          onClick={() => setShowLogs(d)}
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4  cursor-pointer hover:text-red-600"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </Tooltip>
                    </div>
                  </td>
                  <td className="px-3 py-2" align="left">
                    {moment(d.created_at).format("DD MMM YYYY hh:mm a") ??
                      "---"}
                  </td>
                  <td className="px-3 py-2" align="left">
                    {moment(d.last_date).format("DD MMM YYYY") ?? "---"}
                  </td>

                  <td className="px-3 py-2" align="left">
                    <Link
                      to={`${urls.operation_task}/update/${d.id}`}
                      className="text-blue-400 hover:text-blue-600 px-3 py-1 "
                    >
                      {d.id}
                    </Link>
                  </td>
                  <td
                    className="px-3 py-2 text-blue-400 hover:text-blue-600"
                    align="left"
                  >
                    {d.order_id ? (
                      <Link
                        target="_blank"
                        to={`${urls.orderList}/${d.order_id}`}
                        key={i}
                      >
                        {d.order_id}
                      </Link>
                    ) : (
                      "---"
                    )}
                  </td>
                  <td className="px-3 py-2" align="left">
                    <div className="">{d.warehouse_name}</div>
                  </td>
                  <td className="px-3 py-2" align="left">
                    <div className="">{d.type}</div>
                  </td>
                  <td className="px-3 py-2" align="left">
                    <div className="flex gap-2 max-w-xs whitespace-nowrap overflow-ellipsis overflow-hidden">
                      {d.device_ids
                        ? typeof d.device_ids === "object"
                          ? d.device_ids.map(
                              (
                                id: { device_id: string; name: string },
                                i: number
                              ) => (
                                <div key={i} className=" ">
                                  {id.device_id}{" "}
                                  <span style={{ fontSize: 8 }}>
                                    ({id.name})
                                  </span>
                                </div>
                              )
                            )
                          : d.device_ids
                        : d.device_items
                        ? d.device_items.reduce(
                            (pv, v) => pv + v.device_id + ", ",
                            ""
                          )
                        : "--"}
                    </div>
                  </td>
                  <td className="px-3 py-2" align="left">
                    {d.emp_name ?? "---"}
                  </td>
                  <td className="px-3 py-2" align="left">
                    <div
                      className={`border rounded px-2 p-1 flex justify-center ${
                        d.status == Ops_task_status.pending
                          ? "text-yellow-500 border-yellow-200 bg-yellow-50"
                          : d.status == Ops_task_status.under_progress
                          ? "text-blue-500 border-blue-200 bg-blue-50"
                          : d.status == Ops_task_status.completed
                          ? "text-green-500 border-green-200 bg-green-50"
                          : "text-gray-500 border-gray-200 bg-gray-50"
                      }`}
                    >
                      {d.status}
                      {/* <select
                        name="status"
                        id="status"
                        value={d.status}
                        onChange={(e) => {
                          updateTaskStatus({
                            id: d.id,
                            status: e.target.value,
                          });
                        }}
                      >
                        <option value="" hidden>
                          Select...
                        </option>
                        {[
                          "Pending",
                          "Under Progress",
                          "Completed",
                          "Cancelled",
                        ].map((op, i) => (
                          <option value={op} key={i}>
                            {op}
                          </option>
                        ))}
                      </select> */}
                    </div>
                  </td>
                  {/* <td className="px-3 py-2 "  align="left">
                    <div className="max-w-xs whitespace-nowrap overflow-ellipsis overflow-hidden">
                      {d.active ? "Active" : "Closed"}
                    </div>
                  </td> */}
                  <td className="px-3 py-2 " align="left">
                    <div className="max-w-xs whitespace-nowrap overflow-ellipsis overflow-hidden">
                      {d.cust_name
                        ?.replaceAll("undefined", "")
                        ?.replaceAll("null", "") ?? "---"}
                    </div>
                  </td>
                  <td className="px-3 py-2" align="left">
                    {" "}
                    <div className="max-w-xs whitespace-nowrap overflow-ellipsis overflow-hidden">
                      {d.cust_mobile ?? "---"},{d.cust_alt_mobile ?? "---"}
                    </div>
                  </td>
                  <td className="px-3 py-2" align="left">
                    {" "}
                    <div className="max-w-xs whitespace-nowrap overflow-ellipsis overflow-hidden">
                      {d?.cust_address ?? d.address?.full_address ?? "---"}
                    </div>
                  </td>
                  {/* <td className="px-3 py-2"  align="left">
                    {" "}
                    <div className="max-w-xs whitespace-nowrap overflow-ellipsis overflow-hidden">
                      {d.description}
                    </div>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="">
          <Pagination
            itemCount={tickets.length}
            page={filter.page}
            total={total}
            count={filter.count}
            onChange={(page) => setFilter((o) => ({ ...o, page }))}
          />
        </div>
      </div>
      <LoadingWidget loading={loading} />
    </div>
  );
};

export default OpsTicketListScreen;
