import { FunctionComponent, useEffect, useState } from "react";
import {
  AlertTriangle,
  AlignJustify,
  Anchor,
  Check,
  XSquare,
} from "react-feather";
import {
  useToast,
  ToastType,
} from "../../../context_providers/toast/toast_ctx";
import { GetTaskAnalyticsRepo } from "../../../service/repos/ops/task_session_repo";
import LoadingWidget from "../../components/loading_spinner";
import AdminTaskGraphWidget from "./admin_graph";
import EmpTaskSessionsWidget from "./attendance/task_session_admin";

export interface Counter {
  Completed: number;
  Pending: number;
  "Under Progress": number;
  Cancelled: number;
  total: number;
}
export interface EmpVsStatus {
  [key: string]: {
    Pending: number;
    Completed: number;
    Cancelled: number;
    "Under Progress": number;
  };
}
export interface EmpVsType {
  [key: string]: {
    Config: number;
    Survey: number;
    Installation: number;
    Service: number;
    Other: number;
    Delivery: number;
    Complaint: number;
  };
}

export interface OrderVsStatus {
  [key: string]: {
    cust_name: string;
    Pending: number;
    Completed: number;
    Cancelled: number;
    "Under Progress": number;
  };
}
export interface OrderVsType {
  [key: string]: {
    cust_name: string;
    Config: number;
    Survey: number;
    Installation: number;
    Service: number;
    Other: number;
    Delivery: number;
    Complaint: number;
  };
}
interface AnalyticData {
  counter: Counter;
  emp_vs_status: EmpVsStatus;
  emp_vs_type: EmpVsType;
  order_vs_type: OrderVsType;
  order_vs_status: OrderVsStatus;
}

interface GraphData {
  series: { name: string; data: number[] }[];
  xaxis: { categories: string[] };
}
interface OpsAdminViewProps {}

const OpsAdminView: FunctionComponent<OpsAdminViewProps> = () => {
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<AnalyticData>();
  const [graphData, setGraphData] = useState<GraphData>();
  const [filter, setFilter] = useState<number>(1);

  const calGraphData = (d: {
    [key: string]: { date: string; type: string; count: number }[];
  }) => {
    const t: GraphData = {
      series: [],
      xaxis: {
        categories: [],
      },
    };

    // Object.keys(d).forEach((k, i) => {
    //   const tmp: { name: string; data: number[] } = { name: k, data: [] };
    //   d[k].forEach((dt, i) => {
    //     tmp.data.push(dt.count);
    //     if (t.xaxis.categories.indexOf(dt.date) === -1) {
    //       t.xaxis.categories.push(dt.date);
    //     }
    //   });
    //   t.series.push(tmp);
    // });
    // setGraphData({ ...t });

    const v: { [key: string]: { [key: string]: number } } = {};
    Object.keys(d).forEach((k, i) => {
      // const tmp: { name: string; data: number[] } = { name: k, data: [] };

      d[k].forEach((dt, i) => {
        if (v[k]) v[k][dt.date] = dt.count;
        else {
          v[k] = {};
          v[k][dt.date] = dt.count;
        }
      });
      // t.series.push(tmp);
    });
    let keys: string[] = [];
    Object.keys(v).forEach((k) => {
      keys = [...keys, ...Object.keys(v[k])];
    });
    const dates = new Set(keys.sort());
    Object.keys(v).forEach((k, i) => {
      const tmp: { name: string; data: number[] } = { name: k, data: [] };
      dates.forEach((date) => {
        if (v[k][date]) {
          tmp.data.push(v[k][date]);
        } else tmp.data.push(0);
      });
      t.series.push(tmp);
    });
    t.xaxis.categories = [...dates];
    console.log(t);
    setGraphData({ ...t });
  };
  const getData = async () => {
    setLoading(true);
    const res = await GetTaskAnalyticsRepo(filter);
    console.log(res);
    if (res.success) {
      setData(res.data);
      calGraphData(res.data.task_graph_data);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, [filter]);
  return (
    <div className="bg-gray-200 p-3">
      <div className="flex justify-end">
        <select
          value={filter}
          onChange={(e) => {
            const t = parseInt(e.target.value ?? "1");
            console.log(t);

            setFilter(t);
          }}
          name=""
          id=""
          className="border rounded-lg p-2 text-sm outline-none"
        >
          {[
            "Current Month",
            "Last Month",
            "Last Three Month",
            "Last Six Months",
            "Current Year",
            "All",
          ].map((f, i) => (
            <option value={i + 1}>{f}</option>
          ))}
        </select>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4  mt-3  ">
        <div className="border rounded-lg py-4 px-4 bg-white flex justify-between">
          <div className="">
            <p className="text-sm text-gray-500">All Tasks</p>
            <p className="font-bold text-2xl mt-3">
              {data?.counter.total ?? 0}
            </p>
          </div>
          <AlignJustify size={20} className="text-purple-500" />
        </div>
        <div className="border rounded-lg py-4 px-4 bg-white flex justify-between">
          <div className="">
            <p className="text-sm text-gray-500">Completed Tasks</p>
            <p className="font-bold text-2xl mt-3">
              {data?.counter.Completed ?? 0}
            </p>
          </div>
          <Check size={20} className="text-green-500" />
        </div>
        <div className="border rounded-lg py-4 px-4 bg-white flex justify-between">
          <div className="">
            <p className="text-sm text-gray-500">Pending Tasks</p>
            <p className="font-bold text-2xl mt-3">
              {data?.counter.Pending ?? 0}
            </p>
          </div>
          <AlertTriangle size={20} className="text-white" fill="red" />
        </div>
        <div className="border rounded-lg py-4 px-4 bg-white flex justify-between">
          <div className="">
            <p className="text-sm text-gray-500">Under progress Tasks</p>
            <p className="font-bold text-2xl mt-3">
              {data?.counter["Under Progress"] ?? 0}
            </p>
          </div>
          <Anchor size={20} className="text-yellow-500" />
        </div>
        <div className="border rounded-lg py-4 px-4 bg-white flex justify-between">
          <div className="">
            <p className="text-sm text-gray-500">Cancelled Tasks</p>
            <p className="font-bold text-2xl mt-3">
              {data?.counter.Cancelled ?? 0}
            </p>
          </div>
          <XSquare size={20} className="text-gray-500" />
        </div>
      </div>
      <div className="grid grid-flow-col-1 lg:grid-cols-2 mt-6 gap-3">
        <div className=" ">
          <h3 className="font-semibold text-gray-500">Engineer - Task type</h3>
          <div className="border rounded-md bg-white mt-2  h-72  overflow-auto ">
            <table className=" w-full  text-sm ">
              <thead className=" border-b    ">
                <tr className="text-xs sticky top-0 bg-blueTheme  text-white  whitespace-nowrap">
                  <th></th>
                  <th className="px-3 py-2">Name</th>
                  <th>Install</th>
                  <th>Delivery</th>
                  <th>Service</th>
                  <th>Config</th>
                  <th>Survey</th>
                  <th>Complaint</th>
                  <th>Other</th>
                  <th>All</th>
                </tr>
              </thead>
              <tbody>
                {data?.emp_vs_type &&
                  Object.keys(data?.emp_vs_type).map((k, i) => {
                    const installation = data.emp_vs_type[k].Installation ?? 0;
                    const delivery = data.emp_vs_type[k].Delivery ?? 0;
                    const service = data.emp_vs_type[k].Service ?? 0;
                    const config = data.emp_vs_type[k].Config ?? 0;
                    const survey = data.emp_vs_type[k].Survey ?? 0;
                    const complaint = data.emp_vs_type[k].Complaint ?? 0;
                    const other = data.emp_vs_type[k].Other ?? 0;
                    const all =
                      installation +
                      delivery +
                      service +
                      config +
                      survey +
                      complaint +
                      other;
                    return (
                      <tr
                        className={` ${
                          i % 2 === 0 ? " bg-gray-100" : "text-gray-500"
                        } border-b text-xs whitespace-nowrap`}
                        key={i}
                      >
                        <td className="px-3 py-2" align="center">
                          {i + 1}
                        </td>
                        <td
                          className="px-3 py-2 max-w-xs whitespace-nowrap overflow-ellipsis overflow-hidden"
                          align="center"
                        >
                          {k}
                        </td>
                        <td className="px-3 py-2" align="center">
                          {installation}
                        </td>
                        <td className="px-3 py-2" align="center">
                          {delivery}
                        </td>
                        <td className="px-3 py-2" align="center">
                          {service}
                        </td>
                        <td className="px-3 py-2" align="center">
                          {config}
                        </td>
                        <td className="px-3 py-2" align="center">
                          {survey}
                        </td>
                        <td className="px-3 py-2" align="center">
                          {complaint}
                        </td>
                        <td className="px-3 py-2" align="center">
                          {other}
                        </td>
                        <td className="px-3 py-2" align="center">
                          {all}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <h3 className="font-semibold text-gray-500">
            Engineer - Task status{" "}
          </h3>
          <div className="border rounded-md bg-white mt-2 h-72  overflow-auto">
            <table className=" w-full  text-sm  ">
              <thead className=" border-b    ">
                <tr className="text-xs sticky top-0 bg-blueTheme  text-white  whitespace-nowrap">
                  <th></th>
                  <th className="px-3 py-2">Name</th>
                  <th>Pending</th>
                  <th>Under progress</th>
                  <th>Completed</th>
                  <th>Cancelled</th>
                  <th>All</th>
                </tr>
              </thead>
              <tbody>
                {data?.emp_vs_status &&
                  Object.keys(data?.emp_vs_status).map((k, i) => {
                    const pending = data.emp_vs_status[k].Pending ?? 0;
                    const under_progress =
                      data.emp_vs_status[k]["Under Progress"] ?? 0;
                    const completed = data.emp_vs_status[k].Completed ?? 0;
                    const cancelled = data.emp_vs_status[k].Cancelled ?? 0;

                    const all =
                      pending + under_progress + completed + cancelled;
                    return (
                      <tr
                        className={` ${
                          i % 2 === 0 ? " bg-gray-100" : "text-gray-500"
                        } border-b text-xs whitespace-nowrap`}
                        key={i}
                      >
                        <td className="px-3 py-2" align="center">
                          {i + 1}
                        </td>
                        <td
                          className="px-3 py-2 max-w-xs whitespace-nowrap overflow-ellipsis overflow-hidden"
                          align="center"
                        >
                          {k}
                        </td>
                        <td className="px-3 py-2" align="center">
                          {pending}
                        </td>
                        <td className="px-3 py-2" align="center">
                          {under_progress}
                        </td>
                        <td className="px-3 py-2" align="center">
                          {completed}
                        </td>
                        <td className="px-3 py-2" align="center">
                          {cancelled}
                        </td>
                        <td className="px-3 py-2" align="center">
                          {all}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="grid grid-flow-col-1 gap-5 mt-12">
        <div className="">
          <h3 className="font-semibold text-gray-500 ">Order - Task type</h3>
          <div className="border rounded-md bg-white  mt-2  h-72  overflow-auto">
            <table className=" w-full  text-sm  ">
              <thead className=" border-b    ">
                <tr className="text-xs sticky top-0 bg-blueTheme  text-white  whitespace-nowrap">
                  <th></th>
                  <th className="px-3 py-2">Order ID</th>
                  <th className="px-3 py-2">Customer name</th>
                  <th>Installation</th>
                  <th>Delivery</th>
                  <th>Service</th>
                  <th>Configuration</th>
                  <th>Survey</th>
                  <th>Complaint</th>
                  <th>Other</th>
                  <th>All</th>
                </tr>
              </thead>
              <tbody>
                {data?.order_vs_type &&
                  Object.keys(data?.order_vs_type).map((k, i) => {
                    const installation =
                      data.order_vs_type[k].Installation ?? 0;
                    const delivery = data.order_vs_type[k].Delivery ?? 0;
                    const service = data.order_vs_type[k].Service ?? 0;
                    const config = data.order_vs_type[k].Config ?? 0;
                    const survey = data.order_vs_type[k].Survey ?? 0;
                    const complaint = data.order_vs_type[k].Complaint ?? 0;
                    const other = data.order_vs_type[k].Other ?? 0;
                    const all =
                      installation +
                      delivery +
                      service +
                      config +
                      survey +
                      complaint +
                      other;
                    return (
                      <tr
                        className={` ${
                          i % 2 === 0 ? " bg-gray-100" : "text-gray-500"
                        } border-b text-xs whitespace-nowrap`}
                        key={i}
                      >
                        <td className="px-3 py-2" align="center">
                          {i + 1}
                        </td>
                        <td className="px-3 py-2" align="center">
                          {k}
                        </td>
                        <td
                          className="px-3 py-2 max-w-xs whitespace-nowrap overflow-ellipsis overflow-hidden"
                          align="center"
                        >
                          {data.order_vs_type[k].cust_name ?? 0}
                        </td>
                        <td className="px-3 py-2" align="center">
                          {installation ?? 0}
                        </td>
                        <td className="px-3 py-2" align="center">
                          {delivery}
                        </td>
                        <td className="px-3 py-2" align="center">
                          {service}
                        </td>
                        <td className="px-3 py-2" align="center">
                          {config}
                        </td>
                        <td className="px-3 py-2" align="center">
                          {survey}
                        </td>
                        <td className="px-3 py-2" align="center">
                          {complaint}
                        </td>
                        <td className="px-3 py-2" align="center">
                          {other}
                        </td>
                        <td className="px-3 py-2" align="center">
                          {all}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="mt-12">
          <h3 className="ml-2 font-semibold text-gray-500">
            Order - Task status
          </h3>
          <div className="border  rounded-md bg-white mt-2  h-72  overflow-auto  ">
            <table className=" w-full  text-sm   ">
              <thead className=" border-b    ">
                <tr className="text-xs sticky top-0 bg-blueTheme  text-white  whitespace-nowrap">
                  <th></th>
                  <th className="px-3 py-2">Order ID</th>
                  <th className="px-3 py-2">Customer name</th>
                  <th>Pending</th>
                  <th>Under progress</th>
                  <th>Completed</th>
                  <th>Cancelled</th>
                  <th>All</th>
                </tr>
              </thead>
              <tbody>
                {data?.order_vs_status &&
                  Object.keys(data?.order_vs_status).map((k, i) => {
                    const pending = data.order_vs_status[k].Pending ?? 0;
                    const under_progress =
                      data.order_vs_status[k]["Under Progress"] ?? 0;
                    const completed = data.order_vs_status[k].Completed ?? 0;
                    const cancelled = data.order_vs_status[k].Cancelled ?? 0;

                    const all =
                      pending + under_progress + completed + cancelled;
                    return (
                      <tr
                        className={` ${
                          i % 2 === 0 ? " bg-gray-100" : "text-gray-500"
                        } border-b text-xs whitespace-nowrap`}
                        key={i}
                      >
                        <td className="px-3 py-2" align="center">
                          {i + 1}
                        </td>
                        <td className="px-3 py-2" align="center">
                          {k}
                        </td>
                        <td
                          className="px-3 py-2 max-w-xs whitespace-nowrap overflow-ellipsis overflow-hidden"
                          align="center"
                        >
                          {data.order_vs_status[k].cust_name ?? "--"}
                        </td>
                        <td className="px-3 py-2" align="center">
                          {pending}
                        </td>
                        <td className="px-3 py-2" align="center">
                          {under_progress}
                        </td>
                        <td className="px-3 py-2" align="center">
                          {completed}
                        </td>
                        <td className="px-3 py-2" align="center">
                          {cancelled}
                        </td>
                        <td className="px-3 py-2" align="center">
                          {all}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className=" ">
        {!loading && graphData && (
          <AdminTaskGraphWidget
            xaxis={graphData?.xaxis}
            series={graphData?.series}
          />
        )}
      </div>

      <LoadingWidget loading={loading} />
    </div>
  );
};

export default OpsAdminView;
