import { BaseModel } from "../../../../../service/models/base";

export const JobApplicationStatus: { [key: string]: string } = {
  submitted: "Submitted",
  short_listed: "Short listed",
  selected: "Selected",
  rejected: "Rejected",
  hold: "Hold",
};

export const JobType: {
  [key: string]: string;
} = {
  fullTime: "Full-time",
  intern: "Intern",
};

export const Experience: {
  [key: string]: string;
} = {
  zero: "0 year",
  moreThanSixMonths: ">6 months",
  moreThanOneYear: ">1 year",
  moreThanTwoYear: ">2 years",
  moreThanThreeYear: ">3 years",
  moreThanFourYear: ">4 years",
  moreThanFiveYearPlus: "5+ years",
};

export interface JobDetail extends BaseModel {
  active?: boolean;
  title: string;
  department: string;
  job_type: string;
  experience?: string;
  position?: number;
  location: string;
  description?: string;
  applications?: {
    count: number;
  };
  otherLinks?: string[];
}

export interface JobApplication extends BaseModel {
  jd_id: string | null;
  ref?: {
    uid?: string;
    ref_code: string;
    email?: string;
  };
  name: string;
  email: string;
  mobile: string;
  department?: string;
  experience?: string;
  linkedin?: string;
  file: string;
  status: string;
}
