import { Radio, Select, TextInput } from ".";
import DateTimePicker from "./date_time_picker";
import { FormEvent, FunctionComponent, ReactElement, useState } from "react";
import ZipInput from "./zip_field";
import EmailInput from "./email_field";
import NumberField from "./number_field";
import DatePicker from "./date_picker";
import { formToObject } from "../../../../infrastructure/utils/formDataToJson";
export const InputFields = {
  NUMBER: "number",
  TEXT: "text",
  EMAIL: "email",
  MOBILE: "text",
  ZIP: "text",
  RADIO: "radio",
  SINGLE_SELECT: "select-one",
  SINGLE_MULTI: "select-multi",
  DATETIME_LOCAL: "datetime-local",

  DATE: "date",
};
export interface FormFieldModel {
  name: string;
  label: string;
  inputType: string;
  placeholder?: string;
  alignment?: string;
  disabled?: boolean;
  required?: boolean;
  options?: { label: string; value: any }[];
}

export const GetFormField = ({
  field,
  formInputChange,
  defaultValue,
  value,
}: {
  field: FormFieldModel;
  value: any;
  defaultValue: any;
  formInputChange?: (e: number | string) => void;
}) => {
  switch (field.inputType) {
    case InputFields.TEXT:
      return (
        <div key={field.name}>
          <TextInput
            alignment={field.alignment}
            disabled={field.disabled}
            value={value}
            defaultValue={defaultValue}
            name={field.name}
            label={field.label}
            placeholder={field.placeholder}
            inputChange={formInputChange}
            required={field.required}
          />
        </div>
      );
    case InputFields.NUMBER:
      return (
        <div key={field.name}>
          <NumberField
            alignment={field.alignment}
            disabled={field.disabled}
            value={value}
            defaultValue={defaultValue}
            name={field.name}
            label={field.label}
            placeholder={field.placeholder}
            inputChange={formInputChange}
            required={field.required}
          />
        </div>
      );
    case InputFields.EMAIL:
      return (
        <div key={field.name}>
          <EmailInput
            alignment={field.alignment}
            disabled={field.disabled}
            value={value}
            defaultValue={defaultValue}
            name={field.name}
            label={field.label}
            placeholder={field.placeholder}
            inputChange={formInputChange}
            required={field.required}
          />
        </div>
      );
    case InputFields.ZIP:
      return (
        <div key={field.name}>
          <ZipInput
            alignment={field.alignment}
            disabled={field.disabled}
            value={value}
            defaultValue={defaultValue}
            name={field.name}
            label={field.label}
            placeholder={field.placeholder}
            inputChange={formInputChange}
            required={field.required}
          />
        </div>
      );
    case InputFields.SINGLE_SELECT:
      return (
        <div key={field.name}>
          <Select
            alignment={field.alignment}
            disabled={field.disabled}
            value={value}
            defaultValue={defaultValue}
            name={field.name}
            label={field.label}
            options={field.options!}
            placeholder={field.placeholder!}
            inputChange={formInputChange}
            required={field.required}
          />
        </div>
      );
    case InputFields.RADIO:
      return (
        <div key={field.name}>
          <Radio
            alignment={field.alignment}
            disabled={field.disabled}
            name={field.name}
            value={value}
            defaultValue={defaultValue}
            options={field.options!}
            label={field.label}
            inputChange={formInputChange}
            placeholder={field.placeholder!}
            required={field.required}
          />
        </div>
      );
    case InputFields.DATETIME_LOCAL:
      return (
        <div key={field.name}>
          <DateTimePicker
            alignment={field.alignment}
            disabled={field.disabled}
            name={field.name}
            value={value}
            defaultValue={defaultValue}
            label={field.label}
            inputChange={formInputChange}
            required={field.required}
          />
        </div>
      );
    case InputFields.DATE:
      return (
        <div key={field.name}>
          <DatePicker
            alignment={field.alignment}
            disabled={field.disabled}
            name={field.name}
            value={value}
            defaultValue={defaultValue}
            label={field.label}
            inputChange={formInputChange}
            required={field.required}
          />
        </div>
      );
    default:
      return;
  }
};

interface GenerateFormProps {
  formFields: FormFieldModel[];
  // schema: any;
  className?: string;
  values: { [key: string]: any };
  defaultValue: { [key: string]: any };
  onSubmit?: (data: { [key: string]: any }) => void;
  onFormValueChanged?: (e: any) => void;
  submitBtn?: ReactElement;
  resetBtn?: ReactElement;
}

const GenerateForm: FunctionComponent<GenerateFormProps> = (props) => {
  // const [FormValues, setFormValues] = useState<{ [key: string]: any }>(
  //   props.values
  // );
  // const [errors, setErrors] = useState<{ [key: string]: any }>({});
  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let form = new FormData(e.currentTarget);
    const data = formToObject(form);
    if (props.onSubmit) props.onSubmit(data);

    // try {
    //   e.preventDefault();
    //
    //   await props.schema.validate(FormValues, {
    //     abortEarly: false,
    //   });
    //   setErrors({});
    //
    // } catch (error: any) {
    //   if (error.inner) {
    //     setErrors(
    //       error.inner.reduce((acc: any, currentValue: any) => {
    //         acc[currentValue.path] = currentValue.message;
    //         return acc;
    //       }, {})
    //     );
    //   }
    // }
  };

  return (
    <form onSubmit={submit}>
      <div className={props.className ?? "gird grid-cols-2 gap-2 "}>
        {props.formFields.map((field) =>
          GetFormField({
            field,
            defaultValue: props.defaultValue[`${field.name}`],
            value: props.values[`${field.name}`],
            formInputChange: props.onFormValueChanged,
          })
        )}
      </div>
      <div className="flex justify-center my-5">
        {props.submitBtn ? (
          props.submitBtn
        ) : (
          <button
            type="submit"
            className="px-5 py-2 mx-2 bg-red-400 text-white rounded-3xl hover:bg-red-800"
          >
            Submit
          </button>
        )}
        {props.resetBtn ? (
          props.resetBtn
        ) : (
          <button
            type="reset"
            className="px-5 py-2 mx-2 border border-red-500 hover:text-white rounded-3xl hover:bg-red-800"
          >
            Reset
          </button>
        )}
      </div>
    </form>
  );
};

export default GenerateForm;
