import { XIcon } from "@heroicons/react/solid";
import moment from "moment";
import { FunctionComponent, useState } from "react";
import { UserModel } from "../../../../../context_providers/auth/user_model";
import ProductModel from "../../../../../service/models/product_model";

import {
  OrderStatus,
  OrderStatusLabel,
  OrderType,
  OrderTypeLabel,
  PaymentStatus,
  QcStatus,
  SubsidyStatus,
} from "../../../../../utils/enums";
import EmployeeSearchWidget from "../../../../new_components/emp_search_widget";

interface FilterWidgetProps {
  onClose: () => void;
  filter: {
    search?: string;
    fromDate?: string;
    toDate?: string;
    state?: string[];
    orderStatus?: string[];
    paymentStatus?: string[];
    saleExecutive?: string[];
    type?: string[];
    items?: string[];
    site_survey?: string[];
    wiring_status?: string[];
    subsidy_status?: string[];
  };
  onSubmit: (
    filter: {
      fromDate?: string;
      toDate?: string;
      state?: string[];
      orderStatus?: string[];
      paymentStatus?: string[];
      saleExecutive?: string[];
      type?: string[];
      items?: string[];
      site_survey?: string[];
      wiring_status?: string[];
      subsidy_status?: string[];
    },
    executives: { uid: string; name: string }[]
  ) => void;
  onReset: () => void;
  executives: { uid: string; name: string }[];
  states: { state_code: string; name: string; id: number }[];
  products: ProductModel[];
}

const FilterWidget: FunctionComponent<FilterWidgetProps> = (props) => {
  const [filter, setFilter] = useState<{
    search?: string;
    fromDate?: string;
    toDate?: string;
    state?: string[];
    orderStatus?: string[];
    paymentStatus?: string[];
    saleExecutive?: string[];
    type?: string[];
    items?: string[];
    site_survey?: string[];
    wiring_status?: string[];
    subsidy_status?: string[];
    qc_status?: string[];
  }>(props.filter);
  const [emps, setEmps] = useState<{ uid: string; name: string }[]>([
    ...props.executives,
  ]);

  // const orderState = useAppSelector((state) => state.OrderReducer);

  // const dispatch = useAppDispatch();

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let form = new FormData(e.currentTarget);

    let data: any = filter;
    data["page"] = 1;
    data["count"] = 100;
    // data["fromDate"] = form.get("fromDate");
    // data["toDate"] = form.get("toDate");
    // data["state"] = form.getAll("state");
    // data["orderStatus"] = form.getAll("orderStatus");
    // data["paymentStatus"] = form.getAll("paymentStatus");
    // data["saleExecutive"] = form.getAll("saleExecutive");
    // data["type"] = form.getAll("orderType");
    // data["items"] = form.getAll("items");
    // let i = 0;
    // for (let [key, value] of form) {

    //   data[key] = value;
    //   // if (value) i++;
    // }

    props.onSubmit(data, emps);
    // dispatch(FilterOrderThunk(data));
    // props.onClose();
  };
  return (
    <form onSubmit={submit}>
      {/* <div className="flex flex-wrap  gap-3 border rounded p-1  my-3"> */}
      <div className="grid grid-cols-1 md:grid-cols-2  gap-3  p-3 items-start">
        <div className="">
          <div className="">
            <label htmlFor="from-date" className="text-sm">
              From
            </label>
            <input
              id="from-date"
              className="border rounded   w-full h-8 text-sm"
              type="date"
              name="fromDate"
              placeholder="From"
              defaultValue={filter.fromDate}
              onChange={(e) =>
                setFilter({ ...filter, fromDate: e.target.value })
              }
            />
          </div>
          {filter.fromDate && (
            <div className="flex mt-2">
              <div className="text-xs font-semibold bg-gray-200 rounded-3xl pl-2 py-1 flex">
                {moment(filter.fromDate, "YYYY-MM-DD").format("DD-MM-YYYY")}
                <XIcon
                  className="h-4 w-4 hover:bg-gray-400 hover:text-white p-1 rounded-full mx-1"
                  onClick={() => setFilter({ ...filter, fromDate: "" })}
                />
              </div>
            </div>
          )}
        </div>{" "}
        <div className="">
          <div className="">
            <label htmlFor="from-date" className="text-sm">
              To
            </label>
            <input
              id="from-date"
              className="border rounded   w-full h-8 text-sm"
              type="date"
              name="fromDate"
              placeholder="From"
              defaultValue={filter.toDate}
              onChange={(e) => setFilter({ ...filter, toDate: e.target.value })}
            />
          </div>
          {filter.toDate && (
            <div className="flex mt-2">
              <div className="text-xs font-semibold bg-gray-200 rounded-3xl pl-2 py-1 flex">
                {moment(filter.toDate, "YYYY-MM-DD").format("DD-MM-YYYY")}
                <XIcon
                  className="h-4 w-4 hover:bg-gray-400 hover:text-white p-1 rounded-full mx-1"
                  onClick={() => setFilter({ ...filter, toDate: "" })}
                />
              </div>
            </div>
          )}
        </div>
        <div className="">
          <div className="">
            <label htmlFor="dropdown" className="text-sm">
              State
            </label>
            <select
              // multiple
              id="states"
              name="state"
              className="border rounded  w-full h-8 text-sm"
              aria-label="Default select example"
              onChange={(e) => {
                if (!filter.state?.includes(e.target.value))
                  setFilter({
                    ...filter,
                    state: [...(filter.state ?? []), e.target.value],
                  });
                e.target.value = "";
              }}
            >
              <option value="" hidden>
                Select...
              </option>
              {props.states.map(
                (d: { state_code: string; name: string }, i: number) => (
                  <option key={i} value={d.name}>
                    {d.name}
                  </option>
                )
              )}
            </select>
          </div>{" "}
          {filter.state && filter.state.length > 0 && (
            <div className="flex mt-2 items-center gap-1 flex-wrap  ">
              {filter.state.map((state, i) => (
                <div className="text-xs font-semibold bg-gray-200 rounded-3xl pl-2 py-1 flex">
                  <p className="w-14 truncate">{state}</p>
                  <XIcon
                    className="h-4 w-4 hover:bg-gray-400 hover:text-white p-1 rounded-full mx-1"
                    onClick={() =>
                      setFilter({
                        ...filter,
                        state: filter.state?.filter((val) => val !== state),
                      })
                    }
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="">
          <div className="">
            <div className="">
              <label htmlFor="order-type" className="">
                Order type
              </label>
              <select
                // multiple
                id="order-type"
                className="border rounded text-sm w-full h-8"
                aria-label="Default select example"
                name="type"
                // defaultValue={filter?.type}
                onChange={(e) => {
                  if (!filter.type?.includes(e.target.value))
                    setFilter({
                      ...filter,
                      type: [...(filter.type ?? []), e.target.value],
                    });
                  e.target.value = "";
                }}
              >
                <option value="" hidden>
                  Select...
                </option>
                {Object.values(OrderType).map((type: string, i: number) => (
                  <option key={i} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>
            {filter.type && filter.type.length > 0 && (
              <div className="flex mt-2 items-center gap-1 flex-wrap  ">
                {filter.type.map((type, i) => (
                  <div className="text-xs font-semibold bg-gray-200 rounded-3xl pl-2 py-1 flex">
                    <p className="w-14 truncate">{type}</p>
                    <XIcon
                      className="h-4 w-4 hover:bg-gray-400 hover:text-white p-1 rounded-full mx-1"
                      onClick={() =>
                        setFilter({
                          ...filter,
                          type: filter.type?.filter((val) => val !== type),
                        })
                      }
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="">
          <div className="">
            <label htmlFor="products" className="text-sm">
              Products
            </label>
            <select
              // multiple
              id="products"
              className="border rounded text-sm w-full h-8"
              aria-label="Default select example"
              name="items"
              // defaultValue={filter?.items}
              onChange={(e) => {
                if (!filter.items?.includes(e.target.value))
                  setFilter({
                    ...filter,
                    items: [...(filter.items ?? []), e.target.value],
                  });
                e.target.value = "";
              }}
            >
              <option value="" hidden>
                Select...
              </option>
              {props.products.map((prod: ProductModel, i: number) => (
                <option key={i} value={prod.id}>
                  {prod.name}
                </option>
              ))}
            </select>
          </div>
          {filter.items && filter.items.length > 0 && (
            <div className="flex mt-2 items-center gap-1 flex-wrap  ">
              {filter.items.map((item, i) => (
                <div className="text-xs font-semibold bg-gray-200 rounded-3xl pl-2 py-1 flex">
                  <p className="w-14 truncate">
                    {
                      props.products.find((pro) => pro.id === parseInt(item))
                        ?.name
                    }
                  </p>
                  <XIcon
                    className="h-4 w-4 hover:bg-gray-400 hover:text-white p-1 rounded-full mx-1"
                    onClick={() =>
                      setFilter({
                        ...filter,
                        items: filter.items?.filter((val) => val !== item),
                      })
                    }
                  />
                </div>
              ))}
            </div>
          )}
        </div>{" "}
        <div className="">
          <div className="">
            <label htmlFor="order-status" className="text-sm">
              Order status
            </label>
            <select
              // multiple
              id="order-status"
              name="orderStatus"
              className="border rounded text-sm w-full h-8"
              aria-label="Default select example"
              // defaultValue={filter?.orderStatus}
              onChange={(e) => {
                if (!filter.orderStatus?.includes(e.target.value))
                  setFilter({
                    ...filter,
                    orderStatus: [
                      ...(filter.orderStatus ?? []),
                      e.target.value,
                    ],
                  });
                e.target.value = "";
              }}
            >
              <option value="" hidden>
                Select...
              </option>
              {Object.values(OrderStatus).map((status: string, i: number) => (
                <option key={i} value={status}>
                  {status}
                </option>
              ))}
            </select>
          </div>
          {filter.orderStatus && filter.orderStatus.length > 0 && (
            <div className="flex mt-2 items-center gap-1 flex-wrap  ">
              {filter.orderStatus.map((status, i) => (
                <div
                  className={`text-xs font-semibold bg-gray-200 rounded-3xl pl-2 py-1 flex ${
                    status === OrderStatus.CANCELLED
                      ? "bg-red-100 text-red-800"
                      : status === OrderStatus.INIT ||
                        status === OrderStatus.WANT_TO_CANCEL
                      ? "bg-yellow-50 text-yellow-400"
                      : "bg-green-100 text-green-800"
                  }`}
                >
                  <p className="w-14 truncate">{status}</p>
                  <XIcon
                    className="h-4 w-4 hover:bg-gray-400 hover:text-white p-1 rounded-full mx-1"
                    onClick={() =>
                      setFilter({
                        ...filter,
                        orderStatus: filter.orderStatus?.filter(
                          (val) => val !== status
                        ),
                      })
                    }
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="">
          <div className="">
            <label htmlFor="pay-status" className="text-sm">
              Payment status
            </label>
            <select
              // multiple
              id="pay-status"
              className="border rounded text-sm w-full h-8"
              aria-label="Default select example"
              name="paymentStatus"
              // defaultValue={filter?.paymentStatus}
              onChange={(e) => {
                if (!filter.paymentStatus?.includes(e.target.value))
                  setFilter({
                    ...filter,
                    paymentStatus: [
                      ...(filter.paymentStatus ?? []),
                      e.target.value,
                    ],
                  });
                e.target.value = "";
              }}
            >
              <option value="" hidden>
                Select...
              </option>
              {Object.values(PaymentStatus).map((status: string, i: number) => (
                <option key={i} value={status}>
                  {status}
                </option>
              ))}
            </select>
          </div>{" "}
          {filter.paymentStatus && filter.paymentStatus.length > 0 && (
            <div className="flex mt-2 items-center gap-1 flex-wrap  ">
              {filter.paymentStatus.map((payment_status, i) => (
                <div className="text-xs font-semibold bg-gray-200 rounded-3xl pl-2 py-1 flex">
                  <p className="w-14 truncate">{payment_status}</p>
                  <XIcon
                    className="h-4 w-4 hover:bg-gray-400 hover:text-white p-1 rounded-full mx-1"
                    onClick={() =>
                      setFilter({
                        ...filter,
                        paymentStatus: filter.paymentStatus?.filter(
                          (val) => val !== payment_status
                        ),
                      })
                    }
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="">
          <div className="">
            <label htmlFor="executive" className="text-sm">
              Sales executive
            </label>
            {/* <select
              id="executive"
              className="border rounded text-sm w-full h-8"
              aria-label="Default select example"
              name="saleExecutive"
              // defaultValue={filter?.saleExecutive}

              onChange={(e) => {
                if (!filter.saleExecutive?.includes(e.target.value))
                  setFilter({
                    ...filter,
                    saleExecutive: [
                      ...(filter.saleExecutive ?? []),
                      e.target.value,
                    ],
                  });
                e.target.value = "";
              }}
            >
              <option value="" hidden>
                Select...
              </option>
              {props.executives.map(
                (emp: { id: number; name: string }, i: number) => (
                  <option key={i} value={emp.id}>
                    {emp.name}
                  </option>
                )
              )}
            </select> */}

            <EmployeeSearchWidget
              withBtn={false}
              emp={{ uid: "", name: "" }}
              onSelect={function (data: UserModel): void {
                if (!(filter.saleExecutive || [])?.includes(data.uid!))
                  setFilter({
                    ...filter,
                    saleExecutive: [...(filter.saleExecutive ?? []), data.uid],
                  });

                setEmps((o) => [
                  ...o,
                  {
                    uid: data.uid,
                    name: data.first_name + " " + data.last_name,
                  },
                ]);
              }}
            />
          </div>
          {filter.saleExecutive && filter.saleExecutive.length > 0 && (
            <div className="flex mt-2 items-center gap-1 flex-wrap  ">
              {filter.saleExecutive.map((item, i) => (
                <div className="text-xs font-semibold bg-gray-200 rounded-3xl pl-2 py-1 flex">
                  <p className="w-14 truncate">
                    {emps.find((emp) => emp.uid === item)?.name}
                  </p>
                  <XIcon
                    className="h-4 w-4 hover:bg-gray-400 hover:text-white p-1 rounded-full mx-1"
                    onClick={() =>
                      setFilter({
                        ...filter,
                        saleExecutive: filter.saleExecutive?.filter(
                          (val) => val !== item
                        ),
                      })
                    }
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="">
          <div className="">
            <div className="">
              <label htmlFor="site-survey" className="">
                Site Survey
              </label>
              <select
                // multiple
                id="site-survey"
                className="border rounded text-sm w-full h-8"
                aria-label="Default select example"
                name="site-survey"
                // defaultValue={filter?.type}
                onChange={(e) => {
                  if (!filter.site_survey?.includes(e.target.value))
                    setFilter({
                      ...filter,
                      site_survey: [
                        ...(filter.site_survey ?? []),
                        e.target.value,
                      ],
                    });
                  e.target.value = "";
                }}
              >
                <option value="" hidden>
                  Select...
                </option>
                {["Pending", "Done", "WIP"].map(
                  (site_survey: string, i: number) => (
                    <option key={i} value={site_survey}>
                      {site_survey}
                    </option>
                  )
                )}
              </select>
            </div>
            {filter.site_survey && filter.site_survey.length > 0 && (
              <div className="flex mt-2 items-center gap-1 flex-wrap  ">
                {filter.site_survey.map((site_survey, i) => (
                  <div className="text-xs font-semibold bg-gray-200 rounded-3xl pl-2 py-1 flex">
                    <p className="w-14 truncate">{site_survey}</p>
                    <XIcon
                      className="h-4 w-4 hover:bg-gray-400 hover:text-white p-1 rounded-full mx-1"
                      onClick={() =>
                        setFilter({
                          ...filter,
                          site_survey: filter.site_survey?.filter(
                            (val) => val !== site_survey
                          ),
                        })
                      }
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="">
          <div className="">
            <div className="">
              <label htmlFor="wiring-status" className="">
                Wiring status
              </label>
              <select
                // multiple
                id="wiring-status"
                className="border rounded text-sm w-full h-8"
                aria-label="Default select example"
                name="wiring-status"
                // defaultValue={filter?.type}
                onChange={(e) => {
                  if (!filter.wiring_status?.includes(e.target.value))
                    setFilter({
                      ...filter,
                      wiring_status: [
                        ...(filter.wiring_status ?? []),
                        e.target.value,
                      ],
                    });
                  e.target.value = "";
                }}
              >
                <option value="" hidden>
                  Select...
                </option>
                {["Pending", "Done", "WIP"].map(
                  (wiring_status: string, i: number) => (
                    <option key={i} value={wiring_status}>
                      {wiring_status}
                    </option>
                  )
                )}
              </select>
            </div>
            {filter.wiring_status && filter.wiring_status.length > 0 && (
              <div className="flex mt-2 items-center gap-1 flex-wrap  ">
                {filter.wiring_status.map((site_survey, i) => (
                  <div className="text-xs font-semibold bg-gray-200 rounded-3xl pl-2 py-1 flex">
                    <p className="w-14 truncate">{site_survey}</p>
                    <XIcon
                      className="h-4 w-4 hover:bg-gray-400 hover:text-white p-1 rounded-full mx-1"
                      onClick={() =>
                        setFilter({
                          ...filter,
                          wiring_status: filter.wiring_status?.filter(
                            (val) => val !== site_survey
                          ),
                        })
                      }
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="">
          <div className="">
            <label htmlFor="wiring-status" className="">
              Subsidy status
            </label>
            <select
              // multiple
              id="wiring-status"
              className="border rounded text-sm w-full h-8"
              aria-label="Default select example"
              name="wiring-status"
              // defaultValue={filter?.type}
              onChange={(e) => {
                if (!filter.subsidy_status?.includes(e.target.value))
                  setFilter({
                    ...filter,
                    subsidy_status: [
                      ...(filter.subsidy_status ?? []),
                      e.target.value,
                    ],
                  });
                e.target.value = "";
              }}
            >
              <option value="" hidden>
                Select...
              </option>
              {Object.values(SubsidyStatus).map(
                (subsidy_status: string, i: number) => (
                  <option key={i} value={subsidy_status}>
                    {subsidy_status}
                  </option>
                )
              )}
            </select>
          </div>
          {filter.subsidy_status && filter.subsidy_status.length > 0 && (
            <div className="flex mt-2 items-center gap-1 flex-wrap  ">
              {filter.subsidy_status.map((site_survey, i) => (
                <div className="text-xs font-semibold bg-gray-200 rounded-3xl pl-2 py-1 flex">
                  <p className="w-14 truncate">{site_survey}</p>
                  <XIcon
                    className="h-4 w-4 hover:bg-gray-400 hover:text-white p-1 rounded-full mx-1"
                    onClick={() =>
                      setFilter({
                        ...filter,
                        subsidy_status: filter.subsidy_status?.filter(
                          (val) => val !== site_survey
                        ),
                      })
                    }
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="">
          <div className="">
            <label htmlFor="qc-status" className="">
              Quality check status
            </label>
            <select
              // multiple
              id="qc-status"
              className="border rounded text-sm w-full h-8"
              aria-label="Default select example"
              name="qc-status"
              // defaultValue={filter?.type}
              onChange={(e) => {
                if (!filter.qc_status?.includes(e.target.value))
                  setFilter({
                    ...filter,
                    qc_status: [...(filter.qc_status ?? []), e.target.value],
                  });
                e.target.value = "";
              }}
            >
              <option value="" hidden>
                Select...
              </option>
              {Object.values(QcStatus).map(
                (subsidy_status: string, i: number) => (
                  <option key={i} value={subsidy_status}>
                    {subsidy_status}
                  </option>
                )
              )}
            </select>
          </div>
          {filter.qc_status && filter.qc_status.length > 0 && (
            <div className="flex mt-2 items-center gap-1 flex-wrap  ">
              {filter.qc_status.map((status, i) => (
                <div className="text-xs font-semibold bg-gray-200 rounded-3xl pl-2 py-1 flex">
                  <p className="w-14 truncate">{status}</p>
                  <XIcon
                    className="h-4 w-4 hover:bg-gray-400 hover:text-white p-1 rounded-full mx-1"
                    onClick={() =>
                      setFilter({
                        ...filter,
                        qc_status: filter.qc_status?.filter(
                          (val) => val !== status
                        ),
                      })
                    }
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="flex gap-2 justify-center">
        <button
          type="reset"
          className="px-2 py-1 rounded bg-gray-400 text-white hover:bg-gray-800 "
        >
          Reset
        </button>
        <button
          type="submit"
          className="px-2 py-1 rounded bg-green-400 text-white hover:bg-green-800 "
        >
          Apply
        </button>
        {/* <StadiumButton onTap={() => {} }c>Reset</StadiumButton>
        <StadiumButton onTap={() => {}}>Filter</StadiumButton> */}
      </div>
      {/* <div className="grid grid-cols-1 md:grid-cols-2  gap-3 items-end p-3">
        <div className="">
          <label htmlFor="from-date">From</label>
          <input
            id="from-date"
            className="border rounded p-2 w-full"
            type="date"
            name="fromDate"
            placeholder="From"
            defaultValue={props.filter?.fromDate}
          />
        </div>
        <div className="">
          <label htmlFor="to-date">To</label>
          <input
            id="to-date"
            className="border rounded p-2 w-full"
            type="date"
            name="toDate"
            placeholder="From"
            defaultValue={props.filter?.toDate}
          />
        </div>

        <div className="states">
          <label htmlFor="dropdown" className="">
            State
          </label>
          <select
            multiple
            id="states"
            name="state"
            className="border rounded p-2 w-full"
            aria-label="Default select example"
            defaultValue={props.filter?.state}
          >
            <option value="" hidden>
              Select...
            </option>
            {props.states.map(
              (d: { state_code: string; name: string }, i: number) => (
                <option key={i} value={d.name}>
                  {d.name}
                </option>
              )
            )}
          </select>
        </div>
        <div className="">
          <label htmlFor="order-status" className="">
            Order status
          </label>
          <select
            multiple
            id="order-status"
            name="orderStatus"
            className="border rounded p-2 w-full"
            aria-label="Default select example"
            defaultValue={props.filter?.orderStatus}
          >
            <option value="" hidden>
              Select...
            </option>
            {Object.values(OrderStatus).map((status: number, i: number) => (
              <option key={i} value={status}>
                {OrderStatusLabel[status]}
              </option>
            ))}
          </select>
        </div>
        <div className="">
          <label htmlFor="pay-status" className="">
            Payment status
          </label>
          <select
            multiple
            id="pay-status"
            className="border rounded p-2 w-full"
            aria-label="Default select example"
            name="paymentStatus"
            defaultValue={props.filter?.paymentStatus}
          >
            <option value="" hidden>
              Select...
            </option>
            {Object.values(PaymentStatus).map((status: number, i: number) => (
              <option key={i} value={status}>
                {PaymentStatusLabel[status]}
              </option>
            ))}
          </select>
        </div>
        <div className="">
          <label htmlFor="order-type" className="">
            Order type
          </label>
          <select
            multiple
            id="order-type"
            className="border rounded p-2 w-full"
            aria-label="Default select example"
            name="orderType"
            defaultValue={props.filter?.type}
          >
            <option value="" hidden>
              Select...
            </option>
            {Object.values(OrderType).map((type: number, i: number) => (
              <option key={i} value={type}>
                {OrderTypeLabel[type]}
              </option>
            ))}
          </select>
        </div>
        <div className="">
          <label htmlFor="executive" className="">
            Sales executive
          </label>
          <select
            id="executive"
            className="border rounded p-2 w-full"
            aria-label="Default select example"
            name="saleExecutive"
            defaultValue={props.filter?.saleExecutive}
          >
            <option value="" hidden>
              Select...
            </option>
            {props.executives.map(
              (emp: { id: number; name: string }, i: number) => (
                <option key={i} value={emp.id}>
                  {emp.name}
                </option>
              )
            )}
          </select>
        </div>
        <div className="">
          <label htmlFor="products" className="">
            Products
          </label>
          <select
            multiple
            id="products"
            className="border rounded p-2 w-full"
            aria-label="Default select example"
            name="items"
            defaultValue={props.filter?.items}
          >
            <option value="" hidden>
              Select...
            </option>
            {props.products.map((prod: ProductModel, i: number) => (
              <option key={i} value={prod.id}>
                {prod.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="flex justify-center">
        <button
          onClick={() => {
            // if (props.filter) dispatch(ResetOrderThunk());
            props.onReset();
          }}
          className="px-3 py-1 mx-3 bg-gray-200 rounded text-white hover:bg-red-800"
          type="reset"
        >
          Reset
        </button>
        <button
          className="px-3 py-1 mx-3 bg-red-500 rounded text-white hover:bg-red-800"
          type="submit"
        >
          Apply
        </button>
      </div> */}
    </form>
  );
};

export default FilterWidget;
