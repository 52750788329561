import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Typography,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../../../context_providers/auth/authProvider";
import { ACTION, MODULE } from "../../../../../utils/enums";
import { urls } from "../../../../../utils/urls";
import { URLS } from "../../infrastructure/utils/urls";
export default function ImageCard(props) {
  const { isAuthorised } = useAuth();
  const history = useHistory();
  const handleClick = () => {
    history.push({
      pathname: urls.installationEdit,
      state: props.item,
    });
  };
  return (
    <div className="w-100 h-auto  rounded shadow px-3 py-2">
      <img
        src={props.item.image_link}
        alt={props.item.name}
        className="h-auto w-100 cursor-pointer"
        onClick={handleClick}
      />
      <div className="text-sm font-bold my-2">{props.item.title}</div>
      <div className="text-sm font-bold my-2">{props.item.address}</div>

      <div className="flex justify-between">
        <div className="">
          <div class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
            <input
              type="checkbox"
              onClick={() => props.onToggleActive(props.index)}
              name="toggle"
              id="toggle"
              className={`toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer ${
                props.item.active ? "right-0 border-green-400" : ""
              }`}
            />
            <label
              for="toggle"
              class={`toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer ${
                props.item.active ? "bg-green-300" : ""
              }`}
            ></label>{" "}
          </div>
          {props.item.active ? "Live" : "offline"}
        </div>
        {isAuthorised({ module: MODULE.CONTENT, action: [ACTION.DELETE] }) && (
          <Button
            onClick={() => {
              props.onDelete(props.index);
            }}
            size="small"
            variant="contained"
            color="primary"
            startIcon={<Delete />}
          >
            Delete
          </Button>
        )}{" "}
      </div>
    </div>
  );
}
