import debounce from "lodash.debounce";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useModal } from "../../../context_providers/modal/modal_context";
import {
  ToastType,
  useToast,
} from "../../../context_providers/toast/toast_ctx";
import { fetchGet, toQueryString } from "../../../service/api_client";
import AutoCompleteField from "../../../ui/new_components/common/autocomplete";

import SearchableField from "../../../ui/new_components/common/search_with_suggestion/search_with_suggestion_field";
import ModalDialog from "../../../ui/pages/content_panel/ui/new_components/common/modal";
import { ItemGroupModel } from "../models/item_group_model";
import ItemGroupCreateScreen from "./item_group_create_screen";

interface ItemGroupSearchFieldProps {
  onSelect: (data: ItemGroupModel) => void;
  disabled?: boolean;
  placeholder?: string;
  value?: ItemGroupModel;
}

const ItemGroupSearchField: FunctionComponent<ItemGroupSearchFieldProps> = (
  props
) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/groups`;
  const { showToast } = useToast();
  // const { setLoading } = useModal();
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [addNew, setAddNew] = useState(false);
  const [data, setData] = useState<ItemGroupModel[]>();
  const getData = async (prop: {
    search?: string;
    page: number;
    count: number;
    all?: boolean;
  }) => {
    setLoading(true);
    try {
      const res = await fetchGet(url + toQueryString(prop));
      if (res.success) {
        setData(res.data.data);
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
      //   return { success: false, error: error.message };
    }
    setLoading(false);
  };

  const debouncedHandler = useCallback(
    debounce((query) => {
      if (query) getData({ search: query, page: 1, count: 1, all: true });
      else setData(undefined);
    }, 500),
    []
  );
  useEffect(() => {
    setSearch(props.value?.name ?? "");
  }, [props.value]);
  useEffect(() => {
    if (search && edit) {
      if (props.value && props.value.name === search) return;
      debouncedHandler(search);
    }
  }, [search]);

  return (
    <div className="flex gap-1 items-center    w-full">
      {edit ? (
        <AutoCompleteField
          onAddNew={() => {
            setAddNew(true);
          }}
          loading={loading}
          value={search}
          placeholder={props.placeholder ?? "Select "}
          onSelect={(val) => {
            setEdit(false);
            setSearch(val.value.name);
            props.onSelect(val.value);
          }}
          suggestions={data?.map((d) => ({
            label: (
              <div className="text-xs">
                <b> {d.name} </b>
              </div>
            ),

            value: d,
          }))}
          onChange={setSearch}
        />
      ) : (
        <div
          className={`text-sm px-3 py-2  h-8 rounded focus:outline-none w-full border ${
            search ? "" : " text-gray-400 "
          }`}
          onClick={() => {
            if (!props.disabled) {
              setEdit(true);
              // setSearch(emp?.name ?? "");
            }
          }}
        >
          {search || props.placeholder || "Search"}
        </div>
      )}
      {addNew && (
        <ModalDialog
          onClose={() => {
            setAddNew(false);
          }}
          show={addNew}
          title={"Add Item Group"}
        >
          <ItemGroupCreateScreen
            onClose={(d) => {
              if (d) {
                setAddNew(false);
                setEdit(false);
                setSearch(d.name);
                props.onSelect(d);
              }
            }}
          />
        </ModalDialog>
      )}
    </div>
  );
};

export default ItemGroupSearchField;
