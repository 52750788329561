import axios from "axios";
import {
  fetchGet,
  fetchPost,
  fetchPut,
  getHeaders,
  toQueryString,
} from "../api_client";
import { TransactionDataModel } from "../models/trannsaction_model";

export const GetCustomerTxnBalApi = async (user_id: any) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/users/${user_id}/txn-balance`;
    const res = await fetchGet(url);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const CreateTrasactionRepo = async (data: FormData) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/transaction`;
    const res = await fetchPost(url, data);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const CheckTxnIdRepo = async (txn_id: string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/transaction/exist`;
    const res = await fetchPost(url, { txn_id });
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const GetTxnsApi = async (option: {
  count?: number;
  page?: number;
  all?: boolean;
  search?: string;
  fromDate?: Date;
  toDate?: Date;
  status?: string;
  type?: string;
}) => {
  let url = `${
    process.env.REACT_APP_BACKEND_BASE_URL
  }/api/v3/transaction${toQueryString(option)}`;

  try {
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GetTxnApi = async (id: string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/transaction/${id}`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const UpdateTrasactionStatusRepo = async (data: {
  id: string;
  status: string;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/transaction`;
    const res = await fetchPut(url, data);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const UploadTrasactionAttachmentRepo = async (data: FormData) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/transaction/attachment/upload`;
    const res = await fetchPost(url, data);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GetTrasactionAttachmentRepo = async (id: string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/transaction/attachment?txn_id=${id}`;
    const res = await fetchGet(url);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
