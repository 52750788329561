import { FunctionComponent, useEffect, useState } from "react";
import { X } from "react-feather";

interface AnniCardProps {
  data: any;
  closeAnniModal: () => void;
}

const AnniCard: FunctionComponent<AnniCardProps> = ({
  data,
  closeAnniModal,
}) => {
  return (
    <div className="w-screen h-screen fixed top-0 left-0 flex flex-col items-center justify-center backdrop-filter backdrop-blur-sm">
      <div className="wishcard w-4/5 lg:w-auto ">
        <div className="">
          <div className="wishCardDesc flex flex-col items-center bg-black text-white px-4 lg:px-12 py-12 rounded-lg relative overflow-hidden">
            <div className="w-full mb-6 flex flex-col items-end">
              <X
                className="text-white cursor-pointer"
                size={32}
                onClick={() => closeAnniModal()}
              />
            </div>
            <div className="lg:text-xl leading-7 text-center tracking-wide">
              Congratulations on completing <br /> a wonderful year at
              <span className="text-red-600 font-bold text-2xl"> Kazam </span>
            </div>
            <div
              className={`grid lg:grid-cols-${data.length || 1} gap-x-6 pt-16`}
            >
              {data.length > 0 &&
                data.map((d: any, i: number) => {
                  return (
                    <div className="flex flex-col items-center" key={i}>
                      <div className="w-28 h-28 rounded-full">
                        <img
                          src={d.profile_image}
                          alt=""
                          className="w-full h-full rounded-full"
                        />
                      </div>
                      <div className="text-2xl lg:text-3xl font-bold mt-8 mb-1 text-white text-opacity-70">
                        {d.first_name + " " + d.last_name}
                      </div>
                      <div className="text-sm text-gray-500">
                        {d.designation}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnniCard;
