import { FunctionComponent } from "react";

interface NoDataProps {
  align: string;
  text: string;
  imageSize: string;
}

const NoData: FunctionComponent<NoDataProps> = ({ align, text, imageSize }) => {
  return (
    <div
      className={` flex flex-col ${
        align === "center"
          ? "items-center"
          : align === "right"
          ? "items-end"
          : ""
      }  pt-32`}
    >
      <div className="text-xl font-semibold text-gray-400">{text}</div>
      <div className="">
        <img
          src="/images/noData.png"
          alt=""
          className={` ${
            imageSize === "small"
              ? "w-32"
              : imageSize === "medium"
              ? "w-52"
              : "w-80"
          } `}
        />
      </div>
    </div>
  );
};

export default NoData;
