import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { urls } from "../../../../utils/urls";
import Sidebar from "../components/sidebar";
import Layout from "../layout";

interface PoliciesProps {}

const Policies: FunctionComponent<PoliciesProps> = () => {
  const docData = [
    {
      name: "Health Insurance Policies",
      // link: urls.hrm_gmc_policy,
      link: "/docs/health-policy.pdf",
      image: "/docs/health.png",
    },
    {
      name: "Team Handbook",
      link: "/docs/handbook.pdf",
      image: "/docs/handbook.png",
    },
    {
      name: "Public holiday list",
      link: "/docs/publicHoliday.pdf",
      image: "/docs/publicHoliday.png",
    },
    {
      name: "Kazam Higher Education Funding Policy",
      link: "/docs/higher-education.pdf",
      image: "/docs/publicHoliday.png",
    },
    {
      name: "Prevention of Sexual Harassment (POSH) Policy at workspace",
      link: "/docs/posh-policy.pdf",
      image: "/docs/posh.png",
    },
    {
      name: "Prevention and detection of fraud",
      link: "/docs/anti-fraud-policy.pdf",
      image: "/docs/anti-fraud-policy.png",
    },
  ];

  return (
    <Layout pageTitle="Kazam policies">
      <div className="">
        <div className="lg:px-12 pb-12 pt-12">
          <div className="">
            <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-6 lg:gap-x-12 gap-y-5 lg:gap-y-12">
              {docData.map((item, i) => {
                if (item.name === "GMC Policies") {
                  return (
                    <Link to={item.link}>
                      <div className="bg-white rounded-xl flex flex-col items-center justify-center h-full py-12 px-4 lg:py-16 lg:px-12">
                        <div className="">
                          <img
                            src={item.image}
                            alt=""
                            className=" w-20 lg:w-32"
                          />
                        </div>
                        <div className="text-sm lg:text-xl font-bold dark:text-primaryColor mt-8">
                          {item.name}
                        </div>
                      </div>
                    </Link>
                  );
                } else {
                  return (
                    <a href={item.link} target="_blank" rel="noreferrer">
                      <div className="bg-white rounded-xl flex flex-col items-center justify-center h-full py-12 px-4 lg:py-20 lg:px-12">
                        <div className="">
                          <img
                            src={item.image}
                            alt=""
                            className=" w-20 lg:w-32"
                          />
                        </div>
                        <div className="text-sm lg:text-xl text-center font-bold dark:text-primaryColor mt-8">
                          {item.name}
                        </div>
                      </div>
                    </a>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Policies;
