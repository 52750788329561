import { FunctionComponent, useEffect, useState } from "react";
import {
  useToast,
  ToastType,
} from "../../../../context_providers/toast/toast_ctx";
import {
  InventoryImportData,
  InventoryModel,
} from "../../../../service/models/inventory/inventory_model";
import { GetInventoryListApi } from "../../../../service/repos/inventory_repo";
import { debounce } from "../../../../utils/debounce";
import AutoCompleteField from "../../../new_components/common/autocomplete";

interface InventoryDropDownProps {
  onSelect: (val: { device_id: string; name: string }) => void;
  selected: { device_id: string; name: string }[];
  disabled?: boolean;
  status?: string[];
  product_id?: any[];
}

const InventoryDropDown: FunctionComponent<InventoryDropDownProps> = ({
  selected,
  onSelect,
  disabled = false,
  status,
  product_id,
}) => {
  const { showToast } = useToast();
  const [selectedDeviceIds, setSelectedDeviceIds] = useState<
    { device_id: string; name: string }[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [inventories, setInventories] = useState<InventoryModel[]>([]);
  const [search, setSearch] = useState("");
  const searchInventory = async (search: string) => {
    setLoading(true);

    const res = await GetInventoryListApi({
      search,
      page: 0,
      count: 0,
      status,
      product_id,
    });

    if (res.success) {
      setInventories(res.data.inventories);
    } else {
      setInventories([]);
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  useEffect(() => {
    if (search) {
      debounce(() => searchInventory(search));
    } else setInventories([]);
  }, [search]);
  useEffect(() => {
    setSelectedDeviceIds(selected);
  }, [selected]);
  return (
    <>
      <div className="flex gap-1 flex-col items-start flex-wrap">
        <label htmlFor="device">Devices ({selectedDeviceIds.length})</label>
        <AutoCompleteField
          disabled={disabled}
          loading={loading}
          value={search}
          placeholder={"Device"}
          onSelect={(val) => {
            const notContain = selectedDeviceIds.reduce(
              (pv, v) => pv && v.device_id !== val.value.device_id,
              true
            );
            if (notContain) {
              onSelect({
                device_id: val.value.device_id,
                name: val.value.device.product_name,
              });
              setSelectedDeviceIds((o) => [
                ...o,
                {
                  device_id: val.value.device_id,
                  name: val.value.device.product_name,
                },
              ]);
              setSearch("");
            } else {
              showToast({
                type: ToastType.warning,
                text: `${val.value.device_id}(${val.value.device.product_name}) already selected`,
              });
            }
          }}
          suggestions={inventories.map((e) => ({
            label: (
              <div className="text-xs">
                <b> {e.device_id} </b>
                <span style={{ fontSize: "10px" }}>
                  ({e.device.product_name})
                </span>
              </div>
            ),

            value: e,
          }))}
          onChange={(str) => {
            setSearch(str);
          }}
        />
        <p className="text-xs text-blue-400">
          note : Please select device id from suggestions .
        </p>
      </div>
    </>
  );
};

export default InventoryDropDown;
