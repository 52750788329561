import { mdiCheck } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent } from "react";

interface ProgressBarProps {
  progress: number;
}

const ProgressBar: FunctionComponent<ProgressBarProps> = ({ progress }) => {
  return (
    <div
      className={`flex justify-center items-center  text-xs font-medium text-blue-100 text-center h-full p-0.5  transform duration-300  ${
        progress < 99 ? "rounded-l-full" : "rounded-full  "
      } ${
        progress > 0 && progress < 100
          ? "bg-gradient-to-r from-blue-400 via-purple-500 to-teal"
          : progress === 100
          ? "bg-green-400"
          : " "
      }    `}
      style={{ width: (progress < 5 ? 5 : progress) + "%" }}
    >
      {progress === 100 ? (
        <div className="flex items-center gap-1">
          <Icon path={mdiCheck} size={0.8} /> <p>Completed</p>
        </div>
      ) : (
        `${progress}%`
      )}
    </div>
  );
};

export default ProgressBar;
