import debounce from "lodash.debounce";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useModal } from "../../../context_providers/modal/modal_context";
import {
  ToastType,
  useToast,
} from "../../../context_providers/toast/toast_ctx";
import { fetchGet, toQueryString } from "../../../service/api_client";
import AutoCompleteField from "../../../ui/new_components/common/autocomplete";
import ModalDialog from "../../../ui/new_components/common/modal";
import { PartyModel } from "../models/party_model";
import PartyCreateScreen from "./parties_create";

interface PartySearchFieldProps {
  onSelect: (data: PartyModel) => void;
  disabled?: boolean;
  placeholder?: string;
  value?: PartyModel;
  show_add?: boolean;
}

const PartySearchField: FunctionComponent<PartySearchFieldProps> = (props) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/accounts/party`;
  const { showToast } = useToast();
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [addNew, setAddNew] = useState(false);
  const [suggestions, setSuggestions] = useState<
    {
      label: string;
      value: PartyModel;
    }[]
  >();
  const getData = async (prop: {
    search?: string;
    page: number;
    count: number;
    all?: boolean;
  }) => {
    setLoading(true);
    try {
      const res = await fetchGet(url + toQueryString(prop));
      if (res.success) {
        setSuggestions(
          res.data.data.map((data: PartyModel) => ({
            label: `${data.name}`,
            value: data,
          }))
        );
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
      //   return { success: false, error: error.message };
    }
    setLoading(false);
  };

  const debouncedHandler = useCallback(
    debounce((query) => {
      if (query) getData({ search: query, page: 1, count: 1, all: true });
      else setSuggestions(undefined);
    }, 500),
    []
  );

  useEffect(() => {
    if (search && edit) {
      if (props.value && props.value.name === search) return;
      debouncedHandler(search);
    }
  }, [search]);
  useEffect(() => {
    setSearch(props.value?.name ?? "");
  }, [props.value]);
  return (
    <>
      {edit ? (
        <AutoCompleteField
          loading={loading}
          disabled={props.disabled}
          value={search}
          onChange={setSearch}
          onAddNew={props.show_add ? () => setAddNew(true) : undefined}
          onSelect={(d) => {
            setSearch(d.value.name);
            setEdit(false);

            props.onSelect(d.value);
          }}
          placeholder={props.placeholder || "Search party"}
          suggestions={suggestions}
        />
      ) : (
        <input
          disabled={props.disabled}
          type="text"
          placeholder={props.placeholder || "Search party"}
          className="text-sm p-1   rounded focus:outline-none w-full border cursor-text"
          onClick={() => {
            if (!props.disabled) {
              setEdit(true);
            }
          }}
          value={props.value?.name}
        ></input>
      )}
      {addNew && (
        <ModalDialog
          onClose={() => {
            setAddNew(false);
          }}
          show={addNew}
          title={"Add party"}
        >
          <div className="overflow-auto" style={{ maxHeight: "80vh" }}>
            <PartyCreateScreen
              onClose={(d) => {
                if (d) {
                  setEdit(false);
                  setSearch(d.name);
                  props.onSelect(d);
                  setAddNew(false);
                }
              }}
            />
          </div>
        </ModalDialog>
      )}
    </>
  );
};

export default PartySearchField;
