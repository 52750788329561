import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { UserModel } from "../../../../../../context_providers/auth/user_model";

import {
  useToast,
  ToastType,
} from "../../../../../../context_providers/toast/toast_ctx";
import { toQueryString } from "../../../../../../service/api_client";
import AutoCompleteField from "../../../../../new_components/common/autocomplete";
import { fetchGet } from "../../../../content_panel/services/serviceClient";
import debounce from "lodash.debounce";
import ModalDialog from "../../../../../new_components/common/modal";
import AddCustomerWidget from "./customer_add_widget";

interface UserSearchFieldProps {
  onSelect: (data: UserModel) => void;
  disabled?: boolean;
  placeholder?: string;
  value?: UserModel;
}

const UserSearchField: FunctionComponent<UserSearchFieldProps> = (props) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/users`;
  const { showToast } = useToast();
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [addNew, setAddNew] = useState(false);
  const [suggestions, setSuggestions] = useState<
    {
      label: string;
      value: UserModel;
    }[]
  >();
  const getData = async (prop: {
    search?: string;
    page: number;
    count: number;
    all?: boolean;
  }) => {
    setLoading(true);
    try {
      const res = await fetchGet(url + toQueryString(prop));
      if (res.success) {
        setSuggestions(
          res.data.data.map((data: UserModel) => ({
            label: `${data.first_name} ${data.last_name} (${data.mobile})`,
            value: data,
          }))
        );
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
      //   return { success: false, error: error.message };
    }
    setLoading(false);
  };

  const debouncedHandler = useCallback(
    debounce((query) => {
      if (query) getData({ search: query, page: 1, count: 1, all: true });
      else setSuggestions(undefined);
    }, 500),
    []
  );

  useEffect(() => {
    if (search && edit) {
      if (props.value && props.value.first_name === search) return;
      debouncedHandler(search);
    }
  }, [search]);
  useEffect(() => {
    setSearch(props.value?.first_name ?? "");
  }, [props.value]);
  return (
    <>
      {edit ? (
        <AutoCompleteField
          loading={loading}
          disabled={props.disabled}
          value={search}
          onChange={setSearch}
          onAddNew={() => setAddNew(true)}
          onSelect={(d) => {
            setSearch(d.value.first_name);
            setEdit(false);

            props.onSelect(d.value);
          }}
          placeholder={props.placeholder || "Search party"}
          suggestions={suggestions}
        />
      ) : (
        <input
          type="text"
          placeholder={props.placeholder || "Search party"}
          className="text-sm p-1   rounded focus:outline-none w-full border cursor-text"
          onClick={() => {
            if (!props.disabled) {
              setEdit(true);
            }
          }}
          value={props.value?.first_name}
        ></input>
      )}
      {addNew && (
        <ModalDialog
          onClose={() => {
            setAddNew(false);
          }}
          show={addNew}
          title={"Add customer/user"}
        >
          <div className="overflow-auto" style={{ maxHeight: "80vh" }}>
            {" "}
            <AddCustomerWidget
              onAdded={(user) => {
                if (user) {
                  setEdit(false);
                  setSearch(user.first_name);
                  props.onSelect(user as any);
                  setAddNew(false);
                }
              }}
            />
          </div>
        </ModalDialog>
      )}
    </>
  );
};

export default UserSearchField;
