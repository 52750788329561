import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { urls } from "../../../../../utils/urls";
import Sidebar from "../../components/Sidebar";
import ProtocolLayout from "../components/ProtocolLayout";

interface ComplaintSegmentProps {}

const ComplaintSegment: FunctionComponent<ComplaintSegmentProps> = () => {
  return (
    <ProtocolLayout>
      <div className="flex flex-col items-center py-12">
        <div className="mb-12 text-3xl font-bold underline">
          3 pillars of support
        </div>
        <div className="w-3/5 hidden lg:block">
          <img
            src="/images/kazam-academy/complaint-flow.png"
            alt=""
            className="w-full"
          />
        </div>
        <div className="grid lg:grid-cols-3 justify-items-center gap-y-5 lg:gap-y-0 w-4/5 mt-6 lg:mt-20">
          <Link to={urls.complaint_protocol}>
            <div className="relative bg-gray-100 hover:bg-gray-200 duration-300 w-72 h-64 flex flex-col items-center justify-center rounded-xl shadow-lg py-12 px-4 ">
              <div className="">
                <img src="/kazam-academy/tally.png" alt="" className="w-32" />
              </div>
              <div className="mt-12 text-black text-opacity-40 font-bold text-xl">
                Complaint protocol
              </div>
            </div>
          </Link>
          <a
            // href="https://docs.google.com/document/d/1irj1G2KAp7TWWyLzc0gz_Qt6V4UjkqBG6TZMmI_AsHA/edit"
            // href="https://kazam-media.s3.ap-south-1.amazonaws.com/erp-protocols/customer_support_handbook.docx"
            href="https://docs.google.com/document/d/1T5MApZI7JDQKNbZ2j3MWA47i9jQB8opQzxz7XMc9t4U/edit"
            target="_blank"
            rel="noreferrer"
          >
            <div className="relative bg-gray-100 hover:bg-gray-200 duration-300 w-72 h-64 flex flex-col items-center justify-center rounded-xl shadow-lg py-12 px-4 ">
              <div className="">
                <img src="/docs/gmc1.png" alt="" className="w-32" />
              </div>
              <div className="mt-12 text-black text-opacity-40 font-bold text-xl">
                Handbook
              </div>
            </div>
          </a>
          <a
            href="https://multiverse.kazam.in/"
            target="_blank"
            rel="noreferrer"
          >
            <div className="relative bg-gray-100 hover:bg-gray-200 duration-300 w-72 h-64 flex flex-col items-center justify-center rounded-xl shadow-lg py-12 px-4 ">
              <div className="">
                <img src="/docs/handbook.png" alt="" className="w-32" />
              </div>
              <div className="mt-12 text-black text-opacity-40 font-bold text-lg text-center">
                Driver attempt tracker
              </div>
              {/* <div className="flex items-center mt-3">
              <div className="">
                <img src="/images/denied.png" alt="" className="w-4" />
              </div>
              <div className="text-xs text-red-400 ml-3 ">Need access</div>
            </div> */}
            </div>
          </a>
        </div>
      </div>
    </ProtocolLayout>
  );
};

export default ComplaintSegment;
