import { FunctionComponent, useState } from "react";
import { PaymentRefType } from "../../../../../utils/enums";

interface PaymentLinkWidgetProps {
  order_id: string;
  max_amount: number;
}

const PaymentLinkWidget: FunctionComponent<PaymentLinkWidgetProps> = (
  props
) => {
  const [payfull, setPayFull] = useState(true);
  const [amount, setAmount] = useState(0);
  const link = `${process.env.REACT_APP_BACKEND_BASE_URL}/payment/initiate?ref_id=${props.order_id}&ref_type=${PaymentRefType.order}`;
  return (
    <div className="p-5">
      <div className="flex my-2">
        <div className="mx-3">
          <input
            className="mx-2"
            type="radio"
            defaultChecked={payfull}
            name="type"
            required
            id="full"
            onChange={(e) => {
              if (e.target.value === "on") setPayFull(true);
            }}
          />
          <label className=" font-semibold" htmlFor="full">
            Full payment
          </label>
        </div>
        <div className="mx-3">
          <input
            className="mx-2"
            type="radio"
            defaultChecked={!payfull}
            name="type"
            required
            id="half"
            onChange={(e) => {
              if (e.target.value === "on") setPayFull(false);
            }}
          />
          <label className=" font-semibold" htmlFor="half">
            Partial payment
          </label>
        </div>
      </div>
      {!payfull && (
        <div className="grid grid-cols-3 gap-3 items-center my-2">
          <label className="col-span-1" htmlFor="amount">
            Amount {"\u20b9"}
          </label>
          <input
            id="amount"
            className="border rounded-lg p-2 w-full col-span-2 focus:outline-none"
            type="number"
            name="amount"
            required
            placeholder="Amount"
            value={amount}
            onChange={(e) => {
              const amt = parseInt(e.target.value);
              if (amt > props.max_amount) {
                alert("Maximum amount is :" + props.max_amount);
                return;
              }
              setAmount(amt);
            }}
          />
        </div>
      )}
      <div className="flex gap-3">
        <div className=" w-80 text-blue-400  rounded-lg bg-gray-100 break-all p-3 my-2 ">
          {payfull
            ? link
            : amount > 0
            ? `${link}${!payfull ? "&amount=" + amount : ""}`
            : "Please enter amount"}
        </div>
        <div
          onClick={() => {
            if (payfull) {
              navigator.clipboard.writeText(link);
              alert("copied !");
              return;
            } else {
              if (amount > 0) {
                navigator.clipboard.writeText(
                  `${link}${!payfull ? "&amount=" + amount : ""}`
                );
                alert("copied !");
                return;
              }
            }
          }}
          className="p-2 bg-gray-100 m-auto rounded-full hover:bg-red-800 hover:text-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default PaymentLinkWidget;
