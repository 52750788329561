import { FunctionComponent, useEffect, useState } from "react";
import {
  accountTxnType,
  LedgerEntryModel,
  PaymentEntryType,
  voucherEntryType,
} from "../../models/common_model";

import ItemSearchField from "../../../inventory/items/item_search_field";
import { UTCToLocalDate } from "../../../../utils/date_util";
import moment from "moment";

import LedgerSearchField from "../../ledgers/ledger_search_field";
import {
  account_group,
  account_sub_group,
} from "../../models/ledger_group_model";

import {
  fetchDelete,
  fetchGet,
  fetchPost,
  fetchPut,
  toQueryString,
} from "../../../../service/api_client";
import {
  ToastType,
  useToast,
} from "../../../../context_providers/toast/toast_ctx";
import { numberRegEx } from "../../../../utils/constant";
import { useParams } from "react-router-dom";

import LoadingWidget from "../../../../context_providers/modal/loader";
import {
  recieptDefaultValue,
  RecieptVoucherModel,
} from "../../models/receipt_model";
import RecieptSplitRow from "./components/reciept_split_row";
import { ledgerEntryDefaultValue } from "../../models/purchase_model";
import { SalesVoucherModel } from "../../models/sale_model ";
import { mdiAttachment, mdiChevronRight } from "@mdi/js";
import Icon from "@mdi/react";
import DrawerWidget from "../../../../context_providers/modal/drawer_widget";
import {
  ModalType,
  ModalAlignment,
} from "../../../../context_providers/modal/modal_context";
import { file_dir, PaymentMode } from "../../../../utils/enums";
import axios from "axios";
import {
  AttachmentModel,
  getFileType,
} from "../../../purchase_order/model/purchase_order_model";
import AttachmentSection from "../../../purchase_order/purchase_order_create/components/attachment";
import DoneWidget from "../../../../ui/new_components/common/done";

interface RecieptVoucherProps {
  location?: any;
}

const RecieptVoucher: FunctionComponent<RecieptVoucherProps> = (props) => {
  const { id } = useParams<{ id: string }>();
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/accounts/voucher/reciept`;
  const [showTxnDetail, setShowTxnDetail] = useState(false);
  const [addNewId, setAddNewId] = useState(Math.random() * 1000);
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();
  const [showSuccess, setShowSucess] = useState(false);

  const [voucherData, setVoucherData] =
    useState<RecieptVoucherModel>(recieptDefaultValue);
  const submit = async () => {
    if (!voucherData.reciept_ledger_entry?.ledger_id)
      return showToast({
        type: ToastType.error,
        text: "Purchase voucher required !",
      });
    if (!voucherData.party_ledger_entry?.ledger_id)
      return showToast({
        type: ToastType.error,
        text: "party ledger required !",
      });
    if (
      voucherData.reciept_ledger_entry?.ledger!.group.name ===
      account_sub_group.bank_accounts.name
    ) {
      if (
        !voucherData.txn_detail ||
        !voucherData.txn_detail.date ||
        !voucherData.txn_detail.mode ||
        !voucherData.txn_detail.txn_id
      )
        return showToast({
          type: ToastType.error,
          text: "transaction detail required !",
        });
    } else {
      delete voucherData.txn_detail;
    }
    // if (voucherData.entry_type === "Invoice") {
    //   if (!voucherData.items || voucherData.items.length === 0) {
    //     return showToast({
    //       type: ToastType.error,
    //       text: "Items required for Invoice type entry !",
    //     });
    //   }
    // }

    // voucherData.party_ledger_entry.amount = voucherData.total;
    // voucherData.reciept_ledger_entry.amount =
    //   voucherData.total - (voucherData.tax || 0);
    setLoading(true);
    const res = voucherData.id
      ? await fetchPut(url + "/" + voucherData.id, voucherData)
      : await fetchPost(url, voucherData);
    if (res.success) {
      showToast({ type: ToastType.success, text: res.message });
      if (!voucherData.id) {
        // setVoucherData(recieptDefaultValue);
        setVoucherData(res.data);
        setShowSucess(true);
      }
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };
  useEffect(() => {
    setVoucherData(props.location.state || recieptDefaultValue);
    if (id) getVoucher(id);
  }, []);
  const getVoucher = async (id: string) => {
    setLoading(true);
    const res = await fetchGet(url + "/" + id, voucherData);
    if (res.success) {
      setVoucherData(res.data);
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };
  // const [showSplit, setShowSplit] = useState(false);
  const [outstanding, setOutstanding] = useState<SalesVoucherModel[]>([]);
  const getOutstandingPayment = async (ledger_id: string) => {
    setLoading(true);
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/accounts/ledgers/${ledger_id}/outstanding-reciepts`;
    const res = await fetchGet(url);
    if (res.success) {
      setOutstanding(res.data);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (
      voucherData.party_ledger_entry?.ledger &&
      [
        account_sub_group.sundry_creditors.name,
        account_sub_group.sundry_debtors.name,
      ].includes(voucherData.party_ledger_entry?.ledger.group.name)
    ) {
      getOutstandingPayment(voucherData.party_ledger_entry?.ledger.id);
    }
  }, [voucherData.party_ledger_entry?.ledger_id]);
  const [showAttachment, setShowAttachment] = useState(false);
  const [attachmentKey, setAttachmentKey] = useState(Math.random() * 10000);
  const uploadFile = async ({
    file,
    category,
    removeSelectedFile,
    setShowUpload,
  }: {
    file: File;
    category: string;
    removeSelectedFile: () => void;
    setShowUpload: (v: boolean) => void;
  }) => {
    try {
      if (!file || !category) {
        showToast({
          type: ToastType.warning,
          text: "Please select file and category",
        });
        return;
      }
      setLoading(true);
      const { name, type } = file;
      const create_url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/accounts/voucher/credit-note/${voucherData.id}/attachment`;
      const get_signed_urls = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/signed-url`;
      const query = {
        mimetype: type,
        extension: name.split(".").pop(),
        dir: file_dir.pocuremnt_attachments,
      };
      const res = await fetchGet(get_signed_urls + toQueryString(query));
      if (res.success) {
        const { signed_url, url } = res.data;
        const aws_res = await axios.put(signed_url, file, {
          headers: {
            "Content-Type": type,
          },
        });
        if (aws_res.status === 200) {
          const attachment: AttachmentModel = {
            id: "",
            category,
            type: getFileType(type),
            url,
          };
          const create_res = await fetchPost(create_url, attachment);
          console.log({ create_res });
          if (create_res.success) {
            showToast({
              type: ToastType.success,
              text: create_res.message,
            });
            setVoucherData((o) => ({
              ...o,
              attachments: [...(o.attachments || []), create_res.data],
            }));

            removeSelectedFile();
            setShowUpload(false);
            setAttachmentKey(Math.random() * 1000);
          }
        }
      }
      setLoading(false);
    } catch (error: any) {
      showToast({
        type: ToastType.error,
        text: error.message,
      });
      setLoading(false);
    }
  };
  const deleteFile = async (att: AttachmentModel) => {
    try {
      if (!window.confirm("Are your sure to delete ?")) return;
      if (!att) {
        showToast({
          type: ToastType.warning,
          text: "Please select file",
        });
        return;
      }
      setLoading(true);
      const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/accounts/voucher/debit-note/${voucherData.id}/attachment/${att.id}`;

      const res = await fetchDelete(url);

      if (res.success) {
        showToast({
          type: ToastType.success,
          text: res.message,
        });
        setVoucherData((o) => ({
          ...o,
          attachments: [
            ...(o.attachments || []).filter((f) => f.id !== att.id),
          ],
        }));
        setAttachmentKey(Math.random() * 1000);
      }
      setLoading(false);
    } catch (error: any) {
      showToast({
        type: ToastType.error,
        text: error.message,
      });
      setLoading(false);
    }
  };

  const onSelectAttchment = (d: AttachmentModel) => {
    window.open(d.url, "_blank");
  };
  if (loading) return <LoadingWidget />;
  if (showSuccess)
    return (
      <div className="w-full h-full flex flex-col items-center justify-center">
        <div className="w-80 h-80 p-0">
          <DoneWidget />
        </div>
        <div className="flex flex-col gap-2 justify-start items-center">
          <h1 className="text-3xl font-semibold text-gray-400 ">
            Created successfully
          </h1>
          <span className="text-black italic font-semibold">
            # {voucherData.id}
          </span>
          <div className="flex gap-5">
            <button
              onClick={() => setShowSucess(false)}
              className="border rounded-3xl px-5 py-2 text-green-800 border-green-400"
            >
              Continue
            </button>
            <button
              onClick={() => {
                setVoucherData(recieptDefaultValue);
                setShowSucess(false);
              }}
              className="border rounded-3xl px-4 py-2 bg-green-400 text-white  "
            >
              Create new
            </button>
          </div>
        </div>
      </div>
    );
  return (
    <>
      {showAttachment && voucherData.id && (
        <DrawerWidget
          data={{
            id: 1,
            title: "Attchments",
            type: ModalType.drawer,
            alignment: ModalAlignment.right,
            container: (
              <AttachmentSection
                key={attachmentKey}
                attachments={voucherData.attachments}
                onSubmit={uploadFile}
                onDelete={deleteFile}
                onSelect={onSelectAttchment}
              />
            ),
          }}
          onClose={function (): void {
            setShowAttachment(false);
          }}
        ></DrawerWidget>
      )}
      {id && (
        <div className="fixed bottom-3 right-3">
          <button
            title="Attchments"
            // to={`${window.document.location.pathname}/comment`}
            onClick={() => setShowAttachment(true)}
            className="  rounded-full p-2  hover:scale-105 transform duration-150 bg-green-400 text-white   shadow cursor-pointer flex items-center justify-center"
          >
            <Icon
              path={mdiAttachment}
              size={1}
              className="hover:scale-105 transform -rotate-45"
            ></Icon>
          </button>
        </div>
      )}
      {showTxnDetail && (
        <DrawerWidget
          data={{
            id: 1,
            title: "Transaction detail",
            type: ModalType.drawer,
            alignment: ModalAlignment.right,
            container: (
              <>
                <div className="p-5 text-xs">
                  <div className="grid grid-cols-1  my-2 gap-2">
                    {" "}
                    <div className="grid grid-cols-4 items-center">
                      <label
                        className="text-sm font-semibold"
                        htmlFor="txn_date"
                      >
                        date
                      </label>
                      <input
                        value={
                          voucherData.txn_detail?.date
                            ? moment(voucherData.txn_detail?.date).format(
                                "YYYY-MM-DD"
                              )
                            : ""
                        }
                        onChange={(e) => {
                          let date = UTCToLocalDate(e.target.value)!;
                          date = new Date(date.setHours(0, 0, 0, 0));
                          setVoucherData((o) => ({
                            ...o,
                            txn_detail: {
                              ...(o.txn_detail || {
                                txn_id: "",
                                date: new Date(),
                                mode: "",
                              }),
                              date,
                            },
                          }));
                        }}
                        type="date"
                        name=""
                        id="txn_date"
                        className="col-span-3 focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full border"
                      />
                    </div>
                    <div className="grid grid-cols-4 items-center">
                      <label htmlFor="txn_id" className=" font-semibold  ">
                        Mode
                      </label>
                      <select
                        id="txn_id"
                        className="col-span-3 focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full border"
                        value={voucherData.txn_detail?.mode}
                        onChange={(e) => {
                          setVoucherData((o) => ({
                            ...o,
                            txn_detail: {
                              ...(o.txn_detail || {
                                txn_id: "",
                                date: new Date(),
                                mode: "",
                              }),
                              mode: e.target.value,
                            },
                          }));
                        }}
                      >
                        <option value="" hidden>
                          Select
                        </option>
                        {Object.values(PaymentMode).map((v) => (
                          <option value={v} key={v}>
                            {v}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="grid grid-cols-4 items-center">
                      <label htmlFor="txn_id" className=" font-semibold  ">
                        UTR
                      </label>
                      <input
                        id="txn_id"
                        type="text"
                        className="col-span-3 focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full border"
                        value={voucherData.txn_detail?.txn_id}
                        onChange={(e) => {
                          setVoucherData((o) => ({
                            ...o,
                            txn_detail: {
                              ...(o.txn_detail || {
                                txn_id: "",
                                date: new Date(),
                                mode: "",
                              }),
                              txn_id: e.target.value,
                            },
                          }));
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>
            ),
          }}
          onClose={function (): void {
            setShowTxnDetail(false);
          }}
        ></DrawerWidget>
      )}
      <div className=" mt-2 px-6">
        <div className="flex items-center justify-between mb-2">
          <div className="font-bold text-2xl text-gray-500 border-l-4 border-myPrimaryColor pl-2">
            Receipt voucher
          </div>
          <div className="flex gap-2 items-center">
            <label htmlFor="" className="text-sm font-semibold text-gray-500">
              Date
            </label>{" "}
            <input
              type="date"
              className=" focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full text-sm border"
              value={
                voucherData.date
                  ? moment(voucherData.date).format("YYYY-MM-DD")
                  : ""
              }
              onChange={(e) => {
                const date = UTCToLocalDate(e.target.value)!;
                setVoucherData((o) => ({
                  ...o,
                  date: date,
                }));
              }}
            />
          </div>
        </div>
        <div className="bg-white rounded-lg p-3">
          <div className="grid grid-cols-3 gap-x-12 mt-3">
            {/* <div className="grid grid-cols-4 items-center">
              <label
                htmlFor=""
                className="text-sm font-semibold text-gray-500 "
              >
                Entry Mode
              </label>
              <select
                disabled={id !== undefined}
                name=""
                id=""
                className="col-span-3 focus:outline-none focus:ring-1 ring-blue-400 border border-gray-300 rounded px-3 py-1 cursor-pointer mt-1 text-sm font-semibold w-full"
                value={voucherData.entry_type}
                onChange={(e) =>
                  setVoucherData((o: any) => ({
                    ...o,
                    entry_type: e.target.value!,
                  }))
                }
              >
                {Object.values(PaymentEntryType).map((el, i) => {
                  return <option value={el}>{el}</option>;
                })}
              </select>
            </div> */}
            <div className="grid grid-cols-4 items-center">
              <label htmlFor="" className="text-sm font-semibold text-gray-500">
                Account ledger
              </label>
              <div className="col-span-3">
                <LedgerSearchField
                  key={Math.random() * 10000}
                  value={
                    voucherData.reciept_ledger_entry
                      ? voucherData.reciept_ledger_entry.ledger
                      : undefined
                  }
                  onSelect={(d) => {
                    setVoucherData((o) => ({
                      ...o,
                      reciept_ledger_entry: {
                        ...(o.reciept_ledger_entry || ledgerEntryDefaultValue),
                        ledger: d,
                        ledger_id: d.id,
                        type: accountTxnType.creditor,
                      },
                    }));
                  }}
                  group={[
                    account_sub_group.cash_in_hand.name,
                    account_sub_group.bank_accounts.name,
                  ]}
                />
              </div>
            </div>
            {voucherData.reciept_ledger_entry &&
              voucherData.reciept_ledger_entry?.ledger!.group.name ===
                account_sub_group.bank_accounts.name && (
                <div className="flex justify-end">
                  <div
                    onClick={() => {
                      setShowTxnDetail(true);
                    }}
                    className="flex gap-5 border rounded items-center px-2 py-1  text-sm cursor-pointer"
                  >
                    <p>Txn detail</p>
                    <Icon path={mdiChevronRight} className="w-4 h-4" />
                  </div>
                </div>
              )}
          </div>
        </div>

        <div className="">
          <h3 className="text-sm font-bold">Party</h3>
          <div className="bg-white rounded flex flex-col gap-1">
            <div className="grid grid-cols-3 gap-5 p-1 bg-myPrimaryColor text-white text-sm font-semibold rounded-t">
              <div className="col-span-2">Particular</div>
              <div className="text-center">Amount</div>
            </div>
            <div className="grid grid-cols-3 gap-5 p-1  ">
              <div className="col-span-2">
                <LedgerSearchField
                  key={Math.random() * 10000}
                  value={
                    voucherData.party_ledger_entry
                      ? voucherData.party_ledger_entry.ledger
                      : undefined
                  }
                  onSelect={(d) => {
                    setVoucherData((o) => ({
                      ...o,
                      party_ledger_entry: {
                        ...(o.party_ledger_entry || ledgerEntryDefaultValue),
                        ledger: d,
                        ledger_id: d.id,
                        type: accountTxnType.debitor,
                      },
                    }));
                  }}
                />
              </div>

              <input
                disabled={!voucherData.party_ledger_entry}
                value={voucherData.party_ledger_entry?.amount}
                onChange={(e) => {
                  const val = e.target.value.trim();
                  if (!val || numberRegEx.test(val)) {
                    const value = Number(val || 0);
                    setVoucherData((o) => ({
                      ...o,
                      party_ledger_entry: {
                        ...(o.party_ledger_entry || ledgerEntryDefaultValue),
                        amount: value,
                        type: accountTxnType.debitor,
                      },
                      sub_total: value,
                      total: value,
                    }));
                  }
                }}
                placeholder=""
                type="number"
                className="text-right  p-1 focus:outline-none border rounded  w-full  "
              />
            </div>
          </div>
        </div>

        {voucherData.party_ledger_entry?.ledger_id &&
          voucherData.entry_type === PaymentEntryType.bill_payment && (
            <div className="text-sm mt-5">
              <div className="">
                <p>
                  Bill splits
                  {/* {voucherData.party_ledger_entry?.ledger?.name} */}
                </p>
              </div>

              <div className=" ">
                <div className="grid grid-cols-7 bg-myPrimaryColor text-white font-bold p-1 rounded-t">
                  {/* <div className="">Date</div> */}
                  <div className="col-span-2">Particular</div>
                  <div className="">Voucher no</div>
                  <div className="">Due Date</div>
                  <div className="">Due Amount</div>
                  <div className="">Amount</div>
                </div>

                <div className="flex flex-col gap-2 bg-white rounded pb-2 divide-y">
                  {voucherData.reciept_split.map((split, i) => {
                    const date = new Date(split.voucher.date);
                    const due_date = new Date(
                      new Date(date).setDate(
                        date.getDate() + (split.voucher.credit_period_day || 0)
                      )
                    );
                    const split_exist_in_os_bill = outstanding.find(
                      (v) => v.id === split.voucher_id
                    );
                    return (
                      <RecieptSplitRow
                        key={split.id}
                        reciept_split={split}
                        pending_bills={[
                          ...outstanding,
                          ...(!split_exist_in_os_bill ? [split.voucher] : []),
                        ]}
                        max_amount={
                          (voucherData.party_ledger_entry?.amount || 0) -
                          voucherData.reciept_split.reduce(
                            (pv, val) =>
                              pv +
                              (val.voucher_id !== split.voucher_id
                                ? val.amount
                                : 0),
                            0
                          )
                        }
                        onDelete={(d) => {
                          setVoucherData((o) => ({
                            ...o,
                            payment_split: [...(o.reciept_split || [])].filter(
                              (i) => i.id !== d.id
                            ),
                          }));
                        }}
                        onSubmit={function (data: {
                          voucher_id: string;
                          voucher: SalesVoucherModel;
                          amount: number;
                        }): void {
                          setVoucherData((o) => ({
                            ...o,
                            reciept_split: [
                              ...o.reciept_split.map((v) => {
                                if (v.voucher_id === split.voucher_id) {
                                  return data;
                                }
                                return v;
                              }),
                            ],
                          }));
                        }}
                        edit={false}
                        disabled={false}
                      />
                    );
                    return (
                      <div className="grid grid-cols-7 px-2 pt-2 ">
                        <div className="">
                          {moment(date).format("DD-MM-YYYY")}
                        </div>
                        <div className="col-span-2">
                          {split.voucher.party_ledger_entry.ledger?.name}
                        </div>
                        <div className="">{split.voucher.id}</div>
                        <div className="">
                          {" "}
                          {moment(due_date).format("DD-MM-YYYY")}
                        </div>
                        <div className="">
                          {split.voucher.total - split.voucher.paid}
                        </div>
                        <div className="">
                          <input
                            disabled={!voucherData.party_ledger_entry}
                            value={split?.amount || 0}
                            onChange={(e) => {
                              const val = e.target.value.trim();
                              if (!val || numberRegEx.test(val)) {
                                const value = Number(val || 0);

                                setVoucherData((o) => ({
                                  ...o,
                                  reciept_split: o.reciept_split.map((v) => {
                                    if (v.voucher_id === split.voucher.id) {
                                      v.amount = value;
                                    }
                                    return v;
                                  }),
                                }));
                                // if (!voucherData.reciept_split)
                                //   voucherData.reciept_split = [];
                                // if (split)
                                //   voucherData.reciept_split =
                                // voucherData.reciept_split.map((v) => {
                                //   if (v.voucher_id === split.voucher.id) {
                                //     v.amount = value;
                                //   }
                                //   return v;
                                // });
                                // else {
                                //   voucherData.reciept_split.push({
                                //     voucher_id: split.voucher_id,
                                //     amount: value,
                                //     voucher: split.voucher,
                                //   });
                                // }
                              }
                            }}
                            placeholder=""
                            type="text"
                            className="text-right  p-1 focus:outline-none border rounded  w-full  "
                          />
                        </div>
                      </div>
                    );
                  })}
                  <RecieptSplitRow
                    key={addNewId}
                    pending_bills={outstanding.filter(
                      (v) =>
                        !voucherData.reciept_split
                          .map((s) => s.voucher_id)
                          .includes(v.id)
                    )}
                    max_amount={
                      (voucherData.party_ledger_entry?.amount || 0) -
                      voucherData.reciept_split.reduce(
                        (pv, val) => pv + val.amount,

                        0
                      )
                    }
                    onSubmit={function (data: {
                      voucher_id: string;
                      voucher: SalesVoucherModel;
                      amount: number;
                    }): void {
                      setVoucherData((o) => ({
                        ...o,
                        reciept_split: [...o.reciept_split, data],
                      }));
                      setAddNewId(Math.random() * 10000);
                    }}
                    edit={true}
                    disabled={false}
                  />
                </div>
              </div>
            </div>
          )}
        <div className="">
          {/* <h3 className="text-sm font-bold">Discounts</h3>
          <div className="bg-white rounded flex flex-col gap-1">
            <div className="grid grid-cols-3 gap-5 p-1 bg-myPrimaryColor text-white text-sm font-semibold rounded-t">
              <div className="col-span-2">Particular</div>
              <div className="text-center">Amount</div>
            </div>
            <div className="grid grid-cols-3 gap-5 p-1  ">
              <div className="col-span-2">
                <LedgerSearchField
                  value={
                    (voucherData.discounts || [])[0]
                      ? (voucherData.discounts || [])[0].ledger
                      : undefined
                  }
                  onSelect={function (data: LedgerModel): void {
                    setVoucherData((ol) => {
                      return {
                        ...ol,

                        discounts: [
                          {
                            ...((ol.discounts || [])[0] ||
                              ledgerEntryDefaultValue),
                            ledger: data,
                            ledger_id: data.id,
                            type: accountTxnType.creditor,
                          },
                        ],
                      };
                    });
                  }}
                />
              </div>
              <div className="text-center">
                <input
                  disabled={!(voucherData.discounts || [])[0]}
                  value={(voucherData.discounts || [])[0]?.amount}
                  onChange={(e) => {
                    const val = e.target.value.trim();
                    if (!val || numberRegEx.test(val)) {
                      const value = Number(val || 0);
                      setVoucherData((ol) => ({
                        ...ol,
                        discounts: [
                          {
                            ...((ol.discounts || [])[0] ||
                              ledgerEntryDefaultValue),
                            amount: value,
                            type: accountTxnType.creditor,
                          },
                        ],
                        discount: value,
                        total: ol.sub_total - value,
                      }));
                    }
                  }}
                  placeholder=""
                  type="text"
                  className="text-right  p-1 focus:outline-none border rounded  w-full"
                />
              </div>
            </div>
          </div> */}
          <div className="text-sm flex justify-end">
            <div className="flex flex-col gap-2">
              <div className=" flex gap-5 justify-between">
                <div className="">Sub total:</div>
                <div className="">{voucherData.sub_total || 0}</div>
              </div>

              {/* <div className=" flex gap-5 justify-between">
                <div className="">Discount:</div>
                <div className="">{voucherData.discount || 0}</div>
              </div> */}
              <div className=" flex gap-5 justify-between font-bold">
                <div className="">Total:</div>
                <div className="">{voucherData.total || 0}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" flex justify-end px-6 py-2">
        <button
          type="button"
          onClick={submit}
          className="px-10 py-1 rounded-md bg-myPrimaryColor text-white text-sm "
        >
          Submit
        </button>
      </div>
    </>
  );
};

export default RecieptVoucher;
