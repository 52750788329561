import { FunctionComponent, useEffect, useState } from "react";
import { timeDiff } from "../../../../utils/date_util";

interface TimerWidgetProps {
  start: Date | string | number;
}

const TimerWidget: FunctionComponent<TimerWidgetProps> = ({ start }) => {
  const [timer, setTimer] = useState("");
  useEffect(() => {
    const t = setInterval(() => {
      setTimer(timeDiff(start));
    }, 1000);
    return () => clearInterval(t);
  }, []);
  return (
    <>
      <div className="">{timer}</div>
    </>
  );
};

export default TimerWidget;
