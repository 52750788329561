import axios from "axios";
import { URLS } from "../utils/urls";

export const getHeaders = () => {
  const token = localStorage.getItem("token");

  let headers;
  headers = {
    "Content-Type": "application/json",
  };
  if (token) {
    headers = { ...headers, Authorization: `Bearer ${token}` };
  }
  return headers;
};

export const fetchPost = async (
  url: string,
  data: object,
  headers?: object
) => {
  try {
    const res = await axios.post(url, data, {
      headers: { ...getHeaders(), ...headers },
    });

    if (res.status === 401) {
      localStorage.removeItem("token");
      window.history.replaceState({}, "", URLS.login);
    }
    return res.data;
  } catch (error: any) {
    const { response } = error;

    if (response.status === 401) {
      localStorage.removeItem("token");
      window.history.replaceState({}, "", URLS.login);
    }
    return { success: false, error: response.data.error };
  }
};
export const fetchPut = async (url: string, data: object, headers?: object) => {
  try {
    const res = await axios.put(url, data, {
      headers: { ...getHeaders(), ...headers },
    });

    return res.data;
  } catch (error: any) {
    const { response } = error;

    if (response.status === 401) {
      localStorage.removeItem("token");
      window.history.replaceState({}, "", URLS.login);
    }
    return { success: false, error: response.data.error };
  }
};

export const fetchGet = async (url: string, headers?: object) => {
  try {
    const res = await axios.get(url, {
      headers: { ...getHeaders(), ...headers },
    });

    return res.data;
  } catch (error: any) {
    const { response } = error;

    if (response.status === 401) {
      localStorage.removeItem("token");
      window.history.replaceState({}, "", URLS.login);
    }
    return { success: false, error: response.data.error };
  }
};
export const fetchDelete = async (
  url: string,
  data?: object,
  headers?: object
) => {
  try {
    const res = await axios.delete(url, {
      headers: { ...getHeaders(), ...headers },
    });

    return res.data;
  } catch (error: any) {
    const { response } = error;

    if (response.status === 401) {
      localStorage.removeItem("token");
      window.history.replaceState({}, "", URLS.login);
    }
    return { success: false, error: response.data.error };
  }
};
