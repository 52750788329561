import { fetchGet, fetchPost } from "../../api_client";

export interface TaskSessionModel {
  id?: number;
  emp_id: string;
  emp_name: string;
  on_duty?: string;
  off_duty?: string;
  on_duty_lat?: number;
  on_duty_long?: number;
  off_duty_lat?: number;
  off_duty_long?: number;
  created_at?: string;
  updated_at?: string;
}

export const StartTaskSessionRepo = async (data: {
  lat: number;
  long: number;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/new-ops/task/session/start`;
    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const StopTaskSessionRepo = async (data: {
  lat: number;
  long: number;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/new-ops/task/session/stop`;
    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GetTaskSessionLiveRepo = async () => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/new-ops/task/session/my-live`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GetMyTaskSessionsRepo = async ({
  page,
  count,
}: {
  page: number;
  count: number;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/new-ops/task/session/my?page=${page}&count=${count}`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GetTaskSessionsRepo = async ({
  page,
  count,
}: {
  page: number;
  count: number;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/new-ops/task/session?page=${page}&count=${count}`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GetTaskAnalyticsRepo = async (filter: number) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/new-ops/task/analytics/admin?graph_type=${filter}`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GetEngineerCountRepo = async () => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/new-ops/task/engineer/count`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
