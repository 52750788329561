import { FunctionComponent } from "react";
import Sidebar from "../../components/sidebar";
import Layout from "../../layout";

interface GmcProps {}

const Gmc: FunctionComponent<GmcProps> = () => {
  const gmcDocs = [
    {
      name: "Group mediclaim policy",
      link: "/docs/health-policy.pdf",
      image: "/docs/health-policy.png",
    },
    // {
    //   name: "Claim process",
    //   link: "/docs/gmc2.pdf",
    //   image: "/docs/gmc2.png",
    // },
  ];
  return (
    <Layout>
      <div className="hidden xl:block">
        <div className="pl-12 pb-12 pt-12">
          <div className="grid grid-cols-3 gap-y-12 justify-items-center">
            {gmcDocs.map((item) => {
              return (
                <a href={item.link} target="_blank" rel="noreferrer">
                  <div className="rounded-xl cursor-pointer duration-300 bg-white flex flex-col items-center justify-center w-72 h-72">
                    <div className="">
                      <img src={item.image} alt="" className="w-32" />
                    </div>
                    <div className="text-xl font-bold  mt-4">{item.name}</div>
                  </div>
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Gmc;
