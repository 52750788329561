import { FunctionComponent, useState } from "react";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import { InvoiceModel } from "../../../../../service/models/orders/invoice_model";
import { CreateInvoiceApi } from "../../../../../service/repos/invoice_service";
import { PaymentRefType } from "../../../../../utils/enums";
import PdfTile from "../../../../components/common/pdf_tile";
import LoadingWidget from "../../../../components/loading_spinner";
import FileDropZone from "../../../../new_components/common/file_dropdown";
import StadiumButton from "../../../../new_components/common/stadium_button";

interface CreateInvoiceProps {
  onSuccess: (data: InvoiceModel) => void;
}

const CreateInvoice: FunctionComponent<CreateInvoiceProps> = (props) => {
  const { showToast } = useToast();
  const [ref_id, setRefId] = useState("");
  const [ref_type, setRefType] = useState("");
  const [invoiceNo, setInvoidNo] = useState("");
  const [qty, setQty] = useState(0);
  const [amount, setAMount] = useState(0);
  const [file, setFile] = useState<File>();
  const [loading, setLoading] = useState(false);

  const createInvoice = async () => {
    if (!ref_id || !ref_type || !file || !invoiceNo || !qty || !amount) {
      showToast({
        type: ToastType.warning,
        text: "Please fill required fields !",
      });
      return;
    }
    setLoading(true);
    const form = new FormData();
    form.append("ref_id", ref_id);
    form.append("ref_type", ref_type);
    form.append("invoice_no", invoiceNo);
    form.append("qty", qty.toString());
    form.append("amount", amount.toString());
    form.append("file", file);
    const res = await CreateInvoiceApi(form);

    if (res.success) {
      showToast({ type: ToastType.success, text: res.message ?? "" });
      setRefId("");
      setRefId("");
      setFile(undefined);
      props.onSuccess(res.data);
    } else showToast({ type: ToastType.error, text: res.error ?? "" });
    setLoading(false);
  };

  return (
    <div className="p-2 md:p-5">
      <p className="text-xl font-bold">Upload invoice</p>
      <div className="flex flex-col gap-4  mt-3">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3 ">
          <div className="flex flex-col    ">
            <label htmlFor="ref_type " className="text-sm font-semibold">
              Ref Type*
            </label>
            <select
              className="text-sm border rounded p-2 focus:outline-none"
              onChange={(e) => {
                setRefType(e.target.value);
              }}
              name=""
              id="ref_type"
            >
              <option value="" hidden>
                Select
              </option>
              {Object.values(PaymentRefType).map((type) => (
                <option value={type} id={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col    ">
            <label htmlFor="order-id " className="text-sm font-semibold">
              Ref ID*
            </label>
            <input
              id="order-id"
              className="text-sm border rounded p-2 focus:outline-none"
              type="text"
              placeholder="ORD-XX-XXXX-XXXX"
              value={ref_id}
              onChange={(e) => {
                setRefId(e.target.value);
              }}
            />
          </div>
          <div className="flex flex-col    ">
            <label htmlFor="order-id" className="text-sm font-semibold">
              Invoice No*
            </label>
            <input
              id="order-id"
              className="text-sm border rounded p-2 focus:outline-none"
              type="text"
              placeholder="Invoice no"
              value={invoiceNo}
              onChange={(e) => {
                setInvoidNo(e.target.value);
              }}
            />
          </div>
          <div className="flex flex-col    ">
            <label htmlFor="order-id " className="text-sm font-semibold">
              Qty*
            </label>
            <input
              id="order-id"
              className="text-sm border rounded p-2 focus:outline-none"
              type="number"
              placeholder="Qty"
              value={qty}
              onChange={(e) => {
                setQty(parseInt(e.target.value));
              }}
            />
          </div>
          <div className="flex flex-col    ">
            <label htmlFor="order-id" className="text-sm font-semibold">
              Amount*
            </label>
            <input
              id="order-id"
              className="text-sm border rounded p-2 focus:outline-none"
              type="number"
              placeholder="Amount"
              value={amount}
              onChange={(e) => {
                setAMount(parseInt(e.target.value));
              }}
            />
          </div>
        </div>
        <div className="h-80">
          {file ? (
            <PdfTile url={URL.createObjectURL(file)} />
          ) : (
            <FileDropZone
              className="h-full w-full"
              multiSelect={false}
              onChange={(file) => setFile(file[0])}
              type="application/pdf"
              required
            />
          )}
        </div>
      </div>
      <div className="flex justify-end mt-3">
        <StadiumButton onTap={createInvoice}>Upload</StadiumButton>
      </div>{" "}
      <LoadingWidget loading={loading} />
    </div>
  );
};

export default CreateInvoice;
