export const formToObject = (form: FormData) => {
  let data: { [key: string]: any } = {};
  //  let i = 0;
  for (let [key, value] of form) {
    const t: any = value;
    if (!isNaN(t)) {
      data[key] = parseInt(t);
    } else if (t === "true") {
      data[key] = true;
    } else if (t === "false") {
      data[key] = false;
    } else {
      data[key] = value;
    }
  }
  return data;
};
