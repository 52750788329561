import { mdiArrowLeft } from "@mdi/js";
import Icon from "@mdi/react";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import {
  useToast,
  ToastType,
} from "../../../../context_providers/toast/toast_ctx";
import {
  GetMyTaskSessionsRepo,
  TaskSessionModel,
} from "../../../../service/repos/ops/task_session_repo";
import { timeDiff, timeSince } from "../../../../utils/date_util";
import LoadingWidget from "../../../components/loading_spinner";
import Pagination from "../../../new_components/common/pagination";

interface EmpAttendanceHistoryPageProps {}

const EmpAttendanceHistoryPage: FunctionComponent<
  EmpAttendanceHistoryPageProps
> = () => {
  const { showToast } = useToast();
  const [sessions, setSessions] = useState<TaskSessionModel[]>([]);
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(100);
  const [total, setTotal] = useState(0);
  const getTaskSessions = async () => {
    setLoading(true);
    const res = await GetMyTaskSessionsRepo({ page, count });
    if (res.success) {
      setSessions(res.data.data);
      if (res.data.metadata[0]) setTotal(res.data.metadata[0].total);
    } else showToast({ type: ToastType.error, text: res.error ?? "" });
    setLoading(false);
  };
  useEffect(() => {
    getTaskSessions();
  }, []);
  return (
    <>
      <div
        className="sm:hidden  flex-col justify-between overflow-hidden   "
        style={{ height: "90vh" }}
      >
        <div className="" style={{ height: "92%" }}>
          <div className="flex gap-2 my-1 items-center ml-2">
            <button onClick={() => window.history.back()} className=" p-2">
              <Icon path={mdiArrowLeft} className="w-5 h-5"></Icon>
            </button>
            <h1 className="text-lg font-medium   ">Task Sessions</h1>
          </div>
          <div
            className="overflow-auto flex  flex-col"
            style={{ height: "90%" }}
          >
            {sessions.map((session, i) => (
              <div className="text-sm   border rounded-md m-2 p-2 " key={i}>
                <div className="py-2">
                  <p>
                    Start :
                    {moment(session.on_duty).format("DD MMM YYYY hh:mm a")}
                  </p>
                  <p>
                    Location :{session.on_duty_lat},{session.on_duty_long}
                  </p>
                </div>
                <hr />
                <div className="py-2">
                  <p>
                    End :
                    {moment(session.off_duty).format("DD MMM YYYY hh:mm a")}
                  </p>
                  <p>
                    Location :{session.off_duty_lat},{session.off_duty_long}
                  </p>
                </div>
                <div className="flex justify-end">
                  Duration :
                  {session.on_duty &&
                    session.off_duty &&
                    timeDiff(session.on_duty, session.off_duty)}{" "}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="">
          <Pagination
            itemCount={sessions.length}
            page={page}
            total={total}
            count={count}
            onChange={(page) => setPage(page)}
          />
        </div>
      </div>
      <div
        className="hidden sm:flex  flex-col justify-between overflow-hidden p-5  "
        style={{ height: "100vh" }}
      >
        <div className="flex gap-2 my-1 items-center">
          <button
            onClick={() => window.history.back()}
            className="border rounded-full p-2"
          >
            <Icon path={mdiArrowLeft} className="w-6 h-6"></Icon>
          </button>
          <h1 className="text-lg font-medium  ">Task Sessions</h1>
        </div>
        <div
          style={{ height: "95%" }}
          className="border rounded overflow-auto bg-white "
        >
          <table className="w-full  text-sm ">
            <thead className="rounded shadow ">
              <tr className="text-xs sticky top-0  z-10 whitespace-nowrap">
                <th className="px-2 py-2"> Start time</th>
                <th className="px-2 py-2">Location</th>
                <th className="px-3 py-2">End time</th>
                <th className="px-3 py-2">Location</th>
                <th className="px-3 py-2">Duration</th>
              </tr>
            </thead>
            <tbody>
              {sessions.map((d, i) => (
                <tr className="border-b text-xs whitespace-nowrap" key={i}>
                  <td className="px-3 py-2" align="center">
                    {moment(d.on_duty).format("DD MMM YYYY hh:mm a") ?? "---"}
                  </td>
                  <td className="px-3 py-2" align="center">
                    <div className="">
                      {d.on_duty_lat + "," + d.on_duty_long}
                    </div>
                  </td>
                  <td className="px-3 py-2" align="center">
                    {moment(d.off_duty).format("DD MMM YYYY hh:mm a") ?? "---"}
                  </td>
                  <td className="px-3 py-2" align="center">
                    <div className="">
                      {d.off_duty_lat + "," + d.off_duty_long}
                    </div>
                  </td>
                  <td className="px-3 py-2" align="center">
                    <div className="">
                      {d.on_duty &&
                        d.off_duty &&
                        timeDiff(d.on_duty, d.off_duty)}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="">
          <Pagination
            itemCount={sessions.length}
            page={page}
            total={total}
            count={count}
            onChange={(page) => setPage(page)}
          />
        </div>
      </div>
      <LoadingWidget loading={loading} />
    </>
  );
};

export default EmpAttendanceHistoryPage;
