import { FunctionComponent, useState } from "react";
import {
  useToast,
  ToastType,
} from "../../../../../../context_providers/toast/toast_ctx";
import { CreateUserRepo } from "../../../../../../service/repos/customer/customer_repo";
import LoadingWidget from "../../../../../components/loading_spinner";

interface AddCustomerWidgetProps {
  onAdded: (user: {
    uid: number;
    first_name: string;
    last_name: string;
    mobile?: string;
    email?: string;
  }) => void;
}

const AddCustomerWidget: FunctionComponent<AddCustomerWidgetProps> = ({
  onAdded,
}) => {
  const { showToast } = useToast();
  const [customer, setCustomer] = useState<{
    first_name: string;
    last_name: string;
    mobile?: string;
    email?: string;
  }>();
  const [loading, setLoading] = useState(false);
  const createCustomer = async () => {
    setLoading(true);
    const res = await CreateUserRepo(customer!);
    if (res.success) {
      showToast({ type: ToastType.success, text: "Created Successfull !" });
      setLoading(false);
      return onAdded(res.data);
    } else {
      showToast({ type: ToastType.error, text: res.error });
    }
    setLoading(false);
  };
  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (window.confirm("Are your to create new customer ?")) {
            createCustomer();
          }
        }}
        className="flex flex-col gap-2"
      >
        <div className="grid grid-cols-1 md:grid-cols-3 items-center">
          <label htmlFor="first_name">First name | Business *</label>
          <input
            required
            type="text"
            className="col-span-2 p-2 rounded bg-gray-100 focus:outline-none"
            id="first_name"
            placeholder="First name | Business name"
            value={customer?.first_name}
            onChange={(e) =>
              setCustomer((o) => ({ ...o!, first_name: e.target.value }))
            }
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 items-center">
          <label htmlFor="last_name">Last name </label>
          <input
            type="text"
            className="col-span-2 p-2 rounded  bg-gray-100 focus:outline-none"
            id="last_name"
            placeholder="Last name"
            value={customer?.last_name}
            onChange={(e) =>
              setCustomer((o) => ({ ...o!, last_name: e.target.value }))
            }
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 items-center">
          <label htmlFor="mobile">Mobile *</label>
          <input
            required
            className="col-span-2 p-2 rounded bg-gray-100 focus:outline-none "
            id="mobile"
            type="tel"
            pattern="[1-9]{1}[0-9]{9}"
            name="mobile"
            placeholder="10 digits"
            value={customer?.mobile}
            onChange={(e) =>
              setCustomer((o) => ({ ...o!, mobile: e.target.value }))
            }
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 items-center">
          <label htmlFor="email">Email</label>
          <input
            type="text"
            className="col-span-2 p-2 rounded bg-gray-100 focus:outline-none"
            id="email"
            placeholder="Valid email address"
            value={customer?.email}
            onChange={(e) =>
              setCustomer((o) => ({ ...o!, email: e.target.value }))
            }
          />
        </div>
        <div className="flex justify-center mt-3">
          <button
            onClick={() => {}}
            className="bg-myPrimaryColor font-semibold text-sm text-white rounded-3xl px-3 py-1 hover:scale-110 transform duration-300"
          >
            Submit
          </button>
        </div>
      </form>{" "}
      <LoadingWidget loading={loading} />
    </>
  );
};

export default AddCustomerWidget;
