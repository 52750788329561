import { mdiMagnify } from "@mdi/js";
import Icon from "@mdi/react";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../context_providers/auth/authProvider";
import { useTaskSession } from "../../../context_providers/task_session_ctx";
import {
  useToast,
  ToastType,
} from "../../../context_providers/toast/toast_ctx";
import { AddressModel } from "../../../service/models/address_model";
import { WarehouseModel } from "../../../service/models/inventory/warehouse_model";
import { OpsTaskModel } from "../../../service/models/ops/ops_ticket";
import { CustomerDetailModel } from "../../../service/models/orders/customer_detail_model";
import { GetCustomerOfOrderApi } from "../../../service/repos/customer/customer_repo";
import {
  CreateOpsTicketApi,
  GetOpsTaskApi,
  UpdateOpsTaskApi,
  UpdateOpsTaskStatusApi,
} from "../../../service/repos/ops/ops_tickets_repo";

import {
  ACTION,
  MODULE,
  Ops_task_status,
  PriorityType,
} from "../../../utils/enums";
import { isValidJson } from "../../../utils/formDataToJson";
import { urls } from "../../../utils/urls";
import LoadingWidget from "../../components/loading_spinner";
import DrawerModal, {
  DrawerModalLoaction,
} from "../../new_components/common/drawer_modal";
import ModalDialog from "../../new_components/common/modal";
import EmployeeSearchWidget from "../../new_components/emp_search_widget";
import CommentWidget from "./comment_widget";
import TaskAttachmentWidget from "./components/attachment_widget";
import LogsWidget from "./logs_widget";

interface MyOpsTaskDetailPageProps {
  location: any;
}

const MyOpsTaskDetailPage: FunctionComponent<MyOpsTaskDetailPageProps> = (
  props
) => {
  const { showToast } = useToast();
  const { session, onDuty } = useTaskSession();
  const { user, isAuthorised } = useAuth();

  const [loading, setLoading] = useState(false);
  const [showLogs, setShowLogs] = useState<boolean>(false);
  const [showComments, setShowComments] = useState<boolean>(false);

  const [data, setData] = useState<OpsTaskModel>();
  const [deviceIds, setDeviceIds] = useState<
    { device_id: string; name: string }[]
  >([]);

  const [showAttachment, setShowAttachment] = useState(false);

  const getTask = async (id: string) => {
    setLoading(true);
    const res = await GetOpsTaskApi(id);

    if (res.success) {
      setData({ ...res.data });
      if (res.data.device_ids && isValidJson(res.data.device_ids)) {
        setDeviceIds(JSON.parse(res.data.device_ids));
      }
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  const updateStatus = async (status: string) => {
    setLoading(true);
    const res = await UpdateOpsTaskStatusApi({ id: data!.id ?? "", status });

    if (res.success) {
      setData((o) => ({ ...o!, status }));
      showToast({ type: ToastType.success, text: res.message ?? "" });
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };

  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    const id = query.get("id");

    if (id) {
      getTask(id);
    }
  }, []);
  const edit = onDuty && data?.active && data.emp_id === user?.uid!;
  return (
    <div className="p-2 sm:p-5 text-sm sm:text-base">
      {showAttachment && (
        <ModalDialog
          show={showAttachment}
          title="Attachment"
          onClose={() => setShowAttachment(false)}
        >
          <TaskAttachmentWidget
            task_id={data?.id}
            attachments={data?.attachments || []}
            onRefresh={function (): void {}}
          />
        </ModalDialog>
      )}
      {showComments && (
        <DrawerModal
          show={showComments}
          title={"Comments"}
          location={DrawerModalLoaction.right}
          onClose={() => {
            setShowComments(false);
          }}
        >
          <CommentWidget task_id={data!.id} active={data!.active} />
        </DrawerModal>
      )}
      {showLogs && (
        <DrawerModal
          show={showLogs}
          title={"Log history"}
          location={DrawerModalLoaction.right}
          onClose={() => {
            setShowLogs(false);
          }}
        >
          <LogsWidget task_id={data!.id} />
        </DrawerModal>
      )}

      <div className="flex justify-between flex-wrap">
        <h1 className="text-lg font-medium">Task Detail</h1>
      </div>
      <h3>Task ID :{data?.id}</h3>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-2 text-sm">
        <div className="flex flex-col h-full  border bg-white rounded-lg">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2     p-2">
            <div className="flex flex-col ">
              <label htmlFor="order_id">Order ID</label>
              <div className="flex gap-2 border rounded">
                <input
                  disabled={true}
                  value={data?.order_id}
                  id="order_id"
                  type="text"
                  name="order_id"
                  placeholder="Order Id"
                  className=" px-2 p-1 focus:outline-none w-full "
                />
              </div>
            </div>

            <div className="flex   flex-col">
              <label htmlFor="device">Client Name</label>
              <input
                disabled={true}
                value={data?.cust_name}
                type="text"
                name="customer_name"
                className="border rounded px-2 p-1 focus:outline-none "
              />
            </div>

            <div className="flex   flex-col">
              <label htmlFor="device">Contact No.</label>
              <input
                disabled={true}
                value={data?.cust_mobile}
                type="text"
                name="customer_mobile"
                className="border rounded px-2 p-1 focus:outline-none"
              />
            </div>

            <div className="flex    flex-col">
              <label htmlFor="device">Alternative No.</label>
              <input
                disabled={true}
                value={data?.cust_alt_mobile}
                type="text"
                name="customer_alt_mobile"
                className="border rounded px-2 p-1 focus:outline-none "
              />
            </div>

            <div className="flex flex-col   md:col-span-2  ">
              <label htmlFor="device">Address</label>
              <textarea
                disabled={true}
                value={data?.cust_address}
                cols={35}
                rows={2}
                name="customer_address"
                className="border rounded px-2 p-1 focus:outline-none "
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col h-full  border bg-white rounded-lg">
          <div className="grid grid-cols-1 md:grid-cols-2  gap-2     p-2">
            <div className="  ">
              <div className="flex  flex-col   ">
                <label htmlFor="warehouse">Office*</label>
                <div className="border rounded px-2 py-1">
                  {data?.warehouse_name}
                </div>
              </div>
            </div>
            <div className=" ">
              <div className="flex flex-col">
                <label htmlFor="type">Task Type*</label>
                <div className="border rounded px-2 p-1 focus:outline-none w-full">
                  {data?.type}
                </div>
              </div>
            </div>
            <div className="">
              {" "}
              <div className="flex      flex-col">
                <label htmlFor="device">Responsible person 1</label>
                <div className="border rounded px-2 p-1 focus:outline-none w-full h-8">
                  {data?.emp_name}
                </div>
              </div>
            </div>
            <div className="">
              {" "}
              <div className="flex      flex-col">
                <label htmlFor="device">Responsible person 2</label>
                <div className="border rounded px-2 p-1 focus:outline-none w-full h-8">
                  {data?.emp_name2}
                </div>
              </div>{" "}
            </div>
            <div className="">
              <div className="flex   flex-col">
                <label htmlFor="device">Status*</label>
                <select
                  disabled={!edit}
                  value={data?.status}
                  onChange={(e) => {
                    updateStatus(e.target.value);
                  }}
                  className="border rounded px-2 p-1 focus:outline-none w-full"
                  name="status"
                  id="status"
                >
                  <option value="" hidden>
                    Select ....
                  </option>
                  {Object.values(Ops_task_status).map((type, i) => (
                    <option key={i} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="">
              {" "}
              <div className="flex     flex-col">
                <label htmlFor="date">Date</label>
                <div className="border rounded px-2 p-1 focus:outline-none w-full h-8">
                  {data?.date ? moment(data.date).format("lll") : "--"}
                </div>
              </div>{" "}
            </div>

            <div className="">
              <div className="flex     flex-col">
                <label htmlFor="date">Due Date</label>
                <div className="border rounded px-2 p-1 focus:outline-none w-full">
                  {data?.last_date
                    ? moment(data.last_date).format("lll")
                    : "--"}
                </div>
              </div>
            </div>
            <div className="flex     flex-col">
              <label htmlFor="date">Device ID</label>
              <div className="flex flex-wrap gap-2 col-span-2 text-xs  ">
                {deviceIds.map((d, i) => (
                  <div className="border rounded-3xl p-1 flex gap-1" key={i}>
                    <p>
                      {d.device_id}{" "}
                      <span style={{ fontSize: 8 }}>({d.name})</span>
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" border bg-white rounded  p-5 my-5">
        <div className="flex gap-1 flex-col items-start flex-wrap">
          <label htmlFor="device">Description</label>
          <textarea
            disabled={true}
            value={data?.description}
            cols={35}
            rows={2}
            name="device"
            placeholder="Short note "
            className="border rounded px-2 p-1 focus:outline-none w-full "
          />
        </div>{" "}
        <div className="flex flex-wrap gap-5">
          <div className="flex flex-col ">
            <label htmlFor="order_id">Complaint ID</label>
            <div className="flex gap-2 border rounded w-min">
              <input
                disabled={true}
                value={data?.complaint_id}
                id="order_id"
                type="text"
                name="order_id"
                placeholder="Order Id"
                className=" px-2 p-1 focus:outline-none  "
              />
            </div>
          </div>
          <div className="flex flex-wrap items-center  justify-between   w-min">
            <label htmlFor="priority" className="  ">
              Priority
            </label>
            <select
              disabled={true}
              required
              value={data?.priority}
              name="priority"
              id="priority"
              className={` px-2 py-1 w-48  rounded border-2 focus:outline-none  ${
                data?.priority === PriorityType.low ? "bg-yellow-400" : ""
              } `}
            >
              <option value="" disabled hidden>
                Select
              </option>
              {Object.values(PriorityType).map((d) => (
                <option value={d} className="bg-white">
                  {d}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="flex gap-1 flex-wrap my-5">
        <button
          onClick={() => setShowComments(true)}
          className="border rounded px-2 py-1"
        >
          Comment
        </button>
        <button
          onClick={() => setShowLogs(true)}
          className="border rounded px-2 py-1"
        >
          History
        </button>
        <button
          onClick={() => setShowAttachment(true)}
          className="border rounded px-2 py-1"
        >
          Attachment
        </button>
      </div>
      <LoadingWidget loading={loading} />
    </div>
  );
};

export default MyOpsTaskDetailPage;
