import { Redirect, useHistory } from "react-router-dom";

import { useCallback, useEffect, useState } from "react";
import { GoogleLogin } from "react-google-login";
import {
  useToast,
  ToastType,
} from "../../../context_providers/toast/toast_ctx";
import { useAuth } from "../../../context_providers/auth/authProvider";
import Icon from "@mdi/react";
import { mdiArrowRight } from "@mdi/js";
import { EmailLoginApi } from "../../../service/repos/auth_repo";
import { formToObject } from "../../../utils/formDataToJson";
import { urls } from "../../../utils/urls";
import debounce from "lodash.debounce";
import LoadingWidget from "../../../context_providers/modal/loader";

const LoginPageWidget = (props: any) => {
  const history = useHistory();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  // const [showReset, setShowReset] = useState(false);
  // const [showPassword, setShowPassword] = useState(false);
  const { google_login, user } = useAuth();
  console.log("login page ", user);

  const debouncedHandler = useCallback(
    debounce((query) => {
      handleSubmit(query);
    }, 500),
    []
  );

  const handleSubmit = async (data: any) => {
    setLoading(true);
    const res = await EmailLoginApi(data);
    console.log({ res });

    if (res.success) {
      showToast({ type: ToastType.success, text: res.message });
      const url = new URL(res.data);
      console.log(window.location);

      if (res.data) {
        var a = document.createElement("a");

        a.href = window.location.origin + url.pathname + url.search;
        a.click();
        a.remove();
        // history.replace(url.pathname + url.search);
      }
    } else {
      showToast({ type: ToastType.error, text: res.error });
    }
    setLoading(false);
  };
  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data: any = formToObject(new FormData(e.currentTarget));
    if (!data.email) {
      return;
    }
    debouncedHandler(data);
  };
  if (user) {
    return <Redirect to={"/"} />;
  }
  return (
    <>
      {/* {authState.error !== "" && (
        <ModalDialog
          title="Alert"
          show={authState.error !== ""}
          onClose={() => {
            dispatch(clearError());
          }}
        >
          <ErrorPage message={authState.error!} />
        </ModalDialog>
      )} */}
      {/* {showReset && (
        <ModalDialog
          title="Forget password ?"
          show={showReset}
          onClose={() => {
            setShowReset(false);
          }}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              const data = formToObject(new FormData(e.currentTarget));
              dispatch(ForgetPasswordThunk(data));
            }}
            className="p-10 flex flex-col gap-3"
          >
            <label htmlFor="username">Email</label>
            <input
              required
              className=" border rounded px-2 py-1"
              type="email"
              name="username"
              id="usename"
              placeholder="Enter Email address"
            />

            <button
              type="submit"
              className="border px-3 py-1 rounded hover:bg-black hover:text-white"
            >
              Submit
            </button>
            <p className="text-xs">
              Note: Verification link will be sent to registered email.
            </p>
          </form>
        </ModalDialog>
      )} */}

      {/* New code re design */}

      <div className="h-screen w-screen flex select-none">
        <div className="login_left_screen w-screen lg:w-1/2 flex flex-col items-center justify-center px-6 lg:px-0">
          <div className="lg:w-auto login_card py-10 px-10 rounded-xl shadow-xl   flex flex-col items-center justify-center">
            <div className="">
              <div className="w-12 h-12">
                <img src="/logo512.png" alt="" className="w-full h-full" />
              </div>
              {/* <div className="w-16 h-6 mt-10">
              <img
                src="/assets/login-text.png"
                alt=""
                className="w-full h-full"
              />
            </div> */}
              {/* {!showReset ? ( */}
              <div className="text-white text-opacity-70">
                <div className="text-xl font-extrabold mt-10 text-white text-opacity-70">
                  Login
                </div>

                <div className="text-sm mt-1 mb-6">
                  To access the dashboard and its features
                </div>
                <div className="flex justify-center items-center my-3">
                  <div className="w-12 h-px bg-gray-300"></div>
                  <div className=" mx-2 text-sm ">Use kazam email id</div>
                  <div className="w-12 h-px bg-gray-300"></div>
                </div>
                <GoogleLogin
                  isSignedIn={true}
                  hostedDomain="kazam.in"
                  clientId={
                    "225306128297-9b0pj2cq68voiqk87qpod3fpuevghoje.apps.googleusercontent.com"
                  }
                  render={(renderProps) => (
                    <button
                      className="rounded-full   bg-hrmBgDark    flex justify-center gap-2 items-center px-12 py-2"
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                    >
                      <img
                        src="/assets/g.png"
                        alt=""
                        className="h-7 w-7 select-none"
                      />
                      <div className="text-sm font-semibold">
                        Sign in with google
                      </div>
                    </button>
                  )}
                  buttonText="Login"
                  onSuccess={(res: any) => {
                    const idToken = res.tokenObj.id_token;
                    google_login(idToken);
                  }}
                  onFailure={(e) => {}}
                  cookiePolicy={"single_host_origin"}
                />
                <div className="flex justify-center items-center my-3">
                  <div className="w-12 h-px bg-gray-300"></div>
                  <div className=" mx-2 text-sm ">Or</div>
                  <div className="w-12 h-px bg-gray-300"></div>
                </div>
                <form
                  autoComplete="off"
                  className="flex gap-2     bg-hrmBgDark rounded-full px-3"
                  onSubmit={submit}
                >
                  <input
                    required
                    className=" px-4 py-3 text-sm focus:outline-none  w-full  rounded-full bg-hrmBgDark  "
                    type="email"
                    name="email"
                    id="email"
                    placeholder="abc@kazam.in"
                  />
                  <button className="hover:translate-x-1 transform duration-200">
                    <Icon path={mdiArrowRight} size={1}></Icon>
                  </button>
                </form>
                {/* <div className="">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      const data: any = formToObject(
                        new FormData(e.currentTarget)
                      );
                 

                      dispatch(LoginThunk(data));
                    }}
                    className=""
                  >
                    <input
                      required
                      className=" px-4 py-3 text-sm focus:outline-none rounded-full w-full mt-4 bg-gray-100"
                      type="text"
                      name="username"
                      id="username"
                      placeholder="Email or mobile"
                    />{" "}
                    <br />
                    <div className="flex items-center bg-gray-100 rounded-full w-full mt-4 py-3">
                      <input
                        required
                        className="px-4 text-sm focus:outline-none w-5/6 bg-gray-100  "
                        type={showPassword ? "text" : "password"}
                        name="password"
                        id="password"
                        placeholder="Password"
                      />
                      <div
                        onClick={() => setShowPassword(!showPassword)}
                        className="text-gray-400 hover:text-gray-600 cursor-pointer"
                      >
                        <Icon
                          path={showPassword ? mdiEyeOutline : mdiEyeOffOutline}
                          size={0.8}
                          className=""
                        />
                      </div>
                    </div>
                    <div
                      className="text-right text-xs my-2 cursor-pointer"
                      onClick={() => setShowReset(true)}
                    >
                      Forgot password ?
                    </div>
                    <div className="mt-4 text-center">
                      <button className="py-3 px-16 text-sm text-white font-semibold rounded-full bg-black">
                        Submit
                      </button>
                    </div>
                  </form>
                </div> */}
              </div>
              {/* ) : (
                <div className="mt-12 px-2 ">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      const data = formToObject(new FormData(e.currentTarget));
                      dispatch(ForgetPasswordThunk(data));
                    }}
                    className=""
                  >
                    <input
                      required
                      className="  px-4 py-3 text-sm focus:outline-none rounded-full w-full  bg-gray-100"
                      type="email"
                      name="username"
                      id="usename"
                      placeholder="Enter Email address"
                    />{" "}
                    <p className="text-xs text-gray-400 mt-2 ml-2">
                      Verification link will be sent <br /> to registered email.
                    </p>
                    <div className="text-center">
                      <button
                        type="submit"
                        className="py-3 mt-4 px-16 text-sm text-white font-semibold rounded-full bg-black"
                      >
                        Submit
                      </button>
                    </div>
                    <p className="text-xs mt-4 text-gray-500">
                      Remembered your password?{" "}
                      <span
                        onClick={() => setShowReset(false)}
                        className=" text-sm underline cursor-pointer"
                      >
                        Login
                      </span>
                    </p>
                  </form>
                </div>
              )} */}
            </div>
          </div>
        </div>
        <div className="w-1/2 hidden  lg:flex flex-col items-center justify-center  ">
          <div className="flex flex-col items-center">
            <div className="flex items-center">
              <div className="w-16 h-16">
                <img src="/logo512.png" alt="" className="w-full h-full" />
              </div>
              <div className="ml-4">
                <div className="">
                  <img src="/assets/kazamtext.svg" alt="" />
                </div>
                <div className="font-semibold mt-1 text-lg">
                  Kazam Workspace
                </div>
              </div>
            </div>
            <div className="mt-5 mb-8 text-center">
              Track and Manage all your sales, financial transactions <br /> and
              growth of the company.
            </div>
            <div className="w-96">
              <img src="/assets/login-illus.png" alt="" className="w-full" />
            </div>
          </div>
        </div>
      </div>

      {/* Old Code before new design */}

      {/* <div className="flex h-screen w-full justify-center items-center bg-gray-800 select-none">
        <div className="fixed top-5 h-full filter blur-sm">
          <img
            className="h-full w-full  select"
            src="/images/kazam_logo.svg"
            alt=""
            unselectable="on"
          />
        </div>

        <div className=" z-10 px-5 py-10 rounded-xl shadow-lg md:px-20 md:py-20  bg-white bg-opacity-20">
          <div className="">
            <div
              className={`front   flex flex-col justify-center items-center`}
            >
              <div className="my-2 md:my-5  text-xl font-bold text-gray-200 text-center">
                Please Login
              </div>
              <GoogleLogin
                hostedDomain="kazam.in"
                clientId={
                  "225306128297-9b0pj2cq68voiqk87qpod3fpuevghoje.apps.googleusercontent.com"
                }
                render={(renderProps) => (
                  <button
                    className="rounded-lg bg-white  font-bold flex justify-center gap-2 items-center px-2 py-2"
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                  >
                    <img
                      src="/assets/g.png"
                      alt=""
                      className="h-7 w-7 select-none"
                    />
                    <div className="font-semibold tex-lg">
                      Login with Google
                    </div>
                  </button>
                )}
                buttonText="Login"
                onSuccess={(res: any) => {
                  const token = res.tokenObj.id_token;

                  dispatch(GoogleLoginThunk({ token }));
                }}
                onFailure={(e) => {
                 
                }}
                cookiePolicy={"single_host_origin"}
              />
              <p className="text-xs  text-gray-200 my-2">
                Please login with your Kazam email ID
              </p> */}
      {/* <form
                onSubmit={(e) => {
                  e.preventDefault();
                  const data: any = formToObject(new FormData(e.currentTarget));
                 

                  dispatch(LoginThunk(data));
                }}
                className=""
              >
                <div className="grid grid-cols-1 gap-2">
                  <div className="flex flex-col">
                    <label
                      htmlFor="username"
                      className="text-md font-semibold text-gray-200"
                    >
                      Username
                    </label>
                    <input
                      required
                      className="text-md px-3 py-2 focus:outline-none rounded-lg bg-gray-100"
                      type="text"
                      name="username"
                      id="username"
                      placeholder="Email or mobile"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label
                      htmlFor="password"
                      className="text-md font-semibold text-gray-200"
                    >
                      Password
                    </label>
                    <div className="flex items-center gap-2 rounded-lg bg-gray-100">
                      <input
                        required
                        className="text-md px-3 py-2 focus:outline-none rounded-lg bg-gray-100 "
                        type={showPassword ? "text" : "password"}
                        name="password"
                        id="password"
                        placeholder="Password"
                      />
                      <div
                        onClick={() => setShowPassword(!showPassword)}
                        className="text-gray-400 hover:text-gray-600"
                      >
                        <Icon
                          path={showPassword ? mdiEyeOutline : mdiEyeOffOutline}
                          size={1}
                          className="mx-2"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => setShowReset(true)}
                    className="text-xs hover:text-gray-600 duration-300 text-gray-200 cursor-pointer"
                  >
                    Forgot password ?
                  </div>
                  <button className="px-3 py-2 rounded-md  bg-gray-600 text-white hover:bg-gray-800 ">
                    Login
                  </button>
                </div>
              </form> */}
      {/* </div>
            <div className={`back ${showReset ? "block" : "hidden"}`}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  const data = formToObject(new FormData(e.currentTarget));
                  dispatch(ForgetPasswordThunk(data));
                }}
                className="  flex flex-col gap-3"
              >
                <label
                  htmlFor="username"
                  className="text-md font-semibold text-gray-200"
                >
                  Email
                </label>
                <input
                  required
                  className=" border rounded px-2 py-1"
                  type="email"
                  name="username"
                  id="usename"
                  placeholder="Enter Email address"
                />{" "}
                <p className="text-xs  text-gray-200">
                  Verification link will be sent to registered email.
                </p>
                <button
                  type="submit"
                  className="border px-3 py-1 rounded hover:bg-black text-white hover:border-transparent"
                >
                  Submit
                </button>
                <p className="text-xs font-medium   text-gray-200">
                  Remembered your password?{" "}
                  <span
                    onClick={() => setShowReset(false)}
                    className="font-semibold text-sm text-white cursor-pointer"
                  >
                    Login
                  </span>
                </p>
                <p className="text-white mt-5 text-xs">
                  Suggestion : Badam khaya karo
                </p>
              </form>
            </div>
          </div>
        </div>
      </div> */}
      {/* <LoadingWidget loading={authState.loading} /> */}
      {loading && <LoadingWidget />}
    </>
  );
};

export default LoginPageWidget;

// const LoginPage = () => {
//   return (<AuthContext.Consumer>{(auth) => {
//     return <LoginPageWidget auth={auth} />
//   }}
//   </AuthContext.Consumer>);
// }
// const LoginPage = () => {
//   const history=useHistory();
//   return (<LoginPageWidget /></AuthWrapperWidget>)
// }
// export default withRouter(LoginPage);
