import { FunctionComponent } from "react";
import { NavLink } from "react-router-dom";
import { urls } from "../../../../../utils/urls";
import Card from "../Card";

interface VideosCategoryProps {}

const VideosCategory: FunctionComponent<VideosCategoryProps> = () => {
  const links = [
    {
      name: "General",
      link: urls.general_learning,
      logo: "/academy/general.png",
    },
    {
      name: "CMS",
      link: urls.cms_learning,
      logo: "/academy/cms.png",
    },
    {
      name: "Sales",
      link: urls.sales_learning,
      logo: "/academy/sales.png",
    },
    {
      name: "Tally",
      link: urls.tally_learning,
      logo: "/academy/tally.png",
    },
  ];

  return (
    <div className="relative">
      <div className="flex flex-col items-center pt-20">
        <div className="w-11/12 lg:w-4/5">
          <div className="text-xl lg:text-2xl font-semibold">
            Select videos by category
          </div>
          <div className="grid grid-cols-2 lg:grid-cols-4 justify-items-stretch gap-4 lg:gap-12 mt-8">
            {links.map(
              (el: { name: string; link: string; logo: string }, i: number) => {
                return <Card data={el} />;
              }
            )}
          </div>
          <NavLink to={urls.videos_category}>
            <div className="flex flex-col items-end underline mt-3 lg:text-lg">
              View all category &gt; &gt;
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default VideosCategory;
