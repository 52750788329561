import { file } from "@babel/types";
import { useState } from "react";
import { TransactionDataModel } from "../../../service/models/trannsaction_model";
import { UploadTrasactionAttachmentRepo } from "../../../service/repos/transaction_repo";
import FileDropZone from "../../new_components/common/file_dropdown";
import StadiumButton from "../../new_components/common/stadium_button";
import ImageTile from "./image_tile";
import ModalDialog from "./modal";
import PdfTile from "./pdf_tile";

export interface FileUploadProps {
  onSubmit: (file: File[]) => void;
  files?: File[];
}
const FileUploadWidget: React.FC<FileUploadProps> = (props) => {
  const [files, setFiles] = useState<File[]>(props.files ? props.files : []);

  return (
    <>
      <div className="text-center">
        {/* <FileDropZone
          multiSelect={true}
          onChange={(files) => {
            setFiles((o) => [...o, ...files]);
          }}
          type="image/*"
          required
        /> */}
        <label htmlFor="files">Select files:</label>
        <input
          type="file"
          id="files"
          name="files"
          multiple
          accept="image/*,application/pdf"
          onChange={(e) => {
            if (e.target.files) {
              setFiles([...files, ...Array.from(e.target.files)]);
            }
          }}
        />{" "}
        <br />
        <div className="flex justify-center gap-2 my-2">
          <StadiumButton
            onTap={() => {
              const temp: any = document.getElementById("files");
              temp.value = "";
              setFiles([]);
            }}
          >
            Clear
          </StadiumButton>
          <StadiumButton onTap={() => props.onSubmit(files)}>
            Submit
          </StadiumButton>
        </div>
      </div>
      <div className="flex y-2 flex-wrap">
        {files.map((file, i) => {
          return file.type.startsWith("image") ? (
            <div className=" relative">
              <ImageTile key={i} url={URL.createObjectURL(file)} />
              <p
                className="cursor-pointer h-5 w-5 absolute top-2 left-2 text-red-500 bg-gray-200 rounded-full flex justify-center items-center"
                onClick={() => {
                  setFiles((o) => {
                    return o.filter((f, ind) => ind !== i);
                  });
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </p>
            </div>
          ) : (
            <div className=" relative">
              <PdfTile key={i} url={URL.createObjectURL(file)} />
              <p
                className="cursor-pointer h-5 w-5 absolute top-2 left-2 text-red-500 bg-gray-200 rounded-full flex justify-center items-center"
                onClick={() => {
                  setFiles((o) => {
                    return o.filter((f, ind) => ind !== i);
                  });
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </p>
            </div>
          );
        })}
      </div>
    </>
  );
};
export default FileUploadWidget;
