import { mdiDelete, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useState } from "react";
import {
  ToastType,
  useToast,
} from "../../../../context_providers/toast/toast_ctx";
import { OpeningStockModel } from "../../models/item_model";
import ItemSearchField from "../item_search_field";
import WarehouseSearchField from "../../warehouse/warehouse_search_field";
import { UTCToLocalDate } from "../../../../utils/date_util";
import moment from "moment";
import { WAREHOUSE_TYPE } from "../../models/warehouse_model";

interface OpeningStockProps {
  item: OpeningStockModel;
  onChange?: (d: { [key: string]: any }) => void;
  onSubmit?: (item: OpeningStockModel) => void;
  onDelete?: (item: OpeningStockModel) => void;
}

const OpeningStock: FunctionComponent<OpeningStockProps> = (props) => {
  const { showToast } = useToast();
  const [data, setData] = useState(props.item);
  return (
    <div className="grid grid-cols-10 gap-1  items-end">
      {" "}
      <div className="col-span-2 flex flex-col items-start text-sm w-full">
        <input
          placeholder="0"
          className="p-1 rounded  focus:outline-none w-full border"
          id="date"
          name="date"
          value={data.date ? moment(data.date).format("YYYY-MM-DD") : ""}
          // required={true}
          type="date"
          onChange={(e) => {
            const date = UTCToLocalDate(e.target.value)!;
            date.setHours(0, 0, 0, 0);
            setData((o) => ({
              ...o,
              date,
            }));
          }}
        ></input>
      </div>
      <div className="col-span-3 flex flex-col items-start text-sm w-full">
           <WarehouseSearchField type={WAREHOUSE_TYPE.warehouse}
          value={data.warehouse}
          onSelect={(d) => {
            setData((o) => ({ ...o, warehouse: d, warehouse_id: d.id }));
            if (props.onChange)
              props.onChange({ warehouse: d, warehouse_id: d.id });
          }}
        />
      </div>
      <div className="flex flex-col items-start text-sm w-full col-span-2">
        <input
          placeholder="0"
          className="p-1 rounded  focus:outline-none w-full border"
          id="unit"
          name="unit"
          value={data.unit_no}
          // required={true}
          type="number"
          onChange={(e) => {
            const unit_no = Number(e.target.value);
            if (props.onChange) props.onChange({ unit_no });
            setData((o) => ({
              ...o,
              unit_no,
            }));
          }}
        ></input>
      </div>
      <div className="flex flex-col items-start text-sm w-full col-span-2">
        <input
          placeholder="0"
          className="p-1 rounded  focus:outline-none w-full border"
          id="price"
          name="price"
          value={data?.price}
          // required={true}
          type="number"
          onChange={(e) => {
            const price = Number(e.target.value);
            if (props.onChange) props.onChange({ price });
            setData((o) => ({
              ...o,
              price,
            }));
          }}
        ></input>
      </div>
      {props.onSubmit && (
        <div className="">
          <button
            onClick={() => {
              if (!data.warehouse || !data.price || !data.unit_no) {
                showToast({
                  type: ToastType.error,
                  text: "Please fill the required fields",
                });
                return;
              }
              if (props.onSubmit) props.onSubmit(data);
            }}
            className="rounded bg-white p-1 shadow"
          >
            <Icon path={mdiPlus} className="h-5 w-5"></Icon>
          </button>
        </div>
      )}
      {props.onDelete && (
        <div className="">
          <button
            onClick={() => {
              props.onDelete!(data);
            }}
            className="rounded bg-white p-1 shadow"
          >
            <Icon path={mdiDelete} className="h-5 w-5 text-red-400"></Icon>
          </button>
        </div>
      )}
    </div>
  );
};

export default OpeningStock;
