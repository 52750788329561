import { FunctionComponent, useEffect, useState } from "react";
import { mdiApplicationEditOutline, mdiPencilOutline } from "@mdi/js";
import { mdiDeleteForever } from "@mdi/js";
import { Icon } from "@mdi/react";
import ModalDialog from "../../new_components/common/modal";
import WarehouseCreate from "./components/create_widget";
import { WarehouseModel } from "../../../service/models/inventory/warehouse_model";
import {
  CreateWareHouseApi,
  GetWareHouseListApi,
  UpdateWareHouseApi,
} from "../../../service/repos/warehouse_repo";
import LoadingWidget from "../../components/loading_spinner";
import Pagination from "../../new_components/common/pagination";
import {
  ToastType,
  useToast,
} from "../../../context_providers/toast/toast_ctx";

interface WarehouseListingProps {}

const WarehouseListing: FunctionComponent<WarehouseListingProps> = () => {
  const { showToast } = useToast();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(100);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [warehouses, setWarehouses] = useState<WarehouseModel[]>([]);
  const [showCreate, setShowCreate] = useState(false);
  const [updateData, setUpdateData] = useState<WarehouseModel>();
  useEffect(() => {
    getWarehouse();
  }, []);
  const getWarehouse = async () => {
    setLoading(true);
    const res = await GetWareHouseListApi({ page, count });

    if (res.success) {
      if (res.data.length > 0) {
        setWarehouses(res.data.data);
        setTotal(res.metadata.total);
      }
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };

  const onSubmit = async (data: WarehouseModel) => {
    setLoading(true);
    const res = await CreateWareHouseApi(data);

    if (res.success) {
      setWarehouses((o) => [res.data, ...o]);
      setShowCreate(false);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  const onUpdate = async (data: WarehouseModel) => {
    setLoading(true);
    const res = await UpdateWareHouseApi(data);

    if (res.success) {
      setWarehouses((o) =>
        o.map((d) => {
          if (d.id === updateData?.id) return res;
          else return d;
        })
      );
      setUpdateData(undefined);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };

  return (
    <div className="px-3 py-5 h-screen">
      {(showCreate || updateData) && (
        <ModalDialog
          show={showCreate || updateData !== null}
          onClose={() =>
            showCreate ? setShowCreate(false) : setUpdateData(undefined)
          }
          title={updateData ? "Update Warehouse" : "Add Warehouse"}
        >
          <WarehouseCreate
            data={updateData}
            onSubmit={updateData ? onUpdate : onSubmit}
          />
        </ModalDialog>
      )}
      <div className="flex justify-between my-3">
        <h1 className="font-bold text-2xl text-gray-500 ">Warehouses</h1>
        <div className="flex justify-end gap-2">
          <button
            onClick={() => setShowCreate(true)}
            className="rounded-lg border px-2 py-1 hover:bg-gray-800 hover:text-white"
          >
            ADD
          </button>
        </div>{" "}
      </div>
      {/* <div className="mt-3">
        <Pagination
          itemCount={warehouses.length}
          page={page}
          total={total}
          count={count}
          onChange={(p) => {
            setPage(p);
            // dispatch(GoToPageOrderThunk({ page: p, count }));
          }}
        />
      </div> */}
      <div className="my-2 overflow-auto" style={{ height: "81vh" }}>
        <table className="w-full   text-xs divide-y divide-gray-200 relative">
          <thead className="bg-gray-800  text-white py-2">
            <tr className="border">
              <th>ID</th>
              <th className="py-2">Warehouse</th>
              <th>Address</th>
              <th>City</th>
              <th>State</th>
              <th>Pincode</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {warehouses.length > 0 ? (
              warehouses.map((d, i) => (
                <tr className="border text-center " key={i}>
                  <td>{d.id}</td>
                  <td>{d.name}</td>
                  <td>{d.address}</td>
                  <td>{d.city}</td>
                  <td>{d.state}</td>
                  <td>{d.pincode}</td>

                  <td>
                    <div className="flex justify-center gap-2">
                      <button
                        onClick={() => setUpdateData(d)}
                        className="hover:text-blue-400  "
                      >
                        <Icon
                          path={mdiPencilOutline}
                          className="h-4 w-4 "
                        ></Icon>
                      </button>
                      <button className=" hover:text-blue-400">
                        <Icon
                          path={mdiDeleteForever}
                          className="h-4 w-4 "
                        ></Icon>
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4}>
                  <div className="h-56 text-center p-5 my-10">
                    <h5 className="text-gray-400 text-2xl">No Data found!</h5>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>{" "}
      <div className="mt-3">
        <Pagination
          itemCount={warehouses.length}
          page={page}
          total={total}
          count={count}
          onChange={(p) => {
            setPage(p);
            // dispatch(GoToPageOrderThunk({ page: p, count }));
          }}
        />
      </div>
      <LoadingWidget loading={loading} />
    </div>
  );
};

export default WarehouseListing;
