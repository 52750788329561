import {
  mdiAccount,
  mdiCheck,
  mdiClose,
  mdiEmail,
  mdiHome,
  mdiHomeCity,
  mdiMail,
  mdiMapMarker,
  mdiOfficeBuilding,
  mdiPencil,
  mdiPhone,
  mdiPlus,
} from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useState } from "react";
import {
  ModalType,
  useModal,
} from "../../../../../context_providers/modal/modal_context";

import { useOrderDetailCtx } from "../../../../../context_providers/order_detail_context";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";

import { CustomerDetailModel } from "../../../../../service/models/orders/customer_detail_model";
import { GetBillingProfilesOfUserApi } from "../../../../../service/repos/customer/billing_profile_repo";
import { AddressType } from "../../../../../utils/enums";
import ModalDialog from "../../../../new_components/common/modal";

import AddBillingProfileWidget from "./add_billing_profile_widget";

interface DeliveryProfileWidgetProps {}

const DeliveryProfileWidget: FunctionComponent<
  DeliveryProfileWidgetProps
> = () => {
  const { showModal, onClose } = useModal();
  const { showToast } = useToast();
  const { order, setOrder, onDeleveryProfileSelect } = useOrderDetailCtx();

  // const [addNew, setAddNew] = useState(false);
  const [edit, setEdit] = useState(false);
  // const [customers, setCustomers] = useState<CustomerDetailModel[]>([]);
  // const [loading, setLoading] = useState(false);

  // const getCustomers = async () => {
  //   setLoading(true);
  //   const res = await GetBillingProfilesOfUserApi(order.user_id);

  //   if (res.success) {
  //     setCustomers(res.data.billing_profiles);
  //   } else {
  //     showToast({ type: ToastType.error, text: res.error });
  //   }
  //   setLoading(false);
  // };
  // useEffect(() => {
  //   if (edit && order.user_id) getCustomers();
  // }, [edit]);
  return (
    <div className="bg-white   rounded-md text-sm  h-full">
      {order.delivery_profile && !edit ? (
        <div className="p-5">
          <div className="flex justify-end">
            <button
              // onClick={() => setEdit(true)}
              onClick={() => {
                showModal({
                  title: "Edit Billing Profile",
                  type: ModalType.modal,

                  container: (id) => (
                    <AddBillingProfileWidget
                      address_type="delivery"
                      type={order.type}
                      billing={order.delivery_profile}
                      user_id={order.user_id}
                      order_id={order.order_id}
                      onAdded={(data) => {
                        // setCustomers((o) =>
                        //   o.map((f) => (f.id === data.id ? data : f))
                        // );
                        // if (data.id === order.delivery_profile.id)
                        onDeleveryProfileSelect(data);
                        onClose(id);
                      }}
                    />
                  ),
                });
              }}
              className="cursor-pointer hover:scale-125 transform duration-300 text-myPrimaryColor font-semibold"
            >
              Edit
            </button>
          </div>
          <div className="">
            <div className="flex justify-between">
              <div className="">
                {" "}
                <div className="flex gap-1">
                  <span>
                    <Icon
                      path={
                        order.delivery_profile.address.type === AddressType.HOME
                          ? mdiHome
                          : order.delivery_profile.address.type ===
                            AddressType.OFFICE
                          ? mdiOfficeBuilding
                          : mdiHomeCity
                      }
                      className="h-4 w-4 text-gray-500"
                    ></Icon>
                  </span>
                  <p>
                    <b>{order.delivery_profile.address.type}</b>
                  </p>
                </div>
                <div className="flex gap-1">
                  <span>
                    <Icon
                      path={mdiAccount}
                      className="h-4 w-4 text-gray-500"
                    ></Icon>
                  </span>
                  <p>
                    <b>
                      {order.delivery_profile.firstName}{" "}
                      {order.delivery_profile.lastName}
                    </b>
                  </p>
                </div>
                <div className="flex gap-1">
                  <Icon
                    path={mdiPhone}
                    className="h-4 w-4 text-gray-500"
                  ></Icon>

                  <p>
                    {order.delivery_profile.mobile} ,{" "}
                    {order.delivery_profile.alt_mobile}
                  </p>
                </div>
                <div className="flex gap-1">
                  <Icon
                    path={mdiEmail}
                    className="h-4 w-4 text-gray-500"
                  ></Icon>
                  <p>{order.delivery_profile.email}</p>
                </div>
              </div>
              <div className="text-xs">
                <div className="flex gap-1">
                  GST # :<p>{order.delivery_profile.gst ?? "N/A"}</p>
                </div>
                <div className="flex gap-1">
                  CA # :<p>{order.delivery_profile.ca ?? "N/A"}</p>
                </div>
              </div>
            </div>
            <hr className="my-2" />
            <div className="flex gap-1">
              <Icon
                path={mdiMapMarker}
                size={1}
                className="  text-gray-500"
              ></Icon>
              <p className="text-xs">
                {order.delivery_profile.address.line1},
                {order.delivery_profile.address.line2},
                {order.delivery_profile.address.city},
                {order.delivery_profile.address.state},
                {order.delivery_profile.address.country}-
                {order.delivery_profile.address.pincode}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="">
          <div className="flex justify-end gap-2 p-5">
            <button
              onClick={() => {
                if (!order.user_id) {
                  showToast({
                    type: ToastType.info,
                    text: "Please select customer ",
                  });
                  return;
                }
                // setAddNew(true);
                showModal({
                  container: (id) => (
                    <AddBillingProfileWidget
                      address_type="delivery"
                      type={order.type}
                      user_id={order.user_id}
                      onAdded={(data) => {
                        onDeleveryProfileSelect(data);
                        setEdit(false);
                      }}
                    />
                  ),

                  type: ModalType.modal,
                  title: "Add new billing profile",
                });
              }}
              className="cursor-pointer   text-myPrimaryColor font-semibold p-1 bg-green-100 rounded"
            >
              <Icon path={mdiPlus} className="h-4 w-4"></Icon>
            </button>
            {edit && (
              <button
                onClick={() => {
                  setEdit(false);
                }}
                className="cursor-pointer   text-red-400 font-semibold p-1 bg-red-100 rounded"
              >
                <Icon path={mdiClose} className="h-4 w-4"></Icon>
              </button>
            )}
          </div>
          {/* <div className="flex flex-col divide-y  max-h-96 overflow-auto ">
            {customers.map((customer, i) => (
              <div className=" hover:bg-blue-50 px-5 py-3" key={i}>
                {order.delivery_profile.id === customer.id && (
                  <div className="flex justify-end">
                    <div className=" flex gap-1 border rounded-3xl border-green-400  text-green-400 px-2 py-1 text-xs">
                      <Icon path={mdiCheck} className="h-4 w-4"></Icon> Selected
                    </div>
                  </div>
                )}
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    if (customer.id !== order.delivery_profile.id) {
                      if (window.confirm("Are your sure ?"))
                        onCustomerSelect(customer);
                      else return;
                    }
                    setEdit(false);
                  }}
                >
                  <div className="flex gap-1">
                    <span>
                      <Icon
                        path={
                          customer.address.type === AddressType.HOME
                            ? mdiHome
                            : customer.address.type === AddressType.OFFICE
                            ? mdiOfficeBuilding
                            : mdiHomeCity
                        }
                        className="h-4 w-4 text-gray-500"
                      ></Icon>
                    </span>
                    <p>
                      <b>{customer.address.type}</b>
                    </p>
                  </div>
                  <div className="flex gap-1">
                    <span>
                      <Icon
                        path={mdiAccount}
                        className="h-4 w-4 text-gray-500"
                      ></Icon>
                    </span>
                    <p>
                      <b>
                        {customer.firstName} {customer.lastName}
                      </b>
                    </p>
                  </div>
                  <div className="flex gap-1">
                    <Icon
                      path={mdiPhone}
                      className="h-4 w-4 text-gray-500"
                    ></Icon>

                    <p>
                      {customer.mobile} , {customer.alt_mobile}
                    </p>
                  </div>
                  <div className="flex gap-1">
                    <Icon
                      path={mdiEmail}
                      className="h-4 w-4 text-gray-500"
                    ></Icon>
                    <p>{customer.email}</p>
                  </div>
                  <hr />
                  <div className="flex gap-1">
                    GST # :<p>{customer.gst ?? "N/A"}</p>
                  </div>
                  <div className="flex gap-1">
                    CA # :<p>{customer.ca ?? "N/A"}</p>
                  </div>
                  <hr className="my-2" />
                  <div className="flex gap-1">
                    <Icon
                      path={mdiMapMarker}
                      className="h-4 w-4 text-gray-500"
                    ></Icon>
                    <p className="">
                      {customer.address.line1},{customer.address.line2},
                      {customer.address.city},{customer.address.state},
                      {customer.address.country}-{customer.address.pincode}
                    </p>
                  </div>
                </div>
                <div className="flex justify-end ">
                  <div
                    onClick={() => {
                      showModal({
                        title: "Edit Billing Profile",
                        type: ModalType.modal,

                        container: (id) => (
                          <AddBillingProfileWidget
                            type={order.type}
                            billing={customer}
                            user_id={order.user_id}
                            onAdded={(data) => {
                              setCustomers((o) =>
                                o.map((f) => (f.id === data.id ? data : f))
                              );
                              if (data.id === order.delivery_profile.id)
                                onCustomerSelect(data);
                            }}
                          />
                        ),
                      });
                    }}
                    className=" flex gap-1 cursor-pointer  text-blue-400 px-2 py-1 text-xs hover:text-blue-600"
                  >
                    <Icon path={mdiPencil} className="h-4 w-4"></Icon> Edit
                  </div>
                </div>
              </div>
            ))}
          </div> */}
        </div>
      )}
    </div>
  );
};

export default DeliveryProfileWidget;
