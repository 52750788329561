import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { da } from "date-fns/locale";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import OrderItemModel from "../../../../../service/models/orders/order_item_model";
import { OrderModel } from "../../../../../service/models/orders/order_model";
import { getOrgNamespaceApi } from "../../../../../service/repos/cms/org_repo";
import { UTCToLocalDate } from "../../../../../utils/date_util";
import { formToObject, isValidJson } from "../../../../../utils/formDataToJson";
import AutoCompleteField from "../../../../new_components/common/autocomplete";
import InventoryDropDown from "../../../new_ops/components/inventory_dropdown";
import { InventoryStatus } from "../../../../../utils/enums";

interface DeviceAllotmentWidgetProps {
  order: OrderModel;
  onCreateOrderDevices: (data: {
    device_ids: string[];
    order_id: string;
    status: string;
    org_namespace: string;
    org_name: string;
    date: Date;
    item_id: string;
  }) => void;
}
const defaultData: {
  device_ids: string[];
  order_id: string;
  status: string;
  org_namespace: string;
  org_name: string;
  date: Date;
  item_id: string;
} = {
  device_ids: [],
  order_id: "",
  status: "",
  org_namespace: "",
  org_name: "",
  date: new Date(),
  item_id: "",
};
const OrderDeviceCreateWidget: FunctionComponent<
  DeviceAllotmentWidgetProps
> = ({ order, onCreateOrderDevices }) => {
  const [item, setItem] = useState<OrderItemModel>();
  const [data, setData] = useState<{
    device_ids: string[];
    order_id: string;
    status: string;
    org_namespace: string;
    org_name: string;
    date: Date;
    item_id: string;
  }>(defaultData);

  const [namespaces, setNameSpace] = useState<
    {
      name: string;
      namespace: string;
    }[]
  >([]);
  const [selectedDeviceIds, setSelectedDeviceIds] = useState<
    { device_id: string; name: string }[]
  >([]);
  useEffect(() => {
    getOrgNamespaceApi()
      .then((val) => {
        if (val.success) {
          console.log(val.data);

          setNameSpace(val.data);
        } else alert(val.error);
      })
      .catch((e) => alert(e.message));
  }, []);
  let bal = 0;
  if (item) {
    bal = item.qty - item.delivered_qty + item.returned_qty;
  }

  return (
    <>
      {!item ? (
        <div className=" overflow-auto  w-full">
          <table className="rounded-xl bg-gray-50 w-full ">
            <thead className="">
              <tr>
                <th className="px-3 py-2  text-xs text-right text-black uppercase tracking-wider">
                  Sl.NO
                </th>
                <th className="px-3 py-2  text-xs text-start text-black uppercase tracking-wider">
                  Product
                </th>

                <th className="px-3 py-2  text-xs text-right text-black uppercase tracking-wider">
                  Price
                </th>
                <th className="px-3 py-2  text-xs text-right text-black uppercase tracking-wider">
                  Qty
                </th>
                <th className="px-3 py-2  text-xs text-right text-black uppercase tracking-wider">
                  Delivered Qty
                </th>
                <th className="px-3 py-2  text-xs text-right text-black uppercase tracking-wider">
                  Installed Qty
                </th>
                <th className="px-3 py-2  text-xs text-right text-black uppercase tracking-wider">
                  Returned Qty
                </th>
                <th className="px-3 py-2  text-xs text-right text-black uppercase tracking-wider">
                  Net Qty
                </th>
              </tr>
            </thead>
            <tbody className="h-10 overflow-auto">
              {order.items.map((item, i) => (
                <tr
                  key={i}
                  className="hover:bg-gray-200 rounded-md   cursor-pointer"
                  onClick={() => {
                    if (item.qty <= item.delivered_qty - item.returned_qty) {
                      return alert(
                        "You cannot allot device more then the item qty"
                      );
                    }
                    setItem(item);
                  }}
                >
                  <td className="px-3 py-2 text-xs whitespace-nowrap text-right ">
                    {i + 1}
                  </td>
                  <td className="px-3 py-2 text-xs whitespace-nowrap text-start">
                    {item.name}
                  </td>
                  <td className="px-3 py-2 text-xs whitespace-nowrap text-right">
                    {"\u20b9"} {item.price}
                  </td>
                  <td className="px-3 py-2 text-xs whitespace-nowrap text-right">
                    {item.qty}
                  </td>
                  <td className="px-3 py-2 text-xs whitespace-nowrap text-right">
                    {item.delivered_qty}
                  </td>
                  <td className="px-3 py-2 text-xs whitespace-nowrap text-right">
                    {item.installed_qty}
                  </td>
                  <td className="px-3 py-2 text-xs whitespace-nowrap text-right">
                    {item.returned_qty}
                  </td>
                  <td className="px-3 py-2 text-xs whitespace-nowrap text-right">
                    {item.delivered_qty - item.returned_qty}
                  </td>
                  {/* <td className="px-3 py-2 text-xs whitespace-nowrap text-right">
                    {"\u20b9"} {item.qty * item.price}
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();

            const device_ids = selectedDeviceIds.map((d) => d.device_id);
            if (!item.id) {
              alert("Item id is missing!");
              return;
            }

            onCreateOrderDevices({
              ...data,
              item_id: item.id,
              device_ids: device_ids,
              order_id: order.order_id!,
            });
          }}
          className="text-sm md:w-96"
        >
          <div className="flex justify-between">
            <div className="">{item.name}</div>
            <div className="">
              Remaining Qty {item.qty - item.delivered_qty - item.returned_qty}
            </div>
          </div>
          <div className="flex flex-col mt-3 ">
            <label htmlFor="namespace">Organization*</label>
            {/* <AutoCompleteField  
              suggestions={namespaces.map((n, i) => ({
                label: n.name,
                value: n.namespace,
              }))}
              loading={false}
            /> */}
            <select
              required
              name="namespace"
              id="namespace"
              // className="bg-gray-100 rounded focus:outline-none p-1"
              className={`w-full text-xs  px-3 p-1 cursor-pointer bg-gray-100 rounded focus:outline-none`}
              value={JSON.stringify(
                data.org_name
                  ? { name: data.org_name, namespace: data.org_namespace }
                  : {}
              )}
              onChange={(e) => {
                const org = JSON.parse(e.target.value);
                setData((o) => ({
                  ...o,
                  org_name: org.name,
                  org_namespace: org.namespace,
                }));
              }}
            >
              <option value="" hidden>
                Select
              </option>
              {namespaces

                .sort((a, b) =>
                  a.name
                    ? a.name.localeCompare(b.name)
                    : a.namespace.localeCompare(b.namespace)
                )
                .map((n, i) => (
                  <option key={i} value={JSON.stringify(n)}>
                    {n.name ?? n.namespace}
                  </option>
                ))}
            </select>
          </div>
          <h3 className="mt-2">Status*</h3>
          <div className=" flex items-center gap-5">
            <div className="flex items-center gap-2">
              <input
                required
                type="radio"
                id="delivered"
                name="status"
                value="Delivered"
                checked={data.status === "Delivered"}
                onChange={(e) => {
                  setData((o) => ({
                    ...o,
                    status: "Delivered",
                  }));
                }}
              />
              <label htmlFor="delivered">Delivered</label>
            </div>
            <div className="flex items-center gap-2">
              <input
                required
                type="radio"
                id="installed"
                name="status"
                value="Installed"
                checked={data.status === "Installed"}
                onChange={(e) => {
                  setData((o) => ({
                    ...o,
                    status: "Installed",
                  }));
                }}
              />
              <label htmlFor="installed">Installed</label>
            </div>
          </div>
          <div className=" flex flex-col my-2">
            <label htmlFor="date">Date*</label>
            <input
              required
              name="date"
              type="date"
              id="date"
              className="rounded px-2 py-1 border"
              value={moment(data.date).format("YYYY-MM-DD")}
              onChange={(e) => {
                setData((o) => ({
                  ...o,
                  date: UTCToLocalDate(e.target.value)!,
                }));
              }}
            />
          </div>
          <InventoryDropDown
            status={[InventoryStatus.stocked, InventoryStatus.returnedNStocked]}
            product_id={[item.product_id!]}
            disabled={bal <= 0 || selectedDeviceIds.length === bal}
            selected={selectedDeviceIds}
            onSelect={(v) => setSelectedDeviceIds((o) => [...o, v])}
          />
          <div className="">
            <div className="flex flex-wrap gap-2 col-span-2 text-xs border-t py-1">
              {selectedDeviceIds
                .filter((f, i) => i < 11)
                .map((d, i) => (
                  <div className="border rounded-3xl p-1 flex gap-1" key={i}>
                    <p>
                      {d.device_id}{" "}
                      <span style={{ fontSize: 8 }}>({d.name})</span>
                    </p>
                    <span
                      onClick={() => {
                        setSelectedDeviceIds((o) =>
                          o.filter((v, j) => j !== i)
                        );
                      }}
                      className="hover:text-red-500"
                    >
                      <Icon path={mdiClose} size="16"></Icon>
                    </span>
                  </div>
                ))}
              {selectedDeviceIds.length - 10 > 0 && (
                <div className="border rounded-3xl p-1 flex gap-1">
                  + {selectedDeviceIds.length - 10} more
                </div>
              )}
            </div>
          </div>
          {/* <div className="flex flex-col mt-3">
            <label htmlFor="devices">Device IDs*</label>
            <textarea
              required
              placeholder="separed by comma (,)."
              className="border rounded-lg p-5"
              name="device_ids"
              id="devices"
              cols={30}
              rows={3}
            ></textarea>
          </div> */}

          <div className="flex justify-end mt-5 gap-3">
            <button
              onClick={() => setItem(undefined)}
              className="border rounded-lg px-3 py-1 text-sm hover:bg-black hover:text-white cursor-pointer"
              type="button"
            >
              Back
            </button>
            <button
              className="border rounded-lg px-3 py-1 text-sm hover:bg-black hover:text-white cursor-pointer"
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
      )}
    </>
  );
};

export default OrderDeviceCreateWidget;
