import { BaseModel } from "../../../service/models/base";
import { itemGroupDefaultValue, ItemGroupModel } from "./item_group_model";
import { unitMeasureDefaultValue, UnitMeasureModel } from "./item_unit_model";
import { WarehouseModel } from "./warehouse_model";

export interface ItemTaxDetail {
  type: string;
  hsn_sac: string;
  integreted: number;
  central: number;
  state: number;
  cess: number;
}
export const ItemType = {
  goods: "Goods",
  service: "Service",
};

export interface OpeningStockModel {
  id?: any;
  warehouse_id?: string;
  warehouse?: WarehouseModel;
  date: Date;
  unit_no: number;
  price: number;
}
export interface ItemModel extends BaseModel {
  type: string;
  item_code: string;
  name: string;
  description?: string;
  category?: string;
  subcategory?: string;
  manufacturer?: string;
  group: ItemGroupModel;
  unit: UnitMeasureModel;
  tax_applicable: boolean;
  tax_detail: ItemTaxDetail;
  image?: string;
  bom_applicable: boolean;
  bom: ItemBom;
  opening_stock: OpeningStockModel[];
}

//all params except item_id,price and qty should be deleted from ItemBom.items as it will be lookup from item masters
export interface BomItem {
  id: string;
  item_id: string;
  unit_no: number;
  price?: number;
  closing_qty?: number;
  item?: ItemModel;
}
export interface ItemBom extends BaseModel {
  name: string;
  item_id: string;
  item_name: string;
  unit_no: number;
  unit_measure: UnitMeasureModel;
  items: BomItem[];
}
export const itemTaxDetailDefault: ItemTaxDetail = {
  type: "",
  hsn_sac: "",
  integreted: 0,
  central: 0,
  state: 0,
  cess: 0,
};
export const bomDefaultValue: ItemBom = {
  name: "",
  item_id: "",
  item_name: "",
  unit_no: 0,
  unit_measure: unitMeasureDefaultValue,
  items: [],
};

export const opening_stock_default: OpeningStockModel = {
  warehouse_id: "",
  date: new Date(),
  unit_no: 0,
  price: 0,
};
export const itemDefaultValue: ItemModel = {
  item_code: "",
  name: "",
  group: itemGroupDefaultValue,
  bom_applicable: false,
  unit: unitMeasureDefaultValue,
  tax_applicable: false,
  tax_detail: itemTaxDetailDefault,
  type: ItemType.goods,
  opening_stock: [],
  bom: bomDefaultValue,
};
