import { mdiRefresh, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import LoadingWidget from "../../../context_providers/modal/loader";
import {
  useModal,
  ModalType,
} from "../../../context_providers/modal/modal_context";
import {
  useToast,
  ToastType,
} from "../../../context_providers/toast/toast_ctx";
import { FieldType } from "../../../procurement/components/create_view/form_field";
import TableView from "../../../procurement/components/table_comp";
import {
  fetchDelete,
  fetchGet,
  toQueryString,
} from "../../../service/api_client";
import SearchFieldWidget from "../../../ui/new_components/search_field";
import { urls } from "../../../utils/urls";
import CreateLedgerGroup from "../../accounts/ledger_group/create_ledger_group";
import { ItemGroupModel } from "../models/item_group_model";
import ItemGroupCreateScreen from "./item_group_create_screen";

interface ItemGroupListScreenProps {}

const ItemGroupListScreen: FunctionComponent<ItemGroupListScreenProps> = () => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/groups`;
  const { showModal, onClose } = useModal();

  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(50);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<ItemGroupModel[]>([]);
  const [search, setSearch] = useState("");
  const fetchData = async (prop: {
    search?: string;
    page: number;
    count: number;
    all?: boolean;
  }) => {
    setLoading(true);
    try {
      const res = await fetchGet(url + toQueryString(prop));
      if (res.success) {
        setData(res.data.data);
        if (res.data.metadata) {
          setTotal(res.data.metadata.total);
        }
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
      //   return { success: false, error: error.message };
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData({ page, count, search });
  }, [page]);
  useEffect(() => {
    if (search) fetchData({ page, count, search });
  }, [search]);
  const navaigation = useHistory();
  const onSelect = (d: ItemGroupModel) => {
    navaigation.push(urls.procurement_masters_item_groups + "/update/" + d.id);
  };
  const onDelete = async (d: ItemGroupModel) => {
    const propmt = window.prompt("Type 'delete' to confirm.");
    if (!propmt || propmt?.trim() !== "delete") {
      return;
    }
    setLoading(true);
    const res = await fetchDelete(url + "/" + d.id);
    if (res.success) {
      await fetchData({ page, count, search });
      showToast({ type: ToastType.success, text: res.message });
    } else {
      showToast({ type: ToastType.error, text: res.error });
    }
    setLoading(false);
  };
  return (
    <>
      <div
        id="page_container"
        className="flex flex-col justify-between h-full p-1"
      >
        <section id="top_section" className=" ">
          <div className="flex justify-between items-end my-1">
            <h1 className="font-bold text-2xl text-gray-500 flex items-center gap-3">
              Item Groups
              <button
                title="Refersh"
                onClick={() => fetchData({ page, count, search })}
              >
                <Icon path={mdiRefresh} className="h-6 w-6"></Icon>
              </button>
            </h1>
            <div className="flex justify-end   text-sm items-center gap-1 ">
              <SearchFieldWidget
                defaulValue={search}
                onClear={() => {
                  setSearch("");
                  fetchData({ page: 1, count });
                }}
                onSubmit={(val) => {
                  fetchData({ page: 1, count, search: val });
                  setPage(1);
                  setSearch("");

                  // setTimeout(() => {
                  //   setSearch(val);
                  // }, 10);
                }}
              />
              <div
                onClick={() => {
                  navaigation.push(
                    urls.procurement_masters_item_groups + "/create"
                  );

                  // showModal({
                  //   title: `Add Item Groups`,
                  //   type: ModalType.modal,
                  //   container(id) {
                  //     return (
                  //       <ItemGroupCreateScreen
                  //         onClose={(d) => {
                  //           if (d) {
                  //             setData((o) => {
                  //               o.pop();
                  //               return [d, ...o];
                  //             });
                  //             setTotal((o) => o + 1);
                  //           }
                  //           onClose(id);
                  //         }}
                  //       />
                  //     );
                  //   },
                  // });
                }}
                className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
              >
                <Icon path={mdiPlus} className="h-4 w-4"></Icon>Add
              </div>
            </div>
          </div>
          {/* <div className="h-52 w-full bg-green-200 "></div> */}
        </section>

        <TableView
          rows={data.map((group, i) => ({
            data: group,
            values: [group.name],
          }))}
          headers={[{ key: "name", title: "Name", type: FieldType.string }]}
          page={page}
          total={total}
          count={count}
          itemCount={data.length}
          onPageChange={setPage}
          onClick={onSelect}
          onEdit={onSelect}
          onDelete={onDelete}
        />
      </div>

      {loading && <LoadingWidget></LoadingWidget>}
    </>
  );
};

export default ItemGroupListScreen;
