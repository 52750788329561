import { FunctionComponent, useEffect, useState } from "react";
import { PieChart } from "react-minimal-pie-chart";
// @ts-ignore
import gsap from "gsap/all";
import { Tooltip } from "@material-ui/core";
import {
  Activity,
  AlertTriangle,
  Home,
  ShieldOff,
  Sunset,
  X,
} from "react-feather";
import {
  GetLeaveRequestsApproved,
  GetLeavesData,
} from "../../../../../service/repos/hrm/hrm_repo";
import {
  LeaveGetModel,
  LeaveRequest,
} from "../../../../../service/models/user_model";
import HrmLoader from "../../components/loading";
import HrmNotify from "../../../landing_screen/components/HrmNotify";
import HrmPopup from "../../components/HrmPopup";
import { LeaveType } from "../../../../../utils/enums";
import { useAuth } from "../../../../../context_providers/auth/authProvider";

interface LeavesBlockProps {}

const LeavesBlock: FunctionComponent<LeavesBlockProps> = () => {
  const [leaveData, setLeaveData] = useState<LeaveGetModel>();
  const [earnedHistory, setEarnedHistory] = useState<LeaveRequest[]>();
  const [medicalHistory, setMedicalHistory] = useState<LeaveRequest[]>();
  const [show, setShow] = useState<boolean>(false);
  const [showEarned, setShowEarned] = useState<boolean>(true);

  const { user } = useAuth();

  const getData = async () => {
    const res = await GetLeavesData();

    if (res.success) {
      setLeaveData(res.data);
      // animateData();
    }
  };

  const getHistoryData = async () => {
    const res = await GetLeaveRequestsApproved();

    if (res.success) {
      const tempEarned = res.data.filter(
        (el: LeaveRequest) => el.leave_name === LeaveType.paid_leave
      );
      setEarnedHistory(tempEarned);

      const tempMedical = res.data.filter(
        (el: LeaveRequest) => el.leave_name === LeaveType.medicle_leave
      );
      setMedicalHistory(tempMedical);
    }
  };

  useEffect(() => {
    getHistoryData();
  }, []);

  const animateData = () => {
    const tl = gsap.timeline();
    tl.fromTo(
      ".leave_card",
      0.5,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        stagger: 0.5,
      }
    );
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {leaveData ? (
        <div
          className={`w-full grid ${
            user?.emp_profile?.emp_type === "Intern"
              ? "lg:grid-cols-2"
              : "lg:grid-cols-3"
          }   gap-3`}
        >
          <DataCard
            leave_name="Earned"
            taken={leaveData?.leave_taken?.paid_leaves}
            total={leaveData?.leave_available?.paid_leaves}
            color1="#4B69FA"
            color2="#EAEAEA"
            handleHistory={() => {
              setShow(true);
              setShowEarned(true);
            }}
          />

          <DataCard
            leave_name="Medical"
            taken={leaveData?.leave_taken?.medical_leaves}
            total={leaveData?.leave_available?.medical_leaves}
            color1="#5D73FF"
            color2="#EAEAEA"
            handleHistory={() => {
              setShow(true);
              setShowEarned(false);
            }}
          />

          <div className="leave_card  grid grid-cols-2 items-center justify-items-center bg-white dark:bg-hrmCardDark py-6 px-3 rounded-2xl">
            <div className="flex flex-col items-center">
              <div className="font-bold">Unpaid</div>
              <div className="text-2xl lg:text-2xl font-semibold dark:text-white mt-3">
                {leaveData?.leave_taken?.unpaid_leaves}
              </div>
            </div>

            <div className="flex flex-col items-center">
              <div className="font-bold">WFH</div>
              <div className="text-2xl lg:text-2xl font-semibold dark:text-white mt-3">
                {leaveData?.leave_taken?.work_from_home}/
                {leaveData?.leave_available.work_from_home}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="">
          <HrmLoader className="w-10 h-10" />
        </div>
      )}
      {show && (
        <History
          close={() => setShow(false)}
          data={showEarned ? earnedHistory! : medicalHistory!}
        />
      )}
    </>
  );
};

export default LeavesBlock;

interface DataCardProps {
  leave_name: string;
  taken?: number;
  total?: number;
  color1: string;
  color2: string;
  handleHistory: () => void;
}

const DataCard: FunctionComponent<DataCardProps> = ({
  leave_name,
  taken,
  total,
  color1,
  color2,
  handleHistory,
}) => {
  const takenPercent =
    taken && total && Math.ceil((taken / (taken + total)) * 100);

  return (
    <div className=" relative leave_card  bg-white dark:bg-hrmCardDark  pt-4 pb-2 px-3 rounded-lg">
      <div
        className="absolute top-3 right-3 cursor-pointer"
        onClick={() => handleHistory()}
      >
        <Activity size={16} className="text-gray-500" />
      </div>
      <div className="">
        <div className=" text-black font-bold">{leave_name}</div>
        <div
          className="relative w-4/5  bg-opacity-10 h-2 my-2 rounded-xl"
          style={{ background: "#EAEAEA" }}
        >
          <div
            className="absolute top-0 left-0 h-2 bg-hrmBlue rounded-xl"
            style={{
              width: `${takenPercent}%`,
              background:
                "linear-gradient(90deg, rgba(75,105,250,1) 9%, rgba(75,105,250,1) 22%, rgba(234,234,234,1) 100%)",
            }}
          ></div>
        </div>
        <div className="flex justify-between items-center">
          {" "}
          <div className="text-2xl 2xl:text-2xl font-semibold ">
            {total ?? 0} <span className="text-sm"> left </span>
          </div>
          <div className="text-xs text-black text-opacity-60">
            {taken ?? 0} / {(total ?? 0) + (taken ?? 0)}
          </div>
        </div>
        <div className=""></div>
      </div>
    </div>
  );
};

interface HistoryProps {
  close: () => void;
  data: LeaveRequest[];
}

const History: FunctionComponent<HistoryProps> = ({ close, data }) => {
  return (
    <HrmPopup>
      <div className="relative bg-white pb-12 px-4 lg:py-12 lg:px-12 rounded-xl w-4/5 lg:w-1/2">
        <div className="absolute top-3  right-3">
          <X onClick={() => close()} className="cursor-pointer" />
        </div>
        <div className="hidden lg:grid grid-cols-4 pr-4  font-semibold text-primaryColor text-sm">
          <div className="">Leave name</div>
          <div className="">Reason</div>
          <div className="">From</div>
          <div className="">To</div>
        </div>
        <div className="max-h-96 mt-6 lg:mt-0 overflow-y-auto">
          {data.length > 0 ? (
            data.map((el, i) => {
              return (
                <div
                  className="grid grid-cols-2 lg:grid-cols-4 gap-y-1 lg:gap-y-0 lg:gap-x-5  mt-8 lg:pr-4 text-sm"
                  key={i}
                >
                  <div className="">{el.leave_name}</div>
                  <div className="col-span-2 lg:col-span-1">
                    {" "}
                    <span className="lg:hidden">Reason:</span> {el.message}
                  </div>
                  <div className="">
                    <span className="lg:hidden">From:</span> {el.from_date}
                  </div>
                  <div className="">
                    {" "}
                    <span className="lg:hidden">To:</span> {el.to_date}
                  </div>
                </div>
              );
            })
          ) : (
            <div className="">No data</div>
          )}
        </div>
      </div>
    </HrmPopup>
  );
};
