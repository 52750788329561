import { FunctionComponent, useEffect, useState } from "react";
import { ChevronLeft, ChevronRight } from "react-feather";
import { useHistory } from "react-router-dom";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import { MemoryGetModel } from "../../../../../service/models/user_model";
import { GetMemory } from "../../../../../service/repos/hrm/hrm_repo";
import { urls } from "../../../../../utils/urls";

interface MemoriesBlockProps {
  hrm: boolean;
}

const MemoriesBlock: FunctionComponent<MemoriesBlockProps> = ({ hrm }) => {
  const [latestMemory, setLatestMemory] = useState<MemoryGetModel>();
  const { showToast } = useToast();

  const getMemories = async () => {
    const params: { page: number; count: number } = {
      page: 1,
      count: 2,
    };
    const res = await GetMemory(params);

    if (res.success) {
      if (res.data.posts.length > 0) {
        setLatestMemory(res.data.posts[0]);
      }
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
  };

  const history = useHistory();

  useEffect(() => {
    getMemories();
  }, []);

  return (
    <div className="px-4 py-3  dark:bg-hrmCardDark rounded-b-2xl">
      <div className={`${hrm ? "text-black" : ""} font-thin `}>
        Latest memories
      </div>
      {/* <div className="flex items-center gap-6 mt-2">
        <div className="">
          <ChevronLeft className="text-hrmRed" size={32} />
        </div> */}

      {latestMemory ? (
        <div
          className="w-full cursor-pointer"
          onClick={() => {
            // setShowView(true);
            // setViewData(el);
            history.push(urls.hrm_memories_view, latestMemory);
          }}
        >
          <div className=" w-full h-40 2xl:h-52">
            <img
              src={latestMemory.files[0].url}
              alt=""
              className="h-full w-full object-cover"
            />
          </div>
          <div
            className={`${
              hrm ? "text-black" : "text-white"
            } dark:text-primaryColor text-sm  mt-1`}
          >
            {latestMemory.name} posted a new pic
          </div>
        </div>
      ) : (
        <div className="w-full">No memories</div>
      )}

      {/* 
        <div className="">
          <ChevronRight className="text-hrmRed" size={32} />
        </div>
      </div> */}
    </div>
  );
};

export default MemoriesBlock;
