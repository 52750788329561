import { FunctionComponent } from "react";
import SafetyChecklistView from "../../../components/safety_check_list_widget";
import { useOpsTask } from "../../../../../../context_providers/ops_task_engineer_detail/ops_task_engineer_detail";
import { fetchPut } from "../../../../../../service/api_client";
import {
  ToastType,
  useToast,
} from "../../../../../../context_providers/toast/toast_ctx";
import { useHistory } from "react-router-dom";
import { Ops_task_status } from "../../../../../../utils/enums";
import Icon from "@mdi/react";
import { mdiArrowLeft } from "@mdi/js";

interface ChecklistScreenProps {}

const ChecklistScreen: FunctionComponent<ChecklistScreenProps> = () => {
  const { goBack } = useHistory();
  const { data, setData } = useOpsTask();
  const { showToast } = useToast();
  const history = useHistory();
  const updateChecklist = async (checklist: {
    section_a: number[];
    section_b: number[];
    section_c: number[];
  }) => {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/new-ops/task/my-tasks/${data?.id}/checklist`;
    const res = await fetchPut(url, checklist);
    if (res.success) {
      setData((o) => ({ ...o!, checklist }));
      showToast({ text: "Updated successfully", type: ToastType.success });
      history.goBack();
    } else {
      showToast({ text: res.error, type: ToastType.error });
    }
  };

  return (
    <>
      <button
        className="flex gap-1 items-center border rounded-lg  px-2 py-1 mb-2 bg-white text-sm"
        onClick={() => {
          goBack();
        }}
      >
        {" "}
        <Icon path={mdiArrowLeft} size={0.6}></Icon>
        Back
      </button>

      <SafetyChecklistView
        editEnable={
          (data?.start_date &&
            !data.end_date &&
            data.status == Ops_task_status.under_progress)!
        }
        data={data?.checklist}
        onSubmit={function (checklist: {
          section_a: number[];
          section_b: number[];
          section_c: number[];
        }): void {
          updateChecklist(checklist);
        }}
      />
    </>
  );
};

export default ChecklistScreen;
