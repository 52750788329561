import { mdiDeleteForever } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useState } from "react";
import {
  inventoryDefaultValue,
  InventoryModel,
} from "../../../../service/models/inventory/inventory_model";
import { WarehouseModel } from "../../../../service/models/inventory/warehouse_model";
import { GetProductsApi } from "../../../../service/repos/products_repo";

import { ACTION, InventoryStatus, MODULE } from "../../../../utils/enums";
import EmployeeSearchWidget from "../../../new_components/emp_search_widget";
import { UserModel } from "../../../../context_providers/auth/user_model";

interface InventoryCreateProps {
  data?: InventoryModel[];
  onSubmit: (data: InventoryModel[]) => void;
  // onDelete: (device_id: string) => void;
  warehouses: WarehouseModel[];
}

const InventoryCreate: FunctionComponent<InventoryCreateProps> = (props) => {
  const [data, setData] = useState<InventoryModel>({
    ...inventoryDefaultValue,
  });
  useEffect(() => {
    if ((props.data || [])[0]) setData((props.data || [])[0]);
  }, []);
  const [products, setProducts] = useState<
    { id: number; name: string; price: number; sale_price: number }[]
  >([]);

  const getProducts = async () => {
    const res = await GetProductsApi();
    if (res.success) {
      setProducts(res.data!);
    }
  };
  // const getWarehouses = async () => {
  //   const res = await GetWareHouseListApi({ page: 0, all: true, count: 0 });

  //   if (res.success) {
  //     setWarehouses(res.data!);
  //   }
  // };
  useEffect(() => {
    // getWarehouses();
    getProducts();
  }, []);
  const disabled = props.data ? props.data.length > 1 : false;
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        // const postData = formToObject(new FormData(e.currentTarget)) as any;
        // const pro = products.find((p) => p.id === data.product_id);
        // if (pro) data.product_name = pro.name!;
        const res = disabled
          ? props.data!.map((d) => ({
              ...d,

              warehouse_id: data.warehouse_id,
              warehouse: data.warehouse,

              device: {
                ...d.device,
                product_id: data.device.product_id,
                product_name: data.device.product_name,
              },

              status: data.status,
            }))
          : [data];
        props.onSubmit(res);
      }}
      className="p-5"
    >
      <div className="grid grid-cols-1 md:grid-cols-3 text-sm gap-5">
        <div className="flex flex-col">
          <label htmlFor="warehouse">Warehouse*</label>
          <select
            onChange={(e) => {
              const warehouse = JSON.parse(e.target.value);
              setData({ ...data, warehouse_id: warehouse.id, warehouse });
            }}
            value={data.warehouse ? JSON.stringify(data.warehouse) : ""}
            required
            name="warehouse_id"
            id="warehouse"
            className="rounded-md px-2 py-1 border focus:outline-none"
          >
            <option value="" hidden>
              Select ...
            </option>
            {props.warehouses.map((w, i) => (
              <option value={JSON.stringify(w)}>{w.name}</option>
            ))}
          </select>
        </div>

        <div className="flex flex-col">
          <label htmlFor="product">Product*</label>
          <select
            onChange={(e) =>
              setData({
                ...data,
                device: { ...data.device, ...JSON.parse(e.target.value) },
              })
            }
            value={JSON.stringify({
              product_id: data.device.product_id,
              product_name: data.device.product_name,
            })}
            required
            name="product"
            id="product"
            className="rounded-md px-2 py-1 border focus:outline-none"
          >
            <option value="" hidden>
              Select ...
            </option>
            {products.map((p, i) => (
              <option
                value={JSON.stringify({
                  product_id: p.id,
                  product_name: p.name,
                })}
              >
                {p.name}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col">
          <label htmlFor="device_id">Device ID*</label>
          <input
            disabled={props.data !== undefined}
            onChange={(e) => setData({ ...data, device_id: e.target.value })}
            value={disabled ? "" : data.device_id}
            className="rounded-md px-2 py-1 border focus:outline-none"
            required
            type="text"
            id="device_id"
            name="device_id"
            placeholder="Device Id"
          />
        </div>

        <div className="flex flex-col">
          <label htmlFor="sim_no">Sim Number</label>
          <input
            disabled={disabled}
            onChange={(e) =>
              setData({
                ...data,
                device: { ...data.device, sim_no: e.target.value },
              })
            }
            value={disabled ? "" : data.device.sim_no}
            className="rounded-md px-2 py-1 border focus:outline-none"
            type="text"
            id="sim_no"
            name="sim_no"
            placeholder="Sim number"
          />
        </div>
        {/* <div className="flex flex-col">
          <label htmlFor="sim_ismi">Sim ISMI</label>
          <input
            disabled={disabled}
            onChange={(e) => setData({ ...data, sim_imsi: e.target.value })}
            value={disabled ? "" : data.sim_imsi}
            className="rounded-md px-2 py-1 border focus:outline-none"
            type="text"
            id="sim_ismi"
            name="sim_ismi"
            placeholder="Sim ISMI"
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="sim_status">Sim Status</label>
          <select
            disabled={disabled}
            onChange={(e) => setData({ ...data, sim_status: e.target.value })}
            value={disabled ? "" : data.sim_status}
            className="rounded-md px-2 py-1 border focus:outline-none"
            id="sim_status"
            name="sim_status"
            placeholder="Sim status"
          >
            <option value="" hidden>
              Select
            </option>
            <option value="Active">Active</option>
            <option value="Deactive">Deactive</option>
          </select>
        </div>

        <div className="flex flex-col">
          <label htmlFor="module_imei">Module IMEI</label>
          <input
            disabled={disabled}
            onChange={(e) => setData({ ...data, module_imei: e.target.value })}
            value={data.module_imei}
            className="rounded-md px-2 py-1 border focus:outline-none"
            type="text"
            id="module_imei"
            name="module_imei"
            placeholder="Module IMEI"
          />
        </div> */}
        <div className="flex flex-col">
          <label htmlFor="status">Status</label>
          <select
            onChange={(e) => setData({ ...data, status: e.target.value })}
            value={data.status}
            required
            name="warehouse_id"
            id="warehouse"
            className="rounded-md px-2 py-1 border focus:outline-none"
          >
            <option value="" hidden>
              Select ...
            </option>
            {Object.values(InventoryStatus).map((w: string, i) => (
              <option value={w}>{w}</option>
            ))}
          </select>
        </div>
        <div className="flex flex-col">
          <label htmlFor="responsible">Responsible</label>
          <EmployeeSearchWidget
            withBtn={false}
            emp={
              data.responsible_id && data.responsible_name
                ? { uid: data.responsible_id!, name: data.responsible_name! }
                : undefined
            }
            onSelect={function (d: UserModel): void {
              setData((o) => ({
                ...o,
                responsible_id: d.uid,
                responsible_name: d.first_name + " " + d.last_name,
              }));
            }}
          />
        </div>
      </div>{" "}
      <div className="flex justify-end gap-2 my-2">
        <button
          className="border rounded-lg px-2 py-1 hover:bg-gray-800 hover:text-white"
          type="submit"
        >
          {props.data ? "Update" : "Create"}
        </button>
      </div>
    </form>
  );
};

export default InventoryCreate;
