import { FunctionComponent } from "react";
import Sidebar from "../../components/Sidebar";
import ProtocolLayout from "../components/ProtocolLayout";

interface HubCreationProps {}

const HubCreation: FunctionComponent<HubCreationProps> = () => {
  return (
    <ProtocolLayout>
      <div className="flex flex-col items-center">
        <div className="mt-20 w-11/12 lg:w-4/5">
          <div className=" flex flex-col items-center mb-12">
            <div className="text-xl lg:text-3xl font-semibold">
              MANDATORY CHECK LIST for EV FLEET CHARGING-HUB
            </div>
          </div>
          <div className="">
            <div className="font-bold text-2xl">
              Electrical Safety Requirements:
            </div>
            <ul className="mt-6 list-decimal pl-6">
              <li>
                Overload Protection for Charging stations input supply and
                output supply shall be provided. (MCB/MCCB)
              </li>

              <li>
                Lightning protection system shall be provided as per IS/ IEC
                62305. (Type-1/2 SPD Mandatory)
              </li>
              <li>
                Looping or Clubbing shall not be made, Charging points supply
                connection shall be individually from switchboard.
              </li>
              <li>
                Earthing System shall be provided for Protection, Lightening &
                Neutral(Optional)
              </li>
              <li>
                Wire Joint shall be with inline tube crimping only. (Refer
                attached Image)
              </li>
            </ul>
          </div>

          <div className="mt-12">
            <div className="font-bold text-2xl">Civil Safety Requirements:</div>
            <ul className="mt-6 list-decimal pl-6">
              <li>
                Charging point shall be installed at least 800 mm above the
                finished ground level
              </li>

              <li>
                Minimum area for parking & safe maneuvering of vehicle shall be
                provided. (Refer attached Image) [Typically 15ft X 7ft shall be
                allocated for each vehicle]
              </li>
              <li>
                Concrete flooring around charging point shall be provided,
                Foundation withconcrete shall be provided for pedestal (Standee)
              </li>
              <li>
                Proper drain arrangment shall be provided, water stagnation
                shall be prevented
              </li>
              <li>
                Vehicle parking place shall be within five meter from the EV
                charging point for charging.
              </li>
              <li>
                Sufficient Ventilation shall be provided, Forced ventilation
                required in-case of insufficiency. (Shall not be in a Closed
                Room/Building)
              </li>
            </ul>
          </div>
          <div className="mt-12">
            <div className="font-bold text-2xl">
              Operation & Safety Management:
            </div>
            <ul className="mt-6 list-decimal pl-6">
              <li>
                Personal Protection shall be provided. (Electrical Gloves)
              </li>

              <li>
                Reflective Jacket/Apron shall be provided for guard/supervisor.
              </li>
              <li>Approved First-Aid box shall be provided.</li>
              <li>
                Fire control system shall be provided as per Indian Standards.
                (Co2 for Electrical fires-2No’s & Foam type with F-500EA-2No’s
                for every 10charge points)
              </li>
              <li>
                Vehicle parking place shall be within five meter from the EV
                charging point for charging.
              </li>
              <li>
                Sufficient Ventilation shall be provided, Forced ventilation
                required in-case of insufficiency. (Shall not be in a Closed
                Room/Building)
              </li>
            </ul>
          </div>
          <div className="">
            <img src="/kazam-academy/hubcreation.png" alt="" className="" />
          </div>
        </div>
      </div>
    </ProtocolLayout>
  );
};
export default HubCreation;
