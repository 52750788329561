import { UserModel } from "../../context_providers/auth/user_model";
import {
  fetchGet,
  fetchPost,
  fetchPut,
  getHeaders,
  toQueryString,
} from "../api_client";

// ${process.env.REACT_APP_BACKEND_BASE_URL}

export const GetEmps = async (option: {
  count?: number;
  page?: number;
  search?: string;
  all?: boolean;
  active?: boolean;
}) => {
  try {
    let url = `${
      process.env.REACT_APP_BACKEND_BASE_URL
    }/api/v3/employees${toQueryString(option)}`;

    const res = await fetchGet(url);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GetEmployeesByDept = async (dept: string) => {
  try {
    let url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/employees/all/department/${dept}?all=true&sort=created_at&sort_order=1`;

    const res = await fetchGet(url);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GetReportingManager = async () => {
  try {
    let url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/employees/reporting-manager`;

    const res = await fetchGet(url);
    let executives: {
      id: number;
      name: string;
      dep: string;
      sub_dep: string;
    }[] = [];
    if (res.success) {
      executives = res.data.map((emp: UserModel) => {
        return {
          id: emp.uid,
          name: `${emp.first_name} ${emp.last_name}`,
          dep: emp.emp_profile?.department,
          sub_dep: emp.emp_profile?.sub_dep,
        };
      });
    }
    return { success: true, data: executives };
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GetHod = async () => {
  try {
    let url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/employees/hod`;

    const res = await fetchGet(url);
    let executives: {
      id: number;
      name: string;
      dep: string;
      sub_dep: string;
    }[] = [];
    if (res.success) {
      executives = res.data.map((emp: UserModel) => {
        return {
          id: emp.uid,
          name: `${emp.first_name} ${emp.last_name}`,
          dep: emp.emp_profile?.department,
          sub_dep: emp.emp_profile?.sub_dep,
        };
      });
    }
    return { success: true, data: executives };
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GetEmpDetails = async (uid: any) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/employees/${uid}`;
    const res = await fetchGet(url);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const CreateEmpRepo = async (data: UserModel) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/employees`;
    const res = await fetchPost(url, data);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const UpdateEmpDetails = async (data: UserModel) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/employees/${data.uid}`;
    const res = await fetchPut(url, data);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const UpdateEmpStatusRepo = async (data: {
  uid: number;
  active: boolean;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/employees/${data.uid}/active`;
    const res = await fetchPut(url, data);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const InviteUsers = async (data: {
  emails: string[];
  roles: number[];
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/auth/invite-emp`;
    const res = await fetchPost(url, data);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const ResetPassword = async (uid: any) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/employees/reset-password`;
    const res = await fetchPost(url, { uid });

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const GetDepartments = async () => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/employees/departments`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const ImportEmpRepo = async (data: FormData) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/employees/import`;
    const res = await fetchPost(url, data);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
