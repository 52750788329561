import { BaseModel } from "./base";

export interface GeoLocation {
  type: "Point";
  coordinates: [lng: number, lat: number];
}
export const getFullAddress = (address: AddressModel) => {
  const addressArray = [];
  const fields = [
    "line1",
    "line2",
    "landmark",
    "city",
    "state",
    "country",
    "pincode",
  ];
  for (let i in fields) {
    if (address[fields[i]]) {
      addressArray.push(address[fields[i]]);
    }
  }

  const full_address = addressArray.join(", ");

  return full_address;
};

export interface AddressModel extends BaseModel {
  id?: any;
  type?: string;
  label?: string;
  line1: string;
  line2?: string;
  line3?: string;
  city: string;
  state: string;
  country: string;
  pincode: number;
  full_address?: string;
  location?: GeoLocation;
}
export interface AddressModel {
  [key: string]: any;
}

export const getDefaultAddressValue = () => {
  const x: AddressModel = {
    line1: "",
    city: "",
    state: "",
    country: "",
    pincode: 0,
  };
  return x;
};
