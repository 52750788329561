import { NavLink } from "react-router-dom";
import { urls } from "../../../utils/urls";
import "./index.css";

export default function index() {
  return (
    <div className="flex h-screen w-screen justify-center items-center">
      <div className="flex flex-col justify-center items-center">
        <img src="/404.svg" alt="" />
        <h1 className="text-lg text-red-800 font-semibold mt-5">
          Oops... You are in wrong path !
        </h1>
        <NavLink
          to={urls.root}
          className="text-lg text-blue-800 font-semibold mt-5"
        >
          Back to home
        </NavLink>
      </div>
    </div>
  );
}
