import debounce from "lodash.debounce";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import {
  useToast,
  ToastType,
} from "../../../../../context_providers/toast/toast_ctx";
import AutoCompleteField from "../../../../new_components/common/autocomplete";
import { OrgSearchInComplaints } from "../../../../../service/repos/support/complaint_repo";
import { GetOrgsForRoleApi } from "../../../../../service/repos/roles_repo";

export interface OrgModel {
  id?: string;
  name: string;
  namespace: string;
}

interface OrgSearchFieldProps {
  onSelect: (data: OrgModel) => void;
  disabled?: boolean;
  placeholder?: string;
  value?: string | any;
}

const OrgSearchFieldForRoles: FunctionComponent<OrgSearchFieldProps> = (
  props
) => {
  const { showToast } = useToast();
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState<string>("");
  const [data, setData] = useState<OrgModel[]>([]); // Default to an empty array

  const getData = async (query: string) => {
    setLoading(true);
    try {
      const res = await GetOrgsForRoleApi({ search: query });
      console.log("Result of Search:", res.data);
      if (res.success) {
        // Map the results to OrgModel
        const orgs: OrgModel[] = res.data.map(
          (item: { name: string; namespace: string }) => ({
            id: item.namespace,
            name: item.name,
            namespace: item.namespace,
          })
        );
        setData(orgs);
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
    } finally {
      setLoading(false);
    }
  };

  const debouncedHandler = useCallback(
    debounce((query) => {
      if (query) {
        getData(query);
      } else {
        setData([]);
      }
    }, 500),
    []
  );

  useEffect(() => {
    if (search && edit) {
      if (props.value && props.value === search) return;
      debouncedHandler(search);
    }
  }, [search, edit, props.value]);

  useEffect(() => {
    setSearch(props.value ?? "");
  }, [props.value]);

  return (
    <div className="flex gap-1 items-center    w-full">
      {edit ? (
        <AutoCompleteField
          loading={loading}
          value={search}
          placeholder={props.placeholder ?? "Select Org"}
          onSelect={(val: { label: any; value: OrgModel }) => {
            setEdit(false);
            setSearch(val.value.name); // Set the search input to the selected org name
            props.onSelect(val.value); // Pass the selected OrgModel
          }}
          suggestions={data.map((d) => ({
            label: (
              <div className="text-xs flex flex-col">
                <b>{d.name}</b>
                <span>{d.namespace}</span>
              </div>
            ),
            value: d,
          }))}
          onChange={setSearch}
        />
      ) : (
        <div
          className={`text-sm px-3 py-2 h-8 rounded border cursor-pointer w-full select-none ${
            search ? "" : "text-gray-400"
          } ${props.disabled ? "bg-gray-100 cursor-not-allowed" : "bg-white"}`}
          onClick={() => {
            if (!props.disabled) {
              setEdit(true);
            }
          }}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap", // Prevents text from wrapping to the next line
          }}
        >
          <span className="truncate text-black overflow-hidden overflow-ellipsis">
            {search || props.placeholder || "Org Search"}
          </span>
        </div>

        //   <div
        //   className="text-sm px-3 py-2  h-8 rounded focus:outline-none w-full border"
        //   onClick={() => {
        //     if (!props.disabled) {
        //       setEdit(true);
        //     }
        //   }}
        // >
        //   {search || props.placeholder}
        // </div>
      )}
    </div>
  );
};

export default OrgSearchFieldForRoles;
