import { Tooltip } from "@material-ui/core";
import { FunctionComponent, useEffect, useState } from "react";
import ProductModel from "../../../../service/models/product_model";
import {
  GetInventoryOverViewByStatusApi,
  GetInventoryOverViewByWarehousApi,
} from "../../../../service/repos/inventory_repo";
import {
  GetProductsApi,
  itemType,
} from "../../../../service/repos/products_repo";

import { InventoryStatus } from "../../../../utils/enums";
import LoadingWidget from "../../../components/loading_spinner";

interface OverviewData {
  all_inventory: {
    product_id: number;
    product_name: string;
    count: number;
  }[];
  stocked_inventory: {
    product_id: number;
    product_name: string;
    count: number;
  }[];
  installed_inventory: {
    product_id: number;
    product_name: string;
    count: number;
  }[];
  defect_inventory: {
    product_id: number;
    product_name: string;
    count: number;
  }[];
  warehouse_inventory: {
    [key: string]: {
      warehouse_id: number;
      warehouse_name: string;
      product_name: string;
      product_id: number;
      count: number;
    }[];
  };
}

interface InventoryOverViewPageProps {}

const InventoryOverViewPage: FunctionComponent<
  InventoryOverViewPageProps
> = () => {
  const [data, setData] = useState<OverviewData>();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<ProductModel[]>([]);
  const getProducts = async () => {
    const res = await GetProductsApi();
    if (res.success) {
      setProducts(
        res.data.filter((p: ProductModel) => p.type === itemType.goods)
      );
    }
  };
  const getData = async () => {
    setLoading(true);
    const all_inventory = await GetInventoryOverViewByStatusApi({
      status: [],
    });
    const stocked_inventory = await GetInventoryOverViewByStatusApi({
      status: [InventoryStatus.stocked, InventoryStatus.returnedNStocked],
    });
    const installed_inventory = await GetInventoryOverViewByStatusApi({
      status: [InventoryStatus.installed, InventoryStatus.delivered],
    });
    const defect_inventory = await GetInventoryOverViewByStatusApi({
      status: [InventoryStatus.defect, InventoryStatus.missing],
    });
    const warehouse_inventory = await GetInventoryOverViewByWarehousApi({
      status: [InventoryStatus.stocked, InventoryStatus.returnedNStocked],
    });
    setData({
      all_inventory: all_inventory.data ?? [],
      stocked_inventory: stocked_inventory.data ?? [],
      installed_inventory: installed_inventory.data ?? [],
      defect_inventory: defect_inventory.data ?? [],
      warehouse_inventory: warehouse_inventory.data ?? [],
    });
    setLoading(false);
  };

  useEffect(() => {
    getData();
    getProducts();
  }, []);
  return (
    <>
      <div className="flex">
        <h1 className="text-2xl text-gray-500  mt-5 ml-5 font-semibold border-l-4 border-teal pl-2">
          Inventory Overview
        </h1>
      </div>
      <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-20 m-5">
        <div className="rounded-2xl  border  shadow-lg p-5">
          <h3 className="font-semibold text-lg text-gray-500">All Devices</h3>
          <hr className="my-2" />

          {/* <div className="grid"> */}
          {data?.all_inventory.map((d) => (
            <div
              title={`product id: ${d.product_id}`}
              className="flex justify-between text-sm font-semibold my-2"
            >
              <div className="">{d.product_name}</div>
              <div className="text-sm font-semibold">{d.count}</div>
            </div>
          ))}
          <hr />
          <div className="flex justify-between text-sm font-semibold my-2 ">
            <div className="">Total</div>
            <div className="text-sm font-semibold">
              {data?.all_inventory.reduce((p, v) => p + v.count, 0)}
            </div>
          </div>
          {/* </div> */}
        </div>
        <div className="rounded-2xl  border  shadow-lg p-5">
          <h3 className="font-semibold text-lg text-gray-500">
            In Stocked Devices
          </h3>
          <hr className="my-2" />

          {/* <div className="grid"> */}
          {data?.stocked_inventory.map((d) => (
            <div
              title={`product id: ${d.product_id}`}
              className="flex justify-between text-xs my-2"
            >
              <div className="">{d.product_name}</div>
              <div className="text-sm font-semibold">{d.count}</div>
            </div>
          ))}
          <hr />
          <div className="flex justify-between text-sm font-semibold my-2 ">
            <div className="">Total</div>
            <div className="text-sm font-semibold">
              {data?.stocked_inventory.reduce((p, v) => p + v.count, 0)}
            </div>
          </div>
          {/* </div> */}
        </div>
        <div className="rounded-2xl  border  shadow-lg p-5">
          <h3 className="font-semibold text-lg text-gray-500">
            Delivered | Installed
          </h3>
          <hr className="my-2" />
          {/* <div className="grid"> */}
          {data?.installed_inventory.map((d) => (
            <div
              title={`product id: ${d.product_id}`}
              className="flex justify-between text-xs my-2"
            >
              <div className="">{d.product_name}</div>
              <div className="text-sm font-semibold">{d.count}</div>
            </div>
          ))}{" "}
          <hr />
          <div className="flex justify-between text-sm font-semibold my-2 ">
            <div className="">Total</div>
            <div className="text-sm font-semibold">
              {data?.installed_inventory.reduce((p, v) => p + v.count, 0)}
            </div>
          </div>
          {/* </div> */}
        </div>
        <div className="rounded-2xl  border  shadow-lg p-5">
          <h3 className="font-semibold text-lg text-gray-500">Defect</h3>
          <hr className="my-2" />

          {/* <div className="grid"> */}
          {data?.defect_inventory.map((d) => (
            <div
              title={`product id: ${d.product_id}`}
              className="flex justify-between text-xs my-2"
            >
              <div className="">{d.product_name}</div>
              <div className="text-sm font-semibold">{d.count}</div>
            </div>
          ))}
          <hr />
          <div className="flex justify-between   my-2 text-sm font-semibold ">
            <div className="">Total</div>
            <div className="">
              {data?.defect_inventory.reduce((p, v) => p + v.count, 0)}
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
      <div className=" overflow-hidden" style={{ height: "65vh" }}>
        <div className="flex">
          <h1 className="text-xl text-gray-500 font-medium mt-5 ml-5">
            Warehouses{" "}
            <div className="border rounded-full px-3 py-1 inline-flex text-sm">
              In stocked
            </div>
          </h1>
        </div>
        <div className=" overflow-auto" style={{ maxHeight: "90%" }}>
          {Object.keys(data?.warehouse_inventory ?? {}).map((warehouse, i) => (
            <div className="rounded-2xl  border  shadow-lg p-5 m-5">
              <h3 className="font-semibold text-lg text-gray-500">
                {warehouse}
              </h3>
              <hr className="my-2" />

              <div
                className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-8 gap-5 `}
              >
                {products.map((p, i) => {
                  const d = data?.warehouse_inventory[warehouse].find(
                    (f) => f.product_id === p.id
                  );
                  return (
                    <div className="flex flex-col gap-1  text-sm my-2">
                      <div className="">{p.name} </div>
                      <div className="text-base font-semibold">
                        {d?.count ?? 0}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
      <LoadingWidget loading={loading} />
    </>
  );
};

export default InventoryOverViewPage;
