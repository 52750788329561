import axios from "axios";
import fetch from "isomorphic-unfetch";
import { toQueryString } from "../../../../service/api_client";
import {
  fetchGet,
  fetchDelete,
  getHeaders,
  fetchPost,
  fetchPut,
} from "./serviceClient";

export const uploadInstallation = async (data: any) => {
  try {
    const res = await fetchPost(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/installation`,
      data
    );
    return res;
  } catch (error) {
    console.log(error);
  }
  // return await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/installation/admin`, {
  //   method: "POST",
  //   headers: getHeaders(),
  //   body: data,
  // })
  //   .then(async (resp) => {
  //     return await resp.json();
  //   })
  //   .catch((err) => console.log(err));
};

export const getInstallations = async (data: {
  count: number;
  page: number;
}) => {
  const { count, page } = data;
  const url = `${
    process.env.REACT_APP_BACKEND_BASE_URL
  }/api/v3/installation${toQueryString(data)}`;
  const res = await fetchGet(url);
  return res;

  // return {success:true,data:[{url:"https://source.unsplash.com/random",place:"bangalore",active:true},{url:"https://source.unsplash.com/random",place:"bangalore",active:true},{url:"https://source.unsplash.com/random",place:"bangalore",active:true},{url:"https://source.unsplash.com/random",place:"bangalore",active:true},{url:"https://source.unsplash.com/random",place:"bangalore",active:true}]};
};

export const updateInsllation = async (body: any) => {
  try {
    const res = await fetchPut(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/installation`,
      body
    );
    return res;
  } catch (error) {
    console.log(error);
  }
  // return await fetch(, {
  //   method: "PUT",
  //   headers: {
  //     Authorization: `Bearer ${tokenData?.token}`,
  //   },
  //   body: body,
  // })
  //   .then(async (resp) => {
  //     return await resp.json();
  //   })
  //   .catch((err) => console.log(err));
  // return await fetchPut(
  //   `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/installation/admin`,
  //   body
  // );
  // return {success:true,data:body}
};
export const deleteInsllation = async (body: any) => {
  return await fetchDelete(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/installation`,
    body
  );
  // return {success:true}
};
