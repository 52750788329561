import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import EditorJs from "react-editor-js";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EDITOR_JS_TOOLS } from "../tools";
// import "./index.css";
import { uploadBlogPhoto } from "../../../services/blogPostService";
import { compressImage } from "../../../infrastructure/utils/image_compressor";

const contentStyle = {
  backgroundColor: "#e9ecef",
};
interface someProps {
  ref: any;
  content: any;
  contentSave: (content: any) => void;
  errors: any;
}

const Index: React.ForwardRefExoticComponent<someProps> = forwardRef(
  (props, ref) => {
    const { content, contentSave, errors } = props;
    const instanceRef = useRef<any>(null);
    const [editorState, setEditorState] = useState(() =>
      content
        ? content.time
          ? null
          : EditorState.createWithContent(convertFromRaw(content))
        : EditorState.createEmpty()
    );
    useImperativeHandle(ref, () => ({
      async handleSave() {
        if (content && content.time) {
          const savedData = await instanceRef!.current!.save();
          contentSave(savedData);
        } else {
          const content = editorState!.getCurrentContent();
          const raw = convertToRaw(content);

          contentSave(raw);
        }
      },
    }));

    return (
      <div className="  col-span-10 col-start-2 my-5">
        {content && content.time ? (
          <div style={contentStyle}>
            <EditorJs
              data={content}
              tools={EDITOR_JS_TOOLS}
              instanceRef={(instance) => (instanceRef.current = instance)}
            />
          </div>
        ) : (
          <div
            style={{
              boxSizing: "border-box",
              border: "1px solid #ddd",
              cursor: "text",
              padding: "16px",
              borderRadius: "2px",
              marginBottom: "2em",
              boxShadow: "inset 0px 1px 8px -3px #ABABAB",
              background: "#fefefe",
            }}
          >
            <Editor
              editorState={editorState!}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
              onEditorStateChange={setEditorState}
              uploadCallback={async (file) => {
                try {
                  let data = new FormData();
                  const image: any = await compressImage(file as File);
                  data.append("image", image);
                  const response = await uploadBlogPhoto(data);

                  const res: any = { data: { link: response.file.url } };
                  return res;
                } catch (error) {
                  console.log(error);
                }
              }}
            />
          </div>
        )}
      </div>
    );
  }
);
export default Index;
