import moment from "moment";
import { FunctionComponent, useState } from "react";
import { X } from "react-feather";
import { NavLink } from "react-router-dom";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import { LeaveRequest } from "../../../../../service/models/user_model";
import { CreateLeaveRequestRepo } from "../../../../../service/repos/hrm/hrm_repo";
import { LeavePeriod, LeaveType } from "../../../../../utils/enums";
import { urls } from "../../../../../utils/urls";
import HrmPopup from "../../components/HrmPopup";

interface QuickLeaveProps {
  close: () => void;
}

const QuickLeave: FunctionComponent<QuickLeaveProps> = ({ close }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [leaveType, setLeaveType] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [showReason, setShowReason] = useState<boolean>(false);

  const reasons = ["Cough and cold", "Out of town", "Personal work"];

  const date = new Date();
  // const dateToday = date.toISOString().split("T")[0];

  const { showToast } = useToast();

  const handleSelectLeave = (leave: string) => {
    setShowReason(true);
    setLeaveType(leave);
  };

  const submit = async () => {
    if (message === "") {
      alert("Please add reason");
    } else {
      setLoading(true);

      const formData = {
        leave_name: leaveType,
        from_date: moment(date).utcOffset(330).format("YYYY-MM-DD"),
        to_date: moment(date).utcOffset(330).format("YYYY-MM-DD"),
        period: LeavePeriod.full_day,
        message: message,
      };

      console.log(formData);

      const res: any = await CreateLeaveRequestRepo(formData);

      if (res.success) {
        showToast({ type: ToastType.success, text: "Successfully submitted" });
      } else {
        showToast({ type: ToastType.error, text: res.error ?? "" });
      }
      setLoading(false);
      close();
    }
  };

  return (
    <HrmPopup>
      <div className="bg-white py-8 px-10 rounded-xl">
        <div className="flex flex-col items-end mb-4">
          <div
            className="flex items-center gap-2  bg-gray-100 hover:bg-gray-200 py-1 px-4 rounded-md cursor-pointer"
            onClick={() => close()}
          >
            <div className="">Close</div>
            <X className="transform rotate-180 cursor-pointer" size={16} />
          </div>
        </div>
        {!showReason ? (
          <div className="">
            <div className="text-lg text-secondaryColor">
              Choose the type of leave required
            </div>
            <div className="flex flex-col lg:flex-row items-center gap-5 mt-6">
              <div
                className="bg-hrmPurple bg-opacity-70 cursor-pointer text-opacity-80 text-white rounded-full font-bold py-3 px-6"
                onClick={() => handleSelectLeave(LeaveType.medicle_leave)}
              >
                1 medical leave today
              </div>
              <div
                className="  bg-hrmRed bg-opacity-70 cursor-pointer text-opacity-80 text-white rounded-full font-bold py-3 px-6"
                onClick={() => handleSelectLeave(LeaveType.paid_leave)}
              >
                1 earned leave today
              </div>
              <div
                className="  bg-teal bg-opacity-70 cursor-pointer text-opacity-80 text-white rounded-full font-bold py-3 px-6"
                onClick={() => handleSelectLeave(LeaveType.work_from_home)}
              >
                1 work from home today
              </div>
            </div>
            <div className="mt-12 text-hrmPurple underline text-center">
              <NavLink to={urls.hrm_leave_application}>
                Choose custom dates
              </NavLink>
            </div>
          </div>
        ) : (
          <div className="">
            <div className="">Leave on {moment(Date.now()).format("ll")}</div>
            <div className=" lg:w-96">
              <textarea
                className="w-full bg-gray-100 border border-gray-300 py-4 px-4 focus:outline-none rounded-lg resize-none"
                placeholder="Add reason for leave"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
            <div className="mt-4 text-sm">Common reasons::</div>
            <div className="grid lg:grid-cols-3 gap-2 mt-2">
              {reasons.map((el, i) => {
                return (
                  <button
                    className="py-1 px-2 text-sm rounded-full bg-teal text-white font-semibold"
                    key={i}
                    onClick={() => setMessage(el)}
                  >
                    {el}
                  </button>
                );
              })}
            </div>
            <div className="mt-6 flex flex-col items-center">
              {loading ? (
                <button className="bg-hrmPurple py-2 px-8 text-white rounded-md font-semibold">
                  Loading...
                </button>
              ) : (
                <button
                  className="bg-hrmPurple py-2 px-8 text-white rounded-md font-semibold"
                  onClick={() => submit()}
                >
                  Apply
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </HrmPopup>
  );
};

export default QuickLeave;
