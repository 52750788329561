import { AuthorModel } from "../../../ui/pages/authors/create";
import { isSet } from "../../../utils/helpers";
export const blogCountry = { india: "India", usa: "USA" };
export const blogLang = {
  english: "English",
  hindi: "Hindi",
  kannada: "Kannada",
  marathi: "Marathi",
};
export default function Index(props: any) {
  const { errors, blog, inputChange, authors } = props;

  return (
    <form className=" col-start-4 col-span-6 flex flex-col gap-2 justify-center h-full mt-10 ">
      <div className="grid grid-cols-4 items-center">
        <label htmlFor="title">Title</label>
        <div className="col-span-3">
          <input
            id="title"
            name="title"
            value={blog["title"]}
            className=" border rounded px-2 py-1 w-full"
            type="text"
            placeholder="Meta title"
            onChange={(e) => inputChange(e)}
          />
          {isSet(errors) && isSet(errors.title) && (
            <div
              className="m-0 p-0 text-red-400"
              style={{ fontSize: 12, fontWeight: "bold" }}
            >
              {errors && errors.title}
            </div>
          )}
        </div>
      </div>
      <div className="grid grid-cols-4 items-center">
        <label htmlFor="description">Description</label>
        <div className="col-span-3">
          <input
            id="description"
            name="description"
            value={blog["description"]}
            className=" border rounded px-2 py-1 w-full"
            type="text"
            placeholder="Meta description"
            onChange={(e) => inputChange(e)}
          />
          {isSet(errors) && isSet(errors.description) && (
            <div
              className="m-0 p-0 text-danger"
              style={{ fontSize: 12, fontWeight: "bold" }}
            >
              {errors && errors.description}
            </div>
          )}
        </div>
      </div>
      <div className="grid grid-cols-4 items-center">
        <label htmlFor="author_id">Author</label>
        <div className="col-span-3">
          <select
            className=" border rounded px-2 py-1 w-full"
            name="author_id"
            id="author_id"
            required
            onChange={(e) => inputChange(e)}
            value={blog["author_id"]}
          >
            <option value="" hidden>
              Select author
            </option>
            {authors.map((author: AuthorModel, i: number) => (
              <option key={i} value={author.id}>
                {author.name}
              </option>
            ))}
          </select>
          {/* <input
            id="author"
            name="author"
            value={blog["author"]}
            type="text"
          className=" border rounded px-2 py-1 w-full"
            placeholder="Author's name"
            onChange={(e) => inputChange(e)}
          /> */}
          {isSet(errors) && isSet(errors.author_id) && (
            <div
              className="m-0 p-0 text-danger"
              style={{ fontSize: 12, fontWeight: "bold" }}
            >
              {errors && errors.author_id}
            </div>
          )}
        </div>
      </div>
      <div className="grid grid-cols-4 items-center">
        <label htmlFor="feature">Feature image</label>
        <div className="col-span-3">
          <input
            id="feature"
            name="feature_image"
            value={blog["feature_image"]}
            type="text"
            className=" border rounded px-2 py-1 w-full"
            placeholder="Feature image url"
            onChange={(e) => inputChange(e)}
          />
          {isSet(errors) && isSet(errors.feature) && (
            <div
              className="m-0 p-0 text-danger"
              style={{ fontSize: 12, fontWeight: "bold" }}
            >
              {errors && errors.feature}
            </div>
          )}
        </div>
      </div>{" "}
      <div className="grid grid-cols-4 items-center">
        <label htmlFor="country">Country</label>
        <div className="col-span-3">
          <select
            className=" border rounded px-2 py-1 w-full"
            name="country"
            id="country"
            required
            onChange={(e) => inputChange(e)}
            value={blog["country"]}
          >
            <option value="" hidden>
              Select country
            </option>
            {Object.values(blogCountry).map((country: string, i: number) => (
              <option key={i} value={country}>
                {country}
              </option>
            ))}
          </select>
          {/* <input
            id="author"
            name="author"
            value={blog["author"]}
            type="text"
          className=" border rounded px-2 py-1 w-full"
            placeholder="Author's name"
            onChange={(e) => inputChange(e)}
          /> */}
          {isSet(errors) && isSet(errors.author_id) && (
            <div
              className="m-0 p-0 text-danger"
              style={{ fontSize: 12, fontWeight: "bold" }}
            >
              {errors && errors.author_id}
            </div>
          )}
        </div>
      </div>
      <div className="grid grid-cols-4 items-center">
        <p>Language</p>
        <div className="flex gap-2 col-span-3">
          {Object.values(blogLang).map((lang, i) => (
            <>
              <input
                checked={blog["language"] === lang}
                onChange={(e) => inputChange(e)}
                type="radio"
                id={lang}
                name="language"
                value={lang}
              />{" "}
              <label htmlFor={lang} className="mx-1">
                {lang}
              </label>
            </>
          ))}
        </div>
      </div>
    </form>
  );
}
