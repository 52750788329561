import { FunctionComponent, useEffect, useState } from "react";
import { compressImage } from "../../../../infrastructure/utils/image_compressor";
import {
  CreateAuthorRepo,
  GetUsersList,
  updateAuthorRepo,
} from "../../../../services/blogPostService";
import FileDropZone from "../../../new_components/common/file_drop_widget";
import Loader from "../../../new_components/common/loader";
import ToggleButton from "../../../new_components/form_components/toggle_button";
const initState = {
  name: "",
  img: "",
  about: "",
  email: "",
  linked_in: "",
  twitter: "",
};
export interface AuthorModel {
  [key: string]: any;
}
export interface AuthorModel {
  id?: number;
  // uid?: number;
  name: string;
  email: string;
  linked_in: string;
  twitter: string;
  img: string;
  about: string;
}
interface AuthorCreateProps {
  location: { state?: AuthorModel };
}

const AuthorCreate: FunctionComponent<AuthorCreateProps> = (props) => {
  const { state } = props.location;
  const [preData, setPreData] = useState<AuthorModel>(
    state ? { ...state } : initState
  );

  const [image, setImage] = useState<File>();
  const [loading, setLoading] = useState(false);

  const onImageSelected = async (file: File) => {
    try {
      setLoading(true);
      const compressedImage = await compressImage(file);
      setImage(compressedImage);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (e: any) => {
    try {
      e.preventDefault();

      if (!image && !preData.img) {
        alert("Please select an profile image!");
        return;
      }
      const sure = window.confirm("Are you sure?");
      if (!sure) return;
      setLoading(true);
      const formdata = new FormData();
      if (image) {
        formdata.append("image", image);
      }

      Object.keys(preData).forEach((k) => {
        formdata.append(k, preData[k]);
      });

      if (preData.id) {
        const res = await updateAuthorRepo(formdata);
        if (res.success) alert(res.message);
        else alert(res.error);
      } else {
        const res = await CreateAuthorRepo(formdata);

        if (res.success) {
          alert(res.message);
          setImage(undefined);
          setPreData(initState);
        } else alert(res.error);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  if (loading) return <Loader />;

  return (
    <div className="flex justify-center items-center w-full h-full p-10">
      <form onSubmit={onSubmit}>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-3  shadow-sm p-5 rounded bg-white">
          <input
            type="file"
            accept="image/*"
            name="image"
            id="image"
            className="hidden"
            onChange={async (e) => {
              if (e.target.files?.length) {
                onImageSelected(e.target.files[0]);
              }
            }}
          />
          <div className=" flex justify-center">
            {/* <label htmlFor="image" className=" text-lg">
            Display image
          </label> */}

            <div className="">
              {(preData && preData.img) || image ? (
                <img
                  alt="profile_image"
                  className="h-56 w-56  rounded-full object-cover"
                  src={image ? URL.createObjectURL(image) : preData!.img}
                ></img>
              ) : (
                <div className="h-56 w-56 rounded-full bg-gray-200 flex justify-center items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-40 w-40 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
              )}
              <div
                onClick={() => {
                  document.getElementById("image")?.click();
                }}
                className="  flex justify-center items-center p-2 bg-gray-100 rounded-full my-3  cursor-pointer hover:text-white hover:scale-125 hover:bg-red-800"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 mx-2  "
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
                  />

                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div className="col-span-2 grid grid-cols-1 md:grid-cols-2  gap-3 mt-5">
            <div className="flex flex-col">
              <label htmlFor="title" className=" text-lg">
                Author name
              </label>
              <input
                onChange={(e) => {
                  setPreData((pre) => ({ ...pre, name: e.target.value }));
                }}
                value={preData.name}
                className="focus:outline-none p-2 bg-gray-100 rounded"
                type="text"
                name="name"
                id="name"
                required
                placeholder="Enter Full name"
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="email" className=" text-lg">
                Email
              </label>
              <input
                onChange={(e) => {
                  setPreData((pre) => ({ ...pre, email: e.target.value }));
                }}
                value={preData.email}
                className="focus:outline-none p-2 bg-gray-100 rounded"
                type="email"
                name="email"
                id="email"
                required
                placeholder="Enter email"
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="linkedin" className=" text-lg">
                LinkedIn
              </label>
              <input
                onChange={(e) => {
                  setPreData((pre) => ({ ...pre, linked_in: e.target.value }));
                }}
                value={preData.linked_in}
                className="focus:outline-none p-2 bg-gray-100 rounded"
                type="text"
                name="linkedin"
                id="linkedin"
                required
                placeholder="Enter linkedIn url"
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="twitter" className=" text-lg">
                Twitter
              </label>
              <input
                onChange={(e) => {
                  setPreData((pre) => ({ ...pre, twitter: e.target.value }));
                }}
                value={preData.twitter}
                className="focus:outline-none p-2 bg-gray-100 rounded"
                type="text"
                name="twitter"
                id="twitter"
                required
                placeholder="Enter twitter url"
              />
            </div>
            <div className="col-span-2 flex flex-col">
              <label htmlFor="about" className=" text-lg">
                About
              </label>
              <textarea
                rows={5}
                onChange={(e) => {
                  setPreData((pre) => ({
                    ...pre,
                    about: e.target.value,
                  }));
                }}
                value={preData.about}
                className="focus:outline-none p-2 bg-gray-100 rounded"
                name="about"
                id="about"
                required
                placeholder="Enter short description"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-center my-3">
          {/*           
                      <button
              className="px-5 py-2 mx-2 border border-red-400 hover:text-white rounded-3xl hover:bg-red-800"
              type="reset"
              onClick={() => {
                setImage(undefined);

                setPreData({ ...(state ? state : initState) });
              }}
            >
              Reset
            </button> */}
          <button
            className="px-5 py-2 mx-2 bg-red-400 text-white rounded-3xl hover:bg-red-800"
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default AuthorCreate;
