import { NavLink, useLocation } from "react-router-dom";
// @ts-ignore
import gsap from "gsap/all";
import {
  Aperture,
  BarChart,
  BarChart2,
  Briefcase,
  ChevronDown,
  ChevronUp,
  Clipboard,
  Cpu,
  FileText,
  Flag,
  Gift,
  PieChart,
  User,
  UserPlus,
  Users,
  X,
} from "react-feather";
import "../styles/index.css";
import { urls } from "../../../../utils/urls";
import { ACTION, MODULE } from "../../../../utils/enums";
import { useAuth } from "../../../../context_providers/auth/authProvider";
import { FunctionComponent, useEffect, useState } from "react";

interface SidebarProps {
  show: boolean;
  collapsed: boolean;
  handleSidebar: () => void;
  handleTheme: () => void;
  handleCollapsed: () => void;
}

const Sidebar: FunctionComponent<SidebarProps> = ({
  show,
  collapsed,
  handleSidebar,
  handleTheme,
}) => {
  const { user, isAuthorised } = useAuth();
  const location = useLocation();

  const sidebarLinks = [
    {
      subLinks: true,
      name: "Home",
      linkArray: [
        {
          admin: false,
          link: urls.hrm_home,
          subName: "Dashboard",
          icon: <BarChart2 size={16} className="" />,
        },
        {
          admin: false,
          link: urls.hrm_leave_application,
          subName: "Leave application",
          icon: <UserPlus size={16} className="" />,
        },
        {
          admin: false,
          link: urls.hrm_policies,
          subName: "Policies",
          icon: <FileText size={16} className="" />,
        },
        {
          admin: false,
          link: urls.hrm_profile,
          subName: "Profile",
          icon: <User size={16} className="" />,
        },
        {
          admin: false,
          link: urls.hrm_memories,
          subName: "Memories",
          icon: <Aperture size={16} className="" />,
          activeIcon: (
            <div className="">
              <img src="/hrm-icons/memories.gif" alt="" className="w-5" />
            </div>
          ),
        },
      ],
    },

    {
      subLinks: true,
      name: "Organization",
      linkArray: [
        {
          admin: false,
          link: urls.hrm_dept,
          subName: "Employees",
          icon: <Users size={16} className="" />,
        },

        {
          admin: false,
          link: urls.hrm_organization_tree,
          subName: "Organization Tree",
          icon: <Cpu size={16} className="" />,
        },
      ],
    },

    {
      subLinks: true,
      name: "HR",
      linkArray: [
        {
          admin: true,
          link: urls.hrm_overview,
          subName: "Leave activity",
          icon: <PieChart size={16} className="" />,
        },
        {
          admin: true,
          link: urls.hrm_analytics,
          subName: "Analytics",
          icon: <BarChart size={16} className="" />,
        },
        {
          admin: true,
          link: urls.hrm_add_employee,
          subName: "Add employee",
          icon: <UserPlus size={16} className="" />,
        },
        {
          admin: true,
          link: urls.hrm_hr_leave_record,
          subName: "Create leave record",
          icon: <Clipboard size={16} className="" />,
        },

        {
          admin: true,
          link: urls.hrm_hr_leave_panel,
          subName: "HR Leave panel",
          icon: <Flag size={16} className="" />,
        },

        {
          admin: true,
          link: urls.hrm_leave_data,
          subName: "Leave data",
          icon: <Clipboard size={16} className="" />,
        },
      ],
    },
    {
      subLinks: true,
      name: "Job portal",
      linkArray: [
        {
          admin: false,
          link: urls.hrm_job_portal,
          subName: "Job listings",
          icon: <Briefcase size={16} className="" />,
        },

        {
          admin: true,
          link: urls.hrm_create_job,
          subName: "Create Job",
          icon: <UserPlus size={16} className="" />,
        },
        {
          admin: false,
          link: urls.hrm_job_applicants,
          subName: "Job applicants",
          icon: <FileText size={16} className="" />,
        },
      ],
    },

    // {
    //   subLinks: true,
    //   name: "Entertainment",
    //   linkArray: [
    //     {
    //       admin: false,
    //       link: urls.hrm_memories,
    //       subName: "Memories",
    //       icon: <Aperture size={16} className="" />,
    //       activeIcon: (
    //         <div className="">
    //           <img src="/hrm-icons/memories.gif" alt="" className="w-5" />
    //         </div>
    //       ),
    //     },
    //   ],
    // },

    {
      subLinks: true,
      name: "Events",
      linkArray: [
        {
          admin: false,
          link: urls.hrm_birthday,
          subName: "Birthdays",
          icon: <Gift size={16} className="" />,
          activeIcon: (
            <div className="">
              <img src="/hrm-icons/birthday.gif" alt="" className="w-5" />
            </div>
          ),
        },
        {
          admin: false,
          link: urls.hrm_work_anniversary,
          subName: "Work Anniversary",
          icon: <Briefcase size={16} className="" />,
        },
      ],
    },

    // {
    //   subLinks: true,
    //   name: "Profile",
    //   linkArray: [
    //     {
    //       admin: false,
    //       link: urls.hrm_policies,
    //       subName: "Policies",
    //       icon: <FileText size={16} className="" />,
    //     },
    //     {
    //       admin: false,
    //       link: urls.hrm_profile,
    //       subName: "Profile",
    //       icon: <User size={16} className="" />,
    //     },
    //   ],
    // },
  ];

  return (
    <div
      className={` ${show ? "block sidebar_animate" : " hidden lg:block"} ${
        collapsed ? "sidebar_collapse" : "sidebar"
      } fixed top-0 left-0  z-50 bg-black dark:bg-hrmCardDark   overflow-y-auto pb-6 `}
      style={{ height: "100vh" }}
    >
      <div className="lg:hidden absolute top-5 right-3">
        <X className="text-white" size={32} onClick={() => handleSidebar()} />
      </div>

      <div
        className={`flex justify-between items-center pt-4  ${
          collapsed ? "px-2" : "px-5"
        } `}
      >
        <NavLink exact to={urls.root} className={`text-sm font-medium  `}>
          <div className="flex items-center ">
            {collapsed ? (
              <div className="">
                <img src="/images/logo.png" alt="" className={`  w-6 `} />
              </div>
            ) : (
              <div className="">
                <img src="/assets/logo.png" alt="" className={` w-28 `} />
              </div>
            )}
          </div>
        </NavLink>
      </div>
      <div className="text-primaryColor pt-2">
        {sidebarLinks.map(
          (
            item: {
              subLinks: boolean;
              admin?: boolean;
              name: string;
              linkArray?: {
                link: string;
                admin?: boolean;
                subName: string;
                icon?: any;
              }[];
              link?: string;
              icon?: any;
              activeIcon?: any;
            },
            i: number
          ) => {
            return item.subLinks ? (
              <Accordion item={item} collapsed={collapsed} key={i} />
            ) : (
              <div className="">
                {item.admin ? (
                  <NavLink
                    to={item.link as any}
                    className={` ${
                      isAuthorised({
                        module: MODULE.hrm_leave,
                        action: [ACTION.UPDATE],
                      })
                        ? "block"
                        : "hidden"
                    }  relative flex items-center lg:text-sm 2xl:text-base pl-5 my-3 gap-3 transition-all duration-300`}
                    activeClassName="nav_active"
                  >
                    {location.pathname === item.link && item.activeIcon ? (
                      <div className="">{item.activeIcon}</div>
                    ) : (
                      <div className="">{item.icon}</div>
                    )}
                    <div className={` ${collapsed ? "hidden" : "block"} `}>
                      {item.name}
                    </div>
                  </NavLink>
                ) : (
                  <NavLink
                    to={item.link as any}
                    className={`relative flex items-center lg:text-sm 2xl:text-base pl-5 my-3 gap-3 transition-all duration-300`}
                    activeClassName="nav_active"
                  >
                    {location.pathname === item.link && item.activeIcon ? (
                      <div className="">{item.activeIcon}</div>
                    ) : (
                      <div className="">{item.icon}</div>
                    )}
                    <div className={` ${collapsed ? "hidden" : "block"} `}>
                      {item.name}
                    </div>
                  </NavLink>
                )}
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};

export default Sidebar;

interface AccordionProps {
  item: any;
  collapsed: boolean;
}

const Accordion: FunctionComponent<AccordionProps> = ({ item, collapsed }) => {
  const { user, isAuthorised } = useAuth();
  const location = useLocation();
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const checkActive = (routeName: string) => {
    const hrmActiveRoute = localStorage.getItem("hrmRoute");
    if (hrmActiveRoute && hrmActiveRoute === routeName) {
      setShowDropdown(true);
    }
  };

  const handleHrmRoute = (routeName: string) => {
    localStorage.setItem("hrmRoute", routeName);
  };

  useEffect(() => {
    checkActive(item.name);
  }, []);

  return (
    <div className="mt-6">
      <div
        className={`cursor-pointer flex items-center justify-between px-4`}
        onClick={() => {
          setShowDropdown(!showDropdown);
          handleHrmRoute(item.name);
        }}
      >
        <div
          className={` ${
            collapsed ? "hidden" : "block"
          }  font-semibold  text-gray-500`}
        >
          {item.name}
        </div>
        <div className="">
          {showDropdown ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
        </div>
      </div>

      {/* {showDropdown && ( */}
      <div
        className={`overflow-hidden  duration-300 ${
          showDropdown ? "max-h-52" : "max-h-0"
        }`}
      >
        {item.linkArray?.map(
          (el: {
            admin: boolean;
            link: string;
            subName: string;
            icon?: any;
            activeIcon?: any;
          }) => {
            return el.admin ? (
              <NavLink
                to={el.link}
                className={` ${
                  isAuthorised({
                    module: MODULE.hrm_leave,
                    action: [ACTION.UPDATE],
                  })
                    ? "block"
                    : "hidden"
                } relative flex items-center pl-5 my-3 gap-3 transition-all duration-300`}
                activeClassName="nav_active"
                onClick={() => handleHrmRoute(item.name)}
              >
                {location.pathname === el.link && el.activeIcon ? (
                  <div className="">{el.activeIcon}</div>
                ) : (
                  <div className="">{el.icon}</div>
                )}
                <div className={` ${collapsed ? "hidden" : "block"} text-sm `}>
                  {el.subName}
                </div>
              </NavLink>
            ) : (
              <NavLink
                to={el.link}
                className={`relative flex items-center pl-5 my-3 gap-3 transition-all duration-300`}
                activeClassName="nav_active"
                onClick={() => handleHrmRoute(item.name)}
              >
                {location.pathname === el.link && el.activeIcon ? (
                  <div className="">{el.activeIcon}</div>
                ) : (
                  <div className="">{el.icon}</div>
                )}
                <div className={` ${collapsed ? "hidden" : "block"} text-sm `}>
                  {el.subName}
                </div>
              </NavLink>
            );
          }
        )}
      </div>
      {/* )} */}
    </div>
  );
};
