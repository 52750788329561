import { FunctionComponent } from "react";

import UserTableWidget from "./components/user_table";

interface UsersScreenProps {}

const UsersScreen: FunctionComponent<UsersScreenProps> = () => {
  return (
    <>
      <div className="h-screen w-full overflow-hidden px-5">
        <UserTableWidget />
      </div>
    </>
  );
};

export default UsersScreen;
