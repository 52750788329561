import { FunctionComponent, useEffect, useState } from "react";
import { useAuth } from "../../../../../../../../context_providers/auth/authProvider";
import { MODULE, ACTION } from "../../../../../../../../utils/enums";
import { VehiclePrice } from "../../../../../infrastructure/models/car_model";
import {
  deletetVehiclePrice,
  getPricesByVerientIdRepo,
  postVehiclePrice,
  putPriceVisibility,
  putVehiclePrice,
} from "../../../../../services/vehicle_service";
import DropDownMenu from "../../../../new_components/common/drop_down_menu";
import ModalDialog from "../../../../new_components/common/modal";
import ToggleButton from "../../../../new_components/form_components/toggle_button";
import AddPriceWidget from "./add_price";

interface PriceListingProps {
  location: any;
}

const PriceListing: FunctionComponent<PriceListingProps> = (props) => {
  const { varient_id } = props.location.state;

  const { user, isAuthorised } = useAuth();
  const [prices, setPrices] = useState<VehiclePrice[]>([]);
  const [selectedPrice, setSelectedPrice] = useState<VehiclePrice>();
  const [showAddPrice, setShowAddPrice] = useState(false);
  const getPrices = async () => {
    const res = await getPricesByVerientIdRepo({ varient_id });

    if (res.success) {
      // if(res.data.lenght)
      setPrices(res.data);
    } else {
      alert(res.error);
    }
  };
  const onSubmit = async (data: any) => {
    data.active = true;
    if (selectedPrice) {
      data.id = selectedPrice?.id;
      data.active = selectedPrice.active;
    }
    const res = selectedPrice
      ? await putVehiclePrice({ varient_id, price: data })
      : await postVehiclePrice({ varient_id, prices: [data] });
    if (res.success) {
      alert(res.message);
      setShowAddPrice(false);
      getPrices();
    } else {
      alert(res.error);
    }
  };
  const onDelete = async (price_id: string) => {
    const str = "DELETE";
    const confirm = prompt(`Enter ${str} to confirm (note : Cannot be undo)`);
    if (confirm !== str) return;
    const res = await deletetVehiclePrice({ varient_id, price_id });
    if (res.success) {
      alert(res.message);
      getPrices();
    } else {
      alert(res.error);
    }
  };
  useEffect(() => {
    getPrices();
  }, []);
  const onChangeVisibility = async (price_id: string, active: boolean) => {
    const res = await putPriceVisibility({ id: varient_id, price_id, active });
    if (res.success) {
      alert(res.message);
      getPrices();
    } else {
      alert(res.error);
    }
  };
  return (
    <div>
      <ModalDialog
        onClose={() => setShowAddPrice(false)}
        show={showAddPrice}
        title="Add Price"
      >
        <div
          style={{ height: "85vh", width: "90vw" }}
          className=" w-screen  overflow-y-scroll p-5 "
        >
          <AddPriceWidget price={selectedPrice} onSubmit={onSubmit} />
        </div>
      </ModalDialog>
      {selectedPrice && (
        <ModalDialog
          onClose={() => setSelectedPrice(undefined)}
          show={selectedPrice !== undefined}
          title="Edit Price"
        >
          <div
            style={{ height: "85vh", width: "90vw" }}
            className=" w-screen  overflow-y-scroll p-5 "
          >
            <AddPriceWidget price={selectedPrice} onSubmit={onSubmit} />
          </div>
        </ModalDialog>
      )}
      <div className="flex justify-end my-3">
        <div
          onClick={() => setShowAddPrice(true)}
          className="flex items-center bg-gray-100 px-3 py-2 rounded font-bold hover:bg-gray-400 hover:text-white cursor-pointer"
        >
          Add Price
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
              />
            </svg>
          </span>
        </div>
      </div>
      <div className="block w-full min-h-screen overflow-x-auto border-t border-blue-50">
        <table className="items-center w-full bg-transparent border-collapse ">
          <thead className="thead-light sticky top-0 select-none z-10">
            <tr className="bg-gray-200">
              <th className="px-5 py-2">State</th>
              <th className="px-5 py-2">City</th>
              <th className="px-5 py-2">On-road price</th>
              <th className="px-5 py-2">Ex-showroom price</th>
              <th className="px-5 py-2">Rto cost</th>
              <th className="px-5 py-2"> Insurance cost</th>
              <th className="px-5 py-2">Other costs</th>
              <th className="px-5 py-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {prices.length ? (
              prices.map((p, i) => (
                <tr key={i} className="border-b ">
                  <td className="px-5 py-2">{p.state_display}</td>
                  <td className="px-5 py-2">{p.city_display}</td>
                  <td className="px-5 py-2">{p.on_road_price ?? "--"}</td>
                  <td className="px-5 py-2">{p.ex_showroom_price ?? "--"}</td>
                  <td className="px-5 py-2">{p.rto_cost ?? "--"}</td>
                  <td className="px-5 py-2"> {p.insurance_cost ?? "--"}</td>
                  <td className="px-5 py-2">{p.other_costs ?? "--"}</td>
                  <td>
                    <div className="flex">
                      <ToggleButton
                        onToggle={(v) => onChangeVisibility(p.id, v)}
                        value={p.active}
                        trueLabel="Live"
                        falseLabel="Offline"
                      />

                      <div
                        onClick={() => setSelectedPrice(p)}
                        className="m-2 rounded-full shadow h-10 w-10 bg-gray-50 flex justify-center items-center transform transition-all duration-300 hover:text-red-700 hover:scale-125 "
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                          />
                        </svg>
                      </div>
                      {isAuthorised({
                        module: MODULE.content,
                        action: [ACTION.DELETE],
                      }) && (
                        <div
                          onClick={() => onDelete(p.id)}
                          className=" m-2  rounded-full shadow h-10 w-10 bg-gray-50 flex justify-center items-center transform transition-all duration-300 hover:text-red-700 hover:scale-125 "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="text-center" colSpan={8}>
                  No Data found !
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PriceListing;
