import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { useAuth } from "../../../context_providers/auth/authProvider";
import {
  useToast,
  ToastType,
} from "../../../context_providers/toast/toast_ctx";
import { OpsTaskCommentModel } from "../../../service/models/ops/ops_ticket";
import {
  CreateOpsTaskCommentApi,
  GetOpsTaskCommentsApi,
} from "../../../service/repos/ops/ops_task_comment_repo";
import { MODULE, ACTION } from "../../../utils/enums";
import LoadingWidget from "../../components/loading_spinner";
import MentionTextFieldWidget from "../orders/order_list/components/mention_text_field";

interface CommentWidgetProps {
  task_id: string;
  active: boolean;
}

const CommentWidget: FunctionComponent<CommentWidgetProps> = ({
  task_id,
  active,
}) => {
  const { showToast } = useToast();
  const { user, isAuthorised } = useAuth();

  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState<OpsTaskCommentModel[]>([]);
  const getComments = async () => {
    setLoading(true);
    const res = await GetOpsTaskCommentsApi(task_id);
    if (res.success) {
      setComments(res.data);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  const createComment = async (comment: string) => {
    setLoading(true);
    const res = await CreateOpsTaskCommentApi({
      task_id,
      comment,
    });

    if (res.success) {
      getComments();
      setInput("");
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  useEffect(() => {
    getComments();
  }, []);
  return (
    <>
      {" "}
      <div className="flex-col justify-between h-full p-2">
        <div className="overflow-auto" style={{ height: "78%" }}>
          {comments.length === 0 && (
            <p className="text-center my-10">No Comment!</p>
          )}
          {comments.map((c, i) => (
            <div key={i} className="p-2 border-b">
              <div className="flex items-center justify-between gap-2 mb-1">
                {/* <div className="bg-blue-500 text-white p-2 flex justify-center items-center rounded-full w-10 h-10">
                  {c.created_by_name?.charAt(0)}
                </div> */}
                <div className="text-sm font-semibold">{c.created_by_name}</div>{" "}
                <div className="text-xs font-thin">
                  {moment(c.created_at)
                    .utcOffset(330)
                    .format("DD MMM YYYY hh:mm a")}
                </div>
              </div>

              <div className=" text-sm italic">{c.comment}</div>
            </div>
          ))}
        </div>

        {!loading && (
          <div className=" grid grid-cols-1 md:grid-cols-2   z-50 fixed bottom-0 w-full right-0  p-2 ">
            <MentionTextFieldWidget
              placeholder="Note"
              disabled={
                !isAuthorised({
                  module: MODULE.ops_task,
                  action: [ACTION.UPDATE],
                })
              }
              value={input}
              onChange={(e) => {
                setInput(e);
              }}
            />

            <button
              onClick={() => {
                if (input) {
                  createComment(input);
                }
              }}
              type="button"
              className="  bg-green-50 text-green-500 border border-green-300 rounded py-1 px-2 text-sm   col-span-2"
            >
              Submit
            </button>
          </div>
        )}
        {/* <div className="">
            <label htmlFor="comment">Add comment</label>
            <textarea
              disabled={!active}
              id="comment"
              className="border rounded p-2 w-full"
              name="comment"
              placeholder="comment"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </div>
          <div className="mx-2 flex">
            <button
              disabled={!active}
              onClick={() => {
                if (comment) {
                  createComment(comment);
                  setComment("");
                }
              }}
              type="button"
              className="m-1 bg-gray-200 rounded py-2 px-4 hover:bg-gray-400 hover:text-white"
            >
              Submit
            </button>
          </div> */}

        <LoadingWidget loading={loading} />
      </div>
    </>
  );
};

export default CommentWidget;
