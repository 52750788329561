import { mdiSecurity } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent } from "react";

interface ErrorPageProps {}

const ErrorPage: FunctionComponent<ErrorPageProps> = (props) => {
  return (
    <div className="flex flex-col w-screen h-screen   justify-center items-center select-none">
      <Icon path={mdiSecurity} size={5} className="text-myPrimaryColor" />
      {/* <div className=" text-lg font-bold text-center">Please note</div> */}
      <div className=" text-3xl font-semibold text-center text-gray-500 mt-5">
        Unauthorised access!
      </div>
      <div className="text-gray-400">
        Sorry buddy you don't have access to the resource
      </div>
    </div>
  );
};

export default ErrorPage;
