import { FunctionComponent, useEffect, useState } from "react";

interface OtpFieldProps {
  onSubmit: (otp: string) => void;
  onChange: (otp: string) => void;
}

const OtpField: FunctionComponent<OtpFieldProps> = (props) => {
  const [otp, setotp] = useState("");
  const handleOTPChange = (e: any) => {
    const currentId = Number(e.target.id);
    e.target.value = parseInt(e.target.value).toString();
    if (e.target.value.length > 1) {
      e.target.value = e.target.value.substring(0, 1);
      e.target.blur();
      return;
    }

    if (e.target.value.length === 1) {
      setotp((o) => (o += e.target.value));
      const nextId = currentId + 1;
      const nextInput = document.getElementById(nextId.toString());
      if (nextInput) {
        nextInput.focus();
      } else {
        e.target.blur();
      }
    } else if (e.target.value.length === 0) {
      setotp((o) => {
        return o.slice(0, -1);
      });
      const prevId = currentId - 1;
      const prevInput = document.getElementById(prevId.toString());
      if (prevInput) {
        prevInput.focus();
      }
    }
  };
  const handleClick = (e: any) => {
    let nextId = otp.length + 1;
    if (nextId > 4) {
      nextId = 4;
    }
    const nextInput = document.getElementById(nextId.toString());
    if (nextInput) {
      nextInput.focus();
    }
  };
  useEffect(() => {
    function handleKeyPress(event: any) {
      if (event.key === "Enter") {
        props.onSubmit(otp);
        return;
      }
      if (event.key === "Backspace") {
        setotp((o) => {
          o = o.substring(0, o.length - 1);
          const nextId = o.length + 1;
          const nextInput = document.getElementById(nextId.toString());
          if (nextInput) {
            nextInput.focus();
          }
          return o;
        });

        return;
      }
    }
    document.addEventListener("keyup", handleKeyPress);
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("keyup", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    props.onChange(otp);
  }, [otp]);
  return (
    <form
      id="otp-form"
      onSubmit={(e) => {
        e.preventDefault();
        props.onSubmit(otp);
      }}
      className="flex items-center justify-between"
    >
      {[1, 2, 3, 4].map((f, index) => (
        <div
          className="flex items-center  "
          style={{ height: "50px", width: "50px" }}
        >
          <input
            onClick={handleClick}
            value={otp[index] ?? ""}
            id={f.toString()}
            type="number"
            className={`w-full h-full text-center focus:outline-none focus:ring-0 border border-gray-600 rounded-lg text-base bg-transparent ${
              otp[index] ? "text-green-500 border-green-400 font-semibold" : ""
            }`}
            maxLength={1}
            min="0"
            onChange={handleOTPChange}
          />
        </div>
      ))}
    </form>
  );
};

export default OtpField;
