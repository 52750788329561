import { FunctionComponent, useState } from "react";
import { Menu } from "react-feather";
import Sidebar from "../../components/Sidebar";
import ProtocolSidebar from "./ProtocolSidebar";

interface ProtocolLayoutProps {}

const ProtocolLayout: FunctionComponent<ProtocolLayoutProps> = (props) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);

  return (
    <>
      <Sidebar />
      <div className="lg:ml-20">
        {/* <ProtocolSidebar
          showMenu={showMenu}
          handleSidebar={() => setShowMenu(false)}
        /> */}
        <div className="fixed top-2 left-3 lg:hidden">
          <Menu className="" size={20} onClick={() => setShowMenu(true)} />
        </div>
        <div className="mt-4 lg:mt-0 bg-white ">{props.children}</div>
      </div>
    </>
  );
};

export default ProtocolLayout;
