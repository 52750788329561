import draftToHtml from "draftjs-to-html";
import HTMLReactParser from "html-react-parser";
import { tagsProcessor } from "../../../utils/tag_processor";
import "./index.css";
const Index = (props) => {
  const content = props.content;
  let listComponents;
  if (content.time) {
    listComponents = content.blocks.map((block) => {
      return tagsProcessor[[`${block.type}`]](block.data);
    });
  }
  return (
    <div className="  col-span-10 col-start-2 my-5">
      {listComponents || HTMLReactParser(draftToHtml(props.content))}
      <style global jsx>{`
        .content img {
          width: 100%;
        }
      `}</style>
    </div>
  );
};
export default Index;
