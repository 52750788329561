import { fetchGet, fetchPost } from "../../api_client";

export const getOrgNamespaceApi = async () => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/cms/org/namespaces`;
  const res = await fetchGet(url);

  return res;
};
export const getActiveOrgPlanApi = async (org: string) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/subscription/plan/org/${org}`;
  const res = await fetchGet(url);

  return res;
};
