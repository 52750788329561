import { mdiRefresh } from "@mdi/js";
import Icon from "@mdi/react";
import { da } from "date-fns/locale";
import { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import LoadingWidget from "../../../../../context_providers/modal/loader";
import { useModal } from "../../../../../context_providers/modal/modal_context";
import {
  useToast,
  ToastType,
} from "../../../../../context_providers/toast/toast_ctx";
import { FieldType } from "../../../../../procurement/components/create_view/form_field";
import TableView from "../../../../../procurement/components/table_comp";
import { fetchGet, toQueryString } from "../../../../../service/api_client";
import SearchFieldWidget from "../../../../../ui/new_components/search_field";
import { currencyFormat } from "../../../../../utils/orders_utils";
import { LedgerEntryModel, accountTxnType } from "../../../models/common_model";
import { LedgerModel } from "../../../models/ledger_model";
import { PurchaseVoucherModel } from "../../../models/purchase_model";
import { SalesVoucherModel } from "../../../models/sale_model ";
import { urls } from "../../../../../utils/urls";

interface OutstandingPayableEntriesListScreenProps {}

const OutstandingPayableEntriesListScreen: FunctionComponent<
  OutstandingPayableEntriesListScreenProps
> = () => {
  const { id } = useParams<{ id: string }>();
  const navaigation = useHistory();
  // const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/accounts/voucher/payment/outstanding/${id}`;
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/accounts/outstanding/payables/${id}`;

  const { showModal, onClose } = useModal();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(50);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<{ voucher: PurchaseVoucherModel }[]>([]);
  const [summery, setSummery] = useState<
    { debit: number; credit: number; ledger: LedgerModel }[]
  >([]);
  const [search, setSearch] = useState("");
  const fetchData = async (prop: {
    search?: string;
    page: number;
    count: number;
    all?: boolean;
  }) => {
    setLoading(true);
    try {
      const res = await fetchGet(url + toQueryString(prop));
      if (res.success) {
        setData(res.data.data);
        if (res.data.metadata) {
          setTotal(res.data.metadata.total);
        }
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
      //   return { success: false, error: error.message };
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData({ page, count, search });
  }, [page]);
  useEffect(() => {
    if (search) fetchData({ page, count, search });
  }, [search]);
  // return <LedgerCreateScreen />;

  const onSelect = (d: PurchaseVoucherModel) => {
    navaigation.push(urls.purchase_voucher + "/update/" + d.id);
  };
  return (
    <>
      <div
        id="page_container"
        className="flex flex-col justify-between h-full p-1"
      >
        <section
          id="top_section"
          className="flex justify-between items-end my-1 "
        >
          <h1 className="font-bold text-2xl text-gray-500 flex items-center gap-3">
            <p className="border-l-4 border-myPrimaryColor pl-2">
              Payable to '{data[0]?.voucher.party_ledger_entry?.ledger?.name}'
            </p>
            <button
              title="Refersh"
              onClick={() => fetchData({ page, count, search })}
            >
              <Icon path={mdiRefresh} className="h-6 w-6"></Icon>
            </button>
          </h1>
          <div className="flex justify-end   text-sm items-center gap-1 ">
            {/* <div className="">
              Opening balance :
              {data[0]?.voucher.party_ledger_entry?.ledger && (
                <b>
                  {currencyFormat(
                    data[0]?.voucher.party_ledger_entry?.ledger?.opening_balance
                      ?.amount
                  )}{" "}
                  {data[0]?.voucher.party_ledger_entry?.ledger?.opening_balance
                    ?.txn_type === accountTxnType.debitor
                    ? "Dr"
                    : "Cr"}
                </b>
              )}
            </div> */}
            <SearchFieldWidget
              defaulValue={search}
              onClear={() => {
                setSearch("");
                fetchData({ page: 1, count });
              }}
              onSubmit={(val) => {
                fetchData({ page: 1, count, search: val });
                setPage(1);
                setSearch("");

                // setTimeout(() => {
                //   setSearch(val);
                // }, 10);
              }}
            />
            {/* <div
              onClick={() => {
                showModal({
                  title: `Add Ledger`,
                  type: ModalType.modal,
                  container(id) {
                    return (
                      <LedgerCreateScreen
                        onClose={(d) => {
                          if (d)
                            setData((o) => {
                              o.pop();
                              return [d, ...o];
                            });
                          setTotal((o) => o + 1);
                          onClose(id);
                        }}
                      />
                    );
                  },
                });
              }}
              className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
            >
              <Icon path={mdiPlus} className="h-4 w-4"></Icon>Add
            </div> */}
          </div>
        </section>{" "}
        <TableView
          headers={[
            {
              colspan: 1,
              key: "date",
              title: "Date",
              type: FieldType.date,
            },
            {
              colspan: 1,
              key: "id",
              title: "voucher no",
              type: FieldType.string,
            },
            {
              colspan: 4,
              key: "voucher_type",
              title: "Voucher",
              type: FieldType.long_string,
            },

            {
              key: "amount",
              title: "Amount",
              type: FieldType.currency,
            },
            {
              key: "paid",
              title: "Paid",
              type: FieldType.currency,
            },
            {
              key: "bal",
              title: "Balance",
              type: FieldType.currency,
            },
            {
              key: "due_date",
              title: "Due date",
              type: FieldType.date,
            },
          ]}
          rows={data.map((data, i) => ({
            data: data.voucher,
            values: [
              data.voucher.date,
              data.voucher.id,
              data.voucher.voucher_type,
              currencyFormat(data.voucher.total),
              currencyFormat(data.voucher.paid),
              currencyFormat(data.voucher.total - (data.voucher.paid || 0)),
              data.voucher.due_date,
            ],
            onClick: onSelect,
          }))}
          onClick={onSelect}
          page={page}
          total={total}
          count={count}
          itemCount={data.length}
          onPageChange={setPage}
        />
      </div>

      {loading && <LoadingWidget></LoadingWidget>}
    </>
  );
};

export default OutstandingPayableEntriesListScreen;
