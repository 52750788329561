import { Tooltip } from "@material-ui/core";
import {
  mdiBookmarkBoxMultipleOutline,
  mdiClose,
  mdiCommentMultipleOutline,
  mdiDelete,
  mdiDice1Outline,
  mdiEmail,
  mdiMail,
  mdiReceipt,
} from "@mdi/js";
import Icon from "@mdi/react";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { useAuth } from "../../../../../context_providers/auth/authProvider";
import {
  useToast,
  ToastType,
} from "../../../../../context_providers/toast/toast_ctx";

import {
  CreateOrderCommentApi,
  DeleteOrderCommentApi,
  GetOrderCommentApi,
} from "../../../../../service/repos/order_repo";

import {
  ACTION,
  MODULE,
  remarks,
  SubsidyStatus,
} from "../../../../../utils/enums";
import LoadingWidget from "../../../../components/loading_spinner";
import CommunicationWidget from "./communication_widget";
import InvoiceWidget from "./invoice_widget";
import MentionTextFieldWidget from "./mention_text_field";
export interface OrderCommentModel {
  id?: string;
  order_id: string;
  category: string;
  comment: string;
  created_at?: number;
  uid: any;
  user: string;
  img?: string;
  deleted: boolean;
  deleted_at: number;
}
interface CommentCategoryDropdownProps {
  onChange: (val: string) => void;
  value: string;
}

const CommentCategoryDropdown: FunctionComponent<
  CommentCategoryDropdownProps
> = ({ onChange, value }) => {
  return (
    <Tooltip title="Category">
      <select
        name=""
        id=""
        className="px-2 py-1 bg-gray-100 rounded focus:outline-none"
        onChange={(e) => onChange(e.target.value)}
        value={value}
      >
        <option value="" hidden>
          Select
        </option>
        {[
          "All",
          "General",
          "Site Survey",
          "Registration",
          "Installation",
          "Payment",
          "Wiring",
        ].map((type, i) => (
          <option value={type} key={i}>
            {type}
          </option>
        ))}
      </select>
    </Tooltip>
  );
};

interface OrderCommentsProps {
  order: {
    order_id: string;
    hold_remark?: string;
    registration_remark?: string;
    subsidy_status?: string;
    remark?: string;
  };
  // show: boolean;
  // onClose: (v: boolean) => void;
  onRemarkChange: (val: { [key: string]: string }) => void;
}

const OrderComments: FunctionComponent<OrderCommentsProps> = (props) => {
  const { showToast } = useToast();
  const { user, isAuthorised, logout } = useAuth();
  const [updateDiabled, setUpdateDisabled] = useState(true);
  const [commentDiabled, setCommentDisabled] = useState(true);
  const [selectedTab, setSelectedTab] = useState(1);
  const [comments, setComments] = useState<OrderCommentModel[]>([]);
  const [comment, setComment] = useState("");
  const [category, setCategory] = useState("All");
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(6);
  const [total, setTotal] = useState(0);
  const getComments = () =>
    GetOrderCommentApi({
      order_id: props.order!.order_id!,
      category,
      page,
      count,
    })
      .then((res) => {
        console.log(res);
        if (res.success) {
          setComments((o) => {
            // console.log({ x: o.length, y: (page - 1) * count });

            if (o.length > (page - 1) * count) {
              //pop excess

              const d = o.length - (page - 1) * count;
              console.log({ excess: d, l: o.length - d });

              // o = o.filter((f, i) => i < o.length -d);
              const deleted = o.splice(0, d);
              console.log({ o, deleted });
            }
            return [...res.data.comments.reverse(), ...o];
          });
          if (res.data.metadata[0]) {
            setTotal(res.data.metadata[0].total);
          }
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  const createComment = async (comment: string) => {
    setLoading(true);

    const res = await CreateOrderCommentApi({
      order_id: props.order.order_id,
      comment,
      category,
    });

    if (res.success) {
      setComments((old) => [...old, res.data]);
      setComment("");
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }

    setLoading(false);
  };
  const deleteComment = async (comment_id: string) => {
    if (!window.confirm("Are your sure !")) {
      return;
    }
    setLoading(true);

    const res = await DeleteOrderCommentApi({
      order_id: props.order.order_id,
      comment_id,
    });
    if (res.success) {
      setComments((old) => {
        return old.map((f) => {
          if (f.id === comment_id) {
            f.deleted = true;
            f.deleted_at = Date.now();
          }
          return f;
        });
      });
      showToast({ type: ToastType.success, text: res.message ?? "" });
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }

    setLoading(false);
  };
  useEffect(() => {
    getComments();
  }, [category, page]);
  const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
    if (!scrolled && comments.length > 0) scrollBottom();
  }, [comments]);
  const scrollBottom = () => {
    const container = document.getElementById("chat_wrap");
    if (container) {
      container.scrollTop = container.scrollHeight;
      setScrolled(true);
    }
  };
  // useEffect(() => {
  //   const chatScreen = document.getElementById("chat_wrap")!;
  //   const cb = () => {
  //     if (chatScreen.scrollTop === 0) {
  //       console.log("scrolled", {
  //         nextPageAvailable: page * count < total,
  //         offset: page * count,
  //         total,
  //       });

  //       if (page * count < total) setPage((o) => o + 1);
  //     }
  //   };
  //   if (chatScreen) {
  //     chatScreen.addEventListener("scroll", cb);
  //   }
  //   return () => {
  //     if (chatScreen) chatScreen.removeEventListener("scroll", cb);
  //     console.log("removed listner");
  //   };
  // }, [page, total]);

  useEffect(() => {
    setUpdateDisabled(
      !isAuthorised({
        module: MODULE.order,
        action: [ACTION.UPDATE],
      })
    );
    setCommentDisabled(
      !isAuthorised({
        module: MODULE.order_comment,
        action: [ACTION.CREATE],
      })
    );
  }, []);

  const lastTime = Date.now() - 3.6e6;
  const showLoadMoreComment = page * count < total;
  return (
    <div className="flex-col justify-between h-full   bg-gray-100 relative">
      <div className="border-b border-gray-200 dark:border-gray-700 sticky top-0 z-10 bg-white">
        <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
          <li className="mr-2">
            <button
              onClick={() => setSelectedTab(1)}
              className={`inline-flex gap-1 p-4 transition duration-300 rounded-t-lg border-b-2 ${
                selectedTab === 1
                  ? "text-red-600 border-red-600 active dark:text-blue-500 dark:border-blue-500"
                  : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
              } group`}
            >
              <Icon path={mdiCommentMultipleOutline} className="w-5 h-5"></Icon>
              Comments
            </button>
          </li>
          <li className="mr-2">
            <button
              onClick={() => setSelectedTab(2)}
              className={`inline-flex gap-1 p-4 transition duration-300 rounded-t-lg border-b-2 ${
                selectedTab === 2
                  ? "text-red-600 border-red-600 active dark:text-blue-500 dark:border-blue-500"
                  : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
              } group`}
              aria-current="page"
            >
              <Icon
                path={mdiBookmarkBoxMultipleOutline}
                className="w-5 h-5"
              ></Icon>
              Remarks
            </button>
          </li>
          <li className="mr-2">
            <button
              onClick={() => setSelectedTab(3)}
              className={`inline-flex gap-1 p-4 transition duration-300 rounded-t-lg border-b-2 ${
                selectedTab === 3
                  ? "text-red-600 border-red-600 active dark:text-blue-500 dark:border-blue-500"
                  : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
              } group`}
              aria-current="page"
            >
              <Icon path={mdiEmail} className="w-5 h-5"></Icon>
              Mail
            </button>
          </li>
          <li className="mr-2">
            <button
              onClick={() => setSelectedTab(4)}
              className={`inline-flex gap-1 p-4 transition duration-300 rounded-t-lg border-b-2 ${
                selectedTab === 4
                  ? "text-red-600 border-red-600 active dark:text-blue-500 dark:border-blue-500"
                  : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
              } group`}
              aria-current="page"
            >
              <Icon path={mdiReceipt} className="w-5 h-5"></Icon>
              Invoice
            </button>
          </li>
        </ul>
      </div>

      {selectedTab === 1 ? (
        <>
          <div className="flex justify-end">
            <div className="my-2">
              <CommentCategoryDropdown
                onChange={(val) => setCategory(val)}
                value={category}
              />
            </div>
          </div>
          <div
            className="flex flex-col justify-between gap-2  p-1 "
            style={{ height: "83%" }}
          >
            <div id="chat_wrap" className="overflow-auto    ">
              {comments.length === 0 && (
                <p className="text-center my-10">No Comment!</p>
              )}
              {comments.map((c, i) => {
                if (showLoadMoreComment && i === 0) {
                  return (
                    <div className="flex justify-center">
                      <button
                        title="Click me to load old comments"
                        onClick={() => setPage((p) => p + 1)}
                        className="text-sm border rounded-3xl px-3 py-1"
                      >
                        Load More
                      </button>
                    </div>
                  );
                }

                const comment = comments[i - (showLoadMoreComment ? 1 : 0)];

                return comment.deleted ? (
                  <div className=" my-2 shadow rounded bg-white p-2">
                    <div className="p-1 text-xs italic flex   ">
                      <p className="px-3 py-1  bg-red-100 rounded-lg">
                        {comment.uid === user?.uid ? "You" : comment.user}{" "}
                        deleted this comment
                      </p>{" "}
                    </div>
                    <div
                      className="flex justify-between italic "
                      style={{ fontSize: "10px" }}
                    >
                      <p>
                        Deleted at
                        {" " + moment(comment.deleted_at).format("lll")}{" "}
                      </p>
                      {moment(comment.created_at).format("lll")}{" "}
                    </div>
                  </div>
                ) : (
                  <div
                    key={i}
                    className="my-2 shadow rounded bg-white p-2 relative"
                  >
                    {comment.uid === user?.uid &&
                      comment.created_at! >= lastTime && (
                        <div
                          className="p-1 rounded-bl-full bg-red-600 text-white absolute top-0 right-0 hover:scale-125 transition duration-300"
                          onClick={() => deleteComment(comment.id!)}
                        >
                          <Icon path={mdiClose} className="h-4 w-4"></Icon>
                        </div>
                      )}
                    <div className="flex justify-start items-center py-2 gap-2">
                      {comment.img ? (
                        <div className="bg-red-400 text-white rounded-full w-10 h-10  flex justify-center items-center font-bold ">
                          <img
                            src={`${comment.img}`}
                            alt=""
                            className="w-10 h-10 rounded-full"
                          />
                        </div>
                      ) : (
                        <div className="bg-red-400 text-white rounded-full w-10 h-10  flex justify-center items-center font-bold ">
                          {comment.user ? comment.user[0].toUpperCase() : "A"}
                        </div>
                      )}

                      <div className="flex justify-between w-full">
                        <div className=" text-sm font-medium">
                          {comment.user}
                        </div>
                        {category === "All" && (
                          <span className=" text-xs bg-gray-200   rounded-3xl px-2 py-1 mr-4">
                            {comment.category}
                          </span>
                        )}
                      </div>
                    </div>

                    <p className="text-xs   whitespace-pre-wrap overflow-hidden">
                      {comment.comment}
                    </p>
                    <div
                      className="flex justify-end italic "
                      style={{ fontSize: "10px" }}
                    >
                      {moment(comment.created_at).format("lll")}{" "}
                    </div>
                  </div>
                );
              })}
            </div>
            {!loading && (
              <div className=" grid grid-cols-1 md:grid-cols-2   z-50   bottom-3 left-3 right-3  ">
                <MentionTextFieldWidget
                  placeholder={`Add ${
                    category === "All" ? "General" : category
                  } comment`}
                  disabled={commentDiabled}
                  value={comment}
                  onChange={(e) => {
                    setComment(e);
                  }}
                />

                <button
                  onClick={() => {
                    if (comment) {
                      createComment(comment);
                    }
                  }}
                  type="button"
                  className="  bg-gray-200 rounded py-1 px-2 text-sm hover:bg-red-400 hover:text-white col-span-2"
                >
                  Submit
                </button>
              </div>
            )}
          </div>
        </>
      ) : selectedTab === 2 ? (
        <div className="grid grid-cols-1 gap-4 bg-white p-5 ">
          <div className="">
            <div className=" mb-1 font-semibold text-xs">
              Pending collection remark
            </div>
            <select
              disabled={updateDiabled}
              name=""
              id=""
              className={`w-full text-xs  px-3 py-1 ${
                updateDiabled ? "cursor-not-allowed" : "cursor-pointer"
              } bg-gray-100 rounded focus:outline-none`}
              value={props.order.remark}
              required
              onChange={(e) => {
                if (
                  window.confirm(
                    "Are you sure to update Pending collection remark  to :" +
                      e.target.value
                  )
                )
                  props.onRemarkChange({ remark: e.target.value });
                else e.target.value = "";
              }}
            >
              <option value="" hidden>
                Select ...
              </option>
              {remarks.sort().map((src, i) => (
                <option value={src} key={i}>
                  {src}
                </option>
              ))}
            </select>
          </div>
          <div className="">
            <div className=" mb-1 font-semibold text-xs">Subsidy remark</div>
            <select
              disabled={updateDiabled}
              className={`w-full text-xs  px-3 py-1 ${
                updateDiabled ? "cursor-not-allowed" : "cursor-pointer"
              } bg-gray-100 rounded focus:outline-none`}
              value={props.order.subsidy_status}
              onChange={(e) => {
                if (
                  window.confirm(
                    "Are you sure to update subsidy status  :" + e.target.value
                  )
                )
                  props.onRemarkChange({
                    subsidy_status: e.target.value,
                  });
                else e.target.value = "";
              }}
            >
              <option value="" hidden>
                Select
              </option>
              <option value="">N/A</option>
              {Object.values(SubsidyStatus)
                .sort()
                .map((item, i) => (
                  <option key={i} value={item}>
                    {item}
                  </option>
                ))}
            </select>
          </div>{" "}
          <div className="">
            <div className=" mb-1 font-semibold text-xs">
              Registration remark
            </div>

            <select
              disabled={updateDiabled}
              name=""
              id=""
              className={`w-full text-xs  px-3 py-1 ${
                updateDiabled ? "cursor-not-allowed" : "cursor-pointer"
              } bg-gray-100 rounded focus:outline-none`}
              value={props.order.registration_remark}
              onChange={(e) => {
                if (
                  window.confirm(
                    "Are you sure to update registration remark  to :" +
                      e.target.value
                  )
                )
                  props.onRemarkChange({
                    registration_remark: e.target.value,
                  });
                else e.target.value = "";
              }}
            >
              <option value="" hidden>
                Select ...
              </option>
              {[
                "Less load",
                "Documents Not shared",
                "Meter Category Issue",
                "Client Not Responding",
                "Name change required",
                "Other",
              ].map((src, i) => (
                <option value={src} key={i}>
                  {src}
                </option>
              ))}
            </select>
          </div>{" "}
          <div className="">
            <div className=" mb-1 font-semibold text-xs">Hold remark</div>
            <select
              disabled={updateDiabled}
              name=""
              id=""
              className={`w-full text-xs  px-3 py-1 ${
                updateDiabled ? "cursor-not-allowed" : "cursor-pointer"
              } bg-gray-100 rounded focus:outline-none`}
              value={props.order.hold_remark}
              onChange={(e) => {
                if (
                  window.confirm(
                    "Are you sure to update hold remark  to :" + e.target.value
                  )
                )
                  props.onRemarkChange({
                    hold_remark: e.target.value,
                  });
                else e.target.value = "";
              }}
            >
              <option value="" hidden>
                Select ...
              </option>
              {[
                "Location not finalized",
                "Order approval pending",
                "Upfront blackout competitor acquired",
                "Construction is happening",
                "Committee changed",
                "Loss of sale",
              ].map((src, i) => (
                <option value={src} key={i}>
                  {src}
                </option>
              ))}
            </select>
          </div>
        </div>
      ) : selectedTab === 3 ? (
        <CommunicationWidget order_id={props.order.order_id} />
      ) : selectedTab === 4 ? (
        <InvoiceWidget order_id={props.order.order_id} />
      ) : (
        <></>
      )}
      <LoadingWidget loading={loading} />
    </div>
  );
};

export default OrderComments;
