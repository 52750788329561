import debounce from "lodash.debounce";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { UserModel } from "../../../../../context_providers/auth/user_model";
import {
  useToast,
  ToastType,
} from "../../../../../context_providers/toast/toast_ctx";
import { GetEmps } from "../../../../../service/repos/users_repo";

interface MentionTextFieldWidgetProps {
  placeholder: string;
  disabled: boolean;
  value: string;
  onChange: (str: string) => void;
  className?: string;
}

const MentionTextFieldWidget: FunctionComponent<
  MentionTextFieldWidgetProps
> = ({ placeholder, disabled, onChange, value, className }) => {
  const { showToast } = useToast();
  const [comment, setComment] = useState(value ?? "");
  const [showMention, setShowMention] = useState(false);

  const [emps, setEmps] = useState<UserModel[]>([]);

  const getMentions = async (str: string) => {
    console.log({ str });

    if (!str) {
      setEmps([]);
      return;
    }
    const res = await GetEmps({ search: str, all: true });
    console.log({ res });

    if (res.success) {
      setEmps(res.data.data);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
  };
  useEffect(() => {
    if (comment) {
      const split = comment.trim().split(" ");
      if (!split[split.length - 1].startsWith("@")) {
        setShowMention(false);
      } else {
        setShowMention(true);
        const qry = split[split.length - 1].substring(1);
        debouncedHandler(qry);
        // debounce(() => {
        //   getMentions(qry)
        //     .then((v) => {
        //       console.log("completed");
        //     })
        //     .catch((e) => console.log(e));
        // }, 500);
      }
    } else setShowMention(false);
    // debounce(() => {
    onChange(comment);
    // }, 500);
  }, [comment]);

  const debouncedHandler = useCallback(
    debounce((query) => {
      console.log({ query });

      if (query) getMentions(query);
    }, 500),
    []
  );
  return (
    <>
      <div className={`  relative col-span-2`}>
        <textarea
          id="comment"
          rows={3}
          className={` ${className} border rounded p-2 w-full text-sm focus:outline-none`}
          name="comment"
          disabled={disabled}
          placeholder={placeholder}
          value={comment}
          onChange={(e) => {
            setComment(e.target.value);
          }}
        />

        {showMention && (
          <div className="absolute --top-56 bottom-24 border rounded-md text-sm  flex flex-col gap-2 bg-white max-h-52 w-full overflow-auto z-10 ">
            {emps.length > 0 ? (
              emps.map((e, i) => (
                <div
                  key={i}
                  className="border-b hover:bg-gray-100 p-2 cursor-pointer font-semibold"
                  onClick={() => {
                    setComment((o) => {
                      const split = o.trim().split(" ");
                      return o.replace(split[split.length - 1], e.email + " ");
                    });
                  }}
                >
                  {e.email}
                </div>
              ))
            ) : (
              <div className="border-b   p-2  ">Tag Users</div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default MentionTextFieldWidget;
