import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import ProfileCard from "../profile/components/ProfileCard";
import Sidebar from "../components/sidebar";
import "../styles/index.css";
import { useEffect, useState } from "react";
import { GetDepartments, GetEmps } from "../../../../service/repos/users_repo";
import { ACTION, MODULE, SubDepartments } from "../../../../utils/enums";
import { Search, X } from "react-feather";
import EmpCard from "../dept-employees/components/EmpCard";
import Layout from "../layout";
import HrmLoader from "../components/loading";
import { useAuth } from "../../../../context_providers/auth/authProvider";
import { urls } from "../../../../utils/urls";

const Employees = () => {
  const [showModal, setShowModal] = useState(false);
  const [count, setCount] = useState(0);
  const [searchName, setSearchName] = useState<string>("");
  const [showUsers, setShowUsers] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [searchedUsers, setSearchedUsers] = useState<[]>([]);
  const { user, isAuthorised } = useAuth();
  const location = useLocation();

  const [empData, setEmpData] = useState<
    {
      department: string;
      count: number;
    }[]
  >([]);

  const closeModal = () => {
    setShowModal(false);
  };

  const getLogo = (dept: string) => {
    const deps = Object.keys(SubDepartments);

    switch (dept) {
      case deps[0]:
        return "accounts";
      case deps[1]:
        return "strategy";
      case deps[2]:
        return "procurement";
      case deps[3]:
        return "hr";
      case deps[4]:
        return "management1";
      case deps[5]:
        return "sales";
      case deps[6]:
        return "content";
      case deps[7]:
        return "operations";
      case deps[8]:
        return "tech";
      case deps[9]:
        return "design";

      default:
        return "nothing";
    }
  };

  const history = useHistory();

  const getDeptData = async () => {
    setShowLoading(true);
    const res = await GetDepartments();
    if (res.success) {
      const checkData = res.data?.filter((d: any) => d.department !== null);

      var count = 0;
      for (let i = 0; i < checkData.length; i++) {
        count = count + checkData[i].count;
      }
      setCount(count);
      setEmpData(res.data);
      setShowLoading(false);
    }
  };

  useEffect(() => {
    getDeptData();
  }, []);

  const findUsers = async (n: string) => {
    if (n === "") {
      return;
    }
    setShowLoading(true);
    const res = await GetEmps({ search: n });
    if (res.success) {
      setSearchedUsers(res.data.data);
      setShowLoading(false);
      setShowUsers(true);
      history.push(urls.hrm_dept + `?search=${n}`);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const searchValue = queryParams.get("search");
    if (searchValue) {
      console.log(typeof searchValue);

      setSearchName(searchValue);
      findUsers(searchValue);
    }
  }, []);

  return (
    <Layout pageTitle="Departments">
      <div className="py-4 lg:pl-6">
        <div className="">
          <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center">
            <div className="pl-2 mt-3 font-semibold text-secondaryColor dark:text-primaryColor">
              {count} people
            </div>
            {isAuthorised({
              module: MODULE.hrm_leave,
              action: [ACTION.UPDATE],
            }) && (
              <div className="ml-3 lg:mr-20 mt-4 lg:mt-0">
                <NavLink to={urls.hrm_add_employee}>
                  <button className="bg-hrmBlue text-sm lg:text-base py-2 px-6 text-white rounded-md">
                    Add employee +
                  </button>
                </NavLink>
              </div>
            )}
          </div>

          <div className=" flex flex-col items-center my-6">
            <form
              action=""
              onSubmit={(e) => {
                e.preventDefault();
                findUsers(searchName);
              }}
              className="w-4/5 lg:w-1/2"
            >
              <div className="bg-white rounded-full px-6 py-3 flex">
                <input
                  type="text"
                  className="w-5/6 bg-transparent focus:outline-none  dark:text-primaryColor "
                  value={searchName}
                  required
                  placeholder="Search employee"
                  onChange={(e) => setSearchName(e.target.value)}
                />
                <div className="w-1/6 flex gap-1 justify-end">
                  {showUsers ? (
                    <X
                      className="dark:text-primaryColor cursor-pointer"
                      onClick={() => {
                        setShowUsers(false);
                        setSearchName("");
                        history.push(urls.hrm_dept);
                      }}
                      size={20}
                    />
                  ) : (
                    <Search
                      className="text-primaryColor dark:text-primaryColor cursor-pointer"
                      onClick={() => findUsers(searchName)}
                      size={20}
                    />
                  )}
                </div>
              </div>
            </form>
          </div>
          {showUsers ? (
            <>
              {showLoading ? (
                <div className="flex flex-col items-center">
                  <HrmLoader className="w-12 h-12" />
                </div>
              ) : (
                <div className="grid grid-cols-1 gap-x-4 gap-y-5 lg:gap-y-6 lg:grid-cols-4">
                  {searchedUsers.map((user, i) => {
                    return <EmpCard emp={user} key={i} />;
                  })}
                </div>
              )}
            </>
          ) : (
            <>
              {showLoading ? (
                <div className="flex flex-col items-center">
                  <HrmLoader className="w-12 h-12" />
                </div>
              ) : (
                <div className="grid grid-cols-2 lg:grid-cols-4  gap-x-2 gap-y-2 lg:gap-x-6 lg:gap-y-4 mt-3 pb-12 lg:pb-0">
                  {empData
                    .filter((d) => d.department !== null)
                    .map((item, i) => {
                      return (
                        <div className="w-full lg:w-auto">
                          <ProfileCard
                            data={{
                              ...item,
                              logo: getLogo(item.department),
                              link: `/hrm/departments/${item.department}`,
                              count: item.count + " people",
                            }}
                            key={i}
                          />
                        </div>
                      );
                    })}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Employees;
