import gsap from "gsap/all";
import { FunctionComponent, useEffect, useState } from "react";
import { Trash2 } from "react-feather";
import { useAuth } from "../../../../context_providers/auth/authProvider";
import {
  ToastType,
  useToast,
} from "../../../../context_providers/toast/toast_ctx";
import { ACTION, MODULE } from "../../../../utils/enums";
import HrmPopup from "../components/HrmPopup";
import HrmLoader from "../components/loading";
import Layout from "../layout";
import { JobDetail } from "../service/models/job_portal";
import {
  DeleteJob,
  GetJobs,
  UpdateJobDetail,
} from "../service/repos/job_portal";
import Description from "./components/Description";
import Referral from "./components/Referral";
import "../styles/index.css";

interface JobPortalProps {}

const JobPortal: FunctionComponent<JobPortalProps> = () => {
  const [jobs, setJobs] = useState<JobDetail[]>([]);
  const [showActive, setShowActive] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const { showToast } = useToast();
  const { user, isAuthorised } = useAuth();

  const getJobData = async () => {
    setLoading(true);
    const res = await GetJobs({
      page: 1,
      count: 10,
      with_count: false,
      active: showActive,
    });
    console.log(res);
    if (res.success) {
      setJobs(res.data?.data);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };

  const deleteJobCard = (id: string) => {
    const tempJobs = jobs?.filter((el) => el.id !== id);
    setJobs(tempJobs);
  };

  const animateData = () => {
    const tl = gsap.timeline();
    tl.fromTo(
      ".job_card",
      0.5,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        stagger: 0.2,
      }
    );
  };

  useEffect(() => {
    getJobData();
  }, [showActive]);

  useEffect(() => {
    animateData();
  }, []);

  return (
    <Layout pageTitle="Employee job portal">
      <div className="lg:px-0 lg:pl-6  py-3 lg:py-0">
        <div className="flex gap-8  ">
          <div
            className={`${
              showActive ? "border-b-4 border-hrmBlue pb-2 font-bold" : ""
            } cursor-pointer `}
            onClick={() => setShowActive(true)}
          >
            Active
          </div>
          {isAuthorised({
            module: MODULE.hrm_leave,
            action: [ACTION.UPDATE],
          }) && (
            <div
              className={`${
                !showActive ? "border-b-4 border-hrmBlue pb-2 font-bold" : ""
              } cursor-pointer `}
              onClick={() => setShowActive(false)}
            >
              Inactive
            </div>
          )}
        </div>
        {loading ? (
          <div className="">
            <HrmLoader className="w-12 h-12" />
          </div>
        ) : (
          <div className="mt-4 lg:mt-8 lg:px-5">
            {jobs?.length > 0 ? (
              <div className=" grid justify-items-center lg:justify-items-start lg:grid-cols-4 lg:gap-x-12 gap-y-10  ">
                {jobs.map((el: JobDetail, i: number) => {
                  return (
                    <JobCard data={el} DeleteCard={deleteJobCard} key={i} />
                  );
                })}
              </div>
            ) : (
              <div className="">No jobs</div>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default JobPortal;

interface JobCardProps {
  data: JobDetail;
  DeleteCard: (id: string) => void;
}

const JobCard: FunctionComponent<JobCardProps> = ({ data, DeleteCard }) => {
  const [showReferral, setShowReferral] = useState<boolean>(false);
  const [showDescription, setShowDescription] = useState<boolean>(false);
  const { user, isAuthorised } = useAuth();

  const updateStatus = async (id: string, data: JobDetail) => {
    data.active = !data.active;
    const res = await UpdateJobDetail(id, data);

    if (res.success) {
      DeleteCard(id);
    }
  };

  const deleteJob = async (id: string) => {
    const res = await DeleteJob(id);
    console.log(res);

    if (res.success) {
      DeleteCard(id);
    } else {
      alert(res.error);
    }
  };

  return (
    <div className="w-4/5 lg:w-full">
      <div className="bg-white py-8 px-5 rounded-2xl shadow-md relative ">
        {isAuthorised({
          module: MODULE.hrm_leave,
          action: [ACTION.UPDATE],
        }) && (
          <div className="absolute top-3 right-3">
            <div className="flex items-center gap-6">
              <div className="flex items-center gap-2">
                <div className="text-sm text-gray-500">
                  {data?.active ? "Inactive" : "Active"}
                </div>
                <div
                  className="flex w-6 h-3 rounded-full bg-gray-300 cursor-pointer"
                  onClick={() => updateStatus(data?.id, data)}
                >
                  <div
                    className={`w-3 h-3 rounded-full ${
                      data?.active ? "bg-hrmRed" : "bg-hrmBlue"
                    } `}
                  ></div>
                </div>
              </div>

              <Trash2
                size={20}
                className="text-hrmRed cursor-pointer"
                onClick={() => {
                  const confirm = window.confirm(
                    "Are you sure you want to delete the job?"
                  );
                  if (confirm) {
                    deleteJob(data.id);
                  }
                }}
              />
            </div>
          </div>
        )}

        <div className="font-semibold text-sm text-gray-500 border-b border-gray-200">
          {data.department}
        </div>
        <div className="truncate text-xl font-bold mt-3">{data.title}</div>
        <div className=" text-sm">{data.job_type}</div>
        <div className="my-10 grid grid-cols-2  ">
          <div className=" flex flex-col items-center">
            <div className="text-sm text-gray-500">Location</div>
            <div className=" font-semibold">{data.location}</div>
          </div>
          <div className=" flex flex-col items-center">
            <div className="text-sm text-gray-500">Position</div>
            <div className=" font-semibold">{data.position}</div>
          </div>
        </div>

        <div className="h-px bg-gray-200 mt-1 mb-5 w-full"></div>
        <div className="flex justify-between items-center">
          <div
            className="text-sm font-bold  text-opacity-70 cursor-pointer"
            onClick={() => setShowDescription(true)}
          >
            See job details &gt;
          </div>
          <div className="">
            <button
              className="py-2 px-6 text-sm rounded-md text-white font-bold bg-hrmBlue border-hrmBlue "
              onClick={() => setShowReferral(true)}
            >
              Refer
            </button>
          </div>
        </div>
      </div>
      {showReferral && (
        <HrmPopup>
          <Referral id={data.id} close={() => setShowReferral(false)} />
        </HrmPopup>
      )}
      {showDescription && (
        <HrmPopup>
          <Description data={data} close={() => setShowDescription(false)} />
        </HrmPopup>
      )}
    </div>
  );
};
