import { id } from "date-fns/locale";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LoadingWidget from "../../../../context_providers/modal/loader";
import {
  ToastType,
  useToast,
} from "../../../../context_providers/toast/toast_ctx";
import {
  fetchGet,
  fetchPost,
  fetchPut,
  toQueryString,
} from "../../../../service/api_client";
import { numberRegEx } from "../../../../utils/constant";
import { UTCToLocalDate } from "../../../../utils/date_util";
import { currencyFormat } from "../../../../utils/orders_utils";
import LedgerSearchField from "../../../accounts/ledgers/ledger_search_field";
import {
  PaymentEntryType,
  accountTxnType,
  LedgerEntryModel,
} from "../../../accounts/models/common_model";
import { ledgerEntryDefaultValue } from "../../../accounts/models/purchase_model";
import { SalesVoucherModel } from "../../../accounts/models/sale_model ";
import ItemSearchField from "../../items/item_search_field";
import {
  itemMovementDefaultValue,
  ItemMovementModel,
} from "../../models/inventory_voucher";
import { BomItem, itemDefaultValue, ItemModel } from "../../models/item_model";

import WarehouseSearchField from "../../warehouse/warehouse_search_field";
import { WAREHOUSE_TYPE } from "../../models/warehouse_model";

interface ManufacturingProjectionProps {}

const ManufacturingProjection: FunctionComponent<
  ManufacturingProjectionProps
> = (props) => {
  const { id } = useParams<{ id: string }>();
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/journal/manufacturing`;
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();
  const [data, setData] = useState<ItemMovementModel>({
    ...itemMovementDefaultValue,
  });

  const getBom = async (item: ItemModel) => {
    const url = `${
      process.env.REACT_APP_BACKEND_BASE_URL
    }/api/v3/erp/inventory/items/${item.id}/bom${toQueryString({
      warehouse_id: data.warehouse_id,
    })}`;
    setLoading(true);
    const res = await fetchGet(url);
    if (res.success) {
      setData((o) => ({
        ...o,
        item: { ...o.item!, bom: res.data },
      }));
    } else {
      showToast({ type: ToastType.error, text: res.error });
      setData((o) => ({
        ...o,
        item: { ...o.item!, bom: { ...o.item!.bom, items: [] } },
      }));
    }
    setLoading(false);
  };

  return (
    <>
      <div className=" mt-2 px-6">
        <div className="flex items-center justify-between mb-2">
          <div className="text-xl font-bold border-l-4 border-myPrimaryColor pl-2">
            Manufacturing projection
          </div>
          {/* <div className="flex gap-2 items-center">
            <label htmlFor="" className="text-sm font-semibold text-gray-500">
              Date
            </label>{" "}
            <input
              type="date"
              className=" focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full text-sm border"
              value={data.date ? moment(data.date).format("YYYY-MM-DD") : ""}
              onChange={(e) => {
                const date = UTCToLocalDate(e.target.value)!;
                setData((o) => ({
                  ...o,
                  date: date,
                }));
              }}
            />
          </div> */}
        </div>
        <div className="bg-white rounded-lg px-3 py-5">
          <div className="grid grid-cols-3 gap-5 ">
            {" "}
            <div className="grid grid-cols-4 items-center ">
              <label htmlFor="" className="text-sm font-semibold text-gray-500">
                Warehouse
              </label>
              <div className="col-span-3">
                   <WarehouseSearchField type={WAREHOUSE_TYPE.warehouse}
                  placeholder="Warehouse"
                  value={data.id ? data.warehouse : undefined}
                  onSelect={(d) => {
                    setData((o) => ({
                      ...o,
                      warehouse: d,
                      warehouse_id: d.id,
                    }));
                  }}
                />
              </div>
            </div>
            <div className="grid grid-cols-4 items-center">
              <label htmlFor="" className="text-sm font-semibold text-gray-500">
                Product
              </label>
              <div className="col-span-3">
                <ItemSearchField
                  disabled={!data.warehouse_id}
                  placeholder="Manufacture product"
                  value={data.id ? data.item : undefined}
                  onSelect={(d) => {
                    getBom(d);
                  }}
                />
              </div>
            </div>
            <div className="grid grid-cols-4 items-center ">
              <label htmlFor="" className="text-sm font-semibold text-gray-500">
                Qunatity
              </label>

              <input
                value={data?.unit_no}
                onChange={(e) => {
                  const val = e.target.value.trim();
                  if (!val || numberRegEx.test(val)) {
                    const value = Number(val || 0);
                    setData((old) => {
                      return {
                        ...old,
                        unit_no: value,
                      };
                    });
                  }
                }}
                //   disabled={!editable || props.disabled}
                placeholder="Manufacture Qty"
                type="text"
                className="text-right  p-1 focus:outline-none border rounded col-span-3"
              />
            </div>
            {/* <div className="grid grid-cols-4 items-center">
              <label htmlFor="" className="text-sm font-semibold text-gray-500">
                Bom
              </label>
              <div className=" p-1   border rounded col-span-3">
                {data.product_item_entry.item?.bom.name || "---"}
              </div>
            </div> */}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <div className="my-5">
            <h3 className="text-sm font-bold">Components(consumption)</h3>
            <div
              className="bg-white rounded flex flex-col gap-1 border  overflow-auto"
              style={{ height: "75vh" }}
            >
              <div className="grid grid-cols-6 gap-5 p-1 bg-myPrimaryColor text-white text-sm font-semibold rounded-t">
                <div className="col-span-2">Particular</div>
                <div className="text-center">qty</div>
                <div className="text-right">Rate</div>
                <div className="text-right">Amount</div>
                <div className="text-right">closing qty</div>
              </div>
              <div className="flex flex-col ">
                {data.item?.bom.items.map((it, i) => {
                  const price = it.price || 0;
                  const units = it.unit_no * data.unit_no;
                  const amount = price * units;
                  // let req_units = units - (it.closing_qty || 0);
                  // if (req_units < 0) req_units = 0;
                  return (
                    <div
                      key={i}
                      className="grid grid-cols-6 bg-white gap-5 text-xs p-1"
                    >
                      <div className="col-span-2">{it.item?.name}</div>
                      <div className="grid grid-cols-4 gap-1 items-center">
                        <p className="col-span-3 text-right">{units}</p>
                        <div className="">{it.item?.unit.symbol}</div>
                      </div>
                      <div className="text-right">{currencyFormat(price)}</div>
                      <div className="text-right">{currencyFormat(amount)}</div>
                      <div className="grid grid-cols-4 gap-1 items-center">
                        <p className="col-span-3 text-right">
                          {it.closing_qty}
                        </p>
                        <div className="">{it.item?.unit.symbol}</div>
                      </div>
                      {/* <div className="grid grid-cols-4 gap-1 items-center">
                      <p className="col-span-3 text-right">{req_units}</p>
                      <div className="">{it.item?.unit.symbol}</div>
                    </div> */}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="my-5  ">
            <h3 className="text-sm font-bold">Components to procure</h3>
            <div
              className="bg-white rounded flex flex-col gap-1 border  overflow-auto"
              style={{ height: "75vh" }}
            >
              <div className="grid grid-cols-3 gap-5 p-1 bg-myPrimaryColor text-white text-sm font-semibold rounded-t">
                <div className="col-span-2">Particular</div>
                <div className="text-center"> qty</div>
              </div>
              {data.item?.bom.items.map((it, i) => {
                const units = it.unit_no * data.unit_no;
                let req_units = units - (it.closing_qty || 0);
                if (req_units < 0) req_units = 0;

                return req_units === 0 ? (
                  <></>
                ) : (
                  <div
                    key={i}
                    className="grid grid-cols-3 bg-white gap-5 text-xs p-2"
                  >
                    <div className="col-span-2">{it.item?.name}</div>

                    <div className="grid grid-cols-4 gap-1 items-center">
                      <p className="col-span-3 text-right">{req_units}</p>
                      <div className="">{it.item?.unit.symbol}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManufacturingProjection;
