import { FunctionComponent } from "react";
import Cms from "./components/Home/Cms";
import Header from "./components/Home/Header";
import ProtocolsCategory from "./components/Home/Protocols";
import Recommend from "./components/Home/Recommend";
import Sales from "./components/Home/Sales";
import VideosCategory from "./components/Home/Videos";
import AcademyNavbar from "./components/Navbar";

interface KazamAcademyProps {}

const KazamAcademy: FunctionComponent<KazamAcademyProps> = () => {
  return (
    <div className="bg-hrmBgLight">
      <AcademyNavbar />
      <Header />
      <Recommend />
      <VideosCategory />
      <ProtocolsCategory />
      <Sales />
      <Cms />
    </div>
  );
};

export default KazamAcademy;
