import { mdiChevronRight } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useState } from "react";
import { Link } from "react-router-dom";
import { urls } from "../../utils/urls";

interface ProcurementLandingPageProps {}

const ProcurementLandingPage: FunctionComponent<
  ProcurementLandingPageProps
> = () => {
  // const { user, isAuthorised } = useAuth();
  // const hasPermission = isAuthorised({ module, action, operator });

  return (
    <>
      <div className="relative w-full h-full  ">
        <svg
          className="wave_svg "
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="100%"
          height="100%"
          viewBox="0 0 1600 900"
          preserveAspectRatio="xMidYMax slice"
        >
          <defs>
            <linearGradient id="bg">
              <stop
                offset="0%"
                style={{ stopColor: "rgba(130, 158, 249, 0.06)" }}
              ></stop>
              <stop
                offset="50%"
                style={{ stopColor: "rgba(76, 190, 255, 0.6)" }}
              ></stop>
              <stop
                offset="100%"
                style={{ stopColor: "rgba(115, 209, 72, 0.2)" }}
              ></stop>
            </linearGradient>
            <path
              id="wave"
              fill="url(#bg)"
              d="M-363.852,502.589c0,0,236.988-41.997,505.475,0
	s371.981,38.998,575.971,0s293.985-39.278,505.474,5.859s493.475,48.368,716.963-4.995v560.106H-363.852V502.589z"
            />
          </defs>
          <g>
            <use xlinkHref="#wave" opacity=".3">
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="translate"
                dur="10s"
                calcMode="spline"
                values="270 230; -334 180; 270 230"
                keyTimes="0; .5; 1"
                keySplines="0.42, 0, 0.58, 1.0;0.42, 0, 0.58, 1.0"
                repeatCount="indefinite"
              />
            </use>
            <use xlinkHref="#wave" opacity=".6">
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="translate"
                dur="8s"
                calcMode="spline"
                values="-270 230;243 220;-270 230"
                keyTimes="0; .6; 1"
                keySplines="0.42, 0, 0.58, 1.0;0.42, 0, 0.58, 1.0"
                repeatCount="indefinite"
              />
            </use>
            <use xlinkHref="#wave" opacity=".9">
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="translate"
                dur="6s"
                calcMode="spline"
                values="0 230;-140 200;0 230"
                keyTimes="0; .4; 1"
                keySplines="0.42, 0, 0.58, 1.0;0.42, 0, 0.58, 1.0"
                repeatCount="indefinite"
              />
            </use>
          </g>
        </svg>
        <div className=" grid grid-cols-1 gap-5 md:grid-cols-2  absolute left-10 top-5 right-10  md:left-28 md:right-28  md:top-20">
          <div className="flex flex-col gap-2 md:gap-2 text-white justify-center">
            <h1 className="text-2xl md:text-6xl font-bold">Kazam</h1>
            <h1 className="text-xl md:text-4xl">
              Enterprise resource planning
            </h1>
            <h3 className="text-md md:text-xl">
              Manage all your finance and inventory.
            </h3>
          </div>
          <div className="flex justify-center md:justify-end">
            <div className="rounded-lg shadow bg-white  w-72 ">
              <h2 className=" font-semibold text-center my-5 mx-auto">
                Quick access
              </h2>
              <hr />
              <div className="flex flex-col  items-center divide-y text-sm mb-5">
                <Link
                  to={urls.purchase_order}
                  className="  w-full pl-8 pr-5 py-3 flex justify-between hover:bg-gray-200 cursor-pointer"
                >
                  <p>Purchase Orders</p>
                  <Icon path={mdiChevronRight} className="w-5 h-5"></Icon>
                </Link>
                <Link
                  to={urls.purchase_voucher}
                  className="  w-full pl-8 pr-5 py-3 flex justify-between hover:bg-gray-200 cursor-pointer"
                >
                  <p>Purchase voucher</p>
                  <Icon path={mdiChevronRight} className="w-5 h-5"></Icon>
                </Link>
                {/* <Link
                  to={urls.sales_voucher}
                  className="  w-full pl-8 pr-5 py-3 flex justify-between hover:bg-gray-200 cursor-pointer"
                >
                  <p>Sales voucher</p>
                  <Icon path={mdiChevronRight} className="w-5 h-5"></Icon>
                </Link>
                <Link
                  to={urls.payment_voucher}
                  className="  w-full pl-8 pr-5 py-3 flex justify-between hover:bg-gray-200 cursor-pointer"
                >
                  <p>Payment voucher</p>
                  <Icon path={mdiChevronRight} className="w-5 h-5"></Icon>
                </Link>
                <Link
                  to={urls.receipt_voucher}
                  className="  w-full pl-8 pr-5 py-3 flex justify-between hover:bg-gray-200 cursor-pointer"
                >
                  <p>Receipt voucher</p>
                  <Icon path={mdiChevronRight} className="w-5 h-5"></Icon>
                </Link> */}
                <Link
                  to={urls.stock_transfer_voucher}
                  className="  w-full pl-8 pr-5 py-3 flex justify-between hover:bg-gray-200 cursor-pointer"
                >
                  <p>Inventory journal</p>
                  <Icon path={mdiChevronRight} className="w-5 h-5"></Icon>
                </Link>
                <Link
                  to={urls.stock_manufacture_voucher}
                  className="  w-full pl-8 pr-5 py-3 flex justify-between hover:bg-gray-200 cursor-pointer"
                >
                  <p>Manufacturing journal</p>
                  <Icon path={mdiChevronRight} className="w-5 h-5"></Icon>
                </Link>
                <Link
                  className="  w-full pl-8 pr-5 py-3 flex justify-between hover:bg-gray-200 cursor-pointer"
                  to={urls.account_ledger_summery}
                >
                  <p>Ledger summary</p>
                  <Icon path={mdiChevronRight} className="w-5 h-5"></Icon>
                </Link>
                <Link
                  to={urls.stock_items}
                  className="  w-full pl-8 pr-5 py-3 flex justify-between hover:bg-gray-200 cursor-pointer rounded-b-lg"
                >
                  <p>Inventory summary</p>
                  <Icon path={mdiChevronRight} className="w-5 h-5"></Icon>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProcurementLandingPage;
