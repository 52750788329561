import { mdiChevronDoubleLeft, mdiChevronDoubleRight } from "@mdi/js";
import Icon from "@mdi/react";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { ModalAlignment, ModalProps } from "./modal_context";

interface DrawerModalProps {
  data: ModalProps;
  onClose: () => void;
}

const DrawerWidget: FunctionComponent<DrawerModalProps> = (props) => {
  //   useEffect(() => {
  //     const cb = (ev: KeyboardEvent) => {
  //       if (ev.key === "Escape") {
  //         props.onClose();
  //       }
  //     };
  //     document.addEventListener("keyup", cb);
  //     return () => document.removeEventListener("keyup", cb);
  //   }, []);
  return (
    <div className=" relative  ">
      <div
        onClick={props.onClose}
        className="fixed top-0 left-0 right-0 w-screen h-screen    z-50"
      ></div>

      <div
        style={{ height: "100vh" }}
        className={`fixed z-50  top-0 bottom-0 ${
          props.data.alignment === ModalAlignment.left
            ? "left-0 rounded-tr-3xl "
            : " "
        }  ${
          props.data.alignment === ModalAlignment.right
            ? "right-0   rounded-tl-3xl"
            : " "
        }   bg-white shadow rounded w-full md:w-1/3   transform duration-500 ease-in-out  ${
          props.data.alignment === ModalAlignment.right
            ? "slide_drawer_rtl"
            : "slide_drawer_ltr"
        }`}
      >
        <div className="    " style={{ height: "5%" }}>
          <div className="flex m-3">
            <div
              title="close"
              className="cursor-pointer hover:text-red-400"
              onClick={props.onClose}
            >
              <Icon
                path={
                  props.data.alignment === ModalAlignment.left
                    ? mdiChevronDoubleLeft
                    : mdiChevronDoubleRight
                }
                size={1}
              ></Icon>
            </div>
            <h5 className="mx-3">{props.data.title}</h5>
          </div>
          <hr className="" />
        </div>
        <div className="overflow-y-auto  " style={{ height: "95%" }}>
          {props.children || props.data.container}
        </div>
      </div>
    </div>
  );
};

export default DrawerWidget;
