import Icon from "@mdi/react";
import {
  mdiClose,
  mdiCollage,
  mdiDelete,
  mdiDownload,
  mdiFileExcel,
  mdiImageBrokenVariant,
  mdiMicrosoftExcel,
  mdiRefresh,
  mdiUpload,
  mdiVideo,
} from "@mdi/js";
import moment from "moment";
import * as htmlToImage from "html-to-image";
import { FunctionComponent, useState, useEffect } from "react";
import {
  useToast,
  ToastType,
} from "../../../../context_providers/toast/toast_ctx";
import { AttachmentModel } from "../../../../service/models/attachment_modal";
import {
  GetOrderAttachmentApi,
  DeleteOrderAttachmentApi,
} from "../../../../service/repos/order_repo";

import {
  FileType,
  attachement_belong,
  MODULE,
  ACTION,
} from "../../../../utils/enums";
import PdfTile from "../../../components/common/pdf_tile";
import LoadingWidget from "../../../components/loading_spinner";
import ModalDialog from "../../../new_components/common/modal";
import * as FileUploadWidget1 from "../../../new_components/common/file_upload";
import { useModal } from "../../../../context_providers/modal/modal_context";
import { useAuth } from "../../../../context_providers/auth/authProvider";

export const AttachmentCategory = [
  "PO",
  "E-Bill",
  "Aadhaar Card",
  "PAN Card",
  "Installation Photos",
  "Email",
  "Subsidy Registration Acknowledgement Copy",
  "Facilitation fees Invoice",
  "Customer Review video",
  "SLD",
  "BOQ",
  "Work Completion report",
  "Other",
];
interface OrderAttachmentWidgetProps {
  order_id: string;
}

const OrderAttachmentWidget: FunctionComponent<OrderAttachmentWidgetProps> = ({
  order_id,
}) => {
  const { loading, setLoading } = useModal();
  const { showToast } = useToast();
  const { user, isAuthorised } = useAuth();
  const [category, setCategory] = useState("");
  // const [loading, setLoading] = useState(false);
  const [showCollage, setShowCollage] = useState(false);
  const [selectedFile, setSelectedFile] = useState<AttachmentModel>();
  const [selectedAttachments, setSelectedAttachments] = useState<File[]>([]);
  const [showUploadAttachment, setShowUploadAttachement] = useState(false);
  const [attachments, setAttachments] = useState<AttachmentModel[]>([]);
  const getAttachments = async () => {
    setLoading(true);
    const res = await GetOrderAttachmentApi(order_id);

    if (res.success) {
      setAttachments(res.data);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  const deleteAttachment = async (id: any) => {
    const res = await DeleteOrderAttachmentApi(id);
    if (res.success) {
      getAttachments();
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
  };
  // const uploadAttachments = async (form: FormData) => {
  //   const res = await UploadOrderAttachmentApi(form);
  //   if (res.success) {
  //     setShowUploadAttachement(false);
  //     getAttachments();
  //   } else {
  //     showToast({ type: ToastType.error, text: res.error ?? "" })
  //   }
  // };
  const [disabled, setDisabled] = useState(true);
  useEffect(() => {
    getAttachments();

    setDisabled(
      !isAuthorised({
        module: MODULE.order,
        action: [ACTION.UPDATE],
      })
    );
  }, []);

  const [downloading, setDownloading] = useState(false);
  return (
    <>
      {selectedFile && (
        <ModalDialog
          title={selectedFile.category}
          show={selectedFile !== undefined}
          onClose={() => setSelectedFile(undefined)}
        >
          <div className="max-h-96">
            {selectedFile.type === FileType.VIDEO ? (
              <video
                className={`rounded-lg w-96 h-auto `}
                id="cmsVideo"
                autoPlay
                muted
                playsInline
                controls
              >
                <source src={selectedFile.url} type="video/mp4" />
              </video>
            ) : selectedFile.type === FileType.IMAGE ? (
              <img src={selectedFile.url} className="w-96 h-auto"></img>
            ) : selectedFile.type === FileType.PDF ? (
              <>
                <embed
                  src={selectedFile.url}
                  type="application/pdf"
                  className="w-96 h-auto"
                ></embed>{" "}
              </>
            ) : selectedFile.type === FileType.CSV ? (
              <>CSV file can be downloaded</>
            ) : (
              <>Unkown format</>
            )}
          </div>
        </ModalDialog>
      )}
      {showUploadAttachment && (
        <ModalDialog
          title="Upload attachment"
          show={showUploadAttachment}
          onClose={() => setShowUploadAttachement(false)}
        >
          <div className="  " style={{ maxHeight: "80vh" }}>
            <div className="my-2">
              <div className=" mb-1 font-semibold text-sm">
                Attachment category
              </div>
              <select
                value={category}
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
                name=""
                id=""
                className=" w-full text-xs  px-3 py-1 cursor-pointer bg-gray-100 rounded focus:outline-none"
              >
                <option hidden>Select</option>
                {AttachmentCategory.map((at, i) => (
                  <option value={at}>{at}</option>
                ))}
              </select>
            </div>
            {category && (
              <FileUploadWidget1.default
                onComplete={() => {
                  getAttachments();
                  setShowUploadAttachement(false);
                }}
                belong={attachement_belong.order}
                ref_id={order_id}
                category={category}
              />
            )}
          </div>
        </ModalDialog>
      )}
      {selectedAttachments.length > 0 && (
        <ModalDialog
          title="Installation"
          show={selectedAttachments.length > 0}
          onClose={() => setSelectedAttachments([])}
        >
          <div className=" ">
            <div
              className="overflow-auto "
              id="collage"
              style={{ maxHeight: "85vh", width: "65vw" }}
            >
              <div
                className={`grid ${
                  selectedAttachments.length > 4 ? "grid-cols-3" : "grid-cols-2"
                } max-w-screen-lg items-center justify-center bg-white`}
              >
                {selectedAttachments.map((at, i) => (
                  <div className=" relative h-full w-full border-white border-4">
                    <img
                      className="w-full max-h-72 object-cover rounded-md"
                      src={URL.createObjectURL(at)}
                      alt=""
                    />
                    {!downloading && (
                      <div
                        onClick={() =>
                          setSelectedAttachments((ol) =>
                            ol.filter((fm, j) => i !== j)
                          )
                        }
                        className="absolute right-0 top-0 bg-white p-2 rounded-full"
                      >
                        <Icon path={mdiClose} size="20"></Icon>
                      </div>
                    )}
                  </div>
                ))}
              </div>{" "}
            </div>
            <div className="flex my-2 justify-end">
              <button
                className="border px-2 py-1"
                onClick={() => {
                  setDownloading(true);
                  setTimeout(() => {
                    const html = document.getElementById("collage");
                    console.log(html);
                    htmlToImage.toPng(html!).then(function (dataUrl) {
                      console.log({ dataUrl });
                      const downloadLink = document.createElement("a");
                      downloadLink.href = dataUrl;
                      downloadLink.download = order_id + "-installation.png";
                      downloadLink.click();
                      setDownloading(false);
                    });
                  }, 100);
                }}
              >
                Download
              </button>
            </div>
          </div>
        </ModalDialog>
      )}

      <div
        className={`grid grid-cols-1 lg:grid-cols-12 text-sm gap-2 my-10 right-0
             transform duration-500 ease-in-out   slide_drawer_rtl `}
      >
        <div className="lg:col-start-2 lg:col-span-10  xl:col-start-3 xl:col-span-8">
          <div>
            <div className="">
              <div className="flex justify-end my-2 items-end text-sm gap-2">
                <button
                  className="p-1 border rounded flex items-center gap-2"
                  onClick={() => {
                    const tmp = attachments.filter(
                      (a) =>
                        a.category === "Installation Photos" &&
                        a.type === FileType.IMAGE
                    );
                    // setSelectedAttachments(
                    //   tmp
                    // )

                    for (let index = 0; index < tmp.length; index++) {
                      const t = tmp[index];
                      fetch(t.url)
                        .then((e) => {
                          return e.blob();
                        })
                        .catch((e) => {
                          console.log(e);
                        })
                        .then((blob) => {
                          let b: any = blob;
                          b.lastModifiedDate = new Date();
                          b.name = order_id + "-" + t.category + (index + 1);
                          setSelectedAttachments((ol) => [...ol, b]);
                        })
                        .catch((e) => console.log(e));
                    }
                  }}
                >
                  <Icon path={mdiCollage} size="20"></Icon>
                  Insallation collage
                </button>
                <button
                  className="p-1 border rounded flex items-center gap-2"
                  onClick={() => getAttachments()}
                >
                  <Icon path={mdiRefresh} size="20"></Icon>
                  Refresh
                </button>
                <button
                  className="p-1 border rounded flex items-center gap-2"
                  onClick={() => setShowUploadAttachement(true)}
                >
                  <Icon path={mdiUpload} size="20"></Icon>
                  Upload
                </button>
              </div>

              <div className="flex flex-col gap-2    md:hidden">
                {attachments.length > 0 ? (
                  attachments.map((file, i) => (
                    <div
                      className=" flex  gap-2 justify-center border rounded items-center  h-auto cursor-pointer bg-white"
                      key={i}
                    >
                      <div className="w-28">
                        <div
                          className="flex justify-center items-center   w-28 h-20  bg-gray-200"
                          onClick={() => {
                            setSelectedFile(file);
                          }}
                        >
                          {file.type === FileType.IMAGE ? (
                            <img
                              src={file.url}
                              alt=""
                              className="h-20 w-28  "
                            />
                          ) : file.type === FileType.PDF ? (
                            <PdfTile url={file.url} />
                          ) : file.type === FileType.VIDEO ? (
                            <Icon path={mdiVideo} size="30" />
                          ) : file.type === FileType.CSV ? (
                            <Icon path={mdiMicrosoftExcel} size="30" />
                          ) : (
                            <Icon path={mdiImageBrokenVariant} size="30" />
                          )}
                        </div>
                      </div>
                      <div className="text-xs w-28">
                        <p>{file.category}</p>
                        <p>{file.created_by_name}</p>
                        <p> {moment(file.created_at).format("DD MMM YYYY")}</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="my-10 flex flex-col gap-5 items-center justify-center text-2xl font-semibold text-gray-400">
                    <p> No Record Found !</p>
                  </div>
                )}
              </div>
              <div className="  hidden md:block bg-white rounded-lg">
                <table className=" w-full text-xs mb-5 border rounded-sm ">
                  <thead>
                    <tr className="border-b bg-white p-2">
                      {/* <th></th> */}
                      <th className="py-2">File</th>
                      <th>Category</th>
                      <th>Created Date</th>
                      <th>Created by</th>

                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {attachments.length > 0 ? (
                      attachments.map((file, i) => (
                        <tr key={i}>
                          {/* <td>
                      <div className="">
                        <input type="checkbox" name="" id="" />
                      </div>
                    </td> */}
                          <td align="center">
                            <div
                              className="flex justify-center items-center rounded-full h-14 w-14 bg-gray-200 cursor-pointer"
                              onClick={() => {
                                console.log(file);
                                if (file.type !== FileType.PDF)
                                  setSelectedFile(file);
                              }}
                            >
                              {file.type === FileType.IMAGE ? (
                                <img
                                  src={file.url}
                                  alt=""
                                  className="h-14 w-14 rounded-full"
                                />
                              ) : file.type === FileType.PDF ? (
                                <a target="_blank" href={file.url}>
                                  View
                                </a>
                              ) : file.type === FileType.VIDEO ? (
                                <Icon path={mdiVideo} size="30" />
                              ) : (
                                <Icon path={mdiImageBrokenVariant} size="30" />
                              )}
                            </div>
                          </td>
                          <td align="center">{file.category}</td>
                          <td align="center">
                            {moment(file.created_at).format("DD MMM YYYY")}
                          </td>
                          <td align="center">{file.created_by_name}</td>
                          <td>
                            <div className="flex gap-2">
                              <div
                                className="cursor-pointer"
                                onClick={() => {
                                  var link = document.createElement("a");
                                  link.setAttribute("href", file.url);
                                  link.setAttribute("target", "_blank");
                                  link.setAttribute(
                                    "download",
                                    order_id + "-" + file.url.split("/").pop()
                                  );
                                  document.body.appendChild(link);

                                  link.click();
                                  document.body.removeChild(link);
                                }}
                                // href={file.url}
                                // download={order_id + "-" + file.url.split("/").pop()}
                              >
                                <Icon path={mdiDownload} size="20" />
                              </div>
                              {!disabled && (
                                <div
                                  className="cursor-pointer"
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        "Are you sure to delete ? "
                                      )
                                    ) {
                                      deleteAttachment(file.id!);
                                    }
                                  }}
                                >
                                  <Icon path={mdiDelete} size="20" />
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={5}>
                          <div className="my-10 flex flex-col gap-5 items-center justify-center text-2xl font-semibold text-gray-400">
                            <p> No Record Found !</p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {/* <LoadingWidget loading={loading} /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderAttachmentWidget;
