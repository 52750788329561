import { FunctionComponent, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { Role } from "../../../../context_providers/auth/user_model";
import {
  ToastType,
  useToast,
} from "../../../../context_providers/toast/toast_ctx";
import {
  GetRolesApi,
  DeleteRoleApi,
} from "../../../../service/repos/roles_repo";

import { urls } from "../../../../utils/urls";
import LoadingWidget from "../../../components/loading_spinner";
import Pagination from "../../../new_components/common/pagination";

interface RoleTableWidgetProps {}

const RoleTableWidget: FunctionComponent<RoleTableWidgetProps> = (props) => {
  const { showToast } = useToast();

  const history = useHistory();
  const [roles, setRoles] = useState<Role[]>([]);

  const [rolePage, setRolePage] = useState(1);
  const [roleCount, setRoleCount] = useState(50);
  const [roleTotal, setRoleTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const getRoles = async () => {
    setLoading(true);
    const res = await GetRolesApi({ count: roleCount, page: rolePage });

    if (res.success) {
      setRoles(res.data.data);
      if (res.data.metadata) setRoleTotal(res.data.metadata.total!);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  useEffect(() => {
    getRoles();
  }, [rolePage]);
  const handleClick = (role: Role) => {
    history.push(urls.editRole, role);
  };

  const onDeleteRole = async (role: Role) => {
    const sure = window.confirm(`Are you sure to delete role : ${role.title}`);
    if (!sure) return;
    setLoading(true);
    const res = await DeleteRoleApi(role.id!);

    if (res.success) {
      setRoles((old) => old.filter((r, i) => r.id !== role.id));
      setRoleTotal(roleTotal - 1);
    }
    setLoading(false);
  };

  const heads = ["Role", "Action"];
  const gotoCreateRole = () => {
    history.push(urls.createRole);
  };

  return (
    <>
      <div className=" flex  flex-col justify-start h-screen mx-5">
        <div className="flex justify-between ">
          <h6 className="font-semibold text-lg  mb-2">Roles/Permissions</h6>
        </div>
        <div className=" overflow-auto  " style={{ height: "90%" }}>
          <table className="border divide-y divide-gray-200 relative w-full ">
            <thead className="bg-gray-200 sticky  top-0">
              <tr>
                {heads.map((h) => (
                  <th
                    align="left"
                    key={h}
                    className="px-3 py-2 text-xs font-medium   text-black uppercase tracking-wider"
                  >
                    {h}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200   text-xs">
              {roles.map((role) => {
                //
                return (
                  <tr
                    className="hover:bg-gray-200"
                    key={role.id!}
                    // onDoubleClick={() => props.onTxnClick(txn.id!)}
                  >
                    <td className="px-3 py-2 whitespace-nowrap text-xs font-medium text-gray-900">
                      {role.title}
                    </td>

                    <td align="center" className="flex">
                      <button
                        className="text-blue-300 rounded  hover:text-red-600 mx-1 p-1"
                        onClick={() => handleClick(role)}
                      >
                        Edit
                      </button>
                      <button
                        className="text-blue-300 rounded  hover:text-red-600 mx-1 p-1"
                        onClick={() => onDeleteRole(role)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="">
          <Pagination
            itemCount={roles.length}
            page={rolePage}
            total={roleTotal}
            count={roleCount}
            onChange={(p) => {
              setRolePage(p);
              // dispatch(GoToPageTxnThunk({ page: p, count }));
            }}
          />
        </div>
      </div>
      <LoadingWidget loading={loading} />
    </>
  );
};

export default RoleTableWidget;
