import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import TextField from "../../components/form_components/text_field";
import DropDown from "../../components/form_components/dropdown";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { ACTION, SubDepartments } from "../../../utils/enums";
import { useEffect, useState } from "react";

import PermissionWidget from "./permission_widget";
import { GetRolesApi } from "../../../service/repos/roles_repo";
import LoadingWidget from "../../components/loading_spinner";
import {
  CreateEmpRepo,
  GetEmpDetails,
  ResetPassword,
  UpdateEmpDetails,
} from "../../../service/repos/users_repo";
import { ApiResponse } from "../../../service/models/response";
import { useHistory } from "react-router-dom";
import { formToObject } from "../../../utils/formDataToJson";
import {
  useToast,
  ToastType,
} from "../../../context_providers/toast/toast_ctx";
import { Role, UserModel } from "../../../context_providers/auth/user_model";
import Icon from "@mdi/react";
import { mdiDelete } from "@mdi/js";

const schema = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  // designation: yup.string().required(),
  department: yup.string().required(),
  sub_dep: yup.string(),
  mobile: yup.string().required(),
  email: yup.string().email().required(),
  active: yup.boolean(),
});

export interface UserAccessDetailPageProps {
  location: any;
}
const defaultValue: UserModel = {
  first_name: "",
  last_name: "",
  mobile: "",

  email: "",
  email_verified: false,
  mobile_verified: false,
  active: false,
  created_by: 0,
  updated_by: 0,
  emp_profile: {
    roles: [],
    active: false,
    department: "",
    sub_dep: "",
    zone: "",
    isHod: false,
    isReportingManager: false,
    weekoff: {
      off: [],
      alt_off: [],
      alt_off_even_week: false,
    },
  },
};
const UserAccessDetailPage: React.FC<UserAccessDetailPageProps> = (props) => {
  const { showToast } = useToast();
  const state: { user: UserModel; roles: Role[] } = props.location.state;

  const [loading, setLoading] = useState(false);
  const [preState, setPreState] = useState<UserModel | undefined>(state?.user);
  const [employee, setEmployee] = useState<UserModel>(
    state?.user || defaultValue
  );

  const [roles, setRoles] = useState<Role[]>(state ? [...state?.roles] : []);
  const [selectedRoleId, setSelectedRoleId] = useState<string>();

  const [departments, setDepartment] = useState<string[]>(
    Object.keys(SubDepartments)
  );

  const [subDepartments, setSubDepartment] = useState<string[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<Role[]>([]);
  const [unSelectedRoles, setUnSelectedRoles] = useState<Role[]>([]);

  // const [selectedDes, setSelectedDes] = useState<string>("");
  useEffect(() => {
    if (employee?.emp_profile?.department) {
      setSubDepartment(SubDepartments[employee?.emp_profile?.department] ?? []);
    } else setSubDepartment([]);
  }, [employee]);
  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  // } = useForm({
  //   resolver: yupResolver(schema),
  //   defaultValues: employee,
  // });
  const history = useHistory();
  useEffect(() => {
    if (roles.length === 0) getRoles();
  }, []);
  const getRoles = async () => {
    try {
      setLoading(true);
      // if (state) {
      //   const res: ApiResponse = await GetEmpDetails(state.id!);

      //   if (res.success) {
      //     // if (perms.length > 0) setPermissions(perms);
      //     if (res.data) setEmployee(res.data);
      //     // setActive(emp.active ?? false);
      //   }
      // }
      const res = await GetRolesApi({ all: true });
      if (res.success) {
        setRoles(res.data.data);
      } else {
        showToast({ type: ToastType.error, text: res.error ?? "" });
      }
      setLoading(false);
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error ?? "" });
    }
  };

  const removeRole = (role: Role) => {
    setEmployee((o) => ({
      ...o!,
      emp_profile: {
        ...o!.emp_profile!,
        roles: [...o!.emp_profile!.roles!.filter((r) => r !== role.id)],
      },
    }));
  };
  const handleAddRole = () => {
    if (selectedRoleId) {
      setEmployee((o) => ({
        ...o!,
        emp_profile: {
          ...o!.emp_profile!,
          roles: [...o!.emp_profile!.roles!, selectedRoleId!],
        },
      }));
    }
  };

  // const handleDepartmentDropChange = (value: any) => {
  //   //

  //   // setSelectedDep(value);
  //   const t = Departments.find((d) => d.department === value);
  //   if (t) setDesignation(t?.designation);
  // };
  // const handleDesignationDropChange = (event: any) => {
  //
  // setSelectedDes(event.target.value);
  // };
  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // const data: any = formToObject(new FormData(e.currentTarget));
    // data.active = active;

    setLoading(true);
    const res: ApiResponse = state
      ? await UpdateEmpDetails(employee)
      : await CreateEmpRepo(employee);

    if (res.success) {
      showToast({
        type: ToastType.success,
        text: state ? "Successfully updated" : "Successfully created",
      });
    } else showToast({ type: ToastType.error, text: res.error ?? "" });
    setLoading(false);
  };
  const onResetPassword = async () => {
    const res = await ResetPassword(employee.uid!);

    if (res.success)
      showToast({ type: ToastType.success, text: res.message ?? "" });
    else showToast({ type: ToastType.error, text: res.error ?? "" });
  };
  const sortRoles = () => {
    if (employee && roles.length > 0) {
      const selected: Role[] = [];
      const unselected: Role[] = [];
      for (let i = 0; i < roles.length; i++) {
        const role = roles[i];
        if (employee.emp_profile?.roles?.includes(role.id)) {
          selected.push(role);
        } else {
          unselected.push(role);
        }
      }
      setSelectedRoles(selected);
      setUnSelectedRoles(unselected);
    }
  };

  useEffect(() => {
    sortRoles();
  }, [employee.emp_profile?.roles]);
  useEffect(() => sortRoles(), [roles]);
  //grid grid-cols-1 md:grid-cols-12 md:col-start-4 md:col-span-5
  return (
    <>
      <form onSubmit={submit} className=" text-sm bg-myBgcolor ">
        <div className="p-2 md:p-5   ">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
            <div>
              <h3>User Detail</h3>
              <div className="p-2 md:p-5 md:h-96   bg-white rounded-md">
                <div className="flex">
                  <label>Status</label>

                  <div className=" form-check form-switch mx-5">
                    <input
                      checked={employee.emp_profile?.active}
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      onChange={(e) => {
                        setEmployee((o) => ({
                          ...o,
                          emp_profile: {
                            ...o.emp_profile!,
                            active: e.target.checked,
                          },
                        }));
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexSwitchCheckChecked"
                    >
                      Active
                    </label>
                  </div>
                </div>
                {!employee.emp_profile?.active && (
                  <p className="text-xs text-red-500">
                    User will not be able to login
                  </p>
                )}
                {employee && (
                  <div className="flex justify-between my-2">
                    <h3>EMP ID : {employee.emp_profile?.emp_id}</h3>
                    <h3>UID : {employee.uid}</h3>
                  </div>
                )}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                  <div className="flex flex-col">
                    <label htmlFor="first_name">First Name</label>
                    <input
                      required
                      className="border rounded p-2"
                      type="text"
                      name="first_name"
                      id="first_name"
                      value={employee.first_name}
                      placeholder="First name"
                      onChange={(e) =>
                        setEmployee((o) => ({
                          ...o,
                          first_name: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className="flex flex-col">
                    <label htmlFor="last_name">Last Name</label>
                    <input
                      required
                      className="border rounded p-2"
                      type="text"
                      name="last_name"
                      id="last_name"
                      value={employee.last_name}
                      placeholder="Last name"
                      onChange={(e) =>
                        setEmployee((o) => ({
                          ...o,
                          last_name: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className="flex flex-col">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      required
                      className="border rounded p-2"
                      type="text"
                      name="mobile"
                      id="mobile"
                      pattern="[1-9]{1}[0-9]{9}"
                      value={employee.mobile}
                      placeholder="10 digit mobile number"
                      onChange={(e) => {
                        if (e.target.value.length < 11)
                          setEmployee((o) => ({
                            ...o,
                            mobile: e.target.value,
                          }));
                      }}
                    />
                  </div>
                  <div className="flex flex-col">
                    <label htmlFor="email">Email</label>
                    <input
                      required
                      className="border rounded p-2"
                      type="email"
                      name="email"
                      id="email"
                      value={employee.email}
                      placeholder="Official email"
                      onChange={(e) =>
                        setEmployee((o) => ({
                          ...o,
                          email: e.target.value,
                        }))
                      }
                    />
                  </div>

                  <div className="flex flex-col">
                    <label htmlFor="deapartment">Department</label>
                    <select
                      required
                      className="border rounded p-2"
                      name="department"
                      id="deapartment"
                      value={employee.emp_profile?.department}
                      onChange={(e) =>
                        setEmployee((o) => ({
                          ...o!,
                          emp_profile: {
                            ...o!.emp_profile!,
                            department: e.target.value,
                          },
                        }))
                      }
                    >
                      <option value="" hidden>
                        Select ...
                      </option>
                      {departments.sort().map((d) => (
                        <option value={d}>{d}</option>
                      ))}
                    </select>
                  </div>
                  <div className="flex flex-col">
                    <label htmlFor="sub_department">Sub Department</label>
                    <select
                      className="border rounded p-2"
                      name="sub_dep"
                      id="sub_department"
                      value={employee.emp_profile?.sub_dep ?? ""}
                      onChange={(e) =>
                        setEmployee((o) => ({
                          ...o!,
                          emp_profile: {
                            ...o!.emp_profile!,
                            sub_dep: e.target.value,
                          },
                        }))
                      }
                    >
                      <option value="" hidden>
                        Select ...
                      </option>
                      {subDepartments.sort().map((d) => (
                        <option value={d}>{d}</option>
                      ))}
                    </select>
                  </div>

                  {/* <div className="col-sm-12 col-md-6 col-lg-6">
                  <DropDown
                    options={designations.map((d) => {
                      return { name: d, value: d };
                    })}
                    error={
                      errors.designation
                        ? "Please select designation"
                        : undefined
                    }
                    register={register("designation")}
                    id="role"
                    label="Designation"
                    placeholder="Select"
                    alignment="row"
                  ></DropDown>
                </div> */}
                </div>
              </div>
            </div>

            <div className="">
              <h5 className="">Roles</h5>
              <div className="  p-2 md:p-5 bg-white rounded-md">
                <div className=" grid grid-cols-1 md:grid-cols-4  gap-2 ">
                  <select
                    value={selectedRoleId}
                    onChange={(e) => {
                      setSelectedRoleId(e.target.value);
                    }}
                    className="px-3 py-1 border rounded-sm col-span-3"
                  >
                    <option selected hidden>
                      Select role
                    </option>
                    {unSelectedRoles.map((ro, i) => (
                      <option key={i} value={ro.id}>
                        {ro.title}
                      </option>
                    ))}
                  </select>

                  <div className=" ">
                    <button
                      type="button"
                      onClick={handleAddRole}
                      className="border rounded-md hover:bg-black hover:text-white px-2 py-1"
                    >
                      Add{" "}
                    </button>
                  </div>
                </div>
              </div>
              <h3 className="mt-8">Permissions</h3>
              <div className="flex flex-col  p-5 divide-y text-sm  bg-white rounded md:max-h-64 overflow-auto">
                {selectedRoles.map((role, i) => (
                  <div className="flex  items-center justify-between py-1">
                    <div className="flex gap-2">
                      <p>{i + 1}</p>
                      <div className="">
                        <h4>
                          {role.title}
                          {!preState?.emp_profile?.roles?.includes(role.id) && (
                            <span className="text-xs text-green-400 bg-green-100 px-1 rounded ml-5">
                              New
                            </span>
                          )}
                        </h4>
                        <p className="italic text-xs">{role.desc}</p>
                      </div>
                    </div>
                    <button
                      type="button"
                      onClick={() =>
                        setEmployee((o) => ({
                          ...o!,
                          emp_profile: {
                            ...o!.emp_profile!,
                            roles: o!.emp_profile!.roles?.filter(
                              (f) => f !== role.id
                            ),
                          },
                        }))
                      }
                      className="p-1 bg-red-100 rounded-md hover:scale-110 duration-200 transform"
                    >
                      <Icon
                        path={mdiDelete}
                        className="h-4 w-4  text-red-400"
                      ></Icon>
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="flex justify-center my-5 gap-2">
            <button
              type="button"
              onClick={() => history.goBack()}
              className="border rounded-md hover:bg-black hover:text-white px-2 py-1"
            >
              Cancel{" "}
            </button>
            <button
              type="submit"
              className="border rounded-md hover:bg-black hover:text-white px-2 py-1"
            >
              {state ? "Update" : "Create"}
            </button>
          </div>
        </div>
      </form>
      <LoadingWidget loading={loading} />
    </>
  );
};

export default UserAccessDetailPage;
