import { FunctionComponent } from "react";
import Sidebar from "../../components/Sidebar";
import ProtocolLayout from "../components/ProtocolLayout";

interface SaasProps {}

const Saas: FunctionComponent<SaasProps> = () => {
  return (
    <ProtocolLayout>
      <div className="flex flex-col items-center">
        <div className="mt-20 w-11/12 lg:w-4/5">
          <div className="">
            <div className="text-xl lg:text-3xl font-semibold">
              Saas Sales Protocol
            </div>
            <ul className="list-disc pl-6 mt-6 ">
              <li>
                Basic draft mail listing the features and ppt and plans is sent
                to the client (no prices)
              </li>
              <br />
              <li>
                Book a demo and give a CMS demo. If a customer asks for a demo
                account in CMS, sign the NDA. Ask the customer to purchase one
                piece or onboard one of the devices onto the CMS for better
                understanding. (dont send devices to OEMs)
              </li>
              <br />
              <li>
                After the demo is done, send the same mail with prices written
                in it. (Demo should be give by the Relationship Manager
                himself/herself. Tech team intervention is not needed here)
              </li>
              <br />
              <li>
                If customer wants Quotation or Performa Invoice, you can send
                the same (only after Vaibhav&apos;s approval)
              </li>
              <br />
              <li>
                If the customer wants draft SLA for reading purpose, that also
                can be sent (only after approval from Vaibhav)
              </li>
              <br />
              <li>
                Once the customer has made the payment for SaaS, you get the
                final SLA signed and mail about their order details to account
                team. Order entry will be created in ops portal with prices
                details.
              </li>
              <br />
              <li> Sign SLA with customer post payment. </li>
              <br />
              <li>
                Inform tech team and initiate product delivery. (Assist customer
                in payment gateway account creation etc)
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ProtocolLayout>
  );
};
export default Saas;
