import {
  mdiRefresh,
  mdiPlus,
  mdiImport,
  mdiArrowBottomLeft,
  mdiClose,
} from "@mdi/js";
import Icon from "@mdi/react";
import axios, { CancelTokenSource } from "axios";
import { FunctionComponent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import LoadingWidget from "../../../context_providers/modal/loader";
import {
  useModal,
  ModalType,
} from "../../../context_providers/modal/modal_context";
import ModalWidget from "../../../context_providers/modal/modal_widget";
import {
  useToast,
  ToastType,
} from "../../../context_providers/toast/toast_ctx";
import { FieldType } from "../../../procurement/components/create_view/form_field";
import TableView from "../../../procurement/components/table_comp";
import {
  fetchDelete,
  fetchGet,
  fetchPost,
  toQueryString,
} from "../../../service/api_client";
import SearchFieldWidget from "../../../ui/new_components/search_field";
import { urls } from "../../../utils/urls";
import ItemGroupCreateScreen from "../item_group/item_group_create_screen";
import { ItemGroupModel } from "../models/item_group_model";
import { ItemModel } from "../models/item_model";
import ImportWizard from "./components/import_comp";
import ExportWizard from "./components/import_comp";
import ItemCreateScreen from "./item_create/item_create_screen";
import ItemGroupSearchField from "../item_group/item_group_search_field";

interface ItemListScreenProps {}

const ItemListScreen: FunctionComponent<ItemListScreenProps> = () => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/items`;
  const { showModal, onClose } = useModal();

  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(50);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<ItemModel[]>([]);
  const [group, setgroup] = useState<ItemGroupModel>();

  const [search, setSearch] = useState("");
  const fetchData = async (prop: {
    search?: string;
    page: number;
    count: number;
    all?: boolean;
    group_id?: string;
  }) => {
    setLoading(true);
    try {
      const res = await fetchGet(url + toQueryString(prop));
      if (res.success) {
        setData(res.data.data);
        if (res.data.metadata) {
          setTotal(res.data.metadata.total);
        }
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
      //   return { success: false, error: error.message };
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData({ page, count, search, group_id: group?.id });
  }, [page, group]);
  useEffect(() => {
    if (search) fetchData({ page, count, search });
  }, [search]);
  // return <ItemCreateScreen onClose={(d) => {}} />;
  const navaigation = useHistory();
  const onSelect = (d: ItemModel) => {
    navaigation.push(urls.procurement_masters_item + "/update/" + d.id);
  };
  const onDelete = async (d: ItemModel) => {
    const propmt = window.prompt("Type 'delete' to confirm.");
    if (!propmt || propmt?.trim() !== "delete") {
      return;
    }
    setLoading(true);
    const res = await fetchDelete(url + "/" + d.id);
    if (res.success) {
      await fetchData({ page, count, search });
      showToast({ type: ToastType.success, text: res.message });
    } else {
      showToast({ type: ToastType.error, text: res.error });
    }
    setLoading(false);
  };
  let cancelTokenSource: CancelTokenSource;
  const onImport = async (d: {
    data: any[];
    setCompleted: (loading: boolean) => void;
    setUpLoading: (loading: boolean) => void;
    setProgress: (loading: number) => void;
    // setCancelled: (d: boolean) => void;
  }) => {
    cancelTokenSource = axios.CancelToken.source();
    d.setUpLoading(true);
    const config = {
      cancelToken: cancelTokenSource.token,
      onUploadProgress: (e: any) => {
        const percentCompleted = Math.round((e.loaded * 100) / e.total);
        d.setProgress(percentCompleted);
        console.log("uploaded " + percentCompleted + " %");
      },
    };
    const form = new FormData();
    form.append("data", JSON.stringify(d.data));
    const res = await fetchPost(url + "/import", form, {}, config);
    if (res.success) {
      await fetchData({ page, count, search });

      showToast({ type: ToastType.success, text: res.message });
    } else {
      showToast({ type: ToastType.error, text: res.error });
    }
    d.setUpLoading(false);
  };

  const [showImport, setShowImport] = useState(false);
  return (
    <>
      {showImport && (
        <ModalWidget
          data={{
            id: 1,
            title: "Import",
            type: ModalType.modal,

            onClose: () => {
              setShowImport(false);
            },
          }}
          onClose={function (): void {
            setShowImport(false);
          }}
        >
          <ImportWizard
            onClose={() => {
              setShowImport(false);
            }}
            onCancel={function () {
              if (cancelTokenSource) {
                console.log("Cancelled by user");
                cancelTokenSource.cancel("cancelled by user");
              }
            }}
            onSubmit={onImport}
            onError={function (error: string): void {
              showToast({ type: ToastType.error, text: error });
            }}
          />
        </ModalWidget>
      )}

      <div
        id="page_container"
        className="flex flex-col justify-between h-full p-1"
      >
        <section id="top_section" className=" ">
          <div className="flex justify-between items-end my-1">
            <h1 className="font-bold text-2xl text-gray-500 flex items-center gap-3">
              Items
              <button
                title="Refersh"
                onClick={() =>
                  fetchData({ page, count, search, group_id: group?.id })
                }
              >
                <Icon path={mdiRefresh} className="h-6 w-6"></Icon>
              </button>
            </h1>
            <div className="flex justify-end   text-sm items-center gap-1 ">
              {" "}
              <div className="bg-white rounded w-52 flex gap-1">
                <ItemGroupSearchField
                  value={group}
                  placeholder="Filter by group"
                  onSelect={function (data: ItemGroupModel): void {
                    setgroup(data);
                  }}
                />
                {group && (
                  <button className="p-1" onClick={() => setgroup(undefined)}>
                    <Icon path={mdiClose} size={0.8}></Icon>
                  </button>
                )}
              </div>
              <SearchFieldWidget
                defaulValue={search}
                onClear={() => {
                  setSearch("");
                  fetchData({ page: 1, count });
                }}
                onSubmit={(val) => {
                  fetchData({ page: 1, count, search: val });
                  setPage(1);
                  setSearch("");

                  // setTimeout(() => {
                  //   setSearch(val);
                  // }, 10);
                }}
              />
              {/* <div
                onClick={() => {
                  setShowImport(true);
                }}
                className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
              >
                <Icon path={mdiArrowBottomLeft} size={0.8} />
                Import
              </div>*/}
              <div
                onClick={() => {
                  navaigation.push(urls.procurement_masters_item + "/create");
                }}
                className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
              >
                <Icon path={mdiPlus} className="h-4 w-4"></Icon>Add
              </div>
            </div>
          </div>
          {/* <div className="h-52 w-full bg-green-200 "></div> */}
        </section>

        <TableView
          rows={data.map((item, i) => ({
            data: item,
            values: [item.item_code || "--", item.name, item.group.name],
          }))}
          headers={[
            { key: "code", title: "Item code", type: FieldType.string },
            { key: "name", title: "Name", type: FieldType.string },
            { key: "group", title: "Group", type: FieldType.string },
          ]}
          page={page}
          total={total}
          count={count}
          itemCount={data.length}
          onPageChange={setPage}
          onClick={onSelect}
          onEdit={onSelect}
          onDelete={onDelete}
        />
      </div>

      {loading && <LoadingWidget></LoadingWidget>}
    </>
  );
};

export default ItemListScreen;
