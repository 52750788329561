import { RouteParam } from ".";
import Dashboard from "../ui/pages/dashboard";
import OrderCreatePage from "../ui/pages/orders/order_create";
import OrderDetailPage from "../ui/pages/orders/order_detail/order_detail_page";
import OrderListPage from "../ui/pages/orders/order_list";
import { ACTION, MODULE } from "../utils/enums";
import { urls } from "../utils/urls";

const kpi_routes: RouteParam[] = [
  
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.dashboard,
    component: Dashboard,
    module: MODULE.dashboard,
    main_module: MODULE.dashboard,
    action: [ACTION.READ],
  },
];

export default kpi_routes;
