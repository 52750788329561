import { FunctionComponent, useState } from "react";

interface TabWidgetProps {
  tabs: string[];
  curIndex: number;
  onClick: (i: number) => void;
}

const TabWidget: FunctionComponent<TabWidgetProps> = (props) => {
  return (
    <>
      <div className=" flex bg-gray-50 border rounded  ">
        {props.tabs.map((t, i) => (
          <button onClick={() => props.onClick(i)}>
            <div
              className={`  cursor-pointer  transition-all duration-300 ease-in-out ${
                i === props.curIndex ? "bg-white    " : ""
              }  px-3 py-2  `}
            >
              {t}
            </div>
            {i === props.curIndex && (
              <div className="h-1 w-full rounded-lg bg-teal"></div>
            )}
          </button>
        ))}
      </div>
    </>
  );
};

export default TabWidget;
