import { mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useState } from "react";
import { useOrderCreateCtx } from "../../../../../context_providers/order_create_context";
import { DiscountDataModel } from "../../../../../service/models/orders/order_discount_model";
import { GetDiscountAmt } from "../../../../../utils/order_util";
import DicountRowWidget from "./discount_row";

interface DiscountSectionWidgetProps {}

const DiscountSectionWidget: FunctionComponent<
  DiscountSectionWidgetProps
> = () => {
  const { order, setOrder } = useOrderCreateCtx();

  const [formId, setFormId] = useState(0);

  return (
    <>
      <h3 className="font-semibold mt-5">Discount</h3>
      <div className="bg-white rounded-md shadow p-3 my-2 text-sm">
        <div className="grid grid-cols-4 gap-3 ">
          <h4 className="font-semibold text-center col-span-2">
            {" "}
            <span className="text-base text-red-500">*</span>Description
          </h4>
          <h4 className="font-semibold text-center">
            {" "}
            <span className="text-base text-red-500">*</span>Amount
          </h4>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        {order.discountData.map((discount, i) => (
          <DicountRowWidget
            edit={false}
            key={discount.id}
            discount={discount}
            onDelete={(it: DiscountDataModel) => {
              const discountData = [...order.discountData].filter(
                (v, j) => v.id !== it.id
              );
              const dis = GetDiscountAmt(discountData);
              setOrder((old) => ({
                ...old,
                discount: dis,
                discountData,
              }));
            }}
            onSubmit={(data) => {
              const discountData = order.discountData.map((it, j) => {
                if (it.id === discount.id) {
                  return data;
                }
                return it;
              });
              const dis = GetDiscountAmt(discountData);
              setOrder((old) => ({
                ...old,
                discount: dis,
                discountData,
              }));
            }}
          />
        ))}
        <DicountRowWidget
          key={formId}
          edit={true}
          // form_id="discount_form"
          onSubmit={(data) => {
            data.id = Date.now();
            const discountData = [...order.discountData, data];
            const discount = GetDiscountAmt(discountData);
            setOrder((old) => ({
              ...old,
              discount,
              discountData: [...old.discountData, data],
            }));
            setFormId(Math.random());
          }}
        />
        <div className="flex justify-between">
          <p className="text-sm italic text-gray-400">
            Please add description and amount then hit + to add product.
          </p>
        </div>
        {/* <div className="flex justify-center my-3">
          <button
            form="discount_form"
            className="bg-myPrimaryColor rounded-full p-2 hover:scale-110 transform duration-300"
          >
            <Icon path={mdiPlus} className="h-5 w-5 text-white"></Icon>
          </button>
        </div> */}
      </div>
    </>
  );
};

export default DiscountSectionWidget;
