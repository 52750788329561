import { FunctionComponent } from "react";
import { academyVideoCategory } from "../../../../../utils/enums";
import VideoLayout from "../components/VideosLayout";

interface GeneralProps {}

const General: FunctionComponent<GeneralProps> = () => {
  const segment = academyVideoCategory.General;

  return <VideoLayout segment={segment} />;
};

export default General;
