import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useState } from "react";

interface ModalDialogProps {
  show: boolean;
  title: string;
  onClose: () => void;
}

const ModalDialog: FunctionComponent<ModalDialogProps> = (props) => {
  // useEffect(() => {
  //   if (props.show) {
  //     document.body.style.overflow = "hidden";
  //   } else {
  //     document.body.style.overflow = "auto";
  //   }
  // }, [props.show]);
  const [containerId, setContainerId] = useState("contaner" + Date.now());
  const [headerId, setHeaderId] = useState("hearder" + Date.now());
  useEffect(() => {
    const cb = (ev: KeyboardEvent) => {
      if (ev.key === "Escape") {
        props.onClose();
      }
    };
    document.addEventListener("keyup", cb);
    return () => document.removeEventListener("keyup", cb);
  }, []);

  useEffect(() => {
    dragElement(document.getElementById(containerId)!);

    function dragElement(elmnt: HTMLElement) {
      var pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;
      const header = document.getElementById(headerId);
      if (header) {
        // if present, the header is where you move the DIV from:
        header.onmousedown = dragMouseDown;
      } else {
        // otherwise, move the DIV from anywhere inside the DIV:
        elmnt.onmousedown = dragMouseDown;
      }

      function dragMouseDown(this: GlobalEventHandlers, e: MouseEvent) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      function elementDrag(this: GlobalEventHandlers, e: MouseEvent) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:

        const {
          left = 0,
          right = 0,
          top = 0,
          bottom = 0,
        } = document.getElementById("main")?.getBoundingClientRect() || {};
        if (
          e.clientX > left &&
          e.clientX < right &&
          e.clientY > top &&
          e.clientY < bottom
        ) {
          pos1 = pos3 - e.clientX;
          pos2 = pos4 - e.clientY;
          pos3 = e.clientX;
          pos4 = e.clientY;
          // set the element's new position:
          elmnt.style.top = elmnt.offsetTop - pos2 + "px";
          elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
        }
      }

      function closeDragElement() {
        // stop moving when mouse button is released:
        document.onmouseup = null;
        document.onmousemove = null;
      }
    }
  }, []);
  // backdrop-filter backdrop-blur-xs
  return (
    <div className="relative">
      <div
        id="main"
        className={`fixed top-0 left-0 right-0 bottom-0  z-50  w-screen h-screen overflow-hidden  bg-black bg-opacity-25  ${
          props.show ? "block" : "hidden"
        }`}
      >
        <div className="flex h-full w-full justify-center items-center ">
          <div
            id={containerId}
            className={`absolute mx-auto       shadow-lg rounded-xl bg-white zoom_wrapper`}
          >
            <div
              id={headerId}
              className="flex flex-col justify-between items-center cursor-move     rounded-t-lg"
            >
              <div className="flex justify-center mt-2 hover:scale-110 transform">
                <div className="w-20 h-1 bg-gray-400 rounded-lg"></div>
              </div>
              <div className="flex justify-between items-center w-full px-3">
                <div className=" text-sm font-semibold ">{props.title}</div>
                <div
                  className="p-px   rounded-full cursor-pointer   hover:text-red-600 hover:scale-110 transform"
                  onClick={props.onClose}
                >
                  <Icon path={mdiClose} size={0.8}></Icon>
                </div>
              </div>
            </div>

            <div style={{ maxHeight: "90%" }} className="overflow-y-auto p-2">
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalDialog;
