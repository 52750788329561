export const learningFaq = [
  {
    name: "General Questions",
    dataArray: [
      {
        ques: "Q. What are requirements for EV charging station? (in terms of space)",
        ans: "Sir AC chargers are wall mounted and are 1 ft x 0.5 ft  so it takes very less space, and can fit on any wall or pole near your parking spot, either in front of the car or on the side.DC chargers are floor bolted and are 7 ft tall ( similar to a fridge), on floor you need to have 2ft x 2ft space to install it  and again for parking you need to have enough space for a car to be parked and side space so that connector can be attached.",
        show: false,
      },
      {
        ques: "Q. But I think petrol pumps will get chargers. Then why should I install?",
        ans: "when you go and refuel your petrol car it takes 5 mins, for electric car it takes 1 hr in fast charger to several hrs in slow charger, people will not go petrol pump and sit their for hrs to charge vehicle.even in Europe and america, chargers are installed in parking spaces of offices, malls, restaurants, apartments where people can park thier car and get on with their work.",
        show: false,
      },
      {
        ques: "Q. I dont see many cars..",
        ans: "Sir, we are looking at lot of EVs on road today, you can see TATA Nexon has already started its delivery, MG ZS got more than 3000 booking before launch(in top 5 cities), Hyundai Kona is already selling good numbers in Delhi,pune, mumbai, blore, Mahindra everito, TATA Tigor, Reva, E2O are already in market for quite some time. Maruti is launching WagonR EV, Manindra is launching eKUV100 , eXUV300 , REnault, WV are also coming up with budget EVs. And 1.5 lacs rwo wheelers were sold and 3 lacs three wheeler.In premium segment: Jaguar ipace, Audi e-tron, BMW i3, Nissan Leaf are EVs. The future will bring more EVs, but now is the time to make money from it as 3-4 yrs later there will be more competition as everyone with a parking spot will install a charger.",
        show: false,
      },
      {
        ques: "Q. Is there a license required?",
        ans: "No sir, as per Govt. No license is required to install a charger, you just need to have a three phase connection which can take load of EV chargers (will connect to your current meter)",
        show: false,
      },
      {
        ques: "Q. Do you manufacure your own charging stations?",
        ans: "We have our plant based in Pune, and sales team & technical team in all Metro cities including Mumbai, pune, blore, delhi.",
        show: false,
      },
      {
        ques: "Q. Where is your office? can I come and meet you there",
        ans: "We have recently expanded to Mumbai/Pune/ Blore so it is mostly Field Sales, but we would be happy to meet you at your location and take it forward",
        show: false,
      },
      {
        ques: "Q. In how much price can I sell to drivers? (per unit)",
        ans: "There is no legal limit to what you can charge but based on what we see in the market, EV station owners charge anywhere between 13-20 rs per unit, it is totally upto you how much you want to charge the customer per unit, but dont keep it too high or else people might not use your station. Usually the higher side of the range (around 18-20 rs per unit) is for DC fast charging and lower side is for slow charging.",
        show: false,
      },
      {
        ques: "Q. Please take my property on rent and use it",
        ans: "we dont have this business modl as of now",
        show: false,
      },
      {
        ques: "Q. Can I get depreciation on the charging station as I am a commercial owner",
        ans: "Yes, as the charger is a capital investment, you can depreciate it over few years. The normal life of a charger is at least 5 years so you can depreciate it for that much time at least.",
        show: false,
      },
      {
        ques: "Q. I dont want to meet anyone, can you just send me a detailed quotation?",
        ans: "Contact Manager",
        show: false,
      },
      {
        ques: "Q. Is there any subsidy / benefits from the government?",
        ans: "Subsidy is on electric vehicles as of now",
        show: false,
      },
      {
        ques: "Q. I dont want to use your app. i want to take payment myself manually",
        ans: "Hi sir, we would prefer via our app as it will help you track payments and ev drivers to authenticate",
        show: false,
      },
      {
        ques: "Q. what about AMC? (after 1 year)",
        ans: "We will charge Rs 500 per year for AMC (after 1 year replacement warranty)",
        show: false,
      },
      {
        ques: "Q. How much are you selling DC charger?",
        ans: "Sir that costs around 7.5 lacs, for that I can ask my fast charger division to call you",
        show: false,
      },
      {
        ques: "Q. Please send you office address? (only give after Inside sales Manager permission))",
        ans: "Noida-G- 38 ,SECTOR - 3 ,Noida -201301 Uttar Prades. Mumbai - First floor, plot no.71, opposite daily needs, sector 16-A , Nerul , Navi Mumbai 400706 , Bangalore- Koramangala Pune- contact Vaibhav",
        show: false,
      },
      {
        ques: "Q. Please send your current installation details?",
        ans: "Please download Kazam EV mobile app an you can see ll the installed locations there",
        show: false,
      },
      {
        ques: "Q. Power rating and current rating?",
        ans: "Power rating- 3.3 KW, Current rating- 16 Ampere",
        show: false,
      },
      {
        ques: "Q. From where will the electricity connection come?",
        ans: "Current will be taken from your present meter itself",
        show: false,
      },
      //   kshdiejashduheajk
      {
        ques: "Q. Please send me some charge locations?",
        ans: "Sir, please download Kazam EV app and in their, you can find all the installaed locations of kazam chargers",
        show: false,
      },
      {
        ques: "Q. What is the service turn around time? If I file a complaint",
        ans: "Sir, we have 24x7 customer support number. We will try to resolve the complaint remotley. if not done, we will send an engineer within 3 days",
        show: false,
      },
      {
        ques: "Q. Does it charge Li ion battery  or lead acid battery or both?",
        ans: "Both",
        show: false,
      },
      {
        ques: "Q. Any tax benefit?",
        ans: "There is tax benfit on interest on loa forbuying EV but not charging station (upto 1.5 lacs)",
        show: false,
      },
      {
        ques: "Q. Do you guy take any cut in the transaction?",
        ans: "Yes. 10%. on total transaction (not for personal use case)",
        show: false,
      },
      {
        ques: "Q. Is the product made in india?",
        ans: "Yes: Sir, 80% componenet s are mede in India.. some are from europe",
        show: false,
      },
      {
        ques: "Q. Is there EMI plan?",
        ans: "Sir, if you have a credit card, you can pay via that and EMI option will be there (rate of interest and installment depend upon your credit card company sir)",
        show: false,
      },
      {
        ques: "Q. Can I connect this with solar?",
        ans: "Yes sir,, solar will be connected to your main meter and then from the your main meter charging station will be attached",
        show: false,
      },
      //   kdhia
      {
        ques: "Q. Is this a fast charger?",
        ans: "No",
        show: false,
      },
      {
        ques: "Q. what if someone tries to steal the product?",
        ans: "Sir, there is a tamper proof feature if someone tries to open the box, a notification ill go to the host",
        show: false,
      },
      {
        ques: "Q. does it have a battery backup?",
        ans: "No sir",
        show: false,
      },
      {
        ques: "Q. Is this BHarat AC 001?",
        ans: "No, and Bharat AC001 is a very old type charger not usable anymore",
        show: false,
      },
      {
        ques: "Q. Do you provide installation everywhere?",
        ans: "We give installation only in Maharashtra, Karnataka, Delhi NCR (Delhi, Noida, Gurgaon, Ghaziabad), Hyderabad and Chennai. Rest of the places we only deliver.",
        show: false,
      },
      {
        ques: "Q. How to do the installation?",
        ans: "https://www.youtube.com/watch?v=wmVFkwbKYqQ",
        show: false,
      },
    ],
  },
  {
    name: "ABOUT THE PRODUCT",
    dataArray: [
      {
        ques: "Which automobile can Kazam EV Charging station charge?",
        ans: "You can charge every automobile ranging from EV buses, cars, bikes. Just plug the charger and you're all set",
        show: false,
      },
      {
        ques: "What are the key features of this product?",
        ans: "Remote monitoring dashboard designed for high visibility, Compact & rugged design, Single output, Intelligent to authenticate & online payment, Safe to use with proper earthing and many more",
        show: false,
      },
      {
        ques: "What are the technical specifications of the product?",
        ans: "Not only is the device IoT 2G-based, IP 67-rated, and tamper-proof with an ABS plastic body or comparable, but it also features a 30cm x 15cm size with MCB and fuse and a white LED to attract EV drivers in addition to the 16 Amp, 3.3 kW power output.",
        show: false,
      },
      {
        ques: "Is Kazam EVCS waterproof/water-resistant?",
        ans: "Yes, Kazam has a water-resistant rating.",
        show: false,
      },
    ],
  },
  {
    name: "GETTING STARTED",
    dataArray: [
      {
        ques: "How can we start/stop charging?",
        ans: "Install the Kazam EV app, register and set the timing. You can now use your voice to instruct the app for start/stop charging.",
        show: false,
      },
      {
        ques: "How to install the Kazam EV Charger, What are some basic requirements for setting up the charger? ",
        ans: "It is preferable to have a three-phase connection capable of handling the load of your intended EV chargers. If the metre does not meet the specifications, Kazam personnel will assist you in raising the metre limit for a charge. It comes with a one-year replacement guarantee and a five-year AMC, or annual maintenance charge, free.",
        show: false,
      },
      {
        ques: "Do I need a license to install an electric vehicle charger?",
        ans: "Installing an EV charger does not require a licence. ",
        show: false,
      },
      {
        ques: "How Can Kazam EV Charger Assist You in Earning Money?",
        ans: "When you install a Kazam EV Charger at your workplace, Kirana shop, mall, residence, warehouse, or other site, it will appear on Google Maps and the Kazam EV App as a public charging station.When electric car owners locate your location, they will come to the EV charger and scan the QR code. They will charge their automobiles, and when they are finished, money will be deducted from his account and credited to your account.",
        show: false,
      },
      {
        ques: "How much money will I make with the Kazam EV?",
        ans: "There are no legal limitations on the amount of money you may charge your customers (for electricity & services provided). It is completely up to you how much you charge clients per unit, however we recommend not setting it too high, since this may deter consumers from visiting your station.",
        show: false,
      },
    ],
  },
];
