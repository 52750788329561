import { FunctionComponent, useEffect, useState } from "react";
import {
  CreateLeaveRequestRepo,
  GetHolidayAll,
  GetPublicHolidayList,
} from "../../../../service/repos/hrm/hrm_repo";
import {
  LeavePeriod,
  LeaveType,
  LeaveTypeIntern,
} from "../../../../utils/enums";
import "../styles/index.css";
import moment from "moment";
import { extendMoment } from "moment-range";
import Layout from "../layout";
import HrmLoader from "../components/loading";
import {
  useToast,
  ToastType,
} from "../../../../context_providers/toast/toast_ctx";
// @ts-ignore
import gsap from "gsap/all";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";

// @ts-ignore
import { DateRange } from "react-date-range";
import { addDays } from "date-fns";
import { CheckCircle } from "react-feather";
import { Link } from "react-router-dom";
import { urls } from "../../../../utils/urls";
import HrmPopup from "../components/HrmPopup";
import Calendar from "../home/components/Calendar";
import { differenceInDays } from "date-fns";
import { useAuth } from "../../../../context_providers/auth/authProvider";
import {
  UTCToLocalDate,
  getDaysDiff,
  isExpired,
} from "../../../../utils/date_util";

interface LeaveApplicationProps {}

const defaultValue: {
  leave_name: string;
  from_date: string;
  to_date: string;
  period?: string;
  message: string;
} = {
  leave_name: "",
  from_date: "",
  to_date: "",
  period: LeavePeriod.full_day,
  message: "",
};

const LeaveApplication: FunctionComponent<LeaveApplicationProps> = () => {
  const [totalLeaveCount, setTotalLeaveCount] = useState<number>(0);
  const [calendar, setCalendar] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [holidays, setHolidays] = useState<any>([]);
  const [dateRange, setDateRange] = useState<any>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const days = ["Sunday"];
  const date = new Date();

  const { user } = useAuth();
  // @ts-ignore
  const momentRange = extendMoment(moment);

  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [leaveRequest, setLeaveRequest] = useState<{
    leave_name: string;
    from_date: string;
    to_date: string;
    period?: string;
    message: string;
  }>({ ...defaultValue });

  const daysIntoYear = (date: any) => {
    return (
      (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) -
        Date.UTC(date.getFullYear(), 0, 0)) /
      24 /
      60 /
      60 /
      1000
    );
  };

  const animateData = async () => {
    const tl = gsap.timeline();
    tl.fromTo(
      ".success_wrapper",
      0.4,
      {
        opacity: 0,
      },
      {
        opacity: 1,
      }
    );
  };

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    e.preventDefault();

    const tempStart = moment(dateRange[0].startDate).format("YYYY-MM-DD");
    const tempEnd = moment(dateRange[0].endDate).format("YYYY-MM-DD");
    if (leaveRequest.leave_name === LeaveType.work_from_home) {
      const diff =
        getDaysDiff(
          UTCToLocalDate(tempStart, 330)!,
          UTCToLocalDate(tempEnd, 330)!
        ) + 1;
      console.log({ diff });

      if (diff > 4) {
        setLoading(false);
        return showToast({
          type: ToastType.error,
          text: "You can apply for max 4 days work from home",
        });
      }
    }
    const formData = {
      leave_name: leaveRequest.leave_name,
      from_date: tempStart,
      to_date: tempEnd,
      period: leaveRequest.period,
      message: leaveRequest.message,
    };

    const res: any = await CreateLeaveRequestRepo(formData);
    if (res.success) {
      setLoading(false);
      setShowSuccess(true);
      animateData();
    } else {
      setLoading(false);
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
  };

  // const getAllHolidays = async () => {
  //   const currentYear = date.getFullYear();
  //   const res = await GetHolidayAll(currentYear, user?.emp_profile?.zone);
  //   console.log(res);
  // };

  const getHolidayList = async () => {
    const res = await GetPublicHolidayList({
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    });

    setHolidays(res.data);
  };

  useEffect(() => {
    getHolidayList();
  }, []);

  useEffect(() => {
    const range = momentRange.range(
      dateRange[0].startDate,
      dateRange[0].endDate
    );
    const tempDatesArray = [];
    for (let day of range.by("days")) {
      tempDatesArray.push(day.unix() * 1000);
    }
    var tempLeave = 0;
    for (let i = 0; i < tempDatesArray.length; i++) {
      for (let j = 0; j < holidays.length; j++) {
        if (tempDatesArray[i] === holidays[j].date) {
          tempLeave++;
        }
      }
    }
    setTotalLeaveCount(tempDatesArray.length - tempLeave);
  }, [dateRange]);

  return (
    <Layout>
      <div className="h-screen relative">
        {showSuccess && (
          <div className="success_wrapper opacity-0 fixed top-0 left-0 h-screen w-screen bg-white flex flex-col items-center justify-center z-20">
            <div className="flex items-center gap-4">
              <div className="text-2xl italic">Submitted Successfully</div>
              <CheckCircle size={48} className="text-green-400" />
            </div>
            <div className="">
              <Link to={urls.hrm_home}>
                <button className="py-2 px-6 rounded bg-hrmPurple text-white font-semibold">
                  Go back home
                </button>
              </Link>
            </div>
          </div>
        )}
        <div className="flex flex-col lg:flex-row items-center h-full">
          <div className="w-3/5  flex flex-col items-center">
            <div className="hidden lg:block w-3/4">
              <div className="dark:text-primaryColor text-3xl font-semibold">
                Leave application form
              </div>
              <ul className="pl-4 list-disc text-secondaryColor dark:text-primaryColor mt-4">
                <li>Select leave type, range and apply for a leave here</li>
                <li className="">
                  Your leave request will be approved by your{" "}
                  <span className="font-bold underline"> Line Manager</span>
                </li>
              </ul>
              <div className="w-4/5 mt-7">
                <Calendar
                  showClose={false}
                  close={() => {
                    setCalendar(false);
                  }}
                />
              </div>
              {/* <div className="">
                <img src="/images/letter.png" alt="" className="w-64" />
              </div> */}
            </div>
          </div>
          <div className="w-full overflow-y-auto  h-full lg:w-2/5 relative  flex flex-col  items-center py-7 rounded-l-xl bg-white ">
            <div
              className="lg:hidden  w-full pl-3 mb-4 text-hrmBlue underline"
              onClick={() => setCalendar(true)}
            >
              Leave calendar
            </div>
            <div className="w-full px-5 flex items-center gap-3 ">
              {leaveRequest.leave_name !== LeaveType.work_from_home && (
                <div className="font-semibold text-gray-500 ">
                  {/* w-12 h-12 flex flex-col items-center justify-center */}
                  Total number of leaves deducted :-{" "}
                  <span className="text-black text-2xl font-bold">
                    {totalLeaveCount}
                  </span>
                </div>
              )}
            </div>
            <form className="w-11/12 mt-2  2xl:text-lg" onSubmit={submit}>
              <div className="text-gray-500 font-semibold text-sm">
                Select date range
              </div>
              <div className="mt-2">
                <DateRange
                  // date={new Date()}
                  ranges={dateRange}
                  // minDate={new Date()}
                  onChange={(item: any) => {
                    setDateRange([item.selection]);
                  }}
                  rangeColors={["#4B69FA", "#8302E9"]}
                  className="w-full"
                />
              </div>

              <label
                htmlFor="date"
                className="text-gray-500 font-semibold text-sm"
              >
                Type
              </label>
              <br />
              <select
                value={leaveRequest.leave_name}
                name="leave_type"
                id="leave_type"
                className="w-full mt-1 text-sm bg-transparent border border-primaryColor text-secondaryColor focus:outline-none rounded py-2 px-2 cursor-pointer"
                onChange={(e) =>
                  setLeaveRequest((o) => ({
                    ...o,
                    leave_name: e.target.value,
                  }))
                }
                required
              >
                <option value="" hidden>
                  Select ...
                </option>
                {user?.emp_profile?.emp_type === "Intern"
                  ? Object.values(LeaveTypeIntern).map((item, i) => (
                      <option
                        key={i}
                        value={item}
                        className="text-secondaryColor"
                      >
                        {item}
                      </option>
                    ))
                  : Object.values(LeaveType).map((item, i) => (
                      <option
                        key={i}
                        value={item}
                        className="text-secondaryColor"
                      >
                        {item}
                      </option>
                    ))}
              </select>
              <br />
              <br />
              {moment(dateRange[0].startDate).format("YYYY-MM-DD") ===
                moment(dateRange[0].endDate).format("YYYY-MM-DD") && (
                <>
                  <label
                    htmlFor="date"
                    className="text-gray-500 font-semibold text-sm"
                  >
                    Leave period
                  </label>
                  <br />
                  <select
                    name="leave_period"
                    id=""
                    className="w-full mt-1 text-sm bg-transparent border border-primaryColor text-secondaryColor focus:outline-none rounded py-2 px-2 cursor-pointer"
                    required
                    defaultValue={LeavePeriod.full_day}
                    onChange={(e) =>
                      setLeaveRequest((o) => ({
                        ...o,
                        period: e.target.value,
                      }))
                    }
                  >
                    {Object.values(LeavePeriod).map((el, i) => {
                      return <option value={el}>{el}</option>;
                    })}
                  </select>
                  <br />
                  <br />
                </>
              )}

              <label
                htmlFor="date"
                className="text-gray-500 font-semibold text-sm"
              >
                Message
              </label>
              <br />
              <textarea
                name="message"
                id="message"
                cols={30}
                rows={3}
                className="resize-none w-full mt-1 text-sm bg-transparent border border-primaryColor text-secondaryColor rounded py-1 px-2 focus:outline-none"
                value={leaveRequest.message}
                onChange={(e) =>
                  setLeaveRequest((o) => ({
                    ...o,
                    message: e.target.value,
                  }))
                }
                required
              ></textarea>
              <br />
              <br />
              <div className="flex flex-col items-end">
                {!loading ? (
                  <button
                    className={`hrm_btn bg-hrmBlue py-3 px-12  text-white font-bold rounded-full hover:scale-105 duration-150 ${
                      loading ? "animate-pulse" : ""
                    }`}
                    disabled={loading}
                  >
                    Submit
                  </button>
                ) : (
                  <HrmLoader className="w-10 h-10" />
                )}
              </div>
            </form>
          </div>
        </div>
        {calendar && (
          <HrmPopup>
            <Calendar
              close={() => {
                setCalendar(false);
              }}
              showClose={true}
            />
          </HrmPopup>
        )}
      </div>
    </Layout>
  );
};

export default LeaveApplication;
