import EmpCard from "./components/EmpCard";
import { useEffect, useState } from "react";
import { UserModel } from "../../../../context_providers/auth/user_model";
import { GetEmployeesByDept } from "../../../../service/repos/users_repo";
import Layout from "../layout";

const DeptEmployees = ({ match }: { match: any }) => {
  const [employees, setEmployees] = useState<UserModel[]>([]);
  const [count, setCount] = useState<number>(0);

  const getData = async () => {
    const res = await GetEmployeesByDept(match.params.dept);

    if (res.success) {
      setEmployees(res.data.data);
      const checkData = res.data?.data?.filter((d: any) => d.active);
      var count = 0;
      for (let i = 0; i < checkData.length; i++) {
        count = count + 1;
      }

      // console.log(checkData);

      const tempData = checkData.map((el: any) => {
        return {
          name: el.first_name + el.last_name,
          lineManager: el.reporting_to_name,
        };
      });

      setCount(count);
    } else {
      setEmployees([]);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Layout>
      <div className="py-4 lg:px-2">
        <div className="flex items-center gap-2">
          <div className="ml-1 text-xl">
            {employees[0]?.emp_profile?.department} Team
          </div>
          <div className="h-4 w-px bg-black"></div>
          <div className="ml-1 my-4 font-semibold text-secondaryColor dark:text-primaryColor">
            {count} People
          </div>
        </div>

        <div className="mt-5 lg:mt-10">
          <div className="flex items-center">
            <div className=" font-semibold text-green-500">Active</div>
            <div className="w-2 h-2 rounded-full bg-green-500 ml-1"></div>
          </div>
          {/* <div className="h-px w-1/2 bg-green-300 bg-opacity-40 mb-4 "></div> */}
          <div className="grid grid-cols-1 lg:grid-cols-4 lg:gap-x-2 gap-y-4 lg:gap-y-7 mt-2">
            {employees.length > 0 &&
              employees
                .filter((d) => d.emp_profile?.active)
                .map((item, i) => {
                  return <EmpCard emp={item} key={i} />;
                })}
          </div>
        </div>
        <div className="mt-32 lg:mt-64">
          <div className="flex items-center">
            <div className="text-xl font-semibold text-red-600">Inactive</div>
            <div className="w-2 h-2 rounded-full bg-red-600 ml-1"></div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-x-12 gap-y-7 mt-2">
            {employees.length > 0 &&
              employees
                .filter((d) => !d.emp_profile?.active)
                .map((item, i) => {
                  return <EmpCard emp={item} key={i} />;
                })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DeptEmployees;
