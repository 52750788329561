import { mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useState } from "react";
import { FunctionComponent } from "react";
import { useOrderCreateCtx } from "../../../../../context_providers/order_create_context";
import { OrderExtraModal } from "../../../../../service/models/orders/order_excess_model";
import OrderItemModel from "../../../../../service/models/orders/order_item_model";
import { GetExcessAmt } from "../../../../../utils/order_util";
import ProductRowWidget from "../product/product_row";
import ExtraRowWidget from "./extra_row";

interface ExtraSectionWidgetProps {}

const ExtraSectionWidget: FunctionComponent<ExtraSectionWidgetProps> = () => {
  const { order, setOrder } = useOrderCreateCtx();

  const [formId, setFormId] = useState(0);

  return (
    <>
      <h3 className="font-semibold mt-5">Extras</h3>
      <div className="bg-white rounded-md shadow p-3 my-2 text-sm">
        <div className="grid grid-cols-9 gap-3 ">
          <h4 className="font-semibold  text-center col-span-2">
            <span className="text-base text-red-500">*</span>Warehouse
          </h4>
          <h4 className="font-semibold text-center col-span-2">
            <span className="text-base text-red-500">*</span>Item
          </h4>
          <h4 className="font-semibold text-center">
            <span className="text-base text-red-500">*</span>Cost/unit
          </h4>
          <h4 className="font-semibold text-center">
            <span className="text-base text-red-500">*</span>Price/unit
          </h4>
          <h4 className="font-semibold text-center">
            <span className="text-base text-red-500">*</span>Unit
          </h4>
          <h4 className="font-semibold text-center">
            <span className="text-base text-red-500"></span>Amount
          </h4>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        {order.excessData.map((extra, i) => (
          <ExtraRowWidget
            edit={false}
            key={extra.id}
            extra={extra}
            onDelete={(it) => {
              const excessData = [...order.excessData].filter(
                (v, j) => v.id !== it.id
              );
              const excess = GetExcessAmt(excessData);
              setOrder((old) => ({
                ...old,
                excess,
                excessData,
              }));
            }}
            onSubmit={(data) => {
              const excessData = order.excessData.map((it, j) => {
                if (it.id === extra.id) {
                  return data;
                }
                return it;
              });
              const excess = GetExcessAmt(excessData);
              setOrder((old) => ({
                ...old,
                excess,
                excessData,
              }));
            }}
          />
        ))}
        <ExtraRowWidget
          key={formId}
          edit={true}
          // form_id="extra_form"
          onSubmit={(data) => {
            data.id = Date.now();
            const excessData = [...order.excessData, data];
            const excess = GetExcessAmt(excessData);
            setOrder((old) => ({
              ...old,
              excess,
              excessData,
            }));
            setFormId(Math.random());
          }}
        />
        <div className="flex justify-between">
          <p className="text-sm italic text-gray-400">
            Please select category,description and amount then hit + to add
            extra.
          </p>
        </div>
        {/* <div className="flex justify-center my-3">
          <button
            form="extra_form"
            className="bg-myPrimaryColor rounded-full p-2 hover:scale-110 transform duration-300"
          >
            <Icon path={mdiPlus} className="h-5 w-5 text-white"></Icon>
          </button>
        </div> */}
      </div>
    </>
  );
};

export default ExtraSectionWidget;
