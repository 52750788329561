import React, { useState, useEffect } from "react";
import NoRecordsFound from "../../components/common/noRecordsFound";
import Loader from "../../ui/new_components/common/loader";

import {
  deleteBlogPost,
  getBlogPosts,
  getSearchBlogPosts,
  updateVisibilityBlogPost,
} from "../../services/blogPostService";

import BlogListItem from "../../components/blogEditor/blogListItem";

import "./index.css";

import Pagination from "../../ui/new_components/common/pagination";
import { formToObject } from "../../infrastructure/utils/formDataToJson";

import { useAuth } from "../../../../../context_providers/auth/authProvider";
import { ACTION, MODULE } from "../../../../../utils/enums";
import moment from "moment";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import Icon from "@mdi/react";
import { mdiReload, mdiFileDownloadOutline } from "@mdi/js";
import { Tooltip } from "chart.js";
import { filter } from "lodash";
import SearchFieldWidget from "../../../../new_components/search_field";
import { Link } from "react-router-dom";
import { urls } from "../../../../../utils/urls";
import { blogCountry, blogLang } from "../../components/blogEditor/blogDetails";
import LoadingWidget from "../../../../../context_providers/modal/loader";

export default function Index() {
  const { showToast } = useToast();
  const { user, isAuthorised } = useAuth();

  const [total, setTotal] = useState(0);

  const [filter, setFilter] = useState<{
    page: number;
    count: number;
    search?: string;
  }>({ page: 1, count: 50 });
  const [fetching, setFetching] = useState(false);
  const [blogposts, setBlogposts] = useState<any[]>([]);

  const [timeStamp, setTimeStamp] = useState("");
  const [lang, setLang] = useState(
    localStorage.getItem("blog_lang") ?? blogLang.english
  );
  const [country, setCountry] = useState(
    localStorage.getItem("blog_country") ?? blogCountry.india
  );
  const [showDelete, setShowDelete] = useState(false);
  useEffect(() => {
    setShowDelete(
      isAuthorised({ module: MODULE.content, action: [ACTION.DELETE] })
    );
  }, []);

  const fetchBlogs = async () => {
    setFetching(true);
    try {
      const res = await getBlogPosts({ lang, country, ...filter });

      if (res.success) {
        setBlogposts(res.data.blogs);
        setTimeStamp(res.timeStamp);
        if (res.data.metadata[0]) setTotal(res.data.metadata[0]?.total);
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
    }
    setFetching(false);
  };
  // const getSearchBlogs = async (page: number, count: number) => {
  //   setFetching(true);
  //   try {
  //     setShowSearch(true);
  //     const options = { count, page, search, lang };
  //     const response = await getBlogPosts(options);
  //     if (response && response.success) {
  //       setBlogposts(response.data.blogs);
  //       setTotalCount(response.data.metadata[0].total);
  //       setFetching(false);
  //     }
  //   } catch (error: any) {
  //     console.log(error.message);
  //     setFetching(false);
  //   }
  // };

  useEffect(() => {
    localStorage.setItem("blog_lang", lang);
    localStorage.setItem("blog_country", country);
    fetchBlogs();
  }, [filter, lang, country]);
  const handleDeletePost = async (post: any) => {
    const del = "delete blog";
    const ans = prompt(`Enter '${del}' to Confirm `);

    if (ans !== del) return;

    setFetching(true);

    setFetching(true);
    try {
      const res = await deleteBlogPost(post.id);

      if (res.success) {
        await fetchBlogs();
        return;
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
    }
    setFetching(false);
  };
  const handleVisibilityChange = async (post: {
    active: boolean;
    id: string;
  }) => {
    setFetching(true);

    try {
      const res = await updateVisibilityBlogPost({
        blog_id: post.id,
        active: post.active,
      });
      console.log({ res });
      console.log({ active: post.active });

      if (res.success) {
        setBlogposts((old) =>
          old.map((po) => {
            if (po.id === post.id) {
              po.active = post.active;
              console.log("Update ", po.id, po.active);
            }
            return { ...po };
          })
        );
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
    }
    setFetching(false);
  };
  // if (fetching) return <Loader />;

  return (
    <>
      {fetching && <LoadingWidget />}
      <div className="p-5">
        <div className="flex flex-wrap justify-between  items-end mb-2">
          <div className="">
            <h1 className="font-bold text-2xl text-gray-500 ">Blogs</h1>
            <div className="flex gap-2">
              <p className="text-xs text-gray-400">
                Fetched at :{moment(timeStamp).format("hh:mm:ss A")}
              </p>
              <span
                className="transform hover:scale-125 duration-300  cursor-pointer text-gray-400 hover:text-gray-800 "
                onClick={fetchBlogs}
              >
                <div title="Refresh" className="flex  items-center ">
                  <Icon path={mdiReload} className="w-4 h-4"></Icon>
                </div>
              </span>
            </div>
          </div>
          <div className="flex gap-2 items-center flex-wrap">
            <SearchFieldWidget
              placeholder="Search"
              defaulValue={filter.search ?? ""}
              onClear={() => {
                setFilter({ ...filter, page: 1, search: "" });
              }}
              onSubmit={(search) => {
                if (search) {
                  setFilter({ ...filter, page: 1, search });
                }
              }}
            />

            <div className="flex gap-1 text-sm flex-col">
              {/* <label htmlFor="language">Language</label> */}
              <select
                title="Country"
                className=" bg-myBgcolor  border rounded px-2 py-1"
                name="country"
                id="country"
                value={country}
                onChange={(e) => {
                  setCountry(e.target.value);
                }}
              >
                {Object.values(blogCountry).map((country) => (
                  <option value={country} key={country}>
                    {country}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex gap-1 text-sm flex-col">
              {/* <label htmlFor="language">Language</label> */}
              <select
                title="Language"
                className=" bg-myBgcolor  border rounded px-2 py-1"
                name="language"
                id="language"
                value={lang}
                onChange={(e) => {
                  setLang(e.target.value);
                }}
              >
                {Object.values(blogLang).map((lang) => (
                  <option value={lang} key={lang}>
                    {lang}
                  </option>
                ))}
              </select>
            </div>
            <Link
              title="Create new Blog post"
              to={urls.blogCreate}
              className="bg-myPrimaryColor text-white px-2 py-1 rounded text-sm"
            >
              Add new
            </Link>
          </div>
        </div>

        <div className=" flex justify-center" style={{ height: "83vh" }}>
          {blogposts.length > 0 ? (
            <div
              id="scroll_listener"
              className=" overflow-auto h-full border rounded-lg p-1"
            >
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-3">
                {blogposts.map((post: any, i) => (
                  <BlogListItem
                    key={i}
                    showDelete={showDelete}
                    onDelete={handleDeletePost}
                    toggleVisibility={handleVisibilityChange}
                    post={post}
                  />
                ))}
              </div>
            </div>
          ) : (
            <NoRecordsFound displayText="No Blogs found. Please create!" />
          )}
        </div>

        <div className="mt-1">
          <Pagination
            page={filter.page}
            itemCount={blogposts.length}
            total={total}
            onChange={(p) => setFilter((o) => ({ ...o, page: p }))}
            count={filter.count}
          />
        </div>
      </div>
    </>
  );
}
