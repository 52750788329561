import { isSet } from "../../../utils/helpers";
export default function Index(props) {
  const { tags, errors, selectedTags, tagSelection } = props;
  return (
    <>
      <div
        className="  col-span-6 col-start-4 p-5 mt-10"
        style={{ backgroundColor: "#E9ECEF" }}
      >
        <div className=" flex items-center justify-center gap-5 ">
          {" "}
          <>
            {tags.map((tag) => (
              <div key={tag.tag_slug} className="flex gap-1">
                <label style={{ fontSize: 14 }}>
                  <input
                    type="checkbox"
                    className="mr-2"
                    id={tag.id}
                    onChange={(e) => tagSelection(tag)}
                    checked={
                      selectedTags.find((v) => v.id === tag.id) !== undefined
                    }
                  />
                  {tag.tag_display}
                </label>
              </div>
            ))}
          </>
        </div>{" "}
        {isSet(errors) && isSet(errors.tags) && (
          <div
            className="m-0 p-0 text-red-400 text-center"
            style={{ fontSize: 12, fontWeight: "bold" }}
          >
            {errors && errors.tags}
          </div>
        )}
      </div>
    </>
  );
}
