import { FunctionComponent, useEffect } from "react";
import Lottie from "lottie-web";
import qrscan from "../../lottie_files/qrscan.json";
interface QrScanLottieIconProps {}

const QrScanLottieIcon: FunctionComponent<QrScanLottieIconProps> = () => {
  useEffect(() => {
    Lottie.loadAnimation({
      container: document.querySelector("#qr-icon")!,
      animationData: qrscan,
      renderer: "canvas", // "canvas", "html"
      loop: false, // boolean
      autoplay: true, // boolean
    });
  }, []);
  return (
    <>
      {/* <div className="flex h-screen w-screen justify-center items-center  select-none">
      <div className="flex flex-col justify-center items-center"> */}
      <div id="qr-icon" className="h-full w-full"></div>
      {/* </div>
    </div> */}
    </>
  );
};

export default QrScanLottieIcon;
