import { mdiCheckCircle, mdiCheckCircleOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { useAuth } from "../../../../../../context_providers/auth/authProvider";

export default function Index(props) {
  const { user } = useAuth();
  // if (publishing) {
  //   return (
  //     <div
  //       className="container-fluid d-flex"
  //       style={{ backgroundColor: "#E9ECEF" }}
  //     >
  //       <div
  //         className="spinner-border"
  //         style={{ margin: "auto" }}
  //         role="status"
  //       >
  //         <span className="sr-only">Loading...</span>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div className="container-fluid bg-white">
      <div className="p-5">
        {/* <Icon
          path={mdiCheckCircleOutline}
          className="h-32 w-32 text-myPrimaryColor"
        ></Icon> */}
        <div className="flex justify-center">
          <img
            src="/images/icons/done.gif"
            alt=""
            srcset=""
            className="w-1/2 h-auto"
          />
        </div>
        <h2 className="text-center text-2xl font-semibold">Great work !</h2>
        <h3 className="text-center text-mySecondaryColor font-semibold text-lg">
          {user.first_name + " " + user.last_name}
        </h3>
        <h5 className="text-center mt-5">Your blog has been published.</h5>
      </div>
    </div>
  );
}
