import { RouteParam } from "../../../routes";
import { ACTION, MODULE } from "../../../utils/enums";
import { urls } from "../../../utils/urls";
import InventoryBasketCreateScreen from "../inventory_basket/inventory_basket_create";
import InventoryBasketListScreen from "../inventory_basket/inventory_basket_list_screen";

const inventory_bucket_routes: RouteParam[] = [
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.procurement_masters_inventory_bucket,
    component: InventoryBasketListScreen,
    module: MODULE.procurement_master,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.procurement_masters_inventory_bucket + "/create",
    component: InventoryBasketCreateScreen,
    module: MODULE.procurement_master,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.procurement_masters_inventory_bucket + "/update/:id",
    component: InventoryBasketCreateScreen,
    module: MODULE.procurement_master,
    main_module: MODULE.procurement_panel,
    action: [ACTION.UPDATE],
    operator: "or",
  },
];

export default inventory_bucket_routes;
