import { Note, Tune } from "@material-ui/icons";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import {
  ComLogModel,
  ComplaintCommentModel,
  ComplaintLogModel,
} from "../../../../../service/models/complaint_model";
import {
  CreateComplaintComment,
  getComplaintComments,
  getComplaintLogs,
} from "../../../../../service/repos/support/complaint_repo";

import { ACTION, complaintLogs, MODULE } from "../../../../../utils/enums";
import LoadingWidget from "../../../../components/loading_spinner";

const getComplaintLogTemplate = (log: ComLogModel) => {
  switch (log.log_type) {
    case complaintLogs.created:
      return (
        <>
          <p className="text-sm">
            <b>{log.user_name}</b> has created the complaint.
          </p>
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    case complaintLogs.updated:
      return (
        <>
          <p className="text-sm">
            <b>{log.user_name}</b> has{" "}
            <span className="text-purple-600">updated</span> the complaint.
          </p>
          <table className="border rounded w-full overflow-auto text-xs">
            <thead className="border rounded">
              <tr>
                <th className="">Field</th>
                <th>Previous value</th>
                <th>New value</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(log.params.changes).map((l, i) => (
                <tr key={i} className="border-b">
                  <td align="center">{l}</td>
                  <td align="center">{log.params.old[l]}</td>
                  <td align="center">{log.params.changes[l]}</td>
                </tr>
              ))}
            </tbody>
          </table>{" "}
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    case complaintLogs.commented:
      return (
        <>
          <p className="text-sm">
            <b>{log.user_name}</b> has{" "}
            <span className="text-blue-500">commented</span>.
          </p>
          <p className="italic text-xs">{log.params.comment}</p>{" "}
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    case complaintLogs.pending:
      return (
        <>
          <p>
            <b>{log.user_name}</b> has marked the complaint as pending.
          </p>{" "}
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    case complaintLogs.under_progress:
      return (
        <>
          <p>
            <b>{log.user_name}</b> has marked the complaint as{" "}
            <span className="text-yellow-400"> under progress</span>.
          </p>{" "}
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    case complaintLogs.solved:
      return (
        <>
          <p className="text-sm">
            <b>{log.user_name}</b> has marked the complaint as{" "}
            <span className="text-green-500">solved</span>.
          </p>
          <table className="border rounded w-full overflow-auto text-xs">
            <thead className="border rounded">
              <tr>
                <th className="">Field</th>
                <th>Previous value</th>
                <th>New value</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(log.params.changes).map((l, i) => (
                <tr key={i} className="border-b">
                  <td align="center">{l}</td>
                  <td align="center">{log.params.old[l]}</td>
                  <td align="center">{log.params.changes[l]}</td>
                </tr>
              ))}
            </tbody>
          </table>{" "}
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    case complaintLogs.noted_for_future:
      return (
        <>
          <p className="text-sm">
            <b>{log.user_name}</b> has marked the complaint as{" "}
            <span className="text-green-500">Noted for future</span>.
          </p>
          <table className="border rounded w-full overflow-auto text-xs">
            <thead className="border rounded">
              <tr>
                <th className="">Field</th>
                <th>Previous value</th>
                <th>New value</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(log.params.changes).map((l, i) => (
                <tr key={i} className="border-b">
                  <td align="center">{l}</td>
                  <td align="center">{log.params.old[l]}</td>
                  <td align="center">{log.params.changes[l]}</td>
                </tr>
              ))}
            </tbody>
          </table>{" "}
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );

    default:
      break;
  }
};

interface HistoryWidgetProps {
  complaint_id: string;
}

const HistoryWidget: FunctionComponent<HistoryWidgetProps> = ({
  complaint_id,
}) => {
  const { showToast } = useToast();

  const [logs, setLogs] = useState<any[]>([]);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const getComments = async () => {
    setLoading(true);
    const res = await getComplaintLogs({
      page: 1,
      count: 1,
      all: true,
      complaint_id,
    });
    if (res.success) {
      setLogs(res.data);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };

  useEffect(() => {
    getComments();
  }, []);
  return (
    <>
      {/* <div className="  h-full p-3">
        <div className="overflow-auto" style={{ height: "95%" }}>
          {logs.length === 0 && (
            <p className="text-center my-10">No History!</p>
          )}
          {logs.map((log, i) => (
            <div className="mb-2">
              {log.log_type ? (
                <div key={i} className="border-b">
                  {getComplaintLogTemplate(log)}
                </div>
              ) : (
                <div key={i}>
                  <p className="text-sm"> {log.log}</p>
                  <div className="flex justify-end items-end py-1">
                    <span className="text-xs italic">
                      {moment(log.created_at).format("lll")}
                    </span>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div> */}
      <div className="flex-col justify-between h-full">
        <div
          className="overflow-auto p-1 bg-gray-200"
          style={{ height: "97%" }}
        >
          {logs.length === 0 && (
            <p className="text-center my-10">No History!</p>
          )}
          {logs.map((log, i) => (
            <div key={i} className="  mb-2 shadow rounded bg-white p-1">
              {log.log_type ? (
                getComplaintLogTemplate(log)
              ) : (
                <div>
                  <p className="text-sm"> {log.log}</p>
                  <div className="flex justify-end items-end py-2">
                    <span className="text-xs italic">
                      {moment(log.created_at).format("lll")}
                    </span>
                  </div>
                  {/* <hr className="my-2" /> */}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>{" "}
      <LoadingWidget loading={loading} />
    </>
  );
};

export default HistoryWidget;
