import axios from "axios";
import fetch from "isomorphic-unfetch";
import { toQueryString } from "../../../../service/api_client";
import {
  fetchDelete,
  fetchGet,
  fetchPost,
  fetchPut,
  getHeaders,
} from "./serviceClient";

export const uploadBlogPhoto = async (data: FormData) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v4/blog-post/blog-photo`;
    const res = await fetchPost(url, data);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
  // return await fetch(
  //   `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/blog-post/blog-photo`,
  //   {
  //     method: "POST",
  //     body: data,
  //   }
  // )
  //   .then(async (resp) => {
  //     return await resp.json();
  //   })
  //   .catch((err) => console.log(err));
};

export const getAllCategories = async () => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v4/blog-post/categories`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const getAllTags = async () => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v4/blog-post/tags`;
    const res = await fetchGet(url);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
  // return await fetchGet(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/blog-post/tags`);
};

// Services related to BlogPosts
export const createBlogPost = async (body: FormData) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v4/blog-post`;
    const res = await fetchPost(url, body);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
  // return await fetchPost(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/blog-post`, body);
};
export const getBlogPosts = async (data: {
  lang: string;
  count: number;
  page: number;
  all?: boolean;
  search?: string;
  country?: string;
}) => {
  try {
    const url = `${
      process.env.REACT_APP_BACKEND_BASE_URL
    }/api/v4/blog-post${toQueryString(data)}`;

    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const getSearchBlogPosts = async (data: {
  search: string;
  count: number;
  page: number;
  all?: boolean;
}) => {
  try {
    let { search, count, page, all } = data;
    let url;
    if (count === undefined) {
      count = 10;
    }
    if (page === undefined) {
      page = 1;
    }
    url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v4/blog-post/search?term=${search}&count=${count}&page=${page}`;
    if (all !== undefined) {
      url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v4/blog-post/search?term=${search}all=${all}`;
    }

    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const GetBlogPost = async (slug: any) => {
  try {
    let url;

    url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v4/blog-post/${slug}`;

    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
  // return await fetchGet(
  //   `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/blog-post?count=${count}&page=${page}`
  // );
};

export const updateBlogPost = async (body: any) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v4/blog-post/${body.blogId}`;
    const res = await fetchPut(url, body);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const deleteBlogPost = async (id: any) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v4/blog-post/${id}`;
    const res = await fetchDelete(url);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const updateVisibilityBlogPost = async (body: any) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v4/blog-post/change/visibility`;
    const res = await fetchPut(url, body);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const GetUsersList = async (department: string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/employees/department/${department}`;

    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GetAuthorsList = async ({
  count,
  page,
  all,
}: {
  count: number;
  page: number;
  all?: boolean;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v4/blog-post/author?count=${count}&page=${page}&all=${all}`;

    const res = await fetchGet(url);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const CreateAuthorRepo = async (author: any) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v4/blog-post/author`;

    const res = await fetchPost(url, author);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const updateAuthorRepo = async (author: FormData) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v4/blog-post/author`;

    const res = await fetchPut(url, author, {
      "Content-Type": "application/x-www-form-urlencoded",
    });
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
