import { mdiClipboard, mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import {
  ToastType,
  useToast,
} from "../../../context_providers/toast/toast_ctx";
import {
  ApiClientModel,
  DeleteApiClientApi,
  GenerateApiClientsApi,
  GetAdminApiClientsApi,
  GetApiClientsApi,
} from "../../../service/repos/api_clients_repo";
import { formToObject } from "../../../utils/formDataToJson";
import LoadingWidget from "../../components/loading_spinner";
import Pagination from "../../new_components/common/pagination";

interface MyAPIClientsPageProps {}

const MyAPIClientsPage: FunctionComponent<MyAPIClientsPageProps> = () => {
  const { showToast } = useToast();
  const [clients, setClients] = useState<ApiClientModel[]>([]);
  const [created, setCreated] = useState<ApiClientModel>();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(100);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showApiModal, setShowApiModal] = useState(false);

  const generateApiKey = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = formToObject(new FormData(e.currentTarget));

    setLoading(true);
    const res = await GenerateApiClientsApi(data as any);
    if (res.success) {
      setCreated(res.data);
      setClients((o) => [res.data, ...o]);
      setTotal((o) => o + 1);
      setShowApiModal(true);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  const deleteClient = async (id: string) => {
    setLoading(true);
    const res = await DeleteApiClientApi(id);

    if (res.success) {
      if (id === clients[0].id) setShowApiModal(false);
      setClients((o) => o.filter((f) => f.id !== id));
      setTotal((o) => o - 1);
      //   setPage(res.page);
      //   setCount(res.count);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  const getClients = async () => {
    setLoading(true);
    const res = await GetApiClientsApi({ count, page });

    if (res.success) {
      setClients(res.data);
      setTotal(res.total);
      //   setPage(res.page);
      //   setCount(res.count);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  useEffect(() => {
    getClients();
  }, [page]);
  return (
    <div className="bg-gray-200  min-h-screen p-3">
      <div className="flex my-1 justify-between ">
        <h3 className="text-lg font-medium">API Keys</h3>

        <button
          className="  rounded-md px-3 py-1 bg-white text-sm font-semibold"
          onClick={() => setShowApiModal(true)}
        >
          Request New
        </button>
      </div>
      {showApiModal && (
        <form
          onSubmit={generateApiKey}
          className="rounded bg-white p-3 my-2 flex flex-col gap-3"
        >
          <div className="flex justify-end ">
            <div
              className="cursor-pointer hover:text-red-400"
              onClick={() => {
                setShowApiModal(false);
                setCreated(undefined);
              }}
            >
              <Icon path={mdiClose} className="h-5 w-5"></Icon>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-2 items-center ">
            <label htmlFor="name">Name</label>
            <div className="flex   justify-between  md:col-span-3">
              <input
                required
                placeholder="Name"
                className="w-full   focus:outline-none bg-gray-200 rounded-l-md p-3"
                type="text"
                id="name"
                name="name"
              />
              <button
                disabled={created !== undefined}
                className={`focus:outline-none bg-red-600 text-white rounded-r-md p-2 text-sm font-semibold ${
                  created !== undefined ? "cursor-not-allowed" : ""
                }`}
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-2 items-center ">
            <label htmlFor="api_id">API ID</label>
            <div className="flex gap-2 justify-between bg-gray-200 rounded-md p-3 md:col-span-3">
              <input
                className="w-full"
                type="text"
                id="api_id"
                disabled={true}
                value={created?.api_id}
              />
              <div
                className=""
                onClick={() => {
                  navigator.clipboard.writeText(created?.api_id ?? "");

                  showToast({
                    type: ToastType.info,
                    text: "Copied API ID to clip board !",
                  });
                }}
              >
                <Icon path={mdiClipboard} className="h-5 w-5"></Icon>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-2 items-center ">
            <label htmlFor="api_key">API Key</label>
            <div className="flex gap-2 justify-between bg-gray-200 rounded-md p-3 md:col-span-3">
              <input
                className="w-full"
                type="text"
                id="api_key"
                disabled={true}
                value={created?.api_key}
              />
              <div
                className=""
                onClick={() => {
                  navigator.clipboard.writeText(created?.api_key ?? "");

                  showToast({
                    type: ToastType.info,
                    text: "Copied API Key to clip board !",
                  });
                }}
              >
                <Icon path={mdiClipboard} className="h-5 w-5"></Icon>
              </div>
            </div>
          </div>
          <p>
            <b>Please note: </b>
            <span className="text-xs italic">
              API key generated will have the permissions of current user, Do
              not share with anyone.
            </span>
          </p>
        </form>
      )}
      <div
        className="bg-white max-h-full rounded-md p-2 flex flex-col justify-between"
        style={{ height: "95%" }}
      >
        <div className=" overflow-auto   " style={{ height: "93%" }}>
          <table className="border divide-y divide-gray-200 relative w-full ">
            <thead className="bg-black text-white sticky  top-0">
              <tr>
                {["Date", "Name", "Api id", "Api key", " "].map((h) => (
                  <th
                    align="center"
                    key={h}
                    className="px-3 py-2 text-xs font-medium    uppercase tracking-wider"
                  >
                    {h}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200   text-xs">
              {clients.map((client, i) => {
                //
                return (
                  <tr
                    className="hover:bg-gray-200"
                    key={i}
                    // onDoubleClick={() => props.onTxnClick(txn.id!)}
                  >
                    <td className="px-3 py-2 whitespace-nowrap text-xs font-medium text-gray-900">
                      {moment(client.created_at).format("lll")}
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap text-xs font-medium text-gray-900">
                      {client.name}
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap text-xs font-medium text-gray-900">
                      {client.api_id}
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap text-xs font-medium text-gray-900">
                      {client.api_key}
                    </td>

                    <td align="center" className="flex">
                      <button
                        className="text-blue-300 rounded  hover:text-red-600 mx-1 p-1"
                        onClick={() => deleteClient(client.id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <hr />
        <div className="">
          <Pagination
            itemCount={clients.length}
            page={page}
            total={total}
            count={count}
            onChange={(p) => setPage(p)}
          />
        </div>
      </div>
      <LoadingWidget loading={loading} />
    </div>
  );
};

export default MyAPIClientsPage;
