import { debounce } from "lodash";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { UserModel } from "../../context_providers/auth/user_model";
import { ToastType, useToast } from "../../context_providers/toast/toast_ctx";
import { GetEmps } from "../../service/repos/users_repo";
import AutoCompleteField from "./common/autocomplete";

interface EmployeeSearchWidgetProps {
  onSelect: (data: UserModel) => void;
  placeholder?: string;
  emp?: { uid: string; name: string };
  disabled?: boolean;
  withBtn?: boolean;
}

const EmployeeSearchWidget: FunctionComponent<EmployeeSearchWidgetProps> = ({
  onSelect,
  placeholder,
  emp,
  withBtn = true,
  disabled = false,
}) => {
  const { showToast } = useToast();
  const [search, setSearch] = useState("");
  const [emps, setEmps] = useState<UserModel[]>([]);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setSearch(emp?.name ?? "");
  }, [emp]);
  useEffect(() => {
    if (search && edit) {
      if (emp && emp.name === search) return;
      debouncedHandler(search);
      // debounce(() => {
      //   searchEmp();
      // });
    }
  }, [search]);

  const searchEmp = async (search: string) => {
    setLoading(true);
    const res = await GetEmps({ search, all: true });
    if (res.success) {
      setEmps(res.data.data!);
    } else {
      setEmps([]);
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  const debouncedHandler = useCallback(
    debounce((query) => {
      if (query) searchEmp(query);
    }, 500),
    []
  );
  // const debounceHandler = debounce((query) => {
  //   console.log(query);
  // }, 500);
  // useEffect(() => {
  //   debouncedHandler(search);
  // }, [search]);
  return (
    <div className="flex gap-1 items-center    w-full">
      {edit ? (
        <AutoCompleteField
          loading={loading}
          value={search}
          placeholder={placeholder ?? "Employees"}
          onSelect={(val) => {
            setEdit(false);
            setSearch(val.value.first_name + " " + val.value.last_name);
            onSelect(val.value);
          }}
          suggestions={emps.map((e) => ({
            label: (
              <div className="text-xs">
                <b> {e.first_name + " " + e.last_name} </b>
                <span
                  style={{ fontSize: "10px" }}
                >{` (${e.emp_profile?.department}-${e.emp_profile?.sub_dep})`}</span>
              </div>
            ),

            value: e,
          }))}
          onChange={(str) => {
            setSearch(str);
          }}
        />
      ) : (
        <div
          className="text-sm px-3 py-2  h-8 rounded focus:outline-none w-full border"
          onClick={() => {
            if (!disabled) {
              setEdit(true);
              // setSearch(emp?.name ?? "");
            }
          }}
        >
          {search || placeholder}
        </div>
      )}
      {/* {withBtn && !disabled && (
        <div
          className="text-xs cursor-pointer "
          onClick={() => {
            if (edit) {
              setSearch(emp?.name ?? "");
            }

            setEdit((o) => !o);
          }}
        >
          {edit ? <p>Cancel</p> : <p>Edit</p>}{" "}
        </div>
      )} */}
    </div>
  );
};

export default EmployeeSearchWidget;
