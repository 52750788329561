import { BaseModel } from "../../../service/models/base";
import {
  AddressModel,
  TaxDetail,
  addressDefaultValue,
 
  taxDetail,
} from "../../accounts/models/party_model";

export interface WarehouseGroupModel extends BaseModel {
  name: string;
  description?: String;
}
export const WAREHOUSE_TYPE = {
  "warehouse": "warehouse", "transit": "transit"
}

export interface SpocModel {
    uid?:string;
    contact_name: string;
    contact_mobile?: string;
    contact_email?: string;
    department?:string;
  
}
export const spocDefaultValue:SpocModel = {
  uid:"",
  contact_name: "",
  contact_mobile: "",
  contact_email: ""
}

export interface WarehouseModel extends BaseModel {
  name: string;
  type: "warehouse" | "transit";
  description?: string;
  group_id?: string;
  group?: WarehouseGroupModel;
  address?: AddressModel;
  tax_detail?: TaxDetail;
  spoc?: SpocModel;
}

export const warehouseDefaultValue: WarehouseModel = {
  name: "",
  group_id: "",
  type: "warehouse",
  address: addressDefaultValue,
  tax_detail: taxDetail,
  
  spoc: spocDefaultValue,
};
