import { RouteParam } from "../../../routes";
import { ACTION, MODULE } from "../../../utils/enums";
import { urls } from "../../../utils/urls";
import DebitNoteListScreen from "../account_booking/debit_note/debit_note_list";
import DebitNote from "../vouchers/debit_note";

const debit_note_routes: RouteParam[] = [
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.debit_note,
    component: DebitNoteListScreen,
    module: MODULE.voucher,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.debit_note + "/create",
    component: DebitNote,
    module: MODULE.voucher,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.debit_note + "/update/:id",
    component: DebitNote,
    module: MODULE.voucher,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
];

export default debit_note_routes;
