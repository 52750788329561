import { Note, Tune } from "@material-ui/icons";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { useAuth } from "../../../../context_providers/auth/authProvider";
import {
  ToastType,
  useToast,
} from "../../../../context_providers/toast/toast_ctx";
import { ComplaintCommentModel } from "../../../../service/models/complaint_model";
import { InventoryCommentModel } from "../../../../service/models/inventory/inventory_model";
import {
  CreateInventoryCommentApi,
  GetInventoryCommentsApi,
} from "../../../../service/repos/inventory_repo";

import { MODULE, ACTION } from "../../../../utils/enums";
import LoadingWidget from "../../../components/loading_spinner";
import MentionTextFieldWidget from "../../orders/order_list/components/mention_text_field";

interface CommentWidgetProps {
  inventory_id: number;
}

const CommentWidget: FunctionComponent<CommentWidgetProps> = ({
  inventory_id,
}) => {
  const { showToast } = useToast();
  const { user, isAuthorised } = useAuth();
  const [comments, setComments] = useState<InventoryCommentModel[]>([]);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const getComments = async () => {
    setLoading(true);
    const res = await GetInventoryCommentsApi(inventory_id);
    if (res.success) {
      setComments(res.data);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  const createComment = async (str: string) => {
    setLoading(true);
    const res = await CreateInventoryCommentApi({
      comment: input,
      inventory_id,
    });
    if (res.success) {
      setComments((pr) => [res.data, ...pr]);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };

  const [disabled, setDisabled] = useState(true);
  useEffect(() => {
    getComments();
    setDisabled(
      !isAuthorised({
        module: MODULE.device_inventory_panel,
        action: [ACTION.READ],
      })
    );
  }, []);
  return (
    <>
      <div className="flex-col justify-between h-full p-2">
        <div className="overflow-auto" style={{ height: "85%" }}>
          {comments.length === 0 && (
            <p className="text-center my-10">No Comment!</p>
          )}
          {comments.map((comment, i) => (
            <div key={i}>
              <div className="flex justify-start items-center py-2">
                <div className="bg-red-400 text-white rounded-full w-10 h-10 flex justify-center items-center font-bold mr-3">
                  {comment.user ? comment.user[0].toUpperCase() : "A"}
                </div>
                <span className="text-xs">
                  <span className="font-semibold"> {comment.user}</span>{" "}
                  {moment(comment.created_at).format("lll")}
                </span>
              </div>
              <p className="text-sm"> {comment.comment}</p>
              <hr className="my-2" />
            </div>
          ))}
        </div>

        {!loading && (
          <div className=" grid grid-cols-1 md:grid-cols-2   z-50  fixed bottom-3 left-3 right-3 ">
            <MentionTextFieldWidget
              placeholder="Note"
              disabled={disabled}
              value={input}
              onChange={(e) => {
                setInput(e);
              }}
            />

            <button
              onClick={() => {
                if (input) {
                  createComment(input);
                  setInput("");
                }
              }}
              type="button"
              className="  bg-gray-200 rounded py-1 px-2 text-sm hover:bg-red-400 hover:text-white col-span-2"
            >
              Submit
            </button>
          </div>
        )}
      </div>{" "}
      <LoadingWidget loading={loading} />
    </>
  );
};

export default CommentWidget;
