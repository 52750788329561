import { FunctionComponent, useEffect, useRef, useState } from "react";
import { Award, X } from "react-feather";
import {
  useToast,
  ToastType,
} from "../../../../../context_providers/toast/toast_ctx";
import { PostMemory } from "../../../../../service/repos/hrm/hrm_repo";
import { compressImage } from "../../../../../utils/image_compressor";
import HrmLoader from "../../components/loading";

interface AddMemoryProps {
  showAdd: any;
  close: () => void;
}

const AddMemory: FunctionComponent<AddMemoryProps> = ({ showAdd, close }) => {
  const { showToast } = useToast();
  const ref = useRef<HTMLDivElement>(null);
  const [image, setImage] = useState<any>();
  const [showImage, setShowImage] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: any) => {
    setLoading(true);
    e.preventDefault();

    const formData = new FormData();
    formData.append("files", image);
    formData.append("description", e.target.caption.value);
    const res = await PostMemory(formData);
    console.log(res);
    if (res.success) {
      window.location.reload();
    } else {
      showToast({
        type: ToastType.warning,
        text: "Fie is too large",
      });
      window.location.reload();
    }
  };

  return (
    <div className=" w-11/12 lg:w-auto bg-white py-12 px-9 relative">
      <X
        onClick={() => close()}
        className="absolute top-2 right-2 cursor-pointer"
      />
      <div className="text-lg font-bold">Upload memory</div>
      <div className="text-sm text-gray-400">Upload and post memory</div>
      <form action="" onSubmit={handleSubmit} className="mt-8">
        {showImage ? (
          <div className="w-40 h-40 lg:w-64 lg:h-64 mx-12 lg:mx-32">
            <img
              src={URL.createObjectURL(image)}
              alt=""
              className="w-full h-full object-contain "
            />
          </div>
        ) : (
          <div className=" flex justify-center items-center w-full">
            <label htmlFor="image-input" className="cursor-pointer">
              <div className="px-24 lg:px-64 py-12 rounded-2xl border-2 border-dashed border-hrmBlue text-5xl  flex flex-col items-center justify-center cursor-pointer">
                +
              </div>
            </label>
            <input
              required
              type="file"
              name="image"
              id="image-input"
              accept="image/*"
              onChange={async (e: any) => {
                if (e.target.files[0]) {
                  if (
                    e.target.files[0].type === "image/png" ||
                    e.target.files[0].type === "image/webp" ||
                    e.target.files[0].type === "image/jpeg" ||
                    e.target.files[0].type === "image/jpg"
                  ) {
                    const tmp = await compressImage(e.target.files[0]);
                    setImage(tmp);
                    setShowImage(true);
                  } else {
                    showToast({
                      type: ToastType.warning,
                      text: "Supported types: png, jpg, jpeg, webp",
                    });
                    e.target.value = "";
                  }
                }
              }}
              className="hidden invisible"
            />
          </div>
        )}
        <div className="my-12">
          <div className="font-bold">Add a caption*</div>
          <input
            required
            type="text"
            name="caption"
            className="w-full py-4 bg-transparent border rounded focus:outline-none px-4 mt-2"
            placeholder="Image caption"
          />
        </div>
        {showImage && (
          <div className="mt-6 mb-4 flex items-center justify-end gap-7">
            <div className="font-bold cursor-pointer" onClick={() => close()}>
              Cancel
            </div>
            {loading ? (
              <HrmLoader className="w-12 h-12" />
            ) : (
              <button className="py-2 lg:py-3 px-10 lg:px-12 text-white bg-hrmBlue font-bold rounded-full">
                Post
              </button>
            )}
          </div>
        )}
      </form>
    </div>
  );
};

export default AddMemory;
