import { RouteParam } from ".";
import RoleTableWidget from "../ui/pages/accesspanel/roles";
import UsersScreen from "../ui/pages/accesspanel/users";
import UserAccessDetailPage from "../ui/pages/accesspanel/user_detail_page";
import RoleCreatePage from "../ui/pages/accesspanel/roles/role_create";
import { ACTION, MODULE } from "../utils/enums";
import { urls } from "../utils/urls";

const access_routes: RouteParam[] = [
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.users,
    component: UsersScreen,
    main_module: MODULE.access_panel,
    module: MODULE.emp,
    action: ACTION.READ,
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.roles,
    component: RoleTableWidget,
    main_module: MODULE.access_panel,
    module: MODULE.role,
    action: ACTION.READ,
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.createRole,
    component: RoleCreatePage,
    main_module: MODULE.access_panel,
    module: MODULE.role,
    action: ACTION.CREATE,
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.editRole,
    component: RoleCreatePage,
    main_module: MODULE.access_panel,
    module: MODULE.role,
    action: ACTION.UPDATE,
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.userCreate,
    component: UserAccessDetailPage,
    main_module: MODULE.access_panel,
    module: MODULE.emp,
    action: ACTION.CREATE,
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.userEdit,
    component: UserAccessDetailPage,
    main_module: MODULE.access_panel,
    module: MODULE.emp,
    action: ACTION.UPDATE,
  },
];

export default access_routes;
