import { ExportToCsv } from "export-to-csv";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import {
  useToast,
  ToastType,
} from "../../../../../context_providers/toast/toast_ctx";
import { GetUsersRepo } from "../../../../../service/repos/customer/customer_repo";

import LoadingWidget from "../../../../components/loading_spinner";
import StadiumButton from "../../../../new_components/common/stadium_button";

interface ExportWidgetProps {}

const ExportWidget: FunctionComponent<ExportWidgetProps> = (props) => {
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);

  const [toDate, setTodate] = useState("");

  const getOrders = async () => {
    setLoading(true);
    const res = await GetUsersRepo({
      all: true,
    });

    if (res.success) {
      const options = {
        fieldSeparator: ",",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        showTitle: true,
        title: "Tasks",
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
      };

      const csvExporter = new ExportToCsv(options);

      csvExporter.generateCsv(res.data);
    }
    setLoading(false);
  };

  return (
    <div className="p-5">
      {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <div className="">
          <label htmlFor="from-date">From</label>
          <input
            id="from-date"
            className="border rounded p-2 w-full"
            type="date"
            name="fromDate"
            placeholder="From"
            required
            value={fromDate}
            onChange={(e) => {
              if (
                toDate &&
                moment(e.target.value, "YYYY-MM-DD").isAfter(
                  moment(toDate, "YYYY-MM-DD")
                )
              ) {
                showToast({
                  type: ToastType.error,
                  text: "From date should not be greater than todate",
                });
                return;
              }
              setFromdate(e.target.value);
            }}
          />
        </div>
        <div className="">
          <label htmlFor="to-date">To</label>
          <input
            id="to-date"
            className="border rounded p-2 w-full"
            type="date"
            name="toDate"
            placeholder="To"
            required
            value={toDate}
            min={fromDate ? fromDate : ""}
            onChange={(e) => {
              if (
                fromDate &&
                moment(fromDate, "YYYY-MM-DD").isAfter(
                  moment(e.target.value, "YYYY-MM-DD")
                )
              ) {
                showToast({
                  type: ToastType.error,
                  text: "To date should  be greater than From date",
                });
                return;
              }
              setTodate(e.target.value);
            }}
          />
        </div>
      </div> */}
      <div className="flex justify-end my-2">
        <StadiumButton type="submit" onTap={getOrders}>
          Export
        </StadiumButton>
      </div>
      <LoadingWidget loading={loading} />
    </div>
  );
};

export default ExportWidget;
