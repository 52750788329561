import { mdiMagnify } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useState } from "react";
import { useOrderCreateCtx } from "../../../../../../context_providers/order_create_context";
import {
  useToast,
  ToastType,
} from "../../../../../../context_providers/toast/toast_ctx";
import { AddressModel } from "../../../../../../service/models/address_model";
import { CustomerDetailModel } from "../../../../../../service/models/orders/customer_detail_model";
import { getCityStateApi } from "../../../../../../service/repos/address/address_repo";
import { CreateBillingProfileApi } from "../../../../../../service/repos/customer/billing_profile_repo";
import { AddressType, OrderType } from "../../../../../../utils/enums";
import LoadingWidget from "../../../../../components/loading_spinner";
import {
  ModalAlignment,
  ModalType,
  useModal,
} from "../../../../../../context_providers/modal/modal_context";
import AddressPickerWidget from "../../../../../new_components/common/location/AddressPicker";

interface AddBillingProfileWidgetProps {
  user_id: any;
  onAdded: (data: CustomerDetailModel) => void;
}
const defaultAddress: AddressModel = {
  type: AddressType.HOME,
  line1: "",
  city: "",
  state: "",
  country: "India",
  pincode: 0,
};
const defaultValue: CustomerDetailModel = {
  firstName: "",
  mobile: "",
  alt_mobile: "",
  email: "",
  address: defaultAddress,
};
const AddBillingProfileWidget: FunctionComponent<
  AddBillingProfileWidgetProps
> = ({ onAdded, user_id }) => {
  const { order, setOrder, user } = useOrderCreateCtx();
  const { showToast } = useToast();
  const { showModal, onClose } = useModal();
  const [customer, setCustomer] = useState<CustomerDetailModel>({
    ...defaultValue,
    user_id,
  });
  const [loading, setLoading] = useState(false);
  const createBillingProfile = async () => {
    setLoading(true);
    customer.firstName = customer.firstName.trim();
    customer.lastName = customer.lastName?.trim();
    customer.mobile = customer.mobile.trim();
    customer.email = customer.email.trim();
    const res = await CreateBillingProfileApi(customer!);
    if (res.success) {
      showToast({ type: ToastType.success, text: "Created Successfull !" });
      setLoading(false);
      return onAdded(res.data);
    } else {
      showToast({ type: ToastType.error, text: res.error });
    }
    setLoading(false);
  };
  const getCityState = async (pincode: number) => {
    console.log({ pincode });

    setLoading(true);
    const res = await getCityStateApi(pincode);

    if (res.success) {
      if (!res.data) {
        setLoading(false);
        alert("invalid zip code !");
        return;
      }
      const { city, state, pincode } = res.data;
      setCustomer((o) => ({
        ...o!,
        address: { ...o?.address, city, state, pincode },
      }));
    } else showToast({ type: ToastType.error, text: res.error ?? "" });
    setLoading(false);
  };
  useEffect(() => {
    setCustomer((o) => ({
      ...o,
      firstName: user!.first_name,
      lastName:
        order.type === OrderType.B2C || order.type === OrderType.DELHI_TENDOR
          ? user!.last_name
          : "",
      mobile: user!.mobile ?? "",
      email: user!.email ?? "",
    }));
  }, []);
  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          createBillingProfile();
        }}
        className="grid grid-cols-1 md:grid-cols-2 gap-8 text-sm"
      >
        <div className="flex flex-col gap-2 ">
          {" "}
          <h3 className="text-sm font-semibold">Customer detail</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 items-center">
            <label htmlFor="first-name">
              {order.type === OrderType.B2C ||
              order.type === OrderType.DELHI_TENDOR
                ? "First Name"
                : "Business Name"}
              <span className="text-red-400">*</span>
            </label>
            <input
              id="first-name"
              className="col-span-2 p-2 rounded bg-gray-100 focus:outline-none"
              type="text"
              name="firstName"
              placeholder={
                order.type === OrderType.B2C ||
                order.type === OrderType.DELHI_TENDOR
                  ? "First Name"
                  : "Business name"
              }
              value={customer.firstName}
              onChange={(e) =>
                setCustomer((o) => ({
                  ...o!,
                  firstName: e.target.value,
                }))
              }
              required
            />
          </div>
          {(order.type === OrderType.B2C ||
            order.type === OrderType.DELHI_TENDOR) && (
            <div className="grid grid-cols-1 md:grid-cols-3 items-center">
              <label htmlFor="last-name">Last Name</label>
              <input
                id="last-name"
                className="col-span-2 p-2 rounded bg-gray-100 focus:outline-none"
                type="text"
                name="lastName"
                // required
                value={customer?.lastName}
                onChange={(e) =>
                  setCustomer((o) => ({
                    ...o!,
                    lastName: e.target.value,
                  }))
                }
                placeholder={"Last Name"}
              />
            </div>
          )}
          <div className="grid grid-cols-1 md:grid-cols-3 items-center">
            <label htmlFor="mobile">
              Mobile <span className="text-red-400">*</span>
            </label>
            <input
              required
              type="tel"
              pattern="[1-9]{1}[0-9]{9}"
              className="col-span-2 p-2 rounded bg-gray-100 focus:outline-none "
              id="mobile"
              value={customer?.mobile}
              placeholder="10 Digits"
              onChange={(e) =>
                setCustomer((o) => ({ ...o!, mobile: e.target.value.trim() }))
              }
              title="Please enter 10 digit mobile number"
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 items-center">
            <label htmlFor="alt_mobile">Alternate Mobile </label>
            <input
              type="tel"
              pattern="[1-9]{1}[0-9]{9}"
              className="col-span-2 p-2 rounded bg-gray-100 focus:outline-none "
              id="alt_mobile"
              placeholder="10 Digits"
              value={customer?.alt_mobile}
              onChange={(e) =>
                setCustomer((o) => ({
                  ...o!,
                  alt_mobile: e.target.value.trim(),
                }))
              }
              title="Please enter 10 digit mobile number"
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 items-center">
            <label htmlFor="email">Email</label>
            <input
              type="text"
              className="col-span-2 p-2 rounded bg-gray-100 focus:outline-none"
              id="email"
              placeholder="Valid email address"
              value={customer?.email}
              onChange={(e) =>
                setCustomer((o) => ({ ...o!, email: e.target.value.trim() }))
              }
            />
          </div>
          {(order.type === OrderType.B2B ||
            order.type === OrderType.PARTNER) && (
            <div className="grid grid-cols-1 md:grid-cols-3 items-center">
              <label htmlFor="poc">
                Contact person <span className="text-red-400">*</span>
              </label>
              <input
                id="poc"
                className="col-span-2 p-2 rounded bg-gray-100 focus:outline-none"
                type="text"
                name="poc"
                required
                placeholder="Contact person name"
                value={customer?.poc}
                onChange={(e) =>
                  setCustomer((o) => ({ ...o!, poc: e.target.value }))
                }
              />
            </div>
          )}
          <div className="grid grid-cols-1 md:grid-cols-3 items-center">
            <label htmlFor="gst">
              GST number <span className="text-red-400">*</span>
            </label>
            <input
              id="gst"
              className="col-span-2 p-2 rounded bg-gray-100 focus:outline-none"
              type="text"
              name="gst"
              // required={
              //   order.type === OrderType.B2B || order.type === OrderType.PARTNER
              // }
              value={customer.gst}
              onChange={(e) =>
                setCustomer((o) => ({ ...o!, gst: e.target.value.trim() }))
              }
              placeholder="GST number"
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 items-center">
            <label htmlFor="ca">CA number</label>
            <input
              id="ca"
              className="col-span-2 p-2 rounded bg-gray-100 focus:outline-none"
              type="text"
              name="ca"
              value={customer.ca}
              onChange={(e) =>
                setCustomer((o) => ({ ...o!, ca: e.target.value.trim() }))
              }
              placeholder="CA number"
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <h3 className="text-sm font-semibold">Address</h3>
          <div className="flex">
            <h3 className="text-sm  ">Type</h3>
            {Object.values(AddressType).map((type, i) => {
              return (
                <div className="mx-3" key={i}>
                  <input
                    onChange={(e) => {
                      setCustomer((o) => ({
                        ...o!,
                        address: { ...o?.address, type: type },
                      }));
                    }}
                    className="mx-2"
                    type="radio"
                    checked={customer.address.type === type}
                    name="type"
                    required
                    id={`${type}`}
                  />
                  <label className=" font-semibold" htmlFor={`${type}`}>
                    {type}
                  </label>
                </div>
              );
            })}
          </div>
          <div className="flex    flex-col col-span-2">
            <label htmlFor="address-picker">Address</label>
            <div
              onClick={() => {
                showModal({
                  type: ModalType.drawer,
                  title: "Address Picker",
                  alignment: ModalAlignment.right,
                  container(id) {
                    return (
                      <div className="  bg-gray-100 p-1 h-full w-full">
                        <AddressPickerWidget
                          data={customer.address}
                          location_required={false}
                          onSubmit={function (_address: AddressModel): void {
                            setCustomer((o) => ({
                              ...o!,
                              address: { ...o!.address, ..._address },
                            }));
                            onClose(id);
                          }}
                          onCancel={function (): void {
                            onClose(id);
                          }}
                        />
                      </div>
                    );
                  },
                });
              }}
              className="border rounded px-2 p-1 focus:outline-none  h-32 w-96"
            >
              {customer.address.full_address}
            </div>
          </div>
          {/* <div className="grid grid-cols-1 md:grid-cols-4 items-center">
            <label htmlFor="line_1">
              Line 1 <span className="text-red-400">*</span>
            </label>
            <input
              required
              type="text"
              className="col-span-3 p-2 rounded bg-gray-100 focus:outline-none"
              id="line_1"
              value={customer?.address.line1}
              placeholder="Line 1"
              onChange={(e) =>
                setCustomer((o) => ({
                  ...o!,
                  address: { ...o?.address, line1: e.target.value },
                }))
              }
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-4 items-center">
            <label htmlFor="line_2">Line 2</label>
            <input
              type="text"
              className="col-span-3 p-2 rounded  bg-gray-100 focus:outline-none"
              id="line_2"
              placeholder="Line 2"
              value={customer?.address.line2}
              onChange={(e) =>
                setCustomer((o) => ({
                  ...o!,
                  address: { ...o?.address, line2: e.target.value },
                }))
              }
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-4 items-center">
            <label htmlFor="city">
              City <span className="text-red-400">*</span>
            </label>
            <input
              required
              disabled
              type="text"
              className="col-span-3 p-2 rounded bg-gray-100 focus:outline-none "
              id="city"
              placeholder="City"
              value={customer?.address.city}
              onChange={(e) =>
                setCustomer((o) => ({
                  ...o!,
                  address: { ...o?.address, city: e.target.value },
                }))
              }
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-4 items-center">
            <label htmlFor="state">
              State <span className="text-red-400">*</span>
            </label>
            <input
              disabled
              type="text"
              className="col-span-3 p-2 rounded bg-gray-100 focus:outline-none"
              id="state"
              placeholder="State"
              value={customer?.address.state}
              onChange={(e) =>
                setCustomer((o) => ({
                  ...o!,
                  address: { ...o?.address, state: e.target.value },
                }))
              }
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-4 items-center">
            <label htmlFor="pincode">
              Pincode <span className="text-red-400">*</span>
            </label>
            <div className="flex gap-2 col-span-3  items-center">
              <input
                type="text"
                className="p-2 rounded bg-gray-100 focus:outline-none"
                id="pincode"
                placeholder="Postal code"
                pattern="[0-9]{6}"
                title="Please enter a 6 digit pincode"
                value={customer.address.pincode}
                onChange={(e) => {
                  let v = 0;

                  if (e.target.value && !isNaN(e.target.value as any)) {
                    v = Number(e.target.value);
                  }

                  if (e.target.value.length <= 6)
                    setCustomer((o) => ({
                      ...o!,
                      address: { ...o?.address, pincode: v },
                    }));
                  if (e.target.value.length === 6) {
                    getCityState(v);
                  }
                }}
              />
              <div
                className="p-2 cursor-pointer bg-gray-200 rounded-full"
                onClick={() => {
                  if (customer.address.pincode?.toString().length === 6) {
                    getCityState(customer.address.pincode);
                  } else {
                    alert("Please enter 6 digits postal code");
                  }
                }}
              >
                <Icon path={mdiMagnify} className="w-5 h-5"></Icon>
              </div>
            </div>
          </div> */}
        </div>
        <div className="col-span-2 flex justify-center mt-5">
          <button className="bg-myPrimaryColor font-semibold text-sm text-white rounded-3xl px-3 py-1 hover:scale-110 transform duration-300">
            Submit
          </button>
        </div>
      </form>
      <LoadingWidget loading={loading} />
    </>
  );
};

export default AddBillingProfileWidget;
