import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import "../../styles/index.css";

interface ProfileCardProps {
  data: { [key: string]: any };
}

const ProfileCard: FunctionComponent<ProfileCardProps> = ({ data }) => {
  return (
    <Link to={data?.link} className="w-3/5">
      <div className="w-full  h-48 lg:h-56 rounded-xl cursor-pointer duration-300 bg-white dark:bg-hrmCardDark ">
        <div className="h-1/2 flex flex-col items-center justify-center">
          <img
            src={`/images/icons/${data?.logo}.png`}
            alt=""
            className="w-8 h-8 lg:w-12  lg:h-12"
          />
        </div>
        <div className="h-1/2 flex  flex-col items-center pt-2">
          <div className="text-xl font-bold dark:text-white">
            {data.department}
          </div>
          <div className="text-secondaryColor dark:text-primaryColor lg:text-xs lg:text-sm">
            {data.count}{" "}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ProfileCard;
