import { FunctionComponent } from "react";
import "./index.css";

interface ContentPanelHomeProps {}

const ContentPanelHome: FunctionComponent<ContentPanelHomeProps> = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "75vh" }}
    >
      <h3>
        Welcome to <strong className="label">KAZAM!</strong>
      </h3>
    </div>
  );
};

export default ContentPanelHome;
