import { mdiRefresh } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import LoadingWidget from "../../../../../context_providers/modal/loader";
import { useModal } from "../../../../../context_providers/modal/modal_context";
import {
  useToast,
  ToastType,
} from "../../../../../context_providers/toast/toast_ctx";
import { FieldType } from "../../../../../procurement/components/create_view/form_field";
import TableView from "../../../../../procurement/components/table_comp";
import { fetchGet, toQueryString } from "../../../../../service/api_client";
import SearchFieldWidget from "../../../../../ui/new_components/search_field";
import { urls } from "../../../../../utils/urls";
import { LedgerModel } from "../../../models/ledger_model";
import { PurchaseVoucherModel } from "../../../models/purchase_model";
import { LedgerGroupModel } from "../../../models/ledger_group_model";
// interface OsPayableLedgers {
//   name: string;
//   group: LedgerGroupModel;
//   opening_balance: {
//     date: Date;
//     txn_type: string;
//     amount: number;
//   };
//   description: string;
//   created_by: string;
//   created_by_name: string;
//   id: string;
//   created_at: string;
//   updated_at: string;
//   debit: number;
//   credit: number;
// }

interface OutstandingLedgerModel extends LedgerModel {
  debit: number;
  credit: number;
}
interface OutstandingPayableLedgerListScreenProps {}

const OutstandingPayableLedgerListScreen: FunctionComponent<
  OutstandingPayableLedgerListScreenProps
> = () => {
  const navaigation = useHistory();
  // const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/accounts/voucher/payment/outstanding`;
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/accounts/outstanding/payables`;
  const { showModal, onClose } = useModal();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(50);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<OutstandingLedgerModel[]>([]);
  const [search, setSearch] = useState("");
  const fetchData = async (prop: {
    search?: string;
    page: number;
    count: number;
    all?: boolean;
  }) => {
    setLoading(true);
    try {
      const res = await fetchGet(url + toQueryString(prop));
      if (res.success) {
        setData(res.data.data);
        if (res.data.metadata) {
          setTotal(res.data.metadata.total);
        }
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
      //   return { success: false, error: error.message };
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData({ page, count, search });
  }, [page]);
  useEffect(() => {
    if (search) fetchData({ page, count, search });
  }, [search]);
  // return <LedgerCreateScreen />;

  const onSelect = (d: OutstandingLedgerModel) => {
    navaigation.push(urls.outstanding_payable + "/" + d.id);
  };
  return (
    <>
      <div
        id="page_container"
        className="flex flex-col justify-between h-full p-1"
      >
        <section
          id="top_section"
          className="flex justify-between items-end my-1 "
        >
          <h1 className="font-bold text-2xl text-gray-500 flex items-center gap-3">
            <p className="border-l-4 border-myPrimaryColor pl-2">
              Outstanding payables
            </p>
            <button
              title="Refersh"
              onClick={() => fetchData({ page, count, search })}
            >
              <Icon path={mdiRefresh} className="h-6 w-6"></Icon>
            </button>
          </h1>
          <div className="flex justify-end   text-sm items-center gap-1 ">
            <SearchFieldWidget
              defaulValue={search}
              onClear={() => {
                setSearch("");
                fetchData({ page: 1, count });
              }}
              onSubmit={(val) => {
                fetchData({ page: 1, count, search: val });
                setPage(1);
                setSearch("");

                // setTimeout(() => {
                //   setSearch(val);
                // }, 10);
              }}
            />
            {/* <div
              onClick={() => {
                showModal({
                  title: `Add Ledger`,
                  type: ModalType.modal,
                  container(id) {
                    return (
                      <LedgerCreateScreen
                        onClose={(d) => {
                          if (d)
                            setData((o) => {
                              o.pop();
                              return [d, ...o];
                            });
                          setTotal((o) => o + 1);
                          onClose(id);
                        }}
                      />
                    );
                  },
                });
              }}
              className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
            >
              <Icon path={mdiPlus} className="h-4 w-4"></Icon>Add
            </div> */}
          </div>
        </section>{" "}
        <TableView
          headers={[
            {
              colspan: 4,
              key: "party",
              title: "Party",
              type: FieldType.long_string,
            },

            {
              key: "amount",
              title: "Amount",
              type: FieldType.currency,
            },
            // {
            //   key: "paid",
            //   title: "Paid",
            //   type: FieldType.currency,
            // },
            // {
            //   key: "bal",
            //   title: "Balance",
            //   type: FieldType.currency,
            // },
            // {
            //   key: "due_date",
            //   title: "Due date",
            //   type: FieldType.date,
            // },
          ]}
          rows={data.map((data, i) => ({
            data: data,
            values: [data.name, data.credit - data.debit],

            onClick: onSelect,
          }))}
          onClick={onSelect}
          page={page}
          total={total}
          count={count}
          itemCount={data.length}
          onPageChange={setPage}
        />
      </div>

      {loading && <LoadingWidget></LoadingWidget>}
    </>
  );
};

export default OutstandingPayableLedgerListScreen;
