import { FunctionComponent, useEffect, useState } from "react";
import { CheckCircle, X } from "react-feather";
import { NavLink, useHistory } from "react-router-dom";
import { useAuth } from "../../../../context_providers/auth/authProvider";
import { AcademyVideoModel } from "../../../../service/models/academy";
import { UploadVideo } from "../../../../service/repos/academy/academy_repo";
import { academyVideoCategory, ACTION, MODULE } from "../../../../utils/enums";
import { urls } from "../../../../utils/urls";
import HrmLoader from "../../hrm/components/loading";

interface UploadAcademyVideoProps {}

const UploadAcademyVideo: FunctionComponent<UploadAcademyVideoProps> = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const { user, isAuthorised } = useAuth();
  const history = useHistory();
  const [uploadData, setUploadData] = useState<AcademyVideoModel>({
    title: "",
    description: "",
    url: "",
    category: academyVideoCategory.General,
  });

  const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    e.preventDefault();
    const res = await UploadVideo(uploadData);
    if (res.success) {
      setLoading(false);
      setShowSuccess(true);
    }
  };

  useEffect(() => {
    if (
      !isAuthorised({
        module: MODULE.academy,
        action: [ACTION.UPDATE],
      })
    ) {
      history.push(urls.kazam_academy);
    }
  }, []);

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-hrmBgLight relative">
      <div className="absolute top-4 right-6 lg:right-10">
        <NavLink to={urls.kazam_academy}>
          <X className="text-black transform scale-100 lg:scale-150" />
        </NavLink>
      </div>
      {showSuccess ? (
        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center gap-4">
            <div className="text-2xl italic">Successfully uploaded</div>
            <div className="">
              <CheckCircle className="text-hrmPurple" size={32} />
            </div>
          </div>
          <div className="mt-2">
            <NavLink to={urls.kazam_academy}>
              <button className="py-2 px-6 text-white bg-hrmPurple rounded">
                Back home
              </button>
            </NavLink>
          </div>
        </div>
      ) : (
        <div className="w-11/12 lg:w-2/3 py-12 bg-white flex flex-col items-center justify-center rounded-lg">
          <div className="w-11/12">
            <div className="text-2xl font-semibold">Upload video</div>
            <form action="" className="w-full mt-6" onSubmit={submitForm}>
              <div className="w-full mt-12">
                <label htmlFor="image">
                  Video link
                  <input
                    type="text"
                    className="py-2 px-4 mt-2 w-full border border-gray-300 rounded-md focus:outline-none"
                    required
                    onChange={(e) =>
                      setUploadData((d) => ({ ...d, url: e.target.value }))
                    }
                  />
                </label>
              </div>
              <div className="w-full mt-12">
                <label htmlFor="image">
                  Title
                  <input
                    type="text"
                    required
                    className="py-2 px-4 mt-2 w-full border border-gray-300 rounded-md focus:outline-none"
                    onChange={(e) =>
                      setUploadData((d) => ({ ...d, title: e.target.value }))
                    }
                  />
                </label>
              </div>{" "}
              <div className="w-full mt-12">
                <label htmlFor="image">
                  Description
                  <input
                    type="text"
                    // required
                    className="py-2 px-4 mt-2 w-full border border-gray-300 rounded-md focus:outline-none"
                    onChange={(e) =>
                      setUploadData((d) => ({
                        ...d,
                        description: e.target.value,
                      }))
                    }
                  />
                </label>
              </div>
              <div className="w-full mt-12">
                <label htmlFor="image">
                  Category
                  <select
                    name=""
                    id=""
                    className="py-2 px-4 mt-2 w-full border border-gray-300 rounded-md bg-transparent focus:outline-none cursor-pointer"
                    required
                    onChange={(e) =>
                      setUploadData((d) => ({
                        ...d,
                        category: e.target.value,
                      }))
                    }
                  >
                    {Object.values(academyVideoCategory).map((el) => {
                      return <option value={el}>{el}</option>;
                    })}
                  </select>
                </label>
              </div>
              <div className="w-full mt-12">
                <label htmlFor="image">
                  In this video(optional)
                  <input
                    type="text"
                    className="py-2 px-4 mt-2 w-full border border-gray-300 rounded-md focus:outline-none"
                    onChange={(e) =>
                      setUploadData((d) => ({
                        ...d,
                        in_video: e.target.value,
                      }))
                    }
                  />
                </label>
              </div>
              <div className="flex flex-col items-center mt-4">
                {loading ? (
                  <div className="flex flex-col items-center">
                    <HrmLoader className="w-8 h-8" />
                  </div>
                ) : (
                  <button className="py-3 px-12 rounded bg-hrmPurple text-white font-semibold">
                    Upload video
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadAcademyVideo;
