import axios from "axios";

import React, { useContext, useState, useEffect, createContext } from "react";
import { useGoogleLogin, useGoogleLogout } from "react-google-login";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { fetchGet, getHeaders } from "../../service/api_client";
import {
  GetAuthDataApi,
  GoogleLoginApi,
  LogoutApi,
} from "../../service/repos/auth_repo";
import { ACTION } from "../../utils/enums";
import { urls } from "../../utils/urls";
import LoadingWidget from "../modal/loader";
import { ToastType, useToast } from "../toast/toast_ctx";
import { OpsTaskModel } from "../../service/models/ops/ops_ticket";
import { GetOpsTaskApi } from "../../service/repos/ops/ops_tickets_repo";

interface opsTaskCtx {
  data?: OpsTaskModel;
  setData: React.Dispatch<React.SetStateAction<OpsTaskModel | undefined>>;
}

const defaultValue: opsTaskCtx = { setData: () => {} };

const OpsTaskContext = createContext<opsTaskCtx>(defaultValue);

export function useOpsTask() {
  return useContext(OpsTaskContext);
}

export function OpsTaskProvider({ children }: { children: any }) {
  const { id } = useParams<{ id: string }>();
  const { showToast } = useToast();
  const [data, setData] = useState<OpsTaskModel>();
  // const { setLoading } = useModal();
  const [loading, setLoading] = useState(true);
  const getData = async (task_id: string) => {
    setLoading(true);
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/new-ops/task/my-tasks/${task_id}`;
    const res = await fetchGet(url);
    if (res.success) {
      setData({ ...res.data });
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  useEffect(() => {
    if (id) {
      getData(id);
    }
  }, []);
  const value = {
    data,
    setData,
  };

  return (
    <OpsTaskContext.Provider value={value}>
      {!loading ? children : <LoadingWidget />}
    </OpsTaskContext.Provider>
  );
}
