import { mdiChevronDown, mdiChevronUp, mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useState } from "react";
import { useOrderCreateCtx } from "../../../../context_providers/order_create_context";
import { currencyFormat } from "../../../../utils/orders_utils";
import {
  GetDiscountAmt,
  GetExcessAmt,
  GetPaidAmt,
  GetSubTotalAmt,
} from "../../../../utils/order_util";

interface BottomSheetWidgetProps {}

const BottomSheetWidget: FunctionComponent<BottomSheetWidgetProps> = () => {
  const { order, onCreateOrder, useBal, balTxnAmt, billing, reset } =
    useOrderCreateCtx();
  const [expanded, setExpanded] = useState(false);
  // const subtotal = GetSubTotalAmt(order.items);
  // const extra = GetExcessAmt(order.excessData);
  // const discount = GetDiscountAmt(order.discountData);
  console.log({ order });

  const total = order.subtotal + order.excess - order.discount;
  const balance = total - (order.paid + (useBal ? balTxnAmt : 0));
  // const paid = GetPaidAmt(order.transactionData);
  const disable = billing === undefined || order.items.length === 0;
  return (
    <div className="">
      {expanded && (
        <>
          <div className="flex justify-between items-center mb-2">
            <p className="font-semibold  italic">Order Summery</p>
            <div
              onClick={() => setExpanded(false)}
              className="hover:scale-110 duration-200 transform bg-gray-100 p-1 rounded-full hover:text-myPrimaryColor"
            >
              <Icon path={mdiClose} className="h-4 w-4"></Icon>
            </div>
          </div>
          <div className="grid grid-cols-8 gap-1 text-sm">
            <div className="col-span-2">
              <div className="flex justify-between w-full ">
                <div className="font-semibold"> Subtotal</div>
                <div className="">{currencyFormat(order.subtotal)}</div>
              </div>
              <div className="flex justify-between w-full ">
                <div className="font-semibold">Extra</div>
                <div className="">{currencyFormat(order.excess)}</div>
              </div>
              <div className="flex justify-between w-full ">
                <div className="font-semibold">Discount</div>
                <div className="">{currencyFormat(order.discount)}</div>
              </div>
              <div className="flex justify-between w-full  my-1">
                <div className="font-semibold">Total</div>
                <div className="font-semibold">{currencyFormat(total)}</div>
              </div>{" "}
              {/* <div className="flex justify-between w-full  whitespace-nowrap italic text-green-500">
                <div className="font-semibold">B/d Balance</div>
                <div className="">{rupeeFormat(balTxnAmt)}</div>
              </div> */}
              <div className="flex justify-between w-full  italic text-green-500">
                <div className="font-semibold">Paid</div>
                <div className="">{currencyFormat(order.paid)}</div>
              </div>
            </div>
          </div>
          <hr className="my-2" />
        </>
      )}
      <div className="flex justify-between mt-2">
        <div className="flex gap-2 items-center">
          <p className="font-semibold">Bal : {currencyFormat(balance)}</p>
          <span
            onClick={() => setExpanded((o) => !o)}
            className="hover:bg-gray-100 rounded-full p-1 hover:scale-110 transform duration-200"
          >
            <Icon
              path={expanded ? mdiChevronDown : mdiChevronUp}
              className="h-5 w-5"
            ></Icon>
          </span>
        </div>
        <div className="flex gap-5 text-sm">
          <button
            onClick={() => {
              if (window.confirm("Are you sure? it cannot be undo.")) {
                reset();
              }
            }}
            className="text-myPrimaryColor"
          >
            Clear
          </button>
          <button
            disabled={disable}
            onClick={() => {
              if (window.confirm("Are you sure to create order?"))
                onCreateOrder();
            }}
            className={`${
              disable ? "bg-gray-400 cursor-not-allowed" : "bg-myPrimaryColor"
            } rounded-md px-3 py-1 text-white`}
          >
            Create Order
          </button>
        </div>
      </div>
    </div>
  );
};

export default BottomSheetWidget;
