import { FunctionComponent, useEffect, useState } from "react";
import Chart from "react-apexcharts";
interface AdminTaskGraphWidgetProps {
  series: { name: string; data: number[] }[];
  xaxis: { categories: string[] };
}

const AdminTaskGraphWidget: FunctionComponent<AdminTaskGraphWidgetProps> = (
  props
) => {
  console.log({ props });

  const [state, setState] = useState({
    series: props.series,
    options: {
      xaxis: props.xaxis,
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 3,
        curve: "smooth",
        // dashArray: [0, 8, 5],
        dashArray: 0,
      },
      title: {
        text: "Task Statistics",
        align: "left",
      },
      legend: {
        tooltipHoverFormatter: function (val: any, opts: any) {
          return val;
          return (
            val +
            " - " +
            opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
            ""
          );
        },
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },

      tooltip: {
        y: [
          {
            title: {
              formatter: function (val: any) {
                return val;
              },
            },
          },
          // {
          //   title: {
          //     formatter: function (val: any) {
          //       return val + " per session";
          //     },
          //   },
          // },
          // {
          //   title: {
          //     formatter: function (val: any) {
          //       return val;
          //     },
          //   },
          // },
        ],
      },
      grid: {
        borderColor: "#f1f1f1",
      },
    },
  });
  useEffect(() => {
    setState((o) => ({
      ...o,
      series: props.series,
      options: { ...o.options, xaxis: props.xaxis },
    }));
  }, []);
  console.log(state);

  return (
    <div className="app my-8 p-3 bg-white ">
      <div className="row">
        <div className="mixed-chart">
          <Chart
            options={state.options as any}
            series={state.series}
            type="line"
            height={400}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminTaskGraphWidget;
