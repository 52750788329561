import { mdiCheck } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { useOpsTask } from "../../../../../../context_providers/ops_task_engineer_detail/ops_task_engineer_detail";
import { useTaskSession } from "../../../../../../context_providers/task_session_ctx";
import {
  useToast,
  ToastType,
} from "../../../../../../context_providers/toast/toast_ctx";
import {
  OpsTaskDeviceItem,
  OpsTaskDeviceItemStatus,
} from "../../../../../../service/models/ops/ops_ticket";
import { urls } from "../../../../../../utils/urls";
import LoadingWidget from "../../../../../components/loading_spinner";
import { fetchPut } from "../../../../content_panel/services/serviceClient";
import DeviceComponentRow from "../../../components/device_component_row";
import DeviceItemCommonDetailSection from "./device_item_detail_common";

interface DeviceItemDetailSectionProps {}

const DeviceItemDetailSection: FunctionComponent<
  DeviceItemDetailSectionProps
> = () => {
  const { onDuty } = useTaskSession();
  const history = useHistory();
  const { showToast } = useToast();
  const { device_id } = useParams<{ device_id: string }>();
  const { data, setData } = useOpsTask();
  const [loading, setLoading] = useState(false);
  const [showTestTxn, setShowTextTxn] = useState(true);
  const [deviceItem, setDeviceItem] = useState<OpsTaskDeviceItem>();
  const updateDeviceItem = async (item: OpsTaskDeviceItem) => {
    if (!window.confirm("Are you sure ?")) return;
    if (deviceItem?.txn_test_done)
      item.status = OpsTaskDeviceItemStatus.completed;

    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/new-ops/task/my-tasks/${data?.id}/device_item/${item.device_id}`;
    const res = await fetchPut(url, item);
    if (res.success) {
      setDeviceItem(res.data);
      setData((o) => ({
        ...o!,
        device_items: [...(o?.device_items || [])].map((f) => {
          if (f.device_id == res.data.device_id) return res.data;
          return f;
        }),
      }));
      showToast({
        type: ToastType.success,
        text: res.messsage ?? "Completed",
      });
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    // setLoading(false);
  };
  useEffect(() => {
    data?.device_items?.forEach((e) => {
      if (e.device_id == device_id) {
        setDeviceItem(e);
      }
    });
  }, [data?.device_items]);

  return (
    <>
      <div className="flex flex-col gap-2 items-start h-full w-full pb-10">
        <div className="flex justify-between w-full">
          <button
            onClick={() => {
              history.goBack();
            }}
            className="rounded-3xl border px-5 py-1 bg-blue-50 text-blue-500 border-blue-400 "
          >
            Back
          </button>

          <Link
            // className="uppercase bg-purple-50 text-purple-500 border-purple-300 border rounded-lg   text-center px-3 py-2 w-full font-bold"
            className="rounded-3xl border px-5 py-1 bg-blue-50 text-blue-500 border-blue-400 "
            to={`${urls.operation_engineer_task}/detail/${data?.id}/device/${device_id}/detail/components`}
          >
            Parts
          </Link>
        </div>

        <DeviceItemCommonDetailSection />

        {deviceItem &&
          onDuty &&
          data?.start_date &&
          deviceItem.status == OpsTaskDeviceItemStatus.pending && (
            <div className="fixed bottom-0 right-0 left-0 p-3 bg-white shadow-sm">
              <Link
                to={`${urls.operation_engineer_task}/detail/${data?.id}/device/${device_id}/transaction-test`}
                // onClick={() => updateDeviceItem(deviceItem!)}
              >
                <div className="uppercase bg-purple-50 text-purple-500 border-purple-300 border rounded-lg   text-center px-3 py-2 w-full font-bold">
                  {deviceItem.txn_test_done ? "Re-Test Device" : "Test Device"}
                </div>
              </Link>
            </div>
          )}
      </div>
      {loading && <LoadingWidget loading={true} />}
    </>
  );
};

export default DeviceItemDetailSection;
