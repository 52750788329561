import { FunctionComponent } from "react";
import { ChevronLeft, FileText, Home, Youtube } from "react-feather";
import { NavLink, useHistory } from "react-router-dom";
import { urls } from "../../../../utils/urls";

interface SidebarProps {}

const Sidebar: FunctionComponent<SidebarProps> = () => {
  const history = useHistory();
  return (
    <div className="lg:h-screen fixed bottom-0 lg:top-0 left-0  lg:w-20 bg-black hidden lg:flex lg:flex-col items-center justify-around lg:justify-center text-white py-4 lg:py-0">
      <div
        className="cursor-pointer hidden lg:flex flex-col items-center justify-center border border-gray-300 rounded-full w-8 h-8 absolute top-2 left-3"
        onClick={() => history.goBack()}
      >
        <ChevronLeft size={20} className="text-white" />
      </div>

      <NavLink to={urls.kazam_academy}>
        <div className="flex flex-col items-center cursor-pointer">
          <div className="">
            <Home size={20} />
          </div>
          <div className="text-sm mt-2">Home</div>
        </div>
      </NavLink>
      <NavLink to={urls.protocol_category}>
        <div className="flex flex-col items-center cursor-pointer lg:mt-12">
          <div className="">
            <FileText size={20} />
          </div>
          <div className="text-sm mt-2">Protocols</div>
        </div>
      </NavLink>
      <NavLink to={urls.videos_category}>
        <div className="flex flex-col items-center cursor-pointer lg:mt-12">
          <div className="">
            <Youtube size={20} />
          </div>
          <div className="text-sm mt-2">Vidoes</div>
        </div>
      </NavLink>
    </div>
  );
};

export default Sidebar;
