import { FunctionComponent } from "react";
import { InputFields } from "./form";

export interface SelectProps {
  alignment?: string;

  name: string;
  label: string;
  value: any;
  defaultValue?: any;

  placeholder: string;
  inputChange?: (data: any) => void;
  disabled?: boolean;
  required?: boolean;
  options: { label: string; value: any }[];
}

const Select: FunctionComponent<SelectProps> = (props) => {
  const {
    alignment,
    name,

    label,
    value,
    defaultValue,
    options,
    disabled = false,
    placeholder,
    required = false,
    inputChange,
  } = props;
  return (
    <div
      className={`grid ${alignment === "row" ? "grid-cols-3" : "grid-col-1"}`}
    >
      <label htmlFor={name} className="">
        {label}
      </label>
      <select
        className="focus:outline-none p-2 bg-gray-100 rounded col-span-2"
        disabled={disabled}
        id={name}
        name={name}
        value={value}
        defaultValue={value}
        onChange={(e) => {
          if (inputChange) inputChange(e.target.value);
        }}
        typeof={InputFields.SINGLE_SELECT}
        required={required}
        title={label + " is required"}
      >
        <option selected value="" className="text-gray-500">
          -- {placeholder} --
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};
export default Select;
