import { FunctionComponent, useEffect, useState } from "react";

import Icon from "@mdi/react";
import {
  mdiClose,
  mdiCollage,
  mdiDelete,
  mdiDownload,
  mdiImageBrokenVariant,
  mdiRefresh,
  mdiUpload,
  mdiVideo,
} from "@mdi/js";
import moment from "moment";
import { AttachmentModel } from "../../../../service/models/attachment_modal";

import {
  FileType,
  attachement_belong,
  MODULE,
  ACTION,
} from "../../../../utils/enums";
import PdfTile from "../../../components/common/pdf_tile";
import LoadingWidget from "../../../components/loading_spinner";
import ModalDialog from "../../../new_components/common/modal";
import FileUploadWidget from "../../../new_components/common/file_upload";
import {
  DeleteOpsTaskAttachmentsApi,
  GetOpsTaskAttachmentsApi,
} from "../../../../service/repos/ops/ops_tickets_repo";
import {
  useToast,
  ToastType,
} from "../../../../context_providers/toast/toast_ctx";
import { useAuth } from "../../../../context_providers/auth/authProvider";
import { Refresh } from "@material-ui/icons";
export const AttachmentCategory = [
  "Installation Photos",
  "Customer Review video",
  "Other",
];
interface TaskAttachmentWidgetProps {
  task_id: any;
  attachments: {
    id: number;
    task_id: number;
    type: string;
    url: string;
    created_by: number;
    created_by_name: string;
    category: string;
    deleted?: boolean | undefined;
    created_at: Date;
    updated_at: Date;
  }[];
  onRefresh: () => void;
}

const TaskAttachmentWidget: FunctionComponent<TaskAttachmentWidgetProps> = ({
  task_id,
  attachments,
  onRefresh,
}) => {
  const { showToast } = useToast();
  const { user, isAuthorised } = useAuth();
  const [category, setCategory] = useState("");
  const [loading, setLoading] = useState(false);
  const [showCollage, setShowCollage] = useState(false);
  const [selectedFile, setSelectedFile] = useState<{
    id: number;
    task_id: number;
    type: string;
    url: string;
    created_by: number;
    created_by_name: string;
    category: string;
    deleted?: boolean | undefined;
    created_at: Date;
    updated_at: Date;
  }>();
  const [selectedAttachments, setSelectedAttachments] = useState<File[]>([]);
  const [showUploadAttachment, setShowUploadAttachement] = useState(false);
  // const [attachments, setAttachments] = useState<AttachmentModel[]>([]);
  // const getAttachments = async () => {
  //   setLoading(true);
  //   const res = await GetOpsTaskAttachmentsApi(task_id);

  //   if (res.success) {
  //     setAttachments(res.data);
  //   } else {
  //     showToast({ type: ToastType.error, text: res.error ?? "" });
  //   }
  //   setLoading(false);
  // };
  const deleteAttachment = async (id: any) => {
    const res = await DeleteOpsTaskAttachmentsApi({ id, task_id });
    if (res.success) {
      showToast({ type: ToastType.success, text: res.message || "Success" });
      onRefresh();
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
  };
  // const uploadAttachments = async (form: FormData) => {
  //   const res = await UploadOrderAttachmentApi(form);
  //   if (res.success) {
  //     setShowUploadAttachement(false);
  //     getAttachments();
  //   } else {
  //     showToast({ type: ToastType.error, text: res.error ?? "" })
  //   }
  // };

  const [downloading, setDownloading] = useState(false);

  const [disabled, setDisabled] = useState(true);
  useEffect(() => {
    setDisabled(
      !isAuthorised({
        module: MODULE.ops_task,
        action: [ACTION.UPDATE],
      })
    );
  }, []);
  return (
    <>
      {selectedFile && (
        <ModalDialog
          title={selectedFile.category}
          show={selectedFile !== undefined}
          onClose={() => setSelectedFile(undefined)}
        >
          <div className="max-h-96">
            {selectedFile.type === FileType.VIDEO ? (
              <video
                className={`rounded-lg w-96 h-auto `}
                id="cmsVideo"
                autoPlay
                muted
                playsInline
                controls
              >
                <source src={selectedFile.url} type="video/mp4" />
              </video>
            ) : selectedFile.type === FileType.IMAGE ? (
              <img src={selectedFile.url} className="w-96 h-auto"></img>
            ) : selectedFile.type === FileType.PDF ? (
              <>
                <embed
                  src={selectedFile.url}
                  type="application/pdf"
                  className="w-96 h-auto"
                ></embed>{" "}
              </>
            ) : (
              <>Unkown format</>
            )}
          </div>
        </ModalDialog>
      )}
      {showUploadAttachment && (
        <ModalDialog
          title="Upload"
          show={showUploadAttachment}
          onClose={() => setShowUploadAttachement(false)}
        >
          <div className="  " style={{ maxHeight: "80vh", minWidth: "320px" }}>
            <div className="my-2">
              <div className=" mb-1 font-semibold text-sm">Category</div>
              <select
                value={category}
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
                name=""
                id=""
                className=" w-full text-xs  px-3 py-1 cursor-pointer bg-gray-100 rounded focus:outline-none"
              >
                <option hidden>Select</option>
                {AttachmentCategory.map((at, i) => (
                  <option value={at}>{at}</option>
                ))}
              </select>
            </div>
            {category && (
              <FileUploadWidget
                onComplete={() => {
                  onRefresh();
                  setShowUploadAttachement(false);
                }}
                belong={attachement_belong.task}
                ref_id={task_id}
                category={category}
              />
            )}
          </div>
        </ModalDialog>
      )}

      <div
        className=" w-full  p-2 md:p-5"
        style={{ height: "80vh", minWidth: "50vw" }}
      >
        <div className="">
          <div className="flex justify-end my-2 items-end text-sm gap-2">
            <button
              className="px-3 py-2 border rounded-lg flex items-center gap-2 bg-blue-50 text-blue-500 border-blue-400"
              onClick={() => onRefresh()}
            >
              <Icon path={mdiRefresh} size="20"></Icon>
              Refresh
            </button>
            <button
              className="px-3 py-2  border rounded-lg flex items-center gap-2 bg-green-50 text-green-500 border-green-400"
              onClick={() => setShowUploadAttachement(true)}
            >
              <Icon path={mdiUpload} size="20"></Icon>
              Upload
            </button>
          </div>

          <div className="flex flex-col gap-2    md:hidden">
            {attachments.map((file, i) => (
              <div
                className=" flex  gap-2 justify-center border rounded items-center  h-auto cursor-pointer"
                key={i}
              >
                <div className="w-28">
                  <div
                    className="flex justify-center items-center   w-28 h-20  bg-gray-200"
                    onClick={() => {
                      setSelectedFile(file);
                    }}
                  >
                    {file.type === FileType.IMAGE ? (
                      <img src={file.url} alt="" className="h-20 w-28  " />
                    ) : file.type === FileType.PDF ? (
                      <PdfTile url={file.url} />
                    ) : file.type === FileType.VIDEO ? (
                      <Icon path={mdiVideo} size="30" />
                    ) : (
                      <Icon path={mdiImageBrokenVariant} size="30" />
                    )}
                  </div>
                </div>
                <div className="text-xs w-28">
                  <p>{file.category}</p>
                  <p>{file.created_by_name}</p>
                  <p> {moment(file.created_at).format("DD MMM YYYY")}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="  hidden md:block">
            <table className=" w-full text-xs mb-5 border rounded-sm ">
              <thead>
                <tr className="border-b">
                  {/* <th></th> */}
                  <th></th>
                  <th>Category</th>
                  <th>Created Date</th>
                  <th>Created by</th>

                  <th></th>
                </tr>
              </thead>
              <tbody>
                {attachments.map((file, i) => (
                  <tr key={i}>
                    {/* <td>
                      <div className="">
                        <input type="checkbox" name="" id="" />
                      </div>
                    </td> */}
                    <td align="center">
                      <div
                        className="flex justify-center items-center rounded-full h-14 w-14 bg-gray-200 cursor-pointer"
                        onClick={() => {
                          console.log(file);
                          if (file.type !== FileType.PDF) setSelectedFile(file);
                        }}
                      >
                        {file.type === FileType.IMAGE ? (
                          <img
                            src={file.url}
                            alt=""
                            className="h-14 w-14 rounded-full"
                          />
                        ) : file.type === FileType.PDF ? (
                          <a target="_blank" href={file.url}>
                            View
                          </a>
                        ) : file.type === FileType.VIDEO ? (
                          <Icon path={mdiVideo} size="30" />
                        ) : (
                          <Icon path={mdiImageBrokenVariant} size="30" />
                        )}
                      </div>
                    </td>
                    <td align="center">{file.category}</td>
                    <td align="center">
                      {moment(file.created_at).format("DD MMM YYYY")}
                    </td>
                    <td align="center">{file.created_by_name}</td>
                    <td>
                      <div className="flex gap-2">
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            var link = document.createElement("a");
                            link.setAttribute("href", file.url);
                            link.setAttribute("target", "_blank");
                            link.setAttribute(
                              "download",
                              task_id + "-" + file.url.split("/").pop()
                            );
                            document.body.appendChild(link);

                            link.click();
                            document.body.removeChild(link);
                          }}
                          // href={file.url}
                          // download={order_ref + "-" + file.url.split("/").pop()}
                        >
                          <Icon path={mdiDownload} size="20" />
                        </div>
                        {!disabled && (
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              if (window.confirm("Are you sure to delete ? ")) {
                                deleteAttachment(file.id!);
                              }
                            }}
                          >
                            <Icon path={mdiDelete} size="20" />
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <LoadingWidget loading={loading} />
      </div>
    </>
  );
};

export default TaskAttachmentWidget;
