import axios from "axios";
import {
  fetchGet,
  fetchDelete,
  getHeaders,
  fetchPost,
  fetchPut,
} from "./serviceClient";

export const uploadMagazineRepo = async (data: any) => {
  try {
    const res = await fetchPost(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/magazine`,
      data
    );
    return res;
  } catch (error) {
    console.log(error);
  }
  // return await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/installation/admin`, {
  //   method: "POST",
  //   headers: getHeaders(),
  //   body: data,
  // })
  //   .then(async (resp) => {
  //     return await resp.json();
  //   })
  //   .catch((err) => console.log(err));
};

export const getMagazinesRepo = async (data: {
  count: number;
  page: number;
}) => {
  const { count, page } = data;
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/magazine?count=${count}&page=${page}`;
  const res = await fetchGet(url);
  return res;

  // return {success:true,data:[{url:"https://source.unsplash.com/random",place:"bangalore",active:true},{url:"https://source.unsplash.com/random",place:"bangalore",active:true},{url:"https://source.unsplash.com/random",place:"bangalore",active:true},{url:"https://source.unsplash.com/random",place:"bangalore",active:true},{url:"https://source.unsplash.com/random",place:"bangalore",active:true}]};
};

export const updateMagazineRepo = async (body: any) => {
  try {
    const res = await fetchPut(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/magazine`,
      body
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const updateMagazineStatusRepo = async (body: any) => {
  try {
    const res = await fetchPut(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/magazine/visiblility`,
      body
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const deleteMagazineRepo = async (id: any) => {
  try {
    const res = await fetchDelete(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/magazine/${id}`
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};
