import { FunctionComponent, useEffect, useState } from "react";
import { useOpsTask } from "../../../../../../context_providers/ops_task_engineer_detail/ops_task_engineer_detail";
import { useTaskSession } from "../../../../../../context_providers/task_session_ctx";
import {
  useToast,
  ToastType,
} from "../../../../../../context_providers/toast/toast_ctx";
import { fetchPut } from "../../../../content_panel/services/serviceClient";
import { Ops_task_status, TaskType } from "../../../../../../utils/enums";
import RemarkWidget from "./remark_widget";
import { useHistory, useParams } from "react-router-dom";
import {
  OpsTaskDeviceItem,
  OpsTaskDeviceItemResolution,
  OpsTaskDeviceItemStatus,
} from "../../../../../../service/models/ops/ops_ticket";
import { OpsTaskDeviceIssueType } from "../../../../../../utils/constant";

interface DeviceItemCommonDetailSectionProps {}

const DeviceItemCommonDetailSection: FunctionComponent<
  DeviceItemCommonDetailSectionProps
> = () => {
  const { onDuty } = useTaskSession();
  const history = useHistory();
  const { showToast } = useToast();
  const { device_id } = useParams<{ device_id: string }>();
  const { data, setData } = useOpsTask();
  const [loading, setLoading] = useState(false);
  const [showTestTxn, setShowTextTxn] = useState(true);
  const [deviceItem, setDeviceItem] = useState<OpsTaskDeviceItem>();
  const [edit, setEdit] = useState(false);
  const updateDeviceItem = async (item: OpsTaskDeviceItem) => {
    if (!window.confirm("Are you sure ?")) return;
    if (deviceItem?.txn_test_done)
      item.status = OpsTaskDeviceItemStatus.completed;

    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/new-ops/task/my-tasks/${data?.id}/device_item/${item.device_id}`;
    const res = await fetchPut(url, item);
    if (res.success) {
      setDeviceItem(res.data);
      setData((o) => ({
        ...o!,
        device_items: [...(o?.device_items || [])].map((f) => {
          if (f.device_id == res.data.device_id) return res.data;
          return f;
        }),
      }));
      showToast({
        type: ToastType.success,
        text: res.messsage ?? "Completed",
      });
      setEdit(false);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    // setLoading(false);
  };
  useEffect(() => {
    data?.device_items?.forEach((e) => {
      if (e.device_id == device_id) {
        setDeviceItem(e);
      }
    });
  }, [data?.device_items]);

  return (
    <div className="flex flex-col gap-2 w-full">
      <div className="flex flex-col">
        <div className="flex justify-between items-end">
          <div className=" ">
            <p>Device ID </p>
          </div>
          <p
            className={`font-semibold ${
              deviceItem?.status == OpsTaskDeviceItemStatus.pending
                ? "text-yellow-600"
                : deviceItem?.status == OpsTaskDeviceItemStatus.completed
                ? "text-green-600"
                : "text-red-500"
            }`}
          >
            {deviceItem?.status}
          </p>
        </div>
        <div className="p-3 bg-white rounded-lg border w-full flex justify-between items-center ">
          {deviceItem?.device_id}{" "}
          {deviceItem?.txn_test_done && (
            <div className="text-green-500 italic text-xs">
              Charger state verified
            </div>
          )}
        </div>
      </div>
      {[TaskType.complaint, TaskType.service].includes(data?.type!) && (
        <>
          {" "}
          <div className="flex flex-col">
            <div className=" ">
              <p>Issue Category </p>
            </div>
            <div className="p-3 bg-white rounded-lg border w-full  ">
              <select
                disabled={!edit}
                value={deviceItem?.issue_type}
                onChange={(e) => {
                  setDeviceItem((o) => ({
                    ...o!,
                    issue_type: e.target.value,
                  }));
                }}
                name=""
                id=""
                className="w-full focus:outline-none bg-transparent"
              >
                {" "}
                <option value="" hidden>
                  Select
                </option>
                {Object.values(OpsTaskDeviceIssueType).map((f) => (
                  <option value={f} key={f}>
                    {f}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex flex-col">
            <div className=" ">
              <p>Resolution </p>
            </div>
            <div className="p-3 bg-white rounded-lg border w-full  ">
              <select
                disabled={!edit}
                value={deviceItem?.resolution}
                onChange={(e) => {
                  setDeviceItem((o) => ({
                    ...o!,
                    resolution: e.target.value,
                  }));
                }}
                name=""
                id=""
                className="w-full focus:outline-none bg-transparent"
              >
                {" "}
                <option value="" hidden>
                  Select
                </option>
                {Object.values(OpsTaskDeviceItemResolution).map((f) => (
                  <option value={f} key={f}>
                    {f}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </>
      )}
      <div className="flex flex-col my-2">
        <label htmlFor="time-taken">Time Taken (Minutes)</label>
        <input
          disabled={!edit}
          id="time-taken"
          placeholder="Click here"
          className="w-full focus:outline-none border rounded-lg p-2 bg-white"
          type="number"
          value={deviceItem?.time_taken}
          onChange={(e) => {
            const time_taken = Number(e.target.value);
            setDeviceItem((o) => ({
              ...o!,
              time_taken,
            }));
          }}
        ></input>
      </div>
      <div className="flex flex-col">
        <div className=" ">
          <p>Remarks </p>
        </div>
        <div className="p-3 bg-white rounded-lg border w-full  ">
          <textarea
            disabled={!edit}
            value={deviceItem?.remark}
            onChange={(e) => {
              setDeviceItem((o) => ({
                ...o!,
                remark: e.target.value,
              }));
            }}
            placeholder="Short note"
            name=""
            id=""
            className="w-full focus:outline-none bg-transparent"
          >
            {" "}
          </textarea>
        </div>
      </div>
      {deviceItem && edit ? (
        <div className="grid grid-cols-2 gap-5 my-2">
          <button
            onClick={() => setEdit(false)}
            className="bg-blue-50 text-blue-500 border-blue-300 border rounded-lg px-3 py-1 text-center"
          >
            Cancel
          </button>

          <button
            onClick={() => updateDeviceItem(deviceItem)}
            className="bg-green-50 text-green-500 border-green-300 border rounded-lg px-3 py-1 text-center"
          >
            Update
          </button>
        </div>
      ) : (
        onDuty &&
        data?.start_date &&
        !data.end_date &&
        data.status == Ops_task_status.under_progress && (
          <button
            onClick={() => setEdit(true)}
            className="bg-blue-50 text-blue-500 border-blue-300 border rounded-lg px-3 py-1 text-center w-full"
          >
            Edit
          </button>
        )
      )}
    </div>
  );
};

export default DeviceItemCommonDetailSection;
