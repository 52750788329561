import { mdiCheck, mdiClose, mdiDelete, mdiPencil, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useState } from "react";
import {
  useToast,
  ToastType,
} from "../../../../../../context_providers/toast/toast_ctx";
import { numberRegEx } from "../../../../../../utils/constant";
import LedgerSearchField from "../../../../../accounts/ledgers/ledger_search_field";
import { LedgerEntryModel } from "../../../../../accounts/models/common_model";
import { account_sub_group } from "../../../../../accounts/models/ledger_group_model";
import {
  LedgerModel,
  LedgerTaxDetail,
  tax_category,
  tax_type,
} from "../../../../../accounts/models/ledger_model";
import { ledgerEntryDefaultValue } from "../../../../../accounts/models/purchase_model";
import { ItemMovementModel } from "../../../../models/inventory_voucher";

export const getItemTaxAmount = ({
  items,
  ledgerTaxDetail,
}: {
  items: ItemMovementModel[];
  ledgerTaxDetail: LedgerTaxDetail;
}) => {
  let amount = 0;

  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (!item.item?.tax_applicable) continue;
    if (ledgerTaxDetail.type === tax_type.gst) {
      if (
        ledgerTaxDetail.category ===
        tax_category[ledgerTaxDetail.type].integreted
      ) {
        amount +=
          item.unit_no *
          item.price *
          ((item.item?.tax_detail.integreted ?? 0) / 100);
      } else if (
        ledgerTaxDetail.category === tax_category[ledgerTaxDetail.type].state
      ) {
        amount +=
          item.unit_no *
          item.price *
          ((item.item?.tax_detail.state ?? 0) / 100);
      } else if (
        ledgerTaxDetail.category === tax_category[ledgerTaxDetail.type].central
      ) {
        amount +=
          item.unit_no *
          item.price *
          ((item.item?.tax_detail.central ?? 0) / 100);
      }
    }
  }

  return Math.round(amount);
};

interface TaxEntryRowProps {
  items: ItemMovementModel[];
  entry?: LedgerEntryModel;
  onSubmit: (data: LedgerEntryModel) => void;
  edit: boolean;
  disabled: boolean;
  onDelete?: (i: LedgerEntryModel) => void;
  existingEntries: LedgerEntryModel[]; // Pass existing entries to check for conflicts
}

const TaxEntryRow: FunctionComponent<TaxEntryRowProps> = (props) => {
  const [data, setData] = useState<LedgerEntryModel>({
    ...(props.entry || ledgerEntryDefaultValue),
  });
  const { showToast } = useToast();
  const { edit, onSubmit, existingEntries } = props;

  const [editable, setEditable] = useState(false);
  const [taxType, setTaxType] = useState<string>(""); // Manage the selected tax type
  const [taxCategory, setTaxCategory] = useState<string>(""); // Manage the selected tax category

  useEffect(() => {
    setEditable(edit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit]);

  useEffect(() => {
    if (data.ledger) {
      setData({ ...data });
      setTaxType(data.ledger?.tax_detail?.type || "");
      setTaxCategory(data.ledger?.tax_detail?.category || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.entry, props.items]);

  // const isIGSTPresent = existingEntries.some(
  //   (entry) =>
  //     entry.ledger?.tax_detail?.type === tax_type.gst &&
  //     entry.ledger?.tax_detail?.category ===
  //       tax_category[tax_type.gst].integreted
  // );

  const handleTaxSelection = (d: LedgerModel) => {
    // Determine the presence of different types of taxes in existing entries

    const isCGSTPresent = existingEntries.some(
      (entry) =>
        entry.ledger?.tax_detail?.type === tax_type.gst &&
        entry.ledger?.tax_detail?.category ===
          tax_category[tax_type.gst].central
    );

    const isSGSTPresent = existingEntries.some(
      (entry) =>
        entry.ledger?.tax_detail?.type === tax_type.gst &&
        entry.ledger?.tax_detail?.category === tax_category[tax_type.gst].state
    );

    // New entries that might need to be added
    const newEntries: LedgerEntryModel[] = [];

    // Check conflicts
    if (d.tax_detail?.type === tax_type.gst) {
      if (
        d.tax_detail?.category === tax_category[tax_type.gst].central &&
        !isSGSTPresent
      ) {
        newEntries.push({
          ...data,
          ledger: d,
          ledger_id: d.id,
        });
      } else if (
        d.tax_detail?.category === tax_category[tax_type.gst].state &&
        !isCGSTPresent
      ) {
        newEntries.push({
          ...data,
          ledger: d,
          ledger_id: d.id,
        });
      }
    }

    // Update the current entry
    setData((prevData) => ({
      ...prevData,
      ledger: d,
      ledger_id: d.id,
    }));
    // setData(props.entry!);

    // Handle new entries as needed
    if (newEntries.length > 0) {
      console.log("New entries to add:", newEntries);
      // Example: You might need to merge these with your state or handle them as appropriate
    }

    setTaxType(d.tax_detail?.type || "");
    setTaxCategory(d.tax_detail?.category || "");
    // onDone();
    // const t = { ...data, ledger: d, ledger_id: d.id };
    // props.onSubmit(t);
  };

  const onDone = () => {
    if (!data.ledger) {
      showToast({
        type: ToastType.warning,
        text: "Please select Ledger and Amount",
      });
      return;
    }

    const isIGSTPresent = existingEntries.some(
      (entry) =>
        entry.ledger?.tax_detail?.type === tax_type.gst &&
        entry.ledger?.tax_detail?.category ===
          tax_category[tax_type.gst].integreted
    );

    const isCGSTPresent = existingEntries.some(
      (entry) =>
        entry.ledger?.tax_detail?.type === tax_type.gst &&
        entry.ledger?.tax_detail?.category ===
          tax_category[tax_type.gst].central
    );

    const isSGSTPresent = existingEntries.some(
      (entry) =>
        entry.ledger?.tax_detail?.type === tax_type.gst &&
        entry.ledger?.tax_detail?.category === tax_category[tax_type.gst].state
    );

    if (taxType === tax_type.gst) {
      if (
        taxCategory === tax_category[tax_type.gst].integreted &&
        (isCGSTPresent || isSGSTPresent)
      ) {
        showToast({
          type: ToastType.warning,
          text: "IGST cannot be added with CGST or SGST entries",
        });
        return;
      }
      if (
        (taxCategory === tax_category[tax_type.gst].central ||
          taxCategory === tax_category[tax_type.gst].state) &&
        isIGSTPresent
      ) {
        showToast({
          type: ToastType.warning,
          text: "CGST or SGST cannot be added with IGST entries",
        });
        return;
      }
      if (
        taxCategory === tax_category[tax_type.gst].integreted &&
        isIGSTPresent
      ) {
        showToast({
          type: ToastType.warning,
          text: "IGST already added.",
        });
        return;
      }
      if (taxCategory === tax_category[tax_type.gst].state && isSGSTPresent) {
        showToast({
          type: ToastType.warning,
          text: "SGST already added.",
        });
        return;
      }
      if (taxCategory === tax_category[tax_type.gst].central && isCGSTPresent) {
        showToast({
          type: ToastType.warning,
          text: "CGST already added.",
        });
        return;
      }
    }
    console.log("tax data:", data);
    onSubmit(data);
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onDone();
        }}
        className="bg-white p-1"
      >
        <div className="grid grid-cols-6 gap-x-1">
          <div className="col-span-4">
            <LedgerSearchField
              group={account_sub_group.duties_and_taxes.name}
              disabled={!editable || props.disabled}
              value={data.ledger}
              onSelect={handleTaxSelection}
            />
          </div>
          <input
            disabled={!editable || props.disabled}
            value={Math.round(data.amount)}
            onChange={(e) => {
              const val = e.target.value.trim();
              if (!val || numberRegEx.test(val)) {
                const value = Number(val || 0);
                setData((o) => ({
                  ...o,
                  amount: value,
                }));
              }
            }}
            placeholder=""
            type="text"
            className="text-right p-1 focus:outline-none border rounded w-full"
          />

          <div className="flex gap-2 items-center justify-end">
            {props.entry ? (
              !props.disabled && (
                <>
                  <div className="flex justify-center gap-2">
                    {editable ? (
                      <>
                        <div
                          onClick={() => {
                            if (!window.confirm("Are you sure!")) return;
                            onDone();
                            setData(props.entry!);
                            setEditable(false);
                          }}
                          className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                        >
                          <Icon path={mdiCheck} className="h-4 w-4"></Icon>
                        </div>
                        <div
                          onClick={() => {
                            setData(props.entry!);
                            setEditable(false);
                          }}
                          className="bg-red-100 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                        >
                          <Icon path={mdiClose} className="h-4 w-4"></Icon>
                        </div>
                      </>
                    ) : (
                      (!data.voucher_id || !data.amount) && (
                        <div
                          onClick={() => {
                            setEditable(true);
                            onDone();
                          }}
                          className="bg-blue-100 p-1 rounded text-blue-800 hover:scale-110 transform duration-200"
                        >
                          <Icon path={mdiPencil} className="h-4 w-4" />
                        </div>
                      )
                    )}
                  </div>
                  <div
                    onClick={() => {
                      if (!window.confirm("Are you sure!")) return;
                      if (props.onDelete) props.onDelete(data);
                    }}
                    className="bg-red-200 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                  >
                    <Icon path={mdiDelete} className="h-4 w-4"></Icon>
                  </div>
                </>
              )
            ) : (
              <button
                onClick={() => {
                  onDone();
                }}
                className="flex gap-1 text-white text-sm bg-myPrimaryColor rounded-full px-2 py-1"
              >
                <Icon path={mdiPlus} className="h-5 w-5"></Icon> Add
              </button>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default TaxEntryRow;

// import {
//   mdiCheck,
//   mdiClose,
//   mdiPencil,
//   mdiDelete,
//   mdiPlus,
//   mdiChevronUp,
//   mdiChevronDown,
// } from "@mdi/js";
// import Icon from "@mdi/react";
// import { FunctionComponent, useEffect, useState } from "react";
// import {
//   useToast,
//   ToastType,
// } from "../../../../../../context_providers/toast/toast_ctx";
// import { numberRegEx } from "../../../../../../utils/constant";
// import LedgerSearchField from "../../../../../accounts/ledgers/ledger_search_field";
// import {
//   accountTxnType,
//   LedgerEntryModel,
// } from "../../../../../accounts/models/common_model";
// import { account_sub_group } from "../../../../../accounts/models/ledger_group_model";
// import {
//   LedgerTaxDetail,
//   tax_category,
//   tax_type,
// } from "../../../../../accounts/models/ledger_model";
// import { ledgerEntryDefaultValue } from "../../../../../accounts/models/purchase_model";
// import { ItemMovementModel } from "../../../../models/inventory_voucher";

// export const getItemTaxAmount = ({
//   items,
//   ledgerTaxDetail,
// }: {
//   items: ItemMovementModel[];
//   ledgerTaxDetail: LedgerTaxDetail;
// }) => {
//   let amount = 0;

//   for (let i = 0; i < items.length; i++) {
//     const item = items[i];
//     if (!item.item?.tax_applicable) continue;
//     if (ledgerTaxDetail.type === tax_type.gst) {
//       if (
//         ledgerTaxDetail.category ===
//         tax_category[ledgerTaxDetail.type].integreted
//       ) {
//         amount +=
//           item.unit_no *
//           item.price *
//           ((item.item?.tax_detail.integreted ?? 0) / 100);
//       } else if (
//         ledgerTaxDetail.category === tax_category[ledgerTaxDetail.type].state
//       ) {
//         amount +=
//           item.unit_no *
//           item.price *
//           ((item.item?.tax_detail.state ?? 0) / 100);
//       } else if (
//         ledgerTaxDetail.category === tax_category[ledgerTaxDetail.type].central
//       ) {
//         amount +=
//           item.unit_no *
//           item.price *
//           ((item.item?.tax_detail.central ?? 0) / 100);
//       }
//     }
//   }

//   return amount;
// };
// interface TaxEntryRowProps {
//   items: ItemMovementModel[];
//   entry?: LedgerEntryModel;
//   onSubmit: (data: LedgerEntryModel) => void;
//   edit: boolean;
//   disabled: boolean;
//   onDelete?: (i: LedgerEntryModel) => void;
// }

// const TaxEntryRow: FunctionComponent<TaxEntryRowProps> = (props) => {
//   const [data, setData] = useState<LedgerEntryModel>({
//     ...(props.entry || ledgerEntryDefaultValue),
//     type: accountTxnType.debitor,
//   });
//   const { showToast } = useToast();
//   const [showMore, setShowMore] = useState(false);
//   const { edit, onSubmit } = props;

//   const [editable, setEditable] = useState(false);
//   useEffect(() => {
//     setEditable(edit);
//   }, []);
//   const onDone = () => {
//     if (!data.ledger || !data.amount) {
//       showToast({
//         type: ToastType.warning,
//         text: "Please select Ledger and Amount",
//       });
//       return;
//     }
//     onSubmit(data);
//   };
//   useEffect(() => {
//     if (data.ledger) {
//       // const amount = getItemTaxAmount({
//       //   items: props.items ?? [],
//       //   ledgerTaxDetail: data.ledger?.tax_detail!,
//       // });

//       // setData((o) => ({ ...o, amount }));
//       // // props.onSubmit({ ...data, amount });
//       setData({ ...data });
//     }
//   }, [props.entry, props.items]);
//   return (
//     <>
//       <form
//         // id={props.form_id}
//         onSubmit={(e) => {
//           e.preventDefault();
//           onDone();
//         }}
//         className="bg-white    p-1 "
//       >
//         <div className="grid grid-cols-6 gap-x-1">
//           <div className="col-span-4">
//             <LedgerSearchField
//               group={account_sub_group.duties_and_taxes.name}
//               disabled={!editable || props.disabled}
//               value={data.ledger}
//               onSelect={(d) => {
//                 setData((o) => ({
//                   ...o,
//                 }));
//                 const t = { ...data, ledger: d, ledger_id: d.id };
//                 props.onSubmit(t);
//               }}
//             />
//           </div>
//           <input
//             disabled={!editable || props.disabled}
//             value={data.amount}
//             onChange={(e) => {
//               const val = e.target.value.trim();
//               if (!val || numberRegEx.test(val)) {
//                 const value = Number(val || 0);
//                 setData((o) => ({
//                   ...o,
//                   amount: value,
//                 }));
//               }
//             }}
//             placeholder=""
//             type="text"
//             className="text-right  p-1 focus:outline-none border rounded  w-full"
//           />

//           <div className="flex gap-2 items-center justify-end">
//             {props.entry ? (
//               !props.disabled && (
//                 <>
//                   <div className="flex justify-center gap-2">
//                     {editable ? (
//                       <>
//                         <div
//                           onClick={() => {
//                             if (!window.confirm("Are you sure !")) return;
//                             onDone();
//                             setEditable(false);
//                           }}
//                           className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
//                         >
//                           <Icon path={mdiCheck} className="h-4 w-4"></Icon>
//                         </div>
//                         <div
//                           onClick={() => {
//                             setData(props.entry!);
//                             setEditable(false);
//                           }}
//                           className="bg-red-100 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
//                         >
//                           <Icon path={mdiClose} className="h-4 w-4"></Icon>
//                         </div>
//                       </>
//                     ) : (
//                       <div
//                         onClick={() => setEditable(true)}
//                         className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
//                       >
//                         <Icon path={mdiPencil} className="h-4 w-4"></Icon>
//                       </div>
//                     )}
//                   </div>
//                   <div
//                     onClick={() => {
//                       if (!window.confirm("Are you sure !")) return;
//                       if (props.onDelete) props.onDelete(data);
//                     }}
//                     className="bg-red-200 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
//                   >
//                     <Icon path={mdiDelete} className="h-4 w-4"></Icon>
//                   </div>
//                 </>
//               )
//             ) : (
//               <button
//                 // form="item_form"
//                 className="flex gap-1 text-white text-sm bg-myPrimaryColor rounded-full px-2 py-1  "
//               >
//                 <Icon path={mdiPlus} className="h-5 w-5 "></Icon> Add
//               </button>
//             )}
//             {/* {props.entry && (
//               <div
//                 onClick={() => setShowMore((o) => !o)}
//                 className="p-1 bg-gray-100 rounded cursor-pointer hover:bg-gray-200"
//               >
//                 <Icon
//                   path={showMore ? mdiChevronUp : mdiChevronDown}
//                   className="h-4 w-4"
//                 ></Icon>
//               </div>
//             )} */}
//           </div>
//         </div>
//         {/* {showMore && props.item && (
//           <div className="px-2 py-1">
//             <hr className=" " />
//             <div className="text-xs text-gray-400">
//               <p>Id : {props.item.id}</p>
//               <p>Extra : {rupeeFormat(props.item.addition_amt ?? 0)}</p>
//               <p>Discount : {rupeeFormat(props.item.discount_amt ?? 0)}</p>
//               <p>Paid : {rupeeFormat(props.item.paid ?? 0)}</p>
//             </div>
//             <div className="flex   justify-between gap-2 my-1">
//               <p className="text-xs ">
//                 Created by :<br />
//                 <div className="flex gap-1">
//                   <div className="">
//                     <b>{props.item.created_by_name ?? "N/A"}</b>
//                   </div>
//                   <div className="">
//                     {props.item.created_at
//                       ? moment(props.item.created_at).format(
//                           "DD MMM YYYY h:mm a"
//                         )
//                       : "--"}
//                   </div>
//                 </div>
//               </p>
//               <p className="text-xs ">
//                 Updated by :<br />
//                 <div className="flex gap-1">
//                   <div className="">
//                     <b>{props.item.updated_by_name ?? "N/A"}</b>
//                   </div>
//                   <div className="">
//                     {props.item.updated_at
//                       ? moment(props.item.updated_at).format(
//                           "DD MMM YYYY h:mm a"
//                         )
//                       : "--"}
//                   </div>
//                 </div>
//               </p>
//             </div>
//           </div>
//         )} */}
//       </form>
//     </>
//   );
// };

// export default TaxEntryRow;
