import { FunctionComponent, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { GetEmpStrength } from "../../service/repos/analytics";
import moment from "moment";
import { months } from "../../../../../utils/month";
import HrmLoader from "../../components/loading";

interface StrengthGraphProps {
  fromDate: number;
  toDate: number;
}

const StrengthGraph: FunctionComponent<StrengthGraphProps> = ({
  fromDate,
  toDate,
}) => {
  const [labels, setLabels] = useState<any[]>([]);
  const [chartSeries, setChartSeries] = useState<any[]>([{}]);
  const [loading, setLoading] = useState<boolean>(true);

  const options = {
    chart: {
      id: "basic-bar",
      stacked: true,
    },
    colors: [
      "#4B69FA",
      "#AEEBEE",
      "#FD9E33",
      "#C3FB67",
      "#FFE804",
      "#FF02EC",
      "#C25858",
      "#A6BEEA",
      "#065A1A",
      "#027293",
    ],
    plotOptions: {
      bar: {
        borderRadius: 4,
      },
    },

    xaxis: {
      categories: labels,
    },
  };

  const getStrengthData = async (fromDate: number, toDate: number) => {
    setLoading(true);

    const data = {
      fromDate: fromDate,
      toDate: toDate,
    };
    const res = await GetEmpStrength(data);

    if (res.success) {
      const { data } = res;
      const tempLabels = Object.keys(data).map((el) => {
        return moment(el).format("MM/YY");
      });
      setLabels(tempLabels);

      const tempSeries: any = [];

      Object.keys(data).map((o) => {
        data[o].map((el: any) => {
          if (tempSeries.length === 0) {
            const tempData = {
              name: el.dep,
              data: [el.count],
            };
            tempSeries.push(tempData);
          } else if (
            tempSeries.some(
              (o: { name: string; data: any }) => o.name === el.dep
            )
          ) {
            const index = tempSeries.findIndex((d: any) => d.name === el.dep);
            tempSeries[index].data.push(el.count);
          } else {
            const tempData = {
              name: el.dep,
              data: [el.count],
            };
            tempSeries.push(tempData);
          }
        });
      });

      setChartSeries(tempSeries);
      setLoading(false);
    }
  };

  useEffect(() => {
    getStrengthData(fromDate, toDate);
  }, [fromDate, toDate]);

  return (
    <div className="border border-gray-200 p-3 bg-white rounded-2xl">
      <div className="px-2 border-b border-gray-200  pb-2 font-bold">
        Strength graph
      </div>
      <div className="pt-3">
        {loading ? (
          <div className="flex flex-col items-center pt-6">
            <HrmLoader className="w-12 h-12" />
          </div>
        ) : (
          <Chart options={options} series={chartSeries} type="bar" />
        )}
      </div>
    </div>
  );
};

export default StrengthGraph;
