import moment from "moment";
import { useState } from "react";
import { Info, Mail, Phone, X } from "react-feather";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../../../../context_providers/auth/authProvider";
import { UserModel } from "../../../../../context_providers/auth/user_model";

import { MODULE, ACTION } from "../../../../../utils/enums";
import { urls } from "../../../../../utils/urls";
import "../../styles/index.css";

const EmpCard = ({ emp }: { emp: UserModel }) => {
  const { user, isAuthorised } = useAuth();

  const history = useHistory();

  const handleClick = () => {
    history.push(urls.hrm_profile, emp);
  };

  const isActive = emp.emp_profile?.active;

  return (
    <div
      className=" bg-white cursor-pointer rounded-2xl relative pt-8 pb-6 px-3"
      onClick={
        isAuthorised({
          module: MODULE.hrm_leave,
          action: [ACTION.UPDATE],
        })
          ? () => handleClick()
          : () => {}
      }
    >
      <div
        className={`${
          isActive ? " text-green-600 bg-green-200" : "text-red-600 bg-red-200"
        } absolute top-2 right-2 px-3 rounded-full text-xs`}
      >
        {isActive ? "Active" : "Inactive"}
      </div>
      <div className="flex lg:flex-col gap-4 lg:gap-0">
        <div className=" flex flex-col items-center justify-center">
          {emp.profile_image ? (
            <img
              src={emp.profile_image}
              alt=""
              className=" w-12 h-12 lg:w-24 lg:h-24 rounded-full"
            />
          ) : (
            <img
              src={"/images/cat.jpeg"}
              alt=""
              className=" w-12 h-12 lg:w-24 lg:h-24 rounded-full object-cover"
            />
          )}
        </div>
        <div className=" flex  flex-col lg:items-center ">
          <div
            className={`text-lg font-semibold lg:pt-4 ${
              emp.active ? "dark:text-primaryColor" : "text-gray-500"
            }`}
          >
            {emp?.first_name + " "} {emp?.last_name}
          </div>
          <div className="text-secondaryColor text-sm">
            {emp?.emp_profile?.designation}
          </div>
        </div>
      </div>

      <div className="bg-purple-100  bg-opacity-60 dark:bg-secondaryColor dark:bg-opacity-30 rounded-lg px-6  mt-4 lg:mt-2 py-4 lg:py-8">
        <div className="grid grid-cols-2">
          <div className="">
            <div className="text-xs font-semibold text-secondaryColor dark:text-white">
              Department
            </div>
            <div className="font-semibold dark:text-white dark:text-opacity-40">
              {emp?.emp_profile?.department}
            </div>
          </div>
          <div className="">
            <div className="text-xs font-semibold text-secondaryColor dark:text-white">
              Joining date
            </div>
            <div className="font-semibold dark:text-white dark:text-opacity-40">
              {moment(emp?.emp_profile?.doj).format("ll")}
            </div>
          </div>
        </div>
        <div className="mt-1 lg:mt-3">
          <div className="flex items-center gap-2 text-secondaryColor pt-4">
            <Mail className="" size={16} />
            <div className="text-sm dark:text-white dark:text-opacity-40">
              {emp?.email}
            </div>
          </div>

          <div className="flex items-center gap-2 text-secondaryColor pt-2 lg:pt-4">
            <Phone className="" size={16} />
            <div className="text-sm dark:text-white dark:text-opacity-40">
              {emp?.mobile}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmpCard;
