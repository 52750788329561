import axios from "axios";
import {
  fetchGet,
  fetchPost,
  fetchPut,
  getHeaders,
  toQueryString,
} from "../api_client";
import { WarehouseModel } from "../models/inventory/warehouse_model";

export const CreateWareHouseApi = async (data: WarehouseModel) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/warehouse`;
    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const UpdateWareHouseApi = async (data: WarehouseModel) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/warehouse/${data.id}`;
    const res = await fetchPut(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GetWareHouseApi = async (id: number) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/warehouse/${id}`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GetWareHouseListApi = async (param: {
  page: number;
  count: number;
  all?: any;
}) => {
  try {
    const url = `${
      process.env.REACT_APP_BACKEND_BASE_URL
    }/api/v3/warehouse${toQueryString(param)}`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
