import { FunctionComponent } from "react";
import Sidebar from "../../components/Sidebar";
import ProtocolLayout from "../components/ProtocolLayout";

interface KazamWarrantyProps {}

const KazamWarranty: FunctionComponent<KazamWarrantyProps> = () => {
  console.log("added");
  return (
    <ProtocolLayout>
      <div className="flex flex-col items-center min-h-screen">
        <div className="mt-20 w-11/12 lg:w-4/5">
          <div className="text-xl lg:text-3xl font-bold">
            KAZAM HARDWARE WARRANTY TERMS & CONDITION
          </div>
          <div className="">
            <div className="text-lg lg:text-xl mt-10 font-bold">GENERAL</div>
            <div className="mt-2 lg:mt-4 leading-8">
              These Terms and Conditions constitute the entire and exclusive and
              final statement of the agreement between you and the Company with
              respect to the subject matter hereof, superseding any prior
              agreements or negotiations between you and the Company with
              respect to such subject matter. The Company shall neither be
              liable to the other nor shall be in default if, and to the extent
              that, the performance or delay in performance of any of its
              obligations under these Terms and Conditions is prevented,
              restricted, delayed or interfered with due to circumstances beyond
              the reasonable control of the Company or any force majeure event.
              The failure of the Company to exercise or enforce any right or
              provision of these Terms and Conditions shall not constitute a
              waiver of such right or provision. If any provision of these Terms
              and Conditions is found by a court of competent jurisdiction to be
              invalid, you nevertheless agree that the court should endeavor to
              give effect to the intentions of the Company and you as reflected
              in the provision, and that the other provisions of these Terms and
              Conditions remain in full force and effect. These Terms and
              Conditions, and any rights and licenses granted hereunder, may not
              be transferred or assigned by you, but may be assigned by the
              Company without restriction. The Company may, in its sole and
              absolute discretion, change these Terms and Conditions from time
              to time.
            </div>
          </div>

          <ListComponent
            heading="Upon purchase of a Kazam hardware, Kazam offers :-"
            data={[
              "1 Year Replacement Warranty against manufacturing defects.",
            ]}
          />
          <ListComponent
            heading="Exclusions from Product warranty"
            data={[
              "The warranty does not apply to any damage, defect, or failure caused by any part of the equipment being modified, opened, or repaired unless performed by the manufacturer’s authorized technician.",
              "Damage as a result of power supply limits exceeding the values (voltage & frequency) as mentioned in the specifications of the product.",
              "Improper earthing connection & earthing maintenance.",
              "Used for a purpose other than EV charging.",
              "Vandalism, abuse, misuse, normal wear and tear, cosmetic damage, or physical damage caused by negligence, including being collision by a vehicle/mechanical impact.",
              "Damages due to Rodents or Pest .",
              "Not applicable after the expiration of the warranty coverage period.",
            ]}
          />
          <ListComponent
            heading="Extended Warranty:"
            data={[
              "An extension of warranty can be purchased for an additional 2 years from the standard warranty at Rs. 2000 per device per year",
            ]}
          />
          <ListComponent
            heading="Annual Maintenance Contract (AMC):"
            data={[
              "Optional AMC is available by paying Rs.1000 per year per device which includes 2 free maintenance visits.",
            ]}
          />
          <ListComponent
            heading="Annual Maintenance Contract (AMC):"
            data={[
              "Optional AMC is available by paying Rs.1000 per year per device which includes 2 free maintenance visits.",
            ]}
          />
          <div className="mt-12">
            <div className="font-bold lg:text-lg">
              Services have been categorized into the following categories:
            </div>
            <div className="mt-10">
              <img
                src="/kazam-academy/warranty.png"
                alt=""
                className="lg:w-4/5"
              />
            </div>
          </div>
          <ListComponent
            heading="Other terms and conditions:"
            data={[
              "Additional visits will be charged at Rs. 1000 per visit in Tier-1 cities",
              "Category-2 and Category-3 support available in select cities- Delhi NCR, Bengaluru, Pune, Mumbai, Hyderabad, Chennai.",
              "Services for other cities' or post warranty visits are on paid basis on actuals.",
            ]}
          />

          <ListComponent
            heading="Warranty Claim Process & Support:"
            data={[
              "To request service, you are required to call the customer service line. The customer service team will assist in determining whether the issue can be resolved remotely.",
              "Upon receipt and verification that the issue is covered by a warranty, the service center will repair / replace the unit.",
              "Standard parts can be available on-site within 4 days.",
            ]}
          />

          <div className="mb-20">
            <div className="text-lg lg:text-xl mt-10 font-bold">
              Limitation of Liability, Disclaimer of Warranties, and
              Indemnification
            </div>
            <div className="mt-2 lg:mt-4 leading-8">
              To the extent permitted by law, neither Kazam nor its affiliates
              will be liable for any direct, indirect, consequential, or other
              damages, including but not limited to property damage, loss of
              use, loss of business, economic loss, loss of data, or loss of
              profits, arising out of or in connection with your use or access
              to, or inability to use or access, any Kazam product or its
              content. Any new features, such as new material and/or the release
              of new software tools or resources, are subject to these Terms of
              Use unless otherwise noted. Kazam will make every effort to ensure
              that the information and materials supplied on any Kazam product
              are accurate. Kazam, on the other hand, cannot guarantee the
              veracity of all information and materials and disclaims all duty
              or liability for the accuracy, completeness, or authenticity of
              any information or materials provided by Kazam products. We do not
              guarantee that any Kazam product will operate without interruption
              or error, or that the Kazam products will be free of viruses or
              other components that could harm equipment or software. Kazam
              makes no promise that Kazam goods will work with the equipment or
              software you use, and it also makes no guarantee that Kazam
              products will be available at all times or at any given time. You
              must not attempt to interfere with the proper functioning of any
              of our Kazam products, including any computer system, server,
              website, router, or other internet-connected devices, by
              circumventing security, tampering with, hacking into, or otherwise
              disrupting any computer system, server, website, router, or other
              internet-connected devices. We have the right to change, suspend,
              or terminate any feature of our Kazam products, including your
              access to them, at any time. You undertake to keep Kazam and its
              affiliates blameless from any liability or loss arising from your
              breach of these Kazam products' Terms of Use, including your use
              of the Website.
            </div>
          </div>
        </div>
      </div>
    </ProtocolLayout>
  );
};

interface ListComponentProps {
  heading: string;
  data: any[];
}
export const ListComponent: FunctionComponent<ListComponentProps> = ({
  heading,
  data,
}) => {
  return (
    <div className="mt-10">
      <div className="font-bold lg:text-lg">{heading}</div>
      <ul className="list-disc pl-6">
        {data.map((el, i) => {
          return <li className="mt-3">{el}</li>;
        })}
      </ul>
    </div>
  );
};

export default KazamWarranty;
