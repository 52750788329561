import AllActivity from "./components/AllActivity";
import ChartSection from "./components/ChartSection";
import "../styles/index.css";
import {
  GetLeaveRequests,
  GetOverviewData,
} from "../../../../service/repos/hrm/hrm_repo";
import { useEffect, useState } from "react";
import {
  LeaveRequest,
  OverviewModel,
} from "../../../../service/models/user_model";
import { useHistory } from "react-router-dom";
import Layout from "../layout";

import { ACTION, MODULE } from "../../../../utils/enums";
import { Pagination } from "@material-ui/lab";
import HrmLoader from "../components/loading";
import { useAuth } from "../../../../context_providers/auth/authProvider";

const defaultValue: OverviewModel = {
  next_day_off_emp: [],
  offDuty: 0,
  onDuty: 0,
  today_off_emp: [],
  wfh_emp: [],
  onWFH: 0,
  next_day_wfh_emp: [],
  next_day_onWFH: 0,
};

const Overview = () => {
  const { user, isAuthorised } = useAuth();

  const [activityData, setActivityData] = useState<LeaveRequest[]>([]);

  const count = 20;
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const access = isAuthorised({
    module: MODULE.hrm_leave,
    action: [ACTION.UPDATE],
  });

  const getActivityAll = async (p: number) => {
    setLoading(true);
    const params: { page: number; count: number } = {
      page: p,
      count: count,
    };
    const res = await GetLeaveRequests(params);

    if (res.success) {
      setLoading(false);

      setTotal(res.data.metadata[0]?.total ?? 0);
      setActivityData(res.data?.leave_requests);
    }
  };

  const onPageChange = async (p: number) => {
    if (p !== page) {
      setPage(p);
      await getActivityAll(p);
    }
  };

  useEffect(() => {
    getActivityAll(page);
  }, []);

  return (
    <Layout>
      <div className=" bg-white dark:bg-hrmCardDark  rounded-lg mt-4">
        <div className="hidden lg:grid grid-cols-8 mb-6  text-sm border-b py-3 pl-4 font-bold bg-gray-200 ">
          <div className="">Name</div>
          <div className="">Leave Type</div>
          <div className="">From</div>
          <div className="">To</div>
          <div className="">Period</div>
          <div className="">Applied on</div>
          <div className="">Reason</div>
          <div className="">Status</div>
        </div>
        <div className="overflow-y-scroll" style={{ maxHeight: "80vh" }}>
          {loading ? (
            <div
              className="flex flex-col items-center justify-center"
              style={{ height: "50vh" }}
            >
              <HrmLoader className="w-12 h-12" />
            </div>
          ) : (
            activityData.map((item, i) => {
              return <AllActivity uid={user?.uid as any} data={item} />;
            })
          )}
        </div>
      </div>
      <div className="flex flex-col items-center">
        <Pagination
          page={page}
          count={Math.ceil(total / count)}
          className={`my-5 hrm_pagination`}
          color="secondary"
          variant="outlined"
          onChange={(event, page) => {
            onPageChange(page);
          }}
        />
      </div>
    </Layout>
  );
};

export default Overview;
