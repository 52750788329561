import { ItemModel } from "../../../erp/inventory/models/item_model";
import { AddressModel, GeoLocation } from "../address_model";
import { BaseModel } from "../base";

export const OpsTaskDeviceItemStatus = {
  pending: "Pending",
  completed: "Completed",
  cancelled: "Cancelled",
};
export const OpsTaskDeviceItemResolution = {
  repaired: "Repaired",
  replaced: "Replaced",
  none: "None",
};
export const getDefaultTaskDeviceItem = () => {
  const x: OpsTaskDeviceItem = {
    id: "",
    device_id: "",
    name: "",
    status: OpsTaskDeviceItemStatus.pending,
    remark: "",
    resolution: OpsTaskDeviceItemResolution.none,
    components: [],
    charger_state: "",
  };
  return x;
};
export interface OpsTaskDeviceItem {
  id: string;
  device_id: string;
  name: string;
  status: string;
  charger_state?: string;
  txn_test_done?: boolean;
  issue_type?: string; //in case of complaint
  issue_sub_type?: string; //in case of complaint
  remark: string;
  resolution?: string;
  components?: { item: ItemModel; qty: number; id: string }[]; //in case of complaint/service
  time_taken?: number;
}

export interface OpsTaskModel extends BaseModel {
  id: string;
  start_date?: Date;
  end_date?: Date;
  distance?: number;
  distance_travelled?: number;
  // hour_input?: number;

  start_location?: GeoLocation;
  stop_location?: GeoLocation;
  verified: Boolean;
  checklist?: {
    section_a: number[];
    section_b: number[];
    section_c: number[];
  };
  remarks: string;
  type: string;
  date?: Date;
  last_date?: Date;
  device_ids?: { device_id: string; name: string }[];
  device_items?: OpsTaskDeviceItem[];
  order_id?: string;
  complaint_id?: string;
  priority?: string;
  cust_name?: string;
  cust_email?: string;
  cust_mobile?: string;
  cust_alt_mobile?: string;
  cust_address?: string;
  line1?: string;
  line2?: string;
  city?: string;
  state?: string;
  country?: string;
  pincode?: number;
  address: AddressModel;
  description?: string;
  status: string;
  emp_id: string;
  emp_name: string;
  emp_id2: string;
  emp_name2: string;
  warehouse_id: number;
  warehouse_name: string;
  active: boolean;
  attachments?: {
    id: number;
    task_id: number;
    type: string;
    url: string;
    created_by: number;
    created_by_name: string;
    category: string;
    deleted?: boolean | undefined;
    created_at: Date;
    updated_at: Date;
  }[];
}

export interface OpsTaskCommentModel extends BaseModel {
  id?: number;
  task_id: string;
  comment: string;
}
export interface OpsTaskLogModel extends BaseModel {
  id?: number;
  task_id: string;
  log: string;
}
