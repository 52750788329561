import {
  useState,
  useRef,
  useLayoutEffect,
  FunctionComponent,
  RefObject,
  useEffect,
} from "react";

interface PopUpButtonProps {
  button: React.ReactNode;
  items: {
    onClick?: () => void;
    leading?: React.ReactNode;
    title?: React.ReactNode;
    subtitle?: React.ReactNode;
    trailling?: React.ReactNode;
  }[];
}

const PopUpButton: FunctionComponent<PopUpButtonProps> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const buttonRef: RefObject<HTMLButtonElement> =
    useRef<HTMLButtonElement>(null);
  const dropdownRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const [menuPosition, setMenuPosition] = useState<
    "bottom-left" | "bottom-right" | "top-left" | "top-right"
  >("bottom-left");

  //   useLayoutEffect(() => {
  //     if (isOpen && dropdownRef.current && buttonRef.current) {
  //       const buttonRect = buttonRef.current.getBoundingClientRect();
  //       const dropdownRect = dropdownRef.current.getBoundingClientRect();
  //       const spaceAbove = buttonRect.top;
  //       const spaceBelow = window.innerHeight - buttonRect.bottom;
  //       const spaceLeft = buttonRect.left;
  //       const spaceRight = window.innerWidth - buttonRect.right;
  //       const maxHeight = Math.max(spaceAbove, spaceBelow) - 16; // Subtract 16px for padding
  //       const top = spaceBelow >= spaceAbove ? buttonRect.bottom : "auto";
  //       const bottom =
  //         spaceBelow < spaceAbove ? window.innerHeight - buttonRect.top : "auto";
  //       let left =
  //         spaceRight >= dropdownRect.width
  //           ? buttonRect.left
  //           : spaceLeft >= dropdownRect.width
  //           ? "auto"
  //           : spaceRight - dropdownRect.width;
  //       let right =
  //         spaceRight >= dropdownRect.width
  //           ? "auto"
  //           : spaceLeft >= dropdownRect.width
  //           ? window.innerWidth - buttonRect.right
  //           : spaceLeft - dropdownRect.width;

  //       if (left !== "auto" && right !== "auto" && spaceRight >= spaceLeft) {
  //         // There is enough space on the right, so use it
  //         left = "auto";
  //         right = `${spaceRight}px`;
  //       }

  //       dropdownRef.current.style.maxHeight = `${maxHeight}px`;
  //       dropdownRef.current.style.top =
  //         top === "auto" ? top : `${buttonRect.bottom}px`;
  //       dropdownRef.current.style.bottom =
  //         bottom === "auto" ? bottom : `${window.innerHeight - buttonRect.top}px`;
  //       dropdownRef.current.style.left = left === "auto" ? left : `${left}px`;
  //       dropdownRef.current.style.right = right === "auto" ? right : `${right}px`;
  //     }
  //   }, [isOpen]);
  useEffect(() => {
    function calculateMenuPosition() {
      const button = buttonRef.current;
      if (button) {
        const buttonRect = button.getBoundingClientRect();
        const availableSpace = {
          bottom: window.innerHeight - buttonRect.bottom,
          right: window.innerWidth - buttonRect.right,
          top: buttonRect.top,
        };
        if (availableSpace.bottom >= 200 && availableSpace.right >= 200) {
          setMenuPosition("bottom-left");
        } else if (availableSpace.right < 200) {
          setMenuPosition("bottom-right");
        } else if (availableSpace.top >= 200) {
          setMenuPosition("top-left");
        } else {
          setMenuPosition("top-right");
        }
      }
    }
    if (isOpen) {
      calculateMenuPosition();
      window.addEventListener("resize", calculateMenuPosition);
    }
    return () => {
      window.removeEventListener("resize", calculateMenuPosition);
    };
  }, [isOpen]);

  const ref = useRef<any>(null);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
  return (
    <div className="relative flex  w-full h-full " ref={ref}>
      <button
        ref={buttonRef}
        className=" w-full"
        onClick={() => setIsOpen(!isOpen)}
      >
        {props.button || "Button"}
      </button>
      {props.items.length > 0 && isOpen && (
        <div
          ref={dropdownRef}
          className={`absolute z-20 bg-white dark:bg-darkCardColor border dark:border-gray-700 rounded-lg shadow-md overflow-auto  ${
            menuPosition === "bottom-left" ? "top-full left-0 mt-2" : ""
          } ${menuPosition === "bottom-right" ? "top-full right-0 mt-2" : ""} ${
            menuPosition === "top-left" ? "bottom-full left-0 mb-2" : ""
          } ${menuPosition === "top-right" ? "bottom-full right-0 mb-2" : ""}`}
        >
          <ul className=" flex flex-col    divide-y divide-gray-300 dark:divide-gray-700  ">
            {props.items.map((item, i) => (
              <>
                <div
                  className={`flex items-center justify-between gap-2 p-1 hover:bg-gray-400 hover:text-white dark:hover:bg-darkBgcolor  cursor-pointer ${
                    i == 0 && "pt-2"
                  } ${i == props.items.length - 1 && "pb-2"} `}
                  onClick={() => {
                    if (item.onClick) item.onClick();
                    setIsOpen(false);
                  }}
                >
                  <div className="flex gap-1">
                    <div className="">{item.leading}</div>
                    <div className="flex flex-col">
                      <div className="">{item.title}</div>
                      <div className="">{item.subtitle}</div>
                    </div>
                  </div>
                  <div className="">{item.trailling}</div>
                </div>
                {/* {i < props.items.length - 1 && (
                  <div className="mx-1 h-px bg-gray-700" />
                )} */}
              </>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default PopUpButton;
