import { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import LoadingWidget from "../../../context_providers/modal/loader";
import {
  ToastType,
  useToast,
} from "../../../context_providers/toast/toast_ctx";
import { fetchGet, fetchPost, fetchPut } from "../../../service/api_client";
import { urls } from "../../../utils/urls";
import { ItemGroupModel } from "../models/item_group_model";
import DoneWidget from "../../../ui/new_components/common/done";
const defaulValue: ItemGroupModel = {
  name: "",
};
//dev
interface ItemGroupCreateScreenProps {
  data?: ItemGroupModel;
  onClose: (data?: ItemGroupModel) => void;
}

const ItemGroupCreateScreen: FunctionComponent<ItemGroupCreateScreenProps> = (
  props
) => {
  const { id } = useParams<{ id: string }>();
  const is_update_page = useRouteMatch(
    urls.procurement_masters_item_groups + "/update/:id"
  );
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();
  const history = useHistory();
  const [data, setData] = useState<ItemGroupModel>({
    ...(props.data || defaulValue),
  });
  const [showSuccess, setShowSucess] = useState(false);

  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/groups`;
  const onSubmit = async () => {
    if (!data.name) {
      showToast({
        type: ToastType.error,
        text: "Please fill required fields !",
      });
      return;
    }
    setLoading(true);

    const res = data.id
      ? await fetchPut(url + "/" + data.id, data)
      : await fetchPost(url, data);

    if (res.success) {
      if (!data.id) {
        setData(defaulValue);
        setShowSucess(true);
      }
      setData(res.data);
      showToast({ type: ToastType.success, text: res.message });
      if (props.onClose) props.onClose(res.data);
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  useEffect(() => {
    if (id && is_update_page) getData(id);
  }, []);
  
  const getData = async (id: string) => {
    setLoading(true);
    const res = await fetchGet(url + "/" + id);
    if (res.success) {
      setData(res.data);
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  if (showSuccess)
    return (
      <div className="w-full h-full flex flex-col items-center justify-center">
        <div className="w-80 h-80 p-0">
          <DoneWidget />
        </div>
        <div className="flex flex-col gap-2 justify-start items-center">
          <h1 className="text-3xl font-semibold text-gray-400 ">
            Created successfully
          </h1>
          <span className="text-black italic font-semibold"># {data.id}</span>
          <div className="flex gap-5">
            <button
              onClick={() => {
                history.push(
                  urls.procurement_masters_item_groups + "/update/" + data.id
                );
                setShowSucess(false);
              }}
              className="border rounded-3xl px-5 py-2 text-green-800 border-green-400"
            >
              Edit
            </button>
            <button
              onClick={() => {
                setData({
                  ...defaulValue,
                });

                setShowSucess(false);
              }}
              className="border rounded-3xl px-4 py-2 bg-green-400 text-white  "
            >
              Create new
            </button>
          </div>
        </div>
      </div>
    );

  return (
    <>
      <div className="p-5 md:p-10 flex flex-col gap-5">
        <div className="flex flex-col items-start text-sm w-full">
          <label className="font-semibold w-full" htmlFor="name">
            Name*
          </label>
          <input
            placeholder="Enter name"
            className="p-1 rounded  focus:outline-none w-full border"
            id="name"
            name="name"
            value={data.name || ""}
            required={true}
            type="text"
            onChange={(e) => {
              setData((o) => ({ ...o, name: e.target.value }));
            }}
          ></input>
        </div>
        <div className="flex flex-col items-start text-sm w-full">
          <label className="font-semibold w-full" htmlFor="name">
            Description
          </label>
          <textarea
            placeholder="Enter name"
            className="p-1 rounded  focus:outline-none w-full border"
            id="desc"
            name="desc"
            value={data.description || ""}
            onChange={(e) => {
              setData((o) => ({ ...o, description: e.target.value }));
            }}
          ></textarea>
        </div>

        <div className="flex justify-center gap-5 mt-5 mb-2">
          <button
            onClick={onSubmit}
            type="submit"
            className="rounded-md text-sm font-semibold bg-myPrimaryColor text-white px-3 py-1"
          >
            Submit
          </button>
        </div>
      </div>
      {loading && <LoadingWidget />}
    </>
  );
};

export default ItemGroupCreateScreen;
