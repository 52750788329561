import moment from "moment";
import {
  createContext,
  FunctionComponent,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { string } from "yup";
import { DiscomModel } from "../service/models/discom_model";
import { CustomerDetailModel } from "../service/models/orders/customer_detail_model";
import {
  defaultOrderVal,
  OrderModel,
} from "../service/models/orders/order_model";
import {
  subscriptionPlanDefault,
  SubscriptionPlanModel,
} from "../service/models/orders/subscription_plan_model";
import ProductModel from "../service/models/product_model";
import { ApiResponse } from "../service/models/response";
import { TransactionDataModel } from "../service/models/trannsaction_model";
import { GetDiscomApi } from "../service/repos/discom";
import {
  CreateOrderApi,
  GetLastOrderOfCustomerApi,
  GetOrderApi,
  GetOrderNumberApi,
} from "../service/repos/order_repo";
import { GetProductsApi } from "../service/repos/products_repo";
import { GetCustomerTxnBalApi } from "../service/repos/transaction_repo";
import LoadingWidget from "../ui/components/loading_spinner";
import { Step } from "../ui/new_components/common/stepper_widget";
import {
  PO_STATUS,
  PaymentStatus,
  AddressType,
  OrderType,
  OrderStatus,
} from "../utils/enums";
import { GetTxnAmt } from "../utils/order_util";
import { useModal } from "./modal/modal_context";
import { ToastType, useToast } from "./toast/toast_ctx";

interface OrderCreateCtxModel {
  setAttachments: React.Dispatch<
    SetStateAction<
      {
        category: string;
        file: File;
      }[]
    >
  >;
  attachments: {
    category: string;
    file: File;
  }[];
  order: OrderModel;
  setOrder: React.Dispatch<React.SetStateAction<OrderModel>>;
  products: ProductModel[];
  discoms: DiscomModel[];
  setUser: React.Dispatch<
    React.SetStateAction<
      | {
          uid: any;
          first_name: string;
          last_name: string;
          mobile?: string | undefined;
          email?: string | undefined;
        }
      | undefined
    >
  >;
  user?: {
    uid: number;
    first_name: string;
    last_name: string;
    mobile?: string;
    email?: string;
  };
  setBilling: React.Dispatch<
    React.SetStateAction<CustomerDetailModel | undefined>
  >;
  billing?: CustomerDetailModel;
  setDeliveryProfile: React.Dispatch<
    React.SetStateAction<CustomerDetailModel | undefined>
  >;
  deliveryProfile?: CustomerDetailModel;
  balTxns: TransactionDataModel[];
  balTxnAmt: number;
  useBal: boolean;
  setUseBal: React.Dispatch<React.SetStateAction<boolean>>;
  setStep: (s: number) => void;
  step: number;
  steps: Step[];
  onCreateOrder: () => void;
  setSubscription: React.Dispatch<SetStateAction<SubscriptionPlanModel>>;
  subscription: SubscriptionPlanModel;
  setSubscriptionReq: React.Dispatch<SetStateAction<boolean>>;
  subscriptionReq: boolean;
  namespaces: {
    name: string;
    namespace: string;
  }[];
  setNamespaces: React.Dispatch<
    SetStateAction<
      {
        name: string;
        namespace: string;
      }[]
    >
  >;
  reset: (order_id?: boolean) => Promise<void>;
  lastOrder?: OrderModel;
  selectLastOrder: () => void;
  setPrefilled: React.Dispatch<SetStateAction<boolean>>;
  prefilled: boolean;
}
const defaultVal: OrderCreateCtxModel = {
  steps: [],
  onCreateOrder: () => {},
  step: 0,
  setStep: () => {},
  useBal: false,
  setUseBal: () => {},
  balTxnAmt: 0,
  balTxns: [],
  order: defaultOrderVal,
  setOrder: () => {},
  products: [],
  discoms: [],
  setUser: () => {},
  user: {
    uid: 0,
    first_name: "",
    last_name: "",
    mobile: "",
    email: "",
  },
  setBilling: function (
    value: SetStateAction<CustomerDetailModel | undefined>
  ): void {
    throw new Error("Function not implemented.");
  },
  subscription: subscriptionPlanDefault,
  setSubscription: () => {},
  setAttachments: function (
    value: SetStateAction<{ category: string; file: File }[]>
  ): void {
    throw new Error("Function not implemented.");
  },
  attachments: [],
  namespaces: [],
  setNamespaces: function (
    value: SetStateAction<{ name: string; namespace: string }[]>
  ): void {
    throw new Error("Function not implemented.");
  },
  reset: async () => {},
  setSubscriptionReq: function (value: SetStateAction<boolean>): void {
    throw new Error("Function not implemented.");
  },
  subscriptionReq: false,
  selectLastOrder: () => {},
  setPrefilled: function (value: SetStateAction<boolean>): void {
    throw new Error("Function not implemented.");
  },
  prefilled: false,
  setDeliveryProfile: function (
    value: SetStateAction<CustomerDetailModel | undefined>
  ): void {
    throw new Error("Function not implemented.");
  },
};

const defautSteps: Step[] = [
  { label: "Customer", required: true, validated: false },
  {
    label: "Order Detail",
    required: true,
    validated: false,
  },
  { label: "Products", required: true, validated: false },
  { label: "Extras & Discounts", required: false, validated: false },
  { label: "Subscription", required: false, validated: false },
  { label: "Payments", required: false, validated: false },
  { label: "Attachments", required: false, validated: false },
];
const context = createContext<OrderCreateCtxModel>(defaultVal);
export const useOrderCreateCtx = () => useContext(context);
interface OrderCreateContextProps {
  children?: any;
}

const OrderCreateContext: FunctionComponent<OrderCreateContextProps> = ({
  children,
}) => {
  const { showToast } = useToast();
  const [attachments, setAttachments] = useState<
    { category: string; file: File }[]
  >([]);
  const [subscription, setSubscription] = useState<SubscriptionPlanModel>({
    ...subscriptionPlanDefault,
  });
  const [subscriptionReq, setSubscriptionReq] = useState(false);
  const [namespaces, setNamespaces] = useState<
    {
      name: string;
      namespace: string;
    }[]
  >([]);
  const [order, setOrder] = useState(defaultOrderVal);
  const [lastOrder, setLastOrder] = useState();
  const [prefilled, setPrefilled] = useState(false);

  const [products, setProducts] = useState<ProductModel[]>([]);
  const { setLoading } = useModal();
  const [discoms, setDiscoms] = useState<DiscomModel[]>([]);
  const [balTxns, setBalTxns] = useState<TransactionDataModel[]>([]);
  const [balTxnAmt, setBalTxnAmt] = useState(0);
  const [useBal, setUseBal] = useState(false);
  const [step, setStep] = useState(0);
  const [user, setUser] = useState<{
    uid: number;
    first_name: string;
    last_name: string;
    mobile?: string;
    email?: string;
  }>();
  const [billing, setBilling] = useState<CustomerDetailModel>();
  const [deliveryProfile, setDeliveryProfile] = useState<CustomerDetailModel>();
  const [steps, setSteps] = useState<Step[]>([]);

  const reset = async (order_id?: boolean) => {
    if (order_id) {
      const orderNumberRes: ApiResponse = await GetOrderNumberApi();

      if (orderNumberRes.success) {
        const order_id = orderNumberRes.data.order_id;
        setOrder((o) => ({ ...defaultOrderVal, order_id }));
      } else showToast({ type: ToastType.error, text: orderNumberRes.error });
    } else {
      setOrder((o) => ({ ...defaultOrderVal }));
    }

    setBalTxnAmt(0);
    setBalTxns([]);
    setStep(0);
    setSubscription({ ...subscriptionPlanDefault });
    setUser(undefined);
    setBilling(undefined);
    setDeliveryProfile(undefined);
    setSteps((o) =>
      o.map((s) => {
        s.validated = false;
        return s;
      })
    );
  };
  const getBalTxn = async () => {
    setLoading(true);
    const res = await GetCustomerTxnBalApi(user?.uid);
    if (res.success) {
      setBalTxns(res.data);
      setBalTxnAmt(GetTxnAmt(res.data));
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };
  useEffect(() => {
    if (user) {
      setBilling(undefined);
      getBalTxn();
      getLastOrder();
    }
  }, [user]);

  const init = async () => {
    setLoading(true);
    setSteps((o) => [...defautSteps]);
    const orderNumberRes: ApiResponse = await GetOrderNumberApi();

    if (orderNumberRes.success) {
      const order_id = orderNumberRes.data.order_id;
      setOrder((o) => ({ ...defaultOrderVal, order_id }));
    } else showToast({ type: ToastType.error, text: orderNumberRes.error });
    if (products.length === 0) {
      const productRes = await GetProductsApi();
      if (productRes.success) {
        setProducts(productRes.data);
      } else showToast({ type: ToastType.error, text: productRes.error });
    }
    if (discoms.length === 0) {
      const discomRes = await GetDiscomApi();
      if (discomRes.success) {
        const t = discomRes.data;
        setDiscoms(
          t.sort((a: any, b: any) => {
            var x = a.state.toLowerCase();
            var y = b.state.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
          })
        );
      } else showToast({ type: ToastType.error, text: discomRes.error });
    }
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    setSteps((o) => [
      ...o.map((s, i) => {
        if (i === 4) {
          s.required = subscriptionReq;
        }
        return s;
      }),
    ]);
  }, [subscriptionReq]);
  const selectLastOrder = () => {
    if (!lastOrder) return;
    const {
      type,
      category,
      segment,
      source,
      sales_person_id,
      sales_person_name,
      rm_uid,
      rm_name,
      rm2_name,
      rm2_uid,
      discom,
      meter_type,
      load_sanction,
      registration_doc_issued,
      no_device_registered,
    } = lastOrder!;
    setOrder((ol) => ({
      ...ol,
      type,
      category,
      segment,
      source,
      sales_person_id,
      sales_person_name,
      rm_uid,
      rm_name,
      rm2_name,
      rm2_uid,
      discom,
      meter_type,
      load_sanction,
      registration_doc_issued,
      no_device_registered,
    }));
  };
  const getLastOrder = async () => {
    setLoading(true);
    const res = await GetLastOrderOfCustomerApi(user!.uid);
    if (res.success) {
      setLastOrder(res.data);
    } else {
      showToast({ type: ToastType.error, text: res.error });
    }
    setLoading(false);
  };

  const onCreateOrder = async () => {
    setLoading(true);
    const form = new FormData();
    order.customer = billing!;
    order.customer_id = billing?.id!;
    order.customer_id = billing?.id!;
    order.delivery_profile = deliveryProfile!;
    order.delivery_profile_id = deliveryProfile?.id!;
    order.user_id = user?.uid!;
    order.transactionData.forEach((txn, i) => {
      if (txn.files && txn.files.length > 0)
        form.append("txn_attachment", txn.files[0], txn.id);
    });
    form.append("data", JSON.stringify(order));
    if (subscriptionReq)
      form.append("subscription", JSON.stringify(subscription));
    form.append("use_bal", `${useBal}`);
    const attachment_category: { [key: string]: string } = {};
    attachments.forEach((at, i) => {
      const { file, category } = at;
      const exe = file.name.split(".").pop();
      const fileName = `file-${i}.${exe}`;
      form.append("attachment", file, fileName);
      attachment_category[fileName] = category;
    });
    form.append("attachment_category", JSON.stringify(attachment_category));
    const res = await CreateOrderApi(form);
    if (res.success) {
      showToast({ type: ToastType.success, text: res.message });
      await reset(true);
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  const changeStep = (s: number) => {
    if (steps[step].required && step === 0 && s > step) {
      const valid =
        !billing || !deliveryProfile
          ? "Please select customer and billing profile! "
          : true;
      setSteps((o) =>
        o.map((f, i) => {
          if (i === step) {
            f.validated = typeof valid !== "string";
          }
          return f;
        })
      );
      if (typeof valid === "string") {
        showToast({ type: ToastType.error, text: valid });
        return;
      }
    }
    if (steps[step].required && step === 1 && s > step) {
      const valid = orderDetailValidator(order);
      setSteps((o) =>
        o.map((f, i) => {
          if (i === step) {
            f.validated = typeof valid !== "string";
          }
          return f;
        })
      );
      if (typeof valid === "string") {
        showToast({ type: ToastType.error, text: valid });
        return;
      }
    }
    if (steps[step].required && step === 2 && s > step) {
      const valid =
        order.items.length === 0 ? "Please select product/services! " : true;
      setSteps((o) =>
        o.map((f, i) => {
          if (i === step) {
            f.validated = typeof valid !== "string";
          }
          return f;
        })
      );
      if (typeof valid === "string") {
        showToast({ type: ToastType.error, text: valid });
        return;
      }
    }

    if (steps[step].required && step === 4 && s > 4) {
      const valid =
        !subscription || !subscription.org_namespace
          ? "Please select subscription plan! "
          : true;
      setSteps((o) =>
        o.map((f, i) => {
          if (i === step) {
            f.validated = typeof valid !== "string";
          }
          return f;
        })
      );
      if (typeof valid === "string") {
        showToast({ type: ToastType.error, text: valid });
        return;
      }
    }

    let allowed = true;
    for (let i = 0; i < s; i++) {
      const st = steps[i];

      if (st.required && !st.validated) {
        allowed = false;
        break;
      }
    }

    if (allowed) setStep(s);
  };

  const value: OrderCreateCtxModel = {
    order,
    setOrder,
    products,
    discoms,
    user,
    setUser,
    billing,
    setBilling,
    balTxns,
    balTxnAmt,
    useBal,
    setUseBal,
    setStep: changeStep,
    step,
    onCreateOrder,
    steps,
    subscription,
    setSubscription,
    attachments,
    setAttachments,
    namespaces,
    setNamespaces,
    reset,
    subscriptionReq,
    setSubscriptionReq,
    lastOrder,
    selectLastOrder,
    prefilled,
    setPrefilled,
    setDeliveryProfile,
    deliveryProfile,
  };

  return <context.Provider value={value}>{children ?? <></>}</context.Provider>;
};

export default OrderCreateContext;

export const orderDetailValidator = (order: OrderModel) => {
  try {
    if (!order.order_date || !moment(order.order_date).isValid())
      return "Order date required !";
    if (!moment(order.order_date).isValid()) return "Order date is invalid !";
    if (!order.order_id) return "Order id required !";
    if (!order.type) return "Order type required !";
    if (!order.segment) return "Order lead segment required !";
    // if (!order.source) return "Order lead source required !";
    if (!order.sales_person_id) return "Order sales person required !";
    if (!order.rm_uid) return "Order relationship manager required !";
    if (!order.status) return "Order status required !";
    return true;
  } catch (error: any) {
    return error.message;
  }
};
