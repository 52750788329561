import { FunctionComponent } from "react";
import { academyVideoCategory } from "../../../../../utils/enums";
import VideoLayout from "../components/VideosLayout";

interface HrPoliciesProps {}

const HrPolicies: FunctionComponent<HrPoliciesProps> = () => {
  const segment = academyVideoCategory.HR_Policies;

  return <VideoLayout segment={segment} />;
};

export default HrPolicies;
