import { BaseModel } from "../../../service/models/base";

export const po_payment_req_status = {
    pending: "Pending",
    accepted: "Accepted",
    rejected: "Rejected"
}
export interface PaymentRequestModel extends BaseModel {
    po_no: string;
    amount: number;
    due_date: Date;
    description: string;
    status: string; approved_at?: Date;
    approved_by?: string;
    approved_by_name?: string;
    reason?: string;
}


export const payment_request_default: PaymentRequestModel = {
    po_no: "",
    amount: 0,
    due_date: new Date(),
    description: "",
    status: po_payment_req_status.pending,

}