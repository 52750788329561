import { FunctionComponent } from "react";

interface HrmPopupProps {}

const HrmPopup: FunctionComponent<HrmPopupProps> = (props) => {
  return (
    <div className="fixed top-0 left-0 h-screen w-screen bg-black bg-opacity-50  flex flex-col items-center justify-center z-50">
      {props.children}
    </div>
  );
};

export default HrmPopup;

// backdrop-filter backdrop-blur-sm
