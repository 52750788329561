import { FunctionComponent, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { SubDepartments } from "../../../../../utils/enums";
import { GetLeaveData } from "../../service/repos/analytics";
import Multiselect from "multiselect-react-dropdown";
import EmployeeSearchWidget from "../../../../new_components/emp_search_widget";
import { UserModel } from "../../../../../context_providers/auth/user_model";
import HrmLoader from "../../components/loading";

interface LeavesGraphProps {
  fromDate: number;
  toDate: number;
}

const LeavesGraph: FunctionComponent<LeavesGraphProps> = ({
  fromDate,
  toDate,
}) => {
  const [labels, setLabels] = useState<any[]>([]);
  const [chartSeries, setChartSeries] = useState<any[]>([10, 20, 30, 20]);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchByDept, setSearchByDept] = useState<boolean>(true);
  const [dept, setDept] = useState<string[]>([]);
  const [uid, setUid] = useState<string>("");
  const [total, setTotal] = useState<number>(0);

  const options = {
    chart: {
      id: "basic-bar",
      stacked: true,
    },

    colors: ["#FD9E33", "#4B69FA", "#AEEBEE", "#C3FB67", "#FFEA47"],

    labels: searchByDept ? labels : [...labels, "Attended office"],
  };

  const getLeaveData = async (
    fromDate: number,
    toDate: number,
    dept: any,
    uid: string
  ) => {
    setLoading(true);
    var data;
    if (uid == "" && dept.length === 0) {
      data = {
        fromDate,
        toDate,
      };
    } else {
      if (searchByDept) {
        data = {
          fromDate: fromDate,
          toDate: toDate,
          department: dept,
        };
      } else {
        data = {
          fromDate,
          toDate,
          uid,
        };
      }
    }
    const res = await GetLeaveData(data);
    console.log(res);

    if (res.success) {
      let tempLabel: any = [];
      let tempSeries: any = [];
      let tempTotal = res.data.total_days ? res.data.total_days : 0;
      for (let i = 0; i < res.data?.data.length; i++) {
        tempLabel.push(res.data?.data[i].leave_name);
        tempSeries.push(res.data?.data[i].days);
        tempTotal = tempTotal - res.data.data[i].days;
      }
      setLabels(tempLabel);
      if (searchByDept) {
        setChartSeries(tempSeries);
      } else {
        setChartSeries([...tempSeries, tempTotal]);
      }
      setTotal(res.data?.total_days);
    }

    setLoading(false);
    // hilV1KR5xpsK
  };

  useEffect(() => {
    getLeaveData(fromDate, toDate, dept, uid);
  }, [fromDate, toDate, dept, uid]);

  return (
    <div className="border border-gray-200 p-3 bg-white rounded-2xl">
      <div className="flex justify-between items-center px-2 border-b border-gray-200  pb-2 ">
        <div className="font-bold">Employee leaves</div>
        <div className="flex items-center gap-3">
          <div className="text-sm">Search by employee</div>

          <div
            className={`w-9 h-4 rounded-full bg-gray-300 cursor-pointer flex  ${
              !searchByDept ? "justify-end" : "justify-start"
            }`}
            onClick={() => {
              if (searchByDept) {
                setDept([]);
              } else {
                setUid("");
              }
              setSearchByDept(!searchByDept);
            }}
          >
            <div
              className={`h-4 w-4 rounded-full ${
                !searchByDept ? "bg-green-500" : "bg-hrmBlue"
              }  `}
            ></div>
          </div>
        </div>
      </div>
      <div className="py-3">
        {searchByDept ? (
          <Multiselect
            options={Object.keys(SubDepartments).map((el, i) => {
              return {
                name: el,
                id: i,
              };
            })}
            onSelect={(list, value) => {
              const tempList = list.map((el: { name: string; id: number }) => {
                return el.name;
              });

              setDept(tempList);
            }}
            onRemove={(list, value) => {
              const tempList = list.map((el: { name: string; id: number }) => {
                return el.name;
              });
              setDept(tempList);
            }}
            displayValue="name"
          />
        ) : (
          <div className="">
            <EmployeeSearchWidget
              withBtn={false}
              onSelect={(e: UserModel) => setUid(e.uid)}
            />
          </div>
        )}
      </div>

      {loading ? (
        <div className="flex flex-col items-center pt-6">
          <HrmLoader className="w-12 h-12" />
        </div>
      ) : (
        <div className="pt-3">
          {!searchByDept && uid === "" ? (
            <div className="">Please select employee</div>
          ) : (
            <Chart
              options={options}
              series={chartSeries}
              type="donut"
              width="100%"
            />
          )}

          {/* {!searchByDept && (
            <div className="pt-12 text-center font-semibold">
              Total days: {total}
            </div>
          )} */}
        </div>
      )}
    </div>
  );
};

export default LeavesGraph;
