import { FunctionComponent, useState } from "react";
import { Lock } from "react-feather";
import { useAuth } from "../../../../../context_providers/auth/authProvider";
import Layout from "../../layout";
import ApplicantList from "../components/ApplicantList";
import NonJobApplicantList from "../components/NonJobApplicantList";
import "../styles/index.css";

interface ApplicantsProps {}

const Applicants: FunctionComponent<ApplicantsProps> = () => {
  const [showListView, setShowListView] = useState<boolean>(false);
  const { user } = useAuth();

  return (
    <Layout pageTitle="Applicants">
      {user?.emp_profile?.isReportingManager ? (
        <div className="py-4 pl-4 pr-4 lg:pl-6 lg:pr-6 mt-12">
          <div className="  flex gap-12 border-b mb-4 border-gray-300 text-gray-500  font-semibold">
            <div
              className={`cursor-pointer  ${
                showListView ? "" : "border-b-4 border-hrmBlue"
              } `}
              onClick={() => setShowListView(false)}
            >
              Job applicants
            </div>
            <div
              className={`cursor-pointer  ${
                showListView ? "border-b-4 border-hrmBlue" : ""
              } `}
              onClick={() => setShowListView(true)}
            >
              Open applicants
            </div>
          </div>

          {!showListView ? <ApplicantList /> : <NonJobApplicantList />}
        </div>
      ) : (
        <div className="flex flex-col items-center py-32">
          <div className="">
            <img src="/images/noData.png" alt="" className="w-64" />
          </div>
          <div className="text-xl mt-12">
            Only reporting managers can access this page.
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Applicants;
