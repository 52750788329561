import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { UTCToLocalDate } from "../../../../../utils/date_util";
import { TransactionStatus, TransactionType } from "../../../../../utils/enums";
import { formToObject } from "../../../../../utils/formDataToJson";

interface TxnFilterWidgetProps {
  onClose: () => void;
  onReset: () => void;
  filter?: {
    fromDate?: Date;
    toDate?: Date;
    status?: string;
    type?: string;
    search?: string;
  };
  onSubmit: (data: any) => void;
}

const TxnFilterWidget: FunctionComponent<TxnFilterWidgetProps> = (props) => {
  // const props = useAppSelector((props) => props.TxnReducer);

  // const dispatch = useAppDispatch();

  const [filter, setFilter] = useState<{
    fromDate?: Date;
    toDate?: Date;
    status?: string;
    type?: string;
    search?: string;
  }>({});
  useEffect(() => {
    setFilter({ ...props.filter });
  }, [props.filter]);

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let form = new FormData(e.currentTarget);
    form.append("page", "1");
    form.append("count", "50");

    const data = formToObject(form);

    if (Object.keys(data).length > 2) {
      if (data.fromDate) {
        data.fromDate = UTCToLocalDate(data.fromDate)!.toISOString();
      }
      if (data.toDate) {
        data.toDate = UTCToLocalDate(data.toDate)!.toISOString();
      }
      props.onSubmit(data);
      // dispatch(FilterTxnThunk(data));
      props.onClose();
    }
  };

  return (
    <form onSubmit={submit}>
      <div className="grid grid-cols-1 md:grid-cols-2  gap-3 items-end p-3">
        <div className="">
          <label htmlFor="from-date">From</label>
          <input
            id="from-date"
            className="border rounded p-2 w-full"
            type="date"
            name="fromDate"
            placeholder="From"
            value={
              filter?.fromDate
                ? moment(filter?.fromDate).format("YYYY-MM-DD")
                : ""
            }
            onChange={(e) =>
              setFilter((old) => ({
                ...old,
                fromDate: UTCToLocalDate(e.target.value)!,
              }))
            }
          />
        </div>
        <div className="">
          <label htmlFor="to-date">To</label>
          <input
            id="to-date"
            className="border rounded p-2 w-full"
            type="date"
            name="toDate"
            placeholder="From"
            value={
              filter?.toDate ? moment(filter?.toDate).format("YYYY-MM-DD") : ""
            }
            onChange={(e) =>
              setFilter((old) => ({
                ...old,
                toDate: UTCToLocalDate(e.target.value)!,
              }))
            }
          />
        </div>

        <div className="">
          <label htmlFor="type" className="">
            Transaction Type
          </label>
          <select
            id="type"
            name="type"
            className="border rounded p-2 w-full"
            aria-label="Default select example"
            value={filter?.type}
            onChange={(e) =>
              setFilter((old) => ({ ...old, type: e.target.value }))
            }
          >
            <option value="" hidden>
              Select...
            </option>
            {Object.values(TransactionType).map((type: string, i: number) => (
              <option key={i} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>
        <div className="">
          <label htmlFor="order-status" className="">
            Transaction status
          </label>
          <select
            id="order-status"
            name="status"
            className="border rounded p-2 w-full"
            aria-label="Default select example"
            value={filter?.status}
            onChange={(e) =>
              setFilter((old) => ({ ...old, status: e.target.value }))
            }
          >
            <option value="">Select...</option>
            {Object.values(TransactionStatus).map(
              (status: string, i: number) => (
                <option key={i} value={status}>
                  {status}
                </option>
              )
            )}
          </select>
        </div>
      </div>
      <div className="flex justify-end">
        <button
          className="px-3 py-1 mx-3 bg-gray-200 rounded text-white hover:bg-red-800"
          type="reset"
          onClick={() => props.onReset()}
        >
          Reset
        </button>
        <button
          className="px-3 py-1 mx-3 bg-myPrimaryColor rounded text-white  "
          type="submit"
        >
          Apply
        </button>
      </div>
    </form>
  );
};

export default TxnFilterWidget;
