import {
  mdiCheck,
  mdiChevronDown,
  mdiChevronUp,
  mdiClose,
  mdiDelete,
  mdiPencil,
  mdiPlus,
} from "@mdi/js";
import Icon from "@mdi/react";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import {
  useToast,
  ToastType,
} from "../../../../../context_providers/toast/toast_ctx";
import OrderItemModel from "../../../../../service/models/orders/order_item_model";
import ProductModel from "../../../../../service/models/product_model";
import { numberRegEx } from "../../../../../utils/constant";
import { currencyFormat } from "../../../../../utils/orders_utils";
import {
  itemMovementDefaultValue,
  ItemMovementModel,
} from "../../../../inventory/models/inventory_voucher";
import { ItemModel } from "../../../../inventory/models/item_model";
import WarehouseSearchField from "../../../../inventory/warehouse/warehouse_search_field";
import {
  defaultSalesValue,
  SalesVoucherModel,
} from "../../../models/sale_model ";

interface RecieptSplitRowProps {
  pending_bills: SalesVoucherModel[];
  max_amount: number;
  reciept_split?: {
    voucher_id: string;
    voucher: SalesVoucherModel;
    amount: number;
  };
  onSubmit: (data: {
    id?: string;
    voucher_id: string;
    voucher: SalesVoucherModel;
    amount: number;
  }) => void;
  edit: boolean;
  disabled: boolean;
  onDelete?: (i: {
    id?: string;
    voucher_id: string;
    amount: number;
    voucher: SalesVoucherModel;
  }) => void;
}

const RecieptSplitRow: FunctionComponent<RecieptSplitRowProps> = (props) => {
  const [data, setData] = useState<{
    voucher_id: string;
    voucher: SalesVoucherModel;
    amount: number;
  }>(
    props.reciept_split || {
      voucher_id: "",
      voucher: defaultSalesValue,
      amount: 0,
    }
  );
  const { showToast } = useToast();
  const [showMore, setShowMore] = useState(false);
  const { edit, onSubmit } = props;

  const [editable, setEditable] = useState(false);
  useEffect(() => {
    setEditable(edit);
  }, []);
  const onDone = () => {
    if (!data.voucher || !data.voucher_id || !data.amount) {
      alert("Please select Bill and amount");
      // showToast({
      //   type: ToastType.warning,
      //   text: "Please select Bill and amount",
      // });
      // return;
    }
    onSubmit(data);
  };

  let date;
  let due_date;
  if (data.voucher) {
    date = new Date(data.voucher.date);
    due_date = new Date(
      new Date(date).setDate(
        date.getDate() + (data.voucher.credit_period_day || 0)
      )
    );
  }
  return (
    <>
      <form
        // id={props.form_id}
        onSubmit={(e) => {
          e.preventDefault();
          onDone();
        }}
        className="bg-white    p-1   "
      >
        <div className="grid grid-cols-7 px-2 pt-2 ">
          {/* <div className="">
            {data.voucher ? moment(data.voucher.date).format("DD-MM-YYYY") : ""}
          </div> */}
          <div className="col-span-2">
            <select
              disabled={!editable || props.disabled}
              value={data.voucher_id}
              onChange={(e) => {
                const v = props.pending_bills.find(
                  (val) => val.id === e.target.value
                );
                setData((o) => ({
                  ...o,
                  voucher: v!,
                  voucher_id: v!.id,
                }));
              }}
            >
              <option value="" hidden>
                select
              </option>
              {props.pending_bills.map((pending) => (
                <option value={pending.id}>
                  {`${moment(pending.date).format("DD-MM-YYYY")} - ${
                    pending.party_ledger_entry.ledger?.name
                  } - ${currencyFormat(pending.total)}`}
                </option>
              ))}
            </select>
          </div>
          <div className="">{data.voucher_id}</div>
          <div className=""> {moment(due_date).format("DD-MM-YYYY")}</div>
          <div className="">
            {data.voucher.total - (data.voucher.paid || 0)}
          </div>
          <div className="">
            <input
              disabled={!editable || props.disabled}
              value={data?.amount || 0}
              onChange={(e) => {
                const val = e.target.value.trim();
                if (!val || numberRegEx.test(val)) {
                  const value = Number(val || 0);

                  if (props.max_amount < value) {
                    // showToast({
                    //   type: ToastType.info,
                    //   text: `Max amount is ${props.max_amount}`,
                    // });
                    alert(`Max amount is ${props.max_amount}`);
                    return;
                  }

                  setData((o) => ({ ...o, amount: value }));
                  // if (!voucherData.reciept_split)
                  //   voucherData.reciept_split = [];
                  // if (split)
                  //   voucherData.reciept_split =
                  // voucherData.reciept_split.map((v) => {
                  //   if (v.voucher_id === split.voucher.id) {
                  //     v.amount = value;
                  //   }
                  //   return v;
                  // });
                  // else {
                  //   voucherData.reciept_split.push({
                  //     voucher_id: split.voucher_id,
                  //     amount: value,
                  //     voucher: split.voucher,
                  //   });
                  // }
                }
              }}
              placeholder=""
              type="number"
              className="text-right  p-1 focus:outline-none border rounded  w-full  "
            />
          </div>

          <div className="flex gap-2 items-center justify-end">
            {props.reciept_split ? (
              !props.disabled && (
                <>
                  <div className="flex justify-center gap-2">
                    {editable ? (
                      <>
                        <div
                          onClick={() => {
                            if (!window.confirm("Are you sure !")) return;
                            onDone();
                            setEditable(false);
                          }}
                          className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                        >
                          <Icon path={mdiCheck} className="h-4 w-4"></Icon>
                        </div>
                        <div
                          onClick={() => {
                            setData(props.reciept_split!);
                            setEditable(false);
                          }}
                          className="bg-red-100 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                        >
                          <Icon path={mdiClose} className="h-4 w-4"></Icon>
                        </div>
                      </>
                    ) : (
                      <div
                        onClick={() => setEditable(true)}
                        className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                      >
                        <Icon path={mdiPencil} className="h-4 w-4"></Icon>
                      </div>
                    )}
                  </div>
                  <div
                    onClick={() => {
                      if (!window.confirm("Are you sure !")) return;
                      if (props.onDelete) props.onDelete(data);
                    }}
                    className="bg-red-200 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                  >
                    <Icon path={mdiDelete} className="h-4 w-4"></Icon>
                  </div>
                </>
              )
            ) : (
              <button
                // form="item_form"
                className="flex gap-1 text-white text-sm bg-myPrimaryColor rounded-full px-2 py-1  "
              >
                <Icon path={mdiPlus} className="h-5 w-5 "></Icon> Add
              </button>
            )}
            {/* {props.reciept_split && (
              <div
                onClick={() => setShowMore((o) => !o)}
                className="p-1 bg-gray-100 rounded cursor-pointer hover:bg-gray-200"
              >
                <Icon
                  path={showMore ? mdiChevronUp : mdiChevronDown}
                  className="h-4 w-4"
                ></Icon>
              </div>
            )} */}
          </div>
        </div>
        {/* {showMore && props.item && (
          <div className="px-2 py-1">
            <hr className=" " />
            <div className="text-xs text-gray-400">
              <p>Id : {props.item.id}</p>
              <p>Extra : {rupeeFormat(props.item.addition_amt ?? 0)}</p>
              <p>Discount : {rupeeFormat(props.item.discount_amt ?? 0)}</p>
              <p>Paid : {rupeeFormat(props.item.paid ?? 0)}</p>
            </div>
            <div className="flex   justify-between gap-2 my-1">
              <p className="text-xs ">
                Created by :<br />
                <div className="flex gap-1">
                  <div className="">
                    <b>{props.item.created_by_name ?? "N/A"}</b>
                  </div>
                  <div className="">
                    {props.item.created_at
                      ? moment(props.item.created_at).format(
                          "DD MMM YYYY h:mm a"
                        )
                      : "--"}
                  </div>
                </div>
              </p>
              <p className="text-xs ">
                Updated by :<br />
                <div className="flex gap-1">
                  <div className="">
                    <b>{props.item.updated_by_name ?? "N/A"}</b>
                  </div>
                  <div className="">
                    {props.item.updated_at
                      ? moment(props.item.updated_at).format(
                          "DD MMM YYYY h:mm a"
                        )
                      : "--"}
                  </div>
                </div>
              </p>
            </div>
          </div>
        )} */}
      </form>
    </>
  );
};

export default RecieptSplitRow;
