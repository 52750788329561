import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { GetOrderLogsApi } from "../../../../../service/repos/order_repo";
import { OrderLogType } from "../../../../../utils/enums";
import LoadingWidget from "../../../../components/loading_spinner";
import Pagination from "../../../../new_components/common/pagination";
import { isObject } from "../../../../../utils/import_export_parser";
export interface OrderLogModel {
  id: string;
  operation: String;
  user_id: string;
  order_id: string;
  created_at: string;
  user: string;
  log_type?: string;
  params?: any;
}
interface ActionHistoryProps {
  order_id: string;
  log_type?: string;
  // show: boolean;
  // onClose: (val: boolean) => void;
}

const ActionHistory: FunctionComponent<ActionHistoryProps> = (props) => {
  const [logs, setLogs] = useState<OrderLogModel[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(10);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    GetOrderLogsApi({
      order_id: props.order_id,
      page,
      count,
      log_type: props.log_type,
    })
      .then((res) => {
        if (res.success) {
          setLogs(res.data.logs.reverse());

          if (res.data.metadata[0]) {
            setTotal(res.data.metadata[0].total);
          }
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [page]);
  useEffect(() => {
    scrollBottom();
  }, [logs]);
  const scrollBottom = () => {
    const container = document.getElementById("history_wrap");
    if (container) container.scrollTop = container.scrollHeight;
  };
  return (
    <div className=" ">
      <div className="flex flex-col justify-between h-full">
        <div
          id="history_wrap"
          className="overflow-auto p-1 bg-gray-100"
          style={{ height: "88vh" }}
        >
          {logs.length === 0 && (
            <p className="text-center my-10">No History!</p>
          )}
          {logs.map((log, i) => (
            <div key={i} className="  mb-2 shadow rounded bg-white p-1">
              {log.log_type ? (
                getOrderLogTemplate(log)
              ) : (
                <div>
                  <b>{log.user}</b>
                  <p className="text-sm"> {log.operation}</p>
                  <div className="flex justify-end items-end py-2">
                    <span className="text-xs italic">
                      {moment(log.created_at).format("lll")}
                    </span>
                  </div>
                  {/* <hr className="my-2" /> */}
                </div>
              )}
            </div>
          ))}
        </div>
        <Pagination
          page={page}
          total={total}
          count={count}
          itemCount={logs.length}
          onChange={(page: number) => setPage(page)}
        />
      </div>
      <LoadingWidget loading={loading} />
    </div>
  );
};

export default ActionHistory;

const getOrderLogTemplate = (log: OrderLogModel) => {
  switch (log.log_type) {
    case OrderLogType.created:
      return (
        <>
          <p className="text-sm">
            <b>{log.user}</b> has created the order #{" "}
            <b className="text-blue-500">{log.params.order_id}</b>
          </p>
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    case OrderLogType.updated:
      return (
        <>
          <p className="text-sm">
            <b>{log.user}</b> has{" "}
            <span className="text-yellow-500 font-semibold">Updated </span> the
            Order details.
          </p>
          <table className="border rounded w-full overflow-auto text-xs">
            <thead className="border rounded">
              <tr>
                <th className="">Field</th>
                <th>Previous value</th>
                <th>New value</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(log.params.new_value).map((l, i) => (
                <tr key={i} className="border-b">
                  <td align="center">{l}</td>
                  <td align="center" className=" bg-red-100 text-red-800">
                    {typeof log.params.pre_value[l] === "object" ? (
                      <span title={JSON.stringify(log.params.pre_value[l])}>
                        view
                      </span>
                    ) : (
                      log.params.pre_value[l]
                    )}
                  </td>
                  <td align="center" className="bg-green-100 text-green-800">
                    {typeof log.params.new_value[l] == "object" ? (
                      <span title={JSON.stringify(log.params.new_value[l])}>
                        view
                      </span>
                    ) : (
                      log.params.new_value[l]
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>{" "}
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    case OrderLogType.item_added:
      return (
        <>
          <p className="text-sm">
            <b>{log.user}</b> has{" "}
            <span className="text-green-500 font-semibold">Added </span> product
            or services.
          </p>
          <table className="border rounded w-full overflow-auto text-xs">
            <thead className="border rounded">
              <tr>
                <th className="">Product/Service</th>
                <th>Qty</th>
                <th>Price</th>
                <th>Del Qty</th>
                <th>Ins Qty</th>
                <th>Ret Qty</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b bg-green-100 text-green-800">
                <td align="center">{log.params.item.name}</td>
                <td align="center">{log.params.item.qty}</td>
                <td align="center">{log.params.item.price}</td>
                <td align="center">{log.params.item.delivered_qty}</td>
                <td align="center">{log.params.item.installed_qty}</td>
                <td align="center">{log.params.item.returned_qty}</td>
                <td align="center">
                  {log.params.item.price * log.params.item.qty}
                </td>
              </tr>
            </tbody>
          </table>{" "}
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    case OrderLogType.item_update:
      return (
        <>
          <p className="text-sm">
            <b>{log.user}</b> has{" "}
            <span className="text-yellow-500 font-semibold">Updated </span>{" "}
            product or services.
          </p>
          <table className="border rounded w-full overflow-auto text-xs">
            <thead className="border rounded">
              <tr>
                <th className=""> </th>
                <th className="">Product/Service</th>
                <th>Qty</th>
                <th>Price</th>
                <th>Del Qty</th>
                <th>Ins Qty</th>
                <th>Ret Qty</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b bg-red-100 text-red-800">
                <td align="left">- Previous</td>
                <td align="center">{log.params.pre_value.name}</td>
                <td align="center">{log.params.pre_value.qty}</td>
                <td align="center">{log.params.pre_value.price}</td>
                <td align="center">{log.params.pre_value.delivered_qty}</td>
                <td align="center">{log.params.pre_value.installed_qty}</td>
                <td align="center">{log.params.pre_value.returned_qty}</td>
                <td align="center">
                  {log.params.pre_value.price * log.params.pre_value.qty}
                </td>
              </tr>
              <tr className="border-b bg-green-100 text-green-800">
                <td align="left">+ New</td>
                <td align="center">{log.params.new_value.name}</td>
                <td align="center">{log.params.new_value.qty}</td>
                <td align="center">{log.params.new_value.price}</td>
                <td align="center">{log.params.new_value.delivered_qty}</td>
                <td align="center">{log.params.new_value.installed_qty}</td>
                <td align="center">{log.params.new_value.returned_qty}</td>
                <td align="center">
                  {log.params.new_value.price * log.params.new_value.qty}
                </td>
              </tr>
            </tbody>
          </table>{" "}
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    case OrderLogType.item_deleted:
      return (
        <>
          <p className="text-sm">
            <b>{log.user}</b> has{" "}
            <span className="text-red-500 font-semibold">Removed </span> product
            or services.
          </p>
          <table className="border rounded w-full overflow-auto text-xs">
            <thead className="border rounded">
              <tr>
                <th className="">Product/Service</th>
                <th>Qty</th>
                <th>Price</th>
                <th>Del Qty</th>
                <th>Ins Qty</th>
                <th>Ret Qty</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b   bg-red-100 text-red-800">
                <td align="center">{log.params.item.name}</td>
                <td align="center">{log.params.item.qty}</td>
                <td align="center">{log.params.item.price}</td>
                <td align="center">{log.params.item.delivered_qty}</td>
                <td align="center">{log.params.item.installed_qty}</td>
                <td align="center">{log.params.item.returned_qty}</td>
                <td align="center">
                  {log.params.item.price * log.params.item.qty}
                </td>
              </tr>
            </tbody>
          </table>{" "}
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    case OrderLogType.extra_added:
      return (
        <>
          <p className="text-sm">
            <b>{log.user}</b> has{" "}
            <span className="text-green-500 font-semibold">Added </span> Extras.
          </p>
          <table className="border rounded w-full overflow-auto text-xs">
            <thead className="border rounded">
              <tr>
                <th className="">Category</th>
                <th>Description</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b bg-green-100 text-green-800">
                <td align="center">{log.params.extra.category}</td>
                <td align="center">{log.params.extra.name}</td>
                <td align="center">{log.params.extra.amount}</td>
              </tr>
            </tbody>
          </table>{" "}
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    case OrderLogType.extra_update:
      return (
        <>
          <p className="text-sm">
            <b>{log.user}</b> has{" "}
            <span className="text-yellow-500 font-semibold">Updated </span>{" "}
            Extras.
          </p>
          <table className="border rounded w-full overflow-auto text-xs">
            <thead className="border rounded">
              <tr>
                <th className=""> </th>
                <th className="">Category</th>
                <th>Description</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b bg-red-100 text-red-800">
                <td align="left">- Previous</td>
                <td align="center">{log.params.pre_value.category}</td>
                <td align="center">{log.params.pre_value.name}</td>
                <td align="center">{log.params.pre_value.amount}</td>
              </tr>
              <tr className="border-b bg-green-100 text-green-800">
                <td align="left">+ New</td>
                <td align="center">{log.params.new_value.category}</td>
                <td align="center">{log.params.new_value.name}</td>
                <td align="center">{log.params.new_value.amount}</td>
              </tr>
            </tbody>
          </table>{" "}
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    case OrderLogType.extra_deleted:
      return (
        <>
          <p className="text-sm">
            <b>{log.user}</b> has{" "}
            <span className="text-red-500 font-semibold">Removed </span> Extras.
          </p>
          <table className="border rounded w-full overflow-auto text-xs">
            <thead className="border rounded">
              <tr>
                <th className="">Category</th>
                <th>Description</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b   bg-red-100 text-red-800">
                <td align="center">{log.params.extra.category}</td>
                <td align="center">{log.params.extra.name}</td>
                <td align="center">{log.params.extra.amount}</td>
              </tr>
            </tbody>
          </table>{" "}
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    case OrderLogType.discount_added:
      return (
        <>
          <p className="text-sm">
            <b>{log.user}</b> has{" "}
            <span className="text-green-500 font-semibold">Added </span>{" "}
            Discount.
          </p>
          <table className="border rounded w-full overflow-auto text-xs">
            <thead className="border rounded">
              <tr>
                <th>Description</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b bg-green-100 text-green-800">
                <td align="center">{log.params.discount.name}</td>
                <td align="center">{log.params.discount.amount}</td>
              </tr>
            </tbody>
          </table>{" "}
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    case OrderLogType.discount_update:
      return (
        <>
          <p className="text-sm">
            <b>{log.user}</b> has{" "}
            <span className="text-yellow-500 font-semibold">Updated </span>{" "}
            Discount.
          </p>
          <table className="border rounded w-full overflow-auto text-xs">
            <thead className="border rounded">
              <tr>
                <th className=""> </th>
                <th>Description</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b bg-red-100 text-red-800">
                <td align="left">- Previous</td>

                <td align="center">{log.params.pre_value.name}</td>
                <td align="center">{log.params.pre_value.amount}</td>
              </tr>
              <tr className="border-b bg-green-100 text-green-800">
                <td align="left">+ New</td>

                <td align="center">{log.params.new_value.name}</td>
                <td align="center">{log.params.new_value.amount}</td>
              </tr>
            </tbody>
          </table>{" "}
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    case OrderLogType.discount_deleted:
      return (
        <>
          <p className="text-sm">
            <b>{log.user}</b> has{" "}
            <span className="text-red-500 font-semibold">Removed </span>{" "}
            Discount.
          </p>
          <table className="border rounded w-full overflow-auto text-xs">
            <thead className="border rounded">
              <tr>
                <th>Description</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b   bg-red-100 text-red-800">
                <td align="center">{log.params.discount.name}</td>
                <td align="center">{log.params.discount.amount}</td>
              </tr>
            </tbody>
          </table>{" "}
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    case OrderLogType.commented:
      return (
        <>
          <p className="text-sm">
            <b>{log.user}</b> has{" "}
            <span className="text-green-500 font-semibold">Added </span>{" "}
            comment.{" "}
          </p>
          <p className="text-xs italic">{log.params.comment}</p>
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    case OrderLogType.comment_deleted:
      return (
        <>
          <p className="text-sm">
            <b>{log.user}</b> has{" "}
            <span className="text-red-500 font-semibold">Deleted </span> comment
          </p>
          <p className="text-xs italic">{log.params.comment}</p>
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    case OrderLogType.created_complaint:
      return (
        <>
          <p className="text-sm">
            <b>{log.user}</b> has created complaint with ID :{" "}
            <b className="text-blue-500">{log.params.complaint_id}</b>
          </p>
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    case OrderLogType.customer_update:
      return (
        <>
          <p className="text-sm">
            <b>{log.user}</b> has{" "}
            <span className="text-yellow-500 font-semibold">Updated </span>{" "}
            Billing Profile.
          </p>
          <table className="border rounded w-full overflow-auto text-xs">
            <thead className="border rounded">
              <tr>
                <th className=""> </th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Mobile</th>
                <th>Alt Mobile</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b bg-red-100 text-red-800">
                <td align="left">- Previous</td>

                <td align="center">{log.params.pre_value.firstName}</td>
                <td align="center">{log.params.pre_value.lastName}</td>
                <td align="center">{log.params.pre_value.mobile}</td>
                <td align="center">{log.params.pre_value.alt_mobile}</td>
                <td align="center">{log.params.pre_value.email}</td>
              </tr>
              <tr className="border-b bg-green-100 text-green-800">
                <td align="left">+ New</td>

                <td align="center">{log.params.new_value.firstName}</td>
                <td align="center">{log.params.new_value.lastName}</td>
                <td align="center">{log.params.new_value.mobile}</td>
                <td align="center">{log.params.new_value.alt_mobile}</td>
                <td align="center">{log.params.new_value.email}</td>
              </tr>
            </tbody>
          </table>{" "}
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    case OrderLogType.address_update:
      return (
        <>
          <p className="text-sm">
            <b>{log.user}</b> has{" "}
            <span className="text-yellow-500 font-semibold">Updated </span>{" "}
            Billing Address.
          </p>
          <table className="border rounded w-full overflow-auto text-xs">
            <thead className="border rounded">
              <tr>
                <th className=""> </th>
                <th>Type</th>
                <th>Line 1</th>
                <th>Line 2</th>
                <th>City</th>
                <th>State</th>
                <th>Country</th>
                <th>Picode</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b bg-red-100 text-red-800">
                <td align="left">- Previous</td>

                <td align="center">{log.params.pre_value.type}</td>
                <td align="center">{log.params.pre_value.line1}</td>
                <td align="center">{log.params.pre_value.line2}</td>
                <td align="center">{log.params.pre_value.city}</td>
                <td align="center">{log.params.pre_value.state}</td>
                <td align="center">{log.params.pre_value.country}</td>
                <td align="center">{log.params.pre_value.pincode}</td>
              </tr>
              <tr className="border-b bg-green-100 text-green-800">
                <td align="left">+ New</td>

                <td align="center">{log.params.new_value.type}</td>
                <td align="center">{log.params.new_value.line1}</td>
                <td align="center">{log.params.new_value.line2}</td>
                <td align="center">{log.params.new_value.city}</td>
                <td align="center">{log.params.new_value.state}</td>
                <td align="center">{log.params.new_value.country}</td>
                <td align="center">{log.params.new_value.pincode}</td>
              </tr>
            </tbody>
          </table>{" "}
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );

    case OrderLogType.payment_pending:
      return (
        <>
          <p className="text-sm">
            <b>{log.user}</b> has{" "}
            <span className="text-yellow-500 font-semibold">Updated </span>{" "}
            payment.
          </p>
          <table className="border rounded w-full overflow-auto text-xs">
            <thead className="border rounded">
              <tr>
                <th className=""> </th>
                <th>Txn Id</th>
                <th>Mode</th>
                <th>Status</th>

                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b bg-red-100 text-red-800">
                <td align="left">- Previous</td>

                <td align="center">{log.params.pre_value.txn_id}</td>
                <td align="center">{log.params.pre_value.mode}</td>
                <td align="center">{log.params.pre_value.status}</td>
                <td align="center">{log.params.pre_value.amount}</td>
              </tr>
              <tr className="border-b bg-green-100 text-green-800">
                <td align="left">+ New</td>

                <td align="center">{log.params.new_value.txn_id}</td>
                <td align="center">{log.params.new_value.mode}</td>
                <td align="center">{log.params.new_value.status}</td>
                <td align="center">{log.params.new_value.amount}</td>
              </tr>
            </tbody>
          </table>{" "}
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );

    case OrderLogType.payment_approved:
      return (
        <>
          <p className="text-sm">
            <b>{log.user}</b> has{" "}
            <span className="text-green-500 font-semibold">Approved </span>{" "}
            payment.
          </p>
          <table className="border rounded w-full overflow-auto text-xs">
            <thead className="border rounded">
              <tr>
                <th className=""> </th>
                <th>Txn Id</th>
                <th>Mode</th>
                <th>Status</th>

                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b bg-red-100 text-red-800">
                <td align="left">- Previous</td>

                <td align="center">{log.params.pre_value.txn_id}</td>
                <td align="center">{log.params.pre_value.mode}</td>
                <td align="center">{log.params.pre_value.status}</td>
                <td align="center">{log.params.pre_value.amount}</td>
              </tr>
              <tr className="border-b bg-green-100 text-green-800">
                <td align="left">+ New</td>

                <td align="center">{log.params.new_value.txn_id}</td>
                <td align="center">{log.params.new_value.mode}</td>
                <td align="center">{log.params.new_value.status}</td>
                <td align="center">{log.params.new_value.amount}</td>
              </tr>
            </tbody>
          </table>{" "}
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    case OrderLogType.payment_rejected:
      return (
        <>
          <p className="text-sm">
            <b>{log.user}</b> has{" "}
            <span className="text-red-500 font-semibold">Rejected </span>{" "}
            payment.
          </p>
          <table className="border rounded w-full overflow-auto text-xs">
            <thead className="border rounded">
              <tr>
                <th className=""> </th>
                <th>Txn Id</th>
                <th>Mode</th>
                <th>Status</th>

                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b bg-red-100 text-red-800">
                <td align="left">- Previous</td>

                <td align="center">{log.params.pre_value.txn_id}</td>
                <td align="center">{log.params.pre_value.mode}</td>
                <td align="center">{log.params.pre_value.status}</td>
                <td align="center">{log.params.pre_value.amount}</td>
              </tr>
              <tr className="border-b bg-green-100 text-green-800">
                <td align="left">+ New</td>

                <td align="center">{log.params.new_value.txn_id}</td>
                <td align="center">{log.params.new_value.mode}</td>
                <td align="center">{log.params.new_value.status}</td>
                <td align="center">{log.params.new_value.amount}</td>
              </tr>
            </tbody>
          </table>{" "}
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );

    case OrderLogType.payment_added:
      return (
        <>
          <p className="text-sm">
            <b>{log.user}</b> has{" "}
            <span className="text-green-500 font-semibold">Added </span>{" "}
            payment.
          </p>
          <table className="border rounded w-full overflow-auto text-xs">
            <thead className="border rounded">
              <tr>
                <th> </th>
                <th>Txn Id</th>
                <th>Mode</th>
                <th>Status</th>

                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b bg-green-100 text-green-800">
                <td align="left">+ New</td>

                <td align="center">{log.params.payment.txn_id}</td>
                <td align="center">{log.params.payment.mode}</td>
                <td align="center">{log.params.payment.status}</td>
                <td align="center">{log.params.payment.amount}</td>
              </tr>
            </tbody>
          </table>{" "}
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );

    case OrderLogType.payment_attachment_added:
      return (
        <>
          <p className="text-sm">
            <b>{log.user}</b> has{" "}
            <span className="text-green-500 font-semibold">Uploaded </span>{" "}
            payment attachments.
          </p>

          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    case OrderLogType.order_attachment_added:
      return (
        <>
          <p className="text-sm">
            <b>{log.user}</b> has{" "}
            <span className="text-green-500 font-semibold">Uploaded </span>{" "}
            Order attachments.
          </p>

          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    default:
      return <>Not handled {log.log_type}</>;
  }
};
