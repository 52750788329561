import { FunctionComponent, useEffect, useState } from "react";
import Sidebar from "../components/sidebar";
// @ts-ignore
import gsap from "gsap/all";
import HrmLoader from "../components/loading";
import { Menu } from "react-feather";
import { Link } from "react-router-dom";
import { urls } from "../../../../utils/urls";

interface LayoutProps {
  pageTitle?: string;
}

const Layout: FunctionComponent<LayoutProps> = (props) => {
  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [dark, setDark] = useState<boolean>(false);
  // const [isDark, setIsDark] = useState<any>(localStorage.getItem("isDark"));

  const handleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const handleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleTheme = () => {
    setDark(!dark);
    const tl = gsap.timeline();
    tl.fromTo(
      ".theme_slider",
      1,
      {
        display: "flex",
        left: "-2000",
      },
      {
        left: 0,
      }
    )
      .fromTo(
        ".theme_point",
        0.5,
        {
          opacity: 0,
        },
        {
          opacity: 1,
        }
      )

      .fromTo(
        ".magic_gif",
        0.5,
        {
          opacity: 0,
        },
        {
          opacity: 1,
        }
      );

    if (dark) {
      tl.fromTo(
        ".theme_slider",
        0.5,
        {
          background: "black",
        },
        {
          background: "white",
          delay: 1,
        }
      );
    } else {
      tl.fromTo(
        ".theme_slider",
        0.5,
        {
          background: "white",
        },
        {
          background: "black",
          delay: 1,
        }
      );
    }

    tl.fromTo(
      ".theme_slider",
      1.5,
      {
        top: 0,
      },
      {
        top: "-1000",
        delay: 1,

        // ease: Bounce.easeOut,
      }
    ).to(".theme_slider", 0.3, {
      display: "none",
    });
  };

  return (
    <div className=" min-h-screen relative ">
      {/* Mobile navbar button */}
      <div className="lg:hidden fixed top-0 left-0 z-40 bg-white rounded-md py-2 px-3 shadow w-screen flex justify-between">
        <Link to={urls.root}>
          <div className="">
            <img src="/kazam-logo-black.png" alt="" className="w-24" />
          </div>
        </Link>
        <Menu
          size={24}
          className="text-hrmBlue"
          onClick={() => setShowSidebar(!showSidebar)}
        />
      </div>

      <Sidebar
        show={showSidebar}
        collapsed={collapsed}
        handleSidebar={handleSidebar}
        handleTheme={handleTheme}
        handleCollapsed={handleCollapsed}
      />

      <div className="data_wrapper relative">
        <main className="min-h-screen py-10 lg:py-2 px-2">
          {" "}
          {props.children}
        </main>
      </div>
    </div>
  );
};

export default Layout;
