import imageCompression from "browser-image-compression";

export const compressImage = async (imageFile: File) => {
  console.log("file : ", imageFile);
  const options = {
    maxSizeMB: 3,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
    // initialQuality: 0.6,
  };
  try {
    if (imageFile.size / 1024 < 500) return imageFile;
    const compressedFile = await imageCompression(imageFile, options);

    return compressedFile;
  } catch (error) {
    throw error;
  }
};
