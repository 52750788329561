import debounce from "lodash.debounce";
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import AutoCompleteField from "../../../new_components/common/autocomplete";
import {
  fetchGet,
  fetchPost,
  toQueryString,
} from "../../../../service/api_client";
import { InventoryModel } from "../../../../service/models/inventory/inventory_model";
import {
  ToastType,
  useToast,
} from "../../../../context_providers/toast/toast_ctx";

interface InventorySearchFieldProps {
  status?: string[];
  product_id?: any[];
  onSelect: (data: InventoryModel) => void;
  disabled?: boolean;
  placeholder?: string;
  value?: { device_id: string; name: string };
}

const InventorySearchField: FunctionComponent<InventorySearchFieldProps> = (
  props
) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/inventory/get`;
  const { showToast } = useToast();
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [addNew, setAddNew] = useState(false);
  const [data, setData] = useState<InventoryModel[]>();
  const getData = async (prop: {
    search?: string;
    page: number;
    count: number;
    all?: boolean;
    status?: string[];
    product_id?: string[];
  }) => {
    setLoading(true);
    try {
      const res = await fetchPost(url, prop);
      if (res.success) {
        setData(res.data.inventories);
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
      //   return { success: false, error: error.message };
    }
    setLoading(false);
  };

  const debouncedHandler = useCallback(
    debounce((query) => {
      if (query)
        getData({
          search: query,
          page: 1,
          count: 1,
          all: true,
          status: props.status,
          product_id: props.product_id,
        });
      else setData(undefined);
    }, 500),
    []
  );

  useEffect(() => {
    if (search && edit) {
      if (props.value && props.value.device_id === search) return;
      debouncedHandler(search);
    }
  }, [search]);
  useEffect(() => {
    setSearch(props.value?.device_id ?? "");
  }, [props.value]);

  return (
    <div className="flex gap-1 items-center    w-full">
      {edit ? (
        <AutoCompleteField
          loading={loading}
          value={search}
          placeholder={props.placeholder ?? "Select a device "}
          onSelect={(val) => {
            setEdit(false);
            setSearch(val.value.device_id);
            props.onSelect(val.value);
          }}
          suggestions={data?.map((d) => ({
            label: (
              <div className="text-xs">
                <b>
                  {d.device.product_name}({d.device_id}){" "}
                </b>
              </div>
            ),

            value: d,
          }))}
          onChange={setSearch}
        />
      ) : (
        <div
          className={`text-sm px-3 py-2  h-8 rounded focus:outline-none w-full border  overflow-hidden overflow-ellipsis${
            search ? "" : " text-gray-400 "
          }`}
          onClick={() => {
            if (!props.disabled) {
              setEdit(true);
              // setSearch(emp?.name ?? "");
            }
          }}
        >
          {search || props.placeholder || "Select a device "}
        </div>
      )}
    </div>
  );
};

export default InventorySearchField;
