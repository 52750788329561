import { ExportToCsv } from "export-to-csv";
import moment from "moment";
import { FunctionComponent, useState, useEffect } from "react";
import {
  useToast,
  ToastType,
} from "../../../../../../context_providers/toast/toast_ctx";
import { UTCToLocalDate } from "../../../../../../utils/date_util";
import LoadingWidget from "../../../../../../ui/components/loading_spinner";
import StadiumButton from "../../../../../../ui/new_components/common/stadium_button";
import { fetchPost } from "../../../../../../service/api_client";
import {
  ItemStatus,
  StockJournalModel,
  StockTransferImportData,
} from "../../../../models/inventory_voucher";

export const GetStockJornalListApi = async (data: {
  voucher_id?: string[];
  status?: string[];
  search?: string;
  page: number;
  count: number;
  all?: any;
  warehouse_id?: string[];
  fromDate?: Date;
  toDate?: Date;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/journal/source_transfer/get`;

    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

interface ExportInventoryProps {
  filter: {
    voucher_id?: string[];
    status?: string[];
    search?: string;
    page?: number;
    count?: number;
    all?: any;
    warehouse_id?: string[];
    fromDate?: Date;
    toDate?: Date;
  };
}

const ExportTransferJournal: FunctionComponent<ExportInventoryProps> = (
  props
) => {
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);

  const [fromDate, setFromdate] = useState<Date>();
  const [toDate, setTodate] = useState<Date>();

  const getTransferVouchers = async () => {
    if (!fromDate || !toDate) {
      showToast({
        type: ToastType.warning,
        text: "Please select date",
      });

      return;
    } else if (
      moment(fromDate, "YYYY-MM-DD").isAfter(moment(toDate, "YYYY-MM-DD"))
    ) {
      showToast({
        type: ToastType.warning,
        text: "From Date should not be greater than To Date ",
      });
      return;
    }
    setLoading(true);
    const res = await GetStockJornalListApi({
      all: true,
      page: 0,
      count: 0,
      ...props.filter,
      fromDate,
      toDate,
    });

    if (res.success) {
      if (res.data.data.length) {
        const options = {
          fieldSeparator: ",",
          quoteStrings: '"',
          decimalSeparator: ".",
          showLabels: true,
          showTitle: true,
          title: "Stock Transfer",
          useTextFile: false,
          useBom: true,
          useKeysAsHeaders: true,
          // headers: [
          //   "Name",
          //   "Dept",
          //   "Earned leave av.",
          //   "Earned leave taken",
          //   "Current month taken",
          //   "Medical leave av.",
          //   "Medical leave taken",
          //   "Current month taken",
          //   "Unpaid leave",
          // ],
        };

        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(
          res.data.data.map((inv: StockJournalModel, i: number) => {
            const allotment = inv.source?.find(
              (od) => od.status === ItemStatus.partialReceipt || od.status === ItemStatus.completed
            );
            const t: StockTransferImportData = {
              voucher_id: allotment?.voucher_id || "",
              date: inv.date || new Date(),
              unit_no: allotment?.unit_no || 0,
              received_unit_no: allotment?.received_unit_no || 0,
              lost_unit_no: allotment?.lost_unit_no || 0,
              price: allotment?.price || 0,
              amount: allotment?.amount || 0,
              movement_type: allotment?.movement_type || "",
              voucher_type: allotment?.voucher_type || "",
              status: allotment?.status || "",
              warehouse_id: allotment?.warehouse_id || "",
              warehouse: allotment?.warehouse.name || "",
            };
            console.log("data that send is:", t)
            return t;
          })
        );
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    const date = new Date();

    if (props.filter?.fromDate && props.filter.toDate) {
      setFromdate(props.filter.fromDate);
      setTodate(props.filter.toDate);
    } else {
      setFromdate(new Date(date.setHours(0, 0, 0, 0)));
      setTodate(new Date(date.setHours(23, 59, 59, 999)));
    }
  }, []);

  return (
    <div className="p-5">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <div className="">
          <label htmlFor="from-date">From</label>
          <input
            id="from-date"
            className="border rounded p-2 w-full"
            type="date"
            name="fromDate"
            placeholder="From"
            required
            value={moment(fromDate).format("YYYY-MM-DD")}
            onChange={(e) => {
              let date = UTCToLocalDate(e.target.value)!;
              date = new Date(date.setHours(0, 0, 0, 0));
              if (toDate && moment(date).isAfter(moment(toDate))) {
                showToast({
                  type: ToastType.warning,
                  text: "From Date should not be greater than To Date ",
                });
                return;
              }
              setFromdate(date);
            }}
          />
        </div>
        <div className="">
          <label htmlFor="to-date">To</label>
          <input
            id="to-date"
            className="border rounded p-2 w-full"
            type="date"
            name="toDate"
            placeholder="To"
            required
            value={moment(toDate).format("YYYY-MM-DD")}
            min={fromDate ? moment(fromDate).format("YYYY-MM-DD") : ""}
            onChange={(e) => {
              let date = UTCToLocalDate(e.target.value)!;
              date = new Date(date.setHours(23, 59, 59, 999));
              if (fromDate && moment(fromDate).isAfter(moment(date))) {
                showToast({
                  type: ToastType.warning,
                  text: "To Date should  be greater than From Date ",
                });
                return;
              }
              setTodate(date);
            }}
          />
        </div>
      </div>
      <div className="flex justify-end my-2">
        <StadiumButton type="submit" onTap={getTransferVouchers}>
          Export
        </StadiumButton>
      </div>
      <LoadingWidget loading={loading} />
    </div>
  );
};

export default ExportTransferJournal;
