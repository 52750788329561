import {
  mdiChevronDown,
  mdiChevronLeft,
  mdiChevronUp,
  mdiClipboard,
  mdiComment,
  mdiCommentOffOutline,
  mdiCommentOutline,
  mdiContentCopy,
  mdiCopyleft,
  mdiFileDocument,
  mdiHistory,
  mdiReload,
} from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import {
  Link,
  NavLink,
  Route,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import {
  ModalAlignment,
  ModalType,
  useModal,
} from "../../../../context_providers/modal/modal_context";
import OrderDetailContext, {
  useOrderDetailCtx,
} from "../../../../context_providers/order_detail_context";
import {
  ToastType,
  useToast,
} from "../../../../context_providers/toast/toast_ctx";
import { OrderStatus } from "../../../../utils/enums";
import { urls } from "../../../../utils/urls";
import ActionHistory from "../order_list/components/history_logs";
import OrderComments from "../order_list/components/order_comments";
import BillingProfileWidget from "./billing_profile/billing_profile_widget";
import CommunicationWidget from "../order_list/components/communication_widget";
import DiscountSectionWidget from "./dicount_section/discount_section_widget";
import ExtraSectionWidget from "./extra_section/extra_section_widget";
import OrderAttachmentWidget from "./order_attachement_widget";
import OrderDetailSection from "./order_detail_section";
import OrderDeviceSection from "./order_devices/order_devices_section";
import OrderSummery from "./order_summery";
import PaymentSectionWidget from "./payments/payment_section_widget";
import ProductSection from "./product/product_section";
import SubscriptionSection from "./subscription/subscription_section";
import { Tooltip } from "@material-ui/core";
import CommentWidget from "./comment_widget";
import LogWidget from "./log_widget";
import DeliveryProfileWidget from "./billing_profile/delivery_profile_widget";
import WorkCompletionReportSection from "../components/work_completion_report";
import BoqReportSection from "../components/boq_report";
interface OrderDetailPageProps {
  location: any;
}

const OrderDetailPage: FunctionComponent<OrderDetailPageProps> = (props) => {
  // const query = new URLSearchParams(props.location.search);
  // const order_id = query.get("order_id");

  return (
    <>
      <OrderDetailContext>
        <OrderDetail />
      </OrderDetailContext>
    </>
  );
};

export default OrderDetailPage;

interface OrderDetailProps {}

const OrderDetail: FunctionComponent<OrderDetailProps> = () => {
  // const [show_subs, set_show_subs] = useState(false);
  const { showToast } = useToast();
  const { showModal } = useModal();
  const { getOrder, order, onOrderDetailUpdated } = useOrderDetailCtx();
  const history = useHistory();
  const ref = useRef(null);

  const { url, path } = useRouteMatch();
  useEffect(() => {
    const cb = (e: KeyboardEvent) => {
      if (!e.key) return;
      if (e.key === "Escape") {
        history.goBack();
      } else if (e.key.toLowerCase() === "z" && e.altKey) {
        history.push(`${url}`);
      } else if (e.key.toLowerCase() === "x" && e.altKey) {
        history.push(`${url}/subscription`);
      } else if (e.key.toLowerCase() === "c" && e.altKey) {
        history.push(`${url}/payments`);
      } else if (e.key.toLowerCase() === "v" && e.altKey) {
        history.push(`${url}/devices`);
      } else if (e.key.toLowerCase() === "b" && e.altKey) {
        showComment();
      } else if (e.key.toLowerCase() === "n" && e.altKey) {
        showLog();
      }
    };
    document.addEventListener("keyup", cb);
    return () => document.removeEventListener("keyup", cb);
  }, []);
  const showComment = () => {
    showModal({
      title: "Comment and Remarks",
      type: ModalType.drawer,
      alignment: ModalAlignment.right,
      container: (id) => (
        <OrderComments
          onRemarkChange={(d) => onOrderDetailUpdated(d as any)}
          order={{
            order_id: order.order_id,
            hold_remark: order.hold_remark,
            registration_remark: order.registration_remark,
            remark: order.remark,
            subsidy_status: order.subsidy_status,
          }}
        />
      ),
    });
  };
  const showLog = () => {
    showModal({
      title: "Logs",
      type: ModalType.drawer,
      alignment: ModalAlignment.right,
      container: (id) => <ActionHistory order_id={order.order_id as any} />,
    });
  };
  const showWorkCompletionReport = () => {
    showModal({
      title: "Work completion report",
      type: ModalType.drawer,
      alignment: ModalAlignment.right,
      container: (id) => (
        <WorkCompletionReportSection order_id={order.order_id as any} />
      ),
    });
  };
  const showBoqReport = () => {
    showModal({
      title: "Boq report",
      type: ModalType.drawer,
      alignment: ModalAlignment.right,
      container: (id) => <BoqReportSection order_id={order.order_id as any} />,
    });
  };
  return (
    <>
      <div
        ref={ref}
        className="fixed bottom-3 right-3 flex flex-col justify-center items-center gap-2 z-10"
      >
        <button
          title="Work completion reports"
          // to={`${window.document.location.pathname}/comment`}
          onClick={showWorkCompletionReport}
          className="  rounded-full p-2  hover:scale-105 transform duration-150 bg-myPrimaryColor text-white   shadow cursor-pointer flex items-center justify-center"
        >
          <Icon path={mdiFileDocument} className="h-6 w-6  "></Icon>
        </button>
        <button
          title="Boq reports"
          // to={`${window.document.location.pathname}/comment`}
          onClick={showBoqReport}
          className="  rounded-full p-2  hover:scale-105 transform duration-150 bg-myPrimaryColor text-white   shadow cursor-pointer flex items-center justify-center"
        >
          <Icon path={mdiFileDocument} className="h-6 w-6  "></Icon>
        </button>
        <button
          title="Comment and Remarks"
          // to={`${window.document.location.pathname}/comment`}
          onClick={showComment}
          className="  rounded-full p-2  hover:scale-105 transform duration-150 bg-myPrimaryColor text-white   shadow cursor-pointer flex items-center justify-center"
        >
          <Icon path={mdiCommentOutline} className="h-6 w-6  "></Icon>
        </button>

        <button
          title="Logs"
          // to={`${window.document.location.pathname}/log`}
          onClick={showLog}
          className=" rounded-full p-2 hover:scale-105 transform duration-150    bg-myPrimaryColor text-white   shadow cursor-pointer flex items-center justify-center"
        >
          <Icon path={mdiHistory} className="h-6 w-6  "></Icon>
        </button>
      </div>
      <div className="relative bg-myBgcolor h-screen   overflow-auto">
        <div
          className={`sticky top-0 z-10  bg-gradient-to-b ${
            order.status === OrderStatus.CANCELLED
              ? "from-red-400"
              : order.status === OrderStatus.INIT
              ? "from-yellow-400"
              : "from-green-400"
          } to-myBgcolor`}
        >
          <div className="flex justify-between   select-none text-white ">
            <button
              className="  flex  items-center p-2  hover:text-gray-200  font-bold text-lg"
              onClick={() => {
                history.goBack();
              }}
            >
              <Icon path={mdiChevronLeft} className="h-6 w-6 font-bold"></Icon>{" "}
              Back
            </button>
            <div className="flex gap-2 items-center cursor-pointer   ">
              <div
                onClick={() => {
                  navigator.clipboard.writeText(order.order_id);
                  showToast({
                    type: ToastType.info,
                    text: "Copied to clipboard !",
                  });
                }}
                className="flex gap-2 items-center  hover:text-gray-200 px-2"
              >
                <Icon path={mdiContentCopy} className="w-4 h-4"></Icon>
                <h2 className="text-lg font-semibold">ORDER #</h2>
                <h2 className="text-lg font-semibold italic">
                  {order.order_id}
                </h2>
              </div>
              <div className="w-px h-5 bg-gray-500 "></div>
              <button
                className="  flex  items-center p-2  hover:text-gray-200  font-bold text-lg gap-2"
                onClick={() => getOrder()}
              >
                <Icon path={mdiReload} className="h-4 w-4 font-bold"></Icon>
              </button>
            </div>
          </div>
          <div className="flex justify-center gap-2 py-2 text-sm items-center">
            <NavLink
              exact
              to={`${url}`}
              activeClassName="bg-myPrimaryColor text-white"
              className="rounded-3xl py-1  px-3    bg-white   shadow cursor-pointer flex items-center justify-center"
            >
              <p className="">Order Details</p>
            </NavLink>
            <NavLink
              to={`${url}/subscription`}
              activeClassName="bg-myPrimaryColor text-white"
              className="rounded-3xl py-1  px-3    bg-white   shadow cursor-pointer flex items-center justify-center"
            >
              <p className="">Subscription</p>
            </NavLink>
            <NavLink
              to={`${url}/payments`}
              activeClassName="bg-myPrimaryColor text-white"
              className="rounded-3xl py-1  px-3    bg-white   shadow cursor-pointer flex items-center justify-center"
            >
              <p className="">Payments</p>
            </NavLink>
            <NavLink
              to={`${url}/devices`}
              activeClassName="bg-myPrimaryColor text-white"
              className="rounded-3xl py-1  px-3    bg-white   shadow cursor-pointer flex items-center justify-center"
            >
              <p className="">Devices</p>
            </NavLink>
            <NavLink
              to={`${url}/attachment`}
              activeClassName="bg-myPrimaryColor text-white"
              className="rounded-3xl py-1  px-3    bg-white   shadow cursor-pointer flex items-center justify-center"
            >
              <p className="text-sm">Attachments</p>
            </NavLink>
          </div>
        </div>

        <Route exact path={`${path}`}>
          <div
            className="  p-5 right-0
              transform duration-500 ease-in-out   slide_drawer_ltr "
          >
            <div className="grid grid-cols-1 md:grid-cols-5 gap-5">
              <h3 className="  font-bold  text-gray-500 col-span-2">
                Billing detail
              </h3>
              <h3 className="  font-bold  text-gray-500 col-span-2">
                Delivery detail
              </h3>
              <h3 className="font-semibold text-gray-500 ">Order summery</h3>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-5 gap-5">
              <div className="col-span-2 h-full">
                <BillingProfileWidget />
              </div>
              <div className="col-span-2  h-full">
                <DeliveryProfileWidget />
              </div>
              <OrderSummery />
            </div>
            <div className="my-5">
              <ProductSection />
            </div>{" "}
            {/* {order.monthly_recurring_fee === "Yes" && (
              <div
                className={show_subs ? "" : "bg-white rounded p-5 px-2 my-5"}
              >
                <div className="flex justify-between items-end my-1">
                  <h3 className="font-semibold text-gray-500 ">
                    Subscriptions and Bills
                  </h3>
                  <button
                    onClick={() => set_show_subs((o) => !o)}
                    className="rounded p-1 bg-white"
                  >
                    <Icon
                      className=""
                      path={show_subs ? mdiChevronUp : mdiChevronDown}
                      size={0.8}
                    ></Icon>
                  </button>
                </div>
                {show_subs && <SubscriptionSection />}
              </div>
            )} */}
            <div className="">
              <OrderDetailSection />
            </div>
            <div className="grid grid-cols-1  gap-5 my-5">
              <div className="">
                <ExtraSectionWidget />
              </div>
              <div className="">
                <DiscountSectionWidget />
              </div>
            </div>
          </div>
        </Route>
        <Route path={`${path}/payments`}>
          <div
            className="m-5 right-0
            transform duration-500 ease-in-out   slide_drawer_rtl"
          >
            <PaymentSectionWidget />
          </div>
        </Route>
        <Route path={`${path}/subscription`}>
          <div className="p-5">
            <SubscriptionSection />
          </div>
        </Route>

        <Route path={`${path}/devices`}>
          <OrderDeviceSection />
        </Route>
        <Route path={`${path}/attachment`}>
          <OrderAttachmentWidget order_id={order.order_id} />
        </Route>
      </div>
    </>
  );
};
