import {
  createContext,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  GetTaskSessionLiveRepo,
  StartTaskSessionRepo,
  StopTaskSessionRepo,
  TaskSessionModel,
} from "../service/repos/ops/task_session_repo";
import LoadingWidget from "../ui/components/loading_spinner";
import { getCurrentLocation } from "../utils/location_util";
import { ToastType, useToast } from "./toast/toast_ctx";

export interface TaskCtxModel {
  session?: TaskSessionModel;
  onDuty: boolean;
  position?: GeolocationPosition;
  StopSession: () => Promise<void>;
  StartSession: () => Promise<void>;
  getLocation: () => Promise<void>;
}
const defaultVal: TaskCtxModel = {
  onDuty: true,
  StopSession: async function () {
    // throw new Error("Function not implemented.");
  },
  StartSession: async () => {},
  getLocation: async () => {},
};
const context = createContext<TaskCtxModel>(defaultVal);
export const useTaskSession = () => useContext(context);
interface TaskSessionProviderProps {}

const TaskSessionProvider: FunctionComponent<TaskSessionProviderProps> = ({
  children,
}) => {
  const { showToast } = useToast();
  const [session, setSession] = useState<TaskSessionModel>();
  const [loading, setLoading] = useState(false);
  const [onDuty, setOnDuty] = useState(false);
  const [position, setPosition] = useState<GeolocationPosition>();

  useEffect(() => {
    console.log({ session });

    if (session && !session.off_duty) {
      setOnDuty(true);
    } else setOnDuty(false);
  }, [session]);
  useEffect(() => {
    getSession();
    getLocation();
  }, []);
  const getSession = async () => {
    setLoading(true);
    const res = await GetTaskSessionLiveRepo();
    if (res.success) {
      if (res.data) setSession(res.data);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  const StartSession = async () => {
    setLoading(true);
    if (position) {
      const res = await StartTaskSessionRepo({
        lat: position!.coords.latitude,
        long: position!.coords.longitude,
      });
      if (res.success) {
        setSession(res.data);
      } else {
        showToast({ type: ToastType.error, text: res.error ?? "" });
      }
    }
    setLoading(false);
  };
  const StopSession = async () => {
    setLoading(true);
    if (position) {
      const res = await StopTaskSessionRepo({
        lat: position!.coords.latitude,
        long: position!.coords.longitude,
      });
      if (res.success) {
        setSession(res.data);
      } else {
        showToast({ type: ToastType.error, text: res.error ?? "" });
      }
    }
    setLoading(false);
  };

  const getLocation = async () => {
    setLoading(true);
    try {
      const pos = await getCurrentLocation();
      if (pos) {
        setPosition(pos);
      } else
        showToast({
          type: ToastType.warning,
          text: "Please allow location permission",
        });
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message ?? "" });
    }
    setLoading(false);
  };

  const value: TaskCtxModel = {
    onDuty,
    session,
    position,
    StartSession,
    StopSession,
    getLocation,
  };

  return (
    <context.Provider value={value}>
      {children}
      {loading && <LoadingWidget loading={loading} />}
    </context.Provider>
  );
};

export default TaskSessionProvider;
