import { BaseModel } from "../../../service/models/base";
import { ItemTaxDetail } from "../../inventory/models/item_model";
import {
  ledgerGroupDefaultValue,
  LedgerGroupModel,
} from "./ledger_group_model";
import { BankDetail, PartyModel } from "./party_model";
export const tax_type = {
  gst: "gst",
  import_duties: "import duties",
};
export const tax_category = {
  [tax_type.gst]: {
    integreted: "integreted",
    central: "central",
    state: "state",
    cess: "cess",
  },
};
export interface LedgerTaxDetail {
  type: string;
  category: string;
}
export const ledgerTaxDetailDefaultValue: LedgerTaxDetail = {
  type: "",
  category: "",
};
export interface LedgerModel extends BaseModel {
  party_id?: string;
  party?: PartyModel;
  name: string;
  description?: string;
  group: LedgerGroupModel;
  tax_detail?: LedgerTaxDetail;
  bank_detail?: BankDetail;
  service_tax_applicable?: boolean;
  service_tax?: ItemTaxDetail;
  credit_periods?: number;
  is_default: boolean;
  opening_balance: {
    date: Date;
    txn_type: string;
    amount: number;
  };
}

export const ledgerDefaultValue: LedgerModel = {
  name: "",
  group: ledgerGroupDefaultValue,
  is_default: false,
  opening_balance: {
    date: new Date(),
    txn_type: "",
    amount: 0,
  },
};
