import { fetchGet, fetchPost } from "../../api_client";
export async function GetAuthDataApi() {
  try {
    // ${process.env.REACT_APP_BACKEND_BASE_URL}
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/auth/data`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
}
export async function LogoutApi() {
  try {
    // ${process.env.REACT_APP_BACKEND_BASE_URL}
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/auth/logout`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
}
// export async function AuthRevokeApi(token: string) {
//   try {
//     // ${process.env.REACT_APP_BACKEND_BASE_URL}
//     const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/auth/revoke-token`;
//     const res = await fetchPost(url, { token });

//     return res;
//   } catch (error: any) {
//     return { success: false, error: error.message };
//   }
// }
export async function LoginApi(data: { username: string; password: string }) {
  try {
    // ${process.env.REACT_APP_BACKEND_BASE_URL}
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/auth/login`;
    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
}
export async function EmailLoginApi(data: { email: string }) {
  try {
    // ${process.env.REACT_APP_BACKEND_BASE_URL}
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/auth/email-login`;
    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
}
export async function GoogleLoginApi(data: { idToken: string }) {
  try {
    // ${process.env.REACT_APP_BACKEND_BASE_URL}
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/auth/google-login`;
    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
}
export async function ForgotPasswordApi(data: { [key: string]: string }) {
  try {
    // ${process.env.REACT_APP_BACKEND_BASE_URL}
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/auth/forgot-password`;
    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
}
