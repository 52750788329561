import { BaseModel } from "../../../service/models/base";
export interface UnitMeasureModel extends BaseModel {
  symbol: string;
  formal_name: string;
  uqc: string;
}

export const unitMeasureDefaultValue: UnitMeasureModel = {
  symbol: "",
  formal_name: "",
  uqc: "",
};

export const unique_qty_code = [
  {
    id: 1,
    quantity: "BAGS",
    quantity_type: "Measure",
    uqc: "BAG",
  },
  {
    id: 2,
    quantity: "BALE",
    quantity_type: "Measure",
    uqc: "BAL",
  },
  {
    id: 3,
    quantity: "BUNDLES",
    quantity_type: "Measure",
    uqc: "BDL",
  },
  {
    id: 4,
    quantity: "BUCKLES",
    quantity_type: "Measure",
    uqc: "BKL",
  },
  {
    id: 5,
    quantity: "BILLIONS OF UNITS",
    quantity_type: "Measure",
    uqc: "BOU",
  },
  {
    id: 6,
    quantity: "BOX",
    quantity_type: "Measure",
    uqc: "BOX",
  },
  {
    id: 7,
    quantity: "BOTTLES",
    quantity_type: "Measure",
    uqc: "BTL",
  },
  {
    id: 8,
    quantity: "BUNCHES",
    quantity_type: "Measure",
    uqc: "BUN",
  },
  {
    id: 9,
    quantity: "CANS",
    quantity_type: "Measure",
    uqc: "CAN",
  },
  {
    id: 10,
    quantity: "CUBIC METER",
    quantity_type: "Volume",
    uqc: "CBM",
  },
  {
    id: 11,
    quantity: "CUBIC CENTIMETER",
    quantity_type: "Volume",
    uqc: "CCM",
  },
  {
    id: 12,
    quantity: "CENTIMETER",
    quantity_type: "Length",
    uqc: "CMS",
  },
  {
    id: 13,
    quantity: "CARTONS",
    quantity_type: "Measure",
    uqc: "CTN",
  },
  {
    id: 14,
    quantity: "DOZEN",
    quantity_type: "Measure",
    uqc: "DOZ",
  },
  {
    id: 15,
    quantity: "DRUM",
    quantity_type: "Measure",
    uqc: "DRM",
  },
  {
    id: 16,
    quantity: "GREAT GROSS",
    quantity_type: "Measure",
    uqc: "GGR",
  },
  {
    id: 17,
    quantity: "GRAMS",
    quantity_type: "Weight",
    uqc: "GMS",
  },
  {
    id: 18,
    quantity: "GROSS",
    quantity_type: "Measure",
    uqc: "GRS",
  },
  {
    id: 19,
    quantity: "GROSS YARDS",
    quantity_type: "Length",
    uqc: "GYD",
  },
  {
    id: 20,
    quantity: "KILOGRAMS",
    quantity_type: "Weight",
    uqc: "KGS",
  },
  {
    id: 21,
    quantity: "KILOLITER",
    quantity_type: "Volume",
    uqc: "KLR",
  },
  {
    id: 22,
    quantity: "KILOMETRE",
    quantity_type: "Length",
    uqc: "KME",
  },
  {
    id: 23,
    quantity: "MILLILITRE",
    quantity_type: "Volume",
    uqc: "MLT",
  },
  {
    id: 24,
    quantity: "METERS",
    quantity_type: "Length",
    uqc: "MTR",
  },
  {
    id: 25,
    quantity: "METRIC TONS",
    quantity_type: "Weight",
    uqc: "MTS",
  },
  {
    id: 26,
    quantity: "NUMBERS",
    quantity_type: "Measure",
    uqc: "NOS",
  },
  {
    id: 27,
    quantity: "PACKS",
    quantity_type: "Measure",
    uqc: "PAC",
  },
  {
    id: 28,
    quantity: "PIECES",
    quantity_type: "Measure",
    uqc: "PCS",
  },
  {
    id: 29,
    quantity: "PAIRS",
    quantity_type: "Measure",
    uqc: "PRS",
  },
  {
    id: 30,
    quantity: "QUINTAL",
    quantity_type: "Weight",
    uqc: "QTL",
  },
  {
    id: 31,
    quantity: "ROLLS",
    quantity_type: "Measure",
    uqc: "ROL",
  },
  {
    id: 32,
    quantity: "SETS",
    quantity_type: "Measure",
    uqc: "SET",
  },
  {
    id: 33,
    quantity: "SQUARE FEET",
    quantity_type: "Area",
    uqc: "SQF",
  },
  {
    id: 34,
    quantity: "SQUARE METERS",
    quantity_type: "Area",
    uqc: "SQM",
  },
  {
    id: 35,
    quantity: "SQUARE YARDS",
    quantity_type: "Area",
    uqc: "SQY",
  },
  {
    id: 36,
    quantity: "TABLETS",
    quantity_type: "Measure",
    uqc: "TBS",
  },
  {
    id: 37,
    quantity: "TEN GROSS",
    quantity_type: "Measure",
    uqc: "TGM",
  },
  {
    id: 38,
    quantity: "THOUSANDS",
    quantity_type: "Measure",
    uqc: "THD",
  },
  {
    id: 39,
    quantity: "TONNES",
    quantity_type: "Weight",
    uqc: "TON",
  },
  {
    id: 40,
    quantity: "TUBES",
    quantity_type: "Measure",
    uqc: "TUB",
  },
  {
    id: 41,
    quantity: "US GALLONS",
    quantity_type: "Volume",
    uqc: "UGS",
  },
  {
    id: 42,
    quantity: "UNITS",
    quantity_type: "Measure",
    uqc: "UNT",
  },
  {
    id: 43,
    quantity: "YARDS",
    quantity_type: "Length",
    uqc: "YDS",
  },
  {
    id: 44,
    quantity: "OTHERS",
    quantity_type: "",
    uqc: "OTH",
  },
];
