import {
  mdiCheck,
  mdiCheckboxMarkedCircleOutline,
  mdiChevronDown,
  mdiChevronUp,
  mdiClose,
  mdiCloseCircleOutline,
  mdiInformationOutline,
} from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useState } from "react";
import ProgressBar from "../../../inventory/items/components/progress_bar";
import {
  PurchaseOrderModel,
  approval_level,
  approval_threshold,
  getPoApprovedCounts,
  levelModule,
  po_approval_status,
} from "../../model/purchase_order_model";
import ApprovalProgressBar from "./approval_progress_bar";
import { Link } from "react-router-dom";
import { urls } from "../../../../utils/urls";
import moment from "moment";
import StepperWidget from "../../../../ui/new_components/common/stepper_widget";
import { useAuth } from "../../../../context_providers/auth/authProvider";
import {
  useModal,
  ModalType,
  ModalAlignment,
} from "../../../../context_providers/modal/modal_context";
import { ACTION } from "../../../../utils/enums";
import ApprovalData from "../../purchase_order_approval/components/approval_data_sheet";

interface PoCreateBottomSheetWidgetProps {
  data: PurchaseOrderModel;
  onSubmit: () => void;
}

const PoCreateBottomSheetWidget: FunctionComponent<
  PoCreateBottomSheetWidgetProps
> = (props) => {
  const { user, isAuthorised } = useAuth();
  const { showModal } = useModal();
  const [expanded, setExpanded] = useState(false);
  const [showActionBtn, setshowActionBtn] = useState(false);
  const [approvalCounts, setapprovalCounts] = useState<{
    [key: string]: number;
  }>({});
  const [approvalLevels, setapprovalLevels] = useState<{
    [key: string]: number;
  }>({});

  useEffect(() => {
    const counts = getPoApprovedCounts({ approvals: props.data.approvals });
    setapprovalCounts(counts);

    const rule =
      approval_threshold[props.data.approval_level || approval_level.level_0];

    const t = rule.approvals_required.reduce(
      (
        pv: {
          [key: string]: number;
        },
        v: { level: string; count: number }
      ) => ({ ...pv, [v.level]: v.count }),
      {}
    );

    setapprovalLevels(t);
  }, [props.data]);

  useEffect(() => {
    setshowActionBtn(
      (props.data.current_approval_required_count ?? 0) >
        (props.data.current_approval_count ?? 0) &&
        isAuthorised({
          action: ACTION.READ,
          module: levelModule[props.data.current_approval_level!],
        })
    );
  }, [props.data]);
  const levels = Object.keys(approvalLevels);
  
  return (
    <div className="">
      {props.data.approval_level !== approval_level.level_0 && (
        <div className="mt-2 border rounded-md p-2 bg-gray-50 mb-2">
          <div className="pb-5">
            <StepperWidget
              steps={levels.map((level, i) => ({
                label: level,
                validated:
                  i < levels.indexOf(props.data.current_approval_level!) ||
                  (props.data.approval_level ==
                    props.data.current_approval_level &&
                    (props.data.current_approval_count ?? 0) >=
                      (props.data.current_approval_required_count ?? 0)),
                required: false,
              }))}
              activeStep={levels.indexOf(props.data.current_approval_level!)}
              onChange={function (step: number): void {
                showModal({
                  container: (i) => (
                    <div className="p-5">
                      <ApprovalData
                        approvals={
                          props.data.approvals?.filter(
                            (f) => f.level == levels[step]
                          ) ?? []
                        }
                        approved_count={approvalCounts[levels[step]] ?? 0}
                        approval_count_required={approvalLevels[levels[step]]}
                        approval_level={levels[step]}
                      />
                    </div>
                  ),
                  title: `${levels[step]} Approval Detail`,
                  type: ModalType.drawer,
                  alignment: ModalAlignment.right,
                });
              }}
            />
          </div>
        </div>
      )}
      <div className="flex justify-between ">
        <div className="">
          <div className="flex gap-1 text-gray-500 text-xs mt-2 items-center">
            <Icon path={mdiInformationOutline} size={0.8}></Icon>
            This Purchase order requires {props.data.approval_level} approval
          </div>
        </div>

        <div className="text-sm font-semibold flex gap-2">
          {props.data.current_approval_level === props.data.approval_level &&
            props.data.current_approval_count ===
              props.data.current_approval_required_count &&
            props.data.po_no && (
              <Link
                to={urls.purchase_voucher + `/create?po_no=${props.data.po_no}`}
                className={`text-center px-3 py-1 rounded-md bg-myPrimaryColor text-white text-sm ${props.data.items.reduce((acc, next) => {
                  acc += next.unit_no - (next.received_unit_no - next.returned_unit_no)
                  return acc
                }, 0) === 0 ? "hidden" : ""}`}

              >
                Inward
              </Link>
            )}
          <button
            onClick={() => {
              props.onSubmit();
            }}
            className={`${
              false ? "bg-gray-400 cursor-not-allowed" : "bg-myPrimaryColor"
            } rounded-md px-3 py-1 text-white`}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default PoCreateBottomSheetWidget;
