import { Tooltip } from "@material-ui/core";
import { mdiMicrosoftExcel } from "@mdi/js";
import Icon from "@mdi/react";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { UserModel } from "../../../../context_providers/auth/user_model";
import {
  useToast,
  ToastType,
} from "../../../../context_providers/toast/toast_ctx";
import { GetUsersRepo } from "../../../../service/repos/customer/customer_repo";

import { urls } from "../../../../utils/urls";
import ModalDialog from "../../../new_components/common/modal";
import Pagination from "../../../new_components/common/pagination";
import SearchFieldWidget from "../../../new_components/search_field";
import ExportWidget from "./component/export_widget";

interface CustomersListingPageProps {}

const CustomersListingPage: FunctionComponent<
  CustomersListingPageProps
> = () => {
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [customers, setCustomers] = useState<UserModel[]>([]);
  const [showExport, setShowExport] = useState(false);

  const [filter, setFilter] = useState<{
    page: number;
    count: number;
    all?: boolean;
    unique?: boolean;
    fromDate?: string;
    toDate?: string;
    type?: string[];
    search?: string;
  }>({ page: 1, count: 100, unique: true });
  const getCustomers = async () => {
    setLoading(true);
    const res = await GetUsersRepo(filter);
    if (res.success) {
      setTotal(res.data.metadata.total);
      setCustomers(res.data.data);
    } else showToast({ type: ToastType.error, text: res.error ?? "" });
    setLoading(false);
  };
  useEffect(() => {
    getCustomers();
  }, [filter]);
  return (
    <div className="p-5">
      {" "}
      {showExport && (
        <ModalDialog
          show={showExport}
          title="Export"
          onClose={() => setShowExport(false)}
        >
          <ExportWidget />
        </ModalDialog>
      )}
      <div className="flex justify-between  flex-wrap mb-2">
        <h1 className="text-lg font-medium">Customers</h1>
        <div className="flex gap-2 items-end flex-wrap">
          <Tooltip title="Search by ticket id ,order id,resonsible person">
            <div className="">
              <SearchFieldWidget
                onClear={() => {
                  setFilter((o) => ({ ...o, search: "" }));
                }}
                placeholder="Search"
                onSubmit={(search) => {
                  setFilter((o) => ({ ...o, search }));
                }}
              />
            </div>
          </Tooltip>
          <button
            className="flex items-center border rounded px-3 py-1 text-sm gap-2"
            onClick={() => setShowExport(true)}
          >
            <Icon path={mdiMicrosoftExcel} className="h-4 w-4  " />
            Export
          </button>
          {/* <Link
            to={urls.create_operation_task}
            className="flex items-center border rounded px-3 py-1 text-sm "
          >
            Create Task
          </Link> */}
        </div>
      </div>
      {/* <div
        className="sm:hidden  flex-col justify-between overflow-hidden   "
        style={{ height: "90vh" }}
      >
        <div className="overflow-auto" style={{ height: "92%" }}>
          {customers.map((customer, i) => (
            <Link to={`${urls.create_customer}?id=${customer.id}`} key={i}>
              <div className="my-5 shadow p-2 text-sm">
                <div className="flex justify-between">
                  <div className="">{customer.firstName}</div>
                  <div className="">{ticket.type}</div>
                </div>
                <div className="text-xs">{ticket.order_id ?? ""}</div>
                <div className="text-xs">{ticket.warehouse_name}</div>
                <div className="flex justify-between mt-2">
                  <div className="text-xs">{ticket.emp_name ?? ""}</div>
                  <div className="text-xs">
                    Due Date {new Date(ticket.last_date).toLocaleDateString()}
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
        <div className="">
          <Pagination
            itemCount={customers.length}
            page={filter.page}
            total={total}
            count={filter.count}
            onChange={(page) => setFilter((o) => ({ ...o, page }))}
          />
        </div>
      </div> */}
      <div
        className="hidden sm:flex  flex-col justify-between overflow-hidden   "
        style={{ height: "90vh" }}
      >
        <div
          style={{ height: "95%" }}
          className="border rounded overflow-auto "
        >
          <table className="w-full  text-sm">
            <thead className="rounded shadow ">
              <tr className="text-xs sticky top-0 bg-white z-10 whitespace-nowrap">
                <th className="px-2 py-2" align="left">
                  Id
                </th>
                <th className="px-2 py-2" align="left">
                  Name
                </th>

                <th className="px-2 py-2" align="left">
                  mobile
                </th>

                <th className="px-2 py-2" align="left">
                  Mail
                </th>
              </tr>
            </thead>
            <tbody>
              {customers.map((cust, i) => (
                <tr className="border-b text-xs whitespace-nowrap" key={i}>
                  <td className="px-3 py-2" align="left">
                    <div className="">{cust.uid}</div>
                  </td>
                  <td className="px-3 py-2" align="left">
                    <div className="">
                      {cust.first_name} {cust.last_name ?? ""}
                    </div>
                  </td>

                  <td className="px-3 py-2" align="left">
                    <div className="">{cust.mobile}</div>
                  </td>

                  <td className="px-3 py-2" align="left">
                    <div className="">{cust.email}</div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="">
          <Pagination
            itemCount={customers.length}
            page={filter.page}
            total={total}
            count={filter.count}
            onChange={(page) => setFilter((o) => ({ ...o, page }))}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomersListingPage;
