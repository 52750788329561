import { fetchGet, fetchPost, fetchPut } from "../../api_client";
export interface OpsTicketDevice {
  device_id: string;
  product_id: number;
  product_name: string;
  operation: string;
  contact_person: string;
  contact_person_mobile: string;
  address: string;
  state: string;
  city: string;
  pincode: string;
  emp_id: string;
  emp_name: string;
  valid?: boolean;
}
export interface OpsTicketData {
  order_id: string;
  warehouse_id: number;
  inventories: OpsTicketDevice[];
}
export interface OpsBasketModel {
  id?: number;
  ops_id: number;
  device_id: string;
  order_id: string;
  warehouse_id: number;
  emp_id: string;
  emp_name: string;
  received_from: string;
  received_from_id: number;
  handed_to?: string;
  handed_to_id?: number;
  status: string;
  created_at?: string;
  updated_at?: string;
}

export interface OpsTicketModel {
  id?: number;
  active: number;
  order_id: string;
  warehouse_id: number;
  emp_id: string;
  emp_name: string;
  device_id: string;
  product_id: number;
  product_name: string;
  operation: string;
  contact_person: string;
  contact_person_mobile: string;
  address: string;
  city: string;
  state: string;
  pincode: string;
  status: string;
  created_by: number;
  updated_by?: number;
  created_by_name: string;
  updated_by_name: string;

  created_at?: string;

  updated_at?: string;
  courier_name?: string;
  courier_tracking_id?: string;
}
export const CreateOpsTicket = async (data: OpsTicketData) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/ops/ops-ticket`;
    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GetOpsTicket = async (data: {
  page: number;
  count: number;
  all?: any;
  mine?: any;
  warehouse_id?: number;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/ops/get-list`;
    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const UpdateOpsTicket = async (data: OpsTicketModel) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/ops/ops-ticket`;
    const res = await fetchPut(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const DevicePickedRepo = async (data: {
  ops_id: number;
  device_id: string;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/ops/ops-ticket/picked-device`;
    const res = await fetchPost(url, data);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GetOpsBasketByOpsIdRepo = async ({
  ops_id,
}: {
  ops_id: number;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/ops/ops-ticket/ops-basket?ops_id=${ops_id}`;
    const res = await fetchGet(url);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GetOpsBasketByEmpIDRepo = async ({
  emp_id,
}: {
  emp_id?: string;
}) => {
  try {
    const url = `${
      process.env.REACT_APP_BACKEND_BASE_URL
    }/api/v2/ops/ops-ticket/emp-basket${emp_id ? `?emp_id=${emp_id}` : ""}`;
    const res = await fetchGet(url);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GetOpsBasketByOrderIDRepo = async ({
  order_id,
}: {
  order_id: number;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/ops/ops-ticket/order-basket?order_id=${order_id}`;
    const res = await fetchGet(url);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const PostDeviceCourierRepo = async (data: {
  ops_id: number;
  courier_name: string;
  courier_tracking_id: string;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/ops/ops-ticket/courier-device`;
    const res = await fetchPost(url, data);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GetDeviceDeliveryOtpRepo = async (data: { ops_id: number }) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/ops/ops-ticket/delivery-device-get-otp`;
    const res = await fetchPost(url, data);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const VerifyDeviceDeliveryOtpRepo = async (data: {
  ops_id: number;
  otp: string;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/ops/ops-ticket/delivery-device-verify-otp`;
    const res = await fetchPost(url, data);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
