import moment from "moment";
import { FunctionComponent, useState } from "react";
import { NavLink } from "react-router-dom";
import { useAuth } from "../../../../context_providers/auth/authProvider";
import { UTCToLocalDate } from "../../../../utils/date_util";
import { urls } from "../../../../utils/urls";
import HrmButton from "../components/HrmButton";
import Layout from "../layout";
import AttritionGraph from "./components/Attrition";
import LeavesGraph from "./components/LeavesGraph";
import MoodGraph from "./components/Mood";
import StrengthGraph from "./components/Strength";

interface HrmAnalyticsProps {}

const HrmAnalytics: FunctionComponent<HrmAnalyticsProps> = () => {
  const [fromDate, setFromDate] = useState<number>(
    moment().subtract(90, "days").unix() * 1000
  );
  const [toDate, setToDate] = useState<number>(moment().unix() * 1000);

  const [tempFrom, setTempFrom] = useState<number>(
    moment().subtract(30, "days").unix() * 1000
  );
  const [tempTo, setTempTo] = useState<number>(moment().unix() * 1000);

  const { user } = useAuth();

  return (
    <Layout>
      <div className="grid lg:grid-cols-3 gap-x-6 gap-y-10 py-4 lg:py-2 px-2">
        <div
          className=" flex flex-col justify-center text-white rounded-xl lg:rounded-3xl px-6 py-12 lg:py-0 bg-black"
          // style={{ background: "#111727" }}
        >
          <div className="lg:text-2xl ">
            Welcome back,{" "}
            <span className="font-semibold"> {user?.first_name} </span>{" "}
          </div>
          <div className="text-gray-400 mt-3 text-sm">
            Track department and zone-wise organization's strength as well as
            attrition rate. Also track department wise leave and average mood of
            the organization.
          </div>

          <div className="flex items-center gap-4 mt-4">
            <div className="">
              <label htmlFor="" className="text-sm text-gray-400">
                From
              </label>
              <br />
              <input
                type="date"
                min="2021-01-01"
                className="text-sm focus:outline-none bg-white text-black border border-gray-300 rounded px-1"
                defaultValue={moment(fromDate).format("YYYY-MM-DD")}
                onChange={
                  (e) => {
                    const date = UTCToLocalDate(e.target.value)!;
                    setTempFrom(date.getTime());
                  }
                  // setTempFrom(moment(e.target.value).unix() * 1000)
                }
              />
            </div>
            <div className="">
              <label htmlFor="" className="text-sm text-gray-400">
                To
              </label>
              <br />
              <input
                type="date"
                className="text-sm focus:outline-none bg-white text-black border border-gray-300 rounded px-1"
                defaultValue={moment(toDate).format("YYYY-MM-DD")}
                onChange={(e) => {
                  const date = UTCToLocalDate(e.target.value)!;
                  setTempTo(date.getTime());
                }}
                max={moment().format("YYYY-MM-DD")}
              />
            </div>
          </div>
          <div className="mt-6">
            <HrmButton
              text="Apply"
              type="primary"
              className="text-sm"
              onClick={() => {
                setFromDate(tempFrom);
                setToDate(tempTo);
                console.log("done");
              }}
            />
          </div>
        </div>
        <LeavesGraph fromDate={fromDate} toDate={toDate} />
        <div className="row-span-2 rounded-2xl w-full shadow-lg">
          <MoodGraph fromDate={fromDate} toDate={toDate} />
          <div className="bg-white">
            <div className="font-bold px-4 pt-2">
              Kazam employee area wise graph{" "}
            </div>
            <NavLink to={urls.hrm_employee_map}>
              <img src="/images/hrm/analytics/kazamIndiaMap.png" alt="" />
            </NavLink>
          </div>
        </div>
        <AttritionGraph fromDate={fromDate} toDate={toDate} />
        <StrengthGraph fromDate={fromDate} toDate={toDate} />
      </div>
    </Layout>
  );
};

export default HrmAnalytics;
