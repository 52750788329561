import { mdiRefresh } from "@mdi/js";
import Icon from "@mdi/react";
import debounce from "lodash.debounce";
import moment from "moment";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import LoadingWidget from "../../../../context_providers/modal/loader";
// import { useModal } from "../../../../context_providers/modal/modal_context";
import {
  useToast,
  ToastType,
} from "../../../../context_providers/toast/toast_ctx";
import { FieldType } from "../../../../procurement/components/create_view/form_field";
import TableView from "../../../../procurement/components/table_comp";
import { fetchGet, toQueryString } from "../../../../service/api_client";
import SearchFieldWidget from "../../../../ui/new_components/search_field";
import { UTCToLocalDate } from "../../../../utils/date_util";
import { currencyFormat } from "../../../../utils/orders_utils";
import {
  PaymentRequestModel,
  po_payment_req_status,
} from "../../model/payment_request_model";
import ModalDialog from "../../../../ui/new_components/common/modal";
import { ProcurementProjectModel } from "../../projects/model/project_model";
import { PurchaseOrderModel } from "../../model/purchase_order_model";

interface PaymentRequestListProps {}

const PaymentRequestList: FunctionComponent<PaymentRequestListProps> = () => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order/payment_request`;
  // const { showModal, onClose } = useModal();

  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [currentPODetails, setCurrentPODetails] =
    useState<PaymentRequestModel>();

  const [project, setProject] = useState<ProcurementProjectModel>();
  const [page, setPage] = useState(1);
  const [count] = useState(50);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<PaymentRequestModel[]>([]);
  const [search, setSearch] = useState("");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  useEffect(() => {
    const now = new Date();
    let from = new Date(new Date(now).setHours(0, 0, 0, 0));
    from = new Date(from.setDate(1));
    let to = new Date(new Date(now).setHours(23, 59, 59, 999));
    to = new Date(to.setMonth(now.getMonth() + 1));
    to = new Date(to.setDate(0));
    setFromDate(from);
    setToDate(to);
  }, []);
  const fetchData = async (prop: {
    search?: string;
    page: number;
    count: number;
    all?: boolean;
    fromDate: Date;
    toDate: Date;
  }) => {
    setLoading(true);
    try {
      const res = await fetchGet(url + toQueryString(prop));
      if (res.success) {
        setData(res.data.data);
        if (res.data.metadata) {
          setTotal(res.data.metadata.total);
        }
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
      //   return { success: false, error: error.message };
    }
    setLoading(false);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandler = useCallback(
    debounce(
      (prop: {
        search?: string;
        page: number;
        count: number;
        all?: boolean;
        fromDate: Date;
        toDate: Date;
      }) => {
        if (prop) fetchData(prop);
        else setData([]);
      },
      100
    ),
    []
  );

  const fetchPurchaseOrderByPoNo = async (
    poNo: string
  ): Promise<PurchaseOrderModel | null> => {
    try {
      const res = await fetchGet(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order/${poNo}`
      );
      if (res.success) {
        // Assuming res.data is a single PurchaseOrderModel
        return res.data as PurchaseOrderModel;
      } else {
        showToast({ type: ToastType.error, text: res.error });
        return null;
      }
    } catch (error) {
      showToast({ type: ToastType.error, text: (error as Error).message });
      return null;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (data.length > 0) {
        const poNo = data[0].po_no;
        const po = await fetchPurchaseOrderByPoNo(poNo);

        if (po && po.project_id) {
          getProjectData(po.project_id);
        }
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const getProjectData = async (id: string) => {
    setLoading(true);

    const res = await fetchGet(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/project/${id}`
    );
    if (res.success) {
      setProject(res.data); // Directly set the project data
    } else {
      showToast({ type: ToastType.error, text: res.error });
    }
    setLoading(false);
  };

  useEffect(() => {
    debouncedHandler({ page, count, search, fromDate, toDate });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, fromDate, toDate, search]);
  // const navaigation = useHistory();
  const onSelect = (d: PaymentRequestModel) => {
    setCurrentPODetails(d);
    // navaigation.push(urls.purchase_order + "/" + d.id);
  };
  return (
    <>
      {currentPODetails && (
        <ModalDialog
          show={currentPODetails ? true : false}
          title={"Payment Request Approve"}
          onClose={function (): void {
            setCurrentPODetails(undefined);
          }}
        >
          <div className="flex flex-col gap-4 w-96 h-max p-2">
            <div className="flex flex-col gap-1">
              <label className="text-sm text-gray-500">PO No</label>
              <p>{currentPODetails?.po_no || "--"}</p>
            </div>
            <div className="flex items-center justify-between">
              <div>
                <label className="text-sm text-gray-500">Amount</label>
                <p>{currentPODetails?.amount || 0}</p>
              </div>
              <div>
                <label className="text-sm text-gray-500">Due Date</label>
                <p>
                  {moment(currentPODetails?.due_date).format("YYYY-MM-DD") ||
                    "--"}
                </p>
              </div>
              <div>
                <label className="text-sm text-gray-500">Request by</label>
                <p>{currentPODetails?.created_by_name || "--"}</p>
              </div>
            </div>

            {currentPODetails.status === po_payment_req_status.pending && (
              <div className="flex items-center gap-2">
                PO Status :-
                <p className="text-blue-500 bg-blue-100 px-4 py-1 rounded-full w-max text-sm">
                  Pending
                </p>
              </div>
            )}
            {currentPODetails.status === po_payment_req_status.accepted && (
              <div className="flex items-center gap-2">
                PO Status :-
                <p className="text-green-500 bg-green-100 px-4 py-1 rounded-full w-max text-sm">
                  Accepted
                </p>
              </div>
            )}
            {currentPODetails.status === po_payment_req_status.rejected && (
              <>
                <div className="flex items-center gap-2">
                  PO Status :-
                  <p className="text-red-500 bg-red-100 px-4 py-1 rounded-full w-max text-sm">
                    Rejected
                  </p>
                </div>
                <p className="text-sm text-gray-500">
                  Reason :-{" "}
                  <span className="italic">
                    {currentPODetails.reason || "--"}
                  </span>
                </p>
              </>
            )}
          </div>
        </ModalDialog>
      )}

      <div
        id="page_container"
        className="flex flex-col justify-between h-full p-1"
      >
        <section id="top_section" className=" ">
          <div className="flex justify-between items-end my-1">
            <h1 className="font-bold text-2xl text-gray-500 flex items-center gap-3">
              <p className=" font-bold border-l-4 border-myPrimaryColor pl-2">
                PO Payment Requests
              </p>
              <button
                title="Refersh"
                onClick={() =>
                  debouncedHandler({ page, count, search, fromDate, toDate })
                }
              >
                <Icon path={mdiRefresh} className="h-6 w-6"></Icon>
              </button>
            </h1>
            <div className="flex justify-end   text-sm items-center gap-1 ">
              <div className="text-sm flex items-center gap-2">
                <div className="flex items-center  gap-1">
                  <label htmlFor="fromDate" className="">
                    From
                  </label>
                  <input
                    type="date"
                    name=""
                    id="fromDate"
                    className="p-1 rounded "
                    value={moment(fromDate).format("YYYY-MM-DD")}
                    onChange={(e) => {
                      const now = UTCToLocalDate(e.target.value)!;
                      const from = new Date(new Date(now).setHours(0, 0, 0, 0));

                      setFromDate(from);
                    }}
                  />
                </div>
                <div className="flex items-center  gap-1">
                  <label htmlFor="toDate" className="">
                    To
                  </label>
                  <input
                    type="date"
                    name=""
                    id="toDate"
                    className="p-1 rounded "
                    value={moment(toDate).format("YYYY-MM-DD")}
                    onChange={(e) => {
                      const now = UTCToLocalDate(e.target.value)!;
                      const to = new Date(
                        new Date(now).setHours(23, 59, 59, 999)
                      );
                      setToDate(to);
                    }}
                  />
                </div>
              </div>
              <SearchFieldWidget
                defaulValue={search}
                onClear={() => {
                  setSearch("");
                  setPage(1);
                }}
                onSubmit={(val) => {
                  setPage(1);
                  setSearch(val);

                  // setTimeout(() => {
                  //   setSearch(val);
                  // }, 10);
                }}
              />
              {/* <div
                onClick={() => {
                  navaigation.push(urls.purchase_order + "/approval/:id");

                  // showModal({
                  //   title: `Add item unit`,
                  //   type: ModalType.modal,
                  //   container(id) {
                  //     return (
                  //       <ItemUnitCreateScreen
                  //         onClose={(d) => {
                  //           if (d)
                  //             setData((o) => {
                  //               o.pop();
                  //               return [d, ...o];
                  //             });
                  //           setTotal((o) => o + 1);
                  //           onClose(id);
                  //         }}
                  //       />
                  //     );
                  //   },
                  // });
                }}
                className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
              >
                <Icon path={mdiPlus} className="h-4 w-4"></Icon>Add
              </div> */}
            </div>
          </div>
          {/* <div className="h-52 w-full bg-green-200 "></div> */}
        </section>

        <TableView
          headers={[
            {
              key: "po_no",
              title: "PO #",
              type: FieldType.string,
            },
            {
              key: "project_name",
              title: "Project",
              type: FieldType.string,
            },
            {
              key: "date",
              title: "Date",
              type: FieldType.date,
            },
            {
              colspan: 4,
              key: "description",
              title: "Note",
              type: FieldType.long_string,
            },
            {
              key: "amount",
              title: "Amount",
              type: FieldType.currency,
            },
            {
              key: "status",
              title: "Status",
              type: FieldType.string,
            },
            {
              key: "created_by",
              title: "Requested by",
              type: FieldType.string,
            },
            {
              key: "approved_at",
              title: "Approved At",
              type: FieldType.date,
            },
            {
              key: "approved_by",
              title: "Approved by",
              type: FieldType.string,
            },
          ]}
          rows={data.map((data, i) => ({
            data,
            values: [
              data.po_no,
              project?.name,
              data.created_at,
              data.description || "--",
              currencyFormat(data.amount),
              data.status,
              data.created_by_name,
              data.approved_at,
              data.approved_by_name,
            ],
          }))}
          page={page}
          total={total}
          count={count}
          itemCount={data.length}
          onPageChange={setPage}
          onClick={onSelect}
        />
      </div>

      {loading && <LoadingWidget></LoadingWidget>}
    </>
  );
};

export default PaymentRequestList;
