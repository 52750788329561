import { RouteParam } from ".";
import APIClientsPage from "../ui/pages/api_clients/admin";
import MyAPIClientsPage from "../ui/pages/api_clients/my_api_client_page";

import TransactionPage from "../ui/pages/transactions/txn_listing";
import { ACTION, MODULE } from "../utils/enums";
import { urls } from "../utils/urls";

const api_integration_routes: RouteParam[] = [
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.api_client_list,
    component: APIClientsPage,
    main_module: MODULE.access_panel,
    module: MODULE.api_master,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.api_client_my_list,
    component: MyAPIClientsPage,
    main_module: MODULE.api_inegration_panel,
    module: MODULE.api,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
];

export default api_integration_routes;
