import moment from "moment";
import { FunctionComponent, useState } from "react";
import { X } from "react-feather";
import { LeaveRequest } from "../../../../../service/models/user_model";
import { LeaveRequestStatus } from "../../../../../utils/enums";
import HrmPopup from "../../components/HrmPopup";
import "../../styles/index.css";

interface AllActivityProps {
  data: LeaveRequest;
  uid: string;
}

const AllActivity: FunctionComponent<AllActivityProps> = ({ uid, data }) => {
  const [selectedRequest, setSelectedRequest] = useState<LeaveRequest>();

  return (
    <>
      <div className="grid grid-cols-2 lg:grid-cols-8 gap-y-3 py-3 pl-4 border-b">
        <div className="">
          <div className="text-black dark:text-primaryColor text-sm font-semibold">
            {data.emp_name}
          </div>

          <div className="text-xs mt-1 text-secondaryColor">
            LM -<span className="text-xs ml-2">{data.reporting_name}</span>
          </div>
        </div>
        <div className="text-black dark:text-primaryColor text-sm">
          {data.leave_name}
        </div>
        <div className="text-sm text-black dark:text-primaryColor">
          <span className="lg:hidden">From-</span>
          {moment(data.from_date, "YYYY-MM-DD HH-mm-ss").format("DD MMM YYYY")}
        </div>
        <div className="text-sm text-black dark:text-primaryColor">
          <span className="lg:hidden">To-</span>
          {moment(data.to_date, "YYYY-MM-DD HH-mm-ss").format("DD MMM YYYY")}
        </div>
        <div className="text-sm text-black dark:text-primaryColor">
          {data?.period}
        </div>
        <div className="text-sm text-black ">
          {moment(data.created_at).format("lll")}
        </div>
        <div className="text-sm text-black dark:text-primaryColor">
          {data?.message}
        </div>
        <div className="">
          {/* <button
            className=" px-3 py-1 rounded-full bg-red-700 text-white text-xs font-semibold cursor-pointer"
            onClick={() => setSelectedRequest(data)}
          >
            View
          </button> */}
          <button
            className={`ml-2 lg:ml-4 mt-1 lg:mt-0 py-1 px-3 font-semibold rounded-full inline-block lg:inline ${
              data.status === LeaveRequestStatus.approved
                ? "bg-green-400"
                : data.status === LeaveRequestStatus.rejected
                ? "bg-red-400"
                : data.status === LeaveRequestStatus.cancelled
                ? "bg-yellow-400"
                : "bg-blue-300"
            }
          
          ${
            data.status === LeaveRequestStatus.approved
              ? "text-green-800"
              : data.status === LeaveRequestStatus.rejected
              ? "text-red-800"
              : data.status === LeaveRequestStatus.cancelled
              ? "text-yellow-600"
              : "text-blue-800"
          }
           text-xs`}
          >
            {data.status}
          </button>
          <div
            className={` ${
              data.status === LeaveRequestStatus.pending ? "hidden" : ""
            } text-xs mt-1`}
          >
            by {data.updated_by_name}
          </div>
        </div>
      </div>

      {selectedRequest && (
        <HrmPopup>
          <div className="m-auto  formModal py-8 px-10 rounded-xl relative bg-white dark:bg-hrmCardDark">
            <div className="absolute top-2 right-3">
              <X
                className="dark:text-primaryColor transform hover:scale-125 duration-300 cursor-pointer"
                size={24}
                onClick={() => setSelectedRequest(undefined)}
              />
            </div>
            <div className="text-2xl font-bold text-white">Leave detail</div>
            <div className="flex justify-between text-sm mt-7">
              <div className="dark:text-secondaryColor">
                <div className="">Name</div>
                <div className="mt-6">From</div>
                <div className="mt-6">To</div>
                <div className="mt-6">Leave Type</div>
                <div className="mt-6">Message</div>
              </div>
              <div className="dark:text-primaryColor text-sm">
                <div className="">{selectedRequest.emp_name}</div>
                <div className="mt-6">{selectedRequest.from_date}</div>
                <div className="mt-6">{selectedRequest.to_date}</div>
                <div className="mt-6">{selectedRequest.leave_name}</div>
                <div className="mt-6 dark:text-white text-base font-semibold">
                  {selectedRequest.message}
                </div>
              </div>
            </div>
          </div>
        </HrmPopup>
      )}
    </>
  );
};

export default AllActivity;
