import { mdiImageBrokenVariant, mdiVideo } from "@mdi/js";
import Icon from "@mdi/react";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";

import { AttachmentModel } from "../../../../../service/models/attachment_modal";
import {
  GetComplaintAttachmentRepo,
  UploadComplaintAttachmentRepo,
} from "../../../../../service/repos/support/complaint_repo";
import {
  GetTrasactionAttachmentRepo,
  UploadTrasactionAttachmentRepo,
} from "../../../../../service/repos/transaction_repo";
import { attachement_belong, FileType } from "../../../../../utils/enums";
import { compressImage } from "../../../../../utils/image_compressor";
// import FileUploadWidget from "../../../../components/common/file_upload_widget";
import ImageTile from "../../../../components/common/image_tile";
import PdfTile from "../../../../components/common/pdf_tile";
import LoadingWidget from "../../../../components/loading_spinner";
import FileUploadWidget from "../../../../new_components/common/file_upload";
import ModalDialog from "../../../../new_components/common/modal";
import StadiumButton from "../../../../new_components/common/stadium_button";

interface AttachmentWidgetProps {
  complaint_id: string;
}

const AttachmentWidget: FunctionComponent<AttachmentWidgetProps> = ({
  complaint_id,
}) => {
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [attachments, setAttachments] = useState<AttachmentModel[]>([]);
  const [showAddAttachment, setShoAddAttachment] = useState<boolean>(false);
  const [showUploadAttachment, setShowUploadAttachement] = useState(false);
  const [selectedFile, setSelectedFile] = useState<AttachmentModel>();

  const getAttachments = async () => {
    setLoading(true);

    const res = await GetComplaintAttachmentRepo(complaint_id);
    if (res.success) {
      setAttachments(res.data);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  const uploadAttachment = async (data: FormData) => {
    setLoading(true);

    const res = await UploadComplaintAttachmentRepo(complaint_id, data);
    if (res.success) {
      setShoAddAttachment(false);
      getAttachments();
      showToast({ type: ToastType.error, text: res.message ?? "" });
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  useEffect(() => {
    getAttachments();
  }, []);

  return (
    <>
      {selectedFile && (
        <ModalDialog
          title={selectedFile.category}
          show={selectedFile !== undefined}
          onClose={() => setSelectedFile(undefined)}
        >
          {" "}
          <div className="max-h-96">
            {selectedFile.type === FileType.VIDEO ? (
              <video
                className={`rounded-lg w-96 h-auto `}
                id="cmsVideo"
                autoPlay
                muted
                playsInline
                controls
              >
                <source src={selectedFile.url} type="video/mp4" />
              </video>
            ) : selectedFile.type === FileType.IMAGE ? (
              <img src={selectedFile.url} className="w-96 h-auto"></img>
            ) : selectedFile.type === FileType.PDF ? (
              <>
                <embed
                  src={selectedFile.url}
                  type="application/pdf"
                  className="w-96 h-auto"
                ></embed>{" "}
              </>
            ) : (
              <>Unkown format</>
            )}
          </div>
        </ModalDialog>
      )}
      {showAddAttachment && (
        <ModalDialog
          title="Upload attachment"
          show={showAddAttachment}
          onClose={() => setShoAddAttachment(false)}
        >
          <div className="p-5">
            <FileUploadWidget
              onComplete={() => {
                getAttachments();
                setShoAddAttachment(false);
              }}
              belong={attachement_belong.complaint}
              ref_id={complaint_id}
            />
          </div>
        </ModalDialog>
      )}
      <div
        className=" w-full  p-5"
        style={{ height: "80vh", minWidth: "50vw" }}
      >
        <div className="">
          <div className="flex justify-end my-2">
            <StadiumButton onTap={() => setShoAddAttachment(true)}>
              {" "}
              Upload Attachment
            </StadiumButton>
          </div>{" "}
          <div className="flex flex-col gap-2    md:hidden">
            {attachments.map((file, i) => (
              <div
                className=" flex  gap-2 justify-center border rounded items-center  h-auto cursor-pointer"
                key={i}
              >
                <div className="w-28">
                  <div
                    className="flex justify-center items-center   w-28 h-20  bg-gray-200"
                    onClick={() => {
                      console.log(file);

                      setSelectedFile(file);
                    }}
                  >
                    {file.type === FileType.IMAGE ? (
                      <img src={file.url} alt="" className="h-20 w-28  " />
                    ) : file.type === FileType.PDF ? (
                      <PdfTile url={file.url} />
                    ) : file.type === FileType.VIDEO ? (
                      <Icon path={mdiVideo} size="30" />
                    ) : (
                      <Icon path={mdiImageBrokenVariant} size="30" />
                    )}
                  </div>
                </div>
                <div className="text-xs w-28">
                  <p>{file.created_by_name}</p>
                  <p> {moment(file.created_at).format("DD MMM YYYY")}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="  hidden md:block">
            <table className=" w-full text-xs mb-5 border rounded-sm ">
              <thead>
                <tr className="border-b">
                  <th></th>

                  <th>Created Date</th>
                  <th>Created by</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {attachments.map((file, i) => (
                  <tr key={i}>
                    <td align="center">
                      <div
                        className="flex justify-center items-center rounded-full h-14 w-14 overflow-hidden bg-gray-200 cursor-pointer"
                        onClick={() => {
                          console.log(file);

                          setSelectedFile(file);
                        }}
                      >
                        {file.type === FileType.IMAGE ? (
                          <img
                            src={file.url}
                            alt=""
                            className="h-14 w-14 rounded-full"
                          />
                        ) : file.type === FileType.PDF ? (
                          <PdfTile url={file.url} />
                        ) : file.type === FileType.VIDEO ? (
                          <Icon path={mdiVideo} size="30" />
                        ) : (
                          <Icon path={mdiImageBrokenVariant} size="30" />
                        )}
                      </div>
                    </td>
                    <td align="center">
                      {moment(file.created_at).format("DD MMM YYYY")}
                    </td>
                    <td align="center">{file.created_by_name}</td>{" "}
                    <td>
                      <div className="">
                        <a target={"_blank"} href={file.url}>
                          View
                        </a>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <LoadingWidget loading={loading} />
    </>
  );
};

export default AttachmentWidget;
