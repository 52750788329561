import { mdiCheck, mdiClose, mdiPause, mdiPlay } from "@mdi/js";
import Icon from "@mdi/react";
import axios, { CancelTokenSource } from "axios";
import { log } from "console";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import {
  ToastType,
  useToast,
} from "../../../context_providers/toast/toast_ctx";
import { fetchPost } from "../../../service/api_client";
import {
  CancelUploadApi,
  CompleteUploadApi,
  GetUploadUrlsApi,
} from "../../../service/repos/file_upload";
import { attachement_belong } from "../../../utils/enums";
import FileDropZone from "./file_drop_zone";

interface Part {
  ETag: string;
  PartNumber: number;
}

interface FileUploadSnapProps {
  file: File;
  key: number;
  onCancel: () => void;
  onCompleted: () => void;
  ref_id: string;
  category?: string;
  belong: string;
}

const FileUploadSnap: FunctionComponent<FileUploadSnapProps> = ({
  file,
  key,
  onCancel,
  onCompleted,
  ref_id,
  category,
  belong,
}) => {
  const { showToast } = useToast();
  const [part_number, setPartNumber] = useState(1);
  const [index, setIndex] = useState(0);
  const [total_parts, setTotalParts] = useState(0);
  const [Key, setKey] = useState("");
  const [uploadId, setUploadId] = useState("");
  // const [ref_id, setRef_id] = useState(ref_id);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(true);
  const [cancelled, setCancelled] = useState(false);
  const [uploads, setUploads] = useState<Part[]>([]);
  const [urls, setUrls] = useState<{ [key: string]: string }>();
  const cancelTokenSource = useRef<CancelTokenSource>();

  const FILE_CHUNK_SIZE = 10000000;

  useEffect(() => {
    getUrls();
  }, []);
  useEffect(() => {
    if (urls && uploading) upload();
  }, [urls, uploading, index]);

  const getUrls = async () => {
    setLoading(true);
    const parts = Math.ceil(file.size / FILE_CHUNK_SIZE);
    setTotalParts(parts);
    const res = await GetUploadUrlsApi({
      mimetype: file.type,
      parts,
      belong,
    });
    if (res.success) {
      setKey(res.data.key);
      setUploadId(res.data.upload_id);
      setUrls(res.data.urls);
      showToast({ type: ToastType.success, text: res.message ?? "" });
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };

  async function uploadChunk(readerEvent: ProgressEvent<FileReader>) {
    const client = axios.create();
    delete client.defaults.headers.put["Content-Type"];
    const buffer = readerEvent.target!.result as Buffer;
    const start = index * FILE_CHUNK_SIZE;
    const end = (index + 1) * FILE_CHUNK_SIZE;
    const total_parts = Object.keys(urls ?? {}).length;
    const blob =
      index < total_parts ? buffer.slice(start, end) : buffer.slice(start);
    cancelTokenSource.current = axios.CancelToken.source();
    const config = {
      cancelToken: cancelTokenSource.current.token,
      onUploadProgress: (progressEvent: any) => {
        const per = Math.ceil(
          ((progressEvent.loaded / progressEvent.total + index) / total_parts) *
            100
        );
        setProgress(per);
      },
    };
    const res = await client.put(urls![index.toString()], blob, config);

    if (index === total_parts - 1) {
      await CompleteUploadApi({
        upload_id: uploadId,
        key: Key,
        mimetype: file.type,
        meta: {
          belong,
          ref_id,
          category,
        },
      });
      onCompleted();
    } else {
      setIndex((o) => o + 1);
    }
  }

  const upload = () => {
    console.log({ urls });
    const reader = new FileReader();
    reader.onload = (ev) => {
      if (ev.target) uploadChunk(ev);
    };
    reader.readAsArrayBuffer(file);
  };
  const cancelRequest = () => {
    if (cancelTokenSource.current) cancelTokenSource.current.cancel();
  };
  const cancel = async () => {
    if (cancelTokenSource.current) cancelTokenSource.current.cancel();
    const res = await CancelUploadApi({ key: Key, upload_id: uploadId });

    if (res.success) {
      setUploadId("");
      setKey("");
      setUrls({});
      setIndex(0);
      setCancelled(true);
      showToast({ type: ToastType.info, text: res.message });
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
  };

  return (
    <div key={key} className="p-5 border rounded-md">
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <div className="max-w-xs  overflow-ellipsis overflow-hidden text-xs">
            {file.name}
          </div>
        </div>
        <div className="">
          <button
            onClick={async () => {
              if (uploadId && progress < 100) {
                await cancel();
                onCancel();
              } else onCancel();
            }}
          >
            <Icon path={mdiClose} size="20"></Icon>
          </button>
        </div>
      </div>
      {loading ? (
        <div className="text-sm text-center">Loading ...</div>
      ) : (
        <div className="">
          <p className="text-xs">
            {progress < 100 ? "Uploading" : "Completed"}
          </p>{" "}
          <div className="flex">
            <div className="w-full bg-gray-200 rounded-full">
              {cancelled ? (
                <div className="flex justify-center items-center  text-xs font-medium text-blue-100 bg-red-600 text-center h-full p-0.5 rounded-full">
                  cancelled
                </div>
              ) : (
                <div
                  className={`flex justify-center items-center  text-xs font-medium text-blue-100 text-center h-full p-0.5   ${
                    progress < 99 ? "rounded-l-full" : "rounded-full  "
                  } ${
                    progress > 0 && progress < 100
                      ? "bg-blue-600"
                      : progress === 100
                      ? "bg-green-600"
                      : " "
                  }    `}
                  style={{ width: (progress < 5 ? 5 : progress) + "%" }}
                >
                  {progress === 100 ? "Completed" : `${progress}%`}
                </div>
              )}
            </div>
            <div className="flex">
              {progress !== 100 && !cancelled && (
                <button
                  onClick={() => {
                    if (uploading) {
                      cancelRequest();
                    }
                    setUploading((o) => !o);
                  }}
                >
                  <Icon path={uploading ? mdiPause : mdiPlay} size="20"></Icon>
                </button>
              )}
            </div>
          </div>
          <div className="flex justify-end mt-5">
            {uploadId && progress !== 100 && (
              <button
                className="text-xs border rounded-full px-2 py-1"
                onClick={() => {
                  cancel();
                }}
              >
                Cancel
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

interface FileUploadWidgetProps {
  ref_id: string;
  category?: string;
  belong: string;
  onComplete: () => void;
}

const FileUploadWidget: FunctionComponent<FileUploadWidgetProps> = ({
  ref_id,
  category,
  belong,
  onComplete,
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [completed, setCompleted] = useState(0);

  return (
    <>
      <FileDropZone
        onDrop={(f) => setFiles((o) => [...o, ...f])}
        multiple={true}
      />
      <div className="text-xs max-w-sm">
        <p>Please note:</p>
        <ul className="list-disc">
          <li>Multi file upload is supported</li>
          <li>Do not close the modal form before upload completes.</li>
          {/* <li>Once completed you can close the form and hit refresh button.</li> */}
        </ul>
      </div>
      <div className="my-3 flex flex-col gap-2">
        {files.map((file, i) => (
          <FileUploadSnap
            onCompleted={() => {
              if (completed === files.length - 1) {
                onComplete();
              } else setCompleted((o) => o++);
            }}
            belong={belong}
            category={category}
            ref_id={ref_id}
            file={file}
            key={i}
            onCancel={() => setFiles((o) => o.filter((f, j) => i !== j))}
          />
        ))}
      </div>
    </>
  );
};

export default FileUploadWidget;
