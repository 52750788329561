import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import {
  ToastType,
  useToast,
} from "../../../context_providers/toast/toast_ctx";
import {
  ApiClientModel,
  DeleteApiClientApi,
  GetAdminApiClientsApi,
} from "../../../service/repos/api_clients_repo";
import LoadingWidget from "../../components/loading_spinner";
import Pagination from "../../new_components/common/pagination";

interface APIClientsPageProps {}

const APIClientsPage: FunctionComponent<APIClientsPageProps> = () => {
  const { showToast } = useToast();
  const [clients, setClients] = useState<ApiClientModel[]>([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(100);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const deleteClient = async (id: string) => {
    setLoading(true);
    const res = await DeleteApiClientApi(id);

    if (res.success) {
      setClients((o) => o.filter((f) => f.id !== id));
      setTotal((o) => o - 1);
      //   setPage(res.page);
      //   setCount(res.count);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  const getClients = async () => {
    setLoading(true);
    const res = await GetAdminApiClientsApi({ count, page });

    if (res.success) {
      setClients(res.data);
      setTotal(res.total);
      //   setPage(res.page);
      //   setCount(res.count);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  useEffect(() => {
    getClients();
  }, [page]);
  return (
    <div className="bg-gray-200 h-screen p-3">
      <div className="flex my-1">
        <h3 className="text-lg font-medium">API Clients</h3>
      </div>
      <div
        className="bg-white max-h-full rounded-md p-2 flex flex-col justify-between"
        style={{ height: "95%" }}
      >
        <div className=" overflow-auto   " style={{ height: "93%" }}>
          <table className="border divide-y divide-gray-200 relative w-full ">
            <thead className="bg-black text-white sticky  top-0">
              <tr>
                {["Date", "Username", "Name", "Api id", "Api key", " "].map(
                  (h) => (
                    <th
                      align="center"
                      key={h}
                      className="px-3 py-2 text-xs font-medium    uppercase tracking-wider"
                    >
                      {h}
                    </th>
                  )
                )}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200   text-xs">
              {clients.map((client, i) => {
                //
                return (
                  <tr
                    className="hover:bg-gray-200"
                    key={i}
                    // onDoubleClick={() => props.onTxnClick(txn.id!)}
                  >
                    <td className="px-3 py-2 whitespace-nowrap text-xs font-medium text-gray-900">
                      {moment(client.created_at).format("lll")}
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap text-xs font-medium text-gray-900">
                      {client.user_name}
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap text-xs font-medium text-gray-900">
                      {client.name}
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap text-xs font-medium text-gray-900">
                      {client.api_id}
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap text-xs font-medium text-gray-900">
                      {client.api_key}
                    </td>

                    <td align="center" className="flex">
                      <button
                        className="text-blue-300 rounded  hover:text-red-600 mx-1 p-1"
                        onClick={() => deleteClient(client.id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <hr />
        <div className="">
          <Pagination
            itemCount={clients.length}
            page={page}
            total={total}
            count={count}
            onChange={(p) => setPage(p)}
          />
        </div>
      </div>
      <LoadingWidget loading={loading} />
    </div>
  );
};

export default APIClientsPage;
